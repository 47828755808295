import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { createContext } from 'react';
import { useAppSelector } from '../redux/hooks';
import useFetchResource from '../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess } from '../react-query/types';
import {
    DASHBOARD_BUILDER_CLIENT_USERS,
    DASHBOARD_BUILDER_DASHBOARD_HOMEPAGE,
} from '../dashboard-builder/config/resources-dashboard-builder';
import { useQueryClient } from '@tanstack/react-query';

export type HomepageDashboard = {
    dashboardId: number | null;
    dashboardTitle: string | null;
};

type HomepageContextValues = {
    homepage: HomepageDashboard;
    setHomepage: Dispatch<SetStateAction<HomepageDashboard>>;
    homepageIsLoading: boolean;
};

export const HomepageContext = createContext<HomepageContextValues>({} as HomepageContextValues);

const HomepageProvider = ({ children }: { children: JSX.Element }) => {
    const queryClient = useQueryClient();
    const { token } = useAppSelector(state => state.account);
    const { email } = useAppSelector(state => state.user);

    const [clientUserId, setClientUserId] = useState<number | null>(null);
    const [homepage, setHomepage] = useState<HomepageDashboard>({ dashboardId: null, dashboardTitle: null });
    const [homepageIsLoading, setHomepageIsLoading] = useState(true);

    const clientUserIdQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_CLIENT_USERS,
        params: [{ key: 'email', value: email }],
        enabled: !!token && !!email,
    });

    const homepageQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_DASHBOARD_HOMEPAGE,
        params: [{ key: 'user', value: clientUserId || '' }],
        enabled: !!clientUserId,
    });

    useEffect(() => {
        if (!email || !token) {
            setClientUserId(null);
            setHomepage({
                dashboardId: null,
                dashboardTitle: null,
            });
            queryClient.invalidateQueries();
        }
    }, [email, token]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (clientUserIdQuery.isSuccess) {
            if (clientUserIdQuery.data && clientUserIdQuery.data.objects.length === 1) {
                setClientUserId(clientUserIdQuery.data.objects[0].id);
            }
        }
    }, [clientUserIdQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (homepageQuery.status === 'success') {
            if (homepageQuery.data && homepageQuery.data.objects && homepageQuery.data.objects.length === 1) {
                setHomepage({
                    dashboardId: homepageQuery.data.objects[0].dashboard.id,
                    dashboardTitle: homepageQuery.data.objects[0].dashboard.title,
                });
            }
            setHomepageIsLoading(false);
        } else {
            setHomepageIsLoading(false);
        }
    }, [homepageQuery.status]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <HomepageContext.Provider value={{ homepage, setHomepage, homepageIsLoading }}>
            {children}
        </HomepageContext.Provider>
    );
};

export default HomepageProvider;
