import React from 'react';
import { PieData } from '../../../../types';
import WidgetMenuDropdown from '../widget-menu-dropdown';
import WidgetMenuRadio from '../widget-menu-radio';
import WidgetMenuSectionTitle from '../widget-menu-section-title';

type WidgetMenuPieProps = {
    data: PieData;
};

const WidgetMenuPie = ({ data }: WidgetMenuPieProps) => {
    const loading = data.status === 'loading';
    const currentChartField = data.request?.chartField;
    const chartMetrics = data.request?.chartMetrics;
    const setChartField = data.request?.setChartField;

    if (currentChartField && chartMetrics) {
        return (
            <WidgetMenuDropdown loading={loading}>
                <WidgetMenuSectionTitle title="Chart Metric" />
                <WidgetMenuRadio
                    options={chartMetrics.map(metric => {
                        return {
                            value: metric.rawName,
                            label: metric.displayName,
                        };
                    })}
                    value={currentChartField.rawName}
                    onValueChange={option => {
                        const newField = chartMetrics.find(metric => metric.rawName === option);

                        if (newField && setChartField) {
                            setChartField(newField);
                        }
                        return;
                    }}
                />
            </WidgetMenuDropdown>
        );
    }
    return null;
};

export default WidgetMenuPie;
