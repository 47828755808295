// React Dependencies
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink, useLocation } from 'react-router-dom';

// Core Dependencies
import Axios from 'axios';
import {
    isPassword,
    hasCapitalLetter,
    hasLowerCaseLetter,
    hasNumber,
    hasSpecialCharacter,
    hasPasswordValidCharacters,
} from '../helpers/validator';

// Redux Actions
import { addNotification } from '../redux/actions/notification';
import { NotificationMessageType } from '../enums/notification-types';

// Import Components
import FormPassword from '../components/form-fields/form-password';
import InputButton from '../components/inputs/input-button';
import IconArrow from '../components/icons/arrow';

// Import Views
import ViewNotifications from './notifications';
import ViewLoadingSplash from './loading-splash';
import PasswordRequirements from '../components/password-requirements';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import imageLogoWhiteFull from '../assets/images/white-cubed-logo-full.png';

const ViewUserPasswordReset = () => {
    const { meta, user } = useSelector(state => state);
    const dispatch = useDispatch();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [isValidLink, setIsValidLink] = useState(null);
    const [passwordHasLength, setPasswordHasLength] = useState(false);
    const [passwordHasCapitalLetter, setPasswordHasCapitalLetter] = useState(false);
    const [passwordHasLowercaseLetter, setPasswordHasLowercaseLetter] = useState(false);
    const [passwordHasNumber, setPasswordHasNumber] = useState(false);
    const [passwordHasSpecialCharacter, setPasswordHasSpecialCharacter] = useState(false);
    const [passwordHasValidCharacter, setPasswordHasValidCharacter] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState(false);
    const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState('');
    const [passwordValue, setPassword] = useState('');
    const [repeatPasswordValue, setRepeatPassword] = useState('');

    let isLoggedIn = false;

    useEffect(() => {
        const uidToken = location.pathname.split('/')[4];

        Axios.get(`${meta.apiDomain}/api/user/password/reset-password/${uidToken}/`)
            .then(res => {
                setIsLoading(false);
                setIsValidLink(true);
            })
            .catch(error => {
                setIsLoading(false);
                setIsValidLink(false);

                dispatch(
                    addNotification({
                        copy: 'This link is no longer valid, please request another password reset.',
                        type: NotificationMessageType.Error,
                    })
                );
            });
    });

    const formValidator = () => {
        let hasFormError = false;
        let errorMessageObject = {
            'password__error-message': '',
            'repeat-password__error-message': '',
        };

        if (!isPassword(passwordValue) || passwordValue.length === 0) {
            hasFormError = true;
            errorMessageObject['password__error-message'] = 'Please enter a valid Password.';
        }

        if (passwordValue !== repeatPasswordValue || repeatPasswordValue.length === 0) {
            hasFormError = true;
            errorMessageObject['repeat-password__error-message'] = 'Please ensure that the new passwords match.';
        }

        if (hasFormError) {
            setPasswordErrorMessage(errorMessageObject['password__error-message']);
            setRepeatPasswordErrorMessage(errorMessageObject['repeat-password__error-message']);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    const passwordReset = () => {
        const resetConfig = {
            method: 'POST',
            url: `${meta.apiDomain}/api/user/password/reset-complete/`,
            data: {
                new_password1: passwordValue,
                new_password2: repeatPasswordValue,
                token: location.pathname.split('/')[4],
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        Axios(resetConfig)
            .then(res => {
                dispatch(addNotification({ copy: 'Password has been reset.', type: NotificationMessageType.Success }));
                setIsComplete(true);
                setIsButtonLoading(false);
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue trying to reset your password. Please try again later',
                        type: NotificationMessageType.Error,
                    })
                );
                setIsComplete(false);
                setIsButtonLoading(false);
            });
    };

    const onResetPasswordClick = () => {
        setIsButtonLoading(true);
        setPasswordErrorMessage('');
        setRepeatPasswordErrorMessage('');

        if (formValidator() === true) {
            passwordReset();
        } else {
            setIsButtonLoading(false);
        }
    };

    const onNewPasswordChange = event => {
        const value = event.target.value;

        setPasswordHasLength(value.length >= 8);
        setPasswordHasCapitalLetter(hasCapitalLetter(value));
        setPasswordHasLowercaseLetter(hasLowerCaseLetter(value));
        setPasswordHasNumber(hasNumber(value));
        setPasswordHasSpecialCharacter(hasSpecialCharacter(value));
        setPasswordHasValidCharacter(hasPasswordValidCharacters(value));
        setPassword(value);
    };

    const onRepeatPasswordChange = event => {
        const value = event.target.value;
        setRepeatPassword(value);
    };

    if (isLoggedIn || user.isLoggedIn) {
        return <Navigate to="/account-selection" />;
    } else if (isLoading === true) {
        return <ViewLoadingSplash />;
    } else if (isValidLink === false) {
        return <Navigate to="/forgotten-password" />;
    } else if (isComplete === true) {
        return <Navigate to="/" />;
    }

    return (
        <div id="forgotten-password__view">
            <img src={imageLoginBackground} alt="Background" />
            <div className="forgotten-password__content">
                <img src={imageLogoWhiteFull} alt="Yard" />
                <div className="forgotten-password__content__form">
                    <NavLink to="/">
                        <div class="icon icon-arrow">
                            <IconArrow />
                        </div>
                    </NavLink>
                    <h3>Reset Password</h3>
                    <FormPassword
                        inputPlaceholder="New Password..."
                        inputValue={passwordValue}
                        inputOnChange={onNewPasswordChange}
                        errorMessage={passwordErrorMessage}
                    />
                    <PasswordRequirements
                        hasCharacterLength={passwordHasLength}
                        hasCapitalLetter={passwordHasCapitalLetter}
                        hasLowercaseLetter={passwordHasLowercaseLetter}
                        hasNumber={passwordHasNumber}
                        hasSpecialCharacter={passwordHasSpecialCharacter}
                        hasValidCharacter={passwordHasValidCharacter}
                    />
                    <FormPassword
                        inputPlaceholder="Repeat New Password..."
                        inputValue={repeatPasswordValue}
                        inputOnChange={onRepeatPasswordChange}
                        errorMessage={repeatPasswordErrorMessage}
                    />
                    <div className="forgotten-password__content__form__buttons">
                        <InputButton
                            isLoading={isButtonLoading}
                            value="Reset Password"
                            onClick={onResetPasswordClick}
                        />
                    </div>
                </div>
            </div>
            <ViewNotifications />
        </div>
    );
};

export default ViewUserPasswordReset;
