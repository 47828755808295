import React, { useState } from 'react';
import styled from 'styled-components';
import axios, { AxiosPromise } from 'axios';
import validators from '../../../components/forms/validation/validators';

// Redux
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../redux/actions/modal';
import { addNotification } from '../../../redux/actions/notification';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { FieldError, FieldValues } from 'react-hook-form';
import { NotificationMessageType } from '../../../enums/notification-types';

// Helpers
import { generateUrlDetail } from '../../../helpers/request-builder';

// Components
import ModalNavigation from '../../../components/modal-navigation';
import Form from '../../../components/forms/form';
import InputErrorMessage from '../../../components/forms/inputs/components/input-error-message';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 10px 20px;
`;

const StyledDescription = styled.p`
    font-size: 0.9rem;
    margin: 0;
`;

const InviteUsers = () => {
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);

    const [isEmpty, setIsEmpty] = useState(false);
    const [hasDuplicates, setHasDuplicates] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const checkData = (data: FieldValues) => {
        const emails = [
            ...(data?.emailsAdmin ? data.emailsAdmin : []),
            ...(data?.emailsViewer ? data.emailsViewer : []),
            ...(data?.emailsAPI ? data.emailsAPI : []),
        ];

        const empty = emails.length === 0;
        const duplicates = new Set(emails).size !== emails.length;

        setIsEmpty(empty);
        setHasDuplicates(duplicates);

        if (!empty && !duplicates) {
            return data;
        }
    };

    const handleFormValuesChange = (data: FieldValues) => {
        checkData(data);
    };

    const handleSubmit = (data: FieldValues) => {
        setHasSubmitted(true);
        const checkedData = checkData(data);

        if (checkedData) {
            const allEmails: { email: string; group: number }[] = [];
            const requests: AxiosPromise[] = [];

            checkedData?.emailsAdmin &&
                checkedData.emailsAdmin.forEach((email: string) => {
                    allEmails.push({ email, group: 1 });
                });

            checkedData?.emailsViewer &&
                checkedData.emailsViewer.forEach((email: string) => {
                    allEmails.push({ email, group: 3 });
                });

            checkedData?.emailsAPI &&
                checkedData.emailsAPI.forEach((email: string) => {
                    allEmails.push({ email, group: 5 });
                });

            allEmails.forEach(emailObject => {
                requests.push(
                    axios({
                        method: 'POST',
                        url: generateUrlDetail(account.token, 'users', 'invite', [], false) || '',
                        data: emailObject,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });

            Promise.all(requests)
                .then(response => {
                    let errors = false;
                    let specificUserErrors: string[] = [];

                    response.forEach(res => {
                        switch (res.data.status) {
                            case 500:
                                specificUserErrors.push(res.data.message);
                                errors = true;
                                break;

                            default:
                                break;
                        }
                    });

                    if (errors) {
                        // Request successful but there were non network type errors
                        // Example : 5 users invited but one already as access to this account.
                        specificUserErrors.forEach(message => {
                            dispatch(addNotification({ copy: 'Emails sent but  ' + message, type: 'warning' }));
                        });
                        dispatch(setModal('ManageUsers', {}));
                    } else {
                        dispatch(
                            addNotification({
                                copy: 'All invites have been successfully sent.',
                                type: NotificationMessageType.Success,
                            })
                        );
                        dispatch(setModal('ManageUsers', {}));
                    }
                })

                .catch(e => {
                    // Handles actual request failure
                    dispatch(
                        addNotification({
                            copy: 'There was a problem sending the invites. If the problem persists please contact support@cubed.email.',
                            type: NotificationMessageType.Error,
                        })
                    );
                });
        }
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(setModal('ManageUsers', {})),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Invite Users</h2>
            <p>
                Invite new users to have access to your Cubed account. Please ensure you enter the correct email
                addresses and give the user adequate access by selecting the appropriate role. More details about each
                role can be found on our support page. Simply type individual emails or paste a list of emails separated
                by commas, and press enter to add them to the list.
            </p>

            <StyledFormContainer>
                <Form onSubmit={handleSubmit} onWatchFieldsChange={handleFormValuesChange}>
                    <Form.Body direction="column">
                        <Form.Section>
                            <Form.SectionTitle>Admin Users</Form.SectionTitle>

                            <StyledDescription>
                                An admin user has full access to the platform to both view and modify any account
                                settings or configurations.
                            </StyledDescription>

                            <Form.Field>
                                <Form.InputTag name="emailsAdmin" validators={[validators.isEmailList]} />
                            </Form.Field>
                        </Form.Section>

                        <Form.Section>
                            <Form.SectionTitle>Viewer Users</Form.SectionTitle>

                            <StyledDescription>
                                A user with the viewer role can only view the dashboards and has no permission to view
                                or modify any account settings or configurations.
                            </StyledDescription>

                            <Form.Field>
                                <Form.InputTag name="emailsViewer" validators={[validators.isEmailList]} />
                            </Form.Field>
                        </Form.Section>

                        <Form.Section>
                            <Form.SectionTitle>API Users</Form.SectionTitle>

                            <StyledDescription>
                                A user with the API role can only use the API section of the platform.
                            </StyledDescription>

                            <Form.Field>
                                <Form.InputTag name="emailsAPI" validators={[validators.isEmailList]} />
                            </Form.Field>
                        </Form.Section>
                        {hasSubmitted && isEmpty ? (
                            <InputErrorMessage error={{ message: 'Please add at least one email' } as FieldError} />
                        ) : null}
                        {hasSubmitted && hasDuplicates ? (
                            <InputErrorMessage error={{ message: 'Duplicate email detected' } as FieldError} />
                        ) : null}
                    </Form.Body>
                    <Form.Footer>
                        <Form.InputButton value="Send Invites" type="submit" />
                        <Form.InputButton value="Clear" type="reset" buttonTheme={ButtonThemes.Secondary} />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </>
    );
};

export default InviteUsers;
