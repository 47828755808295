import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import ContributionTableBody from './body/contribution-table-body';
import ContributionTableHeaderRow from './rows/contribution-table-header-row';

// Types
import { ContributionTableData, ContributionTableHeaders, orderByDir } from '../types';

// Styles
const StyledContributionAnalysisTableWrapper = styled.div`
    ${props => props.theme.boxShadow(1)}
    ${props => props.theme.scrollbar(8, 8)}
    background-color: ${props => props.theme.colours.white};
    position: relative;
    width: 100%;
    padding: 7px;
    margin: 5px;
    float: right;
    height: 78vh;
    overflow-y: auto;
`;

const StyledContributionAnalysisTable = styled.table<{ isLoading: boolean }>`
    position: relative;
    width: 100%;
    opacity: ${props => (props.isLoading ? `0.3` : `1`)};
    transition: ${props => props.theme.transition};
`;

// Types
export type ContributionTableProps = {
    headerColumns: ContributionTableHeaders[];
    data: ContributionTableData[];
    isLoading: boolean;
    orderBy: string;
    orderDir: orderByDir;
    setOrderBy: (columnName: string, orderDir: orderByDir) => void;
};

const ContributionTable = ({
    headerColumns,
    data,
    isLoading,
    orderBy = 'lc_referer',
    orderDir = 'asc',
    setOrderBy,
}: ContributionTableProps) => {
    const [orderByState, setOrderByState] = useState(orderBy);
    const [orderDirState, setOrderDirState] = useState(orderDir);

    const handleSetCurrentOrder = (columnName: string) => {
        let orderDir = 'asc';

        if (columnName === orderByState) {
            orderDir = orderDirState === 'asc' ? 'desc' : 'asc';
        }

        setOrderBy(columnName, orderDir as orderByDir);
        setOrderByState(columnName);
        setOrderDirState(orderDir as orderByDir);
    };

    return (
        <StyledContributionAnalysisTableWrapper>
            <StyledContributionAnalysisTable isLoading={isLoading}>
                <ContributionTableHeaderRow
                    orderByState={orderByState}
                    orderDirState={orderDirState}
                    headerColumns={headerColumns}
                    handleSetCurrentOrder={handleSetCurrentOrder}
                />
                <ContributionTableBody data={data} />
            </StyledContributionAnalysisTable>
        </StyledContributionAnalysisTableWrapper>
    );
};

export default ContributionTable;
