import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

export enum WidgetDialogCloseButtonTheme {
    Primary,
    Secondary,
}

const StyledButtonWrapper = styled.div<{ buttonTheme: WidgetDialogCloseButtonTheme; disabled?: boolean }>`
    & button {
        cursor: pointer;
        background-color: ${props =>
            props.buttonTheme === WidgetDialogCloseButtonTheme.Secondary
                ? 'transparent'
                : props.disabled
                ? props.theme.sectionDashboard.widget.dialog.closeButton.backgroundColorDisabled
                : props.theme.sectionDashboard.widget.dialog.closeButton.backgroundColor};
        color: ${props =>
            props.buttonTheme === WidgetDialogCloseButtonTheme.Secondary
                ? props.theme.sectionDashboard.widget.dialog.closeButton.textColorSecondary
                : props.disabled
                ? props.theme.sectionDashboard.widget.dialog.closeButton.textColorDisabled
                : props.theme.sectionDashboard.widget.dialog.closeButton.textColor};
        padding: 10px 20px;
        border-radius: 3px;
        font-weight: ${props => props.theme.sectionDashboard.widget.dialog.closeButton.fontWeight};
    }
`;

const StyledDialogClose = styled(Dialog.Close)`
    all: inherit;
    border: 0;
`;

type WidgetDialogCloseButtonProps = {
    value: string;
    theme: WidgetDialogCloseButtonTheme;
    disabled?: boolean;
    onClick?: () => void;
};

const WidgetDialogCloseButton = ({ value, theme, disabled, onClick }: WidgetDialogCloseButtonProps) => {
    const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        if (disabled) {
            event.preventDefault();
        } else {
            onClick && onClick();
        }
    };
    return (
        <StyledButtonWrapper disabled={disabled} buttonTheme={theme}>
            <StyledDialogClose onClick={handleButtonClick}>{value}</StyledDialogClose>
        </StyledButtonWrapper>
    );
};

export default WidgetDialogCloseButton;
