import moment from 'moment';
import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType } from './common/chart-types';
import { DatePickerType } from './enums/date-picker-type';
import dateStringToUnix from '../helpers/date-string-to-unix';

export const config = {
    pageTitle: 'Monthly Channel Performance',
    dateDimension: fields.REPORT_DATE_DATETIME,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: true,
            isEnableMetrics: true,
        },
        datePicker: {
            datePickerType: DatePickerType.Year,
            customStartDate: () => {
                const startDate = moment().startOf('year');
                return startDate;
            },
            customEndDate: () => {
                const endDate = moment().endOf('year');
                return endDate;
            },
        },
    },
    resources: [resources.REPORT_MONTHLY_CHANNEL_PERFORMANCE_REFERER],
    showDropdownLegend: true,
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: [],
        chartId: 'monthly-channel-performance',
        allYMetrics: [
            fields.VISITS,
            fields.FC_VISITS,
            fields.LY_VISITS,
            fields.VISITS_VARIANCE_VS_FC,
            fields.VISITS_VARIANCE_VS_LY,
            fields.FM_SALES,
            fields.FC_SALES,
            fields.LY_SALES,
            fields.SALES_VARIANCE_FM_VS_FC,
            fields.SALES_VARIANCE_FM_VS_LY,
            fields.FM_REVENUE,
            fields.FC_REVENUE,
            fields.LY_REVENUE,
            fields.REVENUE_VARIANCE_FM_VS_FC,
            fields.REVENUE_VARIANCE_FM_VS_LY,
            fields.BUDGET,
        ],
        initialYMetrics: [fields.VISITS, fields.FC_VISITS],
        dataFormatter: function (_, datas, dimension, metric) {
            return datas.map(data => {
                return [dateStringToUnix(data[dimension.rawName].raw_value), data[metric].raw_value];
            });
        },
        setTickIntervalOverride: function () {
            return null;
        },
    },
    table: {
        tabListItems: [
            {
                tabName: 'visits',
                displayName: 'Visits',
                isSelected: true,
                metrics: [
                    fields.VISITS,
                    fields.FC_VISITS,
                    fields.LY_VISITS,
                    fields.VISITS_VARIANCE_VS_FC,
                    fields.VISITS_VARIANCE_VS_LY,
                ],
            },
            {
                tabName: 'sales',
                displayName: 'Sales',
                metrics: [
                    fields.FM_SALES,
                    fields.FC_SALES,
                    fields.LY_SALES,
                    fields.SALES_VARIANCE_FM_VS_FC,
                    fields.SALES_VARIANCE_FM_VS_LY,
                ],
            },
            {
                tabName: 'revenue',
                displayName: 'Revenue & Budget',
                metrics: [
                    fields.FM_REVENUE,
                    fields.FC_REVENUE,
                    fields.LY_REVENUE,
                    fields.REVENUE_VARIANCE_FM_VS_FC,
                    fields.REVENUE_VARIANCE_FM_VS_LY,
                    fields.BUDGET,
                ],
            },
            {
                tabName: 'all',
                displayName: 'All',
                metrics: [
                    fields.VISITS,
                    fields.FC_VISITS,
                    fields.LY_VISITS,
                    fields.VISITS_VARIANCE_VS_FC,
                    fields.VISITS_VARIANCE_VS_LY,
                    fields.FM_SALES,
                    fields.FC_SALES,
                    fields.LY_SALES,
                    fields.SALES_VARIANCE_FM_VS_FC,
                    fields.SALES_VARIANCE_FM_VS_LY,
                    fields.FM_REVENUE,
                    fields.FC_REVENUE,
                    fields.LY_REVENUE,
                    fields.REVENUE_VARIANCE_FM_VS_FC,
                    fields.REVENUE_VARIANCE_FM_VS_LY,
                    fields.BUDGET,
                ],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.REFERER.id]: {
                default: [
                    {
                        resources: resources.REPORT_MONTHLY_CHANNEL_PERFORMANCE_DATE,
                        field: fields.REPORT_DATE_DATETIME,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
