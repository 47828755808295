import React from 'react';

// Context
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Components
import WidgetBase from '../base/widget-base';
import WidgetTableCardBoxComparison from './widget-table-card-box-comparison';
import SectionDashboardTableComparison from '../../components/tables/section-dashboard-table-comparison';

// Types
import { ComparisonTableData, TotalComparisonData } from '../../types';

export type WidgetTableComparisonProps = {
    title: string;
    data: ComparisonTableData;
    totalData?: TotalComparisonData;
    href?: string;
    menu?: React.ReactElement;
};

const WidgetTableComparison = ({ title, data, totalData, href, menu }: WidgetTableComparisonProps) => {
    const { width, height } = useSectionDashboard();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} menu={menu} />;
    }

    const { rows, columns, request } = data;

    const ascendingSorts: string[] = [];
    const descendingSorts: string[] = [];
    const withFilters: string[] = [];

    if (request && request.orderBy) {
        request.orderBy.forEach(sort => {
            if (sort.orderByDirection === 'asc') {
                ascendingSorts.push(sort.field.rawName);
            } else if (sort.orderByDirection === 'desc') {
                descendingSorts.push(sort.field.rawName);
            }
        });
    }

    if (request && request.filters) {
        request.filters.forEach(filter => {
            withFilters.push(filter.field.rawName);
        });
    }

    if (width === 1 || height === 1) {
        return (
            <WidgetBase title={title} href={href} menu={menu}>
                <WidgetTableCardBoxComparison rows={rows} columns={columns} width={width} />
            </WidgetBase>
        );
    } else {
        return (
            <WidgetBase title={title} href={href} menu={menu}>
                <SectionDashboardTableComparison
                    request={data.request}
                    columns={columns}
                    rows={rows}
                    totalData={totalData}
                    width={width}
                    height={height}
                    ascendingSorts={ascendingSorts}
                    descendingSorts={descendingSorts}
                    withFilters={withFilters}
                />
            </WidgetBase>
        );
    }
};

export default WidgetTableComparison;
