import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
    background-color: ${props => props.theme.colours.offBlackLighter};
    color: ${props => props.theme.colours.light};
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    min-width: 40px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 36px;
    font-weight: 500;
`;

const StyledInput = styled.input`
    display: none;
`;

// Types
export type InputFileUploadProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputFileUpload = ({ onChange }: InputFileUploadProps) => {
    return (
        <>
            <StyledLabel htmlFor="file-upload">Choose File</StyledLabel>
            <StyledInput id="file-upload" type="file" onChange={onChange} accept=".csv,.xlsx,.xls" />
        </>
    );
};

export default InputFileUpload;
