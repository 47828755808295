import React from 'react';
import styled, { useTheme } from 'styled-components';

// Configuration
import { histogramChartConfig } from '../../configurations/widget-histogram-chart-config';

// Highcharts
import Highcharts from 'highcharts';
import bellcurve from 'highcharts/modules/histogram-bellcurve';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Context
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Widgets
import WidgetBase from '../base/widget-base';
import WidgetHistogramBigNumberComparison from './widget-histogram-big-number-comparison';

// Components
import Message, { IconDirection, IconSize } from '../../../components/message';

// Types
import { HistogramChartComparisonData } from '../../types';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;

const StyledBigNumberContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const StyledMessageContainer = styled.div<{ isFullView: boolean }>`
    width: 100%;
    align-self: center;
    height: ${props => (props.isFullView ? `100%` : `50%`)};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export type WidgetHistogramComparisonProps = {
    title: string;
    data: HistogramChartComparisonData;
    href?: string;
};

bellcurve(Highcharts);

const WidgetHistogramComparison = ({ title, data, href }: WidgetHistogramComparisonProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    const histogramConfig = histogramChartConfig(theme);

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    if (width === 1 || height === 1) {
        const list = data.series;
        const comparisonList = data.comparisonSeries;

        const direction = width > height ? 'horizontal' : 'vertical';

        return (
            <WidgetBase title={title} href={href}>
                <StyledBigNumberContainer>
                    <WidgetHistogramBigNumberComparison
                        metric={data.metric}
                        data={list}
                        comparisonData={comparisonList}
                        width={width}
                        height={height}
                        direction={direction}
                    />
                </StyledBigNumberContainer>
            </WidgetBase>
        );
    }

    let size: IconSize = 'small';
    let display: IconDirection = 'horizontal';

    if (height < 2 || width < 3) {
        size = 'xsmall';
        display = 'vertical';
    } else if (height < 4 || width < 4) {
        size = 'small';
    }

    if (width > 1) {
        display = 'horizontal';
    }

    const config = {
        ...histogramConfig,
        xAxis: histogramConfig.xAxis.map(axis => {
            return { ...axis, title: { text: axis.opposite ? 'This Period' : null } };
        }),
        yAxis: histogramConfig.yAxis.map(axis => {
            return { ...axis, title: { text: !axis.opposite ? data.metric : null } };
        }),
        series: histogramConfig.series.map(series => {
            if (series.type === 'histogram') return { ...series, color: data.histogramColour };
            else if (series.type === 'scatter')
                return {
                    ...series,
                    data: data.series,
                    name: data.metric,
                    marker: {
                        fillColor: data.scatterColour,
                        lineColor: data.scatterColour,
                        lineWidth: 1,
                    },
                };
            return series;
        }),
    };

    const comparisonConfig = {
        ...histogramConfig,
        xAxis: histogramConfig.xAxis.map(axis => {
            return { ...axis, title: { text: axis.opposite ? 'Comparison Period' : null } };
        }),
        yAxis: histogramConfig.yAxis.map(axis => {
            return { ...axis, title: { text: !axis.opposite ? data.metric : null } };
        }),
        series: histogramConfig.series.map(series => {
            if (series.type === 'histogram') return { ...series, color: data.histogramColour };
            else if (series.type === 'scatter')
                return {
                    ...series,
                    data: data.comparisonSeries,
                    name: data.metric,
                    marker: {
                        fillColor: data.scatterColour,
                        lineColor: data.scatterColour,
                        lineWidth: 1,
                    },
                };
            return series;
        }),
    };

    const noDataWidget = (isFullView: boolean) => (
        <StyledMessageContainer isFullView={isFullView}>
            <Message
                shortTitle={'No Data'}
                title={'No Data Available'}
                copy={'Try adjusting your filters.'}
                type={'info'}
                size={size}
                display={display}
            />
        </StyledMessageContainer>
    );

    return (
        <WidgetBase title={title} href={href}>
            <StyledContainer>
                {data.series.length > 0 || (data.comparisonSeries && data.comparisonSeries.length > 0) ? (
                    <>
                        {data.series.length > 0 ? (
                            <CommonHighcharts
                                highcharts={Highcharts}
                                options={{ ...config }}
                                containerProps={{ style: { height: '50%', width: '100%' } }}
                            />
                        ) : (
                            <>{noDataWidget(false)}</>
                        )}

                        {data.comparisonSeries && data.comparisonSeries.length > 0 ? (
                            <CommonHighcharts
                                highcharts={Highcharts}
                                options={{ ...comparisonConfig }}
                                containerProps={{ style: { height: '50%', width: '100%' } }}
                            />
                        ) : (
                            <>{noDataWidget(false)}</>
                        )}
                    </>
                ) : (
                    <>{noDataWidget(true)}</>
                )}
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetHistogramComparison;
