/* eslint-disable react-hooks/exhaustive-deps */
import React, { SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import DataTableBody from './body/data-table-body';
import DataTableHeaderRow from './rows/data-table-header-row';
import DataTableTotalsRow from './rows/data-table-totals-row';
import Message from '../../message';

// Helpers
import { mathClamp } from '../../../helpers/utils';
import { getTotals, getProportions } from '../helpers/table-helper';

// Types
import { CubedField } from '../../../types';
import {
    DataTableColumn,
    ReportConfig,
    orderByDir,
    DataTableSelectedRows,
    DataTableData,
    DataTableTotals,
} from '../types';

// Styles
const StyledTableNoDataContainer = styled.div`
    width: 100%;
    position: relative;
`;

const StyledTableSkeletonContainer = styled.div`
    width: 100%;
    margin: 6px 0;
    height: 198px;
    border-radius: 12px;
    ${props => props.theme.skeletonLoaderGradient};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledTableContainer = styled.div`
    max-width: 100%;
    background-color: ${props => props.theme.colours.whiteBg};
    overflow: visible;
    overflow-x: auto;
    border: ${props => `1px solid ${props.theme.colours.borderGrey}`};
    ${props => props.theme.scrollbar(8, 8)}
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
`;

// Types
export type DataTableProps = {
    config: ReportConfig;
    data: DataTableData;
    dimensions: DataTableColumn[];
    disableRowClick: (columns: CubedField[]) => {};
    error: string;
    handleDimensionClick: () => void;
    hideTotals?: boolean;
    loading: boolean;
    metrics: DataTableColumn[];
    noData: boolean;
    orderBy: CubedField;
    orderByDir: orderByDir;
    selectedKey: number;
    selectedRows: DataTableSelectedRows[] | [];
    setOwnerState: (key: string, value: DataTableSelectedRows | orderByDir | DataTableColumn) => void;
    isLive?: boolean;
};

const DataTable = ({
    config,
    data,
    dimensions,
    disableRowClick,
    error,
    handleDimensionClick,
    hideTotals,
    loading,
    metrics = [],
    noData,
    orderBy,
    orderByDir,
    selectedKey,
    selectedRows = [],
    setOwnerState,
    isLive,
}: DataTableProps) => {
    const loadingDelay = 10000; // 10s
    const loadingStateMessages = ['', 'Please wait.', 'We are still fetching your data.'];
    let loadingStateIndex = 0;
    let intervalId: NodeJS.Timer | null;

    const [scrollPosX, setScrollPosX] = useState(0);
    const [loadingMsg, setLoadingMsg] = useState('');
    const [tableData, setTableData] = useState(data);
    const [tableTotals, setTableTotals] = useState<DataTableTotals[]>([]);
    const [errorCopy, setErrorCopy] = useState('');

    const columns = [...dimensions, ...metrics];

    useEffect(() => {
        // if we have just switched to "loading", set a timer to display messages after delay
        if (loading) {
            prepareLoadingState();
        } else {
            resetLoadingState();
        }
    }, [loading]);

    useEffect(() => {
        // if we suddenly have an error
        if (error) {
            setErrorCopy(
                'The server has stopped responding. Please try again. If this error persists please contact support@cubed.email with the current URL and selected date range.'
            );
        }
    }, [error]);

    useEffect(() => {
        const tableTotalValues = getTotals(data, dimensions, metrics);
        const newData = getProportions(data, tableTotalValues, dimensions, metrics);
        setTableData(newData);
        setTableTotals(tableTotalValues);
    }, [data, metrics, selectedKey]);

    const prepareLoadingState = () => {
        if (intervalId) return;
        intervalId = setInterval(setLoadingState, loadingDelay);
    };

    const setLoadingState = () => {
        loadingStateIndex++;
        loadingStateIndex = mathClamp(loadingStateIndex, 1, loadingStateMessages.length - 1);
        var newLoadingState = loadingStateMessages[loadingStateIndex];
        setLoadingMsg(newLoadingState);
    };

    const resetLoadingState = () => {
        if (intervalId) {
            clearInterval(intervalId as unknown as string | number | undefined);
            intervalId = null;
        }
        loadingStateIndex = 0;
        setLoadingMsg('');
    };

    const handleOnScroll = (event: SyntheticEvent<HTMLDivElement>) => {
        setScrollPosX(event.currentTarget.scrollLeft);
    };

    if (error) {
        return (
            <StyledTableNoDataContainer>
                <Message copy={errorCopy} title="Issue:" type="warning" />
            </StyledTableNoDataContainer>
        );
    }

    if (loading) {
        return (
            <StyledTableSkeletonContainer>
                <Message copy={loadingMsg} title="Loading..." type="info" />
            </StyledTableSkeletonContainer>
        );
    }

    if (noData || !tableData.objects) {
        if (isLive) {
            return <Message copy="Waiting for data..." title="Live Reporting" type="info" />;
        }

        return (
            <StyledTableNoDataContainer>
                <Message copy="Try adjusting your filters." title="No Data Available." type="info" />{' '}
            </StyledTableNoDataContainer>
        );
    }

    return (
        <StyledTableContainer onScroll={handleOnScroll}>
            <StyledTable>
                <thead>
                    <DataTableHeaderRow
                        columns={columns}
                        orderBy={orderBy}
                        orderByDir={orderByDir}
                        setOwnerState={setOwnerState}
                        scrollPosX={scrollPosX}
                    />
                    {tableTotals.length > 0 && (
                        <DataTableTotalsRow totals={tableTotals} hideTotals={hideTotals} scrollPosX={scrollPosX} />
                    )}
                </thead>

                <DataTableBody
                    handleDimensionClick={handleDimensionClick}
                    dimensions={dimensions}
                    loading={loading}
                    selectedRows={selectedRows}
                    columns={columns}
                    rows={tableData.objects}
                    setOwnerState={setOwnerState}
                    disableRowClick={disableRowClick}
                    config={config}
                    dataMeta={tableData}
                    scrollPosX={scrollPosX}
                />
            </StyledTable>
        </StyledTableContainer>
    );
};

export default DataTable;
