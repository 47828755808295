import React, { useState } from 'react';
import styled from 'styled-components';

// Redux
import { setModal } from '../../../redux/actions/modal';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../redux/actions/notification';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { FieldError, FieldValues } from 'react-hook-form';
import { NotificationMessageType } from '../../../enums/notification-types';

// Hooks
import usePostMultiResourcesWithPayload from '../../../react-query/hooks/use-post-multi-resource-with-payload';

// Config
import { CONFIG_KWR_BLACKLISTED_KEYWORD } from '../../../configurations/resources-config';

// Helpers
import { generatePath } from '../../../helpers/request-builder';

// Components
import FormCreateBlacklistedKeywords from './forms/form-create-blacklisted-keywords';
import Form from '../../../components/forms/form';
import InputErrorMessage from '../../../components/forms/inputs/components/input-error-message';
import ModalNavigation from '../../../components/modal-navigation';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const LayoutModalAddBlacklistedKeywords = () => {
    const dispatch = useDispatch();

    const [showErrorEmpty, setShowErrorEmpty] = useState(false);

    const blacklistKeywordPostMutation = usePostMultiResourcesWithPayload({
        resource: CONFIG_KWR_BLACKLISTED_KEYWORD,
        handleOnSuccess: () => {
            dispatch(setModal('ManageBlacklistedKeywords', {}));
            dispatch(
                addNotification({
                    copy: 'Blacklisted keywords added successfully',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(setModal('ManageBlacklistedKeywords', {}));
            dispatch(
                addNotification({
                    copy: 'Failed to add one or more of the blacklisted keywords. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const handleSubmit = (data: FieldValues) => {
        if (data?.keywords?.length > 0) {
            setShowErrorEmpty(false);
            const keywordPayload = data.keywords.map((keyword: string) => {
                return {
                    keyword,
                    market: generatePath('config', 'seogd-market', String(data.market)),
                    type: generatePath('config', 'keyword-type', String(data.type)),
                };
            });

            blacklistKeywordPostMutation.mutate(keywordPayload);
        } else {
            setShowErrorEmpty(true);
        }
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(setModal('ManageBlacklistedKeywords', {})),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Add Blacklisted Keywords</h2>

            <p>
                You can add keywords one at a time or paste a list of comma separated keywords and press enter to add.
            </p>

            <StyledFormContainer>
                <Form onSubmit={handleSubmit} status={blacklistKeywordPostMutation.isPending ? 'disabled' : 'enabled'}>
                    <FormCreateBlacklistedKeywords />
                    {showErrorEmpty && (
                        <InputErrorMessage error={{ message: 'Please add at least one keyword' } as FieldError} />
                    )}
                    <Form.Footer>
                        <Form.InputButton
                            type="submit"
                            value="Add Keywords"
                            loading={blacklistKeywordPostMutation.isPending}
                        />
                        <Form.InputButton type="reset" value="Clear" buttonTheme={ButtonThemes.Secondary} />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </>
    );
};

export default LayoutModalAddBlacklistedKeywords;
