import React from 'react';
import styled from 'styled-components';

import './../sass/components/sparkline.scss';

const StyledSkeletonWrapper = styled.div`
    flex: 1 1 0;
    margin: 6px;
    position: relative;
    width: 100%;
`;

const StyledSkeletonTitle = styled.div`
    width: 100%;
    padding: 6px;
    margin: 6px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const StyledSkeletonChart = styled.div`
    width: 100%;
    padding: 50px;
    margin: 6px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const StyledSkeletonFooter = styled.div`
    width: 100%;
    padding: 24px;
    margin: 6px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const SparkSkeleton = () => {
    return (
        <StyledSkeletonWrapper>
            <StyledSkeletonTitle />
            <StyledSkeletonChart />
            <StyledSkeletonFooter />
        </StyledSkeletonWrapper>
    );
};

export default SparkSkeleton;
