// React Dependencies
import React from 'react';
import styled from 'styled-components';
import IconSearch from '../../assets/images/icons/search.png';

const StyledInput = styled.input<{ disabled: boolean; variant: string | null }>`
    box-sizing: border-box;
    font-family: inherit;
    font-size: ${props => props.variant === 'transparent' && '16px'};
    font-weight: ${props => props.variant === 'transparent' && '400'};
    background-image: url(${IconSearch});
    background-position: 12px center;
    background-size: 18px;
    background-repeat: no-repeat;
    padding: 0px 5px 0px 42px;
    width: 100%;
    height: 40px;
    background-color: ${props => (props.variant === 'transparent' ? 'initial' : props.theme.colours.inputColour)};
    border: ${props =>
        props.variant === 'transparent'
            ? props.disabled
                ? `1px solid ${props.theme.colours.midGrey}`
                : `1px solid ${props.theme.colours.textDefault}`
            : 'none'};
    border-bottom: ${props =>
        props.variant !== 'transparent' &&
        (props.disabled
            ? `2px solid ${props.theme.colours.midGrey}`
            : `2px solid ${props.theme.colours.offBlackLighter}`)};
    border-radius: ${props => (props.variant === 'transparent' ? 'initial' : '2px')};
    margin-bottom: ${props => props.variant === 'transparent' && '20px'};

    &:focus {
        outline: none;
    }

    &::placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }
`;

export type InputSearchProps = {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    variant?: 'transparent' | null;
};

const InputSearch = ({ value, onChange, placeholder, disabled, variant }: InputSearchProps) => {
    return (
        <>
            <StyledInput
                type="text"
                placeholder={placeholder || 'Search...'}
                onChange={onChange}
                value={value}
                disabled={disabled || false}
                variant={variant || null}
            />
        </>
    );
};

export default InputSearch;
