export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_ACCOUNT':
            return Object.assign({}, state, {
                id: action.payload.id,
                name: action.payload.name,
                token: action.payload.token,
                group: action.payload.group,
                hasEccoms: action.payload.hasEccoms,
                hasSubscription: action.payload.hasSubscription,
                isSelected: true,
            });
        case 'DEL_ACCOUNT':
            return Object.assign({}, state, {
                id: '',
                name: '',
                token: '',
                group: '',
                hasEccoms: '',
                hasSubscription: '',
                isSelected: false,
            });
        default:
            return state;
    }
}

const initialState = {
    id: '',
    name: '',
    token: '',
    group: '',
    hasEccoms: '',
    hasSubscription: '',
    isSelected: false,
};
