// React Dependencies
import React from 'react';
import { connect } from 'react-redux';

// Redux Actions
import { removeModal } from '../redux/actions/modal';
import { cancelRequest } from '../redux/slices/request';

const ModalBackdrop = props => {
    const handleBackdropClick = () => {
        props.removeModal();
        props.cancelRequest();
    };

    return (
        <div className="modal-backdrop" onClick={handleBackdropClick}>
            {props.children}
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
    };
};

export default connect(null, mapDispatchToProps)(ModalBackdrop);
