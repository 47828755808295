import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled, { css } from 'styled-components';

import imageIconCancel from '../../assets/images/cancel.svg';

import { FilterSelectFilter } from '../../filter-bar/types';

// Styles
const StyledSelectedFilterCapsule = css`
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    line-height: 32px;
    margin: 4px 0 0 4px;
    border-radius: 60px;
    background-color: white;
    color: #8b8b8b;
    box-shadow: 0 0 3px rgb(0 0 0 / 30%);
    padding: 0 32px 0 12px;
    max-width: 256px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const StyledEmptySelectedFilter = styled.div`
    ${StyledSelectedFilterCapsule}
    top: 2px;
    margin: 0 2px;
    border: 2px solid #f44336;
`;

const StyledAllSelectedFilter = styled.div`
    ${StyledSelectedFilterCapsule}
`;

const StyledManySelectedFilter = styled.div`
    ${StyledSelectedFilterCapsule}
    width: 190px;
    max-width: 20vw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const StyledSingleSelectedFilter = styled.div`
    ${StyledSelectedFilterCapsule}
`;

const StyledSingleSelectedFilterSpan = styled.span`
    white-space: nowrap;
`;

const StyledSingleSelectedFilterAction = styled.div`
    right: 0;
    position: absolute;
    margin: 5px;
    height: 20px;
    width: 20px;
    background-image: url(${imageIconCancel});
    background-size: contain;
    background-repeat: no-repeat;
    top: 1px;
    opacity: 0.5;
    transition: 0.2s ease-in-out;
    cursor: pointer;
`;

// Types
type FilterSelectCapsulesProps = {
    selectedFilterData: FilterSelectFilter[];
    filterOptions: FilterSelectFilter[];
    handleSelectedFilterRemove: (filters: FilterSelectFilter) => void;
    hideCapsules: boolean;
    filterName: string;
};

const FilterSelectCapsules = ({
    selectedFilterData,
    filterOptions,
    handleSelectedFilterRemove,
    hideCapsules,
    filterName,
}: FilterSelectCapsulesProps) => {
    const capsuleElement = () => {
        if (filterOptions.length === 0) {
            return <StyledEmptySelectedFilter>No {filterName} Available</StyledEmptySelectedFilter>;
        } else {
            if (selectedFilterData.length < 1) {
                return (
                    <StyledEmptySelectedFilter data-testid="empty-selected-filter">
                        No {filterName} Selected
                    </StyledEmptySelectedFilter>
                );
            } else if (selectedFilterData.length === filterOptions.length) {
                return <StyledAllSelectedFilter>All {filterName}</StyledAllSelectedFilter>;
            } else if (selectedFilterData.length > 3 || hideCapsules) {
                return (
                    <StyledManySelectedFilter>
                        <span>{`${selectedFilterData.length} of ${filterOptions.length} selected.`}</span>
                    </StyledManySelectedFilter>
                );
            } else {
                return selectedFilterData.map(filter => {
                    return (
                        <StyledSingleSelectedFilter key={filter.id}>
                            <StyledSingleSelectedFilterSpan title={filter.name}>
                                {filter.name}
                            </StyledSingleSelectedFilterSpan>
                            <StyledSingleSelectedFilterAction onClick={() => handleSelectedFilterRemove(filter)} />
                        </StyledSingleSelectedFilter>
                    );
                });
            }
        }
    };

    return <>{capsuleElement()}</>;
};

export default memo(FilterSelectCapsules, isEqual);
