/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import theme from '../../../styled-components/theme.styled';

// Fields & Resources
import * as fields from '../../../configurations/fields';
import * as resources from '../../../configurations/resources';

// Helpers
import getCurrentTier from '../../helpers/get-current-tier';

// Types
import { DatePickerType } from '../../../configurations/enums/date-picker-type';
import { WidgetColour, WidgetMenuObj } from '../../../section-dashboard/types';

// Context
import { ChannelDetailDashboardMenuContext } from '../../channel-detail-context/channel-detail-dashboard-menu-context';
import { FilterBarContext } from '../../../filter-bar/context/filter-bar-context';

// Hooks
import useResourceTotalComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-total-comparison';
import useResourceSparkLineComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-spark-line-comparison';
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourceBigNumberComparison from '../../../section-dashboard/hooks/widget-comparison/big-number/use-resource-big-number-comparison';
import useResourcePieChartTableComparison from '../../../section-dashboard/hooks/widget-comparison/pie-chart-table/use-resource-pie-chart-table-comparison';
import useRequestTable from '../../../section-dashboard/hooks/request/use-request-table';
import useResourceTable from '../../../section-dashboard/hooks/widget/table/use-resource-table';
import useResourceTotal from '../../../section-dashboard/hooks/widget/use-resource-total';
import useResourceTableComparison from '../../../section-dashboard/hooks/widget-comparison/table/use-resource-table-comparison';
import useResourceTableComparisonVarianceOnly from '../../../section-dashboard/hooks/widget-comparison/table/use-resource-table-comparison-variance-only';
import useResourceTableComparisonVarianceOnlyTotals from '../../../section-dashboard/hooks/widget-comparison/table/use-resource-table-comparison-variance-only-totals';
import useResourceComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-comparison';
import useResourceStackedAreaChartComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-stacked-area-chart-comparison';
import useResourcePieChartTableLimitComparison from '../../../section-dashboard/hooks/widget-comparison/pie-chart-table/use-resource-pie-chart-table-limit-comparison';
import useRequestStackedAreaChart from '../../../section-dashboard/hooks/request/use-request-stacked-area-chart';
import useResourcePieTableComparisonMatchSectionColours from '../../../section-dashboard/hooks/widget-comparison/pie-chart-table/use-resource-pie-table-comparison-match-section-colours';
import useRequestPieComparisonMultiple from '../../../section-dashboard/hooks/request/use-request-pie-comparison-multiple';

// Components
import BeforeGridConfiguredGoals from '../../../section-dashboard/components/sections/before-grid/before-grid-configured-goals';

import WidgetSparkLineComparison from '../../../section-dashboard/widgets/widget-comparison/widget-spark-line-comparison';
import WidgetBigNumberComparison from '../../../section-dashboard/widgets/widget-comparison/widget-big-number-comparison';
import WidgetTable from '../../../section-dashboard/widgets/widget/widget-table';
import WidgetMenuTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';
import WidgetTableComparison from '../../../section-dashboard/widgets/widget-comparison/widget-table-comparison';
import WidgetStackedAreaChartComparison from '../../../section-dashboard/widgets/widget-comparison/widget-stacked-area-chart-comparison';
import WidgetMenuStackedAreaChart from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-stacked-area-chart';
import useWidgetMenuFooterQuerySelect from '../../../section-dashboard/widgets/menus/hooks/use-widget-menu-footer-query-select';
import WidgetPieChartTableComparisonMultiple from '../../../section-dashboard/widgets/widget-comparison/widget-pie-chart-table-comparison-multiple';
import WidgetMenuPieComparisonMultiple from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie-comparison-multiple';

// Queries
import { goalsDropdownQuery } from '../../../section-dashboard/queries';

export const config = {
    title: 'Channel Detail Dashboard',
    description: '', // TODO description
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableComparisonDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnableButtonGroup: false,
            isEnablePdfExport: true,
            isEnableChannelMultiSelect: false,
            isEnableChannelSingleSelect: {
                enabled: true,
                channelFilterResource: {
                    resource: resources.REPORT_MCS_CHANNEL,
                    dimension: fields.CHANNEL_NAME,
                    preselectMetric: fields.FM_SALES,
                },
            },
        },
        comparisonStatus: {
            showComparisonDatePicker: true,
            redirectUrl: 'channel-detail-dashboard',
        },
        datePicker: {},
        comparisonDatePicker: {
            datePickerType: DatePickerType.Comparison,
        },
    },
    context: () => {
        // Context
        const menuContext = useContext(ChannelDetailDashboardMenuContext);
        return { menuContext };
    },
    sectionTitles: (tier: string) => {
        const { selectedChannel } = useContext(FilterBarContext);
        const currentTier = getCurrentTier(tier, selectedChannel);

        if (typeof currentTier !== 'string' && 'displayName' in currentTier) {
            return currentTier.displayName;
        }

        return currentTier;
    },
    layouts: {
        xs: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a'
                    'b'
                    'c'
                    'd'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'q'
                    'r'
                    's'
                    't'
                    'u'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h'
                    'i'
                    'j'
                    'k'
                    'l'
                    'm'
                    'n'
                    'o'
                    'p'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    'v'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'ee'
                    'ff'
                    'gg'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'aa'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'dd'
                    'f'
                    'g'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'bb'
                    `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a a'
                    'b b'
                    'c c'
                    'd d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'q q'
                    'r r'
                    's s'
                    't t'
                    'u u'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h i'
                    'j k'
                    'l m'
                    'n n'
                    'o o'
                    'p p'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    'v v'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'ee ee'
                    'ff ff'
                    'gg gg'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'aa aa'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'dd dd'
                    'f f'
                    'g g'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'bb bb'
                    `,
                },
            ],
        },
        md: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a a b b'
                    'c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'q q q r'
                    'q q q s'
                    'q q q t'
                    'u u u u'
                    'u u u u'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h h i i'
                    'j j k k'
                    'l l m m'
                    'n n n n'
                    'n n n n'
                    'o o o o'
                    'o o o o'
                    'p p p p'
                    'p p p p'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    'v v v v'
                    'v v v v'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'ee ee ee ee'
                    'ff ff ff ff'
                    'gg gg gg gg'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'aa aa aa aa'
                    'aa aa aa aa'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'dd dd dd dd'
                    'dd dd dd dd'
                    'f f f f'
                    'f f f f'
                    'g g g g'
                    'g g g g'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'bb bb bb bb'
                    'bb bb bb bb'
                    `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a a a b b b'
                    'a a a b b b'
                    'c c c d d d'
                    'c c c d d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'q q q q q r'
                    'q q q q q s'
                    'q q q q q t'
                    'u u u u u u'
                    'u u u u u u'
                    'u u u u u u'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h i j k l m'
                    'n n n n n n'
                    'n n n n n n'
                    'o o o o o o'
                    'o o o o o o'
                    'p p p p p p'
                    'p p p p p p'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    'v v v v v v'
                    'v v v v v v'
                    'v v v v v v'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'ee ee ee ee ee ee'
                    'ee ee ee ee ee ee'
                    'ff ff ff ff ff ff'
                    'ff ff ff ff ff ff'
                    'gg gg gg gg gg gg'
                    'gg gg gg gg gg gg'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'aa aa aa aa aa aa'
                    'aa aa aa aa aa aa'
                    'aa aa aa aa aa aa'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'dd dd dd dd dd dd'
                    'dd dd dd dd dd dd'
                    'f f f f f f'
                    'f f f f f f'
                    'g g g g g g'
                    'g g g g g g'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'bb bb bb bb bb bb'
                    'bb bb bb bb bb bb'
                    'bb bb bb bb bb bb'
                    `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a a b b c c d d'
                    'a a b b c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'q q q q q q r r'
                    'q q q q q q s s'
                    'q q q q q q t t'
                    'u u u u u u u u'
                    'u u u u u u u u'
                    'u u u u u u u u'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h x z i j k l m'
                    'n n n n n n n n'
                    'n n n n n n n n'
                    'o o o o o o o o'
                    'o o o o o o o o'
                    'p p p p p p p p'
                    'p p p p p p p p'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    'v v v v v v v v'
                    'v v v v v v v v'
                    'v v v v v v v v'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'ee ee ee ee ee ee ee ee'
                    'ee ee ee ee ee ee ee ee'
                    'ff ff ff ff ff ff ff ff'
                    'ff ff ff ff ff ff ff ff'
                    'gg gg gg gg gg gg gg gg'
                    'gg gg gg gg gg gg gg gg'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'aa aa aa aa aa aa aa aa'
                    'aa aa aa aa aa aa aa aa'
                    'aa aa aa aa aa aa aa aa'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'dd dd dd dd dd dd dd dd'
                    'dd dd dd dd dd dd dd dd'
                    'f f f f f f f f'
                    'f f f f f f f f'
                    'g g g g g g g g'
                    'g g g g g g g g'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'bb bb bb bb bb bb bb bb'
                    'bb bb bb bb bb bb bb bb'
                    'bb bb bb bb bb bb bb bb'
                    `,
                },
            ],
        },
    },
    components: () => {
        const menuContext = useContext(ChannelDetailDashboardMenuContext);
        const {
            currentResource,
            selectedChannelFilter,
            tier2Field,
            tier3Field,
            tier4Field,
            tier2ProfileSectionMenu,
            tier2GoalCompletionSectionMenu,
            tier3ProfileSectionMenu,
            tier3GoalCompletionSectionMenu,
            tier4ProfileSectionMenu,
            tier4GoalCompletionSectionMenu,
        } = menuContext;

        // Sparklines
        const sparkData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
            request: {
                isGraph: true,
                groupBy: fields.SALES_DATE_DATE,
                graphGroupBy: [fields.SALES_DATE_DATE],
                graphMetrics: [fields.VISITS, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.COST],
                pageFilters: selectedChannelFilter,
                enabled: !!selectedChannelFilter,
                selectedFields: [
                    fields.SALES_DATE_DATE,
                    fields.VISITS,
                    fields.GOAL_COMPLETIONS,
                    fields.GOAL_VALUE,
                    fields.COST,
                ],
            },
        });

        const sparkTotalData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                totals: true,
                pageFilters: selectedChannelFilter,
                enabled: !!selectedChannelFilter,
                selectedFields: [
                    fields.SALES_DATE_DATE,
                    fields.VISITS,
                    fields.GOAL_COMPLETIONS,
                    fields.GOAL_VALUE,
                    fields.COST,
                ],
            },
        });

        const sparkTotals = useResourceTotalComparison({
            resourceData: sparkTotalData,
        });

        // Visits Sparkline
        const visitsSpark = useResourceSparkLineComparison({
            resourceData: sparkData,
            metric: fields.VISITS,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Sales Sparkline
        const salesSpark = useResourceSparkLineComparison({
            resourceData: sparkData,
            metric: fields.FM_SALES,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Revenue Sparkline
        const revenueSpark = useResourceSparkLineComparison({
            resourceData: sparkData,
            metric: fields.FM_REVENUE,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Cost Sparkline
        const costSpark = useResourceSparkLineComparison({
            resourceData: sparkData,
            metric: fields.COST,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Goal Snapshot
        // Table
        const goalSnapshotTableRequest = useRequestTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            selectedFields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
        });

        const goalSnapshotTableData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
            request: {
                ...goalSnapshotTableRequest,
            },
        });

        const goalSnapshotTableTotalData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
            request: {
                ...goalSnapshotTableRequest,
                totals: true,
            },
        });

        const goalSnapshotTable = useResourceTableComparison({
            resourceData: goalSnapshotTableData,
            request: goalSnapshotTableRequest,
            dimension: fields.PRODUCT,
        });

        const goalSnapshotTableTotals = useResourceTotalComparison({
            resourceData: goalSnapshotTableTotalData,
        });

        // BIG NUMBERS
        const goalSnapshotBigNumberGoalCompletionsData = (menu: WidgetMenuObj) =>
            useResourceComparison({
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
                request: {
                    isGraph: true,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
                    pageFilters: selectedChannelFilter,
                    ...menu.request,
                    enabled: !!selectedChannelFilter,
                    selectedFields: [
                        fields.PRODUCT,
                        fields.GOAL_COMPLETIONS,
                        fields.GOAL_VALUE,
                        fields.GOAL_CVR_GOAL_SNAPSHOT,
                    ],
                },
            });

        // Goal Completions
        const goalSnapshotBigNumberGoalCompletionsMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalSnapshotBigNumberGoalCompletions = useResourceBigNumberComparison({
            resourceData: goalSnapshotBigNumberGoalCompletionsData(goalSnapshotBigNumberGoalCompletionsMenu),
            field: fields.GOAL_COMPLETIONS,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Goal Value
        const goalSnapshotBigNumberGoalValueMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalSnapshotBigNumberGoalValue = useResourceBigNumberComparison({
            resourceData: goalSnapshotBigNumberGoalCompletionsData(goalSnapshotBigNumberGoalValueMenu),
            field: fields.GOAL_VALUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // Conversion Rate
        const goalSnapshotBigNumberGoalCVRMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalSnapshotBigNumberGoalCVR = useResourceBigNumberComparison({
            resourceData: goalSnapshotBigNumberGoalCompletionsData(goalSnapshotBigNumberGoalCVRMenu),
            field: fields.GOAL_CVR_GOAL_SNAPSHOT,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Goal Completions Over Time
        const goalCompletionRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Count of Completions', field: fields.FM_SALES, active: true },
                { name: 'Value of Completions', field: fields.FM_REVENUE, active: false },
            ],
            groupBy: fields.PRODUCT,
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.FM_SALES, orderByDirection: 'desc' },
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            selectedFields: [fields.PRODUCT, fields.FM_SALES, fields.FM_REVENUE],
        });

        const goalCompletionData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION_CHANNEL,
            request: goalCompletionRequest,
        });

        const goalCompletionsAdditionalSeries = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION_CHANNEL,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                pageFilters: selectedChannelFilter,
                enabled: !!selectedChannelFilter,
                selectedFields: [fields.SALES_DATE_DATE, fields.VISITS, fields.VISITORS],
            },
        });

        const goalCompletionStackedAreaChart = useResourceStackedAreaChartComparison({
            areaSeriesMetric: fields.PRODUCT,
            resourceData: goalCompletionData,
            dateDimension: fields.SALES_DATE_DATE,
            optionalSeries: [
                {
                    title: 'Unique Visits',
                    yAxis: fields.VISITS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.purple,
                },
                {
                    title: 'Unique Visitors',
                    yAxis: fields.VISITORS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.emerald,
                },
            ],
        });

        // Tier 2 Profile
        // 2nd Tier Profile Big Numbers
        const tier2ProfileBigNumbersData = useResourceComparison({
            resource: currentResource,
            request: {
                isGraph: true,
                graphGroupBy: currentResource && [currentResource?.dateDimension],
                graphMetrics: [
                    fields.VISITS,
                    fields.NEW_VISITS,
                    fields.REPEAT_VISITS,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.BOUNCES,
                    fields.COST,
                    fields.FM_ROI,
                ],
                selectedFields: [
                    fields.VISITS,
                    fields.NEW_VISITS,
                    fields.REPEAT_VISITS,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.BOUNCES,
                    fields.COST,
                    fields.FM_ROI,
                ],
                pageFilters: selectedChannelFilter,
                enabled: !!selectedChannelFilter,
                ...tier2ProfileSectionMenu.request,
            },
        });

        const tier2BigNumberVisits = useResourceBigNumberComparison({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.VISITS,
            aggregation: 'sum',
            rounding: {
                applyRounding: false,
            },
        });

        const tier2BigNumberNewVisits = useResourceBigNumberComparison({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.NEW_VISITS,
            aggregation: 'sum',
            rounding: {
                applyRounding: false,
            },
        });

        const tier2BigNumberRepeatVisits = useResourceBigNumberComparison({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.REPEAT_VISITS,
            aggregation: 'sum',
            rounding: {
                applyRounding: false,
            },
        });

        const tier2BigNumberGoalCompletions = useResourceBigNumberComparison({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.FM_SALES,
            aggregation: 'sum',
        });

        const tier2BigNumberCompletionValue = useResourceBigNumberComparison({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
        });

        const tier2BigNumberBounces = useResourceBigNumberComparison({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.BOUNCES,
            aggregation: 'sum',
        });

        const tier2BigNumberCost = useResourceBigNumberComparison({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.COST,
            aggregation: 'sum',
            formatting: 'currency',
        });

        const tier2BigNumberROI = useResourceBigNumberComparison({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.FM_ROI,
            aggregation: 'sum',
            formatting: 'percentage',
        });

        // Detailed View - This Period
        const tier2ProfileDetailedViewThisPeriodRequest = useRequestTable({
            groupBy: tier2Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier2Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier2ProfileSectionMenu.request,
            selectedFields: [
                tier2Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const tier2ProfileDetailedViewThisPeriodData = useResource({
            resource: currentResource,
            request: {
                ...tier2ProfileDetailedViewThisPeriodRequest,
            },
        });

        const tier2ProfileDetailedViewThisPeriodTotalData = useResource({
            resource: currentResource,
            request: {
                totals: true,
                ...tier2ProfileDetailedViewThisPeriodRequest,
            },
        });

        const tier2ProfileDetailedViewThisPeriodTable = useResourceTable({
            resourceData: tier2ProfileDetailedViewThisPeriodData,
        });

        const tier2ProfileDetailedViewThisPeriodTableTotals = useResourceTotal({
            resourceData: tier2ProfileDetailedViewThisPeriodTotalData,
        });

        // Detailed View - Comparison Period
        const tier2ProfileDetailedViewComparisonPeriodRequest = useRequestTable({
            groupBy: tier2Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier2Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier2ProfileSectionMenu.request,
            selectedFields: [
                tier2Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const tier2ProfileDetailedViewComparisonPeriodData = useResource({
            resource: currentResource,
            isComparison: true,
            request: {
                ...tier2ProfileDetailedViewComparisonPeriodRequest,
            },
        });

        const tier2ProfileDetailedViewComparisonPeriodTotalData = useResource({
            resource: currentResource,
            isComparison: true,
            request: {
                totals: true,
                ...tier2ProfileDetailedViewComparisonPeriodRequest,
            },
        });

        const tier2ProfileDetailedViewComparisonPeriodTable = useResourceTable({
            resourceData: tier2ProfileDetailedViewComparisonPeriodData,
        });

        const tier2ProfileDetailedViewComparisonPeriodTableTotals = useResourceTotal({
            resourceData: tier2ProfileDetailedViewComparisonPeriodTotalData,
        });

        // Detailed View - Period vs Period Variance
        const tier2ProfileDetailedViewVarianceTableRequest = useRequestTable({
            groupBy: tier2Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier2Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier2ProfileSectionMenu.request,
        });

        const tier2ProfileDetailedViewVarianceTableData = useResourceComparison({
            resource: currentResource,
            request: tier2ProfileDetailedViewVarianceTableRequest,
        });

        const tier2ProfileDetailedViewVarianceTableTotalData = useResourceComparison({
            resource: currentResource,
            request: {
                totals: true,
                ...tier2ProfileDetailedViewVarianceTableRequest,
            },
        });

        const tier2ProfileDetailedViewVarianceTableVarianceOnly = useResourceTableComparisonVarianceOnly({
            resourceData: tier2ProfileDetailedViewVarianceTableData,
            dimension: tier2Field,
        });

        const tier2ProfileDetailedViewVarianceTableTotals = useResourceTableComparisonVarianceOnlyTotals({
            resourceData: tier2ProfileDetailedViewVarianceTableTotalData,
        });

        // Goal Completion Count & Value- By Tier 2
        // Goal Completion Count - By Tier 2 Pie
        const goalCompletionCountByTier2PieTableRequest = {
            groupBy: tier2Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [tier2Field, fields.GOAL_COMPLETIONS],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            chartField: fields.GOAL_COMPLETIONS,
            ...tier2GoalCompletionSectionMenu.request,
            selectedFields: [tier2Field, fields.GOAL_COMPLETIONS],
        };

        const goalCompletionCountByTier2PieTableData = useResourceComparison({
            resource: currentResource,
            request: goalCompletionCountByTier2PieTableRequest,
        });

        const goalCompletionCountByTier2PieTableTotalData = useResourceComparison({
            resource: currentResource,
            request: {
                ...goalCompletionCountByTier2PieTableRequest,
                totals: true,
            },
        });

        const goalCompletionCountByTier2PieTable = useResourcePieChartTableComparison({
            resourceData: goalCompletionCountByTier2PieTableData,
            chartDimension: tier2Field,
        });

        const goalCompletionCountByTier2PieTableTotals = useResourceTotalComparison({
            resourceData: goalCompletionCountByTier2PieTableTotalData,
        });

        const goalCompletionCountByTier2PieTableLimit = useResourcePieChartTableLimitComparison({
            resourceData: goalCompletionCountByTier2PieTable,
            totalData: goalCompletionCountByTier2PieTableTotals,
            ratioField: fields.GOAL_COMPLETIONS,
            limit: 10,
        });

        // Goal Completion Value - By Tier 2 Pie
        const goalCompletionValueByTier2PieTableRequest = {
            groupBy: tier2Field,
            orderBy: [{ field: fields.GOAL_VALUE, orderByDirection: 'desc' }],
            fields: [tier2Field, fields.GOAL_VALUE],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            chartField: fields.GOAL_VALUE,
            ...tier2GoalCompletionSectionMenu.request,
            selectedFields: [tier2Field, fields.GOAL_VALUE],
        };

        const goalCompletionValueByTier2PieTableData = useResourceComparison({
            resource: currentResource,
            request: goalCompletionValueByTier2PieTableRequest,
        });

        const goalCompletionValueByTier2PieTableTotalData = useResourceComparison({
            resource: currentResource,
            request: {
                ...goalCompletionValueByTier2PieTableRequest,
                totals: true,
            },
        });

        const goalCompletionValueByTier2PieTable = useResourcePieChartTableComparison({
            resourceData: goalCompletionValueByTier2PieTableData,
            chartDimension: tier2Field,
        });

        const goalCompletionValueByTier2PieTableTotals = useResourceTotalComparison({
            resourceData: goalCompletionValueByTier2PieTableTotalData,
        });

        const goalCompletionValueByTier2PieTableLimit = useResourcePieChartTableLimitComparison({
            resourceData: goalCompletionValueByTier2PieTable,
            totalData: goalCompletionValueByTier2PieTableTotals,
            ratioField: fields.GOAL_VALUE,
            limit: 10,
        });

        const goalCompletionValueByTier2PieTableMatchColours = useResourcePieTableComparisonMatchSectionColours({
            resourceData: goalCompletionValueByTier2PieTableLimit,
            resourceDataToMatch: goalCompletionCountByTier2PieTableLimit,
            dimension: tier2Field,
        });
        const goalCompletionTier2ComparisonValues = useRequestPieComparisonMultiple({
            comparisonCharts: [
                {
                    chartField: fields.GOAL_COMPLETIONS,
                    data: goalCompletionCountByTier2PieTableLimit,
                    totalData: goalCompletionCountByTier2PieTableTotals,
                },
                {
                    chartField: fields.GOAL_VALUE,
                    data: goalCompletionValueByTier2PieTableMatchColours,
                    totalData: goalCompletionValueByTier2PieTableTotals,
                },
            ],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
        });

        // Tier 3 Profile
        // Detailed View - This Period
        const tier3ProfileDetailedViewThisPeriodRequest = useRequestTable({
            groupBy: tier3Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier3Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier3ProfileSectionMenu.request,
            selectedFields: [
                tier3Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const tier3ProfileDetailedViewThisPeriodData = useResource({
            resource: currentResource,
            request: {
                ...tier3ProfileDetailedViewThisPeriodRequest,
            },
        });

        const tier3ProfileDetailedViewThisPeriodTotalData = useResource({
            resource: currentResource,
            request: {
                totals: true,
                ...tier3ProfileDetailedViewThisPeriodRequest,
            },
        });

        const tier3ProfileDetailedViewThisPeriodTable = useResourceTable({
            resourceData: tier3ProfileDetailedViewThisPeriodData,
        });

        const tier3ProfileDetailedViewThisPeriodTableTotals = useResourceTotal({
            resourceData: tier3ProfileDetailedViewThisPeriodTotalData,
        });

        // Detailed View - Comparison Period
        const tier3ProfileDetailedViewComparisonPeriodRequest = useRequestTable({
            groupBy: tier3Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier3Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier3ProfileSectionMenu.request,
            selectedFields: [
                tier3Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const tier3ProfileDetailedViewComparisonPeriodData = useResource({
            resource: currentResource,
            isComparison: true,
            request: {
                ...tier3ProfileDetailedViewComparisonPeriodRequest,
            },
        });

        const tier3ProfileDetailedViewComparisonPeriodTotalData = useResource({
            resource: currentResource,
            isComparison: true,
            request: {
                totals: true,
                ...tier3ProfileDetailedViewComparisonPeriodRequest,
            },
        });

        const tier3ProfileDetailedViewComparisonPeriodTable = useResourceTable({
            resourceData: tier3ProfileDetailedViewComparisonPeriodData,
        });

        const tier3ProfileDetailedViewComparisonPeriodTableTotals = useResourceTotal({
            resourceData: tier3ProfileDetailedViewComparisonPeriodTotalData,
        });

        // Detailed View - Period vs Period Variance
        const tier3ProfileDetailedViewVarianceTableRequest = useRequestTable({
            groupBy: tier3Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier3Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier3ProfileSectionMenu.request,
            selectedFields: [
                tier3Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const tier3ProfileDetailedViewVarianceTableData = useResourceComparison({
            resource: currentResource,
            request: tier3ProfileDetailedViewVarianceTableRequest,
        });

        const tier3ProfileDetailedViewVarianceTableTotalData = useResourceComparison({
            resource: currentResource,
            request: {
                totals: true,
                ...tier3ProfileDetailedViewVarianceTableRequest,
            },
        });

        const tier3ProfileDetailedViewVarianceTableVarianceOnly = useResourceTableComparisonVarianceOnly({
            resourceData: tier3ProfileDetailedViewVarianceTableData,
            dimension: tier3Field,
        });

        const tier3ProfileDetailedViewVarianceTableTotals = useResourceTableComparisonVarianceOnlyTotals({
            resourceData: tier3ProfileDetailedViewVarianceTableTotalData,
        });

        // Goal Completion Count & Value - By Tier 3
        // Goal Completion Count - By Tier 3 Pie
        const goalCompletionCountByTier3PieTableRequest = {
            groupBy: tier3Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [tier3Field, fields.GOAL_COMPLETIONS],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            chartField: fields.GOAL_COMPLETIONS,
            ...tier3GoalCompletionSectionMenu.request,
            selectedFields: [tier3Field, fields.GOAL_COMPLETIONS],
        };

        const goalCompletionCountByTier3PieTableData = useResourceComparison({
            resource: currentResource,
            request: goalCompletionCountByTier3PieTableRequest,
        });

        const goalCompletionCountByTier3PieTableTotalData = useResourceComparison({
            resource: currentResource,
            request: {
                ...goalCompletionCountByTier3PieTableRequest,
                totals: true,
            },
        });

        const goalCompletionCountByTier3PieTable = useResourcePieChartTableComparison({
            resourceData: goalCompletionCountByTier3PieTableData,
            chartDimension: tier3Field,
        });

        const goalCompletionCountByTier3PieTableTotals = useResourceTotalComparison({
            resourceData: goalCompletionCountByTier3PieTableTotalData,
        });

        const goalCompletionCountByTier3PieTableLimit = useResourcePieChartTableLimitComparison({
            resourceData: goalCompletionCountByTier3PieTable,
            totalData: goalCompletionCountByTier3PieTableTotals,
            ratioField: fields.GOAL_COMPLETIONS,
            limit: 10,
        });

        // Goal Completion Value - By Tier 3 Pie
        const goalCompletionValueByTier3PieTableRequest = {
            groupBy: tier3Field,
            orderBy: [{ field: fields.GOAL_VALUE, orderByDirection: 'desc' }],
            fields: [tier3Field, fields.GOAL_VALUE],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            chartField: fields.GOAL_VALUE,
            ...tier3GoalCompletionSectionMenu.request,
            selectedFields: [tier3Field, fields.GOAL_VALUE],
        };

        const goalCompletionValueByTier3PieTableData = useResourceComparison({
            resource: currentResource,
            request: goalCompletionValueByTier3PieTableRequest,
        });

        const goalCompletionValueByTier3PieTableTotalData = useResourceComparison({
            resource: currentResource,
            request: {
                ...goalCompletionValueByTier3PieTableRequest,
                totals: true,
            },
        });

        const goalCompletionValueByTier3PieTable = useResourcePieChartTableComparison({
            resourceData: goalCompletionValueByTier3PieTableData,
            chartDimension: tier3Field,
        });

        const goalCompletionValueByTier3PieTableTotals = useResourceTotalComparison({
            resourceData: goalCompletionValueByTier3PieTableTotalData,
        });

        const goalCompletionValueByTier3PieTableLimit = useResourcePieChartTableLimitComparison({
            resourceData: goalCompletionValueByTier3PieTable,
            totalData: goalCompletionValueByTier3PieTableTotals,
            ratioField: fields.GOAL_VALUE,
            limit: 10,
        });

        const goalCompletionValueByTier3PieTableMatchColours = useResourcePieTableComparisonMatchSectionColours({
            resourceData: goalCompletionValueByTier3PieTableLimit,
            resourceDataToMatch: goalCompletionCountByTier3PieTableLimit,
            dimension: tier3Field,
        });

        const goalCompletionTier3ComparisonValues = useRequestPieComparisonMultiple({
            comparisonCharts: [
                {
                    chartField: fields.GOAL_COMPLETIONS,
                    data: goalCompletionCountByTier3PieTableLimit,
                    totalData: goalCompletionCountByTier3PieTableTotals,
                },
                {
                    chartField: fields.GOAL_VALUE,
                    data: goalCompletionValueByTier3PieTableMatchColours,
                    totalData: goalCompletionValueByTier3PieTableTotals,
                },
            ],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
        });

        // Tier 4 Profile
        // Detailed View - This Period
        const tier4ProfileDetailedViewThisPeriodRequest = useRequestTable({
            groupBy: tier4Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier4Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier4ProfileSectionMenu.request,
            selectedFields: [
                tier4Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const tier4ProfileDetailedViewThisPeriodData = useResource({
            resource: currentResource,
            request: {
                ...tier4ProfileDetailedViewThisPeriodRequest,
            },
        });

        const tier4ProfileDetailedViewThisPeriodTotalData = useResource({
            resource: currentResource,
            request: {
                totals: true,
                ...tier4ProfileDetailedViewThisPeriodRequest,
            },
        });

        const tier4ProfileDetailedViewThisPeriodTable = useResourceTable({
            resourceData: tier4ProfileDetailedViewThisPeriodData,
        });

        const tier4ProfileDetailedViewThisPeriodTableTotals = useResourceTotal({
            resourceData: tier4ProfileDetailedViewThisPeriodTotalData,
        });

        // Detailed View - Comparison Period
        const tier4ProfileDetailedViewComparisonPeriodRequest = useRequestTable({
            groupBy: tier4Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier4Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier4ProfileSectionMenu.request,
            selectedFields: [
                tier4Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const tier4ProfileDetailedViewComparisonPeriodData = useResource({
            resource: currentResource,
            isComparison: true,
            request: {
                ...tier4ProfileDetailedViewComparisonPeriodRequest,
            },
        });

        const tier4ProfileDetailedViewComparisonPeriodTotalData = useResource({
            resource: currentResource,
            isComparison: true,
            request: {
                totals: true,
                ...tier4ProfileDetailedViewComparisonPeriodRequest,
            },
        });

        const tier4ProfileDetailedViewComparisonPeriodTable = useResourceTable({
            resourceData: tier4ProfileDetailedViewComparisonPeriodData,
        });

        const tier4ProfileDetailedViewComparisonPeriodTableTotals = useResourceTotal({
            resourceData: tier4ProfileDetailedViewComparisonPeriodTotalData,
        });

        // Detailed View - Period vs Period Variance
        const tier4ProfileDetailedViewVarianceTableRequest = useRequestTable({
            groupBy: tier4Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier4Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier4ProfileSectionMenu.request,
            selectedFields: [
                tier4Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const tier4ProfileDetailedViewVarianceTableData = useResourceComparison({
            resource: currentResource,
            request: tier4ProfileDetailedViewVarianceTableRequest,
        });

        const tier4ProfileDetailedViewVarianceTableTotalData = useResourceComparison({
            resource: currentResource,
            request: {
                totals: true,
                ...tier4ProfileDetailedViewVarianceTableRequest,
            },
        });

        const tier4ProfileDetailedViewVarianceTableVarianceOnly = useResourceTableComparisonVarianceOnly({
            resourceData: tier4ProfileDetailedViewVarianceTableData,
            dimension: tier4Field,
        });

        const tier4ProfileDetailedViewVarianceTableTotals = useResourceTableComparisonVarianceOnlyTotals({
            resourceData: tier4ProfileDetailedViewVarianceTableTotalData,
        });

        // Goal Completion Count & Value - By Tier 4
        // Goal Completion Count - By Tier 4 Pie
        const goalCompletionCountByTier4PieTableRequest = {
            groupBy: tier4Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [tier4Field, fields.GOAL_COMPLETIONS],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            chartField: fields.GOAL_COMPLETIONS,
            ...tier4GoalCompletionSectionMenu.request,
            selectedFields: [tier4Field, fields.GOAL_COMPLETIONS],
        };

        const goalCompletionCountByTier4PieTableData = useResourceComparison({
            resource: currentResource,
            request: goalCompletionCountByTier4PieTableRequest,
        });

        const goalCompletionCountByTier4PieTableTotalData = useResourceComparison({
            resource: currentResource,
            request: {
                ...goalCompletionCountByTier4PieTableRequest,
                totals: true,
            },
        });

        const goalCompletionCountByTier4PieTable = useResourcePieChartTableComparison({
            resourceData: goalCompletionCountByTier4PieTableData,
            chartDimension: tier4Field,
        });

        const goalCompletionCountByTier4PieTableTotals = useResourceTotalComparison({
            resourceData: goalCompletionCountByTier4PieTableTotalData,
        });

        const goalCompletionCountByTier4PieTableLimit = useResourcePieChartTableLimitComparison({
            resourceData: goalCompletionCountByTier4PieTable,
            totalData: goalCompletionCountByTier4PieTableTotals,
            ratioField: fields.GOAL_COMPLETIONS,
            limit: 10,
        });

        // Goal Completion Value - By Tier 4
        // Pie
        const goalCompletionValueByTier4PieTableRequest = {
            groupBy: tier4Field,
            orderBy: [{ field: fields.GOAL_VALUE, orderByDirection: 'desc' }],
            fields: [tier4Field, fields.GOAL_VALUE],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            chartField: fields.GOAL_VALUE,
            ...tier4GoalCompletionSectionMenu.request,
            selectedFields: [tier4Field, fields.GOAL_VALUE],
        };

        const goalCompletionValueByTier4PieTableData = useResourceComparison({
            resource: currentResource,
            request: {
                ...goalCompletionValueByTier4PieTableRequest,
                selectedFields: [tier4Field, fields.GOAL_VALUE],
            },
        });

        const goalCompletionValueByTier4PieTableTotalData = useResourceComparison({
            resource: currentResource,
            request: {
                ...goalCompletionValueByTier4PieTableRequest,
                totals: true,
                selectedFields: [tier4Field, fields.GOAL_VALUE],
            },
        });

        const goalCompletionValueByTier4PieTable = useResourcePieChartTableComparison({
            resourceData: goalCompletionValueByTier4PieTableData,
            chartDimension: tier4Field,
        });

        const goalCompletionValueByTier4PieTableTotals = useResourceTotalComparison({
            resourceData: goalCompletionValueByTier4PieTableTotalData,
        });

        const goalCompletionValueByTier4PieTableLimit = useResourcePieChartTableLimitComparison({
            resourceData: goalCompletionValueByTier4PieTable,
            totalData: goalCompletionValueByTier4PieTableTotals,
            ratioField: fields.GOAL_VALUE,
            limit: 10,
        });

        const goalCompletionValueByTier4PieTableMatchColours = useResourcePieTableComparisonMatchSectionColours({
            resourceData: goalCompletionValueByTier4PieTableLimit,
            resourceDataToMatch: goalCompletionCountByTier4PieTableLimit,
            dimension: tier4Field,
        });

        const goalCompletionTier4ComparisonValues = useRequestPieComparisonMultiple({
            comparisonCharts: [
                {
                    chartField: fields.GOAL_COMPLETIONS,
                    data: goalCompletionCountByTier4PieTableLimit,
                    totalData: goalCompletionCountByTier4PieTableTotals,
                },
                {
                    data: goalCompletionValueByTier4PieTableMatchColours,
                    totalData: goalCompletionValueByTier4PieTableTotals,
                    chartField: fields.GOAL_VALUE,
                },
            ],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
        });

        return {
            a: (
                <WidgetSparkLineComparison
                    title="Visits by Day"
                    data={visitsSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Teal}
                />
            ),
            b: (
                <WidgetSparkLineComparison
                    title="Sales by Day"
                    data={salesSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Orange}
                />
            ),
            c: (
                <WidgetSparkLineComparison
                    title="Revenue by Day"
                    data={revenueSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Green}
                />
            ),
            d: (
                <WidgetSparkLineComparison
                    title="Cost by Day"
                    data={costSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Rose}
                />
            ),
            h: <WidgetBigNumberComparison title="Visits" data={tier2BigNumberVisits} />,
            x: <WidgetBigNumberComparison title="New Visits" data={tier2BigNumberNewVisits} />,
            z: <WidgetBigNumberComparison title="Repeat Visits" data={tier2BigNumberRepeatVisits} />,
            i: <WidgetBigNumberComparison title="Goal Completions" data={tier2BigNumberGoalCompletions} />,
            j: <WidgetBigNumberComparison title="Completion Value" data={tier2BigNumberCompletionValue} />,
            k: <WidgetBigNumberComparison title="Bounces" data={tier2BigNumberBounces} />,
            l: <WidgetBigNumberComparison title="Cost" data={tier2BigNumberCost} />,
            m: <WidgetBigNumberComparison title="ROI" data={tier2BigNumberROI} />,
            n: (
                <WidgetTable
                    title="Detailed View - This Period"
                    data={tier2ProfileDetailedViewThisPeriodTable}
                    totalData={tier2ProfileDetailedViewThisPeriodTableTotals}
                    menu={<WidgetMenuTable data={tier2ProfileDetailedViewThisPeriodTable} />}
                />
            ),
            o: (
                <WidgetTable
                    title="Detailed View - Comparison Period"
                    data={tier2ProfileDetailedViewComparisonPeriodTable}
                    totalData={tier2ProfileDetailedViewComparisonPeriodTableTotals}
                    menu={<WidgetMenuTable data={tier2ProfileDetailedViewComparisonPeriodTable} />}
                />
            ),
            p: (
                <WidgetTable
                    title="Detailed View - Period on Period Variance"
                    data={tier2ProfileDetailedViewVarianceTableVarianceOnly}
                    totalData={tier2ProfileDetailedViewVarianceTableTotals}
                    menu={<WidgetMenuTable data={tier2ProfileDetailedViewVarianceTableVarianceOnly} />}
                />
            ),
            ee: (
                <WidgetTable
                    title="Detailed View - This Period"
                    data={tier3ProfileDetailedViewThisPeriodTable}
                    totalData={tier3ProfileDetailedViewThisPeriodTableTotals}
                    menu={<WidgetMenuTable data={tier3ProfileDetailedViewThisPeriodTable} />}
                />
            ),
            ff: (
                <WidgetTable
                    title="Detailed View - Comparison Period"
                    data={tier3ProfileDetailedViewComparisonPeriodTable}
                    totalData={tier3ProfileDetailedViewComparisonPeriodTableTotals}
                    menu={<WidgetMenuTable data={tier3ProfileDetailedViewComparisonPeriodTable} />}
                />
            ),
            gg: (
                <WidgetTable
                    title="Detailed View - Period on Period Variance"
                    data={tier3ProfileDetailedViewVarianceTableVarianceOnly}
                    totalData={tier3ProfileDetailedViewVarianceTableTotals}
                    menu={<WidgetMenuTable data={tier3ProfileDetailedViewVarianceTableVarianceOnly} />}
                />
            ),
            dd: (
                <WidgetTable
                    title="Detailed View - This Period"
                    data={tier4ProfileDetailedViewThisPeriodTable}
                    totalData={tier4ProfileDetailedViewThisPeriodTableTotals}
                    menu={<WidgetMenuTable data={tier4ProfileDetailedViewThisPeriodTable} />}
                />
            ),
            f: (
                <WidgetTable
                    title="Detailed View - Comparison Period"
                    data={tier4ProfileDetailedViewComparisonPeriodTable}
                    totalData={tier4ProfileDetailedViewComparisonPeriodTableTotals}
                    menu={<WidgetMenuTable data={tier4ProfileDetailedViewComparisonPeriodTable} />}
                />
            ),
            g: (
                <WidgetTable
                    title="Detailed View - Period on Period Variance"
                    data={tier4ProfileDetailedViewVarianceTableVarianceOnly}
                    totalData={tier4ProfileDetailedViewVarianceTableTotals}
                    menu={<WidgetMenuTable data={tier4ProfileDetailedViewVarianceTableVarianceOnly} />}
                />
            ),
            q: (
                <WidgetTableComparison
                    title="Goal Snapshot"
                    data={goalSnapshotTable}
                    totalData={goalSnapshotTableTotals}
                    menu={<WidgetMenuTable data={goalSnapshotTable} />}
                />
            ),
            r: (
                <WidgetBigNumberComparison
                    title="Goal Completions"
                    data={goalSnapshotBigNumberGoalCompletions}
                    menu={goalSnapshotBigNumberGoalCompletionsMenu.menu}
                />
            ),
            s: (
                <WidgetBigNumberComparison
                    title="Goal Value"
                    data={goalSnapshotBigNumberGoalValue}
                    menu={goalSnapshotBigNumberGoalValueMenu.menu}
                />
            ),
            t: (
                <WidgetBigNumberComparison
                    title="Goal Conversion Rate"
                    data={goalSnapshotBigNumberGoalCVR}
                    menu={goalSnapshotBigNumberGoalCVRMenu.menu}
                />
            ),
            u: (
                <WidgetStackedAreaChartComparison
                    title="Goal Completions Over Time"
                    data={goalCompletionStackedAreaChart}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={goalCompletionStackedAreaChart}
                            request={goalCompletionRequest}
                        />
                    }
                />
            ),
            v: (
                <WidgetPieChartTableComparisonMultiple
                    title={`Goal Completion Count & Value - ${
                        tier2Field.displayName ? tier2Field.displayName : '2nd Tier'
                    }`}
                    comparisonPieChartTables={goalCompletionTier2ComparisonValues}
                    display="donut"
                    menu={<WidgetMenuPieComparisonMultiple data={goalCompletionTier2ComparisonValues} />}
                />
            ),
            aa: (
                <WidgetPieChartTableComparisonMultiple
                    title={`Goal Completion Count & Value - ${
                        tier3Field.displayName ? tier3Field.displayName : '3rd Tier'
                    }`}
                    comparisonPieChartTables={goalCompletionTier3ComparisonValues}
                    display="donut"
                    menu={<WidgetMenuPieComparisonMultiple data={goalCompletionTier3ComparisonValues} />}
                />
            ),

            bb: (
                <WidgetPieChartTableComparisonMultiple
                    title={`Goal Completion Count & Value - ${
                        tier4Field.displayName ? tier4Field.displayName : '4th Tier'
                    }`}
                    comparisonPieChartTables={goalCompletionTier4ComparisonValues}
                    display="donut"
                    menu={<WidgetMenuPieComparisonMultiple data={goalCompletionTier4ComparisonValues} />}
                />
            ),
        };
    },
};
