import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import IconMinus from '../icons/minus';
import IconPlus from '../icons/plus';
import NavDropdownGroupItem from './nav-dropdown-group-item';
import { StyledCollapseIconContainerExpanded } from './nav-item';

const StyledExpandedTitle = styled.div`
    display: flex;
    color: ${props => props.theme.colours.light};
    font-size: 13px;
    padding: ${props => `16px 16px 15px ${props.hasSiblingMenuItems ? '34px' : '50px'}`};
    width: 100%;
    font-weight: 500;
    border-left: 2px solid ${props => props.theme.colours.offBlack};

    &:hover {
        border-left: 2px solid ${props => props.theme.colours.cubedOrange};
        background-color: ${props => props.theme.colours.offBlackLighter};
    }
`;

const StyledIconExpandedContainer = styled.div`
    width: 20px;
    margin-right: 18px;
`;

export const StyledIconExpanded = styled.div`
    & svg {
        height: 16px;
        width: 100%;

        & path {
            fill: ${props => props.theme.colours.cubedOrange};
        }
    }
`;

const NavGroupAccordion = ({ group, selectedItem, setSelectedItem, hasSiblingMenuItems }) => {
    const { account } = useSelector(state => state);
    const [dropdownIsActive, setDropdownIsActive] = useState(false);

    useEffect(() => {
        if (group.displayName !== selectedItem.subgroup) {
            setDropdownIsActive(false);
        }
        if (group.groupItems) {
            group.groupItems.forEach(item => {
                if (item.displayName === selectedItem.subgroup) {
                    setDropdownIsActive(true);
                }
                if (item.hasDropdown) {
                    item.dropDownSubGroups.forEach(subItem => {
                        if (subItem.displayName === selectedItem.subgroup) {
                            setDropdownIsActive(true);
                        }
                    });
                }
            });
        }
    }, [group, selectedItem]);

    const handleItemClick = () => {
        setSelectedItem({ ...selectedItem, subgroup: group.displayName });
        setDropdownIsActive(!dropdownIsActive);
    };

    const handleGroupItemClick = item => {
        setSelectedItem({ ...selectedItem, subgroup: item.displayName });
    };

    const handleItemEnterPress = e => {
        if (e.key === 'Enter') {
            setSelectedItem({ ...selectedItem, subgroup: group.displayName });
            setDropdownIsActive(!dropdownIsActive);
        }
    };

    return (
        <li key={group.displayName} data-testid="nav-subgroups-items">
            <StyledExpandedTitle
                onClick={handleItemClick}
                onKeyDown={handleItemEnterPress}
                tabIndex={0}
                hasSiblingMenuItems={hasSiblingMenuItems}
            >
                <StyledIconExpandedContainer>
                    <StyledIconExpanded>{group.icon}</StyledIconExpanded>
                </StyledIconExpandedContainer>
                {group.displayName}
                <StyledCollapseIconContainerExpanded>
                    {dropdownIsActive ? <IconMinus /> : <IconPlus />}
                </StyledCollapseIconContainerExpanded>
            </StyledExpandedTitle>

            {dropdownIsActive && (
                <ul>
                    {group.groupItems
                        .filter(group => !group.subGroupExcludedAccountTypes?.includes(account.group))
                        .map(item => {
                            return (
                                <NavDropdownGroupItem
                                    item={item}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    onGroupItemClick={handleGroupItemClick}
                                    key={item.displayName}
                                    hasSiblingMenuItems={hasSiblingMenuItems}
                                />
                            );
                        })}
                </ul>
            )}
        </li>
    );
};

export default NavGroupAccordion;
