import { combineReducers } from 'redux';

import AccountReducer from './account';
import UserReducer from './user';
import MetaReducer from './meta';
import ModalReducer from './modal';
import PopupReducer from './popup';
import NotificationsReducer from './notification';
import PageMetaReducer from './page-meta';
import DateReducer from './date';
import ComparisonDateReducer from './comparison-date';
import GlobalSalesTitleReducer from './globalSalesTitle';
import NavBarReducer from './nav-bar';
import CurrencyReducer from './currency';
import requestSlice from '../slices/request';

const rootReducer = combineReducers({
    user: UserReducer,
    account: AccountReducer,
    meta: MetaReducer,
    modal: ModalReducer,
    request: requestSlice,
    popup: PopupReducer,
    notification: NotificationsReducer,
    pageMeta: PageMetaReducer,
    date: DateReducer,
    comparisonDate: ComparisonDateReducer,
    saleTitle: GlobalSalesTitleReducer,
    nav: NavBarReducer,
    currency: CurrencyReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
