import React, { createContext, useContext } from 'react';
import { QueryStatus } from '@tanstack/react-query';

type ConfigDragAndDropTableContextValues = {
    status: QueryStatus;
    isFetching: boolean;
    tableDisabled: boolean;
    empty: boolean;
    dragDisabled: boolean;
};

const ConfigDragAndDropTableContext = createContext<ConfigDragAndDropTableContextValues | undefined>(undefined);

export const useConfigDragAndDropTableContext = () => {
    const context = useContext(ConfigDragAndDropTableContext);
    if (context === undefined) {
        throw new Error(
            '"Config Table" child components cannot be rendered outside of the ConfigDragAndDropTable component'
        );
    }
    return context;
};

type ConfigDragAndDropTableProviderProps = {
    status: QueryStatus;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
    dragDisabled: boolean;
    children: React.ReactNode;
};

export const ConfigDragAndDropTableProvider = ({
    status,
    isFetching,
    disabled,
    empty,
    dragDisabled,
    children,
}: ConfigDragAndDropTableProviderProps) => {
    return (
        <ConfigDragAndDropTableContext.Provider
            value={{ status, isFetching, tableDisabled: disabled, empty, dragDisabled }}
        >
            {children}
        </ConfigDragAndDropTableContext.Provider>
    );
};
