import React from 'react';
import numeral from 'numeral';
import styled from 'styled-components';

const StyledProgressBarWrapper = styled.div`
    position: relative;
`;

const StyledProgressBar = styled.div`
    width: 100%;
    display: flex;
`;

const StyledProgressBarContainer = styled.div`
    position: relative;
    width: 100%;
    height: 6px;
    flex: 1 1 auto;
    margin-top: 6px;
`;

const StyledProgressBarLabel = styled.div`
    width: 100%;
    text-transform: uppercase;
    color: ${props => props.theme.colours.offBlackLighter};
    padding: 2px 2px 2px;
    font-size: 11px;
    font-weight: 600;
`;

const StyledCurrent = styled.div<{ val: number; posDifference: boolean; calcDifference: number }>`
    position: absolute;
    width: 3px;
    height: 15px;
    z-index: 1;
    top: -4px;
    left: ${props => (props.posDifference ? `${(props.val - props.calcDifference) * 100}%` : `${props.val * 100}%`)};
    background-color: #607d8b;
`;

const StyledBar = styled.div<{ progress: string | null }>`
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: ${props =>
        props.progress === 'low' ? '0 12px 12px 0' : props.progress === 'high' ? '12px 0 0 12px' : '12px'};
    height: 100%;
    background-color: ${props => props.theme.colours.shadowGrey};
`;

const StyledProgressBarCurrentPct = styled.div`
    padding: 1px 4px;
    font-size: 12px;
    flex: 0 1 60px;
    margin: 0 8px 0 0;
    background-color: ${props => props.theme.colours.lightGrey};
    border-radius: 3px;
    text-align: center;
`;

const StyledProgressBarValue = styled.div<{ val: number }>`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${props => `${props.val * 100}%`};
    background-color: #607d8b;
`;

const StyledProgressBarDifference = styled.div<{ posDifference: boolean; calcDifference: number; val: number }>`
    position: absolute;
    height: 100%;
    background-color: ${props => (props.posDifference ? '#48c465' : '#f5656c')};
    width: ${props => `${Math.abs(props.calcDifference) * 100}%`};
    left: ${props => (props.posDifference ? `${(props.val - props.calcDifference) * 100}%` : `${props.val * 100}%`)};
`;

export type ProgressBarProps = {
    label: string;
    value: number;
    difference: number;
};

const ProgressBar = ({ label = 'Placeholder Label', value = 0, difference = 0 }: ProgressBarProps) => {
    const renderBar = () => {
        const val = Math.min(Math.max(value, 0), 1);
        const calcDifference = Math.min(Math.max(difference, -1), 1);
        const posDifference = calcDifference > 0;

        return [
            <StyledCurrent
                key={1}
                val={val}
                posDifference={posDifference}
                calcDifference={calcDifference}
            ></StyledCurrent>,
            <StyledBar key={2} progress={value < 0.05 ? 'low' : value > 0.95 ? 'high' : null}>
                <StyledProgressBarValue val={val} />
                <StyledProgressBarDifference val={val} posDifference={posDifference} calcDifference={calcDifference} />
            </StyledBar>,
        ];
    };

    const val = Math.round(Math.min(Math.max(value, 0), 1) * 10000) / 10000;

    return (
        <StyledProgressBarWrapper>
            <StyledProgressBarLabel>{label}</StyledProgressBarLabel>
            <StyledProgressBar>
                <StyledProgressBarCurrentPct>{numeral(val).format('0%')}</StyledProgressBarCurrentPct>
                <StyledProgressBarContainer>{renderBar()}</StyledProgressBarContainer>
            </StyledProgressBar>
        </StyledProgressBarWrapper>
    );
};

export default ProgressBar;
