import React, { FunctionComponent, memo } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Common
import CommonInput from '../../components/common/common-input';
import CommonSelect from '../../components/common/common-select';

// Helpers
import { isInteger, isDate, isNumber } from '../../helpers/validator';

// Type
import { MetricValueFieldProps } from '../types';

const StyledErrorMessage = styled.p`
    color: red;
    font-size: 0.9rem;
`;

const FilterBarMetricValueField: FunctionComponent<MetricValueFieldProps> = ({
    handleMetricsFilterValueOnChange,
    filterData,
}) => {
    const { value, metricType } = filterData;

    const valueField = () => {
        switch (metricType) {
            case 1: // date
                return (
                    <>
                        <CommonInput
                            inputType="date"
                            name="value"
                            placeholder="Date"
                            onChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                            value={value}
                            autocomplete="off"
                        />
                        {value !== '' && !isDate(value) && (
                            <StyledErrorMessage>Please enter a valid date</StyledErrorMessage>
                        )}
                    </>
                );
            case 2: // time
                return (
                    <CommonInput
                        inputType="time"
                        name="value"
                        placeholder="Time"
                        onChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                        value={value}
                        autocomplete="off"
                    />
                );
            case 3: // date time
                return (
                    <CommonInput
                        inputType="datetime-local"
                        name="value"
                        placeholder="Date and Time"
                        onChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                        value={value}
                        autocomplete="off"
                    />
                );
            case 4: // number
                return (
                    <>
                        <CommonInput
                            inputType="number"
                            name="value"
                            placeholder="Value"
                            onChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                            value={value}
                            autocomplete="off"
                            min="0"
                            step="1"
                        />
                        {value !== '' && !isInteger(value) && (
                            <StyledErrorMessage>Please enter a valid number</StyledErrorMessage>
                        )}
                    </>
                );
            case 5: // float
                return (
                    <>
                        <CommonInput
                            inputType="number"
                            name="value"
                            placeholder="Value"
                            onChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                            value={value}
                            autocomplete="off"
                            min="0"
                            step="0.1"
                        />
                        {value !== '' && !isNumber(value) && (
                            <StyledErrorMessage>Please enter a valid number</StyledErrorMessage>
                        )}
                    </>
                );
            case 6: // string
                return (
                    <CommonInput
                        inputType="text"
                        name="value"
                        placeholder="Value"
                        onChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                        value={value}
                        autocomplete="off"
                    />
                );
            case 7: // duration
                return (
                    <CommonInput
                        inputType="text"
                        name="value"
                        placeholder="Value"
                        onChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                        value={value}
                        autocomplete="off"
                    />
                );
            case 8: // money
                return (
                    <>
                        <CommonInput
                            inputType="number"
                            name="value"
                            placeholder="Value"
                            onChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                            value={value}
                            autocomplete="off"
                            min="0"
                            step="0.1"
                        />
                        {value !== '' && !isNumber(value) && (
                            <StyledErrorMessage>Please enter a valid number</StyledErrorMessage>
                        )}
                    </>
                );
            case 9: // boolean
                return (
                    <CommonSelect
                        options={[
                            {
                                id: 'true',
                                name: 'True',
                                value: 'true',
                            },
                            {
                                id: 'false',
                                name: 'False',
                                value: 'false',
                            },
                        ]}
                        defaultText="Select a value"
                        defaultValue=""
                        handleSelectChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                        value={value!}
                    />
                );
            case 10: // selector
                return (
                    <CommonInput
                        inputType="text"
                        name="value"
                        placeholder="Value"
                        onChange={event => handleMetricsFilterValueOnChange(event, filterData)}
                        value={value}
                        autocomplete="off"
                    />
                );
            default:
                return <CommonInput inputType="text" name="value" placeholder="Value" disabled />;
        }
    };

    return <>{valueField()}</>;
};

export default memo(FilterBarMetricValueField, isEqual);
