import { v4 as uuidv4 } from 'uuid';
import { unknownErrorData } from '../../../helpers/errors';
import { PieTableData, TableRow, TotalData } from '../../../types';
import theme from '../../../../styled-components/theme.styled';
import { CubedField } from '../../../../types';
import { graphColours } from '../../../helpers/graph-colours';

type UseResourcePieChartTableLimitArgs = {
    resourceData: PieTableData;
    totalData?: TotalData;
    ratioField: CubedField;
    limit: number;
};

const useResourcePieChartTableLimit = ({
    resourceData,
    totalData,
    ratioField,
    limit,
}: UseResourcePieChartTableLimitArgs): PieTableData => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const filteredRows = resourceData.rows.filter(row => row.values[ratioField.rawName].rawValue !== 0);

        if (filteredRows.length > limit) {
            if (!resourceData?.request?.fields) {
                throw new Error('Expected fields in the request');
            }

            // Limit the rows and group the others into a single 'other' row
            const topRows = filteredRows.slice(0, limit);
            const otherRows = filteredRows.slice(limit);

            const otherRow = {
                __id: uuidv4(),
                colour: theme.colours.borderGrey,
                values: {},
            } as TableRow;

            resourceData.request.fields.forEach(field => {
                if (!field.isDimension) {
                    const value = otherRows.reduce((a, b) => a + +b.values[field.rawName].rawValue, 0).toFixed(2);
                    otherRow.values[field.rawName] = {
                        rawValue: value,
                        value: String(value),
                    };
                } else {
                    otherRow.values[field.rawName] = {
                        rawValue: 'Others',
                        value: 'Others',
                    };
                }
            });

            // Limit the series and group the others into a single 'other' series
            const topSeriesData = resourceData.series[0].data.slice(0, limit);
            const otherSeriesData = resourceData.series[0].data.slice(limit);

            const otherSeries = {
                name: 'Others',
                color: theme.colours.borderGrey,
                value: 0,
            };

            otherSeriesData.forEach(series => {
                otherSeries.value += series.value;
            });

            otherSeries.value = parseFloat(otherSeries.value.toFixed(2));

            // If the totalData is present, we can calculate the limit vs others ratio
            const ratio = {
                ratioLeft: {
                    colour: graphColours[0].solidGraphColour,
                    value: 0,
                },
                ratioRight: {
                    colour: theme.colours.borderGrey,
                    value: 0,
                },
            };
            if (totalData && totalData.status === 'success') {
                const total = +totalData.totals.values[ratioField.rawName].rawValue;

                ratio.ratioLeft.value = Math.round(
                    (topRows.reduce((a, b) => a + +b.values[ratioField.rawName].rawValue, 0) / total) * 100
                );
                ratio.ratioRight.value = 100 - ratio.ratioLeft.value;
            }

            return {
                ...resourceData,
                rows: [...topRows, otherRow],
                series: [
                    {
                        ...resourceData.series[0],
                        data: [...topSeriesData, otherSeries],
                    },
                ],
                ratio: totalData ? ratio : undefined,
                limit: limit,
            };
        }
        return {
            ...resourceData,
            rows: filteredRows,
        };
    }

    return unknownErrorData();
};

export default useResourcePieChartTableLimit;
