import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import * as L from 'leaflet';
import markerImage from '../../assets/images/red-marker.png';
import styled from 'styled-components';
import '../../sass/components/location-map.scss';
import '../../sass/widgets/_leaflet.scss';

const StyledLocationMapWrapper = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    border: 1px solid ${props => props.theme.colours.offBlackLighter};
    height: 200px;
`;

const StyledLocationMap = styled.div`
    position: relative;
    width: 100%;
    height: 232px;
`;

const LocationMap = props => {
    const RenderMarkers = () => {
        const markers = props.locations.map((loc, index) => {
            if (!loc.lat || !loc.lng) {
                return null;
            }
            return <Marker key={index} position={[loc.lat, loc.lng]} icon={redIcon}></Marker>;
        });
        return markers;
    };
    const redIcon = new L.Icon({
        iconUrl: markerImage,
        popupAnchor: [-0, -0],
        iconSize: [40, 40],
    });

    const RenderMapContainer = () => {
        if (!props.locations[0].lat || !props.locations[0].lng) {
            return;
        }
        return (
            <MapContainer
                center={[props.locations[0].lat, props.locations[0].lng]}
                zoom={13}
                scrollWheelZoom={true}
                attributionControl={false}
                zoomControl={false}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <RenderMarkers />
            </MapContainer>
        );
    };
    const RenderMap = () => {
        return (
            <StyledLocationMapWrapper>
                <StyledLocationMap>
                    <RenderMapContainer />
                </StyledLocationMap>
            </StyledLocationMapWrapper>
        );
        // }
    };
    return <RenderMap />;
};

export default LocationMap;
