// React Dependencies
import React, { useState } from 'react';
import Papa from 'papaparse';
import styled from 'styled-components';

// Validation
import validators from '../../components/forms/validation/validators';
import validateFileUpload from '../../components/forms/validation/validate-file-upload';

// Helpers
import { ButtonThemes } from '../../enums/button-themes';

// Redux
import { removeModal } from '../../redux/actions/modal';
import { useSelector, useDispatch } from 'react-redux';

// Components
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import InputButton from '../../components/inputs/input-button';
import ProgressBar from '../../components/progress-bar';
import Form from '../../components/forms/form';

// Types
import { RootState } from '../../redux/store';
import { FieldValues } from 'react-hook-form';
import { ConfigDataSuccess } from '../../react-query/types';
import { FileColumnDataType, FileData, FileType } from '../../components/forms/validation/types';

// Hooks
import useCSVUploadResource from '../../react-query/hooks/use-csv-upload-resource';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';

// Config
import { CONFIG_CHANNELS, CONFIG_GOALS, FORECAST_FILE_UPLOAD_RESOURCE } from '../../configurations/resources-config';

const StyledButtonWithMargin = styled.div`
    margin-top: 20px;
`;

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const LayoutModalAddForecasts = () => {
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);

    const [formData, setFormData] = useState<FormData | null>(null);

    const goalsQuery = useFetchResource({
        resource: CONFIG_GOALS,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.name,
                    value: object.id,
                };
            });
        },
    });

    const channelQuery = useFetchResource({
        resource: CONFIG_CHANNELS,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => object.name);
        },
    });

    const csvFormatConfig = [
        {
            header: 'Budget',
            format: FileColumnDataType.NUMBER,
        },
        {
            header: 'Channel',
            format: FileColumnDataType.STRING_IN_ARRAY,
            comparisonArray: channelQuery?.data || [],
        },
        {
            header: 'Date',
            format: FileColumnDataType.DATE,
        },
        {
            header: 'Forecasted_Revenue',
            format: FileColumnDataType.NUMBER,
        },
        {
            header: 'Forecasted_Sales',
            format: FileColumnDataType.NUMBER,
        },
        {
            header: 'Forecasted_Traffic',
            format: FileColumnDataType.NUMBER,
        },
    ];

    const {
        uploadIsLoading,
        uploadIsUploading,
        uploadComplete,
        uploadProgress,
        returningMessage,
        uploadError,
        resetStateFunction,
    } = useCSVUploadResource({
        fileUploadProgressResource: FORECAST_FILE_UPLOAD_RESOURCE,
        formData,
    });

    const finishUpload = () => {
        resetStateFunction();
    };

    const handleSubmit = (data: FieldValues) => {
        const file = data.file[0];

        const reader = new FileReader();
        let fileData: FileData[] = [];

        reader.readAsText(file);
        reader.onload = fileEvent => {
            const csvData: string = fileEvent.target?.result as string;

            Papa.parse(csvData, {
                skipEmptyLines: true,
                header: true,
                complete: result => {
                    fileData = result.data as FileData[];
                },
            });

            const keys = Object.keys(fileData[0]);
            const commaSeparatedString = [
                keys.join(','),
                fileData.map(row => keys.map(key => row[key]).join(',')).join('\n'),
            ].join('\n');
            const fileBlob = new Blob([commaSeparatedString], { type: 'text/csv' });

            const form = new FormData();
            form.append('upload_type', 'forecast');
            form.append('file', fileBlob);
            form.append('product', data.goal);
            form.append('account_id', account.id);

            setFormData(form);
        };
    };

    const RenderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: () => dispatch(removeModal()),
                disabled: false,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    if (uploadIsLoading) {
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add Forecasts</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (uploadError || goalsQuery.isError || channelQuery.isError) {
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add Forecasts</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact" />
            </div>
        );
    }

    if (uploadError) {
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add Forecasts</h2>
                <WarningMessage copy="There was a problem with your file upload. Please check your file, try again later or contact" />
            </div>
        );
    }

    if (uploadIsUploading || uploadComplete) {
        const progressCopy = `File upload ${uploadProgress === '100.00' ? 'complete!' : 'in progress...'}`;
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add Forecasts</h2>
                <div className="file-upload-progress">
                    {returningMessage && <p>{returningMessage}</p>}
                    <p>{progressCopy}</p>
                    <ProgressBar value={parseFloat(uploadProgress) / 100} difference={100} label="Upload Progress" />
                    <StyledButtonWithMargin>
                        <InputButton
                            buttonTheme={ButtonThemes.Primary}
                            value={'Done'}
                            hidden={uploadProgress !== '100.00'}
                            onClick={finishUpload}
                        />
                    </StyledButtonWithMargin>
                </div>
            </div>
        );
    }

    return (
        <div className="modal__side-panel__add-csv manage-modals">
            <RenderModalNavigation />
            <h2>Add Forecasts</h2>
            <p>
                Import forecast data into Cubed to enable advanced forecast reporting. It is important to use the
                defined CSV structure to upload your forecasts - a blank CSV template can be downloaded below. A goal
                and channel must be specified. For more information on uploading forecasts and budgets using the CSV
                template, please see our <a href="https://tag.docs.withcubed.com/onboarding/forecasts">documentation</a>
                .
            </p>
            <p>
                If you are uploading forecasts and budgets for dates in the past, please contact a member of the team to
                re-run your reports.
            </p>
            <p>
                <a href="/files/forecast_template.csv">CSV Template</a>
            </p>

            <StyledFormContainer>
                <Form
                    onSubmit={handleSubmit}
                    defaultValues={{ goal: '', file: '' }}
                    status={uploadIsLoading || goalsQuery.isLoading || channelQuery.isLoading ? 'disabled' : 'enabled'}
                >
                    <Form.Body>
                        <Form.Section>
                            <Form.InputLabel
                                htmlFor="goal"
                                label="Goal"
                                tooltipCopy="The goal associated with the forecasts and budgets inserted."
                            />
                            <Form.InputSelect
                                name="goal"
                                placeholder={
                                    goalsQuery.isLoading || channelQuery.isLoading
                                        ? 'Fetching goals...'
                                        : 'Please select a goal'
                                }
                                options={goalsQuery.data || []}
                                validators={[validators.required]}
                                disabled={uploadIsLoading || uploadIsUploading}
                            />

                            <Form.InputLabel htmlFor="file" label="Forecast CSV File" />
                            <Form.InputFileUpload
                                name="file"
                                validators={[
                                    validateFileUpload.required,
                                    validateFileUpload.validateFileType(FileType.CSV),
                                    validateFileUpload.validateContainsData(),
                                    validateFileUpload.validateHeaders(csvFormatConfig),
                                    validateFileUpload.validateColumnValues(csvFormatConfig),
                                ]}
                                disabled={uploadIsLoading || uploadIsUploading}
                            />
                        </Form.Section>
                    </Form.Body>
                    <Form.Footer>
                        <Form.InputButton type="submit" value="Upload" loading={uploadIsLoading || uploadIsUploading} />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </div>
    );
};

export default LayoutModalAddForecasts;
