import React from 'react';
import styled from 'styled-components';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

const StyledDialogDescription = styled(AlertDialog.Description)`
    font-size: 0.9rem;
    font-weight: 400;
    color: ${props => props.theme.dialog.content.color};
`;

const DialogDescription = ({ children }: { children: React.ReactNode }) => {
    return <StyledDialogDescription>{children}</StyledDialogDescription>;
};

export default DialogDescription;
