import { useEffect, useState } from 'react';

// Hooks & Helpers
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import buildDropdownResource from '../../../../react-query/helpers/build-dropdown-resource';

// Types
import { CubedField } from '../../../../types';
import { ConfigDataSuccess, ResourceDataSuccess } from '../../../../react-query/types';
import { DropdownOption, SelectOptionsRequest, SelectQuery, RequestFilter, RequestStatus } from '../../../types';

type UseWidgetMenuSelectStateArgs = {
    query: SelectQuery;
    dropdownField: CubedField;
    includeAllOption?: boolean;
    selectedOverride?: string;
    label?: string;
};

const useWidgetMenuSelectState = ({
    query,
    dropdownField,
    includeAllOption = false,
    selectedOverride,
    label,
}: UseWidgetMenuSelectStateArgs) => {
    const [dropdownOptions, setDropdownOptions] = useState<SelectOptionsRequest>({
        status: RequestStatus.LOADING,
        options: [],
    });
    const [selectedOption, setSelectedOption] = useState<DropdownOption>();
    const [filters, setFilters] = useState<RequestFilter[]>([]);
    const items = useFetchResource<ConfigDataSuccess | ResourceDataSuccess, DropdownOption[]>(query);

    useEffect(() => {
        const dropdownResource = buildDropdownResource(items);

        if (dropdownResource.options.length > 0) {
            const allOption = {
                label: `All ${label || dropdownField.displayName}s`,
                value: 'all',
            } as DropdownOption;

            if (includeAllOption) {
                setDropdownOptions({
                    ...dropdownResource,
                    options: [allOption, ...dropdownResource.options],
                });
                setSelectedOption(allOption);
            } else {
                setDropdownOptions(dropdownResource);
                setSelectedOption(dropdownResource.options[0]);
            }
        } else {
            setDropdownOptions(dropdownResource);
            setSelectedOption(undefined);
        }
    }, [items.status, items.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedOption && selectedOption.value !== 'all') {
            setFilters([{ field: dropdownField, value: selectedOption.label }]);
        } else {
            setFilters([]);
        }
    }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

    // If there is a selected override, set it as the selected option
    useEffect(() => {
        if (selectedOverride) {
            if (dropdownOptions) {
                const selectedItem = dropdownOptions.options.find(object => object.label === selectedOverride);

                if (selectedItem) {
                    setSelectedOption(selectedItem);
                }
            }
        }
    }, [dropdownOptions, selectedOverride]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeSelectedOption = (selectedOption: string) => {
        setSelectedOption(dropdownOptions.options.find(option => option.value === selectedOption));
    };

    return {
        dropdownOptions,
        selectedOption,
        handleChangeSelectedOption,
        filters,
        enabled: dropdownOptions.status === RequestStatus.EMPTY || !!selectedOption,
        showEmpty: dropdownOptions.status === RequestStatus.ERROR,
        showLoading: dropdownOptions.status === RequestStatus.LOADING,
    };
};

export default useWidgetMenuSelectState;
