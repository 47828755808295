export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_SHOWSIDEBAR':
            return Object.assign({}, state, {
                showSidebar: action.payload.showSidebar,
            });
        default:
            return state;
    }
}

const initialState = {
    showSidebar: false,
};
