import React from 'react';
import Form from '../../../../components/forms/form';
import validators from '../../../../components/forms/validation/validators';
import { ButtonThemes } from '../../../../enums/button-themes';
import { DropdownOption } from '../../../../section-dashboard/types';

const FormInviteExistingUser = ({
    roles,
    isPending,
    handleInviteCancelClick,
}: {
    roles: DropdownOption[];
    isPending: boolean;
    handleInviteCancelClick: () => void;
}) => {
    return (
        <>
            <Form.Body>
                <Form.Section>
                    <Form.Field>
                        <Form.InputLabel htmlFor="email" label="Email" />
                        <Form.InputText name="email" validators={[validators.required, validators.isEmail]} />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputLabel htmlFor="role" label="Role" />
                        <Form.InputSelect
                            name="role"
                            options={roles}
                            disabled={false}
                            validators={[validators.required]}
                        />
                    </Form.Field>
                </Form.Section>
            </Form.Body>
            <Form.Footer>
                <Form.InputButton type="submit" value="Send Invite" loading={isPending} />
                <Form.InputButton
                    type="button"
                    value="Cancel"
                    buttonTheme={ButtonThemes.Secondary}
                    onClick={handleInviteCancelClick}
                />
            </Form.Footer>
        </>
    );
};

export default FormInviteExistingUser;
