// React Dependencies
import React from 'react';
import styled from 'styled-components';

// Import Components
import InputEmail from '../inputs/input-email';
import Tooltip from '../tooltip';

const StyledFormField = styled.div`
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 15px;
`;

const StyledFormFieldTop = styled.div`
    margin-bottom: 5px;
`;

const StyledFormFieldLabel = styled.label`
    font-size: 1.1em;
    font-weight: 400;
`;

const StyledErrorMessage = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colours.red};
    margin-bottom: 1em;
`;

const StyledRequiredField = styled.span`
    margin-bottom: 5px;
    color: ${props => props.theme.colours.red};
`;

export type FormEmailProps = {
    toolTipCopy?: string;
    errorMessage?: string;
    requiredField?: boolean;
    label: string;
    inputValue: string;
    inputOnChange: () => void;
    onInputKeyPress: () => void;
};

const FormEmail = ({
    toolTipCopy,
    errorMessage,
    requiredField,
    label,
    inputValue,
    inputOnChange,
    onInputKeyPress,
}: FormEmailProps) => {
    return (
        <StyledFormField>
            <StyledFormFieldTop>
                <StyledFormFieldLabel>
                    {label} {requiredField && <StyledRequiredField>*</StyledRequiredField>}
                </StyledFormFieldLabel>
                {toolTipCopy && <Tooltip copy={toolTipCopy} />}
            </StyledFormFieldTop>

            <InputEmail value={inputValue} onChange={inputOnChange} onKeyDown={onInputKeyPress} />

            {errorMessage && <StyledErrorMessage> {errorMessage} </StyledErrorMessage>}
        </StyledFormField>
    );
};

export default FormEmail;
