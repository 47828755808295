import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generatePath, generateUrl } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isAlphaNumericSpecialCharacterString } from '../../helpers/validator';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import { MarketResponse } from '../types';
import { DropdownOption } from '../../types';

type ErrorMessageObject = {
    regexTextErrorMessage: string;
    marketErrorMessage: string;
};

const LayoutAddPagesToMarket = () => {
    const [closeButtonState, setCloseButtonState] = useState<string>('close');
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState<boolean>(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState<boolean>(true);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageError, setPageError] = useState<boolean>(false);

    const [regexText, setRegexText] = useState<string>('');
    const [isInverseRegexPattern, setIsInverseRegexPattern] = useState<boolean>(false);
    const [marketList, setMarketList] = useState<DropdownOption[]>([]);
    const [selectedMarket, setSelectedMarket] = useState<DropdownOption[]>([]);

    const [errorMessageObject, setErrorMessageObject] = useState<ErrorMessageObject>({
        regexTextErrorMessage: '',
        marketErrorMessage: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        fetchAll();
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        setSaveChangesButtonDisabled(true);
        setCloseButtonState('close');
        if (regexText || selectedMarket.length !== 0) {
            setSaveChangesButtonDisabled(false);
            setErrorMessageObject({
                regexTextErrorMessage: '',
                marketErrorMessage: '',
            });
            setCloseButtonState('cancel');
        }
    }, [regexText, selectedMarket]);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            regexTextErrorMessage: '',
            marketErrorMessage: '',
        };

        if (!isAlphaNumericSpecialCharacterString(regexText) || regexText.length === 0) {
            hasFormError = true;
            errorMessageObj.regexTextErrorMessage = 'Please enter valid regex pattern.';
        }

        if (selectedMarket.length === 0) {
            hasFormError = true;
            errorMessageObj.marketErrorMessage = 'Please select a market.';
        }

        if (hasFormError) {
            setSaveChangesButtonDisabled(true);
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const handleRegexText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegexText(event?.target?.value);
    };

    const handleMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
        let marketId = parseInt(event?.target?.value);
        let selectMarket = marketList.filter(market => market?.value === marketId);
        setSelectedMarket(selectMarket);
    };

    const onCheckBoxClick = (checkBoxName: React.ChangeEvent<HTMLInputElement>) => {
        if (String(checkBoxName) === 'isInverseRegexPattern') {
            setIsInverseRegexPattern(!isInverseRegexPattern);
        } else {
            setIsInverseRegexPattern(isInverseRegexPattern);
        }
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const fetchAll = () => {
        const allRequests: AxiosPromise[] = [];

        allRequests.push(
            axios({
                method: 'GET',
                url: generateUrl('config', 'seogd-market', [
                    { key: 'active', value: 1 },
                    { key: 'limit', value: 0 },
                    { key: 'order_by', value: 'country' },
                ]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        axios
            .all(allRequests)
            .then(
                axios.spread((...response) => {
                    const allMarkets = response[0].data.objects.map((data: MarketResponse) => {
                        return {
                            label: data.alpha2,
                            value: data.id,
                            name: data.country,
                        };
                    });

                    setMarketList(allMarkets);
                    setIsLoading(false);
                })
            )
            .catch(error => {
                setIsLoading(false);
                setPageError(true);
                errorHandling(error);
            });
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(setModal('ManagePagesToMarket', {}));
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const saveRegexPattern = () => {
        return new Promise(async (resolve, reject) => {
            const payload = {
                regex: regexText,
                inverse: isInverseRegexPattern,
                market: generatePath('config', 'seogd-market', String(selectedMarket[0].value)),
            };
            try {
                const pathMarketRequestConfig: AxiosRequestConfig = {
                    method: 'POST',
                    url: generateUrl('config', 'path-market-config'),
                    data: payload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                await axios(pathMarketRequestConfig).then(response => {
                    if (![200, 201, 202].includes(response.status)) {
                        throw new Error('Status not 201');
                    }
                });
            } catch (error) {
                errorHandling(error);
                reject('Error making a connection to API.');
            }
            resolve('Regex Pattern is added.');
        });
    };

    const handleNavigateManageModal = () => {
        dispatch(setModal('ManagePagesToMarket', {}));
    };

    const onSaveChangesClick = () => {
        // If there is an error reopen the goals details tab as the error will be there
        if (!formValidator()) {
            return;
        }

        setSaveChangesButtonLoading(true);
        setSaveChangesButtonDisabled(true);
        setCloseButtonDisabled(true);

        saveRegexPattern()
            .then(() => {
                dispatch(
                    addNotification({
                        copy: 'Regex Pattern added successfully.',
                        type: NotificationMessageType.Success,
                    })
                );
                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
                handleNavigateManageModal();
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue adding Regex Pattern.',
                        type: NotificationMessageType.Error,
                    })
                );

                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
            });
    };

    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const renderAccordion = () => {
        const accordions = [
            {
                header: 'Map Pages to Markets',
                required: false,
                open: true,
                type: 'form',
                intro: '',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Regex:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: `A regex pattern to identify the pages to assign to a specific market. If you'd like to assign all pages to a specific market, please use (.*?).`,
                                inputKeyValue: 'regexText',
                                inputValue: regexText,
                                inputOnChange: handleRegexText,
                                errorMessage: errorMessageObject.regexTextErrorMessage,
                            },
                            {
                                label: 'Inverse:',
                                type: 'checkbox',
                                toolTipCopy:
                                    'Inverse is false as default. Set True for the inverse of the regex pattern.',
                                inputKeyValue: 'isInverseRegexPattern',
                                checked: isInverseRegexPattern,
                                inputOnChange: onCheckBoxClick,
                            },
                            {
                                label: 'Market:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: `The market you'd like to assign the paths identified.`,
                                inputKeyValue: 'market',
                                inputOptions: marketList,
                                inputOnChange: handleMarket,
                                errorMessage: errorMessageObject.marketErrorMessage,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Map Pages to Markets</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Map Pages to Markets</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Map Pages to Markets</h2>
            <p>Map your pages to Markets to support automated KWR</p>
            {renderAccordion()}
        </div>
    );
};

export default LayoutAddPagesToMarket;
