// config for areaspline charts: https://www.highcharts.com/docs/chart-and-series-types/areaspline-chart
export const areaSplineChartConfig = {
    options: {
        time: {
            useUTC: true,
        },
        title: {
            text: null,
        },
        chart: {
            animation: {
                duration: 500,
            },
            height: 335,
            events: {},
            backgroundColor: {
                linearGradient: [0, 160, 0, 720],
                stops: [
                    [0, 'transparent'],
                    [1, 'transparent'],
                ],
            },
            margin: [30, 30, 64, 64],
            style: {
                fontFamily: 'Work Sans',
            },
        },
        credits: {
            enabled: false,
        },
        yAxis: {
            title: {
                text: null,
            },
            labels: {
                enabled: true,
                style: {
                    color: '#4d4d4d',
                    fontSize: '0.7em',
                },
            },
        },
        xAxis: {
            gridLineWidth: 1,
            gridLineColor: '#E3E3FF',
            type: 'datetime',
            tickInterval: 24 * 60 * 60 * 1000,
            labels: {
                enabled: true,
                style: {
                    color: '#4d4d4d',
                    fontSize: '0.7em',
                },
            },
            lineColor: '#E3E3FF',
            tickColor: '#E3E3FF',
        },
        legend: {
            enabled: false,
        },
        seriesConfig: {
            lineWidth: 1,
            animate: true,
            type: 'areaspline',
            dataLabels: {
                enabled: false,
            },
            marker: {
                enabled: false,
            },
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [0, 'rgba(255,0,0,0.5)'],
                    [1, 'rgba(255,0,0,0)'],
                ],
            },
        },
        plotOptions: {
            series: {
                connectNulls: true,
            },
        },
    },
};
