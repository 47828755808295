import React from 'react';
import styled from 'styled-components';

import InputSearch from '../../../inputs/input-search';

const StyledSearchContainer = styled.div`
    margin-bottom: 18px;
`;

type TableSearchProps = {
    searchValue: string;
    onTableSearch: (searchValue: string) => void;
    isDisabled?: boolean;
};

const TableSearch = ({ searchValue, onTableSearch, isDisabled }: TableSearchProps) => {
    const handleOnSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onTableSearch(event.target.value);
    };

    return (
        <StyledSearchContainer>
            <InputSearch value={searchValue} onChange={handleOnSearchValueChange} disabled={isDisabled} />
        </StyledSearchContainer>
    );
};

export default TableSearch;
