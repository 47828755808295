import { CubedResource } from '../../../types';
import { resourceObjects } from './get-dimensions';

const getAvailableResources = ({
    dimension,
    currentMetrics,
}: {
    dimension?: string;
    currentMetrics?: string[];
}): CubedResource[] => {
    const resources = resourceObjects();

    // Filter the resources to only include those that have the selected dimension
    let availableResources = dimension
        ? resources.filter(res => {
              let found = false;
              if ('dimensions' in res[1] && res[1].dimensions) {
                  res[1].dimensions.forEach(dim => {
                      if (dim.id === dimension) {
                          found = true;
                      }
                  });
              }
              return found;
          })
        : resources;

    // Further filter the resources to only include those that contain the selected metrics
    if (currentMetrics && currentMetrics.length > 0) {
        availableResources = availableResources.filter(res => {
            let found = false;
            if ('metrics' in res[1] && res[1].metrics) {
                res[1].metrics.forEach(metric => {
                    if (currentMetrics.includes(metric.id)) {
                        found = true;
                    }
                });
            }
            return found;
        });
    }

    // Sort the resources by the number of dimensions
    const resourcesSorted = availableResources
        .map(resource => resource[1] as CubedResource)
        .map(resource => {
            return {
                ...resource,
                dimensionCount: resource.dimensions ? resource.dimensions.length : 0,
            };
        })
        .sort((a, b) => a.dimensionCount - b.dimensionCount) as CubedResource[];

    return resourcesSorted;
};

export default getAvailableResources;
