import React, { useContext } from 'react';
import styled from 'styled-components';
import Form from '../../../../components/forms/form';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import IconPlus from '../../../../components/icons/plus';
import FormDashboardLibraryDashboardSearch from '../forms/form-dashboard-library-dashboard-search';
import { DashboardLibraryContext } from '../context/dashboard-library-context';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${props => props.theme.dashboardBuilder.container.backgroundColor};
    ${props => props.theme.boxShadow(1)}
    padding:8px 16px;
    border-radius: 6px;
`;

const StyledCreateButton = styled.button<{ disabled: boolean }>`
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${props =>
        props.disabled ? props.theme.buttons.secondary.colorDisabled : props.theme.buttons.secondary.color};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')} !important;
`;

const StyledIconPlus = styled(IconPlus)`
    width: 12px;
`;

const LayoutDashboardLibraryTopBar = () => {
    const navigate = useNavigate();
    const accountToken = useSelector((state: RootState) => state.account.token);

    const { widgetQuery } = useContext(DashboardLibraryContext);

    return (
        <StyledContainer>
            <StyledCreateButton
                onClick={() => navigate(`/${accountToken}/dashboard-builder/create-a-dashboard`)}
                disabled={widgetQuery.data ? widgetQuery.data.objects.length === 0 : false}
            >
                <StyledIconPlus />
                New Dashboard
            </StyledCreateButton>
            <Form onSubmit={() => {}}>
                <FormDashboardLibraryDashboardSearch />
            </Form>
        </StyledContainer>
    );
};

export default LayoutDashboardLibraryTopBar;
