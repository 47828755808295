import React from 'react';
import styled from 'styled-components';

// Components
import SimpleTableTotalsRow from '../rows/simple-table-totals-row';
import SimpleTableBodyRow from '../rows/simple-table-body-row';

// Types
import { SimpleTableRow } from '../../types';

// Styles
const StyledSimpleTableBody = styled.tbody<{ isScrollable: boolean; hasPills: boolean }>`
    display: block;
    overflow-y: ${props => (props.isScrollable ? 'scroll' : 'auto')};
    overflow-x: hidden;
    min-height: ${props => props.hasPills && '650px'};
    height: ${props => props.hasPills && `calc(100vh - 250px)`};
    max-height: ${props => (props.hasPills ? 'initial' : props.isScrollable ? '250px' : '100%')};
    ${props => props.theme.scrollbar(8, 8)}
`;

// Types
type SimpleTableBodyProps = {
    id?: string;
    rowsState: SimpleTableRow[];
    handleOnTableDrop: (event: React.DragEvent<HTMLDivElement>) => void;
    handleOnTableDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
    isScrollable: boolean;
    hasPills?: boolean;
    hasTotals?: boolean;
    totalSales: number | string;
    totalRevenue: number | string;
    hasIcons?: boolean;
    selectableRows?: boolean;
    disableOrdering?: boolean;
    selectDisabled?: boolean;
    allowOrdering?: boolean;
    hasEndAction?: boolean;
    onRowClick?: (event: React.MouseEvent<HTMLTableRowElement>, row: SimpleTableRow) => void;
    reorderRows: (oldIndex: number, newIndex: number) => void;
};

const SimpleTableBody = ({
    id,
    rowsState,
    handleOnTableDrop,
    handleOnTableDragOver,
    isScrollable,
    hasPills,
    hasTotals,
    totalSales,
    totalRevenue,
    hasIcons,
    selectableRows,
    disableOrdering,
    selectDisabled,
    allowOrdering,
    hasEndAction,
    onRowClick,
    reorderRows,
}: SimpleTableBodyProps) => {
    return (
        <StyledSimpleTableBody
            onDrop={handleOnTableDrop}
            onDragOver={handleOnTableDragOver}
            isScrollable={isScrollable}
            hasPills={hasPills!}
        >
            {hasTotals && (
                <SimpleTableTotalsRow hasPills={hasPills} totalSales={totalSales} totalRevenue={totalRevenue} />
            )}

            {rowsState.map((row: SimpleTableRow, index: number) => {
                return (
                    <SimpleTableBodyRow
                        key={`row.keyValue${index}` || `row.key${index}`}
                        id={id}
                        row={row}
                        index={index}
                        hasIcons={hasIcons}
                        selectableRows={selectableRows}
                        disableOrdering={disableOrdering}
                        selectDisabled={selectDisabled}
                        allowOrdering={allowOrdering}
                        hasEndAction={hasEndAction}
                        hasPills={hasPills}
                        onRowClick={onRowClick}
                        reorderRows={reorderRows}
                    />
                );
            })}
        </StyledSimpleTableBody>
    );
};

export default SimpleTableBody;
