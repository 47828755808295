// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import parse from 'html-react-parser';
import Papa from 'papaparse';
import Axios from 'axios';
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { isString } from '../../helpers/validator';

// Redux Actions
import { removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import ProgressBar from '../../components/progress-bar';
import moment from 'moment';
import styled from 'styled-components';
import { ButtonThemes } from '../../enums/button-themes';

const StyledDiv = styled.div`
    ul li {
        list-style: disc;
        margin-left: 40px;
    }
`;

class LayoutModalReplayOfflineSales extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageError: false,
            selectedFile: null,
            selectedFileIsValid: false,
            accordionVisible: true,
            accordionOpen: true,
            uploadButtonDisabled: true,
            closeButtonDisabled: false,
            backButtonDisabled: false,
            backButtonHidden: true,
            showUploadProgress: false,
            uploadProgress: null,
            uploadId: null,
            fileUploadId: null,
            processId: null,
            returningMessage: null,
            fileInputErrorMessage: null,
            uploadError: null,
            showLoading: false,
            saleEventProductEventOptions: [],
            saleEvent: '',
            pageURL: '',
            currencyOptions: [],
            currency: '',
            saleEventErrorMessage: '',
            currencyErrorMessage: '',
            pageURLErrorMessage: '',
        };

        this.onBackClick = this.onBackClick.bind(this);

        this.fileHeaders = [
            'currency',
            'customer_id',
            'event_date_time',
            'event_item_sku',
            'event_name',
            'event_revenue',
            'page_url',
            'referrer',
            'sync_id',
            'transaction_id',
        ];
    }

    componentDidMount() {
        // check if there is already an upload in progress for this account
        Axios({
            method: 'GET',
            url: generateUrl('config', 'file-upload', [
                { key: 'account_id', value: this.props.account.id },
                { key: 'processing', value: true },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length > 0) {
                    this.setState({
                        showUploadProgress: true,
                        backButtonDisabled: true,
                        uploadProgress: response.data.objects[0].progress,
                        fileUploadId: response.data.objects[0].id,
                        processId: response.data.objects[0].process_id,
                        returningMessage: 'There is currently a file upload in progress for this account.',
                    });
                    this.fetchUploadProgress();
                }
            })
            .catch(() => {
                this.setState({
                    pageError: true,
                });
            });

        // set up product and industry request
        const requestConfig = [
            {
                resourceGroup: 'config',
                resourceName: 'lite-event',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
            {
                resourceGroup: 'config',
                resourceName: 'currency',
                params: [
                    {
                        key: 'account_id',
                        value: this.props.account.id,
                    },
                    {
                        key: 'limit',
                        value: 0,
                    },
                ],
            },
        ];
        this.props.getRequest(requestConfig);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fileUploadId !== this.state.fileUploadId) {
            this.fetchUploadProgress();
        }
        if (this.state.isLoading && !this.props.request.isLoading) {
            if (!this.props.request.hasError) {
                const saleEvents = this.props.request.data[0]?.objects.map(event => {
                    return {
                        id: event.id,
                        display_name: event.display_name,
                        name: event.name,
                    };
                });

                const currency = this.props.request.data[1]?.objects.map(curr => {
                    return {
                        id: curr.id,
                        value: curr.id,
                        name: curr.iso,
                    };
                });

                this.setState({
                    currencyOptions: currency,
                    saleEventProductEventOptions: saleEvents,
                    isLoading: false,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }
        }
    }

    generateCSVFormValidator = () => {
        this.setState({
            saleEvent: '',
            currency: '',
            pageURL: '',
        });

        let hasFormError = false;
        let errorMessageObject = {
            saleEventErrorMessage: '',
            currencyErrorMessage: '',
            pageURLErrorMessage: '',
        };

        if (!isString(this.state.saleEvent) || this.state.saleEvent.length === 0) {
            hasFormError = true;
            errorMessageObject.saleEventErrorMessage = 'Please enter a Sale Event.';
        }

        if (!isString(this.state.pageURL) || this.state.pageURL.length === 0) {
            hasFormError = true;
            errorMessageObject.pageURLErrorMessage = 'Please enter a page URL.';
        }

        if (!isString(this.state.currency) || this.state.currency.length === 0) {
            hasFormError = true;
            errorMessageObject.currencyErrorMessage = 'Please select a currency.';
        }

        if (hasFormError) {
            this.setState(errorMessageObject);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    onCloseClick = () => {
        this.props.removeModal();
    };

    onBackClick = () => {
        this.setState({
            uploadError: false,
            backButtonHidden: true,
            uploadButtonDisabled: false,
            showUploadProgress: false,
            selectedFile: null,
            saleEventErrorMessage: '',
            pageURLErrorMessage: '',
            currencyErrorMessage: '',
            fileInputErrorMessage: '',
            returningMessage: '',
        });
    };

    onFileInputChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();
        let firstRowData;
        let dataCsv;

        if (file?.name.split('.').at(-1) === 'csv' || 'xlsx' || 'xls') {
            this.setState({
                uploadButtonDisabled: false,
            });

            try {
                reader.readAsText(file);
                reader.onload = event => {
                    const csvData = event.target.result;

                    // read file headers with Papaparse
                    Papa.parse(csvData, {
                        skipEmptyLines: true,
                        header: true,
                        complete: result => {
                            firstRowData = result.data[0];
                            dataCsv = result.data;
                        },
                    });

                    if (firstRowData === undefined) {
                        this.setState({
                            selectedFileIsValid: false,
                            selectedFile: null,
                            fileInputErrorMessage:
                                "Sorry, this file doesn't appear to have data. Please check it and try again.",
                        });
                    } else {
                        // validate the file
                        if (this.validateHeadersCSVFile(firstRowData)) {
                            this.setState({
                                selectedFileIsValid: true,
                                selectedFile: file,
                                fileInputErrorMessage: null,
                            });
                            this.validateDataCSVFile(firstRowData, dataCsv);
                        } else {
                            this.setState({
                                selectedFileIsValid: false,
                                selectedFile: null,
                                fileInputErrorMessage:
                                    "Sorry, this file doesn't appear to be in the correct format. Please check it and try again.",
                            });
                        }
                    }
                };
                reader.onerror = () => {
                    this.setState({
                        selectedFileIsValid: false,
                        selectedFile: null,
                        fileInputErrorMessage:
                            'Sorry, there was a problem reading this file. Please check it and try again.',
                    });
                };
            } catch (error) {
                this.setState({
                    selectedFileIsValid: false,
                    selectedFile: null,
                    fileInputErrorMessage:
                        'Sorry, there was a problem reading this file. Please check it and try again.',
                });
            }
        } else {
            this.setState({
                fileInputErrorMessage: 'Please select appropriate file format to upload.',
            });
        }
    };

    onSaleEventChange = event => {
        this.setState({
            saleEvent: event.target.value,
        });
    };
    onPageUrlChange = event => {
        this.setState({
            pageURL: event.target.value,
        });
    };
    onCurrencyChange = event => {
        this.setState({
            currency: event.target.value,
        });
    };

    uploadCSVFile = file => {
        const data = new FormData();
        data.append('upload_type', 'replay_csv');
        data.append('file', file);
        data.append('account_id', this.props.account.id);
        Axios({
            method: 'POST',
            url: generateUrlDetail(this.props.account.token, 'upload', 'csv-importer', [], false),
            data: data,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        uploadId: response.data.upload_id,
                        showUploadProgress: true,
                        backButtonDisabled: true,
                        processId: response.data.pid,
                        fileUploadId: response.data.file_upload_id,
                        uploadProgress: 0.0,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    showLoading: false,
                    uploadError: true,
                });
            });
    };

    validateHeadersCSVFile = data => {
        // check for the correct columns in csv
        const fileHeaders = data;
        const fileHeadersSorted = Object.keys(fileHeaders)
            .sort()
            .map(header => {
                return header.toLowerCase();
            });
        return (
            this.fileHeaders.length === fileHeadersSorted.length &&
            this.fileHeaders.every((header, index) => {
                return header === fileHeadersSorted[index];
            })
        );
    };

    validateDataCSVFile = (headerData, data) => {
        // check for the correct columns in csv
        const csvData = data;
        const fileHeaders = headerData;
        const fileHeadersSorted = Object.keys(fileHeaders)
            .sort()
            .map(header => {
                return header;
            });

        let invalidCols = [];
        fileHeadersSorted.map(header => {
            let checkError = true;
            csvData.forEach(data => {
                if (header === 'event_date_time') {
                    if (data[header] === '') {
                        let isDate = moment(data[header], 'YYYY-MM-DD hh:mm:ss', true).isValid();
                        if (checkError) {
                            if (!isDate) {
                                invalidCols.push(header);
                                checkError = false;
                                return header;
                            }
                        }
                    }
                } else if (header === 'event_name') {
                    if (data[header] === '') {
                        if (checkError) {
                            invalidCols.push(header);
                            checkError = false;
                            return header;
                        }
                    }
                } else if (header === 'page_url') {
                    if (data[header] === '') {
                        if (checkError) {
                            invalidCols.push(header);
                            checkError = false;
                            return header;
                        }
                    }
                } else if (header === 'referrer') {
                    if (data[header] === '') {
                        if (checkError) {
                            invalidCols.push(header);
                            checkError = false;
                            return header;
                        }
                    }
                } else if (header === 'transaction_id') {
                    if (data[header].trim().length === 0 && data[header] === '') {
                        if (checkError) {
                            invalidCols.push(header);
                            checkError = false;
                            return header;
                        }
                    }
                }
            });
            return checkError;
        });

        if (invalidCols.length > 0) {
            this.setState({
                uploadButtonDisabled: true,
                selectedFileIsValid: false,
                selectedFile: null,
                backButtonDisabled: false,
                backButtonHidden: false,
                uploadError: true,
                fileInputErrorMessage:
                    '<b>Error(s) during upload:</b>' +
                    '<br>' +
                    'Following columns can not be empty. Please check all rows and re-upload.' +
                    invalidCols
                        .map(col => {
                            return `<li style='margin-left: 20px;'>${col}</li>`;
                        })
                        .join('') +
                    'Please check your CSV file and try to upload again. If you are still having issues please contact us at: support@cubed.email',
            });
        }
    };

    convertToCSV = jsonCSVTemplate => {
        var array = typeof jsonCSVTemplate !== 'object' ? JSON.parse(jsonCSVTemplate) : jsonCSVTemplate;
        const headers = Object.keys(array[0]);
        const csv = [headers.join(','), array.map(row => headers.map(key => row[key]).join(',')).join('\n')].join('\n');
        return csv;
    };

    onGenerateClick = () => {
        if (this.generateCSVFormValidator()) {
            const csvTemplate = [
                {
                    currency: this.state.currencyOptions.find(curr => curr.id === Number(this.state.currency))?.name,
                    customer_id: 'customer-124',
                    event_date_time: '2022-06-12 00:00:00',
                    event_item_sku: 'abcd123',
                    event_name: this.state.saleEvent,
                    event_revenue: '1234',
                    page_url: this.state.pageURL,
                    referrer: 'Offline',
                    sync_id: 'username@mail.com',
                    transaction_id: '123aabcd',
                },
            ];

            const downloadUrl = window.URL.createObjectURL(new Blob([this.convertToCSV(csvTemplate)]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'replay-csv-importer.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    };

    onUploadClick = () => {
        this.setState({
            showLoading: true,
        });
        this.uploadCSVFile(this.state.selectedFile);
    };

    fetchUploadProgress = () => {
        const getProgress = () => {
            if (this.state.uploadProgress !== '100.00' && !this.state.pageError) {
                Axios({
                    method: 'GET',
                    url: generateUrl('config', 'file-upload', [{ key: 'id', value: this.state.fileUploadId }]),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        if (response.data.objects[0].error) {
                            this.setState({
                                showLoading: false,
                                uploadError: true,
                            });
                        } else {
                            this.setState({
                                showLoading: false,
                                uploadProgress: response.data.objects[0].progress,
                            });
                        }
                    })
                    .catch(() => {
                        this.setState({
                            showLoading: false,
                            pageError: true,
                        });
                    });
            } else {
                this.setState({
                    backButtonDisabled: false,
                    backButtonHidden: false,
                });
            }

            if (this.state.uploadProgress !== '100.00' && !this.state.uploadError) {
                setTimeout(getProgress, 2000);
            } else {
                this.setState({
                    returningMessage: null,
                });
            }
        };

        if (this.state.uploadProgress !== '100.00') {
            getProgress();
        }
    };

    renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme: ButtonThemes.Secondary,
            },
            {
                value: 'Upload New File',
                onClick: this.onBackClick,
                disabled: this.state.backButtonDisabled,
                hidden: this.state.backButtonHidden,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    renderUploadAccordion = () => {
        const accordion = [
            {
                header: 'Generate CSV Template',
                required: false,
                open: this.state.accordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Sale Event',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a sale event.',
                                inputKeyValue: 'saleEvent',
                                inputOptions: this.state.saleEventProductEventOptions,
                                inputValue: this.state.saleEvent,
                                inputOnChange: this.onSaleEventChange,
                                errorMessage: this.state.saleEventErrorMessage,
                            },
                            {
                                label: 'Page URL',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Add Page URL.',
                                inputPlaceholder: 'https://www.client1.com/offline-sales',
                                inputKeyValue: 'pageURL',
                                inputValue: this.state.pageURL,
                                inputOnChange: this.onPageUrlChange,
                                errorMessage: this.state.pageURLErrorMessage,
                            },
                            {
                                label: 'Currency',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a Currency.',
                                inputKeyValue: 'currency',
                                inputOptions: this.state.currencyOptions,
                                inputValue: this.state.currency,
                                inputOnChange: this.onCurrencyChange,
                                errorMessage: this.state.currencyErrorMessage,
                            },
                        ],
                        columns: 1,
                        buttons: [
                            {
                                value: 'Generate CSV Template',
                                onClick: this.onGenerateClick,
                            },
                        ],
                    },
                },
            },
            {
                header: 'Import CSV',
                required: false,
                open: this.state.accordionOpen,
                type: 'form',
                intro: `<p>
                        <b>The following fields/columns are required for a successful import:</b>
                        <ul>
                            <li>event_date_time</li>
                            <li>event_name</li>
                            <li>page_url</li>
                            <li>referrer</li>
                            <li>transaction_id</li>
                        </ul>
                    </p>
                    <p>
                        <b>The following fields/columns are optional:</b>
                        <ul>
                            <li>currency</li>
                            <li>customer_id</li>
                            <li>event_item_sku</li>
                            <li>event_revenue</li>
                            <li>sync_id</li>
                        </ul>
                    </p>`,
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Upload CSV File:',
                                type: 'file-upload',
                                requiredField: true,
                                inputKeyValue: 'import_replay-csv',
                                inputOnChange: this.onFileInputChange,
                                isValid: this.state.selectedFileIsValid,
                                uploadedFile: this.state.selectedFile?.name,
                                // errorMessage: this.state.fileInputErrorMessage,
                            },
                        ],
                        columns: 1,
                        buttons: [
                            {
                                value: 'UPLOAD',
                                onClick: this.onUploadClick,
                                disabled: this.state.uploadButtonDisabled,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordion} />;
    };

    render() {
        if (this.state.isLoading || this.state.showLoading) {
            return (
                <div>
                    <this.renderModalNavigation />
                    <h2>Replay Offline Sales</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div>
                    <this.renderModalNavigation />
                    <h2>Replay Offline Sales</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        if (this.state.uploadError) {
            return (
                <div>
                    <this.renderModalNavigation />
                    <h2>Replay Offline Sales</h2>
                    <WarningMessage
                        copy={
                            parse(this.state.fileInputErrorMessage) ||
                            'There was a problem with your file upload.' +
                                ' If you are still having an issue with the uploader please contact us at: support@cubed.email'
                        }
                    />
                </div>
            );
        }

        if (this.state.showUploadProgress) {
            const progressCopy = `File upload ${
                this.state.uploadProgress === '100.00' ? 'complete!' : 'in progress...'
            }`;
            return (
                <div>
                    <this.renderModalNavigation />
                    <h2>Replay Offline Sales</h2>
                    <div className="file-upload-progress">
                        {this.state.returningMessage && <p>{this.state.returningMessage}</p>}
                        <p>{progressCopy}</p>
                        <ProgressBar
                            value={parseFloat(this.state.uploadProgress) / 100}
                            difference={100}
                            label="Upload Progress"
                        />
                    </div>
                </div>
            );
        }

        return (
            <StyledDiv>
                <this.renderModalNavigation />
                <h2>Replay Offline Sales</h2>
                <p>
                    Import offline sales data by filling out the template guide we’ve provided.{' '}
                    <b>
                        In order to generate a template you must: Select a Cubed Goal, Add a mock Page URL, Currency
                        Code.
                    </b>
                </p>
                <p>
                    The <b>Cubed Goal</b> defines an end point to a visitor’s journey and allows us to map the offline
                    data with our online data. The <b>Currency code</b> gives us the correct value for the revenue you
                    will add. The mock <b>Page URL</b> is for out internal system, so when we insert the offline sales
                    events, we can assign them to a page internally which is used by our{' '}
                    <b>Modelling and Attribution</b> calculations.
                </p>
                <this.renderUploadAccordion />
            </StyledDiv>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        request: state.request,
        meta: state.meta,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalReplayOfflineSales);
