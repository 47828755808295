import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType } from './common/chart-types';
import dateStringToUnix from '../helpers/date-string-to-unix';

export const config = {
    pageTitle: 'PageSpeed Dashboard',
    reportDescription:
        'Improve and measure your web pages performance, accessibility, and SEO. This report updates every Sunday.',
    dateDimension: fields.UPDATED_DATE,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: true,
        },
    },
    resources: [resources.REPORT_PAGE_SPEED],
    ignoreProducts: true,
    chart: {
        chartType: ChartType.Line,
        allowedChartTypes: [],
        chartId: 'report-page-speed',
        allYMetrics: [
            fields.VISITS,
            fields.OVERALL_SCORE,
            fields.FCP,
            fields.FID,
            fields.LCP,
            fields.CLS,
            fields.INP,
            fields.TIME_INTERACTIVE,
            fields.BLOCKING_TIME_DURATION,
            fields.TOTAL_TASKS,
            fields.TOTAL_TASKS_TIME,
            fields.LONG_TASKS,
            fields.UNUSED_JS_SCORE,
            fields.PAGE_SIZE,
            fields.CACHED_PAGE_SIZE,
        ],
        dataFormatter: function (_, datas, dimension, metric) {
            return datas.map(data => {
                return [dateStringToUnix(data[dimension.rawName].raw_value), data[metric].raw_value];
            });
        },
        initialYMetrics: [fields.OVERALL_SCORE],
    },
    table: {
        tabListItems: [
            {
                tabName: 'Core Web Vitals',
                displayName: 'Core Web Vitals',
                isSelected: true,
                metrics: [
                    fields.VISITS,
                    fields.OVERALL_SCORE,
                    fields.FCP,
                    fields.FID,
                    fields.INP,
                    fields.LCP,
                    fields.CLS,
                    fields.TIME_INTERACTIVE,
                    fields.BLOCKING_TIME_DURATION,
                ],
            },
            {
                tabName: 'Long Tasks',
                displayName: 'Long Tasks',
                isSelected: true,
                metrics: [fields.TOTAL_TASKS, fields.TOTAL_TASKS_TIME, fields.LONG_TASKS],
            },
            {
                tabName: 'Unused JS',
                displayName: 'Unused JS',
                isSelected: true,
                metrics: [fields.UNUSED_JS_SCORE, fields.UNUSED_JS_SAVINGS],
            },
            {
                tabName: 'Page Size',
                displayName: 'Page Size',
                isSelected: true,
                metrics: [fields.PAGE_SIZE, fields.CACHED_PAGE_SIZE],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.PATH_HOST.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_PAGE_SPEED,
                        field: fields.PATH_PATH,
                    },
                ],
            },
            [fields.PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_SPEED,
                        field: fields.PATH_GEOGRAPHY,
                    },
                ],
            },
            [fields.PATH_GEOGRAPHY.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_SPEED,
                        field: fields.PATH_LANGUAGE,
                    },
                ],
            },
            [fields.PATH_MARKET.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_SPEED,
                        field: fields.PATH_LANGUAGE,
                    },
                ],
            },
            [fields.PATH_LANGUAGE.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_SPEED,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_CONTENT_TYPE.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_SPEED,
                        field: fields.PATH_CONTENT_LENGTH,
                    },
                ],
            },
            [fields.PATH_CATEGORY.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_SPEED,
                        field: fields.PATH_CONTENT_TYPE,
                    },
                ],
            },
            [fields.PATH_CONTENT_LENGTH.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_SPEED,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_TOPIC.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_SPEED,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
