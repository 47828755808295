import { BigNumberAggregation } from '../types';

export const aggregateBigNumber = (
    values: number[],
    aggregation: BigNumberAggregation,
    aggregationSumValues?: number[]
) => {
    switch (aggregation) {
        case 'avg':
            if (values.length === 0) {
                return 0;
            }
            return values.reduce((a, b) => a + b, 0) / values.length;
        case 'min':
            return Math.min(...values);
        case 'max':
            return Math.max(...values);
        case 'sum':
            return values.reduce((a, b) => a + b, 0);
        case 'ratio':
        case 'mix':
            if (values.length === 0 || (aggregationSumValues && aggregationSumValues.length === 0)) {
                return 0;
            }
            const totalValue = values.reduce((a, b) => a + b, 0);
            const totalMixValue = aggregationSumValues ? aggregationSumValues.reduce((a, b) => a + b, 0) : 0;
            return (totalValue / totalMixValue) * 100;
        case 'difference':
            if (values.length === 0 || (aggregationSumValues && aggregationSumValues.length === 0)) {
                return 0;
            }
            return (
                values.reduce((a, b) => a + b, 0) -
                (aggregationSumValues ? aggregationSumValues.reduce((a, b) => a + b, 0) : 0)
            );
        default:
            throw new Error(`Unknown aggregation type ${aggregation}`);
    }
};
