import React from 'react';
import Form from '../../../../components/forms/form';
import validators from '../../../../components/forms/validation/validators';
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import {
    CONFIG_BRAND_SPLIT_CATEGORY,
    CONFIG_BRAND_SPLIT_INNOVATION,
    CONFIG_BRAND_SPLIT_PRICE,
    CONFIG_BRAND_SPLIT_PURCHASED,
    CONFIG_BRAND_SPLIT_SECTOR,
    CONFIG_BRAND_SPLIT_SIZE,
} from '../../../../configurations/resources-config';
import { ConfigDataSuccess } from '../../../../react-query/types';

const FormAddBrandSplitConfig = () => {
    const sectorQuery = useFetchResource({
        resource: CONFIG_BRAND_SPLIT_SECTOR,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.sector,
                    value: object.id,
                };
            });
        },
    });

    const purchasedQuery = useFetchResource({
        resource: CONFIG_BRAND_SPLIT_PURCHASED,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.purchased,
                    value: object.id,
                };
            });
        },
    });

    const priceQuery = useFetchResource({
        resource: CONFIG_BRAND_SPLIT_PRICE,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.price,
                    value: object.id,
                };
            });
        },
    });

    const innovationQuery = useFetchResource({
        resource: CONFIG_BRAND_SPLIT_INNOVATION,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.innovation,
                    value: object.id,
                };
            });
        },
    });

    const categoryQuery = useFetchResource({
        resource: CONFIG_BRAND_SPLIT_CATEGORY,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.category,
                    value: object.id,
                };
            });
        },
    });

    const sizeQuery = useFetchResource({
        resource: CONFIG_BRAND_SPLIT_SIZE,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.size,
                    value: object.id,
                };
            });
        },
    });

    const isLoading =
        sectorQuery.isFetching ||
        purchasedQuery.isFetching ||
        priceQuery.isFetching ||
        innovationQuery.isFetching ||
        categoryQuery.isFetching ||
        sizeQuery.isFetching;

    return (
        <Form.Body>
            <Form.Section>
                <Form.Field>
                    <Form.InputLabel htmlFor="sector" label="Sector" required={true} />
                    <Form.InputSelect
                        name="sector"
                        options={sectorQuery.data || []}
                        validators={[validators.required]}
                        disabled={isLoading}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="purchased" label="Purchased" required={true} />
                    <Form.InputSelect
                        name="purchased"
                        options={purchasedQuery.data || []}
                        validators={[validators.required]}
                        disabled={isLoading}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="price" label="Price" required={true} />
                    <Form.InputSelect
                        name="price"
                        options={priceQuery.data || []}
                        validators={[validators.required]}
                        disabled={isLoading}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="innovation" label="Innovation" required={true} />
                    <Form.InputSelect
                        name="innovation"
                        options={innovationQuery.data || []}
                        validators={[validators.required]}
                        disabled={isLoading}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="category" label="Category" required={true} />
                    <Form.InputSelect
                        name="category"
                        options={categoryQuery.data || []}
                        validators={[validators.required]}
                        disabled={isLoading}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="size" label="Size" required={true} />
                    <Form.InputSelect
                        name="size"
                        options={sizeQuery.data || []}
                        validators={[validators.required]}
                        disabled={isLoading}
                    />
                </Form.Field>
            </Form.Section>
        </Form.Body>
    );
};

export default FormAddBrandSplitConfig;
