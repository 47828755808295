import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generateUrl, generatePath, generateUrlDetail } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isAlphaNumericString, isNumber, isDomain } from '../../helpers/validator';
import { MarketResponse, LanguageResponse } from '../types';
import { DropdownOption } from '../../types';

type Topic = {
    id: number;
    name: string;
    status: number;
    topic: boolean;
    updated: string;
};

type TopicList = {
    id: number;
    name: string;
    status: number;
    topic: boolean;
    updated: string;
};

type KWRConfig = {
    id: number;
    brandName: string;
    domainName: string;
    marketId: number;
    languageId: number;
    searchVolumeThreshold: number;
    impressionThreshold: number;
    kwrTopicConfigId: number;
};

type TopicResponse = {
    active: boolean;
    created: string;
    id: number;
    name: string;
    resource_uri: string;
    status: number;
    topic: boolean;
    updated: string;
};

export type KWRConfigResponse = {
    active: boolean;
    brand_name: string;
    created: string;
    domain: string;
    gtrends_related_n: number;
    id: number;
    kwr_topic_config: TopicResponse;
    language: LanguageResponse;
    market: MarketResponse;
    reduction_impressions: number;
    reduction_volume: number;
    relevance_score: number;
    resource_uri: string;
    updated: string;
};

export type PathFilterParam = {
    key: string;
    value: string | number;
};

export type PathLoadOptions = {
    value: number; // id
    label: string; // path
};

export type PathResponse = {
    id: number;
    path: string;
};

type KWRMappedPathResponseData = {
    kwr_topic_config: KWRConfigResponse;
    id: number;
    path: PathResponse;
};

type KWRMappedPath = {
    kwrTopiConfigId: number;
    mappedPathId: number;
    pathId: number;
    pathName: string;
};

const LayoutAutomatedKWRConfig = () => {
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);
    const [marketList, setMarketList] = useState<DropdownOption[]>([]);
    const [languageList, setLanguageList] = useState<DropdownOption[]>([]);
    const [selectedMarket, setSelectedMarket] = useState<DropdownOption[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<DropdownOption[]>([]);
    const [selectedConfig, setSelectedConfig] = useState<TopicList[]>([]);
    const [updatedConfig, setUpdatedConfig] = useState<TopicList[]>([]);
    const [deletedConfig, setDeletedConfig] = useState<TopicList[]>([]);
    const [editSearchVolumeThreshold, setEditSearchVolumeThreshold] = useState('');
    const [editImpressionThreshold, setEditImpressionThreshold] = useState('');
    const [editTopicText, setEditTopicText] = useState('');
    const [editBrandName, setEditBrandName] = useState('');
    const [editDomainName, setEditDomainName] = useState('');

    const [topicList, setTopicList] = useState<TopicList[]>([]);
    const [kwrConfigs, setKWRConfigs] = useState<KWRConfig[]>([]);
    const [kwrMappedPath, setKwrMappedPath] = useState<KWRMappedPath[]>([]);

    const [pathForReactSelect, setPathForReactSelect] = useState<PathLoadOptions[]>([]);
    const [selectedDetailedMappedPath, setSelectedDetailedMappedPath] = useState<KWRMappedPath[]>([]);

    const [closeButtonState, setCloseButtonState] = useState('close');
    const [disableKWRConfigSelect, setDisableKWRConfigSelect] = useState(false);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState(true);
    const [topicSpecificChecked, setTopicSpecificChecked] = useState(false);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState(true);
    const [addNewButtonDisabled, setAddNewButtonDisabled] = useState(false);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
    const [editKWRConfigAccordionOpen, setEditKWRConfigAccordionOpen] = useState(false);
    const [editKWRAccordionVisible, setEditKWRConfigAccordionVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [pageError, setPageError] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [removeButtonState, setRemoveButtonState] = useState(true);

    const [errorMessageObject, setErrorMessageObject] = useState({
        topicTextErrorMessage: '',
        brandNameErrorMessage: '',
        domainNameErrorMessage: '',
        searchVolumeThresholdErrorMessage: '',
        impressionThresholdErrorMessage: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            topicTextErrorMessage: '',
            brandNameErrorMessage: '',
            domainNameErrorMessage: '',
            searchVolumeThresholdErrorMessage: '',
            impressionThresholdErrorMessage: '',
        };

        if (topicSpecificChecked) {
            if (!isAlphaNumericString(editTopicText) || editTopicText.length === 0) {
                hasFormError = true;
                errorMessageObj.topicTextErrorMessage = 'Please enter a valid Topic Name.';
            }
        }

        if (!isAlphaNumericString(editBrandName) || editBrandName.length === 0) {
            hasFormError = true;
            errorMessageObj.brandNameErrorMessage = 'Please enter a valid Brand Name.';
        }

        if (!isDomain(editDomainName) || editDomainName.length === 0) {
            hasFormError = true;
            errorMessageObj.domainNameErrorMessage = 'Please enter a valid Domain Name.';
        }

        if (!isNumber(editSearchVolumeThreshold) || Number(editSearchVolumeThreshold) === 0) {
            hasFormError = true;
            errorMessageObj.searchVolumeThresholdErrorMessage = 'Please enter a valid Search Volume Threshold Value.';
        }

        if (!isNumber(editImpressionThreshold) || Number(editImpressionThreshold) === 0) {
            hasFormError = true;
            errorMessageObj.impressionThresholdErrorMessage = 'Please enter a valid Impression Threshold Value.';
        }

        if (hasFormError) {
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const fetchData = async () => {
        await Promise.all([fetchKWRConfig(), fetchMarketList(), fetchLanguageList(), fetchMappedPaths()]).then(() => {
            setIsLoading(false);
        });
    };

    const fetchKWRConfig = async () => {
        await axios({
            method: 'GET',
            url: generateUrl('config', 'kwr-topic-config', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const allTopics = response.data.objects.map((data: TopicResponse) => {
                    return {
                        id: data.id,
                        name: data.name,
                        status: data.status,
                        topic: data.topic,
                        updated: data.updated,
                    };
                });
                setTopicList(allTopics);
            })
            .catch(error => {
                setPageError(true);
                errorHandling(error);
            });

        await axios({
            method: 'GET',
            url: generateUrl('config', 'kwr-config', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const allKWRConfigs = response.data.objects.map((config: KWRConfigResponse) => {
                    return {
                        id: config.id,
                        brandName: config.brand_name,
                        domainName: config.domain,
                        marketId: config.market.id,
                        languageId: config.language.id,
                        searchVolumeThreshold: config.reduction_volume,
                        impressionThreshold: config.reduction_impressions,
                        kwrTopicConfigId: config.kwr_topic_config.id,
                    };
                });

                setKWRConfigs(allKWRConfigs);
            })
            .catch(error => {
                setPageError(true);
                errorHandling(error);
            });
    };

    const fetchMarketList = async () => {
        await axios({
            method: 'GET',
            url: generateUrl('config', 'seogd-market', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
                { key: 'order_by', value: 'country' },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const allMarkets = response.data.objects.map((data: MarketResponse) => {
                    return {
                        label: data.alpha2,
                        value: data.id,
                        name: data.country,
                    };
                });
                setMarketList(allMarkets);
            })
            .catch(error => {
                setPageError(true);
                errorHandling(error);
            });
    };

    const fetchLanguageList = async () => {
        await axios({
            method: 'GET',
            url: generateUrl('config', 'attrib-language', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const languages = response.data.objects.map((data: LanguageResponse) => {
                    return {
                        label: data.language,
                        value: data.id,
                        name: data.language,
                    };
                });
                setLanguageList(languages);
            })
            .catch(error => {
                setPageError(true);
                errorHandling(error);
            });
    };

    const fetchMappedPaths = async () => {
        await axios({
            method: 'GET',
            url: generateUrl('config', 'kwr-topic-mapped-path', [{ key: 'limit', value: 0 }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const mappedPath = response.data.objects.map((data: KWRMappedPathResponseData) => {
                    return {
                        kwrTopiConfigId: data.kwr_topic_config.id,
                        mappedPathId: data.id,
                        pathId: data.path.id,
                        pathName: data.path.path,
                    };
                });
                setKwrMappedPath(mappedPath);
            })
            .catch(error => {
                setPageError(true);
                errorHandling(error);
            });
    };

    const handleTopic = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditTopicText(event?.target?.value);
    };

    const handleSelectMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
        let marketId = parseInt(event.target.value);
        let selectMarket = marketList.filter(market => market?.value === marketId);
        setSelectedMarket(selectMarket);
    };

    const handleSelectLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let languageId = parseInt(event.target.value);
        let selectLanguage = languageList.filter(lng => lng.value === languageId);
        setSelectedLanguage(selectLanguage);
    };

    const handleBrandName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditBrandName(event?.target?.value);
    };

    const handleDomainName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditDomainName(event?.target?.value);
    };

    const handleSearchVolume = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditSearchVolumeThreshold(event?.target?.value);
    };

    const handleImpressions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditImpressionThreshold(event?.target?.value);
    };

    const handlePaths = (path: PathLoadOptions[]) => {
        setPathForReactSelect(path);
    };

    const pathLoadOptions = async (search: string, prevOptions: PathLoadOptions[]) => {
        /**
         * This function is responsible for paginate and search inside the dropdown.
         */

        const filterParams: PathFilterParam[] = [
            { key: 'active', value: 1 },
            { key: 'deleted', value: 0 },
            { key: 'limit', value: 25 },
            { key: 'offset', value: prevOptions.length },
        ];

        if (search) {
            filterParams.push({ key: 'path__icontains', value: search.toLowerCase() });
        }
        const filterUrl = generateUrl('config', 'path', filterParams);

        const filteredOptions = await axios.get(filterUrl, { withCredentials: true });

        const options = filteredOptions.data.objects.map((item: PathResponse) => {
            return {
                value: item.id,
                label: item.path,
            };
        });

        const hasMore = filteredOptions.data.meta.next ? true : false;

        return {
            options: options,
            hasMore,
        };
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onDeleteKWRConfig = () => {
        setSaveChangesButtonDisabled(false);
        setCloseButtonState('cancel');
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);

        if (disableKWRConfigSelect === true) {
            return;
        }

        let tempRemovedConfigs = Array.prototype.concat(deletedConfig);
        let tempUpdatedConfigs = Array.prototype.concat(updatedConfig);

        selectedConfig.forEach(config => {
            if (removeButtonState === true) {
                tempRemovedConfigs.push(config);
            } else {
                tempRemovedConfigs.splice(tempRemovedConfigs.indexOf(config), 1);
            }

            // If a config was edited and then revoked in the same instance then the revoke should take priority
            const updatedConfigIndex = tempUpdatedConfigs.indexOf(config);

            if (updatedConfigIndex >= 0) {
                tempUpdatedConfigs.splice(updatedConfigIndex, 1);
            }
        });

        setDeletedConfig(tempRemovedConfigs);
        setUpdatedConfig(tempUpdatedConfigs);
        setSelectedConfig([]);
        setRemoveButtonState(true);
    };

    const onConfigSelect = (topic: Topic) => {
        if (disableKWRConfigSelect) {
            return;
        }

        const selectedConfigId = topic.id;
        let selectedConfigs = [];

        if (selectedConfig.filter(event => event.id === selectedConfigId).length > 0) {
            selectedConfigs = selectedConfig.filter(event => event.id !== selectedConfigId);
            setSelectedConfig(selectedConfigs);
            setEditButtonDisabled(selectedConfigs.length === 0);
            setRemoveButtonDisabled(selectedConfigs.length === 0);
            setAddNewButtonDisabled(selectedConfigs.length === 0);
        } else {
            selectedConfigs = selectedConfig.concat(topicList.filter(topic => topic.id === selectedConfigId));
        }

        // Check the status of the items that have been selected.
        let containsRevoked = false;
        let containsNonRevoked = false;
        let containsEdited = false;

        selectedConfigs.forEach(event => {
            if (deletedConfig.indexOf(event) >= 0) {
                containsRevoked = true;
            } else {
                containsNonRevoked = true;
            }

            if (updatedConfig.indexOf(event) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;
        let addButtonDisabled = false;

        if (selectedConfigs.length > 0) {
            editButtonDisabled = false;
            removeButtonDisabled = false;
            addButtonDisabled = true;
        }

        if (selectedConfigs.length > 1) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRevoked && !containsNonRevoked && !containsEdited) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRevoked) {
            editButtonDisabled = true;
        }

        if (containsRevoked && containsNonRevoked) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        setAddNewButtonDisabled(addButtonDisabled);
        setSelectedConfig(selectedConfigs);
        setEditButtonDisabled(editButtonDisabled);
        setRemoveButtonDisabled(removeButtonDisabled);
        setRemoveButtonState(removeButtonState);
        setUpdateButtonDisabled(true);
        setEditKWRConfigAccordionOpen(false);
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(removeModal());
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const handleAddNew = () => {
        dispatch(setModal('AddAutomatedKWRConfig', {}));
    };

    const onEditClick = () => {
        const getMappedPaths = kwrMappedPath.filter(mappedPath => mappedPath.kwrTopiConfigId === selectedConfig[0].id);
        const getKWRconfig = kwrConfigs.filter(con => con.kwrTopicConfigId === selectedConfig[0].id)[0];
        const getMarket = marketList.filter(market => market.value === getKWRconfig.marketId);
        const getLanguage = languageList.filter(lang => lang.value === getKWRconfig.languageId);

        setEditKWRConfigAccordionVisible(true);
        setEditKWRConfigAccordionOpen(true);
        setRemoveButtonDisabled(true);
        setUpdateButtonDisabled(false);
        setDisableKWRConfigSelect(true);
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setTopicSpecificChecked(selectedConfig[0].topic);
        setEditTopicText(selectedConfig[0].name);
        setEditBrandName(getKWRconfig.brandName);
        setEditDomainName(getKWRconfig.domainName);
        setSelectedMarket(getMarket);
        setSelectedLanguage(getLanguage);
        setSelectedDetailedMappedPath(getMappedPaths);
        setEditSearchVolumeThreshold(String(getKWRconfig.searchVolumeThreshold));
        setEditImpressionThreshold(String(getKWRconfig.impressionThreshold));
        const pathForReactSelect = getMappedPaths.map((item: KWRMappedPath) => {
            return {
                label: item.pathName,
                value: item.pathId,
            };
        });
        setPathForReactSelect(pathForReactSelect);
        setErrorMessageObject({
            topicTextErrorMessage: '',
            brandNameErrorMessage: '',
            domainNameErrorMessage: '',
            searchVolumeThresholdErrorMessage: '',
            impressionThresholdErrorMessage: '',
        });
    };

    const updateKWRConfig = () => {
        return new Promise(async (resolve, reject) => {
            let selectedMarketId = typeof selectedMarket === 'object' ? selectedMarket[0].value : selectedMarket;

            let selectedLanguageId =
                typeof selectedLanguage === 'object' ? selectedLanguage[0].value : selectedLanguage;

            const kwrTopicPayload = {
                topic: topicSpecificChecked,
                name: topicSpecificChecked ? editTopicText : '*',
            };

            try {
                const kwrTopicConfig: AxiosRequestConfig = {
                    method: 'PATCH',
                    url: generateUrlDetail('config', 'kwr-topic-config', String(selectedConfig[0].id))!,
                    data: kwrTopicPayload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const kwrTopicConfigRequest = await axios(kwrTopicConfig);
                if (kwrTopicConfigRequest.status !== 202) {
                    throw new Error('Status not 202');
                }
            } catch (error) {
                console.log(error);
                reject('Error making a connection to API.');
            }

            const kwrConfigPayload = {
                kwr_topic_config: generatePath('config', 'kwr-topic-config', String(selectedConfig[0].id)),
                market: generatePath('config', 'seogd-market', String(selectedMarketId)),
                language: generatePath('config', 'attrib-language', String(selectedLanguageId)),
                brand_name: editBrandName,
                domain: editDomainName,
                reduction_volume: editSearchVolumeThreshold,
                reduction_impressions: editImpressionThreshold,
                reduction_trend: 0,
                reduction_clicks: 0,
            };

            const getKWRconfigId = kwrConfigs.filter(con => con.kwrTopicConfigId === selectedConfig[0].id)[0];

            try {
                const kwrConfig: AxiosRequestConfig = {
                    method: 'PATCH',
                    url: generateUrlDetail('config', 'kwr-config', String(getKWRconfigId.id))!,
                    data: kwrConfigPayload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const kwrConfigRequest = await axios(kwrConfig);
                if (kwrConfigRequest.status !== 202) {
                    throw new Error('Status not 202');
                }
            } catch (error) {
                console.log(error);
                reject('Error making a connection to API.');
            }
            const createdMappedPath = pathForReactSelect.filter((selectedPath: PathLoadOptions) => {
                return !selectedDetailedMappedPath.find(previousPath => {
                    return previousPath.pathId === selectedPath.value;
                });
            });

            const deletedMappedPath = selectedDetailedMappedPath.filter((detailedSelectedPaths: KWRMappedPath) => {
                return !pathForReactSelect.find(selectedPath => {
                    return detailedSelectedPaths.pathId === selectedPath.value;
                });
            });

            const mappedPathPayloadForCreate = createdMappedPath.map(item => {
                return {
                    kwr_topic_config: `/api/config/kwr-topic-config/${selectedConfig[0].id}/`,
                    path: `/api/config/path/${item.value}/`,
                };
            });
            const mappedPathPayloadForDelete = deletedMappedPath.map(item => {
                return `/api/config/kwr-topic-mapped-path/${item.mappedPathId}/`;
            });
            const mappedPathPayload = {
                objects: mappedPathPayloadForCreate,
                deleted_objects: mappedPathPayloadForDelete,
            };

            try {
                const mappedPathConfig: AxiosRequestConfig = {
                    method: 'PATCH',
                    url: generateUrl('config', 'kwr-topic-mapped-path')!,
                    data: mappedPathPayload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const kwrTopicConfigRequest = await axios(mappedPathConfig);
                if (kwrTopicConfigRequest.status !== 202) {
                    throw new Error('Status not 202');
                }
            } catch (error) {
                console.log(error);
                reject('Error making a connection to API.');
            }
            resolve('KWR Config Updated');
        });
    };

    const handleNavigateMangeKWRConfigModal = () => {
        dispatch(removeModal());
    };

    const onUpdateClick = () => {
        if (!formValidator()) {
            return;
        }
        setIsUpdating(true);
        updateKWRConfig()
            .then(() => {
                dispatch(
                    addNotification({
                        copy: 'A KWR config was successfully updated.',
                        type: NotificationMessageType.Success,
                    })
                );
                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
                setIsUpdating(false);
                handleNavigateMangeKWRConfigModal();
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue updating a KWR config.',
                        type: NotificationMessageType.Error,
                    })
                );

                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
            });
    };

    const onSaveChangesClick = () => {
        setSaveChangesButtonLoading(true);
        const requests: AxiosPromise[] = [];

        if (deletedConfig.length > 0) {
            const deleteKWRConfig = {
                active: 0,
                deleted: 1,
            };
            let kwrConfig;
            let deletedMappedPath;

            deletedConfig.forEach(config => {
                kwrConfig = kwrConfigs.filter(con => con.kwrTopicConfigId === config.id)[0];
                deletedMappedPath = kwrMappedPath.filter(mappedPath => mappedPath.kwrTopiConfigId === config.id);
                deletedMappedPath.forEach((mappedPath: KWRMappedPath) => {
                    requests.push(
                        axios({
                            method: 'DELETE',
                            url: generateUrlDetail('config', 'kwr-topic-mapped-path', String(mappedPath.mappedPathId))!,
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                });
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'kwr-topic-config', String(config.id))!,
                        data: deleteKWRConfig,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'kwr-config', String(kwrConfig.id))!,
                        data: deleteKWRConfig,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
            console.log(requests);
        }

        axios
            .all(requests)
            .then(
                axios.spread((...res) => {
                    console.log(res);

                    dispatch(
                        addNotification({
                            copy: 'These changes have been successfully saved.',
                            type: NotificationMessageType.Success,
                        })
                    );
                    setSaveChangesButtonLoading(false);
                    dispatch(removeModal());
                })
            )
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue trying to save your changes. Please try again later or contact Cubed Support.',
                        type: NotificationMessageType.Error,
                    })
                );
                setSaveChangesButtonLoading(false);
            });
    };

    const onCheckBoxClick = (checkBoxName: React.ChangeEvent<HTMLInputElement>) => {
        let checked = String(checkBoxName) === 'editTopicSpecificValue' ? !topicSpecificChecked : topicSpecificChecked;
        setTopicSpecificChecked(checked);
        setEditTopicText('');
    };

    const renderModalNavigation = () => {
        let numberOfChanges = updatedConfig.length + deletedConfig.length;
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    };

    const renderAccordion = () => {
        if (editKWRAccordionVisible) {
            const accordions = [
                {
                    header: 'KWR Config',
                    required: false,
                    open: editKWRConfigAccordionOpen,
                    type: 'form',
                    intro: '',
                    config: {
                        formConfig: {
                            fields: [
                                {
                                    label: 'Topic Specific:',
                                    type: 'checkbox',
                                    toolTipCopy: `Topic specific keyword research allows users to run automated keyword research on a specific topic. If left unchecked, we'll run automated keyword research for the whole website.`,
                                    inputKeyValue: 'editTopicSpecificValue',
                                    checked: topicSpecificChecked,
                                    inputOnChange: onCheckBoxClick,
                                },
                                topicSpecificChecked
                                    ? {
                                          label: 'Topic:',
                                          type: 'text',
                                          requiredField: false,
                                          toolTipCopy: 'Enter a Topic.',
                                          inputKeyValue: 'editTopicSpecificText',
                                          inputValue: editTopicText,
                                          inputOnChange: handleTopic,
                                          errorMessage: errorMessageObject.topicTextErrorMessage,
                                      }
                                    : {},
                                topicSpecificChecked
                                    ? {
                                          label: 'Path:',
                                          type: 'reactSelect',
                                          requiredField: false,
                                          inputOptions: pathLoadOptions,
                                          toolTipCopy: 'Select paths with similar content.',
                                          isMulti: true,
                                          inputKeyValue: 'edit-topic-specific-path',
                                          inputValue: pathForReactSelect,
                                          inputOnChange: handlePaths,
                                          errorMessage: '',
                                          isClearable: true,
                                      }
                                    : {},
                                {
                                    label: 'Market:',
                                    type: 'select',
                                    requiredField: true,
                                    toolTipCopy: 'Select a Market.',
                                    inputKeyValue: 'seogdMarket',
                                    inputValue: selectedMarket[0].value,
                                    inputOptions: marketList,
                                    inputOnChange: handleSelectMarket,
                                    errorMessage: '',
                                },
                                {
                                    label: 'Language:',
                                    type: 'select',
                                    requiredField: true,
                                    toolTipCopy: 'Select a Language.',
                                    inputKeyValue: 'seogdLanguage',
                                    inputValue: selectedLanguage[0].value,
                                    inputOptions: languageList,
                                    inputOnChange: handleSelectLanguage,
                                    errorMessage: '',
                                },
                                {
                                    label: 'Brand Name:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: `We'll use brand name to filter out branded terms from the final keyword set.`,
                                    inputKeyValue: 'editBrandName',
                                    inputValue: editBrandName,
                                    inputOnChange: handleBrandName,
                                    errorMessage: errorMessageObject.brandNameErrorMessage,
                                },
                                {
                                    label: 'Domain Name:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter domain name.',
                                    inputKeyValue: 'editKWRDomainName',
                                    inputValue: editDomainName,
                                    inputOnChange: handleDomainName,
                                    errorMessage: errorMessageObject.domainNameErrorMessage,
                                },
                                {
                                    label: 'Search Volume Threshold:',
                                    type: 'text',
                                    requiredField: false,
                                    toolTipCopy: `Set the search volume threshold which we'll use to filter out keywords which have a search volume less than this limit. Search volume is pulled form SEMRUSH and the value is monthly.`,
                                    inputKeyValue: 'editSearchVolumeThreshold',
                                    inputValue: editSearchVolumeThreshold,
                                    inputOnChange: handleSearchVolume,
                                    errorMessage: errorMessageObject.searchVolumeThresholdErrorMessage,
                                },
                                {
                                    label: 'Impressions Threshold:',
                                    type: 'text',
                                    requiredField: false,
                                    toolTipCopy: `Set the impression limit which we'll use to filter out keywords which have a search volume less than this limit. Impression are pulled form Google Search Console and the value is monthly.`,
                                    inputKeyValue: 'editImpressionThreshold',
                                    inputValue: editImpressionThreshold,
                                    inputOnChange: handleImpressions,
                                    errorMessage: errorMessageObject.impressionThresholdErrorMessage,
                                },
                            ],
                            columns: 2,
                            buttons: [
                                {
                                    value: 'UPDATE',
                                    onClick: onUpdateClick,
                                    disabled: updateButtonDisabled,
                                    isLoading: isUpdating,
                                },
                                {
                                    value: 'CANCEL',
                                    onClick: onCloseClick,
                                    buttonTheme: ButtonThemes.Secondary,
                                },
                            ],
                        },
                    },
                },
            ];

            return <WidgetAccordion accordions={accordions} />;
        }
    };

    const renderTable = () => {
        const errorMessageOverride = 'No Automated keyword configuration created for this account.';

        const header = {
            columns: [
                {
                    title: 'Topic',
                },
                {
                    title: 'Topic Specific',
                },
                {
                    title: 'Status',
                },
                {
                    title: 'Updated',
                },
            ],
        };

        const rows = topicList.map(topic => {
            const rowProperty = {
                selected: selectedConfig.includes(topic),
                deleted: deletedConfig.includes(topic),
                edited: updatedConfig.includes(topic),
                disabled: disableKWRConfigSelect === true,
            };

            return {
                onClick: () => onConfigSelect(topic),
                key: `kwrConfig__${topic.id}`,
                dataValue: topic.id,
                rowProperty,
                columns: [
                    {
                        copy: topic.name,
                    },
                    {
                        copy: topic.topic ? 'Yes' : 'No',
                    },
                    {
                        copy: topic.status ? 'Processed' : 'Unprocessed',
                    },
                    {
                        copy: topic.updated.split('T')[0],
                    },
                ],
            };
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Automated Keyword Configuration</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Automated Keyword Configuration</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Automated Keyword Configuration</h2>
            <p>
                Manage your existing automated KWR configurations. If you would like to edit the settings for your
                configuration, please click the edit button and save.
            </p>
            <div className="">
                {renderTable()}
                <InputButtonWrapper>
                    <InputButton value="Add New" disabled={addNewButtonDisabled} onClick={handleAddNew} />
                    <InputButton value="EDIT" disabled={editButtonDisabled} onClick={onEditClick} />
                    <InputButton
                        buttonTheme={ButtonThemes.Red}
                        value={removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                        disabled={removeButtonDisabled}
                        onClick={onDeleteKWRConfig}
                    />
                </InputButtonWrapper>
            </div>
            {renderAccordion()}
        </div>
    );
};

export default LayoutAutomatedKWRConfig;
