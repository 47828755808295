export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_MODAL':
            return Object.assign({}, state, {
                isVisible: true,
                type: action.payload.type,
                config: action.payload.config,
            });
        case 'DEL_MODAL':
            return Object.assign({}, state, {
                isVisible: false,
                type: '',
                config: [],
            });
        default:
            return state;
    }
}

const initialState = {
    isVisible: false,
    type: '',
    config: [],
};
