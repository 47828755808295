import React from 'react';
import styled from 'styled-components';
import Form from '../../../../components/forms/form';
import FormWidgetLibraryWidgetSearch from '../forms/form-widget-library-widget-search';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import IconPlus from '../../../../components/icons/plus';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: ${props => props.theme.dashboardBuilder.container.backgroundColor};
    ${props => props.theme.boxShadow(1)}
    padding:8px 16px;
    border-radius: 6px;
`;

const StyledCreateButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${props => props.theme.buttons.secondary.color};
`;

const StyledIconPlus = styled(IconPlus)`
    width: 12px;
`;

const LayoutWidgetLibraryTopBar = () => {
    const navigate = useNavigate();
    const accountToken = useSelector((state: RootState) => state.account.token);

    return (
        <StyledContainer>
            <StyledCreateButton onClick={() => navigate(`/${accountToken}/dashboard-builder/create-a-widget`)}>
                <StyledIconPlus />
                New Widget
            </StyledCreateButton>
            <Form onSubmit={() => {}}>
                <FormWidgetLibraryWidgetSearch />
            </Form>
        </StyledContainer>
    );
};

export default LayoutWidgetLibraryTopBar;
