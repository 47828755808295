import React, { memo } from 'react';

// Core Dependencies
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Types
import { BreadcrumbsDropdownItemProps } from '../types';

// Styles
const StyledDropdownItem = styled.li`
    position: relative;
    cursor: pointer;
    width: inherit;
    background-color: ${props => props.theme.colours.hoverGrey};
    padding: 4px 12px;
    border-radius: 3px;
    margin: 2px;
    transition: ${props => props.theme.transition};

    &:hover {
        background-color: #dfdfdf;
    }
`;

const BreadcrumbsDropdownItem = ({ resource, field, onClick }: BreadcrumbsDropdownItemProps) => {
    const handleClick = () => {
        onClick(resource, field);
    };

    return <StyledDropdownItem onClick={handleClick}>{field.displayName}</StyledDropdownItem>;
};

export default memo(BreadcrumbsDropdownItem, isEqual);
