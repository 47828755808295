import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Hooks
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import useDeleteResource from '../../../../react-query/hooks/use-delete-resource';
import usePatchMultiResourceWithPayload from '../../../../react-query/hooks/use-patch-multi-resource-with-payload';

// Config
import { CONFIG_DASH_REFERER, REALLOCATION_CONFIG } from '../../../../configurations/resources-config';

// Types & Enums
import { ConfigDataSuccess } from '../../../../react-query/types';
import { DropdownOption } from '../../../../section-dashboard/types';
import { ButtonThemes } from '../../../../enums/button-themes';
import { NotificationMessageType } from '../../../../enums/notification-types';
import { FieldValues } from 'react-hook-form';

// Redux
import { setModal } from '../../../../redux/actions/modal';
import { useDispatch } from 'react-redux';
import { removePopup, setPopup } from '../../../../redux/actions/popup';
import { addNotification } from '../../../../redux/actions/notification';

// Helpers
import buildDropdownOptions from '../../../../react-query/select-functions/build-dropdown-options';

// Components
import ConfigTable from '../../../../components/tables/config-table/config-table';
import Form from '../../../../components/forms/form';

const StyledH2 = styled.h2`
    font-size: 1.1rem;
`;

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

type ReallocationChannel = {
    id: number;
    channelName: string;
    selected: boolean;
};

const LayoutReallocationChannels = () => {
    const dispatch = useDispatch();

    const [reallocationChannels, setReallocationChannels] = useState<ReallocationChannel[]>([]);
    const [channelToEdit, setChannelToEdit] = useState<ReallocationChannel | null>(null);
    const [channelsToDelete, setChannelsToDelete] = useState<number[]>([]);
    const [showEditForm, setShowEditForm] = useState(false);
    const [formDefaultValues, setFormDefaultValues] = useState({ channel: '' });

    const reallocationChannelQuery = useFetchResource({
        resource: REALLOCATION_CONFIG,
        params: [{ key: 'active', value: true }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    id: object.id,
                    channelName: object.referer.name,
                    selected: false,
                };
            });
        },
    });

    useEffect(() => {
        if (reallocationChannelQuery.data) {
            setReallocationChannels(reallocationChannelQuery.data);
        }
    }, [reallocationChannelQuery.data]);

    const channelQuery = useFetchResource<ConfigDataSuccess, DropdownOption[]>({
        resource: CONFIG_DASH_REFERER,
        params: [
            { key: 'active', value: true },
            { key: 'order_by', value: 'name' },
        ],
        staleTime: 1000 * 60 * 5, // 5 minutes,
        select: (data: ConfigDataSuccess) => buildDropdownOptions({ data: data, labelField: 'name', valueField: 'id' }),
    });

    useEffect(() => {
        if (channelToEdit) {
            setFormDefaultValues({
                ...channelToEdit,
                channel: channelQuery?.data?.find(channel => channel.label === channelToEdit.channelName)?.value || '',
            });
        }
    }, [channelToEdit]); // eslint-disable-line react-hooks/exhaustive-deps

    const reallocationChannelDeleteMutation = useDeleteResource({
        resource: REALLOCATION_CONFIG,
        resourceIds: channelsToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            setChannelsToDelete([]);
            dispatch(
                addNotification({
                    copy: 'Channels deleted successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your channels. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const reallocationChannelPatchMutation = usePatchMultiResourceWithPayload({
        resource: REALLOCATION_CONFIG,
        handleOnSuccess: () => {
            setShowEditForm(false);
            dispatch(addNotification({ copy: 'Reallocation channel successfully updated', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while updating, please try again later or contact support@cubed.email',
                    type: 'error',
                })
            );
        },
    });

    const handleCheckboxChange = (rowId: number) => {
        const updatedChannels = reallocationChannels.map(channel => {
            if (channel.id === rowId) {
                return { ...channel, selected: !channel.selected };
            }
            return channel;
        });
        setReallocationChannels(updatedChannels);

        setChannelsToDelete(updatedChannels.filter(channel => channel.selected).map(channel => channel.id));
    };

    const handleEditClick = (rowId: number) => {
        let channelToEdit = reallocationChannels.find(channel => channel.id === rowId);

        if (channelToEdit) {
            setChannelToEdit(channelToEdit);
            setShowEditForm(true);
        }
    };

    const handleSingleDeleteClick = (rowId: number) => {
        setChannelsToDelete([rowId]);

        const handleDisardClick = () => {
            setChannelsToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            reallocationChannelDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this channel?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkDeleteClick = () => {
        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            reallocationChannelDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected reallocation channels?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleFormSubmit = (data: FieldValues) => {
        reallocationChannelPatchMutation.mutate({
            payload: [
                {
                    referer_id: data.channel,
                },
            ],
            resourceId: [channelToEdit!.id.toString()],
        });
    };

    return (
        <div>
            <StyledH2>Manage Reallocation Channels</StyledH2>
            <p>
                Sales and revenue are reallocated away from the reallocated channel where possible. If a user journey
                consists of only reallocation channels, reallocation will not be possible and we will revert to our
                standard methods.
            </p>

            <ConfigTable
                status={reallocationChannelQuery.status}
                isFetching={
                    reallocationChannelDeleteMutation.isPending ||
                    reallocationChannelPatchMutation.isPending ||
                    reallocationChannelQuery.isFetching
                }
                disabled={reallocationChannelDeleteMutation.isPending}
                empty={reallocationChannels.length === 0}
            >
                <ConfigTable.Table>
                    <ConfigTable.Header>
                        <ConfigTable.Row key={'reallocation-channels-header'}>
                            <ConfigTable.CellHeader />
                            <ConfigTable.CellHeader>Channel</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader />
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {reallocationChannels.map(channel => {
                            return (
                                <ConfigTable.Row key={channel.id}>
                                    <ConfigTable.CellCheckbox
                                        rowId={channel.id}
                                        checked={channel.selected}
                                        onCheckedChange={handleCheckboxChange}
                                    />
                                    <ConfigTable.Cell>{channel.channelName}</ConfigTable.Cell>
                                    <ConfigTable.CellActions>
                                        <ConfigTable.ActionDropdownItem
                                            rowId={channel.id}
                                            type="edit"
                                            label="Edit"
                                            onClick={handleEditClick}
                                        />
                                        <ConfigTable.ActionDropdownItem
                                            rowId={channel.id}
                                            type="delete"
                                            label="Delete"
                                            onClick={handleSingleDeleteClick}
                                        />
                                    </ConfigTable.CellActions>
                                </ConfigTable.Row>
                            );
                        })}
                    </ConfigTable.Body>
                </ConfigTable.Table>
                <ConfigTable.ActionBar>
                    <ConfigTable.ActionButton type="delete" label="Delete" onClick={handleBulkDeleteClick} />
                    <ConfigTable.ActionButton
                        type="add"
                        label="Add Channel"
                        onClick={() => dispatch(setModal('AddReallocationChannels', {}))}
                    />
                </ConfigTable.ActionBar>
            </ConfigTable>

            {showEditForm && channelToEdit && (
                <StyledFormContainer>
                    <Form
                        status={reallocationChannelPatchMutation.status === 'pending' ? 'loading' : 'enabled'}
                        onSubmit={handleFormSubmit}
                        defaultValues={formDefaultValues}
                    >
                        <Form.Body>
                            <Form.Section>
                                <Form.SectionTitle>Edit Reallocation Channel</Form.SectionTitle>

                                <Form.Field>
                                    <Form.InputLabel htmlFor="channel" label="Channel" />
                                    <Form.InputSelect name="channel" options={channelQuery.data || []} />
                                </Form.Field>
                            </Form.Section>
                        </Form.Body>
                        <Form.Footer>
                            <Form.InputButton
                                type="submit"
                                value="Save"
                                loading={reallocationChannelPatchMutation.isPending}
                            />
                            <Form.InputButton
                                type="button"
                                value="Cancel"
                                buttonTheme={ButtonThemes.Secondary}
                                onClick={() => setShowEditForm(false)}
                            />
                        </Form.Footer>
                    </Form>
                </StyledFormContainer>
            )}
        </div>
    );
};

export default LayoutReallocationChannels;
