/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

// Helpers
import { generateTablePaginations, generateTableParams } from '../../configurations/customer-explorer/utils';
import { generateUrl } from '../../helpers/request-builder';
import { getMaxPage } from '../../components/tables/helpers/table-pagination-helper';

// Redux
import { useDispatch } from 'react-redux';

// Config
import { config } from '../../configurations/customer-explorer/config';

// Filterbar context
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';

const queryString = require('query-string');

export const CustomerExplorerContext = createContext();

const CustomerExplorer = ({
    history,
    location,
    startDate,
    endDate,
    selectedProducts,
    selectedChannelData,
    setOwnerState,
    children,
}) => {
    // Redux
    const dispatch = useDispatch();

    // Config
    const { resources, table, pagination, saleTypes, dateTypes } = config;
    const defaultRequest = { meta: [], objects: [] };

    // Tab view
    const [tableTabConfig, setTableTabConfig] = useState(table.tabListItems);
    const [selectedTabId, setSelectedTabId] = useState(
        tableTabConfig.filter(config => config.isSelected)[0]?.linkedResourceKey
    );

    // Table View
    const [visitors, setVisitors] = useState(defaultRequest);
    const [transactions, setTransactions] = useState(defaultRequest);
    const [channel, setChannel] = useState(defaultRequest);
    const [tableMetrics, setTableMetrics] = useState(table.tabListItems[selectedTabId].metrics);
    const [tableDimensions, setTableDimensions] = useState(resources[selectedTabId].defaultDimensions);
    const [tablePagination, setTablePagination] = useState(pagination);
    const [tableLoading, setTableLoading] = useState(true);
    const [tableData, setTableData] = useState(defaultRequest);
    const [tableNoData, setTableNoData] = useState(true);
    const [orderBy, setOrderBy] = useState(resources[selectedTabId].defaultOrderBy);
    const [orderByDir, setOrderByDir] = useState(resources[selectedTabId].defaultOrderBy.defaultOrderDir);

    // Table View Filters
    const [customerSaleTypes, setCutomerSaleTypes] = useState(saleTypes);
    const [dateSelectorTypes, setDateSelectorTypes] = useState(dateTypes);
    const [showSaleFilters, setShowSaleFilters] = useState(false);
    const [showChannelFilters, setShowChannelFilters] = useState(false);

    // Single Customer View
    const [visitorId, setVisitorId] = useState(null);
    const [visits, setVisits] = useState(null);
    const [visitor, setVisitor] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(false);

    const { setFilterStatus, setFilterMetricsOptions, datePickerConfig, setDatePickerConfig } =
        useContext(FilterBarContext);

    // On page load, set status and show table/single customer if query param provided
    useEffect(() => {
        dispatch({ type: 'SET_TITLE', title: 'Customer Explorer' });

        // Currently using a custom filter bar for SCV due to requiring date type radio selector
        setFilterStatus({
            isEnableDatePicker: false,
            isEnableProductSelect: false,
            isEnableMetrics: false,
        });
        setFilterMetricsOptions([]); // reset metrics

        // Set the date
        const start = moment().add(-24, 'hours');
        const end = moment();
        setDatePickerConfig({ customStartDate: () => start, customEndDate: () => end, maxDate: () => moment() });

        // Check the url for a visitor id
        if (location.search.includes('vid')) {
            const vid = queryString.parse(location.search).vid;
            setVisitorId(vid);
        } else {
            setTableMetrics(table.tabListItems[selectedTabId].metrics);
            setTableDimensions(resources[selectedTabId].defaultDimensions);
            setOrderBy(resources[selectedTabId].defaultOrderBy);
            setOrderByDir(resources[selectedTabId].defaultOrderBy.defaultOrderDir);
        }
    }, []);

    useEffect(() => {
        if (selectedTabId === 0 || selectedTabId === 2) {
            if (selectedTabId === 2) {
                setShowSaleFilters(false);
            } else {
                if (customerSaleTypes.some(type => type.value === 'sale' && type.active === true)) {
                    setShowSaleFilters(true);
                } else {
                    setShowSaleFilters(false);
                }
            }
        } else {
            setShowSaleFilters(true);
        }
    }, [customerSaleTypes, selectedTabId]);

    // If the visitor id changes, fetch the visitor
    useEffect(() => {
        if (visitorId) {
            fetchVisitor();
        }
    }, [visitorId]);

    // When filters change, fetch the table data
    useEffect(() => {
        fetchTableData(selectedTabId, tablePagination);
    }, [
        orderBy,
        orderByDir,
        startDate,
        endDate,
        selectedProducts,
        selectedChannelData,
        customerSaleTypes,
        dateSelectorTypes,
        selectedTabId,
    ]);

    useEffect(() => {
        switch (selectedTabId) {
            case 0: // Visitor tab
                setTableData(visitors);
                break;
            case 1: // Transaction tab
                setTableData(transactions);
                break;
            case 2: // Channel tab
                setTableData(channel);
                break;
            default:
                console.log('Unable to get table data for tab');
        }
    }, [visitors, transactions, channel, selectedTabId]);

    useEffect(() => {
        if (selectedTabId === 2) {
            setShowChannelFilters(true);
        } else {
            setShowChannelFilters(false);
        }
    }, [selectedTabId]);

    const handleTabElementClick = selectedTab => {
        setTableLoading(false);

        const updatedTabConfig = tableTabConfig.map(config => {
            if (config.linkedResourceKey === selectedTab) {
                config.isSelected = true;
            } else {
                config.isSelected = false;
            }

            return config;
        });

        if (selectedTab === 0 || selectedTab === 2) {
            setDateSelectorTypes(dateTypes);
            setCutomerSaleTypes(saleTypes);
        } else {
            setDateSelectorTypes(
                dateTypes.map(dateType => {
                    return {
                        ...dateType,
                        hidden: dateType.value !== 'sales_date',
                        checked: dateType.value === 'sales_date',
                    };
                })
            );
        }

        setTableMetrics(table.tabListItems[selectedTab].metrics);
        setTableDimensions(resources[selectedTab].defaultDimensions);
        setOrderBy(resources[selectedTab].defaultOrderBy);
        setOrderByDir(resources[selectedTab].defaultOrderBy.defaultOrderDir);

        setTableTabConfig(updatedTabConfig);
        setTablePagination(pagination);
        setTableData(defaultRequest);
        setSelectedTabId(selectedTab);
    };

    const fetchTableData = (selectedTabId, tablePagination) => {
        setVisitors({ meta: [], objects: [] });
        setTransactions({ meta: [], objects: [] });
        setChannel({ meta: [], objects: [] });
        setTableLoading(true);

        switch (selectedTabId) {
            case 0: // Visitor tab
                fetchVisitorTableData(selectedTabId, tablePagination);
                break;
            case 1: // Transaction tab
                fetchTransactionTableData(selectedTabId, tablePagination);
                break;
            case 2: // Channel tab
                fetchChannelTableData(selectedTabId, tablePagination);
                break;
            default:
                console.log('Please select a tab');
        }
    };

    const fetchVisitorTableData = (selectedTabId, tablePagination) => {
        const params = generateTableParams(
            resources[selectedTabId],
            orderBy,
            orderByDir,
            tablePagination,
            startDate,
            endDate,
            selectedProducts,
            customerSaleTypes,
            dateSelectorTypes,
            selectedTabId
        );

        axios({
            method: 'GET',
            url: generateUrl('report', 'agg-scv-visitor', params),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const visitorData = response.data.objects.map(visitor => {
                    return {
                        visitor_id: visitor.visitor.id,
                        first_visit: moment(visitor.first_visit).format('YYYY/MM/DD HH:mm:ss'),
                        last_visit: moment(visitor.last_visit).format('YYYY/MM/DD HH:mm:ss'),
                        has_sale: visitor.has_sale ? 'Yes' : 'No',
                        has_synced: visitor.has_synced ? 'Yes' : 'No',
                        has_client_customer_id: visitor.has_client_customer_id ? 'Yes' : 'No',
                        client_customer_id: visitor.client_customer_id,
                        row_click_ref: visitor.visitor.id,
                        latest_sale_date: visitor.latest_sale_date
                            ? moment(visitor.latest_sale_date).format('YYYY/MM/DD HH:mm:ss')
                            : 'None',
                    };
                });

                // set the total results for pagination
                if (response.data.meta.total_count !== tablePagination.totalResults) {
                    setTablePagination({
                        ...tablePagination,
                        totalResults: response.data.meta.total_count,
                        maxPage: getMaxPage(response.data.meta.total_count, tablePagination.currentRowCount),
                    });
                }

                setVisitors({ meta: response.data.meta, objects: visitorData });
                setTableNoData(response.data.objects && response.data.objects.length === 0);
                setTableLoading(false);
            })
            .catch(e => {
                setTableLoading(false);
                setTableNoData(true);
                console.log(e);
            });
    };

    const fetchTransactionTableData = (selectedTabId, tablePagination) => {
        const params = generateTableParams(
            resources[selectedTabId],
            orderBy,
            orderByDir,
            tablePagination,
            startDate,
            endDate,
            selectedProducts,
            customerSaleTypes,
            dateSelectorTypes,
            selectedTabId
        );

        axios({
            method: 'GET',
            url: generateUrl('report', 'agg-scv-transaction', params),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const transactionData = response.data.objects.map(transaction => {
                    return {
                        id: transaction.id,
                        transaction_id: transaction.transaction_id,
                        sales_date: transaction.sales_date,
                        row_click_ref: transaction.transaction_id,
                        revenue: transaction.revenue === null ? '' : transaction.revenue,
                    };
                });

                // set the total results for pagination
                if (response.data.meta.total_count !== tablePagination.totalResults) {
                    setTablePagination({
                        ...tablePagination,
                        totalResults: response.data.meta.total_count,
                        maxPage: getMaxPage(response.data.meta.total_count, tablePagination.currentRowCount),
                    });
                }

                setTransactions({ meta: response.data.meta, objects: transactionData });
                setTableNoData(response.data.objects && response.data.objects.length === 0);
                setTableLoading(false);
            })
            .catch(e => {
                setTableLoading(false);
                setTableNoData(true);
                console.log('response error!', e);
            });
    };

    const fetchChannelTableData = (selectedTabId, tablePagination) => {
        const params = generateTableParams(
            resources[selectedTabId],
            orderBy,
            orderByDir,
            tablePagination,
            startDate,
            endDate,
            selectedChannelData,
            customerSaleTypes,
            dateSelectorTypes,
            selectedTabId
        );
        axios({
            method: 'GET',
            url: generateUrl('report', 'report-scv-channel', params),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const channelData = [];
                const channelDataResponse = response.data.objects.map(visitor => {
                    return {
                        visitor_id: visitor.visitor.id,
                        first_visit: moment(visitor.first_visit).format('YYYY/MM/DD HH:mm:ss'),
                        last_visit: moment(visitor.last_visit).format('YYYY/MM/DD HH:mm:ss'),
                        has_sale: visitor.has_sale ? 'Yes' : 'No',
                        has_synced: visitor.has_synced ? 'Yes' : 'No',
                        has_client_customer_id: visitor.has_client_customer_id ? 'Yes' : 'No',
                        client_customer_id: visitor.client_customer_id,
                        row_click_ref: visitor.visitor.id,
                        latest_sale_date: visitor.latest_sale_date
                            ? moment(visitor.latest_sale_date).format('YYYY/MM/DD HH:mm:ss')
                            : 'None',
                        referer_id: visitor.referer.id,
                        referer: visitor.referer.name,
                    };
                });

                channelDataResponse.forEach(channels => {
                    selectedChannelData.forEach(channel => {
                        if (channel.id === channels.referer_id) {
                            channelData.push(channels);
                        }
                    });
                });
                // set the total results for pagination
                if (response.data.meta.total_count !== tablePagination.totalResults && channelData.length > 0) {
                    setTablePagination({
                        ...tablePagination,
                        totalResults: response.data.meta.total_count,
                        maxPage: getMaxPage(response.data.meta.total_count, tablePagination.currentRowCount),
                    });
                }

                setChannel({ meta: response.data.meta, objects: channelData });
                setTableNoData(response.data.objects && response.data.objects.length === 0);
                setTableLoading(false);
            })
            .catch(e => {
                setTableLoading(false);
                setTableNoData(true);
                console.log(e);
            });
    };

    const fetchVisitor = () => {
        let displayColour = 0;

        axios({
            method: 'GET',
            url: generateUrl('config', 'dash-referer', [{ key: 'name', value: 'Display' }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                displayColour = response.data.objects[0].colour;
            })
            .then(() => {
                axios({
                    method: 'GET',
                    url: generateUrl('report', 'scv-visitor', [{ key: 'id', value: visitorId }]),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        setVisits(
                            response.data.objects[0].visits.map(visit => {
                                return {
                                    ...visit,
                                    colour: visit.is_impression ? displayColour : visit.colour,
                                };
                            })
                        );
                        setVisitor(response.data.objects[0]);
                    })
                    .catch(e => {
                        setTableLoading(false);
                        console.log(e);
                    });
            });
    };

    const fetchVisitorIdFromTransactionId = transactionId => {
        axios({
            method: 'GET',
            url: generateUrl('report', 'scv-transaction', [{ key: 'transaction_id', value: transactionId }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                setVisitorId(response.data.objects[0].visitor_id);
                history(location.pathname + '?vid=' + response.data.objects[0].visitor_id);
            })
            .catch(e => {
                setTableLoading(false);
                console.log(e);
            })
            .finally(() => {
                setDetailsLoading(false);
            });
    };

    const handleBackClick = () => {
        setVisitorId(null);
        setVisitor(null);
        setVisits(null);
        history(location.pathname);
        fetchTableData(selectedTabId, tablePagination);
    };

    const handleCustomerSaleTypeChange = option => {
        // set active button
        const updatedOptions = customerSaleTypes.map(saleType => {
            return {
                ...saleType,
                active: option.value === saleType.value,
            };
        });

        // show date types depending on selected sale/non-sale
        let updatedDateTypes;
        if (selectedTabId === 0 || selectedTabId === 2) {
            // Visitors and Channels tabs
            if (option.value === 'sale') {
                updatedDateTypes = dateSelectorTypes.map(dateType => {
                    return {
                        ...dateType,
                        hidden: !(
                            dateType.value === 'first_visit' ||
                            dateType.value === 'last_visit' ||
                            dateType.value === 'latest_sale_date'
                        ),
                        checked: dateType.value === 'last_visit',
                    };
                });
            } else {
                updatedDateTypes = dateSelectorTypes.map(dateType => {
                    return {
                        ...dateType,
                        hidden: !(dateType.value === 'first_visit' || dateType.value === 'last_visit'),
                        checked: dateType.value === 'last_visit',
                    };
                });
            }
        }

        setOrderBy(resources[selectedTabId].defaultOrderBy);
        setOrderByDir(resources[selectedTabId].defaultOrderBy.defaultOrderDir);
        setCutomerSaleTypes(updatedOptions);
        setDateSelectorTypes(updatedDateTypes);
    };

    const handleSelectedDateTypeChange = option => {
        const updatedOptions = dateSelectorTypes.map(dateType => {
            return {
                ...dateType,
                checked: option === dateType.value,
            };
        });
        const selectedRawName = option;
        const newOrderBy = resources[selectedTabId].dateDimensions.filter(
            dimention => dimention.rawName === selectedRawName
        )[0];

        setOrderBy(newOrderBy || orderBy);
        setOrderByDir(orderByDir === 'asc' ? 'desc' : 'asc');
        setDateSelectorTypes(updatedOptions);
    };

    const setCustomOwnerState = (key, value) => {
        let modifiedTablePagination;

        switch (key) {
            case 'tableOrderBy':
                setOrderBy(value);
                break;
            case 'tableOrderDir':
                setOrderByDir(value);
                break;
            case 'rowCount':
                const default_current_page = 1;
                modifiedTablePagination = generateTablePaginations(default_current_page, value, tablePagination);
                setTablePagination(modifiedTablePagination);
                fetchTableData(selectedTabId, modifiedTablePagination);
                break;
            case 'page':
                if (value === tablePagination.currentPage) return;
                modifiedTablePagination = generateTablePaginations(
                    value,
                    tablePagination.currentRowCount,
                    tablePagination
                );
                setTablePagination(modifiedTablePagination);
                fetchTableData(selectedTabId, modifiedTablePagination);
                break;
            case 'selectedRows':
                setDetailsLoading(true);
                if (selectedTabId === 0 || selectedTabId === 2) {
                    const visitorId = value[0].data.visitor_id;
                    history(location.pathname + '?vid=' + visitorId);
                    setVisitorId(visitorId);
                    setDetailsLoading(false);
                } else {
                    const transactionId = value[0].data.transaction_id;
                    fetchVisitorIdFromTransactionId(transactionId);
                }
                break;
            default:
                break;
        }
    };

    return (
        <CustomerExplorerContext.Provider
            value={{
                selectedTabId,
                location,
                visitors,
                visitorId,
                visits,
                visitor,
                transactions,
                dateSelectorTypes,
                showSaleFilters,
                showChannelFilters,
                selectedProducts,
                setOwnerState,
                handleBackClick,
                tableTabConfig,
                tableMetrics,
                tableDimensions,
                tablePagination,
                tableLoading,
                tableData,
                tableNoData,
                orderBy,
                orderByDir,
                customerSaleTypes,
                handleCustomerSaleTypeChange,
                handleSelectedDateTypeChange,
                handleTabElementClick,
                datePickerConfig,
                detailsLoading,
                setCustomOwnerState,
            }}
        >
            {children}
        </CustomerExplorerContext.Provider>
    );
};

export default CustomerExplorer;
