import { firstBreadcrumbResources, excludeBreadcrumbsConfig } from '../configurations/exclude-breadcrumbs';

export const excludeBreadcrumbs = (dimensionFilters, updatedResources, currentResource) => {
    if (dimensionFilters.length > 0) {
        // get first and second breadcrumbs to check for selected channels and subchannels
        const firstBreadcrumbResource = dimensionFilters[0].resource;
        let secondBreadcrumbResource = null;

        if (dimensionFilters.length > 1) {
            secondBreadcrumbResource = dimensionFilters[1].resource;
        }

        // find applicable exclude rules from exclude config
        const channelExcludeRule = excludeBreadcrumbsConfig.filter(configItem =>
            configItem.channels?.includes(dimensionFilters[0].displayValue)
        );
        const resourceExcludeRule = excludeBreadcrumbsConfig.filter(configItem =>
            configItem.resources?.includes(currentResource)
        );

        // check if resources need to be excluded based on current resource
        if (
            !firstBreadcrumbResources.includes(firstBreadcrumbResource) ||
            (firstBreadcrumbResources.includes(firstBreadcrumbResource) &&
                secondBreadcrumbResource &&
                !firstBreadcrumbResources.includes(secondBreadcrumbResource) &&
                dimensionFilters.length > 1) ||
            (firstBreadcrumbResources.includes(firstBreadcrumbResource) &&
                secondBreadcrumbResource &&
                firstBreadcrumbResources.includes(secondBreadcrumbResource) &&
                dimensionFilters.length > 2)
        ) {
            if (resourceExcludeRule.length > 0) {
                updatedResources = updatedResources.filter(resource => {
                    return !resourceExcludeRule[0].exclude.includes(resource);
                });
            }
        }

        // check if resources need to be excluded based on selected channel
        if (channelExcludeRule.length > 0) {
            updatedResources = updatedResources.filter(resource => {
                return !channelExcludeRule[0].exclude.includes(resource);
            });
        }
    }
    return updatedResources;
};
