import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType } from './common/chart-types';
import dateStringToUnix from '../helpers/date-string-to-unix';

export const config = {
    pageTitle: 'Tech SEO Dashboard',
    // reportDescription: 'tbc',
    resources: [resources.REPORT_TECH_SEO],
    ignoreProducts: true,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnablePdfExport: true,
        },
    },
    chart: {
        chartType: ChartType.Line,
        allowedChartTypes: [],
        chartId: 'report-tech-seo',
        allYMetrics: [fields.VISITS, fields.STATUS_CODE, fields.SELF_REFERENCE, fields.DOUBLE_CANONICAL],
        dataFormatter: function (_, datas, dimension, metric) {
            return datas.map(data => {
                return [dateStringToUnix(data[dimension.rawName].raw_value), data[metric].raw_value];
            });
        },
        initialYMetrics: [fields.VISITS],
    },
    table: {
        tabListItems: [
            {
                tabName: 'Page Status',
                displayName: 'Page Status',
                isSelected: true,
                metrics: [fields.VISITS, fields.STATUS_CODE, fields.INDEXED, fields.COUNT],
            },
            {
                tabName: 'Canonicalisation',
                displayName: 'Canonicalisation',
                isSelected: true,
                metrics: [fields.HEADER_CANONICAL, fields.SELF_REFERENCE, fields.DOUBLE_CANONICAL],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.PATH_HOST.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_PATH,
                    },
                ],
            },
            [fields.PATH_GEOGRAPHY.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_LANGUAGE,
                    },
                ],
            },
            [fields.PATH_MARKET.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_LANGUAGE,
                    },
                ],
            },
            [fields.PATH_LANGUAGE.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_CONTENT_TYPE.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_CONTENT_LENGTH,
                    },
                ],
            },
            [fields.PATH_CATEGORY.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_CONTENT_TYPE,
                    },
                ],
            },
            [fields.PATH_CONTENT_LENGTH.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_TOPIC.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.CANONICAL,
                    },
                ],
            },
            [fields.CANONICAL.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_HOST,
                    },
                ],
            },
            [fields.HEADER_CANONICAL.id]: {
                default: [
                    {
                        resource: resources.REPORT_TECH_SEO,
                        field: fields.PATH_HOST,
                    },
                ],
            },
        },
    },
    allDataExport: {
        fileName: 'report-tech-seo',
        resource: resources.REPORT_TECH_SEO_CSV,
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
