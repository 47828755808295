import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType } from './common/chart-types';

export const config = {
    pageTitle: 'Impression Impact Modelling',
    reportDescription: '',
    resources: [resources.REPORT_ATL_IMPRESSION_CAMPAIGN_TRAFFIC_FORECAST],
    showDropdownLegend: true,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: true,
            isEnableMetrics: true,
        },
    },
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: [],
        chartId: 'atl-base-traffic-forecast',
        allYMetrics: [
            fields.IMPRESSIONS_BASELINE_TRAFFIC,
            fields.ACTUAL_TRAFFIC,
            fields.INCREMENTAL_TRAFFIC_IMPRESSIONS,
            fields.ATL_TRAFFIC_PROPORTION,
            fields.ACTUAL_LC_SALES,
            fields.INCREMENTAL_FM_SALES_ATL_IMPRESSIONS,
            fields.ATL_FM_SALES_PROPORTION,
            fields.SALES_VARIANCE,
            fields.SALES_VARIANCE_PCT,
            fields.ACTUAL_LC_REVENUE,
            fields.INCREMENTAL_FM_REVENUE_ATL_IMPRESSIONS,
        ],
        initialYMetrics: [fields.IMPRESSIONS_BASELINE_TRAFFIC, fields.ACTUAL_TRAFFIC],
    },
    table: {
        tabListItems: [
            {
                tabName: 'traffic',
                displayName: 'Traffic',
                isSelected: true,
                metrics: [fields.INCREMENTAL_TRAFFIC_IMPRESSIONS, fields.ATL_TRAFFIC_PROPORTION],
            },
            {
                tabName: 'sales',
                displayName: 'Sales',
                metrics: [
                    fields.INCREMENTAL_FM_SALES_ATL_IMPRESSIONS,
                    fields.ATL_FM_SALES_PROPORTION,
                    fields.INCREMENTAL_FM_REVENUE_ATL_IMPRESSIONS,
                    fields.ATL_FM_REVENUE_PROPORTION,
                ],
            },
            {
                tabName: 'all',
                displayName: 'All',
                metrics: [
                    fields.INCREMENTAL_TRAFFIC_IMPRESSIONS,
                    fields.ACTUAL_TRAFFIC,
                    fields.ATL_TRAFFIC_PROPORTION,
                    fields.INCREMENTAL_FM_SALES_ATL_IMPRESSIONS,
                    fields.ACTUAL_FM_SALES,
                    fields.ATL_FM_SALES_PROPORTION,
                    fields.INCREMENTAL_FM_REVENUE_ATL_IMPRESSIONS,
                    fields.ACTUAL_FM_REVENUE,
                    fields.ATL_FM_REVENUE_PROPORTION,
                ],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.IMPRESSION_MODEL.id]: {
                default: [
                    {
                        resource: resources.REPORT_ATL_IMPRESSION_CAMPAIGN_TRAFFIC_FORECAST,
                        field: fields.IMPRESSION_SOURCE,
                    },
                ],
            },
            [fields.IMPRESSION_SOURCE.id]: {
                default: [
                    {
                        resource: resources.REPORT_ATL_IMPRESSION_CAMPAIGN_TRAFFIC_FORECAST,
                        field: fields.IMPRESSION_MEDIUM,
                    },
                ],
            },
            [fields.IMPRESSION_MEDIUM.id]: {
                default: [
                    {
                        resource: resources.REPORT_ATL_IMPRESSION_CAMPAIGN_TRAFFIC_FORECAST,
                        field: fields.CAMPAIGN_ID,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
