import { keepPreviousData, useQuery } from '@tanstack/react-query';
import fetchResource from '../query-functions/fetch-resource';

// Types
import { CubedConfigResource, CubedResource } from '../../types';
import { Params } from '../../section-dashboard/types';
import { ResourceDataSuccess } from '../types';

// Hooks
import generateQueryKey from '../helpers/generate-query-key';

export type UseFetchResourceArgs<TResponse, TSelectResponse> = {
    resource?: CubedResource | CubedConfigResource;
    params?: Params;
    staleTime?: number;
    enabled?: boolean;
    isPaginated?: boolean;
    select?: (data: TResponse, isLoading?: boolean) => TSelectResponse;
    refetchInterval?: number | false;
};

const useFetchResource = <TResponse = ResourceDataSuccess, TSelectResponse = TResponse>({
    resource,
    params = [],
    staleTime = 1000 * 60 * 10, // 10 minutes
    enabled = !!resource,
    isPaginated = false,
    refetchInterval = false,
    select,
}: UseFetchResourceArgs<TResponse, TSelectResponse>) => {
    if (typeof enabled === 'boolean') {
        enabled = enabled && !!resource;
    }

    const response = useQuery({
        queryKey: generateQueryKey({ resource, params }),
        queryFn: fetchResource,
        placeholderData: isPaginated ? keepPreviousData : undefined,
        staleTime,
        enabled,
        select,
        refetchInterval,
    });

    return response;
};

export default useFetchResource;
