import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const StyledLoadingText = styled.p`
    color: ${props => props.theme.colours.midGrey};
    font-size: 0.9rem;
    font-weight: 500;
    font-style: italic;
`;

const LayoutEmpty = ({ icon, copy }: { icon: React.ReactNode; copy: string }) => {
    return (
        <StyledContainer>
            {icon}
            <StyledLoadingText>{copy}</StyledLoadingText>
        </StyledContainer>
    );
};

export default LayoutEmpty;
