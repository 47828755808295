import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input<{ disabled: boolean }>`
    font-family: inherit;
    font-size: 14.5px;
    width: 100%;
    height: 35px;
    background-color: ${props =>
        props.disabled
            ? props.theme.sectionDashboard.widget.dialog.input.backgroundColorDisabled
            : props.theme.sectionDashboard.widget.dialog.input.backgroundColor};
    border: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    border-radius: 6px;
    color: ${props => props.disabled && props.theme.colours.midGrey};
    padding: 0px 10px;
    margin-top: 5px;
`;

export type DialogInputTextProps = {
    value: string | undefined;
    placeholder?: string;
    disabled?: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
};

const DialogInputText = ({ value, placeholder, disabled, onChange }: DialogInputTextProps) => {
    return (
        <StyledInput
            type="text"
            value={value}
            placeholder={placeholder}
            disabled={disabled ? disabled : false}
            onChange={onChange}
        />
    );
};

export default DialogInputText;
