import React from 'react';
import styled from 'styled-components';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

// Component
import Cancel from './cancel';

// Styles
const StyledDragableItem = styled.div<{ isDragging: boolean }>`
    border: 1px solid ${props => (props.isDragging ? props.theme.colours.info : props.theme.colours.light)};
    ${props => (props.isDragging ? props.theme.boxShadow(3) : props.theme.boxShadow(1))}
    margin: 6px;
    padding: 4px 0px 4px 12px;
    background-color: ${props => props.theme.colours.light};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
`;

// Types
type DraggableItemProps = {
    id: string;
    index: number;
    content?: string;
    removeItem: (id: number) => void;
    isDragDisabled?: boolean;
};

const DraggableItem = ({ id, index, content, removeItem, isDragDisabled = false }: DraggableItemProps) => {
    const handleRemoveItem = () => {
        removeItem(parseInt(id, 10));
    };

    return (
        <Draggable
            key={id}
            draggableId={id}
            index={index}
            isDragDisabled={isDragDisabled}
            disableInteractiveElementBlocking={isDragDisabled}
        >
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                <StyledDragableItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    isDragging={snapshot.isDragging}
                >
                    <span>{content}</span>
                    <Cancel onClick={handleRemoveItem} colour="red" floatRight={true} />
                </StyledDragableItem>
            )}
        </Draggable>
    );
};

export default DraggableItem;
