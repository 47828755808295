import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`;

export type InputButtonWrapperProps = {
    children: React.ReactNode;
};

const InputButtonWrapper = ({ children }: InputButtonWrapperProps) => {
    return <StyledContainer>{children}</StyledContainer>;
};

export default InputButtonWrapper;
