import React, { RefObject, memo, useContext } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Context
import { FilterBarContext } from '../context/filter-bar-context';

// Components
import DatePicker from '../../components/date-picker/date-picker';
import FilterSelect from '../../components/filter-select/filter-select';
import FilterBarMetricFilter from './filter-bar-metric-filter';
import CommonButton from '../../components/common/common-button';
import FilterBarHandler from './filter-bar-handler';
import ButtonGroup from '../../components/button-group';
import IconCalendarsRegular from '../../components/icons/calendars-regular';
import IconCross from '../../components/icons/cross';
import FilterBarChannelSignpost from './filter-bar-channel-signpost';
import PDFDownload from '../../components/pdf-download';

// Types
import { MetricsFilter, FilterBarContextValues } from '../types';

const StyledReportFilter = styled.div`
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    background-color: ${props => props.theme.filterBar.backgroundColor};
    padding: 6px;
    margin-bottom: 8px;
`;

const StyledReportFilterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: initial;
`;

const StyledReportFilterContainerPreview = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
`;

const StyledDatePickerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

const StyledCompareP = styled.p`
    font-size: 13px;
    font-weight: ${props => props.theme.filterBar.button.labelFontWeight};
    text-transform: uppercase;
    display: contents;
    color: ${props => props.theme.filterBar.button.labelColor};
`;

const StyledComparisonViewWrapper = styled.div`
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.filterBar.button.backgroundColor};
    border: 1px solid ${props => props.theme.filterBar.button.backgroundColor};
    padding: 7px 15px;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
        border: 1px solid ${props => props.theme.filterBar.button.borderHover};
    }
`;

const StyledCancelIconWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 15px;
    transition: all 0.2s;
    cursor: pointer;

    & svg {
        width: 20px;
        height: 20px;
        fill: ${props => props.theme.filterBar.button.iconColor};
    }

    :hover {
        transform: scale(1.1);
    }
`;

const StyledIconWrapper = styled.div`
    display: inline-block;
    margin: 3px 8px 0 0;

    & svg {
        width: 16px;
        height: 16px;
        fill: ${props => props.theme.filterBar.button.iconColor};
    }
`;

const StyledComparisonViewP = styled.p`
    font-size: 13px;
    font-weight: ${props => props.theme.filterBar.button.labelFontWeight};
    text-transform: uppercase;
    display: contents;
    color: ${props => props.theme.filterBar.button.labelColor};
`;

const StyledHandlerButton = styled.div`
    display: inline-flex;
    justify-content: flex-end;
    margin-bottom: 6px;
    width: 100%;
`;

const StyledButtonGroup = styled.div`
    position: relative;
    left: 1%;
`;

type FilterBarProps = {
    reportRef: RefObject<HTMLElement> | null;
    loadingPdfDownload: boolean;
    setLoadingPdfDownload: React.Dispatch<React.SetStateAction<boolean>>;
};

const FilterBar = ({ reportRef, loadingPdfDownload, setLoadingPdfDownload }: FilterBarProps) => {
    const {
        // Date picker
        datePickerConfig,
        comparisonDatePickerConfig,

        buttonGroupOptions,
        handleSelectButtonOptions,

        // Product
        productsOptions,
        handleProductSelect,
        handleProductClearAll,
        handleProductSelectAll,
        handleSelectedProductRemove,
        productDataLoading,

        // Filter state
        filterStatus,
        filterProductName,
        reportFilterState: {
            filterExpanded,
            selectedMetricsFilterData,
            selectedProductsFilterData,
            enableFilterApplyButton,
            showAddMetricFilter,
            enableComparisonDateRange,
        },
        handleFilterToggle,
        handleFilterApplyOnClick,
        handleFilterClearOnClick,

        // Add filter
        filterMetricsOptions,
        filterOperatorOptions,
        handleMetricsSelectFilterOnClick,
        handleFilterSelectedMetricEditOnClick,
        handleFilterSelectedMetricRemoveOnClick,
        handleMetricsFilterValueOnChange,
        handleMetricsFilterMetricOnChange,
        handleMetricsFilterOperatorOnChange,
        handleMetricsAddFilterOnClick,
        handleMetricsRemoveFilterOnClick,

        handleComparisonViewOnClick,
    } = useContext(FilterBarContext) as FilterBarContextValues;

    const selectedMetricsArray = selectedMetricsFilterData.filter((data: MetricsFilter) => data.status === 'view');

    const onDownloadPdfLoading = async () => {
        setLoadingPdfDownload(true);
    };
    const onDownloadPdfFinishedLoading = async () => {
        setLoadingPdfDownload(false);
    };

    return (
        <>
            {(filterStatus.isEnableDatePicker ||
                filterStatus.isEnableProductSelect ||
                filterStatus.isEnableMetrics ||
                filterStatus.isEnableButtonGroup ||
                filterStatus.isEnableChannelSingleSelect) && (
                <StyledReportFilter>
                    <StyledReportFilterContainer>
                        <StyledReportFilterContainerPreview>
                            {/* Date Picker */}
                            <StyledDatePickerWrapper>
                                {filterStatus.isEnableDatePicker && (
                                    <DatePicker isEnabled={filterStatus.isEnableDatePicker} config={datePickerConfig} />
                                )}

                                {/* Second Date Picker */}
                                {filterStatus.isEnableComparisonDatePicker && enableComparisonDateRange && (
                                    <>
                                        <StyledCompareP>Compare To:</StyledCompareP>
                                        <DatePicker
                                            isEnabled={filterStatus.isEnableComparisonDatePicker}
                                            config={comparisonDatePickerConfig}
                                            isComparison={true}
                                        />
                                    </>
                                )}
                            </StyledDatePickerWrapper>

                            {/* Comparison Handler */}
                            {filterStatus.isEnableComparisonDatePicker && (
                                <>
                                    {enableComparisonDateRange ? (
                                        <StyledCancelIconWrapper onClick={handleComparisonViewOnClick}>
                                            <IconCross />
                                        </StyledCancelIconWrapper>
                                    ) : (
                                        <StyledComparisonViewWrapper onClick={handleComparisonViewOnClick}>
                                            <StyledIconWrapper>
                                                <IconCalendarsRegular />
                                            </StyledIconWrapper>
                                            <StyledComparisonViewP>Comparison View</StyledComparisonViewP>
                                        </StyledComparisonViewWrapper>
                                    )}
                                </>
                            )}

                            {filterStatus.isEnableButtonGroup && (
                                <StyledButtonGroup>
                                    <ButtonGroup
                                        options={buttonGroupOptions}
                                        onButtonClick={handleSelectButtonOptions}
                                        smallText={false}
                                    />
                                </StyledButtonGroup>
                            )}

                            {/* Product Select */}
                            <div>
                                {filterStatus.isEnableProductSelect && !productDataLoading && (
                                    <FilterSelect
                                        selectedFilterData={selectedProductsFilterData}
                                        filterOptions={productsOptions}
                                        handleFilterSelect={handleProductSelect}
                                        handleFilterClearAll={handleProductClearAll}
                                        handleFilterSelectAll={handleProductSelectAll}
                                        handleSelectedFilterRemove={handleSelectedProductRemove}
                                        handleFilterApplyOnClick={handleFilterApplyOnClick}
                                        enableFilterApplyButton={enableFilterApplyButton}
                                        hideCapsules={false}
                                        filterName={filterProductName}
                                        enableSingleSelection={!filterStatus.isEnableProductSingleSelect}
                                    />
                                )}
                            </div>

                            {/* Filterbar handlers */}
                            <FilterBarHandler
                                filterStatus={filterStatus}
                                selectedMetricsArray={selectedMetricsArray}
                                handleFilterToggle={handleFilterToggle}
                                filterExpanded={filterExpanded}
                                handleFilterClearOnClick={handleFilterClearOnClick}
                                handleFilterApplyOnClick={handleFilterApplyOnClick}
                                enableFilterApplyButton={enableFilterApplyButton}
                            />
                            <>
                                {filterStatus.isEnablePdfExport && (
                                    <PDFDownload
                                        componentRef={reportRef}
                                        filename="report"
                                        isLoading={loadingPdfDownload}
                                        setLoadingPdfDownload={() => onDownloadPdfLoading()}
                                        unSetLoadingPdfDownload={() => onDownloadPdfFinishedLoading()}
                                    />
                                )}
                            </>

                            {/* Filterbar Expand */}
                            {filterExpanded && (
                                <StyledReportFilterContainerPreview>
                                    {filterStatus.isEnableMetrics && (
                                        <FilterBarMetricFilter
                                            selectedMetricsFilterData={selectedMetricsFilterData}
                                            handleFilterSelectedMetricEditOnClick={
                                                handleFilterSelectedMetricEditOnClick
                                            }
                                            handleFilterSelectedMetricRemoveOnClick={
                                                handleFilterSelectedMetricRemoveOnClick
                                            }
                                            filterStatus={filterStatus}
                                            filterMetricsOptions={filterMetricsOptions}
                                            handleMetricsFilterMetricOnChange={handleMetricsFilterMetricOnChange}
                                            handleMetricsFilterOperatorOnChange={handleMetricsFilterOperatorOnChange}
                                            handleMetricsFilterValueOnChange={handleMetricsFilterValueOnChange}
                                            handleMetricsSelectFilterOnClick={handleMetricsSelectFilterOnClick}
                                            filterOperatorOptions={filterOperatorOptions}
                                            handleMetricsAddFilterOnClick={handleMetricsAddFilterOnClick}
                                            handleMetricsRemoveFilterOnClick={handleMetricsRemoveFilterOnClick}
                                            showAddMetricFilter={showAddMetricFilter}
                                        />
                                    )}

                                    <StyledHandlerButton>
                                        <CommonButton
                                            onClick={handleFilterApplyOnClick}
                                            disabled={!enableFilterApplyButton}
                                            value="Apply"
                                        />
                                        <CommonButton
                                            onClick={handleFilterClearOnClick}
                                            value="Clear"
                                            type="secondary"
                                        />
                                    </StyledHandlerButton>
                                </StyledReportFilterContainerPreview>
                            )}
                        </StyledReportFilterContainerPreview>
                    </StyledReportFilterContainer>

                    <div className="clear" />
                </StyledReportFilter>
            )}
            {/* Channel Signpost */}
            {filterStatus.isEnableChannelSingleSelect?.enabled ? <FilterBarChannelSignpost /> : null}
        </>
    );
};

export default memo(FilterBar, isEqual);
