import { useEffect, useState } from 'react';
import { RequestFilter, RequestSort, SectionDashboardRequest } from '../../types';
import { CubedField } from '../../../types';
import { isEqual } from 'lodash';
import { defaultRequestLimit } from '../../configurations/default-request-limit';
import { CATEGORY_MIX } from '../../../configurations/fields';

const useRequestPieChartTable = (request: SectionDashboardRequest) => {
    const limit = request.rowsPerPage || defaultRequestLimit;

    const [pieChartTableRequest, setPieChartTableRequest] = useState<SectionDashboardRequest>({
        ...request,
        offset: 0,
        limit: limit,
    });

    const actions = () => {
        return {
            setChartField: (chartField: CubedField) => {
                setPieChartTableRequest(prevRequest => ({
                    ...prevRequest,
                    chartField,
                    ...(chartField !== CATEGORY_MIX && {
                        orderBy: [
                            {
                                field: chartField,
                                orderByDirection: 'desc',
                            },
                        ],
                    }),
                }));
            },
            setOrderBy: (currentFilters: RequestSort[]) => {
                setPieChartTableRequest(prevRequest => ({ ...prevRequest, orderBy: currentFilters }));
            },
            setFilters: (currentFilters: RequestFilter[]) => {
                setPieChartTableRequest(prevRequest => ({ ...prevRequest, tableFilters: currentFilters }));
            },
            increaseOffset: () => {
                setPieChartTableRequest(prevRequest => ({
                    ...prevRequest,
                    offset: prevRequest.offset ? prevRequest.offset + limit : limit,
                    limit: limit,
                }));
            },
            decreaseOffset: () => {
                setPieChartTableRequest(prevRequest => ({
                    ...prevRequest,
                    offset: prevRequest.offset && prevRequest.offset - limit,
                    limit: limit,
                }));
            },
            jumpToFirst: () => {
                setPieChartTableRequest(prevRequest => ({ ...prevRequest, offset: 0, limit: limit }));
            },
            jumpToLast: (totalPages: number) => {
                setPieChartTableRequest(prevRequest => ({
                    ...prevRequest,
                    offset: limit * totalPages - limit,
                    limit: limit,
                }));
            },
        };
    };

    useEffect(() => {
        setPieChartTableRequest({ ...pieChartTableRequest, ...actions() });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (request.filters) {
            if (!isEqual(request.filters, pieChartTableRequest.filters)) {
                setPieChartTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }

        if (request.sectionFilters) {
            if (!isEqual(request.sectionFilters, pieChartTableRequest.sectionFilters)) {
                setPieChartTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }

        if (request.pageFilters) {
            if (!isEqual(request.pageFilters, pieChartTableRequest.pageFilters)) {
                setPieChartTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }

        if (request.groupBy) {
            if (!isEqual(request.groupBy, pieChartTableRequest.groupBy)) {
                setPieChartTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }

        if (request.fields) {
            if (!isEqual(request.fields, pieChartTableRequest.fields)) {
                setPieChartTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }

        if (request.showLoading !== pieChartTableRequest.showLoading) {
            setPieChartTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
        }

        if (request.showEmpty !== pieChartTableRequest.showEmpty) {
            setPieChartTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
        }

        if (request.rowsPerPage) {
            if (!isEqual(request.rowsPerPage, pieChartTableRequest.rowsPerPage)) {
                setPieChartTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

    return pieChartTableRequest;
};

export default useRequestPieChartTable;
