import React from 'react';
import styled from 'styled-components';

// Components
import LibraryListCategoryTitle from './library-list-category-title';
import LibraryListList from './library-list-list';
import LibraryListListItem from './library-list-list-item';
import LibraryListCell from './library-list-cell';
import LibraryListCellFlex from './library-list-cell-flex';
import LibraryListCellTitle from './library-list-cell-title';
import LibraryListCheckbox from './library-list-checkbox';

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 16px;
`;

const LibraryList = ({ children }: { children: React.ReactNode }) => {
    return <StyledContainer>{children}</StyledContainer>;
};

LibraryList.CategoryTitle = LibraryListCategoryTitle;
LibraryList.List = LibraryListList;
LibraryList.ListItem = LibraryListListItem;
LibraryList.ListItemCell = LibraryListCell;
LibraryList.CellFlex = LibraryListCellFlex;
LibraryList.CellTitle = LibraryListCellTitle;
LibraryList.Checkbox = LibraryListCheckbox;

export default LibraryList;
