// React Dependencies
import React, { useEffect, useState } from 'react';

// Libraries
import axios from 'axios';

// Redux
import { connect, useSelector } from 'react-redux';
import { setModal, removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setPopup, removePopup } from '../../redux/actions/popup';

// Helpers & Reference Objects
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { GroupRule } from '../../helpers/segmenter/group-rule';
import { OPERATORS, SEGMENTER_HEADER } from '../../helpers/segmenter/reference-objects';
import { findButtonStates } from '../../helpers/segmenter/button-states';
import { generateTableRowProperty } from '../../helpers/generate-table-styles-property';
import { ButtonThemes } from '../../enums/button-themes';

// Components
import CreateSegmentGroup from './create-segment-group';
import LoadingSpinner from '../../components/loading-spinner';
import FormTextInput from '../../components/form-fields/form-text-input';
import FormTextArea from '../../components/form-fields/form-text-area';
import WarningMessage from '../../components/warning-message';
import WidgetActionSimpleTable from '../../components/tables/widgets/widget-action-simple-table';
import LayoutModalNavigation from '../../components/navigation/layout-modal-navigation';
import ActionButtons from '../../components/navigation/action-buttons';
import InputButton from '../../components/inputs/input-button';
import styled from 'styled-components';

// API SECTIONS
import ToggleWithLabels from '../../components/inputs/toggle-with-labels';
import FormLabel from '../../components/form-fields/form-label';

// Styled Components
import { StyledH1, StyledP } from './create-conditions';
import {
    StyledFormContainer,
    StyledApiSelectorContainer,
    StyledButtonContainer,
    StyledAddConditionButtonContainer,
    StyledLabelContainer,
} from './create-segment';

import { StyledPatternContainer, StyledSegmentContainer } from './segmenter-rule-builder';

const StyledSubHeader = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const SubHeaderParagraph = styled.span`
    flex: 4 3;
    display: inline;
    line-height: 20px;
    margin: 0;
`;
const SubHeaderButton = styled.span`
    font-size: 10px;
    display: inline;
    margin: 0;
`;

const LayoutModalManageSegments = props => {
    const [conditions, setConditions] = useState([]);
    const [rule, setRule] = useState(new GroupRule());

    // Page States
    const [pageLoading, setPageLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [disableTableClick, setDisableTableClick] = useState(false);
    const [showEditSegmenter, setShowEditSegmenter] = useState(false);

    // Data returned from api
    const [segmenterRuleData, setSegmenterRuleData] = useState([]);
    const [segmenterRuleDataToEdit, setSegmenterRuleDataToEdit] = useState([]);

    // Selected values
    const [selectedSegments, setSelectedSegments] = useState([]);
    const [updatedSegments, setUpdatedSegments] = useState([]);
    const [deletedSegments, setDeletedSegments] = useState([]);
    const [segmentCurrentlyEditing, setSegmenterCurrentlyEditing] = useState({});

    // Button States
    const [newButtonDisabled, setNewButtonDisabled] = useState(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState(true);
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
    const [deleteButtonUndo, setDeleteButtonUndo] = useState(false);
    const [saveChangesButtonHidden, setSaveChangedButtonHidden] = useState(true);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState(false);

    // arbitrary state to use to ensure a rerender when the conditions and rules are updated in the GroupRule class
    const [count, setCount] = useState(0);

    const [apiTargetList, setApiTargetList] = useState([]);
    const [apiTargetExportList, setApiTargetExportList] = useState([]);
    const [apiTargetExportListUnedited, setApiTargetExportListUnedited] = useState([]);
    const [listOfChangedTargets, setListOfChangedTargets] = useState([]);
    const [exportedTargetofSegmenter, setExportedTargetofSegmenter] = useState([]);
    const [exportedTargetofSegmenterUnedited, setExportedTargetofSegmenterUnedited] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);

    const accountToken = useSelector(state => state.account.token);

    // on page load - fetch conditions and functions data
    useEffect(() => {
        fetchAllData(); // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getexportedTargetofSegmenter();
    }, [segmenterRuleData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (apiTargetExportList.length > 0) {
            getexportedTargetofSegmenter();
        }
    }, [apiTargetExportList]); // eslint-disable-line react-hooks/exhaustive-deps

    // manage status of save button
    useEffect(() => {
        if (updatedSegments.length > 0 || deletedSegments.length > 0 || listOfChangedTargets.length > 0) {
            setSaveChangedButtonHidden(false);
        }
        if (listOfChangedTargets.length === 0 && updatedSegments.length === 0 && deletedSegments.length === 0) {
            setSaveChangedButtonHidden(true);
        }
    }, [updatedSegments, deletedSegments, listOfChangedTargets]);

    // update final pattern every time there is a change to rules
    useEffect(() => {
        setSegmenterCurrentlyEditing({
            ...segmentCurrentlyEditing,
            pattern: `(${rule.serialize()})`,
        });
    }, [rule, count]); // eslint-disable-line react-hooks/exhaustive-deps

    // update button states depending on selectedSegments
    useEffect(() => {
        const buttonStates = findButtonStates(selectedSegments, deletedSegments, updatedSegments, showEditSegmenter);

        setEditButtonDisabled(buttonStates.editButtonDisabled);
        setDeleteButtonDisabled(buttonStates.deleteButtonDisabled);
        setDeleteButtonUndo(buttonStates.deleteButtonUndo);
        setNewButtonDisabled(buttonStates.newButtonDisabled);
    }, [selectedSegments, deletedSegments, updatedSegments, showEditSegmenter]);

    const getCheckedTargetsList = segmenterId => {
        const listOfTargets = exportedTargetofSegmenter;
        const listOfTargetsUnedited = exportedTargetofSegmenterUnedited;
        const targetLists = [];
        const targetListsUnedited = [];
        const segmenterExport = apiTargetExportList.filter(item => {
            return item.segmenter.id === segmenterId;
        });

        if (segmenterExport.length > 0) {
            apiTargetList.forEach(target => {
                const listOfTarget = {
                    id: target.id,
                    name: target.name,
                    resource_uri: target.resource_uri,
                    checked: target.checked,
                };
                const listOfTargetUnedited = {
                    id: target.id,
                    name: target.name,
                    resource_uri: target.resource_uri,
                    checked: target.checked,
                };
                const idInExports = segmenterExport.find(item => target.id === item.segmenter_target.id);
                if (idInExports) {
                    listOfTarget.checked = true;
                    listOfTargetUnedited.checked = true;

                    targetLists.push(listOfTarget);
                    targetListsUnedited.push(listOfTargetUnedited);
                }
            });
            listOfTargets.push({
                segmenterId: segmenterId,
                targetList: targetLists,
            });
            listOfTargetsUnedited.push({
                segmenterId: segmenterId,
                targetList: targetListsUnedited,
            });
        } else {
            apiTargetList.forEach(target => {
                const listOfTarget = {
                    id: target.id,
                    name: target.name,
                    resource_uri: target.resource_uri,
                    checked: target.checked,
                };
                const listOfTargetUnedited = {
                    id: target.id,
                    name: target.name,
                    resource_uri: target.resource_uri,
                    checked: target.checked,
                };
                targetLists.push(listOfTarget);
                targetListsUnedited.push(listOfTargetUnedited);
            });

            listOfTargets.push({
                segmenterId: segmenterId,
                targetList: targetLists,
            });
            listOfTargetsUnedited.push({
                segmenterId: segmenterId,
                targetList: targetListsUnedited,
            });
        }
        setExportedTargetofSegmenter(listOfTargets);
        setExportedTargetofSegmenterUnedited(listOfTargetsUnedited);
    };

    const getexportedTargetofSegmenter = () => {
        if (exportedTargetofSegmenter.length === 0 && exportedTargetofSegmenterUnedited.length === 0) {
            segmenterRuleData.map(segmenter => getCheckedTargetsList(segmenter.segmenter.id));
        }
    };

    const stringSplit = name => {
        if (name === null || undefined || '') return;

        const nameArray = name.split(' - ');
        nameArray.shift();
        return nameArray.join(' - ');
    };

    const fetchAllData = () => {
        const requests = [];

        // get segmenter data
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter', []),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get segmenter data
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter-rule', [{ key: 'active', value: true }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get segmenter data
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter-condition'),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get all target pull
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter-target'),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get segmenter target exports data
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter-exports', [{ key: 'active', value: '1' }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    const segmenterRuleData = responses[1].data.objects;

                    const conditionsData = responses[2].data.objects.map(condition => {
                        return {
                            id: condition.id,
                            functionName: condition.function.display_name,
                            relatedTable: condition.function.table_name,
                            relatedTableKey: condition.key,
                            operator: condition.function_operator.name,
                            inputValue: condition.value,
                            label: condition.active ? condition.display_name : stringSplit(condition.display_name),
                            value: condition.id,
                            active: condition.active,
                        };
                    });

                    if (responses[3].data.objects.length > 0) {
                        const targetLists = responses[3].data.objects.map(target => {
                            return {
                                id: target.id,
                                name: target.name,
                                resource_uri: target.resource_uri,
                                checked: false,
                            };
                        });
                        setApiTargetList(targetLists);
                    }

                    const segmenterTargetExports = responses[4].data.objects;

                    setSegmenterRuleData(segmenterRuleData);
                    setSegmenterRuleDataToEdit(segmenterRuleData);
                    setConditions(conditionsData);
                    setApiTargetExportList(segmenterTargetExports);
                    setApiTargetExportListUnedited(JSON.parse(JSON.stringify(segmenterTargetExports)));
                })
            )
            .catch(e => {
                console.log(e);
                setPageError(true);
            })
            .finally(() => {
                setPageLoading(false);
            });
    };

    const handleSelectTableRow = id => {
        if (disableTableClick) return;

        if (selectedSegments.filter(condition => condition.id === id).length > 0) {
            setSelectedSegments([...selectedSegments.filter(condition => condition.id !== id)]);
        } else {
            setSelectedSegments([...selectedSegments, segmenterRuleDataToEdit.find(condition => condition.id === id)]);
        }
    };

    const handleCreateNew = () => {
        props.setModal('SegmenterCreateSegment', {});
    };
    const handleEditClick = () => {
        const segmentToEdit = { ...selectedSegments[0] };

        setSegmenterCurrentlyEditing(segmentToEdit);
        setShowEditSegmenter(true);
        setDisableTableClick(true);

        const group = rule.deserialize(segmentToEdit.pattern, conditions);
        setRule(group);
        setListOfChangedTargets([]);
    };

    const handleDeleteClick = () => {
        if (deleteButtonUndo) {
            setDeletedSegments([...deletedSegments.filter(segmenter => !selectedSegments.includes(segmenter))]);
            setSelectedSegments([]);
        } else {
            // if the segmenter has been edited then delete should take priority
            const deletedIds = deletedSegments.map(segmenter => segmenter.id);
            const filteredUpdated = updatedSegments.filter(segmenter => !deletedIds.includes(segmenter.id));
            setUpdatedSegments(filteredUpdated);
            setDeletedSegments([...deletedSegments, ...selectedSegments]);
            setSelectedSegments([]);
        }
    };

    const handlePopupDiscardClick = () => {
        props.removePopup();
        props.removeModal();
    };

    const handlePopupStayClick = () => {
        props.removePopup();
    };

    const handleSaveChanges = e => {
        setSaveChangesButtonLoading(true);
        const requests = [];

        // patch to condition durations to update duration unit
        if (updatedSegments.length > 0) {
            requests.push(
                axios({
                    method: 'PATCH',
                    url: generateUrl('segmenter', 'segmenter-rule'),
                    data: { objects: updatedSegments },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        }

        if (listOfChangedTargets.length > 0) {
            listOfChangedTargets.forEach(targetItem => {
                if (targetItem.checked === false) {
                    const currentSegmenterExport = apiTargetExportListUnedited.find(
                        item =>
                            item.segmenter.id === targetItem.segmenterId &&
                            item.segmenter_target.id === targetItem.targetId
                    );
                    requests.push(
                        axios({
                            method: 'DELETE',
                            url: generateUrlDetail('segmenter', 'segmenter-exports', currentSegmenterExport.id),
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                } else if (targetItem.checked === true) {
                    const target_resource = `/api/segmenter/segmenter-target/${targetItem.targetId}/`;
                    const segmenter_resource = `/api/segmenter/segmenter/${targetItem.segmenterId}/`;
                    const data = {
                        segmenter_target: target_resource,
                        segmenter: segmenter_resource,
                    };
                    requests.push(
                        axios({
                            method: 'POST',
                            url: generateUrl('segmenter', 'segmenter-exports'),
                            data: data,
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                }
            });
        }

        if (deletedSegments.length > 0) {
            deletedSegments.forEach(segment => {
                requests.push(
                    axios({
                        method: 'DELETE',
                        url: generateUrlDetail('segmenter', 'segmenter-rule', segment.id),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
        }

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    setSaveChangesButtonLoading(false);
                    props.addNotification({
                        copy: 'These changes have been successfully saved.',
                        type: NotificationMessageType.Success,
                    });
                    resetTheExportedTargetList(listOfChangedTargets);
                    setUpdatedSegments([]);
                    setDeletedSegments([]);
                    setListOfChangedTargets([]);
                    setSaveChangedButtonHidden(true);

                    if (deletedSegments.length > 0) {
                        const filteredSegmenters = segmenterRuleData.filter(
                            ({ id: id1 }) => !deletedSegments.some(({ id: id2 }) => id2 === id1)
                        );

                        setSegmenterRuleData(filteredSegmenters);
                        setSegmenterRuleDataToEdit(filteredSegmenters);
                    }

                    axios({
                        method: 'GET',
                        url: generateUrl('segmenter', 'segmenter-exports', [{ key: 'active', value: '1' }]),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then(response => {
                        const tempExportList = response.data.objects;
                        setApiTargetExportList(tempExportList);
                        setApiTargetExportListUnedited(tempExportList);
                    });
                })
            )
            .catch(e => {
                console.log(e);
                props.addNotification({
                    copy: 'There was an issue trying to save your condition. Please try again later or contact Cubed Support.',
                    type: NotificationMessageType.Error,
                });
            });
    };

    const resetTheExportedTargetList = listOfChangedTargets => {
        listOfChangedTargets.forEach(item => {
            const index = exportedTargetofSegmenter.findIndex(
                nestedItem => nestedItem.segmenterId === item.segmenterId
            );
            const targetIndex = exportedTargetofSegmenter[index].targetList.findIndex(
                nestedItem => nestedItem.id === item.targetId
            );
            exportedTargetofSegmenter[index].targetList[targetIndex].checked = item.checked;
        });
        setExportedTargetofSegmenterUnedited(JSON.parse(JSON.stringify(exportedTargetofSegmenter)));
    };

    const handleCloseClick = () => {
        if (updatedSegments.length === 0 && deletedSegments.length === 0) {
            props.removeModal();
        } else {
            props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost',
                },
                buttons: [
                    {
                        onClick: handlePopupDiscardClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: handlePopupStayClick,
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            });
        }
    };

    const checkForChanges = (originalSegment, editingSegment) => {
        const nameChanged = originalSegment.segmenter.name !== editingSegment.segmenter.name;
        const descriptionChanged = originalSegment.segmenter.description !== editingSegment.segmenter.description;
        const patternCHanged = originalSegment.pattern !== editingSegment.pattern;
        const changed = nameChanged || descriptionChanged || patternCHanged;
        return changed;
    };

    const handleApplyForSegmenterExports = () => {
        const currentlyEditing = exportedTargetofSegmenter.find(
            exports => exports.segmenterId === segmentCurrentlyEditing.segmenter.id
        );
        const original = exportedTargetofSegmenterUnedited.find(
            exports => exports.segmenterId === segmentCurrentlyEditing.segmenter.id
        );

        const currentlyEditingFiltered = currentlyEditing.targetList.filter(
            (item, index) => item.checked !== original.targetList[index].checked
        );

        let tempListOfChangedTargets = currentlyEditingFiltered.map((item, index) => {
            return {
                segmenterId: segmentCurrentlyEditing.segmenter.id,
                targetId: item.id,
                checked: item.checked,
            };
        });

        if (currentlyEditingFiltered.length === 0) {
            setListOfChangedTargets([]);
            tempListOfChangedTargets = [];
        } else {
            setListOfChangedTargets(tempListOfChangedTargets);
        }
        return tempListOfChangedTargets;
    };

    const handleApplyClick = e => {
        e.preventDefault();

        const tempListOfChangedTargets = handleApplyForSegmenterExports();

        const originalSegment = segmenterRuleData.find(segmenter => segmenter.id === segmentCurrentlyEditing.id);

        if (checkForChanges(originalSegment, segmentCurrentlyEditing) || tempListOfChangedTargets.length > 0) {
            // check if the condition is already uin updated, if so update it
            if (updatedSegments.some(segmenter => segmenter.id === segmentCurrentlyEditing.id)) {
                const segments = updatedSegments.map(segmenter =>
                    segmenter.id !== segmentCurrentlyEditing.id ? segmenter : segmentCurrentlyEditing
                );
                setUpdatedSegments(segments);
            } else {
                // if the segmenter isn't already updated, add it to the updated segmenters
                setUpdatedSegments([...updatedSegments, segmentCurrentlyEditing]);
            }
        } else {
            // if the segmenter has been updated but then reverted, remove it from updated conditions
            if (updatedSegments.some(segmenter => segmenter.id === segmentCurrentlyEditing.id)) {
                const updated = updatedSegments.filter(segmenter => segmenter.id !== segmentCurrentlyEditing.id);
                setUpdatedSegments(updated);
            }
        }

        // update to segmenters to show changes
        const tempSegmenters = segmenterRuleDataToEdit.map(segmenter => {
            if (segmentCurrentlyEditing.id === segmenter.id) {
                return segmentCurrentlyEditing;
            } else {
                return segmenter;
            }
        });

        setSegmenterRuleDataToEdit(tempSegmenters);

        setShowEditSegmenter(false);
        setSegmenterCurrentlyEditing();
        setSelectedSegments([]);
        setDisableTableClick(false);
    };

    const handleCancelEdit = e => {
        e.preventDefault();
        setShowEditSegmenter(false);
        setSegmenterCurrentlyEditing();
        setSelectedSegments([]);
        setListOfChangedTargets([]);
        setDisableTableClick(false);
    };

    const handleChangeSegmentName = e => {
        const segmenterEditing = {
            ...segmentCurrentlyEditing,
            segmenter: {
                ...segmentCurrentlyEditing.segmenter,
                name: e.target.value,
            },
        };
        setSegmenterCurrentlyEditing(segmenterEditing);
    };

    const handleChangeSegmentDescription = e => {
        const segmenterEditing = {
            ...segmentCurrentlyEditing,
            segmenter: {
                ...segmentCurrentlyEditing.segmenter,
                description: e.target.value,
            },
        };
        setSegmenterCurrentlyEditing(segmenterEditing);
    };

    const handleAddConditions = (groupItem, condition) => {
        groupItem.addCondition(condition);
        // trigger rerender
        setCount(count + 1);
    };

    const handleAddGroup = groupItem => {
        groupItem.addGroup(OPERATORS[0]);
        // trigger rerender
        setCount(count + 1);
    };

    const handleRemoveCondition = (groupItem, condition) => {
        groupItem.removeCondition(condition);
        // trigger rerender
        setCount(count + 1);
    };

    const handleRemoveGroup = groupItem => {
        const iterateGroupItem = obj => {
            obj.groupItems = obj.groupItems.filter(item => item.id !== groupItem.id);
            obj.groupItems.forEach(groupItem => iterateGroupItem(groupItem));
        };
        const tempRules = rule.clone();
        iterateGroupItem(tempRules);
        setRule(tempRules);
    };

    const handleUpdateOperator = (groupItem, operator) => {
        groupItem.updateOperator(operator);
        // trigger rerender
        setCount(count + 1);
    };

    const handleChangeToggle = (idx, segmenterId) => {
        exportedTargetofSegmenter.forEach((target, index) => {
            if (target.segmenterId === segmenterId) {
                exportedTargetofSegmenter[index].targetList[idx].checked =
                    !exportedTargetofSegmenter[index].targetList[idx].checked;
            }
        });
    };

    const renderToggleWithLables = () => {
        const target = exportedTargetofSegmenter.find(item => item.segmenterId === selectedSegments[0].segmenter.id);
        if (target) {
            return (
                <>
                    <StyledLabelContainer>
                        <FormLabel
                            key={target.segmenterId}
                            label={'EXPORTS:'}
                            toolTipCopy={'Select all the apis where you want to export the current segment.'}
                        />
                    </StyledLabelContainer>
                    {target.targetList.map((target, index) => {
                        return (
                            <StyledApiSelectorContainer key={target.id}>
                                <ToggleWithLabels
                                    key={target.id}
                                    checked={target.checked}
                                    onClick={() => handleChangeToggle(index, selectedSegments[0].segmenter.id)}
                                    singleLabel={target.name}
                                />
                            </StyledApiSelectorContainer>
                        );
                    })}
                </>
            );
        }
    };
    const findExportStatus = segmenterId => {
        let containsExport = 'No';
        if (exportedTargetofSegmenter.length > 0) {
            const segmentExportList = exportedTargetofSegmenter.find(item => item.segmenterId === segmenterId);
            if (typeof segmentExportList.targetList.find(item => item.checked === true) !== 'undefined') {
                containsExport = 'Yes';
            }
        } else {
            const containsSegment = apiTargetExportList.find(item => item.segmenter.id === segmenterId);
            if (typeof containsSegment !== 'undefined') {
                containsExport = 'Yes';
            }
        }
        return containsExport;
    };
    const downloadSegmentsCSV = async () => {
        setIsDownloading(true);

        const url = generateUrlDetail(accountToken, 'download', 'segmenter-visitor-rows', [], false);
        const filename = accountToken + '-segments.csv';
        return await axios({
            method: 'GET',
            url: url,
            responseType: 'blob',
            withCredentials: true,
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false);
        });
    };
    let rows = pageLoading
        ? {} // Wait for page to load and then do mapping
        : segmenterRuleDataToEdit.map(data => {
              return {
                  onClick: () => handleSelectTableRow(data.id),
                  keyValue: `segment__${data.id}`,
                  dataValue: data.id,
                  rowProperty: generateTableRowProperty(
                      data,
                      selectedSegments,
                      updatedSegments,
                      deletedSegments,
                      disableTableClick
                  ),
                  columns: [
                      {
                          copy: data.segmenter?.name,
                      },
                      {
                          copy: data.segmenter?.description,
                      },
                      {
                          copy: data.segmenter?.id,
                      },
                      {
                          copy: findExportStatus(data.segmenter.id),
                      },
                  ],
              };
          });

    if (pageError) {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={false}
                    updatedData={updatedSegments}
                    deletedData={deletedSegments}
                    handleSaveChanges={handleSaveChanges}
                    saveChangesButtonHidden={saveChangesButtonHidden}
                    saveChangesButtonLoading={saveChangesButtonLoading}
                    handleCloseClick={handleCloseClick}
                />
                <StyledH1>Manage Segments</StyledH1>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </>
        );
    }
    if (!pageLoading) {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={false}
                    updatedData={updatedSegments}
                    deletedData={deletedSegments}
                    handleSaveChanges={handleSaveChanges}
                    saveChangesButtonHidden={saveChangesButtonHidden}
                    saveChangesButtonLoading={saveChangesButtonLoading}
                    handleCloseClick={handleCloseClick}
                />

                <StyledH1>Manage Segments</StyledH1>

                <StyledSubHeader>
                    <SubHeaderParagraph>
                        <StyledP>
                            Add, edit or delete existing segments. Customer segments are built using a combination of
                            one, or many conditions.
                        </StyledP>
                    </SubHeaderParagraph>

                    <SubHeaderButton>
                        <InputButton
                            value={'Export Segments as CSV'}
                            onClick={downloadSegmentsCSV}
                            isLoading={isDownloading}
                            buttonTheme={ButtonThemes.Secondary}
                        />
                    </SubHeaderButton>
                </StyledSubHeader>

                <WidgetActionSimpleTable
                    rowData={rows}
                    isFrom="Segment"
                    header={SEGMENTER_HEADER}
                    errorMessageOverride="You have not created any segment rules yet."
                    newButtonDisabled={newButtonDisabled}
                    handleCreateNew={handleCreateNew}
                    editButtonDisabled={editButtonDisabled}
                    handleEditClick={handleEditClick}
                    deleteButtonUndo={deleteButtonUndo}
                    deleteButtonDisabled={deleteButtonDisabled}
                    handleDeleteClick={handleDeleteClick}
                    isScrollable={false}
                />
                {showEditSegmenter && (
                    <>
                        <StyledFormContainer>
                            <FormTextInput
                                inputPlaceholder="Enter a name for your Segment"
                                label="Segment Name:"
                                toolTipCopy="Enter a name for your Segment"
                                inputOnChange={handleChangeSegmentName}
                                inputValue={segmentCurrentlyEditing.segmenter.name}
                                requiredField={true}
                            />
                            <FormTextArea
                                label="Description:"
                                toolTipCopy="Enter a description for your Segment"
                                inputValue={segmentCurrentlyEditing.segmenter.description}
                                inputOnChange={handleChangeSegmentDescription}
                            />
                            {renderToggleWithLables()}
                        </StyledFormContainer>

                        <StyledPatternContainer>
                            {segmentCurrentlyEditing.segmenter.name
                                ? segmentCurrentlyEditing.segmenter.name
                                : 'Segment'}
                            :{segmentCurrentlyEditing.pattern.substring(1, segmentCurrentlyEditing.pattern.length - 1)}
                        </StyledPatternContainer>

                        <StyledSegmentContainer>
                            <CreateSegmentGroup
                                isFrom="Edit"
                                key={rule.id}
                                rules={rule}
                                groupItem={rule}
                                operators={OPERATORS}
                                conditions={conditions}
                                nested={false}
                                onAddCondition={handleAddConditions}
                                onRemoveCondition={handleRemoveCondition}
                                onAddGroup={handleAddGroup}
                                onUpdateOperator={handleUpdateOperator}
                                onRemoveGroup={handleRemoveGroup}
                            />
                        </StyledSegmentContainer>

                        <StyledButtonContainer>
                            <StyledAddConditionButtonContainer>
                                <InputButton
                                    value={`CREATE NEW CONDITION`}
                                    onClick={() => props.setModal('SegmenterEditConditions')}
                                />
                            </StyledAddConditionButtonContainer>
                            <ActionButtons handleApplyClick={handleApplyClick} handleCancelEdit={handleCancelEdit} />
                        </StyledButtonContainer>
                    </>
                )}
            </>
        );
    } else {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={false}
                    updatedData={updatedSegments}
                    deletedData={deletedSegments}
                    handleSaveChanges={handleSaveChanges}
                    saveChangesButtonHidden={saveChangesButtonHidden}
                    saveChangesButtonLoading={saveChangesButtonLoading}
                    handleCloseClick={handleCloseClick}
                />

                <StyledH1>Manage Segments</StyledH1>

                <LoadingSpinner />
            </>
        );
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        removeModal: () => {
            dispatch(removeModal());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(null, mapDispatchToProps)(LayoutModalManageSegments);
