export function setPopup(popup) {
    return {
        type: 'SET_POPUP',
        payload: popup,
    };
}

export function removePopup() {
    return {
        type: 'DEL_POPUP',
    };
}
