import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Hooks
import withRouter from '../hooks/with-router';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';

// Configurations
import { FILTER_CONFIG } from '../configurations/filter-config';
import { CONFIG_DASH_REFERER } from '../../configurations/resources-config';

// Enums
import { filterType } from '../enums/filter-types';

// Helpers
import { getOperatorOptions, mapTransitionStyles } from '../helpers/helper';
import buildDropdownOptions from '../../react-query/select-functions/build-dropdown-options';
import buildDropdownResource from '../../react-query/helpers/build-dropdown-resource';
import handleLocationHash from '../../helpers/handle-location-hash';

import { ACTIONS } from '../helpers/actions';
import { ReportFilterReducer } from '../helpers/reducer';

// Redux
import { RootState } from '../../redux/reducers/core';

// Fetch Functions
import { fetchChannels, fetchOperators, fetchProducts } from './filter-bar-fetch-functions';

// Types
import {
    ActionButtonGroupSet,
    ActionChannelFilterDataSet,
    ActionChannelFilterInitialDataSet,
    ActionFilterApply,
    ActionFilterChannelClear,
    ActionFilterClear,
    ActionFilterExpandCollapse,
    ActionMetricsFilterAddNewFilter,
    ActionMetricsFilterChangeMetricData,
    ActionMetricsFilterChangeOperatorData,
    ActionMetricsFilterChangeValueData,
    ActionMetricsFilterEditSelectedFilter,
    ActionMetricsFilterRemoveNewFilter,
    ActionMetricsFilterRemoveSelectedFilter,
    ActionMetricsFilterSelectNewFilter,
    ActionProductFilterDataSet,
    ActionProductFilterInitialDataSet,
    ButtonGroupOptions,
    Channel,
    FilterBarComparisonStatus,
    FilterBarContextProps,
    FilterBarContextValues,
    FilterBarStatus,
    Metric,
    MetricsFilter,
    Operator,
    OperatorResponse,
    Product,
    ReducerActions,
} from '../types';
import { DropdownOption, SelectOptionsRequest, RequestStatus } from '../../section-dashboard/types';
import { FilterSelectFilter } from '../types';
import { ConfigDataSuccess } from '../../react-query/types';

export const FilterBarContext = createContext<FilterBarContextValues>({} as FilterBarContextValues);

const FilterBarProvider = ({ children }: FilterBarContextProps) => {
    const { FILTER, DEFAULT_FILTER_STATUS, DEFAULT_COMPARISON_FILTER_STATUS, FILTERS_OBJECT } = FILTER_CONFIG;

    const router = useLocation();
    const navigate = useNavigate();
    const accountToken = useSelector((state: RootState) => state.account.token);

    // Date picker state
    const [datePickerConfig, setDatePickerConfig] = useState({});
    const [comparisonDatePickerConfig, setComparisonDatePickerConfig] = useState({});

    const [buttonGroupOptions, setButtonGroupOptions] = useState<ButtonGroupOptions[]>([]);

    // Product states
    const [productDataLoading, setProductDataLoading] = useState(true);
    const [selectedProductsData, setSelectedProductsData] = useState<Product[]>([]);
    const [productsOptions, setProductsOptions] = useState<Product[]>([]);
    const [productsInitialsOptions, setProductsInitialsOptions] = useState<Product[]>([]);
    const filterProductName = filterType.GOALS;

    // Metric state
    const [selectedMetricsData, setSelectedMetricsData] = useState<MetricsFilter[]>([]);

    // Description states
    const [description, setDescription] = useState('');

    // Channels
    const [channelOptions, setChannelOptions] = useState<Channel[]>([]);

    // Channel states for multi select
    const [channelDataLoading, setChannelDataLoading] = useState(true);
    const [selectedChannelData, setSelectedChannelData] = useState<Channel[]>([]);
    const [channelsInitialsOptions, setChannelsInitialsOptions] = useState<Channel[]>([]);
    const filterChannelName = filterType.CHANNELS;

    // Filter state
    const [reportFilterState, reportFilterDispatch] = useReducer<
        (state: typeof FILTER_CONFIG.FILTER, action: ReducerActions) => any
    >(ReportFilterReducer, FILTER);
    const [filterStatus, setFilterStatus] = useState<FilterBarStatus>(DEFAULT_FILTER_STATUS);
    const [comparisonFilterStatus, setComparisonFilterStatus] = useState<FilterBarComparisonStatus>(
        DEFAULT_COMPARISON_FILTER_STATUS
    );
    const [filterMetricsOriginalData, setFilterMetricsOriginalData] = useState([]);
    const [filterMetricsOptions, setFilterMetricsOptions] = useState([]);
    const [initialFilterMetricsOptions, setInitialFilterMetricsOptions] = useState([]);
    const [operatorResponse, setOperatorResponse] = useState<OperatorResponse[]>([]);
    const [filterOperatorOptions, setFilterOperatorOptions] = useState<Operator[]>([]);

    // Channel states for single select
    const [channelDropdownOptions, setChannelDropdownOptions] = useState<SelectOptionsRequest>({
        status: RequestStatus.LOADING,
        options: [],
    });
    const [selectedChannel, setSelectedChannel] = useState<DropdownOption | undefined>();

    const channels = useFetchResource<ConfigDataSuccess, DropdownOption[]>({
        resource: CONFIG_DASH_REFERER,
        params: [
            { key: 'active', value: true },
            { key: 'order_by', value: 'name' },
        ],
        staleTime: 1000 * 60 * 5, // 5 minutes,
        select: data => buildDropdownOptions({ data: data, labelField: 'name', valueField: 'id' }),
    });

    useEffect(() => {
        const channelDropdownResource = buildDropdownResource(channels);
        setChannelDropdownOptions(channelDropdownResource);

        if (channelDropdownResource.status === RequestStatus.SUCCESS) {
            const channelHash = handleLocationHash(router.hash);
            const hashChannel = channelDropdownResource.options.find(
                (option: DropdownOption) => option.label === channelHash
            );

            const directChannel = channelDropdownResource.options.find(
                (option: DropdownOption) => option.label === 'Direct'
            );
            setSelectedChannel(hashChannel || directChannel || channelDropdownResource.options[0]);
        }
    }, [router, channels.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Reset products
        const filteredProductsOptions = productsInitialsOptions.filter(productsOption => productsOption.default);

        reportFilterDispatch({
            type: ACTIONS.FILTER_CLEAR as ActionFilterClear['type'],
            payload: { product: filteredProductsOptions as unknown as Product },
        });
        setSelectedMetricsData([]);
        setProductsOptions(productsInitialsOptions);
        setSelectedProductsData(filteredProductsOptions);

        // Reset channels
        const filteredChannelsOptions = channelsInitialsOptions.filter(channelsOption => channelsOption.default);

        reportFilterDispatch({
            type: ACTIONS.FILTER_CHANNEL_CLEAR as ActionFilterChannelClear['type'],
            payload: { channel: filteredChannelsOptions as unknown as Channel },
        });

        setSelectedChannelData(filteredChannelsOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    useEffect(() => {
        // Fetch products
        const products = fetchProducts();
        products.then(response => {
            if (response) {
                setProductDataLoading(false);
                setProductsOptions(response.productOptions);
                setProductsInitialsOptions(response.localProductInitialOptions);
                setSelectedProductsData(response.selectedProductOptions);
                setSelectedMetricsData([]);

                reportFilterDispatch({
                    type: ACTIONS.PRODUCT_FILTER_DATA_INITIAL_SET as ActionProductFilterInitialDataSet['type'],
                    payload: {
                        product: response.selectedProductOptions,
                        enableFilterApplyButton: false,
                    },
                });
            }
        });

        // Fetch operators
        const operators = fetchOperators();
        operators.then(response => {
            setOperatorResponse(response);
        });
    }, []);

    useEffect(() => {
        if (filterStatus.isEnableChannelMultiSelect && channelOptions.length === 0) {
            // Fetch channels
            const channels = fetchChannels();
            channels.then(channelOptions => {
                // Set multi channel states
                setChannelDataLoading(false);
                setChannelOptions(channelOptions);
                setChannelsInitialsOptions(channelOptions);
                setSelectedChannelData(channelOptions);

                reportFilterDispatch({
                    type: ACTIONS.CHANNEL_FILTER_DATA_INITIAL_SET as ActionChannelFilterInitialDataSet['type'],
                    payload: {
                        channel: channelOptions,
                        enableChannelFilterApplyButton: false,
                    },
                });
            });
        }
    }, [filterStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            filterStatus.isEnableComparisonDatePicker === undefined &&
            comparisonFilterStatus.showComparisonDatePicker === undefined
        ) {
            return;
        }
        reportFilterDispatch({
            type: ACTIONS.COMPARISON_DATE_RANGE_TOGGLE as ActionFilterExpandCollapse['type'],
            payload: comparisonFilterStatus.showComparisonDatePicker,
        });
    }, [filterStatus, comparisonFilterStatus]);

    const handleFilterToggle = useCallback(() => {
        setInitialFilterMetricsOptions(
            [...initialFilterMetricsOptions, ...filterMetricsOptions].filter(
                (v: Metric, i, a) => a.findIndex((v2: Metric) => v2.rawName === v.rawName) === i
            )
        );

        reportFilterDispatch({
            type: ACTIONS.FILTER_EXPAND_COLLAPSE as ActionFilterExpandCollapse['type'],
            payload: !reportFilterState.filterExpanded,
        });
    }, [reportFilterState, initialFilterMetricsOptions, filterMetricsOptions]);

    const handleFilterApplyOnClick = () => {
        const modifiedArray: MetricsFilter[] = [];
        reportFilterState.selectedMetricsFilterData.forEach((data: MetricsFilter) => {
            if (data.status === 'view') {
                modifiedArray.push(data);
            } else {
                if (Object.keys(data.metric).length > 0 && Object.keys(data.operator).length > 0 && data.value !== '') {
                    modifiedArray.push({
                        ...data,
                        status: 'view',
                    });
                }
            }
        });

        reportFilterDispatch({
            type: ACTIONS.FILTER_APPLY as ActionFilterApply['type'],
            payload: { selectedMetricsFilterData: modifiedArray },
        });

        setSelectedProductsData(reportFilterState.selectedProductsFilterData);
        setProductsInitialsOptions(productsOptions);
        setSelectedMetricsData(modifiedArray);
    };

    const handleFilterClearOnClick = () => {
        reportFilterDispatch({
            type: ACTIONS.FILTER_CLEAR as ActionFilterClear['type'],
            payload: { product: productsOptions.filter(object => object.default) as unknown as Product },
        });
        setFilterOperatorOptions([]);
        setSelectedMetricsData(FILTER.selectedMetricsFilterData);

        setFilterMetricsOptions(initialFilterMetricsOptions);
    };

    const handleMetricsSelectFilterOnClick = (filterData: MetricsFilter) => {
        reportFilterDispatch({
            type: ACTIONS.METRICS_FILTER_SELECT_NEW_FILTER as ActionMetricsFilterSelectNewFilter['type'],
            payload: {
                filterData,
                enableFilterApplyButton: true,
                showAddMetricFilter: false,
            },
        });

        setInitialFilterMetricsOptions(
            [...initialFilterMetricsOptions, ...filterMetricsOptions].filter(
                (v: Metric, i, a) => a.findIndex((v2: Metric) => v2.rawName === v.rawName) === i
            )
        );

        setFilterOperatorOptions([]);
    };

    const handleMetricsAddFilterOnClick = () => {
        reportFilterDispatch({
            type: ACTIONS.METRICS_FILTER_ADD_NEW_FILTER as ActionMetricsFilterAddNewFilter['type'],
            payload: true,
        });
    };

    const handleMetricsRemoveFilterOnClick = (metricData: MetricsFilter) => {
        const { id } = metricData;

        reportFilterDispatch({
            type: ACTIONS.METRICS_FILTER_REMOVE_NEW_FILTER as ActionMetricsFilterRemoveNewFilter['type'],
            payload: {
                showAddMetricFilter: false,
                id,
            },
        });
    };

    const handleFilterSelectedMetricEditOnClick = (filterData: MetricsFilter) => {
        reportFilterDispatch({
            type: ACTIONS.METRICS_FILTER_EDIT_SELECTED_FILTER as ActionMetricsFilterEditSelectedFilter['type'],
            payload: {
                filterData,
                enableFilterApplyButton: true,
                showAddMetricFilter: true,
            },
        });

        const existingOperatorsForFilter = reportFilterState.selectedMetricsFilterData
            .filter(
                (filter: MetricsFilter) =>
                    filter.id !== filterData.id && filter.metric.rawName === filterData.metric.rawName
            )
            .map((filter: MetricsFilter) => filter.operator.display_name);

        setFilterOperatorOptions(
            getOperatorOptions(operatorResponse, filterData.metric.dataType, existingOperatorsForFilter)
        );
    };

    const handleFilterSelectedMetricRemoveOnClick = (filterData: MetricsFilter) => {
        const { id } = filterData;
        reportFilterDispatch({
            type: ACTIONS.METRICS_FILTER_REMOVE_SELECTED_FILTER as ActionMetricsFilterRemoveSelectedFilter['type'],
            payload: {
                id,
                enableFilterApplyButton: true,
            },
        });
    };

    const handleMetricsFilterValueOnChange = (
        event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>,
        filterData: MetricsFilter
    ) => {
        const target = event.target as HTMLTextAreaElement;
        const value = target.value;
        const { id } = filterData;

        reportFilterDispatch({
            type: ACTIONS.METRICS_FILTER_CHANGE_VALUE_DATA as ActionMetricsFilterChangeValueData['type'],
            payload: { id, value },
        });
    };

    const handleMetricsFilterMetricOnChange = (data: Metric, filterData: MetricsFilter) => {
        const { id } = filterData;
        const { dataType } = data;

        reportFilterDispatch({
            type: ACTIONS.METRICS_FILTER_CHANGE_METRIC_DATA as ActionMetricsFilterChangeMetricData['type'],
            payload: { id, data },
        });

        const existingFiltersOperators = reportFilterState.selectedMetricsFilterData
            .filter((filter: MetricsFilter) => filter.metric.rawName === data.rawName)
            .map((filter: MetricsFilter) => filter.operator.display_name);

        setFilterOperatorOptions(getOperatorOptions(operatorResponse, dataType, existingFiltersOperators));
    };

    const handleMetricsFilterOperatorOnChange = (data: Operator, filterData: MetricsFilter) => {
        const { id } = filterData;

        reportFilterDispatch({
            type: ACTIONS.METRICS_FILTER_CHANGE_OPERATOR_DATA as ActionMetricsFilterChangeOperatorData['type'],
            payload: { id, data },
        });
    };

    const handleProductSelect = (selectedFilter: FilterSelectFilter) => {
        const newProductOptions = productsOptions.map(product => {
            if (filterStatus.isEnableProductSingleSelect) {
                if (product.id === selectedFilter.id) {
                    product = {
                        ...product,
                        default: !product.default,
                    };
                } else {
                    // Setting all other products to false to enforce single select
                    product = {
                        ...product,
                        default: false,
                    };
                }
            } else {
                // Multi select
                if (product.id === selectedFilter.id) {
                    product = {
                        ...product,
                        default: !product.default,
                    };
                }
            }
            return product;
        });

        const newSelectedProductOptions = newProductOptions.filter(object => object.default);

        setProductsOptions(newProductOptions);
        reportFilterDispatch({
            type: ACTIONS.PRODUCT_FILTER_DATA_SET as ActionProductFilterDataSet['type'],
            payload: {
                product: newSelectedProductOptions as unknown as Product,
                enableFilterApplyButton: true,
            },
        });
    };

    const handleProductClearAll = () => {
        const newProductOptions = productsOptions.map(product => {
            product = {
                ...product,
                default: false,
            };

            return product;
        });

        setProductsOptions(newProductOptions);
        reportFilterDispatch({
            type: ACTIONS.PRODUCT_FILTER_DATA_SET as ActionProductFilterDataSet['type'],
            payload: {
                product: [] as unknown as Product,
                enableFilterApplyButton: true,
            },
        });
    };

    const handleProductSelectAll = () => {
        const newProductOptions = productsOptions.map(product => {
            return {
                ...product,
                default: true,
            };
        });

        setProductsOptions(newProductOptions);
        reportFilterDispatch({
            type: ACTIONS.PRODUCT_FILTER_DATA_SET as ActionProductFilterDataSet['type'],
            payload: {
                product: newProductOptions as unknown as Product,
                enableFilterApplyButton: true,
            },
        });
    };

    const handleSelectedProductRemove = (selectedFilter: FilterSelectFilter) => {
        const newProductOptions = productsOptions.map(product => {
            if (selectedFilter.id === product.id) {
                product.default = false;
            }

            return product;
        });
        const newSelectedProductOptions = newProductOptions.filter(object => object.default);

        setProductsOptions(newProductOptions);
        reportFilterDispatch({
            type: ACTIONS.PRODUCT_FILTER_DATA_SET as ActionProductFilterDataSet['type'],
            payload: {
                product: newSelectedProductOptions as unknown as Product,
                enableFilterApplyButton: true,
            },
        });
    };

    const handleSelectButtonOptions = (selectedButtonGroupOptions: ButtonGroupOptions) => {
        let selectedOption = buttonGroupOptions.map(option => {
            return {
                ...option,
                active: option === selectedButtonGroupOptions,
            };
        });

        setButtonGroupOptions(selectedOption);
        reportFilterDispatch({
            type: ACTIONS.BUTTON_GROUP_SET as ActionButtonGroupSet['type'],
            payload: {
                buttonGroupOptions: buttonGroupOptions as unknown as ButtonGroupOptions,
            },
        });
    };

    const handleComparisonViewOnClick = () => {
        reportFilterDispatch({
            type: ACTIONS.COMPARISON_DATE_RANGE_TOGGLE as ActionFilterExpandCollapse['type'],
            payload: !reportFilterState.enableComparisonDateRange,
        });

        if (filterStatus.isDashboardBuilder) {
            navigate(`/${accountToken}/${comparisonFilterStatus.redirectUrl}`);
        } else {
            navigate(`/${accountToken}/reports/attribution/${comparisonFilterStatus.redirectUrl}`);
        }
    };

    const handleChannelFilterApplyOnClick = () => {
        const modifiedArray: MetricsFilter[] = [];
        reportFilterState.selectedMetricsFilterData.forEach((data: MetricsFilter) => {
            if (data.status === 'view') {
                modifiedArray.push(data);
            } else {
                if (Object.keys(data.metric).length > 0 && Object.keys(data.operator).length > 0 && data.value !== '') {
                    modifiedArray.push({
                        ...data,
                        status: 'view',
                    });
                }
            }
        });

        reportFilterDispatch({
            type: ACTIONS.FILTER_APPLY as ActionFilterApply['type'],
            payload: { selectedMetricsFilterData: modifiedArray },
        });

        setSelectedChannelData(reportFilterState.selectedChannelFilterData);
        setChannelsInitialsOptions(channelOptions);
    };

    const handleChannelSelect = (selectedChannel: Channel) => {
        const newChannelOptions = channelOptions.map(channel => {
            if (channel.id === selectedChannel.id) {
                channel = {
                    ...channel,
                    default: !channel.default,
                };
            }

            return channel;
        });
        const newSelectedChannelOptions = newChannelOptions.filter(object => object.default);

        setChannelOptions(newChannelOptions);
        reportFilterDispatch({
            type: ACTIONS.CHANNEL_FILTER_DATA_SET as ActionChannelFilterDataSet['type'],
            payload: {
                channel: newSelectedChannelOptions as unknown as Channel,
                enableChannelFilterApplyButton: true,
            },
        });
    };

    const handleChannelClearAll = () => {
        const newChannelOptions = channelOptions.map(channel => {
            channel = {
                ...channel,
                default: false,
            };

            return channel;
        });

        setChannelOptions(newChannelOptions);
        reportFilterDispatch({
            type: ACTIONS.CHANNEL_FILTER_DATA_SET as ActionChannelFilterDataSet['type'],
            payload: {
                channel: [] as unknown as Channel,
                enableChannelFilterApplyButton: true,
            },
        });
    };

    const handleChannelSelectAll = () => {
        const newChannelOptions = channelOptions.map(channel => {
            return {
                ...channel,
                default: true,
            };
        });

        setChannelOptions(newChannelOptions);
        reportFilterDispatch({
            type: ACTIONS.CHANNEL_FILTER_DATA_SET as ActionChannelFilterDataSet['type'],
            payload: {
                channel: newChannelOptions as unknown as Channel,
                enableChannelFilterApplyButton: true,
            },
        });
    };

    const handleSelectedChannelRemove = (selectedChannel: Channel) => {
        const newChannelOptions = channelOptions.map(channel => {
            if (selectedChannel.id === channel.id) {
                channel.default = false;
            }

            return channel;
        });
        const newSelectedChannelOptions = newChannelOptions.filter(object => object.default);

        setChannelOptions(newChannelOptions);
        reportFilterDispatch({
            type: ACTIONS.CHANNEL_FILTER_DATA_SET as ActionChannelFilterDataSet['type'],
            payload: {
                channel: newSelectedChannelOptions as unknown as Channel,
                enableChannelFilterApplyButton: true,
            },
        });
    };

    // Handler for channel dropdown
    const handleSingleChannelSelect = (option: string) => {
        setSelectedChannel(channelDropdownOptions.options.find(channel => channel.value === option));
    };

    return (
        <FilterBarContext.Provider
            value={{
                router,
                // Transition effect
                mapTransitionStyles,

                // Date picker
                datePickerConfig,
                setDatePickerConfig,

                // Comparison Date picker
                comparisonDatePickerConfig,
                setComparisonDatePickerConfig,

                buttonGroupOptions,
                handleSelectButtonOptions,
                setButtonGroupOptions,

                // Product
                productDataLoading,
                selectedProductsData,
                productsOptions,
                filterProductName,
                setProductsOptions,
                handleProductSelect,
                handleProductClearAll,
                handleProductSelectAll,
                handleSelectedProductRemove,

                // Metric state
                selectedMetricsData,
                setSelectedMetricsData,

                // Description
                description,
                setDescription,

                // Filter state
                filterStatus,
                setFilterStatus,
                comparisonFilterStatus,
                setComparisonFilterStatus,
                reportFilterState,
                reportFilterDispatch,
                handleFilterToggle,
                handleFilterApplyOnClick,
                handleFilterClearOnClick,

                // Add filter
                FILTERS_OBJECT,
                filterMetricsOriginalData,
                setFilterMetricsOriginalData,
                filterMetricsOptions,
                setFilterMetricsOptions,
                filterOperatorOptions,
                setFilterOperatorOptions,
                handleMetricsSelectFilterOnClick,
                handleFilterSelectedMetricEditOnClick,
                handleFilterSelectedMetricRemoveOnClick,
                handleMetricsFilterValueOnChange,
                handleMetricsFilterMetricOnChange,
                handleMetricsFilterOperatorOnChange,
                handleMetricsAddFilterOnClick,
                handleMetricsRemoveFilterOnClick,

                handleComparisonViewOnClick,

                // Channels
                channelDataLoading,
                selectedChannelData,
                channelOptions,
                filterChannelName,
                setChannelOptions,
                handleChannelSelect,
                handleChannelClearAll,
                handleChannelSelectAll,
                handleSelectedChannelRemove,

                // Filter state
                handleChannelFilterApplyOnClick,

                // Single Channel Dropdown
                channelDropdownOptions,
                selectedChannel,
                handleSingleChannelSelect,
            }}
        >
            {children}
        </FilterBarContext.Provider>
    );
};

export default withRouter(FilterBarProvider);
