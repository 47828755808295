import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generatePath, generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isString } from '../../helpers/validator';
import { DropdownOption } from '../../types';

type ErrorMessageObject = {
    editSourceErrorMessage: string;
    editChannelErrorMessage: string;
};

type ImpressionsResponse = {
    id: number;
    value: string;
};

type Impressions = {
    id: number;
    value: string;
};

type Referer = {
    colour: number;
    subchannels: Array<string>;
    value: string;
};

type ImpressionsConfigResponse = {
    active: boolean;
    created: string;
    id: number;
    impression_model: ImpressionsResponse;
    referer: Referer;
    resource_uri: string;
    updated: string;
};

type ImpressionsConfig = {
    id: number;
    impressionModel: Impressions;
    referer: Referer;
    resourceUri: string;
};

type ChannelsResponse = {
    active: boolean;
    colour: number;
    created: string;
    id: number;
    name: string;
    resource_uri: string;
    updated: string;
};

const LayoutManageImpressionModelling = () => {
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(true);
    const [closeButtonState, setCloseButtonState] = useState('close');
    const [disableSelectedImpression, setDisableSelectedConnection] = useState<boolean>(false);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState<boolean>(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState<boolean>(true);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState<boolean>(true);
    const [addNewButtonDisabled, setAddNewButtonDisabled] = useState<boolean>(false);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState<boolean>(true);
    const [editImpressionsAccordionOpen, setEditImpressionsAccordionOpen] = useState<boolean>(false);
    const [editImpressionsAccordionVisible, setEditImpressionsAccordionVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageError, setPageError] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

    const [selectedImpression, setSelectedImpression] = useState<Impressions[]>([]);
    const [updatedImpression, setUpdatedImpression] = useState<Impressions[]>([]);
    const [deletedImpression, setDeletedImpression] = useState<Impressions[]>([]);

    const [impressions, setImpressions] = useState<Impressions[]>([]);
    const [impressionsConfig, setImpressionsConfig] = useState<ImpressionsConfig[]>([]);
    const [channels, setChannels] = useState<DropdownOption[]>([]);
    const [editSource, setEditSource] = useState<string>('');
    const [editSelectedChannel, setEditSelectedChannel] = useState<DropdownOption | undefined>(undefined);

    const [errorMessageObject, setErrorMessageObject] = useState<ErrorMessageObject>({
        editSourceErrorMessage: '',
        editChannelErrorMessage: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        setCloseButtonState('close');
        fetchAll();
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        setSaveChangesButtonDisabled(true);
        if (editSource || editSelectedChannel) {
            setSaveChangesButtonDisabled(false);
            setErrorMessageObject({
                editSourceErrorMessage: '',

                editChannelErrorMessage: '',
            });
        }
    }, [editSource, editSelectedChannel]);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            editSourceErrorMessage: '',

            editChannelErrorMessage: '',
        };

        if (!isString(editSource) || editSource.length === 0) {
            hasFormError = true;
            errorMessageObj.editSourceErrorMessage = 'Please enter valid Source name.';
        }

        if (!editSelectedChannel) {
            hasFormError = true;
            errorMessageObj.editChannelErrorMessage = 'Please select a Channel.';
        }

        if (hasFormError) {
            setSaveChangesButtonDisabled(true);
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const handleEditSource = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditSource(event?.target?.value);
    };

    const handleEditChannels = (event: React.ChangeEvent<HTMLInputElement>) => {
        const channelId = parseInt(event?.target?.value);
        const selectedChannel = channels.find(channel => channel.value === channelId);
        setEditSelectedChannel(selectedChannel);
    };

    const fetchAll = () => {
        const resources = ['impression_model', 'impression-model-config', 'referer'];
        const allRequests: AxiosPromise[] = [];

        resources.forEach(resource => {
            allRequests.push(
                axios({
                    method: 'GET',
                    url: generateUrl('config', resource, [
                        { key: 'active', value: 1 },
                        { key: 'limit', value: 0 },
                    ]),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        });
        axios
            .all(allRequests)
            .then(
                axios.spread((...response) => {
                    const impressions = response[0].data.objects.map((data: ImpressionsResponse) => {
                        return {
                            id: data.id,
                            value: data.value,
                        };
                    });

                    const impressionsConfig = response[1].data.objects.map((data: ImpressionsConfigResponse) => {
                        return {
                            id: data.id,
                            impressionModel: data.impression_model,
                            referer: data.referer,
                            resourceUri: data.resource_uri,
                        };
                    });

                    const channels = response[2].data.objects.map((channel: ChannelsResponse) => {
                        return {
                            label: channel.name,
                            name: channel.name,
                            value: channel.id,
                        };
                    });

                    setImpressions(impressions);
                    setImpressionsConfig(impressionsConfig);
                    setChannels(channels);
                    setIsLoading(false);
                })
            )
            .catch(error => {
                setIsLoading(false);
                setPageError(true);
                errorHandling(error);
            });
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onDeleteConnection = () => {
        setSaveChangesButtonDisabled(false);
        setCloseButtonState('cancel');
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);

        if (disableSelectedImpression) {
            return;
        }

        let tempRemovedConnection = Array.prototype.concat(deletedImpression);
        let tempUpdatedConnection = Array.prototype.concat(updatedImpression);

        selectedImpression.forEach(impression => {
            if (removeButtonState) {
                tempRemovedConnection.push(impression);
            } else {
                tempRemovedConnection.splice(tempRemovedConnection.indexOf(impression), 1);
            }

            // If a impression was edited and then revoked in the same instance then the revoke should take priority
            const updatedConfigIndex = tempUpdatedConnection.indexOf(impression);

            if (updatedConfigIndex >= 0) {
                tempUpdatedConnection.splice(updatedConfigIndex, 1);
            }
        });

        setDeletedImpression(tempRemovedConnection);
        setUpdatedImpression(tempUpdatedConnection);
        setSelectedImpression([]);
        setRemoveButtonState(true);
    };

    const onImpressionSelect = (impression: Impressions) => {
        if (disableSelectedImpression) {
            return;
        }

        const selectedImpressionId = impression.id;
        let selectedImpressions = [];

        if (selectedImpression.filter(event => event.id === selectedImpressionId).length > 0) {
            selectedImpressions = selectedImpression.filter(event => event.id !== selectedImpressionId);
            setSelectedImpression(selectedImpressions);
            setEditButtonDisabled(selectedImpressions.length === 0);
            setRemoveButtonDisabled(selectedImpressions.length === 0);
            setAddNewButtonDisabled(selectedImpressions.length === 0);
        } else {
            selectedImpressions = selectedImpression.concat(
                impressions.filter(item => item.id === selectedImpressionId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRevoked = false;
        let containsNonRevoked = false;
        let containsEdited = false;

        selectedImpressions.forEach(event => {
            if (deletedImpression.indexOf(event) >= 0) {
                containsRevoked = true;
            } else {
                containsNonRevoked = true;
            }

            if (updatedImpression.indexOf(event) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;
        let addButtonDisabled = false;

        if (selectedImpressions.length > 0) {
            editButtonDisabled = false;
            removeButtonDisabled = false;
            addButtonDisabled = true;
        }

        if (selectedImpressions.length > 1) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRevoked && !containsNonRevoked && !containsEdited) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRevoked) {
            editButtonDisabled = true;
        }

        if (containsRevoked && containsNonRevoked) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        setAddNewButtonDisabled(addButtonDisabled);
        setSelectedImpression(selectedImpressions);
        setEditButtonDisabled(editButtonDisabled);
        setRemoveButtonDisabled(removeButtonDisabled);
        setRemoveButtonState(removeButtonState);
        setUpdateButtonDisabled(true);
        setEditImpressionsAccordionOpen(false);
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(removeModal());
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const handleAddNew = () => {
        dispatch(setModal('AddImpressionModelling', {}));
    };

    const onEditClick = () => {
        const selectedImpressionsModel = impressionsConfig.filter(
            config => config.impressionModel.id === selectedImpression[0].id
        )[0];

        const editChannel = selectedImpressionsModel
            ? channels.filter(channel => channel.name === selectedImpressionsModel.referer.value)
            : channels;

        const editSource = selectedImpressionsModel
            ? selectedImpressionsModel.impressionModel.value
            : selectedImpression[0].value;

        setCloseButtonState('cancel');
        setEditImpressionsAccordionVisible(true);
        setEditImpressionsAccordionOpen(true);
        setRemoveButtonDisabled(true);
        setUpdateButtonDisabled(false);
        setDisableSelectedConnection(true);
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setEditSource(editSource);
        setEditSelectedChannel(editChannel[0]);
        setErrorMessageObject({
            editSourceErrorMessage: '',
            editChannelErrorMessage: '',
        });
    };

    const updateImpressions = () => {
        return new Promise(async (resolve, reject) => {
            const payload = {
                source: editSource,
                active: 1,
            };

            try {
                const request: AxiosRequestConfig = {
                    method: 'PATCH',
                    url: generateUrlDetail('config', 'impression_model', String(selectedImpression[0].id))!,
                    data: payload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                await axios(request).then(response => {
                    if (![200, 201, 202].includes(response.status)) {
                        throw new Error('Status not 201');
                    }

                    const requestPayload = {
                        impression_model: generatePath('config', 'impression_model', String(response.data.id)),
                        referer: generatePath('config', 'referer', String(editSelectedChannel?.value)),
                        active: 1,
                    };

                    const requestConfig: AxiosRequestConfig = {
                        method: 'PATCH',
                        url: generateUrl('config', 'impression-model-config'),
                        data: requestPayload,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    };

                    axios(requestConfig).then(response => {
                        if (![200, 201, 202].includes(response.status)) {
                            throw new Error('Status not 201');
                        }
                    });
                });
            } catch (error) {
                errorHandling(error);
                reject('Error making a connection to API.');
            }
            resolve('Impressions added.');
        });
    };

    const handleNavigateManageConnectionModal = () => {
        dispatch(removeModal());
    };

    const onUpdateClick = () => {
        if (!formValidator()) {
            return;
        }
        setIsUpdating(true);
        updateImpressions()
            .then(() => {
                dispatch(
                    addNotification({
                        copy: 'Impressions was successfully updated.',
                        type: NotificationMessageType.Success,
                    })
                );
                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
                setIsUpdating(false);
                handleNavigateManageConnectionModal();
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue updating a Impressions.',
                        type: NotificationMessageType.Error,
                    })
                );

                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
            });
    };

    const onSaveChangesClick = () => {
        setSaveChangesButtonLoading(true);
        const requests: AxiosPromise[] = [];

        if (deletedImpression.length > 0) {
            deletedImpression.forEach(impression => {
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'impression_model', String(impression.id))!,
                        data: {
                            active: 0,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'impression-model-config', String(impression.id))!,
                        data: {
                            active: 0,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
        }

        axios
            .all(requests)
            .then(
                axios.spread((...res) => {
                    dispatch(
                        addNotification({
                            copy: 'These changes have been successfully saved.',
                            type: NotificationMessageType.Success,
                        })
                    );
                    setSaveChangesButtonLoading(false);
                    dispatch(removeModal());
                })
            )
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue trying to save your changes. Please try again later or contact Cubed Support.',
                        type: NotificationMessageType.Error,
                    })
                );
                setSaveChangesButtonLoading(false);
            });
    };

    const renderModalNavigation = () => {
        let numberOfChanges = updatedImpression.length + deletedImpression.length;
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    };

    const renderAccordion = () => {
        if (editImpressionsAccordionVisible) {
            const accordions = [
                {
                    header: 'Edit Impressions',
                    required: false,
                    open: editImpressionsAccordionOpen,
                    type: 'form',
                    intro: '',
                    config: {
                        formConfig: {
                            fields: [
                                {
                                    label: 'Source:',
                                    type: 'text',
                                    inputPlaceholder: 'Enter source name',
                                    requiredField: true,
                                    toolTipCopy:
                                        'The name of the impression source data (i.e. Display, VOD, Social Impressions, Facebook, TikTok etc...)',
                                    inputKeyValue: 'source',
                                    inputValue: editSource,
                                    inputOnChange: handleEditSource,
                                    errorMessage: errorMessageObject.editSourceErrorMessage,
                                },
                                {
                                    label: 'Channel:',
                                    type: 'select',
                                    requiredField: true,
                                    toolTipCopy: 'Select a Channel associated with Impression modelling.',
                                    inputKeyValue: 'market',
                                    inputValue: editSelectedChannel?.value,
                                    inputOptions: channels,
                                    inputOnChange: handleEditChannels,
                                    errorMessage: errorMessageObject.editChannelErrorMessage,
                                },
                            ],
                            buttons: [
                                {
                                    value: 'UPDATE',
                                    onClick: onUpdateClick,
                                    disabled: updateButtonDisabled,
                                    isLoading: isUpdating,
                                },
                            ],
                        },
                    },
                },
            ];

            return <WidgetAccordion accordions={accordions} />;
        }
    };

    const renderTable = () => {
        const errorMessageOverride = 'No Impressions is added for this account.';

        const header = {
            columns: [
                {
                    title: 'Impression',
                },
            ],
        };

        const rows = impressions.map(impression => {
            const rowProperty = {
                selected: selectedImpression.includes(impression),
                deleted: deletedImpression.includes(impression),
                edited: updatedImpression.includes(impression),
                disabled: disableSelectedImpression,
            };

            return {
                onClick: () => onImpressionSelect(impression),
                key: `impression__${impression.id}`,
                dataValue: impression.id,
                rowProperty,
                columns: [
                    {
                        copy: impression.value,
                    },
                ],
            };
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Impressions</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Impressions</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Manage Impressions</h2>
            <p>
                Our impression reporting is built upon modelling a baseline based on the relationship impression traffic
                and website traffic. We model a baseline for each impression activity, such as Display, VOD or Social
                Impressions. Please configure below.
            </p>
            <div className="">
                {renderTable()}
                <InputButtonWrapper>
                    <InputButton value="Add New" disabled={addNewButtonDisabled} onClick={handleAddNew} />
                    <InputButton value="EDIT" disabled={editButtonDisabled} onClick={onEditClick} />
                    <InputButton
                        buttonTheme={ButtonThemes.Red}
                        value={removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                        disabled={removeButtonDisabled}
                        onClick={onDeleteConnection}
                    />
                </InputButtonWrapper>
            </div>
            {renderAccordion()}
        </div>
    );
};

export default LayoutManageImpressionModelling;
