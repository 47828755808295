export function setTitle(title) {
    return {
        type: 'SET_TITLE',
        title: title,
    };
}

export function setDisplayMenu(displayMenu) {
    return {
        type: 'SET_DISPLAY_MENU',
        displayMenu: displayMenu,
    };
}

export function setDescription(description) {
    return {
        type: 'SET_DESCIPTION',
        description: description,
    };
}
