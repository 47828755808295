// Import react dependencies
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonThemes } from '../../../enums/button-themes';
import { RootState } from '../../../redux/reducers/core';

// Import Components
import InputButton from '../../../components/inputs/input-button';
import InputButtonWrapper from '../../../components/inputs/input-button-wrapper';
import { removePopup } from '../../../redux/actions/popup';

import ToggleWithLabels from '../../../components/inputs/toggle-with-labels';

// export const StyledToggleContainer = styled.div`
//     display: flex;
//     margin-bottom: 1rem;
// `;
export const StyledToggleContainer = styled.div`
    display: flex;
    width: max-content;
    margin-bottom: 1rem;
`;
export const StyledPopupDescription = styled.p`
    margin-bottom: 2rem;
`;
export const StyledPopupLabel = styled.p`
    font-weight: 500;
`;

type PopupCreateOauthConnectionProps = {
    description: string;
    detailedDescription: string;
    informationAboutPreviousConnection: string;
};

const LayoutPopupCreateOauthConnection = ({
    description,
    detailedDescription,
    informationAboutPreviousConnection,
}: PopupCreateOauthConnectionProps) => {
    // service select toggles state
    const [adwordsSelected, setAdwordsSelected] = useState(false);
    const [youtubeSelected, setYoutubeSelected] = useState(false);
    const [searchConsoleSelected, setSearchConsoleSelected] = useState(false);
    const [doubleClickSelected, setDoubleClickSelected] = useState(false);

    const dispatch = useDispatch();
    const accountToken: string = useSelector((state: RootState) => state.account.token);
    const apiDomain: string = useSelector((state: RootState) => state.meta.apiDomain);

    const getServicesParams = () => {
        const selectedServices = [];

        if (adwordsSelected) selectedServices.push('adwords');
        if (youtubeSelected) selectedServices.push('youtube');
        if (searchConsoleSelected) selectedServices.push('search_console');
        if (doubleClickSelected) selectedServices.push('doubleclick');

        const queryParam = selectedServices.length > 0 ? `?services=${selectedServices.join('&services=')}` : '';

        return queryParam;
    };

    const onStayHereClick = () => {
        dispatch(removePopup());
    };
    const onCreatePopupContinue = () => {
        const win: Window = window;
        win.location = `${apiDomain}/api/${accountToken}/integrations/google-oauth/initialise/` + getServicesParams();
    };
    return (
        <>
            <StyledPopupDescription>
                {detailedDescription} <b>{informationAboutPreviousConnection}</b>
            </StyledPopupDescription>
            <StyledPopupLabel>{description}</StyledPopupLabel>
            <StyledToggleContainer>
                <ToggleWithLabels
                    checked={adwordsSelected}
                    onClick={() => {
                        setAdwordsSelected(!adwordsSelected);
                    }}
                    singleLabel={'Googe Ads'}
                />
                <ToggleWithLabels
                    checked={searchConsoleSelected}
                    onClick={() => {
                        setSearchConsoleSelected(!searchConsoleSelected);
                    }}
                    singleLabel={'Search Console'}
                />
                <ToggleWithLabels
                    checked={youtubeSelected}
                    onClick={() => {
                        setYoutubeSelected(!youtubeSelected);
                    }}
                    singleLabel={'Youtube'}
                />
            </StyledToggleContainer>
            <StyledPopupDescription>
                {
                    "If you created a connection to Cubed via Google pre 2024, due to Cubed being configured to use DoubleClick as a default service, please toggle DoubleClick below. However, we can assure you that we don't pull any DoubleClick data."
                }
            </StyledPopupDescription>
            <ToggleWithLabels
                checked={doubleClickSelected}
                onClick={() => {
                    setDoubleClickSelected(!doubleClickSelected);
                }}
                singleLabel={'DoubleClick'}
            />
            <InputButtonWrapper>
                <InputButton
                    value={'SAVE & CONTINUE'}
                    buttonTheme={ButtonThemes.Primary}
                    // disabled={!searchConsoleSelected && !adwordsSelected}
                    disabled={!searchConsoleSelected && !adwordsSelected && !youtubeSelected}
                    onClick={onCreatePopupContinue}
                    isLoading={false}
                />
                <InputButton
                    value={'STAY HERE'}
                    buttonTheme={ButtonThemes.Secondary}
                    disabled={false}
                    onClick={onStayHereClick}
                    isLoading={false}
                />
            </InputButtonWrapper>
        </>
    );
};

export default LayoutPopupCreateOauthConnection;
