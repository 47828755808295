import { Validator } from '../../types';

const basicValidators = ['required', 'min', 'max', 'minLength', 'maxLength'];

const buildValidators = (validators: Validator[]) => {
    let validate = {
        validate: {},
    };

    if (validators && validators.length > 0) {
        validators.forEach((validator: Validator) => {
            if (basicValidators.includes(Object.keys(validator)[0]) || Object.keys(validator)[0] === 'pattern') {
                validate = {
                    ...validate,
                    ...validator,
                };
            } else {
                validate.validate = {
                    ...validate.validate,
                    ...validator,
                };
            }
        });
    }

    return validate;
};

export default buildValidators;
