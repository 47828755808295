import { v4 as uuidv4 } from 'uuid';
import {
    DataType,
    NestedDonutCategory,
    NestedDonutChartData,
    NestedDonutChartSeries,
    NestedDonutChartTableRow,
    NestedDonutColumn,
} from '../../types';
import { unknownErrorData } from '../../helpers/errors';

type useResourceNestedDonutChartTableArgs = {
    categories: NestedDonutCategory[];
    columns: NestedDonutColumn;
};

const useResourceNestedDonutChartTable = ({
    categories,
    columns,
}: useResourceNestedDonutChartTableArgs): NestedDonutChartData => {
    const loading = categories.map(category => category.resourceData.status).some(status => status === 'loading');
    const error = categories.map(category => category.resourceData.status).some(status => status === 'error');
    const success = categories.map(category => category.resourceData.status).every(status => status === 'success');
    const empty = categories.map(category => category.resourceData.status).every(status => status === 'empty');

    const errorObject = (message?: string) => ({
        type: 'nestedDonutChart' as DataType,
        status: 'error' as 'error',
        error: { message: message || 'Error loading data' },
    });

    if (loading) {
        return {
            type: 'nestedDonutChart',
            status: 'loading',
        };
    }

    if (error) return errorObject();

    if (empty) {
        return {
            type: 'nestedDonutChart',
            status: 'empty',
        };
    }

    if (success) {
        try {
            // Build the chart series
            const series: NestedDonutChartSeries[] = [];

            columns.columnConfig.forEach(column => {
                const columnSeries = {
                    name: column.displayName,
                    data: [] as number[],
                };

                categories.forEach(category => {
                    if (category.showInGraph) {
                        let value = 0;

                        if ('objects' in category.resourceData) {
                            category.resourceData.objects.forEach(object => {
                                columns.columnFields.forEach(field => {
                                    if (
                                        field.rawName in object.values &&
                                        column.flags.includes(object.values[field.rawName].value)
                                    ) {
                                        value = object.values[category.field.rawName].rawValue as number;
                                    }
                                });
                            });
                        }

                        columnSeries.data.push(value);
                    }
                });

                series.push(columnSeries);
            });

            // Build the table row
            const row: NestedDonutChartTableRow = {
                __id: uuidv4(),
                values: {},
            };

            const rowValues = columns.columnConfig.map(column => {
                const columnValues = categories.map(category => {
                    let rawValue = 0;

                    if ('objects' in category.resourceData) {
                        category.resourceData.objects.forEach(object => {
                            columns.columnFields.forEach(field => {
                                if (
                                    field.rawName in object.values &&
                                    column.flags.includes(object.values[field.rawName].value)
                                ) {
                                    rawValue += object.values[category.field.rawName].rawValue as number;
                                }
                            });
                        });
                    }

                    return {
                        [category.field.rawName]: {
                            rawValue: rawValue,
                            value: category.showInGraph ? String(rawValue) : `${String(rawValue)}%`,
                        },
                    };
                });

                return {
                    [column.rawName]: columnValues.reduce((acc, val) => Object.assign(acc, val), {}),
                };
            });

            row.values = rowValues.reduce((acc, val) => Object.assign(acc, val), {});

            return {
                type: 'nestedDonutChart',
                status: 'success',
                categories: categories.map(category => category.title),
                series: series,
                columns: columns.columnConfig.map(column => {
                    return {
                        __id: uuidv4(),
                        rawName: column.rawName,
                        displayName: column.displayName,
                    };
                }),
                subColumns: categories.map(column => {
                    return {
                        __id: uuidv4(),
                        rawName: column.field.rawName,
                        displayName: column.showInGraph ? column.field.displayName : column.title,
                    };
                }),
                tableRow: row,
            };
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            return errorObject(message);
        }
    }

    return unknownErrorData();
};

export default useResourceNestedDonutChartTable;
