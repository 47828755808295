import React from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

const StyledPopoverContent = styled(Popover.Content)`
    background-color: ${props => props.theme.colours.white};
    border-radius: 4px;
    padding: 20px;
    min-width: 400px;
    background-color: ${props => props.theme.popover.content.backgroundColor};
    color: ${props => props.theme.popover.content.color};
    box-shadow: ${props => props.theme.popover.content.shadow};
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    z-index: 51; // Overlap the sidebar
`;

const PopoverContent = ({ children }: { children: React.ReactNode }) => {
    return (
        <Popover.Portal>
            <StyledPopoverContent>{children}</StyledPopoverContent>
        </Popover.Portal>
    );
};

export default PopoverContent;
