export function signOut() {
    return {
        type: 'SIGN_OUT',
    };
}

export function signOutComplete() {
    return {
        type: 'SIGN_OUT_COMPLETE',
    };
}

export function signOutError() {
    return {
        type: 'SIGN_OUT_ERROR',
    };
}
