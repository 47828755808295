import React from 'react';
import styled from 'styled-components';

// Chart Config
import { vennConfig } from '../../../configurations/charts/venn-diagram-config';

// Highcharts Dependencies
import Highcharts from 'highcharts';
import VennModule from 'highcharts/modules/venn.js';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Components
import WidgetBase from '../base/widget-base';
import WidgetVennBigNumber from '../big-numbers/widget-venn-big-number';
import WidgetMenuVenn from '../menus/components/widget-menu/widget-menu-venn';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';
import useWidgetVennState from '../menus/hooks/use-widget-menu-venn-state';

// Types
import { VennData, VennDataSuccess } from '../../types';

VennModule(Highcharts);

const StyledBigNumberContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const StyledDiagramContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0px;
`;

export type WidgetVennProps = {
    title: string;
    data: VennData;
    href?: string;
};

const WidgetVenn = ({ title, data, href }: WidgetVennProps) => {
    const { width, height } = useSectionDashboard();
    const { menuConfig, handleCheckboxChange } = useWidgetVennState({ data });

    const success = data.status === 'success';
    const empty = data.status === 'empty' || (menuConfig && menuConfig.every(config => !config.checked));

    if (data.status === 'loading') {
        return (
            <WidgetBase
                title={title}
                loading={true}
                menu={<WidgetMenuVenn data={data} menuConfig={menuConfig} handleMenuChange={handleCheckboxChange} />}
            />
        );
    }

    if (empty) {
        return (
            <WidgetBase
                title={title}
                noData={true}
                href={href}
                menu={<WidgetMenuVenn data={data} menuConfig={menuConfig} handleMenuChange={handleCheckboxChange} />}
            />
        );
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    if (success) {
        let { series } = data as VennDataSuccess;

        if (menuConfig) {
            series = {
                ...series,
                data: series.data.map(item => {
                    return {
                        ...item,
                        value:
                            menuConfig && menuConfig.find(config => item.sets.includes(config.label) && config.checked)
                                ? item.value
                                : 0,
                    };
                }),
            };
        }

        if (width === 1 || height === 1) {
            // get the top crossovers for display in big numbers
            const list = series.data
                .filter(item => item.sets && item.sets.length > 1)
                .sort((a, b) => b.value - a.value);
            const numberOfBoxes = Math.max(width, height);
            const dataToDisplay = list.slice(0, numberOfBoxes);
            const direction = width > height ? 'horizontal' : 'vertical';

            return (
                <WidgetBase
                    title={title}
                    href={href}
                    menu={
                        <WidgetMenuVenn data={data} menuConfig={menuConfig} handleMenuChange={handleCheckboxChange} />
                    }
                >
                    <StyledBigNumberContainer>
                        <WidgetVennBigNumber data={dataToDisplay} direction={direction} />
                    </StyledBigNumberContainer>
                </WidgetBase>
            );
        }
        return (
            <WidgetBase
                title={title}
                href={href}
                menu={<WidgetMenuVenn data={data} menuConfig={menuConfig} handleMenuChange={handleCheckboxChange} />}
            >
                <StyledDiagramContainer>
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ series: [series], title: 'Segments', ...vennConfig }}
                        immutable={true}
                    />
                </StyledDiagramContainer>
            </WidgetBase>
        );
    }
    return null;
};

export default WidgetVenn;
