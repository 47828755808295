import { ResourceDataObject, ResourceDataObjectValue } from '../../react-query/types';

const getObjectValue = (obj: ResourceDataObject, key: string): ResourceDataObjectValue => {
    const value = obj.values[key];
    if (typeof value == 'string') {
        throw Error(`Unexpected type for field ${key}. Full value is ${formatValue(value, key)}`);
    }
    return value;
};

const formatValue = (value: ResourceDataObjectValue, key: string) => {
    return JSON.stringify({ [key]: value });
};

export const getValue = (obj: ResourceDataObject, key: string): string => {
    try {
        return getObjectValue(obj, key).value;
    } catch {
        return 'unknown';
    }
};

export const getRawValueAsString = (obj: ResourceDataObject, key: string): string => {
    const value = getObjectValue(obj, key);
    if (typeof value.rawValue != 'string') {
        throw Error(
            `Expected rawValue to be of type string, instead was ${typeof value.rawValue}. Full value is ${formatValue(
                value,
                key
            )}`
        );
    }
    return value.rawValue.toString();
};

export const getRawValueAsNumber = (obj: ResourceDataObject, key: string): number => {
    const value = getObjectValue(obj, key);
    if (typeof value.rawValue != 'number') {
        throw Error(
            `Expected rawValue to be of type number, instead was ${typeof value.rawValue}. Full value is ${formatValue(
                value,
                key
            )}`
        );
    }
    return value.rawValue;
};
