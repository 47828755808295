import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import IconCopy from './icons/copy';

const StyledCodeBlock = styled.div`
    margin-bottom: 10;
    position: relative;
    min-height: 50px;
    padding: 5px 20px;
`;

const StyledButton = styled.button`
    right: 0;
    top: 10px;
    position: relative;
    right: -10px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    opacity: 50%;
    float: right;
    padding: 1px;
    margin: 1px;
`;

const StyledIcon = styled(IconCopy)`
    width: 20px;
    fill: ${props => props.theme.colours.darkerGrey};
`;

const StyledCopiedText = styled.span<{ copied: boolean }>`
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.offBlack};
    position: relative;
    top: 35px;
    border-radius: 25px;
    float: right;
    left: 30px;
    padding: 4px 8px;

    -webkit-animation: fadeinout 4s linear forwards;
    animation: fadeinout 4s linear forwards;
    @-webkit-keyframes fadeinout {
        0%,
        100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }

    @keyframes fadeinout {
        0%,
        100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
`;

type CodeBlockProps = {
    code: string;
    language: string;
};

const CodeBlock = ({ code, language }: CodeBlockProps) => {
    const [copied, setCopied] = useState(false);

    const delay = (time: number) => {
        return new Promise(resolve => setTimeout(resolve, time));
    };

    const copyMessage = () => {
        delay(10000).then(() => setCopied(false));
    };

    return (
        <StyledCodeBlock>
            <CopyToClipboard text={code} onCopy={() => setCopied(true)}>
                <StyledButton title="Copy Code">
                    <StyledIcon />
                </StyledButton>
            </CopyToClipboard>

            {copied && <StyledCopiedText copied={copied}>COPIED!</StyledCopiedText>}

            <>{copyMessage()}</>
            <SyntaxHighlighter language={language}>{code}</SyntaxHighlighter>
        </StyledCodeBlock>
    );
};

export default CodeBlock;
