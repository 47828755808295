export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_META':
            return Object.assign({}, state, {
                apiDomain: action.payload.apiDomain,
                environment: action.payload.environment,
            });
        default:
            return state;
    }
}

const initialState = {
    apiDomain: '',
    environment: '',
};
