import React from 'react';
import { CubedIcon } from './types';

const IconOrder: CubedIcon = ({ className }) => {
    return (
        <svg
            className={className}
            data-name="order"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 45 45"
            fill="currentColor"
        >
            <circle cx="22.5" cy="4.5" r="4.5" />
            <circle cx="40.5" cy="4.5" r="4.5" />
            <circle cx="4.5" cy="4.5" r="4.5" />
            <circle cx="22.5" cy="22.5" r="4.5" />
            <circle cx="40.5" cy="22.5" r="4.5" />
            <circle cx="4.5" cy="22.5" r="4.5" />
            <circle cx="22.5" cy="40.5" r="4.5" />
            <circle cx="40.5" cy="40.5" r="4.5" />
            <circle cx="4.5" cy="40.5" r="4.5" />
        </svg>
    );
};

export default IconOrder;
