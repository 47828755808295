import React, { Component } from 'react';
import IconChevronRight from '../icons/chevron-right';

//
// 	Class ListRowTitleCopy
//		props.keyValue :    String
//		props.title :       String
//      props.copy :        String
// 		props.onClick :     function
//      props.chevron :     boolean
//

class ListRowTitleCopy extends Component {
    constructor(props) {
        super(props);

        this.renderChevron = this.renderChevron.bind(this);
    }

    renderChevron() {
        return this.props.chevron ? (
            <div className="icon-container">
                <div className="icon icon-chevron-right">
                    <IconChevronRight />
                </div>
            </div>
        ) : null;
    }

    render() {
        return (
            <div
                key={this.props.keyValue}
                data-key={this.props.keyValue}
                className="list-item__title-copy"
                onClick={this.props.onClick}
            >
                <div className="list-item__title-copy__content">
                    <h3>{this.props.title}</h3>
                    <p>{this.props.copy}</p>
                </div>
                <this.renderChevron />
            </div>
        );
    }
}

export default ListRowTitleCopy;
