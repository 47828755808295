import React from 'react';
import WidgetTableCardBox from './widget-table-card-box';

// Context
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Components
import WidgetBase from '../base/widget-base';
import SectionDashboardTable from '../../components/tables/section-dashboard-table';

// Types
import { TableData, TotalData } from '../../types';

export type WidgetTableProps = {
    title: string;
    data: TableData;
    totalData?: TotalData;
    href?: string;
    menu?: React.ReactElement;
};

const WidgetTable = ({ title, data, totalData, href, menu }: WidgetTableProps) => {
    const { width, height } = useSectionDashboard();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} menu={menu} />;
    }

    const { rows, columns, request } = data;

    const ascendingSorts: string[] = [];
    const descendingSorts: string[] = [];
    const withFilters: string[] = [];

    if (request && request.orderBy) {
        request.orderBy.forEach(sort => {
            if (sort.orderByDirection === 'asc') {
                ascendingSorts.push(sort.field.rawName);
            } else if (sort.orderByDirection === 'desc') {
                descendingSorts.push(sort.field.rawName);
            }
        });
    }

    if (request && request.filters) {
        request.filters.forEach(filter => {
            withFilters.push(filter.field.rawName);
        });
    }

    if (width === 1) {
        return (
            <WidgetBase title={title} href={href} menu={menu}>
                <WidgetTableCardBox rows={rows} columns={columns} />
            </WidgetBase>
        );
    } else {
        return (
            <WidgetBase title={title} href={href} menu={menu}>
                <SectionDashboardTable
                    request={request}
                    columns={columns}
                    rows={rows}
                    totalData={totalData}
                    width={width}
                    height={height}
                    ascendingSorts={ascendingSorts}
                    descendingSorts={descendingSorts}
                    withFilters={withFilters}
                />
            </WidgetBase>
        );
    }
};

export default WidgetTable;
