import tinyColor from 'tinycolor2';

export function intToColour(integer) {
    var r = (integer >> 16) & 0xff;
    var g = (integer >> 8) & 0xff;
    var b = integer & 0xff;
    return tinyColor('rgb(' + r + ', ' + g + ', ' + b + ')');
}

export function colourToInt(colour) {
    if (typeof colour !== 'object' || !colour.isValid()) {
        throw TypeError;
    }

    colour = colour.toRgb();

    return (colour.a << 24) + (colour.r << 16) + (colour.g << 8) + colour.b;
}

export const newColourAsRgba = (colour, alpha) => {
    return colour.clone().setAlpha(alpha).toRgbString();
};

export const shuffleColours = (colours = [], steps = 3) => {
    const arr = [];
    for (let n = 0; arr.length < colours.length; n++) {
        colours.forEach((colour, index) => {
            if ((index + n) % steps === 0) {
                arr.push(colour);
            }
        });
    }
    return arr;
};

export const selectRandomColour = () => {
    return shuffleColours(channelColours, 6)[Math.floor(Math.random() * channelColours.length)].colour;
};

export function assignColour(data, dimensions, selectedRows) {
    let colour;
    if (data[dimensions[0].rawName]?.colour) {
        colour = intToColour(data[dimensions[0].rawName]['colour']);
    } else {
        colour = selectRandomColour();
        selectedRows.forEach(row => {
            if (row.colour === colour) {
                colour = selectRandomColour();
            }
        });
    }
    return colour;
}

// DO NOT reorder this array in code, if needed [].concat(channelColours) if you want to manipulate it.
export const channelColours = [
    {
        name: 'Salmon',
        colour: tinyColor('#f5656c'),
        hex: '#f5656c',
    },
    {
        name: 'Red',
        colour: tinyColor('#e0151c'),
        hex: '#e0151c',
    },
    {
        name: 'Dark Red',
        colour: tinyColor('#b31711'),
        hex: '#b31711',
    },
    {
        name: 'Light Purple',
        colour: tinyColor('#ce90e8'),
        hex: '#ce90e8',
    },
    {
        name: 'Purple',
        colour: tinyColor('#673ab7'),
        hex: '#673ab7',
    },
    {
        name: 'Dark Blue',
        colour: tinyColor('#1558c4'),
        hex: '#1558c4',
    },
    {
        name: 'Blue',
        colour: tinyColor('#4da5ed'),
        hex: '#4da5ed',
    },
    {
        name: 'Light Blue',
        colour: tinyColor('#75daff'),
        hex: '#75daff',
    },
    {
        name: 'Grey Blue',
        colour: tinyColor('#607d8b'),
        hex: '#607d8b',
    },
    {
        name: 'Dark Teal',
        colour: tinyColor('#16ccba'),
        hex: '#16ccba',
    },
    {
        name: 'Light Teal',
        colour: tinyColor('#72dbcf'),
        hex: '#72dbcf',
    },
    {
        name: 'Light Green',
        colour: tinyColor('#8dd44f'),
        hex: '#8dd44f',
    },
    {
        name: 'Green',
        colour: tinyColor('#48c465'),
        hex: '#48c465',
    },
    {
        name: 'Yellow',
        colour: tinyColor('#f0e248'),
        hex: '#f0e248',
    },
    {
        name: 'Tan',
        colour: tinyColor('#ebca7b'),
        hex: '#ebca7b',
    },
    {
        name: 'Light Brown',
        colour: tinyColor('#dbb14e'),
        hex: '#dbb14e',
    },
    {
        name: 'Brown',
        colour: tinyColor('#a86b54'),
        hex: '#a86b54',
    },
    {
        name: 'Orange',
        colour: tinyColor('#ff5722'),
        hex: '#ff5722',
    },
];
