import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

import WidgetIcon from '../../widgets/components/widget-icon';

const StyledSectionItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border: 1px solid ${props => props.theme.dashboardBuilder.createADashboard.sidebar.draggable.borderColor};
    background-color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.draggable.backgroundColor};
    color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.draggable.color};
    border-radius: 4px;
    padding: 8px 16px;
    display: ${props => props.hidden && 'none'};
    font-size: 0.9rem;
    font-weight: 500;
`;

const StyledDetailsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const CreateADashboardDraggableItem = ({
    itemId,
    displayName,
    iconType,
    index,
    menu,
}: {
    itemId: number | string;
    displayName: string;
    iconType: string;
    index: number;
    menu?: React.ReactNode;
}) => {
    return (
        <Draggable key={`draggable-${itemId}`} draggableId={`draggable-${itemId}`} index={index}>
            {provided => (
                <StyledSectionItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <StyledDetailsContainer>
                        {iconType && <WidgetIcon widgetType={iconType} />}
                        {displayName}
                    </StyledDetailsContainer>
                    <div>{menu}</div>
                </StyledSectionItem>
            )}
        </Draggable>
    );
};

export default CreateADashboardDraggableItem;
