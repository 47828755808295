import { ChartDateGranularity } from '../configurations/common/chart-types';
import dataTypes from '../filter-bar/enums/data-types';

export const getDateGranularity = (startDate, endDate, resource) => {
    if (startDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
        // if dataType === DATE, we cannot show data by hour
        if (
            resource &&
            resource.dateDimension &&
            resource.dateDimension.dataType &&
            resource.dateDimension.dataType === dataTypes.DATE
        ) {
            return ChartDateGranularity.Day;
        }

        return ChartDateGranularity.Hour;
    } else {
        return ChartDateGranularity.Day;
    }
};
