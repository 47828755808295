import { ResourceDataSuccess } from '../react-query/types';
import * as resources from '../configurations/resources';
import { UseFetchResourceArgs } from '../react-query/hooks/use-fetch-resource';

export const channelInsightsTopChannelQuery = ({
    startDate,
    endDate,
}: {
    startDate: string;
    endDate: string;
}): UseFetchResourceArgs<ResourceDataSuccess, string | undefined> => {
    return {
        resource: resources.REPORT_MCS_CHANNEL,
        params: [
            { key: `${resources.REPORT_MCS_CHANNEL.dateDimension.rawName}__gte`, value: startDate },
            { key: `${resources.REPORT_MCS_CHANNEL.dateDimension.rawName}__lte`, value: endDate },
            { key: 'group_by', value: 'referer' },
            { key: 'order_by', value: '-fm_sales' },
            { key: 'limit', value: 1 },
            { key: 'selected_fields', value: 'referer, fm_sales' },
        ],
        select: (data: ResourceDataSuccess) => {
            if (data.objects.length > 0) {
                return data.objects[0].values.referer.value;
            }
        },
    };
};
