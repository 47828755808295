import React from 'react';
import styled from 'styled-components';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { FieldValues } from 'react-hook-form';

// Redux
import { useDispatch } from 'react-redux';
import { setModal } from '../../../redux/actions/modal';
import { addNotification } from '../../../redux/actions/notification';

// Hooks
import usePostResourceWithPayload from '../../../react-query/hooks/use-post-resource-with-payload';

// Config
import { CONFIG_BRAND_SPLIT_CONFIG } from '../../../configurations/resources-config';

// Helpers
import { generatePath } from '../../../helpers/request-builder';

// Components
import ModalNavigation from '../../../components/modal-navigation';
import Form from '../../../components/forms/form';
import FormAddBrandSplitConfig from './forms/form-add-brand-split-config';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const LayoutModalAddBrandSplitConfig = () => {
    const dispatch = useDispatch();

    const brandSplitPostMutation = usePostResourceWithPayload({
        resource: CONFIG_BRAND_SPLIT_CONFIG,
        handleOnSuccess: () => {
            dispatch(setModal('BrandSplitConfig'));
            dispatch(addNotification({ copy: 'Brand split configuration successfully added', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(addNotification({ copy: 'Error adding brand split configuration', type: 'error' }));
        },
    });

    const handleSubmit = (data: FieldValues) => {
        brandSplitPostMutation.mutate({
            sector: generatePath('config', 'brand-split-sector', String(data.sector)),
            purchased: generatePath('config', 'brand-split-purchased', String(data.purchased)),
            price: generatePath('config', 'brand-split-price', String(data.price)),
            innovation: generatePath('config', 'brand-split-innovation', String(data.innovation)),
            category: generatePath('config', 'brand-split-category', String(data.category)),
            size: generatePath('config', 'brand-split-size', String(data.size)),
        });
    };
    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(setModal('BrandSplitConfig')),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Add Brand Split Configuration</h2>

            <StyledFormContainer>
                <Form status={brandSplitPostMutation.isPending ? 'disabled' : 'enabled'} onSubmit={handleSubmit}>
                    <FormAddBrandSplitConfig />
                    <Form.Footer>
                        <Form.InputButton type="submit" value="Save" loading={brandSplitPostMutation.isPending} />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </>
    );
};

export default LayoutModalAddBrandSplitConfig;
