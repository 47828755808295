import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

// Redux
import { useDispatch } from 'react-redux';
import { setTitle } from '../../../../redux/actions/page-meta';

// Context
import WidgetLibraryContextProvider, { WidgetLibraryContext } from '../context/widget-library-context';

// Components
import LayoutWidgetLibraryTopBar from './layout-widget-library-top-bar';
import LayoutWidgetLibraryList from './layout-widget-library-list';
import LayoutLoading from '../../../layouts/layout-loading';
import LayoutEmpty from '../../../layouts/layout-empty';
import IconImageSlash from '../../../../components/icons/image-slash';

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const StyledIconImageSlash = styled(IconImageSlash)`
    width: 40px;
`;

const LayoutWidgetLibraryContent = () => {
    const { dashboardWidgetsQuery, widgetQuery, widgetTypeQuery, widgets } = useContext(WidgetLibraryContext);

    if (
        dashboardWidgetsQuery.isLoading ||
        widgetQuery.isLoading ||
        widgetTypeQuery.isLoading ||
        widgetQuery.isFetching
    ) {
        return <LayoutLoading copy={'Loading widgets...'} />;
    }

    if (widgetQuery.isSuccess && widgetQuery.data.objects.length === 0) {
        return (
            <StyledContainer>
                <LayoutWidgetLibraryTopBar />
                <LayoutEmpty icon={<StyledIconImageSlash />} copy="You have not created any widgets yet." />
            </StyledContainer>
        );
    }

    if (widgets.length === 0) {
        return (
            <StyledContainer>
                <LayoutWidgetLibraryTopBar />
                <LayoutEmpty icon={<StyledIconImageSlash />} copy="No widgets found." />
            </StyledContainer>
        );
    }

    return (
        <StyledContainer>
            <LayoutWidgetLibraryTopBar />
            <LayoutWidgetLibraryList />
        </StyledContainer>
    );
};

const LayoutWidgetLibrary = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle('Widget Library'));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <WidgetLibraryContextProvider>
            <LayoutWidgetLibraryContent />
        </WidgetLibraryContextProvider>
    );
};

export default LayoutWidgetLibrary;
