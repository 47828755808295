import React, { memo } from 'react';

// Core Dependencies
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Image
import IconHome from '../../components/icons/home';

// Types
import { BreadcrumbHomeProps } from '../types';

// Styles
const StyledBreadcrumbsItem = styled.div`
    margin-left: -20px;
    z-index: 1;
`;

const StyledBreadcrumbsItemLabel = styled.div`
    height: 11px;
    line-height: 11px;
    position: relative;
    padding-left: 24px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 2px;
    text-transform: uppercase;
    color: #0a0a0a;
`;

const StyledBreadcrumbsItemContentWrapper = styled.div<{ backgroundColor: string }>`
    border-radius: 0 37px 37px 0;
    background-color: ${props =>
        props.backgroundColor === 'grey' ? props.theme.colours.lightGrey : props.theme.colours.white};
    padding: 6px;
    display: block;
    width: inherit;
    height: 100%;
    cursor: pointer;
`;

const StyledBreadcrumbsItemContentHome = styled.div<{ backgroundColor: string }>`
    padding-left: 0;
    border: none;
    border-top: 1px solid
        ${props => (props.backgroundColor === 'grey' ? props.theme.colours.lightGrey : props.theme.colours.white)};
    border-bottom: 1px solid #d2d2d2;
    line-height: 37px;
    height: 37px;
    min-width: 54px;
    position: relative;
    border-radius: 0 37px 37px 0;
    background-color: ${props =>
        props.backgroundColor === 'grey' ? props.theme.colours.white : props.theme.colours.lightGrey};
    overflow: hidden;
    ${props => props.theme.boxShadow(0)};

    & svg {
        fill: ${props => props.theme.colours.black};
        height: 27px;
        width: 27px;
        position: absolute;
        top: 50%;
        left: 47%;
        transform: translate(-53%, -50%);
    }
`;

const BreadcrumbHome = ({ onClick, backgroundColor }: BreadcrumbHomeProps) => {
    return (
        <StyledBreadcrumbsItem>
            <StyledBreadcrumbsItemLabel />
            <StyledBreadcrumbsItemContentWrapper backgroundColor={backgroundColor!}>
                <StyledBreadcrumbsItemContentHome onClick={onClick} backgroundColor={backgroundColor!}>
                    <IconHome />
                </StyledBreadcrumbsItemContentHome>
            </StyledBreadcrumbsItemContentWrapper>
        </StyledBreadcrumbsItem>
    );
};

export default memo(BreadcrumbHome, isEqual);
