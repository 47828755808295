import * as fields from './fields';
import * as resources from './resources';
import moment from 'moment';
import { getDateOfTheDay } from '../helpers/valid-date-helper';

//Helper
import { ChartType, allChartTypesWithLine } from './common/chart-types';
import dateStringToUnix from '../helpers/date-string-to-unix';

export const config = {
    pageTitle: 'SEO Rank, Visibility & Marketshare',
    reportDescription:
        'The Rank, Visibility & Marketshare of your SEO Keywords by Market, Category & Sub Category. This report updates every Sunday.',
    dateDimension: fields.DATE_DATE,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: true,
        },
        datePicker: {
            // data is batched weekly Sunday - Sunday
            customStartDate: () => {
                const lastValidDay = getDateOfTheDay(moment.utc(), 'Sunday');
                const startDate = lastValidDay.subtract(1, 'weeks');
                return startDate;
            },
            customEndDate: () => {
                const endDate = getDateOfTheDay(moment.utc(), 'Sunday');
                return endDate;
            },
            // disable day if date is not a sunday
            disabledDays: function (date) {
                return moment(date).format('dddd') !== 'Sunday';
            },
        },
    },
    resources: [resources.REPORT_SEOPT_KEYWORD_VISIBILITY, resources.REPORT_SEOPT_KEYWORD_VISIBILITY_URL],
    ignoreProducts: true,
    chart: {
        chartType: ChartType.Line,
        allowedChartTypes: allChartTypesWithLine,
        skippedPlotNullValues: true,
        chartId: 'report-seogd-keyword-visibility',
        allYMetrics: [
            fields.MARKET_SHARE,
            fields.SEARCH_VOLUME_WEEKLY,
            fields.SEO_KEYWORD_VISIBILITY,
            fields.SEO_KEYWORD_MAX_VISIBILITY,
            fields.POSITION,
        ],
        initialYMetrics: [fields.MARKET_SHARE],
        showEmpty: false,
        dataFormatter: function (dates, datas, dimension, metric) {
            return datas.map(data => {
                return [dateStringToUnix(data[dimension.rawName].raw_value), data[metric].raw_value];
            });
        },
    },
    table: {
        tabListItems: [
            {
                tabName: 'Overview',
                displayName: 'Overview',
                isSelected: true,
                metrics: [
                    fields.SEARCH_VOLUME_WEEKLY,
                    fields.CURRENT_POSITION,
                    fields.SEO_KEYWORD_VISIBILITY,
                    fields.SEO_KEYWORD_MAX_VISIBILITY,
                    fields.MARKET_SHARE,
                ],
            },
            {
                tabName: 'Marketshare Change Over Time',
                displayName: 'Marketshare',
                isSelected: true,
                metrics: [
                    fields.MARKET_SHARE,
                    fields.MARKETSHARE_CHANGE_OVER_TIME,
                    fields.MARKETSHARE_CHANGE_OVER_TIME_PCT,
                ],
            },
            {
                tabName: 'Search Volume Change Over Time',
                displayName: 'Search Volume',
                isSelected: true,
                metrics: [
                    fields.SEARCH_VOLUME_WEEKLY,
                    fields.SEARCHVOLUME_CHANGE_OVER_TIME,
                    fields.SEARCHVOLUME_CHANGE_OVER_TIME_PCT,
                ],
            },
            {
                tabName: 'Average Rank Change Over Time',
                displayName: 'Ranking',
                isSelected: true,
                metrics: [
                    fields.CURRENT_POSITION,
                    fields.POSITION_CHANGE_OVER_TIME,
                    fields.POSITION_CHANGE_OVER_TIME_PCT,
                ],
            },
            {
                tabName: 'Visibility Rank Change Over Time',
                displayName: 'Visibility',
                isSelected: true,
                metrics: [
                    fields.SEO_KEYWORD_VISIBILITY,
                    fields.VISIBILITY_CHANGE_OVER_TIME,
                    fields.VISIBILITY_CHANGE_OVER_TIME_PCT,
                ],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.MARKET_KEYWORD_COUNTRY.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.LANGUAGE.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.CORE_PARENT_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.CORE_CHILD_CAT,
                    },
                ],
            },
            [fields.CORE_PRODUCT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.CORE_CHILD_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.CORE_SUB_CAT,
                    },
                ],
            },
            [fields.CORE_SUB_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.MARKET_KEYWORD_KEYWORD,
                    },
                ],
            },
            [fields.KEYWORD_MODIFIER.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.LOCALE,
                    },
                ],
            },
            [fields.LOCALE.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.MARKET_KEYWORD_MAPPED_HOST.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.MARKET_KEYWORD_MAPPED_PATH,
                    },
                ],
            },
            [fields.MARKET_KEYWORD_MAPPED_PATH.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY,
                        field: fields.MARKET_KEYWORD_KEYWORD,
                    },
                ],
            },
            [fields.MARKET_KEYWORD_KEYWORD.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY_URL,
                        field: fields.RANKING_URL,
                    },
                ],
            },
        },
    },
    allDataExport: {
        fileName: 'report-seo-rank-visibility-marketshare',
        resource: resources.REPORT_SEOPT_KEYWORD_VISIBILITY_URL_CSV,
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
