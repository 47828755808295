import React from 'react';
import { CubedIcon } from './types';

const IconInfoTriangle: CubedIcon = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 512 512">
            <ellipse
                fill="none"
                stroke="#000000"
                strokeMiterlimit="10"
                strokeWidth="20px"
                cx="255"
                cy="256.2"
                rx="225"
                ry="222.96"
            />
            <path
                fill="#000000"
                d="M230.34,316.44h7.8v-56.2h-7.8a7.81,7.81,0,0,1-7.81-7.81V233.7a7.81,7.81,0,0,1,7.81-7.81h43.71a7.81,7.81,0,0,1,7.81,7.81v82.74h7.8a7.81,7.81,0,0,1,7.81,7.81V343a7.81,7.81,0,0,1-7.81,7.81H230.34a7.81,7.81,0,0,1-7.81-7.81V324.25A7.81,7.81,0,0,1,230.34,316.44Zm29.5-165.65a28.1,28.1,0,1,0,28.1,28.1A28.1,28.1,0,0,0,259.84,150.79Z"
            />
        </svg>
    );
};

export default IconInfoTriangle;
