import React from 'react';
import Form from '../../../../components/forms/form';
import getDimensions from '../../helpers/get-dimensions';
import validators from '../../../../components/forms/validation/validators';
import { useFormContext } from 'react-hook-form';

const FormFieldDimension = ({ label }: { label?: string }) => {
    const { resetField } = useFormContext();

    // Dimensions
    const dimensions = getDimensions();

    // When the dimension changes, reset the orderBy and fields
    const handleChangeDimension = () => {
        resetField('orderBy');
        resetField('fields');
    };
    return (
        <Form.Field>
            <Form.InputLabel htmlFor="groupBy" label={label || 'Dimension'} />
            <Form.InputSelect
                name="groupBy"
                options={dimensions}
                placeholder="Please select a dimension"
                validators={[validators.required]}
                onValueChange={handleChangeDimension}
            />
        </Form.Field>
    );
};

export default FormFieldDimension;
