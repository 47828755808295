import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import SkeletonTimeline from '../components/skeleton-timeline';
import IconLocation from '../../assets/images/icons/location.svg';
import IconClock from '../../assets/images/icons/clock-icon.png';
import IconFlag from '../../assets/images/icons/011-flag.svg';
import IconEye from '../../assets/images/icons/eye.svg';

const StyledTimeline = styled.div`
    width: 100%;
`;

const StyledTimelineItemWrapper = styled.div`
    display: flex;
    min-height: 100px;
`;

const StyledTimelineItem = styled.div`
    flex: 0 1 60px;
    display: flex;
    flex-direction: column;
`;

const StyledTimelineItemIconWrapper = styled.div`
    width: 100%;
    flex: 0 1 60px;
`;

const StyledItemIcon = styled.div`
    height: 30px;
    width: 30px;
    margin-left: calc(50% - 15px);
    border-radius: 100px;
    border: 4px solid orange;
    background: ${props =>
        props.icon === 'impression'
            ? `url(${IconEye}) no-repeat center center`
            : props.icon === 'sale'
            ? `url(${IconFlag}) no-repeat center center`
            : `url(${IconLocation}) no-repeat center center`};
    background-size: 15px;
    border-color: ${props => props.borderColor};
`;

const StyledIconInBetween = styled.div`
    min-height: 15px;
    width: calc(50% + 2px);
    border-right: ${props =>
        props.isHidden ? '4px solid transparent !important' : props.dotted ? '4px dotted orange' : '4px solid orange'};
    border-color: ${props => props.borderColor};
`;

const StyledInbetween = styled.div`
    width: calc(50% + 2px);
    border-right: ${props =>
        props.isHidden ? '4px solid transparent !important' : props.dotted ? '4px dotted orange' : '4px solid orange'};
    flex: ${props => (props.dotted ? '0 1 0.7' : '1 1 auto')};
    min-height: 15px;
    border-color: ${props => props.borderColor};
`;

const StyledChildWrapper = styled.div`
    flex: 1 1 auto;
    margin-top: 24px;
`;

const StyledItemDuration = styled.div`
    display: block;
    width: 100%;
    padding: 5px 0px 5px 22px;
`;

const StyledDurationIcon = styled.div`
    width: 15px;
    height: 15px;
    background: url(${IconClock}) no-repeat center center;
    background-size: contain;
    float: left;
`;

const StyledDurationCopy = styled.div`
    color: ${props => props.theme.colours.textDefault};
    font-style: italic;
    float: left;
    font-size: 11px;
    margin-left: 10px;
    line-height: 15px;
`;

const StyledClear = styled.div`
    clear: both;
`;

const Timeline = ({ items = [] }) => {
    const calculateDuration = (start, end) => {
        if (!start || !end) return;
        const fStart = moment(start);
        const fEnd = moment(end);
        return moment.duration(fStart.diff(fEnd)).humanize();
    };

    if (!items || items.length < 1) return <SkeletonTimeline />;
    return (
        <StyledTimeline>
            {items.map((item, index) => {
                const isLast = index >= items.length - 1;
                let duration = 0;
                if (!isLast) {
                    duration = calculateDuration(item.end, items[index + 1].start);
                }
                return (
                    <div key={index}>
                        <StyledTimelineItemWrapper>
                            <StyledTimelineItem>
                                <StyledTimelineItemIconWrapper>
                                    <StyledIconInBetween
                                        dotted
                                        isHidden={index < 1}
                                        borderColor={item.colour.toHexString()}
                                    />
                                    <StyledIconInBetween isHidden={index < 1} borderColor={item.colour.toHexString()} />
                                    <StyledItemIcon icon={item.icon} borderColor={item.colour.toHexString()} />
                                </StyledTimelineItemIconWrapper>
                                <StyledInbetween isHidden={isLast} borderColor={item.colour.toHexString()} />
                                <StyledInbetween dotted isHidden={isLast} borderColor={item.colour.toHexString()} />
                            </StyledTimelineItem>
                            <StyledChildWrapper>{item.component}</StyledChildWrapper>
                        </StyledTimelineItemWrapper>
                        {isLast ? null : (
                            <StyledItemDuration>
                                <StyledDurationIcon />
                                <StyledDurationCopy>{duration}</StyledDurationCopy>
                                <StyledClear />
                            </StyledItemDuration>
                        )}
                    </div>
                );
            })}
        </StyledTimeline>
    );
};

export default Timeline;
