import React from 'react';
import styled from 'styled-components';

// Icon
import imageArrow from '../../../../assets/images/icons/icon-arrowup-black.svg';

// Types
import { ContributionTableHeaders, orderByDir } from '../../types';

// Styles
const StyledTableHeader = styled.th`
    border: 1px solid ${props => props.theme.colours.borderGrey};
    line-height: 17px;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    color: ${props => props.theme.colours.textDefault};
    user-select: none;
    position: relative;
    transition: ${props => props.theme.transition};

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    padding: 8px 10px !important;
`;

const StyledTableHeaderDiv = styled.div`
    display: inline-block;
    padding: 12px !important;
    margin-left: 22px;
    position: relative;
`;

const StyledOrderIcon = styled.i<{ visible: boolean; orderDir: orderByDir }>`
    background-image: url(${imageArrow});
    background-size: contain;
    background-repeat: no-repeat;
    display: inline;
    position: absolute;
    top: 40%;
    left: 10px;
    height: 12px;
    width: 12px;
    opacity: ${props => (props.visible ? 0.5 : 0)};
    transform: ${props => (props.orderDir === 'asc' ? `rotate(0deg)` : `rotate(180deg)`)};
    transition: ${props => props.theme.transition};
`;

// Types
type ContributionTableHeaderCellProps = {
    header: ContributionTableHeaders;
    orderByState: string;
    orderDirState: orderByDir;
    handleSetCurrentOrder: (name: string) => void;
};

const ContributionTableHeaderCell = ({
    header,
    orderByState,
    orderDirState,
    handleSetCurrentOrder,
}: ContributionTableHeaderCellProps) => {
    return (
        <StyledTableHeader
            onClick={() => {
                handleSetCurrentOrder(header.name);
            }}
        >
            <StyledOrderIcon visible={orderByState === header.name} orderDir={orderDirState} />
            <StyledTableHeaderDiv>{header.displayName}</StyledTableHeaderDiv>
        </StyledTableHeader>
    );
};

export default ContributionTableHeaderCell;
