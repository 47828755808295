import { useEffect, useRef, useState } from 'react';

const UseComponentVisible = initialIsVisible => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);

    const ref = useRef(null);

    const handleHideElement = event => {
        if (event.key === 'Escape') setIsComponentVisible(false);
    };

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) setIsComponentVisible(false);
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideElement, true);
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('keydown', handleHideElement, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
};

export default UseComponentVisible;
