export const vennConfig = {
    chart: {
        backgroundColor: 'transparent',
    },
    plotOptions: {
        series: {
            animation: false,
        },
    },
    credits: {
        enabled: false,
    },
    legend: { enabled: true },
};
