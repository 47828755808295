// Action
import { ACTIONS } from './actions';

// Helper
import { isAnyDataEmptyCheck } from './helper';

// Configurations
import { FILTER_CONFIG } from '../configurations/filter-config';

// Types
import {
    ActionFilterApply,
    ActionFilterClear,
    ActionFilterChannelClear,
    ActionFilterExpandCollapse,
    ActionMetricsFilterAddNewFilter,
    ActionMetricsFilterChangeMetricData,
    ActionMetricsFilterChangeOperatorData,
    ActionMetricsFilterChangeValueData,
    ActionMetricsFilterEditSelectedFilter,
    ActionMetricsFilterRemoveNewFilter,
    ActionMetricsFilterRemoveSelectedFilter,
    ActionMetricsFilterSelectNewFilter,
    ActionProductFilterDataSet,
    ActionProductFilterInitialDataSet,
    ActionChannelFilterInitialDataSet,
    ActionChannelFilterDataSet,
    FilterActions,
    FilterChannelActions,
    Metric,
    MetricsFilter,
    Operator,
    Product,
    Channel,
    ReducerActions,
    ActionButtonGroupSet,
    ButtonGroupOptions,
} from '../types';

export const ReportFilterReducer = (state: typeof FILTER_CONFIG.FILTER, action: ReducerActions) => {
    const { type, payload } = action;

    switch (type) {
        case ACTIONS.FILTER_EXPAND_COLLAPSE:
            return onFilterExpandCollapseSet(state, payload as ActionFilterExpandCollapse['payload']);
        case ACTIONS.METRICS_FILTER_CHANGE_METRIC_DATA:
            return onMetricsFilterChangeMetricData(state, payload as ActionMetricsFilterChangeMetricData['payload']);
        case ACTIONS.METRICS_FILTER_CHANGE_OPERATOR_DATA:
            return onMetricsFilterChangeOperatorData(
                state,
                payload as ActionMetricsFilterChangeOperatorData['payload']
            );
        case ACTIONS.METRICS_FILTER_CHANGE_VALUE_DATA:
            return onMetricsFilterChangeValueData(state, payload as ActionMetricsFilterChangeValueData['payload']);
        case ACTIONS.METRICS_FILTER_SELECT_NEW_FILTER:
            return onMetricsFilterSelectNewFilter(state, payload as ActionMetricsFilterSelectNewFilter['payload']);
        case ACTIONS.METRICS_FILTER_EDIT_SELECTED_FILTER:
            return onMetricsFilterEditFilter(state, payload as ActionMetricsFilterEditSelectedFilter['payload']);
        case ACTIONS.METRICS_FILTER_REMOVE_SELECTED_FILTER:
            return onMetricsFilterRemoveFilter(state, payload as ActionMetricsFilterRemoveSelectedFilter['payload']);
        case ACTIONS.METRICS_FILTER_ADD_NEW_FILTER:
            return onMetricsFilterAddNewFilter(state, payload as ActionMetricsFilterAddNewFilter['payload']);
        case ACTIONS.METRICS_FILTER_REMOVE_NEW_FILTER:
            return onMetricsFilterRemoveNewFilter(state, payload as ActionMetricsFilterRemoveNewFilter['payload']);
        case ACTIONS.FILTER_APPLY:
            return onFilterApply(state, payload as ActionFilterApply['payload']);
        case ACTIONS.FILTER_CLEAR:
            return onFilterClear(state, payload as ActionFilterClear['payload']);
        case ACTIONS.PRODUCT_FILTER_DATA_INITIAL_SET:
            return onProductDataSet(state, payload as ActionProductFilterInitialDataSet['payload']);
        case ACTIONS.PRODUCT_FILTER_DATA_SET:
            return onProductDataSet(state, payload as ActionProductFilterDataSet['payload']);
        case ACTIONS.CHANNEL_FILTER_DATA_INITIAL_SET:
            return onChannelDataSet(state, payload as ActionChannelFilterInitialDataSet['payload']);
        case ACTIONS.CHANNEL_FILTER_DATA_SET:
            return onChannelDataSet(state, payload as ActionChannelFilterDataSet['payload']);
        case ACTIONS.FILTER_CHANNEL_CLEAR:
            return onChannelFilterClear(state, payload as ActionFilterChannelClear['payload']);
        case ACTIONS.BUTTON_GROUP_SET:
            return onButtonGroupSelect(state, payload as ActionButtonGroupSet['payload']);
        case ACTIONS.COMPARISON_DATE_RANGE_TOGGLE:
            return onFilterDateComparisonViewToggle(state, payload as ActionFilterExpandCollapse['payload']);
        default:
            return state;
    }
};

const onButtonGroupSelect = (state: typeof FILTER_CONFIG.FILTER, value: { buttonGroupOptions: ButtonGroupOptions }) => {
    return {
        ...state,
        buttonGroupOptions: value,
    };
};

const onFilterExpandCollapseSet = (state: typeof FILTER_CONFIG.FILTER, value: boolean) => {
    return {
        ...state,
        filterExpanded: value,
        showAddMetricFilter: false,
    };
};

const onMetricsFilterChangeMetricData = (state: typeof FILTER_CONFIG.FILTER, value: { id: number; data: Metric }) => {
    const modifiedArray = [...state.selectedMetricsFilterData].map(data => {
        if (data.id === value.id) {
            data = {
                ...data,
                metric: value.data,
                metricType: value.data.dataType,
                operator: '' as any, // reset selected operator
                value: '', // reset value
            };
        }
        return data;
    });

    const changedObject = modifiedArray.filter(data => data.id === value.id)[0];

    return {
        ...state,
        selectedMetricsFilterData: modifiedArray,
        enableFilterApplyButton: !isAnyDataEmptyCheck(changedObject),
    };
};

const onMetricsFilterChangeOperatorData = (
    state: typeof FILTER_CONFIG.FILTER,
    value: { id: number; data: Operator }
) => {
    const modifiedArray = [...state.selectedMetricsFilterData].map(data => {
        if (data.id === value.id) {
            data = {
                ...data,
                operator: value.data,
                value: '', // reset value
            };
        }
        return data;
    });

    const changedObject = modifiedArray.filter(data => data.id === value.id)[0];

    return {
        ...state,
        selectedMetricsFilterData: modifiedArray,
        enableFilterApplyButton: !isAnyDataEmptyCheck(changedObject),
    };
};

const onMetricsFilterChangeValueData = (state: typeof FILTER_CONFIG.FILTER, value: { id: number; value: string }) => {
    const modifiedArray = [...state.selectedMetricsFilterData].map(data => {
        if (data.id === value.id) {
            data = {
                ...data,
                value: value.value,
            };
        }
        return data;
    });

    const changedObject = modifiedArray.filter(data => data.id === value.id)[0];

    return {
        ...state,
        selectedMetricsFilterData: modifiedArray,
        enableFilterApplyButton: !isAnyDataEmptyCheck(changedObject),
    };
};

const onMetricsFilterSelectNewFilter = (state: typeof FILTER_CONFIG.FILTER, value: FilterActions) => {
    const { enableFilterApplyButton, filterData, showAddMetricFilter } = value;

    const newFilterObject = {
        id: Math.floor(100000 + Math.random() * 900000),
        metric: '',
        metricType: null,
        operator: '',
        value: '',
        status: 'add',
    };

    const modifiedArray = state.selectedMetricsFilterData.map(data => {
        if (data.id === filterData!.id) {
            data = {
                ...data,
                metric: filterData!.metric,
                operator: filterData!.operator,
                value: filterData!.value!,
                status: 'view',
            };
        }
        return data;
    });

    return {
        ...state,
        selectedMetricsFilterData: [...modifiedArray, ...[newFilterObject]],
        enableFilterApplyButton,
        showAddMetricFilter,
    };
};

const onMetricsFilterEditFilter = (state: typeof FILTER_CONFIG.FILTER, value: FilterActions) => {
    const { enableFilterApplyButton, filterData, showAddMetricFilter } = value;

    const modifiedArray = state.selectedMetricsFilterData
        .map(data => {
            if (data.id === filterData!.id) {
                data = {
                    ...data,
                    status: 'edit',
                };
            }
            return data;
        })
        .filter(data => data.status !== 'add');

    return {
        ...state,
        selectedMetricsFilterData: modifiedArray,
        enableFilterApplyButton,
        showAddMetricFilter,
    };
};

const onMetricsFilterRemoveFilter = (state: typeof FILTER_CONFIG.FILTER, value: FilterActions) => {
    const { id, enableFilterApplyButton } = value;

    const modifiedArray = state.selectedMetricsFilterData.filter(data => data.id !== id);

    return {
        ...state,
        selectedMetricsFilterData: modifiedArray,
        enableFilterApplyButton,
    };
};

const onMetricsFilterAddNewFilter = (state: typeof FILTER_CONFIG.FILTER, value: boolean) => {
    return {
        ...state,
        showAddMetricFilter: value,
        enableFilterApplyButton: false,
    };
};

const onMetricsFilterRemoveNewFilter = (state: typeof FILTER_CONFIG.FILTER, value: FilterActions) => {
    const { id, showAddMetricFilter } = value;

    const modifiedArray = state.selectedMetricsFilterData.filter(data => data.id !== id);

    const checkAddArray = modifiedArray.filter(data => data.status === 'add');

    if (checkAddArray.length === 0) {
        const newFilterObject = {
            id: Math.floor(100000 + Math.random() * 900000),
            metric: '' as any, // reset metric
            metricType: 0,
            operator: '' as any, // reset selected operator
            value: '',
            status: 'add',
        };
        modifiedArray.push(newFilterObject);
    }

    return {
        ...state,
        selectedMetricsFilterData: modifiedArray,
        showAddMetricFilter,
        enableFilterApplyButton: !(modifiedArray.length === 0),
    };
};

const onFilterClear = (state: typeof FILTER_CONFIG.FILTER, value: { product: Product }) => {
    const { product } = value;
    const newFilterArray = [
        {
            id: Math.floor(100000 + Math.random() * 900000),
            metric: '',
            metricType: null,
            operator: '',
            value: '',
            status: 'add',
        },
    ];

    return {
        ...state,
        selectedMetricsFilterData: newFilterArray,
        selectedProductsFilterData: product,
        enableFilterApplyButton: false,
        filterExpanded: false,
        showAddMetricFilter: false,
    };
};

const onChannelFilterClear = (state: typeof FILTER_CONFIG.FILTER, value: { channel: Channel }) => {
    const { channel } = value;

    return {
        ...state,
        selectedChannelFilterData: channel,
        enableChannelFilterApplyButton: false,
    };
};

const onFilterApply = (state: typeof FILTER_CONFIG.FILTER, value: { selectedMetricsFilterData: MetricsFilter[] }) => {
    const { selectedMetricsFilterData } = value;

    const newFilterObject = {
        id: Math.floor(100000 + Math.random() * 900000),
        metric: '' as any, // reset metric
        metricType: 0,
        operator: '' as any, // reset selected operator
        value: '',
        status: 'add',
    };
    selectedMetricsFilterData.push(newFilterObject);

    return {
        ...state,
        enableFilterApplyButton: false,
        selectedMetricsFilterData,
    };
};

const onProductDataSet = (state: typeof FILTER_CONFIG.FILTER, value: FilterActions) => {
    const { product, enableFilterApplyButton } = value;

    return {
        ...state,
        selectedProductsFilterData: product,
        enableFilterApplyButton,
    };
};

const onChannelDataSet = (state: typeof FILTER_CONFIG.FILTER, value: FilterChannelActions) => {
    const { channel, enableChannelFilterApplyButton } = value;

    return {
        ...state,
        selectedChannelFilterData: channel,
        enableChannelFilterApplyButton,
    };
};

const onFilterDateComparisonViewToggle = (state: typeof FILTER_CONFIG.FILTER, value: boolean) => {
    return {
        ...state,
        enableComparisonDateRange: value,
    };
};
