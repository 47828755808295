import React, { useContext, useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../redux/actions/page-meta';
import { addNotification } from '../../../redux/actions/notification';

// Context
import { DashboardBuilderContext } from '../../context/dashboard-builder-context';
import CreateAWidgetContextProvider, { CreateAWidgetContext } from '../context/create-a-widget-context';

// Resources
import {
    DASHBOARD_BUILDER_WIDGET,
    DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
} from '../../config/resources-dashboard-builder';

// Types
import { FieldValues } from 'react-hook-form';
import { CubedResource } from '../../../types';
import { NotificationMessageType } from '../../../enums/notification-types';
import { RootState } from '../../../redux/store';

// Hooks
import { useQueryClient } from '@tanstack/react-query';
import usePostResource from '../../../react-query/hooks/use-post-resource';
import { useNavigate } from 'react-router';

// Helpers
import { generatePath } from '../../../helpers/request-builder';
import getAvailableResources from '../helpers/get-available-resources';
import getCurrentSelectedMetrics from '../helpers/get-current-selected-metrics';
import generateQueryKey from '../../../react-query/helpers/generate-query-key';

// Components
import Form from '../../../components/forms/form';
import CreateAWidgetForm from '../forms/create-a-widget-form';
import LayoutLoading from '../../layouts/layout-loading';

const CreateAWidgetFormWrapper = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const accountToken = useSelector((state: RootState) => state.account.token);
    const { clientUserId } = useContext(DashboardBuilderContext);
    const { visualisations, orderByDirection } = useContext(CreateAWidgetContext);

    // State
    const [formData, setFormData] = useState<FieldValues>();
    const [resource, setResource] = useState<CubedResource>();
    const [createdWidgetId, setCreatedWidgetId] = useState<number>();

    const visualisationId =
        formData && formData.visualisation && visualisations && visualisations.data
            ? visualisations.data.objects.filter(vis => vis.name === formData?.visualisation)[0].id
            : 0;

    const orderByDirectionId =
        formData && formData.orderByDirection && orderByDirection && orderByDirection.data
            ? orderByDirection.data.objects.filter(orderByDir => orderByDir.direction === formData.orderByDirection)[0]
                  .id
            : 0;

    // When the form is submitted, get the resource
    // If there are multiple resources, use the one with the fewest dimensions
    useEffect(() => {
        if (formData) {
            // Current Selected Metrics
            const currentSelectedMetrics = getCurrentSelectedMetrics(formData);

            // Current Available Resources
            const currentAvailableResources = getAvailableResources({
                dimension: formData?.groupBy,
                currentMetrics: currentSelectedMetrics,
            });

            setResource(currentAvailableResources[0]);
        }
    }, [formData]);

    const widgetPostMutation = usePostResource({
        resource: DASHBOARD_BUILDER_WIDGET,
        data: {
            title: formData?.widgetName,
            type: generatePath('dashboard-builder', 'dashboard-builder-widget-type', visualisationId) || 0,
            author: generatePath('dashboard-builder', 'dashboard-builder-client-users', String(clientUserId)) || '0',
        },
        handleOnSuccess: data => {
            setCreatedWidgetId(data.id as number);
        },
        handleOnError: error => {
            if (error && error.includes('duplicate')) {
                dispatch(
                    addNotification({
                        copy: 'A widget with this name already exists.',
                        type: NotificationMessageType.Error,
                    })
                );
            } else {
                dispatch(
                    addNotification({
                        copy: 'There was an issue creating this widget. Please try again later or contact support@cubed.email',
                        type: NotificationMessageType.Error,
                    })
                );
            }
        },
    });

    const widgetRequestConfigPostMutation = usePostResource({
        resource: DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
        data: {
            resource: resource?.id as string,
            group_by: formData?.groupBy,
            order_by: formData?.orderBy,
            order_by_direction:
                generatePath('dashboard-builder', 'dashboard-builder-order-by-direction', orderByDirectionId) || 0,
            fields: formData?.fields.filter((field: string) => field !== '').join(','),
            widget: generatePath('dashboard-builder', 'dashboard-builder-widget', String(createdWidgetId)) || 0,
            graph_metrics: formData?.graphMetric,
            widget_color: formData?.widgetColor,
            ...(formData?.visualisation === 'pieChart' && { pie_chart_display: formData?.pieChartDisplay }),
            ...(formData?.visualisation === 'bigNumber' && { aggregation: formData?.aggregation }),
            ...(formData?.visualisation === 'bigNumber' && { formatting: formData?.formatting }),
            ...(typeof formData?.roundingDecimals === 'number' && { rounding_decimals: formData?.roundingDecimals }),
            ...(formData?.visualisation === 'lineGraph' && { interpolated: formData?.interpolated }),
            ...(formData?.visualisation === 'lineGraph' && { show_fill_color: formData?.showFillColor }),
            ...(formData?.visualisation === 'textBox' && { copy: formData?.copy }),
            ...(formData?.visualisation === 'textBox' && { button_copy: formData?.buttonCopy }),
            ...(formData?.visualisation === 'textBox' && { button_href: formData?.buttonHref }),
            ...(formData?.visualisation === 'textBox' && { text_align: formData?.textAlign }),
            ...(formData?.visualisation === 'textBox' && { text_justify: formData?.textJustify }),
            ...(formData?.visualisation === 'textBox' && { font_size: formData?.fontSize }),
        },
        handleOnSuccess: () => {
            setFormData(undefined);
            setResource(undefined);
            setCreatedWidgetId(undefined);

            queryClient.invalidateQueries({
                queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_WIDGET }),
            });

            dispatch(
                addNotification({
                    copy: `Widget created successfully!`,
                    type: NotificationMessageType.Success,
                })
            );
            navigate(`/${accountToken}/dashboard-builder/widget-library`);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue creating this widget. Please try again later or contact support@cubed.email',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    useEffect(() => {
        if (resource) {
            widgetPostMutation.mutate();
        }
    }, [resource]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (createdWidgetId) {
            widgetRequestConfigPostMutation.mutate();
        }
    }, [createdWidgetId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFormSubmit = (data: FieldValues) => {
        setFormData(data);
    };

    // Form default values must be saved in state to prevent unnecessary re-rendering of the form e.g. when the dates are changed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [formDefaultValues, _] = useState({
        visualisation: '',
        widgetName: '',
        groupBy: '',
        fields: [],
        orderBy: '',
        orderByDirection: 'desc',
        graphMetric: '',
        widgetColor: '',
        showComparison: false,
        pieChartDisplay: 'pie',
        aggregation: 'avg',
        formatting: '',
        applyRounding: false,
        roundingDecimals: '',
        interpolated: false,
        showFillColor: false,
        copy: '',
        buttonCopy: '',
        buttonHref: '',
        textJustify: 'left',
        fontSize: '1rem',
    });

    if (visualisations.status === 'pending' || orderByDirection.status === 'pending') {
        return <LayoutLoading copy="Loading visualisations..." />;
    }

    if (visualisations.status === 'error' || orderByDirection.status === 'error') {
        return <div>Error</div>;
    }

    return (
        <>
            <Form
                onSubmit={data => handleFormSubmit(data)}
                status={
                    widgetPostMutation.status === 'pending' || widgetRequestConfigPostMutation.status === 'pending'
                        ? 'loading'
                        : 'enabled'
                }
                defaultValues={formDefaultValues}
            >
                <CreateAWidgetForm />
            </Form>
        </>
    );
};

const LayoutCreateAWidget = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle('Create A Widget'));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CreateAWidgetContextProvider>
            <CreateAWidgetFormWrapper />
        </CreateAWidgetContextProvider>
    );
};

export default LayoutCreateAWidget;
