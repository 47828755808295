import React from 'react';
import { QueryStatus } from '@tanstack/react-query';

// Components
import Table from './components/table';
import Header from './components/header';
import Body from './components/body';
import Row from './components/row';
import CellHeader from './components/cells/cell-header';
import Cell from './components/cells/cell';
import CellActions from './components/cells/cell-actions';
import CellCheckbox from './components/cells/cell-checkbox';
import CellDragAndDrop from './components/cells/cell-drag-and-drop';
import ActionDropdownItem from './components/actions/action-dropdown/action-dropdown-item';
import ActionBar from './components/actions/action-bar/action-bar';
import ActionBarJustifyLeft from './components/actions/action-bar/action-bar-justify-left';
import ActionBarJustifyRight from './components/actions/action-bar/action-bar-justify-right';
import ActionButton from './components/actions/action-bar/action-button';

// Context
import { ConfigDragAndDropTableProvider } from './context/config-drag-and-drop-table-context';

// Types
export type ConfigDragAndDropTableProps = {
    status: QueryStatus;
    children: React.ReactNode;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
    dragDisabled: boolean;
};

const ConfigDragAndDropTable = ({
    status,
    isFetching,
    disabled,
    empty,
    dragDisabled,
    children,
}: ConfigDragAndDropTableProps) => {
    return (
        <ConfigDragAndDropTableProvider
            status={status}
            isFetching={isFetching}
            disabled={disabled}
            empty={empty}
            dragDisabled={dragDisabled}
        >
            {children}
        </ConfigDragAndDropTableProvider>
    );
};

export default ConfigDragAndDropTable;

ConfigDragAndDropTable.Table = Table;
ConfigDragAndDropTable.Header = Header;
ConfigDragAndDropTable.Body = Body;
ConfigDragAndDropTable.Row = Row;
ConfigDragAndDropTable.CellHeader = CellHeader;
ConfigDragAndDropTable.Cell = Cell;
ConfigDragAndDropTable.CellActions = CellActions;
ConfigDragAndDropTable.CellCheckbox = CellCheckbox;
ConfigDragAndDropTable.CellDragAndDrop = CellDragAndDrop;
ConfigDragAndDropTable.ActionBar = ActionBar;
ConfigDragAndDropTable.ActionDropdownItem = ActionDropdownItem;
ConfigDragAndDropTable.ActionBarJustifyLeft = ActionBarJustifyLeft;
ConfigDragAndDropTable.ActionBarJustifyRight = ActionBarJustifyRight;
ConfigDragAndDropTable.ActionButton = ActionButton;
