import React from 'react';

// Helpers
import getResource from '../../helpers/get-resource';
import getField from '../../helpers/get-field';

// Hooks
import useResourceComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-comparison';
import useResourcePieChartComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-pie-chart-comparison';

// Types
import { CubedField } from '../../../types';
import { WidgetConfig } from '../../types';

// Components
import CreateAWidgetPreviewMessage from '../../create-a-widget/components/create-a-widget-preview-message';
import WidgetPieChartComparison from '../../../section-dashboard/widgets/widget-comparison/widget-pie-chart-comparison';

const WidgetPreviewPieChartComparison = ({ config }: { config: WidgetConfig }) => {
    // Get the request values from the widget
    const resource = getResource(config.resource);
    const groupByField = getField(config.group_by);

    const orderByField = getField(config.order_by);
    const orderByDirection = config.order_by_direction.direction;
    const fields = [
        ...(groupByField ? [groupByField] : []),
        ...config.fields.split(',').map((field: string) => getField(field)),
    ].filter(field => field !== undefined) as CubedField[];

    const resourceData = useResourceComparison({
        resource: groupByField && resource,
        request: {
            orderBy:
                typeof orderByField !== 'undefined'
                    ? [{ field: orderByField, orderByDirection: orderByDirection }]
                    : [],
            groupBy: groupByField,
            chartField: orderByField,
            fields,
            limit: 15,
            enabled: !!resource && !!groupByField && !!orderByField,
        },
    });

    const pieChartData = useResourcePieChartComparison({
        resourceData: resourceData,
        dimension: groupByField,
    });

    if (config.group_by.length > 0 && config.order_by.length > 0) {
        return (
            <WidgetPieChartComparison
                title={config.widget.title}
                data={pieChartData}
                display={config.pie_chart_display || 'pie'}
            />
        );
    }

    return (
        <CreateAWidgetPreviewMessage message="Please select a dimension, chart metric and at least one column to preview the pie chart widget" />
    );
};

export default WidgetPreviewPieChartComparison;
