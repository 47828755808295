import * as fields from './fields';
import * as resources from './resources';

export const config = {
    pageTitle: 'SEO Prioritisation Tool ',
    filters: {
        status: {
            isEnableDatePicker: false,
            isEnableProductSelect: false,
            isEnableMetrics: true,
        },
    },
    reportDescription:
        'Identify and prioritise your SEO keywords globally to focus your efforts in order to maximise return on investment.',
    resources: [resources.REPORT_SEOPT_KEYWORDS_LANDING],
    ignoreProducts: true,
    table: {
        tabListItems: [
            {
                tabName: 'overview',
                displayName: 'Overview',
                isSelected: true,
                metrics: [
                    fields.SEARCH_VOLUME,
                    fields.COMPETITION,
                    fields.CURRENT_POSITION,
                    fields.GLASS_CEILING,
                    fields.RANK_DIFFERENCE,
                    fields.POSITION_EFFORT,
                ],
            },
            {
                tabName: 'rank',
                displayName: 'Ranking',
                metrics: [
                    fields.CURRENT_POSITION,
                    fields.GLASS_CEILING,
                    fields.RANK_DIFFERENCE,
                    fields.POSITION_EFFORT,
                ],
            },
            {
                tabName: 'opportunity',
                displayName: 'Clicks',
                metrics: [
                    fields.POSITION_EFFORT,
                    fields.CURRENT_CLICK_OPPORTUNITY,
                    fields.GLASS_CEILING_CLICK_OPPORTUNITY,
                    fields.INCREMENTAL_CLICK_OPPORTUNITY,
                    fields.INC_CLICKS_VARIANCE_PCT,
                    fields.RANK_1_INCREMENTAL_CLICK_OPPORTUNITY,
                    fields.SEO_CLICK_PRIORITY,
                ],
            },
            {
                tabName: 'sales',
                displayName: 'Conversion',
                metrics: [
                    fields.POSITION_EFFORT,
                    fields.CURRENT_FM_SALES,
                    fields.GLASS_CEILING_FM_SALES,
                    fields.INCREMENTAL_FM_SALES,
                    fields.INC_SALES_VARIANCE_PCT,
                    fields.RANK_1_INCREMENTAL_FM_SALES_OPPORTUNITY,
                    fields.SEO_SALE_PRIORITY,
                ],
            },
            {
                tabName: 'revenue',
                displayName: 'Revenue',
                metrics: [
                    fields.POSITION_EFFORT,
                    fields.CURRENT_FM_REVENUE,
                    fields.GLASS_CEILING_FM_REVENUE,
                    fields.INCREMENTAL_FM_REVENUE,
                    fields.INC_REVENUE_VARIANCE_PCT,
                    fields.RANK_1_INCREMENTAL_FM_REVENUE_OPPORTUNITY,
                    fields.SEO_REVENUE_PRIORITY,
                ],
            },
            {
                tabName: 'summary',
                displayName: 'Summary',
                metrics: [
                    fields.SEARCH_VOLUME,
                    fields.CURRENT_POSITION,
                    fields.GLASS_CEILING,
                    fields.COMPETITION,
                    fields.POSITION_EFFORT,
                    fields.INCREMENTAL_CLICK_OPPORTUNITY,
                    fields.INCREMENTAL_FM_SALES,
                    fields.INCREMENTAL_FM_REVENUE,
                ],
            },
            {
                tabName: 'all',
                displayName: 'All',
                metrics: [
                    fields.SEARCH_VOLUME,
                    fields.COMPETITION,
                    fields.CURRENT_POSITION,
                    fields.GLASS_CEILING,
                    fields.POSITION_EFFORT,
                    fields.CURRENT_CLICK_OPPORTUNITY,
                    fields.GLASS_CEILING_CLICK_OPPORTUNITY,
                    fields.INCREMENTAL_CLICK_OPPORTUNITY,
                    fields.RANK_1_INCREMENTAL_CLICK_OPPORTUNITY,
                    fields.CURRENT_FM_SALES,
                    fields.GLASS_CEILING_FM_SALES,
                    fields.INCREMENTAL_FM_SALES,
                    fields.RANK_1_INCREMENTAL_FM_SALES_OPPORTUNITY,
                    fields.CURRENT_FM_REVENUE,
                    fields.GLASS_CEILING_FM_REVENUE,
                    fields.INCREMENTAL_FM_REVENUE,
                    fields.RANK_1_INCREMENTAL_FM_REVENUE_OPPORTUNITY,
                    fields.INC_SALES_VARIANCE_PCT,
                    fields.INC_REVENUE_VARIANCE_PCT,
                    fields.SEO_CLICK_PRIORITY,
                    fields.SEO_SALE_PRIORITY,
                    fields.SEO_REVENUE_PRIORITY,
                ],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.MARKET_KEYWORD_COUNTRY.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.LANGUAGE.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.CORE_PRODUCT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.CORE_PARENT_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.CORE_CHILD_CAT,
                    },
                ],
            },
            [fields.CORE_CHILD_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.CORE_SUB_CAT,
                    },
                ],
            },
            [fields.CORE_SUB_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.SEO_MAPPED_PAGE,
                    },
                ],
            },
            [fields.KEYWORD_MODIFIER.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.LOCALE,
                    },
                ],
            },
            [fields.LOCALE.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.MARKET_KEYWORD_KEYWORD.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.RANKED_PATH_PATH,
                    },
                ],
            },
            [fields.MARKET_KEYWORD_MAPPED_HOST.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.MARKET_KEYWORD_MAPPED_PATH,
                    },
                ],
            },
            [fields.MARKET_KEYWORD_MAPPED_PATH.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.MARKET_KEYWORD_KEYWORD,
                    },
                ],
            },
            [fields.RANKED_PATH_HOST.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.RANKED_PATH_PATH,
                    },
                ],
            },
            [fields.RANKED_PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.MARKET_KEYWORD_KEYWORD,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 100],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 100,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
