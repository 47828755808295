import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { UserTypes } from './enum/user-types';
type RouteUserAccessProps = {
    children?: React.ReactNode;
    userAccess: string | UserTypes[];
};
const RouteUserAccess = ({ userAccess, children }: RouteUserAccessProps): JSX.Element => {
    const { user, account } = useAppSelector(state => state);
    const dispatch = useAppDispatch();
    const userHasAccess = Object.values(userAccess).includes(account.group) || userAccess === account.group;

    useEffect(() => {
        if (!userHasAccess) {
            dispatch(
                addNotification({
                    copy: 'You have been redirected as you do not have permission to the page you tried to access.',
                    type: NotificationMessageType.Warn,
                })
            );
        }
    }, [userHasAccess, dispatch]);

    // If the user is Staff or SuperUser they override access
    if (user.staff === true || user.superUser === true) {
        return <>{children}</>;
    }

    if (userHasAccess) {
        return <>{children}</>;
    } else {
        switch (account.group) {
            case 'Admin':
                return <Navigate to={`/${account.token}/reports/attribution/channel-insights-dashboard`} replace />;
            case 'Viewer':
                return <Navigate to={`/`} replace />;
            case 'API':
                return <Navigate to={`/${account.token}/account-configuration`} replace />;
            default:
                return <Navigate to={`/account-selection`} replace />;
        }
    }
};
export default RouteUserAccess;
