import React from 'react';
import styled, { css } from 'styled-components';

// Type
import { DataTableColumn } from '../../types';

// Styles
const StyledStickyColumn = css`
    position: sticky !important;
    left: 0;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.16);
    z-index: 2;
`;

const StyledTableTotalsCell = styled.th<{ isDimension: boolean; scrollPosX: number; width: string | number }>`
    ${props => props.isDimension && props.scrollPosX > 0 && StyledStickyColumn};

    ${props => props.theme.no_select()};
    line-height: 17px;
    font-size: 13px;
    text-align: right;
    padding: 3px;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    transition: 0.2s ease-in-out !important;
    background-color: ${props => props.theme.colours.white};
    background-clip: padding-box;
    position: relative;

    max-width: ${props => props.width};
    min-width: ${props => props.width};
    width: ${props => props.isDimension && props.width};
`;

// Types
type DataTableTotalsCellProps = {
    displayData: string;
    isDimension: boolean;
    column: DataTableColumn;
    scrollPosX: number;
};

const DataTableTotalsCell = ({ displayData, isDimension, column, scrollPosX }: DataTableTotalsCellProps) => {
    const { fixedWidth } = column;

    const columnWidth = isDimension ? fixedWidth || '270px' : '';

    return (
        <StyledTableTotalsCell key="1" isDimension={column.isDimension!} scrollPosX={scrollPosX} width={columnWidth}>
            <div>{displayData}</div>
        </StyledTableTotalsCell>
    );
};

export default DataTableTotalsCell;
