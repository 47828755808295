/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import moment from 'moment';

// Fields & Resources
import * as fields from '../../configurations/fields';
import * as resources from '../../configurations/resources';

// Hooks
import { useDatesFormatted } from '../../hooks/use-dates';
import useResource from '../../section-dashboard/hooks/widget/use-resource';
import useResourceTable from '../../section-dashboard/hooks/widget/table/use-resource-table';
import useResourceVenn from '../../section-dashboard/hooks/widget/venn/use-resource-venn';
import useResourceBarGraph from '../../section-dashboard/hooks/widget/use-resource-bar-graph';
import useResourceInterpolated from '../../section-dashboard/hooks/widget/line-graph/use-resource-line-graph-interpolated';
import useResourceLineGraph from '../../section-dashboard/hooks/widget/line-graph/use-resource-line-graph';
import useResourceVennResource from '../../section-dashboard/hooks/widget/venn/use-resource-venn-resource';

// Helpers
import { fixed } from '../../section-dashboard/helpers/resource-date-range';
import { lastValue } from '../../section-dashboard/helpers/legend-order';
import { DatePickerType } from '../../configurations/enums/date-picker-type';

// Components
import WidgetTable from '../../section-dashboard/widgets/widget/widget-table';
import WidgetVenn from '../../section-dashboard/widgets/widget/widget-venn';
import WidgetLineGraph from '../../section-dashboard/widgets/widget/widget-line-graph';
import WidgetBarGraph from '../../section-dashboard/widgets/widget/widget-bar-graph';

export const config = {
    title: 'Segment Dashboard',
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
        },
        datePicker: {
            datePickerType: DatePickerType.Day,
            customStartDate: () => {
                const startDate = moment().subtract(1, 'day').startOf('day');
                return startDate;
            },
            customEndDate: () => {
                const endDate = moment().subtract(1, 'day').endOf('day');
                return endDate;
            },
        },
    },
    layouts: {
        xs: {
            sections: [
                {
                    grid: `
                        'a'
                        'b'
                        'c'
                        'd'
                    `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    grid: `
                    'a a'
                    'b b'
                    'c c'
                    'd d'
                `,
                },
            ],
        },
        md: {
            sections: [
                {
                    grid: `
                    'a a b b'
                    'c c d d'
                    `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    grid: `
                    'a a a b b b'
                    'a a a b b b'
                    'c c c d d d'
                    'c c c d d d'
                    `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    grid: `
                    'a a a a b b b b'
                    'a a a a b b b b'
                    'a a a a b b b b'
                    'c c c c d d d d'
                    'c c c c d d d d'
                    'c c c c d d d d'
                    `,
                },
            ],
        },
    },
    components: () => {
        const { startDate, endDate } = useDatesFormatted({ resource: resources.REPORT_SEGMENTER_SEGMENT_VENN });

        // Top Segments - Table & Bar Graph
        const segmenterSegmentSeries = useResource({
            resource: resources.REPORT_SEGMENTER_SEGMENT,
            request: {
                groupBy: fields.DATE_DATE,
                orderBy: [{ field: fields.VISITORS, orderByDirection: 'desc' }],
                fields: [fields.SEGMENTER, fields.VISITORS],
            },
        });

        const topSegments = useResourceTable({
            resourceData: segmenterSegmentSeries,
        });

        const topSegmentsBarGraph = useResourceBarGraph({
            category: fields.SEGMENTER,
            metric: fields.VISITORS,
            resourceData: segmenterSegmentSeries,
        });

        // Amount of Visitors in Segments - Line Graph
        const amountOfVisitorsInSegmentsSeries = useResource({
            resource: resources.REPORT_SEGMENTER_SEGMENT,
            request: {
                groupBy: fields.SEGMENTER,
                orderBy: [{ field: fields.DATE_DATE, orderByDirection: 'desc' }],
                dateRange: fixed({
                    startDate: moment(endDate).subtract(30, 'days'),
                    endDate: moment(endDate),
                }),
            },
        });

        const amountOfVisitorsInSegmentsSeriesInterpolated = useResourceInterpolated({
            resourceData: amountOfVisitorsInSegmentsSeries,
            dateDimension: fields.DATE_DATE,
            seriesField: fields.SEGMENTER,
            yAxis: fields.VISITORS,
            dateRange: fixed({
                startDate: moment(endDate).subtract(30, 'days'),
                endDate: moment(endDate),
            }),
        });

        const amountOfVisitorsInSegments = useResourceLineGraph({
            resourceData: amountOfVisitorsInSegmentsSeriesInterpolated,
            dateDimension: fields.DATE_DATE,
            seriesField: fields.SEGMENTER,
            yAxis: fields.VISITORS,
            legendOrder: lastValue(),
        });

        const topOverlappingSegmentsData = useResourceVennResource({
            dimensionResource: {
                resource: resources.REPORT_SEGMENT,
                request: {
                    ignoreDate: true,
                    groupBy: fields.ID,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                },
            },
            vennResource: {
                resource: resources.REPORT_SEGMENTER_SEGMENT_VENN,
                request: {
                    ignoreDate: true,
                    groupBy: fields.ID,
                    orderBy: [{ field: fields.VISITORS, orderByDirection: 'desc' }],
                    filters: [
                        { field: fields.START_DATETIME, operator: 'equals', value: startDate },
                        { field: fields.END_DATETIME, operator: 'equals', value: endDate },
                    ],
                },
            },
        });

        const topOverlappingSegments = useResourceVenn({
            resourceData: topOverlappingSegmentsData,
            metric: fields.VISITORS,
            diagramTitle: 'Top Overlapping Segments',
        });

        // Widget Links
        const links = {
            segmentVenn: '/reports/segmenter/venn',
        };

        return {
            a: <WidgetTable title={'Top Segments'} data={topSegments} />,
            b: <WidgetVenn title={'Top Overlapping Segments'} data={topOverlappingSegments} href={links.segmentVenn} />,
            c: (
                <WidgetLineGraph
                    title={'Amount of Visitors in Segments - Last 30 days'}
                    data={amountOfVisitorsInSegments}
                />
            ),
            d: <WidgetBarGraph title={'Top Segments'} data={topSegmentsBarGraph} />,
        };
    },
};
