import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

// Config
import { config } from '../configurations/content-insights-dashboard-comparison';

// Components
import SectionDashboard from '../../../section-dashboard/components/section-dashboard';

// Redux
import { setDescription, setTitle } from '../../../redux/actions/page-meta';

// Context
import { FilterBarContext } from '../../../filter-bar/context/filter-bar-context';
import ContentInsightsDashboardMenuProvider from '../../content-insights-context/content-insights-dashboard-menu-context';

// Types
import { FilterBarContextValues } from '../../../filter-bar/types';
import { RouteLocation } from '../../../styled-components/theme-context/cubed-theme-context';

const ContentInsightsDashboardComparison = () => {
    const dispatch = useDispatch();

    const location = useLocation() as RouteLocation;
    location.isSectionDashboard = true;

    const {
        setComparisonFilterStatus,
        setFilterStatus,
        setDatePickerConfig,
        setFilterMetricsOptions,
        setComparisonDatePickerConfig,
    } = useContext(FilterBarContext) as FilterBarContextValues;

    useEffect(() => {
        dispatch(setTitle(config.title));
        dispatch(setDescription(config.description));

        // Filter bar configurations
        setFilterStatus(config.filters.status);
        setComparisonFilterStatus(config.filters.comparisonStatus); // Comparison status
        setFilterMetricsOptions([]); // reset metrics
        setDatePickerConfig(config.filters.datePicker); // reset datepicker
        setComparisonDatePickerConfig(config.filters.comparisonDatePicker); // reset datepicker
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ContentInsightsDashboardMenuProvider>
            <SectionDashboard config={config} />
        </ContentInsightsDashboardMenuProvider>
    );
};

export default ContentInsightsDashboardComparison;
