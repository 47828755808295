import React from 'react';
import PropTypes from 'prop-types';
import CommonFormInput from '../../components/common/common-form-input';
import DropdownWithSearch from '../../components/common/dropdown-with-search';

const RenderDataTypeField = ({
    dataType,
    value,
    onInputChange,
    durationItems,
    selectedDurationUnit,
    onDurationInputUnitChange,
}) => {
    switch (dataType) {
        case 'selector':
        case 'bool':
            return null;
        case 'string':
            return (
                <CommonFormInput
                    inputType={'text'}
                    placeholder={'Enter a value'}
                    value={value}
                    label={'Value:'}
                    requiredField={true}
                    onChange={onInputChange}
                />
            );
        case 'float':
        case 'money':
            return (
                <CommonFormInput
                    inputType={'number'}
                    step={0.01}
                    placeholder={'Enter a value'}
                    value={value}
                    label={'Value:'}
                    requiredField={true}
                    onChange={onInputChange}
                    min={0}
                />
            );
        case 'number':
            return (
                <CommonFormInput
                    inputType={'number'}
                    step={1}
                    placeholder={'Enter a value'}
                    value={value}
                    label={'Value:'}
                    requiredField={true}
                    onChange={onInputChange}
                    min={0}
                />
            );
        case 'time':
            return (
                <CommonFormInput
                    inputType="time"
                    value={value}
                    label={'Value:'}
                    requiredField={true}
                    onChange={onInputChange}
                />
            );
        case 'date':
            return (
                <CommonFormInput
                    inputType="date"
                    value={value}
                    label={'Value'}
                    requiredField={true}
                    onChange={onInputChange}
                />
            );
        case 'datetime':
            return (
                <CommonFormInput
                    inputType="datetime-local"
                    value={value}
                    label={'Value:'}
                    requiredField={true}
                    onChange={onInputChange}
                />
            );
        case 'duration':
            return (
                <>
                    <CommonFormInput
                        inputType="number"
                        step={1}
                        placeholder={'Enter a value'}
                        value={value}
                        label={'Value:'}
                        requiredField={true}
                        onChange={onInputChange}
                        min={0}
                    />
                    <DropdownWithSearch
                        dropdownItems={durationItems}
                        placeholder={'Select a unit'}
                        selected={selectedDurationUnit}
                        toolTipCopy={'Select a unit'}
                        requiredField={true}
                        label={'Duration Unit:'}
                        onSelectedItemChange={onDurationInputUnitChange}
                        isSearchable={false}
                        isClearable={false}
                    />
                </>
            );
        default:
            throw new Error(`Unknown data type ${dataType}`);
    }
};

RenderDataTypeField.propTypes = {
    dataType: PropTypes.string,
    value: PropTypes.any,
    onInputChange: PropTypes.func,
    durationItems: PropTypes.array,
    selectedDurationUnit: PropTypes.object,
    onDurationInputUnitChange: PropTypes.func,
};

export default RenderDataTypeField;
