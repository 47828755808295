import React from 'react';
import styled, { css } from 'styled-components';
import { useConfigTableContext } from '../../../context/config-table-context';

// Icons
import IconTrash from '../../../../../icons/trash';
import IconPlus from '../../../../../icons/plus';
import IconBan from '../../../../../icons/ban';

const StyledButton = css`
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 500;
    font-size: 14px;
    background-color: ${props => props.theme.colours.offBlackLighter};
    color: ${props => props.theme.colours.white};
    text-transform: uppercase;
    border-radius: 2px;
    border: 0;
    padding: 10px 15px;
    :disabled {
        cursor: not-allowed;
        background-color: ${props => props.theme.colours.midGrey};
    }
`;

const StyledPrimaryButton = styled.button`
    ${StyledButton}
`;

const StyledDeleteButton = styled.button`
    ${StyledButton}
    background-color: ${props => props.theme.colours.red};
`;

const StyledAddButton = styled.button`
    ${StyledButton}
    background-color: ${props => props.theme.colours.green};
`;

const StyledIconTrash = styled(IconTrash)`
    height: 15px;
`;

const StyledIconPlus = styled(IconPlus)`
    height: 15px;
`;

const StyledIconBan = styled(IconBan)`
    height: 15px;
`;

type ActionButtonProps = {
    type: 'primary' | 'delete' | 'add' | 'revoke';
    label: string;
    isDisabled?: boolean;
    onClick: () => void;
};

const ActionButton = ({ type, label, isDisabled, onClick }: ActionButtonProps) => {
    const { status, tableDisabled } = useConfigTableContext();

    const handleClick = () => {
        if (!tableDisabled && status === 'success') {
            onClick();
        }
    };

    if (status) {
        switch (type) {
            case 'primary':
                return (
                    <StyledPrimaryButton
                        disabled={isDisabled || tableDisabled || status !== 'success' ? true : false}
                        onClick={handleClick}
                    >
                        {label}
                    </StyledPrimaryButton>
                );
            case 'delete':
                return (
                    <StyledDeleteButton
                        disabled={isDisabled || tableDisabled || status !== 'success' ? true : false}
                        onClick={handleClick}
                    >
                        <StyledIconTrash />
                        {label}
                    </StyledDeleteButton>
                );
            case 'add':
                return (
                    <StyledAddButton
                        disabled={isDisabled || tableDisabled || status !== 'success' ? true : false}
                        onClick={handleClick}
                    >
                        <StyledIconPlus />
                        {label}
                    </StyledAddButton>
                );
            case 'revoke':
                return (
                    <StyledDeleteButton
                        disabled={isDisabled || tableDisabled || status !== 'success' ? true : false}
                        onClick={handleClick}
                    >
                        <StyledIconBan />
                        {label}
                    </StyledDeleteButton>
                );
            default:
                return null;
        }
    }
    return null;
};

export default ActionButton;
