import React, { Component } from 'react';

//
// 	Class SidebarMenu
//		props.errorMessageOverride :    String | undefined
// 		props.onClick :                 function
//		props.rows :                    Array < Row Object >
//

class SidebarMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasItems: false,
        };

        this.errorMessage = this.errorMessage.bind(this);
        this.renderSubList = this.renderSubList.bind(this);
    }

    // Checks if the side bar will be empty. If it is an error message will appear to the user.
    componentDidMount() {
        if (this.props.rows !== undefined && this.props.rows.length > 0) {
            this.setState({
                hasItems: true,
            });
        }
    }

    // Checks if this.props.errorMessageOverride is set, if it's not the return the default "No Items" message
    errorMessage() {
        return `${
            this.props.errorMessageOverride !== undefined
                ? this.props.errorMessageOverride
                : 'There are no items in this list.'
        }`;
    }

    renderSubList(list) {
        if (list.rows === undefined) {
            return null;
        }

        return (
            <ul>
                {list.rows.map(item => (
                    <li key={item.keyValue} data-key={item.keyValue} onClick={this.props.onClick}>
                        <p>{item.title}</p>
                    </li>
                ))}
            </ul>
        );
    }

    renderList(list) {
        return (
            <ul key={'ul' + list.keyValue} className="sidebar__menu__list">
                <li key={list.keyValue} data-key={list.keyValue} onClick={this.props.onClick}>
                    <h4>{list.header !== undefined ? list.header.title : list.title}</h4>
                    {this.renderSubList(list)}
                </li>
            </ul>
        );
    }

    render() {
        if (!this.state.hasItems) {
            return (
                <div className="sidebar__menu">
                    <div className="sidebar__menu__error">
                        <h3>
                            <this.errorMessage />
                        </h3>
                    </div>
                </div>
            );
        }

        return <div className="sidebar__menu">{this.props.rows.map(list => this.renderList(list))}</div>;
    }
}

export default SidebarMenu;
