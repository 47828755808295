export type PositionOption = { label: 'Before' | 'After'; value: 'before' | 'after' };

export type RuleOption = { label: string; value: number };

export type SubchannelRule = {
    id: number;
    name: string;
    rule: string;
    description: string;
    priority: number;
    subchannel: string;
    subchannelId: number;
    referer: string;
    dragId?: string;
    selected?: boolean;
};

export type TableRow = {
    dragId: string;
    selected: boolean;
} & SubchannelRule;

/**
 * Compares the current table rows with a snapshot priority wise
 * And returns the rows with updated priorities.
 */
type GetPriorityUpdatedTableRowsProps = {
    tableRows: TableRow[];
    tableRowsSnapshot: TableRow[];
};
export const getPriorityUpdatedTableRows = ({
    tableRows,
    tableRowsSnapshot,
}: GetPriorityUpdatedTableRowsProps): TableRow[] => {
    return tableRows.filter((newRow, index) => newRow.priority !== tableRowsSnapshot[index].priority);
};

/**
 * Compares the current table rows with a snapshot index wise.
 * And returns the rows with updated priorities.
 */
type GetIndexedUpdatedTableRowsProps = {
    tableRows: TableRow[];
    tableRowsSnapshot: TableRow[];
};
export const getIndexedUpdatedTableRows = ({
    tableRows,
    tableRowsSnapshot,
}: GetIndexedUpdatedTableRowsProps): TableRow[] => {
    return tableRows.filter((newRow, index) => newRow.id !== tableRowsSnapshot[index].id);
};

/**
 * Updates the priorities of the table rows based on the selected position and rule.
 * If no position or rule is provided, adds the new table row at the end with the highest priority.
 */
type UpdateRulePriorityProps = {
    selectedPosition?: PositionOption;
    selectedRule?: RuleOption;
    newTableRow: TableRow;
    existingTableRows: TableRow[];
};
export const updateTableRowPriority = ({
    selectedPosition,
    selectedRule,
    newTableRow,
    existingTableRows,
}: UpdateRulePriorityProps) => {
    if (!selectedPosition || !selectedRule) {
        newTableRow.priority = existingTableRows.length + 1;

        for (let i = 0; i < existingTableRows.length; i++) {
            existingTableRows[i].priority = i + 1;
        }

        return {
            updatedExistingTableRows: existingTableRows,
            updatedNewTableRow: newTableRow,
        };
    }

    const selectedIndex = existingTableRows.findIndex(row => row.priority === selectedRule.value);

    if (selectedIndex !== -1) {
        if (selectedPosition.value === 'before') {
            newTableRow.priority = existingTableRows[selectedIndex].priority;

            for (let i = selectedIndex; i < existingTableRows.length; i++) {
                existingTableRows[i].priority = i + 2;
            }
        } else if (selectedPosition.value === 'after') {
            newTableRow.priority = existingTableRows[selectedIndex].priority + 1;

            for (let i = selectedIndex + 1; i < existingTableRows.length; i++) {
                existingTableRows[i].priority = i + 2;
            }
        }
    }

    return {
        updatedExistingTableRows: existingTableRows,
        updatedNewTableRow: newTableRow,
    };
};

/**
 * Computes the differences in priorities between the current table rows and a snapshot.
 * If no position or rule is provided, it updates the rule priority first.
 */
type GetUpdatedDifferencesProps = {
    selectedPosition?: PositionOption;
    selectedRule?: RuleOption;
    subchannelRuleToEdit: TableRow;
    tableRows: TableRow[];
    tableRowsSnapshot: TableRow[];
};
export const getUpdatedDifferences = ({
    selectedPosition,
    selectedRule,
    subchannelRuleToEdit,
    tableRows,
    tableRowsSnapshot,
}: GetUpdatedDifferencesProps): TableRow[] => {
    if (!selectedPosition && !selectedRule) {
        const { updatedExistingTableRows } = updateTableRowPriority({
            selectedPosition,
            selectedRule,
            newTableRow: subchannelRuleToEdit as unknown as TableRow,
            existingTableRows: tableRows,
        });

        return getPriorityUpdatedTableRows({ tableRows: updatedExistingTableRows, tableRowsSnapshot });
    } else {
        return getPriorityUpdatedTableRows({ tableRows, tableRowsSnapshot });
    }
};

/**
 * Updates the priorities of the tableRows by moving an item from the source index to the destination index.
 * Ensures the source and destination indices are within bounds.
 */
type ReorderTableRowsProps = {
    tableRows: TableRow[];
    source: number;
    destination: number;
};
export const reorderTableRows = ({ tableRows, source, destination }: ReorderTableRowsProps) => {
    if (source === destination) {
        return tableRows.map((row, index) => ({
            ...row,
            priority: index + 1,
        }));
    }

    if (source < 0 || source >= tableRows.length || destination < 0 || destination >= tableRows.length) {
        throw new Error('Source or destination index out of bounds');
    }

    const [movedItem] = tableRows.splice(source, 1);

    tableRows.splice(destination, 0, movedItem);

    return tableRows.map((row, index) => ({
        ...row,
        priority: index + 1,
    }));
};
