import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

//Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Context
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Hooks
import { useSelector } from 'react-redux';
import useWidgetTheme from '../../hooks/base/use-widget-theme';

// Component
import WidgetBase from '../base/widget-base';

// Configurations
import { ChartDateGranularity } from '../../../configurations/common/chart-types';
import { sparkLineConfig } from '../../configurations/widget-spark-line-config';

// Helpers
import { getRawValueAsNumberWithNull } from '../../helpers/table-data';
import { calculateVariance, formatVariance } from '../../helpers/variance-helpers';
import { formatNumber, formatValue } from '../../helpers/format-number';

// Types
import { SparkLineComparisonData, TotalComparisonData, WidgetColour } from '../../types';
import dataTypes from '../../../filter-bar/enums/data-types';
import { CubedField } from '../../../types';
import { RootState } from '../../../redux/store';

const StyledContainer = styled.div<{ colour: string; direction: 'horizontal' | 'vertical' }>`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: ${props => props.colour};
    display: flex;
    flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
    align-items: center;
`;

const StyledPercentageContainer = styled.div<{ colourCode: number; colour: string; height: number; width: number }>`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-top: ${props =>
        props.height > 1 && props.width > 1
            ? props.colourCode === 0
                ? `1px solid ${props.colour}`
                : '1px solid rgba(255, 255, 255, 0.3)'
            : null};
`;

const StyledPercentage = styled.div<{
    direction: string;
    isDisabledSparkLine: boolean;
    colourCode: number;
    colour: string;
    height: number;
    width: number;
}>`
    padding: ${props => (props.width === 1 && props.height === 1 ? `0 3px` : `0 7px`)};
    margin: 7px 15px;
    background-color: ${props => props.theme.sectionDashboard.widget.sparkLine.percentage.backgroundColor};
    border-radius: 20px;
    border: 1px solid ${props => (props.colourCode === 0 ? props.colour : ` rgba(255, 255, 255, 0.3)`)};
`;

const StyledPercentageValue = styled.h1<{ metric: CubedField; variance: number | null; width: number; height: number }>`
    margin: 0;
    padding: 0;
    font-size: ${props =>
        props.height === 1 && (props.width < 2 || props.width > 5)
            ? '0.8rem'
            : props.height === 1 && (props.width >= 3 || props.width <= 5)
            ? '0.9rem'
            : '1rem'};
    color: ${props =>
        props.variance
            ? props.variance > 0
                ? props.metric.reverseVariance
                    ? props.theme.sectionDashboard.widget.variance.textColorNegative
                    : props.theme.sectionDashboard.widget.variance.textColorPositive
                : props.variance < 0
                ? props.metric.reverseVariance
                    ? props.theme.sectionDashboard.widget.variance.textColorPositive
                    : props.theme.sectionDashboard.widget.variance.textColorNegative
                : props.theme.sectionDashboard.widget.sparkLine.varianceTextColor
            : props.theme.sectionDashboard.widget.sparkLine.varianceTextColor};
`;

const StyledGraphContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledTextContainer = styled.div<{
    direction: string;
    width: number;
    height: number;
    isDisabledSparkLine: boolean;
}>`
    width: ${props => (props.isDisabledSparkLine || props.direction === 'vertical' ? '100%' : '250px')};
    height: ${props =>
        props.isDisabledSparkLine || props.direction === 'horizontal' ? '100%' : props.height < 3 ? '120px' : '200px'};
    display: flex;
    flex-direction: ${props =>
        props.direction === 'horizontal' && props.isDisabledSparkLine
            ? 'row'
            : props.width >= 2 && props.height > 1
            ? 'row'
            : 'column'};
    justify-content: space-evenly;
    background-color: ${props => !props.isDisabledSparkLine && 'rgba(255,255,255,0.15)'};
    position: relative;
`;

const StyledTextSection = styled.div<{
    colourCode: number;
    colour: string;
    width: number;
    height: number;
    direction: string;
    isDisabledSparkLine: boolean;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px;
    flex-grow: ${props => props.direction === 'horizontal' && 1};
    flex-basis: ${props => props.direction === 'horizontal' && 0};
    width: ${props => props.direction === 'vertical' && props.width > 1 && '50%'};
    border-top: ${props =>
        props.height > 1 && props.width > 1
            ? props.colourCode === 0
                ? `1px solid ${props.colour}`
                : '1px solid rgba(255, 255, 255, 0.3)'
            : null};
`;

const StyledValue = styled.h1<{ colour: string; colourCode: number; width: number; height: number }>`
    margin: 0px 8px;
    padding: 0;
    font-size: ${props =>
        props.height === 1 && (props.width < 2 || props.width > 5)
            ? '0.8rem'
            : props.height === 1 && (props.width >= 3 || props.width <= 5)
            ? '1rem'
            : '1.3rem'};
    color: ${props =>
        props.colourCode === 0 ? props.colour : props.theme.sectionDashboard.widget.sparkLine.textColor};
`;

const StyledText = styled.p<{ colour: string; colourCode: number; width: number; height: number }>`
    margin: 0px 8px;
    padding: 0;
    font-size: ${props => (props.height <= 2 ? '0.7rem' : '0.9rem')};
    font-weight: 400;
    color: ${props =>
        props.colourCode === 0 ? props.colour : props.theme.sectionDashboard.widget.sparkLine.textColor};
`;

export type WidgetSparkLineComparisonProps = {
    title: string;
    data: SparkLineComparisonData;
    totalData: TotalComparisonData;
    chartDateGranularity?: string;
    href?: string;
    colour?: WidgetColour;
};

const WidgetSparkLineComparison = ({
    title,
    data,
    totalData,
    chartDateGranularity = ChartDateGranularity.Day,
    href,
    colour = WidgetColour.Default,
}: WidgetSparkLineComparisonProps) => {
    const { width, height } = useSectionDashboard();
    const dates = useSelector((state: RootState) => state.date);
    const comparisonDates = useSelector((state: RootState) => state.comparisonDate);
    const widgetTheme = useWidgetTheme(colour);

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const direction = height === 1 ? (width === 1 ? 'vertical' : 'horizontal') : 'vertical';
    const isDisabledSparkLine = width === 1 || (height === 1 && width < 6);
    const thisPeriodDateRange = moment(dates.endDate).diff(moment(dates.startDate), 'days') + 1;
    const comparisonPeriodDateRange =
        moment(comparisonDates.endDate).diff(moment(comparisonDates.startDate), 'days') + 1;

    let chartOptions = {
        ...sparkLineConfig,
        chart: {
            ...sparkLineConfig.chart,
            backgroundColor: widgetTheme.gradientEnd,
        },
        series: [
            {
                name: data.metric.displayName,
                data: data.series,
                color: colour === 0 ? widgetTheme.splineGradientStart : '#FFFFFF',
            },
            {
                name: data.metric.displayName,
                data: data.comparisonSeries,
                color: colour === 0 ? widgetTheme.splineGradientStart : '#FFFFFF',
                dashStyle: 'ShortDash',
                xAxis: 1,
            },
        ],
    };

    // Get the totals
    let thisPeriodTotal: string | number = 'No Data';
    let comparisonPeriodTotal: string | number = ' No Data';

    if (totalData && totalData.status === 'success' && totalData.totals && Object.keys(totalData.totals).length > 0) {
        thisPeriodTotal = formatNumber(getRawValueAsNumberWithNull(totalData.totals, data.metric.rawName), width);
    }

    if (
        totalData &&
        totalData.status === 'success' &&
        totalData.comparisonTotals &&
        Object.keys(totalData.comparisonTotals).length > 0
    ) {
        comparisonPeriodTotal = formatNumber(
            getRawValueAsNumberWithNull(totalData.comparisonTotals, data.metric.rawName),
            width
        );
    }

    if (data.metric.dataType === dataTypes.MONEY) {
        thisPeriodTotal = formatValue(thisPeriodTotal, 'currency');
        comparisonPeriodTotal = formatValue(comparisonPeriodTotal, 'currency');
    }

    // Calculate the variance
    let variance = null;
    let varianceFormatted = null;

    if (
        totalData &&
        totalData.status === 'success' &&
        totalData.totals &&
        Object.keys(totalData.totals).length > 0 &&
        totalData.comparisonTotals &&
        Object.keys(totalData.comparisonTotals).length > 0
    ) {
        const total = getRawValueAsNumberWithNull(totalData.totals, data.metric.rawName);
        const comparisonTotal = getRawValueAsNumberWithNull(totalData.comparisonTotals, data.metric.rawName);

        variance = calculateVariance(total, comparisonTotal);
        varianceFormatted = formatVariance(variance, true);
    }

    return (
        <WidgetBase title={title} href={href}>
            <StyledContainer colour={widgetTheme.gradientEnd} direction={direction}>
                {!isDisabledSparkLine && (
                    <StyledGraphContainer>
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={chartOptions}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    </StyledGraphContainer>
                )}

                <StyledTextContainer
                    direction={direction}
                    width={width}
                    height={height}
                    isDisabledSparkLine={isDisabledSparkLine}
                >
                    <StyledTextSection
                        colourCode={colour}
                        colour={widgetTheme.splineGradientStart}
                        width={width}
                        height={height}
                        direction={direction}
                        isDisabledSparkLine={isDisabledSparkLine}
                    >
                        <StyledValue
                            colour={widgetTheme.splineGradientStart}
                            colourCode={colour}
                            width={width}
                            height={height}
                        >
                            {thisPeriodTotal}
                        </StyledValue>
                        <StyledText
                            colour={widgetTheme.splineGradientStart}
                            colourCode={colour}
                            width={width}
                            height={height}
                        >
                            {chartDateGranularity === ChartDateGranularity.Hour
                                ? 'Total for selected date range'
                                : `Total for date range (${thisPeriodDateRange} ${chartDateGranularity}${
                                      thisPeriodDateRange > 1 ? 's' : ''
                                  })`}
                        </StyledText>
                    </StyledTextSection>

                    {varianceFormatted && varianceFormatted !== 'N/A' ? (
                        <StyledPercentageContainer
                            colourCode={colour}
                            colour={widgetTheme.splineGradientStart}
                            width={width}
                            height={height}
                        >
                            <StyledPercentage
                                direction={direction}
                                isDisabledSparkLine={isDisabledSparkLine}
                                colourCode={colour}
                                colour={widgetTheme.splineGradientStart}
                                width={width}
                                height={height}
                            >
                                <StyledPercentageValue
                                    metric={data.metric}
                                    variance={variance}
                                    width={width}
                                    height={height}
                                >
                                    {varianceFormatted}
                                </StyledPercentageValue>
                            </StyledPercentage>
                        </StyledPercentageContainer>
                    ) : null}

                    <StyledTextSection
                        colourCode={colour}
                        colour={widgetTheme.splineGradientStart}
                        width={width}
                        height={height}
                        direction={direction}
                        isDisabledSparkLine={isDisabledSparkLine}
                    >
                        <StyledValue
                            colour={widgetTheme.splineGradientStart}
                            colourCode={colour}
                            width={width}
                            height={height}
                        >
                            {comparisonPeriodTotal}
                        </StyledValue>
                        <StyledText
                            colour={widgetTheme.splineGradientStart}
                            colourCode={colour}
                            width={width}
                            height={height}
                        >
                            {chartDateGranularity === ChartDateGranularity.Hour
                                ? 'Total for selected date range'
                                : `Total for comparison (${comparisonPeriodDateRange} ${chartDateGranularity}${
                                      comparisonPeriodDateRange > 1 ? 's' : ''
                                  })`}
                        </StyledText>
                    </StyledTextSection>
                </StyledTextContainer>
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetSparkLineComparison;
