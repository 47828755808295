import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import IconPlus from '../icons/plus';
import IconMinus from '../icons/minus';

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`;

const StyledItemExpanded = styled.div`
    display: flex;
    padding: 15px 26px 13px 33px;
    border-left: 2px solid ${props => (props.isActive ? props.theme.colours.cubedOrange : props.theme.colours.offBlack)};

    &:hover {
        border-color: ${props => props.theme.colours.cubedOrange};
        background-color: ${props => props.theme.colours.offBlackLighter};
    }
`;

const StyledIconExpandedContainer = styled.div`
    width: 20px;
    margin-right: 18px;
`;

const StyledIconExpanded = styled.div`
    & svg {
        height: 16px;
        width: 100%;

        & path {
            fill: ${props => props.theme.colours.cubedOrange};
        }
    }
`;

const StyledTextExpanded = styled.div`
    color: ${props => props.theme.colours.light};
    font-size: 13px;
    position: relative;
    top: 2px;
    font-weight: 500;
`;

const StyledIconCollapsed = styled.div`
    margin: 28px auto;

    &:hover {
        & svg path {
            fill: ${props => props.theme.colours.orange};
        }
    }

    & svg {
        height: 16px;
        width: 100%;

        & path {
            fill: ${props => props.theme.colours.cubedOrange};
        }
    }
`;

export const StyledCollapseIconContainerExpanded = styled.div`
    position: absolute;
    right: 0;
    margin-right: 24px;

    & svg {
        color: ${props => props.theme.colours.darkGrey};
        width: 9px;
    }
`;

const NavItem = ({
    navItem,
    dropdownIsActive,
    onItemClick,
    onItemEnterPress,
    sidebarVisible,
    onCollapsedItemClick,
    onHomeClick,
    toggleSidebar,
}) => {
    const { account } = useSelector(state => state);

    const onCollapsedItemEnterPress = e => {
        onItemEnterPress(e);
        if (e.key === 'Enter') {
            toggleSidebar();
        }
    };

    const renderMenuItems = () => {
        return (
            <>
                {sidebarVisible && (
                    <StyledItemExpanded onClick={onItemClick} onKeyDown={onItemEnterPress} tabIndex={0}>
                        <StyledIconExpandedContainer>
                            <StyledIconExpanded>{navItem.icon}</StyledIconExpanded>
                        </StyledIconExpandedContainer>
                        <StyledTextExpanded>{navItem.displayName}</StyledTextExpanded>
                        <StyledCollapseIconContainerExpanded>
                            {dropdownIsActive ? <IconMinus /> : <IconPlus />}
                        </StyledCollapseIconContainerExpanded>
                    </StyledItemExpanded>
                )}
                {!sidebarVisible && (
                    <StyledIconCollapsed
                        onClick={onCollapsedItemClick}
                        onKeyDown={onCollapsedItemEnterPress}
                        tabIndex={0}
                    >
                        {navItem.icon}
                    </StyledIconCollapsed>
                )}
            </>
        );
    };

    if (!navItem.hasDropdown && !navItem.hasSubGroups) {
        return (
            <StyledNavLink
                to={`/${account.token + navItem.path}`}
                tabIndex={0}
                onClick={() => onHomeClick()}
                children={({ isActive }) => {
                    if (sidebarVisible) {
                        return (
                            <StyledItemExpanded isActive={isActive}>
                                <StyledIconExpandedContainer>
                                    <StyledIconExpanded>{navItem.icon}</StyledIconExpanded>
                                </StyledIconExpandedContainer>
                                <StyledTextExpanded>{navItem.displayName}</StyledTextExpanded>
                            </StyledItemExpanded>
                        );
                    } else {
                        return <StyledIconCollapsed>{navItem.icon}</StyledIconCollapsed>;
                    }
                }}
            />
        );
    } else if (navItem.path) {
        return (
            <StyledNavLink to={`/${account.token + navItem.path}`} tabIndex={0}>
                {renderMenuItems()}
            </StyledNavLink>
        );
    } else {
        return <>{renderMenuItems()}</>;
    }
};

export default NavItem;
