import React from 'react';
import { CubedIcon } from './types';

const IconTableRows: CubedIcon = ({ className }) => {
    return (
        <svg className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm128 0l0 128 320 0 0-128L128 96zM448 288l-320 0 0 128 320 0 0-128z" />
        </svg>
    );
};

export default IconTableRows;
