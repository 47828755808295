import { useMemo } from 'react';

// Helpers
import { buildParams } from '../../helpers/build-params';
import { formatDates } from '../../../helpers/format-dates';
import { getDateGranularity } from '../../../helpers/get-date-granularity';

// Types
import { ResourceData, SectionDashboardRequest } from '../../types';
import { CubedResource } from '../../../types';

// Hooks
import { useDatesFormatted, useDatesMoment } from '../../../hooks/use-dates';
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';

export type UseResourceArgs = {
    resource?: CubedResource;
    request: SectionDashboardRequest;
    isComparison?: boolean;
};

const useResource = ({ resource, request, isComparison = false }: UseResourceArgs): ResourceData => {
    // Get the formatted dates
    let { startDate, endDate } = useDatesFormatted({ resource, isComparison });
    if (request.dateRange && request.dateRange.type === 'fixed') {
        const { startDate: fixedStartDate, endDate: fixedEndDate } = formatDates(
            request.dateRange.startDate,
            request.dateRange.endDate,
            resource
        );
        startDate = fixedStartDate;
        endDate = fixedEndDate;
    }
    const { startDate: momentStartDate, endDate: momentEndDate } = useDatesMoment({ isComparison });
    const granularity = getDateGranularity(momentStartDate, momentEndDate, resource);

    // Build the params
    const params = useMemo(() => {
        if (resource) {
            return buildParams({
                resource,
                request,
                startDate: startDate,
                endDate: endDate,
                isComparison,
                granularity,
            });
        }
        return [];
    }, [resource, request, startDate, endDate, granularity]); // eslint-disable-line react-hooks/exhaustive-deps

    // Fetch the response
    const response = useFetchResource({
        resource,
        params,
        enabled: request.enabled,
    });

    if (response.isSuccess) {
        if (request.showLoading) {
            return {
                type: 'resource',
                status: 'loading',
                request: request,
            };
        }
        if (request.showEmpty || (response.data.objects.length === 0 && !request.totals)) {
            return {
                type: 'resource',
                status: 'empty',
                request: request,
            };
        } else {
            return {
                type: 'resource',
                status: 'success',
                resource: resource,
                request: request,
                meta: response.data.meta,
                objects: response.data.objects,
            };
        }
    }

    if (response.isError) {
        return {
            type: 'resource',
            status: 'error',
            request: request,
            error: response.error,
        };
    }

    return {
        type: 'resource',
        status: 'loading',
        request: request,
    };
};

export default useResource;
