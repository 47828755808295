import { CubedField } from '../../../types';
import { unknownErrorData } from '../../helpers/errors';
import joinDimensions from '../../helpers/join-dimensions';
import { ResourceData } from '../../types';

type UseResourceJoinDimensionsArgs = {
    resourceData: ResourceData;
    displayField: CubedField;
    joiningField: CubedField;
};

const useResourceJoinDimensions = ({ resourceData, displayField, joiningField }: UseResourceJoinDimensionsArgs) => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const updatedData = resourceData.objects.map(object => {
            return joinDimensions({ resourceObject: object, displayField, joiningField });
        });

        return {
            ...resourceData,
            objects: updatedData,
        };
    }

    return unknownErrorData();
};

export default useResourceJoinDimensions;
