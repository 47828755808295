import React from 'react';
import styled, { keyframes } from 'styled-components';
import IconLoader from '../../../components/icons/loader';

const spinAnimation = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const StyledContainer = styled.div<{ width: number; height: number; margin: number }>`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    margin: ${props => props.margin}px;
`;

const StyledLoader = styled.div<{ color?: string }>`
    width: 100%;
    height: 100%;
    animation: ${spinAnimation} 5s linear infinite;
    transform-origin: center center;
    color: ${props => props.color || props.theme.sectionDashboard.widget.base.loader.color};
`;

const WidgetBaseLoader = ({
    width,
    height,
    margin,
    color,
}: {
    width: number;
    height: number;
    margin: number;
    transformOrigin?: boolean;
    color?: string;
}) => {
    return (
        <StyledContainer width={width} height={height} margin={margin}>
            <StyledLoader color={color}>
                <IconLoader />
            </StyledLoader>
        </StyledContainer>
    );
};

export default WidgetBaseLoader;
