// React Dependencies
import React, { Component } from 'react';

// Import Components
import Accordion from '../components/accordion';

class WidgetAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="accordion">
                {this.props.accordions.map((item, index) => (
                    <Accordion accordion={item} key={index} />
                ))}
            </div>
        );
    }
}

export default WidgetAccordion;
