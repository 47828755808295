import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../redux/actions/page-meta';

// Context
import CreateAWidgetContextProvider, { CreateAWidgetContext } from '../context/create-a-widget-context';
import { DashboardBuilderContext } from '../../context/dashboard-builder-context';

// Hooks
import { useLocation, useNavigate, useParams } from 'react-router';
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePatchResource from '../../../react-query/hooks/use-patch-resource';

// Helpers
import { generatePath } from '../../../helpers/request-builder';
import getCurrentSelectedMetrics from '../helpers/get-current-selected-metrics';
import getAvailableResources from '../helpers/get-available-resources';

// Types
import { ConfigDataSuccess } from '../../../react-query/types';
import { FieldValues } from 'react-hook-form';
import { CubedResource } from '../../../types';
import { RootState } from '../../../redux/store';

// Notifications
import { addNotification } from '../../../redux/actions/notification';
import { NotificationMessageType } from '../../../enums/notification-types';

// Resources
import {
    DASHBOARD_BUILDER_WIDGET,
    DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
} from '../../config/resources-dashboard-builder';

// Components
import LayoutEmpty from '../../layouts/layout-empty';
import IconImageSlash from '../../../components/icons/image-slash';
import LayoutLoading from '../../layouts/layout-loading';
import Form from '../../../components/forms/form';
import CreateAWidgetForm from '../forms/create-a-widget-form';

const StyledIconImageSlash = styled(IconImageSlash)`
    width: 40px;
`;

const EditWidgetFormWrapper = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const accountToken = useSelector((state: RootState) => state.account.token);
    const { clientUserId } = useContext(DashboardBuilderContext);
    const { visualisations, orderByDirection } = useContext(CreateAWidgetContext);
    const { widgetId } = useParams();
    const locationId = location.search.split('=').at(-1);

    // Widget to edit details
    const widgetDetailsQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
        params: [
            { key: 'widget', value: widgetId || locationId || 0 },
            { key: 'widget__author', value: clientUserId },
        ],
        enabled: (!!widgetId || !!locationId) && !!clientUserId,
    });

    const widgetDetails = widgetDetailsQuery?.data?.objects[0];

    // State
    const [formData, setFormData] = useState<FieldValues>();
    const [resource, setResource] = useState<CubedResource>();

    // Form default values must be saved in state to prevent unnecessary re-rendering of the form e.g. when the dates are changed
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [formDefaultValues, setFormDefaultValues] = useState<any>();

    useEffect(() => {
        if (widgetDetailsQuery.status === 'success') {
            setFormDefaultValues({
                visualisation: widgetDetails?.widget.type.name || '',
                widgetName: widgetDetails?.widget.title || '',
                groupBy: widgetDetails?.group_by || '',
                fields: widgetDetails?.fields.split(','),
                orderBy: widgetDetails?.order_by || '',
                orderByDirection: widgetDetails?.order_by_direction.direction || '',
                graphMetric:
                    widgetDetails?.graph_metrics.split(',').length === 1
                        ? widgetDetails?.graph_metrics.split(',')[0]
                        : [],
                widgetColor: widgetDetails?.widget_color || '',
                pieChartDisplay: widgetDetails?.pie_chart_display || 'pie',
                aggregation: widgetDetails?.aggregation || 'avg',
                formatting: widgetDetails?.formatting || '',
                applyRounding: widgetDetails?.rounding_decimals ? true : false,
                rounding_decimals: widgetDetails?.rounding_decimals,
                interpolated: widgetDetails?.interpolated || '',
                showFillColor: widgetDetails?.show_fill_color || '',
                copy: widgetDetails?.copy || '',
                buttonCopy: widgetDetails?.button_copy || '',
                buttonHref: widgetDetails?.button_href || '',
                textJustify: widgetDetails?.text_justify || '',
                fontSize: widgetDetails?.font_size || '',
            });
        }
    }, [widgetDetailsQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    const visualisationId =
        formData && formData.visualisation && visualisations && visualisations.data
            ? visualisations.data.objects.filter(vis => vis.name === formData?.visualisation)[0].id
            : 0;

    const orderByDirectionId =
        formData && formData.orderByDirection && orderByDirection && orderByDirection.data
            ? orderByDirection.data.objects.filter(orderByDir => orderByDir.direction === formData.orderByDirection)[0]
                  .id
            : 0;

    // When the form is submitted, get the resource
    // If there are multiple resources, use the one with the fewest dimensions
    useEffect(() => {
        if (formData) {
            // Current Selected Metrics
            const currentSelectedMetrics = getCurrentSelectedMetrics(formData);

            // Current Available Resources
            const currentAvailableResources = getAvailableResources({
                dimension: formData?.groupBy,
                currentMetrics: currentSelectedMetrics,
            });

            setResource(currentAvailableResources[0]);
        }
    }, [formData]);

    useEffect(() => {
        if (resource) {
            widgetPatchMutation.mutate();
        }
    }, [resource]); // eslint-disable-line react-hooks/exhaustive-deps

    const widgetPatchMutation = usePatchResource({
        resource: DASHBOARD_BUILDER_WIDGET,
        resourceId: widgetDetails?.widget.id || 0,
        data: {
            title: formData?.widgetName,
            type: generatePath('dashboard-builder', 'dashboard-builder-widget-type', visualisationId) || 0,
        },
        handleOnSuccess: () => {
            widgetRequestConfigPatchMutation.mutate();
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue creating this widget. Please try again later or contact support@cubed.email',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const widgetRequestConfigPatchMutation = usePatchResource({
        resource: DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
        resourceId: widgetDetails?.id || 0,
        data: {
            resource: resource?.id as string,
            group_by: formData?.groupBy,
            order_by: formData?.orderBy,
            order_by_direction:
                generatePath('dashboard-builder', 'dashboard-builder-order-by-direction', orderByDirectionId) || 0,
            fields: formData?.fields.filter((field: string) => field !== '').join(','),
            graph_metrics: formData?.graphMetric,
            widget_color: formData?.widgetColor || '',
            ...(formData?.visualisation === 'pieChart' && { pie_chart_display: formData?.pieChartDisplay }),
            ...(formData?.visualisation === 'bigNumber' && { aggregation: formData?.aggregation }),
            ...(formData?.visualisation === 'bigNumber' && { formatting: formData?.formatting }),
            ...(typeof formData?.apply_rounding && formData?.roundingDecimals === 'number'
                ? { rounding_decimals: formData?.roundingDecimals }
                : { rounding_decimals: null }),
            ...(formData?.visualisation === 'lineGraph' && { interpolated: formData?.interpolated }),
            ...(formData?.visualisation === 'lineGraph' && { show_fill_color: formData?.showFillColor }),
            ...(formData?.visualisation === 'textBox' && { copy: formData?.copy }),
            ...(formData?.visualisation === 'textBox' && { button_copy: formData?.buttonCopy }),
            ...(formData?.visualisation === 'textBox' && { button_href: formData?.buttonHref }),
            ...(formData?.visualisation === 'textBox' && { text_justify: formData?.textJustify }),
            ...(formData?.visualisation === 'textBox' && { font_size: formData?.fontSize }),
        },
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: `Widget successfully updated!`,
                    type: NotificationMessageType.Success,
                })
            );
            !locationId && navigate(`/${accountToken}/dashboard-builder/widget-library`);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue creating this widget. Please try again later or contact support@cubed.email',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const handleFormSubmit = (data: FieldValues) => {
        setFormData(data);
    };

    if (widgetDetailsQuery.isLoading || widgetDetailsQuery.isFetching || !formDefaultValues) {
        return <LayoutLoading copy="Loading widget..." />;
    }

    if (!widgetDetailsQuery.data || (widgetDetailsQuery.data && widgetDetailsQuery.data.objects.length === 0)) {
        return <LayoutEmpty icon={<StyledIconImageSlash />} copy="Sorry, this widget could not be found." />;
    }

    return (
        <>
            <Form
                onSubmit={data => handleFormSubmit(data)}
                status={
                    widgetPatchMutation.status === 'pending' || widgetRequestConfigPatchMutation.status === 'pending'
                        ? 'loading'
                        : 'enabled'
                }
                defaultValues={formDefaultValues}
            >
                <CreateAWidgetForm />
            </Form>
        </>
    );
};

const LayoutEditWidget = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle('Edit Widget'));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CreateAWidgetContextProvider>
            <EditWidgetFormWrapper />
        </CreateAWidgetContextProvider>
    );
};

export default LayoutEditWidget;
