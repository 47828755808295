import Papa from 'papaparse';
import { FileFormatConfig } from '../types';

const validateHeaders = (file: File, columnConfig: FileFormatConfig) => {
    return new Promise<boolean>(resolve => {
        const reader = new FileReader();
        let fileHeaders: string[];

        reader.readAsText(file);
        reader.onload = fileEvent => {
            const csvData: string = fileEvent.target?.result as string;

            Papa.parse(csvData, {
                skipEmptyLines: true,
                header: true,
                preview: 1,
                complete: result => {
                    fileHeaders = result.meta.fields as string[];
                },
            });

            const headersToCheck = columnConfig
                .map(column => column.header)
                .sort()
                .map(header => {
                    return header.toLowerCase();
                });

            const fileHeadersSorted = fileHeaders.sort().map(header => {
                return header.toLowerCase();
            });

            const result =
                headersToCheck.length === fileHeadersSorted.length &&
                headersToCheck.every((header, index) => {
                    return header === fileHeadersSorted[index];
                });

            resolve(result);
        };
    });
};

export default validateHeaders;
