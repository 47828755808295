import React from 'react';
import styled from 'styled-components';

const SkeletonContainer = styled.div`
    padding: 6px;
    width: 100%;
    height: 420px;
`;

const SkeletonProfile = styled.div`
    position: relative;
`;

const SkeletonAvatar = styled.div`
    ${props => props.theme.skeletonLoader}
    border: 6px solid white;
    border-radius: 200px;
    width: 72px;
    height: 72px;
    position: relative;
    z-index: 2;
`;

const SkeletonId = styled.div`
    ${props => props.theme.skeletonLoader}
    position: absolute;
    top: 19px;
    left: 57px;
    width: 200px;
    padding-left: 13px;
    padding: 12px 4px 12px 22px;
    border-radius: 0 63px 63px 0;
    z-index: 1;
    font-size: 13px;
    color: transparent;
`;

const SkeletonMetrics = styled.div`
    width: 100%;
`;

const SkeletonMetric = styled.div`
    display: inline-block;
    position: relative;
    padding: 6px;
    width: ${props => props.width};
`;

const SkeletonMetricLabel = styled.div`
    border-radius: 3px;
    ${props => props.theme.skeletonLoader}
    text-transform: uppercase;
    text-align: center;
    margin: 6px 3px 3px;
    font-size: 12px;
    font-weight: 600;
    color: transparent;
`;

const SkeletonMetricValue = styled.div`
    border-radius: 3px;
    ${props => props.theme.skeletonLoader}
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: transparent;
`;

const SkeletonVisitorInfo = () => {
    return (
        <SkeletonContainer>
            <SkeletonProfile>
                <SkeletonAvatar />
                <SkeletonId />
            </SkeletonProfile>
            <SkeletonMetrics>
                <SkeletonMetric width={'25%'}>
                    <SkeletonMetricLabel>1</SkeletonMetricLabel>
                    <SkeletonMetricValue>1</SkeletonMetricValue>
                </SkeletonMetric>
            </SkeletonMetrics>
        </SkeletonContainer>
    );
};

export default SkeletonVisitorInfo;
