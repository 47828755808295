import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Hooks
import { useQueryClient } from '@tanstack/react-query';
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import useDeleteResource from '../../../react-query/hooks/use-delete-resource';
import useBulkDeleteWithParams from '../../../react-query/hooks/use-bulk-delete-with-params';
import usePatchMultiResourceWithPayload from '../../../react-query/hooks/use-patch-multi-resource-with-payload';
import usePostMultiResources from '../../../react-query/hooks/use-post-multi-resources';

// Config
import {
    CONFIG_ATL,
    CONFIG_ATL_CONFIG,
    CONFIG_ATL_REGION,
    CONFIG_CHANNELS,
} from '../../../configurations/resources-config';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal, setModal } from '../../../redux/actions/modal';
import { removePopup, setPopup } from '../../../redux/actions/popup';
import { addNotification } from '../../../redux/actions/notification';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { ConfigDataSuccess } from '../../../react-query/types';
import { FieldValues } from 'react-hook-form';

// Components
import ModalNavigation from '../../../components/modal-navigation';
import ConfigTable from '../../../components/tables/config-table/config-table';
import Form from '../../../components/forms/form';
import FormCreateAtl from './forms/form-create-atl';

// Helpers
import { generatePath } from '../../../helpers/request-builder';
import generateQueryKey from '../../../react-query/helpers/generate-query-key';

const StyledEditContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

type ATL = {
    id: number;
    name: string;
    selected: boolean;
    configId?: number;
    startDate?: string;
    endDate?: string;
    channelId?: number;
};

type ATLRegion = { country: string; city: string };

type ATLRegions = {
    regional: boolean;
    regions?: ATLRegion[];
};

type AtlRegionRequest = { atl_config: string; nationwide: boolean; country_name: string; city_name: string };

const LayoutModalManageAboveTheLine = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [atlList, setAtlList] = useState<ATL[]>([]);
    const [atlToEdit, setAtlToEdit] = useState<ATL>();
    const [atlToEditRegions, setAtlToEditRegions] = useState<ATLRegions>();
    const [atlToEditNewRegions, setAtlToEditNewRegions] = useState<ATLRegions>();
    const [atlToDelete, setAtlToDelete] = useState<number>();

    // Queries
    const atlQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_ATL,
        params: [{ key: 'active', value: 1 }],
    });

    const atlConfigQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_ATL_CONFIG,
    });

    useEffect(() => {
        if (atlQuery.data && atlConfigQuery.data) {
            let atlList = atlQuery.data.objects.map(object => {
                return {
                    id: object.id,
                    name: object.name,
                    selected: false,
                };
            });

            atlList = atlList.map(atl => {
                const matchingConfig = atlConfigQuery.data.objects.find(config => config.atl.id === atl.id);

                return {
                    ...atl,
                    configId: matchingConfig?.id,
                    startDate: matchingConfig?.base_activity_start_date,
                    endDate: matchingConfig?.base_activity_end_date,
                    channelId: matchingConfig?.referer.id,
                };
            });

            setAtlList(atlList);
        }
    }, [atlQuery.data, atlConfigQuery.data]);

    const channelQuery = useFetchResource({
        resource: CONFIG_CHANNELS,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.name,
                    value: object.id,
                };
            });
        },
    });

    const regionQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_ATL_REGION,
        params: [{ key: 'atl_config', value: atlToEdit?.id || 0 }],
        enabled: !!atlToEdit,
    });

    useEffect(() => {
        if (regionQuery.data && atlToEdit) {
            const regional = regionQuery.data.objects[0]?.nationwide ? false : true;
            const regions = regionQuery.data.objects.map(object => {
                return {
                    country: object.country_name,
                    city: object.city_name,
                };
            });

            setAtlToEditRegions({
                regional: regional,
                regions: regions,
            });
        }
    }, [regionQuery.data, atlToEdit?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    // Mutations
    // Bulk Delete
    const atlBulkDeleteMutation = useDeleteResource({
        resource: CONFIG_ATL,
        resourceIds: atlList.filter(atl => atl.selected).map(atl => String(atl.id)),
        handleOnSuccess: () => {
            dispatch(
                addNotification({ copy: 'The ATL configurations have been successfully removed.', type: 'success' })
            );
        },

        handleOnError: () => {
            dispatch(
                addNotification({ copy: 'There was an issue while removing the ATL configurations.', type: 'error' })
            );
        },
    });

    const atlConfigBulkDeleteMutation = useBulkDeleteWithParams({
        resource: CONFIG_ATL_CONFIG,
        params: atlList.filter(atl => atl.selected).map(atl => ({ key: 'atl', value: atl.id })),
        handleOnSuccess: () => {
            atlRegionBulkDeleteMutation.mutate();
        },
        handleOnError: () => {
            dispatch(
                addNotification({ copy: 'There was an issue while removing the ATL configurations.', type: 'error' })
            );
        },
    });

    const atlRegionBulkDeleteMutation = useBulkDeleteWithParams({
        resource: CONFIG_ATL_REGION,
        params: atlList.filter(atl => atl.selected).map(atl => ({ key: 'atl_config', value: atl.id })),
        handleOnSuccess: () => {
            atlBulkDeleteMutation.mutate();
        },
        handleOnError: () => {
            dispatch(
                addNotification({ copy: 'There was an issue while removing the ATL configurations.', type: 'error' })
            );
        },
    });

    // Single Delete
    const atlSingleDeleteMutation = useDeleteResource({
        resource: CONFIG_ATL,
        resourceIds: [atlToDelete ? String(atlToDelete) : ''],
        handleOnSuccess: () => {
            setAtlToDelete(undefined);
            dispatch(
                addNotification({ copy: 'The ATL configuration has been successfully removed.', type: 'success' })
            );
        },
        handleOnError: () => {
            setAtlToDelete(undefined);
            dispatch(
                addNotification({ copy: 'There was an issue while removing this ATL configuration.', type: 'error' })
            );
        },
    });

    const atlConfigSingleDeleteMutation = useBulkDeleteWithParams({
        resource: CONFIG_ATL_CONFIG,
        params: [{ key: 'atl', value: atlToDelete || 0 }],
    });

    const atlRegionSingleDeleteMutation = useBulkDeleteWithParams({
        resource: CONFIG_ATL_REGION,
        params: [{ key: 'atl_config', value: atlToDelete || 0 }],
    });

    // Edit
    const atlPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_ATL,
    });

    const atlConfigPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_ATL_CONFIG,
    });

    const getAtlRegionMutationData = () => {
        if (atlToEditNewRegions?.regional) {
            const regionRequests: AtlRegionRequest[] = [];

            atlToEditNewRegions?.regions?.forEach((region: ATLRegion) => {
                if (region.country && region.city) {
                    regionRequests.push({
                        atl_config: generatePath('config', 'atl', String(atlToEdit?.id)),
                        nationwide: false,
                        country_name: region.country,
                        city_name: region.city,
                    });
                }
            });

            return regionRequests;
        } else {
            return [
                {
                    atl_config: generatePath('config', 'atl', String(atlToEdit?.id)),
                    nationwide: true,
                },
            ];
        }
    };

    const atlRegionPostMutation = usePostMultiResources({
        resource: CONFIG_ATL_REGION,
        data: getAtlRegionMutationData(),
    });

    const atlRegionDeleteMutation = useBulkDeleteWithParams({
        resource: CONFIG_ATL_REGION,
        params: [{ key: 'atl_config', value: atlToEdit?.id || 0 }],
        handleOnSuccess: () => {
            atlRegionPostMutation.mutate();
            queryClient.invalidateQueries({
                queryKey: generateQueryKey({ resource: CONFIG_ATL }),
            });
            queryClient.invalidateQueries({
                queryKey: generateQueryKey({ resource: CONFIG_ATL_REGION }),
            });
            setAtlToEdit(undefined);
            dispatch(addNotification({ copy: 'Above the line updated successfully.', type: 'success' }));
        },
    });

    // Handlers
    const handleCheckboxChange = (rowId: number) => {
        setAtlList(
            atlList.map(atl => {
                if (atl.id === rowId) {
                    return {
                        ...atl,
                        selected: !atl.selected,
                    };
                }
                return atl;
            })
        );
    };

    const handleBulkDeleteClick = () => {
        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove the selected ATL configurations?',
                },
                buttons: [
                    {
                        onClick: handleBulkDelete,
                        value: 'Remove',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkDelete = () => {
        dispatch(removePopup());
        atlConfigBulkDeleteMutation.mutate();
    };

    const handleSingleDeleteClick = (rowId: number) => {
        setAtlToDelete(rowId);
        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove this ATL configuration?',
                },
                buttons: [
                    {
                        onClick: handleSingleDelete,
                        value: 'Remove',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleSingleDelete = () => {
        dispatch(removePopup());

        atlSingleDeleteMutation.mutate();
        atlConfigSingleDeleteMutation.mutate();
        atlRegionSingleDeleteMutation.mutate();
    };

    const handleSubmitForm = (data: FieldValues) => {
        atlPatchMutation.mutate({
            resourceId: [String(atlToEdit?.id)],
            payload: [
                {
                    name: data.atlName,
                },
            ],
        });

        atlConfigPatchMutation.mutate({
            resourceId: [String(atlToEdit?.configId)],
            payload: [
                {
                    base_activity_start_date: data.startDate,
                    base_activity_end_date: data.endDate,
                    referer: generatePath('config', 'referer', String(data.channel)),
                },
            ],
        });

        atlRegionDeleteMutation.mutate();

        if (
            data.regional &&
            data.regions.length > 0 &&
            data.regions.every((region: ATLRegion) => region.country && region.city)
        ) {
            setAtlToEditNewRegions({
                regional: true,
                regions: data.regions,
            });
            // data.regions.forEach((region: ATLRegion) => {
            //     if (region.country && region.city) {
            //         atlRegionPostMutation.mutate([
            //             {
            //                 atl_config: generatePath('config', 'atl', String(atlToEdit?.id)),
            //                 nationwide: false,
            //                 country_name: region.country,
            //                 city_name: region.city,
            //             },
            //         ]);
            //     }
            // });
        } else {
            setAtlToEditNewRegions({
                regional: false,
                regions: [],
            });
            // atlRegionPostMutation.mutate([
            //     {
            //         atl_config: generatePath('config', 'atl', String(atlToEdit?.id)),
            //         nationwide: true,
            //     },
            // ]);
        }
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(removeModal()),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Manage Above the Line</h2>

            <ConfigTable
                status={atlQuery.status}
                isFetching={
                    atlQuery.isFetching ||
                    atlBulkDeleteMutation.isPending ||
                    atlConfigBulkDeleteMutation.isPending ||
                    atlRegionBulkDeleteMutation.isPending ||
                    atlSingleDeleteMutation.isPending ||
                    atlConfigSingleDeleteMutation.isPending ||
                    atlRegionSingleDeleteMutation.isPending
                }
                disabled={
                    atlBulkDeleteMutation.isPending ||
                    atlConfigBulkDeleteMutation.isPending ||
                    atlRegionBulkDeleteMutation.isPending ||
                    atlSingleDeleteMutation.isPending ||
                    atlConfigSingleDeleteMutation.isPending ||
                    atlRegionSingleDeleteMutation.isPending
                }
                empty={atlQuery.data?.objects.length === 0}
            >
                <ConfigTable.Table>
                    <ConfigTable.Header>
                        <ConfigTable.Row key={'manage-atl-header'}>
                            <ConfigTable.CellHeader />
                            <ConfigTable.CellHeader>ATL Name</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader />
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {atlList.map(atl => (
                            <ConfigTable.Row key={atl.id}>
                                <ConfigTable.CellCheckbox
                                    rowId={atl.id}
                                    checked={atl.selected}
                                    onCheckedChange={handleCheckboxChange}
                                />
                                <ConfigTable.Cell>{atl.name}</ConfigTable.Cell>
                                <ConfigTable.CellActions>
                                    <ConfigTable.ActionDropdownItem
                                        rowId={atl.id}
                                        type="edit"
                                        onClick={rowId => setAtlToEdit(atlList.find(atl => atl.id === rowId))}
                                    />
                                    <ConfigTable.ActionDropdownItem
                                        rowId={atl.id}
                                        type="delete"
                                        onClick={handleSingleDeleteClick}
                                    />
                                </ConfigTable.CellActions>
                            </ConfigTable.Row>
                        ))}
                    </ConfigTable.Body>
                </ConfigTable.Table>
                <ConfigTable.ActionBar>
                    <ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionButton type="delete" label="Remove" onClick={handleBulkDeleteClick} />
                    </ConfigTable.ActionBarJustifyLeft>
                    <ConfigTable.ActionBarJustifyRight>
                        <ConfigTable.ActionButton
                            type="add"
                            label="Add ATL Configuration"
                            onClick={() => dispatch(setModal('SetupAboveTheLine', {}))}
                        />
                    </ConfigTable.ActionBarJustifyRight>
                </ConfigTable.ActionBar>
            </ConfigTable>

            {atlToEdit && atlToEditRegions && (
                <StyledEditContainer>
                    <Form
                        status={
                            regionQuery.isFetching || atlPatchMutation.isPending || atlConfigPatchMutation.isPending
                                ? 'loading'
                                : 'enabled'
                        }
                        onSubmit={handleSubmitForm}
                        defaultValues={{
                            atlName: atlToEdit.name,
                            startDate: moment(atlToEdit.startDate).format('YYYY-MM-DD'),
                            endDate: moment(atlToEdit.endDate).format('YYYY-MM-DD'),
                            channel: atlToEdit.channelId,
                            regional: atlToEditRegions.regional,
                            regions: atlToEditRegions?.regions,
                        }}
                    >
                        <FormCreateAtl channels={channelQuery.data || []} />
                        <Form.Footer>
                            <Form.InputButton
                                type="submit"
                                value="Update"
                                loading={atlPatchMutation.isPending || atlConfigPatchMutation.isPending}
                            />
                            <Form.InputButton
                                type="button"
                                value="Cancel"
                                buttonTheme={ButtonThemes.Secondary}
                                onClick={() => setAtlToEdit(undefined)}
                            />
                        </Form.Footer>
                    </Form>
                </StyledEditContainer>
            )}
        </>
    );
};

export default LayoutModalManageAboveTheLine;
