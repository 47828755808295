import { CubedField } from '../../../../types';
import { lineGraphDataSeries } from '../../../helpers/create-data-series';
import { unknownErrorData } from '../../../helpers/errors';

import { DataType, LineGraphLegendOrder, LineGraphSeries, ResourceComparisonData } from '../../../types';

type UseResourceLineGraphComparisonArgs = {
    resourceData: ResourceComparisonData;
    dateDimension?: CubedField;
    seriesField?: CubedField;
    yAxis?: CubedField;
    legendOrder?: LineGraphLegendOrder;
};

const useResourceLineGraphComparison = ({
    resourceData,
    dateDimension,
    seriesField,
    yAxis,
    legendOrder,
}: UseResourceLineGraphComparisonArgs) => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success' && dateDimension && seriesField && yAxis) {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        let lineSeries: LineGraphSeries[] = [];
        let lineComparisonSeries: LineGraphSeries[] = [];

        if (resource.status === 'success') {
            lineSeries = lineGraphDataSeries(resource, seriesField, dateDimension, yAxis, legendOrder) || [];
        }

        if (comparisonResource.status === 'success') {
            lineComparisonSeries =
                lineGraphDataSeries(comparisonResource, seriesField, dateDimension, yAxis, legendOrder) || [];
        }

        return {
            type: 'lineGraphComparison' as DataType,
            status: 'success' as const,
            metric: yAxis.displayName,
            series: lineSeries || [],
            comparisonSeries: lineComparisonSeries || [],
        };
    }

    return unknownErrorData();
};

export default useResourceLineGraphComparison;
