import React, { Component } from 'react';

//
// 	Class ListHeaderCheckTitle
//		props.header :    String
//

class ListHeaderCheckTitle extends Component {
    render() {
        return (
            <div className="list-widget__header-check-title">
                <p>{this.props.title}</p>
            </div>
        );
    }
}

export default ListHeaderCheckTitle;
