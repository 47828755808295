import { CubedField } from '../../../../types';
import { unknownErrorData } from '../../../helpers/errors';
import { getValue } from '../../../helpers/table-data';
import { ComparisonTableData, PieTableComparisonData } from '../../../types';

type UseResourcePieTableGraphColoursArgs = {
    resourceData: ComparisonTableData;
    graphResource: PieTableComparisonData;
    dimension: CubedField;
};

const useResourcePieTableMatchColours = ({
    resourceData,
    graphResource,
    dimension,
}: UseResourcePieTableGraphColoursArgs) => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    const status = resourceData.status;
    const graphResourceStatus = graphResource.status;

    if (status !== 'success' || graphResourceStatus !== 'success') {
        return resourceData;
    }

    if (status === 'success' && graphResourceStatus === 'success') {
        if (!graphResource.series) {
            throw new Error('Expected graph series');
        }

        if (!resourceData.rows) {
            throw new Error('Expected table rows for matching with series data');
        }

        const updatedRows = resourceData.rows.map(row => {
            const dimensionToMatch = getValue(row.dimension, dimension.rawName);
            let foundGraphSeries = graphResource.series[0]?.data.find(series => series.name === dimensionToMatch);

            if (!foundGraphSeries) {
                foundGraphSeries = graphResource.comparisonSeries[0]?.data.find(
                    series => series.name === dimensionToMatch
                );
            }

            return {
                ...row,
                colour: foundGraphSeries?.color,
            };
        });

        return {
            ...resourceData,
            rows: updatedRows,
        };
    }
    return unknownErrorData();
};

export default useResourcePieTableMatchColours;
