import React from 'react';
import styled from 'styled-components';
import { SectionDashboardLayout, WidgetMenuCollection, WidgetMenuType } from '../../../../types';
import WidgetMenuDropdown, { DropdownSide } from '../widget-menu-dropdown';
import WidgetMenuSeparatorHorizontal from '../widget-menu-separator-horizontal';
import WidgetMenuSectionTitle from '../widget-menu-section-title';
import { useLayoutContext } from '../../context/layout-context';

const StyledContainer = styled.div<{ layout: SectionDashboardLayout }>`
    display: flex;
    gap: ${props => ([SectionDashboardLayout.XS, SectionDashboardLayout.SM].includes(props.layout) ? '5px' : '15px')};
`;

type WidgetMenuSectionArgs = {
    sections: WidgetMenuCollection[];
};

const WidgetMenuSection = ({ sections }: WidgetMenuSectionArgs) => {
    const { layout } = useLayoutContext();
    const isLoading = sections.map(section => section.menus.some(menu => menu.status === 'loading'))[0];

    if (
        layout === SectionDashboardLayout.XS ||
        layout === SectionDashboardLayout.SM ||
        layout === SectionDashboardLayout.MD
    ) {
        return (
            <WidgetMenuDropdown loading={isLoading} dropdownSide={DropdownSide.BOTTOM}>
                {sections.map((section, index) => {
                    const isLastSection = index === sections.length - 1;

                    return (
                        <React.Fragment key={`${section.title}-${index}`}>
                            {section.title ? <WidgetMenuSectionTitle title={section.title} /> : null}
                            {section.menus.map((menu, index) => (
                                <React.Fragment key={`menu-${index}`}> {menu.menu}</React.Fragment>
                            ))}
                            {!isLastSection && <WidgetMenuSeparatorHorizontal />}
                        </React.Fragment>
                    );
                })}
            </WidgetMenuDropdown>
        );
    }

    const selectMenus: React.ReactNode[] = [];
    const groupedMenus: { title?: string; menus: React.ReactNode[] }[] = [];

    sections.forEach((section, sectionIndex) => {
        if (section.menus.filter(menu => menu.type === WidgetMenuType.CHECKBOX).length > 0) {
            groupedMenus.push({
                title: section.title,
                menus: [section.menus.map(menu => menu.type === WidgetMenuType.CHECKBOX && menu.menu)],
            });
        }

        return section.menus.forEach((menu, index) => {
            if (menu.type === WidgetMenuType.SELECT) {
                selectMenus.push(<React.Fragment key={`menu-${sectionIndex}-${index}`}>{menu.menu}</React.Fragment>);
            }
        });
    });

    return (
        <StyledContainer layout={layout}>
            <>
                {selectMenus.map(section => section)}
                {groupedMenus.length > 0 ? (
                    <WidgetMenuDropdown loading={false} dropdownSide={DropdownSide.BOTTOM}>
                        {groupedMenus.map((groupedMenu, index) => {
                            return (
                                <React.Fragment key={`groupedMenu-${groupedMenu.title}-${index}`}>
                                    {groupedMenu.title ? <WidgetMenuSectionTitle title={groupedMenu.title} /> : null}
                                    {groupedMenu.menus.map(menu => menu)}
                                </React.Fragment>
                            );
                        })}
                    </WidgetMenuDropdown>
                ) : null}
            </>
        </StyledContainer>
    );
};

export default WidgetMenuSection;
