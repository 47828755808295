import React from 'react';
import styled from 'styled-components';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import Message, { IconDirection, IconSize } from '../../../components/message';
import WidgetBaseContainer from './widget-base-container';
import WidgetBaseLoader from './widget-base-loader';
import WidgetBaseLink from './widget-base-link';

// Enums & Types
import { WidgetColour } from '../../types';

// Context
import SectionDashboardWidgetContextProvider from '../../context/section-dashboard-widget-context';
import { RootState } from '../../../redux/store';

const StyledWidgetContent = styled.div`
    position: relative;
    overflow: auto;
    flex: 1;
`;

const StyledWidgetFooter = styled.div`
    border-top: 1px solid ${props => props.theme.sectionDashboard.widget.base.footer.borderColor};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StyledFeedbackDisplay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const StyledNoDataWrapper = styled.div`
    width: 100%;
    padding: 10px;
    color: ${props => props.theme.sectionDashboard.widget.base.textColorNoData};
`;

const StyledMenuContainer = styled.div<{ $loading?: boolean }>`
    display: flex;

    & button {
        all: inherit;
        cursor: ${props => !props.$loading && 'pointer'};
    }
`;

export type WidgetBaseProps = {
    title: string;
    loading?: boolean;
    noData?: boolean;
    error?: boolean;
    href?: string;
    children?: React.ReactNode;
    colour?: WidgetColour;
    menu?: React.ReactNode;
};

const WidgetBase = ({
    title,
    loading,
    noData,
    error,
    href,
    children,
    colour = WidgetColour.Default,
    menu,
}: WidgetBaseProps) => {
    let navigate = useNavigate();
    const { width, height } = useSectionDashboard();

    const accountToken = useSelector((state: RootState) => state.account.token);

    const onTitleClick = () => {
        if (href) {
            navigate(`/${accountToken}${href}`);
        }
    };

    const renderLoading = () => {
        return (
            <StyledFeedbackDisplay>
                <WidgetBaseLoader width={40} height={40} margin={10} transformOrigin={true} />
            </StyledFeedbackDisplay>
        );
    };

    const renderNoDataOrError = (type: string, width: number, height: number) => {
        let size: IconSize = 'small';
        let display: IconDirection = 'horizontal';

        if (height < 2 || width < 3) {
            size = 'xsmall';
            display = 'vertical';
        } else if (height < 4 || width < 4) {
            size = 'small';
        }

        if (width > 1) {
            display = 'horizontal';
        }

        return (
            <StyledFeedbackDisplay>
                <StyledNoDataWrapper>
                    {type === 'noData' ? (
                        <Message
                            shortTitle={'No Data'}
                            title={'No Data Available'}
                            copy={'Try adjusting your filters.'}
                            type={'info'}
                            size={size}
                            display={display}
                        />
                    ) : (
                        <Message
                            shortTitle={'Error'}
                            title={'Error'}
                            copy={
                                'There was an error retrieving your data. Please try again later or contact support@cubed.email'
                            }
                            type={'error'}
                            size={size}
                            display={display}
                        />
                    )}
                </StyledNoDataWrapper>
            </StyledFeedbackDisplay>
        );
    };

    return (
        <SectionDashboardWidgetContextProvider colour={colour}>
            <WidgetBaseContainer>
                <StyledWidgetContent>
                    {loading
                        ? renderLoading()
                        : noData
                        ? renderNoDataOrError('noData', width, height)
                        : error
                        ? renderNoDataOrError('error', width, height)
                        : children}
                </StyledWidgetContent>
                <StyledWidgetFooter data-testid="sd-widget-footer">
                    <>
                        <WidgetBaseLink loading={loading} text={title} onClick={href ? onTitleClick : undefined} />
                        {menu && <StyledMenuContainer $loading={loading}>{menu}</StyledMenuContainer>}
                    </>
                </StyledWidgetFooter>
            </WidgetBaseContainer>
        </SectionDashboardWidgetContextProvider>
    );
};

export default WidgetBase;
