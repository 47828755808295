import { differenceInCalendarMonths, startOfYear, endOfYear, addYears } from 'date-fns';
export default function getMonthDisplayRange(date, dateOptions, fixedHeight) {
    const startDateOfMonth = startOfYear(date, dateOptions);
    const endDateOfMonth = endOfYear(date, dateOptions);
    const startDateOfCalendar = startOfYear(startDateOfMonth, dateOptions);
    let endDateOfCalendar = endOfYear(endDateOfMonth, dateOptions);
    if (fixedHeight && differenceInCalendarMonths(endDateOfCalendar, startDateOfCalendar) <= 1) {
        endDateOfCalendar = addYears(endDateOfCalendar, 1);
    }
    return {
        start: startDateOfCalendar,
        end: endDateOfCalendar,
        startDateOfMonth,
        endDateOfMonth,
    };
}
