// React Dependencies
import React from 'react';
import styled from 'styled-components';

// Import Components
import InputPassword from '../inputs/input-password';
import Tooltip from '../tooltip';

const StyledFormField = styled.div`
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 15px;
`;

const StyledFormFieldTop = styled.div`
    margin-bottom: 5px;
`;

const StyledFormFieldLabel = styled.label`
    font-size: 1.1em;
    font-weight: 400;
`;

const StyledErrorMessage = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colours.red};
    margin-bottom: 1em;
`;

const StyledRequiredField = styled.span`
    margin-bottom: 5px;
    color: ${props => props.theme.colours.red};
`;

export type FormPasswordProps = {
    toolTipCopy?: string;
    errorMessage?: string;
    requiredField?: boolean;
    label: string;
    inputPlaceholder: string;
    inputValue: string;
    inputAutocomplete: string;
    inputOnChange: () => void;
    onInputKeyPress: () => void;
};

const FormPassword = ({
    toolTipCopy,
    errorMessage,
    requiredField,
    label,
    inputPlaceholder,
    inputValue,
    inputAutocomplete,
    inputOnChange,
    onInputKeyPress,
}: FormPasswordProps) => {
    return (
        <StyledFormField>
            <StyledFormFieldTop>
                <StyledFormFieldLabel>
                    {label} {requiredField && <StyledRequiredField>*</StyledRequiredField>}
                </StyledFormFieldLabel>
                {toolTipCopy && <Tooltip copy={toolTipCopy} />}
            </StyledFormFieldTop>

            <InputPassword
                value={inputValue}
                placeholder={inputPlaceholder}
                autocomplete={inputAutocomplete}
                onChange={inputOnChange}
                onKeyDown={onInputKeyPress}
            />

            {errorMessage && <StyledErrorMessage> {errorMessage} </StyledErrorMessage>}
        </StyledFormField>
    );
};

export default FormPassword;
