import React from 'react';
import styled from 'styled-components';
import { useConfigTableContext } from '../context/config-table-context';

const StyledRow = styled.tr<{ isFetching: boolean; disabled: boolean }>`
    :hover,
    :focus {
        background-color: ${props => !props.disabled && !props.isFetching && '#f9f9f9'};
    }
`;

type RowProps = {
    key: string | number;
    children: React.ReactNode;
};

const Row = ({ children }: RowProps) => {
    const { status, isFetching, tableDisabled } = useConfigTableContext();

    if (status) {
        return (
            <StyledRow isFetching={isFetching} disabled={tableDisabled} data-testid="config-table-row">
                {children}
            </StyledRow>
        );
    }
    return null;
};

export default Row;
