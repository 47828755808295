import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Notification } from '../components/notification';

class ViewNotifications extends Component {
    render() {
        return (
            <div className="notifications">
                {this.props.notification.notifications.map(notification => (
                    <Notification
                        key={notification.id}
                        id={notification.id}
                        copy={notification.copy}
                        type={notification.type}
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        notification: state.notification,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewNotifications);
