import React from 'react';
import styled from 'styled-components';
import IconErrorTriangle from '../../components/icons/error-triangle';

const StyledErrorContainer = styled.div`
    padding: 20px;
    background: ${props => props.theme.sectionDashboard.validation.backgroundColor};
    box-shadow: 0px 1px 4px 0px ${props => props.theme.sectionDashboard.validation.shadowColor};
    text-align: center;
`;

const StyledErrorIcon = styled.div`
    margin-bottom: 30px;
    & svg {
        height: 60px;
    }
`;

const StyledP = styled.p`
    font-size: 1.1rem;
    font-weight: 500;
`;

const StyledUL = styled.ul`
    list-style: disc inside;
`;

const StyledLI = styled.li`
    margin: 15px;
`;

export type ValidationMessageProps = {
    messages: string[];
};

const ValidationMessage = ({ messages }: ValidationMessageProps) => {
    return (
        <StyledErrorContainer>
            <StyledErrorIcon>
                <IconErrorTriangle />
            </StyledErrorIcon>
            <StyledP>Configuration validation failed with the following errors:</StyledP>

            <StyledUL>
                {messages.map((message, index) => {
                    return <StyledLI key={`${message}-${index}`}>{message}</StyledLI>;
                })}
            </StyledUL>
        </StyledErrorContainer>
    );
};

export default ValidationMessage;
