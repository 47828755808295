import React, { Component } from 'react';
import LayoutTableGraph from './../table-graph/layouts/table-graph';
import { config } from '../configurations/monthly-channel-performance';

class ReportMonthlyChannelPerformance extends Component {
    constructor(props) {
        super(props);
        this.config = config;
    }

    render() {
        return (
            <LayoutTableGraph
                hash={this.props.location.hash}
                fetchProducts={this.props.fetchProducts}
                config={this.config}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedProducts={this.props.selectedProducts}
                selectedMetrics={this.props.selectedMetrics}
            />
        );
    }
}

export default ReportMonthlyChannelPerformance;
