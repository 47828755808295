import { CubedField, CubedResource } from '../../../types';

const getMetrics = ({ availableResources }: { availableResources: CubedResource[] }) => {
    // Create an array of all available metrics
    const metrics: CubedField[][] = [];

    availableResources.forEach(res => {
        if ('metrics' in res && res.metrics) {
            metrics.push(res.metrics);
        }
    });

    const metricsFlat = [...new Set(metrics.flat())];

    // Create the metric dropdown options
    const metricDropdownOptions = metricsFlat
        .map(metric => {
            return {
                value: metric.id,
                label: metric.displayName,
            };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    return metricDropdownOptions;
};

export default getMetrics;
