import React from 'react';
import styled from 'styled-components';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import IconTriangleExclamation from '../../../components/icons/triangle-exclamation';
import IconFloppyDisk from '../../../components/icons/floppy-disk';
import IconPenToSquare from '../../../components/icons/pen-to-square';

const StyledTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
`;

const StyledDialogTitle = styled(AlertDialog.Title)`
    font-size: 1rem;
    margin: 0;
    font-weight: 600;
    color: ${props => props.theme.dialog.content.color};
`;

const StyledIconTriangleExclamation = styled(IconTriangleExclamation)`
    width: 20px;
    color: ${props => props.theme.colours.warning};
`;

const StyledIconFloppyDisk = styled(IconFloppyDisk)`
    width: 18px;
    color: ${props => props.theme.colours.green};
`;

const StyledIconPenToSquare = styled(IconPenToSquare)`
    width: 18px;
`;

type IconType = 'warning' | 'save' | 'edit';

const DialogTitle = ({ iconType, children }: { iconType?: IconType; children: React.ReactNode }) => {
    return (
        <StyledTitleContainer>
            {iconType && iconType === 'warning' && <StyledIconTriangleExclamation />}
            {iconType && iconType === 'save' && <StyledIconFloppyDisk />}
            {iconType && iconType === 'edit' && <StyledIconPenToSquare />}
            <StyledDialogTitle>{children}</StyledDialogTitle>
        </StyledTitleContainer>
    );
};

export default DialogTitle;
