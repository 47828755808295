import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Request from '../../helpers/request';

import Event from './event';

const StyledContainer = styled.div`
    position: relative;
`;

const StyledLoading = styled.div`
    ${props => props.theme.skeletonLoader}
    padding: 18px;
    border-radius: 50px;
    margin-top: 3px;
    width: 50%;
`;

const StyledPage = styled.div`
    font-style: italic;
    margin-top: 4px;
    line-height: 33px;
    padding: 0 12px;
    display: inline-block;
    border-radius: 20px;
    background-color: ${props => props.theme.colours.lightGrey};
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 600px;
    overflow: hidden;
`;

const PageInfo = ({ pageId }) => {
    const [page, setPage] = useState(null);

    useEffect(() => {
        fetchPage();
    }, [pageId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchPage = () => {
        const controller = new AbortController();
        const fetchPromise = new Request();
        fetchPromise.get('report', 'scv-page', [{ key: 'id', value: pageId }]).then(response => {
            setPage(response.data.objects[0]);
        });
        return () => controller.abort();
    };

    if (!page) return <StyledLoading />;

    if (page?.events.length < 1) {
        return (
            <StyledContainer>
                <StyledPage>{page.url}</StyledPage>
            </StyledContainer>
        );
    } else {
        return page.events.map((event, index) => {
            return (
                <StyledContainer key={index}>
                    <Event event={event} />
                </StyledContainer>
            );
        });
    }
};

export default PageInfo;
