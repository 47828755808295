import React from 'react';
import styled from 'styled-components';

// Context
import { useConfigDragAndDropTableContext } from '../../../context/config-drag-and-drop-table-context';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
`;

const ActionBarJustifyLeft = ({ children }: { children: React.ReactNode }) => {
    const { status } = useConfigDragAndDropTableContext();

    if (status) {
        return <StyledContainer>{children}</StyledContainer>;
    }
    return null;
};

export default ActionBarJustifyLeft;
