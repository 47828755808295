import React from 'react';
import styled from 'styled-components';
import DropdownWithSearch from '../../components/common/dropdown-with-search';
import IconCross from '../../components/icons/cross';
import { StyledDeleteButton } from './create-segment-add-condition';

// Styled Components
const StyledGroupHeader = styled.div`
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d3d3d3;
    height: 80px;
    padding: 20px 20px 0px 20px;
`;

const StyledTitle = styled.p`
    display: inline-block;
    padding: 10px;
    font-size: 1rem;
    font-weight: 600;
    margin: 0;
`;

const StyledGroupOperator = styled.div`
    display: inline-block;
    width: 120px;
`;

const StyledGroupNav = styled.div`
    float: right;
    text-align: right;
`;

const StyledIconContainer = styled.div`
    width: 14px;
    height: 14px;
`;

const CreateSegmentGroupHeader = ({ nested, operators, selectedOperator, onSelectOperator, onCloseGroup }) => {
    return (
        <StyledGroupHeader nested={nested}>
            <div>
                <StyledTitle>Match</StyledTitle>
                <StyledGroupOperator>
                    <DropdownWithSearch
                        isSearchable={false}
                        isClearable={false}
                        dropdownItems={operators}
                        selected={selectedOperator}
                        onSelectedItemChange={onSelectOperator}
                    />
                </StyledGroupOperator>
                <StyledTitle>of the following:</StyledTitle>
            </div>
            {nested && (
                <StyledGroupNav>
                    <StyledDeleteButton onClick={onCloseGroup}>
                        <StyledIconContainer>
                            <IconCross />
                        </StyledIconContainer>
                    </StyledDeleteButton>
                </StyledGroupNav>
            )}
        </StyledGroupHeader>
    );
};

export default CreateSegmentGroupHeader;
