import React, { Component } from 'react';

import Button from './../components/button';
import Cancel from './cancel';
import styled from 'styled-components';
import './../sass/components/add-item.scss';

const StyledCancel = styled.div`
    position: absolute;
    right: 0;
    margin: 0.4rem;
`;

class AddItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasFocus: false,
            itemList: this.props.itemList,
            filterString: '',
        };

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onFocus() {
        this.setState({ hasFocus: true });
    }
    onBlur() {
        this.setState({ hasFocus: false });
    }

    handleSearchItemList = (value, lists) => {
        let filterListItem = [];
        this.setState({ filterString: value });

        if (value === '') {
            this.setState({ itemList: lists });
        } else {
            filterListItem = lists.filter(list => list.name.toLowerCase().includes(value.toLowerCase()));
            this.setState({ itemList: filterListItem });
        }
    };

    render() {
        return (
            <li className={this.props.classNameAddItem} tabIndex="0" onFocus={this.onFocus} onBlur={this.onBlur}>
                <div className="add-items__item__content__wrapper">
                    <div
                        className="add-items__item__content"
                        onClick={() => {
                            if (this.state.filterString === '') this.filterInput.focus();
                        }}
                    >
                        <input
                            type="text"
                            className="add-items__item__event"
                            ref={input => {
                                this.filterInput = input;
                            }}
                            placeholder={this.props.buttonText}
                            value={this.state.filterString}
                            onChange={e => this.handleSearchItemList(e.target.value, this.props.itemList)}
                            disabled={this.props.disabled}
                        />
                        <StyledCancel>
                            <Cancel
                                onClick={() => {
                                    this.setState({ filterString: '' });
                                    this.setState({ itemList: this.props.itemList });
                                }}
                                colour={`${this.state.filterString === '' ? 'white' : 'red'}`}
                            />
                        </StyledCancel>
                    </div>
                </div>
                <div
                    style={this.props.dropdownStyle}
                    className={`add-item__dropdown__wrapper ${
                        this.state.hasFocus && !this.props.disabled ? '' : 'hidden'
                    }`}
                >
                    <ul className="add-item__dropdown scrollbar">
                        {this.state.itemList.length > 0 ? (
                            this.state.itemList.map(item => {
                                return (
                                    <li key={item.id}>
                                        <Button
                                            level="addItem"
                                            onClick={() => this.props.handleAdd(item)}
                                            fullWidth={true}
                                        >
                                            {item.display_name}
                                        </Button>
                                    </li>
                                );
                            })
                        ) : (
                            <li>{this.props.noItemMessage}</li>
                        )}
                    </ul>
                    <div className="add-item__dropdown  add-item__close-btn">
                        <Button
                            level="danger"
                            onClick={() => {
                                this.setState({ hasFocus: false });
                                this.setState({ filterString: '' });
                                this.setState({ itemList: this.props.itemList });
                            }}
                            disabled={false}
                            fullWidth={true}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </li>
        );
    }
}

export default AddItem;
