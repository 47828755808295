import React, { useContext } from 'react';
import validators from '../../../components/forms/validation/validators';

// Context
import { CreateAWidgetFormContext } from './context/create-a-widget-form-context';

// Types
import { WidgetColour } from '../../../section-dashboard/types';

// Components
import Form from '../../../components/forms/form';

const CreateAWidgetFormSparkline = () => {
    // Context
    const { allMetrics } = useContext(CreateAWidgetFormContext);

    const colourOptions = Object.keys(WidgetColour).filter(v => isNaN(Number(v)));
    const colourDropdownOptions = colourOptions.map(colour => {
        return {
            label: colour,
            value: colour,
        };
    });

    return (
        <>
            <Form.Section>
                <Form.SectionTitle>Display Settings</Form.SectionTitle>
                <Form.Field>
                    <Form.InputLabel htmlFor="graphMetric" label="Metric" />
                    <Form.InputSelect
                        name="graphMetric"
                        options={allMetrics}
                        placeholder="Please select a metric"
                        validators={[validators.required]}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.InputLabel htmlFor="widgetColor" label="Color" />
                    <Form.InputSelect
                        name="widgetColor"
                        options={colourDropdownOptions}
                        placeholder="Please select a color"
                        validators={[validators.required]}
                    />
                </Form.Field>
            </Form.Section>
        </>
    );
};

export default CreateAWidgetFormSparkline;
