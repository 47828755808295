export const generateTableParams = (
    resource,
    orderBy,
    orderByDir,
    pagination,
    startDate,
    endDate,
    selectedProducts,
    customerSaleTypes,
    dateSelectorTypes,
    selectedTabId
) => {
    const startDateFormatted = startDate.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    const endDateFormatted = endDate.format('YYYY-MM-DDTHH:mm:ss') + 'Z';

    const orderDirModifier = orderByDir === 'desc' ? '-' : '';
    const sortBy = orderBy.requestParam ? orderBy.requestParam : orderBy.rawName;

    const params = [
        {
            key: 'order_by',
            value: `${orderDirModifier}${sortBy}`,
        },
        {
            key: 'limit',
            value: pagination.currentRowCount,
        },
        {
            key: 'offset',
            value: pagination.currentRowCount * (pagination.currentPage - 1),
        },
    ];

    // Add date params
    dateSelectorTypes.forEach(type => {
        if (type.checked === true) {
            params.push(
                {
                    key: `${type.value}__gte`,
                    value: startDateFormatted,
                },
                {
                    key: `${type.value}__lte`,
                    value: endDateFormatted,
                }
            );
        }
    });

    // Add params for customer sale types
    if (selectedTabId === 0 || selectedTabId === 2) {
        // Visitor Tab
        if (customerSaleTypes.some(type => type.value === 'sale' && type.active === true)) {
            params.push({
                key: 'has_sale',
                value: true,
            });
        } else if (customerSaleTypes.some(type => type.value === 'non-sale' && type.active === true)) {
            params.push({
                key: 'has_sale',
                value: false,
            });
        }
        // Selected Product or Channel
        if (selectedTabId === 2) {
            params.push({
                key: 'referer__id__in',
                value: selectedProducts.map(product => product.id).toString(),
            });
        } else {
            params.push({
                key: 'product__id__in',
                value: selectedProducts.map(product => product.id).toString(),
            });
        }
    } else {
        // Transaction Tab
        params.push({
            key: 'product__id__in',
            value: selectedProducts.map(product => product.id).toString(),
        });
    }

    return params;
};

export const generateTablePaginations = (startValue, endValue, tablePaginations) => {
    if (typeof startValue !== 'number' || typeof endValue !== 'number')
        throw new Error('Please provide a valid number for either startValue and endValue');

    const currentRange = [(startValue - 1) * endValue + 1, startValue * endValue];

    return {
        ...tablePaginations,
        currentPage: startValue,
        currentRowCount: endValue,
        currentRange,
    };
};
