import axios, { AxiosRequestConfig } from 'axios';
import { Middleware } from 'redux';
import { generateUrl } from '../../helpers/request-builder';
import { removeAccount } from '../actions/account';
import { signOutError } from '../actions/sign-out';
import { removeUser } from '../actions/user';

const signOutMiddleware: Middleware = store => next => action => {
    if (action.type === 'SIGN_OUT') {
        try {
            const config: AxiosRequestConfig = {
                method: 'GET',
                url: generateUrl('user', 'logout', [], false) || undefined,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            axios(config).then(() => {
                store.dispatch(removeUser());
                store.dispatch(removeAccount());
            });
        } catch {
            store.dispatch(signOutError());
        }
    }

    next(action);
};

export default signOutMiddleware;
