import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

type CommonButtonProps = {
    type?: 'primary' | 'secondary' | 'danger';
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
    value: string;
};

export const StyledCommonButtonShared = styled('button')<{
    buttonType?: 'primary' | 'secondary' | 'danger';
    disabled: boolean;
}>`
    border: none;
    min-width: 40px;
    padding: 0 16px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 36px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    position: relative;
    background-color: ${props =>
        props.buttonType === 'primary'
            ? props.disabled
                ? `${props.theme.colours.offBlack}99`
                : props.theme.colours.offBlack
            : props.buttonType === 'secondary'
            ? 'transparent'
            : props.buttonType === 'danger'
            ? props.theme.colours.danger
            : ''};
    color: ${props =>
        props.buttonType === 'secondary' ? props.theme.colours.textDefault : props.theme.colours.textLight};
    cursor: ${props => (props.disabled ? 'not-allowed !important' : 'pointer')};
    &:hover,
    &:focus {
        box-shadow: ${props =>
            props.buttonType === 'secondary' && '0 1px 1px rgb(0 0 0 / 10%), 0 1px 3px rgb(0 0 0 / 18%)'};
        background-color: ${props => props.buttonType === 'danger' && '#b3003f'};
    }
`;

const CommonButton = ({ type = 'primary', onClick, disabled, value }: CommonButtonProps) => {
    return (
        <StyledCommonButtonShared buttonType={type} onClick={onClick} disabled={disabled!}>
            {value}
        </StyledCommonButtonShared>
    );
};

export default memo(CommonButton, isEqual);
