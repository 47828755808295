import React from 'react';
import styled from 'styled-components';

// Component
import Dropdown from '../../../dropdown-component';

// Styles
const StyledPaginationDescription = styled.div`
    font-size: 13px;
    & option,
    & select {
        font-size: 13px;
    }
`;

const StyledPaginationDropdownSpan = styled.span`
    margin-right: 22px;
    margin-left: -8px;
`;

// Types
type TablePaginationDescriptionProps = {
    currentPage: number;
    currentRowCount: number;
    maxPage: number;
    minPage: number;
    ownerCallback: (key: string, value: number) => void;
    rowDefault: number;
    rowOptions: { value: number }[];
    selectedRowCount: number;
    totalResults: number;
};

const TablePaginationDescription = ({
    currentPage,
    currentRowCount,
    ownerCallback,
    rowDefault,
    rowOptions,
    selectedRowCount,
    totalResults,
}: TablePaginationDescriptionProps) => {
    const setRowCount = (value: string) => {
        ownerCallback('rowCount', parseInt(value, 10));
    };

    const currentRange = [(currentPage - 1) * currentRowCount + 1, currentPage * currentRowCount];

    return (
        <StyledPaginationDescription>
            <span data-testid="pagination-total">
                {currentRange[0]} to {Math.min(currentRange[1], totalResults)} of {totalResults} results. Showing{' '}
            </span>
            <StyledPaginationDropdownSpan>
                <Dropdown
                    selectedRowCount={selectedRowCount}
                    defaultValue={rowDefault}
                    options={rowOptions}
                    ownerCallback={setRowCount}
                    indexId={0}
                    legendStyle="solid"
                    showTitle={false}
                    title=""
                />
            </StyledPaginationDropdownSpan>
            <span data-testid="pagination-rows">rows per page.</span>
        </StyledPaginationDescription>
    );
};

export default TablePaginationDescription;
