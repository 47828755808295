import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// HighCharts
import Message from '../../../components/message';
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Helper
import { assignColour } from '../../../helpers/colours';
import { updateChartTooltipPointFormat, sortChartData } from './helpers/chart-helper';
import { useSelector } from 'react-redux';

const StyledBarChart = styled.div`
    min-height: 310px;
    min-width: 0px;
    position: relative;
    opacity: ${props => props.isLoading && 0.5};
    transition: ${props => props.isLoading && props.theme.transition};
    flex-grow: 1;
    flex-basis: 20em;
`;

const BarChart = ({
    config,
    data,
    dimensions,
    metrics,
    loading,
    selectedMetric,
    selectedRows,
    messageTitle = 'No data.',
    messageCopy = '',
    messageType = 'info',
    noData,
}) => {
    const columns = [...dimensions, ...metrics];
    const [sortedData, setSortedData] = useState([]);
    const [hasNoData, setHasNoData] = useState(noData);
    const currencySymbol = useSelector(state => state.user.symbol);

    useEffect(() => {
        if (config.chart) {
            getBarChartData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedMetric, selectedRows, config.chart.chartType]);

    const getBarChartData = () => {
        let barData = [];

        if (config.chart.chartType === 'barHorizontal') {
            config.chart.options.chart.inverted = true;
        } else {
            config.chart.options.chart.inverted = false;
        }

        if (selectedRows.length === 0) {
            columns.forEach(column => {
                if (data.objects !== undefined) {
                    data.objects.map(row => {
                        if (column.children && row[column.rawName] !== undefined) {
                            updateChartTooltipPointFormat(
                                config,
                                row[selectedMetric[0].rawName]?.value,
                                currencySymbol
                            );
                            let barValue = row[selectedMetric[0].rawName]?.value.replaceAll(',', '');

                            barData.push({
                                name: row[column.rawName].value,
                                y: parseFloat(barValue.replace(currencySymbol, '')),
                                color: assignColour(row, dimensions, selectedRows)._originalInput,
                            });
                        }
                        return barData;
                    });
                }
                return barData;
            });
        }

        if (selectedRows.length > 0) {
            selectedRows.forEach(row => {
                updateChartTooltipPointFormat(config, row.data[selectedMetric[0].rawName]?.value);
                let barValue = row.data[selectedMetric[0].rawName]?.value.replaceAll(',', '');

                barData.push({
                    name: row.data[dimensions[0].rawName].value,
                    y: parseFloat(barValue.replace(currencySymbol, '')),
                    color: row.colour._originalInput,
                });
            });
        }

        let dataSorted = barData.sort(sortChartData);

        // return top 10 from data
        setSortedData(dataSorted.slice(0, 10));

        if (!loading) {
            setHasNoData(noData);
        }
    };

    if (!loading) {
        config.chart.options.series[0].data = sortedData;
    }

    if (hasNoData) {
        return (
            <StyledBarChart isLoading={loading}>
                <Message title={messageTitle} copy={messageCopy} type={messageType} />
            </StyledBarChart>
        );
    }

    return (
        <StyledBarChart isLoading={loading}>
            <CommonHighcharts
                highcharts={Highcharts}
                options={{ ...config.chart.options }}
                containerProps={{
                    style: {
                        height: '100 %',
                    },
                }}
            />
        </StyledBarChart>
    );
};

export default BarChart;
