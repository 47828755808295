import React from 'react';
import styled from 'styled-components';
import { useConfigTableContext } from '../../context/config-table-context';
import { QueryStatus } from '@tanstack/react-query';

const StyledCellHeader = styled.th<{ textAlign?: 'left' | 'center' | 'right'; status: QueryStatus; disabled: boolean }>`
    padding: 12px;
    text-align: ${props => props.textAlign};
    font-weight: 600;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    border-bottom: 1px solid ${props => props.theme.colours.borderGrey};
    color: ${props =>
        props.status === 'pending' || props.status === 'error' || props.disabled
            ? props.theme.colours.borderGrey
            : props.theme.colours.textDefault};
`;

type CellHeaderProps = {
    textAlign?: 'left' | 'center' | 'right';
    children?: React.ReactNode;
};

const CellHeader = ({ textAlign = 'left', children }: CellHeaderProps) => {
    const { status, tableDisabled } = useConfigTableContext();

    if (status) {
        return (
            <StyledCellHeader textAlign={textAlign} status={status} disabled={tableDisabled}>
                {children}
            </StyledCellHeader>
        );
    }
    return null;
};

export default CellHeader;
