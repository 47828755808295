const viewportOptions = [
    {
        label: 'Extra Small',
        value: 'xs',
        disabled: false,
    },
    {
        label: 'Small',
        value: 'sm',
        disabled: false,
    },
    {
        label: 'Medium',
        value: 'md',
        disabled: false,
    },
    {
        label: 'Large',
        value: 'lg',
        disabled: false,
    },
    {
        label: 'Extra Large',
        value: 'xl',
        disabled: false,
    },
];

export default viewportOptions;
