import { comparisonRequestError } from '../../../helpers/errors';
import { getMultiRequestStatus } from '../../../helpers/get-multi-request-status';
import { ResourceComparisonData } from '../../../types';
import useResource, { UseResourceArgs } from '../use-resource';

type useResourceVennResourceArgs = {
    dimensionResource: UseResourceArgs;
    vennResource: UseResourceArgs;
};

const useResourceVennResource = ({
    dimensionResource,
    vennResource,
}: useResourceVennResourceArgs): ResourceComparisonData => {
    // Fetch data
    const dimensionData = useResource(dimensionResource);
    const vennData = useResource(vennResource);

    // Get request status
    const status = getMultiRequestStatus({ requiredRequests: [dimensionData], requests: [vennData] });

    if (status === 'loading' || status === 'empty') {
        return {
            type: 'venn',
            status: status,
        };
    }

    if (status === 'error') {
        return {
            type: 'venn',
            status: status,
            error: {
                message: comparisonRequestError(dimensionData, vennData),
            },
        };
    }

    return {
        type: 'venn',
        status: status,
        resources: [
            {
                ...dimensionData,
                type: 'vennResourceDimension',
            },
            {
                ...vennData,
                type: 'vennResourceData',
            },
        ],
    };
};

export default useResourceVennResource;
