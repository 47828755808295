import React from 'react';
import styled from 'styled-components';

const StyledListItemCellContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const LibraryListCellFlex = ({ children }: { children: React.ReactNode }) => {
    return <StyledListItemCellContainer>{children}</StyledListItemCellContainer>;
};

export default LibraryListCellFlex;
