import React from 'react';
import styled from 'styled-components';

// Component
import DataTableBodyRow from '../rows/data-table-body-row';

// Types
import { CubedField } from '../../../../types';
import { DataTableColumn, ReportConfig, DataTableSelectedRows, DataTableDataObject, DataTableData } from '../../types';

// Styles
const StyledTableBody = styled.tbody<{ loading: boolean }>`
    background-color: ${props => props.loading && props.theme.colours.selectionGrey};
    opacity: ${props => (props.loading ? 0.5 : 1)};
    transition: 0.2s ease-in-out;
`;

// Types
type DataTableBodyProps = {
    handleDimensionClick: () => void;
    dimensions: CubedField[];
    loading: boolean;
    selectedRows: DataTableSelectedRows[] | [];
    columns: DataTableColumn[];
    rows: DataTableDataObject[];
    setOwnerState: (key: string, value: DataTableSelectedRows) => void;
    disableRowClick: (columns: CubedField[]) => {};
    config: ReportConfig;
    dataMeta: DataTableData;
    scrollPosX: number;
};

const DataTableBody = ({
    handleDimensionClick,
    dimensions,
    loading,
    selectedRows,
    columns,
    rows,
    setOwnerState,
    disableRowClick,
    config,
    dataMeta,
    scrollPosX,
}: DataTableBodyProps) => {
    return (
        <StyledTableBody loading={loading}>
            {rows.map((row, index) => (
                <DataTableBodyRow
                    key={row.id}
                    loading={loading}
                    handleDimensionClick={handleDimensionClick}
                    dimensions={dimensions}
                    selectedRows={selectedRows}
                    columns={columns}
                    rowId={index}
                    data={row}
                    setOwnerState={setOwnerState}
                    disableRowClick={disableRowClick}
                    config={config}
                    dataMeta={dataMeta}
                    scrollPosX={scrollPosX}
                />
            ))}
        </StyledTableBody>
    );
};

export default DataTableBody;
