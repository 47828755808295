import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Types
export type CheckboxItemProps = {
    isDisabled?: boolean;
    checked: boolean;
};

// Styles
const StyledCheckboxHolder = styled.div`
    width: 18px;
    position: relative;
`;

const StyledCheckbox = styled('div')<CheckboxItemProps>`
    padding: 0 2px;
    height: 18px;
    position: absolute;
    opacity: 1;
    transition: 0.2s ease-in-out;
    border-radius: ${props => (props.checked ? '20px' : '3px')};
    left: 0;
    right: 0;

    cursor: ${props => props.isDisabled && 'not-allowed'};
    background-color: ${props => (props.isDisabled ? '#e1e1e1' : props.checked ? '#000' : '#afafaf')};
`;

const StyledCheckboxTickSVG = styled('svg')<CheckboxItemProps>`
    left: 2px;
    position: relative;
    opacity: ${props => (!props.isDisabled && props.checked ? '1' : '0')};
    top: ${props => (!props.isDisabled && props.checked ? '-1px' : '-16px')};
    -webkit-transform: ${props => (!props.isDisabled && props.checked ? 'scale(1)' : 'scale(7)')};
    transform: ${props => (!props.isDisabled && props.checked ? 'scale(1)' : 'scale(7)')};
    transition: ${props => (!props.isDisabled && props.checked ? '0.2s ease-in' : '0.4s ease-out')};
    visibility: ${props => (!props.isDisabled && props.checked ? 'visible' : 'hidden')};
`;

const CheckboxItem = ({ isDisabled, checked }: CheckboxItemProps) => {
    return (
        <StyledCheckboxHolder>
            <StyledCheckbox isDisabled={isDisabled} checked={checked}>
                <div>
                    <StyledCheckboxTickSVG
                        isDisabled={isDisabled}
                        checked={checked}
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="10px"
                        height="10px"
                        viewBox="0 0 45.701 45.7"
                        fill="#EFEFEF"
                    >
                        <g>
                            <g>
                                <path
                                    d="M20.687,38.332c-2.072,2.072-5.434,2.072-7.505,0L1.554,26.704c-2.072-2.071-2.072-5.433,0-7.504
										c2.071-2.072,5.433-2.072,7.505,0l6.928,6.927c0.523,0.522,1.372,0.522,1.896,0L36.642,7.368c2.071-2.072,5.433-2.072,7.505,0
										c0.995,0.995,1.554,2.345,1.554,3.752c0,1.407-0.559,2.757-1.554,3.752L20.687,38.332z"
                                />
                            </g>
                        </g>
                    </StyledCheckboxTickSVG>
                </div>
            </StyledCheckbox>
        </StyledCheckboxHolder>
    );
};

export default memo(CheckboxItem, isEqual);
