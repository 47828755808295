import { QueryStatus } from '@tanstack/react-query';
import React from 'react';
import styled from 'styled-components';
import { useConfigTableContext } from '../context/config-table-context';
import WidgetBaseLoader from '../../../../section-dashboard/widgets/base/widget-base-loader';
import Message from '../../../message';

const StyledContainer = styled.div<{ maxHeight?: string; status: QueryStatus; empty: boolean }>`
    max-height: ${props => props.maxHeight};
    overflow: ${props => (props.status !== 'success' || props.empty ? 'hidden' : 'auto')};
`;

const StyledTable = styled.table`
    width: 100%;
    background-color: #ffffff;
    border-collapse: collapse;
`;

const StyledStatusContainer = styled.div<{ maxHeight?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    background-color: #ffffff;
    width: 100%;
    min-height: 200px;
    height: ${props => (props.maxHeight ? props.maxHeight : '100%')};
`;

const StyledMessageContainer = styled.div`
    max-width: 500px;
`;

type TableProps = {
    maxHeight?: string;
    emptyMessage?: string;
    children: React.ReactNode;
};

const Table = ({ maxHeight = '100%', emptyMessage = 'No data available.', children }: TableProps) => {
    const { status, empty } = useConfigTableContext();

    return (
        <StyledContainer maxHeight={maxHeight} status={status} empty={empty}>
            <StyledTable>{children}</StyledTable>

            {status === 'pending' ? (
                <StyledStatusContainer maxHeight={maxHeight}>
                    <div>
                        <WidgetBaseLoader width={40} height={40} margin={0} />
                    </div>
                </StyledStatusContainer>
            ) : null}

            {status === 'error' ? (
                <StyledStatusContainer maxHeight={maxHeight}>
                    <StyledMessageContainer>
                        <Message
                            type="error"
                            size="small"
                            copy="There was an error retrieving your data. Please try again later or contact support@cubed.email"
                        />
                    </StyledMessageContainer>
                </StyledStatusContainer>
            ) : null}

            {empty ? (
                <StyledStatusContainer maxHeight={maxHeight}>
                    <StyledMessageContainer>
                        <Message type="info" size="small" copy={emptyMessage} />
                    </StyledMessageContainer>
                </StyledStatusContainer>
            ) : null}
        </StyledContainer>
    );
};

export default Table;
