import React from 'react';
import styled from 'styled-components';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

const StyledDialogOverlay = styled(AlertDialog.Overlay)`
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;

const StyledDialogContent = styled(AlertDialog.Content)<{ width?: string; height?: string; $backgroundColor?: string }>`
    background-color: ${props => props.$backgroundColor || props.theme.dialog.content.backgroundColor};
    border-radius: 6px;
    box-shadow: ${props => props.theme.dialog.content.shadow};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width || ''};
    height: ${props => props.height || ''};
    max-width: ${props => props.width || '500px'};
    max-height: ${props => props.height || '85vh'};
    padding: 25px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 51; // Overlap the sidebar
    overflow-y: auto;
`;

const DialogContent = ({
    width,
    height,
    backgroundColor,
    children,
}: {
    width?: string;
    height?: string;
    backgroundColor?: string;
    children: React.ReactNode;
}) => {
    return (
        <AlertDialog.Portal>
            <StyledDialogOverlay />
            <StyledDialogContent width={width} height={height} $backgroundColor={backgroundColor}>
                {children}
            </StyledDialogContent>
        </AlertDialog.Portal>
    );
};

export default DialogContent;
