import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

// ACTIONS
import { removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';

// TYPES/ENUMS
import { DropdownOption } from '../../types';
import { RootState } from '../../redux/reducers/core';
import { ButtonThemes } from '../../enums/button-themes';
import { ConfigDataSuccess } from '../../react-query/types';
import { NotificationMessageType } from '../../enums/notification-types';

// COMPONENTS
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import ModalNavigation from '../../components/modal-navigation';
import ConfigTable from '../../components/tables/config-table/config-table';
import WidgetAccordion from '../../widgets/accordion';

// RESOURCES
import {
    CONFIG_CHANNELS,
    CONFIG_ACCOUNT_USER,
    CONFIG_AUTO_EMAIL_PAGE,
    CONFIG_AUTO_EMAIL_CONFIG,
    CONFIG_AUTO_EMAIL_RECIPIENTS,
    CONFIG_AUTO_EMAIL_CONFIG_CHANNEL,
    CONFIG_AUTO_EMAIL_CONFIG_EMAIL_RECIPIENTS,
} from '../../configurations/resources-config';

// HELPERS
import generateQueryKey from '../../react-query/helpers/generate-query-key';
import { generatePath } from '../../helpers/request-builder';

// HOOKS
import usePostResource from '../../react-query/hooks/use-post-resource';
import usePatchResource from '../../react-query/hooks/use-patch-resource';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';
import usePostMultiResources from '../../react-query/hooks/use-post-multi-resources';

type AutoEmailPage = {
    id: number;
    name: string;
};

type PageConfig = {
    id: number;
    comparison: string;
    frequency: string;
    interval: string;
    comparisonInterval: string;
    autoEmailPage: AutoEmailPage;
    selected: boolean;
};

type PageConfigChannel = {
    pageConfigId: string;
    channelName: string;
    pageConfigChannelId: number;
};

const StyledContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`;

const StyledSubContainer = styled.div`
    margin-top: 20px;
`;

const LayoutAutoEmailConfigModal = () => {
    const dispatch = useDispatch();
    const frequencyOptions = [
        { name: 'WEEKLY', value: 'WEEKLY', label: 'Weekly' },
        { name: 'FORTNIGHTLY', value: 'FORTNIGHTLY', label: 'Fortnightly' },
        { name: 'MONTHLY', value: 'MONTHLY', label: 'Monthly' },
    ];

    const intervalOptions = [
        { name: 'WEEK', value: 'WEEK', label: 'Week' },
        { name: 'FORTNIGHT', value: 'FORTNIGHT', label: 'Fortnight' },
        { name: 'MONTH', value: 'MONTH', label: 'Month' },
        { name: 'QUARTER', value: 'QUARTER', label: 'Quarter' },
    ];
    const channelDropDownEnabledPages = ['Channel Detail Dashboard', 'Channel Detail Dashboard Comparison'];

    const userEmail = useSelector((state: RootState) => state.user.email);
    const [pageConfigs, setPageConfigs] = useState<PageConfig[]>([]);
    const [pageConfigsToDelete, setPageConfigsToDelete] = useState<number[]>([]);
    const [nonExistingPages, setNonExistingPages] = useState<DropdownOption[]>([]);
    const [comparisonPages, setComparisonPages] = useState<string[]>([]);
    const [channelOptions, setChannelOptions] = useState<DropdownOption[]>([]);

    // Page states
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [disableTable, setDisableTable] = useState(false);

    const [pageConfigToEdit, setPageConfigToEdit] = useState<PageConfig>();
    const [pageConfigIdToCreateRecipients, setPageConfigIdToCreateRecipients] = useState<string>();
    const [pageConfigChannelToEdit, setPageConfigChannelToEdit] = useState<PageConfigChannel>();

    const [pageConfigEditPatchData, setPageConfigEditPatchData] = useState({
        auto_email_page: '',
        frequency: '',
        interval: '',
        comparison_interval: '',
    });
    const [pageConfigChannelPatchData, setPageConfigChannelPatchData] = useState({
        auto_email_config: '',
        channel: '',
    });
    const [selectedPageChannel, setSelectedPageChannel] = useState('');
    const [addPageConfigAccordionOpen, setAddPageConfigAccordionOpen] = useState(false);
    const [editPageConfigAccordionOpen, setEditPageConfigAccordionOpen] = useState(false);
    const [pageConfigChannelPostData, setPageConfigChannelPostData] = useState({
        auto_email_config: '',
        channel: '',
    });
    const [pageConfigChannelDeleteId, setPageConfigChannelDeleteId] = useState<number>();

    const [emailRecipientOptions, setEmailRecipientOptions] = useState<DropdownOption[]>([]);
    const [userEmailOptions, setUserEmailOptions] = useState<string[]>([]);
    const [selectedEmailListsBeforeEdit, setSelectedEmailListsBeforeEdit] = useState<number[]>([]);
    const [selectedEmailLists, setSelectedEmailLists] = useState<DropdownOption[]>([]);
    const [deletedEmailRecipients, setDeletedEmailRecipients] = useState<number[]>([]);
    const [addedEmailRecipients, setAddedEmailRecipients] = useState<number[]>([]);

    // add page config form states
    const [pageConfigFormElements, setPageConfigFormElements] = useState({
        page: '',
        frequency: '',
        interval: '',
        comparisonInterval: '',
        channel: '',
        isComparisonIntervalHidden: false,
        isChannelHidden: true,
    });
    const [pageConfigPostData, setPageConfigPostData] = useState({
        auto_email_page: '',
        frequency: '',
        interval: '',
        comparison_interval: '',
    });
    const [cancelCreatePageConfigDisabled, setCancelCreatePageConfigDisabled] = useState(false);
    const [cancelEditPageConfigDisabled, setCancelEditPageConfigDisabled] = useState(false);
    const [isUpdatingPageConfig, setIsUpdatingPageConfig] = useState(false);

    const [missingEmails, setMissingEmails] = useState<string[]>([]);

    const channelQuery = useFetchResource({
        resource: CONFIG_CHANNELS,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(channel => {
                return {
                    id: channel.id,
                    name: channel.name,
                    colour: channel.colour,
                    selected: false,
                };
            });
        },
    });

    const usersQuery = useFetchResource({
        resource: CONFIG_ACCOUNT_USER,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(accountUser => {
                return {
                    id: accountUser.user.id,
                    email: accountUser.user.email,
                    selected: false,
                };
            });
        },
    });

    const pagesConfigQuery = useFetchResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG,
        params: [],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(page => {
                return {
                    id: page.id,
                    comparison: page.auto_email_page.comparison,
                    frequency: page.frequency,
                    interval: page.interval,
                    comparisonInterval: page.comparison_interval,
                    selected: false,
                    autoEmailPage: page.auto_email_page,
                };
            });
        },
        staleTime: 0,
    });

    const reportPagesQuery = useFetchResource({
        resource: CONFIG_AUTO_EMAIL_PAGE,
        params: [],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(pageConfig => {
                return {
                    id: pageConfig.id,
                    name: pageConfig.name,
                    comparisonPage: pageConfig.comparison,
                };
            });
        },
    });

    const pageConfigChannelsQuery = useFetchResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG_CHANNEL,
        params: [],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(reportConfigChannel => {
                return {
                    pageConfigId: reportConfigChannel.auto_email_config.id,
                    channelName: reportConfigChannel.channel.name,
                    pageConfigChannelId: reportConfigChannel.id,
                };
            });
        },
    });

    const pageConfigPostMutation = usePostResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG,
        data: pageConfigPostData,
        handleOnSuccess: async responseData => {
            setPageConfigIdToCreateRecipients(responseData.id.toString());
            setAddedEmailRecipients(selectedEmailLists.map(emailRecipient => Number(emailRecipient.value)));
            if (pageConfigFormElements.isChannelHidden) {
                dispatch(
                    addNotification({
                        copy: 'Page Config added successfully. Please add email recipients to the page config.',
                        type: NotificationMessageType.Success,
                    })
                );
                invalidateQueries();
            } else {
                setPageConfigChannelPostData({
                    auto_email_config: generatePath(
                        CONFIG_AUTO_EMAIL_CONFIG.category,
                        CONFIG_AUTO_EMAIL_CONFIG.id,
                        responseData.id.toString()
                    ),
                    channel: generatePath(
                        CONFIG_CHANNELS.category,
                        CONFIG_CHANNELS.id,
                        pageConfigFormElements.channel.toString()
                    ),
                });
            }
            invalidateQueries();
            setSelectedEmailLists([]);
            setSelectedEmailListsBeforeEdit([]);
            resetPageConfigFormElements();
        },
        handleOnError: () => {
            dispatch(addNotification({ copy: 'Page Config could not be added.', type: NotificationMessageType.Error }));
        },
    });

    const emailRecipientsQuery = useFetchResource({
        resource: CONFIG_AUTO_EMAIL_RECIPIENTS,
        params: [],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(emailRecipient => {
                return {
                    id: emailRecipient.id,
                    email: emailRecipient.user_email,
                };
            });
        },
        staleTime: 0,
    });

    const missingEmailsPostMutation = usePostMultiResources({
        resource: CONFIG_AUTO_EMAIL_RECIPIENTS,
        data: missingEmails.map(email => ({ user_email: email })),
        handleOnSuccess: () => {
            invalidateQueries();
            setMissingEmails([]);
            setDisableTable(false);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'Email Recipients could not be added.',
                    type: NotificationMessageType.Error,
                })
            );
            setIsError(true);
        },
    });
    const pageConfigEmailRecipientsQuery = useFetchResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG_EMAIL_RECIPIENTS,
        params: [],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(emailRecipient => {
                return {
                    id: emailRecipient.id,
                    autoEmailConfigId: emailRecipient.auto_email_config.id,
                    emailRecipientId: emailRecipient.auto_email_recipient.id,
                };
            });
        },
        staleTime: 0,
    });
    const pageConfigEmailRecipientsPostMutation = usePostMultiResources({
        resource: CONFIG_AUTO_EMAIL_CONFIG_EMAIL_RECIPIENTS,
        data: addedEmailRecipients.map(emailRecipient => ({
            auto_email_config: generatePath(
                CONFIG_AUTO_EMAIL_CONFIG.category,
                CONFIG_AUTO_EMAIL_CONFIG.id,
                pageConfigIdToCreateRecipients || ''
            ),
            auto_email_recipient: generatePath(
                CONFIG_AUTO_EMAIL_RECIPIENTS.category,
                CONFIG_AUTO_EMAIL_RECIPIENTS.id,
                emailRecipient.toString()
            ),
        })),
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'Email Recipients added successfully.',
                    type: NotificationMessageType.Success,
                })
            );
            invalidateQueries();
            setAddedEmailRecipients([]);
            setSelectedEmailListsBeforeEdit([]);
            setDisableTable(false);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'Email Recipients could not be added.',
                    type: NotificationMessageType.Error,
                })
            );
            setIsError(true);
        },
    });
    const pageConfigEmailRecipientsDeleteMutation = useDeleteResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG_EMAIL_RECIPIENTS,
        resourceIds: deletedEmailRecipients.map(emailRecipientId => emailRecipientId.toString()),
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'Email Recipients deleted successfully.',
                    type: NotificationMessageType.Success,
                })
            );
            invalidateQueries();
            setDeletedEmailRecipients([]);
            setDisableTable(false);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'Email Recipients could not be deleted.',
                    type: NotificationMessageType.Error,
                })
            );
            setIsError(true);
        },
    });

    const pageConfigChannelPostMutation = usePostResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG_CHANNEL,
        data: pageConfigChannelPostData,
        handleOnSuccess: () => {
            setPageConfigChannelPostData({
                auto_email_config: '',
                channel: '',
            });
            dispatch(
                addNotification({ copy: 'Page Config added successfully.', type: NotificationMessageType.Success })
            );
            resetPageConfigFormElements();
        },
        handleOnError: () => {
            dispatch(addNotification({ copy: 'Page Config could not be added.', type: NotificationMessageType.Error }));
            setIsError(true);
        },
    });

    const pageConfigPatchMutation = usePatchResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG,
        resourceId: pageConfigToEdit?.id.toString() || '',
        data: pageConfigEditPatchData,
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'Page Config updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
            invalidateQueries();
            setEditPageConfigAccordionOpen(false);
            setDisableTable(false);
            resetPageConfigFormElements();
            setPageConfigToEdit(undefined);
            setPageConfigIdToCreateRecipients(undefined);
            setPageConfigEditPatchData({
                auto_email_page: '',
                frequency: '',
                interval: '',
                comparison_interval: '',
            });
            setCancelEditPageConfigDisabled(false);
            setIsUpdatingPageConfig(false);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'Page Config could not be updated.',
                    type: NotificationMessageType.Error,
                })
            );
            setIsUpdatingPageConfig(false);
            setCancelEditPageConfigDisabled(false);
            setIsError(true);
        },
    });

    const pageConfigChannelPatchMutation = usePatchResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG_CHANNEL,
        resourceId: pageConfigChannelToEdit?.pageConfigChannelId.toString() || '',
        data: pageConfigChannelPatchData,
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'Channel updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
            invalidateQueries();
            setEditPageConfigAccordionOpen(false);
            setDisableTable(false);
            resetPageConfigFormElements();
            setPageConfigToEdit(undefined);
            setPageConfigChannelToEdit(undefined);
            setPageConfigChannelPatchData({
                auto_email_config: '',
                channel: '',
            });
            setCancelEditPageConfigDisabled(false);
            setIsUpdatingPageConfig(false);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'Channel could not be updated.',
                    type: NotificationMessageType.Error,
                })
            );
            setIsUpdatingPageConfig(false);
            setCancelEditPageConfigDisabled(false);
            setIsError(true);
        },
    });

    const pageConfigChannelDeleteMutation = useDeleteResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG_CHANNEL,
        resourceIds: [pageConfigChannelDeleteId?.toString() || ''],
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'Channel deleted successfully.',
                    type: NotificationMessageType.Success,
                })
            );
            invalidateQueries();
            setPageConfigChannelDeleteId(undefined);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'Channel could not be deleted.',
                    type: NotificationMessageType.Error,
                })
            );
            setIsError(true);
        },
    });

    const pageConfigsDeleteMutation = useDeleteResource({
        resource: CONFIG_AUTO_EMAIL_CONFIG,
        resourceIds: pageConfigsToDelete.map(pageConfigId => pageConfigId.toString()),
        handleOnSuccess: () => {
            setDisableTable(false);
            setPageConfigsToDelete([]);
            dispatch(
                addNotification({
                    copy: 'Page Configs deleted successfully.',
                    type: NotificationMessageType.Success,
                })
            );
            invalidateQueries();
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting the Page Configs. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
            setIsError(true);
        },
    });

    const queryClient = useQueryClient();

    const invalidateQueries = () => {
        const pageConfigsQueryKey = generateQueryKey({ resource: CONFIG_AUTO_EMAIL_CONFIG });
        queryClient.invalidateQueries({ queryKey: pageConfigsQueryKey });

        const pageConfigEmailRecipientsQueryKey = generateQueryKey({
            resource: CONFIG_AUTO_EMAIL_CONFIG_EMAIL_RECIPIENTS,
        });
        queryClient.invalidateQueries({ queryKey: pageConfigEmailRecipientsQueryKey });

        const channelQueryKey = generateQueryKey({ resource: CONFIG_AUTO_EMAIL_CONFIG_CHANNEL });
        queryClient.invalidateQueries({ queryKey: channelQueryKey });

        const emailRecipientsQueryKey = generateQueryKey({ resource: CONFIG_AUTO_EMAIL_RECIPIENTS });
        queryClient.invalidateQueries({ queryKey: emailRecipientsQueryKey });
    };

    const resetPageConfigFormElements = () => {
        setPageConfigFormElements({
            page: '',
            frequency: '',
            interval: '',
            comparisonInterval: '',
            isComparisonIntervalHidden: false,
            isChannelHidden: true,
            channel: '',
        });
    };
    useEffect(() => {
        if (pageConfigPostMutation.isSuccess) {
            setAddPageConfigAccordionOpen(false);
            setDisableTable(false);
            resetPageConfigFormElements();
        }
    }, [pageConfigPostMutation.isSuccess]);

    useEffect(() => {
        const loading =
            channelQuery.isLoading ||
            usersQuery.isLoading ||
            reportPagesQuery.isLoading ||
            pagesConfigQuery.isLoading ||
            emailRecipientsQuery.isLoading;
        if (!loading) {
            setIsLoading(loading);
            setDisableTable(false);
        }
    }, [
        channelQuery.isLoading,
        usersQuery.isLoading,
        reportPagesQuery.isLoading,
        pagesConfigQuery.isLoading,
        emailRecipientsQuery.isLoading,
    ]); // eslint-disable-line

    useEffect(() => {
        let userEmailDropDownOptions = [];
        if (usersQuery.data && usersQuery.data.length > 0) {
            userEmailDropDownOptions = usersQuery.data?.map(user => user.email);
            userEmailDropDownOptions.push(userEmail);
            setUserEmailOptions(userEmailDropDownOptions);
        }
    }, [usersQuery.data]); // eslint-disable-line

    useEffect(() => {
        if (userEmailOptions.length === 0 && userEmail) {
            setUserEmailOptions([userEmail]);
        }
    }, [userEmail]); // eslint-disable-line

    useEffect(() => {
        let nonExistingPagesTemp = reportPagesQuery.data?.filter(
            page => !pagesConfigQuery.data?.map(pageConfig => pageConfig.autoEmailPage.name).includes(page.name)
        );

        if (nonExistingPagesTemp)
            setNonExistingPages(
                nonExistingPagesTemp.map(page => ({
                    name: page.name,
                    value: page.id,
                    label: page.name,
                })) as DropdownOption[]
            );
        else setNonExistingPages([]);

        if (pagesConfigQuery.data) {
            setPageConfigs(pagesConfigQuery.data);
        }

        let comparisonPages = reportPagesQuery.data
            ?.filter(pageConfig => pageConfig.comparisonPage)
            .map(pageConfig => pageConfig.name);
        setComparisonPages(comparisonPages || []);
    }, [reportPagesQuery.data, pagesConfigQuery.data, channelQuery.data]); // eslint-disable-line

    useEffect(() => {
        if (pagesConfigQuery.data) {
            setPageConfigs(pagesConfigQuery.data);
        }
    }, [pagesConfigQuery.data]);

    useEffect(() => {
        setChannelOptions(
            channelQuery.data?.map(channel => ({
                name: channel.name,
                value: channel.id,
                label: channel.name,
            })) as DropdownOption[]
        );
    }, [channelQuery.data]);

    useEffect(() => {
        if (emailRecipientsQuery.data) {
            setEmailRecipientOptions(
                emailRecipientsQuery.data.map(emailRecipient => ({
                    name: emailRecipient.email,
                    value: emailRecipient.id,
                    label: emailRecipient.email,
                })) as DropdownOption[]
            );
        }
    }, [emailRecipientsQuery.data]);

    useEffect(() => {
        const userEmailsToAdd = [];
        if (emailRecipientsQuery.data?.filter(recipient => recipient.email === userEmail).length === 0) {
            userEmailsToAdd.push(userEmail);
        }
        if (emailRecipientsQuery.isSuccess && usersQuery.data && usersQuery.data.length > 0) {
            for (let i = 0; i < usersQuery.data.length; i++) {
                if (
                    emailRecipientsQuery.data?.filter(
                        emailRecipient => emailRecipient.email === usersQuery.data[i].email
                    ).length === 0
                ) {
                    userEmailsToAdd.push(usersQuery.data[i].email);
                }
            }
        }
        if (userEmailsToAdd.length > 0) {
            setMissingEmails(userEmailsToAdd);
        }
    }, [emailRecipientsQuery.data, usersQuery.data]); // eslint-disable-line

    useEffect(() => {
        if (!channelQuery.data || !pagesConfigQuery.data) return;
    }, [channelQuery.data, pagesConfigQuery.data]);

    useEffect(() => {
        if (
            pageConfigPostMutation.isSuccess &&
            pageConfigChannelPostData.auto_email_config &&
            pageConfigChannelPostData.channel
        ) {
            pageConfigChannelPostMutation.mutate();
        }
    }, [pageConfigChannelPostData, pageConfigPostMutation.isSuccess]); // eslint-disable-line

    useEffect(() => {
        if (missingEmails.length > 0) {
            missingEmailsPostMutation.mutate();
        }
    }, [missingEmails]); // eslint-disable-line
    useEffect(() => {
        if (addedEmailRecipients.length > 0 && pageConfigIdToCreateRecipients) {
            pageConfigEmailRecipientsPostMutation.mutate();
        }
    }, [addedEmailRecipients, pageConfigIdToCreateRecipients]); // eslint-disable-line

    useEffect(() => {
        if (deletedEmailRecipients.length > 0) {
            pageConfigEmailRecipientsDeleteMutation.mutate();
        }
    }, [deletedEmailRecipients]); // eslint-disable-line

    useEffect(() => {
        if (
            pageConfigEditPatchData.comparison_interval &&
            pageConfigEditPatchData.auto_email_page &&
            pageConfigEditPatchData.frequency &&
            pageConfigEditPatchData.interval
        ) {
            pageConfigPatchMutation.mutate();
        }
    }, [pageConfigEditPatchData]); // eslint-disable-line

    useEffect(() => {
        if (pageConfigChannelPatchData.auto_email_config !== '' && pageConfigChannelPatchData.channel !== '') {
            pageConfigChannelPatchMutation.mutate();
        }
    }, [pageConfigChannelPatchData]); // eslint-disable-line

    useEffect(() => {
        if (pageConfigChannelDeleteId) {
            pageConfigChannelDeleteMutation.mutate();
        }
    }, [pageConfigChannelDeleteId]); // eslint-disable-line

    useEffect(() => {
        if (pageConfigsToDelete.length > 0) {
            pageConfigsDeleteMutation.mutate();
        }
    }, [pageConfigsToDelete]); // eslint-disable-line

    useEffect(() => {
        if (pageConfigToEdit) {
            setPageConfigIdToCreateRecipients(pageConfigToEdit.id.toString());
        }
    }, [pageConfigToEdit]); // eslint-disable-line

    const Error = () => {
        return (
            <WarningMessage
                copy={
                    'There was a server issue getting this page ready. Please try again later or contact support@cubed.com.'
                }
            />
        );
    };

    const handleCheckboxChange = (rowId: number) => {
        setPageConfigs(
            pageConfigs.map(pageConfig => {
                if (pageConfig.id === rowId) {
                    return { ...pageConfig, selected: !pageConfig.selected };
                }
                return pageConfig;
            })
        );
    };

    const handleSinglePageConfigDeleteClick = (rowId: number) => {
        setDisableTable(true);
        setPageConfigsToDelete([rowId]);
    };

    const handleEditPageConfigClick = (rowId: number) => {
        let pageConfigMatching = pageConfigs.find(pageConfig => pageConfig.id === rowId);

        if (!pageConfigMatching) return;

        const existingEmailRecipients =
            pageConfigEmailRecipientsQuery.data
                ?.filter(emailRecipient => emailRecipient.autoEmailConfigId === rowId)
                .map(emailRecipient => emailRecipient.emailRecipientId) || [];

        setSelectedEmailListsBeforeEdit(existingEmailRecipients);
        setSelectedEmailLists(
            existingEmailRecipients.map(
                emailRecipient =>
                    emailRecipientOptions.find(
                        option => option.value.toString() === emailRecipient.toString()
                    ) as DropdownOption
            )
        );

        setPageConfigToEdit(pageConfigMatching);
        setPageConfigIdToCreateRecipients(pageConfigMatching.id.toString());
        const channelOfPage = pageConfigChannelsQuery.data?.find(
            pageConfigChannel => pageConfigChannel.pageConfigId === pageConfigMatching?.id
        );
        setPageConfigChannelToEdit(channelOfPage);
        setSelectedPageChannel(channelOfPage?.channelName || '');
        setPageConfigFormElements({
            page: pageConfigMatching.autoEmailPage.name,
            frequency: pageConfigMatching.frequency,
            interval: pageConfigMatching.interval,
            comparisonInterval: pageConfigMatching.comparison
                ? pageConfigMatching.comparisonInterval
                : 'NOT APPLICABLE',
            channel: channelDropDownEnabledPages.includes(pageConfigMatching.autoEmailPage.name)
                ? channelOptions.find(channel => channel.name === channelOfPage?.channelName)?.value.toString() || ''
                : '',
            isComparisonIntervalHidden: !pageConfigMatching.comparison,
            isChannelHidden: !channelDropDownEnabledPages.includes(pageConfigMatching.autoEmailPage.name),
        });

        setEditPageConfigAccordionOpen(true);
        setAddPageConfigAccordionOpen(false);
        setDisableTable(true);
    };

    const handleBulkDeletePageConfigClick = () => {
        const handleDiscardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setPageConfigsToDelete(
                pageConfigs.filter(pageConfig => pageConfig.selected).map(pageConfig => pageConfig.id)
            );
            setDisableTable(true);
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected page configs?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDiscardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleCreateNewPageConfig = () => {
        setEditPageConfigAccordionOpen(false);
        setAddPageConfigAccordionOpen(true);
        setDisableTable(true);

        if (emailRecipientOptions.length === 1) setSelectedEmailLists(emailRecipientOptions);

        setPageConfigFormElements({
            page: nonExistingPages[0].value.toString() || '',
            frequency: frequencyOptions[0].name || '',
            interval: intervalOptions[0].name || '',
            comparisonInterval: intervalOptions[0].name || '',
            isComparisonIntervalHidden: !comparisonPages.includes(nonExistingPages[0].name ?? ''),
            channel: channelOptions[0].value.toString(),
            isChannelHidden: !channelDropDownEnabledPages.includes(nonExistingPages[0].name ?? ''),
        });
    };

    const handleSelectPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedPage = nonExistingPages.find(page => page.value.toString() === event.target.value);

        const isComparisonIntervalHidden = !comparisonPages.includes(selectedPage?.name ?? '');

        setPageConfigFormElements(pageConfigFormElements => ({
            ...pageConfigFormElements,
            page: event.target.value,
            isComparisonIntervalHidden,
            isChannelHidden: !channelDropDownEnabledPages.includes(selectedPage?.name ?? ''),
            channel: !channelDropDownEnabledPages.includes(selectedPage?.name ?? '')
                ? ''
                : pageConfigFormElements.channel.toString(),
        }));
    };

    const handleSelectFrequency = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageConfigFormElements({
            ...pageConfigFormElements,
            frequency: event.target.value,
        });
    };

    const handleSelectInterval = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageConfigFormElements({
            ...pageConfigFormElements,
            interval: event.target.value,
        });
    };

    const handleSelectComparisonInterval = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageConfigFormElements({
            ...pageConfigFormElements,
            comparisonInterval: event.target.value,
        });
    };

    const handleSelectChannel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageConfigFormElements({
            ...pageConfigFormElements,
            channel: event.target.value,
        });
    };

    const handleEmailRecipientsOnChange = (selectedOptions: DropdownOption[]) => {
        setSelectedEmailLists(selectedOptions);
    };

    const handleAddPageConfigClick = async () => {
        const selectedPageResourceURI = generatePath(
            CONFIG_AUTO_EMAIL_PAGE.category,
            CONFIG_AUTO_EMAIL_PAGE.id,
            pageConfigFormElements.page
        );

        let comparisonInterval = pageConfigFormElements.comparisonInterval;
        if (reportPagesQuery?.data) {
            comparisonInterval = reportPagesQuery.data.find(page => page.id.toString() === pageConfigFormElements.page)
                ?.comparisonPage
                ? pageConfigFormElements.comparisonInterval
                : 'NOT APPLICABLE';
        }
        setPageConfigPostData(prevPageConfigPostData => ({
            ...prevPageConfigPostData,
            auto_email_page: selectedPageResourceURI,
            frequency: pageConfigFormElements.frequency,
            interval: pageConfigFormElements.interval,
            comparison_interval: comparisonInterval,
        }));
        setCancelCreatePageConfigDisabled(true);
        pageConfigPostMutation.mutate();
        setCancelCreatePageConfigDisabled(false);
    };

    const handleCancelAddPageConfigClick = () => {
        setAddPageConfigAccordionOpen(false);
        setDisableTable(false);
        resetPageConfigFormElements();
    };

    const handleCancelEditPageConfigClick = () => {
        setEditPageConfigAccordionOpen(false);
        setDisableTable(false);
        resetPageConfigFormElements();
        setPageConfigToEdit(undefined);
        setPageConfigIdToCreateRecipients(undefined);
        setPageConfigEditPatchData({
            auto_email_page: '',
            frequency: '',
            interval: '',
            comparison_interval: '',
        });
        setCancelEditPageConfigDisabled(false);
        setSelectedEmailListsBeforeEdit([]);
        setSelectedEmailLists([]);
    };

    const handleApplyEditPageConfigClick = () => {
        setIsUpdatingPageConfig(true);
        setCancelEditPageConfigDisabled(true);
        const emailAdded = selectedEmailLists
            .filter(email => !selectedEmailListsBeforeEdit.includes(Number(email.value)))
            .map(email => Number(email.value));
        const emailDeleted = selectedEmailListsBeforeEdit.filter(
            email => !selectedEmailLists.map(email => Number(email.value)).includes(email)
        );
        const deletedEmailRecipientsIds =
            pageConfigEmailRecipientsQuery.data
                ?.filter(
                    emailRecipient =>
                        emailDeleted.includes(emailRecipient.emailRecipientId) &&
                        emailRecipient.autoEmailConfigId === pageConfigToEdit?.id
                )
                .map(emailRecipient => emailRecipient.id) || [];

        if (emailAdded.length > 0) {
            setAddedEmailRecipients(emailAdded);
        }
        if (deletedEmailRecipientsIds.length > 0) {
            setDeletedEmailRecipients(deletedEmailRecipientsIds);
        }
        if (
            pageConfigToEdit?.frequency === pageConfigFormElements.frequency &&
            pageConfigToEdit?.interval === pageConfigFormElements.interval &&
            pageConfigToEdit?.comparisonInterval === pageConfigFormElements.comparisonInterval &&
            pageConfigToEdit?.autoEmailPage.name === pageConfigFormElements.page &&
            selectedPageChannel === pageConfigFormElements.channel &&
            emailAdded.length === 0 &&
            emailDeleted.length === 0
        ) {
            setIsUpdatingPageConfig(false);
            setEditPageConfigAccordionOpen(false);
            setDisableTable(false);
            resetPageConfigFormElements();
            setPageConfigToEdit(undefined);
            setPageConfigIdToCreateRecipients(undefined);
            setPageConfigEditPatchData({
                auto_email_page: '',
                frequency: '',
                interval: '',
                comparison_interval: '',
            });
            setSelectedEmailListsBeforeEdit([]);
            setSelectedEmailLists([]);
            setCancelEditPageConfigDisabled(false);
            return;
        }
        setPageConfigEditPatchData({
            auto_email_page: generatePath(
                CONFIG_AUTO_EMAIL_PAGE.category,
                CONFIG_AUTO_EMAIL_PAGE.id,
                pageConfigToEdit?.autoEmailPage.id.toString() || ''
            ),
            frequency: pageConfigFormElements.frequency,
            interval: pageConfigFormElements.interval,
            comparison_interval: pageConfigFormElements.comparisonInterval,
        });
        if (selectedPageChannel !== pageConfigFormElements.channel) {
            const selectedChannelId = channelOptions.find(
                channel => channel.value.toString() === pageConfigFormElements.channel
            );

            setPageConfigChannelPatchData({
                auto_email_config: generatePath(
                    CONFIG_AUTO_EMAIL_CONFIG.category,
                    CONFIG_AUTO_EMAIL_CONFIG.id,
                    pageConfigToEdit?.id.toString() || ''
                ),
                channel: generatePath(
                    CONFIG_CHANNELS.category,
                    CONFIG_CHANNELS.id,
                    selectedChannelId?.value.toString()
                ),
            });
        }
        setCancelEditPageConfigDisabled(true);
    };

    const HomePage = () => {
        const editPageConfigAccordion = [
            {
                header: 'Edit Page Config for Automated Email Reporting',
                required: false,
                open: true,
                type: 'form',
                config: {
                    copy: `Currently Editing:  ${pageConfigFormElements.page}`,
                    formConfig: {
                        fields: [
                            {
                                label: 'Frequency',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a frequency by which the email report is sent to you.',
                                inputOptions: frequencyOptions,
                                inputKeyValue: 'editPageConfig__frequency',
                                inputValue: pageConfigFormElements.frequency,
                                customPlaceholder: pageConfigFormElements.frequency,
                                errorMessage: '',
                                inputOnChange: handleSelectFrequency,
                            },
                            {
                                label: 'Interval',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose interval for the data to be selected.',
                                inputOptions: intervalOptions,
                                inputKeyValue: 'editPageConfig__interval',
                                inputValue: pageConfigFormElements.interval,
                                customPlaceholder: pageConfigFormElements.interval,
                                errorMessage: '',
                                inputOnChange: handleSelectInterval,
                            },
                            !pageConfigFormElements.isComparisonIntervalHidden && {
                                label: 'Comparison Interval',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a comparison interval for the data to be selected.',
                                inputOptions: intervalOptions,
                                inputKeyValue: 'editPageConfig__comparisonInterval',
                                inputValue: pageConfigFormElements.comparisonInterval,
                                customPlaceholder: pageConfigFormElements.comparisonInterval,
                                errorMessage: '',
                                inputOnChange: handleSelectComparisonInterval,
                            },
                            !pageConfigFormElements.isChannelHidden && {
                                label: 'Channel to Report',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a channel to report for the page.',
                                inputOptions: channelOptions,
                                inputKeyValue: 'editPageConfig__channel',
                                inputValue: pageConfigFormElements.channel,
                                customPlaceholder: channelOptions.find(
                                    channel => channel.name === pageConfigFormElements.channel
                                )?.name,
                                errorMessage: '',
                                inputOnChange: handleSelectChannel,
                            },
                            {
                                label: 'Email Recipients',
                                type: 'reactSelect',
                                placeholder: 'Select User Emails',
                                inputOptions: emailRecipientOptions,
                                toolTipCopy: 'Select email to send the report of the page.',
                                isMulti: true,
                                inputKeyValue: 'pageReport__emailRecipients',
                                inputValue: selectedEmailLists,
                                inputOnChange: handleEmailRecipientsOnChange,
                                errorMessage: '',
                                isClearable: true,
                                displaySingleOption: true,
                            },
                        ],
                        buttons: [
                            {
                                value: 'UPDATE',
                                onClick: handleApplyEditPageConfigClick,
                                isLoading: isUpdatingPageConfig,
                                disabled: selectedEmailLists.length === 0,
                            },
                            {
                                value: 'CANCEL',
                                onClick: handleCancelEditPageConfigClick,
                                theme: ButtonThemes.Secondary,
                                disabled: cancelEditPageConfigDisabled,
                            },
                        ],
                    },
                },
            },
        ];
        const addPageConfigAccordion = [
            {
                header: 'Add Page Config for Automated Email Reporting',
                required: false,
                open: true,
                type: 'form',
                config: {
                    copy: 'Add pages to add to your auto email reporting.',
                    formConfig: {
                        fields: [
                            {
                                label: 'Page To Report',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a Page that you want to include.',
                                inputOptions: nonExistingPages,
                                inputKeyValue: 'addPageConfig__page',
                                inputValue: pageConfigFormElements.page,
                                customPlaceholder: nonExistingPages[0]?.name,
                                errorMessage: '',
                                inputOnChange: handleSelectPage,
                            },
                            {
                                label: 'Frequency',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a frequency by which the email report is sent to you.',
                                inputOptions: frequencyOptions,
                                inputKeyValue: 'addPageConfig__frequency',
                                inputValue: pageConfigFormElements.frequency,
                                customPlaceholder: frequencyOptions[0].name,
                                errorMessage: '',
                                inputOnChange: handleSelectFrequency,
                            },
                            {
                                label: 'Interval',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose interval for the data to be selected.',
                                inputOptions: intervalOptions,
                                inputKeyValue: 'addPageConfig__interval',
                                inputValue: pageConfigFormElements.interval,
                                customPlaceholder: intervalOptions[0].name,
                                errorMessage: '',
                                inputOnChange: handleSelectInterval,
                            },
                            !pageConfigFormElements.isComparisonIntervalHidden && {
                                label: 'Comparison Interval',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a comparison interval for the data to be selected.',
                                inputOptions: intervalOptions,
                                inputKeyValue: 'addPageConfig__comparisonInterval',
                                inputValue: pageConfigFormElements.comparisonInterval,
                                customPlaceholder: intervalOptions[0].name,
                                errorMessage: '',
                                inputOnChange: handleSelectComparisonInterval,
                            },
                            !pageConfigFormElements.isChannelHidden && {
                                label: 'Channel to Report',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a channel to report for the page.',
                                inputOptions: channelOptions,
                                inputKeyValue: 'addPageConfig__channel',
                                inputValue: pageConfigFormElements.channel,
                                customPlaceholder: channelOptions[0].name,
                                errorMessage: '',
                                inputOnChange: handleSelectChannel,
                            },
                            {
                                label: 'Email Recipients',
                                type: 'reactSelect',
                                placeholder: 'Select User Emails',
                                inputOptions: emailRecipientOptions,
                                toolTipCopy: 'Select email to send the report of the page.',
                                isMulti: true,
                                inputKeyValue: 'pageReport__emailRecipients',
                                inputValue: selectedEmailLists,
                                inputOnChange: handleEmailRecipientsOnChange,
                                errorMessage: '',
                                isClearable: true,
                                displaySingleOption: true,
                            },
                        ],
                        buttons: [
                            {
                                value: 'ADD',
                                onClick: handleAddPageConfigClick,
                                isLoading: pageConfigPostMutation.isPending,
                                disabled: selectedEmailLists.length === 0,
                            },
                            {
                                value: 'CANCEL',
                                onClick: handleCancelAddPageConfigClick,
                                theme: ButtonThemes.Secondary,
                                disabled: cancelCreatePageConfigDisabled,
                            },
                        ],
                    },
                },
            },
        ];
        return (
            <>
                <p>
                    Configure automated email reporting for pages. The pages report that is sent to your email can be
                    configured here. The selection of pages can be configured by clicking on the CREATE NEW PAGE CONFIG.
                    The list of email recipients can also be selected in the multi select dropdown after the click.
                </p>
                <p>
                    The page configs can be edited/deleted by clicking on the vertical ellipsis and they can be deleted
                    in bulk by selecting the page configs and clicking on DELETE SELECTED PAGE CONFIGS button. For
                    Channel Detail Dashboard(and comparison) page you are also provided an option to select a channel.
                </p>
                <ConfigTable
                    status={reportPagesQuery.status}
                    isFetching={reportPagesQuery.isFetching}
                    disabled={disableTable}
                    empty={pageConfigs.length === 0}
                >
                    <ConfigTable.Table maxHeight="400px">
                        <ConfigTable.Header>
                            <ConfigTable.Row key="thead">
                                <ConfigTable.CellHeader />
                                <ConfigTable.CellHeader>Page</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Frequency</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Interval</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Comparison Interval</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Channel</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader />
                            </ConfigTable.Row>
                        </ConfigTable.Header>
                        <ConfigTable.Body>
                            {pageConfigs.map(pageConfig => {
                                const channelForPage = channelDropDownEnabledPages.includes(
                                    pageConfig.autoEmailPage.name
                                )
                                    ? pageConfigChannelsQuery.data?.find(
                                          pageConfigChannel =>
                                              pageConfigChannel.pageConfigId.toString() === pageConfig.id.toString()
                                      )?.channelName
                                    : 'NOT APPLICABLE';
                                return (
                                    <ConfigTable.Row key={pageConfig.id}>
                                        <ConfigTable.CellCheckbox
                                            rowId={pageConfig.id}
                                            checked={pageConfig.selected}
                                            onCheckedChange={handleCheckboxChange}
                                        />
                                        <ConfigTable.Cell>{pageConfig.autoEmailPage.name}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{pageConfig.frequency}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{pageConfig.interval}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{pageConfig.comparisonInterval}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{channelForPage}</ConfigTable.Cell>
                                        <ConfigTable.CellActions>
                                            <ConfigTable.ActionDropdownItem
                                                rowId={pageConfig.id}
                                                type="edit"
                                                onClick={handleEditPageConfigClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={pageConfig.id}
                                                type="delete"
                                                onClick={handleSinglePageConfigDeleteClick}
                                            />
                                        </ConfigTable.CellActions>
                                    </ConfigTable.Row>
                                );
                            })}
                        </ConfigTable.Body>
                    </ConfigTable.Table>
                    <ConfigTable.ActionBar>
                        <ConfigTable.ActionBarJustifyRight>
                            <ConfigTable.ActionButton
                                type="delete"
                                label="Delete Selected Page Configs"
                                onClick={handleBulkDeletePageConfigClick}
                                isDisabled={pageConfigs.filter(pageConfig => pageConfig.selected).length === 0}
                            />
                        </ConfigTable.ActionBarJustifyRight>

                        {nonExistingPages.length > 0 && (
                            <ConfigTable.ActionBarJustifyRight>
                                <ConfigTable.ActionButton
                                    type="add"
                                    label="Create New Page Config"
                                    onClick={handleCreateNewPageConfig}
                                    isDisabled={disableTable}
                                />
                            </ConfigTable.ActionBarJustifyRight>
                        )}
                    </ConfigTable.ActionBar>
                </ConfigTable>
                {editPageConfigAccordionOpen && (
                    <StyledSubContainer>
                        <WidgetAccordion accordions={editPageConfigAccordion} />
                    </StyledSubContainer>
                )}
                {addPageConfigAccordionOpen && (
                    <StyledSubContainer>
                        <WidgetAccordion accordions={addPageConfigAccordion} />
                    </StyledSubContainer>
                )}
            </>
        );
    };

    const PageLayout = ({ children }: { children: React.ReactNode }) => {
        const dispatch = useDispatch();

        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: () => dispatch(removeModal()),
                disabled: false,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return (
            <>
                <ModalNavigation buttons={modalNavigationButtons} />
                <h2>Manage Automated Email Reporting</h2>

                {children}
            </>
        );
    };

    return (
        <PageLayout>
            <StyledContainer>{isLoading ? <LoadingSpinner /> : isError ? <Error /> : <HomePage />}</StyledContainer>
        </PageLayout>
    );
};

export default LayoutAutoEmailConfigModal;
