type TableData = {
    [key: string]: string | number | boolean;
};

export const TableFilter = (data: TableData[], searchValue: string, keyToFilter?: (keyof TableData)[]) => {
    if (searchValue === '') {
        return data;
    }

    const filteredData = data.filter(rule => {
        return Object.keys(rule).some(key => {
            if (keyToFilter && !keyToFilter.includes(key as keyof TableData)) {
                return false;
            }

            return rule[key as keyof TableData].toString().toLowerCase().includes(searchValue.toLowerCase());
        });
    });

    return filteredData;
};
