import DataTypes from '../../filter-bar/enums/data-types';
import { TableFields } from '../../types';

export const CREATED: TableFields = {
    id: 'created',
    rawName: 'created',
    displayName: 'Seen At',
    defaultOrderDir: 'desc',
    isDimension: true,
    sortBy: true,
    sort: 'desc',
    dataType: DataTypes.DATE,
};

export const FIRST_VISIT: TableFields = {
    id: 'first_visit',
    rawName: 'first_visit',
    displayName: 'Visit First Seen At',
    defaultOrderDir: 'desc',
    isDimension: false,
    sortBy: true,
    sort: 'desc',
    dataType: DataTypes.DATE,
    metricWidth: '',
};

export const IMPRESSION_FIRST_VISIT: TableFields = {
    id: 'impression_first_visit',
    rawName: 'impression_visit__first_visit',
    displayName: 'Visit First Seen At',
    defaultOrderDir: 'desc',
    isDimension: false,
    sortBy: true,
    sort: 'desc',
    dataType: DataTypes.DATE,
    metricWidth: '',
};

export const LAST_VISIT: TableFields = {
    id: 'last_visit',
    rawName: 'last_visit',
    displayName: 'Visit Last Seen At',
    defaultOrderDir: 'desc',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.DATE,
    metricWidth: '',
};

export const EVENT_NAME: TableFields = {
    id: 'event_name',
    rawName: 'event__display_name',
    displayName: 'Event Name',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const TRANSACTION_ID: TableFields = {
    id: 'transaction_id',
    rawName: 'transaction_id',
    displayName: 'Transaction ID',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.NUMBER,
    metricWidth: '',
};

export const REVENUE: TableFields = {
    id: 'revenue',
    rawName: 'revenue',
    displayName: 'Revenue',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.NUMBER,
    metricWidth: '',
};

export const VALIDATION_NAME: TableFields = {
    id: 'validation_name',
    rawName: 'messages__name',
    displayName: 'Name',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const VALIDATION_ERRORED_NAME: TableFields = {
    id: 'validation_errored_name',
    rawName: 'messages__errored_name',
    displayName: 'Errored Name',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
};

export const VALIDATION_MESSAGE: TableFields = {
    id: 'validation_message',
    rawName: 'messages__message',
    displayName: 'Message',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const VALIDATION_VALUE: TableFields = {
    id: 'validation_value',
    rawName: 'messages__value',
    displayName: 'Value',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const MEDIUM: TableFields = {
    id: 'medium',
    rawName: 'medium',
    displayName: 'Medium',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const SOURCE: TableFields = {
    id: 'source',
    rawName: 'source',
    displayName: 'Source',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const CAMPAIGN: TableFields = {
    id: 'campaign',
    rawName: 'campaign',
    displayName: 'Campaign',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const CONTENT: TableFields = {
    id: 'content',
    rawName: 'content',
    displayName: 'Content',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const TERM: TableFields = {
    id: 'term',
    rawName: 'term',
    displayName: 'Term',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const PRODUCT: TableFields = {
    id: 'product',
    rawName: 'product',
    lookupTerm: 'name',
    displayName: 'Goal Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const VALIDATION_OS: TableFields = {
    id: 'validation_os',
    rawName: 'details__os_family',
    displayName: 'OS',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const VALIDATION_BROWSER: TableFields = {
    id: 'validation_browser',
    rawName: 'details__browser_family',
    displayName: 'Browser',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const VALIDATION_HOST: TableFields = {
    id: 'validation_host',
    rawName: 'pages__host',
    displayName: 'Host',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const VALIDATION_PATH: TableFields = {
    id: 'validation_path',
    rawName: 'pages__path',
    displayName: 'Path',
    isDimension: false,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};
