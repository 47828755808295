// React Dependencies
import React from 'react';
import IconFile from '../icons/file';
import InputFileUpload from '../inputs/input-file-upload';
import Tooltip from '../tooltip';
import styled from 'styled-components';

const StyledFormField = styled.div<{ error: boolean }>`
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 15px;
    border-color: ${props => props.error && props.theme.colours.red};
`;

const StyledLabel = styled.label`
    font-size: 1em;
    font-weight: 400;
`;

const StyledRequiredField = styled.span`
    color: ${props => props.theme.colours.red};
`;

const StyledErrorMessage = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colours.red};
    margin: 5px 0px;
`;

const StyledFilename = styled.div`
    display: inline-block;
    position: relative;
    top: 5px;
`;

const StyledIcon = styled.div`
    display: inline-block;
    margin: 10px 5px 0px 10px;

    & svg {
        height: 20px;
    }
`;

export type FormFileUploadProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    errorMessage?: string;
    isValid?: boolean;
    uploadedFile?: string;
    inputKeyValue: string;
    requiredField?: boolean;
    toolTipCopy?: string;
};

const FormFileUpload = ({
    onChange,
    label,
    errorMessage,
    isValid,
    uploadedFile,
    inputKeyValue,
    requiredField = true,
    toolTipCopy,
}: FormFileUploadProps) => {
    return (
        <StyledFormField key={inputKeyValue} error={!!errorMessage}>
            <div>
                <StyledLabel>
                    {label} {label && requiredField && <StyledRequiredField>*</StyledRequiredField>}
                </StyledLabel>
                {toolTipCopy && <Tooltip copy={toolTipCopy} />}
            </div>
            <InputFileUpload onChange={onChange} />
            {isValid && uploadedFile && (
                <StyledFilename>
                    <StyledIcon>
                        <IconFile />
                    </StyledIcon>
                    {uploadedFile}
                </StyledFilename>
            )}
            {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        </StyledFormField>
    );
};

export default FormFileUpload;
