import React, { useState } from 'react';
import styled from 'styled-components';

// Styles
const StyledBreadcrumbSearchWrapper = styled.div`
    position: relative;
    box-sizing: border-box;
    float: right;
    flex: 1;
`;

const StyledBreadCrumbDiv = styled.div<{ backgroundColor: boolean }>`
    background-color: ${props =>
        props.backgroundColor ? props.theme.colours.offBlackLighter : props.theme.colours.cubedOrange};
    padding: 5px;
    height: 100%;
    margin-left: -10px;
`;

const StyledBreadcrumbsItemLabel = styled.div`
    height: 11px;
    line-height: 11px;
    position: relative;
    padding-left: 24px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 2px;
    text-transform: uppercase;
    color: #0a0a0a;
`;

const StyledBreadCrumbInput = styled.input`
    width: calc(100% - 25px);
    margin-left: 20px;
    height: 26px;
    background-color: transparent;
    color: ${props => props.theme.colours.textLight};
    border: none;
    box-shadow: none;
    &::placeholder {
        color: ${props => props.theme.colours.light};
        font-weight: 400;
    }
    &:focus {
        outline: blue;
    }
`;

// Types
type BreadcrumbSearchProps = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    placeHolderOverride?: string;
};

const BreadcrumbSearch = ({ onChange, value, placeHolderOverride }: BreadcrumbSearchProps) => {
    const [readOnly, setReadOnly] = useState(true);

    const handleMouseEnter = (event: React.MouseEvent) => {
        if (event.type === 'mouseenter') {
            setReadOnly(false);
        }
    };

    const handleMouseLeave = (event: React.MouseEvent) => {
        if (event.type === 'mouseleave') {
            setReadOnly(true);
        }
    };

    return (
        <StyledBreadcrumbSearchWrapper>
            <StyledBreadcrumbsItemLabel />
            <StyledBreadCrumbDiv backgroundColor={readOnly}>
                <StyledBreadCrumbInput
                    type="text"
                    placeholder={placeHolderOverride || 'Search...'}
                    value={value}
                    onChange={onChange}
                    readOnly={readOnly}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
            </StyledBreadCrumbDiv>
        </StyledBreadcrumbSearchWrapper>
    );
};

export default BreadcrumbSearch;
