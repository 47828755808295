import * as fields from './fields';
import * as resources from './resources';

export const config = {
    title: 'Single Customer View',
    resources: [resources.REPORT_SCV_VISITORS, resources.REPORT_SCV_TRANSACTIONS, resources.REPORT_SCV_CHANNEL],
    table: {
        tabListItems: [
            {
                tabName: 'visitors',
                linkedResourceKey: 0,
                displayName: 'Visitors',
                isSelected: true,
                metrics: [
                    fields.FIRST_VISIT,
                    fields.LAST_VISIT,
                    fields.HAS_SALE,
                    fields.HAS_SYNCED,
                    fields.HAS_CLIENT_CUSTOMER_ID,
                    fields.CLIENT_CUSTOMER_ID,
                    fields.LATEST_SALE_DATE,
                ],
            },
            {
                tabName: 'transactions',
                linkedResourceKey: 1,
                displayName: 'Transactions',
                isSelected: false,
                metrics: [fields.SALES_DATE, fields.REVENUE],
            },
            {
                tabName: 'channels',
                linkedResourceKey: 2,
                displayName: 'Channels',
                isSelected: false,
                metrics: [
                    fields.FIRST_VISIT,
                    fields.LAST_VISIT,
                    fields.HAS_SALE,
                    fields.HAS_SYNCED,
                    fields.HAS_CLIENT_CUSTOMER_ID,
                    fields.CLIENT_CUSTOMER_ID,
                    fields.LATEST_SALE_DATE,
                    fields.REFERER,
                ],
            },
        ],
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 0,
        maxButtons: 10,
        rowDefault: 25,
        currentRowCount: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
    saleTypes: [
        {
            name: 'Sale',
            value: 'sale',
            active: false,
        },
        {
            name: 'Non Sale',
            value: 'non-sale',
            active: false,
        },
        {
            name: 'All',
            value: 'all',
            active: true,
        },
    ],
    dateTypes: [
        {
            label: 'First Visit',
            value: 'first_visit',
            checked: false,
            hidden: false,
        },
        {
            label: 'Last Visit',
            value: 'last_visit',
            checked: true,
            hidden: false,
        },
        {
            label: 'Latest Sale Date',
            value: 'latest_sale_date',
            checked: false,
            hidden: true,
        },
        {
            label: 'Sales Date',
            value: 'sales_date',
            checked: false,
            hidden: true,
        },
    ],
};
