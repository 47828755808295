import React from 'react';
import styled, { css } from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useLayoutContext } from '../../context/layout-context';

// Types
import { DropdownOption } from '../../../../../types';
import { SelectOptionsRequest, RequestStatus, SectionDashboardLayout } from '../../../../types';

// Icons
import IconChevronUp from '../../../../../components/icons/chevron-up';
import IconCheck from '../../../../../components/icons/check';
import IconChevronLeftThin from '../../../../../components/icons/chevron-left-thin';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.textColor};

    & button {
        all: inherit;
        cursor: 'pointer';
    }
`;

const StyledLabel = styled.div`
    font-size: 0.9rem;
    font-weight: ${props => props.theme.sectionDashboard.section.sectionMenu.select.labelFontWeight};
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.labelColor};
    text-transform: uppercase;
`;

const StyledTriggerContent = styled.div<{ empty?: boolean }>`
    border: 2px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    border-radius: 5px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    min-width: 150px;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: ${props => (props.empty ? 'auto' : 'pointer')};
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: ${props => props.theme.sectionDashboard.widget.menu.triggerBackgroundColor};
`;

const StyledSubTriggerBase = css`
    height: 18px;
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 20px 20px;
    outline: none;
    font-weight: 500;
`;

const StyledSubTrigger = styled(DropdownMenu.SubTrigger)`
    ${StyledSubTriggerBase}
    cursor: pointer;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
    }
`;

const StyledSubTriggerEmpty = styled.div`
    ${StyledSubTriggerBase}
    cursor: not-allowed;
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.textColorEmpty};
`;

const StyledOptionLabel = styled.div<{ empty?: boolean }>`
    font-size: 1rem;
    padding: 5px 10px;
    color: ${props => props.empty && props.theme.sectionDashboard.section.sectionMenu.select.optionLabelColorEmpty};
`;

const StyledIconContainer = styled.div`
    padding: 0px 10px;
    height: 100%;
    border-left: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
`;

const StyledIconChevronSmall = styled(IconChevronUp)<{ empty?: boolean }>`
    transform: rotate(180deg);
    height: 14px;
    margin-top: 8px;
    width: auto;
    & path {
        fill: ${props =>
            props.empty
                ? props.theme.sectionDashboard.widget.menu.separatorColor
                : props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledIconChevronLeftThin = styled(IconChevronLeftThin)`
    height: 18px;
    width: auto;
`;

const StyledContentBase = css`
    background-color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.backgroundColor};
    min-width: 210px;
    padding: 10px 0px;
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.menu.shadow};
    max-height: 50vh;
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.textColor};
    overflow-y: auto;
`;

const StyledContent = styled(DropdownMenu.Content)<{ layout: SectionDashboardLayout }>`
    ${StyledContentBase}
    z-index: 21; // Overlap the top bar
    max-width: ${props =>
        props.layout === SectionDashboardLayout.SM || props.layout === SectionDashboardLayout.XS ? '300px' : '70vw'};
`;

const StyledSubContent = styled(DropdownMenu.SubContent)<{ layout: SectionDashboardLayout }>`
    ${StyledContentBase}
    z-index: 51; // Overlap the sidebar
    max-width: ${props =>
        (props.layout === SectionDashboardLayout.SM || props.layout === SectionDashboardLayout.XS) && '300px'};
`;

const StyledDropdownItem = styled(DropdownMenu.Item)`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 10px 15px;
    cursor: pointer;
    word-break: break-all;

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
        border: none;
        outline: none;
    }
`;

const StyledIconCheck = styled(IconCheck)<{ visible: boolean }>`
    width: 18px;
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const StyledLoadingContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledDropdownSkeleton = styled.div<{
    responsive: boolean;
    viewportSize: SectionDashboardLayout;
    smallViewports: SectionDashboardLayout[];
}>`
    height: 19px;
    width: ${props => (props.responsive && props.smallViewports.includes(props.viewportSize) ? '20px' : '150px')};
    background: ${props => props.theme.sectionDashboard.section.sectionMenu.select.skeletonColor};
    border-radius: ${props => (props.responsive && props.smallViewports.includes(props.viewportSize) ? '10px' : '5px')};
`;

export type WidgetMenuSectionSelectProps = {
    label: string;
    dropdownOptions: SelectOptionsRequest;
    selectedOption?: DropdownOption;
    onOptionChange: (option: string) => void;
    emptyMessage: string;
    customIcon?: React.ReactNode;
    responsive?: boolean;
    menuContentAlign?: 'start' | 'end';
};

const WidgetMenuSectionSelect = ({
    label,
    dropdownOptions,
    selectedOption,
    onOptionChange,
    emptyMessage,
    responsive = true,
    menuContentAlign = 'end',
}: WidgetMenuSectionSelectProps) => {
    let { layout } = useLayoutContext();

    const largeViewports = [SectionDashboardLayout.XL, SectionDashboardLayout.LG];
    const smallViewports = [SectionDashboardLayout.MD, SectionDashboardLayout.SM, SectionDashboardLayout.XS];

    const renderTrigger = ({ isEmpty }: { isEmpty: boolean }) => {
        return (
            <>
                {(layout === SectionDashboardLayout.XL || !responsive) && <StyledLabel>{label}:</StyledLabel>}
                {(!responsive || largeViewports.includes(layout)) && (
                    <DropdownMenu.Trigger>
                        <StyledTriggerContent empty={isEmpty}>
                            <StyledOptionLabel empty={isEmpty}>
                                {selectedOption ? selectedOption.label : emptyMessage}
                            </StyledOptionLabel>
                            <StyledIconContainer>
                                <StyledIconChevronSmall empty={isEmpty} />
                            </StyledIconContainer>
                        </StyledTriggerContent>
                    </DropdownMenu.Trigger>
                )}
            </>
        );
    };

    if (dropdownOptions.status === RequestStatus.LOADING) {
        return (
            <StyledLoadingContainer>
                {(!responsive || layout === SectionDashboardLayout.XL) && <StyledLabel>{label}:</StyledLabel>}
                <StyledDropdownSkeleton responsive={responsive} viewportSize={layout} smallViewports={smallViewports} />
            </StyledLoadingContainer>
        );
    }

    if (dropdownOptions.status === RequestStatus.EMPTY || dropdownOptions.status === RequestStatus.ERROR) {
        if (responsive && smallViewports.includes(layout)) {
            return (
                <StyledSubTriggerEmpty>
                    <StyledIconChevronLeftThin />
                    {label}
                </StyledSubTriggerEmpty>
            );
        }
        return (
            <StyledLoadingContainer>
                <StyledContainer>
                    <DropdownMenu.Root>{renderTrigger({ isEmpty: true })}</DropdownMenu.Root>
                </StyledContainer>
            </StyledLoadingContainer>
        );
    }

    if (dropdownOptions.status === RequestStatus.SUCCESS && selectedOption) {
        if (responsive && smallViewports.includes(layout)) {
            return (
                <DropdownMenu.Sub>
                    <StyledSubTrigger>
                        <StyledIconChevronLeftThin />
                        {label}
                    </StyledSubTrigger>
                    <DropdownMenu.Portal>
                        <StyledSubContent layout={layout}>
                            {dropdownOptions.options.map(option => {
                                return (
                                    <StyledDropdownItem onClick={() => onOptionChange(option.value)} key={option.value}>
                                        <StyledIconCheck visible={option.value === selectedOption.value} />
                                        {option.label}
                                    </StyledDropdownItem>
                                );
                            })}
                        </StyledSubContent>
                    </DropdownMenu.Portal>
                </DropdownMenu.Sub>
            );
        }

        return (
            <StyledContainer>
                <DropdownMenu.Root>
                    {renderTrigger({ isEmpty: false })}

                    <DropdownMenu.Portal>
                        <StyledContent side="bottom" align={menuContentAlign} layout={layout}>
                            {dropdownOptions.options.map(option => {
                                return (
                                    <StyledDropdownItem onClick={() => onOptionChange(option.value)} key={option.value}>
                                        <StyledIconCheck visible={option.value === selectedOption.value} />
                                        {option.label}
                                    </StyledDropdownItem>
                                );
                            })}
                        </StyledContent>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            </StyledContainer>
        );
    }
    return null;
};

export default WidgetMenuSectionSelect;
