import React from 'react';
import * as RadixPopover from '@radix-ui/react-popover';

// Popover Components
import PopoverTrigger from './popover-trigger';
import PopoverContent from './popover-content';
import PopoverClose from './popover-close';

const Popover = ({ children }: { children: React.ReactNode }) => {
    return <RadixPopover.Root>{children}</RadixPopover.Root>;
};

// Popover Components
Popover.Trigger = PopoverTrigger;
Popover.Content = PopoverContent;
Popover.Close = PopoverClose;

export default Popover;
