import React, { Component } from 'react';

import ListBuilder from '../inputs/list-builder/list-builder';

class AccordionListBuilder extends Component {
    render() {
        return (
            <div className="accordion__item-content__list-builder">
                <p className="accordion__item-content__list-builder__tooltip">{this.props.config.toolTip}</p>
                <ListBuilder
                    leftListTitle="Unassigned Events"
                    rightListTitle="Assigned Events"
                    leftList={this.props.config.leftList}
                    rightList={this.props.config.rightList}
                    dragOrdering={this.props.config.dragOrdering}
                />
            </div>
        );
    }
}

export default AccordionListBuilder;
