import React, { useState } from 'react';

// Core Dependencies
import styled from 'styled-components';

// Components
import BreadcrumbCurrent from '../components/breadcrumb-current';
import BreadcrumbItem from '../components/breadcrumb-item';
import BreadcrumbHome from '../components/breadcrumb-home';

// Helpers
import Request from '../../helpers/request';
import { errorHandling } from '../../helpers/request-error-handling';

// Types
import { CubedField } from '../../types';
import { BreadcrumbsWidgetProps, DimensionFilters, DimensionOptions } from '../types';

// Styles
const StyledBreadcrumbsContainer = styled.div`
    display: flex;
`;

const StyledBreadcrumbsWrapper = styled.div`
    text-transform: uppercase;
    margin: -12px 0 4px 22px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
`;

const BreadcrumbsWidget = ({
    resource,
    allResources,
    dimension,
    dateDimension,
    currentDropdownVisible,
    currentColourOverride,
    setResourceAndField,
    showCancel,
    homeClickHandler,
    setTableGroupBy,
    dimensionFilters,
    setDimensionFilters,
    startDate,
    endDate,
}: BreadcrumbsWidgetProps) => {
    const DIM_PROMISES: Request[] = [];

    const [dimensionOptions, setDimensionOptions] = useState<DimensionOptions[]>([]);
    const [dimsLoading, setDimsLoading] = useState<boolean>(false);

    const handleRemoveFilter = (field: CubedField) => {
        setDimensionFilters([...dimensionFilters].filter(filter => filter.field !== field));
    };

    const handleSetFilter = (field: CubedField, value: string, fuzzy: boolean) => {
        let exists = false;

        // if we are changing a filter that has children, we should reset/clear those out as they will not match anymore
        const newFilters: DimensionFilters[] = [];
        let found: boolean = false;
        let newCurrentField!: CubedField;
        dimensionFilters.every(filter => {
            if (filter.field.rawName === field.rawName) {
                // once we've found the current filter, do not add anymore
                // and set the filter value
                filter.value = value;
                filter.fuzzy = fuzzy;
                found = true;
                newFilters.push(filter);
                return true;
            }
            if (found) {
                // if we "found" on previous item, then clear the next filter and add it
                filter.value = '';
                newCurrentField = filter.field;

                // then ignore anything after
                return false;
            } else {
                // if we havent matched yet, keep adding filters
                newFilters.push(filter);
            }

            // keep going
            return true;
        });

        const currentFilters = [...dimensionFilters].map(filter => {
            if (filter.field.rawName === field.rawName) {
                filter.value = value;
                filter.fuzzy = fuzzy;
                exists = true;
            }
            return filter;
        });

        if (!exists) currentFilters.push({ field: field, value: value, fuzzy: fuzzy });

        setDimensionFilters([...newFilters]);

        if (newCurrentField) {
            setTableGroupBy([newCurrentField]);
        }
    };

    const handleFetchDimensionOptions = (field: CubedField, value: string) => {
        const fetchPromise = new Request();
        const dateParams = [];

        setDimensionOptions([]);
        setDimsLoading(true);

        let key = `${field.rawName}${field.lookupTerm ? '__' + field.lookupTerm : ''}`;

        DIM_PROMISES.forEach(promise => promise.cancelRequest('aborted'));
        DIM_PROMISES.push(fetchPromise);

        if (dateDimension) {
            dateParams.push({ key: `${dateDimension.rawName}__gte`, value: startDate });
            dateParams.push({ key: `${dateDimension.rawName}__lte`, value: endDate });
        }

        // when updating a breadcrumb item, use it's parents for context filtering
        let newFilters: { key: string; value: string | number | Date }[] = [];
        dimensionFilters.every((filter: DimensionFilters) => {
            if (field.rawName === filter.field.rawName) {
                return false;
            }
            let key = `${filter.field.rawName}${filter.field.lookupTerm ? '__' + filter.field.lookupTerm : ''}`;
            newFilters.push({ key: key, value: filter.value });
            return true;
        });

        const promiseData = [
            ...newFilters,
            { key: `${key}__icontains`, value: value },
            { key: 'group_by', value: field.rawName },
            ...dateParams,
        ] as unknown as { key: string; value: string | number }[];

        fetchPromise
            .get(resource.category, resource.id, promiseData)
            .then(data => {
                const newObjectArray = data.data.objects.map((data: DimensionOptions) => {
                    return {
                        ...data,
                        id: data.referer.value,
                    };
                });
                setDimensionOptions([...newObjectArray]);
                setDimsLoading(false);
            })
            .catch(error => {
                errorHandling(error);
                if (error.message !== 'aborted') {
                    console.log(error.message);
                } else {
                    setDimensionOptions([]);
                }
            });
    };

    const renderBreadcrumbItems = () => {
        return [...dimensionFilters].reverse().map((filter, index) => {
            return (
                <BreadcrumbItem
                    filter={filter}
                    dimsLoading={dimsLoading}
                    removeFilter={handleRemoveFilter}
                    fetchDimensionOptions={handleFetchDimensionOptions}
                    key={`${index}${filter.value}`}
                    setFilter={handleSetFilter}
                    dimensionOptions={dimensionOptions}
                    showCancel={showCancel}
                />
            );
        });
    };

    const renderBreadcrumb = (
        <StyledBreadcrumbsWrapper>
            <BreadcrumbCurrent
                filters={dimensionFilters}
                resource={resource}
                removeFilter={handleRemoveFilter}
                setResourceAndField={setResourceAndField}
                allResources={allResources}
                dimension={dimension}
                dropdownVisible={currentDropdownVisible}
                colourOverride={currentColourOverride}
            />
            {renderBreadcrumbItems()}
            <BreadcrumbHome onClick={homeClickHandler} />
        </StyledBreadcrumbsWrapper>
    );

    return <StyledBreadcrumbsContainer>{renderBreadcrumb}</StyledBreadcrumbsContainer>;
};

export default BreadcrumbsWidget;
