import { DefaultTheme } from 'styled-components';

export const columnBarGraphConfig = (theme: DefaultTheme) => {
    return {
        chart: {
            type: 'column',
            style: {
                fontFamily: 'Work Sans',
            },
            reflow: true,
            backgroundColor: 'transparent',
        },
        title: {
            text: null,
        },
        xAxis: {
            lineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.3)',
            crosshair: true,
            title: {
                text: null,
            },
            labels: {
                style: {
                    whitespace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: 11,
                },
            },
        },
        yAxis: {
            title: {
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                },
            },
            min: 0,
            labels: {
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: 11,
                },
            },
            gridLineColor: theme.name === 'dark' && 'rgba(255, 255, 255, 0.2)',
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                colorByPoint: true,
            },
            series: {
                animation: false,
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };
};

export const barGraphConfig = (theme: DefaultTheme) => {
    return {
        chart: {
            type: 'bar',
            style: {
                fontFamily: 'Work Sans',
            },
            reflow: true,
            backgroundColor: 'transparent',
        },
        title: {
            text: null,
        },
        xAxis: {
            lineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.3)',
            title: {
                text: null,
            },
            labels: {
                style: {
                    whitespace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: 11,
                },
            },
            crosshair: true,
        },
        yAxis: {
            title: {
                text: null,
            },
            labels: {
                style: {
                    whitespace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: 11,
                },
            },
            gridLineColor: theme.name === 'dark' && 'rgba(255, 255, 255, 0.2)',
        },
        plotOptions: {
            bar: {
                colorByPoint: true,
                borderWidth: 0,
            },
            dataLabels: {
                enabled: true,
            },
            series: {
                animation: false,
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };
};
