import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import styled from 'styled-components';
import ToggleWithLabels from '../../components/inputs/toggle-with-labels';

type OxylabsResponse = {
    id: number;
    crawl_active: Boolean;
    spider_active: Boolean;
};

type OxylabsConfig = {
    id: number;
    crawlActive: Boolean;
    spiderActive: Boolean;
};

const StyledContainer = styled.div`
    background-color: rgb(255, 255, 255);
    padding: 22px 0px 15px 15px;
    margin-bottom: 10px;
    border-radius: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`;

const LayoutManageCrawling = () => {
    // eslint-disable-next-line
    const [closeButtonState, setCloseButtonState] = useState('close');
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState<boolean>(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageError, setPageError] = useState<boolean>(false);

    const [oxylabsConfig, setOxylabsConfig] = useState<OxylabsConfig[]>([]);
    const [oxylabsToggleChecked, setOxylabsToggleChecked] = useState<boolean>(false);
    const [spiderToggleChecked, setSpiderToggleChecked] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchOxylabsConfig();
    }, []);

    const onOxylabsToggle = () => {
        setOxylabsToggleChecked(oxylabsToggleChecked => !oxylabsToggleChecked);
    };

    const onSpiderToggle = () => {
        setSpiderToggleChecked(spiderToggleChecked => !spiderToggleChecked);
    };

    const checkToggleChange = () => {
        return oxylabsConfig[0].crawlActive !== oxylabsToggleChecked ||
            oxylabsConfig[0].spiderActive !== spiderToggleChecked
            ? true
            : false;
    };

    const fetchOxylabsConfig = () => {
        axios({
            method: 'GET',
            url: generateUrl('config', 'oxylabs-config', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const config = response.data.objects.map((data: OxylabsResponse) => {
                    return {
                        id: data.id,
                        crawlActive: data.crawl_active,
                        spiderActive: data.spider_active,
                    };
                });

                setOxylabsConfig(config);
                setOxylabsToggleChecked(config[0]?.crawlActive);
                setSpiderToggleChecked(config[0]?.spiderActive);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setPageError(true);
                errorHandling(error);
            });
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(removeModal());
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const onSaveChangesClick = () => {
        if (!checkToggleChange()) {
            setSaveChangesButtonDisabled(false);

            dispatch(
                setPopup({
                    title: 'No Changes Detected!',
                    iconType: 'informative',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without making any changes?',
                    },
                    buttons: [
                        {
                            value: 'CLOSE PANEL',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'CONTINUE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        } else {
            setSaveChangesButtonLoading(true);

            axios({
                method: 'PATCH',
                url: generateUrlDetail('config', 'oxylabs-config', String(oxylabsConfig[0].id))!,
                data: {
                    crawl_active: oxylabsToggleChecked,
                    spider_active: spiderToggleChecked,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    dispatch(
                        addNotification({
                            copy: 'These changes have been successfully saved.',
                            type: NotificationMessageType.Success,
                        })
                    );
                    setSaveChangesButtonLoading(false);
                    dispatch(removeModal());
                })
                .catch(error => {
                    dispatch(
                        addNotification({
                            copy: 'There was an issue trying to save your changes. Please try again later or contact Cubed Support.',
                            type: NotificationMessageType.Error,
                        })
                    );
                    setSaveChangesButtonLoading(false);
                });
        }
    };

    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: false,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Crawl or Spider</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Crawl or Spider</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Manage Crawl or Spider</h2>
            <p>
                By activating or deactivating following options we crawl the website twice per month, and spider once
                per month.
            </p>
            <StyledContainer>
                <ToggleWithLabels checked={oxylabsToggleChecked} onClick={onOxylabsToggle} singleLabel={'Crawl:'} />

                <ToggleWithLabels checked={spiderToggleChecked} onClick={onSpiderToggle} singleLabel={'Spider:'} />
            </StyledContainer>
        </div>
    );
};

export default LayoutManageCrawling;
