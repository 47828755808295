import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generatePath, generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isString } from '../../helpers/validator';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import { DropdownOption } from '../../types';
import moment from 'moment';
import Papa from 'papaparse';
import { RootState } from '../../redux/store';
import ProgressBar from '../../components/progress-bar';

type ErrorMessageObject = {
    sourceErrorMessage: string;
    selectChannelsErrorMessage: string;
};

type ChannelsResponse = {
    active: boolean;
    colour: number;
    created: string;
    id: number;
    name: string;
    resource_uri: string;
    updated: string;
};

type csvRow = {
    impression_date: string;
    impression_visitors: string;
    total_impressions: string;
};

const LayoutAddImpressionModelling = () => {
    const [closeButtonState, setCloseButtonState] = useState('close');
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState<boolean>(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState<boolean>(true);
    const [saveChangesButtonHidden, setSaveChangesButtonHidden] = useState<boolean>(false);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageError, setPageError] = useState<boolean>(false);

    const [selectedFileIsValid, setSelectedFileIsValid] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
    const [fileInputErrorMessage, setFileInputErrorMessage] = useState<string>('');
    const [showUploadProgress, setShowUploadProgress] = useState<boolean>(false);
    const [fileUploadId, setFileUploadId] = useState<number>(0);
    const [uploadProgress, setUploadProgress] = useState<string>('');
    const [uploadError, setUploadError] = useState<boolean>(false);
    const [returningMessage, setReturningMessage] = useState<string>('');

    const [channels, setChannels] = useState<DropdownOption[]>([]);
    const [channel, setChannel] = useState<string>('');
    const [source, setSource] = useState<string>('');

    const [errorMessageObject, setErrorMessageObject] = useState<ErrorMessageObject>({
        sourceErrorMessage: '',
        selectChannelsErrorMessage: '',
    });

    const dispatch = useDispatch();

    const account = useSelector((state: RootState) => state.account);
    const controller = new AbortController();

    const previousValue = useRef(fileUploadId);

    const csvFileHeaders = ['impression_date', 'impression_visitors', 'total_impressions'];

    useEffect(() => {
        fetchChannels();
        getUploadInProgress();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((previousValue.current !== fileUploadId, uploadProgress !== '100.00' && fileUploadId)) {
            fetchUploadProgress();
        }
    }, [uploadProgress]); // eslint-disable-line react-hooks/exhaustive-deps

    // eslint-disable-next-line
    useEffect(() => {
        setSaveChangesButtonDisabled(true);
        setCloseButtonState('close');
        if (source || channels) {
            setSaveChangesButtonDisabled(false);
            setErrorMessageObject({
                sourceErrorMessage: '',
                selectChannelsErrorMessage: '',
            });
            setCloseButtonState('cancel');
        }
    }, [source, channels]);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            sourceErrorMessage: '',
            selectChannelsErrorMessage: '',
            marketErrorMessage: '',
        };

        if (!isString(source) || source.length === 0) {
            hasFormError = true;
            errorMessageObj.sourceErrorMessage = 'Please enter valid brand name.';
        }

        if (!isString(channel) || channel.length === 0) {
            hasFormError = true;
            errorMessageObj.selectChannelsErrorMessage = 'Please enter valid domain name.';
        }

        if (hasFormError) {
            setSaveChangesButtonDisabled(true);
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const handleSource = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSource(event?.target?.value);
    };

    const handleChannels = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChannel(event?.target?.value);
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const fetchChannels = () => {
        axios({
            method: 'GET',
            url: generateUrl('config', 'referer', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const channels = response.data.objects.map((channel: ChannelsResponse) => {
                    return {
                        id: channel.id,
                        name: channel.name,
                        value: channel.id,
                    };
                });

                setChannels(channels);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setPageError(true);
                errorHandling(error);
            });
    };

    const getUploadInProgress = () => {
        axios({
            method: 'GET',
            signal: controller.signal,
            url: generateUrl('config', 'file-upload', [
                { key: 'account_id', value: account.id },
                { key: 'processing', value: 1 },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length > 0) {
                    setSaveChangesButtonHidden(true);
                    setShowUploadProgress(true);
                    setFileUploadId(response.data.objects[0].id);
                    setUploadProgress(response.data.objects[0].progress);
                    setReturningMessage('There is currently a file upload in progress for this account.');
                    setCloseButtonState('close');
                }
            })
            .catch(() => {
                setPageError(true);
            });
    };

    const validateHeadersCSVFile = (data: csvRow) => {
        // check for the correct columns in csv
        const fileHeaders = data;
        const fileHeadersSorted = Object.keys(fileHeaders)
            .sort()
            .map(header => {
                return header.toLowerCase();
            });
        return (
            csvFileHeaders.length === fileHeadersSorted.length &&
            csvFileHeaders.map((header: string, index: number) => {
                return header === fileHeadersSorted[index];
            })
        );
    };

    const validateDataCSVFile = (headerData: csvRow, data: csvRow[]) => {
        // check for the correct columns in csv
        const csvData = data;
        const fileHeaders = headerData;
        const fileHeadersSorted = Object.keys(fileHeaders)
            .sort()
            .map(header => {
                return header;
            });

        let invalidCols = [];
        fileHeadersSorted.map(header => {
            let checkError = true;
            csvData.forEach((data: csvRow) => {
                if (header === 'total_impressions') {
                    if (data[header] === '') {
                        if (checkError) {
                            invalidCols.push(header);
                            checkError = false;
                            return header;
                        }
                    }
                } else if (header === 'impression_visitors') {
                    if (data[header] === '') {
                        if (checkError) {
                            invalidCols.push(header);
                            checkError = false;
                            return header;
                        }
                    }
                } else if (header === 'impression_date') {
                    if (data[header] === '') {
                        let isDate = moment(data[header], 'YYYY-MM-DD hh:mm:ss', true).isValid();
                        if (checkError) {
                            if (!isDate) {
                                invalidCols.push(header);
                                checkError = false;
                                return header;
                            }
                        }
                    }
                }
            });
            return checkError;
        });
    };

    const uploadCSVFile = (file: File[] | null) => {
        const data = new FormData();
        data.append('upload_type', 'impression_modelling_csv');
        data.append('account_id', account.id);
        data.append('file', file![0]);
        data.append('referer_id', channel);
        data.append('source', source);

        const url: string | null = generateUrlDetail(account.token, 'upload', 'csv-importer', [], false);

        const fileUploadConfig: AxiosRequestConfig = {
            method: 'POST',
            url: url !== null ? url : undefined,
            data: data,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        axios(fileUploadConfig)
            .then(response => {
                if (response.status === 200) {
                    setSaveChangesButtonHidden(true);
                    setShowUploadProgress(true);
                    setFileUploadId(response.data.file_upload_id);
                    setUploadProgress('0.0');
                }
            })
            .catch(() => {
                setPageError(true);
            });
    };

    const fetchUploadProgress = () => {
        const getProgress = () => {
            axios({
                method: 'GET',
                signal: controller.signal,
                url: generateUrl('config', 'file-upload', [{ key: 'id', value: String(fileUploadId) }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.data.objects[0].error) {
                        setUploadError(true);
                        setCloseButtonState('close');
                    } else {
                        previousValue.current = response.data.objects[0].id;
                        setFileUploadId(response.data.objects[0].id);
                        setUploadProgress(response.data.objects[0].progress);
                        setCloseButtonState('close');
                    }
                })
                .catch(() => {
                    setPageError(true);
                });

            if (uploadProgress !== '100.00' && !uploadError) {
                setTimeout(getProgress, 2000);
            } else {
                setReturningMessage('');
            }
        };

        if (uploadProgress !== '100.00') {
            getProgress();
        }
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = event.target.files[0];

            const reader = new FileReader();
            let firstRowData: csvRow;
            let dataCsv: csvRow[];

            if (files?.name.split('.').at(-1) === 'csv' || 'xlsx' || 'xls') {
                try {
                    reader.readAsText(files);
                    reader.onload = fileEvent => {
                        const csvData: string = fileEvent.target!.result as string;
                        // read files headers with Papaparse
                        Papa.parse(csvData, {
                            skipEmptyLines: true,
                            header: true,
                            complete: result => {
                                firstRowData = result.data[0] as csvRow;
                                dataCsv = result.data as csvRow[];
                            },
                        });

                        if (firstRowData === undefined) {
                            setSelectedFileIsValid(false);
                            setSelectedFile(null);
                            setFileInputErrorMessage(
                                "Sorry, this file doesn't appear to have data. Please check it and try again."
                            );
                        } else {
                            // validate the files

                            if (validateHeadersCSVFile(firstRowData)) {
                                setSelectedFileIsValid(true);
                                setSelectedFile([files]);
                                setFileInputErrorMessage('');
                                validateDataCSVFile(firstRowData, dataCsv);
                            } else {
                                setSelectedFileIsValid(false);
                                setSelectedFile(null);
                                setFileInputErrorMessage(
                                    "Sorry, this files doesn't appear to be in the correct format. Please check it and try again."
                                );
                            }
                        }
                    };
                    reader.onerror = () => {
                        setSelectedFileIsValid(false);
                        setSelectedFile(null);
                        setFileInputErrorMessage(
                            'Sorry, there was a problem reading this files. Please check it and try again.'
                        );
                    };
                } catch (error) {
                    setSelectedFileIsValid(false);
                    setSelectedFile(null);
                    setFileInputErrorMessage(
                        'Sorry, there was a problem reading this files. Please check it and try again.'
                    );
                }
            } else {
                setFileInputErrorMessage('Please select appropriate files format to upload.');
            }
        }
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            handleNavigateManageImpressions();
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const saveImpressions = () => {
        return new Promise(async (resolve, reject) => {
            const payload = {
                source: source,
                active: 1,
            };

            try {
                const request: AxiosRequestConfig = {
                    method: 'POST',
                    url: generateUrl('config', 'impression_model'),
                    data: payload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const response = await axios(request);

                if ([200, 201, 202].includes(response.status)) {
                    const requestPayload = {
                        impression_model: generatePath('config', 'impression_model', String(response.data.id)),
                        referer: generatePath('config', 'referer', String(channel)),
                        active: 1,
                    };

                    const requestConfig: AxiosRequestConfig = {
                        method: 'POST',
                        url: generateUrl('config', 'impression-model-config'),
                        data: requestPayload,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    };
                    axios(requestConfig)
                        .then(success => {
                            resolve('Impressions added.');
                        })
                        .catch(error => {
                            throw new Error('Request not successful');
                        });
                } else {
                    throw new Error('Request not successful');
                }
            } catch (error) {
                errorHandling(error);
                reject('Error making a connection to API.');
            }
            resolve('Impressions added.');
        });
    };

    const handleNavigateManageImpressions = () => {
        dispatch(setModal('ManageImpressionModelling', {}));
    };

    const onSaveChangesClick = () => {
        // If there is an error reopen the goals details tab as the error will be there
        if (!formValidator() && selectedFile === null) {
            setFileInputErrorMessage('Please select appropriate file format to upload.');
            return;
        }

        setSaveChangesButtonLoading(true);
        setCloseButtonDisabled(true);

        saveImpressions()
            .then(() => {
                dispatch(
                    addNotification({
                        copy: 'Impressions added successfully and uploading will begin shortly.',
                        type: NotificationMessageType.Success,
                    })
                );

                uploadCSVFile(selectedFile);
                setCloseButtonState('close');
                setSaveChangesButtonLoading(false);
                setCloseButtonDisabled(false);
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue adding Impressions.',
                        type: NotificationMessageType.Error,
                    })
                );

                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
            });
    };

    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE & UPLOAD',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
                hidden: saveChangesButtonHidden,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const renderAccordion = () => {
        const accordions = [
            {
                header: '',
                required: false,
                open: true,
                type: 'form',
                intro: '',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Source:',
                                type: 'text',
                                inputPlaceholder: 'Enter source name',
                                requiredField: true,
                                toolTipCopy:
                                    'The name of the impression source data (i.e. Display, VOD, Social Impressions, Facebook, TikTok etc...)',
                                inputKeyValue: 'source',
                                inputValue: source,
                                inputOnChange: handleSource,
                                errorMessage: errorMessageObject.sourceErrorMessage,
                            },
                            {
                                label: 'Channel:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a Channel associated with Impression modelling.',
                                inputKeyValue: 'market',
                                inputOptions: channels,
                                inputOnChange: handleChannels,
                                errorMessage: errorMessageObject.selectChannelsErrorMessage,
                            },
                            {
                                label: 'CSV File:',
                                type: 'file-upload',
                                requiredField: true,
                                inputKeyValue: 'add-csv_file',
                                inputOnChange: onFileInputChange,
                                isValid: selectedFileIsValid,
                                uploadedFile: selectedFile && selectedFile[0].name,
                                errorMessage: fileInputErrorMessage,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Add Impression Configuration</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Add Impression Configuration</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    if (showUploadProgress) {
        const progressCopy = `File upload ${uploadProgress === '100.00' ? 'complete!' : 'in progress...'}`;
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                {renderModalNavigation()}
                <h2>Set Fixed Cost</h2>
                <div className="file-upload-progress">
                    {returningMessage && <p>{returningMessage}</p>}
                    <p>{progressCopy}</p>
                    <ProgressBar value={parseFloat(uploadProgress) / 100} difference={100} label="Upload Progress" />
                </div>
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Add Impression Configuration</h2>
            <p>
                Import your impressions model data into Cubed. <b>It is important to use the defined CSV format</b> -
                you can download it <a href="/files/impressions_modelling.csv">here</a>.
            </p>
            {renderAccordion()}
        </div>
    );
};

export default LayoutAddImpressionModelling;
