import { ErrorData, ResourceData, ViewData } from '../types';

export const unknownErrorData = (): ErrorData => {
    return {
        type: 'resource',
        status: 'error',
        error: {
            message: 'Unknown error',
        },
    };
};

export const comparisonRequestError = (
    resource: ResourceData | ViewData,
    comparisonResource: ResourceData | ViewData
) => {
    const errors = [];

    if (resource.status === 'error') {
        errors.push(resource.error?.message);
    }
    if (comparisonResource.status === 'error') {
        errors.push(comparisonResource?.error?.message);
    }

    if (errors.length > 0) {
        return errors.join(', ');
    } else return 'Unknown Error';
};
