import { v4 as uuidv4 } from 'uuid';
import moment, { Moment } from 'moment';

// Helpers
import dateStringToUnix from '../../helpers/date-string-to-unix';
import { getRawValueAsNumber, getValue, getRawValueAsString } from './resource-data';
import { createDateRange } from './create-date-range';

// Types
import { CubedField } from '../../types';
import { LineGraphLegendOrder, LineGraphSeries, ResourceData, WidgetTheme } from '../types';
import { ResourceDataObject } from '../../react-query/types';

// Configuration
import { alternativeGraphColours, graphColours } from './graph-colours';

/* 
    Highchart venn diagram has a precision level set for plotting data points (union, intersection, disjoints).
    We ran into an issue when the data were too small to be plotted in the graph. This led to an error causing the whole page
    to not render. The range of values passed to the venn diagram and the number of data points could be
    the culprit here, because scaling the data fixes the issue. They use various algorithm to determine how to plot the circle.
    The error was when bisect algorithm tried to approximate the root in distance equation. If the value of the metrics are too
    small compared to other values, the distance between the points becomes too small and the bisect algorithm fails to approximate
    the root of the equation. To fix this, we scale the value of the metric by below factor for the venn diagram.
    The scaled value needs to be down scaled in the tooltip where the venn data is consumed from this source.
*/
export const VENN_CHART_SCALE_FACTOR = 100;

export const vennChartDataSeries = (
    dimensionResourceData: ResourceData,
    resourceData: ResourceData,
    metric: CubedField
) => {
    let sets: never | { id: number; name: string }[] = [];
    let data: never | { sets: string[]; value: number; color?: string; themeColour?: WidgetTheme }[] = [];

    if (dimensionResourceData && 'objects' in dimensionResourceData) {
        sets = dimensionResourceData.objects.map(object => {
            return {
                id: getRawValueAsNumber(object, 'id'),
                name: getRawValueAsString(object, 'name'),
            };
        });
    }

    if (resourceData && 'objects' in resourceData) {
        const colours = [...graphColours, ...alternativeGraphColours];
        data = resourceData.objects.map((object, index) => {
            const combination = getRawValueAsString(object, 'combination')
                .trim()
                .replace('(', '')
                .replace(')', '')
                .split(',');

            return {
                sets: combination.map(item => {
                    const name = sets.find(set => set.id === parseInt(item))?.name;
                    if (name) return name;
                    return 'Unknown';
                }),
                value: getRawValueAsNumber(object, metric.rawName) * VENN_CHART_SCALE_FACTOR,
                color: colours[index % colours.length].solidGraphColour,
                themeColour: colours[index % colours.length],
            };
        });
    }

    return data;
};

export const sparkLineDataSeries = (
    dataObjects: ResourceDataObject[],
    metric: CubedField,
    dateDimension: CubedField
) => {
    const series: [number, number][] = [];
    dataObjects.forEach(data => {
        const rawValue = getRawValueAsNumber(data, metric.rawName);
        series.push([dateStringToUnix(getValue(data, dateDimension.rawName)), rawValue]);
    });

    return series;
};

export const histogramChartDataSeries = (data: ResourceData, metric: CubedField, category: CubedField) => {
    let histogramData: { __id: string; name: string; y: number; colour: any }[] = [];

    if (data && 'objects' in data) {
        histogramData = data.objects.map((data, index) => {
            return {
                __id: data.__id,
                name: getValue(data, category.rawName),
                y: getRawValueAsNumber(data, metric.rawName),
                colour: graphColours[index % graphColours.length].solidGraphColour,
            };
        });
    }

    return histogramData;
};

export const lineGraphDataSeries = (
    data: ResourceData,
    seriesField: CubedField,
    dateDimension: CubedField,
    yAxis: CubedField,
    legendOrder: LineGraphLegendOrder
) => {
    const getLatestValue = (series: LineGraphSeries) => {
        return series.data.slice(-1)[0].y;
    };

    if (data && 'objects' in data) {
        // Find the unique series names

        let series: LineGraphSeries[] = [];
        let uniqueSeriesNames = [];

        if (seriesField.isDimension) {
            uniqueSeriesNames = [
                ...new Set(
                    data.objects.map(data => {
                        return getValue(data, seriesField.rawName);
                    })
                ),
            ];
        } else {
            uniqueSeriesNames.push(seriesField.displayName);
        }

        // create a series object for each series to be displayed in the graph
        uniqueSeriesNames.forEach((value, index) => {
            series.push({
                name: value,
                data: [],
                total: 0,
                colour: graphColours[index % graphColours.length].solidGraphColour,
            });
        });

        // insert data for each series
        series.forEach(series => {
            let rawValue: number;

            const pushData = (data: ResourceDataObject) => {
                series.data.push({
                    x: dateStringToUnix(getValue(data, dateDimension.rawName)),
                    y: rawValue,
                });
                series.total += rawValue;
            };

            data.objects.forEach(data => {
                if (seriesField.isDimension) {
                    if (series.name === getValue(data, seriesField.rawName)) {
                        rawValue = getRawValueAsNumber(data, yAxis.rawName);
                        pushData(data);
                    }
                } else {
                    rawValue = getRawValueAsNumber(data, yAxis.rawName);
                    pushData(data);
                }
            });
        });

        if (legendOrder?.type === 'lastValue') {
            series = series.sort((a, b) => getLatestValue(b) - getLatestValue(a));
        }

        return series;
    }
};

export const lineGraphDataSeriesInterpolated = (
    data: ResourceData,
    startDate: Moment,
    endDate: Moment,
    dateDimension: CubedField,
    seriesField: CubedField,
    yAxis: CubedField
) => {
    // get the date formats and build an array of dates

    if (data.status === 'success') {
        const dateRawValueFormat = moment(getRawValueAsString(data.objects[0], dateDimension.rawName)).creationData()
            .format as string;

        const dateValueFormat = moment(getValue(data.objects[0], dateDimension.rawName)).creationData()
            .format as string;

        const dateRange = createDateRange(startDate, endDate);

        if (!dateRawValueFormat || !dateValueFormat) {
            throw new Error('Unexpected undefined when retrieving Moment date format');
        }

        // Find the unique dimensions
        let uniqueSeriesNames: string[] = [];

        if (seriesField.isDimension) {
            uniqueSeriesNames = [
                ...new Set(
                    data.objects.map(data => {
                        return getValue(data, seriesField.rawName);
                    })
                ),
            ];
        } else {
            uniqueSeriesNames.push(seriesField.rawName);
        }

        // Interpolate the objects for each unique dimension
        const interpolatedObjects: ResourceDataObject[] = [];

        uniqueSeriesNames.forEach(value => {
            dateRange.forEach(date => {
                let matchFound = false;

                data.objects.forEach(object => {
                    if (seriesField.isDimension) {
                        if (
                            date === getRawValueAsString(object, dateDimension.rawName) &&
                            value === getValue(object, seriesField.rawName)
                        ) {
                            matchFound = true;
                            interpolatedObjects.push(object);
                        } else {
                            if (
                                date === moment(getRawValueAsString(object, dateDimension.rawName)).format('YYYY-MM-DD')
                            ) {
                                matchFound = true;
                                interpolatedObjects.push(object);
                            }
                        }
                    }
                });

                if (!matchFound) {
                    interpolatedObjects.push({
                        __id: uuidv4(),
                        values: {
                            [dateDimension.rawName]: {
                                rawValue: moment(date).format(dateRawValueFormat as string),
                                value: moment(date).format(dateValueFormat as string),
                            },
                            [seriesField.rawName]: {
                                rawValue: value,
                                value: value,
                            },
                            [yAxis.rawName]: {
                                rawValue: 0,
                                value: '0',
                            },
                        },
                    });
                }
            });
        });

        return {
            ...data,
            objects: interpolatedObjects,
        };
    }
    return data;
};
