import React from 'react';
import styled, { useTheme } from 'styled-components';

// Highcharts
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Config
import {
    nestedDonutBarChartConfig,
    nestedDonutChartConfig,
    nestedDonutColumnChartConfig,
} from '../../configurations/widget-nested-donut-chart-config';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Types
import { NestedDonutChartData } from '../../types';

// Components
import WidgetBase from '../base/widget-base';
import SectionDashboardNestedDonutTable from '../../components/tables/section-dashboard-nested-donut-table';

HighchartsMore(Highcharts);

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
`;

const StyledChartContainer = styled.div<{ width: number; height: number }>`
    max-width: ${props => (props.height > 1 && props.width > 3 ? '50%' : '100%')};
    height: 100%;
    align-items: center;
`;

const StyledTableContainer = styled.div`
    width: 100%;
    max-height: 100%;
    overflow: auto;
    align-self: center;
    padding: 20px;
`;

export type WidgetNestedDonutChartTableProps = {
    title: string;
    data: NestedDonutChartData;
    colors?: string[];
    href?: string;
};

const WidgetNestedDonutChartTable = ({ title, data, colors, href }: WidgetNestedDonutChartTableProps) => {
    const theme = useTheme();
    const { width, height } = useSectionDashboard();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const { series, columns, subColumns, tableRow } = data;

    const colorOverride = {
        colors: colors,
    };

    const radialConfig = nestedDonutChartConfig(theme, width, height);
    const chartConfig = {
        ...radialConfig,
        ...(colors && colorOverride),
        xAxis: {
            ...radialConfig.xAxis,
            categories: data.categories,
        },
    };

    const barConfig = nestedDonutBarChartConfig(theme);
    const barChartConfig = {
        ...barConfig,
        ...(colors && colorOverride),
        xAxis: {
            ...barConfig.xAxis,
            categories: data.categories,
        },
    };

    const columnConfig = nestedDonutColumnChartConfig(theme);
    const columnChartConfig = {
        ...columnConfig,
        ...(colors && colorOverride),
        xAxis: {
            ...columnConfig.xAxis,
            categories: data.categories,
        },
    };

    if (height === 1) {
        return (
            <WidgetBase title={title} href={href}>
                <StyledChartContainer width={width} height={height}>
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...barChartConfig, series: series }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                </StyledChartContainer>
            </WidgetBase>
        );
    }

    if (width === 1) {
        return (
            <WidgetBase title={title} href={href}>
                <StyledChartContainer width={width} height={height}>
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...columnChartConfig, series: series }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                </StyledChartContainer>
            </WidgetBase>
        );
    }

    return (
        <WidgetBase title={title} href={href}>
            <StyledContainer>
                <StyledChartContainer width={width} height={height}>
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...chartConfig, series: series }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                </StyledChartContainer>
                {width > 3 && (
                    <StyledTableContainer>
                        <SectionDashboardNestedDonutTable columns={columns} subColumns={subColumns} rows={[tableRow]} />
                    </StyledTableContainer>
                )}
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetNestedDonutChartTable;
