import React from 'react';
import { PieTableComparisonData, PieTableData, WidgetDialogLocation } from '../../../../types';
import WidgetMenuDropdown from '../widget-menu-dropdown';
import WidgetMenuSectionTitle from '../widget-menu-section-title';
import WidgetMenuRadio from '../widget-menu-radio';
import { useSectionDashboard } from '../../../../context/section-dashboard-context';
import WidgetMenuPagination, { WidgetMenuPaginationDisplay } from '../widget-menu-pagination';
import WidgetMenuSeparatorVertical from '../widget-menu-separator-vertical';
import WidgetDialogFilter from '../../../dialog/widget-dialog-filter';
import WidgetMenuSeparatorHorizontal from '../widget-menu-separator-horizontal';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';
import IconChevronLeftThin from '../../../../../components/icons/chevron-left-thin';

const StyledSubTriggerBase = css`
    height: 18px;
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 20px 20px;
    outline: none;
    font-weight: 500;
`;

const StyledSubTrigger = styled(DropdownMenu.SubTrigger)`
    ${StyledSubTriggerBase}
    cursor: pointer;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
    }
`;

const StyledIconChevronLeftThin = styled(IconChevronLeftThin)`
    height: 18px;
    width: auto;
`;

const StyledContentBase = css`
    background-color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.backgroundColor};
    min-width: 210px;
    padding: 10px 0px;
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.menu.shadow};
    max-height: 50vh;
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.textColor};
    overflow-y: auto;
`;

const StyledSubContent = styled(DropdownMenu.SubContent)`
    ${StyledContentBase}
    z-index: 51; // Overlap the sidebar
    max-width: 300px;
`;

type WidgetMenuPieChartTableProps = {
    data: PieTableData | PieTableComparisonData;
};

const WidgetMenuPieChartTable = ({ data }: WidgetMenuPieChartTableProps) => {
    const { width } = useSectionDashboard();

    const loading = data.status === 'loading';
    const success = data.status === 'success';
    const noData = data.status === 'empty' || data.status === 'error';

    const currentChartField = data.request?.chartField;
    const chartMetrics = data.request?.chartMetrics;
    const setChartField = data.request?.setChartField;

    if (data.request) {
        const request = data.request;
        const hasFilters = noData && request.filters && request.filters.length > 0;

        if (!request.setFilters) {
            return null;
        }

        return (
            <>
                {width > 4 && (
                    <>
                        {(!noData || loading) && (
                            <>
                                <WidgetMenuPagination display={WidgetMenuPaginationDisplay.FOOTER} data={data} />
                                <WidgetMenuSeparatorVertical spacingLeft={false} spacingRight={false} />
                            </>
                        )}
                        {(success || hasFilters || loading) && (
                            <WidgetDialogFilter
                                filters={request.filters}
                                options={request.fields}
                                location={WidgetDialogLocation.Footer}
                                loading={loading}
                                onApplyClick={request.setFilters}
                            />
                        )}
                        {currentChartField && chartMetrics && (
                            <>
                                <WidgetMenuSeparatorVertical spacingLeft={false} spacingRight={false} />
                                <WidgetMenuDropdown loading={loading}>
                                    <WidgetMenuSectionTitle title="Chart Metric" />
                                    <WidgetMenuRadio
                                        options={chartMetrics.map(metric => {
                                            return {
                                                value: metric.rawName,
                                                label: metric.displayName,
                                            };
                                        })}
                                        value={currentChartField.rawName}
                                        onValueChange={option => {
                                            const newField = chartMetrics.find(metric => metric.rawName === option);

                                            if (newField && setChartField) {
                                                setChartField(newField);
                                            }
                                            return;
                                        }}
                                    />
                                </WidgetMenuDropdown>
                            </>
                        )}
                    </>
                )}
                {((width < 5 && success) || (width < 3 && hasFilters) || (width < 3 && loading)) && (
                    <WidgetMenuDropdown loading={loading}>
                        {(!noData || loading) && (
                            <>
                                <WidgetMenuPagination display={WidgetMenuPaginationDisplay.DROPDOWN} data={data} />
                                <WidgetMenuSeparatorHorizontal />
                            </>
                        )}
                        {currentChartField && chartMetrics && (
                            <>
                                <DropdownMenu.Sub>
                                    <StyledSubTrigger>
                                        <StyledIconChevronLeftThin />
                                        Chart Metric
                                    </StyledSubTrigger>
                                    <DropdownMenu.Portal>
                                        <StyledSubContent>
                                            <WidgetMenuRadio
                                                options={chartMetrics.map(metric => {
                                                    return {
                                                        value: metric.rawName,
                                                        label: metric.displayName,
                                                    };
                                                })}
                                                value={currentChartField.rawName}
                                                onValueChange={option => {
                                                    const newField = chartMetrics.find(
                                                        metric => metric.rawName === option
                                                    );

                                                    if (newField && setChartField) {
                                                        setChartField(newField);
                                                    }
                                                    return;
                                                }}
                                            />
                                        </StyledSubContent>
                                    </DropdownMenu.Portal>
                                </DropdownMenu.Sub>
                                <WidgetMenuSeparatorHorizontal />
                            </>
                        )}
                        {(success || hasFilters || loading) && (
                            <WidgetDialogFilter
                                filters={request.filters}
                                options={request.fields}
                                location={WidgetDialogLocation.Dropdown}
                                loading={loading}
                                onApplyClick={request.setFilters}
                            />
                        )}
                    </WidgetMenuDropdown>
                )}
            </>
        );
    }

    return null;
};

export default WidgetMenuPieChartTable;
