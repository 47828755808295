import { DateRange } from '../../layouts/health-dashboard/types';

export type ActionFilterReset = {
    type: 'filter-reset';
};

type ActionDateRange = {
    dateRange?: DateRange;
    tempDateRange?: DateRange;
};

export type ActionLiveDataEnable = {
    type: 'live-data-enable';
    payload: ActionDateRange;
};

export type ActionLiveDataDatePickerSelect = {
    type: 'live-data-date-picker-date-select';
    payload: ActionDateRange;
};

export type ActionLiveDataDatePickerApply = {
    type: 'live-data-date-picker-apply';
    payload: ActionDateRange;
};

type ActionValues = {
    name?: string;
    category?: string;
    submited?: boolean;
};

export type ActionFilterCategoryChange = {
    type: 'filter-category-change';
    payload: ActionValues;
};

export type ActionFilterNameChange = {
    type: 'filter-name-change';
    payload: ActionValues;
};

export type ActionFilterSubmit = {
    type: 'filter-submit';
    payload: ActionValues;
};

export type ReducerActions =
    | ActionFilterReset
    | ActionFilterCategoryChange
    | ActionLiveDataEnable
    | ActionLiveDataDatePickerSelect
    | ActionLiveDataDatePickerApply
    | ActionFilterNameChange
    | ActionFilterSubmit;

type ActionsTypes = {
    FILTER_CATEGORY_CHANGE: string;
    FILTER_NAME_CHANGE: string;
    FILTER_SUBMIT: string;
    FILTER_RESET: string;
    LIVE_DATA_ENABLE: string;
    LIVE_DATA_DISABLE: string;
    LIVE_DATA_DATE_PICKER_DATE_SELECT: string;
    LIVE_DATA_DATE_PICKER_APPLY: string;
    LIVE_DATA_DATE_PICKER_CANCEL: string;
};

export const ACTIONS: ActionsTypes = {
    FILTER_CATEGORY_CHANGE: 'filter-category-change',
    FILTER_NAME_CHANGE: 'filter-name-change',
    FILTER_SUBMIT: 'filter-submit',
    FILTER_RESET: 'filter-reset',

    LIVE_DATA_ENABLE: 'live-data-enable',
    LIVE_DATA_DISABLE: 'live-data-disable',
    LIVE_DATA_DATE_PICKER_DATE_SELECT: 'live-data-date-picker-date-select',
    LIVE_DATA_DATE_PICKER_APPLY: 'live-data-date-picker-apply',
    LIVE_DATA_DATE_PICKER_CANCEL: 'live-data-date-picker-cancel',
};
