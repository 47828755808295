import React from 'react';
import styled from 'styled-components';

const StyledFormFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

type FormFooterProps = {
    children: React.ReactNode;
};

const FormFooter = ({ children }: FormFooterProps) => {
    return <StyledFormFooter>{children}</StyledFormFooter>;
};

export default FormFooter;
