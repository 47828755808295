import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

// Redux & Context
import { useSelector } from 'react-redux';
import { TableGraphContext } from '../../context/table-graph-context';

// Helpers & Enums
import dataTypes from '../../../filter-bar/enums/data-types';
import { setTickInterval } from './helpers/chart-helper';
import { formatAnnotationsChartConfig } from './helpers/format-annotations';

// Components
import Message from '../../../components/message';
// Highcharts

import Highcharts from 'highcharts/highstock';
import trendline from 'highcharts/indicators/trendline';
import indicators from 'highcharts/indicators/indicators';
import CommonHighcharts from '../../../components/common/common-highcharts';
import AnnotationsModule from 'highcharts/modules/annotations';
AnnotationsModule(Highcharts);

const StyledTrendLineChart = styled.div`
    min-height: 310px;
    min-width: 0px;
    position: relative;
    opacity: ${props => props.isLoading && 0.5};
    transition: ${props => props.isLoading && props.theme.transition};
    flex-grow: 1;
    flex-basis: 20em;
`;

const TrendLineChart = ({
    chartYMetrics,
    config,
    xMetric,
    messageTitle = 'No data.',
    messageCopy = '',
    messageType = 'info',
    chartSeriesList,
    noData,
    chartLoading,
    chartDateGranularity,
    dates,
}) => {
    indicators(Highcharts);
    trendline(Highcharts);

    // State
    const [tick, setTick] = useState();
    const [annotationConfig, setAnnotationConfig] = useState([]);

    // Redux
    const { date } = useSelector(state => state);

    // Context
    const tableGraphContext = useContext(TableGraphContext);
    const { annotations } = tableGraphContext;

    useEffect(() => {
        setAnnotationConfig(formatAnnotationsChartConfig(annotations));
    }, [annotations]);

    useEffect(() => {
        setTick(setTickInterval(config, xMetric, chartSeriesList, chartDateGranularity, date));
    }, [chartSeriesList]); // eslint-disable-line react-hooks/exhaustive-deps

    let chartSeriesListData;

    config.chart.options.xAxis.tickInterval = tick;
    config.chart.options.xAxis.labels.enabled = true;

    if (chartYMetrics && chartYMetrics.length > 0) {
        config.chart.options.yAxis.reversed = chartYMetrics[0].flipYAxis ? true : false;
    }

    // set the x-axis label formatting depending on x-axis data type
    [dataTypes.DATE, dataTypes.DATE_TIME].includes(xMetric.dataType)
        ? (config.chart.options.xAxis.type = 'datetime')
        : (config.chart.options.xAxis.type = 'linear');

    if (!chartLoading) {
        chartSeriesListData = [{ ...chartSeriesList[0], id: 'area-spline-trendline' }];
    }

    if (xMetric.dataType === dataTypes.DATE && dates.startDate === dates.endDate) {
        return (
            <StyledTrendLineChart>
                <Message
                    title="Not enough data."
                    copy="Singular day selected. Please select a date range if you want to view a line chart."
                    type="info"
                />
            </StyledTrendLineChart>
        );
    }

    return (
        <StyledTrendLineChart isLoading={chartLoading}>
            {!noData && chartSeriesListData !== undefined ? (
                <CommonHighcharts
                    highcharts={Highcharts}
                    options={{
                        ...config.chart.options,
                        series: [
                            ...chartSeriesListData,
                            {
                                type: 'trendline',
                                linkedTo: chartSeriesListData[0].id,
                            },
                        ],
                        annotations: annotationConfig,
                    }}
                    immutable={true}
                />
            ) : (
                <Message title={messageTitle} copy={messageCopy} type={messageType} />
            )}
        </StyledTrendLineChart>
    );
};

export default TrendLineChart;
