/* eslint-disable react-hooks/exhaustive-deps */
// React Dependencies
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FieldValues } from 'react-hook-form';

// Config
import { CONFIG_EVENT } from '../../configurations/resources-config';

// Hooks
import usePostResource from '../../react-query/hooks/use-post-resource';

// Redux
import { useDispatch } from 'react-redux';
import { setModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';

// Types & Enums
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import ConfigTable from '../../components/tables/config-table/config-table';
import Form from '../../components/forms/form';
import validators from '../../components/forms/validation/validators';
import { TooltipPopUpSide } from '../../components/tooltip';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin: 20px 0;
`;

const StyledFormTitle = styled.h3`
    margin: 0 0 30px 0;
`;

const StyledRequiredIndicator = styled.span`
    color: ${props => props.theme.colours.red};
    margin-left: 5px;
`;

type CubedEvent = {
    name: string;
    displayName: string;
};

const LayoutModalCreateEvent = () => {
    const dispatch = useDispatch();

    const [createdEvents, setCreatedEvents] = useState<CubedEvent[]>([]);
    const [tableRows, setTableRows] = useState<CubedEvent[]>([]);

    // Form States
    const [formData, setFormData] = useState<FieldValues>();

    useEffect(() => {
        const newRows = createdEvents.map(createdEvent => {
            return {
                name: createdEvent.name,
                displayName: createdEvent.displayName,
            };
        });

        setTableRows(newRows);
    }, [createdEvents]);

    // Form Submit
    useEffect(() => {
        if (formData && formData.name && formData.displayName) {
            eventMutation.mutate();
        }
    }, [formData]);

    const eventMutation = usePostResource({
        resource: CONFIG_EVENT,
        data: formData ? { name: formData.name, display_name: formData.displayName } : { name: '', display_name: '' },

        handleOnSuccess: () => {
            setCreatedEvents(currentList => [...currentList, formData as CubedEvent]);
            setFormData(undefined);
            dispatch(addNotification({ copy: 'Event successfully added.', type: NotificationMessageType.Success }));
        },
        handleOnError: error => {
            if (error && error.includes('duplicate')) {
                dispatch(
                    addNotification({
                        copy: 'The event you are trying to add already exists.',
                        type: NotificationMessageType.Error,
                    })
                );
            } else {
                dispatch(
                    addNotification({
                        copy: 'There was an issue trying to add your event. Please try again later.',
                        type: NotificationMessageType.Error,
                    })
                );
            }
        },
    });

    const onCloseClick = () => {
        dispatch(setModal('ManageEvents'));
    };

    const handleSubmitForm = (data: FieldValues) => {
        setFormData(data);
    };

    return (
        <div className="modal__side-panel__create-event-wizard">
            <ModalNavigation
                buttons={[
                    {
                        value: 'back',
                        onClick: onCloseClick,
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Create Events</h2>
            <p>
                Create new events using the form below. Events you may wish to configure include adding to basket,
                starting a checkout, downloading a PDF or visiting specific content. For more help see{' '}
                <a href="https://tag.docs.withcubed.com/onboarding/general/#events">setting up events</a>.
            </p>
            <p>
                <b>Event Tag Name</b> is case-sensitive, and must match exactly what is being fired by your Cubed tag
                implementation.
            </p>
            <p>
                <b>Event Display Name</b> is how you want the event name to be displayed in the Cubed dashboards and
                reports.
            </p>

            <StyledFormContainer>
                <StyledFormTitle>
                    Event Details <StyledRequiredIndicator>*</StyledRequiredIndicator>
                </StyledFormTitle>
                <Form onSubmit={handleSubmitForm}>
                    <Form.Body>
                        <Form.Section>
                            <Form.Field>
                                <Form.InputLabel
                                    htmlFor="name"
                                    label="Event Tag Name"
                                    required={true}
                                    tooltipCopy="Please enter the Event Name as it will appear in the tagging implementation. Please note, event name must match exactly and is case sensitive."
                                    tooltipPosition={TooltipPopUpSide.Right}
                                />
                                <Form.InputText
                                    name="name"
                                    placeholder="Event Tag Name..."
                                    validators={[validators.required]}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.InputLabel
                                    htmlFor="displayName"
                                    label="Event Display Name"
                                    required={true}
                                    tooltipCopy="Please enter the Event Display Name as you wish it to appear in the Cubed dashboard display and reports."
                                    tooltipPosition={TooltipPopUpSide.Right}
                                />
                                <Form.InputText
                                    name="displayName"
                                    placeholder="Event Display Name..."
                                    validators={[validators.required]}
                                />
                            </Form.Field>
                        </Form.Section>
                        <Form.Section>
                            <Form.Field>
                                <></>{' '}
                            </Form.Field>
                        </Form.Section>
                    </Form.Body>
                    <Form.Footer>
                        <Form.InputButton
                            value="Add"
                            loading={eventMutation.isPending}
                            disabled={eventMutation.isPending}
                            buttonTheme={ButtonThemes.Green}
                        />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>

            <ConfigTable
                status={'success'}
                isFetching={eventMutation.isPending}
                disabled={false}
                empty={tableRows.length === 0}
            >
                <ConfigTable.Table maxHeight="400px" emptyMessage="No events added yet.">
                    <ConfigTable.Header>
                        <ConfigTable.Row key="thead">
                            <ConfigTable.CellHeader>Event Tag Name</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Event Display Name</ConfigTable.CellHeader>
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {tableRows.map(row => {
                            return (
                                <ConfigTable.Row key={row.name}>
                                    <ConfigTable.Cell>{row.name}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{row.displayName}</ConfigTable.Cell>
                                </ConfigTable.Row>
                            );
                        })}
                    </ConfigTable.Body>
                </ConfigTable.Table>
            </ConfigTable>
        </div>
    );
};

export default LayoutModalCreateEvent;
