import React from 'react';
import styled, { keyframes } from 'styled-components';

// Icons
import IconInfoTriangle from './icons/info-triangle';
import IconWarningTriangle from './icons/warning-triangle';
import IconErrorTriangle from './icons/error-triangle';
import IconLoader from './icons/loader';

// Types
type IconType = 'warning' | 'error' | 'info' | 'loading';

export type IconSize = 'small' | 'xsmall';

export type IconDirection = 'horizontal' | 'vertical';

type MessageProps = {
    title?: string;
    shortTitle?: string;
    copy: string;
    type: IconType;
    size?: IconSize;
    display?: IconDirection;
};

// Styled Components
const handleIconSize = (size: IconSize) => {
    switch (size) {
        case 'small':
            return '80px';
        case 'xsmall':
            return '40px';
        default:
            return '100px';
    }
};

const StyledMessageContainer = styled('div')<{ display: 'horizontal' | 'vertical' }>`
    display: ${props => (props.display === 'horizontal' ? 'flex' : 'block')};
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const StyledMessageIcon = styled('div')<{ size?: IconSize }>`
    display: inline-block;
    & svg {
        height: ${props => handleIconSize(props.size!)};
    }
`;

const StyledMessageText = styled('div')<{ size?: 'small' | 'xsmall'; display: 'horizontal' | 'vertical' }>`
    display: ${props => (props.display === 'horizontal' ? 'inline-block' : 'block')};
    margin-left: ${props => (props.display === 'vertical' ? '0px' : props.size === 'xsmall' ? '10px' : '20px')};
    text-align: ${props => (props.display === 'horizontal' ? 'left' : 'center')};
    white-space: ${props => (props.size === 'small' || props.size === 'xsmall' ? 'pre-line' : '')};
    color: ${props => props.theme.components.message.textColor};
`;

const StyledH3 = styled('h3')<{ size?: 'small' | 'xsmall' }>`
    font-size: ${props => (props.size === 'xsmall' ? '0.9rem' : '1.3rem')};
    margin: 0 0 0.3em 0;
`;

const StyledP = styled.p`
    margin: 0;
`;

const StyledSpinnerContainer = styled.div`
    display: block;
`;

const spinAnimation = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const StyledLoader = styled.div`
    animation: ${spinAnimation} 5s linear infinite;
    color: ${props => props.theme.components.message.icon.loadingColor};
    & svg {
        width: 50px;
    }
`;

const StyledWarningTriangle = styled.div`
    path {
        fill: ${props => props.theme.components.message.icon.warningColor};
    }

    polygon {
        stroke: ${props => props.theme.components.message.icon.warningColor};
    }
`;

const StyledErrorTriangle = styled.div`
    path {
        fill: ${props => props.theme.components.message.icon.errorColor};
    }

    polygon {
        stroke: ${props => props.theme.components.message.icon.errorColor};
    }
`;

const StyledInfoTriangle = styled.div`
    path {
        fill: ${props => props.theme.components.message.icon.infoColor};
    }

    ellipse {
        stroke: ${props => props.theme.components.message.icon.infoColor};
    }
`;

const MessageIcon = ({ type }: { type: IconType }) => {
    switch (type) {
        case 'warning':
            return (
                <StyledWarningTriangle>
                    <IconWarningTriangle />
                </StyledWarningTriangle>
            );
        case 'error':
            return (
                <StyledErrorTriangle>
                    <IconErrorTriangle />
                </StyledErrorTriangle>
            );
        case 'info':
            return (
                <StyledInfoTriangle>
                    <IconInfoTriangle />
                </StyledInfoTriangle>
            );
        case 'loading':
            return (
                <StyledSpinnerContainer>
                    <StyledLoader>
                        <IconLoader />
                    </StyledLoader>
                </StyledSpinnerContainer>
            );
        default:
            return <></>;
    }
};

const Message = ({
    title = '',
    shortTitle = '',
    copy = '',
    type = 'info',
    size,
    display = 'horizontal',
}: MessageProps) => {
    return (
        <StyledMessageContainer display={display} data-testid="message">
            <StyledMessageIcon size={size}>
                <MessageIcon type={type} />
            </StyledMessageIcon>
            <StyledMessageText size={size} display={display}>
                {(title || shortTitle) && <StyledH3 size={size}>{size !== 'xsmall' ? title : shortTitle}</StyledH3>}
                <StyledP>{size !== 'xsmall' && copy}</StyledP>
            </StyledMessageText>
        </StyledMessageContainer>
    );
};

export default Message;
