import React from 'react';
import { CubedIcon } from './types';

const IconWarningTriangle: CubedIcon = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 512 512">
            <polygon
                fill="none"
                stroke="#f68c1f"
                strokeMiterlimit="10"
                strokeWidth="20px"
                points="256 57.48 21 464.52 491 464.52 256 57.48"
            />
            <path
                fill="#f68c1f"
                d="M261.69,209c-31.06,0-51.18,12.78-67,35.57a9.39,9.39,0,0,0,2,12.82l16.74,12.74a9.38,9.38,0,0,0,13-1.62c9.73-12.23,17-19.28,32.16-19.28,12,0,26.78,7.74,26.78,19.39,0,8.81-7.25,13.33-19.07,20-13.78,7.76-32,17.41-32,41.56V334a9.37,9.37,0,0,0,9.37,9.37h28.12a9.38,9.38,0,0,0,9.38-9.37v-2.26C281.23,315,330,314.32,330,269,330,234.91,294.71,209,261.69,209Zm-3.77,145.89A27.06,27.06,0,1,0,285,382,27.09,27.09,0,0,0,257.92,354.92Z"
            />
        </svg>
    );
};

export default IconWarningTriangle;
