/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { DropResult } from 'react-beautiful-dnd';

// Redux
import { useDispatch } from 'react-redux';
import { removePopup, setPopup } from '../../redux/actions/popup';
import { setModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';

// Styled Components
import styled from 'styled-components';

// Helpers
import { GroupRule } from '../../helpers/segmenter/group-rule';
import { OPERATORS } from '../../helpers/segmenter/reference-objects';
import {
    updateTableRowPriority,
    getUpdatedDifferences,
    reorderTableRows,
    getIndexedUpdatedTableRows,
} from '../../components/tables/helpers/drag-and-drop-table-helper';
import { resetTableRows } from '../../helpers/segmenter/advanced-rule-builder-helper';

// Components
import InputButton from '../../components/inputs/input-button';
import DropdownWithSearch from '../../components/common/dropdown-with-search';
import SegmenterRuleBuilder from '../segmenter/segmenter-rule-builder';
import FormTextInput from '../../components/form-fields/form-text-input';
import FormTextArea from '../../components/form-fields/form-text-area';
import ConfigDragAndDropTable from '../../components/tables/config-drag-and-drop-table/config-drag-and-drop-table';

// Configurations
import { CONFIG_CONDITION_RESOURCE, SUBCHANNEL_RULES_RESOURCE } from '../../configurations/resources-config';

// React Queries
import { ConfigDataSuccess } from '../../react-query/types';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import usePatchResource from '../../react-query/hooks/use-patch-resource';
import usePostResource from '../../react-query/hooks/use-post-resource';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';
import usePatchMultiResource from '../../react-query/hooks/use-patch-multi-resources';

// Types & Enums
import { NotificationMessageType } from '../../enums/notification-types';
import { ButtonThemes } from '../../enums/button-themes';

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
`;

const StyledSelectPriorityContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const StyledPriorityFormContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const StyledSelectPriorityDropdown = styled.div`
    flex-grow: 1;
`;

const StyledSaveActionsButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 10.5px;
`;

const StyledAddConditionButtonContainer = styled.div`
    text-align: left;
    margin-bottom: 20px;
    margin-top: 10.5px;
`;

export const StyledFormContainer = styled.div`
    background-color: #ffffff;
    padding: 20px;
`;

type PositionOption = { label: 'Before' | 'After'; value: 'before' | 'after' };

type RuleOption = { label: string; value: number };

type Condition = {
    id: number;
    functionName: string;
    relatedTable: string;
    relatedTableKey: string;
    operator: string;
    inputValue: string;
    label: string;
    value: string;
    active: boolean;
};

type SubchannelRule = {
    id: number;
    name: string;
    rule: string;
    description: string;
    priority: number;
    subchannel: string;
    subchannelId: number;
    referer: string;
    dragId?: string;
    selected?: boolean;
};

type TableRow = {
    dragId: string;
    selected: boolean;
} & SubchannelRule;

type ManageSubchannelsRulesProps = {
    subchannelIdToEditRule: number;
    subchannelRulesQuery: UseQueryResult<TableRow[], Error>;
    handleCancleSubchannelRuleClick: () => void;
};

const ManageSubchannelsRules = ({
    subchannelIdToEditRule,
    subchannelRulesQuery,
    handleCancleSubchannelRuleClick,
}: ManageSubchannelsRulesProps) => {
    // Redux
    const dispatch = useDispatch();

    // Rules and Patterns states
    const [rule, setRule] = useState(new GroupRule(OPERATORS[0]));
    const [pattern, setPattern] = useState('');

    // Edit Rules states
    const [subchannelRuleToEdit, setSubchannelRuleToEdit] = useState<SubchannelRule | undefined>();
    const [subchannelRulesToEdit, setSubchannelRulesToEdit] = useState<{ [key: string]: number }[]>([]);
    const [subchannelRulesIdsToEdit, setSubchannelRulesIdsToEdit] = useState<string[]>([]);
    const [subchannelRuleErrorMessage, setSubchannelRuleErrorMessage] = useState<string>();
    const [subchannelRulesToDelete, setSubchannelRulesToDelete] = useState<number[]>([]);

    const [showSelectPriority, setShowSelectPriority] = useState(false);
    const [showCreateNewSegment, setShowCreateNewSegment] = useState(false);

    // Conditions
    const [conditions, setConditions] = useState<Condition[]>([]);

    // Selected values
    const [selectedPosition, setSelectedPosition] = useState<PositionOption>();
    const [selectedRule, setSelectedRule] = useState<RuleOption>();

    // Table Rows
    const [tableRows, setTableRows] = useState<TableRow[]>([]);
    const [tableRowsSnapshot, setTableRowsSnapshot] = useState<TableRow[]>([]);

    // Table Order Editing
    const [editingTableOrder, setEditingTableOrder] = useState(false);

    const isNotUniqueRule =
        subchannelRuleToEdit &&
        tableRowsSnapshot &&
        tableRowsSnapshot.some(row => row.rule === subchannelRuleToEdit.rule && row.id !== subchannelRuleToEdit.id);

    // Queris
    const conditionQuery = useFetchResource({
        resource: CONFIG_CONDITION_RESOURCE,
        params: [{ key: 'active', value: true }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(condition => {
                return {
                    id: condition.id,
                    functionName: condition.function.display_name,
                    relatedTable: condition.function.table_name,
                    relatedTableKey: condition.key,
                    operator: condition.function_operator.name,
                    inputValue: condition.value,
                    label: condition.display_name,
                    value: condition.id,
                    active: condition.active,
                };
            });
        },
    });

    const subchannelRulePatchMutation = usePatchResource({
        resource: SUBCHANNEL_RULES_RESOURCE,
        resourceId: subchannelRuleToEdit ? subchannelRuleToEdit?.id?.toString() : '0',
        data: {
            description: subchannelRuleToEdit?.description as string,
            name: subchannelRuleToEdit?.name as string,
            rule: subchannelRuleToEdit?.rule as string,
            subchannel: `/api/segmenter/segmenter-subchannel-config/${subchannelRuleToEdit?.subchannelId}/`,
        },
        handleOnSuccess: () => {
            setSubchannelRuleToEdit(undefined);
            setSubchannelRuleErrorMessage(undefined);
            subchannelRulesQuery.refetch();

            dispatch(
                addNotification({
                    copy: 'Rule successfully updated.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your Rule. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const subchannelRulePostMutation = usePostResource({
        resource: SUBCHANNEL_RULES_RESOURCE,
        data: {
            description: subchannelRuleToEdit?.description as string,
            name: subchannelRuleToEdit?.name as string,
            priority: subchannelRuleToEdit?.priority as number,
            rule: subchannelRuleToEdit?.rule as string,
            subchannel: `/api/segmenter/segmenter-subchannel-config/${subchannelRuleToEdit?.subchannelId}/`,
        },
        handleOnSuccess: () => {
            setSubchannelRuleToEdit(undefined);
            setSubchannelRuleErrorMessage(undefined);

            const differencesTableRow = getUpdatedDifferences({
                selectedPosition,
                selectedRule,
                subchannelRuleToEdit: subchannelRuleToEdit as unknown as TableRow,
                tableRows,
                tableRowsSnapshot,
            });

            if (differencesTableRow.length > 0) {
                setSubchannelRulesToEdit(differencesTableRow.map(row => ({ priority: row.priority })));
                setSubchannelRulesIdsToEdit(differencesTableRow.map(row => row.id.toString()));
            } else {
                subchannelRulesQuery.refetch();
                handleCancelRuleEditClick();
            }

            dispatch(
                addNotification({
                    copy: 'Rule successfully added.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            // Reset table rows to the snapshot
            setTableRows(tableRowsSnapshot);

            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your Rule. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const subchannelRulesPatchMutation = usePatchMultiResource({
        resource: SUBCHANNEL_RULES_RESOURCE,
        resourceId: subchannelRulesIdsToEdit,
        data: subchannelRulesToEdit,
        handleOnSuccess: () => {
            setSubchannelRuleToEdit(undefined);
            setSubchannelRuleErrorMessage(undefined);
            setSubchannelRulesToEdit([]);
            setSubchannelRulesIdsToEdit([]);
            subchannelRulesQuery.refetch();
        },
        handleOnError: () => {
            setSubchannelRuleToEdit(undefined);
            setSubchannelRuleErrorMessage(undefined);
            setSubchannelRulesToEdit([]);
            setSubchannelRulesIdsToEdit([]);

            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your Rule. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const subchannelRuleDeleteMutation = useDeleteResource({
        resource: SUBCHANNEL_RULES_RESOURCE,
        resourceIds: subchannelRulesToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            subchannelRulesQuery.refetch();
            setSubchannelRulesToDelete([]);
            dispatch(
                addNotification({
                    copy: 'Rule successfully deleted.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setSubchannelRulesToDelete([]);
            dispatch(
                addNotification({
                    copy: 'There was an issue while trying to delete your Rule. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    useEffect(() => {
        if (subchannelRuleToEdit) {
            setSubchannelRuleToEdit({
                ...subchannelRuleToEdit,
                rule: `(${pattern})`,
            });
        }
    }, [pattern]);

    useEffect(() => {
        if (subchannelRulesQuery.data) {
            setTableRows(subchannelRulesQuery.data);
            setTableRowsSnapshot(subchannelRulesQuery.data);
        }
    }, [subchannelRulesQuery.data]);

    useEffect(() => {
        if (conditionQuery.data) setConditions(conditionQuery.data);
    }, [conditionQuery.data]);

    useEffect(() => {
        setSubchannelRulesToDelete(tableRows.filter(rule => rule.selected).map(rule => rule.id));
    }, [tableRows]);

    useEffect(() => {
        if (subchannelRulesToEdit.length > 0 && subchannelRulesIdsToEdit.length > 0) {
            subchannelRulesPatchMutation.mutate();
        }
    }, [subchannelRulesToEdit, subchannelRulesIdsToEdit]);

    // Form Validation
    const subchannelRuleFormIsValid = (rule?: SubchannelRule) => {
        if (rule) {
            let isValid = true;
            if (rule.name.length === 0) {
                setSubchannelRuleErrorMessage('Please enter a valid Rule Name.');
                isValid = false;
            } else {
                setSubchannelRuleErrorMessage(undefined);
            }

            return isValid;
        }
    };

    const handleResetTableRows = () => {
        setTableRows(resetTableRows(tableRows));
    };

    const handlePatternChange = (pattern: string) => {
        setPattern(pattern);
    };

    const handleSelectedPosition = (selectedOption: PositionOption) => {
        setSelectedPosition(selectedOption);

        const { updatedNewTableRow, updatedExistingTableRows } = updateTableRowPriority({
            selectedPosition: selectedOption,
            selectedRule,
            newTableRow: subchannelRuleToEdit as unknown as TableRow,
            existingTableRows: tableRows,
        });

        setSubchannelRuleToEdit(updatedNewTableRow);
        setTableRows(updatedExistingTableRows);
    };

    const handleSelectedRule = (selectedOption: RuleOption) => {
        setSelectedRule(selectedOption);

        const { updatedNewTableRow, updatedExistingTableRows } = updateTableRowPriority({
            selectedPosition,
            selectedRule: selectedOption,
            newTableRow: subchannelRuleToEdit as unknown as TableRow,
            existingTableRows: tableRows,
        });

        setSubchannelRuleToEdit(updatedNewTableRow);
        setTableRows(updatedExistingTableRows);
    };

    const handleCreateNewRule = () => {
        if (editingTableOrder) return;

        handleResetTableRows();
        setShowSelectPriority(true);
        setShowCreateNewSegment(true);
        setRule(new GroupRule(OPERATORS[0]));

        const segmentToEdit = {
            id: undefined,
            name: '',
            description: '',
            rule: undefined,
            priority: tableRowsSnapshot && tableRowsSnapshot.length + 1,
            subchannel: `/api/segmenter/segmenter-subchannel-config/${subchannelIdToEditRule}/`,
            subchannelId: subchannelIdToEditRule,
            referer: '',
        };

        setSubchannelRuleToEdit(segmentToEdit as unknown as SubchannelRule);
    };

    const handleRulesListCheckboxChange = (id: number) => {
        setTableRows(
            tableRows.map(rule => {
                if (rule.id === id) {
                    return {
                        ...rule,
                        selected: !rule.selected,
                    };
                }
                return rule;
            })
        );
    };

    const handleSingleRuleEditClick = (id: number) => {
        const selectedSubchannelRule = tableRows.find(subchannel => subchannel.id === id);
        const group = rule.deserialize(selectedSubchannelRule?.rule, conditions);

        if (group) setRule(group);
        setSubchannelRuleToEdit(selectedSubchannelRule);
    };

    const handleSingleRuleDeleteClick = (id: number) => {
        setSubchannelRulesToDelete([id]);

        const handleDisardClick = () => {
            setSubchannelRulesToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            subchannelRuleDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this Rule?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkRulesDeleteClick = () => {
        if (subchannelRulesToDelete.length === 0) {
            return;
        }

        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setTableRows(
                tableRows.map(rule => {
                    return {
                        ...rule,
                        selected: false,
                    };
                })
            );

            subchannelRuleDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected Rules?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleRuleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const segmenterEditing = {
            ...subchannelRuleToEdit,
            name: e.target.value,
        };
        setSubchannelRuleToEdit(segmenterEditing as SubchannelRule);
    };

    const handleRuleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const segmenterEditing = {
            ...subchannelRuleToEdit,
            description: e.target.value,
        };
        setSubchannelRuleToEdit(segmenterEditing as SubchannelRule);
    };

    const handleCancelRuleEditClick = () => {
        setShowSelectPriority(false);
        setSubchannelRuleToEdit(undefined);
        handleResetTableRows();
        setRule(new GroupRule(OPERATORS[0]));
        setShowCreateNewSegment(false);
        // reset dropdown selected value
        setSelectedPosition(undefined);
        setSelectedRule(undefined);

        setSubchannelRuleToEdit(undefined);
        // Reset table rows to the snapshot
        setTableRows(tableRowsSnapshot);
    };

    const handleSaveRuleEditClick = () => {
        if (!subchannelRuleFormIsValid(subchannelRuleToEdit)) {
            return;
        }

        subchannelRulePatchMutation.mutate();
    };

    const handleSaveRuleAddClick = async () => {
        if (!subchannelRuleFormIsValid(subchannelRuleToEdit)) {
            return;
        }

        if (!subchannelRuleToEdit) {
            return;
        }

        subchannelRulePostMutation.mutate();
    };

    const handleEditPriorityOrderClick = () => {
        if (tableRows.length === 0) return;
        setEditingTableOrder(true);

        // Reset the selected rows
        setTableRows(
            tableRows.map(rule => {
                return {
                    ...rule,
                    selected: false,
                };
            })
        );
    };

    const handleSavePriorityOrderClick = () => {
        if (tableRows.length === 0) return;

        setEditingTableOrder(false);

        const changedPriorityRows = getIndexedUpdatedTableRows({ tableRows, tableRowsSnapshot });

        if (changedPriorityRows.length === 0) return;

        setSubchannelRulesToEdit(changedPriorityRows.map(row => ({ priority: row.priority })));
        setSubchannelRulesIdsToEdit(changedPriorityRows.map(row => row.id.toString()));
    };

    const handleCancelPriorityOrderClick = () => {
        setEditingTableOrder(false);
        setTableRows(tableRowsSnapshot);
    };

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) return;

        const { source, destination } = result;

        const updatedTableRows = reorderTableRows({
            tableRows,
            source: source.index,
            destination: destination.index,
        });

        setTableRows(updatedTableRows);
    };

    return (
        <>
            <h2>Edit Rules</h2>
            <p>
                Add, edit, or remove Subchannels rules, and specify their priority. Each Rule must contain a unique
                combination of conditions.
            </p>

            {subchannelRulesQuery.status === 'success' || subchannelRulesQuery.status === 'pending' ? (
                <ConfigDragAndDropTable
                    status={subchannelRulesQuery.status}
                    isFetching={
                        subchannelRulesQuery.isFetching ||
                        subchannelRulePatchMutation.isPending ||
                        subchannelRulesPatchMutation.isPending ||
                        subchannelRulePostMutation.isPending ||
                        subchannelRuleDeleteMutation.isPending
                    }
                    disabled={!!subchannelRuleToEdit}
                    empty={tableRows.length === 0}
                    dragDisabled={!editingTableOrder}
                >
                    <ConfigDragAndDropTable.Table maxHeight="400px" onDragEnd={onDragEnd}>
                        <ConfigDragAndDropTable.Header>
                            <ConfigDragAndDropTable.Row key="table-header" index={0}>
                                <ConfigDragAndDropTable.CellHeader />
                                <ConfigDragAndDropTable.CellHeader>Rule Name</ConfigDragAndDropTable.CellHeader>
                                <ConfigDragAndDropTable.CellHeader>Rule Description</ConfigDragAndDropTable.CellHeader>
                                <ConfigDragAndDropTable.CellHeader />
                            </ConfigDragAndDropTable.Row>
                        </ConfigDragAndDropTable.Header>
                        <ConfigDragAndDropTable.Body>
                            {tableRows.map((rule, index) => {
                                return (
                                    <ConfigDragAndDropTable.Row key={rule.id} index={index} row={rule}>
                                        {editingTableOrder ? (
                                            <ConfigDragAndDropTable.CellDragAndDrop isDragging={false} />
                                        ) : (
                                            <ConfigDragAndDropTable.CellCheckbox
                                                rowId={rule.id}
                                                checked={rule.selected}
                                                onCheckedChange={handleRulesListCheckboxChange}
                                            />
                                        )}
                                        <ConfigDragAndDropTable.Cell>{rule.name}</ConfigDragAndDropTable.Cell>
                                        <ConfigDragAndDropTable.Cell>{rule.description}</ConfigDragAndDropTable.Cell>
                                        <ConfigDragAndDropTable.CellActions disabled={editingTableOrder}>
                                            <ConfigDragAndDropTable.ActionDropdownItem
                                                rowId={rule.id}
                                                type="edit"
                                                onClick={handleSingleRuleEditClick}
                                            />
                                            <ConfigDragAndDropTable.ActionDropdownItem
                                                rowId={rule.id}
                                                type="delete"
                                                onClick={handleSingleRuleDeleteClick}
                                            />
                                        </ConfigDragAndDropTable.CellActions>
                                    </ConfigDragAndDropTable.Row>
                                );
                            })}
                        </ConfigDragAndDropTable.Body>
                    </ConfigDragAndDropTable.Table>

                    <ConfigDragAndDropTable.ActionBar>
                        <ConfigDragAndDropTable.ActionBarJustifyLeft>
                            {editingTableOrder ? (
                                <>
                                    <ConfigDragAndDropTable.ActionButton
                                        type="save"
                                        label="Save Priority Order"
                                        onClick={handleSavePriorityOrderClick}
                                        isDisabled={tableRows.length === 0}
                                    />
                                    <ConfigDragAndDropTable.ActionButton
                                        type="cancel"
                                        label="Cancel"
                                        onClick={handleCancelPriorityOrderClick}
                                    />
                                </>
                            ) : (
                                <ConfigDragAndDropTable.ActionButton
                                    type="primary"
                                    label="Edit Priority Order"
                                    onClick={handleEditPriorityOrderClick}
                                    isDisabled={tableRows.length === 0}
                                />
                            )}

                            {!editingTableOrder && (
                                <ConfigDragAndDropTable.ActionButton
                                    type="delete"
                                    label="Delete Rules"
                                    onClick={handleBulkRulesDeleteClick}
                                    isDisabled={subchannelRulesToDelete.length === 0}
                                />
                            )}
                        </ConfigDragAndDropTable.ActionBarJustifyLeft>
                        {!editingTableOrder && (
                            <ConfigDragAndDropTable.ActionBarJustifyRight>
                                <ConfigDragAndDropTable.ActionButton
                                    type="add"
                                    label="Add Rule"
                                    onClick={handleCreateNewRule}
                                    isDisabled={editingTableOrder}
                                />
                                <ConfigDragAndDropTable.ActionButton
                                    type="primary"
                                    label="Cancel"
                                    onClick={handleCancleSubchannelRuleClick}
                                />
                            </ConfigDragAndDropTable.ActionBarJustifyRight>
                        )}
                    </ConfigDragAndDropTable.ActionBar>
                </ConfigDragAndDropTable>
            ) : null}

            {subchannelRuleToEdit && (
                <>
                    <StyledFormContainer>
                        <FormTextInput
                            inputPlaceholder="Enter a name for your Rule"
                            label="Rule Name:"
                            toolTipCopy="Enter a name for your rule"
                            inputOnChange={handleRuleNameChange}
                            inputValue={subchannelRuleToEdit?.name}
                            requiredField={true}
                            errorMessage={subchannelRuleErrorMessage}
                        />
                        <FormTextArea
                            requiredField={false}
                            label="Rule Description:"
                            toolTipCopy="Enter a description for your rule"
                            inputValue={subchannelRuleToEdit?.description}
                            inputOnChange={handleRuleDescriptionChange as ChangeEventHandler}
                        />
                    </StyledFormContainer>

                    {showSelectPriority && (
                        <StyledSelectPriorityContainer>
                            <p>
                                Please select where you would like your rule to appear in the priority list. If you
                                don't select a position for your new rule it will be added to the bottom of the list.
                            </p>
                            <StyledPriorityFormContainer>
                                <StyledSelectPriorityDropdown>
                                    <DropdownWithSearch
                                        isAccordionForm={false}
                                        dropdownItems={[
                                            { label: 'Before', value: 'before' },
                                            { label: 'After', value: 'after' },
                                        ]}
                                        placeholder={'Select a position'}
                                        label={'Position:'}
                                        toolTipCopy={'Select the priority position for your rule'}
                                        requiredField={false}
                                        selected={selectedPosition}
                                        onSelectedItemChange={handleSelectedPosition}
                                    />
                                </StyledSelectPriorityDropdown>
                                <StyledSelectPriorityDropdown>
                                    <DropdownWithSearch
                                        isAccordionForm={false}
                                        dropdownItems={tableRows.map(row => {
                                            return {
                                                label: row.name,
                                                value: row.priority,
                                            };
                                        })}
                                        placeholder={'Select a Rule'}
                                        label={'Rule:'}
                                        toolTipCopy={
                                            'Select the rule you would like your new rule to appear before/after'
                                        }
                                        requiredField={false}
                                        selected={selectedRule}
                                        onSelectedItemChange={handleSelectedRule}
                                    />
                                </StyledSelectPriorityDropdown>
                            </StyledPriorityFormContainer>
                        </StyledSelectPriorityContainer>
                    )}

                    <SegmenterRuleBuilder
                        data={[]}
                        isFrom="Edit"
                        segmentName={subchannelRuleToEdit?.name}
                        onPatternChange={handlePatternChange}
                        rules={rule}
                        isNotUniqueRule={isNotUniqueRule}
                    />

                    {showCreateNewSegment ? (
                        <StyledButtonContainer>
                            <StyledAddConditionButtonContainer>
                                <InputButton
                                    value="Create New Condition"
                                    onClick={() => dispatch(setModal('SegmenterEditConditions'))}
                                />
                            </StyledAddConditionButtonContainer>

                            <StyledSaveActionsButtonsContainer>
                                <InputButton
                                    value="Save"
                                    buttonTheme={ButtonThemes.Primary}
                                    onClick={handleSaveRuleAddClick}
                                    isLoading={subchannelRulePostMutation.isPending}
                                    disabled={isNotUniqueRule}
                                />
                                <InputButton
                                    value="Cancel"
                                    buttonTheme={ButtonThemes.Secondary}
                                    onClick={handleCancelRuleEditClick}
                                />
                            </StyledSaveActionsButtonsContainer>
                        </StyledButtonContainer>
                    ) : (
                        <StyledButtonContainer>
                            <StyledAddConditionButtonContainer>
                                <InputButton
                                    value="Create New Condition"
                                    onClick={() => dispatch(setModal('SegmenterEditConditions'))}
                                />
                            </StyledAddConditionButtonContainer>
                            <StyledSaveActionsButtonsContainer>
                                <InputButton
                                    value="Save"
                                    buttonTheme={ButtonThemes.Primary}
                                    onClick={handleSaveRuleEditClick}
                                    isLoading={subchannelRulePatchMutation.isPending}
                                    disabled={isNotUniqueRule}
                                />
                                <InputButton
                                    value="Cancel"
                                    buttonTheme={ButtonThemes.Secondary}
                                    onClick={handleCancelRuleEditClick}
                                />
                            </StyledSaveActionsButtonsContainer>
                        </StyledButtonContainer>
                    )}
                </>
            )}
        </>
    );
};

export default ManageSubchannelsRules;
