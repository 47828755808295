import React from 'react';
import styled from 'styled-components';

const StyledLoadingBar = styled.div`
    height: 10px;
    background: ${props => props.theme.colours.green};
    position: relative;
    margin-top: 6px;
    animation: infinite-move 2s linear infinite;
    @keyframes infinite-move {
        0% {
            left: 0%;
            right: 100%;
            width: 0%;
        }
        10% {
            left: 0%;
            right: 75%;
            width: 75%;
        }
        90% {
            right: 0%;
            left: 75%;
            width: 25%;
        }
        100% {
            left: 100%;
            right: 0%;
            width: 0%;
        }
    }
`;

const LoadingBar = () => {
    return <StyledLoadingBar />;
};
export default LoadingBar;
