import Request from '../../helpers/request';
import { ComparisonTableData, TableData } from '../types';
import { buildParams } from './build-params';
import { RequestParams } from '../../helpers/request-builder';

type exportTableDataArgs = {
    dates: {
        startDate: string;
        endDate: string;
        comparisonStartDate: string;
        comparisonEndDate: string;
    };
    data: TableData | ComparisonTableData;
    title: string;
};

export const exportTableData = async ({ dates, data, title }: exportTableDataArgs) => {
    const request = new Request();

    if (data.status === 'success' && data.resource && data.request) {
        const promises = [];
        const params = getParams(dates.startDate, dates.endDate, data);

        if (params) {
            promises.push(
                request.download(
                    data.resource.category,
                    data.resource.id,
                    [params],
                    'all',
                    `${title} - ${data.resource.id}.csv`
                )
            );
        }

        if (data.type === 'tableComparison') {
            const comparisonParams = getParams(dates.comparisonStartDate, dates.comparisonEndDate, data);

            if (comparisonParams) {
                promises.push(
                    request.download(
                        data.resource.category,
                        data.resource.id,
                        [comparisonParams],
                        'all',
                        `${title} -${data.resource.id}-comparison.csv`
                    )
                );
            }
        }

        return Promise.all(promises);
    }
};

const getParams = (startDate: string, endDate: string, data: TableData | ComparisonTableData) => {
    if (data.status === 'success' && data.resource && data.request) {
        let params = buildParams({
            resource: data.resource,
            request: data.request,
            startDate: startDate,
            endDate: endDate,
            isComparison: false,
            granularity: 'day',
        });

        params.push({ key: 'format', value: 'csv' });

        params = params.map(param => {
            if (param.key === 'limit') {
                return { key: 'limit', value: 0 };
            }
            if (param.key === 'offset') {
                return { key: 'offset', value: 0 };
            }
            return param;
        }) as RequestParams;

        return params;
    }
};
