import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType } from './common/chart-types';
import dateStringToUnix from '../helpers/date-string-to-unix';

export const config = {
    pageTitle: 'Page Tags Dashboard',
    // reportDescription: 'tbc',
    resources: [resources.REPORT_PAGE_TAGS],
    ignoreProducts: true,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
        },
    },
    chart: {
        chartType: ChartType.Line,
        allowedChartTypes: [],
        chartId: 'report-page-tags',
        allYMetrics: [
            fields.VISITS,
            fields.TITLE_LENGTH,
            fields.DESCRIPTION_LENGTH,
            fields.H1_LENGTH,
            fields.H1_DUPLICATE,
            fields.H2_LENGTH,
        ],
        dataFormatter: function (_, datas, dimension, metric) {
            return datas.map(data => {
                return [dateStringToUnix(data[dimension.rawName].raw_value), data[metric].raw_value];
            });
        },
        initialYMetrics: [fields.VISITS],
    },
    table: {
        tabListItems: [
            {
                tabName: 'Meta Data',
                displayName: 'Meta Data',
                isSelected: true,
                metrics: [fields.VISITS, fields.TITLE_LENGTH, fields.DESCRIPTION_LENGTH],
            },
            {
                tabName: 'Header Tags',
                displayName: 'Header Tags',
                isSelected: true,
                metrics: [fields.H1_LENGTH, fields.H1_DUPLICATE, fields.H2_LENGTH],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.PATH_HOST.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_PATH,
                    },
                ],
            },
            [fields.PATH_GEOGRAPHY.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_LANGUAGE,
                    },
                ],
            },
            [fields.PATH_MARKET.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_LANGUAGE,
                    },
                ],
            },
            [fields.PATH_LANGUAGE.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_CONTENT_TYPE.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_CONTENT_LENGTH,
                    },
                ],
            },
            [fields.PATH_CATEGORY.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_CONTENT_TYPE,
                    },
                ],
            },
            [fields.PATH_CONTENT_LENGTH.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_TOPIC.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PAGE_TITLE,
                    },
                ],
            },
            [fields.PAGE_TITLE.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.META_DESCRIPTION,
                    },
                ],
            },
            [fields.META_DESCRIPTION.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.H1,
                    },
                ],
            },
            [fields.H1.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.H2,
                    },
                ],
            },
            [fields.CONTAINS_SCHEMA.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_HOST,
                    },
                ],
            },
            [fields.JSON.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_HOST,
                    },
                ],
            },
            [fields.MICRODATA.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_HOST,
                    },
                ],
            },
            [fields.RDFA.id]: {
                default: [
                    {
                        resource: resources.REPORT_PAGE_TAGS,
                        field: fields.PATH_HOST,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
