// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import {
    isAlphaNumericString,
    isAlphaString,
    isUrl,
    isAlphaNumericSpecialCharacterString,
} from '../../helpers/validator';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { removeModal, setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';
import Request from '../../helpers/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';

// Import Remove Duplicates
import { removeDuplicates } from '../../helpers/utils';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

class LayoutModalManageMagento extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageError: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: false,
            saveChangesButtonDisabled: false,
            // Accordion States
            editMagentoConnnectionAccordionOpen: false,
            editMagentoConnnectionAccordionVisible: false,
            editMagentoConfigAccordionOpen: false,
            editMagentoConfigAccordionVisible: false,
            // Magento Connection List States
            removeButtonState: true,
            removeButtonDisabled: true,
            editMagentoConnnectionButtonDisabled: true,
            editMagentoConfigButtonDisabled: true,
            // Edit Magento Connection States
            editMagentoConnnectionApplyButtonDisabled: true,
            editMagentoConnnectionCancelButtonDisabled: true,

            // Edit Magento Config States
            editMagentoConfigApplyDisabled: true,
            editMagentoConfigCancelDisabled: true,
            // Disable Table States
            disableMagentoConnnectionSelect: false,

            //Test Magento
            previousMagentoResponseMessage: [],
            previousMessage: [],
            magentoResponseMessage: [],
            isAccessTokenIncorrect: false,
            isStoreCodeIncorrect: false,
            isTestLoading: false,
            isTestFinished: true,
            isUpdatedMagentoTestLoading: false,
            bufferingMessage: '',
            guideMessage: [],
            isServerUrlResponseError: false,
            isMagentoDetailsResponseError: false,
            isBuffering: false,
            getStoreCodeBufferingMessage: '',
            isGetStoreCodeIncorrect: false,
            storeCodeResponseErrorMessage: '',
            editManStoreCodeErrorMessage: '',
            dbStoreCode: '',
            controller: [],
            // List States
            // 'magentoConnection': [],
            magentoConfig: [],
            selectedMagentoConfig: [],
            selectedMagentoConnection: [],
            selectedMagentoConnectionMessage: '',
            removedMagentoConnection: [],
            updatedMagentoConnection: [],
            updatedMagentoConfig: [],
            // Input Field States
            selectedConnectionId: '',
            selectedConfigId: '',
            editConsumerKey: '',
            editConsumerSecret: '',
            editAccessToken: '',
            editAccessTokenSecret: '',
            editServerUrl: '',
            editStoreCode: '',
            editManStoreCode: '',

            storeCodeOptions: [],
            editCurrencyCode: '',
            editSaleEvent: '',
            saleEventProductEventOptions: [],

            editConsumerKeyErrorMessage: '',
            editConsumerSecretErrorMessage: '',
            editAccessTokenErrorMessage: '',
            editAccessTokenSecretErrorMessage: '',
        };
    }

    componentDidMount() {
        const config = [
            {
                resourceGroup: 'config',
                resourceName: 'magento-connection',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
            {
                resourceGroup: 'config',
                resourceName: 'product-event',
                params: [
                    {
                        key: 'product__product_type__id',
                        value: 2,
                    },
                    {
                        key: 'active',
                        value: 1,
                    },
                    {
                        key: 'sale',
                        value: 1,
                    },
                ],
            },
        ];

        this.props.getRequest(config);
        this.beginMagentoTest();
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                // Sorting Out Magento Connection
                const magentoConnection = this.props.request.data[0].objects.map(magentoConnection => {
                    return {
                        id: magentoConnection.id,
                        consumerKey: magentoConnection.consumer_key,
                        consumerSecret: magentoConnection.consumer_secret,
                        accessToken: magentoConnection.access_token,
                        accessTokenSecret: magentoConnection.access_token_secret,
                    };
                });

                // Manipulate the ProductEvent to work with a select field
                let productOptions = this.props.request.data[1].objects
                    .filter(object => object.product.product_type.hidden_config === false)
                    .map(productEvent => {
                        return {
                            keyValue: `product-event-option_${productEvent.event.name.replace(/ /g, '')}`,
                            value: productEvent.event.name,
                            name: productEvent.event.name,
                        };
                    });

                productOptions = removeDuplicates(productOptions, 'name');

                // For cancelling ongoing Magento Test endpoint requests
                const controller = new AbortController();

                this.setState({
                    isLoading: false,
                    magentoConnection: magentoConnection,
                    saleEventProductEventOptions: productOptions,
                    controller: controller,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }

            this.props.delRequest();
        }

        // Enable the save changes and cancel when a user is added into either the revoked or updated arrays
        if (
            this.state.saveChangesButtonHidden &&
            (this.state.updatedMagentoConnection.length > 0 ||
                this.state.removedMagentoConnection.length > 0 ||
                this.state.updatedMagentoConfig.length > 0)
        ) {
            this.setState({
                saveChangesButtonHidden: false,
                closeButtonState: 'cancel',
            });
        }
    }

    formValidatorMagentoConfig = () => {
        this.setState({
            editServerUrlErrorMessage: '',
            editStoreCodeErrorMessage: '',
            editManStoreCodeErrorMessage: '',
            editCurrencyCodeErrorMessage: '',
            editSaleEventErrorMessage: '',
        });

        let hasFormError = false;
        const errorMessageObject = {
            editServerUrlErrorMessage: '',
            editStoreCodeErrorMessage: '',
            editManStoreCodeErrorMessage: '',
            editCurrencyCodeErrorMessage: '',
            editSaleEventErrorMessage: '',
        };

        if (!isUrl(this.state.editServerUrl) || this.state.editServerUrl.length === 0) {
            hasFormError = true;
            errorMessageObject.editServerUrlErrorMessage = 'Please enter a valid Server Url.';
        }

        if (!isAlphaNumericSpecialCharacterString(this.state.editManStoreCode)) {
            hasFormError = true;
            errorMessageObject.editManStoreCodeErrorMessage = 'Please enter a valid Store Code.';
        }

        if (!isAlphaString(this.state.editCurrencyCode) || this.state.editCurrencyCode.length === 0) {
            hasFormError = true;
            errorMessageObject.editCurrencyCodeErrorMessage = 'Please enter a valid Currency Code.';
        }

        if (!isAlphaNumericString(this.state.editSaleEvent) || this.state.editSaleEvent.length === 0) {
            hasFormError = true;
            errorMessageObject.editSaleEventErrorMessage = 'Please enter a valid Server Url.';
        }

        if (hasFormError) {
            this.setState(errorMessageObject);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    formValidatorMagentoConnection = () => {
        this.setState({
            editConsumerKeyErrorMessage: '',
            editConsumerSecretErrorMessage: '',
            editAccessTokenErrorMessage: '',
            editAccessTokenSecretErrorMessage: '',
        });

        let hasFormError = false;
        const errorMessageObject = {
            editConsumerKeyErrorMessage: '',
            editConsumerSecretErrorMessage: '',
            editAccessTokenErrorMessage: '',
            editAccessTokenSecretErrorMessage: '',
        };

        if (
            !isAlphaNumericSpecialCharacterString(this.state.editConsumerKey) ||
            this.state.editConsumerKey.length === 0
        ) {
            hasFormError = true;
            errorMessageObject.editConsumerKeyErrorMessage = 'Please enter a valid Consumer Key.';
        }

        if (
            !isAlphaNumericSpecialCharacterString(this.state.editConsumerSecret) ||
            this.state.editConsumerSecret.length === 0
        ) {
            hasFormError = true;
            errorMessageObject.editConsumerSecretErrorMessage = 'Please enter a valid Consumer Secret.';
        }

        if (
            !isAlphaNumericSpecialCharacterString(this.state.editAccessToken) ||
            this.state.editAccessToken.length === 0
        ) {
            hasFormError = true;
            errorMessageObject.editAccessTokenErrorMessage = 'Please enter a valid Access Token.';
        }

        if (
            !isAlphaNumericSpecialCharacterString(this.state.editAccessTokenSecret) ||
            this.state.editAccessTokenSecret.length === 0
        ) {
            hasFormError = true;
            errorMessageObject.editAccessTokenSecretErrorMessage = 'Please enter a valid Access Token Secret.';
        }

        if (hasFormError) {
            this.setState({
                editConsumerKeyErrorMessage: errorMessageObject.editConsumerKeyErrorMessage,
                editConsumerSecretErrorMessage: errorMessageObject.editConsumerSecretErrorMessage,
                editAccessTokenErrorMessage: errorMessageObject.editAccessTokenErrorMessage,
                editAccessTokenSecretErrorMessage: errorMessageObject.editAccessTokenSecretErrorMessage,
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    saveMagentoConnections = () => {
        return new Promise((resolve, reject) => {
            const requests = [];

            if (this.state.updatedMagentoConnection.length > 0) {
                this.state.updatedMagentoConnection.forEach(magentoConnection => {
                    // updated the magento connection
                    requests.push(
                        Axios({
                            method: 'PUT',
                            url: generateUrlDetail('config', 'magento-connection', magentoConnection.id),
                            data: {
                                consumer_key: magentoConnection.consumerKey,
                                consumer_secret: magentoConnection.consumerSecret,
                                access_token: magentoConnection.accessToken,
                                access_token_secret: magentoConnection.accessTokenSecret,
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                });
            }

            if (this.state.updatedMagentoConfig.length > 0) {
                this.state.updatedMagentoConfig.forEach(magentoConnection => {
                    // updated the magento connection
                    requests.push(
                        Axios({
                            method: 'PUT',
                            url: generateUrlDetail('config', 'magento-config', magentoConnection.id),
                            data: {
                                server_url: magentoConnection.serverUrl,
                                store_code: magentoConnection.storeCode,
                                currency_code: magentoConnection.currencyCode,
                                sale_event: magentoConnection.saleEvent,
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                });
            }

            if (this.state.removedMagentoConnection.length > 0) {
                this.state.removedMagentoConnection.forEach(magentoConnection => {
                    requests.push(
                        Axios({
                            method: 'DELETE',
                            url: generateUrl('config', 'magento-config', [
                                { key: 'connection', value: magentoConnection.id },
                            ]),
                            withCredentials: true,
                        })
                    );

                    requests.push(
                        Axios({
                            method: 'PUT',
                            url: generateUrlDetail('config', 'magento-connection', magentoConnection.id),
                            data: { deleted: true, active: false },
                            withCredentials: true,
                        })
                    );
                });
            }

            Axios.all(requests)
                .then(
                    Axios.spread((...requestRes) => {
                        // When a Product is deleted, all the links between the goal and event must be removed
                        let magentoConfigToDelete = [];

                        requestRes.forEach(res => {
                            // If the res was from a get request getting the product event values for a certain product
                            if (/magentoConfig/.test(res.config.url) && res.config.method === 'get') {
                                magentoConfigToDelete = magentoConfigToDelete.concat(res.data.objects);
                            }
                        });

                        // If no eventProducts were found this means no products were deleted.
                        if (magentoConfigToDelete.length === 0) {
                            resolve();
                        }
                    })
                )
                .catch(error => {
                    reject();
                });
        });
    };

    onSaveChangesClick = () => {
        this.setState({
            closeButtonDisabled: true,
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: true,
        });

        this.saveMagentoConnections()
            .then(() => {
                let updatedMagConnection = [];

                (async () => {
                    await Axios({
                        method: 'GET',
                        url: generateUrl('config', 'magento-connection', [{ key: 'active', value: 1 }]),
                        withCredentials: true,
                    }).then(reqrespo => {
                        updatedMagConnection = reqrespo.data?.objects.map(magentoConnection => {
                            return {
                                id: magentoConnection.id,
                                consumerKey: magentoConnection.consumer_key,
                                consumerSecret: magentoConnection.consumer_secret,
                                accessToken: magentoConnection.access_token,
                                accessTokenSecret: magentoConnection.access_token_secret,
                            };
                        });
                        this.setState({
                            magentoConnection: updatedMagConnection,
                        });
                    });
                })();

                this.setState({
                    closeButtonDisabled: false,
                    saveChangesButtonHidden: false,
                    saveChangesButtonLoading: false,
                    saveChangesButtonDisabled: true,
                    isTestFinished: !this.state.isTestFinished,
                    isTestLoading: !this.state.isTestLoading,
                    isUpdatedMagentoTestLoading: true,
                    bufferingMessage: 'Test is in progress...',
                    closeButtonState: 'close',
                });

                setTimeout(() => {
                    this.forceUpdate();
                }, 600);

                this.props.addNotification({
                    copy: 'The modifications to the Magento Key were made successfully.',
                    type: NotificationMessageType.Success,
                });

                let updatedMagentoConnections = [
                    ...this.state.updatedMagentoConnection,
                    ...this.state.updatedMagentoConfig,
                ].filter((obj, pos, connections) => {
                    return connections.map(conn => conn.id).indexOf(obj.id) === pos;
                });

                this.updatedMagentoTest(updatedMagentoConnections);
            })
            .catch(() => {
                this.props.addNotification({
                    copy: 'There was an issue modifying these Magento Key, please try again later.',
                    type: NotificationMessageType.Error,
                });
                this.setState({
                    closeButtonDisabled: false,
                    saveChangesButtonHidden: false,
                    saveChangesButtonLoading: false,
                });
            });
    };

    onCloseClick = () => {
        if (this.state.closeButtonState === 'close') {
            this.props.removeModal();

            // Cancel ongoing Magento Test endpoint requests
            this.state.controller.abort();

            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            });
        }
    };

    // Manage the input fields changes and updating the state with the new value entered by the user
    onMagentoEditConsumerKeyChange = event => {
        this.setState({
            editConsumerKey: event.target.value,
        });
    };
    onMagentoEditConsumerSecretChange = event => {
        this.setState({
            editConsumerSecret: event.target.value,
        });
    };
    onMagentoEditAccessTokenChange = event => {
        this.setState({
            editAccessToken: event.target.value,
        });
    };
    onMagentoEditAccessTokenSecretChange = event => {
        this.setState({
            editAccessTokenSecret: event.target.value,
        });
    };
    onMagentoEditServerUrlChange = event => {
        const value = event.target.value;
        if (value.length !== 0 && isUrl(value)) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getStores(value);
            }, 2500);
        }
        this.setState({
            editServerUrl: value,
        });
    };
    onMagentoEditStoreCodeChange = event => {
        const selectedStoreCode = this.state.storeCodeOptions.filter(code => code.name === event.target.value)[0]?.code;
        this.setState({
            dbStoreCode: selectedStoreCode,
            editStoreCode: event.target.value,
        });
    };
    onMagentoEditManualStoreCodeChange = event => {
        this.setState({
            editManStoreCode: event.target.value,
        });
    };
    onMagentoEditCurrencyCodeChange = event => {
        this.setState({
            editCurrencyCode: event.target.value,
        });
    };
    onMagentoEditSaleEventChange = event => {
        this.setState({
            editSaleEvent: event.target.value,
        });
    };
    ///

    onAccordionCloseClick = () => {
        // Clear and reset all the user interactions but maintaining what have been already applied
        this.setState({
            editMagentoConnnectionAccordionOpen: false,
            editMagentoConnnectionAccordionVisible: false,
            editMagentoConfigAccordionOpen: false,
            editMagentoConfigAccordionVisible: false,
            editMagentoConnnectionButtonDisabled: true,
            editMagentoConfigButtonDisabled: true,
            editMagentoConnnectionApplyButtonDisabled: true,
            editMagentoConnnectionCancelButtonDisabled: true,
            disableMagentoConnnectionSelect: false,
            editMagentoConfigApplyDisabled: true,
            isGetStoreCodeIncorrect: false,
            // 'magentoConfig': [],
            selectedMagentoConfig: [],
            selectedMagentoConnection: [],
            editConsumerKey: '',
            editConsumerSecret: '',
            editAccessToken: '',
            editAccessTokenSecret: '',
            editServerUrl: '',
            editStoreCode: '',
            editCurrencyCode: '',
            editSaleEvent: '',
        });
    };

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick = () => {
        this.props.removePopup();
        this.props.removeModal();
        // Cancel ongoing Magento Test endpoint requests
        this.state.controller.abort();
    };

    // SecondaryAction of the close popup
    onPopupStayHereClick = () => {
        this.props.removePopup();
    };

    handleOnAdd = () => {
        this.props.setModal('AddMagento', {});
        // Cancel ongoing Magento Test endpoint requests
        this.state.controller.abort();
    };

    onEditMagentoConnectionClick = () => {
        let magentoConnection = this.state.selectedMagentoConnection[0];
        const magentoConnectionInUpdatedMagentoConnection = this.state.updatedMagentoConnection.filter(
            filteredmagentoConnection => filteredmagentoConnection.id === magentoConnection.id
        );

        if (magentoConnectionInUpdatedMagentoConnection.length === 1) {
            magentoConnection = magentoConnectionInUpdatedMagentoConnection[0];
        }

        this.setState({
            editMagentoConnnectionAccordionOpen: true,
            editMagentoConnnectionAccordionVisible: true,
            editMagentoConfigAccordionOpen: false,
            editMagentoConfigAccordionVisible: false,
            editMagentoConnnectionButtonDisabled: true,
            editMagentoConfigButtonDisabled: true,
            editMagentoConnnectionCancelButtonDisabled: false,
            editMagentoConnnectionApplyButtonDisabled: false,
            removeButtonDisabled: true,
            disableMagentoConnnectionSelect: true,
            selectedConnectionId: this.state.selectedMagentoConnection[0].id,
            editConsumerKey: this.state.selectedMagentoConnection[0].consumerKey,
            editConsumerSecret: this.state.selectedMagentoConnection[0].consumerSecret,
            editAccessToken: this.state.selectedMagentoConnection[0].accessToken,
            editAccessTokenSecret: this.state.selectedMagentoConnection[0].accessTokenSecret,
        });
    };

    getStoreCodes = async params => {
        await Axios({
            method: 'GET',
            url: `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/magento/get-stores`,
            withCredentials: true,
            params: params,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.status_code === 200) {
                    let storeCodeList = response.data.data.map(code => {
                        return {
                            id: code.id,
                            name: code.name,
                            code: code.code,
                        };
                    });
                    this.setState({
                        storeCodeOptions: storeCodeList,
                        isStoreCodeIncorrect: false,
                        isServerUrlResponseError: false,
                        isMagentoDetailsResponseError: false,
                        saveChangesButtonDisabled: false,
                        isBuffering: false,
                        getStoreCodeBufferingMessage: '',
                    });
                } else if (response.data.status_code === 500) {
                    // For Host URL validation when incorrect or spelled incorrectly
                    this.setState({
                        isServerUrlResponseError: true,
                        saveChangesButtonDisabled: true,
                        isBuffering: false,
                        getStoreCodeBufferingMessage: '',
                        serverUrlResponseErrorMessage: 'Host URL is incorrect',
                    });
                } else if (response.data.status_code === 400) {
                    // For invalid/empty details
                    this.setState({
                        isMagentoDetailsResponseError: true,
                        saveChangesButtonDisabled: true,
                        isBuffering: false,
                        getStoreCodeBufferingMessage: '',
                        magentoDetailsResponseErrorMessage:
                            '<p style="color:#d2004a">Magento details are not found or incorrect. Check for missing/incorrect Magento details and try again.</p>',
                    });
                } else {
                    this.setState({
                        storeCodeOptions: [{ id: '', name: 'No Store Codes Available', code: '' }],
                        isGetStoreCodeIncorrect: true,
                        isServerUrlResponseError: false,
                        isMagentoDetailsResponseError: false,
                        saveChangesButtonDisabled: true,
                        isBuffering: false,
                        getStoreCodeBufferingMessage: '',
                        storeCodeResponseErrorMessage:
                            "These credentials are not authorized to access the Magento API 'store' resource",
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    onEditMagentoConfigsClick = () => {
        const request = new Request();
        request
            .get('config', 'magento-config', [
                { key: 'connection', value: this.state.selectedMagentoConnection[0].id },
                { key: 'active', value: 1 },
            ])
            .then(res => {
                // Get all the magentoConfig that were in the DB
                let magentoConfig = res.data.objects.map(config => {
                    let connectionSplit = config.connection.split('/');

                    return {
                        id: config.id,
                        serverUrl: config.server_url,
                        storeCode: config.store_code,
                        currencyCode: config.currency_code,
                        saleEvent: config.sale_event,
                        connection: parseInt(connectionSplit[4]),
                    };
                });

                this.setState({
                    editMagentoConnnectionAccordionOpen: false,
                    editMagentoConnnectionAccordionVisible: false,
                    editMagentoConfigAccordionOpen: true,
                    editMagentoConfigAccordionVisible: true,
                    editMagentoConnnectionButtonDisabled: true,
                    editMagentoConfigButtonDisabled: true,
                    removeButtonDisabled: true,
                    editMagentoConnnectionApplyButtonDisabled: false,
                    disableMagentoConnnectionSelect: true,
                    editMagentoConfigApplyDisabled: false,
                    editMagentoConfigCancelDisabled: false,
                    selectedConfigId: this.state.selectedMagentoConnection[0].id,
                    selectedMagentoConfig: magentoConfig,
                });

                this.setState({
                    editServerUrl: magentoConfig[0].serverUrl,
                    editCurrencyCode: magentoConfig[0].currencyCode,
                    editSaleEvent: magentoConfig[0].saleEvent,
                });

                const params = {
                    access_token: this.state.selectedMagentoConnection[0].accessToken,
                    client_url: magentoConfig[0].serverUrl,
                };

                this.setState({
                    isBuffering: true,
                    getStoreCodeBufferingMessage: 'Checking API credentials...',
                });

                setTimeout(() => {
                    this.getStoreCodes(params);
                }, 600);
            });
    };

    onRemoveClick = () => {
        const removedDomains = [].concat(this.state.removedMagentoConnection);
        const updatedDomains = [].concat(this.state.updatedMagentoConnection);

        this.state.selectedMagentoConnection.forEach(magentoConnection => {
            if (this.state.removeButtonState) {
                removedDomains.push(magentoConnection);
            } else {
                removedDomains.splice(removedDomains.indexOf(magentoConnection), 1);
            }

            // If a user was edited and then revoked in the same instance then the revoke should take priority
            const updatedDomainIndex = updatedDomains.indexOf(magentoConnection);

            if (updatedDomainIndex >= 0) {
                updatedDomains.splice(updatedDomainIndex, 1);
            }
        });

        this.setState({
            removedMagentoConnection: removedDomains,
            updatedMagentoConnection: updatedDomains,
            selectedMagentoConnection: [],
            removeButtonState: true,
            editMagentoConnnectionButtonDisabled: true,
            editMagentoConfigButtonDisabled: true,
            removeButtonDisabled: true,
        });
    };

    onEditMagentoConnectionApplyClick = () => {
        if (!this.formValidatorMagentoConnection()) {
            return;
        }

        const updatedMagentoConnection = [].concat(this.state.updatedMagentoConnection);

        this.state.selectedMagentoConnection.forEach(magentoConnection => {
            magentoConnection = Object.assign({}, magentoConnection);

            // Check if any changes were actually made, if not ignore it
            if (
                magentoConnection.consumerKey !== this.state.editConsumerKey ||
                magentoConnection.consumerSecret !== this.state.editConsumerSecret ||
                magentoConnection.accessToken !== this.state.editAccessToken ||
                magentoConnection.accessTokenSecret !== this.state.editAccessTokenSecret
            ) {
                const magentoConnectionInUpdatedMagentoConnection = updatedMagentoConnection.filter(
                    filteredmagentoConnection => filteredmagentoConnection.id === magentoConnection.id
                );
                const indexOfMagentoConnection = updatedMagentoConnection.indexOf(
                    magentoConnectionInUpdatedMagentoConnection[0]
                );

                if (indexOfMagentoConnection >= 0) {
                    updatedMagentoConnection[indexOfMagentoConnection].consumerKey = this.state.editConsumerKey;
                    updatedMagentoConnection[indexOfMagentoConnection].consumerSecret = this.state.editConsumerSecret;
                    updatedMagentoConnection[indexOfMagentoConnection].accessToken = this.state.editAccessToken;
                    updatedMagentoConnection[indexOfMagentoConnection].accessTokenSecret =
                        this.state.editAccessTokenSecret;
                } else {
                    magentoConnection.consumerKey = this.state.editConsumerKey;
                    magentoConnection.consumerSecret = this.state.editConsumerSecret;
                    magentoConnection.accessToken = this.state.editAccessToken;
                    magentoConnection.accessTokenSecret = this.state.editAccessTokenSecret;

                    updatedMagentoConnection.push(magentoConnection);
                }
            }
        });

        this.setState({
            updatedMagentoConnection: updatedMagentoConnection,
        });

        // Call this function as it already handles resetting all the states
        this.onAccordionCloseClick();
    };

    onEditMagentoConfigsApplyClick = () => {
        if (!this.formValidatorMagentoConfig()) {
            return;
        }

        const updatedMagentoConfig = [].concat(this.state.updatedMagentoConfig);

        this.state.selectedMagentoConfig.forEach(magentoConfig => {
            magentoConfig = Object.assign({}, magentoConfig);
            // Check if any changes were actually made, if not ignore it
            if (
                magentoConfig.serverUrl !== this.state.editServerUrl ||
                magentoConfig.storeCode !== (this.state.dbStoreCode || this.state.editManStoreCode) ||
                magentoConfig.currencyCode !== this.state.editCurrencyCode ||
                magentoConfig.saleEvent !== this.state.editSaleEvent
            ) {
                const magentoConfigInUpdatedMagentoConfig = updatedMagentoConfig.filter(
                    filteredMagentoConfig => filteredMagentoConfig.id === magentoConfig.id
                );
                const indexOfMagentoConfig = updatedMagentoConfig.indexOf(magentoConfigInUpdatedMagentoConfig[0]);

                if (indexOfMagentoConfig >= 0) {
                    updatedMagentoConfig[indexOfMagentoConfig].serverUrl = this.state.editServerUrl;
                    updatedMagentoConfig[indexOfMagentoConfig].storeCode =
                        this.state.dbStoreCode || this.state.editManStoreCode;
                    updatedMagentoConfig[indexOfMagentoConfig].currencyCode = this.state.editCurrencyCode;
                    updatedMagentoConfig[indexOfMagentoConfig].saleEvent = this.state.editSaleEvent;
                } else {
                    magentoConfig.serverUrl = this.state.editServerUrl;
                    magentoConfig.storeCode = this.state.dbStoreCode || this.state.editManStoreCode;
                    magentoConfig.currencyCode = this.state.editCurrencyCode;
                    magentoConfig.saleEvent = this.state.editSaleEvent;

                    updatedMagentoConfig.push(magentoConfig);
                }
            }
        });

        this.setState({
            saveChangesButtonDisabled: false,
            updatedMagentoConfig: updatedMagentoConfig,
        });
        // Call this function as it already handles resetting all the states
        this.onAccordionCloseClick();
    };

    onMagentoConnectionSelect = event => {
        // Don't register a click if the table has been disabled
        if (this.state.disableMagentoConnnectionSelect === true) {
            return;
        }

        const selectedDomainId = parseInt(event.currentTarget.getAttribute('data-value'));

        let selectedDomains = [];

        if (
            this.state.selectedMagentoConnection.filter(magentoConnection => magentoConnection.id === selectedDomainId)
                .length > 0
        ) {
            selectedDomains = this.state.selectedMagentoConnection.filter(
                magentoConnection => magentoConnection.id !== selectedDomainId
            );
        } else {
            selectedDomains = this.state.selectedMagentoConnection.concat(
                this.state.magentoConnection.filter(magentoConnection => magentoConnection.id === selectedDomainId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRemoved = false;
        let containsNonRemoved = false;
        let containsEdited = false;

        selectedDomains.forEach(magentoConnection => {
            if (this.state.removedMagentoConnection.indexOf(magentoConnection) >= 0) {
                containsRemoved = true;
            } else {
                containsNonRemoved = true;
            }

            if (this.state.updatedMagentoConnection.indexOf(magentoConnection) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editMagentoConnnectionButtonDisabled = true;
        let editMagentoConfigButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;

        if (selectedDomains.length > 0) {
            editMagentoConnnectionButtonDisabled = false;
            editMagentoConfigButtonDisabled = false;
            removeButtonDisabled = false;
        }

        if (selectedDomains.length > 1) {
            editMagentoConnnectionButtonDisabled = true;
            editMagentoConfigButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRemoved && !containsNonRemoved && !containsEdited) {
            editMagentoConnnectionButtonDisabled = true;
            editMagentoConfigButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRemoved) {
            editMagentoConnnectionButtonDisabled = true;
            editMagentoConfigButtonDisabled = true;
        }

        if (containsRemoved && containsNonRemoved) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        this.setState({
            selectedMagentoConnection: selectedDomains,
            editMagentoConnnectionButtonDisabled: editMagentoConnnectionButtonDisabled,
            editMagentoConfigButtonDisabled: editMagentoConfigButtonDisabled,
            removeButtonDisabled: removeButtonDisabled,
            removeButtonState: removeButtonState,
            editMagentoConnnectionApplyButtonDisabled: true,
            editMagentoConnnectionAccordionOpen: false,
        });
    };

    testEndPoint = (connectionId, controller) => {
        return Axios({
            method: 'GET',
            signal: controller.signal,
            url: `${this.props.meta.apiDomain}/api/${this.props.account.token}/${connectionId}/integrations/magento/test-endpoint`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    };

    getTestEndPointResponse = (connectionId, response) => {
        let returnResponseMessage = {};
        let returnMessage = {};

        if (response.data.status_code === 200) {
            returnResponseMessage = {
                id: connectionId,
                status: 'Connected',
            };
            returnMessage = {
                id: connectionId,
                detailMessage: `Yesterday's sales count from the Magento API for this store code: <b>${response.data.data.total_count}</b>.
                If this is correct please click Apply, and then Save to complete this configuration.
                However if this looks wrong, please try a different store code, or contact us at <a href = "mailto: support@cubed.email">support@cubed.email</a>.`,
                connectionMessage: `Please confirm that the API credentials provided here have the correct permissions, including the "store resource", you can read more about it here:
                 <a href='https://devdocs.magento.com/guides/v2.4/rest/tutorials/prerequisite-tasks/create-admin-token.html' target='_blank'>Magento Documentation</a>`,
            };

            this.setState({
                isMagentoSuccess: true,
            });
        } else if (response.data.status_code === 400) {
            returnResponseMessage = {
                id: connectionId,
                status: 'Incorrect Magento Config',
            };

            returnMessage = {
                id: connectionId,
                storeCodeErrorMessage: 'Please enter a valid Store Code.',
                detailMessage: `Store code is incorrect or these API credentials do not have the correct permissions.
                Please check the store code selected here, and the Magento API Setup for this integration:
                <a href='https://docs.magento.com/user-guide/stores/stores-all-view-edit.html' target='_blank'>Magento Documentation</a>`,
                connectionMessage: 'Please check your Magento Details',
            };

            this.setState({
                isStoreCodeIncorrect: true,
            });
        } else if (response.data.status_code === 401) {
            returnResponseMessage = {
                id: connectionId,
                status: 'Incorrect Access Token',
            };

            returnMessage = {
                id: connectionId,
                accessTokenErrorMessage: 'Please enter a valid Access Token.',
                detailMessage: 'Please check your Magento Connection',
                connectionMessage: `Access Token is incorrect or these API credentials do not have the correct permissions.
                Please check the store code selected here, and the Magento API Setup for this integration:
                <a href='https://devdocs.magento.com/guides/v2.4/rest/tutorials/prerequisite-tasks/create-admin-token.html' target='_blank'>Magento Documentation</a>`,
            };

            this.setState({
                isAccessTokenIncorrect: true,
            });
        }
        return { returnResponseMessage, returnMessage };
    };

    beginMagentoTest = () => {
        this.setState({
            isTestLoading: true,
            isTestFinished: false,
            bufferingMessage: 'Test is in progress...',
            magentoResponseMessage: [],
            guideMessage: [],
        });

        let responseMessage = [];
        let message = [];

        const request = new Request();

        request.get('config', 'magento-connection', [{ key: 'active', value: 1 }]).then(response => {
            const connectionIds = response.data.objects.map(connection => {
                return {
                    id: connection.id,
                };
            });

            (async () => {
                for (let connection = 0; connection < connectionIds.length; connection++) {
                    this.setState({
                        isTestLoading: false,
                    });
                    await this.testEndPoint(connectionIds[connection].id, this.state.controller).then(response => {
                        let { returnResponseMessage, returnMessage } = this.getTestEndPointResponse(
                            connectionIds[connection].id,
                            response
                        );

                        responseMessage.push(returnResponseMessage);
                        message.push(returnMessage);
                    });

                    this.setState({
                        magentoResponseMessage: responseMessage,
                        guideMessage: message,
                    });

                    this.setState({
                        previousMagentoResponseMessage: this.state.magentoResponseMessage,
                        previousMessage: this.state.guideMessage,
                    });
                }
                this.setState({ isTestFinished: true, bufferingMessage: '' });
            })();
        });
    };

    updatedMagentoTest = updatedMagentoConnectionIds => {
        this.setState({
            isTestFinished: false,
        });

        let updatedStatus = this.state.magentoResponseMessage;
        updatedStatus.map(val => {
            var result = updatedMagentoConnectionIds.filter(id => id.id === val.id);
            if (result.length > 0) {
                val.status = 'Test is in progress...';
            }
            return val;
        });

        this.setState({
            previousMagentoResponseMessage: updatedStatus,
        });

        let responseMessage = [];
        let message = [];
        // Test for updated/modified magento connection.
        (async () => {
            for (let connection = 0; connection < updatedMagentoConnectionIds.length; connection++) {
                this.setState({
                    isTestLoading: false,
                    isUpdatedMagentoTestLoading: false,
                });
                await this.testEndPoint(updatedMagentoConnectionIds[connection].id, this.state.controller).then(
                    response => {
                        let { returnResponseMessage, returnMessage } = this.getTestEndPointResponse(
                            updatedMagentoConnectionIds[connection].id,
                            response
                        );

                        responseMessage.push(returnResponseMessage);
                        message.push(returnMessage);
                    }
                );

                // Update new response status messages keeping previously unchanged.

                let updatedResponseMessage = this.state.previousMagentoResponseMessage.map(val => {
                    var respo = responseMessage.filter(message => message.id === val.id);
                    if (respo.length > 0) {
                        val.status = respo[0].status;
                    }
                    return val;
                });

                // Update new messages keeping previously unchanged.
                let updatedMessage = this.state.previousMessage.map(val => {
                    var respo = message.filter(mes => mes.id === val.id);
                    if (respo.length > 0) {
                        val.detailMessage = respo[0].detailMessage;
                        val.connectionMessage = respo[0].connectionMessage;
                        val.storeCodeErrorMessage = respo[0].storeCodeErrorMessage;
                        val.accessTokenErrorMessage = respo[0].accessTokenErrorMessage;
                    }
                    return val;
                });

                this.setState({
                    magentoResponseMessage: updatedResponseMessage,
                    guideMessage: updatedMessage,
                });

                // this.setState({
                //     previousMagentoResponseMessage: this.state.magentoResponseMessage,
                //     previousMessage: this.state.guideMessage,
                // });
            }
            this.setState({
                isTestFinished: true,
                bufferingMessage: '',
                saveChangesButtonHidden: true,
                saveChangesButtonDisabled: false,
                updatedMagentoConnection: [],
                updatedMagentoConfig: [],
            });
        })();
    };

    renderModalNavigation = () => {
        let numberOfChanges =
            this.state.updatedMagentoConnection.length +
            this.state.updatedMagentoConfig.length +
            this.state.removedMagentoConnection.length;

        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: this.onSaveChangesClick,
                hidden: this.state.saveChangesButtonHidden,
                isLoading: this.state.saveChangesButtonLoading,
                disabled: this.state.saveChangesButtonDisabled,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    };

    renderAccordion = () => {
        if (this.state.editMagentoConnnectionAccordionVisible === true) {
            const accordion = [
                {
                    header: 'Edit Magento Connection',
                    required: false,
                    open: this.state.editMagentoConnnectionAccordionOpen,
                    type: 'form',
                    intro: this.state.isTestLoading
                        ? ``
                        : `<p>${
                              this.state.guideMessage.find(message => message.id === this.state.selectedConnectionId)
                                  ?.connectionMessage || 'Loading...'
                          }</p>`,
                    config: {
                        formConfig: {
                            fields: [
                                {
                                    label: 'Consumer Key:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter your magento consumer key.',
                                    inputKeyValue: 'editConsumerKey',
                                    inputPlaceholder: 'Consumer Key...',
                                    inputValue: this.state.editConsumerKey,
                                    inputOnChange: this.onMagentoEditConsumerKeyChange,
                                    errorMessage: this.state.editConsumerKeyErrorMessage,
                                },
                                {
                                    label: 'Consumer Secret:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter your magento consumer secret.',
                                    inputKeyValue: 'editConsumerSecret',
                                    inputPlaceholder: 'Consumer Secret...',
                                    inputValue: this.state.editConsumerSecret,
                                    inputOnChange: this.onMagentoEditConsumerSecretChange,
                                    errorMessage: this.state.editConsumerSecretErrorMessage,
                                },
                                {
                                    label: 'Access Token:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter your magento access token.',
                                    inputKeyValue: 'editAccessToken',
                                    inputPlaceholder: 'Access Token...',
                                    inputValue: this.state.editAccessToken,
                                    inputOnChange: this.onMagentoEditAccessTokenChange,
                                    errorMessage: this.state.isAccessTokenIncorrect
                                        ? this.state.guideMessage.find(
                                              message => message.id === this.state.selectedConnectionId
                                          )?.accessTokenErrorMessage
                                        : '',
                                },
                                {
                                    label: 'Access Token Secret:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter your magento access token secret.',
                                    inputKeyValue: 'editAccessTokenSecret',
                                    inputPlaceholder: 'Access Token Secret...',
                                    inputValue: this.state.editAccessTokenSecret,
                                    inputOnChange: this.onMagentoEditAccessTokenSecretChange,
                                    errorMessage: this.state.editAccessTokenSecretErrorMessage,
                                },
                            ],
                            buttons: [
                                {
                                    value: 'APPLY',
                                    onClick: this.onEditMagentoConnectionApplyClick,
                                    disabled: this.state.editMagentoConnnectionApplyButtonDisabled,
                                },
                                {
                                    value: 'CANCEL',
                                    buttonTheme: ButtonThemes.Secondary,
                                    onClick: this.onAccordionCloseClick,
                                    disabled: this.state.editMagentoConnnectionCancelButtonDisabled,
                                },
                            ],
                        },
                    },
                },
            ];

            return <WidgetAccordion accordions={accordion} />;
        } else if (this.state.editMagentoConfigAccordionVisible === true) {
            const accordions = [
                {
                    header: 'Edit Magento Details',
                    required: false,
                    open: this.state.editMagentoConfigAccordionOpen,
                    type: 'form',
                    intro: this.state.isTestLoading
                        ? ''
                        : `<p>${
                              this.state.guideMessage.find(message => message.id === this.state.selectedConfigId)
                                  ?.detailMessage || 'Loading...'
                          }</p>`,
                    config: {
                        formConfig: {
                            fields: [
                                {
                                    label: 'Server URL:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter your magento server url.',
                                    inputKeyValue: 'editServerUrl',
                                    inputPlaceholder: 'Server URL...',
                                    inputValue: this.state.editServerUrl,
                                    inputOnChange: this.onMagentoEditServerUrlChange,
                                    isLoading: this.state.isBuffering,
                                    errorMessage: this.state.isServerUrlResponseError
                                        ? this.state.serverUrlResponseErrorMessage
                                        : this.state.serverUrlErrorMessage,
                                    bufferingMessage: this.state.getStoreCodeBufferingMessage,
                                },
                                {
                                    label: 'Store Code:',
                                    type: 'select',
                                    requiredField: false,
                                    toolTipCopy: 'Enter your magento store code type.',
                                    inputKeyValue: 'editStoreCode',
                                    inputPlaceholder: 'Store Code...',
                                    customPlaceholder: this.state.storeCodeOptions[0]?.name,
                                    inputOptions: this.state.storeCodeOptions,
                                    inputValue: this.state.editStoreCode,
                                    inputOnChange: this.onMagentoEditStoreCodeChange,
                                    errorMessage: this.state.isGetStoreCodeIncorrect
                                        ? this.state.guideMessage.find(
                                              message => message.id === this.state.selectedConfigId
                                          )?.storeCodeErrorMessage
                                        : '',
                                },
                                // Following field will remain hidden when Server URL retrieves list of Store Code successfully.
                                // If not, it will appear below Store Code field for manual entry from user.
                                this.state.isGetStoreCodeIncorrect
                                    ? {
                                          label: '',
                                          type: 'text',
                                          requiredField: false,
                                          toolTipCopy: '',
                                          inputKeyValue: 'editManStoreCode',
                                          inputPlaceholder: 'Enter Store Code here...',
                                          inputValue: this.state.editManStoreCode,
                                          inputOnChange: this.onMagentoEditManualStoreCodeChange,
                                          errorMessage: this.state.editManStoreCodeErrorMessage,
                                      }
                                    : '',
                                {
                                    label: 'Currency Code:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter your magento currency code.',
                                    inputKeyValue: 'editCurrencyCode',
                                    inputPlaceholder: 'Currency Code...',
                                    inputValue: this.state.editCurrencyCode,
                                    inputOnChange: this.onMagentoEditCurrencyCodeChange,
                                    errorMessage: this.state.editCurrencyCodeErrorMessage,
                                },
                                {
                                    label: 'Sale Event',
                                    type: 'select',
                                    requiredField: true,
                                    toolTipCopy: 'Select a sale event.',
                                    inputKeyValue: 'editSaleEvent',
                                    inputOptions: this.state.saleEventProductEventOptions,
                                    inputValue: this.state.editSaleEvent,
                                    inputOnChange: this.onMagentoEditSaleEventChange,
                                    errorMessage: this.state.editSaleEventErrorMessage,
                                },
                            ],
                            buttons: [
                                {
                                    value: 'APPLY',
                                    onClick: this.onEditMagentoConfigsApplyClick,
                                    disabled: this.state.editMagentoConfigApplyDisabled,
                                },
                                {
                                    value: 'CANCEL',
                                    buttonTheme: ButtonThemes.Secondary,
                                    onClick: this.onAccordionCloseClick,
                                    disabled: this.state.editMagentoConfigCancelDisabled,
                                },
                            ],
                        },
                    },
                },
            ];

            return <WidgetAccordion accordions={accordions} />;
        } else {
            return null;
        }
    };

    renderTable = () => {
        const errorMessageOverride = 'There are no Magento Key associated with this account.';
        let copyValue = '';

        const header = {
            columns: [
                {
                    title: 'Consumer Key',
                },
                {
                    title: 'Access Token',
                },
                {
                    title: 'Status',
                },
            ],
        };

        const rows = this.state.magentoConnection.map(magentoConnection => {
            const magentoConnectionInUpdatedMagentoConnection = this.state.updatedMagentoConnection.filter(
                filteredMagentoConnection => filteredMagentoConnection.id === magentoConnection.id
            );
            const magentoConfigInUpdatedMagentoConfig = this.state.updatedMagentoConfig.filter(
                filteredMagentoConnection => filteredMagentoConnection.connection === magentoConnection.id
            );

            if (magentoConnectionInUpdatedMagentoConnection.length === 1) {
                magentoConnection = magentoConnectionInUpdatedMagentoConnection[0];
            }

            const rowProperty = {
                selected:
                    this.state.selectedMagentoConnection.filter(
                        filteredMagentoConnection => filteredMagentoConnection.id === magentoConnection.id
                    ).length === 1,
                deleted:
                    this.state.removedMagentoConnection.filter(
                        filteredMagentoConnection => filteredMagentoConnection.id === magentoConnection.id
                    ).length === 1,
                edited:
                    magentoConnectionInUpdatedMagentoConnection.length === 1 ||
                    magentoConfigInUpdatedMagentoConfig.length === 1,
                disabled: this.state.disableMagentoConnnectionSelect,
                success:
                    this.state.magentoResponseMessage.find(status => status.id === magentoConnection.id)?.status ===
                    'Connected',
                error:
                    this.state.magentoResponseMessage.find(status => status.id === magentoConnection.id)?.status ===
                        'Incorrect Magento Config' ||
                    this.state.magentoResponseMessage.find(status => status.id === magentoConnection.id)?.status ===
                        'Incorrect Access Token',
            };

            if (this.state.isTestLoading || this.state.isUpdatedMagentoTestLoading) {
                copyValue = '';
            } else {
                copyValue = this.state.magentoResponseMessage.find(
                    status => status.id === magentoConnection.id
                )?.status;
            }

            return {
                onClick: this.onMagentoConnectionSelect,
                keyValue: `magentoConnection__${magentoConnection.id}`,
                dataValue: magentoConnection.id,
                rowProperty,
                columns: [
                    {
                        copy: magentoConnection.consumerKey,
                    },
                    {
                        copy: magentoConnection.accessToken,
                    },
                    {
                        copy:
                            copyValue ||
                            this.state.previousMagentoResponseMessage.find(status => status.id === magentoConnection.id)
                                ?.status ||
                            this.state.bufferingMessage,
                    },
                ],
            };
        });
        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                enableSearch={true}
                searchableColumns={[0]}
            />
        );
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__manage-magento">
                    <this.renderModalNavigation />
                    <h2>Manage Magento Key</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__manage-magento">
                    <this.renderModalNavigation />
                    <h2>Manage Magento Key</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__manage-magento">
                <this.renderModalNavigation />
                <h2>Manage Magento Key</h2>
                <p>Manage previously configured Magento Key below.</p>
                <div className="modal__side-panel__manage-magentoConnection__magentoConnection">
                    <this.renderTable />
                    <InputButtonWrapper>
                        <InputButton value="Add A Magento Key" onClick={this.handleOnAdd} />
                        <InputButton
                            value="EDIT Magento Connection"
                            disabled={this.state.editMagentoConnnectionButtonDisabled}
                            onClick={this.onEditMagentoConnectionClick}
                        />
                        <InputButton
                            value="EDIT Magento Details"
                            disabled={this.state.editMagentoConfigButtonDisabled}
                            onClick={this.onEditMagentoConfigsClick}
                        />
                        <InputButton
                            buttonTheme={this.state.removeButtonState && ButtonThemes.Red}
                            value={this.state.removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                            disabled={this.state.removeButtonDisabled}
                            onClick={this.onRemoveClick}
                        />
                    </InputButtonWrapper>
                </div>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.account,
        request: state.request,
        meta: state.meta,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        removeModal: () => {
            dispatch(removeModal());
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalManageMagento);
