import React from 'react';
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import { CONFIG_KEYWORD_TYPE, CONFIG_SEOGD_MARKET } from '../../../../configurations/resources-config';
import { ConfigDataSuccess } from '../../../../react-query/types';
import Form from '../../../../components/forms/form';
import validators from '../../../../components/forms/validation/validators';

const FormCreateBlacklistedKeywords = () => {
    const marketsQuery = useFetchResource({
        resource: CONFIG_SEOGD_MARKET,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
            { key: 'order_by', value: 'country' },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.country,
                    value: object.id,
                };
            });
        },
    });

    const keywordTypeQuery = useFetchResource({
        resource: CONFIG_KEYWORD_TYPE,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.type,
                    value: object.id,
                };
            });
        },
    });
    return (
        <>
            <Form.Body>
                <Form.Section>
                    <Form.Field>
                        <Form.InputLabel htmlFor="keywords" label="Keywords" />
                        <Form.InputTag name="keywords" />
                    </Form.Field>
                    <Form.Field>
                        <Form.InputLabel htmlFor="market" label="Market" />
                        <Form.InputSelect
                            name="market"
                            options={marketsQuery.data || []}
                            validators={[validators.required]}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputLabel htmlFor="type" label="Keyword Type" />
                        <Form.InputSelect
                            name="type"
                            options={keywordTypeQuery.data || []}
                            validators={[validators.required]}
                        />
                    </Form.Field>
                </Form.Section>
            </Form.Body>
        </>
    );
};

export default FormCreateBlacklistedKeywords;
