import React from 'react';
import styled, { useTheme } from 'styled-components';

// Colours
import tinycolor from 'tinycolor2';
import { newColourAsRgba } from '../../../helpers/colours';

// Config
import { smallChartOptions } from '../../configurations/widget-line-graph-config';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Helpers
import { formatNumber } from '../../helpers/format-number';

// Types
import { WidgetDirection, LineGraphSeries } from '../../types';

const StyledBoxContainer = styled.div<{ direction: WidgetDirection }>`
    display: flex;
    flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
    height: 100%;
`;

const StyledBox = styled.div<{ backgroundColor: string }>`
    flex-grow: 1;
    position: relative;
    background: ${props => props.backgroundColor};
`;

const StyledBoxGraphContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
`;

const StyledValueContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledCentralValueContainer = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    width: 100%;
    color: ${props => props.theme.sectionDashboard.widget.lineGraph.bigNumber.textColor};
`;

const StyledTopValueContainer = styled.div`
    text-align: center;
    max-width: 90%;
    max-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const StyledSmallText = styled.p`
    margin: 2px 0px;
    color: ${props => props.theme.sectionDashboard.widget.lineGraph.bigNumber.textColor};
    font-size: 0.8rem;
    font-weight: 500;
`;

const StyledPillContainer = styled.div`
    margin: 5px 0px;
    border-radius: 15px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 2px 8px;
    color: ${props => props.theme.sectionDashboard.widget.lineGraph.bigNumber.textColor};
    background-color: rgba(255, 255, 255, 0.4);
`;

export type WidgetLineGraphBigNumberProps = {
    data: LineGraphSeries[];
    direction: WidgetDirection;
    width: number;
};

const WidgetLineGraphBigNumber = ({ data, direction, width }: WidgetLineGraphBigNumberProps) => {
    const theme = useTheme();
    const options = smallChartOptions(theme);
    return (
        <StyledBoxContainer direction={direction}>
            {data.map((data, index) => {
                const chartOptions = {
                    ...options,
                    chart: {
                        ...options.chart,
                        backgroundColor: newColourAsRgba(tinycolor(data.colour), 1),
                    },
                    plotOptions: {
                        ...options.plotOptions,
                        series: {
                            ...options.plotOptions.series,
                            zones: [{ color: '#ffffff' }],
                            fillOpacity: 0.2,
                        },
                    },
                    series: {
                        data: data.data,
                    },
                };

                return (
                    <StyledBox backgroundColor={newColourAsRgba(tinycolor(data.colour), 1)} key={index}>
                        <StyledBoxGraphContainer>
                            <CommonHighcharts
                                highcharts={Highcharts}
                                options={{ ...chartOptions }}
                                containerProps={{ style: { height: '100%', width: '100%' } }}
                            />
                        </StyledBoxGraphContainer>
                        <StyledValueContainer>
                            <StyledTopValueContainer>
                                <StyledSmallText title={data.name}>{data.name}</StyledSmallText>
                            </StyledTopValueContainer>
                            <StyledCentralValueContainer>{formatNumber(data.total, width)}</StyledCentralValueContainer>
                            {index === 0 && <StyledPillContainer>Highest</StyledPillContainer>}
                        </StyledValueContainer>
                    </StyledBox>
                );
            })}
        </StyledBoxContainer>
    );
};

export default WidgetLineGraphBigNumber;
