import React, { useState } from 'react';
import styled from 'styled-components';

// Image
import IconTimes from '../icons/times';

// Styles
const StyledTagContainer = styled.div<{
    borderColour: string;
    hasErrors: boolean;
    isDuplicate: boolean;
    isTagSelect: boolean;
}>`
    background-color: ${props => props.theme.colours.whiteBg};
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    margin: 5px;
    padding: 0px 10px;
    position: relative;
    height: 30px;

    color: ${props =>
        props.hasErrors || props.isDuplicate
            ? props.theme.colours.red
            : props.isTagSelect && !props.hasErrors && !props.isDuplicate
            ? props.theme.colours.primary
            : props.theme.colours.textDefault};
    border: ${props =>
        props.hasErrors || props.isDuplicate
            ? `solid 0.3px ${props.theme.colours.red}`
            : props.isTagSelect && !props.hasErrors && !props.isDuplicate
            ? `solid 0.3px`
            : props.borderColour
            ? `solid 2px ${props.borderColour}`
            : `solid 0.3px ${props.theme.colours.offLight}`};
`;

const StyledTagInput = styled.input<{ width: number; hasErrors: boolean; isDuplicate: boolean }>`
    margin: 0;
    outline: none;
    background-color: ${props => props.theme.colours.white};
    padding: 0px;
    border: 0;
    width: ${props => props.width}ch;
    font: inherit;

    color: ${props =>
        props.hasErrors || props.isDuplicate ? props.theme.colours.red : props.theme.colours.textDefault};
`;

const StyledTagSpan = styled.span<{ hasErrors: boolean; isDuplicate: boolean }>`
    padding: 0px;
    color: ${props =>
        props.hasErrors || props.isDuplicate ? props.theme.colours.red : props.theme.colours.textDefault};
`;

const StyledIconTimes = styled.button<{ hasErrors: boolean; isDuplicate: boolean }>`
    background: none;
    border: none;
    padding: 0;
    color: ${props =>
        props.hasErrors || props.isDuplicate ? props.theme.colours.red : props.theme.colours.textDefault};

    & svg {
        fill: ${props =>
            props.hasErrors || props.isDuplicate ? props.theme.colours.red : props.theme.colours.textDefault};
        position: relative;
        margin-left: 12px;
        top: 1px;
        height: 12px;
        &:hover {
            cursor: pointer;
        }
    }
`;

// Type
export type InputTagProps = {
    dataKey: string;
    value: string;
    onChange: (dataKey: string, value: string | null) => void;
    borderColour?: string;
    disableEdit?: boolean;
    hasErrors?: boolean;
    isDuplicate?: boolean;
};

const InputTag = ({ dataKey, value, onChange, borderColour, disableEdit, hasErrors, isDuplicate }: InputTagProps) => {
    const [isTagSelect, setIsTagSelect] = useState(false);

    const handleOnDelete = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        if (onChange !== undefined) {
            onChange(dataKey!, null);
        }
    };

    const handleOnTagClick = (event: React.SyntheticEvent) => {
        if (disableEdit) return;
        event.stopPropagation();
        setIsTagSelect(true);
    };

    const handleOnTagInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (disableEdit) return;
        if (onChange !== undefined) {
            onChange(dataKey!, event.target.value);
        }
    };

    return (
        <StyledTagContainer
            data-key={dataKey}
            borderColour={borderColour!}
            hasErrors={hasErrors!}
            isDuplicate={isDuplicate!}
            isTagSelect={isTagSelect!}
            onClick={handleOnTagClick}
        >
            {isTagSelect ? (
                <StyledTagInput
                    onChange={handleOnTagInputChange}
                    value={value}
                    width={value.length}
                    hasErrors={hasErrors!}
                    isDuplicate={isDuplicate!}
                />
            ) : (
                <StyledTagSpan hasErrors={hasErrors!} isDuplicate={isDuplicate!}>
                    {value}
                </StyledTagSpan>
            )}
            <StyledIconTimes hasErrors={hasErrors!} isDuplicate={isDuplicate!} onClick={handleOnDelete}>
                <IconTimes />
            </StyledIconTimes>
        </StyledTagContainer>
    );
};

export default InputTag;
