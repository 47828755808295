import { CubedField, TableFields } from '../../types';
import { VISIT_ID } from '../fields';
import { IMPRESSION_VISIT_ID } from '../common/table-fields';
import { FIRST_VISIT, CREATED, IMPRESSION_FIRST_VISIT } from './table-fields';

export type ResourceFields = {
    category: string;
    id: string;
    displayName: string;
    dateDimension: TableFields;
    defaultDimensions: Array<TableFields | CubedField>;
    dimensions: Array<TableFields | CubedField>;
    defaultOrderBy: TableFields;
};

export const VISITS_RESOURCE: ResourceFields = {
    category: 'health',
    id: 'health-page-visits',
    displayName: 'Visits',
    dateDimension: FIRST_VISIT,
    defaultDimensions: [VISIT_ID],
    dimensions: [VISIT_ID],
    defaultOrderBy: FIRST_VISIT,
};

export const VIEWS_RESOURCE: ResourceFields = {
    category: 'health',
    id: 'health-page-views',
    displayName: 'Views',
    dateDimension: CREATED,
    defaultDimensions: [CREATED],
    dimensions: [CREATED],
    defaultOrderBy: CREATED,
};

export const EVENTS_RESOURCE: ResourceFields = {
    category: 'health',
    id: 'health-event-items',
    displayName: 'Events',
    dateDimension: CREATED,
    defaultDimensions: [CREATED],
    dimensions: [CREATED],
    defaultOrderBy: CREATED,
};

export const IMPRESSION_RESOURCE: ResourceFields = {
    category: 'health',
    id: 'health-impression-visits',
    displayName: 'Impressions',
    dateDimension: IMPRESSION_FIRST_VISIT,
    defaultDimensions: [IMPRESSION_VISIT_ID],
    dimensions: [IMPRESSION_VISIT_ID],
    defaultOrderBy: IMPRESSION_FIRST_VISIT,
};

export const TAG_VALIDATION_RESOURCE: ResourceFields = {
    category: 'health',
    id: 'health-validation-errors',
    displayName: 'Validation',
    dateDimension: CREATED,
    defaultDimensions: [CREATED],
    dimensions: [CREATED],
    defaultOrderBy: CREATED,
};
