import React from 'react';
import styled from 'styled-components';

// Highcharts
import Highcharts from 'highcharts';
import networkGraph from 'highcharts/modules/networkgraph';
import CommonHighcharts from '../../../components/common/common-highcharts';
import Message from '../../../components/message';

networkGraph(Highcharts);

const StyledNetworkChart = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    opacity: ${props => props.isLoading && 0.3};
    transition: ${props => props.isLoading && props.theme.transition};
`;

const StyledDiv = styled.div``;

const NetworkGraph = ({
    config,
    messageTitle,
    messageCopy,
    messageType,
    chartSeriesList,
    noData,
    chartLoading,
    tableData,
}) => {
    Highcharts.addEvent(Highcharts.Series, 'afterSetOptions', function (e) {
        if (Highcharts.seriesTypes.networkgraph && this instanceof Highcharts.seriesTypes.networkgraph) {
            // normalise the radius size of each of the the markers with sum:
            // marker metric / maximum metric * largest marker size
            if (e.options.data.length > 0 && e.options.data[0].length === 3) {
                let nodes = {};
                let max = Math.max(...e.options.data.map(link => link[2]));
                let min = 4;
                e.options.data.forEach(function (link) {
                    let radius = (link[2] / max) * 20;
                    radius = Math.max(radius, min);

                    nodes[link[0]] = {
                        id: link[0],
                        marker: {
                            radius: radius,
                        },
                    };
                    nodes[link[1]] = {
                        id: link[1],
                        marker: {
                            radius: radius,
                        },
                    };
                });
                e.options.nodes = Object.keys(nodes).map(function (id) {
                    return nodes[id];
                });
            }
        }
    });

    if (tableData.objects?.length < 1 || tableData === undefined) {
        messageTitle = 'No data.';
        messageCopy = '';
        messageType = 'info';
    }
    return (
        <StyledNetworkChart isLoading={chartLoading}>
            {noData === false ? (
                <StyledDiv data-testid="network-graph">
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...config.chart.options, series: chartSeriesList }}
                        immutable={true}
                    />
                </StyledDiv>
            ) : (
                <Message title={messageTitle} copy={messageCopy} type={messageType} />
            )}
        </StyledNetworkChart>
    );
};

export default NetworkGraph;
