import React, { useContext } from 'react';
import { DashboardLibraryContext } from '../context/dashboard-library-context';
import LibraryList from '../../components/library-list/library-list';
import DashboardLibraryListItem from '../components/dashboard-library-list-item';
import { DashboardBuilderContext } from '../../../context/dashboard-builder-context';
import EmptyWidgets from '../components/empty-widgets';

const LayoutDashboardLibraryList = () => {
    const { userDashboardsQuery } = useContext(DashboardBuilderContext);
    const { currentUserDashboards, accountDashboardsQuery, accountDashboards, widgetQuery } =
        useContext(DashboardLibraryContext);

    if (userDashboardsQuery.isSuccess && accountDashboardsQuery.isSuccess) {
        return (
            <>
                {widgetQuery.data && widgetQuery.data.objects.length === 0 ? (
                    <>
                        <LibraryList.CategoryTitle>My Dashboards</LibraryList.CategoryTitle>
                        <EmptyWidgets />
                    </>
                ) : null}
                <LibraryList>
                    {currentUserDashboards.length > 0 && (
                        <>
                            <LibraryList.CategoryTitle>My Dashboards</LibraryList.CategoryTitle>
                            <LibraryList.List>
                                {currentUserDashboards
                                    .sort((a, b) => a.title.localeCompare(b.title))
                                    .map(dashboard => {
                                        return (
                                            <DashboardLibraryListItem
                                                key={dashboard.id}
                                                dashboard={dashboard}
                                                isCurrentUser={true}
                                            />
                                        );
                                    })}
                            </LibraryList.List>
                        </>
                    )}
                    {accountDashboards.length > 0 && (
                        <>
                            <LibraryList.CategoryTitle>Account Dashboards</LibraryList.CategoryTitle>
                            <LibraryList.List>
                                {accountDashboards
                                    .sort((a, b) => a.title.localeCompare(b.title))
                                    .map(dashboard => {
                                        return <DashboardLibraryListItem key={dashboard.id} dashboard={dashboard} />;
                                    })}
                            </LibraryList.List>
                        </>
                    )}
                </LibraryList>
            </>
        );
    }

    return null;
};

export default LayoutDashboardLibraryList;
