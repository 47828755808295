import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import CheckboxItem from '../checkbox-item';

import { FilterSelectFilter } from '../../filter-bar/types';

// Styles
const StyledFilterList = styled.ul`
    position: relative;
    margin: 0;
    padding: 6px;
    list-style: none;
    background-color: white;
    max-height: 330px;
    overflow-y: auto;
    ${props => props.theme.scrollbar(8, 8)}
`;

const StyledFilterListItem = styled('li')<{ default: boolean }>`
    cursor: pointer;
    width: 240px;
    padding: 4px 12px;
    border-radius: 3px;
    margin: 2px;
    transition: 0.2s ease-in;
    color: ${props => (props.default ? '#212121' : '')};
    background-color: ${props => (props.default ? '#dfdfdf' : '#eeeeee')};
    &:hover {
        background-color: #dfdfdf;
    }
`;

const StyledFilterListItemName = styled.div`
    margin-left: 24px;
`;

// Types
type FilterSelectListProps = {
    filterOptions: FilterSelectFilter[];
    handleFilterSelect: (filter: FilterSelectFilter) => void;
};

const FilterSelectList = ({ filterOptions, handleFilterSelect }: FilterSelectListProps) => {
    return (
        <StyledFilterList data-testid="styled-filter-list">
            {filterOptions.map(filter => {
                if (!filter.hidden) {
                    return (
                        <StyledFilterListItem
                            onClick={() => handleFilterSelect(filter)}
                            key={filter.id}
                            default={filter.default}
                        >
                            <CheckboxItem checked={filter.default} />
                            <StyledFilterListItemName>{filter.name}</StyledFilterListItemName>
                        </StyledFilterListItem>
                    );
                }

                return null;
            })}
        </StyledFilterList>
    );
};

export default memo(FilterSelectList, isEqual);
