import Axios, { CancelToken } from 'axios';
import Store from '../redux/store';

export enum matchType {
    OR = 'OR',
    AND = 'AND',
}

export type RequestParams = {
    key: string;
    value: string | number | boolean;
    matchType?: matchType;
}[];

/**
 * Creates the path for a given tastypie resource.
 */
export function generatePath(resourceGroup: string, resourceName: string, resourceId: null | string = null) {
    return `/api/${resourceGroup}/${resourceName}/${resourceId !== null ? `${resourceId}/` : ''}`;
}

/**
 * Creates a string using an array of params
 */
function generateParameterString(params: RequestParams) {
    try {
        if (typeof params !== 'object' || params.length === 0) {
            return '';
        }
        params = params.filter(
            param => param.key !== undefined && param.key !== '' && param.value !== undefined && param.value !== ''
        );

        let orMatchTypeProcessed = 0;
        return params
            .map(param => {
                if (param.matchType && param.matchType === matchType.OR) {
                    orMatchTypeProcessed++;
                    if (orMatchTypeProcessed === 1) {
                        // first 'or' matchType
                        return `&${param.key}=${encodeURIComponent(param.value)}`;
                    } else {
                        // 'or' matchTypes in between
                        return `|${param.key}=${encodeURIComponent(param.value)}`;
                    }
                } else {
                    return `&${param.key}=${encodeURIComponent(param.value)}`;
                }
            })
            .join('');
    } catch {
        throw new TypeError('Unexpected format for params.');
    }
}

/**
 * Create the full URL for a given tastypie resources including the account id parameter as optional.
 */
export function generateUrl(
    resourceGroup: string,
    resourceName: string,
    params: RequestParams = [],
    addAccountId: boolean = true
) {
    const resourcePath = generatePath(resourceGroup, resourceName);

    // Get current reduxState
    const state = Store.getState();

    const accountId = state.account.id;
    const apiDomain = state.meta.apiDomain;

    // Check if account ID is present and the same goes for the API domain
    if ((addAccountId && accountId === '') || apiDomain === '') {
        throw new Error('The Account ID or API Domain is not set.');
    }

    let parameterString = generateParameterString(params);

    try {
        return `${apiDomain}${resourcePath}${addAccountId ? '?account=' + accountId : ''}${
            !addAccountId && parameterString.length > 0 ? `?${parameterString.substring(1)}` : parameterString
        }`;
    } catch (error) {
        throw new Error('Error when generating url');
    }
}

/**
 * Create the full URL for a given tastypie resource including the account id parameter.
 */
export function generateUrlDetail(
    resourceGroup: string,
    resourceName: string,
    resourceId?: string,
    params: RequestParams = [],
    addAccountId = true
) {
    const resourcePath = generatePath(resourceGroup, resourceName, resourceId);

    // Get current reduxState
    const state = Store.getState();

    const accountId = state.account.id;
    const apiDomain = state.meta.apiDomain;

    // Check if account ID is present and the same goes for the API domain
    if ((addAccountId && accountId === '') || apiDomain === '') {
        return null;
    }

    let parameterString = generateParameterString(params);

    try {
        return `${apiDomain}${resourcePath}${addAccountId ? '?account=' + accountId : ''}${
            !addAccountId && parameterString.length > 0 ? `?${parameterString.substring(1)}` : parameterString
        }`;
    } catch (error) {
        throw new Error('Error when generating url');
    }
}

/**
 * Creates a get Axios request ready to be used by Axios
 */
export const generateGetRequest = async (url: string, cancelToken: CancelToken | null = null) => {
    // If the cancel token is present return an axios request with the added token
    if (cancelToken !== null) {
        return await Axios.get(url, { withCredentials: true, cancelToken: cancelToken });
    }

    return await Axios.get(url, { withCredentials: true });
};

// New async function generateMultipleBlobRequest to request multiple api one after first one finishes and returns array of blob response.

export const generateMultipleBlobRequest = async (urls: string[]) => {
    const blobArray: string[] = [];

    for (const url of urls) {
        await Axios.request({
            url,
            method: 'GET',
            responseType: 'blob',
            withCredentials: true,
        }).then((respo: { data: string }) => {
            blobArray.push(respo.data);
        });
    }

    return blobArray;
};

export const generateBlobRequest = async (url: string, filename: string) => {
    if (typeof url !== 'string') {
        return null;
    }

    return await Axios.request({
        url,
        method: 'GET',
        responseType: 'blob',
        withCredentials: true,
    }).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
};

/**
 * Creates an array of get Axios request ready to be used by Axios
 */
export function generateGetRequests(urls: string[], cancelToken: CancelToken) {
    return urls.map((url: string) => generateGetRequest(url, cancelToken));
}
