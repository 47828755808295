import React from 'react';
import styled from 'styled-components';

// Types
import { WidgetConfig } from '../../types';

// Widget Previews
import WidgetPreviewBarGraph from '../widgets/widget-preview-bar-graph';
import WidgetPreviewPieChart from '../widgets/widget-preview-pie-chart';
import WidgetPreviewSparkline from '../widgets/widget-preview-sparkline';
import WidgetPreviewTable from '../widgets/widget-preview-table';
import WidgetPreviewPieChartTable from '../widgets/widget-preview-pie-chart-table';
import WidgetPreviewBigNumber from '../widgets/widget-preview-big-number';
import WidgetPreviewLineGraph from '../widgets/widget-preview-line-graph';

// Comparison Widget Previews
import WidgetPreviewTableComparison from '../comparison-widgets/widget-preview-table-comparison';
import WidgetPreviewSparklineComparison from '../comparison-widgets/widget-preview-sprakline-comparison';
import WidgetPreviewPieChartTableComparison from '../comparison-widgets/widget-preview-pie-chart-table-comparison';
import WidgetPreviewBarGraphComparison from '../comparison-widgets/widget-preview-bar-graph-comparison';
import WidgetPreviewPieChartComparison from '../comparison-widgets/widget-preview-pie-chart-comparison';
import WidgetPreviewBigNumberComparison from '../comparison-widgets/widget-preview-big-number-comparison';
import WidgetPreviewLineGraphComparison from '../comparison-widgets/widget-preview-line-graph-comparison';
import WidgetPreviewTextBox from '../widgets/widget-preview-text-box';

const StyledError = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    font-style: italic;
`;

const getWidgetPreview = ({
    type,
    config,
    showComparison,
}: {
    type: string;
    config: WidgetConfig;
    showComparison: boolean;
}) => {
    if (showComparison) {
        switch (type) {
            case 'table':
                return <WidgetPreviewTableComparison config={config} />;
            case 'barGraph':
                return <WidgetPreviewBarGraphComparison config={config} />;
            case 'pieChart':
                return <WidgetPreviewPieChartComparison config={config} />;
            case 'sparkLine':
                return <WidgetPreviewSparklineComparison config={config} />;
            case 'pieChartTable':
                return <WidgetPreviewPieChartTableComparison config={config} />;
            case 'bigNumber':
                return <WidgetPreviewBigNumberComparison config={config} />;
            case 'lineGraph':
                return <WidgetPreviewLineGraphComparison config={config} />;
            case 'textBox':
                return <WidgetPreviewTextBox config={config} />;
            default:
                return <StyledError>No preview available for this widget.</StyledError>;
        }
    }

    switch (type) {
        case 'table':
            return <WidgetPreviewTable config={config} />;
        case 'barGraph':
            return <WidgetPreviewBarGraph config={config} />;
        case 'pieChart':
            return <WidgetPreviewPieChart config={config} />;
        case 'sparkLine':
            return <WidgetPreviewSparkline config={config} />;
        case 'pieChartTable':
            return <WidgetPreviewPieChartTable config={config} />;
        case 'bigNumber':
            return <WidgetPreviewBigNumber config={config} />;
        case 'lineGraph':
            return <WidgetPreviewLineGraph config={config} />;
        case 'textBox':
            return <WidgetPreviewTextBox config={config} />;
        default:
            return <StyledError>No preview available for this widget.</StyledError>;
    }
};

export default getWidgetPreview;
