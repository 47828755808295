import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

type RouteSuperUserOnlyProps = {
    children?: React.ReactNode;
};

const RouteSuperUserOnly = ({ children }: RouteSuperUserOnlyProps) => {
    const { user } = useAppSelector(state => state);
    const dispatch = useAppDispatch();
    const reject = user.superUser !== true;

    useEffect(() => {
        if (reject) {
            dispatch(
                addNotification({
                    copy: 'You have been redirected as you do not have permission to the page you tried to access.',
                    type: NotificationMessageType.Warn,
                })
            );
        }
    }, [reject, dispatch]);

    if (reject) {
        return <Navigate to={'/'} replace />;
    }

    return <>{children}</>;
};

export default RouteSuperUserOnly;
