import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import sunburst from 'highcharts/modules/sunburst.js';
import sunburstChartConfiguration from '../configurations/charts/sunburst-config';

class WidgetSunburstChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            graphData: [],
            currentJourney: {
                id: '',
                journey: [],
                percentage: 0,
            },
        };
        // Adding sunburst module to Highcharts
        sunburst(Highcharts);
    }

    static defaultProps = {
        chartId: 'sunburstChart',
        options: sunburstChartConfiguration(),
        data: [],
        showGraph: true,
        onPlotPointClick: () => {},
        onCurrentJourneyUpdate: () => {},
    };

    generateOptions = () => {
        const onHover = point => {
            const journey = point.id.split('__');
            journey.shift();

            if (this.state.currentJourney.id !== point.id) {
                const currentJourney = {
                    id: point.id,
                    journey: journey,
                    percentage: point.cubedSalesPercentage,
                };

                this.setState({
                    currentJourney: currentJourney,
                });

                this.props.onCurrentJourneyUpdate(currentJourney);
            }
        };

        const onClick = () => {
            // These are not valid things to click into
            if (this.state.currentJourney.id === '' || this.state.currentJourney.id === 'journey') {
                return;
            }

            return this.props.onPlotPointClick(this.state.currentJourney);
        };

        const options = { ...this.props.options };

        options.series[0].data = this.state.graphData;

        options.plotOptions = {
            series: {
                dataLabels: {
                    enabled: false,
                },
                stickyTracking: false,
                events: {
                    mouseOut: () => this.clearStateOnMouseLeave(),
                    click: function () {
                        onClick();
                    },
                },
            },
        };

        options.tooltip = {
            formatter: function () {
                // Change the context so that setState can be called whilst still accessing this
                onHover(this.point);
                return false;
            },
        };
        return options;
    };

    componentDidMount() {
        this.setState(
            {
                graphData: this.props.data,
            },
            () => {
                this.refs[this.props.chartId].chart.update(this.generateOptions());
                this.refs[this.props.chartId].chart.reflow();
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.setState({
                graphData: this.props.data,
            });
        }

        // If the currentJourney has changed then go through and change the highlighted segments
        if (prevState.currentJourney.id !== this.state.currentJourney.id) {
            const graphData = [...this.state.graphData];
            graphData.forEach(plotPoint => {
                let opacity = 1;
                if (
                    this.state.currentJourney.id !== '' &&
                    this.state.currentJourney.id !== 'journey' &&
                    plotPoint.id !== 'journey'
                ) {
                    plotPoint.journey.every((referer, index) => {
                        if (
                            index === 0 ||
                            (this.state.currentJourney.journey[index - 1] &&
                                referer === this.state.currentJourney.journey[index - 1])
                        ) {
                            return true;
                        }
                        opacity = 0.4;
                        return false;
                    });
                }
                plotPoint.opacity = opacity;
            });
            this.setState({
                graphData: graphData,
            });
        }

        if (prevState.graphData !== this.state.graphData) {
            this.refs[this.props.chartId].chart.reflow();
        }
        this.renderSunBurstToolTip();
    }

    renderSunBurstToolTip = () => {
        // Don't show the tooltip if nothing is being hovered over or if the inner circle is hovered over
        if (this.state.currentJourney.id !== '' && this.state.currentJourney.id !== 'journey') {
            return (
                <div className="sunburst-chart__tooltip">
                    <h1>{this.state.currentJourney.percentage}%</h1>
                    <p>of Sales Journeys followed this pattern.</p>
                </div>
            );
        }
        return null;
    };

    clearStateOnMouseLeave = () => {
        const currentJourney = {
            id: '',
            journey: [],
            percentage: 0,
        };

        this.setState({
            currentJourney: currentJourney,
        });

        this.props.onCurrentJourneyUpdate(currentJourney);
    };

    render() {
        this.renderSunBurstToolTip();
        if (this.props.showGraph) {
            return (
                <div className="sunburst-chart" onMouseLeave={this.clearStateOnMouseLeave}>
                    <HighchartsReact
                        constructorType={'chart'}
                        highcharts={Highcharts}
                        options={this.props.options}
                        ref={this.props.chartId}
                    />
                    <this.renderSunBurstToolTip />
                </div>
            );
        }

        return null;
    }
}

export default WidgetSunburstChart;
