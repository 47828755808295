import React from 'react';
import styled from 'styled-components';

// Styles
const StyledPaginationPageInputHolder = styled.div`
    width: 80px;
    padding: 0;
    position: absolute;
    max-height: 0;
    height: 0;
    overflow: hidden;
    left: -19px;
    z-index: 333;
    transform: scale(1, 0);
    transition: 0.2s ease-in-out;
    top: -40px;
`;

const StyledPaginationPageInputBackground = styled.div`
    top: 4px;
    left: 4px;
    position: absolute;
    background: ${props => props.theme.colours.borderGrey};
    width: 70px;
    padding: 30px;
    text-align: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 14px;

    &:after {
        content: '';
        position: absolute;
        box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        bottom: -10px;
        left: 25px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent ${props => props.theme.colours.borderGrey} ${props => props.theme.colours.borderGrey}
            transparent;
    }
`;

const StyledPaginationPageInputContainer = styled.form`
    height: 100%;
    text-align: center;
`;

const StyledPaginationPageInputDiv = styled.div`
    margin-bottom: 3px;
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 4px;
`;

const StyledPaginationPageInput = styled.input`
    text-align: center;
    border-radius: 6px;
    position: relative;
    width: 52px;
    line-height: 22px;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    background-color: ${props => props.theme.colours.white};
`;

const StyledPaginationPageSubmit = styled.input`
    visibility: hidden;
`;

const TablePaginationGoTo = () => (
    <StyledPaginationPageInputHolder>
        <StyledPaginationPageInputBackground />
        <StyledPaginationPageInputContainer>
            <StyledPaginationPageInputDiv>Go to</StyledPaginationPageInputDiv>
            <StyledPaginationPageInput />
            <StyledPaginationPageSubmit type="submit" />
        </StyledPaginationPageInputContainer>
    </StyledPaginationPageInputHolder>
);

export default TablePaginationGoTo;
