import React, { createContext } from 'react';

// Fields & Resources
import * as resources from '../../configurations/resources';
import * as fields from '../../configurations/fields';

// Types

import { WidgetMenuObj } from '../../section-dashboard/types';

// Hooks
import { useDatesFormatted } from '../../hooks/use-dates';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import useWidgetMenuSectionQuerySelect from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-query-select';
import useWidgetMenuSection from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section';

// Queries
import { channelsDropdownQuery, goalsDropdownQuery } from '../../section-dashboard/queries';
import { channelInsightsTopChannelQuery } from '../queries';

export interface ChannelInsightsDashboardMenuContextValues {
    goalCompletionsSectionMenu: WidgetMenuObj;
    channelPerformanceSectionMenu: WidgetMenuObj;
}

export const ChannelInsightsDashboardMenuContext = createContext<ChannelInsightsDashboardMenuContextValues>(
    {} as ChannelInsightsDashboardMenuContextValues
);

const ChannelInsightsDashboardMenuProvider = ({ children }: { children: JSX.Element }) => {
    // Dates
    const { startDate, endDate } = useDatesFormatted({ resource: resources.REPORT_MCS_CHANNEL });
    const { startDate: comparisonStartDate, endDate: comparisonEndDate } = useDatesFormatted({
        resource: resources.REPORT_MCS_CHANNEL,
        isComparison: true,
    });

    // Goals
    const goalCompletionsSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionQuerySelect({
                        query: goalsDropdownQuery,
                        dropdownField: fields.PRODUCT,
                    }),
                ],
            },
        ],
    });

    // Channels
    const topChannelThisPeriod = useFetchResource(channelInsightsTopChannelQuery({ startDate, endDate }));
    const topChannelComparisonPeriod = useFetchResource(
        channelInsightsTopChannelQuery({ startDate: comparisonStartDate, endDate: comparisonEndDate })
    );

    const channelPerformanceSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionQuerySelect({
                        query: channelsDropdownQuery,
                        dropdownField: fields.REFERER,
                        selectedOverride: topChannelThisPeriod.data || topChannelComparisonPeriod.data,
                    }),
                ],
            },
        ],
    });

    return (
        <ChannelInsightsDashboardMenuContext.Provider
            value={{
                goalCompletionsSectionMenu,
                channelPerformanceSectionMenu,
            }}
        >
            {children}
        </ChannelInsightsDashboardMenuContext.Provider>
    );
};

export default ChannelInsightsDashboardMenuProvider;
