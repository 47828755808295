import React, { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';

//core Dependencies
import styled from 'styled-components';

// Component Dependencies
import SimpleTable from '../../components/tables/components/simple-table';
import WidgetAccordion from '../../widgets/accordion';
import ModalNavigation from '../../components/modal-navigation';
import InputText from '../../components/inputs/input-text';
import InputButton from '../../components/inputs/input-button';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

// Helper
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';
import { generateUrl } from '../../helpers/request-builder';

const StyledGridContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1.2fr;
    gap: 10px;
    margin-bottom: 30px;
`;
const StyledInputPatterns = styled.div`
    display: grid;
    grid-template-columns: 3fr 1.2fr;
    width: 600px;
    align-item: left;
    gap: 10px;
`;
const StyledDarkerBackgroundGridColumn = styled.div`
    background-color: #eeecec;
    border-radius: 5px;
    padding: 5px;
    height: 430px;
`;

const StyledErrorMessage = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colours.red};
    margin-bottom: 1em;
`;

type URLPattern = {
    active: boolean;
    id: number;
    resourceUri: string;
    pattern: string;
};

type APIValidationError = {
    url_pattern: string;
};

type ValidationError = {
    response: Response;
};

type Response = {
    data: ErrorMessage;
    status: number;
};

type ErrorMessage = {
    'oxylabs-url-exclude': APIValidationError;
};
type DuplicateKeyError = {
    code: number;
    message: string;
};

const LayoutExcludedURLsForOxylabs = () => {
    // flags
    const [isLoading, setIsLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [tableIsLoading, setTableIsLoading] = useState(false);
    const [disablePatternSelect, setDisablePatternSelect] = useState(false);
    const [addNewPatternLoading, setAddNewPatternLoading] = useState(false);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
    const [editButtonDisabled, setEditButtonDisabled] = useState(true);
    const [applyButtonLoading, setApplyButtonLoading] = useState(false);
    const [accordionUpdateButtonDisabled, setAccordionUpdateButtonDisabled] = useState(true);
    const [editExcludePatternAccordionOpen, setEditExcludePatternAccordionOpen] = useState(false);
    const [editExcludePatternAccordionVisible, setEditExcludePatternAccordionVisible] = useState(false);

    // data
    const [urlExcludePatterns, setUrlExcludePatterns] = useState<URLPattern[]>([]);
    const [selectedExcludePatterns, setSelectedExcludePatterns] = useState<URLPattern[]>([]);
    const [urlPatternToCreate, setUrlPatternToCreate] = useState<string>('');
    const [urlPatternToEdit, setUrlPatternToEdit] = useState<string>();

    // errors
    const [formErrors, setFormErrors] = useState<APIValidationError | null>();
    const [editFormErrors, setEditFormErrors] = useState<APIValidationError | null>();

    const dispatch = useDispatch();

    useEffect(() => {
        fetchUrlExcludePatterns();
    }, []);

    const fetchUrlExcludePatterns = () => {
        setTableIsLoading(true);
        axios({
            method: 'GET',
            url: generateUrl('config', 'oxylabs-url-exclude', [{ key: 'active', value: 1 }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const flattenedURLPatterns = response.data.objects.map((urlPatternResponse: URLPattern) =>
                    Object.values(urlPatternResponse).map(value => value)
                );
                const urlPatterns = flattenedURLPatterns.map((urlPatternResponse: [number, number, string, string]) => {
                    return {
                        active: urlPatternResponse[0],
                        id: urlPatternResponse[1],
                        resourceUri: urlPatternResponse[2],
                        pattern: urlPatternResponse[3],
                    };
                });

                setUrlExcludePatterns(urlPatterns);
            })
            .finally(() => {
                setIsLoading(false);
                setTableIsLoading(false);
            })
            .catch(e => {
                setPageError(e);
            });
    };

    // PrimaryAction of the close popup
    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };
    const onCloseClick = () => {
        if (accordionUpdateButtonDisabled) {
            dispatch(removeModal());
        } else {
            // warning message
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: () => dispatch(removePopup()),
                        },
                    ],
                })
            );
        }
    };
    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: onCloseClick,
                buttonTheme: ButtonThemes.Primary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    // Validation:
    const updateFormErrors = (error: ErrorMessage | DuplicateKeyError) => {
        if ((error as ErrorMessage)['oxylabs-url-exclude']) {
            setFormErrors((error as ErrorMessage)['oxylabs-url-exclude']);
        }

        if (error as DuplicateKeyError) {
            setFormErrors({ url_pattern: (error as DuplicateKeyError)?.message });
        } else {
            dispatch(
                addNotification({
                    copy: 'Something went wrong adding your key.',
                    type: NotificationMessageType.Error,
                })
            );
        }
    };
    const updateEditFormErrors = (error: ErrorMessage | DuplicateKeyError) => {
        if ((error as ErrorMessage)['oxylabs-url-exclude']) {
            setEditFormErrors((error as ErrorMessage)['oxylabs-url-exclude']);
        }

        if (error as DuplicateKeyError) {
            setEditFormErrors({ url_pattern: (error as DuplicateKeyError)?.message });
        } else {
            dispatch(
                addNotification({
                    copy: 'Something went wrong updating your key.',
                    type: NotificationMessageType.Error,
                })
            );
        }
    };
    /* Button & Input handlers section: */
    const onURLInput = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        setUrlPatternToCreate(target.value);
    };
    const onAddNewPatternClick = () => {
        if (!urlPatternToCreate) {
            setFormErrors({ url_pattern: 'Cannot Create Empty URL Pattern.' });
            return;
        }
        setAddNewPatternLoading(true);
        setSelectedExcludePatterns([]);
        setRemoveButtonDisabled(true);
        setEditButtonDisabled(true);
        const urlExcludePatternPayload = {
            url_pattern: urlPatternToCreate,
            active: '1',
        };

        let urlExcludePatternRequestConfig: AxiosRequestConfig;

        urlExcludePatternRequestConfig = {
            method: 'POST',
            url: generateUrl('config', 'oxylabs-url-exclude'),
            data: urlExcludePatternPayload,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        axios(urlExcludePatternRequestConfig)
            .then(() => {
                fetchUrlExcludePatterns();
                setAddNewPatternLoading(false);
                dispatch(
                    addNotification({
                        copy: 'Your URL Exclude Pattern was successfully created.',
                        type: NotificationMessageType.Success,
                    })
                );
                setUrlPatternToCreate('');
                setFormErrors(null);
            })
            .catch(error => {
                if (
                    (error as ValidationError)?.response?.status === 400 ||
                    (error as ValidationError)?.response?.status === 409
                ) {
                    updateFormErrors((error as ValidationError).response?.data);
                }
                setAddNewPatternLoading(false);
            });
    };

    const onEditPatternClick = () => {
        setUrlPatternToEdit(selectedExcludePatterns[0].pattern);
        setEditExcludePatternAccordionOpen(true);
        setEditExcludePatternAccordionVisible(true);

        // Disable edit and remove buttons
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);

        // Disable table select
        setDisablePatternSelect(true);
    };
    const onRemovePatternClick = () => {
        dispatch(
            setPopup({
                title: 'Confirm delete?',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you want to delete.',
                },
                buttons: [
                    {
                        onClick: deleteExcludePatterns,
                        value: 'CONFIRM DELETE',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'CANCEL',
                        style: 'secondary',
                    },
                ],
            })
        );
    };
    const onCancelUpdateClick = () => {
        setEditExcludePatternAccordionOpen(false);
        setEditExcludePatternAccordionVisible(false);

        // enable buttons and tables.
        setEditButtonDisabled(false);
        setRemoveButtonDisabled(false);
        setDisablePatternSelect(false);
        setEditFormErrors(null);
    };
    const onApplyClick = () => {
        setApplyButtonLoading(true);

        let editedPattern = selectedExcludePatterns[0];
        axios({
            method: 'PATCH',
            url: generateUrl('config', 'oxylabs-url-exclude'),
            data: {
                objects: [{ resource_uri: editedPattern.resourceUri, url_pattern: urlPatternToEdit }],
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(() => {
                setApplyButtonLoading(false);
                dispatch(
                    addNotification({
                        copy: 'Your URL Exclude Pattern was successfully updated.',
                        type: NotificationMessageType.Success,
                    })
                );
                setSelectedExcludePatterns([]);
                setDisablePatternSelect(false);
                setAccordionUpdateButtonDisabled(true);
                setEditExcludePatternAccordionVisible(false);
                setEditExcludePatternAccordionOpen(false);
                setTableIsLoading(true);
                fetchUrlExcludePatterns();
            })
            .catch(error => {
                setApplyButtonLoading(false);
                if (
                    (error as ValidationError)?.response?.status === 400 ||
                    (error as ValidationError)?.response?.status === 409
                ) {
                    updateEditFormErrors((error as ValidationError).response?.data);
                } else {
                    setPageError(true);
                }
            });
    };
    const onEventDetailsChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        setUrlPatternToEdit(target.value);
        setAccordionUpdateButtonDisabled(target.value === selectedExcludePatterns[0].pattern || target.value === '');
    };
    /* Button handler Action */
    const deleteExcludePatterns = () => {
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);
        setIsLoading(true);
        axios({
            method: 'PATCH',
            url: generateUrl('config', 'oxylabs-url-exclude'),
            data: { objects: [], deleted_objects: selectedExcludePatterns.map((item: URLPattern) => item.resourceUri) },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(() => {
                setIsLoading(false);
                dispatch(removePopup());
                setSelectedExcludePatterns([]);
                fetchUrlExcludePatterns();
            })
            .catch(error => {
                setPageError(error);
            });
    };
    const renderEditAccordion = () => {
        if (!editExcludePatternAccordionVisible) {
            return;
        }
        let introText = '';

        introText = `URL Exclude Pattern should be a regex pattern recognized by python interpreter`;
        const accordions = [
            {
                header: 'Edit URL Exclude Pattern',
                required: false,
                open: editExcludePatternAccordionOpen,
                type: 'form',
                intro: introText,
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'URL Exclude Pattern:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Please enter the regex pattern to exclude URL from Oxylabs crawl.',
                                inputKeyValue: 'edit-url-pattern',
                                inputPlaceholder: 'Event Tag Name...',
                                inputValue: urlPatternToEdit,
                                inputOnChange: onEventDetailsChange,
                                errorMessage: editFormErrors?.url_pattern,
                            },
                        ],
                        buttons: [
                            {
                                value: 'APPLY',
                                onClick: onApplyClick,
                                disabled: accordionUpdateButtonDisabled,
                                isLoading: applyButtonLoading,
                            },
                            {
                                value: 'CANCEL',
                                onClick: onCancelUpdateClick,
                                buttonTheme: ButtonThemes.Secondary,
                                disabled: applyButtonLoading,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    };

    const onPatternSelect = (urlPattern: URLPattern) => {
        const selectedExcludePatternId = urlPattern.id;
        let tempSelectedExcludePatterns: URLPattern[] = [];

        if (
            selectedExcludePatterns.filter(
                (excludePattern: URLPattern) => excludePattern.id === selectedExcludePatternId
            ).length > 0
        ) {
            tempSelectedExcludePatterns = selectedExcludePatterns.filter(
                (excludePattern: URLPattern) => excludePattern.id !== selectedExcludePatternId
            );
            setSelectedExcludePatterns(tempSelectedExcludePatterns);
            setEditButtonDisabled(tempSelectedExcludePatterns.length === 0);
            setRemoveButtonDisabled(tempSelectedExcludePatterns.length === 0);
        } else {
            tempSelectedExcludePatterns = selectedExcludePatterns.concat(
                urlExcludePatterns.filter(
                    (urlExcludePattern: URLPattern) => urlExcludePattern.id === selectedExcludePatternId
                )
            );
        }

        // Change the status of the actions buttons depending on number of values selected
        let tempEditButtonDisabled = true;
        let tempRemoveButtonDisabled = true;

        if (tempSelectedExcludePatterns?.length > 0) {
            tempEditButtonDisabled = false;
            tempRemoveButtonDisabled = false;
        }

        if (tempSelectedExcludePatterns.length > 1) {
            tempEditButtonDisabled = true;
            tempRemoveButtonDisabled = false;
        }

        setSelectedExcludePatterns(tempSelectedExcludePatterns);
        setEditButtonDisabled(tempEditButtonDisabled);
        setRemoveButtonDisabled(tempRemoveButtonDisabled);

        setEditExcludePatternAccordionOpen(false);
    };

    const rows = urlExcludePatterns.map((urlPattern: URLPattern) => {
        const selected: boolean = selectedExcludePatterns.includes(urlPattern);

        return {
            onClick: () => onPatternSelect(urlPattern),
            key: `urlpatterns__${urlPattern.id}`,
            dataValue: urlPattern.id,
            selected,
            columns: [
                {
                    copy: urlPattern.pattern,
                },
            ],
        };
    });

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage URL Exclusion List</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage URL Exclusion List</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    const header = {
        columns: [
            {
                title: 'URL Exclude Patterns',
            },
        ],
    };
    return (
        <div>
            {renderModalNavigation()}
            <h2>Manage URL Exclusion List</h2>
            <StyledGridContainer>
                <StyledDarkerBackgroundGridColumn>
                    <p>These are the URL Patterns you are excluding in the oxylabs crawl.</p>
                    <SimpleTable
                        customHeight="335px"
                        header={header}
                        rows={rows}
                        errorMessageOverride={'No URL Patterns present.'}
                        hasIcons={true}
                        isScrollable={true}
                        isLoading={tableIsLoading}
                        selectDisabled={disablePatternSelect}
                    />
                    <InputButtonWrapper>
                        <InputButton
                            buttonTheme={ButtonThemes.Primary}
                            onClick={onEditPatternClick}
                            disabled={editButtonDisabled}
                            value={'Edit'}
                        />
                        <InputButton
                            buttonTheme={ButtonThemes.Red}
                            onClick={onRemovePatternClick}
                            disabled={removeButtonDisabled}
                            value={'REMOVE'}
                        />
                    </InputButtonWrapper>
                </StyledDarkerBackgroundGridColumn>
                <StyledDarkerBackgroundGridColumn>
                    <p>
                        The URL Exclusion List is used to avoid certain paths from being crawled by the Oxylabs API.
                        These patterns must be a regex that is understood by a python interpreter. In this menu the
                        patterns can be added, removed and edited.
                    </p>
                    <p>
                        There are certain URLs such as the one ending with .pdf, which are ignored by default because
                        oxylabs cannot scrape PDF documents.
                    </p>
                    <p>Some examples of url exclusion patterns are:</p>
                    <ul>
                        <li>
                            <b>\/v1\/</b> ignores all the urls that has the word /v1/
                        </li>
                        <p></p>
                        <li>
                            <b>\?filter=</b> ignores all the urls that has the word ?filter=
                        </li>
                    </ul>
                </StyledDarkerBackgroundGridColumn>
            </StyledGridContainer>
            {!editExcludePatternAccordionVisible && (
                <StyledInputPatterns>
                    <InputText
                        value={urlPatternToCreate}
                        placeholder={'URL Pattern to exclude'}
                        onChange={onURLInput}
                    />
                    <InputButton
                        buttonTheme={ButtonThemes.Primary}
                        onClick={onAddNewPatternClick}
                        disabled={false}
                        value={'Add New Pattern'}
                        isLoading={addNewPatternLoading}
                    />
                </StyledInputPatterns>
            )}
            {!editExcludePatternAccordionVisible && formErrors && (
                <StyledErrorMessage> {formErrors?.url_pattern} </StyledErrorMessage>
            )}
            {renderEditAccordion()}
        </div>
    );
};
export default LayoutExcludedURLsForOxylabs;
