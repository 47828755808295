import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldValues } from 'react-hook-form';

// Resources
import {
    CONFIG_EVENT,
    CONFIG_GOALS,
    CONFIG_MARKET,
    CONFIG_PRODUCT_EVENT,
    CONFIG_PRODUCT_LOOKBACK,
} from '../../../configurations/resources-config';

// Redux
import { useDispatch } from 'react-redux';
import { setModal } from '../../../redux/actions/modal';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePostResourceWithPayload from '../../../react-query/hooks/use-post-resource-with-payload';

// Helpers
import { generatePath } from '../../../helpers/request-builder';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { ConfigDataSuccess } from '../../../react-query/types';
import { DropdownOption } from '../../../section-dashboard/types';

// Components
import ModalNavigation from '../../../components/modal-navigation';

import Form from '../../../components/forms/form';
import { addNotification } from '../../../redux/actions/notification';
import { NotificationMessageType } from '../../../enums/notification-types';
import TableAssignToMarket, { Event } from './tables/table-assign-to-market';
import FormCreateGoal from './forms/form-create-goal';
import { QueryStatus } from '@tanstack/react-query';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-bottom: 10px;
`;

const LayoutModalCreateGoal = () => {
    const dispatch = useDispatch();

    const [events, setEvents] = useState<Event[]>([]);
    const [formData, setFormData] = useState<FieldValues>();
    const [savedGoalId, setSavedGoalId] = useState<number>();
    const [mutationStatus, setMutationStatus] = useState<QueryStatus>();

    const eventsQuery = useFetchResource<ConfigDataSuccess, Event[]>({
        resource: CONFIG_EVENT,
        params: [{ key: 'active', value: 'true' }],
        select: data => {
            return data.objects.map(object => {
                return {
                    id: object.id,
                    name: String(object.name),
                    sale: object.sale,
                    selected: false,
                };
            });
        },
    });

    useEffect(() => {
        if (eventsQuery.data) {
            setEvents(eventsQuery.data);
        }
    }, [eventsQuery.data]);

    const productPostMutation = usePostResourceWithPayload({
        resource: CONFIG_GOALS,
        handleOnSuccess: response => {
            setSavedGoalId(response.id as number);
        },
    });

    const productLookbackPostMutation = usePostResourceWithPayload({
        resource: CONFIG_PRODUCT_LOOKBACK,
        handleOnSuccess: () => {},
    });

    const productMarketPostMutation = usePostResourceWithPayload({
        resource: CONFIG_MARKET,
        handleOnSuccess: () => {},
    });

    const productEventPostMutation = usePostResourceWithPayload({
        resource: CONFIG_PRODUCT_EVENT,
        handleOnSuccess: () => {},
    });

    useEffect(() => {
        if (savedGoalId && formData) {
            const lookbackMutation = productLookbackPostMutation.mutateAsync({
                product: generatePath('config', 'product', String(savedGoalId)),
                active: true,
                model_lookback: 120, // set default lookback to 120 days when creating a goal
            });

            const marketMutations = formData.assignedToMarket
                ? formData.assignedMarketList.map((market: DropdownOption) =>
                      productMarketPostMutation.mutateAsync({
                          product: generatePath('config', 'product', String(savedGoalId)),
                          market: generatePath('config', 'seogd-market', market.value),
                      })
                  )
                : [];

            const eventsMutations = events
                .filter(event => event.selected)
                .map(event =>
                    productEventPostMutation.mutateAsync({
                        event: generatePath('config', 'event', String(event.id)),
                        product: generatePath('config', 'product', String(savedGoalId)),
                        weight: 1,
                        sale: true,
                        active: 1,
                        reach: 0,
                    })
                );

            Promise.all([lookbackMutation, ...marketMutations, ...eventsMutations]).then(() => {
                dispatch(addNotification({ copy: 'Goal created successfully', type: NotificationMessageType.Success }));
                onCloseClick();
            });
        }
    }, [savedGoalId, formData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const statuses = [
            productPostMutation.status,
            productLookbackPostMutation.status,
            productMarketPostMutation.status,
            productEventPostMutation.status,
        ];

        if (statuses.some(status => status === 'pending')) {
            setMutationStatus('pending');
        }

        if (statuses.every(status => status === 'success')) {
            dispatch(addNotification({ copy: 'Goal created successfully', type: NotificationMessageType.Success }));
            dispatch(setModal('ManageGoals'));

            setMutationStatus('success');
        }

        if (statuses.some(status => status === 'error')) {
            dispatch(
                addNotification({
                    copy: 'There was an issue adding this Goal. Please try again later or contact support@cubed.email',
                    type: NotificationMessageType.Error,
                })
            );

            setMutationStatus('error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        productPostMutation.status,
        productLookbackPostMutation.status,
        productMarketPostMutation.status,
        productEventPostMutation.status,
    ]);

    const handleEventSelectChange = (rowId: number) => {
        setEvents(events.map(event => (event.id === rowId ? { ...event, selected: !event.selected } : event)));
    };

    const onCloseClick = () => {
        dispatch(setModal('ManageGoals'));
    };

    const handleSaveClick = (data: FieldValues) => {
        setFormData(data);

        productPostMutation.mutate({
            name: data.name,
            revenue_override: data.revenueOverride * 100,
            default: data.productType === 4 || data.productType === 5 ? false : data.default,
            product_type: generatePath('config', 'product-type', data.productType),
        });
    };

    // Store form default values as state to avoid resetting values on re-render
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [formDefaultValues, _] = useState({
        name: '',
        productType: undefined,
        revenueOverride: 0,
    });

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'back',
                        onClick: onCloseClick,
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Create Goal</h2>
            <p>
                Create a new goal using the wizard below. Goals you may wish to configure include product purchases,
                newsletter signups, PDF downloads etc. For more help see{' '}
                <a href="https://tag.docs.withcubed.com/onboarding/general/#goals">setting up goals</a>.
            </p>

            <Form onSubmit={handleSaveClick} defaultValues={formDefaultValues}>
                <StyledFormContainer>
                    <FormCreateGoal disabled={mutationStatus === 'pending'} />
                </StyledFormContainer>
                <StyledFormContainer>
                    <TableAssignToMarket
                        status={eventsQuery.status}
                        disabled={mutationStatus === 'pending'}
                        empty={eventsQuery.data?.length === 0}
                        events={events}
                        handleEventSelectChange={handleEventSelectChange}
                    />
                </StyledFormContainer>
            </Form>
        </>
    );
};

export default LayoutModalCreateGoal;
