import React from 'react';
import styled from 'styled-components';

// Types
import { TableColumn, TableComparisonTableRow } from '../../types';

// Helpers
import { getValue } from '../../helpers/table-data';
import { formatVariance } from '../../helpers/variance-helpers';

const StyledCardContainer = styled.div<{ width: number }>`
    width: 100%;
    position: relative;
    display: ${props => props.width > 2 && 'flex'};
    gap: ${props => props.width > 2 && '5px'};
`;

const StyledCardTable = styled.table`
    color: ${props => props.theme.sectionDashboard.widget.table.cardTable.textColor};
    width: calc(100% - 10px);
    border-collapse: collapse;
    font-size: 0.7rem;
    padding: 5px;
    border: 1px solid ${props => props.theme.sectionDashboard.widget.table.cardTable.borderColor};
    margin: 0 auto;
    margin-top: 5px;
    background: ${props => props.theme.sectionDashboard.widget.table.cardTable.backgroundColor};

    &:nth-child(odd) {
        background: ${props => props.theme.sectionDashboard.widget.table.cardTable.backgroundColorOdd};
    }

    &:last-child {
        margin-bottom: 5px;
    }
`;

const StyledCardHeaderTD = styled.td<{ width: number }>`
    font-weight: 700;
    padding: 5px;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.cardTable.borderColor};
    text-align: left;
`;

const StyledColumnName = styled.p`
    font-weight: 600;
    padding: 5px;
    margin: 0;
`;

const StyledTable = styled.table`
    width: 100%;
`;

const StyledTR = styled.tr`
    border: 1px solid ${props => props.theme.sectionDashboard.widget.table.cardTable.borderColor};
`;

const StyledTH = styled.th`
    font-weight: 500;
    font-size: 0.6rem;
    padding: 5px;
    text-align: left;
`;

const StyledTD = styled.td<{ variance?: number }>`
    padding: 5px;
    font-weight: 600;
    color: ${props =>
        props.variance
            ? props.variance > 0
                ? props.theme.sectionDashboard.widget.variance.textColorPositive
                : props.variance < 0
                ? props.theme.sectionDashboard.widget.variance.textColorNegative
                : props.theme.sectionDashboard.widget.variance.textColor
            : props.theme.sectionDashboard.widget.variance.textColor};
`;

export type WidgetTableCardBoxComparisonProps = {
    rows: TableComparisonTableRow[];
    columns: TableColumn[];
    width: number;
};

const WidgetTableCardBoxComparison = ({ rows, columns, width }: WidgetTableCardBoxComparisonProps) => {
    return (
        <StyledCardContainer width={width}>
            {rows.map(row => {
                return (
                    <StyledCardTable key={row.__id}>
                        <tbody>
                            {columns.map((column, index) => {
                                if (index === 0) {
                                    return (
                                        <tr key={column.displayName}>
                                            <StyledCardHeaderTD width={width}>{`${column.displayName} : ${getValue(
                                                row.dimension,
                                                column.rawName
                                            )}`}</StyledCardHeaderTD>
                                        </tr>
                                    );
                                } else {
                                    let value: string | number = 'No Data';
                                    let comparisonValue: string | number = 'No Data';
                                    let variance: string | number = 'No Data';
                                    if (row.data) {
                                        value = getValue(row.data, column.rawName);
                                    }
                                    if (row.comparisonData) {
                                        comparisonValue = getValue(row.comparisonData, column.rawName);
                                    }
                                    if (row.variance) {
                                        variance = getValue(row.variance, column.rawName);
                                    }

                                    return (
                                        <StyledTR>
                                            <td>
                                                <StyledColumnName>{column.displayName}:</StyledColumnName>
                                                <StyledTable>
                                                    <thead>
                                                        <tr>
                                                            <StyledTH>This Period</StyledTH>
                                                            <StyledTH>Comparison Period</StyledTH>
                                                            <StyledTH>Variance %</StyledTH>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <StyledTD>{value}</StyledTD>
                                                            <StyledTD>{comparisonValue}</StyledTD>
                                                            <StyledTD variance={+variance}>
                                                                {formatVariance(+variance)}
                                                            </StyledTD>
                                                        </tr>
                                                    </tbody>
                                                </StyledTable>
                                            </td>
                                        </StyledTR>
                                    );
                                }
                            })}
                        </tbody>
                    </StyledCardTable>
                );
            })}
        </StyledCardContainer>
    );
};

export default WidgetTableCardBoxComparison;
