import DataTypes from '../../filter-bar/enums/data-types';
import { TableFields } from '../../types';

export const VISIT_ID: TableFields = {
    id: 'visit_id',
    rawName: 'id',
    displayName: 'Visit ID',
    defaultOrderDir: 'Desc',
    isDimension: true,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.NUMBER,
};

export const IMPRESSION_VISIT_ID: TableFields = {
    id: 'impression_visit_id',
    rawName: 'impression_visit__id',
    displayName: 'Visit ID',
    defaultOrderDir: 'Desc',
    isDimension: true,
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.NUMBER,
};

export const URL: TableFields = {
    id: 'url',
    rawName: 'url',
    displayName: 'Url',
    defaultOrderDir: 'asc',
    sortBy: false,
    sort: 'desc',
    dataType: DataTypes.STRING,
    metricWidth: '',
};
