// React Dependencies
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink, useLocation } from 'react-router-dom';

// Core Dependencies
import Axios from 'axios';
import { generateUrl } from '../helpers/request-builder';
import { isString, isEmail, isPassword } from '../helpers/validator';

// Redux Actions
import { setUser } from '../redux/actions/user';
import { addNotification } from '../redux/actions/notification';
import { NotificationMessageType } from '../enums/notification-types';

// Import Components
import InputButton from '../components/inputs/input-button';
import FormEmail from '../components/form-fields/form-email';
import FormPassword from '../components/form-fields/form-password';

// Import Views
import ViewNotification from './notifications';

// Import Styled-Components
import styled from 'styled-components';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import imageLogoWhiteFull from '../assets/images/white-cubed-logo-full.png';

const StyledForm = styled.div`
    ${props => props.theme.dropShadow('5px', '5px', '3px', '0px', 'rgba(0, 0, 0, 0.1)')};
    text-align: left;
    background-color: ${props => props.theme.colours.whiteBg};
    border-radius: 2px;
    padding: 20px;
    width: 390px;
`;

const StyledLogo = styled.img`
    height: 50px;
    width: auto;
    object-fit: initial;
    object-position: initial;
    margin-bottom: 15px;
    display: initial;
`;

const StyledHeading = styled.h3`
    margin-top: 0;
    color: ${props => props.theme.colours.offBlack};
`;

const StyledLoginContent = styled.div`
    position: absolute;
    text-align: center;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`;

const StyledA = styled(NavLink)`
    display: inline-block;
    color: ${props => props.theme.colours.offBlack};
    &:visited {
        color: ${props => props.theme.colours.offBlack};
    }
`;

const StyledInputFieldButton = styled.div`
    display: inline-block;
    margin-right: 15px;
`;

const StyledBackgroundImage = styled.img`
    height: 100%;
    width: 100vw;
    object-fit: cover;
    object-position: top;
    display: block;
`;

const StyledLoginView = styled.div`
    position: relative;
    height: 100vh;
`;

const ViewLogin = () => {
    const { user } = useSelector(state => state);

    const dispatch = useDispatch();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [usernameIsEmail, setUsernameIsEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [queryParam, setQueryParam] = useState('');

    const { search } = useLocation();

    useEffect(() => {
        setQueryParam(search);
        setStrongPassword(isPassword(password));
        setUsernameIsEmail(isEmail(email));
    }, [email, password, search]); // eslint-disable-next-line

    const nextRedirect = () => {
        // Navigate user to url in the query param
        const redirectUrl = queryParam.substring(1).split('=')[1];
        if (/api/.test(redirectUrl)) {
            setIsLoading(true);
            window.location.href = redirectUrl;
        }
        return <Navigate to={redirectUrl} />;
    };

    const formValidator = () => {
        let hasFormError = false;
        let errorMessageObject = {
            'email__error-message': '',
            'password__error-message': '',
        };

        if (!isString(email) || email.length === 0) {
            hasFormError = true;
            errorMessageObject['email__error-message'] = 'Please enter a valid email address.';
        }

        if (!isString(password) || password.length === 0) {
            hasFormError = true;
            errorMessageObject['password__error-message'] = 'Please enter a valid password.';
        }

        if (!isPassword(password) && !isEmail(email)) {
            hasFormError = true;
        }

        if (hasFormError) {
            setEmailErrorMessage(errorMessageObject['email__error-message']);
            setPasswordErrorMessage(errorMessageObject['password__error-message']);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    const login = () => {
        const loginUrl = generateUrl('user', 'login', [], false);

        const loginConfig = {
            method: 'POST',
            url: loginUrl,
            data: {
                email: email,
                password: password,
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        Axios(loginConfig)
            .then(res => {
                dispatch(
                    setUser({
                        id: res.data.id,
                        username: res.data.username,
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        email: res.data.email,
                        staff: res.data.staff,
                        superUser: res.data.superUser,
                        accounts: [],
                        strongPassword: strongPassword,
                        usernameIsEmail: usernameIsEmail,
                    })
                );
                setIsLoggedIn(true);
                setIsLoading(false);
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'Incorrect username/password combination. Please try again.',
                        type: NotificationMessageType.Error,
                    })
                );
                setIsLoading(false);
            });
    };

    const onLoginClick = () => {
        setIsLoading(true);

        if (formValidator()) {
            login();
        } else {
            setIsLoading(false);
        }
    };

    const onEnterKeyPress = event => {
        if (event.key === 'Enter') {
            onLoginClick();
        }
    };

    const onEmailChange = event => {
        setEmail(event.target.value);
    };

    const onPasswordChange = event => {
        setPassword(event.target.value);
    };

    if (/\?next/.test(queryParam) === true && (user.isLoggedIn === true || isLoggedIn === true)) {
        nextRedirect();
    } else if (
        (isLoggedIn === true || user.isLoggedIn === true) &&
        (strongPassword === false ||
            user.strongPassword === false ||
            usernameIsEmail === false ||
            user.usernameIsEmail === false)
    ) {
        return <Navigate to="/force-update-details" />;
    } else if (isLoggedIn === true || user.isLoggedIn === true) {
        return <Navigate to="/account-selection" />;
    }

    return (
        <StyledLoginView>
            <StyledBackgroundImage src={imageLoginBackground} alt="Cubed" />
            <StyledLoginContent>
                <StyledLogo src={imageLogoWhiteFull} />
                <StyledForm>
                    <StyledHeading>Login</StyledHeading>
                    <FormEmail
                        inputValue={email}
                        inputOnChange={onEmailChange}
                        errorMessage={emailErrorMessage}
                        onInputKeyPress={onEnterKeyPress}
                    />
                    <FormPassword
                        inputValue={password}
                        inputOnChange={onPasswordChange}
                        errorMessage={passwordErrorMessage}
                        onInputKeyPress={onEnterKeyPress}
                    />
                    <StyledInputFieldButton>
                        <InputButton isLoading={isLoading} value="Login" onClick={onLoginClick} />
                    </StyledInputFieldButton>
                    <StyledA to="/forgotten-password">Forgotten Password?</StyledA>
                </StyledForm>
            </StyledLoginContent>
            <ViewNotification />
        </StyledLoginView>
    );
};

export default ViewLogin;
