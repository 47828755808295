import Request from '../../helpers/request';
import { Product, Channel } from '../types';

export const fetchProducts = async () => {
    const productRequest = new Request();
    const response = await productRequest.get('config', 'dash-product');

    if (response.data.objects.length > 0) {
        let allProducts = response.data.objects;
        let defaultProducts = allProducts.filter((object: Product) => object.default);
        let selectedProducts = defaultProducts.length > 0 ? defaultProducts : allProducts;

        const productOptions =
            allProducts.length === 0
                ? []
                : allProducts.map((product: Product) => {
                      return { ...product, value: product.id, label: product.name };
                  });

        const localProductInitialOptions =
            defaultProducts.length > 0 || allProducts.length === 0
                ? Array.from(productOptions)
                : allProducts.length > 0
                ? allProducts.map((product: Product) => {
                      return { ...product, value: product.id, label: product.name, default: true };
                  })
                : [];

        const selectedProductOptions =
            selectedProducts.length === 0
                ? []
                : selectedProducts.map((product: Product) => {
                      return { ...product, value: product.id, label: product.name };
                  });

        return {
            productOptions: productOptions,
            localProductInitialOptions: localProductInitialOptions,
            selectedProductOptions: selectedProductOptions,
        };
    }
};

export const fetchOperators = async () => {
    let operatorRequest = new Request();
    const response = await operatorRequest.get('config', 'data-type-operator');

    if (response.data.objects.length > 0) {
        return response.data.objects;
    }
    return [];
};

export const fetchChannels = async () => {
    let channelRequest = new Request();

    const response = await channelRequest.get('config', 'dash-referer');

    if (response.data.objects.length > 0) {
        let allChannels = response.data.objects
            .filter((object: Channel) => object.active)
            .map((channel: Channel) => {
                return {
                    ...channel,
                    default: true,
                };
            });

        const channelOptions = allChannels.map((channel: Channel) => {
            return { ...channel, value: channel.id, label: channel.name };
        });

        return channelOptions;
    }
};
