import React from 'react';
import styled, { keyframes } from 'styled-components';

const StyledLoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    font-size: 2rem;
`;

const StyledLoadingText = styled.p`
    font-weight: 700;
    margin-bottom: 0;
    letter-spacing: 3px;
`;

const StyledEllipsisAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const StyledEllipsisSpan = styled.span`
    opacity: 0;
    animation: ${StyledEllipsisAnimation} 1s infinite;

    &:nth-child(1) {
        animation-delay: 0s;
    }
    &:nth-child(2) {
        animation-delay: 0.1s;
    }
    &:nth-child(3) {
        animation-delay: 0.2s;
    }
`;

const LoadingEllipsis = () => {
    return (
        <StyledLoadingContainer>
            <StyledLoadingText>
                <StyledEllipsisSpan>.</StyledEllipsisSpan>
                <StyledEllipsisSpan>.</StyledEllipsisSpan>
                <StyledEllipsisSpan>.</StyledEllipsisSpan>
            </StyledLoadingText>
        </StyledLoadingContainer>
    );
};

export default LoadingEllipsis;
