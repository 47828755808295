import React, { createContext, useContext } from 'react';
import { SectionDashboardLayout } from '../types';

export type SectionDashboardContextDefinition = {
    sectionIndex: number;
    x: number;
    y: number;
    width: number;
    height: number;
    area: string;
    layout: SectionDashboardLayout;
};

export type SectionDashboardProviderProps = SectionDashboardContextDefinition & {
    children: JSX.Element;
};

export const SectionDashboardContext = createContext<SectionDashboardContextDefinition | null>(null);

export const useSectionDashboard = () => {
    const context = useContext(SectionDashboardContext);

    if (!context) {
        throw new Error('useSectionDashboard has to be used within <SectionDashboardProvider/>');
    }

    return context;
};

const SectionDashboardProvider = ({
    sectionIndex,
    x,
    y,
    width,
    height,
    area,
    layout,
    children,
}: SectionDashboardProviderProps) => {
    const value = { sectionIndex, x, y, width, height, area, layout };

    return <SectionDashboardContext.Provider value={value}>{children}</SectionDashboardContext.Provider>;
};

export default SectionDashboardProvider;
