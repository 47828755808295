import { REPORT_CONTENT_DASHBOARD_PATH } from '../configurations/resources';
import { ResourceDataSuccess } from '../react-query/types';
import { filterUnknownRegex } from '../section-dashboard/helpers/get-resource-filters';
import { SelectQuery } from '../section-dashboard/types';
import { CubedField } from '../types';

type ContentInsightsPageTypeQueryArgs = {
    currentPageTypeField: CubedField;
    startDate: string;
    endDate: string;
    excludeUnknown?: boolean;
};

export const contentInsightsPageTypeDropdownQuery = ({
    currentPageTypeField,
    startDate,
    endDate,
    excludeUnknown = false,
}: ContentInsightsPageTypeQueryArgs) => {
    return {
        resource: REPORT_CONTENT_DASHBOARD_PATH,
        params: [
            { key: `${REPORT_CONTENT_DASHBOARD_PATH.dateDimension.rawName}__gte`, value: startDate },
            { key: `${REPORT_CONTENT_DASHBOARD_PATH.dateDimension.rawName}__lte`, value: endDate },
            { key: 'group_by', value: currentPageTypeField.rawName },
            { key: 'order_by', value: currentPageTypeField.rawName },
            { key: 'limit', value: 0 },
            { key: 'selected_fields', value: `${currentPageTypeField.rawName}` },
            ...(excludeUnknown ? [{ key: `${currentPageTypeField.rawName}__iregex`, value: filterUnknownRegex }] : []),
        ],
        select: (data: ResourceDataSuccess) => {
            return data.objects.map(object => {
                const fieldName = currentPageTypeField.rawName;
                return {
                    label: object.values[fieldName].value,
                    value: object.values[fieldName].value,
                };
            });
        },
    } as SelectQuery;
};
