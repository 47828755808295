// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';

// Core Dependencies
import Axios from 'axios';
import {
    isEmail,
    isPassword,
    isString,
    hasCapitalLetter,
    hasLowerCaseLetter,
    hasNumber,
    hasSpecialCharacter,
    hasPasswordValidCharacters,
} from '../helpers/validator';
import { generateUrlDetail, generateUrl } from '../helpers/request-builder';

// Redux Actions
import { updateUser } from '../redux/actions/user';
import { addNotification } from '../redux/actions/notification';
import { NotificationMessageType } from '../enums/notification-types';

// Import Components
import FormEmail from '../components/form-fields/form-email';
import FormPassword from '../components/form-fields/form-password';
import InputButton from '../components/inputs/input-button';
import IconArrow from '../components/icons/arrow';

// Import Views
import ViewNotifications from './notifications';
import PasswordRequirements from '../components/password-requirements';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import imageLogoWhiteFull from '../assets/images/white-cubed-logo-full.png';

class ViewForceUpdateDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isButtonLoading: false,
            isComplete: false,
            email: this.props.user.email,
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            'new-password__hasLength': false,
            'new-password__hasCapitalLetter': false,
            'new-password__hasLowercaseLetter': false,
            'new-password__hasNumber': false,
            'new-password__hasSpecialCharacter': false,
            'new-password__hasValidCharacters': false,
            'email__error-message': '',
            'new-password__error-message': '',
            'repeat-new-password__error-message': '',
        };
    }

    formValidator = () => {
        this.setState({
            'email__error-message': '',
            'current-password__error-message': '',
            'new-password__error-message': '',
            'repeat-new-password__error-message': '',
        });

        let hasFormError = false;
        let errorMessageObject = {
            'email__error-message': '',
            'current-password__error-message': '',
            'new-password__error-message': '',
            'repeat-new-password__error-message': '',
        };

        if (this.props.user.usernameIsEmail === false) {
            if (!isEmail(this.state.email) || this.state.email.length === 0) {
                hasFormError = true;
                errorMessageObject['email__error-message'] = 'Please enter a valid email address.';
            }
        }

        if (this.props.user.strongPassword === false) {
            if (!isString(this.state.currentPassword) || this.state.currentPassword.length === 0) {
                hasFormError = true;
                errorMessageObject['current-password__error-message'] = 'Please enter a valid password.';
            }

            if (!isPassword(this.state.newPassword) || this.state.newPassword.length === 0) {
                hasFormError = true;
                errorMessageObject['new-password__error-message'] = 'Please enter a valid password.';
            }

            if (this.state.newPassword !== this.state.repeatNewPassword || this.state.repeatNewPassword.length === 0) {
                hasFormError = true;
                errorMessageObject['repeat-new-password__error-message'] =
                    'Please ensure that the new passwords match.';
            }
        }

        if (hasFormError) {
            this.setState({
                'email__error-message': errorMessageObject['email__error-message'],
                'current-password__error-message': errorMessageObject['current-password__error-message'],
                'new-password__error-message': errorMessageObject['new-password__error-message'],
                'repeat-new-password__error-message': errorMessageObject['repeat-new-password__error-message'],
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    updateDetails = () => {
        const requests = [];

        if (this.props.user.usernameIsEmail === false) {
            requests.push(
                Axios({
                    method: 'PUT',
                    url: generateUrlDetail('config', 'users', this.props.user.id, [], false),
                    data: {
                        old_username: this.props.user.username,
                        username: this.state.email,
                        email: this.state.email,
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        }

        if (this.props.user.strongPassword === false) {
            requests.push(
                Axios({
                    method: 'POST',
                    url: generateUrl('user', 'password', [], false),
                    data: {
                        current_password: this.state.currentPassword,
                        new_password1: this.state.newPassword,
                        new_password2: this.state.repeatNewPassword,
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        }

        Axios.all(requests)
            .then(res => {
                this.setState({
                    isComplete: true,
                    isButtonLoading: false,
                });

                this.props.updateUser({
                    id: this.props.user.id,
                    username: this.state.email,
                    firstName: this.props.user.firstName,
                    lastName: this.props.user.lastName,
                    email: this.state.email,
                    accounts: this.props.user.accounts,
                    superUser: this.props.user.superUser,
                    staff: this.props.user.staff,
                    strongPassword: true,
                    usernameIsEmail: true,
                });

                this.props.addNotification({
                    copy: 'Your details have been successfully updated. Please login again to access your account.',
                    type: NotificationMessageType.Success,
                });
            })
            .catch(error => {
                this.props.addNotification({
                    copy: 'There was an issue trying to update your details. Please try again later',
                    type: NotificationMessageType.Error,
                });

                this.setState({
                    isComplete: false,
                    isButtonLoading: false,
                });
            });
    };

    onUpdateDetailsClick = () => {
        this.setState({
            isButtonLoading: true,
        });

        if (this.formValidator() === true) {
            this.updateDetails();
        } else {
            this.setState({
                isButtonLoading: false,
            });
        }
    };

    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    };

    handleCurrentPasswordChange = event => {
        this.setState({ currentPassword: event.target.value });
    };

    handleNewPasswordChange = event => {
        const newPass = event.target.value;
        this.setState({
            newPassword: newPass,
            'new-password__hasLength': newPass.length >= 8,
            'new-password__hasCapitalLetter': hasCapitalLetter(newPass),
            'new-password__hasLowercaseLetter': hasLowerCaseLetter(newPass),
            'new-password__hasNumber': hasNumber(newPass),
            'new-password__hasSpecialCharacter': hasSpecialCharacter(newPass),
            'new-password__hasValidCharacters': hasPasswordValidCharacters(newPass),
        });
    };

    handleRepeatNewPasswordChange = event => {
        this.setState({ repeatNewPassword: event.target.value });
    };

    renderUserDetailsForm = () => {
        if (this.props.user.usernameIsEmail === false) {
            return (
                <div className="force-update-details__content__form__update-details">
                    <FormEmail
                        inputPlaceholder="Email..."
                        inputValue={this.state.email}
                        inputOnChange={this.handleEmailChange}
                        errorMessage={this.state['email__error-message']}
                    />
                </div>
            );
        }

        return null;
    };

    renderPasswordResetForm = () => {
        if (this.props.user.strongPassword === false) {
            return (
                <div className="force-update-details__content__form__password-reset">
                    <FormPassword
                        inputPlaceholder="Current Password..."
                        inputValue={this.state.currentPassword}
                        inputOnChange={this.handleCurrentPasswordChange}
                        errorMessage={this.state['current-password__error-message']}
                    />
                    <FormPassword
                        inputPlaceholder="New Password..."
                        inputValue={this.state.newPassword}
                        inputOnChange={this.handleNewPasswordChange}
                        errorMessage={this.state['new-password__error-message']}
                    />
                    <FormPassword
                        inputPlaceholder="Repeat New Password..."
                        inputValue={this.state.repeatNewPassword}
                        inputOnChange={this.handleRepeatNewPasswordChange}
                        errorMessage={this.state['repeat-new-password__error-message']}
                    />
                    <PasswordRequirements
                        hasCharacterLength={this.state['new-password__hasLength']}
                        hasCapitalLetter={this.state['new-password__hasCapitalLetter']}
                        hasLowercaseLetter={this.state['new-password__hasLowercaseLetter']}
                        hasNumber={this.state['new-password__hasNumber']}
                        hasSpecialCharacter={this.state['new-password__hasSpecialCharacter']}
                        hasValidCharacter={this.state['new-password__hasValidCharacters']}
                    />
                </div>
            );
        }

        return null;
    };

    render() {
        if (this.props.user.isLoggedIn === false) {
            return <Navigate to="/" />;
        } else if (
            this.state.isComplete === false &&
            this.props.user.usernameIsEmail &&
            this.props.user.strongPassword
        ) {
            return <Navigate to="/account-selection" />;
        } else if (this.state.isComplete === true) {
            return <Navigate to="/sign-out" />;
        }

        let intro = '';

        if (this.props.user.usernameIsEmail === false) {
            intro.concat(
                '<p>Please check that your email address is up to date, this will now be used as your username instead of your previous username.</p>'
            );
        }
        if (this.props.user.strongPassword === false) {
            intro.concat(
                '<p>We have noticed that your current password does not meet our new requirements, please enter a new password for this account.</p>'
            );
        }

        return (
            <div id="force-update-details__view">
                <img src={imageLoginBackground} alt="Background" />
                <div className="force-update-details__content">
                    <img src={imageLogoWhiteFull} alt="Yard" />
                    <div className="force-update-details__content__form">
                        <NavLink to="/sign-out">
                            <div className="icon icon-arrow">
                                <IconArrow />
                            </div>
                        </NavLink>
                        <h3>Update your details</h3>
                        <p>
                            With the latest version of the Cubed Dashboard we have decided to enforce changes to every
                            users account. This process will log you out of your account.
                        </p>
                        <div
                            className="force-update-details__content__form__intro"
                            dangerouslySetInnerHTML={{ __html: intro }}
                        ></div>

                        <this.renderUserDetailsForm />
                        <this.renderPasswordResetForm />
                        <div className="force-update-details__content__form__buttons">
                            <InputButton
                                isLoading={this.state.isButtonLoading}
                                value="UPDATE DETAILS"
                                onClick={this.onUpdateDetailsClick}
                            />
                        </div>
                    </div>
                </div>
                <ViewNotifications />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        meta: state.meta,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUser: user => {
            dispatch(updateUser(user));
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewForceUpdateDetails);
