import React from 'react';
import styled from 'styled-components';

// Types
import { ContributionTableData } from '../../types';

// Styles
const StyledTableData = styled.td`
    border: 1px solid ${props => props.theme.colours.borderGrey};
    position: relative;
`;

const StyledTableDataDiv = styled.div`
    display: inline-block;
    padding: 12px !important;
    margin-left: 22px;
    position: relative;
`;

const StyledChannelColour = styled.span<{ backgroundColor: string }>`
    margin: 0;
    position: absolute;
    width: 22px;
    height: 100%;
    background-color: ${props => props.backgroundColor};
`;

// Types
type ContributionTableBodyProps = {
    data: ContributionTableData[];
};

const ContributionTableBody = ({ data }: ContributionTableBodyProps) => {
    return (
        <tbody>
            {data.map(row => {
                return (
                    <tr key={row.key}>
                        <StyledTableData key="lc-ref">
                            <StyledChannelColour backgroundColor={row.lc_referer.colour.getOriginalInput() as string} />
                            <StyledTableDataDiv>{row.lc_referer.name}</StyledTableDataDiv>
                        </StyledTableData>
                        <StyledTableData key="fm-ref">
                            <StyledChannelColour backgroundColor={row.fm_referer.colour.getOriginalInput() as string} />
                            <StyledTableDataDiv>{row.fm_referer.name}</StyledTableDataDiv>
                        </StyledTableData>
                        <StyledTableData key="sales">
                            <StyledTableDataDiv>{row.sales}</StyledTableDataDiv>
                        </StyledTableData>
                        <StyledTableData key="rev">
                            <StyledTableDataDiv>{row.revenue}</StyledTableDataDiv>
                        </StyledTableData>
                    </tr>
                );
            })}
        </tbody>
    );
};

export default ContributionTableBody;
