import React from 'react';
import styled, { useTheme } from 'styled-components';
import { pieChartConfig, pieChartDataLabelsConfig } from '../../configurations/widget-pie-chart-config';

// Types & Helpers
import { PieTableComparisonData, TotalComparisonData } from '../../types';
import { buildChartSeries } from '../../helpers/pie-chart-table-helpers';
import { buildPieRatio } from '../../helpers/build-pie-ratio';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Components
import WidgetBase from '../base/widget-base';
import Message from '../../../components/message';
import SectionDashboardPieComparisonTable from '../../components/tables/section-dashboard-pie-comparison-table';

const StyledContainer = styled.div<{ width: number; height: number }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: ${props =>
        props.width === 1 && props.height === 2 ? 'column' : props.width < 4 && props.height > 2 ? 'column' : 'row'};
`;

const StyledChartContainer = styled.div`
    width: 100%;
    max-width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 95%;
    overflow: hidden;
`;

const StyledTitleContainer = styled.div<{ height: number }>`
    text-align: center;
    font-size: ${props => (props.height > 1 ? '0.9rem ' : '0.8rem')};
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    padding: ${props => (props.height > 1 ? '10px 0px' : '3px 0px')};
    color: ${props => props.theme.sectionDashboard.widget.pieTableComparison.titleColor};
`;

const StyledTableContainer = styled.div`
    width: 100%;
    max-height: 100%;
    overflow: auto;
    align-self: center;
`;

const StyledMessageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export type WidgetPieChartTableComparisonProps = {
    title: string;
    data: PieTableComparisonData;
    totalData?: TotalComparisonData;
    href?: string;
    display?: 'pie' | 'donut' | 'semidonut';
    showLabels?: boolean;
    menu?: React.ReactElement;
};

const WidgetPieChartTableComparison = ({
    title,
    data,
    totalData,
    href,
    display = 'pie',
    showLabels = true,
    menu,
}: WidgetPieChartTableComparisonProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const { series, comparisonSeries, columns, rows, request, ratio, comparisonRatio, limit } = data;
    const isEnableTable = width > 4 && rows.length > 0;

    const ascendingSorts: string[] = [];
    const descendingSorts: string[] = [];
    const withFilters: string[] = [];

    if (request && request.orderBy) {
        request.orderBy.forEach(sort => {
            if (sort.orderByDirection === 'asc') {
                ascendingSorts.push(sort.field.rawName);
            } else if (sort.orderByDirection === 'desc') {
                descendingSorts.push(sort.field.rawName);
            }
        });
    }

    if (request && request.filters) {
        request.filters.forEach(filter => {
            withFilters.push(filter.field.rawName);
        });
    }

    const chartOptions = pieChartConfig(theme);

    const renderChartLabel = (periodLabel: string) => {
        let displayName;
        if (request && request.chartMetrics) {
            displayName = data?.request?.chartField?.displayName;
        }
        const chartLabel = displayName ? `${periodLabel} - ${displayName}` : periodLabel;
        return <StyledTitleContainer height={height}>{chartLabel}</StyledTitleContainer>;
    };

    const chartConfig = {
        ...chartOptions,
        chart: {
            ...chartOptions.chart,
            margin: (width === 1 && height && 1 && [0, 0, 0, 0]) || (height > 2 && [10, 10, 10, 10]),
        },
        plotOptions: {
            ...chartOptions.plotOptions,
            pie: {
                ...chartOptions.plotOptions.pie,
                startAngle: display === 'semidonut' ? -90 : 0,
                endAngle: display === 'semidonut' ? 90 : 360,
                center:
                    display === 'semidonut'
                        ? width > 3
                            ? ['50%', '70%']
                            : height > 2
                            ? ['50%', '60%']
                            : ['50%', '65%']
                        : ['50%', '50%'],
                dataLabels: pieChartDataLabelsConfig(showLabels, width, height),
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b> - {point.value}',
        },
    };

    const seriesChartConfig = {
        ...chartConfig,
        series: buildChartSeries(series, display),
    };

    const titleConfig = {
        useHTML: true,
        verticalAlign: 'middle',
        floating: true,
    };

    const thisPeriodRatioConfig = {
        title: {
            ...titleConfig,
            text: isEnableTable && ratio && limit ? buildPieRatio(theme, width, height, ratio, limit) : '',
        },
    };

    const comparisonPeriodRatioConfig = {
        title: {
            ...titleConfig,
            text:
                isEnableTable && comparisonRatio && limit
                    ? buildPieRatio(theme, width, height, comparisonRatio, limit)
                    : '',
        },
    };

    let comparisonSeriesChartConfig = {};

    if (comparisonSeries) {
        comparisonSeriesChartConfig = {
            ...chartConfig,
            series: buildChartSeries(comparisonSeries, display),
        };
    }

    if (width === 1 && height === 1) {
        return (
            <WidgetBase title={title} href={href} menu={menu}>
                <StyledChartContainer>
                    {series.length > 0 ? (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...seriesChartConfig }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                            width={width}
                        />
                    ) : (
                        <StyledMessageContainer>
                            <Message title="No Data" copy="Try adjusting your filters." type="info" size="xsmall" />
                        </StyledMessageContainer>
                    )}
                    {renderChartLabel('This Period')}
                </StyledChartContainer>
            </WidgetBase>
        );
    }

    const renderMessage = () => {
        return (
            <StyledMessageContainer>
                <Message
                    title="No Data"
                    shortTitle="No Data"
                    copy="Try adjusting your filters."
                    type="info"
                    size={width > 3 && height > 2 ? 'small' : 'xsmall'}
                    display="vertical"
                />
            </StyledMessageContainer>
        );
    };

    return (
        <WidgetBase title={title} href={href} menu={menu}>
            <StyledContainer width={width} height={height}>
                <StyledChartContainer>
                    {series.length > 0 ? (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...seriesChartConfig, ...thisPeriodRatioConfig }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                            width={width}
                        />
                    ) : (
                        renderMessage()
                    )}

                    {renderChartLabel('This Period')}
                </StyledChartContainer>

                {isEnableTable && (
                    <StyledTableContainer>
                        <SectionDashboardPieComparisonTable
                            columns={columns}
                            rows={rows}
                            width={width}
                            height={height}
                            totalData={totalData}
                            ascendingSorts={ascendingSorts}
                            descendingSorts={descendingSorts}
                            withFilters={withFilters}
                        />
                    </StyledTableContainer>
                )}

                <StyledChartContainer>
                    {comparisonSeries.length > 0 ? (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...comparisonSeriesChartConfig, ...comparisonPeriodRatioConfig }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                            width={width}
                        />
                    ) : (
                        renderMessage()
                    )}
                    {renderChartLabel('Comparison Period')}
                </StyledChartContainer>
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetPieChartTableComparison;
