import axios from 'axios';

// Redux
import { removeModal } from '../../redux/actions/modal';
import { setPopup } from '../../redux/actions/popup';

// Helpers & Reference Objects
import { generateUrl, generateUrlDetail, generatePath } from '../request-builder';

const updatePriority = rows => {
    const result = rows.map((row, index) => {
        return {
            ...row,
            columns: {
                ...row.columns,
                priority: index + 1,
            },
        };
    });
    return result;
};

export const reorderRows = (rows, startIndex, endIndex) => {
    let result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result = updatePriority(result);
    return result;
};

export const handleAddNewRow = (selectedPosition, selectedRow, segmentCurrentlyEditing, tableRows) => {
    const newRow = {
        columns: {
            name: segmentCurrentlyEditing.name,
            description: segmentCurrentlyEditing.description,
        },
    };

    let result = Array.from(tableRows);

    if (selectedPosition && selectedRow && selectedPosition.value !== '') {
        const newPosition = selectedPosition.value === 'before' ? selectedRow.value - 1 : selectedRow.value;
        result.splice(newPosition, 0, newRow);
    } else {
        result.push(newRow);
    }

    result = updatePriority(result);
    return result;
};

export const fetchRequests = (resourceGroup, resourceName) => {
    const requests = [];

    // get conditions
    requests.push(
        axios({
            method: 'GET',
            url: generateUrl('segmenter', 'segmenter-condition', [{ key: 'active', value: true }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
    );

    requests.push(
        axios({
            method: 'GET',
            url: generateUrl(resourceGroup, resourceName, [{ key: 'active', value: true }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
    );
    return requests;
};

export const resetTableRows = tableRows => {
    return tableRows.map(row => {
        return {
            ...row,
            selected: false,
        };
    });
};

export const showDeleteModal = (dispatch, handlePopupDiscardClick, handleDelete) => {
    dispatch(
        setPopup({
            title: 'Delete',
            iconType: 'warning',
            contentType: 'simple',
            config: {
                copy: 'Are you sure you would like to delete this rule?',
            },
            buttons: [
                {
                    onClick: handlePopupDiscardClick,
                    value: 'NO, KEEP THIS RULE',
                    style: 'secondary',
                },
                {
                    onClick: handleDelete,
                    value: 'YES',
                },
            ],
        })
    );
};

export const showSaveChangesModal = (dispatch, handlePopupDiscardClick, handleApplyEditChanges) => {
    // e.preventDefault();
    dispatch(
        setPopup({
            title: 'Save Changes',
            iconType: 'warning',
            contentType: 'simple',
            config: {
                copy: 'Are you sure you would like to save the changes to this rule?',
            },
            buttons: [
                {
                    onClick: handlePopupDiscardClick,
                    value: 'DISCARD CHANGES',
                    style: 'secondary',
                },
                {
                    onClick: handleApplyEditChanges,
                    value: 'YES, SAVE THESE CHANGES',
                },
            ],
        })
    );
};

export const handleCloseClick = dispatch => {
    dispatch(removeModal());
};

export const addRowRequest = ({
    results,
    segmenterRuleDataToUpdate,
    status,
    segmentCurrentlyEditing,
    allSegmenterRuleData,
    resource,
    fkResource,
    fkColumnName,
}) => {
    const requests = [];
    results.forEach(result => {
        const row = allSegmenterRuleData.filter(data => data.id === result.id);

        if (result.id) {
            // save modified reordered rows
            if (result.columns.priority !== row[0].priority) {
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail(resource.category, resource.name, result.id),
                        data: {
                            priority: result.columns.priority,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            }
        } else {
            if (segmenterRuleDataToUpdate.length > 0 && result.id !== segmenterRuleDataToUpdate[0].id) {
                // updates unique rule row
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail(resource.category, resource.name, segmenterRuleDataToUpdate[0].id),
                        data: {
                            name: result.columns.name,
                            description: result.columns.description,
                            priority: result.columns.priority,
                            rule: segmentCurrentlyEditing.rule,
                            [fkColumnName]: generatePath(fkResource.category, fkResource.name, status.value),
                            active: true,
                            deleted: false,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            } else {
                requests.push(
                    axios({
                        method: 'POST',
                        url: generateUrl(resource.category, resource.name),
                        data: {
                            name: result.columns.name,
                            description: result.columns.description,
                            priority: result.columns.priority,
                            rule: segmentCurrentlyEditing.rule,
                            [fkColumnName]: generatePath(fkResource.category, fkResource.name, status.value),
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            }
        }
    });
    return requests;
};

export const modifiedRowRequest = ({
    segmenterRuleDataToUpdate,
    segmentCurrentlyEditing,
    status,
    resource,
    fkResource,
    fkColumnName,
}) => {
    const requests = [];
    if (segmenterRuleDataToUpdate.length > 0 && segmentCurrentlyEditing.id !== segmenterRuleDataToUpdate[0].id) {
        // delete row currently editing
        requests.push(
            axios({
                method: 'DELETE',
                url: generateUrlDetail(resource.category, resource.name, segmentCurrentlyEditing.id),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // update unique rule row
        requests.push(
            axios({
                method: 'PATCH',
                url: generateUrlDetail(resource.category, resource.name, segmenterRuleDataToUpdate[0].id),
                data: {
                    name: segmentCurrentlyEditing.name,
                    description: segmentCurrentlyEditing.description,
                    rule: segmentCurrentlyEditing.rule,
                    priority: segmentCurrentlyEditing.priority,
                    [fkColumnName]: generatePath(fkResource.category, fkResource.name, status.value),
                    active: true,
                    deleted: false,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );
    } else {
        requests.push(
            axios({
                method: 'PATCH',
                url: generateUrlDetail(resource.category, resource.name, segmentCurrentlyEditing.id),
                data: {
                    name: segmentCurrentlyEditing.name,
                    description: segmentCurrentlyEditing.description,
                    rule: segmentCurrentlyEditing.rule,
                    [fkColumnName]: generatePath(fkResource.category, fkResource.name, status.value),
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );
    }
    return requests;
};
