import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    border: 1px solid ${props => props.theme.colours.borderGrey};
    border-radius: 50px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 0.95rem;
    padding-top: 1px;
    background-color: ${props => props.theme.colours.white};
    width: fit-content;
    line-height: 20px;
`;

const StyledLabel = styled.p`
    display: inline-block;
    font-weight: 500;
    font-size: 0.9rem;
    color: ${props => props.theme.colours.offBlack};
    margin: 0px 10px 0px 20px;
    text-transform: uppercase;
`;

const StyledOptionContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
`;

const StyledInput = styled.input`
    margin: 0;
    accent-color: ${props => props.theme.colours.offBlack};
`;

const StyledOptionLabel = styled.p`
    margin: 0px 20px 0px 5px;
    display: inline-block;
    font-weight: 400;
    color: ${props => props.theme.colours.textDefault};
`;

export type RadioSelectorOption = {
    label: string;
    value: string;
    checked: boolean;
    hidden: boolean;
};

export type RadioSelectorProps = {
    label: string;
    options: RadioSelectorOption[];
    onSelectedItemChange: (option: string) => void;
};

const RadioSelector = ({ label, options, onSelectedItemChange }: RadioSelectorProps) => {
    return (
        <StyledContainer>
            <StyledLabel>{label}:</StyledLabel>
            {options.map(option => {
                if (!option.hidden) {
                    return (
                        <StyledOptionContainer key={option.value}>
                            <StyledInput
                                type="radio"
                                value={option.value}
                                name={'radio-select'}
                                checked={option.checked}
                                onChange={event => onSelectedItemChange(event.target.value)}
                            />
                            <StyledOptionLabel>{option.label}</StyledOptionLabel>
                        </StyledOptionContainer>
                    );
                }
                return null;
            })}
        </StyledContainer>
    );
};

export default RadioSelector;
