/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';

import { CubedField } from '../../../../types';

type MenuSearchArgs = {
    debouncedSearchText: string;
    searchText: string;
    handleSearch: (search: string) => void;
};

type UseMenuSearchArgs = {
    dropdownField: CubedField;
};

const useMenuSearch = ({ dropdownField }: UseMenuSearchArgs): MenuSearchArgs => {
    const [searchText, setSearchText] = useState('');
    const [debouncedSearchText, setDebouncedSearchText] = useState('');

    useEffect(() => {
        setSearchText('');
        setDebouncedSearchText('');
    }, [dropdownField]);

    const debounceSearch = useMemo(
        () =>
            _debounce((search: string) => {
                setDebouncedSearchText(search);
            }, 500),
        []
    );

    const handleSearch = useCallback(
        (search: string) => {
            setSearchText(search);
            debounceSearch(search);
        },
        [debounceSearch]
    );

    return {
        debouncedSearchText,
        searchText,
        handleSearch,
    };
};

export default useMenuSearch;
