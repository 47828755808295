import moment from 'moment';

export const formatDimensionFilters = (dimensionFilters, RESOURCES, selectedTabId) => {
    const formattedFilters = [];
    dimensionFilters.forEach(filter => {
        let key = `${filter.field.rawName}${filter.field.lookupTerm ? '__' + filter.field.lookupTerm : ''}`;
        if (RESOURCES[selectedTabId].dimensions.includes(filter.field))
            formattedFilters.push({ key: filter.fuzzy ? `${key}__icontains` : key, value: filter.value });
    });

    return formattedFilters;
};

export const generateTableParams = (
    tabId,
    orderBy,
    orderByDir,
    tablePaginationsObject,
    tableFilterStateObject,
    tableLiveDataStateObject,
    RESOURCES,
    TABLE_API_KEYS,
    dimensionFilters,
    selectedTabId
) => {
    const orderDirModifier = orderByDir === 'desc' ? '-' : '';
    const sortBy = orderBy.rawName;

    const dateDimension = RESOURCES[tabId].dateDimension.rawName;

    const params = [
        ...formatDimensionFilters(dimensionFilters, RESOURCES, selectedTabId),
        {
            key: TABLE_API_KEYS.GROUP_BY,
            value: RESOURCES[tabId].defaultDimensions.map(group => group.rawName).toString(),
        },
        {
            key: TABLE_API_KEYS.ORDER_BY,
            value: orderDirModifier + sortBy,
        },
        {
            key: TABLE_API_KEYS.PRODUCT_IN,
            value: '',
        },
        {
            key: TABLE_API_KEYS.LIMIT,
            value: !tableLiveDataStateObject.isLive ? tablePaginationsObject.currentRowCount : 0,
        },
        {
            key: TABLE_API_KEYS.OFFSET,
            value: tablePaginationsObject.currentRowCount * (tablePaginationsObject.currentPage - 1),
        },
    ];

    if (tableFilterStateObject.isFilterSubmited) {
        if (tableFilterStateObject.searchCategory !== '') {
            params.push({
                key: `${tableFilterStateObject.searchCategory}__icontains`,
                value: tableFilterStateObject.searchTerm,
            });
        }
    }

    if (tableLiveDataStateObject.isLive) {
        if (tableLiveDataStateObject.dateRange.startDate !== undefined) {
            params.push({
                key: `${dateDimension}__gte`,
                value: tableLiveDataStateObject.dateRange.startDate.toISOString(true),
            });
        }
    } else {
        if (tableLiveDataStateObject.dateRange.startDate !== undefined) {
            params.push({
                key: `${dateDimension}__gte`,
                value: moment(tableLiveDataStateObject.dateRange.startDate).format('YYYY-MM-DDT00:00:00') + 'Z',
            });
        }

        if (tableLiveDataStateObject.dateRange.endDate !== undefined) {
            params.push({
                key: `${dateDimension}__lte`,
                value: moment(tableLiveDataStateObject.dateRange.endDate).format('YYYY-MM-DDT23:59:59') + 'Z',
            });
        }
    }

    return params;
};

export const generateSparkParams = (sparkLiveDataStateObject, dateDimension, group_by, group_by_type) => {
    const params = [];
    if (group_by !== undefined) {
        params.push(
            {
                key: `graph_group_by`,
                value: group_by,
            },
            {
                key: `group_by`,
                value: group_by,
            },
            {
                key: `graph`,
                value: true,
            }
        );
    }
    if (group_by_type !== undefined) {
        params.push({
            key: `graph_group_by_type`,
            value: `${group_by}__${group_by_type}`,
        });
    }

    if (sparkLiveDataStateObject.isLive) {
        if (sparkLiveDataStateObject.dateRange.startDate !== undefined) {
            params.push({
                key: `${dateDimension}__gte`,
                value: sparkLiveDataStateObject.dateRange.startDate.toISOString(true),
            });
        }

        if (sparkLiveDataStateObject.dateRange.endDate !== undefined) {
            params.push({
                key: `${dateDimension}__lte`,
                value: sparkLiveDataStateObject.dateRange.endDate.toISOString(true),
            });
        }
    } else {
        if (sparkLiveDataStateObject.dateRange.startDate !== undefined) {
            params.push({
                key: `${dateDimension}__gte`,
                value: moment(sparkLiveDataStateObject.dateRange.startDate).format('YYYY-MM-DDT00:00:00') + 'Z',
            });
        }

        if (sparkLiveDataStateObject.dateRange.endDate !== undefined) {
            params.push({
                key: `${dateDimension}__lte`,
                value: moment(sparkLiveDataStateObject.dateRange.endDate).format('YYYY-MM-DDT23:59:59') + 'Z',
            });
        }
    }

    return params;
};

export const generateTablePaginations = (startValue, endValue, tablePaginations) => {
    if (typeof startValue !== 'number' || typeof endValue !== 'number')
        throw new Error('Please provide a valid number for either startValue and endValue');

    const currentRange = [(startValue - 1) * endValue + 1, startValue * endValue];

    return {
        ...tablePaginations,
        currentPage: startValue,
        currentRowCount: endValue,
        currentRange,
    };
};
