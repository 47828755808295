import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';

export const config = {
    pageTitle: 'Page Performance',
    dateDimension: fields.SALES_DATE_DATETIME,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: true,
            isEnableMetrics: true,
        },
    },
    resources: [resources.REPORT_CONTENT_DASHBOARD_PATH, resources.REPORT_CONTENT_DASHBOARD_PATH_CHANNEL],
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'page-performance',
        allYMetrics: [
            fields.PAGE_VIEWS,
            fields.UNIQUE_PAGE_VIEWS,
            fields.PAGE_EXITS,
            fields.PAGE_EXIT_RATE,
            fields.LANDING_PAGE_VIEWS,
            fields.LANDING_PAGE_BOUNCES,
            fields.LANDING_PAGE_BOUNCE_RATE,
            fields.ASSISTS,
            fields.ASSISTS_PAGE_VIEWS_RATE,
            fields.SALES,
            fields.CONVERSIONS_CVR,
            fields.REVENUE,
        ],
        initialYMetrics: [fields.PAGE_VIEWS],
    },
    table: {
        tabListItems: [
            {
                tabName: 'pages',
                displayName: 'Page Traffic',
                isSelected: true,
                metrics: [fields.PAGE_VIEWS, fields.UNIQUE_PAGE_VIEWS, fields.PAGE_EXITS, fields.PAGE_EXIT_RATE],
            },
            {
                tabName: 'landing',
                displayName: 'Landing Page Traffic',
                metrics: [fields.LANDING_PAGE_VIEWS, fields.LANDING_PAGE_BOUNCES, fields.LANDING_PAGE_BOUNCE_RATE],
            },
            {
                tabName: 'sales',
                displayName: 'Conversions',
                metrics: [
                    fields.ASSISTS,
                    fields.ASSISTS_PAGE_VIEWS_RATE,
                    fields.SALES,
                    fields.CONVERSIONS_CVR,
                    fields.REVENUE,
                ],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.PATH_HOST.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_PATH,
                    },
                ],
            },
            [fields.PATH_PATH.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_GEOGRAPHY,
                    },
                ],
            },
            [fields.PATH_GEOGRAPHY.id]: {
                default: [
                    {
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_LANGUAGE,
                    },
                ],
            },
            [fields.PATH_MARKET.id]: {
                default: [
                    {
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_LANGUAGE,
                    },
                ],
            },
            [fields.PATH_HOMEPAGE.id]: {
                default: [
                    {
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_LANGUAGE.id]: {
                default: [
                    {
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_CONTENT_TYPE.id]: {
                default: [
                    {
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_CONTENT_LENGTH,
                    },
                ],
            },
            [fields.PATH_CATEGORY.id]: {
                default: [
                    {
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_CONTENT_TYPE,
                    },
                ],
            },
            [fields.PATH_CONTENT_LENGTH.id]: {
                default: [
                    {
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_TOPIC.id]: {
                default: [
                    {
                        resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
