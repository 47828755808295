import React from 'react';
import LayoutTableGraph from './../table-graph/layouts/table-graph';
import { config } from '../configurations/error-reporting';

const ReportValidationMessages = props => {
    const {
        location: { hash },
        fetchProducts,
        startDate,
        endDate,
        selectedProducts,
        selectedMetrics,
    } = props;

    return (
        <LayoutTableGraph
            hash={hash}
            fetchProducts={fetchProducts}
            config={config}
            startDate={startDate}
            endDate={endDate}
            selectedProducts={selectedProducts}
            selectedMetrics={selectedMetrics}
        />
    );
};

export default ReportValidationMessages;
