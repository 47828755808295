import React, { Component } from 'react';

import IconAdd from './icons/add';
import IconChevronRight from './icons/chevron-right';

class MultiSelectDropDown extends Component {
    render() {
        return (
            <div className="multi-select-dropdown">
                <div className="add-icon">
                    <div className="icon icon-add">
                        <IconAdd />
                    </div>
                </div>

                <ul className="level-one">
                    {this.props.dropdownItems.map(item => {
                        return (
                            <li key={item.type}>
                                <div className="colour-tile-level-one"></div>
                                {item.name}
                                <span className="chevron-container">
                                    <div className="icon icon-chevron-right">
                                        <IconChevronRight />
                                    </div>
                                </span>
                                <ul className="level-two">
                                    {item.channels.map(channel => {
                                        if (channel.items) {
                                            if (!channel.channel && channel.items) {
                                                return (
                                                    <li
                                                        data-type={channel.items[0].type}
                                                        data-key={channel.items[0].id}
                                                        key={channel.items[0].id}
                                                        onClick={this.props.onClick}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: `#${channel.items[0].colour.toHex()}`,
                                                            }}
                                                            className="colour-tile"
                                                        ></div>
                                                        {channel.items[0].name}
                                                    </li>
                                                );
                                            }
                                            return (
                                                <li key={channel.channel}>
                                                    <div
                                                        style={{
                                                            backgroundColor: `#${channel.items[0].colour.toHex()}`,
                                                        }}
                                                        className="colour-tile"
                                                    ></div>
                                                    {channel.channel}
                                                    <span className="chevron-container">
                                                        <div className="icon icon-chevron-right">
                                                            <IconChevronRight />
                                                        </div>
                                                    </span>

                                                    <ul className="level-three">
                                                        {channel.items.map(item => {
                                                            return (
                                                                <li
                                                                    data-type={item.type}
                                                                    data-key={item.id}
                                                                    key={item.id}
                                                                    onClick={this.props.onClick}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            backgroundColor: `#${item.colour.toHex()}`,
                                                                        }}
                                                                        className="colour-tile"
                                                                    ></div>
                                                                    {item.name}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </li>
                                            );
                                        } else {
                                            return (
                                                <li
                                                    data-type={channel.type}
                                                    data-key={channel.id}
                                                    key={channel.id}
                                                    onClick={this.props.onClick}
                                                >
                                                    <div
                                                        style={{ backgroundColor: `#${channel.colour.toHex()}` }}
                                                        className="colour-tile"
                                                    ></div>
                                                    {channel.name}
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default MultiSelectDropDown;
