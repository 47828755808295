import React, { memo } from 'react';

// Core Dependencies
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Types
import { SimpleBreadcrumbItemProps } from '../types';

// Styles
const StyledSimpleBreadcrumbItem = styled.div`
    position: relative;
    box-sizing: border-box;
    float: right;
    margin-left: -20px;
`;

const StyledSimpleBreadcrumbItemLabel = styled.div`
    height: 11px;
    line-height: 11px;
    position: relative;
    padding-left: 24px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 4px;
    text-transform: uppercase;
    color: #0a0a0a;
`;

const StyledSimpleBreadcrumbsItemContentWrapper = styled.div`
    border-radius: 0 37px 37px 0;
    background-color: ${props => props.theme.colours.white};
    padding: 6px;
    display: block;
    width: inherit;
    height: 100%;
`;

const StyledSimpleBreadcrumbsItemContent = styled.div<{ backgroundColor: string; fontColor: string }>`
    border-top: 1px solid ${props => props.theme.colours.white};
    border-bottom: 1px solid #d2d2d2;
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : props.theme.colours.lightGrey)};
    color: ${props => props.fontColor};

    border: none;
    line-height: 37px;
    height: 37px;
    min-width: 54px;
    padding-left: 24px;
    position: relative;
    border-radius: 0 37px 37px 0;
    overflow: hidden;
`;

const StyledSimpleBreadcrumbsItemSpan = styled.span`
    padding-right: 24px;
`;

const SimpleBreadcrumbItem = ({ id, copy, styleOverride, onClick }: SimpleBreadcrumbItemProps) => {
    return (
        <StyledSimpleBreadcrumbItem key={id}>
            <StyledSimpleBreadcrumbItemLabel />
            <StyledSimpleBreadcrumbsItemContentWrapper>
                <StyledSimpleBreadcrumbsItemContent
                    onClick={onClick}
                    backgroundColor={styleOverride.backgroundColor}
                    fontColor={styleOverride.color}
                >
                    <StyledSimpleBreadcrumbsItemSpan>{copy}</StyledSimpleBreadcrumbsItemSpan>
                </StyledSimpleBreadcrumbsItemContent>
            </StyledSimpleBreadcrumbsItemContentWrapper>
        </StyledSimpleBreadcrumbItem>
    );
};

export default memo(SimpleBreadcrumbItem, isEqual);
