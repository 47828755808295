// validates the table in the config file
export const validateConfigTable = config => {
    const messages = [];

    // check for a table in config
    if (!config.table) {
        return ['The configuration file does not contain a table'];
    }

    // check for a tabListItems
    if (!config.table.tabListItems) {
        messages.push('In the configuration file the table does not contain a tabListItems');
    }

    // check for a fieldChildren
    if (!config.table.fieldChildren) {
        messages.push('In the configuration file the table does not contain a fieldChildren');
    }

    return messages;
};
