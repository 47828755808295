import React from 'react';

// Validation
import validators from '../../../../components/forms/validation/validators';
import {
    hasCapitalLetter,
    hasLowerCaseLetter,
    hasNumber,
    hasPasswordValidCharacters,
    hasSpecialCharacter,
} from '../../../../helpers/validator';

// Hooks
import { useFormContext } from 'react-hook-form';

// Types & Enums
import { ButtonThemes } from '../../../../enums/button-themes';

// Components
import Form from '../../../../components/forms/form';
import PasswordRequirements from '../../../../components/password-requirements';
import { DropdownOption } from '../../../../section-dashboard/types';
import { UseQueryResult } from '@tanstack/react-query';

const FormCreateUser = ({ groupQuery }: { groupQuery: UseQueryResult<DropdownOption[], Error> }) => {
    const { watch } = useFormContext();
    const formValues = watch();

    return (
        <>
            <Form.Body>
                <Form.Section>
                    <Form.Field>
                        <Form.InputLabel htmlFor="email" label="Email" />
                        <Form.InputEmail name="email" validators={[validators.required, validators.isEmail]} />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputLabel htmlFor="firstName" label="First Name" />
                        <Form.InputText name="firstName" validators={[validators.required]} />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputLabel htmlFor="lastName" label="Last Name" />
                        <Form.InputText name="lastName" validators={[validators.required]} />
                    </Form.Field>
                </Form.Section>

                <Form.Section>
                    <Form.Field>
                        <Form.InputLabel htmlFor="role" label="Role" />
                        <Form.InputSelect
                            name="role"
                            options={groupQuery.data || []}
                            validators={[validators.required]}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputLabel htmlFor="password" label="Password" />
                        <Form.InputPassword name="password" validators={[validators.required, validators.isPassword]} />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputLabel htmlFor="repeatPassword" label="Repeat Password" />
                        <Form.InputPassword
                            name="repeatPassword"
                            validators={[validators.required, validators.passwordsMatch]}
                        />
                    </Form.Field>

                    <PasswordRequirements
                        hasCharacterLength={formValues.password.length >= 8}
                        hasCapitalLetter={hasCapitalLetter(formValues.password)}
                        hasLowercaseLetter={hasLowerCaseLetter(formValues.password)}
                        hasNumber={hasNumber(formValues.password)}
                        hasSpecialCharacter={hasSpecialCharacter(formValues.password)}
                        hasValidCharacter={hasPasswordValidCharacters(formValues.password)}
                    />
                </Form.Section>
            </Form.Body>
            <Form.Footer>
                <Form.InputButton type="submit" value="Create User" />
                <Form.InputButton type="reset" buttonTheme={ButtonThemes.Secondary} value="Clear" />
            </Form.Footer>
        </>
    );
};

export default FormCreateUser;
