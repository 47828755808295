import { CubedField } from '../../../types';
import { unknownErrorData } from '../../helpers/errors';
import { graphColours } from '../../helpers/graph-colours';
import { getRawValueAsNumber, getValue } from '../../helpers/resource-data';
import { BarGraphBar, BarGraphComparisonData, ResourceComparisonData } from '../../types';

type UseResourceBarGraphComparisonArgs = {
    category?: CubedField;
    metric?: CubedField;
    resourceData: ResourceComparisonData;
};

const useResourceBarGraphComparison = ({
    category,
    metric,
    resourceData,
}: UseResourceBarGraphComparisonArgs): BarGraphComparisonData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success' && category && metric) {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        let bars: BarGraphBar[] = [];
        let comparisonBars: BarGraphBar[] = [];

        if (resource.status === 'success') {
            bars = resource.objects.map((data, index) => {
                return {
                    __id: data.__id,
                    name: getValue(data, category.rawName),
                    value: getRawValueAsNumber(data, metric.rawName),
                    colour: graphColours[index % graphColours.length].solidGraphColour,
                };
            });
        }

        if (comparisonResource.status === 'success') {
            comparisonBars = comparisonResource.objects.map((data, index) => {
                return {
                    __id: data.__id,
                    name: getValue(data, category.rawName),
                    value: getRawValueAsNumber(data, metric.rawName),
                    colour: graphColours[index % graphColours.length].solidGraphColour,
                };
            });
        }

        return {
            type: 'barGraph',
            status: 'success',
            metric: metric.displayName,
            bars: bars,
            barsComparison: comparisonBars,
        };
    }

    return unknownErrorData();
};

export default useResourceBarGraphComparison;
