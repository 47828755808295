import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePostResourceWithPayload from '../../../react-query/hooks/use-post-resource-with-payload';
import usePatchMultiResourceWithPayload from '../../../react-query/hooks/use-patch-multi-resource-with-payload';
import useDeleteResource from '../../../react-query/hooks/use-delete-resource';

// Config
import { CONFIG_ACCOUNT_USER, CONFIG_INVITE_EXISTING } from '../../../configurations/resources-config';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { removeModal, setModal } from '../../../redux/actions/modal';
import { removePopup, setPopup } from '../../../redux/actions/popup';
import { addNotification } from '../../../redux/actions/notification';

// Types & Enums
import { ConfigDataSuccess } from '../../../react-query/types';
import { ButtonThemes } from '../../../enums/button-themes';
import { FieldValues } from 'react-hook-form';

// Helpers
import { generatePath, generateUrlDetail } from '../../../helpers/request-builder';

// Components
import ConfigTable from '../../../components/tables/config-table/config-table';
import FormEditUser from './forms/form-edit-user';
import Form from '../../../components/forms/form';
import ModalNavigation from '../../../components/modal-navigation';
import FormInviteExistingUser from './forms/form-invite-existing-user';

const StyledEditContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 10px 20px;
    margin-top: 20px;
`;

type User = {
    accountUserId: number;
    userId: number;
    email: string;
    role: string;
    selected: boolean;
};

const LayoutModalManageUsers = () => {
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);

    const [users, setUsers] = useState<User[]>([]);
    const [userToDelete, setUserToDelete] = useState<string>();
    const [userToEdit, setUserToEdit] = useState<User>();
    const [showInviteExistingUser, setShowInviteExistingUser] = useState(false);

    const roles = [
        {
            value: '1',
            label: 'Admin',
        },
        {
            value: '5',
            label: 'API',
        },
        {
            value: '3',
            label: 'Viewer',
        },
    ];

    // Queries
    const usersQuery = useFetchResource({
        resource: CONFIG_ACCOUNT_USER,
        params: [
            {
                key: 'active',
                value: 1,
            },
            {
                key: 'account__id',
                value: account.id,
            },
            {
                key: 'user__is_active',
                value: 1,
            },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(user => {
                return {
                    accountUserId: user.id,
                    userId: user.user.id,
                    email: user.user.email,
                    role: user.group.name,
                    selected: false,
                };
            });
        },
    });

    useEffect(() => {
        if (usersQuery.data) {
            setUsers(usersQuery.data);
        }
    }, [usersQuery.data]);

    // Mutations
    const singleUserDeleteMutation = useDeleteResource({
        resource: CONFIG_ACCOUNT_USER,
        resourceIds: [userToDelete || ''],
        handleOnSuccess: () => {
            setUserToDelete(undefined);
            dispatch(addNotification({ copy: 'Access for this user has been successfully revoked.', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was a problem while revoking access. Please try again later.',
                    type: 'success',
                })
            );
        },
    });

    const bulkUserDeleteMutation = useDeleteResource({
        resource: CONFIG_ACCOUNT_USER,
        resourceIds: users.filter(user => user.selected).map(user => String(user.accountUserId)),
        handleOnSuccess: () => {
            setUsers(users.map(user => ({ ...user, selected: false })));
            dispatch(
                addNotification({ copy: 'Access for these users has been successfully revoked.', type: 'success' })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was a problem while revoking access. Please try again later.',
                    type: 'success',
                })
            );
        },
    });

    const userPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_ACCOUNT_USER,
        handleOnSuccess: () => {
            setUserToEdit(undefined);
            dispatch(addNotification({ copy: 'User has been successfully updated.', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(addNotification({ copy: 'There was a problem while updating the user. Please try again later.' }));
        },
    });

    const invitePostMutation = usePostResourceWithPayload({
        resource: CONFIG_INVITE_EXISTING,
        url: generateUrlDetail(account.token, 'users', 'invite-existing', [], false) || '',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        handleOnSuccess: () => {
            setShowInviteExistingUser(false);
            dispatch(addNotification({ copy: 'Invite successfully sent', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(addNotification({ copy: 'There was a problem while inviting the user. Please try again later.' }));
        },
    });

    // Handlers
    const handleCheckboxChange = (rowId: number) => {
        setUsers(
            users.map(user => {
                if (user.userId === rowId) {
                    return { ...user, selected: !user.selected };
                }
                return user;
            })
        );
    };

    // Single User Delete
    const handleSingleDelete = () => {
        setUsers(users.map(user => ({ ...user, selected: false })));
        dispatch(removePopup());
        singleUserDeleteMutation.mutate();
    };

    const handleSingleDeleteClick = (rowId: number) => {
        const user = users.find(user => user.userId === rowId);

        setUserToDelete(String(user?.accountUserId));

        dispatch(
            setPopup({
                title: 'Revoke Access',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to revoke access for ${user?.email || 'this user'}?`,
                },
                buttons: [
                    {
                        onClick: handleSingleDelete,
                        value: 'YES, REVOKE',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    // Bulk User Delete
    const handleBulkDelete = () => {
        dispatch(removePopup());
        bulkUserDeleteMutation.mutate();
    };

    const handleBulkDeleteClick = () => {
        dispatch(
            setPopup({
                title: 'Revoke Access',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to revoke access for the selected users?',
                },
                buttons: [
                    {
                        onClick: handleBulkDelete,
                        value: 'YES, REVOKE',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    // Edit User
    const handleEditUser = (data: FieldValues) => {
        userPatchMutation.mutate({
            payload: [
                {
                    resource_uri: generatePath('config', 'account-user', String(userToEdit?.accountUserId)),
                    group: generatePath('config', 'groups', data.role),
                },
            ],
            resourceId: [String(userToEdit?.accountUserId)],
        });
    };

    // Invite Existing User
    const handleInviteExistingUser = (data: FieldValues) => {
        invitePostMutation.mutate({
            username: data.email,
            group: data.role,
        });
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(removeModal()),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />

            <h2>Manage Users</h2>
            <p>Create, invite or manage existing users that have access to your account.</p>

            <ConfigTable
                status={usersQuery.status}
                isFetching={
                    usersQuery.isFetching || singleUserDeleteMutation.isPending || bulkUserDeleteMutation.isPending
                }
                disabled={false}
                empty={usersQuery?.data?.length === 0}
            >
                <ConfigTable.Table>
                    <ConfigTable.Header>
                        <ConfigTable.Row key="users-header">
                            <ConfigTable.CellHeader />
                            <ConfigTable.CellHeader>User</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Role</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader />
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {users.map(user => {
                            return (
                                <ConfigTable.Row key={user.userId}>
                                    <ConfigTable.CellCheckbox
                                        rowId={user.userId}
                                        checked={user.selected}
                                        onCheckedChange={handleCheckboxChange}
                                    />
                                    <ConfigTable.Cell>{user.email}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{user.role}</ConfigTable.Cell>
                                    <ConfigTable.CellActions>
                                        <ConfigTable.ActionDropdownItem
                                            rowId={user.userId}
                                            type="edit"
                                            onClick={() => setUserToEdit(user)}
                                        />
                                        <ConfigTable.ActionDropdownItem
                                            rowId={user.userId}
                                            type="revoke"
                                            onClick={handleSingleDeleteClick}
                                        />
                                    </ConfigTable.CellActions>
                                </ConfigTable.Row>
                            );
                        })}
                    </ConfigTable.Body>
                </ConfigTable.Table>
                <ConfigTable.ActionBar>
                    <ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionButton
                            type="revoke"
                            label="Revoke Access"
                            onClick={handleBulkDeleteClick}
                            isDisabled={users.filter(user => user.selected).length === 0}
                        />
                    </ConfigTable.ActionBarJustifyLeft>
                    <ConfigTable.ActionBarJustifyRight>
                        <ConfigTable.ActionButton
                            type="primary"
                            label="Invite New User"
                            onClick={() => dispatch(setModal('InviteUser', {}))}
                        />
                        <ConfigTable.ActionButton
                            type="primary"
                            label="Invite Existing User"
                            onClick={() => setShowInviteExistingUser(true)}
                        />
                        <ConfigTable.ActionButton
                            type="add"
                            label="Create New User"
                            onClick={() => dispatch(setModal('CreateUser', {}))}
                        />
                    </ConfigTable.ActionBarJustifyRight>
                </ConfigTable.ActionBar>
            </ConfigTable>

            {userToEdit && (
                <StyledEditContainer>
                    <h2>Edit User - {userToEdit.email}</h2>

                    <p>
                        More details about each role can be found on our{' '}
                        <a href="http://tag.docs.withcubed.com/onboarding/general/" target="_blank" rel="noreferrer">
                            support page
                        </a>
                        .
                    </p>

                    <Form
                        onSubmit={handleEditUser}
                        defaultValues={{ role: roles.find(role => role.label === userToEdit.role)?.value }}
                    >
                        <FormEditUser
                            roles={roles}
                            isPending={userPatchMutation.isPending || usersQuery.isFetching}
                            handleCancelClick={() => setUserToEdit(undefined)}
                        />
                    </Form>
                </StyledEditContainer>
            )}

            {showInviteExistingUser && (
                <StyledEditContainer>
                    <h2>Invite Existing User</h2>

                    <p>Invite users from another account to join your account.</p>

                    <Form onSubmit={handleInviteExistingUser}>
                        <FormInviteExistingUser
                            roles={roles}
                            isPending={invitePostMutation.isPending}
                            handleInviteCancelClick={() => setShowInviteExistingUser(false)}
                        />
                    </Form>
                </StyledEditContainer>
            )}
        </>
    );
};

export default LayoutModalManageUsers;
