import React from 'react';
import { DateRange } from 'react-date-range';
import classnames from 'classnames';
import CubedCalendar from './cubed-calendar';

class CubedDateRange extends DateRange {
    render() {
        return (
            <CubedCalendar
                focusedRange={this.state.focusedRange}
                onRangeFocusChange={this.handleRangeFocusChange}
                preview={this.state.preview}
                onPreviewChange={value => {
                    this.updatePreview(value ? this.calcNewSelection(value) : null);
                }}
                {...this.props}
                displayMode="dateRange"
                className={classnames(this.styles.dateRangeWrapper, this.props.className)}
                onChange={this.setSelection}
                updateRange={val => this.setSelection(val, false)}
                ref={target => {
                    this.calendar = target;
                }}
            />
        );
    }
}

export default CubedDateRange;
