import React, { Component } from 'react';

import InputSearch from '../../../inputs/input-search';
import InputSelect from '../../../inputs/input-select';
import InputButton from '../../../inputs/input-button';

import '../../../../sass/components/search-options.scss';

class TableSearchOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            onSearchHandler: props.onSearchHandler || null,
            searchCategory: props.searchCategory,
            searchTerm: props.searchTerm,
        };

        this.renderSearchCategories = this.renderSearchCategories.bind(this);
        this.renderSearchBar = this.renderSearchBar.bind(this);
        this.renderSubmitButton = this.renderSubmitButton.bind(this);
        this.renderReturn = this.renderReturn.bind(this);

        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSubmitClick = this.onSubmitClick.bind(this);
    }

    onSubmitClick(event) {
        if (this.props.onSearchHandler !== undefined) {
            this.props.onSearchHandler(this.state.searchCategory, this.state.searchTerm);
        }
    }

    onSearchChange(event) {
        this.setState({
            searchTerm: event.target.value,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchTerm !== this.props.searchTerm) {
            this.setState({ searchTerm: this.props.searchTerm });
        }
        if (prevProps.searchCategory !== this.props.searchCategory) {
            this.setState({ searchCategory: this.props.searchCategory });
        }
    }

    renderSearchCategories() {
        let searchCategories = [];

        if (this.props.tabListItems[this.props.tabKey]) {
            searchCategories = this.props.tabListItems[this.props.tabKey].metrics.map(metric => {
                return {
                    keyValue: metric.rawName,
                    name: metric.displayName,
                    value: metric.rawName,
                };
            });
        }
        return (
            <div className="search-options terms">
                <InputSelect
                    options={searchCategories}
                    onChange={event => this.setState({ searchCategory: event.target.value })}
                />
            </div>
        );
    }

    renderSearchBar() {
        return (
            <div className="search-options bar">
                <InputSearch value={this.state.searchTerm} onChange={this.onSearchChange} />
            </div>
        );
    }

    renderSubmitButton() {
        return (
            <div className="search-options submit">
                <InputButton value="Submit" disabled={this.state.tableLoading} onClick={this.onSubmitClick} />
            </div>
        );
    }

    renderReturn() {
        if (this.props.enableSearch !== true) {
            return null;
        }

        return (
            <div className="search__holder">
                <this.renderSearchCategories />
                <this.renderSearchBar />
                <this.renderSubmitButton />
            </div>
        );
    }

    render() {
        return <this.renderReturn />;
    }
}

export default TableSearchOptions;
