// React Dependencies
import React, { useEffect, useState } from 'react';

// Libraries
import styled from 'styled-components';

// Components
import CreateSegmentCondition from './create-segment-condition';
import CreateSegmentAddCondition from './create-segment-add-condition';
import CreateSegmentGroupHeader from './create-segment-group-header';
import CreateSegmentFooter from './create-segment-footer';

// Styled Components
const StyledRuleGroupContainer = styled.div`
    width: 100%;
    display: flex;
`;

const StyledRuleGroup = styled.div`
    width: 100%;
    border-left: ${props => (props.nested ? '1px solid #d3d3d3' : 'none')};
`;

const StyledMargin = styled.div`
    width: 80px;
    display: block;
    text-align: center;
    & p {
        font-size: 0.8rem;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        top: -10px;
    }
`;

const StyledGroupBody = styled.div`
    width: 100%;
`;

const CreateSegmentGroup = ({
    isFrom,
    index,
    rules,
    groupItem,
    operators,
    parentOperator,
    parentConditionsCount,
    conditions,
    nested,
    onAddCondition,
    onRemoveCondition,
    onAddGroup,
    onUpdateOperator,
    onRemoveGroup,
}) => {
    // display state
    const [showAddCondition, setShowAddCondition] = useState(false);
    const [disableAddConditionButton, setDisableAddConditionButton] = useState(false);

    // selected items state
    const [availableConditions, setAvailableConditions] = useState();
    const [selectedCondition, setSelectedCondition] = useState();
    const [selectedConditions, setSelectedConditions] = useState([]);

    useEffect(() => {
        if (isFrom !== 'Edit') return;

        setSelectedConditions(groupItem.selectedConditions);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Remove already selected conditions from conditions dropdown to avoid duplicates
    useEffect(() => {
        const filteredConditions = conditions.filter(
            ({ id: id1 }) => !selectedConditions.some(({ id: id2 }) => id2 === id1)
        );

        setAvailableConditions(filteredConditions.filter(condition => condition.active));
        setDisableAddConditionButton(filteredConditions.length > 0 ? false : true);
    }, [conditions, selectedConditions]);

    const handleShowAddCondition = () => {
        setShowAddCondition(true);
        setDisableAddConditionButton(true);
    };

    const handleHideAddCondition = () => {
        setShowAddCondition(false);
        setDisableAddConditionButton(false);
    };

    const handleSelectCondition = selectedOption => {
        setSelectedCondition(selectedOption);
        setSelectedConditions([...selectedConditions, selectedOption]);
        onAddCondition(groupItem, selectedOption);
        setShowAddCondition(false);
        setDisableAddConditionButton(false);
        setSelectedCondition(null);
    };

    const handleRemoveCondition = condition => {
        setSelectedCondition(selectedConditions.filter(selectedCondition => selectedCondition.id !== condition.id));
        setSelectedConditions(selectedConditions.filter(selectedCondition => selectedCondition.id !== condition.id));
        setAvailableConditions([...availableConditions, condition].filter(condition => condition.active));
        onRemoveCondition(groupItem, condition);
        setDisableAddConditionButton(false);
    };

    const handleAddGroup = () => {
        onAddGroup(groupItem);
    };

    const handleCloseGroup = () => {
        onRemoveGroup(groupItem);
    };

    const handleSelectOperator = selectedOperator => {
        onUpdateOperator(groupItem, selectedOperator);
    };

    const nestedItems = groupItem.groupItems.map((nestedItem, index) => {
        return (
            <CreateSegmentGroup
                isFrom={isFrom}
                index={index}
                key={nestedItem.id}
                rules={rules}
                groupItem={nestedItem}
                operators={operators}
                parentOperator={groupItem.operator}
                parentConditionsCount={groupItem.selectedConditions.length}
                conditions={conditions}
                nested={true}
                onAddCondition={onAddCondition}
                onRemoveCondition={onRemoveCondition}
                onAddGroup={onAddGroup}
                onUpdateOperator={onUpdateOperator}
                onRemoveGroup={onRemoveGroup}
            />
        );
    });

    return (
        <StyledRuleGroupContainer>
            {nested && (
                <StyledMargin>
                    <p>{index > 0 || parentConditionsCount > 0 ? parentOperator.value : null}</p>
                </StyledMargin>
            )}
            <StyledRuleGroup nested={nested}>
                <CreateSegmentGroupHeader
                    nested={nested}
                    operators={operators}
                    selectedOperator={groupItem.operator}
                    onSelectOperator={handleSelectOperator}
                    onCloseGroup={handleCloseGroup}
                />

                <StyledGroupBody>
                    {groupItem.selectedConditions.map((condition, index) => (
                        <CreateSegmentCondition
                            index={index}
                            active={condition.active}
                            key={condition.id}
                            condition={condition}
                            operator={groupItem.operator}
                            onRemoveCondition={handleRemoveCondition}
                        />
                    ))}

                    {showAddCondition && (
                        <CreateSegmentAddCondition
                            conditions={availableConditions}
                            onSelectCondition={handleSelectCondition}
                            selectedCondition={selectedCondition}
                            onCloseAddCondition={handleHideAddCondition}
                        />
                    )}

                    {nestedItems.length > 0 && nestedItems}

                    <CreateSegmentFooter
                        nested={nested}
                        disableAddConditionButton={disableAddConditionButton}
                        onShowAddCondition={handleShowAddCondition}
                        // disableAddGroupButton={groupItem.level > 1}
                        onAddGroup={handleAddGroup}
                    />
                </StyledGroupBody>
            </StyledRuleGroup>
        </StyledRuleGroupContainer>
    );
};

export default CreateSegmentGroup;
