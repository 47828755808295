import React from 'react';
import styled from 'styled-components';

const StyledButtonGroupButton = styled.div`
    line-height: 35px;
    color: ${props => (props.active ? props.theme.colours.white : props.theme.colours.offBlackLighter)};
    padding: 0px 20px;
    cursor: ${props => (props.active ? 'auto' : 'pointer')};
    border-right: 2px solid ${props => props.theme.colours.offBlackLighter};
    background-color: ${props => (props.active ? props.theme.colours.offBlackLighter : props.theme.colours.white)};
    font-weight: ${props => (props.active ? 400 : 400)};
    font-size: ${props => (props.smallText ? '0.8rem' : '1rem')};
    display: flex;
    align-items: center;

    &:last-child {
        border-right: none;
    }
`;

const ButtonGroupButton = ({ active, onButtonClick, smallText, children }) => {
    if (active) {
        return (
            <StyledButtonGroupButton active smallText={smallText}>
                {children}
            </StyledButtonGroupButton>
        );
    } else {
        return (
            <StyledButtonGroupButton onClick={onButtonClick} smallText={smallText}>
                {children}
            </StyledButtonGroupButton>
        );
    }
};

export default ButtonGroupButton;
