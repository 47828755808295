import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

// Hooks, Types & Helpers
import { useSectionDashboard } from '../../context/section-dashboard-context';
import { StackedAreaChartComparisonData } from '../../types';
import {
    buildAreaSeries,
    buildOptionalSeries,
    buildYAxisConfig,
    handleUpdateVisible,
} from '../../helpers/widget-stacked-area-helpers';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Components & Config
import { stackedAreaChartConfig } from '../../configurations/widget-stacked-area-chart-config';
import WidgetBase from '../base/widget-base';
import Message from '../../../components/message';

const StyledContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`;

const StyledSmallContainer = styled.div<{ width?: number; height?: number }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => (props.width && props.height ? (props.height > props.width ? 'column' : 'row') : 'row')};
`;

const StyledChartContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 50%;
`;

const StyledTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    background-color: ${props => props.theme.sectionDashboard.widget.stackedArea.title.backgroundColor};
`;

const StyledTitle = styled.h1`
    transform: rotate(-90deg);
    margin: 0;
    font-size: 0.9rem;
    color: ${props => props.theme.sectionDashboard.widget.stackedArea.title.textColor};
    font-weight: ${props => props.theme.sectionDashboard.widget.stackedArea.title.fontWeight};
    text-transform: uppercase;
    z-index: 10;
    white-space: nowrap;
`;

const StyledMessageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export type WidgetStackedAreaChartComparisonProps = {
    title: string;
    data: StackedAreaChartComparisonData;
    href?: string;
    menu?: React.ReactElement;
};

const WidgetStackedAreaChartComparison = ({ title, data, href, menu }: WidgetStackedAreaChartComparisonProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();
    const [chartData, setChartData] = useState(data);

    useEffect(() => {
        setChartData(data);
    }, [data]);

    const renderMenu = () => {
        if (menu) {
            const widgetMenu = React.cloneElement(menu, {
                data: chartData,
                setData: setChartData,
            });
            return widgetMenu;
        }
    };

    if (chartData.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={renderMenu()} />;
    }

    if (chartData.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (chartData.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    // Build the series' for the main stacked area
    let areaSeries = buildAreaSeries(chartData.areaSeries, theme);
    let areaComparisonSeries = buildAreaSeries(chartData.areaComparisonSeries, theme);

    // Build the optional line series'
    let optionalSeries = chartData.optionalSeries ? buildOptionalSeries(chartData.optionalSeries) : [];
    let optionalComparisonSeries = chartData.optionalComparisonSeries
        ? buildOptionalSeries(chartData.optionalComparisonSeries)
        : [];

    const yAxisAreaConfig = {
        title: {
            text: chartData.areaTitle,
            style: {
                color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                fontSize: '0.7em',
            },
        },
        labels: {
            style: {
                color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                fontSize: '0.7em',
            },
        },
        gridLineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#e6e6e6',
    };

    // Build the optional Y Axis config
    const yAxisOptionalConfig = buildYAxisConfig(optionalSeries, theme);
    const yAxisOptionalComparisonConfig = buildYAxisConfig(optionalComparisonSeries, theme);

    const yAxisConfig = [yAxisAreaConfig, yAxisOptionalConfig];
    const yAxisComparisonConfig = [yAxisAreaConfig, yAxisOptionalComparisonConfig];

    const handleUpdateArea = (seriesName: string) => {
        setChartData({
            ...chartData,
            areaSeries: handleUpdateVisible(chartData.areaSeries, seriesName),
            areaComparisonSeries: handleUpdateVisible(chartData.areaComparisonSeries, seriesName),
            optionalSeries: chartData.optionalSeries && handleUpdateVisible(chartData.optionalSeries, seriesName),
            optionalComparisonSeries:
                chartData.optionalComparisonSeries &&
                handleUpdateVisible(chartData.optionalComparisonSeries, seriesName),
        });
    };

    const chartConfig = stackedAreaChartConfig(theme);

    const smallChartOptions = {
        xAxis: {
            ...chartConfig.xAxis,
            maxPadding: 0,
            minPadding: 0,
        },
        yAxis: {
            title: {
                text: null,
            },
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
        },
        legend: {
            enabled: false,
        },
    };

    const chartOptions = {
        ...chartConfig,
        plotOptions: {
            ...chartConfig.plotOptions,
            series: {
                ...chartConfig.plotOptions.series,
                events: {
                    legendItemClick: (e: React.SyntheticEvent<HTMLInputElement>) => {
                        const { name } = e.target as HTMLInputElement;
                        handleUpdateArea(name);
                    },
                },
            },
        },
    };

    const renderSmallMessage = () => {
        return (
            <StyledMessageContainer>
                <Message
                    title="No Data"
                    shortTitle="No Data"
                    copy="Try adjusting your filters."
                    type="info"
                    size="xsmall"
                    display="vertical"
                />
            </StyledMessageContainer>
        );
    };

    const renderLargeMessage = () => {
        return (
            <StyledMessageContainer>
                <Message title="No Data" copy="Try adjusting your filters." type="info" size="small" />
            </StyledMessageContainer>
        );
    };

    if (width === 1 && height === 1) {
        return (
            <WidgetBase title={title} href={href} menu={renderMenu()}>
                <StyledSmallContainer>
                    {areaSeries.length === 0 ? (
                        renderSmallMessage()
                    ) : (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{
                                ...chartOptions,
                                ...smallChartOptions,
                                title: {
                                    ...chartConfig.title,
                                    text: 'This Period',
                                },
                                series: [...areaSeries],
                            }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    )}
                </StyledSmallContainer>
            </WidgetBase>
        );
    }

    if (width < 3 || height < 3) {
        return (
            <WidgetBase title={title} href={href} menu={renderMenu()}>
                <StyledSmallContainer width={width} height={height}>
                    {areaSeries.length === 0 ? (
                        renderSmallMessage()
                    ) : (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{
                                ...chartOptions,
                                ...smallChartOptions,
                                title: {
                                    ...chartConfig.title,
                                    text: 'This Period',
                                },
                                series: [...areaSeries],
                            }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    )}

                    {areaComparisonSeries.length === 0 ? (
                        renderSmallMessage()
                    ) : (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{
                                ...chartOptions,
                                ...smallChartOptions,
                                title: {
                                    ...chartConfig.title,
                                    text: 'Comparison Period',
                                },
                                series: [...areaComparisonSeries],
                            }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    )}
                </StyledSmallContainer>
            </WidgetBase>
        );
    }

    return (
        <WidgetBase title={title} href={href} menu={renderMenu()}>
            <StyledContainer>
                <StyledChartContainer>
                    <StyledTitleContainer>
                        <StyledTitle>This Period</StyledTitle>
                    </StyledTitleContainer>
                    {areaSeries.length === 0 ? (
                        renderLargeMessage()
                    ) : (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{
                                ...chartOptions,
                                series: [...areaSeries, ...optionalSeries],
                                yAxis: yAxisConfig,
                                legend: {
                                    enabled: false,
                                },
                            }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    )}
                </StyledChartContainer>
                <StyledChartContainer>
                    <StyledTitleContainer>
                        <StyledTitle>Comparison Period</StyledTitle>
                    </StyledTitleContainer>
                    {areaComparisonSeries.length === 0 ? (
                        renderLargeMessage()
                    ) : (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{
                                ...chartOptions,
                                series: [...areaComparisonSeries, ...optionalComparisonSeries],
                                yAxis: yAxisComparisonConfig,
                                legend: {
                                    itemStyle: {
                                        fontSize: height > 2 && width > 2 ? '12px' : '10px',
                                        color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.8)' : '#6f6f6f',
                                        fontWeight: theme.name === 'dark' && 400,
                                    },
                                },
                            }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    )}
                </StyledChartContainer>
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetStackedAreaChartComparison;
