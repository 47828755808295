import { UseQueryResult } from '@tanstack/react-query';
import { DropdownOption, RequestStatus } from '../../section-dashboard/types';

const buildDropdownResource = (dropdownOptions: UseQueryResult<DropdownOption[]>) => {
    switch (dropdownOptions.status) {
        case 'pending':
            return {
                status: RequestStatus.LOADING,
                options: [],
            };
        case 'success':
            if (dropdownOptions.data.length > 0) {
                return {
                    status: RequestStatus.SUCCESS,
                    options: dropdownOptions.data,
                };
            } else {
                return {
                    status: RequestStatus.EMPTY,
                    options: [],
                };
            }
        default:
            return {
                status: RequestStatus.ERROR,
                options: [],
            };
    }
};

export default buildDropdownResource;
