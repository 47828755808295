/* eslint-disable array-callback-return */
import React from 'react';
import styled, { useTheme } from 'styled-components';

// Configuration
import { funnelChartConfig } from '../../configurations/widget-funnel-chart-config';

// Highcharts
import Highcharts from 'highcharts';
import FunnelModule from 'highcharts/modules/funnel';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Widgets
import WidgetBase from '../base/widget-base';
import WidgetFunnelChartBigNumber from '../big-numbers/widget-funnel-chart-big-number';

// Component
import Tooltip from '../../../components/tooltip';
import Message, { IconDirection, IconSize } from '../../../components/message';

// Types & Helpers
import { FunnelChartData } from '../../types';
import { formatVariance } from '../../helpers/variance-helpers';

const StyledContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
`;

const StyledFunnelLedgendWrapper = styled.div<{ width: number }>`
    width: ${props => props.width > 2 && `100%`};
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledFunnelLedgend = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const StyledLedgendName = styled.h3<{ height: number; width: number; color: string }>`
    margin: 0;
    font-size: ${props => (props.width === 2 ? `0.8rem` : `0.9rem`)};
    text-align: center;
    font-weight: 500;
    color: ${props => props.theme.sectionDashboard.widget.funnelChart.legendColor};
    display: block;
    border: 2px solid ${props => props.color};
    background-color: ${props => props.color};
    border-radius: 10px;
    padding: 5px 10px;
    ${props => props.theme.boxShadow(1)}
`;

const StyledLedgendPercentage = styled.h1<{ height: number; width: number; variance?: number | null }>`
    margin: 0;
    font-size: ${props => (props.width === 2 ? `1rem` : `1.1rem`)};
    color: ${props =>
        props.variance
            ? props.variance > 0
                ? props.theme.sectionDashboard.widget.variance.textColorPositive
                : props.variance < 0
                ? props.theme.sectionDashboard.widget.variance.textColorNegative
                : props.theme.sectionDashboard.widget.variance.textColor
            : props.theme.sectionDashboard.widget.variance.textColor};
    display: block;
    border-radius: 10px;
    padding: 5px 10px;
    ${props => (props.width > 2 ? props.theme.boxShadow(1) : props.theme.boxShadow(2))}
    background-color: ${props => props.theme.sectionDashboard.widget.funnelChart.legendPercentageBackgroundColor};
`;

const StyledMessageContainer = styled.div`
    width: 60%;
    align-self: center;
`;

export type WidgetFunnelChartComparisonProps = {
    title: string;
    data: FunnelChartData;
    href?: string;
    menu?: React.ReactElement;
};

FunnelModule(Highcharts);

const WidgetFunnelChartComparison = ({ title, data, href, menu }: WidgetFunnelChartComparisonProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const { series, comparisonSeries, varianceData, total, name } = data;

    if (width === 1 || height === 1) {
        const numberOfBoxes = Math.max(width, height);
        const dataToDisplay = series.slice(0, numberOfBoxes).map((data, index) => {
            return {
                ...data,
                i: 2 * index,
            };
        });
        const comparisonDataToDisplay = comparisonSeries?.slice(0, numberOfBoxes).map((data, index) => {
            return {
                ...data,
                name: `${data.name} (Comparison)`,
                i: 2 * index + 1,
            };
        });
        const mergedDataToDisplay = [...dataToDisplay, ...(comparisonDataToDisplay as [])].sort((a, b) => a.i - b.i);

        const firstSeriesTotal = total;
        const direction = width > height ? 'horizontal' : 'vertical';

        return (
            <WidgetBase title={title} href={href} menu={menu}>
                <WidgetFunnelChartBigNumber data={mergedDataToDisplay} direction={direction} total={firstSeriesTotal} />
            </WidgetBase>
        );
    }

    let size: IconSize = 'small';
    let display: IconDirection = 'horizontal';

    if (height < 2 || width < 3) {
        size = 'xsmall';
        display = 'vertical';
    } else if (height < 4 || width < 4) {
        size = 'small';
    }

    if (width > 1) {
        display = 'horizontal';
    }

    const chartOptions = funnelChartConfig(theme);

    const chartConfig = {
        ...chartOptions,
        series: [
            {
                name: name,
                data: series,
            },
        ],
    };

    const comparisonChartConfig = {
        ...chartOptions,
        series: [
            {
                name: name,
                data: comparisonSeries,
            },
        ],
    };

    return (
        <WidgetBase title={title} href={href} menu={menu}>
            <StyledContainer>
                {series.length > 0 ? (
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...chartConfig }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                ) : (
                    <StyledMessageContainer>
                        <Message
                            shortTitle={'No Data'}
                            title={'No Data Available'}
                            copy={'Try adjusting your filters.'}
                            type={'info'}
                            size={size}
                            display={display}
                        />
                    </StyledMessageContainer>
                )}

                {varianceData && varianceData.length > 0 ? (
                    <StyledFunnelLedgendWrapper width={width}>
                        {varianceData.map(variance => {
                            return (
                                <StyledFunnelLedgend key={variance.name}>
                                    {width > 3 ? (
                                        <StyledLedgendName color={variance.colour} width={width} height={height}>
                                            {variance.name}
                                        </StyledLedgendName>
                                    ) : null}
                                    {width > 3 ? (
                                        <StyledLedgendPercentage
                                            height={height}
                                            width={width}
                                            variance={variance.variance}
                                        >
                                            {variance.variance ? formatVariance(variance.variance, true) : 'N/A'}
                                        </StyledLedgendPercentage>
                                    ) : width > 2 ? (
                                        <div>
                                            <Tooltip copy={variance.name} usePlaceholder={false}>
                                                <StyledLedgendPercentage
                                                    height={height}
                                                    width={width}
                                                    variance={variance.variance}
                                                >
                                                    {formatVariance(variance.variance, true)}
                                                </StyledLedgendPercentage>
                                            </Tooltip>
                                        </div>
                                    ) : null}
                                </StyledFunnelLedgend>
                            );
                        })}
                    </StyledFunnelLedgendWrapper>
                ) : null}

                {comparisonSeries && comparisonSeries.length > 0 ? (
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...comparisonChartConfig }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                ) : (
                    <StyledMessageContainer>
                        <Message
                            shortTitle={'No Data'}
                            title={'No Data Available'}
                            copy={'Try adjusting your filters.'}
                            type={'info'}
                            size={size}
                            display={display}
                        />
                    </StyledMessageContainer>
                )}
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetFunnelChartComparison;
