// validates the chart in the config file
export const validateConfigChart = config => {
    const messages = [];

    // check if config has a chart
    if (!config.chart) {
        return [];
    }

    // check for a chartType
    if (!config.chart.chartType) {
        messages.push('In the configuration file the chart does not contain a chartType');
    }

    // check for a allowedChartTypes
    if (!config.chart.allowedChartTypes) {
        messages.push('In the configuration file the chart does not contain a allowedChartTypes');
    }

    // check for a chartId
    if (!config.chart.chartId) {
        messages.push('In the configuration file the chart does not contain a chartId');
    }

    // check for a allYMetrics
    if (!config.chart.allYMetrics) {
        messages.push('In the configuration file the chart does not contain a allYMetrics');
    }

    // check for a initialYMetrics
    if (!config.chart.initialYMetrics) {
        messages.push('In the configuration file the chart does not contain a initialYMetrics');
    }

    return messages;
};
