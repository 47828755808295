import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

// Redux
import { removeModal } from '../../redux/actions/modal';

// Components
import ModalNavigation from '../../components/modal-navigation';
import WarningMessage from '../../components/warning-message';

// Enums
import { ButtonThemes } from '../../enums/button-themes';

// Styles
const StyledContainer = styled.div`
    z-index: 500001;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: scroll;
    width: 100%;
    max-width: 900px;
    height: 100vh;
    background-color: ${props => props.theme.colours.lightGrey};
    float: right;
    padding: 20px;
    & h2 {
        color: ${props => props.theme.colours.offBlackLighter};
    }
`;

type ModalLayoutProps = { modalHeader: string; children: React.ReactNode };

type ErrorModalLayoutProps = { modalHeader: string };

export const ModalLayout = ({ modalHeader, children }: ModalLayoutProps) => {
    const dispatch = useDispatch();

    const onCloseClick = () => {
        dispatch(removeModal());
    };

    const modalNavigationButtons = [
        {
            value: 'CLOSE',
            onClick: onCloseClick,
            buttonTheme: ButtonThemes.Secondary,
        },
    ];

    return (
        <StyledContainer>
            <ModalNavigation buttons={modalNavigationButtons} />
            <h2>{modalHeader}</h2>
            {children}
        </StyledContainer>
    );
};

export const ErrorModalLayout = ({ modalHeader }: ErrorModalLayoutProps) => {
    return (
        <ModalLayout modalHeader={modalHeader}>
            <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
        </ModalLayout>
    );
};
