// Helper
import { unknownErrorData } from '../../../helpers/errors';
import getMixValue from '../../../helpers/get-mix-value';

// Types
import { CubedField } from '../../../../types';
import { ResourceComparisonData, TotalComparisonData } from '../../../types';
import { ResourceDataObject } from '../../../../react-query/types';

export type UseResourcePieChartTableCategoryMixComparisonArgs = {
    resourceData: ResourceComparisonData;
    totalData: TotalComparisonData;
    mixField: CubedField;
};

const useResourcePieChartTableCategoryMixComparison = ({
    resourceData,
    totalData,
    mixField,
}: UseResourcePieChartTableCategoryMixComparisonArgs) => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    const status = resourceData.status;
    const totalDataStatus = totalData.status;

    if (
        status === 'loading' ||
        totalDataStatus === 'loading' ||
        status === 'empty' ||
        totalDataStatus === 'empty' ||
        status === 'error' ||
        totalDataStatus === 'error'
    ) {
        return resourceData;
    }

    if (status === 'success' && totalData.status === 'success') {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        let resourceObjects: ResourceDataObject[] = [];
        let comparisonResourceObjects: ResourceDataObject[] = [];

        if (resource.status === 'success') {
            resourceObjects = resource.objects.map(data => {
                const mixingTotalValue = totalData.totals?.values[mixField.rawName].rawValue;
                const mix = getMixValue(data, mixField, mixingTotalValue);

                return {
                    ...data,
                    values: {
                        ...data.values,
                        mix,
                    },
                };
            });
        }

        if (comparisonResource.status === 'success') {
            comparisonResourceObjects = comparisonResource.objects.map(data => {
                const mixingTotalValue = totalData.comparisonTotals?.values[mixField.rawName].rawValue;
                const mix = getMixValue(data, mixField, mixingTotalValue);

                return {
                    ...data,
                    values: {
                        ...data.values,
                        mix,
                    },
                };
            });
        }

        return {
            ...resourceData,
            resources: [
                {
                    ...resource,
                    objects: resourceObjects,
                },
                {
                    ...comparisonResource,
                    objects: comparisonResourceObjects,
                },
            ],
        };
    }

    return unknownErrorData();
};

export default useResourcePieChartTableCategoryMixComparison;
