import React, { useContext } from 'react';
import styled from 'styled-components';

// Context
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

// Types
import { FieldValues } from 'react-hook-form';

// Components
import CreateADashboardWidgetList from '../components/create-a-dashboard-widget-list';
import CreateADashboardSectionList from '../components/create-a-dashboard-section-list';
import IconPenToSquare from '../../../components/icons/pen-to-square';
import Popover from '../../components/popover/popover';
import FormDashboardDetails from '../forms/form-dashboard-details';

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 30px;
    background-color: ${props => props.theme.dashboardBuilder.container.backgroundColor};
    ${props => props.theme.boxShadow(1)}
    padding: 30px 16px;
    width: 300px;
    min-width: 300px;
    border-radius: 6px;
`;

const StyledTitleContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
`;

const StyledTitle = styled.h1`
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.dashboardTitleColor};
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const StyledIconPenToSquare = styled(IconPenToSquare)`
    width: 16px;
`;

const LayoutCreateADashboardSidebar = () => {
    const { sections, dashboardDetails, setDashboardDetails } = useContext(CreateADashboardContext);

    const handleDashboardDetailsSubmit = (data: FieldValues) => {
        setDashboardDetails({
            title: data.dashboardName.trim(),
            description: data.dashboardDescription.trim(),
            isPrivate: data.isPrivate,
            enableComparison: data.enableComparison,
            changed: true,
        });
    };

    return (
        <StyledContainer>
            <StyledTitleContainer>
                <StyledTitle title={dashboardDetails.title}>{dashboardDetails.title}</StyledTitle>
                <div data-testid="edit-dashboard-details">
                    <Popover>
                        <Popover.Trigger>
                            <StyledIconPenToSquare />
                        </Popover.Trigger>
                        <Popover.Content>
                            <FormDashboardDetails
                                dashboardDetails={dashboardDetails}
                                onSubmit={handleDashboardDetailsSubmit}
                            />
                        </Popover.Content>
                    </Popover>
                </div>
            </StyledTitleContainer>
            <CreateADashboardSectionList />
            {sections.length > 0 && <CreateADashboardWidgetList />}
        </StyledContainer>
    );
};

export default LayoutCreateADashboardSidebar;
