export function setAccount(account) {
    localStorage.setItem('selectedAccount', JSON.stringify(account));
    return {
        type: 'SET_ACCOUNT',
        payload: account,
    };
}

export function loadAccount() {
    try {
        const localAccount = localStorage.getItem('selectedAccount');
        if (localAccount) {
            return {
                type: 'SET_ACCOUNT',
                payload: localAccount,
            };
        }
    } catch (e) {
        return {};
    }
}

export function removeAccount() {
    localStorage.removeItem('selectedAccount');
    return {
        type: 'DEL_ACCOUNT',
    };
}
