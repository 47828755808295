import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CubedConfigResource, CubedResource } from '../../types';
import generateQueryKey from '../helpers/generate-query-key';
import { generateUrl } from '../../helpers/request-builder';
import axios from 'axios';

type RequestResponseData = { [key: string]: string | number | boolean };

type UsePostMultiResourcesArgs = {
    resource: CubedResource | CubedConfigResource;
    data: RequestResponseData[];
    handleOnSuccess?: () => void;
    handleOnError?: () => void;
};

const usePostMultiResources = ({ resource, data, handleOnSuccess, handleOnError }: UsePostMultiResourcesArgs) => {
    const queryClient = useQueryClient();
    const queryKey = generateQueryKey({ resource });

    const mutation = useMutation({
        mutationFn: async () => {
            const requests = data.map(resourceData => {
                const url = generateUrl(resource.category, resource.id);
                if (url) {
                    return axios({
                        method: 'POST',
                        url: url,
                        data: resourceData,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).catch(error => {
                        throw new Error(error.response.data.message);
                    });
                }
                throw new Error('Error when generating url');
            });

            await Promise.all(requests);
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries({
                    queryKey: queryKey,
                })
                .then(() => {
                    handleOnSuccess && handleOnSuccess();
                });
        },

        onError: handleOnError,
    });

    return mutation;
};

export default usePostMultiResources;
