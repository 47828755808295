import React, { useState } from 'react';
import styled from 'styled-components';
import validators from '../../../../components/forms/validation/validators';

// Redux
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../../redux/actions/notification';

// Hooks
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import usePatchMultiResourceWithPayload from '../../../../react-query/hooks/use-patch-multi-resource-with-payload';

// Config
import { CONFIG_PRODUCT_LOOKBACK } from '../../../../configurations/resources-config';

// Types & Enums
import { ConfigDataSuccess } from '../../../../react-query/types';
import { FieldValues } from 'react-hook-form';
import { ButtonThemes } from '../../../../enums/button-themes';

// Components
import ConfigTable from '../../../../components/tables/config-table/config-table';
import Form from '../../../../components/forms/form';

const StyledH2 = styled.h2`
    font-size: 1.1rem;
`;

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

type Lookback = {
    id: number;
    product: string;
    modelLookback: number;
};

const LayoutLookbackWindow = () => {
    const dispatch = useDispatch();
    const [lookbackToEdit, setLookbackToEdit] = useState<Lookback>();

    const lookbackQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_PRODUCT_LOOKBACK,
        params: [{ key: 'active', value: 'true' }],
    });

    const lookbackPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_PRODUCT_LOOKBACK,
        handleOnSuccess: () => {
            setLookbackToEdit(undefined);
            dispatch(addNotification({ copy: 'Lookback window successfully updated', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while updating, please try again later or contact support@cubed.email',
                    type: 'error',
                })
            );
        },
    });

    const handleEditClick = (rowId: number) => {
        const lookback = lookbackQuery.data?.objects.find(lookback => lookback.id === rowId);

        if (lookback) {
            setLookbackToEdit({
                id: lookback.id,
                product: lookback.product.name,
                modelLookback: lookback.model_lookback,
            });
        }
    };

    const handleFormSubmit = (data: FieldValues) => {
        lookbackPatchMutation.mutate({
            payload: [
                {
                    model_lookback: data.modelLookback,
                },
            ],
            resourceId: [lookbackToEdit!.id.toString()],
        });
    };

    return (
        <div>
            <StyledH2>Lookback Window</StyledH2>
            <p>
                The lookback window (in days) will be used to set a period of time during which visits will be
                considered to have contributed to sales. These changes will apply to your entire account and could
                result in fundamental changes to your attribution numbers.
            </p>

            <ConfigTable
                status={lookbackQuery.status}
                isFetching={lookbackQuery.isFetching || lookbackPatchMutation.isPending}
                disabled={false}
                empty={lookbackQuery.data?.objects.length === 0}
            >
                <ConfigTable.Table maxHeight="200px" emptyMessage="No lookback windows found">
                    <ConfigTable.Header>
                        <ConfigTable.Row key="lookback-header">
                            <ConfigTable.CellHeader>Product</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Lookback</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader />
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {lookbackQuery.data?.objects.map(lookback => (
                            <ConfigTable.Row key={lookback.id}>
                                <ConfigTable.Cell>{lookback.product.name}</ConfigTable.Cell>
                                <ConfigTable.Cell>{lookback.model_lookback}</ConfigTable.Cell>
                                <ConfigTable.CellActions>
                                    <ConfigTable.ActionDropdownItem
                                        rowId={lookback.id}
                                        type="edit"
                                        label="Edit"
                                        onClick={handleEditClick}
                                    />
                                </ConfigTable.CellActions>
                            </ConfigTable.Row>
                        ))}
                    </ConfigTable.Body>
                </ConfigTable.Table>
            </ConfigTable>

            {lookbackToEdit && (
                <StyledFormContainer>
                    <Form status="enabled" onSubmit={handleFormSubmit} defaultValues={lookbackToEdit}>
                        <Form.Body>
                            <Form.Section>
                                <Form.SectionTitle>Edit Lookback Window - {lookbackToEdit.product}</Form.SectionTitle>

                                <Form.Field>
                                    <Form.InputLabel htmlFor="modelLookback" label="Lookback Window" required={true} />
                                    <Form.InputNumber name="modelLookback" validators={[validators.required]} />
                                </Form.Field>
                            </Form.Section>
                        </Form.Body>
                        <Form.Footer>
                            <Form.InputButton value="Apply" type="submit" loading={lookbackPatchMutation.isPending} />
                            <Form.InputButton
                                value="Cancel"
                                type="button"
                                buttonTheme={ButtonThemes.Secondary}
                                onClick={() => setLookbackToEdit(undefined)}
                            />
                        </Form.Footer>
                    </Form>
                </StyledFormContainer>
            )}
        </div>
    );
};

export default LayoutLookbackWindow;
