import { DropdownOption } from '../section-dashboard/types';
import { CubedField, CubedResource } from '../types';
import * as fields from '../configurations/fields';
import { ResourceDataSuccess } from '../react-query/types';

type QueryArgs = {
    currentResource?: CubedResource;
    selectedChannel?: DropdownOption;
    startDate: string;
    endDate: string;
    tier2Field?: CubedField;
    tier3Field?: CubedField;
    tier4Field?: CubedField;
};

export const channelDetailGoalsQuery = ({ currentResource, selectedChannel, startDate, endDate }: QueryArgs) => {
    return {
        resource: currentResource,
        params: [
            { key: `${currentResource?.dateDimension.rawName}__gte`, value: startDate },
            { key: `${currentResource?.dateDimension.rawName}__lte`, value: endDate },
            { key: 'group_by', value: 'product' },
            { key: 'order_by', value: '-fm_sales' },
            { key: fields.REFERER_ID.rawName, value: selectedChannel?.value as string },
            { key: 'limit', value: 0 },
            { key: 'selected_fields', value: 'product, fm_sales' },
        ],
        enabled: !!currentResource && !!selectedChannel,
        staleTime: 1000 * 60 * 5, // 5 minutes,
        select: (data: ResourceDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.values.product.value,
                    value: object.values.product.value,
                };
            });
        },
    };
};

export const channelDetailSubchannelQuery = ({ currentResource, selectedChannel, startDate, endDate }: QueryArgs) => {
    return {
        resource: currentResource,
        params: [
            { key: `${currentResource?.dateDimension.rawName}__gte`, value: startDate },
            { key: `${currentResource?.dateDimension.rawName}__lte`, value: endDate },
            { key: 'group_by', value: 'subchannel__name' },
            { key: 'order_by', value: '-subchannel__name' },
            { key: fields.REFERER_ID.rawName, value: selectedChannel?.value as string },
            { key: 'limit', value: 0 },
            { key: 'selected_fields', value: 'subchannel__name' },
        ],
        enabled: !!currentResource && !!selectedChannel,
        select: (data: ResourceDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.values.subchannel__name.value,
                    value: object.values.subchannel__name.value,
                };
            });
        },
    };
};

export const channelDetailTier2Query = ({
    currentResource,
    selectedChannel,
    startDate,
    endDate,
    tier2Field,
}: QueryArgs) => {
    if (tier2Field) {
        return {
            resource: currentResource,
            params: [
                { key: `${currentResource?.dateDimension.rawName}__gte`, value: startDate },
                { key: `${currentResource?.dateDimension.rawName}__lte`, value: endDate },
                { key: 'group_by', value: tier2Field.rawName },
                { key: 'order_by', value: tier2Field.rawName },
                { key: fields.REFERER_ID.rawName, value: selectedChannel?.value as string },
                { key: 'limit', value: 0 },
                { key: 'selected_fields', value: tier2Field.rawName },
            ],
            enabled: !!currentResource && !!selectedChannel && !!tier2Field,
            select: (data: ResourceDataSuccess) => {
                return data.objects.map(object => {
                    return {
                        label: object.values[tier2Field.rawName].value,
                        value: object.values[tier2Field.rawName].value,
                    };
                });
            },
        };
    }
};

export const channelDetailTier3Query = ({
    currentResource,
    selectedChannel,
    startDate,
    endDate,
    tier3Field,
}: QueryArgs) => {
    if (tier3Field) {
        return {
            resource: currentResource,
            params: [
                { key: `${currentResource?.dateDimension.rawName}__gte`, value: startDate },
                { key: `${currentResource?.dateDimension.rawName}__lte`, value: endDate },
                { key: 'group_by', value: tier3Field.rawName },
                { key: 'order_by', value: tier3Field.rawName },
                { key: fields.REFERER_ID.rawName, value: selectedChannel?.value as string },
                { key: 'limit', value: 0 },
                { key: 'selected_fields', value: tier3Field.rawName },
            ],
            enabled: !!currentResource && !!selectedChannel,
            select: (data: ResourceDataSuccess) => {
                return data.objects.map(object => {
                    return {
                        label: object.values[tier3Field.rawName].value,
                        value: object.values[tier3Field.rawName].value,
                    };
                });
            },
        };
    }
};

export const channelDetailTier4Query = ({
    currentResource,
    selectedChannel,
    startDate,
    endDate,
    tier4Field,
}: QueryArgs) => {
    if (tier4Field) {
        return {
            resource: currentResource,
            params: [
                { key: `${currentResource?.dateDimension.rawName}__gte`, value: startDate },
                { key: `${currentResource?.dateDimension.rawName}__lte`, value: endDate },
                { key: 'group_by', value: tier4Field.rawName },
                { key: 'order_by', value: tier4Field.rawName },
                { key: fields.REFERER_ID.rawName, value: selectedChannel?.value as string },
                { key: 'limit', value: 0 },
                { key: 'selected_fields', value: tier4Field.rawName },
            ],
            enabled: !!currentResource && !!selectedChannel,
            select: (data: ResourceDataSuccess) => {
                return data.objects.map(object => {
                    return {
                        label: object.values[tier4Field.rawName].value,
                        value: object.values[tier4Field.rawName].value,
                    };
                });
            },
        };
    }
};
