import React from 'react';
import styled, { css } from 'styled-components';

// Types
import { orderByDir, SimpleTableColumn, SimpleTableHeader } from '../../types';

// Image
import ArrowupBlackImage from '../../../../assets/images/icons/icon-arrowup-black.svg';

// Styles
const StyledHeader = css`
    border-bottom: solid 1px ${props => props.theme.colours.lightGrey};
    display: table;
    width: 100%;
    table-layout: fixed;
    cursor: initial;

    &not:first-child {
        border-left: solid 1px ${props => props.theme.colours.lightGrey};
    }
`;

const StyledOrderIcon = css`
    width: 25px;
`;

const StyledHeaderPill = css`
    &:nth-child(2) {
        width: 130px;
    }
    &:nth-child(3) {
        width: 130px;
    }
    &:nth-last-child(1) {
        width: 145px;
    }
`;

const StyledOrderIconTableHeader = styled.th`
    ${StyledOrderIcon};
`;

const StyledSimpleTableHeaderRow = styled.tr`
    ${StyledHeader}
`;

const StyledSimpleTableHeader = styled.th<{ hasIcons: boolean; hasEndAction: boolean; hasPills: boolean }>`
    :first-child {
        padding-left: ${props => props.hasIcons && '32px'};
    }
    :last-child {
        width: ${props => props.hasEndAction && '140px'};
    }

    ${props => props.hasPills && StyledHeaderPill}
`;

const StyledArrowIcon = styled.i<{ arrowImage: string; order: string; orderByVisible: string }>`
    transition: 0.2s ease-in-out;
    height: 12px;
    width: 12px;
    background-image: url(${props => props.arrowImage});
    background-size: contain;
    background-repeat: no-repeat;
    opacity: ${props => (props.orderByVisible === 'visible' ? 0.5 : 0)};
    display: ${props => (props.orderByVisible === 'visible' ? 'inline-block' : 'none')};
    position: initial;
    left: initial;
    top: initial;
    transform: ${props => (props.order === 'desc' ? 'rotate(180deg)' : 'rotate(0deg)')};
    vertical-align: initial;
    visibility: visible;
`;

const StyledHeaderTable = styled.h4<{ orderableStyle: string }>`
    font-size: 1rem;
    font-weight: 500;
    color: ${props => props.theme.colours.offBlack};
    padding: 0 5px 7px 5px;
    margin: 0;
    display: inline-block;
    cursor: ${props => props.orderableStyle === 'orderable' && 'pointer'};
`;

// Types
type SimpleTableHeaderCellProps = {
    header?: SimpleTableHeader;
    columns?: SimpleTableColumn[];
    hasIcons?: boolean;
    hasEndAction?: boolean;
    hasPills?: boolean;
    allowOrdering?: boolean;
    currentOrderBy?: { column: number | null; order: orderByDir | null };
    handleOnColumnOrderChanged?: (index: number, order: orderByDir) => void;
};

const SimpleTableHeaderCell = ({
    header,
    columns,
    hasIcons,
    hasEndAction,
    hasPills,
    allowOrdering,
    currentOrderBy,
    handleOnColumnOrderChanged,
}: SimpleTableHeaderCellProps) => {
    const headerColumns = header ? header.columns || header : columns;

    if (headerColumns === undefined) {
        return null;
    }

    const emptyColumn = allowOrdering ? <StyledOrderIconTableHeader /> : null;

    return (
        <StyledSimpleTableHeaderRow>
            {emptyColumn}
            {headerColumns.map((column, index) => {
                let orderByVisible = currentOrderBy && currentOrderBy.column === index ? 'visible' : '';
                let order: orderByDir =
                    currentOrderBy && currentOrderBy.column === index && currentOrderBy.order === 'desc'
                        ? 'asc'
                        : 'desc';
                const orderableStyle = column.orderable ? 'orderable' : '';

                if (currentOrderBy && currentOrderBy.column === null && column.defaultOrder) {
                    orderByVisible = 'visible';
                    order = column.defaultOrder;
                }

                const handleOnClick = () => {
                    if (!column.orderable) {
                        return;
                    }

                    handleOnColumnOrderChanged && handleOnColumnOrderChanged(index, order);
                };

                return (
                    <StyledSimpleTableHeader
                        key={`table-header__${column.title}`}
                        hasIcons={hasIcons!}
                        hasEndAction={hasEndAction!}
                        hasPills={hasPills!}
                    >
                        <StyledArrowIcon arrowImage={ArrowupBlackImage} order={order} orderByVisible={orderByVisible} />
                        <StyledHeaderTable orderableStyle={orderableStyle} onClick={handleOnClick}>
                            {column.title || column.displayName}
                        </StyledHeaderTable>
                    </StyledSimpleTableHeader>
                );
            })}
        </StyledSimpleTableHeaderRow>
    );
};

export default SimpleTableHeaderCell;
