import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import InputButton from '../inputs/input-button';
import { ButtonThemes } from '../../enums/button-themes';

const StyledActionButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 10px;
`;

const ActionButtons = ({ handleApplyClick, handleCancelEdit, applyButtonDisabled }) => {
    return (
        <StyledActionButtonsContainer>
            <InputButton value={'APPLY'} onClick={handleApplyClick} disabled={applyButtonDisabled} />
            <InputButton value={'CANCEL'} buttonTheme={ButtonThemes.Secondary} onClick={handleCancelEdit} />
        </StyledActionButtonsContainer>
    );
};

ActionButtons.propTypes = {
    handleApplyClick: PropTypes.func,
    handleCancelEdit: PropTypes.func,
};

export default memo(ActionButtons, isEqual);
