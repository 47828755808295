// Configuration & Helpers
import { defaultRequestLimit } from '../configurations/default-request-limit';
import { getResourceFilters } from './get-resource-filters';

// Types
import { ChartDateGranularity } from '../../configurations/common/chart-types';
import { CubedResource } from '../../types';
import { Params, SectionDashboardRequest } from '../types';
import { matchType } from '../../helpers/request-builder';

type BuildParamsArgs = {
    resource: CubedResource;
    request: SectionDashboardRequest;
    startDate: string;
    endDate: string;
    isComparison?: boolean;
    granularity: string;
};

export const buildParams = ({ resource, request, startDate, endDate, isComparison, granularity }: BuildParamsArgs) => {
    let params: Params = [];

    if (request.totals) {
        params.push({ key: 'totals', value: 'true' });
    }

    if (typeof request.offset === 'number') {
        if (!isComparison) {
            if (request?.orderBy && !request.orderBy[0]?.sortByVariance) {
                params.push({ key: 'offset', value: request.offset });
                params.push({ key: 'limit', value: request.limit || defaultRequestLimit });
            }
        } else {
            params.push({ key: 'limit', value: 0 });
        }
    } else {
        if (request.limit && !isComparison) {
            params.push({ key: 'limit', value: request.limit });
        } else {
            params.push({ key: 'limit', value: 0 });
        }
    }

    if (!request.ignoreDate) {
        params.push(
            {
                key: `${resource.dateDimension.rawName}__gte`,
                value: startDate,
            },
            {
                key: `${resource.dateDimension.rawName}__lte`,
                value: endDate,
            }
        );
    }

    if (request.isGraph) {
        let mergedGraphGroupBy = '';

        if (request.graphGroupBy) {
            const graphGroupByFields = request.graphGroupBy.map(groupBy => groupBy.rawName);

            mergedGraphGroupBy = graphGroupByFields.join(',');
        }

        if (mergedGraphGroupBy === '') {
            throw new Error('Expected graphGroupBy');
        }

        if (!request.graphMetrics) {
            throw new Error('Expected graphMetrics');
        }

        const groupByType = granularity === ChartDateGranularity.Hour ? '__hour' : '__day';

        params.push(
            { key: 'graph', value: 'true' },
            { key: 'graph_metrics', value: request.graphMetrics.map(metric => metric.rawName).join(',') },
            { key: 'graph_group_by', value: mergedGraphGroupBy },
            { key: 'graph_group_by_type', value: `${resource.dateDimension.rawName}${groupByType}` }
        );
    } else {
        if (!request.orderBy) {
            throw new Error('Expected order_by');
        }

        const orderByFiters = request.orderBy.map(orderByFilter => {
            return {
                key: 'order_by',
                value: `${orderByFilter.orderByDirection === 'desc' ? '-' : ''}${orderByFilter.field.rawName}${
                    orderByFilter.field.lookupTerm ? '__' + orderByFilter.field.lookupTerm : ''
                }`,
            };
        });

        params = [...params, ...orderByFiters];
    }

    // the request must always have a group_by
    if (request.groupBy) {
        if (Array.isArray(request.groupBy)) {
            const groupByFields = request.groupBy.map(groupBy => groupBy.rawName);
            params.push({
                key: 'group_by',
                value: groupByFields.join(','),
            });
        } else {
            params.push({
                key: 'group_by',
                value: request.groupBy.rawName,
            });
        }
    } else if (request.graphGroupBy) {
        params.push({
            key: 'group_by',
            value: request.graphGroupBy[0].rawName,
        });
    } else {
        throw new Error('Expected group_by or graph_group_by');
    }

    if (request.selectedFields) {
        const selectedFields = request.selectedFields.map(field => field.rawName);
        if (request.orderBy) {
            const orderByFields = request.orderBy.map(orderByFilter => orderByFilter.field.rawName);
            selectedFields.push(...orderByFields);
        }
        params.push({
            key: 'selected_fields',
            value: selectedFields.join(','),
        });
    }

    if (request.filters) {
        const resourceFilters = getResourceFilters(request.filters);

        resourceFilters.forEach(filter => {
            params.push(filter);
        });
    }

    if (request.orFilters) {
        const resourceOrFilters = getResourceFilters(request.orFilters, matchType.OR);

        resourceOrFilters.forEach(orFilter => {
            params.push(orFilter);
        });
    }

    if (request.tableFilters) {
        const tableFilters = getResourceFilters(request.tableFilters);

        tableFilters.forEach(filter => {
            params.push(filter);
        });
    }

    if (request.pageFilters) {
        const pageFilters = getResourceFilters(request.pageFilters);

        pageFilters.forEach(filter => {
            params.push(filter);
        });
    }

    if (request.sectionFilters) {
        const sectionFilters = getResourceFilters(request.sectionFilters);

        sectionFilters.forEach(filter => {
            params.push(filter);
        });
    }

    return params;
};
