import React from 'react';
import InputButton from '../components/inputs/input-button';
import { NavLink, Navigate, useLocation } from 'react-router-dom';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import imageLogoWhiteFull from '../assets/images/white-cubed-logo-full.png';

const ViewInviteInvalid = () => {
    const location = useLocation();

    const params = location.search.substring(1).split('='); //take out ? split on =
    const errorMessage = decodeURIComponent(params[1]);

    if (errorMessage === 'undefined') {
        return <Navigate to="/" />;
    }

    return (
        <div className="invite-invalid">
            <img className="invite-invalid__background" src={imageLoginBackground} alt="Background" />

            <div className="invite-invalid__content">
                <img src={imageLogoWhiteFull} alt="Yard" />
                <div className="invite-invalid__form">
                    <h3>{errorMessage}</h3>
                    <p>The link you have tried to use is invalid. Please contact an admin for new invite link</p>
                    <NavLink to="/login">
                        <InputButton value="LOGIN" />
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default ViewInviteInvalid;
