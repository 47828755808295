import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import FilterSelectCapsules from './filter-select-capsules';
import FilterSelectDropDown from './filter-select-drop-down';
import UseComponentVisible from '../../hooks/use-component-visible';

import imageEdit from '../../assets/images/icons/edit-icon.svg';
import { FilterSelectFilter } from '../../filter-bar/types';

const StyledFilterSelect = styled.div`
    margin-left: 12px;
    position: relative;
    float: left;
`;

const StyledFilterSelectArea = styled('div')<{ filterOptions: FilterSelectFilter[] }>`
    position: relative;
    max-height: 40px;
    display: inline-block;
    line-height: 40px;
    color: #8b8b8b;
    min-height: 40px;
    min-width: 117px;
    background-color: #fafafa;
    border-radius: 60px;
    box-shadow: inset 0 0 3px rgb(0 0 0 / 30%);
    padding-left: ${props => (props.filterOptions.length === 0 ? '0' : '48px')};
`;

const StyledFilterSelectAreaEdit = styled.div`
    background: url(${imageEdit}) no-repeat;
    position: absolute;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    left: 0;
    display: inline-block;
    height: 40px;
    border-radius: 60px 0 0 60px;
    background-color: white;
    padding: 0 12px 0 32px;
    background-position-x: 6px;
    background-size: 28px;
    background-position-y: 4px;
    transition: 0.2s ease-in-out;
    cursor: pointer;

    :hover {
        background-color: #f0f0f0;
    }
`;

export type FilterSelectProps = {
    selectedFilterData: FilterSelectFilter[];
    filterOptions: FilterSelectFilter[];
    handleFilterSelect: (selectedFilter: FilterSelectFilter) => void;
    handleFilterClearAll: () => void;
    handleFilterSelectAll: () => void;
    handleSelectedFilterRemove: (selectedFilter: FilterSelectFilter) => void;
    handleFilterApplyOnClick: (event: React.MouseEvent<HTMLElement>) => void;
    enableFilterApplyButton: boolean;
    hideCapsules: boolean;
    filterName: string;
    enableSingleSelection: boolean;
};

const FilterSelect = ({
    selectedFilterData,
    filterOptions,
    handleFilterSelect,
    handleFilterClearAll,
    handleFilterSelectAll,
    handleSelectedFilterRemove,
    handleFilterApplyOnClick,
    enableFilterApplyButton,
    hideCapsules,
    filterName,
    enableSingleSelection = false,
}: FilterSelectProps) => {
    const { ref: filterRef, isComponentVisible, setIsComponentVisible } = UseComponentVisible(false);

    const toggleFilterEditHandler = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    const handleClickInside = () => {
        setIsComponentVisible(true);
    };

    const handleCancelOnClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setIsComponentVisible(false);
    };

    return (
        <StyledFilterSelect>
            <StyledFilterSelectArea filterOptions={filterOptions}>
                <StyledFilterSelectAreaEdit
                    ref={filterRef}
                    onClick={() => toggleFilterEditHandler()}
                    data-testid="filter-select-edit"
                />
                <FilterSelectCapsules
                    selectedFilterData={selectedFilterData}
                    filterOptions={filterOptions}
                    handleSelectedFilterRemove={handleSelectedFilterRemove}
                    hideCapsules={hideCapsules}
                    filterName={filterName}
                />
            </StyledFilterSelectArea>
            <FilterSelectDropDown
                filterRef={filterRef}
                showFilterSelect={isComponentVisible}
                handleFilterSelectAll={handleFilterSelectAll}
                handleFilterClearAll={handleFilterClearAll}
                filterOptions={filterOptions}
                handleFilterSelect={handleFilterSelect}
                handleClickInside={handleClickInside}
                handleFilterApplyOnClick={handleFilterApplyOnClick}
                handleCancelOnClick={handleCancelOnClick}
                enableFilterApplyButton={enableFilterApplyButton}
                enableSingleSelection={enableSingleSelection}
            />
        </StyledFilterSelect>
    );
};

export default memo(FilterSelect, isEqual);
