import React from 'react';
import styled from 'styled-components';

// Icons
import IconArrowUpLong from '../../../components/icons/arrow-up-long';
import IconArrowDownLong from '../../../components/icons/arrow-down-long';
import IconFilter from '../../../components/icons/filter';

// Helpers
import { columnDataTypeIsNumber, getValue } from '../../helpers/table-data';
import { getRawValueAsNumber } from '../../helpers/resource-data';

// Types
import { SectionDashboardRequest, TableColumn, TableRow, TotalData } from '../../types';
import { CubedField } from '../../../types';

// Styles
const StyledTable = styled.table<{ height: number; width: number }>`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: ${props => (props.height > 1 && props.width > 1 ? '0.9rem' : '0.7rem')};
    color: ${props => props.theme.sectionDashboard.widget.table.textColor};
`;

const StyledTHead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 3;
`;

const StyledTH = styled.th`
    background: ${props => props.theme.sectionDashboard.widget.table.header.backgroundColor};
    font-weight: ${props => props.theme.sectionDashboard.widget.table.header.fontWeight};
    position: sticky;
    left: 0;
    z-index: 2;
    white-space: nowrap;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.header.borderColor};
    cursor: pointer;

    &:first-child {
        z-index: 3;
    }
`;

const StyledHeaderContainer = styled.div<{ dataType?: number; height: number; width: number }>`
    padding: ${props => (props.height > 1 && props.width > 1 ? '13px 15px' : '5px 15px')};
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: ${props => props.dataType && (columnDataTypeIsNumber(props.dataType) ? 'flex-end' : 'flex-start')};
    text-align: ${props => props.dataType && (columnDataTypeIsNumber(props.dataType) ? 'right' : 'left')};

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
    }
`;

const StyledIconContainer = styled.div`
    & svg {
        width: 10px;
    }
    & path {
        fill: ${props => props.theme.sectionDashboard.widget.table.header.iconColor};
    }
`;

const StyledTD = styled.td<{
    width: number;
    height: number;
    isTotal?: boolean;
    dataType?: number;
    variance?: number | false;
}>`
    padding: ${props => (props.height > 1 ? '10px 15px' : '5px 15px')};
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.cell.borderColor};
    background: ${props =>
        props.isTotal
            ? props.theme.sectionDashboard.widget.table.totals.backgroundColor
            : props.theme.sectionDashboard.widget.table.cell.backgroundColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
    text-align: ${props => (props.dataType && columnDataTypeIsNumber(props.dataType) ? 'right' : 'left')};
    color: ${props =>
        props.variance
            ? props.variance > 0
                ? props.theme.sectionDashboard.widget.variance.textColorPositive
                : props.variance < 0
                ? props.theme.sectionDashboard.widget.variance.textColorNegative
                : props.theme.sectionDashboard.widget.table.textColor
            : props.theme.sectionDashboard.widget.table.textColor};
    font-weight: ${props => props.variance && 600};

    &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
    }
`;

const StyledGroupTitle = styled.span`
    font-weight: 600;
`;

const StyledValue = styled.span<{ width: number; height: number; value: string }>`
    color: ${props =>
        (props.value === 'No Data' || props.value === 'N/A') &&
        props.theme.sectionDashboard.widget.table.cell.secondaryTextColor};
    font-size: ${props =>
        (props.value === 'No Data' || props.value === 'N/A') &&
        (props.height > 1 && props.width > 1 ? '0.8rem' : '0.7rem')};
`;

const StyledGraphColourIndicator = styled.div<{ colour: string }>`
    display: inline-block;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    background-color: ${props => props.colour};
    border-radius: 50%;
`;

const StyledTotalTR = styled.tr`
    position: sticky;
    bottom: 0;
    z-index: 1;
    font-weight: 600;
`;

const StyledTotalTD = styled(StyledTD)`
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: ${props => (props.height > 1 ? '8px 15px' : '5px 15px')};
`;

export type SectionDashboardTableProps = {
    request?: SectionDashboardRequest;
    columns: TableColumn[];
    rows: TableRow[];
    totalData?: TotalData;
    width: number;
    height: number;
    ascendingSorts: string[];
    descendingSorts: string[];
    withFilters: string[];
};

const SectionDashboardTable = ({
    request,
    columns,
    rows,
    totalData,
    width,
    height,
    ascendingSorts,
    descendingSorts,
    withFilters,
}: SectionDashboardTableProps) => {
    const excludeOrderBy = request?.excludeOrderBy?.map(field => field.rawName) || [];

    const onHeaderClick = (column: TableColumn) => {
        if (request?.orderBy && request.setOrderBy) {
            if (column.rawName === request?.orderBy[0].field.rawName) {
                if (request.orderBy[0].orderByDirection === 'asc') {
                    request.setOrderBy([{ ...request.orderBy[0], orderByDirection: 'desc' }]);
                } else {
                    request.setOrderBy([{ ...request.orderBy[0], orderByDirection: 'asc' }]);
                }
            } else {
                request.setOrderBy([{ field: column as unknown as CubedField, orderByDirection: 'desc' }]);
            }
        }
    };

    return (
        <StyledTable width={width} height={height}>
            <StyledTHead>
                <tr>
                    {columns.map(column => {
                        return (
                            <StyledTH key={column.__id}>
                                <StyledHeaderContainer
                                    dataType={column.dataType}
                                    onClick={() => !excludeOrderBy.includes(column.rawName) && onHeaderClick(column)}
                                    width={width}
                                    height={height}
                                >
                                    {column.displayName}
                                    {ascendingSorts.includes(column.rawName) && (
                                        <StyledIconContainer>
                                            <IconArrowUpLong />
                                        </StyledIconContainer>
                                    )}
                                    {descendingSorts.includes(column.rawName) && (
                                        <StyledIconContainer>
                                            <IconArrowDownLong />
                                        </StyledIconContainer>
                                    )}
                                    {withFilters.includes(column.rawName) && (
                                        <StyledIconContainer>
                                            <IconFilter />
                                        </StyledIconContainer>
                                    )}
                                </StyledHeaderContainer>
                            </StyledTH>
                        );
                    })}
                </tr>
            </StyledTHead>
            <tbody>
                {rows.map(row => {
                    if (row.title) {
                        return (
                            <tr key={row.__id}>
                                <StyledTD key={row.__id} width={width} height={height}>
                                    <StyledGroupTitle>{row.title}</StyledGroupTitle>
                                </StyledTD>
                                <StyledTD
                                    key={row.__id + 'fill'}
                                    width={width}
                                    height={height}
                                    colSpan={columns.length - 1}
                                />
                            </tr>
                        );
                    }
                    return (
                        <tr key={row.__id}>
                            {columns.map(column => {
                                const value = getValue(row, column.rawName);
                                return (
                                    <StyledTD
                                        key={column.__id}
                                        width={width}
                                        height={height}
                                        title={value}
                                        dataType={column.dataType}
                                        variance={column.isVariance && getRawValueAsNumber(row, column.rawName)}
                                    >
                                        {column.isDimension && row.colour ? (
                                            <StyledGraphColourIndicator colour={row.colour} />
                                        ) : null}

                                        <StyledValue width={width} height={height} value={value}>
                                            {value}
                                        </StyledValue>
                                    </StyledTD>
                                );
                            })}
                        </tr>
                    );
                })}
                {totalData && totalData.status === 'success' && (
                    <StyledTotalTR key={`total-row-${columns[0].__id}`}>
                        {columns.map((column, index) => {
                            return (
                                <StyledTotalTD
                                    key={`total-${column.__id}`}
                                    width={width}
                                    height={height}
                                    title={
                                        index === 0 || column.ignoreTotal
                                            ? 'total'
                                            : getValue(totalData.totals, column.rawName)
                                    }
                                    isTotal={true}
                                    dataType={column.dataType}
                                >
                                    {index === 0
                                        ? 'Total'
                                        : column.ignoreTotal
                                        ? ''
                                        : getValue(totalData.totals, column.rawName)}
                                </StyledTotalTD>
                            );
                        })}
                    </StyledTotalTR>
                )}
            </tbody>
        </StyledTable>
    );
};

export default SectionDashboardTable;
