/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, memo } from 'react';
import { isEqual } from 'lodash';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

//  Helpers
import { generateUrl, generateGetRequest } from '../helpers/request-builder';

// Redux
import { useAppDispatch, useAppSelector } from '../redux/hooks';

// Components
import List from '../components/list';
import InputSearch from '../components/inputs/input-search';

// Views
import ViewLoadingSplash from './loading-splash';
import ViewNotifications from './notifications';

// Redux Actions
import { setUserAccounts } from '../redux/actions/user';
import { setAccount } from '../redux/actions/account';
import { addNotification } from '../redux/actions/notification';
import { NotificationMessageType } from '../enums/notification-types';
import { setDisplayMenu } from '../redux/actions/page-meta';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import imageLogoWhiteFull from '../assets/images/white-cubed-logo-full.png';
import { fetchCurrency } from '../helpers/fetch-currency';

// Styles
const StyledAccountSelectionContainer = styled.div`
    position: relative;
    height: 100vh;

    & img {
        display: block;
        height: 100%;
        width: 100vw;
        object-fit: cover;
        object-position: top;
    }
`;

const StyledAccountSelectionWrapper = styled.div`
    position: absolute;
    text-align: center;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & img {
        height: 50px;
        width: auto;
        object-fit: initial;
        object-position: initial;
        margin-bottom: 15px;
        display: initial;
    }
`;

const StyledAccountSelection = styled.div`
    width: 700px;
    max-height: 50vh;
    height: 100%;
    text-align: left;
    padding: 20px;
    background-color: ${props => props.theme.colours.whiteBg};
    border-radius: 2px;
    ${props => props.theme.boxShadow(1)};

    & .list-widget {
        overflow-y: scroll;
        height: calc(50vh - 152px);
        ${props => props.theme.scrollbar(8, 8)};
    }

    & .list-item__copy {
        text-transform: uppercase;
    }
`;

const StyledHeader = styled.h3`
    margin-top: 0;
    color: ${props => props.theme.colours.offBlackLighter};
`;

// Types
type Account = {
    type: 'ListRowCopy';
    keyValue: string;
    copy: string;
    onClick: (event: React.MouseEvent) => void;
};

const ViewAccountSelection = () => {
    const { user, account } = useAppSelector(state => state);

    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [hasAccount, setHasAccount] = useState(false);
    const [allAccounts, setAllAccounts] = useState<Account[]>([]);
    const [visibleAccounts, setVisibleAccounts] = useState<Account[]>([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(setDisplayMenu(false));

        if (!hasAccount) {
            const userAccountsUrl = generateUrl('user', 'accounts', [], false);

            generateGetRequest(userAccountsUrl)
                .then(res => {
                    dispatch(setUserAccounts(res.data));
                    setIsLoading(false);
                })
                .catch(error => {
                    dispatch(
                        addNotification({
                            copy: 'There was an issue accessing your accounts. Please try again later.',
                            type: NotificationMessageType.Error,
                        })
                    );

                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
            setHasAccount(false);
        }
    }, []);

    useEffect(() => {
        if (allAccounts.length === 0 && user.accounts.length > 0) {
            const allAccounts = user.accounts.map(account => {
                return {
                    type: 'ListRowCopy' as const,
                    keyValue: account.token,
                    copy: account.name,
                    onClick: handleAccountClick,
                };
            });

            setAllAccounts(allAccounts);
            setVisibleAccounts(allAccounts);
        }
    }, [allAccounts, user]);

    const handleAccountClick = async (event: React.MouseEvent) => {
        let account = user.accounts.filter(
            account => account.token === event.currentTarget.getAttribute('data-key')
        )[0];
        await fetchCurrency(account.id, dispatch);
        dispatch(setAccount(account));
        dispatch(setDisplayMenu(true));
        setHasAccount(true);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        const searchValueRegex = new RegExp(searchValue, 'i');

        setSearch(searchValue);
        setVisibleAccounts(allAccounts.filter(account => searchValueRegex.test(account.copy)));
    };

    if (user.isLoggedIn !== true) {
        return <Navigate to="/" replace={true} />;
    } else if (isLoading === true) {
        return <ViewLoadingSplash />;
    } else if (hasAccount || account.isSelected) {
        return <Navigate to={`/${account.token}`} replace={true} />;
    }

    return (
        <StyledAccountSelectionContainer>
            <img src={imageLoginBackground} alt="Background" />
            <StyledAccountSelectionWrapper>
                <img src={imageLogoWhiteFull} alt="Yard" />
                <StyledAccountSelection>
                    <StyledHeader>Select Account</StyledHeader>
                    <InputSearch
                        value={search}
                        onChange={handleInputChange}
                        placeholder="Search accounts..."
                        variant="transparent"
                    />
                    <List
                        rows={visibleAccounts}
                        keyValue={undefined}
                        errorMessageOverride={undefined}
                        header={undefined}
                        isLoading={undefined}
                    />
                </StyledAccountSelection>
            </StyledAccountSelectionWrapper>
            <ViewNotifications />
        </StyledAccountSelectionContainer>
    );
};

export default memo(ViewAccountSelection, isEqual);
