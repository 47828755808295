import React, { useContext } from 'react';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

// Components
import Button from '../../components/button';
import ButtonGroup from '../../components/button-group';
import LoadingSpinner from '../../components/loading-spinner';
import TablePagination from '../../components/tables/components/pagination/table-pagination';
import CustomerExplorerFilters from './customer-explorer-filters';
import TabList from '../../components/common/tab-items/tab-list';
import DataTable from '../../components/tables/components/data-table';
import Timeline from './timeline';
import VisitInfo from './visit-info';
import VisitorInfo from './visitor-info';

// Helpers
import { intToColour } from '../../helpers/colours';

// Context
import { CustomerExplorerContext } from '../context/customer-explorer-context';

const StyledCustomerView = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;
const StyledCustomerViewVisitor = styled.div`
    width: 100%;
    height: 100%;
    flex: 0 1 300px;
    display: inline-block;
`;

const StyledCustomerViewVisit = styled.div`
    position: relative;
    flex: 1 1 auto;
    height: auto;
`;

const StyledLoadingSpinnerContainer = styled.div`
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
`;

const StyledTableContainer = styled.div`
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    padding: 12px;
    background-color: ${props => props.theme.colours.white};
`;

const StyledButtonGroupContainer = styled.div`
    position: absolute;
    right: 15px;
    z-index: 1;
`;

const CustomerExplorer = () => {
    const {
        selectedTabId,
        location,
        visitorId,
        visits,
        visitor,
        handleBackClick,
        tableTabConfig,
        tableMetrics,
        tableDimensions,
        tablePagination,
        tableLoading,
        tableData,
        tableNoData,
        orderBy,
        orderByDir,
        customerSaleTypes,
        handleCustomerSaleTypeChange,
        handleTabElementClick,
        datePickerConfig,
        detailsLoading,
        setCustomOwnerState,
    } = useContext(CustomerExplorerContext);

    const renderVisitorDetails = () => {
        return (
            <>
                <StyledCustomerViewVisitor>
                    <div>
                        <Button key="1" level="danger" fullWidth={true} onClick={handleBackClick} data-testid="go-back">
                            Go Back
                        </Button>
                        <VisitorInfo visitor={visitor} />
                    </div>
                </StyledCustomerViewVisitor>
                {visitorId && visits && (
                    <StyledCustomerViewVisit>
                        <Timeline items={renderVisits()} />
                    </StyledCustomerViewVisit>
                )}
            </>
        );
    };

    const renderVisits = () => {
        return visits.map(visit => {
            return {
                component: <VisitInfo visit={visit} isImpression={visit.is_impression} />,
                icon: visit.is_impression ? 'impression' : visit.sales_count > 0 ? 'sale' : 'visit',
                colour: tinycolor(intToColour(visit.colour)),
                start: visit.first_visit,
                end: visit.last_visit,
            };
        });
    };

    const renderLoading = () => (
        <StyledLoadingSpinnerContainer>
            <LoadingSpinner />
        </StyledLoadingSpinnerContainer>
    );

    if (detailsLoading) {
        return renderLoading();
    }

    if (location.search.includes('vid')) {
        if (!visitorId || !visitor) {
            return renderLoading();
        }
        if (visitorId) {
            return <StyledCustomerView>{renderVisitorDetails()}</StyledCustomerView>;
        }
    } else {
        if (tableData) {
            return (
                <>
                    <CustomerExplorerFilters datePickerConfig={datePickerConfig} />
                    <StyledTableContainer>
                        {!tableLoading && (
                            <>
                                <StyledButtonGroupContainer>
                                    {(selectedTabId === 0 || selectedTabId === 2) && (
                                        <ButtonGroup
                                            options={customerSaleTypes}
                                            onButtonClick={handleCustomerSaleTypeChange}
                                            smallText
                                        />
                                    )}
                                </StyledButtonGroupContainer>
                                <TabList
                                    noData={false}
                                    tabLists={tableTabConfig}
                                    loading={false}
                                    handleTabElementClick={handleTabElementClick}
                                />
                            </>
                        )}

                        <DataTable
                            noData={tableNoData}
                            error={''}
                            loading={tableLoading}
                            dimensions={tableDimensions}
                            data={tableData}
                            selectedRows={[]}
                            metrics={tableMetrics}
                            orderBy={orderBy}
                            orderByDir={orderByDir}
                            setOwnerState={setCustomOwnerState}
                            hideTotals={true}
                            selectedKey={0}
                        />

                        {!tableLoading && tablePagination.totalResults > 0 && (
                            <TablePagination
                                noData={tableNoData}
                                currentPage={tablePagination.currentPage}
                                currentRowCount={tablePagination.currentRowCount}
                                totalResults={tablePagination.totalResults}
                                setOwnerState={setCustomOwnerState}
                                config={tablePagination}
                                selectedRowCount={tablePagination.currentRowCount}
                            />
                        )}
                    </StyledTableContainer>
                </>
            );
        } else {
            return renderLoading();
        }
    }
};

export default CustomerExplorer;
