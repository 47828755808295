/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, ChangeEvent, FocusEvent } from 'react';

// Core Dependencies
import { debounce } from 'lodash';
import styled from 'styled-components';

// Component
import Cancel from '../../components/cancel';

// Hooks
import UseComponentVisible from '../../hooks/use-component-visible';

// Types
import { BreadcrumbItemProps, DimensionOptions } from '../types';

// Styles
const StyledBreadcrumbsItem = styled.div`
    position: relative;
    box-sizing: border-box;
    float: right;
    margin-left: -20px;
`;

const StyledBreadcrumbsItemLabel = styled.div`
    height: 11px;
    line-height: 11px;
    position: relative;
    padding-left: 24px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 4px;
    text-transform: uppercase;
    color: #0a0a0a;
`;

const StyledBreadcrumbsItemContentWrapper = styled.div`
    border-radius: 0 37px 37px 0;
    background-color: ${props => props.theme.colours.white};
    padding: 6px;
    display: block;
    width: inherit;
    height: 100%;
`;

const StyledBreadcrumbsItemContent = styled.div`
    border-top: 1px solid ${props => props.theme.colours.white};
    border-bottom: 1px solid #d2d2d2;
    background-color: ${props => props.theme.colours.lightGrey};

    line-height: 37px;
    height: 37px;
    min-width: 54px;
    padding-left: 24px;
    position: relative;
    border-radius: 0 37px 37px 0;
    overflow: hidden;
`;

const StyledBreadcrumbsItemInut = styled.input`
    box-sizing: border-box;
    padding-left: 6px;
    margin-right: 37px;
    margin-top: 1px;
    line-height: 27px;
    font-weight: 300;
    color: ${props => props.theme.colours.darkGrey};
    border-radius: 0 37px 37px 0;
    background-color: transparent;
    transition: ${props => props.theme.transition};
    border: 1px solid transparent;
    max-width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:focus {
        max-width: 260px;
        background-color: rgba(255, 255, 255, 0.6);
        border: 1px solid ${props => props.theme.colours.info};
    }
`;

const StyledBreadcrumbsItemDropdown = styled.div`
    ${props => props.theme.boxShadow(1)}
    position: absolute;
    background-color: ${props => props.theme.colours.textLight};
    transition: ${props => props.theme.transition};
    z-index: 1000;
    width: 100%;
    padding: 6px;
    overflow: hidden;

    visibility: visible;
    max-height: 600px;
`;

const StyledDimensionsList = styled('li')<{ isLoading: boolean }>`
    position: relative;
    cursor: ${props => (props.isLoading ? 'default' : 'pointer')};
    width: inherit;
    background-color: ${props => props.theme.colours.hoverGrey};
    padding: 4px 12px;
    border-radius: 3px;
    margin: 2px;
    transition: ${props => props.theme.transition};

    &:hover {
        background-color: ${props => (props.isLoading ? props.theme.colours.hoverGrey : '#dfdfdf')};
    }
`;

const BreadcrumbItem = ({
    filter,
    dimsLoading,
    removeFilter,
    fetchDimensionOptions,
    setFilter,
    dimensionOptions,
    showCancel = true,
}: BreadcrumbItemProps) => {
    const {
        ref: dimensionsListRef,
        isComponentVisible: expandDimensionsList,
        setIsComponentVisible: setExpandDimensionsList,
    } = UseComponentVisible(false);

    const [previousFilter, setPreviousFilter] = useState('');
    const [currentFilter, setCurrentFilter] = useState('');

    useEffect(() => {
        let field = filter;
        // currentFilter is what we render in the Breadcrumb - it gets updated during keyboard typing within the Input box
        // previousFilter is to store what we're looking at so we can easily undo/revert back when needed
        // - this only updates once currentFilter successfully changes
        setCurrentFilter(typeof field.value === 'boolean' ? (field.displayValue as string) : (field.value as string));
        setPreviousFilter(field.displayValue!);
    }, [filter]);

    const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
        let value = event.target.value;
        fetchDimensionOptions(filter.field, value);
    };

    const handleOnBlur = () => {
        // switch back to the previousFilter - this is the actual filter in use on the page
        setCurrentFilter(previousFilter);
    };

    const handleOnClick = () => setExpandDimensionsList(true);

    const handleRemove = () => {
        removeFilter(filter.field);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        // while typing in the breadcrumb to search/filter items
        setCurrentFilter(event.target.value);
        handleFetchDimensionOptions(filter.field, event.target.value);
    };

    const handleFetchDimensionOptions = useCallback(
        debounce((field, value) => {
            // call this function after a delay
            fetchDimensionOptions(field, value);
        }, 2000),
        []
    );

    const handleSelect = (value: string) => {
        setCurrentFilter(value);
        setPreviousFilter(value);

        // select an Item from the filtered breadcrumbs
        setFilter(filter.field, value, true);
        setExpandDimensionsList(false);
    };

    const renderDimensionOptions = () => {
        if (dimsLoading) {
            return <StyledDimensionsList isLoading={dimsLoading}>Loading...</StyledDimensionsList>;
        }

        return dimensionOptions.map((option: DimensionOptions) => {
            if (option[filter.field.rawName]) {
                return (
                    <StyledDimensionsList
                        isLoading={dimsLoading}
                        key={option.id as unknown as string}
                        onClick={() => handleSelect(option[filter.field.rawName].value)}
                    >
                        <span>{option[filter.field.rawName].value}</span>
                    </StyledDimensionsList>
                );
            }

            return null;
        });
    };

    return (
        <StyledBreadcrumbsItem>
            <StyledBreadcrumbsItemLabel data-testid="selected-breadcrumb-item-label">
                {filter.field.displayName}
            </StyledBreadcrumbsItemLabel>
            <StyledBreadcrumbsItemContentWrapper>
                <StyledBreadcrumbsItemContent data-testid="selected-breadcrumb-item-content">
                    <StyledBreadcrumbsItemInut
                        onFocus={event => handleOnFocus(event)}
                        onBlur={() => handleOnBlur()}
                        onClick={() => handleOnClick()}
                        placeholder={currentFilter}
                        value={currentFilter}
                        onChange={handleChange}
                    />
                    {showCancel && <Cancel onClick={handleRemove} colour="red" floatRight={true} />}
                </StyledBreadcrumbsItemContent>
            </StyledBreadcrumbsItemContentWrapper>
            {expandDimensionsList && (
                <StyledBreadcrumbsItemDropdown ref={dimensionsListRef}>
                    <ul>{renderDimensionOptions()}</ul>
                </StyledBreadcrumbsItemDropdown>
            )}
        </StyledBreadcrumbsItem>
    );
};

export default BreadcrumbItem;
