import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CubedConfigResource, CubedResource } from '../../types';
import generateQueryKey from '../helpers/generate-query-key';
import { generateUrl } from '../../helpers/request-builder';
import axios from 'axios';

type RequestResponseData = { [key: string]: string | number | boolean };

type UsePostResourceArgs = {
    resource: CubedResource | CubedConfigResource;
    url?: string;
    headers?: { [key: string]: string };
    handleOnSuccess?: (data: RequestResponseData) => void;
    handleOnError?: (error?: string) => void;
};

const usePostResourceWithPayload = ({
    resource,
    url,
    headers,
    handleOnSuccess,
    handleOnError,
}: UsePostResourceArgs) => {
    const queryClient = useQueryClient();
    const queryKey = generateQueryKey({ resource });

    const resourceUrl = url || generateUrl(resource.category, resource.id);

    const mutation = useMutation({
        mutationFn: async (payload: RequestResponseData) => {
            if (resourceUrl) {
                return axios({
                    method: 'POST',
                    url: resourceUrl,
                    data: payload,
                    withCredentials: true,
                    headers: headers || {
                        'Content-Type': 'application/json',
                    },
                })
                    .catch(error => {
                        throw new Error(error);
                    })
                    .finally(() => {
                        queryClient.invalidateQueries({
                            queryKey: queryKey,
                        });
                    });
            }
            throw new Error('Error when generating url');
        },
        onSuccess: response => {
            if (handleOnSuccess) {
                handleOnSuccess(response.data);
            }
        },
        onError: error => {
            if (handleOnError) {
                handleOnError(error.message);
            }
        },
    });

    return mutation;
};

export default usePostResourceWithPayload;
