const xAxisConfig = {
    type: 'datetime',
    timezone: 'browser',
    tickAmount: 0,
    lineWidth: 0,
    gridLineWidth: 0,
    labels: {
        enabled: false,
    },
    minorTickLength: 0,
    tickLength: 0,
};

export const sparkLineConfig = {
    title: '',
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: false,
    },
    time: {
        useUTC: true,
    },
    xAxis: [
        {
            ...xAxisConfig,
        },
        {
            ...xAxisConfig,
        },
    ],
    yAxis: {
        title: {
            text: '',
        },
        min: 0,
        gridLineWidth: 0,
        labels: {
            enabled: true,
            style: {
                color: 'rgba(255, 255, 255, 0.6)',
                fontSize: '10px',
            },
        },
    },
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
    },
    chart: {
        skipClone: true,
        borderWidth: 0,
        type: 'spline',
        style: {
            overflow: 'visible',
            fontFamily: 'Work Sans',
        },
        backgroundColor: 'transparent',
    },
    plotOptions: {
        color: '#FFFFFF',
        series: {
            marker: {
                enabled: true,
            },
        },
    },
    legend: {
        enabled: false,
    },
    redraw: true,
};
