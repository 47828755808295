import { useState, useEffect } from 'react';
// Import Components
import ListHeaderTitle from './list-headers/list-header-title';
import ListHeaderCheckTitle from './list-headers/list-header-check-title';
import ListRowTitleCopy from './list-rows/list-row-title-copy';
import ListRowCheckCopy from './list-rows/list-row-check-copy';
import ListRowCopy from './list-rows/list-row-copy';
import ListRowUserDetails from './list-rows/list-row-user-details';
import ListRowTitleCopyLink from './list-rows/list-row-title-copy-link';
import ListRowBody from './list-rows/list-row-body';
import LoadingSpinner from './loading-spinner';
import WarningMessage from './warning-message';

//
// 	Class List
//		props.errorMessageOverride :    String | undefined
// 		props.header :                  < Header Object > | undefined
//      props.keyValue :                String | undefined
//		props.rows :                    Array < Row Object > | undefined
//      props.onRowClick :              function
//
// 	< Header Object >
//		{
// 			type:   String  ( Type of the Header )
// 			title:  String  ( Needed for type="Text" )
// 		}
//
// 	< Row Object >
//		{
// 			type:       String      ( Type of the Row )
//          key:        String      ( Needed for type="ListItemTextCopy" )
// 			title:      String      ( Needed for type="ListItemTextCopy" )
// 			copy:       String      ( Needed for type="ListItemTextCopy" )
// 			onClick:    function    ( Needed for type="ListItemTextCopy" )
// 		}
//

const List = ({ rows, keyValue, errorMessageOverride, header, isLoading }) => {
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        errorMessageOverride ? setErrorMessage(errorMessage) : setErrorMessage('There are no items in list');
    }, [errorMessageOverride]); // eslint-disable-line react-hooks/exhaustive-deps

    // Based on the type of the header return the HTML with all the values filled in.
    const RenderHeader = () => {
        if (header) {
            switch (header.type) {
                case 'Title':
                    return <ListHeaderTitle title={header.title} />;
                case 'CheckTitle':
                    return <ListHeaderCheckTitle title={header.title} />;
                default:
                    return null;
            }
        }
    };

    // Based on the type of the row return the HTML with all the values populated.
    const RenderRow = (row, index) => {
        switch (row.type) {
            case 'ListRowTitleCopy':
                return (
                    <ListRowTitleCopy
                        key={index}
                        keyValue={row.keyValue}
                        title={row.title}
                        copy={row.copy}
                        chevron={row.chevron}
                        onClick={row.onClick}
                    />
                );
            case 'ListRowTitleCopyLink':
                return (
                    <ListRowTitleCopyLink
                        key={index}
                        keyValue={row.keyValue}
                        title={row.title}
                        copy={row.copy}
                        icon={row.icon}
                        link={row.link}
                        external={row.external}
                        rel={row.rel}
                        target={row.target}
                    />
                );
            case 'List':
                return <List key={index} keyValue={row.keyValue} header={row.header} rows={row.rows} />;
            case 'ListRowCheckCopy':
                return (
                    <ListRowCheckCopy
                        key={index}
                        checked={row.checked}
                        keyValue={row.keyValue}
                        dataValue={row.dataValue}
                        copy={row.copy}
                        onClick={row.onClick}
                    />
                );
            case 'ListRowCopy':
                return <ListRowCopy key={index} keyValue={row.keyValue} copy={row.copy} onClick={row.onClick} />;
            case 'ListRowUserDetails':
                return (
                    <ListRowUserDetails
                        key={index}
                        keyValue={row.keyValue}
                        name={row.name}
                        email={row.email}
                        onClick={row.onClick}
                    />
                );
            case 'ListRowBody':
                return (
                    <ListRowBody key={index} keyValue={row.keyValue} bodyContent={row.bodyContent} title={row.title} />
                );
            default:
                return null;
        }
    };

    if (isLoading === true) {
        return (
            <div className="list-widget">
                <div className="list-widget__header" data-key={header?.keyValue}>
                    <RenderHeader />
                </div>

                <div className="list-widget__loading">
                    <LoadingSpinner />
                </div>
            </div>
        );
    }

    if (rows === undefined && rows.length === 0) {
        return (
            <div className="list-widget">
                <div className="list-widget__header" data-key={header?.keyValue}>
                    <RenderHeader />
                </div>

                <div className="list-widget__error">
                    <WarningMessage copy={errorMessage} />
                </div>
            </div>
        );
    }

    return (
        <div key={keyValue} className="list-widget">
            <div className="list-widget__header" data-key={header?.keyValue}>
                <RenderHeader />
            </div>

            <div className="list-widget__rows">{rows.map((row, index) => RenderRow(row, index))}</div>
        </div>
    );
};

export default List;
