import React from 'react';
import styled from 'styled-components';

const StyledSeparatorVertical = styled.div<{ spacingLeft: boolean; spacingRight: boolean }>`
    width: 1px;
    height: 100%;
    border: none;
    background: ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    margin: 0;
    margin-left: ${props => (props.spacingLeft === false ? 0 : '5px')};
    margin-right: ${props => (props.spacingRight === false ? 0 : '5px')};
`;

export type WidgetMenuSeparatorProps = {
    spacingLeft?: boolean;
    spacingRight?: boolean;
};

const WidgetMenuSeparatorVertical = ({ spacingLeft = true, spacingRight = true }: WidgetMenuSeparatorProps) => {
    return <StyledSeparatorVertical spacingLeft={spacingLeft} spacingRight={spacingRight} />;
};

export default WidgetMenuSeparatorVertical;
