import React from 'react';
import styled from 'styled-components';

// Helpers
import { getValue } from '../../helpers/table-data';

// Types
import { TableColumn, TableRow } from '../../types';

const StyledCardContainer = styled.div`
    width: 100%;
    position: relative;
`;

const StyledCardTable = styled.table`
    width: calc(100% - 10px);
    border-collapse: collapse;
    font-size: 0.8rem;
    padding: 5px;
    border: 1px solid ${props => props.theme.sectionDashboard.widget.table.cardTable.borderColor};
    margin: 0 auto;
    margin-top: 5px;
    background: ${props => props.theme.sectionDashboard.widget.table.cardTable.backgroundColor};
    color: ${props => props.theme.sectionDashboard.widget.table.cardTable.textColor};

    &:nth-child(odd) {
        background: ${props => props.theme.sectionDashboard.widget.table.cardTable.backgroundColorOdd};
    }

    &:last-child {
        margin-bottom: 5px;
    }
`;

const StyledCardHeaderTD = styled.td`
    padding: 5px;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.cardTable.borderColor};

    &:first-child {
        font-weight: 600;
    }
`;

const StyledCardTD = styled.td`
    padding: 5px;
    vertical-align: top;

    &:first-child {
        font-weight: 600;
    }
`;

const StyledTitle = styled.div`
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 5px;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.cardTable.borderColor};
`;

export type WidgetTableCardBoxProps = {
    rows: TableRow[];
    columns: TableColumn[];
};

const WidgetTableCardBox = ({ rows, columns }: WidgetTableCardBoxProps) => {
    return (
        <StyledCardContainer>
            {rows.map(row => {
                if (row.title) {
                    return <StyledTitle>{row.title}</StyledTitle>;
                }
                return (
                    <StyledCardTable key={row.__id}>
                        <tbody>
                            {columns.map((column, index) => {
                                if (index === 0) {
                                    return (
                                        <tr key={column.displayName}>
                                            <StyledCardHeaderTD>{column.displayName}</StyledCardHeaderTD>
                                            <StyledCardHeaderTD>{getValue(row, column.rawName)}</StyledCardHeaderTD>
                                        </tr>
                                    );
                                } else {
                                    return (
                                        <tr key={column.displayName}>
                                            <StyledCardTD>{column.displayName}</StyledCardTD>
                                            <StyledCardTD>{getValue(row, column.rawName)}</StyledCardTD>
                                        </tr>
                                    );
                                }
                            })}
                        </tbody>
                    </StyledCardTable>
                );
            })}
        </StyledCardContainer>
    );
};

export default WidgetTableCardBox;
