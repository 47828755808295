import moment from 'moment';

export const setDate = (startDate, endDate) => {
    let finalStartDate = moment(startDate);
    let finalEndDate = moment(endDate);

    return {
        type: 'UPDATE_DATE',
        payload: {
            startDate: finalStartDate,
            endDate: finalEndDate,
        },
    };
};

export const setComparisonDate = (startDate, endDate) => {
    let finalStartDate = moment(startDate);
    let finalEndDate = moment(endDate);

    return {
        type: 'UPDATE_COMPARISON_DATE',
        payload: {
            startDate: finalStartDate,
            endDate: finalEndDate,
        },
    };
};
