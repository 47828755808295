// React Dependencies
import React, { Component } from 'react';

// Import Components
import InputField from '../inputs/input-field';
import Tooltip from '../tooltip';

//
//      class FormFieldText
//          props.toolTipCopy           String | undefined
//          props.label                 String | undefined
//          props.errorMessage          String | undefined
//          props.requiredField         boolean | undefined
//          props.keyValue              String | undefined
//          props.class                 String | undefined
//          props.inputPlaceholder      String | undefined
//          props.onInputKeyPress       function | undefined
//          props.inputOnChange         function(event) | undefined
//          props.inputName             String | undefined
//          props.inputDataValue        String
//          props.inputValue            String
//          props.valueWillChange       function(value, key)
//

class FormTextInput extends Component {
    constructor(props) {
        super(props);

        this.renderToolTip = this.renderToolTip.bind(this);
        this.renderErrorMessage = this.renderErrorMessage.bind(this);
        this.renderRequiredField = this.renderRequiredField.bind(this);
        this.inputClassName = this.inputClassName.bind(this);
    }

    renderToolTip() {
        if (this.props.toolTipCopy === undefined || this.props.toolTipCopy === '') {
            return null;
        }

        return <Tooltip copy={this.props.toolTipCopy} />;
    }

    renderErrorMessage() {
        if (this.props.errorMessage === undefined || this.props.errorMessage === '') {
            return null;
        }

        return <p className="form-field__error-message"> {this.props.errorMessage} </p>;
    }

    renderRequiredField() {
        if (this.props.requiredField === undefined || this.props.requiredField === false) {
            return null;
        }

        return <span className="form-field__top__required-field">*</span>;
    }

    inputClassName() {
        return `form-field form-field__text ${this.props.class !== undefined ? this.props.class : ''} ${
            this.props.errorMessage === undefined || this.props.errorMessage === '' ? '' : 'error'
        }`;
    }

    render() {
        return (
            <div key={this.keyValue} className={this.inputClassName()}>
                <div className="form-field__top">
                    <label>
                        {this.props.label} <this.renderRequiredField />
                    </label>
                    <this.renderToolTip />
                </div>
                {this.props.prefix ? (
                    <div className="form-field__prefix">
                        <span className="form-field__prefix__text">{this.props.prefix}</span>
                        <InputField
                            dataKey={this.props.inputDataKey}
                            name={this.props.inputName}
                            type="text"
                            placeholder={this.props.inputPlaceholder}
                            value={this.props.inputValue}
                            onChange={this.props.inputOnChange}
                            onKeyPress={this.props.onInputKeyPress}
                            required={this.props.requiredField}
                            valueWillChange={this.props.valueWillChange}
                            autocomplete={this.props.autocomplete}
                            disabled={this.props.disabled}
                            isLoading={this.props.isLoading}
                            bufferingMessage={this.props.bufferingMessage}
                            onFocusClick={this.props.onFocusClick}
                        />
                    </div>
                ) : (
                    <InputField
                        dataKey={this.props.inputDataKey}
                        name={this.props.inputName}
                        type="text"
                        placeholder={this.props.inputPlaceholder}
                        value={this.props.inputValue}
                        onChange={this.props.inputOnChange}
                        onKeyPress={this.props.onInputKeyPress}
                        required={this.props.requiredField}
                        valueWillChange={this.props.valueWillChange}
                        autocomplete={this.props.autocomplete}
                        disabled={this.props.disabled}
                        isLoading={this.props.isLoading}
                        bufferingMessage={this.props.bufferingMessage}
                        onFocusClick={this.props.onFocusClick}
                    />
                )}
                <this.renderErrorMessage />
            </div>
        );
    }
}

export default FormTextInput;
