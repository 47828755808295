import { ComparisonTableRow, TableRow, TableRowValue } from '../types';
import DataTypes from '../../filter-bar/enums/data-types';

const getRowValue = (row: TableRow | ComparisonTableRow, key: string): TableRowValue => {
    const value = row.values[key];
    if (typeof value === 'string') {
        throw Error(`Unexpected type for field ${key}`);
    }
    return value;
};

export const getValue = (row: TableRow | ComparisonTableRow, key: string): string => {
    return getRowValue(row, key).value;
};

export const getRawValueAsNumber = (row: TableRow | ComparisonTableRow, key: string): number => {
    const value = getRowValue(row, key);
    if (typeof value.rawValue !== 'number') {
        throw Error(`Expected rawValue to be of type number, instead was ${typeof value.rawValue}`);
    }
    return value.rawValue;
};

export const getRawValueAsNumberWithNull = (row: TableRow, key: string): number => {
    const value = getRowValue(row, key);
    const rawValue = value.rawValue === null ? 0 : value.rawValue;
    if (typeof rawValue != 'number') {
        throw Error(`Expected rawValue to be of type number, instead was ${typeof value.rawValue}`);
    }
    return rawValue;
};

export const columnDataTypeIsNumber = (dataType: number) => {
    return [DataTypes.NUMBER, DataTypes.FLOAT, DataTypes.MONEY].includes(dataType);
};
