import { DefaultTheme } from 'styled-components';

export const stackedAreaChartConfig = (theme: DefaultTheme) => {
    return {
        chart: {
            style: {
                fontFamily: 'Work Sans',
            },
            backgroundColor: 'transparent',
        },
        credits: {
            enabled: false,
        },
        title: {
            text: null,
            style: {
                fontSize: '0.7rem',
                textTransform: 'uppercase',
                fontWeight: 700,
                fontFamily: 'Work Sans',
            },
            margin: 0,
        },
        xAxis: {
            type: 'datetime',
            tickInterval: 24 * 60 * 60 * 1000,
            labels: {
                enabled: true,
                style: {
                    whitespace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: '0.7em',
                },
            },
            lineColor: '#E3E3FF',
            tickColor: '#E3E3FF',
        },
        tooltip: {
            shared: false,
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                },
            },
            series: {
                marker: {
                    symbol: 'circle',
                    radius: 3,
                },
            },
            line: {
                dashStyle: 'ShortDash',
            },
        },
    };
};
