import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import styled from 'styled-components';

// Icons
import IconQuestionMark from '../../components/icons/question-mark';

// Styles
const StyledIconContainer = styled.div`
    background-color: ${props => props.theme.sectionDashboard.tooltip.iconBackgroundColor};
    border-radius: 50%;
    width: 20px;
    height: 20px;
`;

const StyledTrigger = styled(TooltipPrimitive.Trigger)<{
    $tooltipPlacement?: TooltipPlacement;
    $usePlaceholder: boolean;
    $triggerColor?: string;
}>`
    font-family: inherit;
    background-color: transparent;
    padding: ${props => props.$usePlaceholder && '0'};
    outline: none;
    border: none;
    width: ${props => props.$usePlaceholder && '20px'};
    float: ${props => (props.$tooltipPlacement ? props.$tooltipPlacement : 'none')};
    cursor: pointer;
    color: ${props =>
        props.$triggerColor ? props.$triggerColor : props.theme.sectionDashboard.section.sectionMenu.select.textColor};

    &:hover ${StyledIconContainer} {
        background-color: ${props => props.theme.sectionDashboard.tooltip.triggerHoverColor};
    }
`;

const StyledTooltipContent = styled(TooltipPrimitive.Content)<{ $invertColours: boolean; $contentWidth?: number }>`
    background-color: ${props =>
        props.$invertColours
            ? props.theme.sectionDashboard.tooltip.contrastColor2
            : props.theme.sectionDashboard.tooltip.contrastColor1};
    color: ${props =>
        props.$invertColours
            ? props.theme.sectionDashboard.tooltip.contrastColor1
            : props.theme.sectionDashboard.tooltip.contrastColor2};
    border: 2px solid
        ${props =>
            props.$invertColours
                ? props.theme.sectionDashboard.tooltip.contrastColor2
                : props.theme.sectionDashboard.tooltip.contrastColor1};
    font-size: 0.75rem;
    padding: 10px;
    border-radius: 5px;
    max-width: ${props => (props.$contentWidth ? `${props.$contentWidth}px` : '210px')};
    text-align: center;
    z-index: 500001;
`;

const StyledArrow = styled(TooltipPrimitive.Arrow)<{ $invertColours: boolean }>`
    fill: ${props =>
        props.$invertColours
            ? props.theme.sectionDashboard.tooltip.contrastColor2
            : props.theme.sectionDashboard.tooltip.contrastColor1};
`;

const StyledIconQuestionMark = styled(IconQuestionMark)`
    width: 16px;
    height: 16px;
    color: ${props => props.theme.sectionDashboard.tooltip.iconColor};
    position: relative;
    top: 2px;
`;

// Enums
export enum TooltipPlacement {
    Right = 'right',
    Left = 'left',
}

export enum TooltipPopUpSide {
    Top = 'top',
    Right = 'right',
    Bottom = 'bottom',
    Left = 'left',
}

// Types
export type SectionDashboardTooltipProps = {
    copy: string | React.ReactNode;
    tooltipPlacement?: TooltipPlacement;
    tooltipPopUpSide?: TooltipPopUpSide;
    preventClick?: boolean;
    usePlaceholder?: boolean;
    invertColours?: boolean;
    triggerColor?: string;
    contentWidth?: number;
    children?: React.ReactNode;
};

const SectionDashboardTooltip = ({
    copy,
    tooltipPlacement,
    tooltipPopUpSide = TooltipPopUpSide.Top,
    preventClick = true,
    usePlaceholder = true,
    invertColours = false,
    triggerColor,
    contentWidth,
    children,
}: SectionDashboardTooltipProps) => {
    const handleTriggerClick = (event: React.UIEvent) => {
        preventClick && event.preventDefault();
    };

    return (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root delayDuration={0}>
                <StyledTrigger
                    onClick={handleTriggerClick}
                    $tooltipPlacement={tooltipPlacement}
                    $usePlaceholder={usePlaceholder}
                    $triggerColor={triggerColor}
                >
                    {usePlaceholder && (
                        <StyledIconContainer>
                            <StyledIconQuestionMark />
                        </StyledIconContainer>
                    )}
                    {!usePlaceholder && children}
                </StyledTrigger>
                <TooltipPrimitive.Portal>
                    <StyledTooltipContent
                        side={tooltipPopUpSide}
                        $invertColours={invertColours}
                        arrowPadding={-50}
                        $contentWidth={contentWidth}
                    >
                        <StyledArrow $invertColours={invertColours} />
                        {copy}
                    </StyledTooltipContent>
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
};

export default SectionDashboardTooltip;
