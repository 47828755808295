import React, { useState } from 'react';
import styled from 'styled-components';
import { inputStyles } from './styles/input-styles';

import { channelColours } from '../../../helpers/colours';

// Context
import { FieldError, useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

// Validation
import buildValidators from '../validation/helpers/build-validators';
import { Validator } from '../types';

// Components
import InputContainer from './components/input-container';
import InputErrorMessage from './components/input-error-message';

const StyledColourPickerSelect = styled.div<{ disabled: boolean }>`
    ${inputStyles}
    padding: 0;
`;

const StyledColourPickerSelectColourPreview = styled.div<{ selectedColour?: string }>`
    width: 40px;
    height: 40px;
    display: table-cell;
    background-color: ${props => props.selectedColour};
    background-image: ${props =>
        !props.selectedColour &&
        `linear-gradient(
        135deg,
        ${props.theme.colours.light} 0%,
        ${props.theme.colours.light} 48%,
        ${props.theme.colours.red} 48%,
        ${props.theme.colours.red} 52%,
        ${props.theme.colours.light} 52%,
        ${props.theme.colours.light} 100%
    )`};
    background-size: ${props => props.selectedColour === null && '40px 40px'};
`;

const StyledColourPickerPlaceHolder = styled.p<{ selectedColour: string }>`
    display: inline-block;
    display: table-cell;
    font-weight: ${props => (!props.selectedColour ? '300' : '400')};
    font-size: ${props => !props.selectedColour && '0.9em'};
    padding: ${props => (!props.selectedColour ? '12px' : '10px 12px')};
`;

const StyledColourPickerHolder = styled.div<{ showPicker: boolean }>`
    ${props => props.theme.dropShadow('5px', '5px', '3px', '0px', 'rgba(0, 0, 0, 0.1)')};
    transition: ${props => props.theme.transition};
    visibility: ${props => (props.showPicker ? 'visible' : 'hidden')};
    opacity: ${props => (props.showPicker ? '1' : '0')};
    position: absolute;
    height: auto;
    z-index: 5;
    width: 100%;
    max-width: 310px;
    margin-left: 40px;
    background-color: ${props => props.theme.colours.white};
    padding: 2px;
    margin-top: -5px;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
`;

const StyledColourPickerHolderTile = styled.div<{ colour: string }>`
    transition: ${props => props.theme.transition};
    height: 40px;
    flex: 0 0 calc(16.66667% - 4px);
    max-width: calc(16.66667% - 4px);
    display: inline-block;
    margin: 2px;
    cursor: pointer;
    &:hover {
        filter: saturate(0.5);
    }
    background-color: ${props => props.colour};
`;

type Colour = { name: string; hex: string };

export type InputColourPickerProps = {
    name: string;
    disabled?: boolean;
    validators?: Validator[];
};

const InputColourPicker = ({ name, disabled, validators = [] }: InputColourPickerProps) => {
    const { status } = useCubedFormContext();
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const [showPicker, setShowPicker] = useState(false);
    const isDisabled = !!disabled || status === 'disabled' || status === 'loading';
    const currentColour = watch(name);

    const handleMouseOver = () => {
        if (isDisabled) {
            return;
        }
        setShowPicker(true);
    };

    const handleOnTileClick = (selectedColour: Colour) => {
        setValue(name, { name: selectedColour.name, hex: selectedColour.hex });
    };

    return (
        <InputContainer>
            <input type="hidden" disabled={isDisabled} {...register(name, { ...buildValidators(validators) })} />
            <div onMouseEnter={handleMouseOver} onMouseLeave={() => setShowPicker(false)}>
                <StyledColourPickerSelect disabled={isDisabled}>
                    <StyledColourPickerSelectColourPreview selectedColour={currentColour && currentColour.hex} />
                    <StyledColourPickerPlaceHolder selectedColour={currentColour && currentColour.name}>
                        {currentColour && currentColour.name ? currentColour.name : 'Select a colour...'}
                    </StyledColourPickerPlaceHolder>
                </StyledColourPickerSelect>
                <StyledColourPickerHolder showPicker={showPicker}>
                    {channelColours.map(colour => {
                        return (
                            <StyledColourPickerHolderTile
                                data-testid={`colour-picker-tile-${colour.name.toLowerCase()}`}
                                key={colour.hex}
                                colour={colour.hex}
                                onClick={() => handleOnTileClick(colour)}
                                data-name={colour.name}
                            />
                        );
                    })}
                </StyledColourPickerHolder>
            </div>

            {errors[name] && <InputErrorMessage error={errors[name] as FieldError} />}
        </InputContainer>
    );
};

export default InputColourPicker;
