/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import moment from 'moment';
import { TechSEODashboardMenuContext } from '../tech-seo-context/tech-seo-dashboard-menu-context';

import BeforeSectionPageTypeDropdown from '../components/sections/before-section/before-section-page-type-dropdown';
import WidgetMenuTable from '../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';
import WidgetTable from '../../section-dashboard/widgets/widget/widget-table';
import WidgetBigNumber from '../../section-dashboard/widgets/widget/widget-big-number';
import WidgetBarGraph from '../../section-dashboard/widgets/widget/widget-bar-graph';
import WidgetPieChart from '../../section-dashboard/widgets/widget/widget-pie-chart';

// Fields & Resources
import * as fields from '../../configurations/fields';
import * as resources from '../../configurations/resources';

// Hooks
import useResource from '../../section-dashboard/hooks/widget/use-resource';
import useResourceTable from '../../section-dashboard/hooks/widget/table/use-resource-table';
import useResourceBarGraph from '../../section-dashboard/hooks/widget/use-resource-bar-graph';
import useResourceBigNumber from '../../section-dashboard/hooks/widget/big-number/use-resource-big-number';
import useResourceBigNumberSeparateTotalsGraph from '../../section-dashboard/hooks/widget/big-number/use-resource-big-number-separate-totals-graph';
import useResourcePieChart from '../../section-dashboard/hooks/widget/pie/use-resource-pie-chart';
import usePieDataSetSeriesColour from '../../section-dashboard/hooks/widget/pie/use-pie-data-set-series-colour';
import useRequestTable from '../../section-dashboard/hooks/request/use-request-table';

// Types
import { seriesColourBooleanFalse, seriesColourBooleanTrue } from '../../section-dashboard/types';

export const config = {
    title: 'Tech SEO Dashboard',
    description:
        'Explore tech SEO data to optimise your site pages. Websites are updated regularly, with change may come revisions that may affect your SEO rankings and user experiences.',
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
        },
        datePicker: {
            customStartDate: () => {
                let startDate = moment().subtract(1, 'months').startOf('month');

                if (moment().date() > 14) {
                    startDate = moment().startOf('month');
                }

                return startDate;
            },
            customEndDate: () => {
                let endDate = moment().subtract(1, 'months').endOf('month');

                if (moment().date() > 14) {
                    endDate = moment();
                }

                return endDate;
            },
        },
    },
    layouts: {
        xs: {
            sections: [
                {
                    title: 'Page Speed & Core Web Vitals',
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    grid: `
                    'g'
                    'q'
                    'y'
                    'z'
                    'r'
                    'r'
                    'a'
                    'a'
                    'f'
                    'f'
                    's'
                    's'
                    't'
                    'u'
                    'i'
                    'i'
                    'c'
                    'c'
                    'c'
                `,
                },
                {
                    title: 'Page Elements',
                    grid: `
                    'h'
                    'h'
                    'n'
                    'n'
                    'p'
                    'e'
                    'ee'
                    'ff'
                    'gg'
                    'jj'
                    'jj'
                    'j'
                    'k'
                    'l'
                    'm'
                    'o'
                    'o'
                    'o'
                    'w'
                    'w'
                    'x'
                    'x'
                    'aa'
                    'aa'
                    'bb'
                    'bb'
                    'cc'
                    'cc'
                    'dd'
                    'dd'
                `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    title: 'Page Speed & Core Web Vitals',
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    grid: `
                    'g q'
                    'y z'
                    'r r'
                    'r r'
                    'a a'
                    'a a'
                    'f f'
                    's s'
                    'u u'
                    't t'
                    'i i'
                    'i i'
                    'c c'
                    'c c'
                    'c c'
                    'c c'
                `,
                },
                {
                    title: 'Page Elements',
                    grid: `
                    'h h'
                    'h h'
                    'n n'
                    'n n'
                    'p p'
                    'p p'
                    'e e'
                    'e e'
                    'ee ee'
                    'ee ee'
                    'ff ff'
                    'ff ff'
                    'gg gg'
                    'gg gg'
                    'jj jj'
                    'jj jj'
                    'j k'
                    'm l'
                    'o o'
                    'o o'
                    'w w'
                    'w w'
                    'x x'
                    'x x'
                    'aa aa'
                    'aa aa'
                    'bb bb'
                    'bb bb'
                    'cc cc'
                    'cc cc'
                    'dd dd'
                    'dd dd'
                `,
                },
            ],
        },
        md: {
            sections: [
                {
                    title: 'Page Speed & Core Web Vitals',
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    grid: `
                    'i i g y'
                    'i i q z'
                    'r r s t'
                    'r r s t'
                    'r r s t'
                    'r r f u'
                    'a a a a'
                    'a a a a'
                    'c c c c'
                    'c c c c'
                `,
                },
                {
                    title: 'Page Elements',
                    grid: `
                    'n n p p'
                    'n n p p'
                    'e e h h'
                    'e e h h'
                    'ee ee ff ff'
                    'ee ee ff ff'
                    'gg gg jj jj'
                    'gg gg jj jj'
                    'j j k k'
                    'm m l l'
                    'w w w w'
                    'w w w w'
                    'x x x x'
                    'x x x x'
                    'o o o o'
                    'o o o o'
                    'aa aa bb bb'
                    'aa aa bb bb'
                    'cc cc dd dd'
                    'cc cc dd dd'
                `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    title: 'Page Speed & Core Web Vitals',
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    grid: `
                    'g g y y z q'
                    'i i i a a a'
                    'i i i a a a'
                    'i i i a a a'
                    'r r r r u s'
                    'r r r r t f'
                    'c c c c c c'
                    'c c c c c c'
                `,
                },
                {
                    title: 'Page Elements',
                    grid: `
                    'p p p e e e'
                    'p p p e e e'
                    'n n n h h h'
                    'n n n h h h'
                    'ee ee ee ff ff ff'
                    'ee ee ee ff ff ff'
                    'gg gg gg jj jj jj'
                    'gg gg gg jj jj jj'
                    'j j o o o o'
                    'k k o o o o'
                    'l m o o o o'
                    'w w w x x x'
                    'w w w x x x'
                    'w w w x x x'
                    'aa aa aa bb bb bb'
                    'aa aa aa bb bb bb'
                    'cc cc cc dd dd dd'
                    'cc cc cc dd dd dd'
                `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    title: 'Page Speed',
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    grid: `
                    'g g q q y y z z'
                    'i i i i i i i i'
                    'i i i i i i i i'
                    'i i i i i i i i'              
                    'c c c c a a a a'
                    'c c c c a a a a'
                    'c c c c a a a a'
                    `,
                },
                {
                    title: 'Core Web Vitals',
                    grid: `
                    'u u t t r r r r'
                    'u u t t r r r r'
                    's s f f r r r r'
                    's s f f r r r r'
                    `,
                },
                {
                    title: 'Page Elements',
                    grid: `
                    'n n h h p p e e'
                    'n n h h p p e e'
                    'ee ee ff ff gg gg jj jj'
                    'ee ee ff ff gg gg jj jj'
                    'j j o o o o o o'
                    'k k o o o o o o'
                    'l m o o o o o o'
                    'w w w w aa aa bb bb'
                    'w w w w aa aa bb bb'
                    'w w w w cc cc dd dd'
                    'w w w w cc cc dd dd'
                    'x x x x x x x x'
                    'x x x x x x x x'
                    'x x x x x x x x'
                    `,
                },
            ],
        },
    },
    components: () => {
        // Menu Context
        const { currentPageTypeField } = useContext(TechSEODashboardMenuContext);

        // CORE WEB VITALS RESOURCES
        const pageSpeedLCPResource = useResource({
            resource: resources.REPORT_PAGE_SPEED_SECTION_DASHBOARD,
            request: {
                groupBy: fields.LCP_STATUS,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const pageSpeedFIDResource = useResource({
            resource: resources.REPORT_PAGE_SPEED_SECTION_DASHBOARD,
            request: {
                groupBy: fields.FID_STATUS,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const pageSpeedCLSResource = useResource({
            resource: resources.REPORT_PAGE_SPEED_SECTION_DASHBOARD,
            request: {
                groupBy: fields.CLS_STATUS,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const pageSpeedINPResource = useResource({
            resource: resources.REPORT_PAGE_SPEED_SECTION_DASHBOARD,
            request: {
                groupBy: fields.INP_STATUS,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        // PAGE SPEED RESOURCES

        const pageSpeedTableResource = useResource({
            resource: resources.REPORT_PAGE_SPEED,
            request: useRequestTable({
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.UNUSED_JS_SCORE, orderByDirection: 'desc' }],
                fields: [
                    currentPageTypeField,
                    fields.UNUSED_JS_SCORE,
                    fields.LONG_TASKS,
                    fields.TOTAL_TASKS,
                    fields.TOTAL_TASKS_TIME,
                    fields.PAGE_SIZE,
                    fields.CACHED_PAGE_SIZE,
                ],
            }),
        });

        const pageSpeedTotals = useResource({
            resource: resources.REPORT_PAGE_SPEED,
            request: {
                totals: true,
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.UPDATED_DATE, orderByDirection: 'asc' }],
            },
        });
        const pageSpeedUnusedJSGraphSeries = useResource({
            resource: resources.REPORT_PAGE_SPEED,
            request: {
                groupBy: currentPageTypeField,
                graphGroupBy: [fields.UPDATED_DATE],
                graphMetrics: [fields.UNUSED_JS_SCORE],
                isGraph: true,
                orderBy: [{ field: fields.UPDATED_DATE, orderByDirection: 'asc' }],
            },
        });
        const pageSpeedLongTasksGraphSeries = useResource({
            resource: resources.REPORT_PAGE_SPEED,
            request: {
                groupBy: currentPageTypeField,
                graphGroupBy: [fields.UPDATED_DATE],
                graphMetrics: [fields.LONG_TASKS],
                isGraph: true,
                orderBy: [{ field: fields.UPDATED_DATE, orderByDirection: 'asc' }],
            },
        });
        const pageSpeedTotalTasksGraphSeries = useResource({
            resource: resources.REPORT_PAGE_SPEED,
            request: {
                groupBy: currentPageTypeField,
                graphGroupBy: [fields.UPDATED_DATE],
                graphMetrics: [fields.TOTAL_TASKS],
                isGraph: true,
                orderBy: [{ field: fields.UPDATED_DATE, orderByDirection: 'asc' }],
            },
        });
        const pageSpeedTotalTasksTimeGraphSeries = useResource({
            resource: resources.REPORT_PAGE_SPEED,
            request: {
                groupBy: currentPageTypeField,
                graphGroupBy: [fields.UPDATED_DATE],
                graphMetrics: [fields.TOTAL_TASKS_TIME],
                isGraph: true,
                orderBy: [{ field: fields.UPDATED_DATE, orderByDirection: 'asc' }],
            },
        });

        const pageSpeedSlowestPages = useResource({
            resource: resources.REPORT_PAGE_SPEED,
            request: useRequestTable({
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.FID, orderByDirection: 'desc' }],
                fields: [currentPageTypeField, fields.FID, fields.FCP, fields.LCP, fields.TIME_INTERACTIVE],
            }),
        });

        const pageSpeedUnusedJSBar = useResource({
            resource: resources.REPORT_PAGE_SPEED,
            request: {
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.UNUSED_JS_SCORE, orderByDirection: 'desc' }],
                limit: 20,
            },
        });

        const pageSpeedLargestPages = useResource({
            resource: resources.REPORT_PAGE_SPEED,
            request: {
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.PAGE_SIZE, orderByDirection: 'desc' }],
                limit: 20,
            },
        });

        // PAGE ELEMENTS RESOURCES

        // PAGE TAGS RESOURCES

        const pageTagsDataSeries = useResource({
            resource: resources.REPORT_PAGE_TAGS_SECTION_DASHBOARD,
            request: {
                groupBy: fields.UPDATED_DATE,
                orderBy: [{ field: fields.UPDATED_DATE, orderByDirection: 'asc' }],
            },
        });

        const pageTagsLongestTitle = useResource({
            resource: resources.REPORT_PAGE_TAGS_SECTION_DASHBOARD,
            request: useRequestTable({
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.TITLE_LENGTH, orderByDirection: 'desc' }],
                fields: [
                    currentPageTypeField,
                    fields.TITLE_LENGTH,
                    fields.DESCRIPTION_LENGTH,
                    fields.H1_LENGTH,
                    fields.H1_DUPLICATE,
                    fields.H2_LENGTH,
                ],
            }),
        });

        // PAGE SCHEMA RESOURCES

        const pageTagsPageSchema = useResource({
            resource: resources.REPORT_PAGE_TAGS_SECTION_DASHBOARD,
            request: useRequestTable({
                groupBy: currentPageTypeField,
                orderBy: [{ field: currentPageTypeField, orderByDirection: 'desc' }],
                fields: [currentPageTypeField, fields.JSON, fields.CONTAINS_SCHEMA, fields.MICRODATA, fields.RDFA],
            }),
        });

        const pageTagsPageSchemaJson = useResource({
            resource: resources.REPORT_PAGE_TAGS,
            request: {
                groupBy: fields.JSON,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const pageTagsPageSchemaRDFA = useResource({
            resource: resources.REPORT_PAGE_TAGS,
            request: {
                groupBy: fields.RDFA,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const pageTagsPageSchemaMicroData = useResource({
            resource: resources.REPORT_PAGE_TAGS,
            request: {
                groupBy: fields.MICRODATA,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const pageTagsPageSchemaContainsSchema = useResource({
            resource: resources.REPORT_PAGE_TAGS,
            request: {
                groupBy: fields.CONTAINS_SCHEMA,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        // PAGE IMAGES RESOURCE

        const pageImagesDataSeries = useResource({
            resource: resources.REPORT_PAGE_IMAGES,
            request: useRequestTable({
                groupBy: fields.IMAGE_URL,
                orderBy: [{ field: fields.IMAGE_URL, orderByDirection: 'desc' }],
                fields: [fields.PATH, fields.IMAGE_URL, fields.ALT_TEXT, fields.FILE_TYPE],
            }),
        });

        // TECH SEO RESOURCES

        const techSEOStatusCode = useResource({
            resource: resources.REPORT_TECH_SEO_SECTION_DASHBOARD,
            request: {
                groupBy: fields.STATUS_CODE,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const SEOGDGoogleConsoleIndexed = useResource({
            resource: resources.REPORT_SEOGD_GOOGLE_CONSOLE_INDEX_STATUS,
            request: {
                groupBy: fields.INDEXED_VERDICT,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const SEOGDGoogleConsoleIndexedCoverageState = useResource({
            resource: resources.REPORT_SEOGD_GOOGLE_CONSOLE_INDEX_STATUS,
            request: {
                groupBy: fields.COVERAGE_STATE,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const SEOGDGoogleConsoleIndexedRobotsTextState = useResource({
            resource: resources.REPORT_SEOGD_GOOGLE_CONSOLE_INDEX_STATUS,
            request: {
                groupBy: fields.ROBOTS_TEXT_STATE,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const SEOGDGoogleConsoleIndexedPageFetchState = useResource({
            resource: resources.REPORT_SEOGD_GOOGLE_CONSOLE_INDEX_STATUS,
            request: {
                groupBy: fields.PAGE_FETCH_STATE,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const SEOGDGoogleConsoleIndexedMobileVerdict = useResource({
            resource: resources.REPORT_SEOGD_GOOGLE_CONSOLE_INDEX_STATUS,
            request: {
                groupBy: fields.MOBILE_VERDICT,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        const techSEOSelfReference = useResource({
            resource: resources.REPORT_TECH_SEO_SECTION_DASHBOARD,
            request: {
                groupBy: fields.SELF_REFERENCE,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });
        const techSEOMetaRobots = useResource({
            resource: resources.REPORT_TECH_SEO_SECTION_DASHBOARD,
            request: {
                groupBy: fields.META_ROBOTS,
                orderBy: [{ field: fields.COUNT, orderByDirection: 'desc' }],
                chartField: fields.COUNT,
            },
        });

        // TABLES - useResourceTable

        const pageSpeedTable = useResourceTable({
            resourceData: pageSpeedTableResource,
        });

        const pageSpeedSlowestPagesTable = useResourceTable({
            resourceData: pageSpeedSlowestPages,
        });

        const pageTagsSchemaTable = useResourceTable({
            resourceData: pageTagsPageSchema,
        });

        const pageImagesTable = useResourceTable({
            resourceData: pageImagesDataSeries,
        });

        const pageTagsLongestTitleTable = useResourceTable({
            resourceData: pageTagsLongestTitle,
        });

        // BAR GRAPH - useResourceBarGraph
        const pageSpeedUnusedJSBarGraph = useResourceBarGraph({
            category: currentPageTypeField,
            metric: fields.UNUSED_JS_SCORE,
            resourceData: pageSpeedUnusedJSBar,
        });

        const pageSpeedPageSizeBarGraph = useResourceBarGraph({
            category: currentPageTypeField,
            metric: fields.PAGE_SIZE,
            resourceData: pageSpeedLargestPages,
        });

        // BIG NUMBER - useResourceBigNumber

        const pageSpeedMostUnusedJSCount = useResourceBigNumberSeparateTotalsGraph({
            totalsResourceData: pageSpeedTotals,
            graphResourceData: pageSpeedUnusedJSGraphSeries,
            field: fields.UNUSED_JS_SCORE,
        });
        const pageSpeedLongTasksCount = useResourceBigNumberSeparateTotalsGraph({
            totalsResourceData: pageSpeedTotals,
            graphResourceData: pageSpeedLongTasksGraphSeries,
            field: fields.LONG_TASKS,
        });
        const pageSpeedTotalTasksCount = useResourceBigNumberSeparateTotalsGraph({
            totalsResourceData: pageSpeedTotals,
            graphResourceData: pageSpeedTotalTasksGraphSeries,
            field: fields.TOTAL_TASKS,
        });
        const pageSpeedTotalTasksTimeCount = useResourceBigNumberSeparateTotalsGraph({
            totalsResourceData: pageSpeedTotals,
            graphResourceData: pageSpeedTotalTasksTimeGraphSeries,
            field: fields.TOTAL_TASKS_TIME,
        });

        const pageTagsTitleLengthAvg = useResourceBigNumber({
            resourceData: pageTagsDataSeries,
            field: fields.TITLE_LENGTH,
        });
        const pageTagsDescriptionLengthAvg = useResourceBigNumber({
            resourceData: pageTagsDataSeries,
            field: fields.DESCRIPTION_LENGTH,
        });
        const pageTagsH1LengthAvg = useResourceBigNumber({
            resourceData: pageTagsDataSeries,
            field: fields.H1_LENGTH,
        });
        const pageTagsH2LengthAvg = useResourceBigNumber({
            resourceData: pageTagsDataSeries,
            field: fields.H2_LENGTH,
        });

        // Donut Pie Chart - useResourcePieChart
        let pageSpeedLCPPie = useResourcePieChart({
            resourceData: pageSpeedLCPResource,
            dimension: fields.LCP_STATUS,
        });

        let pageSpeedFIDPie = useResourcePieChart({
            resourceData: pageSpeedFIDResource,
            dimension: fields.FID_STATUS,
        });

        let pageSpeedCLSPie = useResourcePieChart({
            resourceData: pageSpeedCLSResource,
            dimension: fields.CLS_STATUS,
        });

        let pageSpeedINPPie = useResourcePieChart({
            resourceData: pageSpeedINPResource,
            dimension: fields.INP_STATUS,
        });

        let pageTagsSchemaJSONPie = useResourcePieChart({
            resourceData: pageTagsPageSchemaJson,
            dimension: fields.JSON,
        });

        let pageTagsSchemaRDFAPie = useResourcePieChart({
            resourceData: pageTagsPageSchemaRDFA,
            dimension: fields.RDFA,
        });
        let pageTagsSchemaMicroDataPie = useResourcePieChart({
            resourceData: pageTagsPageSchemaMicroData,
            dimension: fields.MICRODATA,
        });
        let pageTagsSchemaContainsSchemaPie = useResourcePieChart({
            resourceData: pageTagsPageSchemaContainsSchema,
            dimension: fields.CONTAINS_SCHEMA,
        });
        const techSEOStatusCodePie = useResourcePieChart({
            resourceData: techSEOStatusCode,
            dimension: fields.STATUS_CODE,
        });
        const SEOGDGoogleConsoleIndexedPie = useResourcePieChart({
            resourceData: SEOGDGoogleConsoleIndexed,
            dimension: fields.INDEXED_VERDICT,
        });

        let techSEOSelfReferencePie = useResourcePieChart({
            resourceData: techSEOSelfReference,
            dimension: fields.SELF_REFERENCE,
        });
        const techSEOMetaRobotsPie = useResourcePieChart({
            resourceData: techSEOMetaRobots,
            dimension: fields.META_ROBOTS,
        });
        const techSEORobotsTextStatePie = useResourcePieChart({
            resourceData: SEOGDGoogleConsoleIndexedRobotsTextState,
            dimension: fields.ROBOTS_TEXT_STATE,
        });
        const techSEOMobileVerdictPie = useResourcePieChart({
            resourceData: SEOGDGoogleConsoleIndexedMobileVerdict,
            dimension: fields.MOBILE_VERDICT,
        });
        const techSEOCoverageStatePie = useResourcePieChart({
            dimension: fields.COVERAGE_STATE,
            resourceData: SEOGDGoogleConsoleIndexedCoverageState,
        });

        const techSEOPageFetchStatePie = useResourcePieChart({
            dimension: fields.PAGE_FETCH_STATE,
            resourceData: SEOGDGoogleConsoleIndexedPageFetchState,
        });

        // usePieDataSetSeriesColour
        pageTagsSchemaJSONPie = usePieDataSetSeriesColour({
            resourceData: pageTagsSchemaJSONPie,
            ...seriesColourBooleanTrue,
        });
        pageTagsSchemaJSONPie = usePieDataSetSeriesColour({
            resourceData: pageTagsSchemaJSONPie,
            ...seriesColourBooleanFalse,
        });

        pageTagsSchemaRDFAPie = usePieDataSetSeriesColour({
            resourceData: pageTagsSchemaRDFAPie,
            ...seriesColourBooleanTrue,
        });
        pageTagsSchemaRDFAPie = usePieDataSetSeriesColour({
            resourceData: pageTagsSchemaRDFAPie,
            ...seriesColourBooleanFalse,
        });
        pageTagsSchemaMicroDataPie = usePieDataSetSeriesColour({
            resourceData: pageTagsSchemaMicroDataPie,
            ...seriesColourBooleanTrue,
        });
        pageTagsSchemaMicroDataPie = usePieDataSetSeriesColour({
            resourceData: pageTagsSchemaMicroDataPie,
            ...seriesColourBooleanFalse,
        });
        pageTagsSchemaContainsSchemaPie = usePieDataSetSeriesColour({
            resourceData: pageTagsSchemaContainsSchemaPie,
            ...seriesColourBooleanTrue,
        });
        pageTagsSchemaContainsSchemaPie = usePieDataSetSeriesColour({
            resourceData: pageTagsSchemaContainsSchemaPie,
            ...seriesColourBooleanFalse,
        });
        techSEOSelfReferencePie = usePieDataSetSeriesColour({
            resourceData: techSEOSelfReferencePie,
            ...seriesColourBooleanTrue,
        });
        techSEOSelfReferencePie = usePieDataSetSeriesColour({
            resourceData: techSEOSelfReferencePie,
            ...seriesColourBooleanFalse,
        });

        // Widget Links
        const links = {
            pageSpeed: '/reports/channels/seo/page-speed',
            techSEO: '/reports/channels/seo/tech-seo',
            pageTags: '/reports/channels/seo/page-tags',
        };

        return {
            g: (
                <WidgetBigNumber
                    title={'Page Speed: Unused JS'}
                    data={pageSpeedMostUnusedJSCount}
                    href={links.pageSpeed}
                />
            ),
            q: (
                <WidgetBigNumber
                    title={'Page Speed: Long Tasks'}
                    data={pageSpeedLongTasksCount}
                    href={links.pageSpeed}
                />
            ),
            y: (
                <WidgetBigNumber
                    title={'Page Speed: Total Tasks'}
                    data={pageSpeedTotalTasksCount}
                    href={links.pageSpeed}
                />
            ),
            z: (
                <WidgetBigNumber
                    title={'Page Speed: Total Task Time'}
                    data={pageSpeedTotalTasksTimeCount}
                    href={links.pageSpeed}
                />
            ),
            i: (
                <WidgetTable
                    title={'Page Speed'}
                    data={pageSpeedTable}
                    href={links.pageSpeed}
                    menu={<WidgetMenuTable data={pageSpeedTable} />}
                />
            ),
            a: (
                <WidgetBarGraph
                    title={'Page Speed: Unused JavaScript'}
                    data={pageSpeedUnusedJSBarGraph}
                    href={links.pageSpeed}
                />
            ),
            c: <WidgetBarGraph title={'Page Speed: Largest Pages (bytes)'} data={pageSpeedPageSizeBarGraph} />,
            e: (
                <WidgetPieChart
                    title={'Page Elements: Canonical Self Reference'}
                    data={techSEOSelfReferencePie}
                    href={links.techSEO}
                    display="semidonut"
                />
            ),
            h: (
                <WidgetPieChart
                    title={'Page Elements: Status Codes'}
                    data={techSEOStatusCodePie}
                    href={links.techSEO}
                    display="semidonut"
                />
            ),
            j: (
                <WidgetBigNumber
                    title={'Page Elements: Average Title Length'}
                    data={pageTagsTitleLengthAvg}
                    href={links.pageTags}
                />
            ),
            k: (
                <WidgetBigNumber
                    title={'Page Elements: Average Description Length'}
                    data={pageTagsDescriptionLengthAvg}
                    href={links.pageTags}
                />
            ),
            l: (
                <WidgetBigNumber
                    title={'Page Elements: Average H1 Length'}
                    data={pageTagsH1LengthAvg}
                    href={links.pageTags}
                />
            ),
            m: (
                <WidgetBigNumber
                    title={'Page Elements: Average H2 Length'}
                    data={pageTagsH2LengthAvg}
                    href={links.pageTags}
                />
            ),
            n: (
                <WidgetPieChart
                    title={'Page Elements: Meta Robots'}
                    data={techSEOMetaRobotsPie}
                    href={links.techSEO}
                    display="donut"
                />
            ),
            o: (
                <WidgetTable
                    title={'Page Elements: Page Tags'}
                    data={pageTagsLongestTitleTable}
                    href={links.pageTags}
                    menu={<WidgetMenuTable data={pageTagsLongestTitleTable} />}
                />
            ),
            p: (
                <WidgetPieChart
                    title={'Page Elements: Indexed Pages'}
                    data={SEOGDGoogleConsoleIndexedPie}
                    href={links.techSEO}
                    display="semidonut"
                />
            ),
            r: (
                <WidgetTable
                    title={'Core Web Vitals'}
                    data={pageSpeedSlowestPagesTable}
                    href={links.pageSpeed}
                    menu={<WidgetMenuTable data={pageSpeedSlowestPagesTable} />}
                />
            ),
            t: (
                <WidgetPieChart
                    title={'Core Web Vitals: LCP'}
                    data={pageSpeedLCPPie}
                    href={links.pageSpeed}
                    display="semidonut"
                />
            ),
            s: (
                <WidgetPieChart
                    title={'Core Web Vitals: FID'}
                    data={pageSpeedFIDPie}
                    href={links.pageSpeed}
                    display="semidonut"
                />
            ),
            u: (
                <WidgetPieChart
                    title={'Core Web Vitals: CLS'}
                    data={pageSpeedCLSPie}
                    href={links.pageSpeed}
                    display="semidonut"
                />
            ),
            f: (
                <WidgetPieChart
                    title={'Core Web Vitals: INP'}
                    data={pageSpeedINPPie}
                    href={links.pageSpeed}
                    display="semidonut"
                />
            ),
            x: (
                <WidgetTable
                    title={'Page Elements: Page Images'}
                    data={pageImagesTable}
                    menu={<WidgetMenuTable data={pageImagesTable} />}
                />
            ),
            w: (
                <WidgetTable
                    title={'Page Elements: Page Schema'}
                    data={pageTagsSchemaTable}
                    href={links.pageTags}
                    menu={<WidgetMenuTable data={pageTagsSchemaTable} />}
                />
            ),
            aa: (
                <WidgetPieChart
                    title={'Page Elements: Schema JSON'}
                    data={pageTagsSchemaJSONPie}
                    href={links.pageTags}
                    display="donut"
                />
            ),
            bb: (
                <WidgetPieChart
                    title={'Page Elements: Schema RDFA'}
                    data={pageTagsSchemaRDFAPie}
                    href={links.pageTags}
                    display="donut"
                />
            ),
            cc: (
                <WidgetPieChart
                    title={'Page Elements: Schema MicroData'}
                    data={pageTagsSchemaMicroDataPie}
                    href={links.pageTags}
                    display="donut"
                />
            ),
            dd: (
                <WidgetPieChart
                    title={'Page Elements: Schema Contains Schema'}
                    data={pageTagsSchemaContainsSchemaPie}
                    href={links.pageTags}
                    display="donut"
                />
            ),
            ee: (
                <WidgetPieChart
                    title={'Page Elements: Coverage State'}
                    data={techSEOCoverageStatePie}
                    href={links.techSEO}
                    display="donut"
                />
            ),
            ff: (
                <WidgetPieChart
                    title={'Page Elements: Robots Text State'}
                    data={techSEORobotsTextStatePie}
                    href={links.pageSpeed}
                    display="donut"
                />
            ),
            gg: (
                <WidgetPieChart
                    title={'Page Elements: Crawlability'}
                    data={techSEOPageFetchStatePie}
                    href={links.techSEO}
                    display="semidonut"
                />
            ),
            jj: (
                <WidgetPieChart
                    title={'Page Elements: Mobile Verdict'}
                    data={techSEOMobileVerdictPie}
                    href={links.pageSpeed}
                    display="semidonut"
                />
            ),
        };
    },
};
