import React, { Component } from 'react';
import IconSpinner from './icons/spinner';

class LoadingSpindle extends Component {
    render() {
        return (
            <div className={`loading-spindle ${this.props.className}`}>
                <div className="icon icon-spinner">
                    <IconSpinner />
                </div>
            </div>
        );
    }
}

export default LoadingSpindle;
