import React from 'react';
import styled from 'styled-components';

// Component
import TablePaginationGoTo from './table-pagination-goto';

// Styles
const StyledPaginationList = styled.div<{ isCurrent: boolean; disabled: boolean }>`
    display: inline-block;
    font-size: 13px;
    margin: 0 2px;
    padding: 0 10px;
    user-select: none;
    text-align: center;
    border: 1px solid ${props => (props.isCurrent ? props.theme.colours.offBlack : props.theme.colours.borderGrey)};
    background-color: ${props =>
        props.isCurrent
            ? props.disabled
                ? props.theme.colours.midGrey
                : props.theme.colours.offBlack
            : props.theme.colours.borderGrey};
    cursor: ${props => (props.disabled ? `not-allowed !important` : `pointer`)};
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    min-width: 38px;
    border-radius: 3px;
    line-height: 36px;
    color: ${props => props.isCurrent && props.theme.colours.white};

    &:hover {
        background-color: ${props =>
            props.isCurrent
                ? props.disabled
                    ? props.theme.colours.midGrey
                    : props.theme.colours.offBlack
                : props.disabled
                ? props.theme.colours.borderGrey
                : props.theme.colours.midGrey};
    }
`;

// Types
type TablePaginationPageListItemProps = {
    isCurrent?: boolean;
    pageNumber?: number;
    ownerCallback: (number: number) => void;
    disabled: boolean;
};

const TablePaginationPageListItem = ({
    isCurrent,
    pageNumber,
    ownerCallback,
    disabled,
}: TablePaginationPageListItemProps) => {
    const setCurrentPage = () => {
        ownerCallback(pageNumber!);
    };

    const renderPaginationGoTo = () => {
        if (pageNumber) {
            return null;
        } else {
            return <TablePaginationGoTo />;
        }
    };

    const handleClick = () => {
        if (disabled !== undefined && disabled) return;

        if (pageNumber) {
            setCurrentPage();
        }
    };

    return (
        <li>
            {renderPaginationGoTo()}
            <StyledPaginationList onClick={handleClick} isCurrent={isCurrent!} disabled={disabled}>
                {pageNumber || '...'}
            </StyledPaginationList>
        </li>
    );
};

export default TablePaginationPageListItem;
