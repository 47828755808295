// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Core Dependencies
import Axios from 'axios';
import { generateUrl, generateUrlDetail, generatePath } from '../../helpers/request-builder';
import { errorHandling } from '../../helpers/request-error-handling';
import { isValidUrl } from '../../helpers/valid-url';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { removeModal, setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import WarningMessage from '../../components/warning-message';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WidgetAccordion from '../../widgets/accordion';
import TablePaginationPageList from '../../components/tables/components/pagination/table-pagination-page-list';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import InputSearch from '../../components/inputs/input-search';

const StyledTablePages = styled.div`
    display: flex;
`;

const StyledSearchOptionsContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-direction: row;
`;

class LayoutModalManageSEOKeywords extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageError: false,
            accordionVisible: true,
            accordionOpen: true,
            saveButtonDisabled: false,
            closeButtonDisabled: false,

            closeButtonState: 'close',
            createKeywordsDisabled: false,
            keywords: [],
            mapped_path: '',
            keywordSearchTerm: '',
            marketSearchTerm: '',
            searchChecked: false,
            disableKeywordSelect: false,
            editButtonDisabled: true,
            deleteButtonDisabled: true,
            saveChangesButtonDisabled: true,
            accordionEditOpen: false,
            rowLimit: 20,
            page: 1,
            total_page: 0,
            total_count: 0,
            onEdit: false,
            'selected-events': [],

            marketList: [],
            productList: [],

            'seo-keyword__id': '',
            'seo-keyword__keyword_id': [],
            'seo-keyword__keyword': [],
            'seo-keyword__market': {},
            'seo-keyword__market_id': '',
            'seo-keyword__market_keyword_id': '',
            'seo-keyword__keyword_modifier': [],
            'seo-keyword__locale': [],
            'seo-keyword__product': '',
            'seo-keyword__product_id': '',
            'seo-keyword__translation': [],
            'seo-keyword__parent_category': [],
            'seo-keyword__sub_category': [],
            'seo-keyword__child_category': [],
            'seo-keyword__mapped_path': '',
            keywordActive: false,
            selectedEventBeforeEdit: [],

            errorMessages: {
                market: '',
                product: '',
                mappedPage: '',
                other: '',
            },
        };

        this.timer = null;

        this.fileHeaders = [
            'active',
            'child_category',
            'keyword',
            'keyword_modifier',
            'locale',
            'mapped_page',
            'market',
            'parent_category',
            'product',
            'sub_category',
            'translation',
        ];
    }

    componentDidMount() {
        this.requestKeywordList();
        this.fetchProductList();
        this.fetchMarketList();
    }

    fetchProductList = () => {
        Axios({
            method: 'GET',
            url: generateUrl('config', 'dash-product', [{ key: 'limit', value: 0 }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const allProducts = response.data.objects.map(data => {
                    return {
                        label: data.name,
                        value: data.id,
                        ...data,
                    };
                });
                this.setState({
                    productList: allProducts,
                });
            })
            .catch(error => {
                errorHandling(error);
            });
    };

    fetchMarketList = () => {
        Axios({
            method: 'GET',
            url: generateUrl('config', 'seogd-market', [{ key: 'limit', value: 0 }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const allMarkets = response.data.objects.map(data => {
                    return {
                        label: data.alpha2,
                        value: data.id,
                        name: data.alpha2,
                        ...data,
                    };
                });
                this.setState({
                    marketList: allMarkets,
                });
            })
            .catch(error => {
                errorHandling(error);
            });
    };

    requestKeywordList = page => {
        this.setState({
            isLoading: true,
        });

        Axios({
            method: 'GET',
            url: generateUrl('config', 'kw-market-read', [
                {
                    key: 'keyword__keyword__icontains',
                    value: this.state.keywordSearchTerm,
                },
                {
                    key: 'market__alpha2__icontains',
                    value: this.state.marketSearchTerm,
                },
                {
                    key: 'limit',
                    value: this.state.rowLimit,
                },
                {
                    key: 'offset',
                    value: this.state.rowLimit * (page ? page - 1 : this.state.page - 1),
                },
                {
                    key: 'active',
                    value: 1,
                },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(keywordList => {
            const keywords = keywordList.data.objects.map(keyword => {
                return {
                    market_keyword_id: keyword.id,
                    ...keyword,
                };
            });

            this.setState({
                isLoading: false,
                keywords: keywords,
                total_page: Math.ceil(keywordList.data.meta.total_count / this.state.rowLimit),
                total_count: Math.ceil(keywordList.data.meta.total_count),
            });
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.keywordSearchTerm !== this.state.keywordSearchTerm ||
            prevState.marketSearchTerm !== this.state.marketSearchTerm
        ) {
            this.handleInputTime();
        }
    }

    onCreateKeywordClick = () => {
        this.props.setModal('AddKeywords', {});
    };

    onEditKeywordClick = () => {
        const errorMessages = {
            market: '',
            product: '',
            mappedPage: '',
            other: '',
        };

        this.setState({
            errorMessages,
        });

        const selectedMarketObject = this.state.marketList.filter(
            market => market.alpha2 === this.state['selected-events'][0].market
        )[0];
        const selectedProductObject = this.state.productList.filter(
            product => product.name === this.state['selected-events'][0].product
        );

        this.setState({
            selectedEventBeforeEdit: this.state['selected-events'][0],
            accordionEditOpen: true,
            onEdit: true,
            'seo-keyword__id': this.state['selected-events'][0].id,
            'seo-keyword__keyword_id': this.state['selected-events'][0].keyword_id,
            'seo-keyword__keyword': this.state['selected-events'][0].keyword,
            'seo-keyword__market': selectedMarketObject || {},
            'seo-keyword__market_keyword_id': this.state['selected-events'][0].market_keyword_id,
            'seo-keyword__market_id': this.state['selected-events'][0].market_id,
            'seo-keyword__keyword_modifier': this.state['selected-events'][0].keyword_modifier,
            'seo-keyword__locale': this.state['selected-events'][0].locale,
            'seo-keyword__product': selectedProductObject || '',
            'seo-keyword__product_id': this.state['selected-events'][0].product_id,
            'seo-keyword__translation': this.state['selected-events'][0].translation,
            'seo-keyword__parent_category': this.state['selected-events'][0].parent_category,
            'seo-keyword__kwr_keyword_category_id': this.state['selected-events'][0].kwr_keyword_category_id,
            'seo-keyword__sub_category': this.state['selected-events'][0].sub_category,
            'seo-keyword__child_category': this.state['selected-events'][0].child_category,
            keywordActive: this.state['selected-events'][0].active,
            'seo-keyword__mapped_path': this.state['selected-events'][0].path.path,
            'seo-keyword__mapped_path_id': this.state['selected-events'][0].path.id,
        });
    };

    onCancelClick = () => {
        this.setState({
            pageError: false,
            accordionVisible: true,
            accordionOpen: true,
            saveButtonDisabled: false,
            closeButtonDisabled: false,

            closeButtonState: 'close',
            createKeywordsDisabled: false,
            keywordSearchTerm: '',
            marketSearchTerm: '',
            searchChecked: false,
            disableKeywordSelect: false,
            editButtonDisabled: true,
            deleteButtonDisabled: true,
            saveChangesButtonDisabled: true,
            accordionEditOpen: false,
            onEdit: false,
            'selected-events': [],

            'seo-keyword__id': '',
            'seo-keyword__keyword_id': [],
            'seo-keyword__keyword': [],
            'seo-keyword__market': [],
            'seo-keyword__market_keyword_id': '',
            'seo-keyword__market_id': '',
            'seo-keyword__keyword_modifier': [],
            'seo-keyword__locale': [],
            'seo-keyword__product': '',
            'seo-keyword__product_id': '',
            'seo-keyword__translation': [],
            'seo-keyword__kwr_keyword_category_id': '',
            'seo-keyword__parent_category': [],
            'seo-keyword__sub_category': [],
            'seo-keyword__child_category': [],
            'seo-keyword__mapped_path': '',
            'seo-keyword__mapped_path_id': '',
            keywordActive: false,
        });
    };

    onCloseClick = () => {
        this.props.removeModal();
    };

    onSaveChangesClick = () => {
        if (this.state['seo-keyword__product_id'] === '') {
            const errorMessages = {
                ...this.state.errorMessages,
                product: 'Product is required',
            };
            this.setState({
                errorMessages,
            });

            return;
        }
        if (!isValidUrl(this.state['seo-keyword__mapped_path'])) {
            // checking if the user is passing empty string under mapped path before showing an error.
            if (this.state['seo-keyword__mapped_path'].trim().length > 0) {
                const errorMessages = {
                    ...this.state.errorMessages,
                    mappedPage: 'Invalid URL given. URLS should be in format https://www.example.com',
                };
                this.setState({
                    errorMessages,
                });
                return;
            }
        }

        const errorMessages = {
            ...this.state.errorMessages,
            product: '',
            mappedPage: '',
        };

        this.setState({
            errorMessages,
            saveChangesButtonDisabled: true,
        });

        this.sendRequest();
    };

    onKeywordSearchTermChange = event => {
        this.setState({
            keywordSearchTerm: event.target.value,
            page: 1,
            total_page: 0,
            total_count: 0,
        });
    };
    onMarketSearchTermChange = event => {
        this.setState({
            marketSearchTerm: event.target.value,
            page: 1,
            total_page: 0,
            total_count: 0,
        });
    };

    handleInputTime = () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.triggerUpdate();
        }, 1000);
    };

    triggerUpdate = () => {
        this.requestKeywordList();
    };

    setOwnerState = (key, value) => {
        if (key === 'page' && value === this.state.page) {
            return;
        }

        let obj = {};
        obj[key] = value;

        this.setState(obj);

        this.requestKeywordList(value);
    };

    sendRequest = () => {
        return new Promise(async (resolve, reject) => {
            const requests = [];

            if (this.state.keywords.active !== this.state.keywordActive) {
                requests.push(
                    Axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'seogd_keyword_market', this.state['seo-keyword__id']),
                        data: {
                            active: this.state.keywordActive,
                            market: generatePath('config', 'seogd-market', this.state['seo-keyword__market_id']),
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            }

            const keyWordCategoryPayload = {
                core_parent_cat: this.state['seo-keyword__parent_category'],
                core_sub_cat: this.state['seo-keyword__sub_category'],
                core_child_cat: this.state['seo-keyword__child_category'],
                product:
                    this.state['seo-keyword__product_id'] !== '' &&
                    generatePath('config', 'product', this.state['seo-keyword__product_id']),
                keyword_market: generatePath(
                    'config',
                    `seogd_keyword_market`,
                    this.state['seo-keyword__market_keyword_id']
                ),
            };

            const kwrCategoryId = this.state['seo-keyword__kwr_keyword_category_id'];
            requests.push(
                Axios({
                    method: kwrCategoryId === '' ? 'POST' : 'PATCH',
                    url:
                        kwrCategoryId === ''
                            ? generateUrl('config', 'keyword_category')
                            : generateUrlDetail('config', 'keyword_category', kwrCategoryId),

                    data: keyWordCategoryPayload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
            const createPathPayload = new FormData();
            createPathPayload.append('thisURL', this.state['seo-keyword__mapped_path']);

            // Check if the path is an empty string..
            if (this.state['seo-keyword__mapped_path'].trim().length === 0) {
                if (this.state.selectedEventBeforeEdit.path.path.trim().length > 0) {
                    // remove the path from that keyword/market pair if it is changed to empty string from a valid path
                    requests.push(
                        Axios({
                            method: 'DELETE',
                            url: generateUrlDetail(
                                'config',
                                'mapped_path',
                                this.state.selectedEventBeforeEdit.kwr_path_id
                            ),
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                }
            } else {
                requests.push(
                    Axios({
                        method: 'POST',
                        url: generateUrlDetail(this.props.account.token, 'path', 'create-path', [], false),
                        data: createPathPayload,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then(response => {
                        const pathId = response.data.path_id;
                        const host = response.data.host;
                        const isNew = response.data.created;

                        const keywordPathId = this.state['selected-events'][0].kwr_path_id;
                        Axios({
                            method: keywordPathId === '' ? 'POST' : 'PUT',
                            url:
                                keywordPathId === ''
                                    ? generateUrl('config', 'mapped_path')
                                    : generateUrlDetail('config', 'mapped_path', keywordPathId),
                            withCredentials: true,
                            data: {
                                domain: host,
                                new: isNew,
                                keyword_market: generatePath(
                                    'config',
                                    `seogd_keyword_market`,
                                    this.state['seo-keyword__market_keyword_id']
                                ),
                                path: generatePath('config', `path`, pathId),
                            },
                        });
                    })
                );
            }
            Axios.all(requests)
                .then(() => {
                    resolve('Request successful');
                    this.props.addNotification({
                        copy: 'SEO keywords updated successfully.',
                        type: NotificationMessageType.Success,
                    });
                    this.onCancelClick();
                    this.requestKeywordList();

                    this.setState({
                        saveChangesButtonDisabled: false,
                    });
                })
                .catch(error => {
                    reject('Error making a connection to API.');
                    const errorMessages = {
                        mappedPage:
                            error?.response?.data === 'Invalid URL given.'
                                ? 'Invalid URL given. URLS should be in format https://www.example.com'
                                : 'Something went wrong - please try again and contact support@withcubed.com if the problem persists',
                        other: 'Something went wrong - please try again and contact support@withcubed.com if the problem persists',
                    };
                    this.setState({
                        errorMessages,
                    });
                    this.props.addNotification({
                        copy: 'There was an issue updating the details, please try again later.',
                        type: NotificationMessageType.Error,
                    });
                });
        });
    };

    onKeywordClicked = event => {
        if (this.state.disableKeywordSelect === true) {
            return;
        }

        const selectedEventId = parseInt(event.currentTarget.getAttribute('data-value'));
        let selectedEvents = [];

        if (this.state['selected-events'].filter(event => event.id === selectedEventId).length > 0) {
            selectedEvents = this.state['selected-events'].filter(event => event.id !== selectedEventId);
            this.setState({
                'selected-events': [],
                editButtonDisabled: true,
            });
        } else {
            selectedEvents = this.state.keywords.filter(event => event.market_keyword_id === selectedEventId);
            this.setState({
                'selected-events': selectedEvents,
                editButtonDisabled: false,
            });
        }
    };

    renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    renderKeywordsList = () => {
        const row = this.state.keywords.map(keyword => {
            const rowProperty = {
                selected: this.state['selected-events'].includes(keyword),
                disabled: this.state.disableKeywordSelect === true,
            };

            return {
                columns: [
                    {
                        copy: keyword.keyword,
                    },
                    {
                        copy: keyword.market,
                    },
                ],
                keyValue: `Keyword__${keyword.keyword}`,
                dataValue: keyword.market_keyword_id,
                rowProperty,
                onClick: this.onKeywordClicked,
            };
        });

        const header = {
            columns: [
                {
                    title: 'Keywords',
                },
                {
                    title: 'Market',
                },
            ],
        };

        return (
            <SimpleTable
                isLoading={this.state.isLoading || this.state.showLoading}
                header={header}
                rows={row}
                hasIcons={false}
                selectDisabled={this.state.onEdit}
                isScrollable={true}
            />
        );
    };

    renderEditList = () => {
        if (this.state.accordionEditOpen === false) {
            return null;
        }

        const accordion = [
            {
                header: 'Edit Keywords',
                required: false,
                open: this.state.accordionEditOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Keyword:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: '',
                                inputKeyValue: 'seo-keyword__keyword',
                                inputValue: this.state['seo-keyword__keyword'],
                                inputOnChange: this.onUserDetailsChange,
                                disabled: true,
                            },
                            {
                                type: 'reactSelect',
                                inputKeyValue: 'seo-keyword__market',
                                inputOptions: this.state.marketList,
                                customPlaceholder: 'Select Market',
                                inputValue: this.state['seo-keyword__market'],
                                inputOnChange: e => this.onUserDetailsDropdownChange('seo-keyword__market', e),
                                isSearchable: true,
                                isClearable: false,
                                isMulti: false,
                                label: 'Market:',
                                requiredField: true,
                                toolTipCopy:
                                    'Market is a two letter (alpha2) country code, for example GB, FR, DE or ES. We have over 200 country codes within our Cubed system. *',
                            },
                            {
                                type: 'reactSelect',
                                inputKeyValue: 'seo-keyword__product',
                                inputOptions: this.state.productList,
                                customPlaceholder: 'Select Product',
                                inputValue: this.state['seo-keyword__product'],
                                inputOnChange: e => this.onUserDetailsDropdownChange('seo-keyword__product', e),
                                isSearchable: true,
                                isClearable: false,
                                isMulti: false,
                                label: 'Product:',
                                requiredField: true,
                                toolTipCopy: 'The primary product which is targeted by the keyword being uploaded. *',
                                errorMessage: this.state.errorMessages.product,
                            },
                            {
                                label: 'Parent Category:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: 'The first categorisation of your uploaded keyword.',
                                inputKeyValue: 'seo-keyword__parent_category',
                                inputValue: this.state['seo-keyword__parent_category'],
                                inputOnChange: this.onUserDetailsChange,
                            },
                            {
                                label: 'Sub Category:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: '	The third categorisation of your uploaded keyword.',
                                inputKeyValue: 'seo-keyword__sub_category',
                                inputValue: this.state['seo-keyword__sub_category'],
                                inputOnChange: this.onUserDetailsChange,
                            },
                            {
                                label: 'Child Category:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: 'The second categorisation of your uploaded keyword.',
                                inputKeyValue: 'seo-keyword__child_category',
                                inputValue: this.state['seo-keyword__child_category'],
                                inputOnChange: this.onUserDetailsChange,
                            },
                            {
                                label: 'Mapped Page:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: 'URL the keyword is mapped to. Format should be https://www.example.com',
                                inputKeyValue: 'seo-keyword__mapped_path',
                                inputValue: this.state['seo-keyword__mapped_path'],
                                inputOnChange: this.onUserDetailsChange,
                                errorMessage: this.state.errorMessages.mappedPage,
                            },
                            {
                                label: 'Active:',
                                type: 'checkbox',
                                toolTipCopy: 'Active state of keyword (0 or 1). To remove a keyword, please set to 0.',
                                inputKeyValue: 'keywordActive',
                                checked: this.state.keywordActive,
                                inputOnChange: this.onUserDetailsChange,
                            },
                        ],
                        columns: 2,
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordion} />;
    };

    onUserDetailsDropdownChange = (name, selectedData) => {
        switch (name) {
            case 'seo-keyword__product':
                this.setState({
                    'seo-keyword__product': selectedData,
                    'seo-keyword__product_id': selectedData.id,
                });
                break;
            case 'seo-keyword__market':
                this.setState({
                    'seo-keyword__market': selectedData,
                    'seo-keyword__market_id': selectedData.id,
                });
                break;
            default:
                console.log('something goes wrong!');
        }

        this.setState({
            closeButtonState: 'cancel',
            saveChangesButtonDisabled: false,
        });
    };

    onUserDetailsChange = event => {
        if (this.state.closeButtonState === 'close') {
            this.setState({
                closeButtonState: 'cancel',
                saveChangesButtonDisabled: false,
            });
        }

        if (event === 'keywordActive') {
            this.setState({
                keywordActive: !this.state.keywordActive,
            });
        } else {
            let stateId = event.target.name;

            if (stateId === 'seo-keyword__mapped_path_id') {
                isValidUrl(event.target.value);
            }

            this.setState({
                [stateId]: event.target.value,
            });
        }
    };

    onActiveTickBoxChange = () => {
        this.setState({
            keywordActive: !this.state.keywordActive,
        });
    };

    render() {
        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__manage-keywords manage-modals">
                    <this.renderModalNavigation />
                    <h2>Manage SEO Keywords</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__manage-keywords manage-modals">
                <this.renderModalNavigation />
                <h2>Manage SEO Keywords</h2>

                <StyledSearchOptionsContainer>
                    <InputSearch
                        placeholder="Search Keyword..."
                        value={this.state.keywordSearchTerm}
                        onChange={this.onKeywordSearchTermChange}
                        disabled={this.state.onEdit}
                    />
                    <InputSearch
                        placeholder="Search Market..."
                        value={this.state.marketSearchTerm}
                        onChange={this.onMarketSearchTermChange}
                        disabled={this.state.onEdit}
                    />
                </StyledSearchOptionsContainer>
                <div className="modal__side-panel__manage-keyword__simple-table">
                    <this.renderKeywordsList />
                </div>

                <StyledTablePages>
                    {!(this.state.isLoading || this.state.showLoading) && this.state.keywords.length > 0 && (
                        <TablePaginationPageList
                            ownerCallback={this.setOwnerState}
                            currentRowCount={this.state.rowLimit}
                            totalResults={this.state.total_count}
                            minPage={1}
                            maxPage={this.state.total_page}
                            currentPage={this.state.page}
                            maxButtons={6}
                            disabled={this.state.onEdit}
                        />
                    )}
                    <InputButtonWrapper>
                        <InputButton
                            value="ADD KEYWORDS"
                            disabled={this.state.createKeywordsDisabled}
                            onClick={this.onCreateKeywordClick}
                        />

                        {this.state.onEdit ? (
                            <InputButton
                                value="SAVE"
                                disabled={this.state.saveChangesButtonDisabled}
                                onClick={this.onSaveChangesClick}
                            />
                        ) : (
                            <InputButton
                                value="EDIT"
                                disabled={this.state.editButtonDisabled}
                                onClick={this.onEditKeywordClick}
                            />
                        )}
                        <InputButton
                            value="CANCEL"
                            disabled={!this.state.accordionEditOpen}
                            onClick={this.onCancelClick}
                        />
                    </InputButtonWrapper>
                </StyledTablePages>

                <this.renderEditList />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        removeModal: () => {
            dispatch(removeModal());
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalManageSEOKeywords);
