// React Dependencies
import React from 'react';
import styled from 'styled-components';
import { useState, useEffect } from 'react';

import LoadingSpindle from '../loading-spindle';

//      class InputField
//          required          boolean | undefined
//          name              string  | undefined
//          placeholder       string  | undefined
//          onChange          function(event) | undefined
//          onKeyPress        function(event) | undefined
//          checked           boolean | undefined
//          value             string
//          type              string
//          valueWillChange   function(value, dataKey)
//

const StyledSpinner = styled.div`
    filter: invert(1);
    position: absolute;
    bottom: 17px;
    left: 395px;
`;

const InputField = ({
    value,
    type,
    dataKey,
    required,
    name,
    placeholder,
    isLoading,
    bufferingMessage,
    checked,
    onChange,
    onKeyPress,
    autocomplete,
    disabled,
    onFocusClick,
}) => {
    const [valueInField, setValueInField] = useState(value);

    useEffect(() => {
        setValueInField(value);
    }, [value]);

    const onValueChange = event => {
        setValueInField(event.target.value);

        // If there is a onChange callback from parent call it.
        if (onChange !== undefined) {
            onChange(event);
        }
    };
    return (
        <>
            <input
                className={`input-field input-field__${type}`}
                data-key={dataKey}
                data-required={required}
                type={type}
                name={name}
                placeholder={placeholder}
                value={isLoading ? bufferingMessage : valueInField}
                checked={checked}
                onChange={onValueChange}
                onKeyPress={onKeyPress}
                autoComplete={autocomplete}
                disabled={disabled}
                onFocus={onFocusClick}
            />
            {isLoading && (
                <StyledSpinner>
                    <LoadingSpindle />
                </StyledSpinner>
            )}
        </>
    );
};

export default InputField;
