import React from 'react';

// Helpers
import getResource from '../../helpers/get-resource';
import getField from '../../helpers/get-field';

// Hooks
import useRequestPieChartTable from '../../../section-dashboard/hooks/request/use-request-pie-chart-table';
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourcePieChartTable from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table';

// Types
import { CubedField } from '../../../types';
import { WidgetConfig } from '../../types';

// Components
import CreateAWidgetPreviewMessage from '../../create-a-widget/components/create-a-widget-preview-message';
import WidgetPieChartTable from '../../../section-dashboard/widgets/widget/widget-pie-chart-table';
import WidgetMenuPieChartTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie-chart-table';

const WidgetPreviewPieChartTable = ({ config }: { config: WidgetConfig }) => {
    // Get the request values from the widget
    const resource = getResource(config.resource);
    const groupByField = getField(config.group_by);

    const orderByField = getField(config.order_by);
    const orderByDirection = config.order_by_direction.direction;
    const fields = [
        ...(groupByField ? [groupByField] : []),
        ...config.fields.split(',').map((field: string) => getField(field)),
    ].filter(field => field !== undefined) as CubedField[];

    const request = useRequestPieChartTable({
        orderBy: orderByField ? [{ field: orderByField, orderByDirection: orderByDirection }] : [],
        groupBy: groupByField,
        chartField: orderByField,
        fields,
        limit: 15,
        enabled: !!resource && !!groupByField && !!orderByField,
    });

    const resourceData = useResource({
        resource: request.groupBy && request.orderBy ? resource : undefined,
        request: request,
    });

    const pieChartTableData = useResourcePieChartTable({
        resourceData: resourceData,
        chartDimension: groupByField,
    });

    if (config.group_by.length > 0 && config.order_by.length > 0) {
        return (
            <WidgetPieChartTable
                title={config.widget.title}
                data={pieChartTableData}
                menu={<WidgetMenuPieChartTable data={pieChartTableData} />}
            />
        );
    }

    return (
        <CreateAWidgetPreviewMessage message="Please select a dimension, chart metric and at least one column to preview the pie chart with table widget" />
    );
};

export default WidgetPreviewPieChartTable;
