import { comparisonRequestError } from '../../../helpers/errors';
import { getMultiRequestStatus } from '../../../helpers/get-multi-request-status';
import { ResourceComparisonData } from '../../../types';
import useResource, { UseResourceArgs } from '../../widget/use-resource';

type useResourceVennResourceComparisonArgs = {
    dimensionResource: UseResourceArgs;
    vennResource: UseResourceArgs;
    vennComparisonResource: UseResourceArgs;
};

const useResourceVennResourceComparison = ({
    dimensionResource,
    vennResource,
    vennComparisonResource,
}: useResourceVennResourceComparisonArgs): ResourceComparisonData => {
    // Fetch data
    const dimensionData = useResource(dimensionResource);
    const vennData = useResource(vennResource);
    const vennComparisonData = useResource(vennComparisonResource);

    // Get request status
    const status = getMultiRequestStatus({
        requiredRequests: [dimensionData],
        requests: [vennData, vennComparisonData],
    });

    if (status === 'loading' || status === 'empty') {
        return {
            type: 'venn',
            status: status,
        };
    }

    if (status === 'error') {
        return {
            type: 'venn',
            status: status,
            error: {
                message: comparisonRequestError(dimensionData, vennData),
            },
        };
    }

    return {
        type: 'vennComparison',
        status: status,
        resources: [
            {
                ...dimensionData,
                type: 'vennResourceDimension',
            },
            {
                ...vennData,
                type: 'vennResourceData',
            },
            {
                ...vennComparisonData,
                type: 'vennResourceData',
            },
        ],
    };
};

export default useResourceVennResourceComparison;
