// Helper
import { unknownErrorData } from '../../../helpers/errors';

// Types
import { CubedField } from '../../../../types';
import { ResourceData, TotalData } from '../../../types';
import getMixValue from '../../../helpers/get-mix-value';

export type UseResourcePieChartTableCategoryMixArgs = {
    resourceData: ResourceData;
    totalData: TotalData;
    mixField: CubedField;
};

const useResourcePieChartTableCategoryMix = ({
    resourceData,
    totalData,
    mixField,
}: UseResourcePieChartTableCategoryMixArgs) => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success' && totalData.status === 'success') {
        const resourceObjects = resourceData.objects.map(data => {
            const mixingTotalValue = totalData.totals.values[mixField.rawName].rawValue;
            const mix = getMixValue(data, mixField, mixingTotalValue);

            return {
                ...data,
                values: {
                    ...data.values,
                    mix,
                },
            };
        });

        return {
            ...resourceData,
            objects: resourceObjects,
        };
    }

    return unknownErrorData();
};

export default useResourcePieChartTableCategoryMix;
