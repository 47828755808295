import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div<{ checked: boolean; disabled: boolean }>`
    position: relative;
    display: inline-block;
    width: 56px;
    height: 30px;
    background-color: transparent;
    margin-bottom: 0px;
    border: none;
    opacity: ${props => props.disabled && props.checked && '10%'};

    &:focus {
        outline: none;
    }

    &::placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }
`;

const StyledSlider = styled.span<{ checked: boolean; disabled: boolean }>`
    position: absolute;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props =>
        props.checked ? props.theme.colours.offBlackLighter : props.theme.colours.inputColour};
    -webkit-transition: 0.4s;
    border-radius: 34px;

    &::before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 5px;
        bottom: 5px;
        background-color: ${props => props.theme.colours.white};
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
        -webkit-transform: ${props => props.checked && 'translateX(26px)'};
        -ms-transform: ${props => props.checked && 'translateX(26px)'};
        transform: ${props => props.checked && 'translateX(26px)'};
    }
`;

export type ToggleProps = {
    checked: boolean;
    dataName?: string;
    disabled?: boolean;
    onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
};

const Toggle = ({ checked, dataName, disabled, onClick }: ToggleProps) => {
    const [checkedState, setCheckedState] = useState(() => {
        return checked !== undefined ? checked : false;
    });

    useEffect(() => {
        if (checked !== checkedState) {
            setCheckedState(checked);
        }
    }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
        if (disabled === true) {
            return;
        }
        setCheckedState(!checkedState);
        if (onClick) {
            onClick(event);
        }
    };
    return (
        <StyledContainer
            data-name={dataName}
            data-value={checkedState}
            onClick={handleClick}
            checked={checked}
            disabled={disabled || false}
            data-testid="input-toggle"
        >
            <StyledSlider checked={checkedState} disabled={disabled || false}></StyledSlider>
        </StyledContainer>
    );
};

export default Toggle;
