import React, { useState } from 'react';
import styled, { css } from 'styled-components';

// Component
import Tooltip from '../../../tooltip';

// Redux
import { useAppSelector } from '../../../../redux/hooks';

// Helper
import { formatSalesTitle } from '../../../../helpers/formatGlobalSaleTitle';

// Images
import arrowImage from '../../../../assets/images/icons/icon-arrowup-black.svg';

// Types
import { DataTableColumn, orderByDir } from '../../types';
import { CubedField } from '../../../../types';

// Styles
const StyledStickyColumn = css`
    position: sticky !important;
    left: 0;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.16);
    z-index: 2;
`;

const StyledTableHeader = styled.th<{ isDimension: boolean; scrollPosX: number; columnWidth: string | number }>`
    ${props => props.isDimension && props.scrollPosX > 0 && StyledStickyColumn};

    line-height: 17px;
    font-size: 13px;
    color: ${props => props.theme.colours.textDefault};
    font-weight: 500;
    ${props => props.theme.no_select()};
    padding: 8px 10px !important;
    cursor: pointer;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    transition: 0.2s ease-in-out !important;
    background-color: ${props => props.theme.colours.white};
    background-clip: padding-box;
    position: relative;

    max-width: ${props => props.columnWidth};
    min-width: ${props => props.columnWidth};
    width: ${props => props.isDimension && props.columnWidth};
`;

const StyledTableHeaderIcon = styled.i<{ isVisible: boolean; orderDir: string }>`
    transition: 0.2s ease-in-out;
    position: absolute;
    left: 10px;
    top: calc(50% - 6px);
    transform: rotate(${props => (props.orderDir === 'asc' ? `0deg` : `180deg`)});
    display: inline;
    vertical-align: middle;
    opacity: ${props => (props.isVisible ? 0.5 : 0)};
    height: 12px;
    width: 12px;
    background-image: url(${arrowImage});
    background-size: contain;
    background-repeat: no-repeat;
`;

const StyledTableHeaderText = styled.div`
    display: inline;
    padding: 3px;
    font-size: 13px;
`;

// Types
type DataTableHeaderCellProps = {
    column: DataTableColumn;
    activeColumn: CubedField;
    isOrderBy: boolean;
    orderByDir: orderByDir;
    setOwnerState: (key: string, value: orderByDir | DataTableColumn) => void;
    scrollPosX: number;
};

const DataTableHeaderCell = ({
    column,
    activeColumn,
    isOrderBy,
    orderByDir,
    setOwnerState,
    scrollPosX,
}: DataTableHeaderCellProps) => {
    const { globalSalesTitle } = useAppSelector(state => state.saleTitle);

    const [orderDir, setOrderDir] = useState(orderByDir);

    const { isDimension, fixedWidth, metricWidth } = column;

    const handleSetCurrentOrder = () => {
        if (activeColumn !== column) {
            setOwnerState('tableOrderBy', column);
        } else {
            let newOrderDir = orderDir === 'desc' ? 'asc' : 'desc';
            setOrderDir(newOrderDir as orderByDir);
            setOwnerState('tableOrderDir', newOrderDir as orderByDir);
        }
    };

    const renderCell = () => {
        const columnWidth = isDimension
            ? fixedWidth !== undefined
                ? fixedWidth
                : '270px'
            : metricWidth !== undefined
            ? metricWidth
            : '12em';

        return (
            <StyledTableHeader
                key="1"
                isDimension={isDimension!}
                scrollPosX={scrollPosX}
                columnWidth={columnWidth}
                onClick={handleSetCurrentOrder}
            >
                {column.description && <Tooltip copy={column.description} />}
                <StyledTableHeaderIcon isVisible={isOrderBy} orderDir={orderDir} />
                <StyledTableHeaderText data-testid="table-header-text">
                    {formatSalesTitle(column.displayName, globalSalesTitle)}
                </StyledTableHeaderText>
            </StyledTableHeader>
        );
    };

    return <>{renderCell()}</>;
};

export default DataTableHeaderCell;
