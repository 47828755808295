import { v4 as uuidv4 } from 'uuid';
import { CubedField } from '../../../../types';
import { unknownErrorData } from '../../../helpers/errors';
import { calculateVariance, formatVariance } from '../../../helpers/variance-helpers';
import { DataType, EmptyData, ResourceComparisonData, TableData, TableRow } from '../../../types';

type UseResourceTableComparisonVarianceOnlyArgs = {
    resourceData: ResourceComparisonData;
    dimension: CubedField;
};

const useResourceTableComparisonVarianceOnly = ({
    resourceData,
    dimension,
}: UseResourceTableComparisonVarianceOnlyArgs) => {
    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        if (!resourceData?.request?.fields) {
            throw new Error('Expected fields in the request');
        }

        if (new Set(resourceData.request.fields).size !== resourceData.request.fields.length) {
            throw new Error('Duplicate table columns detected - please check the fields array in the request');
        }

        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        if (resource.status === 'success' && comparisonResource.status === 'success') {
            const data = {
                type: 'tableComparisonVarianceOnly' as DataType,
                status: 'success',
                totalRows: 0,
                request: resourceData.request,
                columns: resourceData.request.fields.map(field => {
                    return {
                        rawName: field.rawName,
                        displayName: field.displayName,
                        dataType: field.dataType,
                        isDimension: field.isDimension,
                        isVariance: !field.isDimension,
                    };
                }),
                rows: [] as TableRow[],
            };

            resource.objects.forEach(object => {
                const row = {
                    __id: uuidv4(),
                    values: {},
                } as TableRow;

                const currentDimension = object.values[dimension.rawName];
                row.values[dimension.rawName] = currentDimension;

                if (currentDimension) {
                    data.columns.forEach(field => {
                        if (!field.isDimension) {
                            const thisPeriodValue = object.values[field.rawName].rawValue || 0;
                            const comparisonPeriodValue =
                                comparisonResource.objects.find(
                                    object => object.values[dimension.rawName]?.value === currentDimension.value
                                )?.values[field.rawName].rawValue || 0;

                            const variance = calculateVariance(+thisPeriodValue, +comparisonPeriodValue);

                            row.values[field.rawName] = {
                                rawValue: variance || 0,
                                value: formatVariance(variance),
                            };
                        }
                    });
                }

                data.rows.push(row);
            });

            data.totalRows = resource.meta.total_count;

            return data as TableData;
        }

        return {
            type: 'tableComparisonVarianceOnly' as DataType,
            status: 'empty',
        } as EmptyData;
    }

    return unknownErrorData();
};

export default useResourceTableComparisonVarianceOnly;
