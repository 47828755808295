import React, { useEffect } from 'react';
import styled from 'styled-components';
import validators from '../../../../components/forms/validation/validators';

import { DropdownOption } from '../../../../section-dashboard/types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import { CONFIG_REGION_LIST } from '../../../../configurations/resources-config';
import { ConfigDataSuccess } from '../../../../react-query/types';

// Components
import Form from '../../../../components/forms/form';
import FormFieldCity from './form-fields/form-field-city';
import IconPlus from '../../../../components/icons/plus';
import IconTimes from '../../../../components/icons/times';

const StyledRegionContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const StyledAddButton = styled.button<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    gap: 5px;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.9rem;
    color: ${props => props.disabled && props.theme.colours.midGrey};
`;

const StyledRemoveButton = styled.button<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    gap: 5px;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: ${props => props.disabled && props.theme.colours.midGrey};
    height: 40px;
    padding: 0px 10px;
`;

const StyledIconPlus = styled(IconPlus)`
    width: 15px;
`;

const StyledIconTimes = styled(IconTimes)`
    width: 14px;
`;

const FormCreateAtl = ({ channels }: { channels: DropdownOption[] }) => {
    const { watch } = useFormContext();
    const formValues = watch();

    const { fields, append, remove } = useFieldArray({
        name: 'regions',
    });

    const regionListCountriesQuery = useFetchResource({
        resource: CONFIG_REGION_LIST,
        params: [
            { key: 'group_by', value: 'geolocation__country_name' },
            { key: 'order_by', value: 'geolocation__country_name' },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.geolocation__country_name.value,
                    value: object.geolocation__country_name.value,
                };
            });
        },
    });

    useEffect(() => {
        if (formValues.regional && formValues.regions.length === 0) {
            append({ country: '' });
        }
    }, [formValues.regional]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form.Body direction="column">
            <Form.Section>
                <Form.SectionTitle>Edit ATL</Form.SectionTitle>

                <Form.Field>
                    <Form.InputLabel htmlFor="atlName" label="ATL Name" />
                    <Form.InputText name="atlName" validators={[validators.required]} />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="startDate" label="Base Activity Start Date" />
                    <Form.InputDatePicker name="startDate" validators={[validators.required]} />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="endDate" label="Base Activity End Date" />
                    <Form.InputDatePicker name="endDate" validators={[validators.required]} />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="channel" label="Channel" />
                    <Form.InputSelect name="channel" options={channels} validators={[validators.required]} />
                </Form.Field>

                <Form.Field>
                    <Form.InputCheckbox name="regional" label="Regional?" />
                </Form.Field>
            </Form.Section>

            {formValues.regional ? (
                <Form.Section>
                    <Form.SectionTitle>Regions</Form.SectionTitle>

                    {fields.map((field, index) => (
                        <StyledRegionContainer>
                            <Form.InputSelect
                                key={field.id}
                                name={`regions.${index}.country`}
                                options={regionListCountriesQuery?.data || []}
                                placeholder={
                                    regionListCountriesQuery.isLoading
                                        ? 'Loading countries...'
                                        : 'Please select a country'
                                }
                            />
                            <FormFieldCity
                                country={formValues?.regions[index].country}
                                fieldId={field.id}
                                index={index}
                            />
                            <StyledRemoveButton
                                onClick={() => remove(index)}
                                disabled={index === 0 && formValues?.regions?.length === 1}
                            >
                                <StyledIconTimes />
                            </StyledRemoveButton>
                        </StyledRegionContainer>
                    ))}

                    <StyledAddButton
                        onClick={() => append({ country: '' })}
                        disabled={!formValues?.regions?.at(-1)?.city}
                    >
                        <StyledIconPlus />
                        Add Another Region
                    </StyledAddButton>
                </Form.Section>
            ) : null}
        </Form.Body>
    );
};

export default FormCreateAtl;
