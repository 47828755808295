import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../../redux/store';
import IconImageSlash from '../../../../components/icons/image-slash';
import { Link } from 'react-router-dom';

const StyledEmptyWidgetContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
`;

const StyledP = styled.p`
    font-size: 0.9rem;
    font-style: italic;
    margin: 0;
`;

const StyledIconImageSlash = styled(IconImageSlash)`
    width: 40px;
`;

const EmptyWidgets = () => {
    const { account } = useSelector((state: RootState) => state);

    return (
        <StyledEmptyWidgetContainer>
            <StyledIconImageSlash />
            <StyledP>
                You have not created any widgets yet. To start building dashboards, you will first need to add some
                widgets.
            </StyledP>
            <StyledP>
                <Link to={`/${account.token}/dashboard-builder/create-a-widget`}>Click here </Link>
                to create a widget.
            </StyledP>
        </StyledEmptyWidgetContainer>
    );
};

export default EmptyWidgets;
