export const sparkConfig = {
    title: '',
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: false,
    },
    time: {
        useUTC: true,
    },
    xAxis: {
        type: 'datetime',
        timezone: 'browser',
        tickAmount: 0,
        lineWidth: 0,
        gridLineWidth: 0,
        labels: {
            enabled: false,
        },
        minorTickLength: 0,
        tickLength: 0,
    },
    yAxis: {
        title: {
            text: '',
        },
        min: 0,
        gridLineWidth: 0,
        labels: {
            enabled: false,
        },
    },
    lang: {
        decimalPoint: ',',
        thousandsSep: '.',
    },
    chart: {
        skipClone: true,
        borderWidth: 0,
        backgroundColor: 'rgba(0,0,0,0.0)',
        type: 'spline',
        style: {
            overflow: 'visible',
            fontFamily: 'Work Sans',
        },
    },
    plotOptions: {
        color: 'white',
    },
    legend: {
        enabled: false,
    },
};
