import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import InputTextArea from '../inputs/input-text-area';
import Tooltip from '../tooltip';

const StyledFormField = styled.div`
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 15px;
`;

const StyledFormFieldTop = styled.div`
    margin-bottom: 5px;
`;

const StyledLabel = styled.label`
    font-size: 1em;
    font-weight: 400;
`;

const StyledRequiredField = styled.span`
    color: ${props => props.theme.colours.red};
`;

const StyledErrorMessage = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colours.red};
    margin-bottom: 1em;
`;

export type FormTextAreaProps = {
    toolTipCopy?: string;
    errorMessage?: string;
    requiredField: boolean;
    label?: string;
    inputValue?: string;
    inputOnChange: ChangeEventHandler<HTMLTextAreaElement>;
    disabled?: boolean;
};

const FormTextArea = ({
    toolTipCopy,
    errorMessage,
    requiredField,
    label,
    inputValue,
    inputOnChange,
    disabled,
}: FormTextAreaProps) => {
    return (
        <StyledFormField>
            <StyledFormFieldTop>
                <StyledLabel>
                    {label} {requiredField && <StyledRequiredField>*</StyledRequiredField>}
                </StyledLabel>
                {toolTipCopy && <Tooltip copy={toolTipCopy} />}
            </StyledFormFieldTop>

            <InputTextArea value={inputValue} onChange={inputOnChange} disabled={disabled} />

            {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        </StyledFormField>
    );
};

export default FormTextArea;
