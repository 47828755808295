import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

import { useSectionDashboard } from '../../../../context/section-dashboard-context';
import { DropdownOption, SelectOptionsRequest } from '../../../../types';

import IconChevronUp from '../../../../../components/icons/chevron-up';
import IconCheck from '../../../../../components/icons/check';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};

    & button {
        all: inherit;
        cursor: 'pointer';
    }
`;

const StyledLabel = styled.div`
    font-size: 1rem;
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.title.fontWeight};
    white-space: nowrap;
`;

const StyledTrigger = styled(DropdownMenu.Trigger)`
    margin: 5px !important;
`;

const StyledTriggerContent = styled.div`
    border: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    background-color: ${props => props.theme.sectionDashboard.widget.menu.triggerBackgroundColor};
    border-radius: 5px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    min-width: 150px;
    font-size: 0.9rem;
    cursor: pointer;
`;

const StyledOptionLabel = styled.div`
    font-size: 1rem;
    padding: 5px 10px;
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.fontWeight};
    white-space: nowrap;
`;

const StyledIconContainer = styled.div`
    padding: 0px 10px;
    height: 100%;
    border-left: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
`;

const StyledIconChevronSmall = styled(IconChevronUp)`
    transform: rotate(180deg);
    height: 14px;
    margin-top: 8px;
    width: auto;
    & path {
        fill: ${props => props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledIconChevronLarge = styled(IconChevronUp)`
    transform: rotate(180deg);
    margin: 11px 10px 5px 10px;
    height: 15px;
    width: auto;
    cursor: pointer;
    & path {
        fill: ${props => props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledContent = styled(DropdownMenu.Content)`
    background-color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.backgroundColor};
    min-width: 210px;
    padding: 10px 0px;
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.menu.shadow};
    max-height: 50vh;
    z-index: 21; // Overlap the top bar
    overflow-y: auto;
`;

const StyledDropdownItem = styled(DropdownMenu.Item)`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 10px 15px;
    cursor: pointer;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.fontWeight};

    &:hover {
        color: ${props => props.theme.sectionDashboard.widget.menu.textColorHover};
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
        border: none;
        outline: none;
    }
`;

const StyledIconCheck = styled(IconCheck)<{ visible: boolean }>`
    width: 18px;
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const StyledLoading = styled.div<{ width: number }>`
    margin-right: 10px;
    height: 19px;
    width: ${props => (props.width > 2 ? '100px' : '20px')};
    background-color: ${props => props.theme.sectionDashboard.widget.menu.skeletonColor};
    border-radius: ${props => (props.width > 2 ? '5px' : '50%')};
`;

export type WidgetMenuFooterSelectProps = {
    label?: string;
    dropdownOptions: SelectOptionsRequest;
    selectedOption?: DropdownOption;
    onOptionChange: (option: string) => void;
};

const WidgetMenuFooterSelect = ({
    label,
    dropdownOptions,
    selectedOption,
    onOptionChange,
}: WidgetMenuFooterSelectProps) => {
    const { width } = useSectionDashboard();
    const success = dropdownOptions.status === 'success';
    const empty = dropdownOptions.status === 'empty';

    if (dropdownOptions.status === 'loading') {
        return (
            <StyledContainer>
                <StyledLoading width={width} />
            </StyledContainer>
        );
    }

    if ((success || empty) && selectedOption) {
        return (
            <StyledContainer>
                <DropdownMenu.Root>
                    {width > 3 && label && <StyledLabel>{label}:</StyledLabel>}
                    <StyledTrigger>
                        {width < 3 && <StyledIconChevronLarge />}
                        {width >= 3 && (
                            <StyledTriggerContent>
                                <StyledOptionLabel>{selectedOption.label}</StyledOptionLabel>
                                <StyledIconContainer>
                                    <StyledIconChevronSmall />
                                </StyledIconContainer>
                            </StyledTriggerContent>
                        )}
                    </StyledTrigger>
                    <DropdownMenu.Portal>
                        <StyledContent side="bottom" align="end">
                            {dropdownOptions.options.map(option => {
                                return (
                                    <StyledDropdownItem onClick={() => onOptionChange(option.value)} key={option.value}>
                                        <StyledIconCheck visible={option.value === selectedOption.value} />
                                        {option.label}
                                    </StyledDropdownItem>
                                );
                            })}
                        </StyledContent>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            </StyledContainer>
        );
    }

    return null;
};

export default WidgetMenuFooterSelect;
