// React Dependencies
import React, { Component } from 'react';

// Required Components
import FormCheckBoxInput from './form-fields/form-checkbox-input';
import FormTextInput from './form-fields/form-text-input';
import FormDatePicker from './form-fields/form-date-picker';
import FormTextArea from './form-fields/form-text-area';
import FormSelect from './form-fields/form-select';
import FormColourPicker from './form-fields/form-colour-picker';
import PasswordRequirements from './password-requirements';
import FormTags from './form-fields/form-tags';
import FormFileUpload from './form-fields/form-file-upload';
import DropdownWithSearch from './common/dropdown-with-search';
import InputButtonWrapper from './inputs/input-button-wrapper';
import InputButton from './inputs/input-button';
import FormPassword from './form-fields/form-password';
import FormEmail from './form-fields/form-email';

// 		class Form
// 			props.config 	{ Config Object }
//
// 		{ Config Object }
// 			fields 				< Field Object >
// 			columns 			int | undefined ( Default = 1 ; Must be divisible by 12! )
// 			formSubmitButton 	{ Submit Button Object } | undefined
//
// 		< Field Object >
//
// 		{ Submit Button Object }
//

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [],
        };

        this.renderFormButtons = this.renderFormButtons.bind(this);
        this.renderColumn = this.renderColumn.bind(this);
        this.splitFields = this.splitFields.bind(this);
        this.columnClassName = this.columnClassName.bind(this);
    }

    componentDidMount() {
        this.splitFields();
    }

    componentDidUpdate(prevProps, prevState) {
        // Every time to component updates check if there are any differences between the previous and current props.config.fields if there is regenerate the columns
        const currentFields = JSON.stringify(this.props.config.fields);
        const prevFields = JSON.stringify(prevProps.config.fields);

        if (prevFields !== currentFields) {
            this.splitFields();
        }
    }

    // Split the fields based on the amount of columns set in the config
    splitFields() {
        // Default columns is 1
        const columns =
            this.props.config.columns !== undefined && this.props.config.columns !== '' ? this.props.config.columns : 1;

        const fieldsPerColumn = this.props.config.fields.length / columns;

        let splitFields = [];

        for (let column = 0; column < columns; column++) {
            const startIndex = column * fieldsPerColumn;
            const endIndex = startIndex + fieldsPerColumn;

            splitFields.push(this.props.config.fields.slice(startIndex, endIndex));
        }

        this.setState({
            columns: splitFields,
        });
    }

    // Based on the number of columns generate the Bootstrap Grid classnames for the columns
    columnClassName() {
        // Using 2 as the default instead of 1 to have the fields not use up the space completely, this can be overwritten if columns = 1;
        const columns =
            this.props.config.columns !== undefined && this.props.config.columns !== '' ? this.props.config.columns : 2;

        const mdColumn = 12 / columns;

        return `col-12 col-md-${mdColumn}`;
    }

    renderColumn(column, index) {
        return (
            <div className={this.columnClassName()} key={index}>
                {column.map(field => this.renderInput(field))}
            </div>
        );
    }

    renderInput(field) {
        switch (field.type) {
            case 'checkbox':
                return (
                    <FormCheckBoxInput
                        key={field.inputKeyValue}
                        label={field.label}
                        inputKeyValue={field.inputKeyValue}
                        inputPlaceholder={field.inputPlaceholder}
                        checked={field.checked}
                        inputOnChange={field.inputOnChange}
                        toolTipCopy={field.toolTipCopy}
                        inputName={field.inputKeyValue}
                        disabled={field.disabled}
                    />
                );
            case 'text':
                return (
                    <FormTextInput
                        key={field.inputKeyValue}
                        label={field.label}
                        requiredField={field.requiredField}
                        inputKeyValue={field.inputKeyValue}
                        inputPlaceholder={field.inputPlaceholder}
                        inputValue={field.inputValue}
                        inputOnChange={field.inputOnChange}
                        toolTipCopy={field.toolTipCopy}
                        inputName={field.inputKeyValue}
                        errorMessage={field.errorMessage}
                        inputAutocomplete={field.autocomplete}
                        disabled={field.disabled}
                        isLoading={field.isLoading}
                        bufferingMessage={field.bufferingMessage}
                        onFocusClick={field.onFocusClick}
                    />
                );
            case 'datePicker':
                return (
                    <FormDatePicker
                        key={field.inputKeyValue}
                        label={field.label}
                        requiredField={field.requiredField}
                        inputKeyValue={field.inputKeyValue}
                        inputPlaceholder={field.inputPlaceholder}
                        inputValue={field.inputValue}
                        inputOnChange={field.inputOnChange}
                        toolTipCopy={field.toolTipCopy}
                        errorMessage={field.errorMessage}
                    />
                );
            case 'textarea':
                return (
                    <FormTextArea
                        key={field.inputKeyValue}
                        label={field.label}
                        requiredField={field.requiredField}
                        inputKeyValue={field.inputKeyValue}
                        inputPlaceholder={field.inputPlaceholder}
                        inputValue={field.inputValue}
                        inputOnChange={field.inputOnChange}
                        toolTipCopy={field.toolTipCopy}
                        inputName={field.inputKeyValue}
                        errorMessage={field.errorMessage}
                    />
                );
            case 'select':
                return (
                    <FormSelect
                        key={field.inputKeyValue}
                        label={field.label}
                        requiredField={field.requiredField}
                        inputOnChange={field.inputOnChange}
                        toolTipCopy={field.toolTipCopy}
                        inputOptions={field.inputOptions}
                        errorMessage={field.errorMessage}
                        infoMessage={field.infoMessage}
                        customPlaceholder={field.customPlaceholder}
                        inputValue={field.inputValue}
                        inputKeyValue={field.inputKeyValue}
                        inputName={field.inputKeyValue}
                    />
                );
            case 'reactSelect':
                return (
                    <div className={field.customClass}>
                        <DropdownWithSearch
                            inputKeyValue={field.inputKeyValue}
                            dropdownItems={field.inputOptions}
                            placeholder={field.customPlaceholder}
                            selected={field.inputValue}
                            onSelectedItemChange={field.inputOnChange}
                            isSearchable={field.isSearchable}
                            isClearable={field.isClearable}
                            isMulti={field.isMulti}
                            label={field.label}
                            requiredField={field.requiredField}
                            toolTipCopy={field.toolTipCopy}
                            name={field.inputKeyValue}
                            isAccordionForm={true}
                            errorMessage={field.errorMessage}
                            displaySingleOption={field.displaySingleOption}
                        />
                    </div>
                );
            case 'colourPicker':
                return (
                    <FormColourPicker
                        label={field.label}
                        requiredField={field.requiredField}
                        inputSelectedColour={field.inputSelectedColour}
                        onInputChange={field.onInputChange}
                        inputKeyValue={field.inputKeyValue}
                        toolTipCopy={field.toolTipCopy}
                        inputColours={field.inputColours}
                        errorMessage={field.errorMessage}
                    />
                );

            case 'email':
                return (
                    <FormEmail
                        label={field.label}
                        requiredField={field.requiredField}
                        toolTipCopy={field.toolTipCopy}
                        inputValue={field.inputValue}
                        inputOnChange={field.inputOnChange}
                        errorMessage={field.errorMessage}
                    />
                );
            case 'password':
                return (
                    <FormPassword
                        toolTipCopy={field.toolTipCopy}
                        errorMessage={field.errorMessage}
                        requiredField={field.requiredField}
                        label={field.label}
                        inputAutocomplete={field.inputAutocomplete}
                        inputPlaceholder={field.inputPlaceholder}
                        inputValue={field.inputValue}
                        inputOnChange={field.inputOnChange}
                        onInputKeyPress={field.onInputKeyPress}
                    />
                );
            case 'passwordRequirements':
                return (
                    <PasswordRequirements
                        hasCharacterLength={field.characterLength}
                        hasCapitalLetter={field.hasCapitalLetter}
                        hasLowercaseLetter={field.hasLowercaseLetter}
                        hasNumber={field.hasNumber}
                        hasSpecialCharacter={field.hasSpecialCharacter}
                        hasValidCharacter={field.hasValidCharacter}
                    />
                );
            case 'tags':
                return (
                    <FormTags
                        fieldName={field.fieldName}
                        type={field.type}
                        splitOnOverride={field.splitOnOverride}
                        errors={field.errors}
                        tags={field.tags}
                        onChange={field.onChange}
                        label={field.label}
                        errorMessage={field.errorMessage}
                        validatorOverride={field.validatorOverride}
                        inputPlaceholder={field.inputPlaceholder}
                        copy={field.copy}
                    />
                );
            case 'file-upload':
                return (
                    <FormFileUpload
                        key={field.inputKeyValue}
                        type={field.type}
                        onChange={field.inputOnChange}
                        label={field.label}
                        errorMessage={field.errorMessage}
                        isValid={field.isValid}
                        uploadedFile={field.uploadedFile}
                        requiredField={field.requiredField}
                        toolTipCopy={field.toolTipCopy}
                    />
                );
            default:
                return null;
        }
    }

    renderFormButtons() {
        if (this.props.config.buttons === undefined) {
            return null;
        }

        return (
            <InputButtonWrapper>
                {this.props.config.buttons.map(button => (
                    <InputButton
                        key={button.value}
                        value={button.value}
                        buttonTheme={button.buttonTheme}
                        disabled={button.disabled}
                        onClick={button.onClick}
                        isLoading={button.isLoading}
                        buttonMessage={button.responseMessage}
                    />
                ))}
            </InputButtonWrapper>
        );
    }

    render() {
        return (
            <div className="form">
                <div className="form__fields row">
                    {this.state.columns.map((column, index) => this.renderColumn(column, index))}
                </div>

                <this.renderFormButtons />
            </div>
        );
    }
}

export default Form;
