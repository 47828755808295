import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';

import SectionDashboard from '../../../section-dashboard/components/section-dashboard';
import { config } from '../configurations/channel-insights-dashboard';
import { useDispatch } from 'react-redux';
import { setDescription, setTitle } from '../../../redux/actions/page-meta';
import { FilterBarContext } from '../../../filter-bar/context/filter-bar-context';
import ChannelInsightsDashboardMenuProvider from '../../channel-insights-context/channel-insights-dashboard-menu-context';

const ChannelInsightsDashboard = () => {
    const dispatch = useDispatch();

    const location = useLocation();
    location.isSectionDashboard = true;

    const {
        setFilterStatus,
        setComparisonFilterStatus,
        setDatePickerConfig,
        setFilterMetricsOptions,
        setComparisonDatePickerConfig,
    } = useContext(FilterBarContext);

    useEffect(() => {
        dispatch(setTitle(config.title));
        dispatch(setDescription(config.description));

        // Filter bar configurations
        setFilterStatus(config.filters.status);
        setComparisonFilterStatus(config.filters.comparisonStatus); // Comparison status
        setFilterMetricsOptions([]); // reset metrics
        setDatePickerConfig(config.filters.datePicker); // reset datepicker
        setComparisonDatePickerConfig(config.filters.datePicker); // reset datepicker
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ChannelInsightsDashboardMenuProvider>
            <SectionDashboard config={config} />
        </ChannelInsightsDashboardMenuProvider>
    );
};

export default ChannelInsightsDashboard;
