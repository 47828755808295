import { useEffect, useState } from 'react';
import React from 'react';
import ChartSelectorButton from '../chart-selector-button';
import LabelOnHover from './hover-tool-tip';

const ChartSelectorButtonWrapper = ({ chartType, selectedChartType, onClick }) => {
    const [hover, setHover] = useState(false);
    const [tooltipLabel, setToolTipLabel] = useState(null);

    useEffect(() => {
        switch (chartType) {
            case 'line':
                return setToolTipLabel('Line');
            case 'areaSpline':
                return setToolTipLabel('Area Spline');
            case 'network':
                return setToolTipLabel('Network');
            case 'trendLine':
                return setToolTipLabel('Trendline');
            case 'donut':
                return setToolTipLabel('Doughnut');
            case 'bar':
                return setToolTipLabel('Bar');
            case 'barHorizontal':
                return setToolTipLabel('Bar Horizontal');
            case 'pie':
                return setToolTipLabel('Pie');
            default:
                return setToolTipLabel(null);
        }
    }, [chartType]);

    return (
        <>
            <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <ChartSelectorButton chartType={chartType} selectedChartType={selectedChartType} onClick={onClick} />
            </div>
            {hover && tooltipLabel && <LabelOnHover label={tooltipLabel} />}
        </>
    );
};

export default ChartSelectorButtonWrapper;
