import React from 'react';
import styled from 'styled-components';

// Helpers
import { formatNumber } from '../../helpers/format-number';

// Types
import { WidgetDirection } from '../../types';

const StyledBoxContainer = styled.div<{ direction: 'horizontal' | 'vertical' }>`
    display: flex;
    flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
    height: 100%;
`;

const StyledBox = styled.div`
    flex-grow: 1;
    position: relative;
    background: ${props => props.theme.sectionDashboard.widget.barGraph.bigNumber.backgroundColor};
`;

const StyledBoxBackgroundPercentage = styled.div<{ direction: 'horizontal' | 'vertical'; percentageHeight: string }>`
    background-color: ${props => props.color};
    height: ${props => (props.direction === 'horizontal' ? props.percentageHeight : '100%')};
    width: ${props => (props.direction === 'vertical' ? props.percentageHeight : '100%')};
    position: absolute;
    bottom: 0;
`;

const StyledValueContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const StyledBigNumber = styled.div`
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    width: 100%;
    color: ${props => props.theme.sectionDashboard.widget.barGraph.bigNumber.textColor};
`;

const StyledTitleContainer = styled.div`
    text-align: center;
    width: 100%;
`;

const StyledTitle = styled.p`
    margin: 2px 0px;
    color: ${props => props.theme.sectionDashboard.widget.barGraph.bigNumber.textColor};
    font-size: 0.8rem;
    font-weight: 500;
`;

export type WidgetBarGraphBigNumberProps = {
    data: number[];
    categories: string[];
    topValue: number;
    direction: WidgetDirection;
    colours: string[];
    width: number;
};

const WidgetBarGraphBigNumber = ({
    data,
    categories,
    topValue,
    direction,
    colours,
    width,
}: WidgetBarGraphBigNumberProps) => {
    return (
        <StyledBoxContainer direction={direction}>
            {data.map((dataPoint, index) => {
                const percentageHeight = (dataPoint / topValue) * 100;

                return (
                    <StyledBox key={index}>
                        <StyledBoxBackgroundPercentage
                            percentageHeight={`${percentageHeight}%`}
                            direction={direction}
                            color={colours[index]}
                        />
                        <StyledValueContainer>
                            <StyledTitleContainer>
                                <StyledTitle>{categories[index]}</StyledTitle>
                            </StyledTitleContainer>

                            <StyledBigNumber>{formatNumber(data[index], width)}</StyledBigNumber>
                        </StyledValueContainer>
                    </StyledBox>
                );
            })}
        </StyledBoxContainer>
    );
};

export default WidgetBarGraphBigNumber;
