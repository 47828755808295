/* eslint-disable no-fallthrough */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CubedDayCell from './cubed-day';
import {
    format,
    startOfDay,
    endOfDay,
    isBefore,
    isSameDay,
    isAfter,
    isWeekend,
    isWithinInterval,
    eachMonthOfInterval,
    getMonth,
} from 'date-fns';
import getMonthDisplayRange from '../cubed-date-picker/cubed-date-func';

const rangeShape = PropTypes.shape({
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    color: PropTypes.string,
    key: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    showDateDisplay: PropTypes.bool,
});

class CubedMonth extends PureComponent {
    isStartOfCubedMonthRow = day => {
        const month = getMonth(day);
        if (month === 0 || month === 4 || month === 8) {
            return true;
        }
        return false;
    };
    isEndOfCubedMonthRow = day => {
        const month = getMonth(day);
        if (month === 3 || month === 7 || month === 11) {
            return true;
        }
        return false;
    };
    render() {
        const now = new Date();
        const { displayMode, focusedRange, drag, styles, disabledDates, disabledDay } = this.props;
        const minDate = this.props.minDate && startOfDay(this.props.minDate);
        const maxDate = this.props.maxDate && endOfDay(this.props.maxDate);
        const monthDisplay = getMonthDisplayRange(this.props.month, this.props.dateOptions, this.props.fixedHeight);
        let ranges = this.props.ranges;
        if (displayMode === 'dateRange' && drag.status) {
            let { startDate, endDate } = drag.range;
            ranges = ranges.map((range, i) => {
                if (i !== focusedRange[0]) return range;
                return {
                    ...range,
                    startDate,
                    endDate,
                };
            });
        }
        const showPreview = this.props.showPreview && !drag.disablePreview;
        return (
            <div className={styles.month} style={this.props.style}>
                {this.props.showMonthName ? (
                    <div className={styles.monthName}>
                        {format(this.props.month, this.props.monthDisplayFormat, this.props.dateOptions)}
                    </div>
                ) : null}
                <div className={styles.days} onMouseLeave={this.props.onMouseLeave}>
                    {eachMonthOfInterval({ start: monthDisplay.start, end: monthDisplay.end }).map((day, index) => {
                        const isStartOfMonth = isSameDay(day, monthDisplay.startDateOfMonth);
                        const isEndOfMonth = isSameDay(day, monthDisplay.endDateOfMonth);
                        const isOutsideMinMax =
                            (minDate && isBefore(day, minDate)) || (maxDate && isAfter(day, maxDate));
                        const isDisabledSpecifically = disabledDates.some(disabledDate => isSameDay(disabledDate, day));
                        const isDisabledDay = disabledDay(day);
                        return (
                            <CubedDayCell
                                {...this.props}
                                ranges={ranges}
                                day={day}
                                preview={showPreview ? this.props.preview : null}
                                isWeekend={isWeekend(day, this.props.dateOptions)}
                                isToday={isSameDay(day, now)}
                                isStartOfWeek={this.isStartOfCubedMonthRow(day)}
                                isEndOfWeek={this.isEndOfCubedMonthRow(day)}
                                isStartOfMonth={isStartOfMonth}
                                isEndOfMonth={isEndOfMonth}
                                key={index}
                                disabled={isOutsideMinMax || isDisabledSpecifically || isDisabledDay}
                                isPassive={
                                    !isWithinInterval(day, {
                                        start: monthDisplay.startDateOfMonth,
                                        end: monthDisplay.endDateOfMonth,
                                    })
                                }
                                styles={styles}
                                onMouseDown={this.props.onDragSelectionStart}
                                onMouseUp={this.props.onDragSelectionEnd}
                                onMouseEnter={this.props.onDragSelectionMove}
                                dragRange={drag.range}
                                drag={drag.status}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

CubedMonth.defaultProps = {};

CubedMonth.propTypes = {
    style: PropTypes.object,
    styles: PropTypes.object,
    month: PropTypes.object,
    drag: PropTypes.object,
    dateOptions: PropTypes.object,
    disabledDates: PropTypes.array,
    disabledDay: PropTypes.func,
    preview: PropTypes.shape({
        startDate: PropTypes.object,
        endDate: PropTypes.object,
    }),
    showPreview: PropTypes.bool,
    displayMode: PropTypes.oneOf(['dateRange', 'date']),
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    ranges: PropTypes.arrayOf(rangeShape),
    focusedRange: PropTypes.arrayOf(PropTypes.number),
    onDragSelectionStart: PropTypes.func,
    onDragSelectionEnd: PropTypes.func,
    onDragSelectionMove: PropTypes.func,
    onMouseLeave: PropTypes.func,
    monthDisplayFormat: PropTypes.string,
    weekdayDisplayFormat: PropTypes.string,
    dayDisplayFormat: PropTypes.string,
    showWeekDays: PropTypes.bool,
    showMonthName: PropTypes.bool,
    fixedHeight: PropTypes.bool,
};

export default CubedMonth;
