import React, { useContext } from 'react';
import validators from '../../../components/forms/validation/validators';

// Context
import { CreateAWidgetContext } from '../context/create-a-widget-context';
import { CreateAWidgetFormContext } from './context/create-a-widget-form-context';

// Components
import Form from '../../../components/forms/form';
import FormFieldDimension from './form-fields/form-field-dimension';

const CreateAWidgetFormBarGraph = () => {
    // Context
    const { orderByDirectionDropdownOptions } = useContext(CreateAWidgetContext);
    const { currentAvailableMetrics } = useContext(CreateAWidgetFormContext);

    return (
        <>
            <Form.Section>
                <Form.SectionTitle>Display Settings</Form.SectionTitle>
                <FormFieldDimension label="Dimension / X Axis" />
                <Form.Field>
                    <Form.InputLabel htmlFor="orderBy" label="Metric / Y Axis" />
                    <Form.InputSelect
                        name="orderBy"
                        options={currentAvailableMetrics}
                        placeholder="Please select a metric"
                        validators={[validators.required]}
                    />
                </Form.Field>
            </Form.Section>
            <Form.Section>
                <Form.SectionTitle>Additional Settings</Form.SectionTitle>
                <Form.Field>
                    <Form.InputLabel htmlFor="orderByDirection" label="Order By Direction" />
                    <Form.InputSelect
                        name="orderByDirection"
                        options={orderByDirectionDropdownOptions || []}
                        placeholder="Please select a direction"
                    />
                </Form.Field>
            </Form.Section>
        </>
    );
};

export default CreateAWidgetFormBarGraph;
