import React, { Component } from 'react';
import LayoutTableGraph from './../table-graph/layouts/table-graph';
import { config } from './../configurations/corridor-performance';

class ReportCorridorPerformance extends Component {
    render() {
        return (
            <LayoutTableGraph
                hash={this.props.location.hash}
                fetchProducts={this.props.fetchProducts}
                config={config}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedProducts={this.props.selectedProducts}
                selectedMetrics={this.props.selectedMetrics}
            />
        );
    }
}

export default ReportCorridorPerformance;
