/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';

// Helpers
import { hasRequestChanged } from '../../helpers/has-request-changed';
import { getResourceFilters } from '../../helpers/get-resource-filters';

// Types
import { CubedResource } from '../../../types';
import { SectionDashboardRequest, ViewData } from '../../types';

// Hooks
import { useDatesFormatted } from '../../../hooks/use-dates';
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';

export type UseViewArgs = {
    resource: CubedResource;
    request: SectionDashboardRequest;
    isComparison?: boolean;
};

const useView = ({ resource, request, isComparison = false }: UseViewArgs): ViewData => {
    const { startDate, endDate } = useDatesFormatted({ resource, isComparison });

    const [resourceRequest, setResourceRequest] = useState(request);

    useEffect(() => {
        if (hasRequestChanged(resourceRequest, request)) {
            setResourceRequest(request);
        }
    }, [request]);

    // Build the params
    const params = useMemo(() => {
        const params: { key: string; value: string | number }[] = [];

        params.push(
            {
                key: `${resource.dateDimension.rawName}__gte`,
                value: startDate,
            },
            {
                key: `${resource.dateDimension.rawName}__lte`,
                value: endDate,
            }
        );

        if (request.filters) {
            const resourceFilters = getResourceFilters(request.filters);

            resourceFilters.forEach(filter => {
                params.push(filter);
            });
        }

        return params;
    }, [resource, resourceRequest, startDate, endDate]);

    // Fetch the response
    const response = useFetchResource({
        resource,
        params,
        enabled: request.enabled,
    });

    if (response.isSuccess) {
        if (response.data.objects && response.data.objects.length > 0) {
            return {
                type: 'view',
                status: 'success',
                request: request,
                series: [],
                total: 0,
                ...response.data,
            };
        }

        return {
            type: 'view',
            status: 'empty',
            request: request,
            ...response.data,
        };
    }

    if (response.isError) {
        return {
            type: 'view',
            status: 'error',
            request: request,
            error: response.error,
        };
    }

    return {
        type: 'view',
        status: 'loading',
        request: request,
    };
};

export default useView;
