import React, { useState } from 'react';
import { RootState } from '../../../../../redux/store';

import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../../../../redux/actions/notification';

// Hooks
import { useDatesFormatted } from '../../../../../hooks/use-dates';
import { useSectionDashboard } from '../../../../context/section-dashboard-context';

// Types
import { ComparisonTableData, TableData, WidgetDialogLocation } from '../../../../types';
import { NotificationMessageType } from '../../../../../enums/notification-types';

// Helpers
import { exportTableData } from '../../../../helpers/export-data';

// Components
import WidgetMenuSeparatorVertical from '../widget-menu-separator-vertical';
import WidgetDialogFilter from '../../../dialog/widget-dialog-filter';
import WidgetMenuDropdown from '../widget-menu-dropdown';
import WidgetMenuPagination, { WidgetMenuPaginationDisplay } from '../widget-menu-pagination';
import WidgetMenuSeparatorHorizontal from '../widget-menu-separator-horizontal';
import WidgetBaseLink from '../../../base/widget-base-link';
import IconDownload from '../../../../../components/icons/download';

export type WidgetMenuTableProps = {
    data: TableData | ComparisonTableData;
};

const WidgetMenuTable = ({ data }: WidgetMenuTableProps) => {
    const title = useSelector((state: RootState) => state.pageMeta.title);
    const dispatch = useDispatch();
    const { width } = useSectionDashboard();

    const [isExporting, setIsExporting] = useState(false);
    const loading = data.status === 'loading';
    const success = data.status === 'success';
    const noData = data.status === 'empty' || data.status === 'error';

    // This Period Dates
    const { startDate, endDate } = useDatesFormatted({
        resource: data.status === 'success' ? data.resource : undefined,
    });

    // Comparison Period Dates
    const { startDate: comparisonStartDate, endDate: comparisonEndDate } = useDatesFormatted({
        resource: data.status === 'success' ? data.resource : undefined,
        isComparison: true,
    });

    const handleExportClick = () => {
        setIsExporting(true);
        exportTableData({
            title: title,
            dates: { startDate, endDate, comparisonStartDate, comparisonEndDate },
            data,
        })
            .then(() => {
                dispatch(addNotification({ copy: 'Data export complete.', type: NotificationMessageType.Success }));
            })
            .catch(e => {
                dispatch(
                    addNotification({
                        copy: 'Export has failed, please try again later.',
                        type: NotificationMessageType.Error,
                    })
                );
            })
            .finally(() => {
                setIsExporting(false);
            });
    };

    if (data.request) {
        const request = data.request;
        const hasFilters = noData && request.filters && request.filters.length > 0;

        if (!request.setFilters) {
            return null;
        }

        return (
            <>
                {width > 4 && (
                    <>
                        {(!noData || loading) && (
                            <>
                                <WidgetMenuPagination display={WidgetMenuPaginationDisplay.FOOTER} data={data} />
                                <WidgetMenuSeparatorVertical spacingLeft={false} spacingRight={false} />
                            </>
                        )}
                        {(success || hasFilters || loading) && (
                            <WidgetDialogFilter
                                filters={request.filters}
                                options={request.fields}
                                location={WidgetDialogLocation.Footer}
                                loading={loading}
                                onApplyClick={request.setFilters}
                            />
                        )}
                        {(!noData || loading) && (
                            <>
                                <WidgetMenuSeparatorVertical spacingLeft={false} spacingRight={false} />
                                <WidgetMenuDropdown loading={loading}>
                                    <WidgetBaseLink
                                        text="Export CSV"
                                        icon={<IconDownload />}
                                        location={WidgetDialogLocation.Dropdown}
                                        loading={loading}
                                        iconLoading={isExporting}
                                        onClick={handleExportClick}
                                    />
                                </WidgetMenuDropdown>
                            </>
                        )}
                    </>
                )}
                {((width < 5 && success) || (width < 3 && hasFilters) || (width < 3 && loading)) && (
                    <WidgetMenuDropdown loading={loading}>
                        {(!noData || loading) && (
                            <>
                                <WidgetMenuPagination display={WidgetMenuPaginationDisplay.DROPDOWN} data={data} />
                                <WidgetMenuSeparatorHorizontal />
                            </>
                        )}

                        {(success || hasFilters || loading) && (
                            <WidgetDialogFilter
                                filters={request.filters}
                                options={request.fields}
                                location={WidgetDialogLocation.Dropdown}
                                loading={loading}
                                onApplyClick={request.setFilters}
                            />
                        )}
                        {(!noData || loading) && (
                            <>
                                <WidgetMenuSeparatorHorizontal />
                                <WidgetBaseLink
                                    text="Export CSV"
                                    icon={<IconDownload />}
                                    location={WidgetDialogLocation.Dropdown}
                                    loading={loading}
                                    iconLoading={isExporting}
                                    onClick={handleExportClick}
                                />
                            </>
                        )}
                    </WidgetMenuDropdown>
                )}
            </>
        );
    }

    return null;
};

export default WidgetMenuTable;
