import React from 'react';
import styled from 'styled-components';

import { useSectionDashboard } from '../../context/section-dashboard-context';
import { useSectionDashboardWidget } from '../../context/section-dashboard-widget-context';

import { WidgetTheme } from '../../types';

const StyledContainer = styled.div<{ gridArea: string; widgetTheme: WidgetTheme }>`
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};
    grid-area: ${props => props.gridArea};
    background: linear-gradient(
        180deg,
        ${props => props.widgetTheme.gradientStart} 0%,
        ${props => props.widgetTheme.gradientEnd} 100%
    );
    border: 1px solid ${props => props.theme.sectionDashboard.widget.base.borderColor};
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
`;

export type WidgetBaseContainerProps = {
    children: JSX.Element[];
};

const WidgetBaseContainer = ({ children }: WidgetBaseContainerProps) => {
    const { area, sectionIndex, x, y, width, height } = useSectionDashboard();
    const { theme } = useSectionDashboardWidget();
    return (
        <StyledContainer
            gridArea={area}
            widgetTheme={theme}
            data-testid={`sd-widget-${sectionIndex}-x:${x}-y:${y}-width:${width}-height:${height}`}
        >
            {children}
        </StyledContainer>
    );
};

export default WidgetBaseContainer;
