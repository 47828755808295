import moment from 'moment';
// Helper
import { channelColours } from '../../../../helpers/colours';
import { generateTableParams } from '../../../../components/tables/helpers/table-helper';
import { formatDimensionFilters } from '../../../../helpers/dimension/dimension-helper';
import dataTypes from '../../../../filter-bar/enums/data-types';
import { ChartDateGranularity } from '../../../../configurations/common/chart-types';
import store from '../../../../redux/store';

export const generateGraphRequests = (
    config,
    dimensionFilters,
    page,
    resource,
    rowCount,
    searchValue,
    selectedProducts,
    selectedMetrics,
    selectedRows,
    tableGroupBy,
    tableOrderBy,
    tableOrderDir,
    xMetric,
    yMetrics,
    startDate,
    endDate,
    chartDateGranularity
) => {
    const requests = [];
    const duration = Math.round(moment.duration(endDate.diff(startDate)).asDays());

    let timeframe;

    if (xMetric.dataType === dataTypes.DATE_TIME) {
        timeframe = { key: 'graph_group_by_type', value: `${resource.dateDimension.rawName}__${chartDateGranularity}` };
    }

    if (xMetric.dataType === dataTypes.DATE) {
        if (chartDateGranularity === ChartDateGranularity.Day || chartDateGranularity === ChartDateGranularity.Month) {
            timeframe = {
                key: 'graph_group_by_type',
                value: `${resource.dateDimension.rawName}__${chartDateGranularity}`,
            };
        }
    }

    const groupby = { key: 'graph_group_by', value: xMetric.rawName };
    const orderby = { key: 'order_by', value: xMetric.rawName };

    const graphMetrics = yMetrics.map(yMetric => yMetric.rawName);
    const graphMetric = { key: 'graph_metrics', value: graphMetrics.join(',') };

    if (resource.graphGroupByOverride) {
        groupby.value = resource.graphGroupByOverride?.map(value => value?.rawName).join();
    }

    if (resource.graphOrderByOverride) {
        orderby.value = resource.graphOrderByOverride.rawName;
    }

    let graphParamOverrides = [
        groupby,
        { key: 'group_by', value: tableGroupBy[0].rawName },
        { key: 'graph', value: true },
        { key: 'pagination', value: false },
        { key: 'limit', value: duration === 1 ? 24 : duration },
        orderby,
        graphMetric,
        { key: 'totals', value: config.chart.showTotals ? config.chart.showTotals : false },
    ];

    timeframe && graphParamOverrides.push(timeframe);

    if (selectedRows.length < 1) {
        let params = mergeParams(
            [
                ...generateTableParams(
                    config,
                    dimensionFilters,
                    page,
                    resource,
                    rowCount,
                    searchValue,
                    selectedProducts,
                    tableGroupBy,
                    tableOrderBy,
                    tableOrderDir,
                    startDate,
                    endDate,
                    selectedMetrics
                ),
            ],
            [...graphParamOverrides]
        );
        if (config.chart.chartType === 'network') {
            params = params.filter(param => !Object.values(param).includes('limit'));
        }

        if (config.chart.extraParams) {
            config.chart.extraParams.forEach(param => {
                params.push(param);
            });
        }

        requests.push({
            resourceGroup: resource.category,
            resourceName: resource.id,
            params: params,
            filter: 'all',
            name: 'all',
            colour: channelColours[6].colour,
        });
    } else {
        let filters = formatDimensionFilters(dimensionFilters, resource);
        graphParamOverrides = graphParamOverrides.concat(filters);

        for (let row of selectedRows) {
            let field = tableGroupBy[0];
            // graph should use raw_value to display metrics if available, otherwise use value
            let data = row.data[tableGroupBy[0].rawName];
            let value = data.raw_value !== undefined ? data.raw_value : data.value;

            let key = `${field.rawName}${field.lookupTerm ? '__' + field.lookupTerm : ''}`;
            let params = mergeParams(
                [
                    ...generateTableParams(
                        config,
                        dimensionFilters,
                        page,
                        resource,
                        rowCount,
                        searchValue,
                        selectedProducts,
                        tableGroupBy,
                        tableOrderBy,
                        tableOrderDir,
                        startDate,
                        endDate,
                        selectedMetrics
                    ),
                ],
                [...graphParamOverrides, { key: key, value: value }]
            );

            if (config.chart.chartType === 'network') {
                params = params.filter(param => !Object.values(param).includes('limit'));
            }

            requests.push({
                resourceGroup: resource.category,
                resourceName: resource.id,
                params: params,
                row: row,
                colour: row.colour,
                filter: `${key}=${value}`,
                name: `${value}`,
            });
        }
    }
    return requests;
};

const mergeParams = (prevParams, newParams) => {
    const updatedParams = [...prevParams];
    for (let newParam of newParams) {
        const exists = prevParams.some(prevParam => prevParam.key === newParam.key);
        for (let prevParam of prevParams) {
            if (prevParam.key === newParam.key) {
                prevParam.value = newParam.value;
            }
        }
        if (!exists) {
            updatedParams.push(newParam);
        }
    }
    return updatedParams;
};

export const updateChartTooltipPointFormat = (config, row) => {
    const currencySymbol = store.getState().currency.symbol;
    config.chart.options.tooltip.pointFormat =
        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}<br/>';
    config.chart.options.plotOptions.series.dataLabels.format = '{point.y:.2f}';

    if (row.includes('%')) {
        config.chart.options.tooltip.pointFormat =
            '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%<br/>';
        config.chart.options.plotOptions.series.dataLabels.format = '{point.y:.2f}%';
    }
    if (row.includes(currencySymbol)) {
        config.chart.options.tooltip.pointFormat = `<span style="color:{point.color}">{point.name}</span>: <b>${currencySymbol}{point.y:.2f}<br/>`;

        config.chart.options.plotOptions.series.dataLabels.format = currencySymbol + '{point.y:.2f}';
    }

    return config;
};

export const sortChartData = (row, prevRow) => {
    if (row['y'] === prevRow['y']) {
        return 0;
    } else {
        return row['y'] > prevRow['y'] ? -1 : 1;
    }
};

export const getTheGraphGranularity = dates => {
    let graphGranularity;
    let duration = moment.duration(dates[1].diff(dates[0]));
    if (duration._data.days >= 1) {
        graphGranularity = ChartDateGranularity.Day;
    } else if (duration._data.hours >= 1) {
        graphGranularity = ChartDateGranularity.Hour;
    } else if (duration._data.minutes >= 1) {
        graphGranularity = ChartDateGranularity.Minute;
    } else if (duration._data.seconds >= 1) {
        graphGranularity = ChartDateGranularity.Second;
    }
    return graphGranularity;
};

export const setTickInterval = (config, xMetric, chartSeriesList, chartDateGranularity, date) => {
    if (config.chart?.setTickIntervalOverride) {
        return config.chart.setTickIntervalOverride(xMetric);
    }
    if (chartSeriesList.length < 1) return 0;
    const dateDiff = Math.round(moment.duration(date.endDate.diff(date.startDate)).asDays());
    const day = 24 * 3600 * 1000;
    const hour = 2 * 3600 * 1000;
    const month = 30 * 24 * 3600 * 1000;

    if (chartDateGranularity === ChartDateGranularity.Hour) {
        return hour;
    } else if (chartDateGranularity === ChartDateGranularity.Month) {
        return month;
    } else if (dateDiff < 30) {
        return day;
    } else {
        return day * Math.ceil(dateDiff / 30);
    }
};
