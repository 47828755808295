import React, { memo, RefObject } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import FilterSelectList from './filter-select-list';
import CommonButton from '../common/common-button';
import { FilterSelectFilter } from '../../filter-bar/types';

// Styles
const StyledFilterSelectHolder = styled('div')<{ showFilterSelect: boolean }>`
    box-shadow: 0 2px 4px rgb(0 0 0 / 16%), 0 1px 3px rgb(0 0 0 / 23%);
    overflow: hidden;
    position: absolute;
    list-style: none;
    transition: max-height 0.2s, visibility 0.2s ease-in;
    z-index: 16777271;
    background-color: #ffffff;
    top: 16px;
    left: 16px;
    line-height: 20px;
    visibility: ${props => (props.showFilterSelect ? 'visible' : 'hidden')};
    max-height: ${props => (props.showFilterSelect ? '512px' : '0')};
`;

const StyledFilterSelectContainer = styled.div`
    padding: 12px;
    max-height: 512px;
    position: relative;
`;

const StyledFilterSelectContainerTopButton = styled.div`
    display: flex;
    padding: 0.5rem;

    & button {
        height: 36px !important;
        flex: 1 1;
        margin-right: 0.25rem !important;
    }
`;

const StyledFilterSelectContainerBottomButton = styled.div`
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
`;

// Types
type FilterSelectDropDownProps = {
    filterRef: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined;
    showFilterSelect: boolean;
    handleFilterSelectAll: () => void;
    handleFilterClearAll: () => void;
    filterOptions: FilterSelectFilter[];
    handleFilterSelect: (selectedFilters: FilterSelectFilter) => void;
    handleClickInside: () => void;
    handleFilterApplyOnClick: (event: React.MouseEvent<HTMLElement>) => void;
    handleCancelOnClick: (event: React.MouseEvent<HTMLElement>) => void;
    enableFilterApplyButton: boolean;
    enableSingleSelection: boolean;
};

const FilterSelectDropDown = ({
    filterRef,
    showFilterSelect,
    handleFilterSelectAll,
    handleFilterClearAll,
    filterOptions,
    handleFilterSelect,
    handleClickInside,
    handleFilterApplyOnClick,
    handleCancelOnClick,
    enableFilterApplyButton,
    enableSingleSelection,
}: FilterSelectDropDownProps) => {
    return (
        <StyledFilterSelectHolder ref={filterRef} onClick={handleClickInside} showFilterSelect={showFilterSelect}>
            <StyledFilterSelectContainer>
                <StyledFilterSelectContainerTopButton>
                    {enableSingleSelection && (
                        <CommonButton onClick={() => handleFilterSelectAll()} value="SELECT ALL" />
                    )}
                    <CommonButton onClick={() => handleFilterClearAll()} value="CLEAR" type="danger" />
                </StyledFilterSelectContainerTopButton>
                <FilterSelectList filterOptions={filterOptions} handleFilterSelect={handleFilterSelect} />
                <StyledFilterSelectContainerBottomButton>
                    <CommonButton
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleFilterApplyOnClick(event);
                            handleCancelOnClick(event);
                        }}
                        disabled={!enableFilterApplyButton}
                        value="APPLY"
                    />
                    <CommonButton onClick={handleCancelOnClick} value="CANCEL" type="secondary" />
                </StyledFilterSelectContainerBottomButton>
                <div className="CLEAR"></div>
            </StyledFilterSelectContainer>
        </StyledFilterSelectHolder>
    );
};

export default memo(FilterSelectDropDown, isEqual);
