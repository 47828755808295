// React
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
// Redux
import { addNotification } from '../redux/actions/notification';
import { NotificationMessageType } from '../enums/notification-types';
// Components
import Form from '../components/form';
import ViewNotification from './notifications';
// Helpers
import {
    isEmail,
    isPassword,
    hasCapitalLetter,
    hasLowerCaseLetter,
    hasNumber,
    hasSpecialCharacter,
    hasPasswordValidCharacters,
    isString,
} from '../helpers/validator';
import { generateUrlDetail } from '../helpers/request-builder';
// Dependencies
import Axios from 'axios';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import imageLogoWhiteFull from '../assets/images/white-cubed-logo-full.png';

const ViewUserSignup = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const [characterLength, setCharacterLength] = useState(false);
    const [textHasCapitalLetter, setTextHasCapitalLetter] = useState(false);
    const [textHasLowerCaseLetter, setTextHasLowerCaseLetter] = useState(false);
    const [textHasNumber, setTextHasNumber] = useState(false);
    const [textHasSpecialCharacter, setTextHasSpecialCharacter] = useState(false);
    const [textHasValidCharacter, setTextHasValidCharacter] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState('');
    const [formInputs, setFormInputs] = useState({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        repeatPassword: '',
    });
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);

    let updateButtonDisabled = false;

    const onEmailChange = event => {
        setFormInputs({ ...formInputs, email: event.target.value });
    };

    const onFirstNameChange = event => {
        setFormInputs({ ...formInputs, firstName: event.target.value });
    };

    const onLastNameChange = event => {
        setFormInputs({ ...formInputs, lastName: event.target.value });
    };

    const onPasswordChange = event => {
        const pass = event.target.value;
        setCharacterLength(pass.length >= 8);
        setTextHasCapitalLetter(hasCapitalLetter(pass));
        setTextHasLowerCaseLetter(hasLowerCaseLetter(pass));
        setTextHasNumber(hasNumber(pass));
        setTextHasSpecialCharacter(hasSpecialCharacter(pass));
        setTextHasValidCharacter(hasPasswordValidCharacters(pass));
        setFormInputs({ ...formInputs, password: pass });
    };

    const onConfirmPasswordChange = event => {
        setFormInputs({ ...formInputs, repeatPassword: event.target.value });
    };

    const validator = () => {
        // Validate all the data the user has entered. Return true if the data passes validation.
        let emailErrorMessage = '',
            firstNameErrorMessage = '',
            lastNameErrorMessage = '',
            passwordErrorMessage = '',
            repeatPasswordErrorMessage = '',
            formError = false;

        if (!isEmail(formInputs.email || formInputs.email.length === 0)) {
            emailErrorMessage = 'Email Invalid';
            formError = true;
        }

        if (!isString(formInputs.firstName) || formInputs.firstName.length === 0) {
            firstNameErrorMessage = 'First Name Invalid';
            formError = true;
        }

        if (!isString(formInputs.lastName) || formInputs.lastName.length === 0) {
            lastNameErrorMessage = 'Last Name Invalid';
            formError = true;
        }

        if (formInputs.password !== formInputs.repeatPassword) {
            passwordErrorMessage = 'Passwords do not match';
            repeatPasswordErrorMessage = 'Passwords do not match';
            formError = true;
        }

        if (!isPassword(formInputs.password)) {
            passwordErrorMessage = 'Password Invalid';
            formError = true;
        }

        if (!isPassword(formInputs.repeatPassword)) {
            repeatPasswordErrorMessage = 'Password Invalid';
            formError = true;
        }

        if (formError === true) {
            setEmailErrorMessage(emailErrorMessage);
            setPasswordErrorMessage(passwordErrorMessage);
            setRepeatPasswordErrorMessage(repeatPasswordErrorMessage);
            setFirstNameErrorMessage(firstNameErrorMessage);
            setLastNameErrorMessage(lastNameErrorMessage);
            return false;
        }

        return true;
    };

    const onSignUpClick = () => {
        if (validator() === true) {
            setEmailErrorMessage('');
            setPasswordErrorMessage('');
            setRepeatPasswordErrorMessage('');
            setFirstNameErrorMessage('');
            setLastNameErrorMessage('');
            setLoading(true);

            const token = location.search.substring(1).split(/&|=/);

            const data = {
                first_name: formInputs.firstName,
                last_name: formInputs.lastName,
                username: formInputs.email,
                password1: formInputs.password,
                password2: formInputs.repeatPassword,
                email: formInputs.email,
            };

            Axios({
                method: 'POST',
                url: generateUrlDetail('user', 'signup', token[1], [], false),
                data: data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(res => {
                    // If the posts return a 200, send the users to the login page. (They should already be logged in and will be redirected to choose an account.)
                    if (res.status === 200) {
                        setRedirect(true);
                        dispatch(
                            addNotification({ copy: 'Sign up successful!', type: NotificationMessageType.Success })
                        );
                    }
                })
                .catch(() => {
                    // Anything other than a 200 means the user hasn't been "added" so Show the user a error message and keep them on the page
                    dispatch(
                        addNotification({
                            copy: 'There was a problem with your sign up, please ensure you sign up with the email address that received the invite. If the problem persists please contact support@cubed.email.',
                            type: NotificationMessageType.Error,
                        })
                    );
                    setLoading(false);
                });
        }
    };

    const renderForm = () => {
        const config = {
            columns: 2,
            fields: [
                {
                    label: 'Email',
                    type: 'email',
                    requiredField: true,
                    toolTipCopy: 'Enter a valid email address',
                    errorMessage: emailErrorMessage,
                    inputPlaceholder: 'Email...',
                    inputOnChange: onEmailChange,
                    inputValue: formInputs.email,
                },
                {
                    label: 'First Name',
                    type: 'text',
                    requiredField: true,
                    toolTipCopy: 'Enter your first name',
                    errorMessage: firstNameErrorMessage,
                    inputPlaceholder: 'First Name...',
                    inputOnChange: onFirstNameChange,
                    inputValue: formInputs.firstName,
                },
                {
                    label: 'Last Name',
                    type: 'text',
                    requiredField: true,
                    toolTipCopy: 'Enter your last name',
                    errorMessage: lastNameErrorMessage,
                    inputPlaceholder: 'Last Name...',
                    inputOnChange: onLastNameChange,
                    inputValue: formInputs.lastName,
                },
                {
                    label: 'Password',
                    type: 'password',
                    requiredField: true,
                    toolTipCopy:
                        'Password must have 8 or more characters, 1 capital letter, 1 lowercase letter, 1 number and 1 special character',
                    errorMessage: passwordErrorMessage,
                    inputPlaceholder: 'Password...',
                    inputOnChange: onPasswordChange,
                    inputValue: formInputs.password,
                },
                {
                    label: 'Confirm Password',
                    type: 'password',
                    requiredField: true,
                    toolTipCopy: 'Confirm your password',
                    errorMessage: repeatPasswordErrorMessage,
                    inputPlaceholder: 'Confirm Password...',
                    inputOnChange: onConfirmPasswordChange,
                    inputValue: formInputs.repeatPassword,
                },
                {
                    type: 'passwordRequirements',
                    characterLength: characterLength,
                    hasCapitalLetter: textHasCapitalLetter,
                    hasLowercaseLetter: textHasLowerCaseLetter,
                    hasNumber: textHasNumber,
                    hasSpecialCharacter: textHasSpecialCharacter,
                    hasValidCharacter: textHasValidCharacter,
                },
            ],
            buttons: [
                {
                    value: 'SignUp',
                    disabled: updateButtonDisabled,
                    onClick: onSignUpClick,
                    isLoading: loading,
                },
            ],
        };

        return <Form config={config} />;
    };

    const params = location.search.substring(1).split('&');
    const accountName = decodeURIComponent(params[1]).split('=')[1];

    if (redirect === true) {
        // Once the signup is successfull send the user to the login page.
        return <Navigate to="/" />;
    }

    return (
        <div className="user-signup">
            <img className="user-signup__background" src={imageLoginBackground} alt="Background" />
            <div className="user-signup__content">
                <img src={imageLogoWhiteFull} alt="Yard" />

                <div className="user-signup__form">
                    <h3>Signup</h3>
                    <p>
                        You have been invited to join {accountName}. However it appears you do not have a cubed account.
                        Use the form below to create an account.
                    </p>
                    {renderForm()}
                </div>
            </div>
            <ViewNotification />
        </div>
    );
};

export default ViewUserSignup;
