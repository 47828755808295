import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

// Types & ENUMS
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';
import { DropdownOption } from '../../types';

// Resources
import { CONFIG_DASH_REFERER, REALLOCATION_CONFIG } from '../../configurations/resources-config';

// Redux
import { useDispatch } from 'react-redux';
import { setModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';

// Components
import ModalNavigation from '../../components/modal-navigation';
import InputButton from '../../components/inputs/input-button';
import FormSelect from '../../components/form-fields/form-select';

// React Query
import usePostResource from '../../react-query/hooks/use-post-resource';
import { ConfigDataSuccess } from '../../react-query/types';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import buildDropdownOptions from '../../react-query/select-functions/build-dropdown-options';

const StyledAddContainer = styled.div`
    margin-top: 20px;
    background-color: white;
    padding: 1px 20px 20px 20px;
`;
const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
`;

const PageLayout = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch();

    const modalNavigationButtons = [
        {
            value: 'CLOSE',
            onClick: () => dispatch(setModal('ManageAttributionModels', {})),
            disabled: false,
            buttonTheme: ButtonThemes.Secondary,
        },
    ];

    return (
        <div>
            <ModalNavigation buttons={modalNavigationButtons} />
            <h2>Add Reallocation Channels</h2>
            <p>Add your Reallocation Channels.</p>
            {children}
        </div>
    );
};

const LayoutModalReallocationChannels = () => {
    const dispatch = useDispatch();

    // State - Edit Form Fields
    const [selectedChannel, setSelectedChannel] = useState('');

    const channelData = useFetchResource<ConfigDataSuccess, DropdownOption[]>({
        resource: CONFIG_DASH_REFERER,
        params: [
            { key: 'active', value: true },
            { key: 'order_by', value: 'name' },
        ],
        staleTime: 1000 * 60 * 5, // 5 minutes,
        select: (data: ConfigDataSuccess) => buildDropdownOptions({ data: data, labelField: 'name', valueField: 'id' }),
    });

    const reallocationChannelAddMutation = usePostResource({
        resource: REALLOCATION_CONFIG,
        data: { referer_id: selectedChannel, referer: `/api/config/referer/${selectedChannel}/`, active: true },
        handleOnSuccess: () => {
            dispatch(setModal('ManageAttributionModels', {}));
            dispatch(
                addNotification({
                    copy: 'Reallocation Channels Added successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while adding your Reallocation Channels. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const handleChangeChannel = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedChannel(event.target.value);
    };

    const handleOnSaveClick = () => {
        reallocationChannelAddMutation.mutate();
    };

    return (
        <PageLayout>
            <StyledAddContainer>
                <h2>Add Reallocation Channels</h2>

                <FormSelect
                    label="Channel:"
                    inputKeyValue="channel"
                    inputOptions={channelData.status === 'success' ? channelData.data : []}
                    inputOnChange={handleChangeChannel}
                    requiredField={true}
                    inputValue={selectedChannel}
                    errorMessage={selectedChannel === '' ? 'Channel is required.' : ''}
                />

                <StyledButtonContainer>
                    <InputButton
                        value="SAVE"
                        onClick={handleOnSaveClick}
                        isLoading={reallocationChannelAddMutation.isPending}
                        disabled={selectedChannel === ''}
                    />
                </StyledButtonContainer>
            </StyledAddContainer>
        </PageLayout>
    );
};

export default LayoutModalReallocationChannels;
