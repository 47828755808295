import React, { memo } from 'react';

// Core Dependencies
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Components
import BreadcrumbHome from '../components/breadcrumb-home';
import BreadcrumbSearchBar from '../components/breadcrumb-search-bar';

// Styles
const StyledBreadcrumbs = styled.div`
    text-transform: uppercase;
    margin: -17px 10px 30px 14px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
`;

// Types
export type SearchBreadcrumbsWidgetProps = {
    searchValue: string;
    onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    homeOnClick: () => void;
};

const SearchBreadcrumbsWidget = ({ searchValue, onSearchChange, homeOnClick }: SearchBreadcrumbsWidgetProps) => {
    return (
        <StyledBreadcrumbs>
            <BreadcrumbSearchBar onChange={onSearchChange} value={searchValue} />
            <BreadcrumbHome onClick={homeOnClick} backgroundColor="grey" />
        </StyledBreadcrumbs>
    );
};

export default memo(SearchBreadcrumbsWidget, isEqual);
