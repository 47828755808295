// React Dependencies
import React from 'react';
import styled from 'styled-components';
import InputDatePicker from '../inputs/input-date-picker';
import Tooltip from '../tooltip';

const StyledFormField = styled.div`
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 15px;
`;

const StyledFormFieldTop = styled.div`
    margin-bottom: 5px;
`;

const StyledLabel = styled.label`
    font-size: 1em;
    font-weight: 400;
`;

const StyledRequiredField = styled.span`
    color: ${props => props.theme.colours.red};
`;

const StyledErrorMessage = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colours.red};
    margin-bottom: 1em;
`;

export type FormDatePickerProps = {
    label?: string;
    requiredField?: boolean;
    inputKeyValue: string;
    inputValue: string;
    inputOnChange: () => void;
    toolTipCopy?: string;
    errorMessage?: string;
};

const FormDatePicker = ({
    label,
    requiredField,
    inputKeyValue,
    inputValue,
    inputOnChange,
    toolTipCopy,
    errorMessage,
}: FormDatePickerProps) => {
    return (
        <StyledFormField key={inputKeyValue}>
            <StyledFormFieldTop>
                <StyledLabel>
                    {label} {requiredField && <StyledRequiredField>*</StyledRequiredField>}
                </StyledLabel>
                {toolTipCopy && <Tooltip copy={toolTipCopy} />}
            </StyledFormFieldTop>

            <InputDatePicker value={inputValue} onChange={inputOnChange} />

            {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        </StyledFormField>
    );
};

export default FormDatePicker;
