import React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { inputStyles } from './styles/input-styles';

// Context
import { FieldError, useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

// Validation
import buildValidators from '../validation/helpers/build-validators';
import { Validator } from '../types';

// Components
import InputContainer from './components/input-container';
import InputErrorMessage from './components/input-error-message';

const StyledInput = styled.input<{ disabled: boolean; theme: DefaultTheme }>`
    ${inputStyles}

    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
        filter: ${props => props.theme.name === 'dark' && 'invert(1)'};
    }
`;

export type InputDatePickerProps = {
    name: string;
    disabled?: boolean;
    validators?: Validator[];
};

const InputDatePicker = ({ name, disabled, validators = [] }: InputDatePickerProps) => {
    const theme = useTheme();
    const { status } = useCubedFormContext();

    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <InputContainer>
            <StyledInput
                type="date"
                disabled={!!disabled || status === 'disabled' || status === 'loading'}
                theme={theme}
                {...register(name, { ...buildValidators(validators) })}
            />
            {errors[name] && <InputErrorMessage error={errors[name] as FieldError} />}
        </InputContainer>
    );
};

export default InputDatePicker;
