import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CONFIG_BROWSER, CONFIG_DEVICE, GEOLOCATION_CONFIG } from '../../configurations/resources-config';

// Hooks
import useFetchResource from '../../react-query/hooks/use-fetch-resource';

// Types
import { ConfigDataSuccess } from '../../react-query/types';
import { CubedConfigResource } from '../../types';
import { Params } from '../../section-dashboard/types';

// Components
import LoadingSpinner from '../../components/loading-spinner';
import InputSearch from '../../components/inputs/input-search';

const StyledContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 30px;
    margin-top: 10px;
`;

const StyledH2 = styled.h2`
    font-size: 1.1rem;
    margin-top: 0;
`;

const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border: 1px solid ${props => props.theme.colours.lightGrey};
    border-bottom: none;
    border-right: none;
    margin-top: 20px;
`;

const StyledGridItem = styled.div`
    padding: 5px 10px;
    border: 1px solid ${props => props.theme.colours.lightGrey};
    border-top: none;
    border-left: none;
    font-size: 0.9rem;
    font-weight: 400;
`;

type FunctionReferenceQuery = {
    resource?: CubedConfigResource;
    params?: Params;
    enabled: boolean;
    select?: (data: ConfigDataSuccess) => any;
};

type FunctionReferenceItem = {
    label?: string;
    functionId?: number;
    query: FunctionReferenceQuery;
};

type FunctionReferenceProps = {
    selectedFunction?: {
        value: number;
        label: string;
    };
};

const FunctionReference = ({ selectedFunction }: FunctionReferenceProps) => {
    const [currentReference, setCurrentReference] = useState<FunctionReferenceItem>({
        query: { enabled: false },
    });
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        if (selectedFunction) {
            let query: FunctionReferenceQuery = { enabled: false };

            switch (selectedFunction?.value) {
                case 22:
                    query = {
                        resource: CONFIG_DEVICE,
                        params: [{ key: 'order_by', value: 'os_family' }],
                        enabled: selectedFunction ? selectedFunction.value === 22 : false,
                        select: (data: ConfigDataSuccess) => {
                            const objects = data.objects.filter(
                                (item, index, objects) =>
                                    objects.findIndex(v2 => v2.os_family === item.os_family) === index
                            );
                            return objects.map(object => {
                                return {
                                    name: object.os_family,
                                };
                            });
                        },
                    };
                    break;
                case 23:
                    query = {
                        resource: CONFIG_BROWSER,
                        params: [{ key: 'order_by', value: 'browser_family' }],
                        enabled: selectedFunction ? selectedFunction.value === 23 : false,
                        select: (data: ConfigDataSuccess) => {
                            const objects = data.objects.filter(
                                (item, index, objects) =>
                                    objects.findIndex(v2 => v2.browser_family === item.browser_family) === index
                            );
                            return objects.map(object => {
                                return { name: object.browser_family };
                            });
                        },
                    };
                    break;
                case 24:
                    query = {
                        resource: GEOLOCATION_CONFIG,
                        params: [{ key: 'order_by', value: 'city_name' }],
                        enabled: selectedFunction ? selectedFunction.value === 24 : false,
                        select: (data: ConfigDataSuccess) => {
                            const objects = data.objects.filter(
                                (item, index, objects) =>
                                    objects.findIndex(v2 => v2.city_name === item.city_name) === index
                            );
                            return objects.map(object => {
                                return { name: object.city_name };
                            });
                        },
                    };
                    break;
                case 25:
                    query = {
                        resource: GEOLOCATION_CONFIG,
                        params: [{ key: 'order_by', value: 'country_name' }],
                        enabled: selectedFunction ? selectedFunction.value === 25 : false,
                        select: (data: ConfigDataSuccess) => {
                            const objects = data.objects.filter(
                                (item, index, objects) =>
                                    objects.findIndex(v2 => v2.country_name === item.country_name) === index
                            );
                            return objects.map(object => {
                                return { name: object.country_name };
                            });
                        },
                    };
                    break;
                case 26:
                    query = {
                        resource: GEOLOCATION_CONFIG,
                        params: [{ key: 'order_by', value: 'continent_name' }],
                        enabled: selectedFunction ? selectedFunction.value === 26 : false,
                        select: (data: ConfigDataSuccess) => {
                            const objects = data.objects.filter(
                                (item, index, objects) =>
                                    objects.findIndex(v2 => v2.continent_name === item.continent_name) === index
                            );
                            return objects.map(object => {
                                return { name: object.continent_name };
                            });
                        },
                    };
                    break;
                default:
                    query = { enabled: false };
            }

            setCurrentReference({
                label: selectedFunction?.label,
                functionId: selectedFunction?.value,
                query: query,
            });
        } else {
            setCurrentReference({ query: { enabled: false } });
        }
    }, [selectedFunction]);

    const referenceItems = useFetchResource(currentReference.query);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    if (referenceItems.status !== 'error') {
        if (currentReference.functionId && currentReference.query.enabled) {
            return (
                <StyledContainer>
                    <StyledH2>Reference</StyledH2>
                    <p>
                        This reference table is for guidance as to the "{currentReference.label}" values we see in our
                        database. Please enter a value in the form above.
                    </p>

                    {referenceItems.status === 'success' ? (
                        <>
                            <InputSearch value={searchTerm} onChange={handleSearchChange} />
                            <StyledGrid>
                                {referenceItems.data
                                    .filter((data: { name: string }) =>
                                        data.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    )
                                    .map((item: { name: string }) => (
                                        <StyledGridItem key={item.name}>{item.name}</StyledGridItem>
                                    ))}
                            </StyledGrid>
                        </>
                    ) : null}

                    {referenceItems.status === 'pending' ? <LoadingSpinner /> : null}
                </StyledContainer>
            );
        }
    }
};

export default FunctionReference;
