// React Dependencies
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldValues } from 'react-hook-form';

// Types
import { ConfigDataSuccess } from '../../react-query/types';

// Redux
import { RootState } from '../../redux/reducers/core';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../redux/actions/notification';

// Layouts
import { ErrorModalLayout, ModalLayout } from './modal-layout';

// Queries
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import usePostMultiResourcesWithPayload from '../../react-query/hooks/use-post-multi-resource-with-payload';

// Configurations
import { CONFIG_CURRENCY, CONFIG_TIMEZONE, CONFIG_TIMEZONE_CURRENCY } from '../../configurations/resources-config';

// Components
import Form from '../../components/forms/form';
import validators from '../../components/forms/validation/validators';
import { TooltipPopUpSide } from '../../components/tooltip';
import { FormStatus } from '../../components/forms/context/form-context';

// Enums
import { NotificationMessageType } from '../../enums/notification-types';

// Helpers
import { generatePath } from '../../helpers/request-builder';

// Styles
const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

const StyledFormTitle = styled.h3`
    margin: 0 0 30px 0;
`;

// Types
type Option = {
    value: string;
    label: string;
    selected?: boolean;
};

type TimezoneCurrency = {
    timezone: string;
    currency: string;
    convertToGbp: boolean;
};

const LayoutModalManageTimezoneAndCurrency = () => {
    const dispatch = useDispatch();

    const [timezoneOptions, setTimezoneOptions] = useState<Option[]>([]);
    const [currencyOptions, setCurrencyOptions] = useState<Option[]>([]);

    const [selectedTimezoneCurrency, setSelectedTimezoneCurrency] = useState<TimezoneCurrency>();
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

    const { account, user } = useSelector((state: RootState) => state);

    // Queries & Mutations
    const timezoneDataQuery = useFetchResource({
        resource: CONFIG_TIMEZONE,
        params: [
            {
                key: 'account_id',
                value: account.id,
            },
            {
                key: 'limit',
                value: 0,
            },
            {
                key: 'order_by',
                value: 'display_name',
            },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(zone => {
                return {
                    value: zone.id,
                    label: zone.display_name,
                };
            });
        },
    });

    const currencyDataQuery = useFetchResource({
        resource: CONFIG_CURRENCY,
        params: [
            {
                key: 'account_id',
                value: account.id,
            },
            {
                key: 'limit',
                value: 0,
            },
            {
                key: 'order_by',
                value: 'iso',
            },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(currency => {
                return {
                    value: currency.id,
                    label: currency.iso + ' - ' + currency.currency,
                };
            });
        },
    });

    const timezoneCurrencyDataQuery = useFetchResource({
        resource: CONFIG_TIMEZONE_CURRENCY,
        params: [
            {
                key: 'cubed_user_id',
                value: user.id,
            },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(timezoneCurrency => {
                return {
                    timezone: timezoneCurrency.timezone,
                    currency: timezoneCurrency.currency,
                    convertToGbp: timezoneCurrency.convert_to_gbp,
                };
            });
        },
    });

    const timezoneCurrencyDataAddMutation = usePostMultiResourcesWithPayload({
        resource: CONFIG_TIMEZONE_CURRENCY,
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'Timezone and Currency successfully Added.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue adding the Timezone and Currency, please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    // Fetch Timezone Data
    useEffect(() => {
        if (timezoneDataQuery.data) {
            setTimezoneOptions(timezoneDataQuery.data);
        }
    }, [timezoneDataQuery.data]);

    // Fetch Currency Data
    useEffect(() => {
        if (currencyDataQuery.data) {
            setCurrencyOptions(currencyDataQuery.data);
        }
    }, [currencyDataQuery.data]);

    // Fetch Saved Timezone Currency Data
    useEffect(() => {
        if (timezoneCurrencyDataQuery.data) {
            const timezoneId = timezoneCurrencyDataQuery.data[0]?.timezone?.id || '';
            const currencyId = timezoneCurrencyDataQuery.data[0]?.currency?.id || '';

            setSelectedTimezoneCurrency({
                timezone: timezoneId,
                currency: currencyId,
                convertToGbp: timezoneCurrencyDataQuery.data[0]?.convertToGbp || false,
            });

            setIsFormDisabled(timezoneId !== '' && currencyId !== '');
        }
    }, [timezoneCurrencyDataQuery.data]);

    // Handlers
    const handleSubmitForm = (data: FieldValues) => {
        const timezoneCurrencyPayload = {
            timezone: generatePath('config', 'timezone', data.timezone),
            currency: generatePath('config', 'currency', data.currency),
            convert_to_gbp: data.convertToGbp,
            cubed_user_id: user.id,
            has_created: true,
        };

        timezoneCurrencyDataAddMutation.mutate([timezoneCurrencyPayload]);
    };

    if (timezoneCurrencyDataQuery.status === 'success' || timezoneCurrencyDataQuery.status === 'pending') {
        return (
            <ModalLayout modalHeader="Add Timezone and Currency">
                <div>
                    <p>
                        Configure your accounts timezone and currency settings. The timezone set will control all time
                        based dimensions and time conversions. The currency set will be used for currency conversions.
                        These changes will apply to your entire account and cannot be undone.
                    </p>
                </div>

                <StyledFormContainer>
                    <StyledFormTitle>Add Timezone and Currency *</StyledFormTitle>
                    <Form
                        status={timezoneCurrencyDataQuery.status as FormStatus}
                        onSubmit={handleSubmitForm}
                        defaultValues={selectedTimezoneCurrency}
                    >
                        <Form.Body>
                            <Form.Section>
                                <Form.Field>
                                    <Form.InputLabel
                                        htmlFor="timezone"
                                        label="Timezone"
                                        required={true}
                                        tooltipCopy="Select a timezone for your account to report in."
                                        tooltipPosition={TooltipPopUpSide.Top}
                                    />
                                    <Form.InputSelect
                                        name="timezone"
                                        options={timezoneOptions}
                                        placeholder="Timezone..."
                                        validators={[validators.required]}
                                        disabled={timezoneCurrencyDataQuery.isPending || isFormDisabled}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.InputLabel
                                        htmlFor="currency"
                                        label="Currency"
                                        required={true}
                                        tooltipCopy="Select the main currency for your account."
                                        tooltipPosition={TooltipPopUpSide.Top}
                                    />
                                    <Form.InputSelect
                                        name="currency"
                                        options={currencyOptions}
                                        placeholder="Currency..."
                                        validators={[validators.required]}
                                        disabled={timezoneCurrencyDataQuery.isPending || isFormDisabled}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <Form.InputCheckbox
                                        name="convertToGbp"
                                        label="Convert to GBP"
                                        disabled={timezoneCurrencyDataQuery.isPending || isFormDisabled}
                                    />
                                </Form.Field>
                            </Form.Section>
                            <Form.Section>
                                <Form.Field>
                                    <></>
                                </Form.Field>
                            </Form.Section>
                        </Form.Body>
                        {!isFormDisabled && (
                            <Form.Footer>
                                <Form.InputButton
                                    value="Save"
                                    disabled={
                                        timezoneCurrencyDataQuery.isPending || timezoneCurrencyDataAddMutation.isPending
                                    }
                                />
                            </Form.Footer>
                        )}
                    </Form>
                </StyledFormContainer>
            </ModalLayout>
        );
    }

    // Page Error
    return <ErrorModalLayout modalHeader="Add Timezone and Currency" />;
};

export default LayoutModalManageTimezoneAndCurrency;
