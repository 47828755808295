import React from 'react';
import Form from '../../../../components/forms/form';
import { ButtonThemes } from '../../../../enums/button-themes';
import { DropdownOption } from '../../../../section-dashboard/types';

const FormEditUser = ({
    roles,
    isPending,
    handleCancelClick,
}: {
    roles: DropdownOption[];
    isPending: boolean;
    handleCancelClick: () => void;
}) => {
    return (
        <>
            <Form.Body>
                <Form.Section>
                    <Form.Field>
                        <Form.InputLabel htmlFor="role" label="Role" />
                        <Form.InputSelect name="role" options={roles} disabled={isPending} />
                    </Form.Field>
                </Form.Section>
            </Form.Body>
            <Form.Footer>
                <Form.InputButton type="submit" value="Save Changes" loading={isPending} />
                <Form.InputButton
                    type="button"
                    value="Cancel"
                    buttonTheme={ButtonThemes.Secondary}
                    onClick={handleCancelClick}
                />
            </Form.Footer>
        </>
    );
};

export default FormEditUser;
