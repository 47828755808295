import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

// Redux & Context
import { useSelector } from 'react-redux';
import { TableGraphContext } from '../../context/table-graph-context';

// Helpers & Enums
import dataTypes from '../../../filter-bar/enums/data-types';
import { setTickInterval } from './helpers/chart-helper';
import { formatAnnotationsChartConfig } from './helpers/format-annotations';

// Components
import Message from '../../../components/message';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Highcharts

import Highcharts from 'highcharts';
import AnnotationsModule from 'highcharts/modules/annotations';
AnnotationsModule(Highcharts);

const StyledLineChart = styled.div`
    min-height: 310px;
    min-width: 0px;
    position: relative;
    opacity: ${props => props.isLoading && 0.5};
    transition: ${props => props.isLoading && props.theme.transition};
    flex-grow: 1;
    flex-basis: 20em;
`;

const LineChart = ({
    chartYMetrics,
    config,
    xMetric,
    messageTitle = 'No data.',
    messageCopy = '',
    messageType = 'info',
    chartSeriesList,
    noData,
    chartLoading,
    chartDateGranularity,
    scatterPlotList,
    dates,
}) => {
    // State
    const [chartSeriesListData, setChartSeriesListData] = useState(null);
    const [scatterDataList, setScatterDataList] = useState();
    const [tick, setTick] = useState();
    const [annotationConfig, setAnnotationConfig] = useState([]);

    // Redux
    const { date } = useSelector(state => state);

    // Context
    const tableGraphContext = useContext(TableGraphContext);
    const { annotations } = tableGraphContext;

    useEffect(() => {
        setAnnotationConfig(formatAnnotationsChartConfig(annotations));
    }, [annotations]);

    useEffect(() => {
        if (!chartLoading) {
            setChartSeriesListData(chartSeriesList);
            if (scatterPlotList) {
                setScatterDataList(scatterPlotList);
                config.chart.options.tooltip = {
                    formatter: function () {
                        const date = new Date(this.x);
                        return (
                            '<br/><br/> ' +
                            '<b>Day: </b>' +
                            date.toDateString() +
                            '<br/>' +
                            '<b>Metric Value: </b>' +
                            this.y +
                            '<br/><br/>'
                        );
                    },
                };
            }
        }

        setTick(setTickInterval(config, xMetric, chartSeriesList, chartDateGranularity, date));
    }, [chartSeriesList]); // eslint-disable-line react-hooks/exhaustive-deps

    config.chart.options.xAxis.tickInterval = tick;
    config.chart.options.xAxis.labels.enabled = true;

    if (chartYMetrics && chartYMetrics.length > 0) {
        config.chart.options.yAxis.reversed = chartYMetrics[0].flipYAxis ? true : false;
    }
    // set the x-axis label formatting depending on x-axis data type
    [dataTypes.DATE, dataTypes.DATE_TIME].includes(xMetric.dataType)
        ? (config.chart.options.xAxis.type = 'datetime')
        : (config.chart.options.xAxis.type = 'linear');

    let seriesForPlot = scatterDataList
        ? chartSeriesListData.concat({
              type: 'scatter',
              name: 'Anomaly',
              color: 'rgba(255, 0, 0, 0.8)',
              data: scatterDataList,
              marker: {
                  radius: 5,
                  symbol: 'circle',
              },
          })
        : chartSeriesListData;

    if (xMetric.dataType === dataTypes.DATE && dates.startDate === dates.endDate) {
        return (
            <StyledLineChart>
                <Message
                    title="Not enough data."
                    copy="Singular day selected. Please select a date range if you want to view a line chart."
                    type="info"
                />
            </StyledLineChart>
        );
    }

    return (
        <StyledLineChart isLoading={chartLoading}>
            {!noData ? (
                <CommonHighcharts
                    highcharts={Highcharts}
                    options={{
                        ...config.chart.options,
                        series: seriesForPlot,
                        annotations: annotationConfig,
                    }}
                    immutable={false}
                />
            ) : (
                <Message title={messageTitle} copy={messageCopy} type={messageType} />
            )}
        </StyledLineChart>
    );
};
export default LineChart;
