import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';

export const config = {
    pageTitle: 'Sku Performance',
    dateDimension: fields.SALES_DATE_DATE,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: true,
            isEnableMetrics: true,
        },
    },
    resources: [
        resources.REPORT_MCS_SKU_CHANNEL,
        resources.REPORT_MCS_SKU_UTM,
        resources.REPORT_MCS_SKU_REFERRER,
        resources.REPORT_MCS_SKU_LANDING,
        resources.REPORT_MCS_SKU_PPC,
    ],
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'sku-performance-chart',
        allYMetrics: [
            fields.LC_SALES,
            fields.FM_SALES,
            fields.SALES_VARIANCE,
            fields.SALES_VARIANCE_PCT,
            fields.LC_REVENUE,
            fields.FM_REVENUE,
            fields.REVENUE_VARIANCE,
            fields.REVENUE_VARIANCE_PCT,
            fields.LC_AOV,
            fields.FM_AOV,
        ],
        initialYMetrics: [fields.LC_SALES],
    },
    table: {
        tabListItems: [
            {
                tabName: 'overview',
                displayName: 'Overview',
                isSelected: true,
                metrics: [
                    fields.LC_SALES,
                    fields.FM_SALES,
                    fields.LC_REVENUE,
                    fields.FM_REVENUE,
                    fields.LC_AOV,
                    fields.FM_AOV,
                ],
            },
            {
                tabName: 'sales_var',
                displayName: 'Sales Variance',
                metrics: [fields.LC_SALES, fields.FM_SALES, fields.SALES_VARIANCE, fields.SALES_VARIANCE_PCT],
            },
            {
                tabName: 'rev_var',
                displayName: 'Revenue Variance',
                metrics: [fields.LC_REVENUE, fields.FM_REVENUE, fields.REVENUE_VARIANCE, fields.REVENUE_VARIANCE_PCT],
            },
            {
                tabName: 'all',
                displayName: 'All',
                metrics: [
                    fields.LC_SALES,
                    fields.FM_SALES,
                    fields.SALES_VARIANCE,
                    fields.SALES_VARIANCE_PCT,
                    fields.LC_REVENUE,
                    fields.FM_REVENUE,
                    fields.REVENUE_VARIANCE,
                    fields.REVENUE_VARIANCE_PCT,
                    fields.LC_AOV,
                    fields.FM_AOV,
                ],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.REFERER.id]: {
                default: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_REFERRER,
                        field: fields.REFERRER_DOMAIN,
                    },
                ],
                PPC: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_PPC,
                        field: fields.PPC_CAMPAIGN_NAME,
                    },
                ],
                SEO: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_LANDING,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
                Direct: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_LANDING,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
            },
            [fields.SUBCHANNEL.id]: {
                default: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_REFERRER,
                        field: fields.REFERRER_DOMAIN,
                    },
                ],
                PPC: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_PPC,
                        field: fields.PPC_CAMPAIGN_NAME,
                    },
                ],
                SEO: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_LANDING,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
                Direct: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_LANDING,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
            },
            [fields.SUBCHANNEL.id]: {
                default: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_REFERRER,
                        field: fields.REFERRER_DOMAIN,
                    },
                ],
                PPC: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_PPC,
                        field: fields.PPC_CAMPAIGN_NAME,
                    },
                ],
                SEO: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_LANDING,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
                Direct: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_LANDING,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
            },
            [fields.UTM_SOURCE.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SKU_UTM,
                        field: fields.UTM_MEDIUM,
                    },
                ],
            },
            [fields.UTM_MEDIUM.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SKU_UTM,
                        field: fields.UTM_CAMPAIGN,
                    },
                ],
            },
            [fields.UTM_CAMPAIGN.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SKU_UTM,
                        field: fields.UTM_TERM,
                    },
                ],
            },
            [fields.UTM_TERM.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SKU_UTM,
                        field: fields.UTM_CONTENT,
                    },
                ],
            },
            [fields.REFERRER_DOMAIN.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SKU_REFERRER,
                        field: fields.REFERRER_PATH,
                    },
                ],
            },
            [fields.LANDING_PATH_HOST.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SKU_LANDING,
                        field: fields.LANDING_PATH_PATH,
                    },
                ],
            },
            [fields.PPC_CAMPAIGN_NAME.id]: {
                default: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_PPC,
                        field: fields.PPC_AD_GROUP_NAME,
                    },
                ],
            },
            [fields.PPC_AD_GROUP_NAME.id]: {
                default: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                    {
                        resource: resources.REPORT_MCS_SKU_PPC,
                        field: fields.PPC_KEYWORD,
                    },
                ],
            },
            [fields.PPC_KEYWORD.id]: {
                default: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_CATEGORY,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                ],
            },
            [fields.ECOMM_CATEGORY.id]: {
                default: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_NAME,
                    },
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                ],
            },
            [fields.ECOMM_NAME.id]: {
                default: [
                    {
                        useCurrent: true,
                        field: fields.ECOMM_SKU,
                    },
                ],
            },
            [fields.ECOMM_SKU.id]: {
                default: [
                    {
                        useCurrent: true,
                        field: fields.REFERER,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
