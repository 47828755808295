export const initialiseButtonSides = (currentPage, maxPage, idealSideLength) => {
    let prePages = [];
    let postPages = [];

    for (let i = 1; i <= idealSideLength; i++) {
        if (currentPage - i >= 1) prePages.unshift({ pageNumber: currentPage - i });
        if (currentPage + i <= maxPage) postPages.push({ pageNumber: currentPage + i });
    }

    if (prePages.length < idealSideLength) {
        let postPageCount = postPages.length;
        for (let i = 1; i <= idealSideLength - prePages.length; i++) {
            postPages.push({ pageNumber: currentPage + i + postPageCount });
        }
    }

    if (postPages.length < idealSideLength) {
        let prePageCount = prePages.length;
        for (let i = 1; i <= idealSideLength - postPages.length; i++) {
            prePages.unshift({ pageNumber: currentPage - i - prePageCount });
        }
    }

    if (prePages.length > 0) prePages[0] = { pageNumber: 1 };
    if (prePages[1] && prePages[1]['pageNumber'] > 2) prePages[1] = {};
    if (postPages.length > 0) postPages[postPages.length - 1] = { pageNumber: maxPage };
    if (postPages[postPages.length - 2] && postPages[postPages.length - 2]['pageNumber'] < maxPage - 1)
        postPages[postPages.length - 2] = {};

    return [prePages, postPages];
};

export const getMaxPage = (totalResults, currentRowCount) => {
    return Math.ceil(totalResults / currentRowCount);
};

export const generateButtonList = (currentPage, maxButtons, minPage, totalResults, currentRowCount) => {
    let pages = [],
        prePages = [],
        postPages = [];
    let idealSideLength = Math.floor(maxButtons / 2);

    if (getMaxPage(totalResults, currentRowCount) === 1) pages = [{ pageNumber: 1 }];
    else if (getMaxPage(totalResults, currentRowCount) < maxButtons) {
        pages = Array.from(Array(getMaxPage(totalResults, currentRowCount) - minPage + 1).keys()).map(value => ({
            pageNumber: value + 1,
            isCurrent: currentPage === value + 1,
        }));
    } else {
        [prePages, postPages] = initialiseButtonSides(
            currentPage,
            getMaxPage(totalResults, currentRowCount),
            idealSideLength
        );
        pages = [...prePages, { pageNumber: currentPage, isCurrent: true }, ...postPages];
    }

    return pages;
};

export const isActivePaginateAction = (currentPage, minPage, maxPage) => {
    return {
        minAction: currentPage > minPage,
        maxAction: currentPage < maxPage,
    };
};
