// React Dependencies
import React, { Component } from 'react';

// Required Components
import Form from '../form';

class AccordionForm extends Component {
    render() {
        return (
            <div className="accordion__item-content__form">
                <p className="accordion__item-content__form__copy">{this.props.config.copy}</p>

                <Form config={this.props.config.formConfig} />
            </div>
        );
    }
}

export default AccordionForm;
