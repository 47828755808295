import React, { Component } from 'react';
import LayoutTableGraph from '../table-graph/layouts/table-graph';
import { config } from '../configurations/lc-vs-attribution-reallocation-atl';

class ReportLcVsAttributionReallocationATL extends Component {
    constructor(props) {
        super(props);
        this.config = config;
    }

    render() {
        return (
            <LayoutTableGraph
                hash={this.props.location.hash}
                fetchProducts={this.props.fetchProducts}
                config={this.config}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedProducts={this.props.selectedProducts}
            />
        );
    }
}

export default ReportLcVsAttributionReallocationATL;
