// Helpers
import { unknownErrorData } from '../../helpers/errors';

// Types
import { CubedField } from '../../../types';
import { FunnelChartData, FunnelChartSeries, FunnelVarianceData, ViewComparisonData } from '../../types';

// Helper
import { graphColours } from '../../helpers/graph-colours';
import { calculateVariance } from '../../helpers/variance-helpers';

export type useResourceFunnelComparisonChartArgs = {
    resourceData: ViewComparisonData;
    metric: CubedField;
};

const useResourceFunnelComparisonChart = ({
    resourceData,
    metric,
}: useResourceFunnelComparisonChartArgs): FunnelChartData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        let series: FunnelChartSeries[] = [];
        let comparisonSeries: FunnelChartSeries[] = [];
        let varianceData: FunnelVarianceData[] = [];
        let total = 0;

        if (resource.status === 'success') {
            series = resource.series.map((seriesData, index) => {
                return {
                    ...seriesData,
                    color: graphColours[index].solidGraphColour,
                };
            });
        }

        if (comparisonResource.status === 'success') {
            comparisonSeries = comparisonResource.series.map((seriesData, index) => {
                return {
                    ...seriesData,
                    color: graphColours[index].solidGraphColour,
                };
            });
        }

        if (resource.status === 'success' && comparisonResource.status === 'success') {
            varianceData = series.map((seriesData, index) => {
                return {
                    name: seriesData.name,
                    colour: graphColours[index].solidGraphColour,
                    variance: calculateVariance(seriesData.y, comparisonSeries[index].y),
                };
            });

            total = resource.total + comparisonResource.total;
        }

        return {
            type: 'funnelChart',
            status: 'success',
            series: series,
            comparisonSeries: comparisonSeries,
            varianceData: varianceData,
            total: total,
            name: metric.displayName,
        };
    }

    return unknownErrorData();
};

export default useResourceFunnelComparisonChart;
