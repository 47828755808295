import React from 'react';
import styled from 'styled-components';

const StyledListItem = styled.tr`
    border-bottom: 1px solid ${props => props.theme.dashboardBuilder.library.libraryList.listItemBorderColor};

    &:last-child {
        border-bottom: none;
    }
`;

const LibraryListListItem = ({ children }: { children: React.ReactNode }) => {
    return <StyledListItem>{children}</StyledListItem>;
};

export default LibraryListListItem;
