import React from 'react';
import styled from 'styled-components';

// Image
import IconSearch from '../../assets/images/icons/search.png';

const StyledInput = styled.input<{ disabled: boolean; iconSearch: string }>`
    font-family: inherit;
    font-size: 1rem;
    width: 95%;
    height: 35px;
    background-color: ${props => props.disabled && props.theme.colours.lightGrey};
    border: solid 1px ${props => props.theme.sectionDashboard.section.sectionMenu.select.borderColor};
    border-radius: 5px;
    color: ${props => props.disabled && props.theme.colours.midGrey};
    padding: 0px 10px;
    margin-bottom: 10px;

    background-image: url(${props => props.iconSearch});
    background-position: 95% center;
    background-repeat: no-repeat;
    background-size: 0.9rem;
    background-color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.backgroundColor};

    &::placeholder {
        color: ${props => props.theme.colours.midGrey};
        font-size: 0.9rem;
    }
`;

export type SearchInputProps = {
    value: string | undefined;
    placeholder?: string;
    disabled?: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
};

const SearchInput = ({ value, placeholder, disabled, onChange }: SearchInputProps) => {
    return (
        <StyledInput
            type="text"
            value={value}
            placeholder={placeholder}
            disabled={disabled ? disabled : false}
            onChange={onChange}
            iconSearch={IconSearch}
        />
    );
};

export default SearchInput;
