import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

// Hooks, Types & Helpers
import { useSectionDashboard } from '../../context/section-dashboard-context';
import { StackedAreaChartData } from '../../types';
import { buildAreaSeries, buildOptionalSeries, buildYAxisConfig } from '../../helpers/widget-stacked-area-helpers';

// Highcharts

import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Components & Config
import { stackedAreaChartConfig } from '../../configurations/widget-stacked-area-chart-config';
import WidgetBase from '../base/widget-base';

const StyledContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: absolute;
    padding-top: 10px;
`;

const StyledSmallContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export type WidgetStackedAreaChartProps = {
    title: string;
    data: StackedAreaChartData;
    href?: string;
    menu?: React.ReactElement;
};

const WidgetStackedAreaChart = ({ title, data, href, menu }: WidgetStackedAreaChartProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();
    const [chartData, setChartData] = useState(data);

    useEffect(() => {
        setChartData(data);
    }, [data]);

    const renderMenu = () => {
        if (menu) {
            const widgetMenu = React.cloneElement(menu, {
                data: chartData,
                setData: setChartData,
            });
            return widgetMenu;
        }
    };

    if (chartData.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={renderMenu()} />;
    }

    if (chartData.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (chartData.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    // Build the series for the main stacked area
    let areaSeries = buildAreaSeries(chartData.areaSeries, theme);

    // Build the optional line series
    let optionalSeries = chartData.optionalSeries ? buildOptionalSeries(chartData.optionalSeries) : [];

    const yAxisAreaConfig = {
        title: {
            text: chartData.areaTitle,
            style: {
                color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                fontSize: '0.7em',
            },
        },
        labels: {
            style: {
                color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                fontSize: '0.7em',
            },
        },
        gridLineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#e6e6e6',
    };

    // Build the optional Y Axis config
    const optionalYAxisTitle: string[] = [];

    optionalSeries.forEach(series => {
        if (series.visible) {
            optionalYAxisTitle.push(series.name);
        }
    });

    const yAxisOptionalConfig = buildYAxisConfig(optionalSeries, theme);
    const yAxisConfig = [yAxisAreaConfig, yAxisOptionalConfig];

    const handleUpdateArea = (seriesName: string) => {
        setChartData({
            ...chartData,

            areaSeries: chartData.areaSeries.map(series => {
                if (series.name === seriesName) {
                    return {
                        ...series,
                        visible: !series.visible,
                    };
                }

                return series;
            }),
            optionalSeries:
                chartData.optionalSeries &&
                chartData.optionalSeries.map(series => {
                    if (series.name === seriesName) {
                        return {
                            ...series,
                            visible: !series.visible,
                        };
                    }

                    return series;
                }),
        });
    };

    const chartConfig = stackedAreaChartConfig(theme);

    const chartOptions = {
        ...chartConfig,
        plotOptions: {
            ...chartConfig.plotOptions,
            series: {
                ...chartConfig.plotOptions.series,
                events: {
                    legendItemClick: (e: React.SyntheticEvent<HTMLInputElement>) => {
                        const { name } = e.target as HTMLInputElement;
                        handleUpdateArea(name);
                    },
                },
            },
        },
    };

    if (width === 1 || height === 1) {
        return (
            <WidgetBase title={title} href={href} menu={renderMenu()}>
                <StyledSmallContainer>
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{
                            ...chartOptions,
                            series: [...areaSeries],
                            xAxis: {
                                ...chartConfig.xAxis,
                                maxPadding: 0,
                                minPadding: 0,
                            },
                            yAxis: {
                                title: {
                                    text: null,
                                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                                },
                                labels: {
                                    enabled: false,
                                },
                                gridLineWidth: 0,
                            },
                            legend: {
                                enabled: false,
                            },
                        }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                </StyledSmallContainer>
            </WidgetBase>
        );
    }

    return (
        <WidgetBase title={title} href={href} menu={renderMenu()}>
            <StyledContainer>
                <CommonHighcharts
                    highcharts={Highcharts}
                    options={{
                        ...chartConfig,
                        ...chartOptions,
                        plotOptions: {
                            ...chartConfig.plotOptions,
                            series: {
                                ...chartConfig.plotOptions.series,
                                events: {
                                    legendItemClick: (e: React.SyntheticEvent<HTMLInputElement>) => {
                                        const { name } = e.target as HTMLInputElement;
                                        handleUpdateArea(name);
                                    },
                                },
                            },
                        },
                        series: [...areaSeries, ...optionalSeries],
                        yAxis: yAxisConfig,
                        legend: {
                            itemStyle: {
                                fontSize: height > 2 && width > 2 ? '12px' : '10px',
                                color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.8)' : '#6f6f6f',
                                fontWeight: theme.name === 'dark' && 400,
                            },
                        },
                    }}
                    containerProps={{ style: { height: '100%', width: '100%' } }}
                />
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetStackedAreaChart;
