// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Core Dependencies
import Moment from 'moment';
import { intToColour } from '../helpers/colours';

// Import Actions
import { getRequest, delRequest } from '../redux/slices/request';
import { addNotification } from '../redux/actions/notification';
import { setDescription, setTitle } from '../redux/actions/page-meta';

// Import Widgets
import WidgetSelectableSimpleTable from '../components/tables/widgets/widget-selectable-simple-table';
import JourneyAnalysisFilters from '../widgets/journey-analysis-filter';
import WidgetSunburstChart from '../widgets/sunburst';
import Message from '../components/message';
import SimpleBreadcrumbsWidget from '../breadcrumbs/widgets/simple-breadcrumbs';
import CSVDownload from '../components/csv-download';
import { FilterBarContext } from '../filter-bar/context/filter-bar-context';

class LayoutJourneyAnalysis extends Component {
    static contextType = FilterBarContext;

    constructor(props) {
        super(props);

        this.state = {
            // Page
            isLoading: true,
            tableGraphIsLoading: true,
            hasPageError: false,
            showSubchannels: false,
            // Raw Data
            goals: [],
            journeys: [],
            graphData: [],
            tableData: [],
            visibleTableData: [],
            visibleJourneys: [],
            referers: [],
            subchannels: [],
            totalSales: 0,
            totalRevenue: {
                rawValue: '',
                value: '',
            },
            // Filter
            filterRevenue: {
                minValue: '',
                maxValue: '',
            },
            filterSales: {
                minValue: '',
                maxValue: '',
            },
            filterJourney: [
                {
                    id: '*',
                    type: 'any',
                    name: 'Any',
                    colour: intToColour(16777216),
                },
            ],
            filterGoal: {
                keyValue: '',
                name: '',
                value: '',
            },
            channelTypeOptions: [
                {
                    name: 'Channels',
                    value: 'channels',
                    active: true,
                },
                {
                    name: 'Subchannels',
                    value: 'subchannels',
                    active: false,
                },
            ],
            saleTypeOptions: [
                {
                    name: 'All',
                    value: 'all',
                    active: true,
                },
                {
                    name: 'New',
                    value: 'new',
                    active: false,
                },
                {
                    name: 'Repeat',
                    value: 'repeat',
                    active: false,
                },
            ],
            // Breadcrumbs
            currentJourney: {
                id: '',
                percentage: 0,
                journey: [],
                journeyIds: [],
            },
            downloadParams: [],
        };
    }

    componentDidMount() {
        this.props.setTitle('Journey Analysis');
        this.props.setDescription('');

        // Hide the default report productSelect and datePicker filter as custom one has been implemented
        // Filter bar configurations
        this.context.setFilterStatus({
            isEnableDatePicker: false,
            isEnableProductSelect: false,
            isEnableMetrics: false,
        });
        this.context.setFilterMetricsOptions([]); // Reset metrics
        this.context.setDatePickerConfig({}); // Reset datepicker

        this.props.getRequest([
            {
                resourceGroup: 'config',
                resourceName: 'dash-product',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
            {
                resourceGroup: 'config',
                resourceName: 'dash-referer',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
            {
                resourceGroup: 'config',
                resourceName: 'dash-subchannel',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
        ]);
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.isLoading || this.state.tableGraphIsLoading) && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                // if request.data.length === 3 that means this is the initial
                // request to get goals, referers and subchannels
                if (Object.keys(this.props.request.data).length === 3) {
                    const goals = this.props.request.data[0].objects.map(goal => {
                        return {
                            keyValue: goal.id,
                            id: goal.id,
                            name: goal.name,
                            value: goal.name,
                            default: goal.default,
                        };
                    });

                    const referers = this.props.request.data[1].objects.map(referer => {
                        return {
                            id: referer.id,
                            type: 'referer',
                            name: referer.name,
                            colour: intToColour(parseInt(referer.colour)),
                        };
                    });

                    const subchannels = this.props.request.data[2].objects.map(subchannel => {
                        return {
                            id: subchannel.id,
                            type: 'subchannel',
                            name: subchannel.name,
                            channel: subchannel.referer.name,
                            colour: intToColour(parseInt(subchannel.referer.colour)),
                        };
                    });

                    const anyJourney = {
                        id: '*',
                        type: 'any',
                        name: 'Any',
                        colour: intToColour(16777216),
                    };

                    referers.unshift(anyJourney);
                    subchannels.unshift(anyJourney);

                    this.props.delRequest();

                    // check for default goals
                    const defaultGoals = goals.filter(goal => goal.default);
                    const goal = defaultGoals.length > 0 ? defaultGoals[0] : goals[0];

                    const filterGoal = {
                        keyValue: goal.id,
                        name: goal.name,
                        value: goal.name,
                    };

                    this.setState(
                        {
                            goals: goals,
                            filterGoal: filterGoal,
                            referers: referers,
                            subchannels: subchannels,
                        },
                        () => {
                            // Once this initial loading is complete, then get journey data based on the first goal in the list
                            this.props.getRequest([this.journeyConfigBuilder()]);
                        }
                    );
                } else {
                    const journeys = [];
                    const tableData = [];
                    const graphData = [
                        {
                            name: '',
                            id: 'journey',
                            cubedDepth: 0,
                            value: 1,
                            parent: '',
                            color: '#ffffff',
                        },
                    ];

                    const totalSales = this.props.request.data[0].meta.totals['lc_sales']['raw_value'];
                    const totalRevenue = this.props.request.data[0].meta.totals['lc_revenue'];

                    this.props.request.data[0].objects.forEach(journey => {
                        // These are the pills that will appear in the table on the page
                        const pills = [];
                        let splitJourney;

                        splitJourney = this.state.showSubchannels
                            ? journey['subchannel_ids'].value.split('%')
                            : journey['referer_ids'].value.split('%');

                        // remove last item if it equals 'end'
                        if (splitJourney[splitJourney.length - 1] === 'end') {
                            splitJourney.pop();
                        }

                        // Add contextual data to the splitJourneys array whilst generating graphData
                        splitJourney = splitJourney.map((journeyItem, index, array) => {
                            let getReferer;

                            if (this.state.showSubchannels) {
                                getReferer = this.state.subchannels.filter(
                                    subchannel => subchannel.id === parseInt(journeyItem.split('*')[0])
                                );
                            } else {
                                getReferer = this.state.referers.filter(
                                    referer => referer.id === parseInt(journeyItem.split('*')[0])
                                );
                            }

                            let colour = '#F1F1F1';

                            if (getReferer.length === 1) {
                                colour = getReferer[0].colour.toHexString();
                            }

                            // Generate item for graphData

                            // Generate parentID array
                            let parentId = ['journey'];

                            if (index > 0) {
                                for (var x = 0; x < index; x++) {
                                    parentId.push(array[x]);
                                }
                            }
                            // Create the ID for the item itself
                            const id = [...parentId, journeyItem];

                            // As the index doesn't include the "start" of the journey add 1 to the index to account for that
                            const cubedDepth = index + 1;

                            // Check if the referer already appears in graphData
                            const refererInSunburst = graphData.filter(filteredReferer => {
                                return filteredReferer.id === id.join('__');
                            });

                            if (refererInSunburst.length === 1) {
                                const indexOfFilter = graphData.indexOf(refererInSunburst[0]);
                                graphData[indexOfFilter].value += journey['lc_sales'].raw_value;
                                graphData[indexOfFilter].cubedSalesPercentage = (
                                    (graphData[indexOfFilter].value / totalSales) *
                                    100
                                ).toFixed(2);
                            } else {
                                // Set the opacity based on either itself or a child is currently hovered on.
                                let opacity = 1;

                                graphData.push({
                                    id: id.join('__'),
                                    opacity: opacity,
                                    name: `${journeyItem}`,
                                    parent: parentId.join('__'),
                                    journey: id,
                                    value: journey['lc_sales'].raw_value,
                                    color: colour,
                                    cubedDepth: cubedDepth,
                                    cubedSalesPercentage: ((journey['lc_sales'].raw_value / totalSales) * 100).toFixed(
                                        2
                                    ),
                                    labels: {
                                        enable: false,
                                    },
                                });
                            }

                            const subchannelCopy = getReferer[0]?.channel
                                ? `${getReferer[0].channel} ${journeyItem.split('*')[1]}`
                                : journeyItem.split('*')[1];

                            // Add Item to pill
                            pills.push({
                                id: `journeys__pill__${journeyItem}`,
                                key: `journeys__pill__${index}`,
                                isMore: journeyItem === '...',
                                isStart: index === 0,
                                isEnd: array.length - 1 === index,
                                isActive: array.length - 1 === index,
                                copy:
                                    journeyItem === '...'
                                        ? '...'
                                        : this.state.showSubchannels
                                        ? subchannelCopy
                                        : journeyItem.split('*')[1],
                                styleOverride: {
                                    backgroundColor: journeyItem === '...' ? colour : 'initial',
                                    border: `solid 2px ${colour}`,
                                },
                            });

                            return {
                                name: journeyItem,
                                colour: colour,
                            };
                        });

                        tableData.push({
                            id: this.state.showSubchannels
                                ? journey['subchannel_ids'].value
                                : journey['referer_ids'].value,
                            key: this.state.showSubchannels
                                ? `journeys__${journey['subchannel_ids'].value}`
                                : `journeys__${journey['referer_ids'].value}`,
                            columns: [
                                {
                                    type: 'pills',
                                    pills: pills,
                                },
                                {
                                    copy: journey['lc_sales'].raw_value,
                                    rawValue: journey['lc_sales'].raw_value,
                                },
                                {
                                    copy: journey['lc_revenue'].value,
                                    rawValue: journey['lc_revenue'].raw_value,
                                },
                            ],
                        });

                        journeys.push({
                            splitJourney: splitJourney,
                            sales: journey['lc_sales'].raw_value,
                            revenue: journey['lc_revenue'],
                        });
                    });

                    this.setState({
                        graphData: graphData,
                        tableData: tableData,
                        visibleTableData: tableData,
                        isLoading: false,
                        tableGraphIsLoading: false,
                        totalSales: totalSales,
                        totalRevenue: totalRevenue,
                    });
                }
            } else {
                this.setState({
                    isLoading: false,
                    tableGraphIsLoading: false,
                    hasPageError: true,
                });
            }

            this.props.delRequest();
        }

        if (
            prevProps.date.startDate !== this.props.date.startDate ||
            prevProps.date.endDate !== this.props.date.endDate
        ) {
            this.setState({
                tableGraphIsLoading: true,
            });

            this.props.getRequest([this.journeyConfigBuilder()]);
        }

        if (prevState.currentJourney.id !== this.state.currentJourney.id) {
            const visibleJourneys = this.state.journeys.filter(filteredJourney => {
                if (this.state.currentJourney.id === '' || this.state.currentJourney.id === 'journey') {
                    return true;
                } else {
                    let journeyMatch = true;

                    this.state.currentJourney.journey.every((journey, index) => {
                        if (
                            filteredJourney.splitJourney[index] === undefined ||
                            journey !== filteredJourney.splitJourney[index].name
                        ) {
                            journeyMatch = false;
                            return false;
                        }
                        return true;
                    });

                    return journeyMatch;
                }
            });

            this.setState({
                visibleJourneys: visibleJourneys,
            });
        }
    }

    onChannelTypeSelect = channelOption => {
        let showChannelType = !this.state.showSubchannels;
        let channelTypeOptions = this.state.channelTypeOptions.map(option => {
            return {
                ...option,
                active: option === channelOption,
            };
        });
        this.setState(
            {
                showSubchannels: showChannelType,
                channelTypeOptions: channelTypeOptions,
                tableGraphIsLoading: true,
            },
            () => {
                this.props.getRequest([this.journeyConfigBuilder()]);
            }
        );
    };

    onSaleTypeSelect = saleOption => {
        let saleTypeOptions = this.state.saleTypeOptions.map(option => {
            return {
                ...option,
                active: option === saleOption,
            };
        });

        this.setState(
            {
                saleTypeOptions: saleTypeOptions,
                tableGraphIsLoading: true,
            },
            () => {
                this.props.getRequest([this.journeyConfigBuilder()]);
            }
        );
    };

    onBreadcrumbHomeClick = () => {
        this.setState(
            {
                filterJourney: [
                    {
                        id: '*',
                        type: 'any',
                        name: 'Any',
                        colour: intToColour(16777216),
                    },
                ],
                tableGraphIsLoading: true,
            },
            () => {
                this.props.getRequest([this.journeyConfigBuilder()]);
            }
        );
    };

    // grab values from store to populate the url.
    journeyConfigBuilder = () => {
        const config = {
            resourceGroup: 'report',
            resourceName: 'sunburst-referer-journey',
            params: [
                {
                    key: 'limit',
                    value: 500,
                },
                {
                    key: 'sales_date__gte',
                    value: this.props.date.startDate.format('YYYY-MM-DDT00:00:00') + 'Z',
                },
                {
                    key: 'sales_date__lte',
                    value: this.props.date.endDate.format('YYYY-MM-DDT23:59:59') + 'Z',
                },
                {
                    key: 'group_by',
                    value: this.state.showSubchannels ? 'subchannel_ids' : 'referer_ids',
                },
                {
                    key: 'order_by',
                    value: '-lc_sales',
                },
            ],
        };

        // If sales type filter is new or repeat add extra params
        this.state.saleTypeOptions.forEach(option => {
            if (option.value === 'new' && option.active) {
                config.params.push({
                    key: 'repeat_sale',
                    value: false,
                });
            } else if (option.value === 'repeat' && option.active) {
                config.params.push({
                    key: 'repeat_sale',
                    value: true,
                });
            }
        });

        // Having can have multiple values associated with it. Build up the value from this var
        const havingParameter = {
            key: 'having',
            value: [],
        };

        // Add sales filter request params
        if (this.state.filterSales.minValue) {
            havingParameter.value.push(`lc_sales__gte=${this.state.filterSales.minValue}`);
        }
        if (this.state.filterSales.maxValue) {
            havingParameter.value.push(`lc_sales__lte=${this.state.filterSales.maxValue}`);
        }

        // Add revenue filter request params
        if (this.state.filterRevenue.minValue) {
            havingParameter.value.push(`lc_revenue__gte=${this.state.filterRevenue.minValue}`);
        }
        if (this.state.filterRevenue.maxValue) {
            havingParameter.value.push(`lc_revenue__lte=${this.state.filterRevenue.maxValue}`);
        }

        // Add goal filter request params
        if (!isNaN(parseInt(this.state.filterGoal.keyValue))) {
            config.params.push({
                key: 'product',
                value: this.state.filterGoal.keyValue,
            });
        }

        // Add journey filter to request params
        // * = all, r = referer, s = subchannel
        if (this.state.filterJourney.length >= 1) {
            config.params.push({
                key: 'referer_subchannel_ids',
                value: this.state.filterJourney
                    .map(journey => {
                        if (journey.type === 'any') {
                            return '*';
                        } else {
                            if (journey.type === 'referer') {
                                return `r${journey.id}`;
                            } else if (journey.type === 'subchannel') {
                                return `s${journey.id}`;
                            }
                            return null;
                        }
                    })
                    .join(','),
            });
        }

        // If having value has items in it's array, add them to the params
        if (havingParameter.value.length >= 1) {
            config.params.push({
                key: havingParameter.key,
                value: havingParameter.value.join(','),
            });
        }

        let filename = config.resourceName;
        const startDate = config.params.filter(item => item.key === 'sales_date__gte')[0];
        const endDate = config.params.filter(item => item.key === 'sales_date__lte')[0];

        filename += `_${Moment(startDate.value).format('DD-MM-YYYY')}_${Moment(endDate.value).format('DD-MM-YYYY')}`;

        this.setState({
            downloadParams: config,
            downloadFileName: filename,
        });
        return config;
    };

    onPlotPointClick = currentJourney => {
        const anyReferer = {
            id: '*',
            type: 'any',
            name: 'Any',
            colour: intToColour(16777216),
        };

        const filterJourney = currentJourney.journey.map(journey => {
            // If journey starts with a ... then use any
            if (journey === '...') {
                return {
                    id: '...',
                    name: 'Any',
                    colour: intToColour(16777216),
                };
            }

            let channel;

            if (this.state.showSubchannels) {
                channel = this.state.subchannels.filter(
                    subchannel => parseInt(journey.split('*')[0]) === subchannel.id
                );
            } else {
                channel = this.state.referers.filter(
                    filteredReferer => parseInt(journey.split('*')[0]) === filteredReferer.id
                );
            }

            if (channel.length === 1) {
                return channel[0];
            } else {
                // Something went wrong if this is returned
                return {};
            }
        });

        // Add any at the end to get all referer even if they continue
        filterJourney.push(anyReferer);

        this.setState(
            {
                tableGraphIsLoading: true,
                currentJourney: {
                    id: '',
                    percentage: 0,
                    journey: [],
                },
                filterJourney: filterJourney,
            },
            () => {
                this.props.getRequest([this.journeyConfigBuilder()]);
            }
        );
    };

    onCurrentJourneyUpdate = currentJourney => {
        let visibleTableData = [];

        // If the current journey id is not blank this means some of the data needs to be hidden
        if (currentJourney.id !== '') {
            visibleTableData = this.state.tableData.filter(row => {
                let id = 'journey__' + row.id.replace('%end', '').replace(/%/g, '__');
                return id.includes(currentJourney.id);
            });
        } else {
            visibleTableData = [...this.state.tableData];
        }

        this.setState({
            currentJourney: currentJourney,
            visibleTableData: visibleTableData,
        });
    };

    // When filters have changed this function be called
    filtersHaveChanged = filters => {
        this.setState(
            {
                filterGoal: filters.goal,
                filterSales: filters.filterSales,
                filterRevenue: filters.filterRevenue,
                filterJourney: filters.filterJourney,
                tableGraphIsLoading: true,
            },
            () => {
                this.props.getRequest([this.journeyConfigBuilder()]);
            }
        );
    };

    renderTable = () => {
        const columns = [
            {
                title: 'Journey',
                orderable: false,
            },
            {
                title: 'Sales',
                orderable: true,
                defaultOrder: 'desc',
            },
            {
                title: 'Revenue',
                orderable: true,
            },
        ];

        const config = {
            columns: columns,
            rows: this.state.visibleTableData,
            disableSelectOverride: false,
            hasTotals: true,
            hasPills: true,
        };

        return <WidgetSelectableSimpleTable config={config} />;
    };

    renderSkeleton = () => {
        return (
            <div className="journeys-skeleton__container container">
                <div className="journeys-skeleton__container__breadcrumbs row">
                    <div className="journeys-skeleton__container__breadcrumbs__item"></div>
                </div>
                <div className="journeys-skeleton__container__table-graph row">
                    <div className="journeys-skeleton__container__table-graph__table col-12 col-lg-6">
                        <div className="journeys-skeleton__container__table-graph__table__item-header"></div>
                        <div className="journeys-skeleton__container__table-graph__table__item"></div>
                        <div className="journeys-skeleton__container__table-graph__table__item"></div>
                        <div className="journeys-skeleton__container__table-graph__table__item"></div>
                        <div className="journeys-skeleton__container__table-graph__table__item"></div>
                        <div className="journeys-skeleton__container__table-graph__table__item"></div>
                        <div className="journeys-skeleton__container__table-graph__table__item"></div>
                        <div className="journeys-skeleton__container__table-graph__table__item"></div>
                        <div className="journeys-skeleton__container__table-graph__table__item"></div>
                        <div className="journeys-skeleton__container__table-graph__table__item"></div>
                    </div>
                    <div className="journeys-skeleton__container__table-graph__graph col-12 col-lg-6">
                        <div className="journeys-skeleton__container__table-graph__graph__item"></div>
                    </div>
                </div>
            </div>
        );
    };

    renderFilters = () => {
        return (
            <div className="journeys__filters">
                <JourneyAnalysisFilters
                    onFilterApply={this.filtersHaveChanged}
                    referers={this.state.referers}
                    subchannels={this.state.subchannels}
                    goals={this.state.goals}
                    journeys={this.state.filterJourney}
                    goal={this.state.filterGoal}
                    channelTypeOptions={this.state.channelTypeOptions}
                    saleTypeOptions={this.state.saleTypeOptions}
                    onChannelTypeSelect={this.onChannelTypeSelect}
                    onSaleTypeSelect={this.onSaleTypeSelect}
                />
            </div>
        );
    };

    render() {
        if (this.state.isLoading === true) {
            return (
                <div className="journeys-skeleton">
                    <div className="journeys-skeleton__filters">
                        <div className="journeys-skeleton__filters__item"></div>
                        <div className="journeys-skeleton__filters__item"></div>
                    </div>
                    <this.renderSkeleton />
                </div>
            );
        } else if (this.state.tableGraphIsLoading === true) {
            return (
                <div className="journeys journeys-skeleton">
                    <this.renderFilters />
                    <this.renderSkeleton />
                </div>
            );
        } else if (this.state.tableData.length === 0) {
            return (
                <div className="journeys">
                    <this.renderFilters />
                    <div className="journeys__container container">
                        <Message
                            title="No Journeys Found."
                            copy="Please redefine your search by using the filters and date picker above."
                            type="info"
                        />
                    </div>
                </div>
            );
        }

        const breadcrumbs = this.state.currentJourney.journey.map(breadcrumb => {
            if (breadcrumb === '...') {
                return {
                    copy: '...',
                    styleOverride: {
                        backgroundColor: '#F1F1F1',
                        color: '#6F6F6F',
                    },
                };
            }

            const referer = this.state.referers.filter(filterReferer => {
                let id = parseInt(breadcrumb.split('*')[0]);
                return filterReferer.id === id;
            });

            const subchannel = this.state.subchannels.filter(subchannel => {
                let id = parseInt(breadcrumb.split('*')[0]);
                return subchannel.id === id;
            });

            const subchannelCopy =
                subchannel.length > 0
                    ? subchannel[0].channel
                        ? `${subchannel[0].channel} ${subchannel[0].name}`
                        : subchannel.name
                    : '';

            return {
                copy: this.state.showSubchannels ? (subchannelCopy ? subchannelCopy : '') : referer[0].name,
                id: uuidv4(),
                styleOverride: {
                    backgroundColor: this.state.showSubchannels
                        ? subchannel[0].colour
                            ? subchannel[0].colour
                            : '#cccccc'
                        : referer[0].colour,
                    color: this.state.showSubchannels
                        ? subchannel[0].colour.getLuminance() < 0.2
                            ? '#ffffff'
                            : '#212121'
                        : referer[0].colour.getLuminance() < 0.2
                        ? '#ffffff'
                        : '#212121',
                },
            };
        });

        return (
            <div className="journeys">
                <this.renderFilters />
                <div className="journeys__container container">
                    {this.state.tableData && this.state.tableData.length > 0 && (
                        <CSVDownload params={this.state.downloadParams} filename={this.state.downloadFileName} />
                    )}

                    <SimpleBreadcrumbsWidget
                        breadcrumbs={breadcrumbs.reverse()}
                        homeOnClick={this.onBreadcrumbHomeClick}
                    />

                    <div className="journeys__container__table-graph row">
                        <div className="journeys__container__table-graph__table col-12 col-lg-6">
                            <this.renderTable />
                        </div>
                        <div className="journeys__container__table-graph__graph col-12 col-lg-6">
                            <WidgetSunburstChart
                                data={this.state.graphData}
                                onPlotPointClick={this.onPlotPointClick}
                                onCurrentJourneyUpdate={this.onCurrentJourneyUpdate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        request: state.request,
        date: state.date,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        setTitle: title => {
            dispatch(setTitle(title));
        },
        setDescription: description => {
            dispatch(setDescription(description));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutJourneyAnalysis);
