import React from 'react';
import styled, { css } from 'styled-components';
import { useSectionDashboard } from '../../context/section-dashboard-context';
import { NestedDonutChartTableRow, TableColumn } from '../../types';

const StyledTable = styled.table<{ height: number; width: number }>`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: ${props => (props.height > 1 && props.width > 1 ? '0.9rem' : '0.7rem')};
    color: ${props => props.theme.sectionDashboard.widget.table.textColor};
`;

const StyledTHead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 3;
`;

const THCSS = css`
    background: ${props => props.theme.sectionDashboard.widget.table.header.backgroundColor};
    font-weight: ${props => props.theme.sectionDashboard.widget.table.header.fontWeight};
    white-space: nowrap;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.header.borderColor};
`;

const StyledHeaderTH = styled.th`
    ${THCSS}
    font-weight: 700;

    &:nth-child(odd):not(:last-child) {
        border-right: 1px solid ${props => props.theme.sectionDashboard.widget.table.header.borderColor};
    }
`;

const StyledTH = styled.th<{ borderInterval: number }>`
    ${THCSS}

    &:nth-child(${props => props.borderInterval}):not(:last-child) {
        border-right: 1px solid ${props => props.theme.sectionDashboard.widget.table.header.borderColor};
    }
`;

const StyledHeaderContainer = styled.div<{ height: number; width: number; textAlign: 'right' | 'left' | 'center' }>`
    padding: 5px 10px;
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: ${props =>
        props.textAlign === 'left' ? 'flex-start' : props.textAlign === 'center' ? 'center' : 'flex-end'};
`;

const StyledTD = styled.td<{
    width: number;
    height: number;
    isTotal?: boolean;
    dataType?: number;
    variance?: number | false;
    borderInterval: number;
}>`
    padding: ${props => (props.height > 1 ? '10px 15px' : '5px 15px')};
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.cell.borderColor};
    background: ${props =>
        props.isTotal
            ? props.theme.sectionDashboard.widget.table.totals.backgroundColor
            : props.theme.sectionDashboard.widget.table.cell.backgroundColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
    text-align: right;
    color: ${props =>
        props.variance
            ? props.variance > 0
                ? props.theme.sectionDashboard.widget.variance.textColorPositive
                : props.variance < 0
                ? props.theme.sectionDashboard.widget.variance.textColorNegative
                : props.theme.sectionDashboard.widget.table.textColor
            : props.theme.sectionDashboard.widget.table.textColor};
    font-weight: ${props => props.variance && 600};

    &:nth-child(${props => props.borderInterval}):not(:last-child) {
        border-right: 1px solid ${props => props.theme.sectionDashboard.widget.table.cell.borderColor};
    }
`;

type SectionDashboardNestedDonutTableProps = {
    columns: TableColumn[];
    subColumns: TableColumn[];
    rows: NestedDonutChartTableRow[];
    isVariance?: boolean;
};

const SectionDashboardNestedDonutTable = ({
    columns,
    subColumns,
    rows,
    isVariance,
}: SectionDashboardNestedDonutTableProps) => {
    const { width, height } = useSectionDashboard();

    return (
        <StyledTable width={width} height={height}>
            <StyledTHead>
                <tr>
                    {columns.map(column => {
                        return (
                            <StyledHeaderTH colSpan={subColumns.length} key={column.rawName}>
                                <StyledHeaderContainer width={width} height={height} textAlign={'right'}>
                                    {column.displayName}
                                </StyledHeaderContainer>
                            </StyledHeaderTH>
                        );
                    })}
                </tr>
                <tr>
                    {columns.map(column => {
                        return subColumns.map(subColumn => {
                            return (
                                <StyledTH
                                    key={`${column.rawName}-${subColumn.rawName}`}
                                    borderInterval={subColumns.length}
                                >
                                    <StyledHeaderContainer width={width} height={height} textAlign={'right'}>
                                        {subColumn.displayName}
                                    </StyledHeaderContainer>
                                </StyledTH>
                            );
                        });
                    })}
                </tr>
            </StyledTHead>
            <tbody>
                <tr>
                    {rows.map(row => {
                        return columns.map(column => {
                            return subColumns.map(subColumn => {
                                return (
                                    <StyledTD
                                        width={width}
                                        height={height}
                                        key={`${column.rawName}-${subColumn.rawName}-value`}
                                        variance={
                                            isVariance
                                                ? (row.values[column.rawName][subColumn.rawName].rawValue as number) ||
                                                  0
                                                : false
                                        }
                                        borderInterval={subColumns.length}
                                    >
                                        {row.values[column.rawName][subColumn.rawName].value}
                                        {isVariance && '%'}
                                    </StyledTD>
                                );
                            });
                        });
                    })}
                </tr>
            </tbody>
        </StyledTable>
    );
};

export default SectionDashboardNestedDonutTable;
