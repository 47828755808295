/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldValues } from 'react-hook-form';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../redux/actions/modal';
import { removePopup, setPopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { RootState } from '../../redux/reducers/core';

// Componenta
import ConfigTable from '../../components/tables/config-table/config-table';
import Form from '../../components/forms/form';
import validators from '../../components/forms/validation/validators';
import { TooltipPopUpSide } from '../../components/tooltip';
import CSVDownload from '../../components/csv-download';

// Enums
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';

// React Query
import { ConfigDataSuccess } from '../../react-query/types';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import usePatchResource from '../../react-query/hooks/use-patch-resource';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';

// Configurations
import { CONFIG_EVENT } from '../../configurations/resources-config';

// Layouts
import { ModalLayout, ErrorModalLayout } from './modal-layout';

const StyledTextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

type EventData = {
    id: number;
    name: string;
    display_name: string;
    selected: boolean;
};

type EventDataToEdit = {
    id: number;
    name: string;
    display_name: string;
};

const LayoutModalManageEvents = () => {
    const dispatch = useDispatch();

    const { account } = useSelector((state: RootState) => state);

    const downloadParams = {
        params: [{ key: 'active', value: 1 }],
        resourceGroup: 'config',
        resourceName: 'event-csv',
    };

    // Event States
    const [eventsData, setEventsData] = useState<EventData[]>([]);
    const [eventsDataToEdit, setEventsDataToEdit] = useState<EventDataToEdit>();
    const [eventsDataToDelete, setEventsDataToDelete] = useState<number[]>([]);
    const [disableOnFocusClick, setDisableOnFocusClick] = useState(true);

    // Table States
    const [disableTable, setDisableTable] = useState(false);

    // Form States
    const [formData, setFormData] = useState<FieldValues>();

    // Queries & Mutations
    const eventQuery = useFetchResource({
        resource: CONFIG_EVENT,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(event => {
                return {
                    id: event.id,
                    name: event.name,
                    display_name: event.display_name,
                    selected: false,
                };
            });
        },
    });

    const eventsDataPatchMutation = usePatchResource({
        resource: CONFIG_EVENT,
        resourceId: eventsDataToEdit ? eventsDataToEdit?.id.toString() : '0',
        data: formData ? { name: formData.name, display_name: formData.display_name } : { name: '', display_name: '' },
        handleOnSuccess: () => {
            setEventsDataToEdit(undefined);
            setDisableTable(false);

            setEventsData(
                eventsData.map(event => {
                    return {
                        ...event,
                        selected: false,
                    };
                })
            );

            dispatch(
                addNotification({
                    copy: 'Events updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your events. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const eventDataDeleteMutation = useDeleteResource({
        resource: CONFIG_EVENT,
        resourceIds: eventsDataToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            setDisableTable(false);
            dispatch(
                addNotification({
                    copy: 'Events successfully deleted.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setDisableTable(false);
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your events. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    // Form Submit
    useEffect(() => {
        if (formData && formData.name && formData.display_name) {
            eventsDataPatchMutation.mutate();
        }
    }, [formData]);

    // Fetch Events
    useEffect(() => {
        if (eventQuery.data) {
            setEventsData(eventQuery.data as EventData[]);
        }
    }, [eventQuery.data]);

    useEffect(() => {
        setEventsDataToDelete(eventsData.filter(event => event.selected).map(event => event.id));
    }, [eventsData]);

    // Handlers
    const handleCheckboxChange = (rowId: number) => {
        setEventsData(
            eventsData.map(event => {
                if (event.id === rowId) {
                    return {
                        ...event,
                        selected: !event.selected,
                    };
                }
                return event;
            })
        );
    };

    const handleEditEventClick = (rowId: number) => {
        let event = eventsData.find(event => event.id === rowId);
        if (event) {
            setEventsDataToEdit({
                id: event.id,
                name: event.name,
                display_name: event.display_name,
            });

            setDisableTable(true);
            setDisableOnFocusClick(true);
        }
    };

    const handleSingleEventDeleteClick = (rowId: number) => {
        setEventsDataToDelete([rowId]);

        const handleDisardClick = () => {
            setEventsDataToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setDisableTable(true);
            eventDataDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this event?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkDeleteClick = () => {
        if (eventsDataToDelete.length === 0) return;

        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setEventsData(
                eventsData.map(event => {
                    return {
                        ...event,
                        selected: false,
                    };
                })
            );
            setDisableTable(true);
            eventDataDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected events?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleCreateEventClick = () => {
        dispatch(setModal('CreateEventWizard', {}));
    };

    const handleSubmitForm = (data: FieldValues) => {
        setFormData(data);
    };

    const handleEditFormCloseClick = () => {
        // Reset Events
        setEventsData(eventsData.map(event => ({ ...event, selected: false })));
        setEventsDataToEdit(undefined);

        // Reset Table
        setDisableTable(false);
    };

    const handleOnFocusClick = () => {
        dispatch(
            setPopup({
                title: 'Warning! Editing Event Name',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you want to change the Event Tag Name?',
                },
                buttons: [
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'No',
                    },
                    {
                        onClick: () => {
                            dispatch(removePopup());
                            setDisableOnFocusClick(false);
                        },
                        value: 'Yes',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    if (eventQuery.status === 'success' || eventQuery.status === 'pending') {
        return (
            <ModalLayout modalHeader="Manage Events">
                <StyledTextWrapper>
                    <p>
                        Manage previously configured events below. For more help see{' '}
                        <a href="https://tag.docs.withcubed.com/onboarding/general/#events">events</a>.
                    </p>
                    <CSVDownload
                        params={downloadParams}
                        filename={`${account.token}-events`}
                        disableDropdown={true}
                        noData={eventsData.length === 0}
                    />
                </StyledTextWrapper>
                <ConfigTable
                    status={eventQuery.status}
                    isFetching={eventQuery.isFetching}
                    disabled={disableTable}
                    empty={eventsData.length === 0}
                >
                    <ConfigTable.Table maxHeight="400px">
                        <ConfigTable.Header>
                            <ConfigTable.Row key="thead">
                                <ConfigTable.CellHeader />
                                <ConfigTable.CellHeader>Event Name</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader />
                            </ConfigTable.Row>
                        </ConfigTable.Header>
                        <ConfigTable.Body>
                            {eventsData.map(event => {
                                return (
                                    <ConfigTable.Row key={event.id}>
                                        <ConfigTable.CellCheckbox
                                            rowId={event.id}
                                            checked={event.selected}
                                            onCheckedChange={handleCheckboxChange}
                                        />
                                        <ConfigTable.Cell>{event.display_name}</ConfigTable.Cell>
                                        <ConfigTable.CellActions>
                                            <ConfigTable.ActionDropdownItem
                                                rowId={event.id}
                                                type="edit"
                                                onClick={handleEditEventClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={event.id}
                                                type="delete"
                                                onClick={handleSingleEventDeleteClick}
                                            />
                                        </ConfigTable.CellActions>
                                    </ConfigTable.Row>
                                );
                            })}
                        </ConfigTable.Body>
                    </ConfigTable.Table>
                    <ConfigTable.ActionBar>
                        <ConfigTable.ActionBarJustifyLeft>
                            <ConfigTable.ActionButton
                                type="delete"
                                label="Delete Events"
                                onClick={handleBulkDeleteClick}
                                isDisabled={eventsDataToDelete.length === 0}
                            />
                        </ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionBarJustifyRight>
                            <ConfigTable.ActionButton
                                type="add"
                                label="Create New Event"
                                onClick={handleCreateEventClick}
                                isDisabled={disableTable}
                            />
                        </ConfigTable.ActionBarJustifyRight>
                    </ConfigTable.ActionBar>
                </ConfigTable>

                {eventsDataToEdit && (
                    <StyledFormContainer>
                        <p>
                            <b>Event Tag Name</b> is case-sensitive, and must match exactly what is being fired by your
                            Cubed tag implementation.
                            <br />
                            <br />
                            <b>Event Display Name</b> is how you want the event name to be displayed in the Cubed
                            dashboards and reports.
                        </p>
                        <Form onSubmit={handleSubmitForm} defaultValues={eventsDataToEdit}>
                            <Form.Body>
                                <Form.Section>
                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="name"
                                            label="Event Tag Name"
                                            required={true}
                                            tooltipCopy="Please enter the Event Name as it will appear in the tagging implementation. Please note, event name must match exactly and is case sensitive."
                                            tooltipPosition={TooltipPopUpSide.Right}
                                        />
                                        <Form.InputText
                                            name="name"
                                            placeholder="Event Tag Name..."
                                            validators={[validators.required]}
                                            onFocus={disableOnFocusClick ? handleOnFocusClick : undefined}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="display_name"
                                            label="Event Display Name"
                                            required={true}
                                            tooltipCopy="Please enter the Event Display Name as you wish it to appear in the Cubed dashboard display and reports."
                                            tooltipPosition={TooltipPopUpSide.Right}
                                        />
                                        <Form.InputText
                                            name="display_name"
                                            placeholder="Event Display Name..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>
                                </Form.Section>
                                <Form.Section>
                                    <Form.Field>
                                        <></>{' '}
                                    </Form.Field>
                                </Form.Section>
                            </Form.Body>
                            <Form.Footer>
                                <Form.InputButton
                                    value="Save"
                                    loading={eventsDataPatchMutation.isPending}
                                    disabled={eventsDataPatchMutation.isPending}
                                />
                                <Form.InputButton
                                    value="Cancel"
                                    type="button"
                                    onClick={handleEditFormCloseClick}
                                    loading={eventsDataPatchMutation.isPending}
                                    disabled={eventsDataPatchMutation.isPending}
                                />
                            </Form.Footer>
                        </Form>
                    </StyledFormContainer>
                )}
            </ModalLayout>
        );
    }

    // Page Error
    return <ErrorModalLayout modalHeader="Manage Events" />;
};

export default LayoutModalManageEvents;
