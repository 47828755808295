import React, { Component } from 'react';
import Request from './../helpers/request';
import tinycolor from 'tinycolor2';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { setTitle } from '../redux/actions/page-meta';
import { intToColour } from './../helpers/colours';
import { customChordLayout, stretchedChord } from './../helpers/chords';
import Button from './../components/button';
import ContributionTable from '../components/tables/components/contribution-table';
import ContributionSkeleton from './../components/contribution-skeleton';
import * as d3 from 'd3';
import $ from 'jquery';
import numeral from 'numeral';
import { FilterBarContext } from '../filter-bar/context/filter-bar-context';
import './../sass/layouts/contribution-analysis.scss';

import Message from '../components/message';

// Styles
const StyledContributionAnalysisWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
`;

class ReportContributionAnalysis extends Component {
    static contextType = FilterBarContext;

    constructor(props) {
        super(props);
        this.contributionUrl = `/api/reports/fetch-contribution-analysis/${this.props.account.token}/`;
        this.contributionRequests = [];
        this.currentAnimationTime = this.props.animationLength;
        this.state = {
            orderBy: 'lc_referer',
            orderDir: 'asc',
            names: [],
            matrix: [],
            fmReferers: [],
            lcReferers: [],
            data: null,
            tableData: null,
            respondents: 0,
            emptyStroke: 0,
            referersList: [],
            showingChannelNames: true,
            isGainsOnly: true,
            isRevenue: false,
            showOptions: false,
            updatingData: false,
            creatingFrames: false,
            isLoading: false,
            hasNoData: false,
            hasNoGains: false, // if there is data but no channel gained/lossed
            hasFailed: false,
            animationInterval: this.calculateAnimationInterval(),
            channels: [],
            expandTranslation: 100,
            centerTranslation: 100,
            expandTranslationY: 100,
            centerTranslationY: 100,
            animationFrames: [],
            animationIntervals: [],
            responseData: [],
            hasResponse: false,
        };
        this.debugMatrix = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 479, 48, 0, 0, 21, 0, 8, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 6, 467, 0, 1, 14, 1, 11, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 4, 7, 1, 2, 0, 1, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 3, 0, 11, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 13, 132, 1, 3, 631, 0, 28, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0, 0, 1, 4, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 60, 2, 3, 28, 0, 296, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 1036, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [479, 6, 4, 1, 13, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [48, 467, 4, 3, 132, 3, 60, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 7, 0, 1, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 1, 1, 11, 3, 0, 3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [21, 14, 2, 0, 631, 1, 28, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 1, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [8, 11, 1, 0, 28, 0, 296, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1036],
        ];
        this.debugNames = [
            { name: 'Affiliates', colour: tinycolor('#FF0000') },
            { name: 'Direct', colour: tinycolor('#FF0000') },
            { name: 'Email', colour: tinycolor('#FF0000') },
            { name: 'Internal', colour: tinycolor('#FF0000') },
            { name: 'Other', colour: tinycolor('#FF0000') },
            { name: 'PPC', colour: tinycolor('#FF0000') },
            { name: 'Referral', colour: tinycolor('#00FF00') },
            { name: 'SEO', colour: tinycolor('#FF0000') },
            { name: 'Social Organic', colour: tinycolor('#FF0000') },
            { name: '', colour: tinycolor('#FF0000') },
            { name: 'Affiliates', colour: tinycolor('#FF0000') },
            { name: 'Direct', colour: tinycolor('#FF0000') },
            { name: 'Email', colour: tinycolor('#FF0000') },
            { name: 'Other', colour: tinycolor('#FF0000') },
            { name: 'PPC', colour: tinycolor('#FF0000') },
            { name: 'Referral', colour: tinycolor('#FF0000') },
            { name: 'SEO', colour: tinycolor('#FF0000') },
            { name: 'Social Organic', colour: tinycolor('#FF0000') },
            { name: '', colour: tinycolor('#FF0000') },
        ];
        this.contributionHeaderColumns = [
            {
                name: 'lc_referer',
                displayName: 'Losing Channel',
            },
            {
                name: 'fm_referer',
                displayName: 'Gaining Channel',
            },
            {
                name: 'sales',
                displayName: 'Sales',
            },
            {
                name: 'revenue',
                displayName: 'Revenue',
            },
        ];
        this.tip = d3.select('body').append('div').attr('class', 'contribution-tooltip').style('opacity', 0);

        this.calculateAnimationInterval = this.calculateAnimationInterval.bind(this);
        this.generateAnimatedFrames = this.generateAnimatedFrames.bind(this);
        this.assignAnimatedFrames = this.assignAnimatedFrames.bind(this);
        this.fillRemainingArray = this.fillRemainingArray.bind(this);
        // this.formatChannelData = this.formatChannelData.bind(this);
        this.getDataValue = this.getDataValue.bind(this);
        this.createRow = this.createRow.bind(this);
        this.createDummyRow = this.createDummyRow.bind(this);
        this.getRespondents = this.getRespondents.bind(this);
        this.createMatrix = this.createMatrix.bind(this);
        this.formatChannels = this.formatChannels.bind(this);
        // this.getColour = this.getColour.bind(this);
        this.initiateChord = this.initiateChord.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.toggleChannelNames = this.toggleChannelNames.bind(this);
        this.animateChart = this.animateChart.bind(this);
        this.renderGraphs = this.renderGraphs.bind(this);
        this.fetchChannelColours = this.fetchChannelColours.bind(this);
        this.setMetric = this.setMetric.bind(this);
        this.setOrderBy = this.setOrderBy.bind(this);
    }
    static defaultProps = {
        chordDesatAmount: 100,
        chordOpacityDefault: 0.5,
        chordOpacityLow: 0.02,
        channelPadding: 0.065,
        emptyPerc: 0.5,
        animationFrameCount: 10,
        animationLength: 180,
        textDistance: 20,
    };
    formatTableData(data, channels) {
        const formattedData = data.map(row => {
            return {
                key: uuidv4(),
                lc_referer: channels.filter(channel => channel.name === row.lc_referer)[0],
                fm_referer: channels.filter(channel => channel.name === row.fm_referer)[0],
                sales: numeral(row.sales).format('0,0[.]00'),
                revenue: this.props.currency.symbol + numeral(row.revenue).format('0,0[.]00'),
            };
        });
        return formattedData;
    }

    calculateAnimationInterval() {
        return this.props.animationLength / this.props.animationFrameCount;
    }
    setMetric(metric) {
        let isRevenue = metric === 'revenue' ? true : false;
        this.setState({ isRevenue });
    }
    generateAnimatedFrames() {
        const animationFrames = [];
        for (let i = 1; i <= this.props.animationFrameCount; i++) {
            animationFrames.push({
                frameId: `chart1-frame${i.toString()}`,
                framePosition: this.state.animationInterval * i,
            });
        }
        this.setState({ animationFrames });
    }
    assignAnimatedFrames(referersList, respondents, data, lcReferers, fmReferers) {
        for (let i = 1; i <= this.state.animationFrames.length; i++) {
            $('#chart1-frame' + i.toString()).empty();
            let paddingAmount =
                this.props.channelPadding * ((this.state.animationInterval * i) / this.props.animationLength);
            this.initiateChord(
                `chart1-frame${i.toString()}`,
                referersList,
                respondents,
                this.props.emptyPerc,
                this.state.emptyStroke,
                this.createMatrix(data, referersList, lcReferers, fmReferers),
                false,
                this.state.showingChannelNames,
                paddingAmount
            );
        }
    }
    fillRemainingArray(isFirst, array, targetLength) {
        const thisArray = [...array];
        if (isFirst) {
            for (let i = thisArray.length; i < targetLength; i++) {
                thisArray.push(0);
            }
        } else {
            for (let i = thisArray.length + 1; i < targetLength; i++) {
                thisArray.unshift(0);
            }
            thisArray.push(0);
        }
        return thisArray;
    }
    setOrderBy(dim) {
        let orderDir = this.state.orderDir === 'asc' ? 'desc' : 'asc';
        this.setState({ orderBy: dim, orderDir });
    }

    getDataValue(data, firstName, lastName, isFirst, isGainsOnly) {
        let first = 'fm_referer';
        let last = 'lc_referer';
        if (isFirst) {
            first = 'lc_referer';
            last = 'fm_referer';
        }
        for (let item of data) {
            if (firstName === item[first] && lastName === item[last] && item.sales > 0) {
                if (isGainsOnly && firstName === lastName) {
                    return 0;
                }
                if (this.state.isRevenue) {
                    return parseFloat(Math.round(item.revenue));
                } else {
                    return parseFloat(Math.round(item.sales));
                }
            }
        }
        return 0;
    }
    createRow(isFirst, data, referers, fullRowLength, name) {
        var arr = [];

        for (var i = 0; i < referers.length; i++) {
            arr.push(this.getDataValue(data, name.name, referers[i].name, isFirst, this.state.isGainsOnly));
        }
        return this.fillRemainingArray(isFirst, arr, fullRowLength);
    }
    createDummyRow(pos, length, emptyStroke) {
        const arr = [];
        for (let i = 0; i < length; i++) {
            arr.push(i === pos ? emptyStroke : 0);
        }
        return arr;
    }
    getRespondents(data, isGainsOnly) {
        let resp = 0;
        for (let item of data) {
            if (item.sales > 0) {
                if (isGainsOnly && item.fm_referer === item.lc_referer) {
                    continue;
                } else {
                    resp = this.state.isRevenue
                        ? resp + Math.round(parseFloat(item.revenue))
                        : resp + parseFloat(item.sales);
                }
            }
        }
        return resp;
    }
    createMatrix(data, referersList, lcReferers, fmReferers) {
        const emptyRow = this.createDummyRow(fmReferers.length, referersList.length, this.state.emptyStroke);
        const firstMatrix = fmReferers.map(referer => {
            return this.createRow(false, data, lcReferers, referersList.length, referer);
        });
        const secondMatrix = lcReferers.map(referer => {
            return this.createRow(true, data, fmReferers, referersList.length, referer);
        });

        const difference = secondMatrix.length - firstMatrix.length;
        if (difference > 0) {
            for (let i = 0; i < difference; i++) {
                firstMatrix.push(emptyRow);
            }
        }

        firstMatrix.push(emptyRow);
        secondMatrix.push(emptyRow);

        return [...firstMatrix, ...secondMatrix];
    }
    formatChannels(allChannels, lcChannels, fmChannels) {
        let emptyChannel = { name: '', colour: 'none' };
        let fmChannelList = allChannels.filter(channel => fmChannels.map(c => c.name).includes(channel.name));
        let lcChannelList = allChannels.filter(channel => lcChannels.map(c => c.name).includes(channel.name)).reverse();

        const difference = lcChannelList.length - fmChannelList.length;
        if (difference > 0) {
            for (let i = 0; Math.abs(i) < Math.abs(difference); i++) {
                fmChannelList.push(emptyChannel);
            }
        }

        fmChannelList.push(emptyChannel);
        lcChannelList.push(emptyChannel);
        return [...fmChannelList, ...lcChannelList];
    }

    initiateChord(id, names, respondents, emptyPerc, emptyStroke, matrix, isDebug, showChannels, paddingAmount) {
        var Names = names;
        var channelPadding = paddingAmount;
        var dataTypeString = this.state.isRevenue ? ' revenue ' : ' sales ';
        ////////////////////////////////////////////////////////////
        //////////////////////// Set-up ////////////////////////////
        ////////////////////////////////////////////////////////////

        var screenWidth = $('#' + id).innerWidth(),
            screenHeight = $('#' + id).innerHeight();

        var margin = { left: 25, top: 0, right: 25, bottom: 0 },
            width = screenWidth - margin.left - margin.right,
            height = screenHeight - margin.top - margin.bottom;

        var svg = d3
            .select('#' + id)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom);

        var wrapper = svg
            .append('g')
            .attr('class', 'chordWrapper')
            .attr('transform', 'translate(' + (width / 2 + margin.left) + ',' + (height / 2 + margin.top) + ')');

        var outerRadius = Math.min(width, height) / 2 - 100,
            innerRadius = outerRadius * 0.95,
            opacityDefault = this.props.chordOpacityDefault, //default opacity of chords
            opacityLow = this.props.chordOpacityLow; //hover opacity of those chords not hovered over

        //How many pixels should the two halves be pulled apart
        var pullOutSize = 50;

        //////////////////////////////////////////////////////
        //////////////////// Titles on top ///////////////////
        //////////////////////////////////////////////////////

        // Losing and Gaining Labels
        wrapper
            .append('text')
            .text(function () {
                if (window.innerWidth > 1200) {
                    return 'Losing Channel';
                }
            })
            .attr('x', -500)
            .attr('y', 0)
            .attr('font-weight', 700)
            .attr('font-size', '0.9rem');
        wrapper
            .append('text')
            .text(function () {
                if (window.innerWidth > 1200) {
                    return 'Gaining Channel';
                }
            })
            .attr('x', 370)
            .attr('y', 0)
            .attr('font-weight', 700)
            .attr('font-size', '0.9rem');

        var titleWrapper = svg.append('g').attr('class', 'chordTitleWrapper'),
            titleOffset = 40,
            titleSeparate = 0;

        //Title	top left
        titleWrapper
            .append('text')
            .attr('class', 'title left')
            .style('font-size', '16px')
            .attr('x', width / 2 + margin.left - outerRadius - titleSeparate)
            .attr('y', titleOffset)
            .text('');
        titleWrapper
            .append('line')
            .attr('class', 'titleLine left')
            .attr('x1', (width / 2 + margin.left - outerRadius - titleSeparate) * 0.6)
            .attr('x2', (width / 2 + margin.left - outerRadius - titleSeparate) * 1.4)
            .attr('y1', titleOffset + 8)
            .attr('y2', titleOffset + 8);
        //Title top right
        titleWrapper
            .append('text')
            .attr('class', 'title right')
            .style('font-size', '16px')
            .attr('x', width / 2 + margin.left + outerRadius + titleSeparate)
            .attr('y', titleOffset)
            .text('');
        titleWrapper
            .append('line')
            .attr('class', 'titleLine right')
            .attr(
                'x1',
                (width / 2 + margin.left - outerRadius - titleSeparate) * 0.6 + 2 * (outerRadius + titleSeparate)
            )
            .attr(
                'x2',
                (width / 2 + margin.left - outerRadius - titleSeparate) * 1.4 + 2 * (outerRadius + titleSeparate)
            )
            .attr('y1', titleOffset + 8)
            .attr('y2', titleOffset + 8);

        ////////////////////////////////////////////////////////////
        /////////////////// Animated gradient //////////////////////
        ////////////////////////////////////////////////////////////

        var defs = wrapper.append('defs');
        var linearGradient = defs
            .append('linearGradient')
            .attr('id', 'animatedGradient')
            .attr('x1', '0%')
            .attr('y1', '0%')
            .attr('x2', '100%')
            .attr('y2', '0')
            .attr('spreadMethod', 'reflect');

        linearGradient
            .append('animate')
            .attr('attributeName', 'x1')
            .attr('values', '0%;100%')
            //	.attr("from","0%")
            //	.attr("to","100%")
            .attr('dur', '7s')
            .attr('repeatCount', 'indefinite');

        linearGradient
            .append('animate')
            .attr('attributeName', 'x2')
            .attr('values', '100%;200%')
            //	.attr("from","100%")
            //	.attr("to","200%")
            .attr('dur', '7s')
            .attr('repeatCount', 'indefinite');

        linearGradient.append('stop').attr('offset', '5%').attr('stop-color', '#E8E8E8');
        linearGradient.append('stop').attr('offset', '45%').attr('stop-color', '#A3A3A3');
        linearGradient.append('stop').attr('offset', '55%').attr('stop-color', '#A3A3A3');
        linearGradient.append('stop').attr('offset', '95%').attr('stop-color', '#E8E8E8');

        ////////////////////////////////////////////////////////////
        ////////////////////////// Data ////////////////////////////
        ////////////////////////////////////////////////////////////

        //Calculate how far the Chord Diagram needs to be rotated clockwise to make the dummy
        //invisible chord center vertically

        var offset = (2 * Math.PI * (emptyStroke / (respondents + emptyStroke))) / 4;

        //Custom sort function of the chords to keep them in the original order
        var chord = customChordLayout() //d3.layout.chord()
            .padding(channelPadding)
            .sortChords(d3.descending) //which chord should be shown on top when chords cross. Now the biggest chord is at the bottom
            .matrix(matrix);

        var arc = d3.svg
            .arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius)
            .startAngle(startAngle)
            .endAngle(endAngle);

        var path = stretchedChord() //Call the stretched chord function
            .radius(innerRadius)
            .startAngle(startAngle)
            .endAngle(endAngle)
            .pullOutSize(pullOutSize);

        ////////////////////////////////////////////////////////////
        //////////////////// Draw outer Arcs ///////////////////////
        ////////////////////////////////////////////////////////////
        var g = wrapper
            .selectAll('g.group')
            .data(chord.groups)
            .enter()
            .append('g')
            .attr('class', 'group')
            // mouse events for chord labels
            .on('mouseover', function (d, i) {
                fade(d, i, opacityLow);
                const value = Math.round(d.value);
                const tooltipText = `${Names[i].name} ${d.pullOutSize === 50 ? 'gained' : 'lost'} ${
                    isRevenue ? this.props.currency.symbol : ''
                }${numeral(value).format('0,0[.]00')} ${dataTypeString} under attribution`;
                showTooltip(d, tooltipText);
            })
            .on('mouseout', function (d, i) {
                fade(d, i, opacityDefault);
                hideTooltip();
            });

        g.append('path')
            .style('stroke', function (d, i) {
                return Names[i].name === '' ? 'none' : Names[i].colour.getOriginalInput();
            })
            .style('fill', function (d, i) {
                return Names[i].name === '' ? 'none' : Names[i].colour.getOriginalInput();
            })
            .style('pointer-events', function (d, i) {
                return Names[i].name === '' ? 'none' : Names[i].colour;
            })
            .attr('d', arc)
            .attr('transform', function (d, i) {
                //Pull the two slices apart
                d.pullOutSize = pullOutSize * (d.startAngle + 0.001 > Math.PI ? -1 : 1);
                return 'translate(' + d.pullOutSize + ',' + 0 + ')';
            });

        ////////////////////////////////////////////////////////////
        ////////////////////// Append Names ////////////////////////
        ////////////////////////////////////////////////////////////

        //The text also needs to be displaced in the horizontal directions
        //And also rotated with the offset in the clockwise direction
        if (showChannels) {
            let textDistance = this.props.textDistance;
            g.append('text')
                .each(function (d) {
                    d.angle = (d.startAngle + d.endAngle) / 2 + offset;
                })
                .attr('dy', '.35em')
                .attr('class', 'titles')
                .attr('opacity', (paddingAmount / this.state.channelPadding).toString())
                .style('font-size', '14px')
                .attr('text-anchor', function (d) {
                    return d.angle > Math.PI ? 'end' : null;
                })
                .attr('transform', function (d, i) {
                    var c = arc.centroid(d);
                    let centerTranslation = `translate(${c[0] + parseFloat(d.pullOutSize)},${c[1]})`;
                    let coreRotation = 'rotate(' + ((d.angle * 180) / Math.PI - 90) + ')';
                    let expandTranslation = `translate(${textDistance}, 0)`;
                    let flipRotation = d.angle > Math.PI ? 'rotate(180)' : '';
                    return centerTranslation + coreRotation + expandTranslation + flipRotation;
                })
                .text(function (d, i) {
                    return Names[i].name;
                })
                .call(wrapChord, 100);
        }
        let isRevenue = this.state.isRevenue;

        ////////////////////////////////////////////////////////////
        //////////////////// Draw inner chords /////////////////////
        ////////////////////////////////////////////////////////////
        let desatAmount = this.props.chordDesatAmount;
        wrapper
            .selectAll('path.chord')
            .data(chord.chords)
            .enter()
            .append('path')
            .attr('class', 'chord')
            .style('stroke', 'none')
            .style('fill', function (d) {
                return Names[d.source.index].name === ''
                    ? 'none'
                    : tinycolor(Names[d.source.index].colour).desaturate(desatAmount).toRgbString();
            })
            .style('opacity', function (d) {
                return Names[d.source.index].name === '' ? 0 : opacityDefault;
            }) //Make the dummy strokes have a zero opacity (invisible)
            .style('pointer-events', function (d, i) {
                return Names[d.source.index].name === '' ? 'none' : 'auto';
            }) //Remove pointer events from dummy strokes
            .attr('d', path)
            // mouse events for chords
            .on('mouseover', function (d) {
                fadeOnChord(d);
                showTooltip(
                    d,
                    `${dataTypeString === 'revenue' ? this.props.currency.symbol : ''}${numeral(d.source.value).format(
                        '0,0[.]00'
                    )} ${dataTypeString} from ${Names[d.target.index].name} to ${Names[d.source.index].name}`
                );
            })
            .on('mouseout', function (d, i) {
                fade(d, i, opacityDefault);
                hideTooltip();
            });

        ////////////////////////////////////////////////////////////
        ////////////////// Extra Functions /////////////////////////
        ////////////////////////////////////////////////////////////

        //Include the offset in de start and end angle to rotate the Chord diagram clockwise
        function startAngle(d) {
            return d.startAngle + offset;
        }
        function endAngle(d) {
            return d.endAngle + offset;
        }

        // Returns an event handler for fading a given chord group
        function fade(d, i, opacity) {
            wrapper
                .selectAll('path.chord')
                .filter(function (d) {
                    return d.source.index !== i && d.target.index !== i && Names[d.source.index].name !== '';
                })
                .transition()
                .style('opacity', opacity);
        } //fade

        // Fade function when hovering over chord
        function fadeOnChord(d) {
            var chosen = d;
            wrapper
                .selectAll('path.chord')
                .transition()
                .style('opacity', function (d) {
                    return d.source.index === chosen.source.index && d.target.index === chosen.target.index
                        ? opacityDefault
                        : opacityLow;
                });
        } //fadeOnChord

        const tooltip = this.tip;

        function showTooltip(d, tooltipText) {
            tooltip
                .style('opacity', 1)
                .style('position', 'relative')
                .html(tooltipText)
                .style('left', `${d3.event.pageX}px`)
                .style('top', `${d3.event.pageY - document.body.getBoundingClientRect().height + 40}px`);
        }

        function hideTooltip() {
            tooltip.style('opacity', 0);
        }

        /*Taken from http://bl.ocks.org/mbostock/7555321
		//Wraps SVG text*/
        function wrapChord(text, width) {
            text.each(function () {
                var text = d3.select(this),
                    words = text.text().split(/\s+/).reverse(),
                    word,
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1.1, // ems
                    y = 0,
                    x = 0,
                    dy = parseFloat(text.attr('dy')),
                    tspan = text
                        .text(null)
                        .append('tspan')
                        .attr('x', x)
                        .attr('y', y)
                        .attr('dy', dy + 'em');

                while (words.length > 0) {
                    word = words.pop();
                    line.push(word);
                    tspan.text(line.join(' '));
                    if (tspan.node().getComputedTextLength() > width) {
                        line.pop();
                        tspan.text(line.join(' '));
                        line = [word];
                        tspan = text
                            .append('tspan')
                            .attr('x', x)
                            .attrd('y', y)
                            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                            .text(word);
                    }
                }
            });
        }
    }
    toggleChannelNames() {
        this.setState(
            {
                showingChannelNames: !this.state.showingChannelNames,
            },
            this.animateChart()
        );
    }
    animateChart() {
        let self = this;
        for (let interval of this.state.animationIntervals) {
            window.clearInterval(interval);
        }
        let anim = window.setInterval(function () {
            let currentTime =
                self.currentAnimationTime +
                (self.state.showingChannelNames ? self.state.animationInterval : self.state.animationInterval * -1);
            if (currentTime >= self.props.animationLength || currentTime <= 0) {
                window.clearInterval(anim);
            }
            self.currentAnimationTime = currentTime;
        }, self.state.animationInterval);
        this.setState({
            animationIntervals: [...this.state.animationIntervals, anim],
        });
    }
    fetchChannelColours() {
        const channelPromise = new Request();
        channelPromise.get('config', 'dash-referer', []).then(response => {
            const channels = response.data.objects.map(channel => {
                return { name: channel.name, colour: tinycolor(intToColour(channel.colour)) };
            });
            this.setState({ channels });
        });
    }
    fetchData() {
        const fetchPromise = new Request();
        for (let promise of this.contributionRequests) {
            promise.cancelRequest('aborted');
        }
        this.contributionRequests.push(fetchPromise);
        this.setState({
            isLoading: true,
            hasNoGains: false,
            hasNoData: false,
        });

        fetchPromise
            .get('reports', `fetch-contribution-analysis/${this.props.account.token}`, [
                { key: 'startdate', value: this.props.startDate.format('YYYY-MM-DDT00:00:00') + 'Z' },
                { key: 'enddate', value: this.props.endDate.format('YYYY-MM-DDT23:59:59') + 'Z' },
                { key: 'product__in', value: this.props.selectedProducts.map(product => product.id).toString() },
                { key: 'orderby', value: this.state.orderBy },
                { key: 'orderdir', value: this.state.orderDir },
            ])
            .then(response => {
                if (response.data.data.length > 0) {
                    let respondents = this.getRespondents(response.data.data, this.state.isGainsOnly);
                    this.setState({
                        respondents: respondents,
                        tableData: this.formatTableData(response.data.data, this.state.channels),
                    });
                    if (respondents < 1) {
                        this.setState({
                            hasNoGains: true,
                            isLoading: false,
                            hasResponse: false,
                        });
                    } else {
                        this.setState({
                            emptyStroke: Math.round(respondents * this.props.emptyPerc),
                            isLoading: false,
                            hasNoGains: false,
                            hasResponse: true,
                            respondents: respondents,
                            responseData: response.data.data,
                            lcReferers: response.data['lc_referers'],
                            fmReferers: response.data['fm_referers'],
                        });
                    }
                } else {
                    this.setState({
                        hasNoData: true,
                    });
                }
            })
            .catch(error => {
                if (error.message !== 'aborted')
                    this.setState({
                        hasNoData: true,
                        isLoading: false,
                    });
            });
    }
    renderGraphs() {
        let currentAnimationTime = this.currentAnimationTime;
        let isLoading = this.state.isLoading;
        let noGains = this.state.hasNoGains;

        if (noGains) {
            return 'No Channel gained more than 1 sale from another during this period.';
        }
        if (isLoading) {
            return <ContributionSkeleton chartOnly={true} />;
        }
        return (
            <div className="contribution-analysis__chart">
                {this.state.animationFrames.map((frame, index) => {
                    return (
                        <div
                            id={frame.frameId}
                            key={index}
                            currentanimtime={currentAnimationTime}
                            frameposition={frame.framePosition}
                            style={{
                                height: '100%',
                                width: '100%',
                                position: 'absolute',
                                transition: '0.2s ease',
                                opacity: currentAnimationTime === frame.framePosition && !isLoading ? '1' : '0',
                            }}
                        ></div>
                    );
                })}
            </div>
        );
    }

    hasNoChannels() {
        if (this.state.hasNoData === false) {
            this.setState({ hasNoData: true });
        }
    }
    componentDidMount() {
        this.props.setTitle('Contribution Analysis');

        // Filter bar configurations
        this.context.setFilterStatus({
            isEnableDatePicker: true,
            isEnableProductSelect: true,
            isEnableMetrics: false,
        });
        this.context.setFilterMetricsOptions([]); // Reset metrics
        this.context.setDatePickerConfig({}); // Reset datepicker

        this.fetchChannelColours();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.channels !== this.state.channels && this.state.channels.length < 1) {
            this.hasNoChannels();
        }
        if (
            (prevProps.selectedProducts !== this.props.selectedProducts && this.state.channels.length > 0) ||
            (prevProps.startDate !== this.props.startDate && this.state.channels.length > 0) ||
            (prevProps.endDate !== this.props.endDate && this.state.channels.length > 0) ||
            (prevState.channels !== this.state.channels && this.state.channels.length > 0) ||
            (prevState.orderBy !== this.state.orderBy && this.state.channels.length > 0) ||
            (prevState.orderDir !== this.state.orderDir && this.state.channels.length > 0) ||
            prevState.isRevenue !== this.state.isRevenue
        ) {
            this.generateAnimatedFrames();
            this.fetchData();
        }
        if (this.state.hasResponse === true) {
            this.assignAnimatedFrames(
                this.formatChannels(this.state.channels, this.state.lcReferers, this.state.fmReferers),
                this.state.respondents,
                this.state.responseData,
                this.state.lcReferers,
                this.state.fmReferers
            );
        }
    }

    renderButtons = () => {
        return (
            <>
                <Button
                    key="goals"
                    level={!this.state.isRevenue ? 'primary' : 'secondary'}
                    onClick={() => this.setMetric('sales')}
                >
                    GOALS
                </Button>
                <Button
                    key="revenue"
                    level={this.state.isRevenue ? 'primary' : 'secondary'}
                    onClick={() => this.setMetric('revenue')}
                >
                    REVENUE
                </Button>
            </>
        );
    };

    render() {
        if (this.state.hasNoGains === true) {
            return (
                <>
                    {this.renderButtons()}
                    <Message
                        title="No significant contributions could be found."
                        copy="No channel gained or lost more than 1 sale during the chosen period."
                        type="info"
                    />
                </>
            );
        }
        if (this.state.hasNoData === true) {
            return (
                <>
                    {this.renderButtons()}
                    <Message
                        title="No data could be found."
                        copy="This could be due to the filters that have been applied, please try a different date range or selecting different goals."
                        type="info"
                    />
                </>
            );
        }
        return (
            <div>
                {this.renderButtons()}
                {this.state.tableData ? (
                    <StyledContributionAnalysisWrapper>
                        <ContributionTable
                            key="data"
                            headerColumns={this.contributionHeaderColumns}
                            setOrderBy={this.setOrderBy}
                            data={this.state.tableData}
                            isLoading={this.state.isLoading}
                        />
                        <this.renderGraphs key="graph" />
                    </StyledContributionAnalysisWrapper>
                ) : (
                    <ContributionSkeleton />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        currency: state.currency,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTitle: title => {
            dispatch(setTitle(title));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportContributionAnalysis);
