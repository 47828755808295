import { CubedField } from '../../../../types';
import { unknownErrorData } from '../../../helpers/errors';
import { PieTableComparisonData } from '../../../types';

type UseResourcePieTableComparisonMatchSectionColoursArgs = {
    resourceData: PieTableComparisonData;
    resourceDataToMatch: PieTableComparisonData;
    dimension: CubedField;
};

const useResourcePieTableComparisonMatchSectionColours = ({
    resourceData,
    resourceDataToMatch,
    dimension,
}: UseResourcePieTableComparisonMatchSectionColoursArgs) => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    const resourceStatus = resourceData.status;
    const resourceToMatchStatus = resourceDataToMatch.status;

    if (resourceStatus !== 'success' || resourceToMatchStatus !== 'success') {
        return resourceData;
    }

    if (resourceStatus === 'success' && resourceToMatchStatus === 'success') {
        const updatedSeriesData = resourceData.series[0]
            ? resourceData.series[0].data.map(data => {
                  const matchingSeries = resourceDataToMatch?.series[0]?.data.find(matchingData => {
                      return matchingData.name === data.name;
                  });

                  const matchingComparisonSeries = resourceDataToMatch?.comparisonSeries[0]?.data.find(matchingData => {
                      return matchingData.name === data.name;
                  });

                  if (matchingSeries) {
                      return {
                          ...data,
                          color: matchingSeries.color,
                      };
                  } else if (matchingComparisonSeries) {
                      return {
                          ...data,
                          color: matchingComparisonSeries.color,
                      };
                  }
                  return data;
              })
            : [];

        const updatedComparisonSeriesData = resourceData.comparisonSeries[0]
            ? resourceData.comparisonSeries[0].data.map(data => {
                  const matchingSeries = resourceDataToMatch?.series[0]?.data.find(matchingData => {
                      return matchingData.name === data.name;
                  });

                  const matchingComparisonSeries = resourceDataToMatch?.comparisonSeries[0]?.data.find(matchingData => {
                      return matchingData.name === data.name;
                  });

                  if (matchingSeries) {
                      return {
                          ...data,
                          color: matchingSeries.color,
                      };
                  } else if (matchingComparisonSeries) {
                      return {
                          ...data,
                          color: matchingComparisonSeries.color,
                      };
                  }
                  return data;
              })
            : [];

        const updatedTableRows = resourceData.rows
            ? resourceData.rows.map(row => {
                  const matchingSeries = resourceDataToMatch.series[0]?.data.find(matchingData => {
                      return matchingData.name === row.dimension.values[dimension.rawName].rawValue;
                  });

                  const matchingComparisonSeries = resourceDataToMatch.comparisonSeries[0]?.data.find(matchingData => {
                      return matchingData.name === row.dimension.values[dimension.rawName].rawValue;
                  });

                  if (matchingSeries) {
                      return {
                          ...row,
                          colour: matchingSeries.color,
                      };
                  } else if (matchingComparisonSeries) {
                      return {
                          ...row,
                          colour: matchingComparisonSeries.color,
                      };
                  }
                  return row;
              })
            : [];

        return {
            ...resourceData,
            series:
                updatedSeriesData.length > 0
                    ? [
                          {
                              ...resourceData.series[0],
                              data: updatedSeriesData,
                          },
                      ]
                    : [],
            comparisonSeries:
                updatedComparisonSeriesData.length > 0
                    ? [
                          {
                              ...resourceData.comparisonSeries[0],
                              data: updatedComparisonSeriesData,
                          },
                      ]
                    : [],
            rows: updatedTableRows,
        };
    }

    return unknownErrorData();
};

export default useResourcePieTableComparisonMatchSectionColours;
