import React, { createContext, useEffect, useState } from 'react';

// Fields
import { PATH_SUBDIRECTORY } from '../../configurations/fields';

// Types
import { DropdownOption, SelectOptionsRequest } from '../../section-dashboard/types';

// Config
import { pageTypeDropdownRequest } from '../../section-dashboard/configurations/page-type-dropdown-options';
import { CubedField } from '../../types';

export interface TechSEODashboardMenuContextValues {
    pageTypeDropdownOptions: SelectOptionsRequest;
    selectedPageType: DropdownOption;
    handlePageTypeChange: (option: string) => void;
    currentPageTypeField: CubedField;
}

type TechSEODashboardMenuContextProps = {
    children: JSX.Element;
};

export const TechSEODashboardMenuContext = createContext<TechSEODashboardMenuContextValues>(
    {} as TechSEODashboardMenuContextValues
);

const TechSEODashboardMenuProvider = ({ children }: TechSEODashboardMenuContextProps) => {
    // Page Type Dropdown
    const pageTypeDropdownOptions = pageTypeDropdownRequest;
    const [selectedPageType, setSelectedPageType] = useState(pageTypeDropdownOptions.options[0]);
    const [currentPageTypeField, setCurrentPageTypeField] = useState(PATH_SUBDIRECTORY);

    const handlePageTypeChange = (selectedOption: string) => {
        setSelectedPageType(pageTypeDropdownOptions.options.filter(option => option.value === selectedOption)[0]);
    };

    useEffect(() => {
        setCurrentPageTypeField(selectedPageType.field);
    }, [selectedPageType]);

    return (
        <TechSEODashboardMenuContext.Provider
            value={{
                // Page Type Dropdown
                pageTypeDropdownOptions,
                selectedPageType,
                handlePageTypeChange,
                currentPageTypeField,
            }}
        >
            {children}
        </TechSEODashboardMenuContext.Provider>
    );
};

export default TechSEODashboardMenuProvider;
