import React, { Component } from 'react';
import { connect } from 'react-redux';

import Axios from 'axios';

import { removeModal, setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';

import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';

import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';
import InputButton from '../../components/inputs/input-button';
import { ButtonThemes } from '../../enums/button-themes';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

class ManageKlaviyoConnections extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            pageError: false,
            editButtonDisabled: true,
            deleteButtonDisabled: true,
            selectedConnection: null,
            klaviyoConnections: [],
        };
    }

    componentDidMount = () => {
        this.getKlaviyoConnectionList();
    };

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                const klaviyoConnections = this.props.request.data.objects.map(connection => {
                    return {
                        id: connection.id,
                        account_name: connection.account_name,
                        public_key: connection.public_key,
                        private_key: connection.private_key,
                        selected: connection.selected,
                    };
                });

                this.setState({
                    isLoading: false,
                    klaviyoConnections: klaviyoConnections,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }
        }
    }

    getKlaviyoConnectionList = () => {
        return new Promise(async (resolve, reject) => {
            const klaviyoConnectionConfig = {
                method: 'GET',
                url: generateUrl('config', 'klaviyo-connection', [{ key: 'active', value: 1 }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            try {
                const klaviyoConnectionRequest = await Axios(klaviyoConnectionConfig);
                const klaviyoConnections = klaviyoConnectionRequest.data.objects;
                this.setState({
                    klaviyoConnections: klaviyoConnections,
                });
            } catch (error) {
                if (error.message.includes('400')) {
                    reject(error.response);
                } else {
                    reject('Error getting Klaviyo connections');
                }
            }

            resolve('Got Klaviyo connection list');
        });
    };

    deleteKlaviyoConnection = () => {
        return new Promise(async (resolve, reject) => {
            const klaviyoConnectionConfig = {
                method: 'DELETE',
                url: generateUrlDetail('config', 'klaviyo-connection', this.state.selectedConnection.id),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            try {
                await Axios(klaviyoConnectionConfig);
            } catch (error) {
                if (error.message.includes('400')) {
                    reject(error.response);
                } else {
                    reject('Error deleting your Klaviyo connection.');
                }
            }
            resolve('Klaviyo Connection Deleted');
            this.getKlaviyoConnectionList();
            this.props.removePopup();
        });
    };

    onRowClick = (_, connection) => {
        connection.selected = !connection.selected;
        if (this.state.selectedConnection !== null && this.state.selectedConnection.id === connection.id) {
            this.setState({
                selectedConnection: null,
                editButtonDisabled: true,
                deleteButtonDisabled: true,
            });
        } else {
            const clearedSelected = this.state.klaviyoConnections.map(conx => {
                if (conx.id !== connection.id) {
                    return { ...conx, selected: false };
                }
                return conx;
            });
            this.setState({
                klaviyoConnections: clearedSelected,
                selectedConnection: connection,
                editButtonDisabled: false,
                deleteButtonDisabled: false,
            });
        }
    };

    onPopupCancelClick = () => {
        this.props.removePopup();
    };

    onCloseClick = () => {
        this.props.removeModal();
    };

    onAddClick = () => {
        this.props.setModal('AddKlaviyo', {});
    };

    onEditClick = () => {
        this.props.setModal('AddKlaviyo', { config: this.state.selectedConnection });
    };

    onDeleteClick = () => {
        this.props.setPopup({
            title: 'Confirm delete?',
            iconType: 'warning',
            contentType: 'simple',
            config: {
                copy: 'Are you sure you want to delete this key?',
            },
            buttons: [
                {
                    onClick: this.deleteKlaviyoConnection,
                    value: 'CONFIRM DELETE',
                },
                {
                    onClick: this.onPopupCancelClick,
                    value: 'CANCEL',
                    buttonTheme: ButtonThemes.Secondary,
                },
            ],
        });
    };

    renderTable = () => {
        const errorMessageOverride = 'There are no Klaivyo keys associated with this account.';

        const header = {
            columns: [
                {
                    title: 'Account Name',
                },
                {
                    title: 'Public Key',
                },
                {
                    title: 'Private Key',
                },
            ],
        };

        const rows = this.state.klaviyoConnections.map(connection => {
            const rowProperty = {
                selected: connection.selected,
            };

            return {
                onClick: event => {
                    this.onRowClick(event, connection);
                },
                keyValue: `klaviyoConnection__${connection.id}`,
                dataValue: connection.id,
                rowProperty,
                columns: [
                    {
                        copy: connection.account_name,
                    },
                    {
                        copy: connection.public_key,
                    },
                    {
                        copy: connection.private_key,
                    },
                ],
            };
        });
        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                enableSearch={false}
                searchableColumns={[0]}
                isScrollable={true}
            />
        );
    };

    renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'close',
                onClick: this.onCloseClick,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel">
                    <this.renderModalNavigation />
                    <h2>Manage Klaviyo Keys</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel">
                    <this.renderModalNavigation />
                    <h2>Manage Klaviyo Keys</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div>
                <this.renderModalNavigation />
                <h2>Manage Klaviyo Keys</h2>
                <p>Manage previously configured Klaviyo keys below.</p>
                <div>
                    <this.renderTable />
                    <InputButtonWrapper>
                        <InputButton value="Add Klaviyo Key" onClick={this.onAddClick} />
                        <InputButton
                            value="Edit Klaviyo Key"
                            disabled={this.state.editButtonDisabled}
                            onClick={this.onEditClick}
                        />
                        <InputButton
                            value="Delete Klaviyo Key"
                            disabled={this.state.deleteButtonDisabled}
                            onClick={this.onDeleteClick}
                        />
                    </InputButtonWrapper>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meta: state.meta,
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        removeModal: () => {
            dispatch(removeModal());
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageKlaviyoConnections);
