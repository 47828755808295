import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

// Context
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

// Types
import { Layout } from 'react-grid-layout';
import { SectionConfig } from '../../types';

// Components
import CreateADashboardSectionGrid from '../components/create-a-dashboard-section-grid';
import IconCirclePlus from '../../../components/icons/circle-plus';
import SectionDashboardSectionAccordion from '../../../section-dashboard/components/sections/section-dashboard-section-accordion';
import IconTimes from '../../../components/icons/times';
import LayoutLoading from '../../layouts/layout-loading';
import IconCircleInfo from '../../../components/icons/circle-info';

const StyledContainer = styled.div<{ breakpoint?: string; isEmpty?: boolean }>`
    width: ${props =>
        props.breakpoint === 'xs'
            ? '600px'
            : props.breakpoint === 'sm'
            ? '905px'
            : props.breakpoint === 'md'
            ? '1240px'
            : props.breakpoint === 'lg'
            ? '1440px'
            : '100%'};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: ${props =>
        !props.isEmpty && `1px solid ${props.theme.dashboardBuilder.createADashboard.preview.borderColor}`};
    border-radius: 6px;
    box-shadow: ${props => !props.isEmpty && props.theme.sectionDashboard.widget.base.shadow};
`;

const StyledEmptyContainer = styled.div`
    width: 70%;
    height: 100%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
`;

const StyledIconInfoTriangle = styled(IconCircleInfo)`
    width: 40px;
    opacity: 0.5;
    color: ${props => props.theme.dashboardBuilder.container.color};
`;

const StyledEmptyP = styled.p`
    color: ${props => props.theme.dashboardBuilder.container.color};
    font-size: 0.9rem;
    font-weight: 400;
    font-style: italic;
    margin: 0;
    text-align: center;
`;

const StyledSectionContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px;
`;

const StyledSectionItem = styled.div`
    width: 100%;
    height: 100%;
`;

const StyledRemoveSectionContainer = styled.div`
    float: right;
    position: relative;
    top: 16px;
`;

const StyledRemoveSectionButton = styled.button`
    border: none;
    outline: none;
    display: flex;
    background-color: transparent;
    color: ${props => props.theme.dashboardBuilder.container.color};
    align-items: center;
    gap: 6px;
    font-size: 0.8rem;
    font-weight: 500;
`;

const StyledIconTimes = styled(IconTimes)`
    width: 10px;
`;

const StyledDroppableContainer = styled.div`
    width: 100%;
    padding: 0px 10px;
    margin-bottom: 16px;
`;

const StyledDroppable = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};
    background-color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.droppable.backgroundColor};
    color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.droppable.color};
    border-radius: 6px;
    padding: 16px 16px;
    font-size: 0.9rem;
    font-weight: 500;
`;

const StyledIconCirclePlus = styled(IconCirclePlus)`
    width: 20px;
    color: ${props => props.theme.colours.midGrey};
`;

const LayoutCreateADashboardPreview = ({
    sections,
    handleLayoutChange,
}: {
    sections: SectionConfig[];
    handleLayoutChange: (sectionId: string, newLayout: Layout[]) => void;
}) => {
    const previewContainerRef = useRef<HTMLHeadingElement>(null);
    const { widgetQuery, breakpoint, setBreakpoint, handleRemoveSection, handleAccordionChange, showComparison } =
        useContext(CreateADashboardContext);

    useEffect(() => {
        if (previewContainerRef.current) {
            setBreakpoint(
                previewContainerRef.current.clientWidth < 600
                    ? 'xs'
                    : previewContainerRef.current.clientWidth < 905
                    ? 'sm'
                    : previewContainerRef.current.clientWidth < 1240
                    ? 'md'
                    : previewContainerRef.current.clientWidth < 1440
                    ? 'lg'
                    : 'xl'
            );
        }
    }, [widgetQuery.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    if (widgetQuery.isLoading) {
        return <LayoutLoading copy="Loading widgets..." />;
    }

    return (
        <StyledContainer breakpoint={breakpoint} ref={previewContainerRef} isEmpty={sections.length === 0}>
            {sections.length === 0 && (
                <StyledEmptyContainer>
                    <StyledIconInfoTriangle />
                    <StyledEmptyP>
                        To start building your dashboard, you will first need to add a 'Section' to your dashboard by
                        clicking the 'Add a Section' link in the sidebar to the left.
                    </StyledEmptyP>
                    <StyledEmptyP>
                        Sections allow you to group together related widgets in your dashboard layout.
                    </StyledEmptyP>
                </StyledEmptyContainer>
            )}
            {sections.length > 0 && (
                <StyledSectionContainer>
                    {sections.map(currentSection => {
                        if (breakpoint) {
                            return (
                                <StyledSectionItem key={currentSection.sectionId}>
                                    <StyledRemoveSectionContainer>
                                        <StyledRemoveSectionButton
                                            onClick={() => handleRemoveSection(currentSection.sectionId)}
                                        >
                                            <StyledIconTimes />
                                            Remove this Section
                                        </StyledRemoveSectionButton>
                                    </StyledRemoveSectionContainer>
                                    <SectionDashboardSectionAccordion
                                        sectionIndex={currentSection.index}
                                        title={currentSection.sectionName}
                                        defaultValues={
                                            !currentSection.collapsed ? [`sd-section-${currentSection.index}`] : []
                                        }
                                        onAccordionChange={handleAccordionChange}
                                    >
                                        <div>
                                            {currentSection.layouts[breakpoint].length > 0 && (
                                                <CreateADashboardSectionGrid
                                                    section={currentSection}
                                                    layouts={
                                                        showComparison
                                                            ? currentSection.comparisonLayouts
                                                            : currentSection.layouts
                                                    }
                                                    layout={
                                                        showComparison
                                                            ? currentSection.comparisonLayouts[breakpoint]
                                                            : currentSection.layouts[breakpoint]
                                                    }
                                                    handleLayoutChange={handleLayoutChange}
                                                />
                                            )}

                                            <StyledDroppableContainer>
                                                <Droppable droppableId={currentSection?.sectionId}>
                                                    {provided => (
                                                        <>
                                                            <StyledDroppable
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >
                                                                <StyledIconCirclePlus />
                                                                Drag and drop a Widget here to add it to this section.
                                                            </StyledDroppable>
                                                            {provided.placeholder}
                                                        </>
                                                    )}
                                                </Droppable>
                                            </StyledDroppableContainer>
                                        </div>
                                    </SectionDashboardSectionAccordion>
                                </StyledSectionItem>
                            );
                        }
                        return null;
                    })}
                </StyledSectionContainer>
            )}
        </StyledContainer>
    );
};

export default LayoutCreateADashboardPreview;
