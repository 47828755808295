import React from 'react';
import styled from 'styled-components';
import WidgetBaseLoader from '../../section-dashboard/widgets/base/widget-base-loader';

const StyledContainer = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const StyledLoadingText = styled.p`
    color: ${props => props.theme.colours.midGrey};
    font-size: 0.9rem;
    font-weight: 500;
    font-style: italic;
`;

const LayoutLoading = ({ copy }: { copy: string }) => {
    return (
        <StyledContainer>
            <WidgetBaseLoader width={40} height={40} margin={10} transformOrigin={true} />
            <StyledLoadingText>{copy}</StyledLoadingText>
        </StyledContainer>
    );
};

export default LayoutLoading;
