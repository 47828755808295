/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import theme from '../../../styled-components/theme.styled';

// Fields & Resources
import * as fields from '../../../configurations/fields';
import * as resources from '../../../configurations/resources';

// Hooks
import { useDatesFormatted } from '../../../hooks/use-dates';
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourceTotal from '../../../section-dashboard/hooks/widget/use-resource-total';
import useResourceSparkLine from '../../../section-dashboard/hooks/widget/use-resource-spark-line';
import useResourceBigNumber from '../../../section-dashboard/hooks/widget/big-number/use-resource-big-number';
import useResourcePieChart from '../../../section-dashboard/hooks/widget/pie/use-resource-pie-chart';
import useRequestTable from '../../../section-dashboard/hooks/request/use-request-table';
import useResourceTable from '../../../section-dashboard/hooks/widget/table/use-resource-table';
import useResourceVennResource from '../../../section-dashboard/hooks/widget/venn/use-resource-venn-resource';
import useResourceVenn from '../../../section-dashboard/hooks/widget/venn/use-resource-venn';
import useWidgetMenuFooterQuerySelect from '../../../section-dashboard/widgets/menus/hooks/use-widget-menu-footer-query-select';
import useView from '../../../section-dashboard/hooks/view/use-view';
import useResourceFunnelChart from '../../../section-dashboard/hooks/widget/use-resource-funnel-chart';
import useResourcePieChartTable from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table';
import useResourceCategoryMix from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table-category-mix';
import useResourceTableCustomGroups from '../../../section-dashboard/hooks/widget/table/use-resource-table-custom-groups';
import useResourceCombineBigNumberRequests from '../../../section-dashboard/hooks/widget/big-number/use-resource-combine-big-number-requests';
import useRequestStackedAreaChart from '../../../section-dashboard/hooks/request/use-request-stacked-area-chart';
import useResourceStackedAreaChart from '../../../section-dashboard/hooks/widget/use-resource-stacked-area-chart';
import useRequestPie from '../../../section-dashboard/hooks/request/use-request-pie';
import useRequestPieChartTable from '../../../section-dashboard/hooks/request/use-request-pie-chart-table';

// Widgets
import WidgetSparkLine from '../../../section-dashboard/widgets/widget/widget-spark-line';
import WidgetBigNumber from '../../../section-dashboard/widgets/widget/widget-big-number';
import WidgetTable from '../../../section-dashboard/widgets/widget/widget-table';
import WidgetPieChart from '../../../section-dashboard/widgets/widget/widget-pie-chart';
import WidgetVenn from '../../../section-dashboard/widgets/widget/widget-venn';
import WidgetFunnelChart from '../../../section-dashboard/widgets/widget/widget-funnel-chart';
import WidgetMenuTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';
import WidgetPieChartTable from '../../../section-dashboard/widgets/widget/widget-pie-chart-table';
import WidgetStackedAreaChart from '../../../section-dashboard/widgets/widget/widget-stacked-area-chart';
import WidgetMenuStackedAreaChart from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-stacked-area-chart';
import WidgetMenuPieChartTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie-chart-table';
import WidgetMenuPie from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie';

// Menu
import BeforeGridGoalMenu from '../../components/sections/before-grid/before-grid-goal-menu';

// Components
import BeforeSectionPageTypeDropdown from '../../components/sections/before-section/before-section-page-type-dropdown';
import BeforeGridConfiguredGoals from '../../../section-dashboard/components/sections/before-grid/before-grid-configured-goals';

// Types
import { FilterOperator, WidgetColour, WidgetMenuObj } from '../../../section-dashboard/types';

// Context
import { ContentInsightsDashboardMenuContext } from '../../content-insights-context/content-insights-dashboard-menu-context';

// Queries
import { defaultGoalsDropdownQuery, goalsDropdownQuery } from '../../../section-dashboard/queries';
import { contentInsightsPageTypeDropdownQuery } from '../../queries';

export const config = {
    title: 'Content Insights Dashboard',
    description: '', // TODO description
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableComparisonDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnableButtonGroup: false,
            isEnablePdfExport: true,
            isEnableChannelMultiSelect: false,
            isEnableChannelSingleSelect: {
                enabled: false,
            },
        },
        comparisonStatus: {
            showComparisonDatePicker: false,
            redirectUrl: 'content-insights-comparison-dashboard',
        },
        datePicker: {},
    },
    context: () => {
        // Context
        const menuContext = useContext(ContentInsightsDashboardMenuContext);
        return { menuContext };
    },
    pageTypeTitle: () => {
        return config.context().menuContext.selectedPageType.label;
    },
    layouts: {
        xs: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a'
                    'b'
                    'c'
                    'd'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'e'
                    'f'
                    'g'
                    'h'
                    'h'
                    'i'
                    'j'
                    'k'
                    'l'
                    'm'
                    'n'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'o'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'p'
                    `,
                },
                {
                    title: (): string => `Content Overview - Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'q'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'r'
                    's'
                    't'
                    'u'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    grid: `
                    'v'
                    'w'
                    'x'
                    `,
                },
                {
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'y'
                    'z'
                    'aa'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'bb'
                    `,
                },
                {
                    title: (): string => `Content Overview - Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'dd'
                    `,
                },
                {
                    title: 'Top Performing Pages',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'ee'
                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ff'
                    `,
                },
                {
                    title: (): string =>
                        ` ${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'gg'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'hh'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'ii'
                    `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a b'
                    'c d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'e f'
                    'g g'
                    'h h'
                    'h h'
                    'i i'
                    'i i'
                    'j j'
                    'k k'
                    'l l'
                    'm m'
                    'm m'
                    'n n'
                    'n n'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'o o'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'p p'
                    `,
                },
                {
                    title: (): string => `Content Overview - Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'q q'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'r r'
                    's s'
                    't t'
                    'u u'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    grid: `
                    'v v'
                    'w w'
                    'x x'
                    `,
                },
                {
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'y y'
                    'z z'
                    'aa aa'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'bb bb'
                    `,
                },
                {
                    title: (): string => `Content Overview - Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'dd dd'
                    `,
                },
                {
                    title: 'Top Performing Pages',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'ee ee'
                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ff ff'
                    `,
                },
                {
                    title: (): string =>
                        ` ${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'gg gg'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'hh hh'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'ii ii'
                    `,
                },
            ],
        },
        md: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b'
                    'c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'i h h e'
                    'i h h g'
                    'j f f k'
                    'l l l l'
                    'm m m m'
                    'm m m m'
                    'n n n n'
                    'n n n n'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'o o o o'
                    'o o o o'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'p p p p'
                    'p p p p'
                    `,
                },
                {
                    title: (): string => `Content Overview - Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'q q q q'
                    'q q q q'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'r r r r'
                    's s s s'
                    't t t t'
                    'u u u u'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    grid: `
                    'v v v v'
                    'w w w w'
                    'x x x x'
                    `,
                },
                {
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'y y y y'
                    'z z z z'
                    'aa aa aa aa'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'bb bb bb bb'
                    'bb bb bb bb'
                    `,
                },
                {
                    title: (): string => `Content Overview - Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'dd dd dd dd'
                    'dd dd dd dd'
                    `,
                },
                {
                    title: 'Top Performing Pages',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'ee ee ee ee'
                    'ee ee ee ee'
                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ff ff ff ff'
                    'ff ff ff ff'
                    `,
                },
                {
                    title: (): string =>
                        ` ${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'gg gg gg gg'
                    'gg gg gg gg'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'hh hh hh hh'
                    'hh hh hh hh'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'ii ii ii ii'
                    'ii ii ii ii'
                    `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a a b b b'
                    'a a a b b b'
                    'c c c d d d'
                    'c c c d d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'i i h h e j'
                    'i i h h f k'
                    'i i h h g l'
                    'm m m n n n'
                    'm m m n n n'
                    'm m m n n n'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'o o o o o o'
                    'o o o o o o'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'p p p p p p'
                    'p p p p p p'
                    'p p p p p p'
                    `,
                },
                {
                    title: (): string => `Content Overview - Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'q q q q q q'
                    'q q q q q q'
                    'q q q q q q'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'r r r s s s'
                    't t t u u u'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    grid: `
                    'v v w w x x'
                    `,
                },
                {
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'y y z z aa aa'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'bb bb bb bb bb bb'
                    'bb bb bb bb bb bb'
                    'bb bb bb bb bb bb'
                    `,
                },
                {
                    title: (): string => `Content Overview - Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'dd dd dd dd dd dd'
                    'dd dd dd dd dd dd'
                    'dd dd dd dd dd dd'
                    `,
                },
                {
                    title: 'Top Performing Pages',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'ee ee ee ee ee ee'
                    'ee ee ee ee ee ee'
                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ff ff ff ff ff ff'
                    'ff ff ff ff ff ff'
                    'ff ff ff ff ff ff'
                    `,
                },
                {
                    title: (): string =>
                        ` ${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'gg gg gg gg gg gg'
                    'gg gg gg gg gg gg'
                    'gg gg gg gg gg gg'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'hh hh hh hh hh hh'
                    'hh hh hh hh hh hh'
                    'hh hh hh hh hh hh'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'ii ii ii ii ii ii'
                    'ii ii ii ii ii ii'
                    'ii ii ii ii ii ii'
                    `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b c c d d'
                    'a a b b c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'i i h h h h e j'
                    'i i h h h h f k'
                    'i i h h h h g l'
                    'm m m m n n n n'
                    'm m m m n n n n'
                    'm m m m n n n n'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'o o o o o o o o'
                    'o o o o o o o o'
                    'o o o o o o o o'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'p p p p p p p p'
                    'p p p p p p p p'
                    'p p p p p p p p'
                    `,
                },
                {
                    title: (): string => `Content Overview - Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'q q q q q q q q'
                    'q q q q q q q q'
                    'q q q q q q q q'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'r r s s t t u u'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'v w x x y z aa aa'
                    `,
                },
                {
                    title: (): string => `Content Overview - ${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'bb bb bb bb bb bb bb bb'
                    'bb bb bb bb bb bb bb bb'
                    'bb bb bb bb bb bb bb bb'
                    `,
                },
                {
                    title: (): string => `Content Overview - Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'dd dd dd dd dd dd dd dd'
                    'dd dd dd dd dd dd dd dd'
                    'dd dd dd dd dd dd dd dd'
                    `,
                },
                {
                    title: 'Top Performing Pages',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'ee ee ee ee ee ee ee ee'
                    'ee ee ee ee ee ee ee ee'
                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ff ff ff ff ff ff ff ff'
                    'ff ff ff ff ff ff ff ff'
                    'ff ff ff ff ff ff ff ff'
                    `,
                },
                {
                    title: (): string =>
                        ` ${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'gg gg gg gg gg gg gg gg'
                    'gg gg gg gg gg gg gg gg'
                    'gg gg gg gg gg gg gg gg'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'hh hh hh hh hh hh hh hh'
                    'hh hh hh hh hh hh hh hh'
                    'hh hh hh hh hh hh hh hh'
                    `,
                },
                {
                    title: (): string => ` ${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'ii ii ii ii ii ii ii ii'
                    'ii ii ii ii ii ii ii ii'
                    'ii ii ii ii ii ii ii ii'
                    `,
                },
            ],
        },
    },
    components: () => {
        const menuContext = useContext(ContentInsightsDashboardMenuContext);
        const {
            currentPageTypeField,
            contentOverviewTrafficSectionMenu,
            contentOverviewTrafficByPageTypeSectionMenu,
            homepageRelianceGoalsMenu,
            contentOverviewConversionMixSectionMenu,
            contentOverViewConversionsByPageTypeSectionMenu,
            topPerformingPagesSectionMenu,
            goalCompletionsGoalSummarySectionMenu,
            pageTypePerformanceSummaryByGoalSectionMenu,
            pageTypePerformanceOverTimeTrafficSectionMenu,
            pageTypePerformanceOverTimeConversionsSectionMenu,
        } = menuContext;

        // Dates
        const { startDate: startDateTime, endDate: endDateTime } = useDatesFormatted({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
        });
        const { startDate, endDate } = useDatesFormatted({ resource: resources.REPORT_CONTENT_DASHBOARD_PATH });

        // Widget Links
        const links = {
            pagePerformance: '/reports/attribution/page-performance',
            attributionExplorer: '/reports/attribution/lc-vs-attribution',
        };

        // Sparklines
        const goalSnapshotData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                isGraph: true,
                groupBy: fields.SALES_DATE_DATE,
                graphGroupBy: [fields.SALES_DATE_DATE],
                graphMetrics: [
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
            },
        });

        const sparkTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                totals: true,
            },
        });

        const sparkTotals = useResourceTotal({
            resourceData: sparkTotalData,
        });

        // Visits Sparkline
        const visitsSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.VISITS,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Sales Sparkline
        const salesSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.FM_SALES,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Revenue Sparkline
        const revenueSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.FM_REVENUE,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Cost Sparkline
        const costSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.COST,
            dateDimension: fields.SALES_DATE_DATE,
        });

        const goalSnapshotDefaultGoalTotalsData = (menu: WidgetMenuObj) =>
            useResource({
                resource: resources.REPORT_MCS_CHANNEL,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                    ...(menu.request.filters ?? []),
                    filters: [
                        ...(menu.request.filters ?? []),
                        { field: fields.PRODUCT_DEFAULT, operator: FilterOperator.EQUALS, value: '1' },
                    ],
                },
            });

        // Default Goal Goals Completed
        const defaultGoalTotalGoalsBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalTotalGoalsBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalGoalsBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Default Goal Attributed Value
        const defaultGoalTotalRevenueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalTotalRevenueBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalRevenueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // Default Goal CR%
        const defaultGoalCRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalCRBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalCRBigNumberMenu),
            field: fields.FM_CVR,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        const goalSnapshotBigNumberData = (menu: WidgetMenuObj) =>
            useResource({
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [
                        fields.VISITS,
                        fields.COST,
                        fields.FM_SALES,
                        fields.FM_REVENUE,
                        fields.GOAL_CVR_GOAL_SNAPSHOT,
                    ],
                    ...menu.request,
                },
            });

        // Total Goals Completed
        const totalGoalsCompletedBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const totalGoalsCompletedBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalGoalsCompletedBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Attributed Value
        const totalAttributedValueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const totalAttributedValueBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalAttributedValueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // All Goals CR%
        const totalCVRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const totalCVRBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalCVRBigNumberMenu),
            field: fields.GOAL_CVR_GOAL_SNAPSHOT,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Goals Snapshot Pie
        const goalSnapshotGoalsPieRequest = useRequestPie({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
        });

        const goalSnapshotGoalsPieData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: goalSnapshotGoalsPieRequest,
        });

        let goalSnapshotPie = useResourcePieChart({
            resourceData: goalSnapshotGoalsPieData,
            dimension: fields.PRODUCT,
        });

        // Goal Snapshot Table
        const goalSnapshotTableRequest = useRequestTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
        });

        const goalSnapshotTableData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: goalSnapshotTableRequest,
        });

        const goalSnapshotTableTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                ...goalSnapshotTableRequest,
                totals: true,
            },
        });

        const goalSnapshotTable = useResourceTable({
            resourceData: goalSnapshotTableData,
        });

        const goalSnapshotTableTotals = useResourceTotal({
            resourceData: goalSnapshotTableTotalData,
        });

        // Goal Completion Crossover Venn
        const goalCrossoverVennData = useResourceVennResource({
            dimensionResource: {
                resource: resources.REPORT_DASH_PRODUCT,
                request: {
                    ignoreDate: true,
                    groupBy: fields.PRODUCT_NAME,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                },
            },
            vennResource: {
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
                request: {
                    ignoreDate: true,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                    groupBy: fields.ID,
                    filters: [
                        { field: fields.START_DATETIME, operator: FilterOperator.EQUALS, value: startDateTime },
                        { field: fields.END_DATETIME, operator: FilterOperator.EQUALS, value: endDateTime },
                    ],
                },
            },
        });

        const goalCrossoverVenn = useResourceVenn({
            resourceData: goalCrossoverVennData,
            metric: fields.VISITORS,
            diagramTitle: 'Goal Completion Crossover',
        });

        // Goal Completion Complexity Funnel
        const goalCompletionComplexityFooterMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalCompletionComplexityFunnelData = useView({
            resource: resources.AGG_CUSTOMER_JOURNEY_COMPLEXITY,
            request: goalCompletionComplexityFooterMenu.request,
        });

        const goalCompletionComplexityFunnel = useResourceFunnelChart({
            resourceData: goalCompletionComplexityFunnelData,
            metric: fields.SALES,
            name: 'Goal Completion Complexity',
        });

        // Goal Completions Stacked Area Chart
        const goalCompletionStackedAreaChartRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Count of Completions', field: fields.FM_SALES, active: true },
                { name: 'Value of Completions', field: fields.FM_REVENUE, active: false },
            ],
            groupBy: fields.PRODUCT,
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' },
            ],
        });

        const goalCompletionsData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: goalCompletionStackedAreaChartRequest,
        });

        const goalCompletionsAdditionalSeries = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
            },
        });

        const goalCompletionStackedAreaChart = useResourceStackedAreaChart({
            areaSeriesMetric: fields.PRODUCT,
            resourceData: goalCompletionsData,
            dateDimension: fields.SALES_DATE_DATE,
            optionalSeries: [
                {
                    title: 'Unique Visits',
                    yAxis: fields.VISITS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.purple,
                },
                {
                    title: 'Unique Visitors',
                    yAxis: fields.VISITORS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.emerald,
                },
            ],
        });

        // Content Overview - Page Type Traffic Mix (by pageviews)
        const contentOverviewTrafficRequest = useRequestPieChartTable({
            groupBy: currentPageTypeField,
            orderBy: [{ field: fields.UNIQUE_PAGE_VIEWS, orderByDirection: 'desc' }],
            fields: [currentPageTypeField, fields.UNIQUE_PAGE_VIEWS, fields.CATEGORY_MIX],
            chartField: fields.UNIQUE_PAGE_VIEWS,
            chartMetrics: [fields.UNIQUE_PAGE_VIEWS, fields.CATEGORY_MIX],
            rowsPerPage: 10,
            ...contentOverviewTrafficSectionMenu.request,
            selectedFields: [currentPageTypeField, fields.UNIQUE_PAGE_VIEWS],
        });

        const contentOverviewTrafficData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: contentOverviewTrafficRequest,
        });

        const contentOverviewByChannelTotalData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...contentOverviewTrafficRequest,
                totals: true,
            },
        });

        const contentOverviewDonutPieChartTableTotal = useResourceTotal({
            resourceData: contentOverviewByChannelTotalData,
        });

        const contentOverviewViewMix = useResourceCategoryMix({
            resourceData: contentOverviewTrafficData,
            totalData: contentOverviewDonutPieChartTableTotal,
            mixField: fields.UNIQUE_PAGE_VIEWS,
        });

        const contentOverviewDonutPieChartTable = useResourcePieChartTable({
            resourceData: contentOverviewViewMix,
            chartDimension: currentPageTypeField,
        });

        // Content Overview
        // Traffic By Page Type Table
        const trafficByPageTypeRequest = useRequestTable({
            groupBy: currentPageTypeField,
            fields: [
                currentPageTypeField,
                fields.UNIQUE_PAGE_VIEWS,
                fields.PAGE_VIEWS,
                fields.PAGE_EXITS,
                fields.PAGE_EXIT_RATE,
                fields.LANDING_PAGE_VIEWS,
                fields.LANDING_PAGE_BOUNCES,
                fields.LANDING_PAGE_BOUNCE_RATE,
            ],
            orderBy: [{ field: fields.UNIQUE_PAGE_VIEWS, orderByDirection: 'desc' }],
            ...contentOverviewTrafficByPageTypeSectionMenu.request,
        });

        const trafficByPageTypeData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: trafficByPageTypeRequest,
        });

        const trafficByPageTypeTotalData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...trafficByPageTypeRequest,
                totals: true,
            },
        });

        const trafficByPageTypeTable = useResourceTable({
            resourceData: trafficByPageTypeData,
        });

        const trafficByPageTypeTableTotals = useResourceTotal({
            resourceData: trafficByPageTypeTotalData,
        });

        /***
         Title: Homepage Reliance Analysis section - Traffic
         Widget: Big Number
        */
        // All Page Resource
        const allpageRelianceAnalysisTrafficData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_SUBDIRECTORY],
                graphMetrics: [fields.UNIQUE_PAGE_VIEWS],
                selectedFields: [fields.PATH_SUBDIRECTORY, fields.UNIQUE_PAGE_VIEWS],
            },
        });

        const homepagePathsRelianceAnalysisTrafficData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.UNIQUE_PAGE_VIEWS],
                orFilters: [
                    { field: fields.PATH_CATEGORY, operator: FilterOperator.EQUALS, value: 'Homepage' },
                    { field: fields.PATH_PATH, operator: FilterOperator.EQUALS, value: '/' },
                    { field: fields.PATH_SUBDIRECTORY, operator: FilterOperator.EQUALS, value: 'home' },
                    { field: fields.PATH_HOMEPAGE, operator: FilterOperator.EQUALS, value: '1' },
                ],
            },
        });

        // Combine the Home and '/' Root page resources
        const homepageRelianceAnalysisTrafficData = useResourceCombineBigNumberRequests({
            resourceData: [homepagePathsRelianceAnalysisTrafficData],
        });

        // Total Page Views (All Pages)
        const totalAllPagesVieswTrafficBigNumber = useResourceBigNumber({
            resourceData: allpageRelianceAnalysisTrafficData,
            field: fields.UNIQUE_PAGE_VIEWS,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Page Views (Home Page)
        const totalHomePageViewsTrafficBigNumber = useResourceBigNumber({
            resourceData: homepageRelianceAnalysisTrafficData,
            field: fields.UNIQUE_PAGE_VIEWS,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Page Views (Home Page) / Total Page Views (All Pages)
        const totalAllPagesVsHomePageViewsTrafficBigNumber = useResourceBigNumber({
            resourceData: homepageRelianceAnalysisTrafficData,
            aggregationSumData: allpageRelianceAnalysisTrafficData,
            field: fields.UNIQUE_PAGE_VIEWS,
            aggregation: 'mix',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        /***
         Title: Homepage Reliance Analysis section - Goals
         Widget: Big Number
        */
        // All Page Resource
        const totalGoalCompletionsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_SUBDIRECTORY],
                graphMetrics: [fields.SALES],
                ...homepageRelianceGoalsMenu.request,
                selectedFields: [fields.PATH_SUBDIRECTORY, fields.SALES],
            },
        });

        // Home Page Resource
        const homepageHomeGoalCompletionsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.SALES],
                orFilters: [
                    { field: fields.PATH_CATEGORY, operator: FilterOperator.EQUALS, value: 'Homepage' },
                    { field: fields.PATH_PATH, operator: FilterOperator.EQUALS, value: '/' },
                    { field: fields.PATH_SUBDIRECTORY, operator: FilterOperator.EQUALS, value: 'home' },
                    { field: fields.PATH_HOMEPAGE, operator: FilterOperator.EQUALS, value: '1' },
                ],
                ...homepageRelianceGoalsMenu.request,
            },
        });

        // Combine the Home and '/' Root page resources
        const homepageGoalCompletionsData = useResourceCombineBigNumberRequests({
            resourceData: [homepageHomeGoalCompletionsData],
        });

        // Total Page Views (All Pages)
        const totalGoalCompletionsBigNumber = useResourceBigNumber({
            resourceData: totalGoalCompletionsData,
            field: fields.SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Page Views (Home Page)
        const totalHomePageGoalCompletionsBigNumber = useResourceBigNumber({
            resourceData: homepageGoalCompletionsData,
            field: fields.SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Page Views (Home Page) / Total Page Views (All Pages)
        const totalAllPagesVsHomePageViewsGoalCompletionsBigNumber = useResourceBigNumber({
            resourceData: homepageGoalCompletionsData,
            aggregationSumData: totalGoalCompletionsData,
            field: fields.SALES,
            aggregation: 'mix',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Top Performing Pages Table
        const pageGroupMostVisitedData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.PAGE_VIEWS, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const groupGreatestConversions = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.CONVERSIONS, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const groupGreatestValue = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.CONVERSION_VALUE, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const pageMostVisitedData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: fields.PATH_PATH,
                orderBy: [{ field: fields.UNIQUE_PAGE_VIEWS, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const pageGreatestConversions = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: fields.PATH_PATH,
                orderBy: [{ field: fields.CONVERSIONS, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const pageGreatestValue = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: fields.PATH_PATH,
                orderBy: [{ field: fields.CONVERSION_VALUE, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const topPerformingPagesTable = useResourceTableCustomGroups({
            fields: [fields.UNIQUE_PAGE_VIEWS, fields.CONVERSIONS, fields.CONVERSION_VALUE],
            descriptionTitle: 'Performance Metric',
            dimensionTitle: 'Page URL',
            groups: [
                {
                    title: 'Page Type Groupings',
                    dimensionField: currentPageTypeField,
                    rows: [
                        {
                            description: 'Most Visited Page Type Group',
                            resourceData: pageGroupMostVisitedData,
                        },
                        {
                            description: 'Group driving greatest count of conversions',
                            resourceData: groupGreatestConversions,
                        },
                        {
                            description: 'Group driving greatest value of conversions',
                            resourceData: groupGreatestValue,
                        },
                    ],
                },
                {
                    title: 'Individual Pages',
                    dimensionField: fields.PATH_PATH,
                    rows: [
                        {
                            description: 'Most Visited Page',
                            resourceData: pageMostVisitedData,
                        },
                        {
                            description: 'Page driving greatest count of conversions',
                            resourceData: pageGreatestConversions,
                        },
                        {
                            description: 'Page driving greatest value of conversions',
                            resourceData: pageGreatestValue,
                        },
                    ],
                },
            ],
        });

        /***
         Title: Pages with Zero Pageviews section
         Widget: Big Number
        */
        //  Total Count of Indexed Pages Resource
        const totalCountOfIndexedPagesTotalsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_DOMAIN_PERFORMANCE,
            request: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.DOMAIN],
                graphMetrics: [fields.INDEXED_PAGE_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: endDate,
                    },
                ],
            },
        });

        // Total Count of Pages viewed Resource
        const totalCountOfPagesViewedTotalsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_DOMAIN_PERFORMANCE,
            request: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.DOMAIN],
                graphMetrics: [fields.PAGES_VIEWED_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: endDate,
                    },
                ],
            },
        });

        // Total Count of Indexed Pages
        const totalCountOfIndexedPagesBigNumber = useResourceBigNumber({
            resourceData: totalCountOfIndexedPagesTotalsData,
            field: fields.INDEXED_PAGE_COUNT,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Count of Pages viewed
        const totalCountOfPageViewedBigNumber = useResourceBigNumber({
            resourceData: totalCountOfPagesViewedTotalsData,
            field: fields.PAGES_VIEWED_COUNT,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Count of Pages Zero Views
        const totalCountOfPageZeroViewsBigNumber = useResourceBigNumber({
            resourceData: totalCountOfIndexedPagesTotalsData,
            aggregationSumData: totalCountOfPagesViewedTotalsData,
            field: fields.INDEXED_PAGE_COUNT,
            aggregationSumField: fields.PAGES_VIEWED_COUNT,
            aggregation: 'difference',
            displayGraph: false,
        });

        // Browsed : Unbrowsed Ratio
        const browsedVsUnbrowsedRatioBigNumber = useResourceBigNumber({
            resourceData: totalCountOfPagesViewedTotalsData,
            aggregationSumData: totalCountOfIndexedPagesTotalsData,
            field: fields.PAGES_VIEWED_COUNT,
            aggregationSumField: fields.INDEXED_PAGE_COUNT,
            aggregation: 'ratio',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 0,
            },
            displayGraph: false,
        });

        // Content Overview - Page Type Conversion Mix (by goal value)
        const contentOverviewConversionsGoalMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const contentOverviewConversionsRequest = useRequestPieChartTable({
            groupBy: currentPageTypeField,
            orderBy: [{ field: fields.CONVERSION_VALUE, orderByDirection: 'desc' }],
            fields: [currentPageTypeField, fields.CONVERSIONS, fields.CONVERSION_VALUE, fields.CATEGORY_MIX],
            chartField: fields.CONVERSION_VALUE,
            chartMetrics: [fields.CONVERSION_VALUE, fields.CONVERSIONS, fields.CATEGORY_MIX],
            rowsPerPage: 10,
            ...contentOverviewConversionsGoalMenu.request,
            ...contentOverviewConversionMixSectionMenu.request,
            selectedFields: [currentPageTypeField, fields.CONVERSIONS, fields.CONVERSION_VALUE],
        });

        const contentOverviewConversionsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: contentOverviewConversionsRequest,
        });

        const contentOverviewConversionsTotalsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...contentOverviewConversionsRequest,
                totals: true,
            },
        });

        const contentOverviewConversionsDonutPieChartTableTotal = useResourceTotal({
            resourceData: contentOverviewConversionsTotalsData,
        });

        const contentOverviewMixGoalValue = useResourceCategoryMix({
            resourceData: contentOverviewConversionsData,
            totalData: contentOverviewConversionsDonutPieChartTableTotal,
            mixField: fields.CONVERSION_VALUE,
        });

        const contentOverviewMixGoalValueDonutPieChartTable = useResourcePieChartTable({
            resourceData: contentOverviewMixGoalValue,
            chartDimension: currentPageTypeField,
        });

        // Content Overview
        // Conversions By Page Type Table
        const conversionsByPageTypeRequest = useRequestTable({
            groupBy: currentPageTypeField,
            fields: [
                currentPageTypeField,
                fields.UNIQUE_PAGE_VIEWS,
                fields.ASSISTS,
                fields.ASSISTS_PAGE_VIEWS_RATE,
                fields.CONVERSIONS,
                fields.CONVERSIONS_CVR,
                fields.CONVERSION_VALUE,
            ],
            orderBy: [{ field: fields.CONVERSION_VALUE, orderByDirection: 'desc' }],
            ...contentOverViewConversionsByPageTypeSectionMenu.request,
            selectedFields: [
                currentPageTypeField,
                fields.UNIQUE_PAGE_VIEWS,
                fields.ASSISTS,
                fields.ASSISTS_PAGE_VIEWS_RATE,
                fields.CONVERSIONS,
                fields.CONVERSIONS_CVR,
                fields.CONVERSION_VALUE,
            ],
        });

        const conversionsByPageTypeData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: conversionsByPageTypeRequest,
        });

        const conversionsByPageTypeTotalData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...conversionsByPageTypeRequest,
                totals: true,
            },
        });

        const conversionsByPageTypeTable = useResourceTable({
            resourceData: conversionsByPageTypeData,
        });

        const conversionsByPageTypeTableTotals = useResourceTotal({
            resourceData: conversionsByPageTypeTotalData,
        });

        // Goal Completions - Goal Summary by Page Type - Donut/Table
        const goalCompletionsGoalByPageMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
        });

        const goalCompletionsGoalByPageRequest = useRequestPieChartTable({
            groupBy: currentPageTypeField,
            orderBy: [{ field: fields.CONVERSIONS, orderByDirection: 'desc' }],
            fields: [
                currentPageTypeField,
                fields.PAGE_VIEWS,
                fields.UNIQUE_PAGE_VIEWS,
                fields.CONVERSIONS,
                fields.REVENUE,
            ],
            chartField: fields.CONVERSIONS,
            chartMetrics: [fields.CONVERSIONS, fields.PAGE_VIEWS, fields.UNIQUE_PAGE_VIEWS, fields.REVENUE],
            rowsPerPage: 10,
            ...goalCompletionsGoalByPageMenu.request,
            ...goalCompletionsGoalSummarySectionMenu.request,
            selectedFields: [
                currentPageTypeField,
                fields.PAGE_VIEWS,
                fields.UNIQUE_PAGE_VIEWS,
                fields.CONVERSIONS,
                fields.REVENUE,
            ],
        });

        const goalCompletionsGoalByPageData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: goalCompletionsGoalByPageRequest,
        });

        const goalCompletionsGoalByPageTotalData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...goalCompletionsGoalByPageRequest,
                totals: true,
            },
        });

        const goalCompletionsGoalByPageDonutTable = useResourcePieChartTable({
            resourceData: goalCompletionsGoalByPageData,
            chartDimension: currentPageTypeField,
        });

        const goalCompletionsGoalByPageDonutTableTotal = useResourceTotal({
            resourceData: goalCompletionsGoalByPageTotalData,
        });

        // Page Type Performance - Page Type Summary By Goal
        const pageTypePerformancePageByGoalMenu = useWidgetMenuFooterQuerySelect({
            query: contentInsightsPageTypeDropdownQuery({
                currentPageTypeField: currentPageTypeField,
                startDate: startDate,
                endDate: endDate,
                excludeUnknown: pageTypePerformanceSummaryByGoalSectionMenu.checked,
            }),
            dropdownField: currentPageTypeField,
        });

        const pageTypePerformancePageByGoalRequest = useRequestPieChartTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.CONVERSIONS, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.PAGE_VIEWS, fields.UNIQUE_PAGE_VIEWS, fields.CONVERSIONS, fields.REVENUE],
            chartField: fields.CONVERSIONS,
            chartMetrics: [fields.CONVERSIONS, fields.PAGE_VIEWS, fields.UNIQUE_PAGE_VIEWS, fields.REVENUE],
            rowsPerPage: 10,
            ...pageTypePerformancePageByGoalMenu.request,
            ...pageTypePerformanceSummaryByGoalSectionMenu.request,
            selectedFields: [
                fields.PRODUCT,
                fields.PAGE_VIEWS,
                fields.UNIQUE_PAGE_VIEWS,
                fields.CONVERSIONS,
                fields.REVENUE,
            ],
        });

        const pageTypePerformancePageByGoalData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: pageTypePerformancePageByGoalRequest,
        });

        const pageTypePerformancePageByGoalTotalData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...pageTypePerformancePageByGoalRequest,
                totals: true,
            },
        });

        const pageTypePerformancePageByGoalDonutTable = useResourcePieChartTable({
            resourceData: pageTypePerformancePageByGoalData,
            chartDimension: fields.PRODUCT,
        });

        const pageTypePerformancePageByGoalDonutTableTotal = useResourceTotal({
            resourceData: pageTypePerformancePageByGoalTotalData,
        });

        // Page Type Performance Over Time - Traffic - Stacked Area Chart
        const pageTypePerformanceOverTimeTrafficRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Page Views', field: fields.PAGE_VIEWS, active: true },
                { name: 'Unique Page Views', field: fields.UNIQUE_PAGE_VIEWS, active: false },
            ],
            groupBy: [currentPageTypeField, fields.SALES_DATE_DATE],
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.PAGE_VIEWS, orderByDirection: 'desc' },
            ],
            ...pageTypePerformanceOverTimeTrafficSectionMenu.request,
            selectedFields: [currentPageTypeField, fields.PAGE_VIEWS, fields.UNIQUE_PAGE_VIEWS],
        });

        const pageTypePerformanceOverTimeTrafficData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: pageTypePerformanceOverTimeTrafficRequest,
        });

        const pageTypePerformanceOverTimeTraffic = useResourceStackedAreaChart({
            areaSeriesMetric: currentPageTypeField,
            resourceData: pageTypePerformanceOverTimeTrafficData,
            dateDimension: fields.SALES_DATE_DATE,
            seriesLimit: 10,
        });

        // Page Type Performance Over Time - Conversions - Stacked Area Chart
        const pageTypePerformanceOverTimeConversionsRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Goal Completions', field: fields.CONVERSIONS, active: true },
                { name: 'Conversion Value', field: fields.CONVERSION_VALUE, active: false },
            ],
            groupBy: [currentPageTypeField, fields.SALES_DATE_DATE],
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.CONVERSIONS, orderByDirection: 'desc' },
            ],
            ...pageTypePerformanceOverTimeConversionsSectionMenu.request,
            selectedFields: [currentPageTypeField, fields.CONVERSIONS, fields.CONVERSION_VALUE],
        });

        const pageTypePerformanceOverTimeConversionsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: pageTypePerformanceOverTimeConversionsRequest,
        });

        const pageTypePerformanceOverTimeConversions = useResourceStackedAreaChart({
            areaSeriesMetric: currentPageTypeField,
            resourceData: pageTypePerformanceOverTimeConversionsData,
            dateDimension: fields.SALES_DATE_DATE,
            seriesLimit: 10,
        });

        return {
            a: (
                <WidgetSparkLine
                    title="Visits by Day"
                    data={visitsSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Teal}
                />
            ),
            b: (
                <WidgetSparkLine
                    title="Sales by Day"
                    data={salesSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Orange}
                />
            ),
            c: (
                <WidgetSparkLine
                    title="Revenue by Day"
                    data={revenueSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Green}
                />
            ),
            d: (
                <WidgetSparkLine
                    title="Cost by Day"
                    data={costSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Rose}
                />
            ),
            e: (
                <WidgetBigNumber
                    title="Default Goal Goals Completed"
                    data={defaultGoalTotalGoalsBigNumber}
                    href={links.attributionExplorer}
                    menu={defaultGoalTotalGoalsBigNumberMenu.menu}
                />
            ),
            f: (
                <WidgetBigNumber
                    title="Default Goal Attributed Value"
                    data={defaultGoalTotalRevenueBigNumber}
                    href={links.attributionExplorer}
                    menu={defaultGoalTotalRevenueBigNumberMenu.menu}
                />
            ),
            g: (
                <WidgetBigNumber
                    title="Default Goal CR%"
                    data={defaultGoalCRBigNumber}
                    href={links.attributionExplorer}
                    menu={defaultGoalCRBigNumberMenu.menu}
                />
            ),
            h: (
                <WidgetTable
                    title="Goals Snapshot"
                    data={goalSnapshotTable}
                    totalData={goalSnapshotTableTotals}
                    href={links.attributionExplorer}
                    menu={<WidgetMenuTable data={goalSnapshotTable} />}
                />
            ),
            i: (
                <WidgetPieChart
                    title={'Goals Completed'}
                    data={goalSnapshotPie}
                    href={links.attributionExplorer}
                    display="donut"
                    menu={<WidgetMenuPie data={goalSnapshotPie} />}
                />
            ),
            j: (
                <WidgetBigNumber
                    title="Total Goals Completed"
                    data={totalGoalsCompletedBigNumber}
                    href={links.attributionExplorer}
                    menu={totalGoalsCompletedBigNumberMenu.menu}
                />
            ),
            k: (
                <WidgetBigNumber
                    title="Total Attributed Value"
                    data={totalAttributedValueBigNumber}
                    href={links.attributionExplorer}
                    menu={totalAttributedValueBigNumberMenu.menu}
                />
            ),
            l: (
                <WidgetBigNumber
                    title="All Goals CR%"
                    data={totalCVRBigNumber}
                    href={links.attributionExplorer}
                    menu={totalCVRBigNumberMenu.menu}
                />
            ),
            m: <WidgetVenn title="Goal Completion Crossover" data={goalCrossoverVenn} />,
            n: (
                <WidgetFunnelChart
                    title="Goal Completion Complexity"
                    data={goalCompletionComplexityFunnel}
                    menu={goalCompletionComplexityFooterMenu.menu}
                />
            ),
            o: (
                <WidgetStackedAreaChart
                    title="Goal Completions Over Time"
                    data={goalCompletionStackedAreaChart}
                    href={links.attributionExplorer}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={goalCompletionStackedAreaChart}
                            request={goalCompletionStackedAreaChartRequest}
                        />
                    }
                />
            ),
            p: (
                <WidgetPieChartTable
                    title={`Content Overview - ${config.pageTypeTitle()} Traffic Mix (by pageviews)`}
                    data={contentOverviewDonutPieChartTable}
                    totalData={contentOverviewDonutPieChartTableTotal}
                    href={links.pagePerformance}
                    display="donut"
                    menu={<WidgetMenuPieChartTable data={contentOverviewDonutPieChartTable} />}
                />
            ),
            q: (
                <WidgetTable
                    title={`Traffic By ${config.pageTypeTitle()}`}
                    data={trafficByPageTypeTable}
                    totalData={trafficByPageTypeTableTotals}
                    href={links.pagePerformance}
                    menu={<WidgetMenuTable data={trafficByPageTypeTable} />}
                />
            ),
            r: <WidgetBigNumber title="Total Count of Indexed Pages" data={totalCountOfIndexedPagesBigNumber} />,
            s: <WidgetBigNumber title="Total Count of Pages viewed" data={totalCountOfPageViewedBigNumber} />,
            t: (
                <WidgetBigNumber
                    title="Total Count of Pages with Zero Views"
                    data={totalCountOfPageZeroViewsBigNumber}
                />
            ),
            u: <WidgetBigNumber title="Browsed : Unbrowsed Ratio" data={browsedVsUnbrowsedRatioBigNumber} />,
            v: (
                <WidgetBigNumber
                    title="Total Pageviews"
                    data={totalAllPagesVieswTrafficBigNumber}
                    href={links.pagePerformance}
                />
            ),
            w: (
                <WidgetBigNumber
                    title="Homepage Pageviews"
                    data={totalHomePageViewsTrafficBigNumber}
                    href={links.pagePerformance}
                />
            ),
            x: (
                <WidgetBigNumber
                    title="Homepage Pageview Mix"
                    data={totalAllPagesVsHomePageViewsTrafficBigNumber}
                    href={links.pagePerformance}
                />
            ),
            y: (
                <WidgetBigNumber
                    title="Total Goal Completions"
                    data={totalGoalCompletionsBigNumber}
                    href={links.pagePerformance}
                />
            ),
            z: (
                <WidgetBigNumber
                    title="Homepage Goal Completions"
                    data={totalHomePageGoalCompletionsBigNumber}
                    href={links.pagePerformance}
                />
            ),
            aa: (
                <WidgetBigNumber
                    title="Homepage Goal Completion Mix"
                    data={totalAllPagesVsHomePageViewsGoalCompletionsBigNumber}
                    href={links.pagePerformance}
                />
            ),
            bb: (
                <WidgetPieChartTable
                    title={`Content Overview - ${config.pageTypeTitle()} Conversion Mix`}
                    data={contentOverviewMixGoalValueDonutPieChartTable}
                    totalData={contentOverviewConversionsDonutPieChartTableTotal}
                    href={links.pagePerformance}
                    display="donut"
                    menu={
                        <>
                            {contentOverviewConversionsGoalMenu.menu}
                            <WidgetMenuPieChartTable data={contentOverviewMixGoalValueDonutPieChartTable} />
                        </>
                    }
                />
            ),
            dd: (
                <WidgetTable
                    title={`Content Overview - Conversions By ${config.pageTypeTitle()}`}
                    data={conversionsByPageTypeTable}
                    totalData={conversionsByPageTypeTableTotals}
                    href={links.pagePerformance}
                    menu={<WidgetMenuTable data={conversionsByPageTypeTable} />}
                />
            ),
            ee: (
                <WidgetTable
                    title="Top Performing Pages (during selected period)"
                    data={topPerformingPagesTable}
                    href={links.pagePerformance}
                />
            ),
            ff: (
                <WidgetPieChartTable
                    title={`Goal Completions - Goal Summary by ${config.pageTypeTitle()}`}
                    data={goalCompletionsGoalByPageDonutTable}
                    totalData={goalCompletionsGoalByPageDonutTableTotal}
                    href={links.pagePerformance}
                    display="donut"
                    menu={
                        <>
                            {goalCompletionsGoalByPageMenu.menu}
                            <WidgetMenuPieChartTable data={goalCompletionsGoalByPageDonutTable} />
                        </>
                    }
                />
            ),
            gg: (
                <WidgetPieChartTable
                    title={`${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`}
                    data={pageTypePerformancePageByGoalDonutTable}
                    totalData={pageTypePerformancePageByGoalDonutTableTotal}
                    href={links.pagePerformance}
                    display="donut"
                    menu={
                        <>
                            {pageTypePerformancePageByGoalMenu.menu}
                            <WidgetMenuPieChartTable data={pageTypePerformancePageByGoalDonutTable} />
                        </>
                    }
                />
            ),
            hh: (
                <WidgetStackedAreaChart
                    title={`${config.pageTypeTitle()} Performance Over Time - Traffic`}
                    data={pageTypePerformanceOverTimeTraffic}
                    href={links.pagePerformance}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={pageTypePerformanceOverTimeTraffic}
                            request={pageTypePerformanceOverTimeTrafficRequest}
                        />
                    }
                />
            ),
            ii: (
                <WidgetStackedAreaChart
                    title={`${config.pageTypeTitle()} Performance Over Time - Conversion`}
                    data={pageTypePerformanceOverTimeConversions}
                    href={links.pagePerformance}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={pageTypePerformanceOverTimeConversions}
                            request={pageTypePerformanceOverTimeConversionsRequest}
                        />
                    }
                />
            ),
        };
    },
};
