import React from 'react';
import styled from 'styled-components';
import { inputStyles } from './styles/input-styles';

// Context
import { FieldError, useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

// Validation
import buildValidators from '../validation/helpers/build-validators';
import { Validator } from '../types';

// Components
import InputContainer from './components/input-container';
import InputErrorMessage from './components/input-error-message';

const StyledInput = styled.input<{ disabled: boolean }>`
    ${inputStyles}
`;

export type InputNumberProps = {
    name: string;
    placeholder?: string;
    disabled?: boolean;
    validators?: Validator[];
};

const InputNumber = ({ name, placeholder, disabled, validators = [] }: InputNumberProps) => {
    const { status } = useCubedFormContext();
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <InputContainer>
            <StyledInput
                type="number"
                placeholder={placeholder}
                disabled={!!disabled || status === 'disabled' || status === 'loading'}
                {...register(name, { ...buildValidators(validators) })}
            />

            {errors[name] && <InputErrorMessage error={errors[name] as FieldError} />}
        </InputContainer>
    );
};

export default InputNumber;
