import { DefaultTheme } from 'styled-components';
import { PieRatio } from '../types';

export const buildPieRatio = (theme: DefaultTheme, width: number, height: number, ratio: PieRatio, limit: number) => {
    if (height > 1) {
        const isLargeWidget = width > 6 && height > 2;
        const fontSize = isLargeWidget ? '2.4rem' : '1.5rem';
        const percentageSize = isLargeWidget ? '1.7rem' : '1rem';
        const top = isLargeWidget ? '-3px' : '-2px';
        const labelSize = isLargeWidget ? '14px' : '12px';

        const percentage = `<span style="font-size: ${percentageSize}; position: relative; top: ${top};">%</span>`;

        const ratioLeft = `
            <span style="color: ${ratio.ratioLeft.colour};">
                ${ratio.ratioLeft.value}${percentage}
            </span>
        `;
        const ratioRight = `
            <span style="color: ${theme.sectionDashboard.widget.pie.ratioColor};">
                ${ratio.ratioRight.value}${percentage}
            </span>
        `;

        return `
            <div style="font-weight: bold; font-size: ${fontSize}; color: ${theme.sectionDashboard.widget.pie.ratioColor}">${ratioLeft} : ${ratioRight}</div>
            <div style="display: block; text-align: center; font-size: ${labelSize}; font-weight: ${theme.sectionDashboard.widget.pie.ratioFontWeight}; color: ${theme.sectionDashboard.widget.pie.ratioDescriptionColor};">Top ${limit} vs Others</div>
        `;
    }
};
