// Helpers
import { unknownErrorData } from '../../helpers/errors';

// Types
import { CubedField } from '../../../types';
import { FunnelChartData, ViewData } from '../../types';
import { graphColours } from '../../helpers/graph-colours';

export type useResourceFunnelChartArgs = {
    resourceData: ViewData;
    metric: CubedField;
    name: string;
};

const useResourceFunnelChart = ({ resourceData, metric, name }: useResourceFunnelChartArgs): FunnelChartData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const series = resourceData.series.map((seriesData, index) => {
            return {
                ...seriesData,
                color: graphColours[index].solidGraphColour,
            };
        });

        return {
            type: 'funnelChart',
            status: 'success',
            series: series,
            total: resourceData.total,
            name: metric.displayName,
        };
    }

    return unknownErrorData();
};

export default useResourceFunnelChart;
