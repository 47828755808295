import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../redux/actions/modal';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Tooltip, { TooltipPopUpSide } from '../tooltip';
import IconChevronRight from '../icons/chevron-right';
import IconMinus from '../icons/minus';
import IconPlus from '../icons/plus';
import { StyledCollapseIconContainerExpanded } from './nav-item';

const StyledNavlink = styled(NavLink)`
    text-decoration: none;
`;

const StyledExpandedTitle = styled.div`
    align-items: center;
    display: flex;
    color: ${props => props.theme.colours.light};
    font-size: 13px;
    padding: 14px 26px 14px 30px;
    margin-left: ${props => (props.hasSiblingMenuItems ? '45px' : '60px')};
    width: 80%;
    font-weight: 500;
    text-align: left;
    border-left: 1px solid
        ${props => (props.isActive ? props.theme.colours.cubedOrange : props.theme.colours.darkerGrey)};

    &:hover {
        border-left: 1px solid ${props => props.theme.colours.cubedOrange};
        background-color: ${props => props.theme.colours.offBlackLighter};
    }
`;

const StyledExpandedDropdownItem = styled.li`
    color: ${props => props.theme.colours.light};
    font-size: 13px;
    margin-left: ${props => (props.hasSiblingMenuItems ? '45px' : '60px')};
    border-left: 1px solid
        ${props => (props.isActive ? props.theme.colours.cubedOrange : props.theme.colours.darkerGrey)};
    padding: 14px 20px 14px 30px;
    font-weight: 500;
    text-align: left;

    &:hover {
        border-left: 1px solid ${props => props.theme.colours.cubedOrange};
        background-color: ${props => props.theme.colours.offBlackLighter};
    }
`;

const StyledSectionTitle = styled.li`
    display: flex;
    gap: 5px;
    cursor: auto;
    color: ${props => props.theme.colours.cubedOrange};
    margin-left: ${props => (props.hasSiblingMenuItems ? '45px' : '60px')};
    border-left: 1px solid ${props => props.theme.colours.darkerGrey};
    padding: 5px 16px;
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
`;

const StyledIconContainer = styled.div`
    & svg {
        height: 8px;

        & path {
            fill: ${props => props.theme.colours.cubedOrange};
        }
    }
`;

const NavDropdownGroupItem = ({ item, selectedItem, setSelectedItem, onGroupItemClick, hasSiblingMenuItems }) => {
    const { account } = useSelector(state => state);
    const dispatch = useDispatch();

    const [dropdownIsActive, setDropdownIsActive] = useState(false);

    const checkActive = isActive => {
        let pathHash = item.path.split('#')[1];

        let locationHash = window.location.hash.slice(1);

        if (pathHash) {
            return pathHash === locationHash;
        }

        return isActive;
    };

    useEffect(() => {
        if (item.displayName !== selectedItem.subgroup) {
            setDropdownIsActive(false);
        }
        if (item.dropDownSubGroups) {
            item.dropDownSubGroups.forEach(subItem => {
                if (subItem.displayName === selectedItem.subgroup) {
                    setDropdownIsActive(true);
                }
            });
        }
    }, [item, selectedItem]);

    const handleItemClick = () => {
        setSelectedItem({ ...selectedItem, subgroup: item.displayName });
        setDropdownIsActive(!dropdownIsActive);
    };

    const handleGroupItemClick = item => {
        setSelectedItem({ ...selectedItem, subgroup: item.displayName });
    };

    const handleItemEnterPress = e => {
        if (e.key === 'Enter') {
            setSelectedItem({ ...selectedItem, subgroup: item.displayName });
            setDropdownIsActive(!dropdownIsActive);
        }
    };

    if (item.isSectionTitle) {
        return (
            <StyledSectionTitle>
                <StyledIconContainer>
                    <IconChevronRight />
                </StyledIconContainer>
                {item.displayName}
            </StyledSectionTitle>
        );
    } else if (item.modal) {
        return (
            <StyledNavlink to={'#'} key={item.displayName} onClick={() => dispatch(setModal(item.modal, {}))}>
                <Tooltip
                    copy={item.description}
                    tooltipPopUpSide={TooltipPopUpSide.Right}
                    usePlaceholder={false}
                    invertColours={true}
                    preventClick={false}
                >
                    <StyledExpandedDropdownItem key={item.displayName}>{item.displayName}</StyledExpandedDropdownItem>
                </Tooltip>
            </StyledNavlink>
        );
    } else if (item.dropDownSubGroups) {
        return (
            <li key={item.displayName}>
                <StyledExpandedTitle
                    onClick={handleItemClick}
                    onKeyDown={handleItemEnterPress}
                    tabIndex={0}
                    hasSiblingMenuItems={hasSiblingMenuItems}
                    hasDropdown={item.hasDropdown}
                >
                    {item.displayName}
                    <StyledCollapseIconContainerExpanded>
                        {dropdownIsActive ? <IconMinus /> : <IconPlus />}
                    </StyledCollapseIconContainerExpanded>
                </StyledExpandedTitle>
                {dropdownIsActive && item.dropDownSubGroups && (
                    <ul data-testid="nav-section-subitem-subgroups">
                        {item.dropDownSubGroups
                            .filter(group => !group.subGroupExcludedAccountTypes?.includes(account.group))
                            .map(item => {
                                return (
                                    <NavDropdownGroupItem
                                        item={item}
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                        handleItemClick={handleItemClick}
                                        onGroupItemClick={handleGroupItemClick}
                                        handleItemEnterPress={handleItemEnterPress}
                                        key={item.displayName}
                                        hasSiblingMenuItems={hasSiblingMenuItems}
                                    />
                                );
                            })}
                    </ul>
                )}
            </li>
        );
    } else {
        return (
            <StyledNavlink
                end
                to={`/${account.token + item.path}`}
                onClick={() => onGroupItemClick(item)}
                children={({ isActive }) => {
                    return (
                        <Tooltip
                            copy={item.description}
                            tooltipPopUpSide={TooltipPopUpSide.Right}
                            usePlaceholder={false}
                            invertColours={true}
                            preventClick={false}
                        >
                            <StyledExpandedDropdownItem
                                key={item.displayName}
                                isActive={checkActive(isActive)}
                                hasSiblingMenuItems={hasSiblingMenuItems}
                            >
                                {item.displayName}
                            </StyledExpandedDropdownItem>
                        </Tooltip>
                    );
                }}
            />
        );
    }
};

export default NavDropdownGroupItem;
