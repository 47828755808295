import React from 'react';
import styled from 'styled-components';

const StyledDialogButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
`;

const DialogButtonGroup = ({ children }: { children: React.ReactNode }) => {
    return <StyledDialogButtonContainer>{children}</StyledDialogButtonContainer>;
};

export default DialogButtonGroup;
