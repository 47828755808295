// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import { generateUrlDetail } from '../../helpers/request-builder';
import Request from '../../helpers/request';
import { isAlphaNumericString, isUrl } from '../../helpers/validator';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { removeModal, setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

class LayoutModalZendeskApi extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageError: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: false,
            // Accordion States
            editZendeskConnectionAccordionOpen: false,
            editZendeskConnectionAccordionVisible: false,
            removeButtonState: true,
            removeButtonDisabled: true,
            editZendeskConnectionButtonDisabled: true,
            editZendeskConnectionApplyButtonDisabled: true,
            editZendeskConnectionCancelButtonDisabled: true,

            // Disable Table States
            disableZendeskConnectionSelect: false,
            // List States

            zendeskConnections: [],
            selectedZendeskConnection: [],
            removedZendeskConnection: [],
            updatedZendeskConnection: [],

            // Input Field States
            editAccountName: '',
            editSubdomain: '',

            //Error Messages
            editAccountNameErrorMessage: '',
            editSubdomainErrorMessage: '',
        };

        this.onCreateClick = this.onCreateClick.bind(this);
        this.onCreatePopupContinue = this.onCreatePopupContinue.bind(this);
    }

    request = new Request();

    componentDidMount() {
        const config = [
            {
                resourceGroup: 'integrations',
                resourceName: 'zendesk-config',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
            {
                resourceGroup: 'integrations',
                resourceName: 'zendesk-connections',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
        ];

        this.props.getRequest(config);
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                // Sorting Out Zendesk Connection and Usernames
                this.getConnection().then(connections => {
                    this.setState({
                        isLoading: false,
                        zendeskConnections: connections,
                    });
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }

            this.props.delRequest();
        }

        // Enable the save changes and cancel when a user is added into either the revoked or updated arrays
        if (
            this.state.saveChangesButtonHidden &&
            (this.state.updatedZendeskConnection.length > 0 || this.state.removedZendeskConnection.length > 0)
        ) {
            this.setState({
                saveChangesButtonHidden: false,
                closeButtonState: 'cancel',
            });
        }
    }

    getConnection() {
        return new Promise(async (resolve, reject) => {
            try {
                const rawConnections = this.props.request.data[0].objects;
                const rawAccounts = this.props.request.data[1].objects;
                const connections = [];

                for (let index = 0; index < rawAccounts.length; index++) {
                    let userEmail = 'Unknown';
                    let userId = rawAccounts[index].cubed_user_id;

                    if (userId === this.props.user.id) {
                        userEmail = this.props.user.email;
                    } else if (this.props.account.group === 'Admin') {
                        try {
                            let user = await this.request.getDetail('config', 'lite-user-email', userId);
                            userEmail = user.data.email;
                        } catch (e) {
                            console.error(e);
                        }
                    }
                    connections.push({
                        id: rawAccounts[index].id,
                        accountName: rawAccounts[index].account_name,
                        cubedUserEmail: userEmail,
                        userId: rawAccounts[index].cubed_user_id,
                        subdomain: rawConnections.filter(
                            config => config.connection === rawAccounts[index].resource_uri
                        )[0]?.subdomain,
                    });
                }

                resolve(connections);
            } catch (e) {
                reject(e);
            }
        });
    }

    formValidatorZendeskConnection = () => {
        this.setState({
            editAccountNameErrorMessage: '',
            editSubdomainErrorMessage: '',
        });

        let hasFormError = false;
        const errorMessageObject = {
            editAccountNameErrorMessage: '',
            editSubdomainErrorMessage: '',
        };

        if (!isAlphaNumericString(this.state.editAccountName) || this.state.editAccountName.length === 0) {
            hasFormError = true;
            errorMessageObject.editAccountNameErrorMessage = 'Please enter a valid Account Name.';
        }

        if (!isUrl(this.state.editSubdomain) || this.state.editSubdomain.length === 0) {
            hasFormError = true;
            errorMessageObject.editSubdomainErrorMessage = 'Please enter a valid subdomain.';
        }

        if (hasFormError) {
            this.setState(errorMessageObject);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    saveZendeskConnections = () => {
        return new Promise((resolve, reject) => {
            const requests = [];
            if (this.state.updatedZendeskConnection.length > 0) {
                this.state.updatedZendeskConnection.forEach(zendeskConnection => {
                    // updated the zendesk connection
                    requests.push(
                        Axios({
                            method: 'PUT',
                            url: generateUrlDetail('integrations', 'zendesk-connections', zendeskConnection.id),
                            data: {
                                account_name: zendeskConnection.accountName,
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );

                    requests.push(
                        Axios({
                            method: 'PUT',
                            url: generateUrlDetail('integrations', 'zendesk-config', zendeskConnection.id),
                            data: {
                                subdomain: this.state.editSubdomain,
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                });
            }

            if (this.state.removedZendeskConnection.length > 0) {
                this.state.removedZendeskConnection.forEach(zendeskConnection => {
                    requests.push(
                        Axios({
                            method: 'DELETE',
                            url: generateUrlDetail('integrations', 'zendesk-connections', zendeskConnection.id),
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );

                    requests.push(
                        Axios({
                            method: 'DELETE',
                            url: generateUrlDetail('integrations', 'zendesk-config', zendeskConnection.config.id),
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                });
            }

            Axios.all(requests)
                .then(
                    Axios.spread((...requestRes) => {
                        resolve();
                    })
                )
                .catch(error => {
                    reject();
                });
        });
    };

    onSaveChangesClick = () => {
        this.setState({
            closeButtonDisabled: true,
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: true,
        });

        this.saveZendeskConnections()
            .then(() => {
                this.props.addNotification({
                    copy: 'The modifications to the Zendesk Account were made successfully.',
                    type: NotificationMessageType.Success,
                });
                this.props.removeModal();
            })
            .catch(() => {
                this.props.addNotification({
                    copy: 'There was an issue modifying these Zendesk Account, please try again later.',
                    type: NotificationMessageType.Error,
                });
                this.setState({
                    closeButtonDisabled: false,
                    saveChangesButtonHidden: false,
                    saveChangesButtonLoading: false,
                });
            });
    };

    onCloseClick = () => {
        if (this.state.closeButtonState === 'close') {
            this.props.removeModal();
            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            });
        }
    };

    onEditAccountNameAccordionChange = event => {
        this.setState({
            editAccountName: event.target.value,
        });
    };
    onEditSubdomainAccordionChange = event => {
        this.setState({
            editSubdomain: event.target.value,
        });
    };

    onAccordionCloseClick = () => {
        // Clear and reset all the user interactions but maintaining what have been already applied
        this.setState({
            editZendeskConnectionAccordionOpen: false,
            editZendeskConnectionAccordionVisible: false,
            editZendeskConnectionButtonDisabled: true,
            editZendeskConnectionApplyButtonDisabled: true,
            editZendeskConnectionCancelButtonDisabled: true,
            disableZendeskConnectionSelect: false,
            selectedZendeskConnection: [],
            editAccountName: '',
        });
    };

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick = () => {
        this.props.removePopup();
        this.props.removeModal();
    };

    // SecondaryAction of the close popup
    onPopupStayHereClick = () => {
        this.props.removePopup();
    };

    onCreatePopupContinue() {
        window.location = `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/zendesk/initialise`;
    }

    onCreateClick = () => {
        this.props.setPopup({
            title: 'Create connection with Zendesk.',
            iconType: 'informative',
            contentType: 'simple',
            config: {
                copy: 'You will be taken to Zendesk to authorize access to your data. Once you have authorized Cubed, our access to your data can be managed via the Cubed account configuration pages.',
            },
            buttons: [
                {
                    value: 'SAVE & CONTINUE',
                    onClick: this.onCreatePopupContinue,
                },
                {
                    value: 'STAY HERE',
                    buttonTheme: ButtonThemes.Secondary,
                    onClick: this.onPopupStayHereClick,
                },
            ],
        });
    };

    onAddZendeskClick = () => {
        this.props.setModal('AddZendesk', {});
    };

    onEditZendeskConnectionClick = () => {
        let zendeskConnection = this.state.selectedZendeskConnection[0];
        const zendeskConnectionInUpdatedZendeskConnection = this.state.updatedZendeskConnection.filter(
            filteredZendeskConnection => filteredZendeskConnection.id === zendeskConnection.id
        );

        if (zendeskConnectionInUpdatedZendeskConnection.length === 1) {
            zendeskConnection = zendeskConnectionInUpdatedZendeskConnection[0];
        }

        this.setState({
            editZendeskConnectionAccordionOpen: true,
            editZendeskConnectionAccordionVisible: true,
            editZendeskConnectionButtonDisabled: true,
            editZendeskConnectionCancelButtonDisabled: false,
            editZendeskConnectionApplyButtonDisabled: false,
            removeButtonDisabled: true,
            disableZendeskConnectionSelect: true,
            editAccountName: this.state.selectedZendeskConnection[0].accountName,
            editSubdomain: this.state.selectedZendeskConnection[0].subdomain,
        });
    };

    onRemoveClick = () => {
        const removedConnections = [].concat(this.state.removedZendeskConnection);
        const updatedConnections = [].concat(this.state.updatedZendeskConnection);

        this.state.selectedZendeskConnection.forEach(zendeskConnection => {
            if (this.state.removeButtonState) {
                removedConnections.push(zendeskConnection);
            } else {
                removedConnections.splice(removedConnections.indexOf(zendeskConnection), 1);
            }

            // If a user was edited and then revoked in the same instance then the revoke should take priority
            const updatedConnectionIndex = updatedConnections.indexOf(zendeskConnection);

            if (updatedConnectionIndex >= 0) {
                updatedConnections.splice(updatedConnectionIndex, 1);
            }
        });

        this.setState({
            removedZendeskConnection: removedConnections,
            updatedZendeskConnection: updatedConnections,
            selectedZendeskConnection: [],
            removeButtonState: true,
            editZendeskConnectionButtonDisabled: true,
            removeButtonDisabled: true,
        });
    };

    onEditZendeskConnectionApplyClick = () => {
        if (!this.formValidatorZendeskConnection()) {
            return;
        }

        const updatedZendeskConnection = [].concat(this.state.updatedZendeskConnection);

        this.state.selectedZendeskConnection.forEach(zendeskConnection => {
            zendeskConnection = Object.assign({}, zendeskConnection);

            // Check if any changes were actually made, if not ignore it
            if (
                zendeskConnection.accountName !== this.state.editAccountName ||
                zendeskConnection.subdomain !== this.state.editSubdomain
            ) {
                const zendeskConnectionInUpdatedZendeskConnection = updatedZendeskConnection.filter(
                    filteredZendeskConnection => filteredZendeskConnection.id === zendeskConnection.id
                );
                const indexOfZendeskConnection = updatedZendeskConnection.indexOf(
                    zendeskConnectionInUpdatedZendeskConnection[0]
                );

                if (indexOfZendeskConnection >= 0) {
                    updatedZendeskConnection[indexOfZendeskConnection].accountName = this.state.editAccountName;
                } else {
                    zendeskConnection.accountName = this.state.editAccountName;
                    updatedZendeskConnection.push(zendeskConnection);
                }
            }
        });

        this.setState({
            updatedZendeskConnection: updatedZendeskConnection,
        });

        // Call this function as it already handles resetting all the states
        this.onAccordionCloseClick();
    };

    onZendeskConnectionSelect = event => {
        // Don't register a click if the table has been disabled
        if (this.state.disableZendeskConnectionSelect === true) {
            return;
        }

        const selectedConnectionId = parseInt(event.currentTarget.getAttribute('data-value'));

        let selectedConnections = [];

        if (
            this.state.selectedZendeskConnection.filter(
                zendeskConnection => zendeskConnection.id === selectedConnectionId
            ).length > 0
        ) {
            selectedConnections = this.state.selectedZendeskConnection.filter(
                zendeskConnection => zendeskConnection.id !== selectedConnectionId
            );
        } else {
            selectedConnections = this.state.selectedZendeskConnection.concat(
                this.state.zendeskConnections.filter(zendeskConnection => zendeskConnection.id === selectedConnectionId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRemoved = false;
        let containsNonRemoved = false;
        let containsEdited = false;

        selectedConnections.forEach(zendeskConnection => {
            if (this.state.removedZendeskConnection.indexOf(zendeskConnection) >= 0) {
                containsRemoved = true;
            } else {
                containsNonRemoved = true;
            }

            if (this.state.updatedZendeskConnection.indexOf(zendeskConnection) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editZendeskConnectionButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;

        if (selectedConnections.length > 0) {
            editZendeskConnectionButtonDisabled = false;
            removeButtonDisabled = false;
        }

        if (selectedConnections.length > 1) {
            editZendeskConnectionButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRemoved && !containsNonRemoved && !containsEdited) {
            editZendeskConnectionButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRemoved) {
            editZendeskConnectionButtonDisabled = true;
        }

        if (containsRemoved && containsNonRemoved) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        this.setState({
            selectedZendeskConnection: selectedConnections,
            editZendeskConnectionButtonDisabled: editZendeskConnectionButtonDisabled,
            removeButtonDisabled: removeButtonDisabled,
            removeButtonState: removeButtonState,
            editZendeskConnectionApplyButtonDisabled: true,
            editZendeskConnectionAccordionOpen: false,
        });
    };

    renderModalNavigation = () => {
        let numberOfChanges = this.state.updatedZendeskConnection.length + this.state.removedZendeskConnection.length;

        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: this.onSaveChangesClick,
                hidden: this.state.saveChangesButtonHidden,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    };

    renderAccordion = () => {
        if (this.state.editZendeskConnectionAccordionVisible === true) {
            const accordion = [
                {
                    header: 'Edit Zendesk Connection',
                    required: false,
                    open: this.state.editZendeskConnectionAccordionOpen,
                    type: 'form',
                    config: {
                        formConfig: {
                            fields: [
                                {
                                    label: 'Account Name:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter your Zendesk Account Name.',
                                    inputKeyValue: 'editAccountName',
                                    inputPlaceholder: 'Account Name...',
                                    inputValue: this.state.editAccountName,
                                    inputOnChange: this.onEditAccountNameAccordionChange,
                                    errorMessage: this.state.editAccountNameErrorMessage,
                                },
                                {
                                    label: 'Subdomain:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter your Zendesk Subdomain.',
                                    inputKeyValue: 'editSubdomain',
                                    inputPlaceholder: 'yoursubdomain.zendesk.com',
                                    inputValue: this.state.editSubdomain,
                                    inputOnChange: this.onEditSubdomainAccordionChange,
                                    errorMessage: this.state.editSubdomainErrorMessage,
                                },
                            ],
                            buttons: [
                                {
                                    value: 'APPLY',
                                    onClick: this.onEditZendeskConnectionApplyClick,
                                    disabled: this.state.editZendeskConnectionApplyButtonDisabled,
                                },
                                {
                                    value: 'CANCEL',
                                    buttonTheme: ButtonThemes.Secondary,
                                    onClick: this.onAccordionCloseClick,
                                    disabled: this.state.editZendeskConnectionCancelButtonDisabled,
                                },
                            ],
                        },
                    },
                },
            ];

            return <WidgetAccordion accordions={accordion} />;
        } else {
            return null;
        }
    };

    renderTable = () => {
        const errorMessageOverride = 'There are no Zendesk Account associated with this account.';

        const header = {
            columns: [
                {
                    title: 'Account Name',
                },
                {
                    title: 'User',
                },
                {
                    title: 'Subdomain',
                },
            ],
        };

        const rows = this.state.zendeskConnections.map(zendeskConnection => {
            const zendeskConnectionInUpdatedZendeskConnection = this.state.updatedZendeskConnection.filter(
                filteredZendeskConnection => filteredZendeskConnection.id === zendeskConnection.id
            );

            if (zendeskConnectionInUpdatedZendeskConnection.length === 1) {
                zendeskConnection = zendeskConnectionInUpdatedZendeskConnection[0];
            }

            const rowProperty = {
                selected:
                    this.state.selectedZendeskConnection.filter(
                        filteredZendeskConnection => filteredZendeskConnection.id === zendeskConnection.id
                    ).length === 1,
                deleted:
                    this.state.removedZendeskConnection.filter(
                        filteredZendeskConnection => filteredZendeskConnection.id === zendeskConnection.id
                    ).length === 1,
                edited: zendeskConnectionInUpdatedZendeskConnection.length === 1,
                disabled: this.state.disableZendeskConnectionSelect === true,
            };

            return {
                onClick: this.onZendeskConnectionSelect,
                keyValue: `domains__${zendeskConnection.id}`,
                dataValue: zendeskConnection.id,
                rowProperty,
                columns: [
                    {
                        copy: zendeskConnection.accountName,
                    },
                    {
                        copy: zendeskConnection.cubedUserEmail,
                    },
                    {
                        copy: zendeskConnection.subdomain,
                    },
                ],
            };
        });
        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div>
                    <this.renderModalNavigation />
                    <h2>Zendesk</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div>
                    <this.renderModalNavigation />
                    <h2>Zendesk</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div>
                <this.renderModalNavigation />
                <h2>Zendesk</h2>
                <p>
                    Manage your imported Zendesk integration with the Cubed Platform, including creating and removing
                    connections.
                </p>
                <p>
                    Follow the Zendesk developers documentation to manage your API integrations with Cubed. For more
                    information see &nbsp;
                    <a href="https://developer.zendesk.com/documentation/" target="_blank" rel="noopener noreferrer">
                        Zendesk API Integrations
                    </a>
                </p>
                <p>
                    In order to make a connection with the Cubed platform you have to add your <b>subdomain</b>. An
                    Example of subdomain is <b>mycompanysupport.zendesk.com</b>.
                </p>
                <div>
                    <this.renderTable />
                    <InputButtonWrapper>
                        <InputButton value="ADD SUBDOMAIN" onClick={this.onAddZendeskClick} />
                        <InputButton
                            value="EDIT"
                            disabled={this.state.editZendeskConnectionButtonDisabled}
                            onClick={this.onEditZendeskConnectionClick}
                        />
                        <InputButton
                            buttonTheme={this.state.removeButtonState && ButtonThemes.Red}
                            value={this.state.removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                            disabled={this.state.removeButtonDisabled}
                            onClick={this.onRemoveClick}
                        />
                    </InputButtonWrapper>
                </div>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.account,
        request: state.request,
        meta: state.meta,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        removeModal: () => {
            dispatch(removeModal());
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalZendeskApi);
