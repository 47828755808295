import React from 'react';
import styled from 'styled-components';

// Component
import Toggle from '../../../inputs/toggle';
import SimplePill from '../../../simple-pill';

// Types
import { SimpleTableRow, SimpleTableRowColumn } from '../../types';

// Styles
const StyledSimpleTableBodyDataToggle = styled.td`
    padding: 6px 0;
`;

const StyledSimpleTableBodyDataText = styled.p`
    padding: 5px;
    margin: 0;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    border-radius: 8px;
    transition: width 0.4s ease;
`;

// Types
type SimpleTableBodyCellProps = {
    column: SimpleTableRowColumn;
    index: number;
    row: SimpleTableRow;
};

const SimpleTableBodyCell = ({ column, index, row }: SimpleTableBodyCellProps) => {
    if (column.type && column.type === 'toggle') {
        if (row.rowProperty?.locked === true) {
            // This will allow us to not add toggle if row is locked
            // A feature used in import integration account popup
            return <StyledSimpleTableBodyDataToggle />;
        }
        return (
            <StyledSimpleTableBodyDataToggle key={`${row.key}__${index}`}>
                <Toggle
                    checked={column.toggleChecked!}
                    dataName={column.toggleDataName}
                    onClick={column.toggleOnClick!}
                    disabled={column.toggleDisabled}
                />
            </StyledSimpleTableBodyDataToggle>
        );
    } else if (column.type && column.type === 'pills') {
        return (
            <td key={`${row.key}__${index}`}>
                {column.pills &&
                    column.pills.map(pill => {
                        return (
                            <SimplePill
                                key={pill.key}
                                isStart={pill.isStart}
                                isEnd={pill.isEnd}
                                isMore={pill.isMore}
                                styleOverride={pill.styleOverride}
                                copy={pill.copy}
                                isActive={pill.isActive}
                            />
                        );
                    })}
            </td>
        );
    }

    return (
        <td key={`${row.key}__${index}`} title={column.copy}>
            <StyledSimpleTableBodyDataText>{column.copy}</StyledSimpleTableBodyDataText>
        </td>
    );
};

export default SimpleTableBodyCell;
