import React from 'react';
import styled, { useTheme } from 'styled-components';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Config
import {
    nestedDonutBarChartConfig,
    nestedDonutChartConfig,
} from '../../configurations/widget-nested-donut-chart-config';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Types
import { NestedDonutChartComparisonData } from '../../types';

// Components
import WidgetBase from '../base/widget-base';
import SectionDashboardNestedDonutTable from '../../components/tables/section-dashboard-nested-donut-table';
import Message from '../../../components/message';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
`;

const StyledChartContainer = styled.div<{ width: number; height: number }>`
    width: ${props => (props.height > 1 && props.width > 3 ? '33%' : '50%')};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledTableContainer = styled.div`
    max-height: 100%;
    overflow: auto;
    align-self: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const StyledTitle = styled.span`
    font-size: 0.75rem;
    font-weight: ${props => props.theme.sectionDashboard.widget.nestedDonutTableComparison.titleFontWeight};
    text-transform: uppercase;
    color: ${props => props.theme.sectionDashboard.widget.nestedDonutTableComparison.titleColor};
    margin-bottom: 3px;
`;

const StyledSmallContainer = styled.div<{ direction: 'column' | 'row' }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => props.direction};
`;

const StyledSmallChartContainer = styled.div<{ direction: 'column' | 'row' }>`
    width: ${props => (props.direction === 'row' ? '50%' : '100%')};
    height: ${props => props.direction === 'column' && '50%'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const StyleSmallTitle = styled.span`
    font-size: 0.7rem;
    font-weight: ${props => props.theme.sectionDashboard.widget.nestedDonutTableComparison.titleFontWeight};
    text-transform: uppercase;
    color: ${props => props.theme.sectionDashboard.widget.nestedDonutTableComparison.titleColor};
    margin-bottom: 3px;
    display: block;
`;

export type WidgetNestedDonutChartTableComparisonProps = {
    title: string;
    data: NestedDonutChartComparisonData;
    colors?: string[];
    href?: string;
};

const WidgetNestedDonutChartTableComparison = ({
    title,
    data,
    colors,
    href,
}: WidgetNestedDonutChartTableComparisonProps) => {
    const theme = useTheme();
    const { width, height } = useSectionDashboard();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const { series, comparisonSeries, columns, subColumns, row, comparisonRow, varianceRow } = data;

    const colorOverride = {
        colors: colors,
    };

    const radialConfig = nestedDonutChartConfig(theme, width, height);
    const chartConfig = {
        ...radialConfig,
        ...(colors && colorOverride),
        xAxis: {
            ...radialConfig.xAxis,
            categories: data.categories,
        },
    };

    const barConfig = nestedDonutBarChartConfig(theme);
    const barChartConfig = {
        ...barConfig,
        ...(colors && colorOverride),
        xAxis: {
            ...barConfig.xAxis,
            categories: data.categories,
        },
    };

    if (height === 1) {
        return (
            <WidgetBase title={title} href={href}>
                <StyledSmallContainer direction="row">
                    <StyledSmallChartContainer direction="row">
                        {series ? (
                            <>
                                <StyleSmallTitle>This Period:</StyleSmallTitle>
                                <CommonHighcharts
                                    highcharts={Highcharts}
                                    options={{ ...barChartConfig, series: series }}
                                    containerProps={{ style: { height: '100%', width: '100%' } }}
                                />
                            </>
                        ) : (
                            <Message
                                copy="Try adjusting your filters"
                                shortTitle="No data"
                                type="info"
                                size="xsmall"
                                display="vertical"
                            />
                        )}
                    </StyledSmallChartContainer>
                    <StyledSmallChartContainer direction="row">
                        {comparisonSeries ? (
                            <>
                                <StyleSmallTitle>Comparison Period:</StyleSmallTitle>
                                <CommonHighcharts
                                    highcharts={Highcharts}
                                    options={{ ...barChartConfig, series: comparisonSeries }}
                                    containerProps={{ style: { height: '100%', width: '100%' } }}
                                />{' '}
                            </>
                        ) : (
                            <Message
                                copy="Try adjusting your filters"
                                shortTitle="No data"
                                type="info"
                                size="xsmall"
                                display="vertical"
                            />
                        )}
                    </StyledSmallChartContainer>
                </StyledSmallContainer>
            </WidgetBase>
        );
    }

    if (width === 1) {
        return (
            <WidgetBase title={title} href={href}>
                <StyledSmallContainer direction="column">
                    <StyledSmallChartContainer direction="column">
                        {series ? (
                            <>
                                <StyleSmallTitle>This Period:</StyleSmallTitle>
                                <CommonHighcharts
                                    highcharts={Highcharts}
                                    options={{ ...barChartConfig, series: series }}
                                    containerProps={{ style: { height: '100%', width: '100%' } }}
                                />
                            </>
                        ) : (
                            <Message
                                copy="Try adjusting your filters"
                                shortTitle="No data"
                                type="info"
                                size="xsmall"
                                display="vertical"
                            />
                        )}
                    </StyledSmallChartContainer>
                    <StyledSmallChartContainer direction="column">
                        {comparisonSeries ? (
                            <>
                                <StyleSmallTitle>Comparison Period:</StyleSmallTitle>
                                <CommonHighcharts
                                    highcharts={Highcharts}
                                    options={{ ...barChartConfig, series: comparisonSeries }}
                                    containerProps={{ style: { height: '100%', width: '100%' } }}
                                />
                            </>
                        ) : (
                            <Message
                                copy="Try adjusting your filters"
                                shortTitle="No data"
                                type="info"
                                size="xsmall"
                                display="vertical"
                            />
                        )}
                    </StyledSmallChartContainer>
                </StyledSmallContainer>
            </WidgetBase>
        );
    }

    return (
        <WidgetBase title={title} href={href}>
            <StyledContainer>
                <StyledChartContainer width={width} height={height}>
                    {series ? (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...chartConfig, series: series }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    ) : (
                        <Message
                            copy="Try adjusting your filters"
                            shortTitle="No data"
                            type="info"
                            size="xsmall"
                            display="vertical"
                        />
                    )}
                </StyledChartContainer>

                {width > 3 ? (
                    <StyledTableContainer>
                        {row ? (
                            <div>
                                <StyledTitle>This Period:</StyledTitle>
                                <SectionDashboardNestedDonutTable
                                    columns={columns}
                                    subColumns={subColumns}
                                    rows={[row]}
                                />
                            </div>
                        ) : null}
                        {comparisonRow ? (
                            <div>
                                <StyledTitle>Comparison Period:</StyledTitle>
                                <SectionDashboardNestedDonutTable
                                    columns={columns}
                                    subColumns={subColumns}
                                    rows={[comparisonRow]}
                                />
                            </div>
                        ) : null}
                        {varianceRow && height > 2 ? (
                            <div>
                                <StyledTitle>Variance %:</StyledTitle>
                                <SectionDashboardNestedDonutTable
                                    columns={columns}
                                    subColumns={subColumns}
                                    rows={[varianceRow]}
                                    isVariance={true}
                                />
                            </div>
                        ) : null}
                    </StyledTableContainer>
                ) : null}
                <StyledChartContainer width={width} height={height}>
                    {comparisonSeries ? (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...chartConfig, series: comparisonSeries }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    ) : (
                        <Message
                            copy="Try adjusting your filters"
                            shortTitle="No data"
                            type="info"
                            size="xsmall"
                            display="vertical"
                        />
                    )}
                </StyledChartContainer>
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetNestedDonutChartTableComparison;
