import { useTheme } from 'styled-components';
import { WidgetColour, WidgetTheme } from '../../types';

const useWidgetTheme = (colour: WidgetColour): WidgetTheme => {
    const theme = useTheme();
    const colours = theme.sectionDashboard.colour;
    switch (colour) {
        case WidgetColour.Default:
            return colours.default;
        case WidgetColour.Red:
            return colours.red;
        case WidgetColour.Orange:
            return colours.orange;
        case WidgetColour.Amber:
            return colours.amber;
        case WidgetColour.Yellow:
            return colours.yellow;
        case WidgetColour.Lime:
            return colours.lime;
        case WidgetColour.Green:
            return colours.green;
        case WidgetColour.Emerald:
            return colours.emerald;
        case WidgetColour.Teal:
            return colours.teal;
        case WidgetColour.Cyan:
            return colours.cyan;
        case WidgetColour.Sky:
            return colours.sky;
        case WidgetColour.Blue:
            return colours.blue;
        case WidgetColour.Indigo:
            return colours.indigo;
        case WidgetColour.Violet:
            return colours.violet;
        case WidgetColour.Purple:
            return colours.purple;
        case WidgetColour.Fuchsia:
            return colours.fuchsia;
        case WidgetColour.Pink:
            return colours.pink;
        case WidgetColour.Rose:
            return colours.rose;
        case WidgetColour.Slate:
            return colours.slate;
    }
};

export default useWidgetTheme;
