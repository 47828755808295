import React from 'react';
import styled from 'styled-components';

const StyledLoadingSpinner = styled.div<{ light?: boolean }>`
    margin: auto;
    width: 100px;
    height: 60px;
    padding: 10px 0;
    text-align: center;
    font-size: 10px;

    & > div {
        background-color: ${props => (props.light ? props.theme.colours.white : props.theme.colours.offBlackLighter)};
    }
`;

const StyledRectangle1 = styled.div`
    margin-right: 4px;
    height: 100%;
    width: 16px;
    display: inline-block;

    -webkit-animation: stretchDelay 1.2s infinite ease-in-out;
    animation: stretchDelay 1.2s infinite ease-in-out;

    @-webkit-keyframes stretchDelay {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
        }
        20% {
            -webkit-transform: scaleY(1);
        }
    }

    @keyframes stretchDelay {
        0%,
        40%,
        100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }
        20% {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
        }
    }
`;

const StyledRectangle2 = styled(StyledRectangle1)`
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
`;

const StyledRectangle3 = styled(StyledRectangle1)`
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
`;

const StyledRectangle4 = styled(StyledRectangle1)`
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
`;

const StyledRectangle5 = styled(StyledRectangle1)`
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
`;

export type LoadingSpinnerProps = {
    light?: boolean;
};

const LoadingSpinner = ({ light }: LoadingSpinnerProps) => {
    return (
        <StyledLoadingSpinner light={light}>
            <StyledRectangle1 />
            <StyledRectangle2 />
            <StyledRectangle3 />
            <StyledRectangle4 />
            <StyledRectangle5 />
        </StyledLoadingSpinner>
    );
};

export default LoadingSpinner;
