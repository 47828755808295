import React from 'react';
import styled from 'styled-components';

// Icons
import GripDotsVertical from '../../../../icons/grip-dots-vertical';

// Context
import { useConfigDragAndDropTableContext } from '../../context/config-drag-and-drop-table-context';

const StyledIconContainer = styled.td`
    width: 30px;
    padding: 0 20px;
`;

const StyledIcon = styled.div<{ isDragging: boolean }>`
    width: 8px;

    & svg path {
        fill: ${props => (props.isDragging ? props.theme.colours.white : props.theme.colours.midGrey)};
    }
`;

type CellDragAndDropProps = {
    isDragging: boolean;
};

const CellDragAndDrop = ({ isDragging }: CellDragAndDropProps) => {
    const { status } = useConfigDragAndDropTableContext();

    if (status === 'success') {
        return (
            <StyledIconContainer>
                <StyledIcon isDragging={isDragging}>
                    <GripDotsVertical />
                </StyledIcon>
            </StyledIconContainer>
        );
    }
    return null;
};

export default CellDragAndDrop;
