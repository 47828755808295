import React, { useEffect, useState } from 'react';
import Button from '../../components/button';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';
import { intToColour } from '../../helpers/colours';

import VisitorInfo from './visitor-info';
import Timeline from './timeline';
import VisitInfo from './visit-info';
import { generateUrl } from '../../helpers/request-builder';
import axios from 'axios';

const VisitorDetailsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

const VisitorContainer = styled.div`
    flex: 0 1 300px;
    display: inline-block;
    height: 100%;
    width: 300px;
`;

const VisitsContainer = styled.div`
    position: relative;
    flex: 1 1 auto;
    height: auto;
`;

const VisitorDetails = ({ visitor, onBackClick }) => {
    const [visits, setVisits] = useState([]);

    useEffect(() => {
        axios({
            method: 'GET',
            url: generateUrl('config', 'dash-referer', [{ key: 'name', value: 'Display' }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            setVisits(
                visitor.visits.map(visit => {
                    return {
                        ...visit,
                        colour: visit.is_impression ? response.data.objects[0].colour : visit.colour,
                    };
                })
            );
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const renderVisits = () => {
        return visits.map(visit => {
            return {
                component: <VisitInfo visit={visit} isImpression={visit.is_impression} />,
                icon: visit.is_impression ? 'impression' : visit.sales_count > 0 ? 'sale' : 'visit',
                colour: tinycolor(intToColour(visit.colour)),
                start: visit.first_visit,
                end: visit.last_visit,
            };
        });
    };

    return (
        <VisitorDetailsContainer>
            <VisitorContainer>
                <div>
                    <Button level="danger" fullWidth={true} onClick={onBackClick}>
                        Go Back
                    </Button>
                    <VisitorInfo visitor={visitor} />
                </div>
            </VisitorContainer>
            <VisitsContainer>
                <Timeline items={renderVisits()} />
            </VisitsContainer>
        </VisitorDetailsContainer>
    );
};

export default VisitorDetails;
