import React from 'react';
import Tooltip from '../tooltip';
import styled from 'styled-components';

const StyledHeader = styled.div`
    display: flex;
`;

const StyledLabel = styled.label`
    margin-right: 0.7rem;
`;

export type FormLabelProps = {
    toolTipCopy?: string;
    label: string;
};

const FormLabel = ({ toolTipCopy, label }: FormLabelProps) => {
    return (
        <StyledHeader>
            <StyledLabel>{label}</StyledLabel>
            {toolTipCopy && <Tooltip copy={toolTipCopy} />}
        </StyledHeader>
    );
};

export default FormLabel;
