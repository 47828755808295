import React from 'react';
import styled, { css } from 'styled-components';

// Styles
const StyledSimpleTableRow = css`
    border-bottom: solid 1px ${props => props.theme.colours.lightGrey};
    background-color: ${props => props.theme.colours.whiteBg};
    cursor: pointer;
    display: table;
    width: 100%;
    table-layout: fixed;

    :hover {
        transition: ${props => props.theme.transition};
        background-color: ${props => props.theme.colours.lightGrey};
    }
`;

const StyledBodyPill = css`
    &:nth-child(2) {
        width: 130px;
    }
    &:nth-child(3) {
        width: 130px;
    }
    &:nth-last-child(1) {
        width: 130px;
    }
`;

const StyledHeader = css`
    border-bottom: solid 1px ${props => props.theme.colours.lightGrey};
    display: table;
    width: 100%;
    table-layout: fixed;
    cursor: initial;

    &not:first-child {
        border-left: solid 1px ${props => props.theme.colours.lightGrey};
    }
`;

const StyledSimpleTableTotalRow = styled.tr<{ hasPills: boolean }>`
    ${StyledSimpleTableRow}

    & th {
        ${props => props.hasPills && StyledBodyPill}
    }
`;

const StyledSimpleTableTotalRowColumHeader = styled.th`
    ${StyledHeader}
`;

const StyledSimpleTableTotalsColumnHeaderLabel = styled.h3`
    margin: 0 2rem 0 0;
    text-align: right;
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 5px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
`;

const StyledSimpleTableTotalsColumnLabel = styled.h3`
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 5px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    margin: 0;
`;

// Types
type SimpleTableTotalsCellProps = {
    hasPills?: boolean;
    totalSales: number | string;
    totalRevenue: number | string;
};

const SimpleTableTotalsCell = ({ hasPills, totalSales, totalRevenue }: SimpleTableTotalsCellProps) => {
    return (
        <StyledSimpleTableTotalRow hasPills={hasPills!} data-testid="simple-table-table-row">
            <StyledSimpleTableTotalRowColumHeader>
                <StyledSimpleTableTotalsColumnHeaderLabel>Totals:</StyledSimpleTableTotalsColumnHeaderLabel>
            </StyledSimpleTableTotalRowColumHeader>

            <th>
                <StyledSimpleTableTotalsColumnLabel>{totalSales}</StyledSimpleTableTotalsColumnLabel>
            </th>

            <th>
                <StyledSimpleTableTotalsColumnLabel>{totalRevenue}</StyledSimpleTableTotalsColumnLabel>
            </th>
        </StyledSimpleTableTotalRow>
    );
};

export default SimpleTableTotalsCell;
