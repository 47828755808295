import { CubedConfigResource } from '../../types';

export const DASHBOARD_BUILDER_CLIENT_USERS: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-client-users',
};

export const DASHBOARD_BUILDER_WIDGET_TYPE: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-widget-type',
};

export const DASHBOARD_BUILDER_ORDER_BY_DIRECTION: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-order-by-direction',
};

export const DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-widget-request-config',
};

export const DASHBOARD_BUILDER_WIDGET: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-widget',
};

export const DASHBOARD_BUILDER_DASHBOARD: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-dashboard',
};

export const DASHBOARD_BUILDER_SECTION: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-section',
};

export const DASHBOARD_BUILDER_SECTION_AREAS: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-section-areas',
};

export const DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-section-areas-comparison',
};

export const DASHBOARD_BUILDER_DASHBOARD_HOMEPAGE: CubedConfigResource = {
    category: 'dashboard-builder',
    id: 'dashboard-builder-dashboard-homepage',
};
