import { CubedField } from '../../../types';
import { unknownErrorData } from '../../helpers/errors';
import joinDimensions from '../../helpers/join-dimensions';
import { ResourceComparisonData } from '../../types';

type UseResourceComparisonJoinDimensionsArgs = {
    resourceData: ResourceComparisonData;
    displayField: CubedField;
    joiningField: CubedField;
};

const useResourceComparisonJoinDimensions = ({
    resourceData,
    displayField,
    joiningField,
}: UseResourceComparisonJoinDimensionsArgs) => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const updatedResources = resourceData.resources.map(resource => {
            if (resource.status === 'success') {
                const updatedResource = resource.objects.map(object => {
                    return joinDimensions({ resourceObject: object, displayField, joiningField });
                });

                return {
                    ...resource,
                    objects: updatedResource,
                };
            }
            return resource;
        });

        return {
            ...resourceData,
            resources: updatedResources,
        };
    }

    return unknownErrorData();
};

export default useResourceComparisonJoinDimensions;
