import React from 'react';
import styled, { useTheme } from 'styled-components';

// Configuration
import { histogramChartConfig } from '../../configurations/widget-histogram-chart-config';

// Highcharts
import Highcharts from 'highcharts';
import bellcurve from 'highcharts/modules/histogram-bellcurve';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Context
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Components
import WidgetBase from '../base/widget-base';
import WidgetBarGraphBigNumber from '../big-numbers/widget-bar-graph-big-number';

// Types
import { HistogramChartData } from '../../types';
import Message, { IconDirection, IconSize } from '../../../components/message';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;

const StyledMessageContainer = styled.div`
    width: 100%;
    align-self: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export type WidgetHistogramProps = {
    title: string;
    data: HistogramChartData;
    href?: string;
};

bellcurve(Highcharts);

const WidgetHistogram = ({ title, data, href }: WidgetHistogramProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    const histogramConfig = histogramChartConfig(theme);

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const dataSeries = data.series.map(data => data.y);
    const categories = data.series.map(data => data.name);
    const colours = data.series.map(data => data.colour);
    const metric = data.metric;

    let size: IconSize = 'small';
    let display: IconDirection = 'horizontal';

    if (height < 2 || width < 3) {
        size = 'xsmall';
        display = 'vertical';
    } else if (height < 4 || width < 4) {
        size = 'small';
    }

    if (width > 1) {
        display = 'horizontal';
    }

    if (width === 1 || height === 1) {
        const numberOfBoxes = Math.max(width, height);
        const dataToDisplay = dataSeries.slice(0, numberOfBoxes);
        const categoriesToDisplay = categories.slice(0, numberOfBoxes);
        const topValue = dataSeries[0];
        const direction = width > height ? 'horizontal' : 'vertical';

        return (
            <WidgetBase title={title} href={href}>
                {data.series.length > 0 ? (
                    <WidgetBarGraphBigNumber
                        data={dataToDisplay}
                        categories={categoriesToDisplay}
                        topValue={topValue}
                        direction={direction}
                        colours={colours as string[]}
                        width={width}
                    />
                ) : (
                    <StyledMessageContainer>
                        <Message
                            shortTitle={'No Data'}
                            title={'No Data Available'}
                            copy={'Try adjusting your filters.'}
                            type={'info'}
                            size={size}
                            display={display}
                        />
                    </StyledMessageContainer>
                )}
            </WidgetBase>
        );
    }

    const config = {
        ...histogramConfig,
        yAxis: histogramConfig.yAxis.map(axis => {
            return { ...axis, title: { text: !axis.opposite ? metric : null } };
        }),
        series: histogramConfig.series.map(series => {
            if (series.type === 'histogram') return { ...series, color: data.histogramColour };
            else if (series.type === 'scatter')
                return {
                    ...series,
                    data: data.series,
                    name: metric,
                    marker: {
                        fillColor: data.scatterColour,
                        lineColor: data.scatterColour,
                        lineWidth: 1,
                    },
                };
            return series;
        }),
    };

    return (
        <WidgetBase title={title} href={href}>
            <StyledContainer>
                {data.series.length > 0 ? (
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...config }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                ) : (
                    <StyledMessageContainer>
                        <Message
                            shortTitle={'No Data'}
                            title={'No Data Available'}
                            copy={'Try adjusting your filters.'}
                            type={'info'}
                            size={size}
                            display={display}
                        />
                    </StyledMessageContainer>
                )}
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetHistogram;
