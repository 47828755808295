import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.span`
    font-size: 0.8rem;
    font-weight: 600;
    color: ${props => props.theme.dashboardBuilder.library.libraryList.listItemCellTitleColor};
`;

const LibraryListCellTitle = ({ children }: { children: React.ReactNode }) => {
    return <StyledLabel>{children}:</StyledLabel>;
};

export default LibraryListCellTitle;
