import { PieSeries } from '../types';

export const buildChartSeries = (series: PieSeries[], display: string) => {
    const chartSeries = series.map(series => {
        return {
            name: series.name,
            data: series.data.map(data => {
                return {
                    name: data.name,
                    value: data.value,
                    color: data.color,
                    y: (data.value / series.total) * 100,
                };
            }),
            innerSize: display === 'donut' || display === 'semidonut' ? '50%' : 0,
        };
    });

    return chartSeries;
};

export const pieChartSeriesValidator = (series: PieSeries[]) => {
    if (series.length === 0) {
        return series;
    }

    const newSeries = [];
    for (let data of series) {
        if (data.data.length > 0) {
            newSeries.push(data);
        }
    }

    return newSeries;
};
