export const barChartConfig = {
    options: {
        chart: {
            type: 'column',
            inverted: false,
            style: {
                fontFamily: 'Work Sans',
            },
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'category',
            title: '',
            labels: {
                enabled: true,
                style: {
                    color: '#4d4d4d',
                    fontSize: '0.7em',
                },
            },
            lineColor: '#E3E3FF',
            tickColor: '#E3E3FF',
        },
        yAxis: {
            title: '',
            labels: {
                enabled: true,
                style: {
                    color: '#4d4d4d',
                    fontSize: '0.7em',
                },
            },
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                animation: false,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.2f}',
                },
            },
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{points.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}<br/>',
        },

        series: [
            {
                data: [],
            },
        ],
        reflow: true,
    },
};
