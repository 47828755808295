import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    color: ${props =>
        props.disabled
            ? props.theme.sectionDashboard.widget.dialog.button.disabledTextColor
            : props.theme.sectionDashboard.widget.dialog.button.textColor};
    font-family: inherit;
    font-size: 16px;
    border: 0;
    background-color: transparent;
    padding: 10px 20px;
    border-radius: 3px;
    font-weight: ${props => props.theme.sectionDashboard.widget.dialog.button.fontWeight};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export type ButtonProps = {
    value: string;
    disabled: boolean;
    onClick: () => void;
};

const Button = ({ value, disabled, onClick }: ButtonProps) => {
    return (
        <StyledButton disabled={disabled} onClick={onClick}>
            {value}
        </StyledButton>
    );
};

export default Button;
