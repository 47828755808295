// React Dependencies
import React, { useEffect, useState } from 'react';

// Libraries
import axios from 'axios';
import styled from 'styled-components';

// Helpers & Reference Objects
import { generateUrl } from '../../helpers/request-builder';
import { GroupRule } from '../../helpers/segmenter/group-rule';
import { OPERATORS } from '../../helpers/segmenter/reference-objects';

// Components
import CreateSegmentGroup from './create-segment-group';
import ComponentLoadingSpinner from '../../components/loading-spinner';
import ComponentWarningMessage from '../../components/warning-message';

export const StyledPatternContainer = styled.div`
    background-color: #ffffff;
    color: ${props => props.isNotUniqueRule && props.theme.colours.red};
    border: 1px solid #d3d3d3;
    border-bottom: 0;
    padding: 20px;
    margin-top: 20px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
`;

export const StyledSegmentContainer = styled.div`
    border: 1px solid #d3d3d3;
`;

const SegmenterRuleBuilder = ({ segmentName, onPatternChange, rules, isFrom, data, isNotUniqueRule }) => {
    const [dataLoading, setDataLoading] = useState(true);
    const [displayPattern, setDisplayPattern] = useState('No pattern');
    const [rule, setRule] = useState(rules || new GroupRule(OPERATORS[0]));
    const [conditions, setConditions] = useState();
    const [pageError, setPageError] = useState(false);
    const [displayNotUniqueRule, setDisplayNotUniqueRule] = useState('');

    // api call - fetch all conditions
    useEffect(() => {
        if (data?.segmenterConditionData?.length > 0) {
            setConditions(data.segmenterConditionData);
            setDataLoading(false);
        } else {
            fetchConditions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchConditions = () => {
        axios({
            method: 'GET',
            url: generateUrl('segmenter', 'segmenter-condition', [{ key: 'active', value: '1' }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const conditions = response.data.objects.map(condition => {
                    return {
                        id: condition.id,
                        functionName: condition.function.display_name,
                        relatedTable: condition.function.table_name,
                        relatedTableKey: condition.key,
                        operator: condition.function_operator.name,
                        inputValue: condition.value,
                        label: `${condition.display_name}`,
                        value: condition.id,
                        active: condition.active,
                    };
                });

                setConditions(conditions);
                setDataLoading(false);
            })

            .catch(error => {
                setPageError(true);
            });
    };

    useEffect(() => {
        let patterns = rule.serialize();
        onPatternChange(patterns);
        setDisplayPattern(
            patterns.length > 0 ? `${segmentName || 'Rule'}: ${patterns}` : 'Start building your rule below...'
        );
        setDisplayNotUniqueRule(`Rule ${patterns} is not unique.`);
    }, [rule, segmentName]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddConditions = (groupItem, condition) => {
        const iterateAddConditionsItem = obj => {
            if (obj.id === groupItem.id) {
                obj.addCondition(condition);
            } else {
                obj.groupItems.forEach(groupItem => iterateAddConditionsItem(groupItem));
            }
        };
        const groupItemCopy = rule.clone();
        iterateAddConditionsItem(groupItemCopy);
        setRule(groupItemCopy);
    };

    const handleRemoveCondition = (groupItem, condition) => {
        const iterateRemoveConditionItem = obj => {
            if (obj.id === groupItem.id) {
                obj.removeCondition(condition);
            } else {
                obj.groupItems.forEach(groupItem => iterateRemoveConditionItem(groupItem));
            }
        };
        const groupItemCopy = rule.clone();
        iterateRemoveConditionItem(groupItemCopy);
        setRule(groupItemCopy);
    };

    const handleAddGroup = groupItem => {
        const iterateAddGroupItem = obj => {
            if (obj.id === groupItem.id) {
                obj.groupItems.push(groupItem.addGroup(OPERATORS[0]));
            } else {
                obj.groupItems.forEach(groupItem => iterateAddGroupItem(groupItem));
            }
        };
        const groupItemCopy = rule.clone();
        iterateAddGroupItem(groupItemCopy);
        setRule(groupItemCopy);
    };

    const recursiveRemoveGroupItem = groupItem => {
        const iterateGroupItem = obj => {
            obj.groupItems = obj.groupItems.filter(item => item.id !== groupItem.id);
            obj.groupItems.forEach(groupItem => iterateGroupItem(groupItem));
        };
        const tempRules = rule.clone();
        iterateGroupItem(tempRules);
        setRule(tempRules);
    };

    const handleUpdateOperator = (groupItem, operator) => {
        const iterateUpdateOperatorItem = obj => {
            if (obj.id === groupItem.id) {
                obj.updateOperator(operator);
            } else {
                obj.groupItems.forEach(groupItem => iterateUpdateOperatorItem(groupItem));
            }
        };
        const groupItemCopy = rule.clone();
        iterateUpdateOperatorItem(groupItemCopy);
        setRule(groupItemCopy);
    };

    if (pageError) {
        return (
            <>
                <ComponentWarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </>
        );
    }

    if (!dataLoading && !pageError) {
        return (
            <>
                <StyledPatternContainer isNotUniqueRule={isNotUniqueRule}>
                    {isNotUniqueRule ? displayNotUniqueRule : displayPattern}
                </StyledPatternContainer>

                <StyledSegmentContainer>
                    <CreateSegmentGroup
                        isFrom={isFrom}
                        key={rule.id}
                        rules={rule}
                        groupItem={rule}
                        operators={OPERATORS}
                        conditions={conditions}
                        nested={false}
                        onAddCondition={handleAddConditions}
                        onRemoveCondition={handleRemoveCondition}
                        onAddGroup={handleAddGroup}
                        onUpdateOperator={handleUpdateOperator}
                        onRemoveGroup={recursiveRemoveGroupItem}
                    />
                </StyledSegmentContainer>
            </>
        );
    } else {
        return (
            <>
                <ComponentLoadingSpinner />
            </>
        );
    }
};

export default SegmenterRuleBuilder;
