import * as fields from '../../configurations/fields';
import { CubedField } from '../../types';

const getField = (currentField: string): CubedField | undefined => {
    const selectedField = Object.entries(fields)
        .filter(field => {
            return field;
        })
        .filter(field => field[1].id === currentField);

    if (selectedField.length > 0) {
        return selectedField[0][1];
    }
};

export default getField;
