import { unknownErrorData } from '../../../helpers/errors';
import { mapObject } from '../../../helpers/map-object';
import { calculateVariance, formatVariance } from '../../../helpers/variance-helpers';
import { DataType, EmptyData, ResourceComparisonData, TotalData, TotalSuccess } from '../../../types';

type UseResourceTableComparisonVarianceOnlyTotalsArgs = {
    resourceData: ResourceComparisonData;
};

const useResourceTableComparisonVarianceOnlyTotals = ({
    resourceData,
}: UseResourceTableComparisonVarianceOnlyTotalsArgs) => {
    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        if (resource.status === 'success' && comparisonResource.status === 'success') {
            const data = {
                type: 'tableComparisonVarianceOnlyTotals' as DataType,
                status: 'success',
                totals: {
                    __id: 'test',
                    values: {},
                },
            } as TotalSuccess;

            const thisPeriodTotals = mapObject(resource.meta.totals);
            const comparisonPeriodTotals = mapObject(comparisonResource.meta.totals);

            if (thisPeriodTotals && comparisonPeriodTotals) {
                for (const [key, value] of Object.entries(thisPeriodTotals.values)) {
                    const variance = calculateVariance(+value.rawValue, +comparisonPeriodTotals.values[key].rawValue);
                    data.totals.values[key] = {
                        rawValue: variance || 0,
                        value: formatVariance(variance),
                    };
                }
            }

            return data as TotalData;
        }

        return {
            type: 'tableComparisonVarianceOnlyTotals' as DataType,
            status: 'empty',
        } as EmptyData;
    }

    return unknownErrorData();
};

export default useResourceTableComparisonVarianceOnlyTotals;
