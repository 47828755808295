import React from 'react';
import styled from 'styled-components';

// Context
import SectionDashboardProvider from '../../../section-dashboard/context/section-dashboard-context';

// Types
import { SectionDashboardLayout } from '../../../section-dashboard/types';

// Hooks
import { useFormContext } from 'react-hook-form';

// Components
import CreateAWidgetPreviewMessage from './create-a-widget-preview-message';
import Form from '../../../components/forms/form';

// Helpers
import getAvailableResources from '../helpers/get-available-resources';
import getWidgetPreview from '../../widgets/helpers/get-widget-preview';
import PreviewDatepicker from '../../components/preview-datepicker';

const StyledPreview = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    overflow: auto;
`;

const StyledControls = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
`;

const StyledPreviewContainer = styled.div`
    background-color: ${props => props.theme.dashboardBuilder.createAWidget.preview.backgroundColor};
    padding: 20px;
    width: 100%;
    height: 100%;
    min-height: 380px;
    border-radius: 6px;
`;

const CreateAWidgetPreview = () => {
    // Form Values
    const { watch } = useFormContext();
    const formValues = watch();

    const renderPreview = () => {
        if (formValues.visualisation) {
            const currentResource = getAvailableResources({
                dimension: formValues.groupBy,
                currentMetrics:
                    formValues.visualisation === 'sparkLine' ||
                    formValues.visualisation === 'bigNumber' ||
                    formValues.visualisation === 'lineGraph'
                        ? formValues.graphMetric
                        : formValues.visualisation === 'table'
                        ? formValues.fields
                        : formValues.orderBy,
            })[0];

            const config = {
                resource: currentResource && currentResource.id,
                group_by: formValues.groupBy,
                order_by: formValues.orderBy,
                order_by_direction: { direction: formValues.orderByDirection },
                fields: formValues.fields.join(','),
                widget: {
                    id: 1,
                    title: formValues.widgetName,
                    type: {
                        name: formValues.visualisation,
                    },
                },
                graph_metrics: formValues.graphMetric,
                widget_color: formValues.widgetColor,
                pie_chart_display: formValues.pieChartDisplay,
                aggregation: formValues.aggregation,
                formatting: formValues.formatting,
                rounding_decimals: formValues.roundingDecimals,
                interpolated: formValues.interpolated,
                show_fill_color: formValues.showFillColor,
                copy: formValues.copy,
                button_copy: formValues.buttonCopy,
                button_href: formValues.buttonHref,
                text_justify: formValues.textJustify,
                font_size: formValues.fontSize,
            };

            return getWidgetPreview({
                type: formValues.visualisation,
                config,
                showComparison: formValues.showComparison,
            });
        }
    };

    return (
        <StyledPreview>
            <StyledControls>
                <PreviewDatepicker showComparison={formValues.showComparison} />

                <div>
                    <Form.Section>
                        <Form.InputCheckbox name="showComparison" label="Show Comparison View" />
                    </Form.Section>
                </div>
            </StyledControls>
            <StyledPreviewContainer>
                {formValues.visualisation ? (
                    <SectionDashboardProvider
                        sectionIndex={0}
                        x={0}
                        y={0}
                        width={8}
                        height={4}
                        area="table"
                        layout={SectionDashboardLayout.XL}
                    >
                        {formValues.visualisation && renderPreview()}
                    </SectionDashboardProvider>
                ) : (
                    <CreateAWidgetPreviewMessage message="Please select a visualisation to start building your widget" />
                )}
            </StyledPreviewContainer>
        </StyledPreview>
    );
};

export default CreateAWidgetPreview;
