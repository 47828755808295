import React from 'react';
import { WidgetTheme } from '../../../types';

export type IconEyeSlashProps = {
    className?: string;
    colour: WidgetTheme;
    id: string;
};

const IconEyeSlash = ({ className, colour, id }: IconEyeSlashProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" className={className}>
            <defs>
                {
                    <linearGradient id={id.slice(1)} x2="0%" y2="100%">
                        <stop offset="5%" stopColor={colour.gradientStart} />;
                        <stop offset="95%" stopColor={colour.gradientEnd} />;
                    </linearGradient>
                }
            </defs>
            <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c5.2-11.8 8-24.8 8-38.5c0-53-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zm223.1 298L373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5z" />
        </svg>
    );
};

export default IconEyeSlash;
