import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import IconPerson from '../components/icons/person';
import IconPersonCog from '../components/icons/person-cog';
import IconEllipsis from '../components/icons/ellipsis';
import IconSignOut from '../components/icons/sign-out';
import IconPortrait from '../components/icons/portrait';

import List from '../components/list';
import InputSearch from '../components/inputs/input-search';
import Tooltip, { TooltipPopUpSide } from '../components/tooltip';

import { setAccount } from '../redux/actions/account';
import { addNotification } from '../redux/actions/notification';

import { fetchCurrency } from '../helpers/fetch-currency';

const LayoutTitleBar = ({ title, visible, description, className }) => {
    const dispatch = useDispatch();
    const { user, account } = useSelector(state => state);
    const history = useNavigate();

    const [allAccounts, setAllAccounts] = useState([]);
    const [visibleAccounts, setVisibleAccounts] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (allAccounts.length === 0 && user.accounts.length > 0) {
            const allAccounts = user.accounts.map(account => {
                return {
                    type: 'ListRowCopy',
                    keyValue: account.token,
                    copy: account.name,
                    onClick: onAccountClick,
                    accountChanged: false,
                };
            });
            setAllAccounts(allAccounts);
            setVisibleAccounts(allAccounts);
        }
        // eslint-disable-next-line
    }, []);

    const onInputChange = event => {
        const searchValue = event.target.value;
        const searchValueRegex = new RegExp(searchValue, 'i');
        setSearch(searchValue);
        setVisibleAccounts(allAccounts.filter(account => searchValueRegex.test(account.copy)));
    };

    const onAccountClick = async event => {
        const account = user.accounts.filter(account => account.token === event.currentTarget.getAttribute('data-key'));
        await fetchCurrency(account[0].id, dispatch);
        dispatch(setAccount(account[0]));
        dispatch(addNotification({ copy: `You have successfully changed to "${account[0].name.toUpperCase()}"` }));
        history(`/${account[0].token}`);
    };

    const renderMessage = () => {
        if (title) {
            return title;
        }
        return 'Summary Dashboard';
    };

    if (visible !== undefined && visible === false) {
        return null;
    }
    return (
        <div className={`menu__upper ${className !== undefined ? className : ''}`}>
            <div className="menu__upper__welcome">
                <h4>{renderMessage()}</h4>
                <div className="menu__upper__welcome__description">
                    {description && <Tooltip copy={description} tooltipPopUpSide={TooltipPopUpSide.Right} />}
                </div>
            </div>
            <div className="menu__upper__user">
                <p>{account.name}</p>
                <div className="menu__upper__user__accounts">
                    <div className="icon-ellipsis">
                        <IconEllipsis />
                    </div>
                    <div className="menu__upper__user__accounts-menu">
                        <InputSearch
                            value={search}
                            onChange={onInputChange}
                            placeholder="Search accounts..."
                            variant="transparent"
                        />
                        <List rows={visibleAccounts} onRowClick={onAccountClick} />
                    </div>
                </div>
                <div className="menu__upper__user__profile">
                    <div className="icon icon-person">
                        <IconPerson />
                    </div>
                    <div className="menu__upper__user__profile-menu">
                        <NavLink to="/user">
                            <div className="icon icon-person-cog">
                                <IconPortrait />
                            </div>
                            Profile
                        </NavLink>
                        {user.staff && (
                            <NavLink to="/cubed-admin">
                                <div className="icon icon-person-cog">
                                    <IconPersonCog />
                                </div>
                                Admin
                            </NavLink>
                        )}
                        <NavLink to="/sign-out">
                            <div className="icon icon-person-cog">
                                <IconSignOut />
                            </div>
                            Sign out
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LayoutTitleBar;
