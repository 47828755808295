import React from 'react';
import { WidgetConfig } from '../../types';
import getResource from '../../helpers/get-resource';
import getField from '../../helpers/get-field';
import useResourceComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-comparison';
import useResourceLineGraphComparison from '../../../section-dashboard/hooks/widget-comparison/line-graph/use-resource-line-graph-comparison';
import WidgetLineGraphComparison from '../../../section-dashboard/widgets/widget-comparison/widget-line-graph-comparison';
import CreateAWidgetPreviewMessage from '../../create-a-widget/components/create-a-widget-preview-message';
import useResourceLineGraphInterpolatedComparison from '../../../section-dashboard/hooks/widget-comparison/line-graph/use-resource-line-graph-interpolated-comparison';

const WidgetPreviewLineGraphComparison = ({ config }: { config: WidgetConfig }) => {
    // Get the request values from the widget
    const resource = getResource(config.resource);
    const graphMetricField = config.graph_metrics && getField(config.graph_metrics);
    const groupByField = getField(config.group_by);

    const resourceData = useResourceComparison({
        resource: groupByField && resource,
        request: {
            groupBy: groupByField && resource ? [groupByField, resource.dateDimension] : [],
            orderBy: resource ? [{ field: resource?.dateDimension, orderByDirection: 'asc' }] : [],
        },
    });

    const resourceDataInterpolated = useResourceLineGraphInterpolatedComparison({
        resourceData: resourceData,
        dateDimension: resource?.dateDimension,
        seriesField: groupByField,
        yAxis: graphMetricField || undefined,
    });

    const lineGraphData = useResourceLineGraphComparison({
        resourceData: config.interpolated ? resourceDataInterpolated : resourceData,
        dateDimension: resource?.dateDimension,
        seriesField: groupByField,
        yAxis: graphMetricField || undefined,
    });

    if (config.graph_metrics && config.graph_metrics.length > 0) {
        return (
            <WidgetLineGraphComparison
                title={config.widget.title}
                data={lineGraphData}
                showFillColour={config.show_fill_color}
            />
        );
    }

    return (
        <CreateAWidgetPreviewMessage message="Please select a dimension and metric to preview the line graph widget" />
    );
};

export default WidgetPreviewLineGraphComparison;
