import React from 'react';
import styled from 'styled-components';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

const StyledDialogCancel = styled(AlertDialog.Cancel)`
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 40px;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 500;
    color: ${props => props.theme.buttons.secondary.color};
`;

const DialogCancel = ({ onClick, children }: { onClick?: () => void; children: React.ReactNode }) => {
    return <StyledDialogCancel onClick={onClick}>{children}</StyledDialogCancel>;
};

export default DialogCancel;
