import React from 'react';
import styled from 'styled-components';

import ModalNavigation from '../../../components/modal-navigation';
import { removeModal } from '../../../redux/actions/modal';
import { ButtonThemes } from '../../../enums/button-themes';
import { useDispatch } from 'react-redux';

// Layouts
import LayoutDateExclusions from './layouts/layout-date-exclusions';
import LayoutReallocationChannels from './layouts/layout-reallocation-channels';
import LayoutLookbackWindow from './layouts/layout-lookback-window';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const LayoutModalManageAttributionModels = () => {
    const dispatch = useDispatch();
    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'close',
                        onClick: () => dispatch(removeModal()),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />

            <h2>Manage Attribution Models</h2>
            <p>
                Manage your attribution models, including setting date exclusions, a lookback window and which channels
                you wish to reallocate
            </p>

            <StyledContainer>
                <LayoutDateExclusions />
                <LayoutLookbackWindow />
                <LayoutReallocationChannels />
            </StyledContainer>
        </>
    );
};

export default LayoutModalManageAttributionModels;
