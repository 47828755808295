import moment, { Moment } from 'moment';
import { formatDates } from '../helpers/format-dates';
import { useAppSelector } from '../redux/hooks';
import { CubedResource } from '../types';

export const validateEndDate = (endDate: Moment) => {
    // Validate that the end date isn't today or in the future- if it is, return yesterday
    if (endDate.isAfter(moment().subtract(1, 'days'))) {
        return moment().subtract(1, 'days').endOf('day');
    }
    return endDate;
};

type DatesMoment = {
    startDate: Moment;
    endDate: Moment;
};

type DatesFormatted = {
    startDate: string;
    endDate: string;
};

type UseDatesMomentArgs = {
    isComparison?: boolean;
};

export const useDatesMoment = ({ isComparison = false }: UseDatesMomentArgs): DatesMoment => {
    // This Period Dates
    const dates = useAppSelector(state => state.date);
    const endDateValidated = validateEndDate(dates.endDate);

    // Comparison Period Dates
    const comparisonDates = useAppSelector(state => state.comparisonDate);
    const comparisonEndDateValidated = validateEndDate(comparisonDates.endDate);

    if (isComparison) {
        return {
            startDate: comparisonDates.startDate,
            endDate: comparisonEndDateValidated,
        };
    }

    return {
        startDate: dates.startDate,
        endDate: endDateValidated,
    };
};

type UseDatesFormattedArgs = {
    resource?: CubedResource;
    isComparison?: boolean;
};

export const useDatesFormatted = ({ resource, isComparison = false }: UseDatesFormattedArgs): DatesFormatted => {
    const { startDate: momentStartDate, endDate: momentEndDate } = useDatesMoment({ isComparison });
    const { startDate, endDate } = formatDates(momentStartDate, momentEndDate, resource);

    return { startDate, endDate };
};
