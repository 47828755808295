import React, { Fragment, useContext } from 'react';
import { WidgetLibraryContext } from '../context/widget-library-context';
import WidgetLibraryListItem from '../components/widget-library-list-item';
import WidgetIcon from '../../../widgets/components/widget-icon';
import LibraryList from '../../components/library-list/library-list';

const LayoutWidgetLibraryList = () => {
    const { widgetQuery, widgets, widgetTypeQuery } = useContext(WidgetLibraryContext);

    if (widgetQuery.isSuccess && widgetTypeQuery.isSuccess) {
        return (
            <LibraryList>
                {widgetTypeQuery.data.objects.map(widgetType => {
                    const typeWidgets = widgets
                        .filter(widget => widget.widget.type.id === widgetType.id)
                        .sort((a, b) => a.widget.title.localeCompare(b.widget.title));

                    if (typeWidgets.length > 0) {
                        return (
                            <Fragment key={widgetType.id}>
                                <LibraryList.CategoryTitle key={widgetType.id}>
                                    <WidgetIcon widgetType={widgetType.name} />
                                    {widgetType.display_name} Widgets
                                </LibraryList.CategoryTitle>
                                <LibraryList.List>
                                    {typeWidgets.map(widget => (
                                        <WidgetLibraryListItem key={widget.widget.id} widget={widget} />
                                    ))}
                                </LibraryList.List>
                            </Fragment>
                        );
                    }
                    return null;
                })}
            </LibraryList>
        );
    }

    return null;
};

export default LayoutWidgetLibraryList;
