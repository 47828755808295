// Helpers
import { unknownErrorData } from '../../helpers/errors';
import { graphColours } from '../../helpers/graph-colours';
import { histogramChartDataSeries } from '../../helpers/create-data-series';

// Types
import { CubedField } from '../../../types';
import { ResourceData, HistogramChartData } from '../../types';

export type UseResourceHistogramChartArgs = {
    category: CubedField;
    metric: CubedField;
    resourceData: ResourceData;
};

const useResourceHistogramChart = ({
    category,
    metric,
    resourceData,
}: UseResourceHistogramChartArgs): HistogramChartData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const series = histogramChartDataSeries(resourceData, metric, category);

        const histogramColour = graphColours[0].solidGraphColour;
        const scatterColour = graphColours[1].solidGraphColour;

        return {
            type: 'histogramChart',
            status: 'success',
            metric: metric.displayName,
            histogramColour,
            scatterColour,
            series,
        };
    }

    return unknownErrorData();
};

export default useResourceHistogramChart;
