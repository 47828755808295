import React, { useContext } from 'react';
import styled from 'styled-components';

// Context
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

// Types
import { CreateADashboardLayout, SectionConfig } from '../../types';

// Components
import CreateADashboardWidgetWrapper from './create-a-dashboard-widget-wrapper';
import IconTimes from '../../../components/icons/times';

// React Grid Layout & Style Dependencies
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
const ResponsiveGridLayout = WidthProvider(Responsive);

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const StyledGridItem = styled.div`
    width: 100%;
    height: 100%;

    & .widget-base-link {
        margin-left: 20px;
    }
`;

const StyledRemove = styled.button`
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    position: absolute;
    bottom: 9px;
    left: 6px;
    cursor: pointer;
    z-index: 5;
    background-color: ${props => props.theme.dashboardBuilder.createADashboard.preview.removeButton.backgroundColor};
    color: ${props => props.theme.dashboardBuilder.createADashboard.preview.removeButton.color};
    border-radius: 50%;
`;

const StyledIconTimes = styled(IconTimes)`
    width: 10px;
`;

const CreateADashboardSectionGrid = ({
    section,
    layouts,
    layout,
    handleLayoutChange,
}: {
    section: SectionConfig;
    layouts: CreateADashboardLayout;
    layout: Layout[];
    handleLayoutChange: (sectionId: string, newLayout: Layout[]) => void;
}) => {
    const { setBreakpoint, handleRemoveWidgetFromSection } = useContext(CreateADashboardContext);

    // Prevent the 'drag' event when clicking the remove button
    const stopPropagation = (event: React.MouseEvent | React.TouchEvent) => {
        event.stopPropagation();
    };

    return (
        <StyledContainer>
            {layout && (
                <ResponsiveGridLayout
                    style={{ minWidth: '100%' }}
                    className="layout"
                    layouts={layouts}
                    breakpoints={{ xl: 1440, lg: 1240, md: 905, sm: 600, xs: 0 }}
                    cols={{ xl: 8, lg: 6, md: 4, sm: 2, xs: 1 }}
                    rowHeight={160}
                    onDragStop={newLayout => handleLayoutChange(section.sectionId, newLayout)}
                    onResizeStop={newLayout => handleLayoutChange(section.sectionId, newLayout)}
                    onBreakpointChange={newBreakpoint => setBreakpoint(newBreakpoint)}
                    onLayoutChange={newLayout => handleLayoutChange(section.sectionId, newLayout)}
                >
                    {layout.map((currentLayout: Layout) => {
                        return (
                            <StyledGridItem key={currentLayout.i}>
                                <StyledRemove
                                    title="Remove Widget"
                                    onClick={() => handleRemoveWidgetFromSection(currentLayout.i)}
                                    onMouseDown={event => stopPropagation(event as React.MouseEvent)}
                                    onTouchStart={event => stopPropagation(event as React.TouchEvent)}
                                >
                                    <StyledIconTimes />
                                </StyledRemove>
                                <CreateADashboardWidgetWrapper layout={currentLayout} />
                            </StyledGridItem>
                        );
                    })}
                </ResponsiveGridLayout>
            )}
        </StyledContainer>
    );
};

export default CreateADashboardSectionGrid;
