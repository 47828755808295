import React from 'react';
import styled from 'styled-components';

// Component
import DataTableTotalsCell from '../cells/data-table-totals-cell';

// Type
import { DataTableTotals } from '../../types';

// Styles
const StyledTableRow = styled.tr`
    position: relative;
`;

// Types
type DataTableTotalsRowProps = {
    totals: DataTableTotals[];
    hideTotals?: boolean;
    scrollPosX: number;
};

const DataTableTotalsRow = ({ totals, hideTotals, scrollPosX }: DataTableTotalsRowProps) => {
    const renderTotalsCell = () => {
        if (!totals || totals.length <= 0 || hideTotals) {
            return null;
        }

        return totals.map(cell => {
            return (
                <DataTableTotalsCell
                    key={cell.key}
                    displayData={cell.displayData}
                    isDimension={cell.isDimension}
                    column={cell.column}
                    scrollPosX={scrollPosX}
                />
            );
        });
    };

    return <StyledTableRow>{renderTotalsCell()}</StyledTableRow>;
};

export default DataTableTotalsRow;
