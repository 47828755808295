import { generateGetRequest, generateUrl } from './request-builder';
import { setCurrency } from '../redux/actions/currency';
import { CONFIG_TIMEZONE_CURRENCY } from '../configurations/resources-config';
import { addNotification } from '../redux/actions/notification';
import { NotificationMessageType } from '../enums/notification-types';

export const fetchCurrency = async (accountId: string, dispatch: Function) => {
    const accountIdParam = { key: 'account', value: accountId };
    const currencyUrl = generateUrl(
        CONFIG_TIMEZONE_CURRENCY.category,
        CONFIG_TIMEZONE_CURRENCY.id,
        [accountIdParam],
        false
    );
    generateGetRequest(currencyUrl)
        .then(res => {
            const isoResponse = {
                iso: res.data.objects.length > 0 ? res.data.objects[0].currency.iso : 'GBP',
            };
            dispatch(setCurrency(isoResponse));
        })
        .catch(error => {
            console.error(error);
            dispatch(
                addNotification({
                    copy: 'There was an issue accessing your currency. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        });
};
