import React from 'react';
import styled from 'styled-components';

// Types
import { WidgetDirection, VennSeriesObjectData } from '../../types';
import { graphColours } from '../../helpers/graph-colours';

const StyledBoxContainer = styled.div<{ direction: WidgetDirection }>`
    display: flex;
    flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
    height: 100%;
`;

const StyledBox = styled.div<{ backgroundColor: string }>`
    flex-grow: 1;
    position: relative;
    text-align: center;
    background-color: ${props => props.backgroundColor};
`;

const StyledValueContainer = styled.div`
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
`;

const StyledCentralValueContainer = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: ${props => props.theme.sectionDashboard.widget.venn.bigNumber.textColor};
`;

const StyledTopValueContainer = styled.div`
    text-align: center;
    width: 100%;
`;

const StyledSmallText = styled.p`
    color: ${props => props.theme.sectionDashboard.widget.venn.bigNumber.textColor};
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 5px;
`;

const StyledPillContainer = styled.div<{ width: number; height: number }>`
    margin: ${props => (props.width === 1 && props.width === 1 ? `0px 0px 3px 0px` : `5px 0px`)};
    border-radius: 15px;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 2px 8px;
    color: ${props => props.theme.sectionDashboard.widget.venn.bigNumber.textColor};
    background-color: rgba(255, 255, 255, 0.4);
`;

const StyledP = styled.p`
    margin: 0;
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 5px;
    font-weight: 600;
`;

export type WidgetVennBigNumberComparisonProps = {
    data: VennSeriesObjectData[];
    comparisonData: VennSeriesObjectData[];
    width: number;
    height: number;
    direction: WidgetDirection;
};

const WidgetVennBigNumberComparison = ({
    data,
    comparisonData,
    width,
    height,
    direction,
}: WidgetVennBigNumberComparisonProps) => {
    const greenColorIndex = 1;
    const redColorIndex = 3;
    const dataList = data.length > 0 ? data[0] : {};
    const comparisonDataList = comparisonData.length > 0 ? comparisonData[0] : {};

    const widgetUI = (data: VennSeriesObjectData, colorIndex: number, isComparison: boolean) => (
        <StyledBox backgroundColor={graphColours[colorIndex].splineGradientStart} key={`${data.value}${colorIndex}`}>
            <StyledValueContainer>
                {Object.keys(data).length > 0 ? (
                    <>
                        {data.sets && (
                            <StyledTopValueContainer>
                                <StyledSmallText>
                                    {data.sets.join(' & ')}{' '}
                                    {height === 1 && width === 1 && (
                                        <>
                                            - <StyledP>{data.value} visits</StyledP>
                                        </>
                                    )}
                                </StyledSmallText>
                            </StyledTopValueContainer>
                        )}
                        {(height > 1 || width > 1) && (
                            <StyledCentralValueContainer>
                                {data.value}
                                <StyledP>visits</StyledP>
                            </StyledCentralValueContainer>
                        )}
                        <StyledPillContainer width={width} height={height}>
                            {`${width > 1 ? 'Top Crossover' : ''} ${
                                isComparison ? 'Comparison Period' : 'This Period'
                            }`}
                        </StyledPillContainer>
                    </>
                ) : (
                    <StyledCentralValueContainer>
                        <StyledP>No Data</StyledP>
                    </StyledCentralValueContainer>
                )}
            </StyledValueContainer>
        </StyledBox>
    );

    return (
        <StyledBoxContainer direction={direction}>
            {widgetUI(dataList as VennSeriesObjectData, greenColorIndex, false)}
            {widgetUI(comparisonDataList as VennSeriesObjectData, redColorIndex, true)}
        </StyledBoxContainer>
    );
};

export default WidgetVennBigNumberComparison;
