import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Images
import SortImage from '../../assets/images/icons/sort.png';

type CommonSelectOption = {
    id?: number | string;
    rawName?: number | string;
    value?: string;
    name?: string;
    displayName?: string;
    defaultOrderDir?: 'desc' | 'asc';
    isDimension?: boolean;
    sort?: 'desc' | 'asc';
    sortBy?: boolean;
};

type CommonSelectProps = {
    handleSelectChange: (event: React.FormEvent<HTMLSelectElement>) => void;
    options: CommonSelectOption[];
    defaultValue: string;
    value: string;
    dataName?: string;
    required?: boolean;
    defaultText: string;
};

const StyledSelect = styled('select')`
    appearance: none;
    background-color: ${props => props.theme.colours.inputColour};
    background-image: url(${SortImage});
    background-size: 0.9em;
    background-repeat: no-repeat;
    background-position: right 5px center;
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: solid 2px ${props => props.theme.colours.offBlackLighter};
    padding: 0 5px;
    margin-bottom: 5px;
    border-radius: 2px;

    &:focus {
        outline: none;
    }
`;

const CommonSelect = ({
    handleSelectChange,
    options,
    defaultValue,
    value,
    dataName,
    required,
    defaultText,
}: CommonSelectProps) => {
    const handleOnChange = (event: React.FormEvent<HTMLSelectElement>): void => handleSelectChange(event);

    return (
        <StyledSelect data-name={dataName} onChange={handleOnChange} data-required={required} value={value}>
            {defaultText !== undefined && <option value={defaultValue}>{defaultText}</option>}
            {options.length > 0 &&
                options.map(option => (
                    <option key={option.id || option.rawName} value={option.value || option.rawName}>
                        {option.name || option.displayName}
                    </option>
                ))}
        </StyledSelect>
    );
};

export default memo(CommonSelect, isEqual);
