import React, { useContext } from 'react';
import styled from 'styled-components';
import IconSun from '../../components/icons/sun';
import IconMoon from '../../components/icons/moon';
import { SectionDashboardTheme } from '../../filter-bar/enums/section-dashboard-theme';
import { CubedThemeContext } from '../../styled-components/theme-context/cubed-theme-context';

const StyledLabel = styled.label`
    display: flex;
    width: 60px;
    height: 28px;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 52px;
    cursor: pointer;
    position: relative;
    font-size: 18px;
    transition: 0.3s;
    border: 1px solid rgba(255, 115, 0, 0.5);
`;

const StyledBall = styled.span`
    position: absolute;
    display: block;
    width: 18px;
    height: 18px;
    top: 4px;
    left: 5px;
    border-radius: 50%;
    transition: 0.8s;
    background: rgba(255, 255, 255, 0.8);
`;

const StyledIconSun = styled(IconSun)`
    position: relative;
    width: 18px;
    height: 18px;
    color: ${props => props.theme.colours.cubedOrange};
    transition: 0.8s;
    opacity: 1;
    transform: translateX(28px);
`;

const StyledIconMoon = styled(IconMoon)`
    width: 20px;
    height: 20px;
    color: #fff;
    transition: 0.8s;
    opacity: 0;
`;

const StyledInput = styled.input`
    display: none;
    :checked {
        + label > ${StyledIconMoon} {
            transform: translateX(-28px);
            opacity: 1;
        }
        + label > ${StyledIconSun} {
            transform: translateX(0);
            opacity: 0;
        }
        + label > ${StyledBall} {
            transform: translateX(30px);
            background: rgba(255, 255, 255, 0.8);
        }
        + label {
            background: #0a0a0a;
            border: 1px solid rgba(255, 255, 255, 0.5);
        }
    }
`;

const ThemeToggle = () => {
    const { sectionDashboardTheme, toggleSectionDashboardTheme } = useContext(CubedThemeContext);

    return (
        <>
            <StyledInput
                type="checkbox"
                id="switch"
                onChange={toggleSectionDashboardTheme}
                checked={sectionDashboardTheme === SectionDashboardTheme.Dark ? true : false}
            />
            <StyledLabel htmlFor="switch">
                <StyledIconSun />
                <StyledIconMoon />
                <StyledBall />
            </StyledLabel>
        </>
    );
};

export default ThemeToggle;
