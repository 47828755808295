import React, { Component } from 'react';

//
// 	Class ListHeaderTitle
//		props.header :    String
//

class ListHeaderTitle extends Component {
    render() {
        return (
            <div className="list-widget__header-text">
                <h2>{this.props.title}</h2>
            </div>
        );
    }
}

export default ListHeaderTitle;
