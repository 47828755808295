import { configureStore } from '@reduxjs/toolkit';
import coreReducer from './reducers/core';

// Middleware
import signOutMiddleware from './middleware/sign-out';

export const store = configureStore({
    reducer: coreReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types and paths when checking that the data we are storing is serializable
                ignoredActions: ['UPDATE_DATE', 'UPDATE_COMPARISON_DATE', 'SET_POPUP', 'DEL_POPUP'],
                ignoredPaths: ['date', 'comparisonDate', 'popup'],
            },
        }).concat([signOutMiddleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
