import { CubedField } from '../types';

import DataTypes from '../filter-bar/enums/data-types';

export const VISIT_ID: CubedField = {
    id: 'visit_id',
    rawName: 'id',
    displayName: 'Visit ID',
    defaultOrderDir: 'Desc',
    isDimension: true,
    dataType: DataTypes.NUMBER,
};

export const ERROR_COUNT: CubedField = {
    id: 'error_count',
    rawName: 'count',
    displayName: 'Count',
    defaultOrderDir: 'desc',
    description: 'The number of times the error occurred. ',
    dataType: DataTypes.NUMBER,
};

export const COUNT: CubedField = {
    id: 'count',
    rawName: 'count',
    displayName: 'Count',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};

export const ERROR_NAME: CubedField = {
    id: 'error_name',
    rawName: 'error_name',
    displayName: 'Error Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const ERROR_MESSAGE: CubedField = {
    id: 'error_message',
    rawName: 'error_message',
    displayName: 'Error Message',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.NUMBER,
};

export const REFERER: CubedField = {
    id: 'referer',
    rawName: 'referer',
    lookupTerm: 'name',
    displayName: 'Channel Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SUBCHANNEL: CubedField = {
    id: 'subchannel',
    rawName: 'subchannel',
    displayName: 'Subchannel Name',
    lookupTerm: 'name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SUBCHANNEL_NAME: CubedField = {
    id: 'subchannel_name',
    rawName: 'subchannel__name',
    displayName: 'Subchannel Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const PATTERN: CubedField = {
    id: 'pattern',
    rawName: 'pattern',
    displayName: 'Pattern',
    lookupTerm: 'pattern',
    defaultOrderDir: 'desc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LABEL_VALUE: CubedField = {
    id: 'label_value',
    rawName: 'label_value',
    displayName: 'Campaign Label',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const DEVICE: CubedField = {
    id: 'device',
    rawName: 'device',
    displayName: 'Device',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const DEVICE_FAMILY: CubedField = {
    id: 'device_family',
    rawName: 'device_family',
    displayName: 'Device Family',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const OS_FAMILY: CubedField = {
    id: 'os_family',
    rawName: 'os_family',
    displayName: 'OS Family',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const BROWSER_FAMILY: CubedField = {
    id: 'browser_family',
    rawName: 'browser_family',
    displayName: 'Browser Family',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PUBLISHER: CubedField = {
    id: 'publisher',
    rawName: 'publisher_name',
    displayName: 'Publisher Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const AFFILIATE_TYPE: CubedField = {
    id: 'affiliate_type',
    rawName: 'affiliate_type',
    displayName: 'Affiliate Type',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const ECOMM_CATEGORY: CubedField = {
    id: 'ecomm_category',
    rawName: 'sku__category',
    displayName: 'Category',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const ECOMM_NAME: CubedField = {
    id: 'ecomm_name',
    rawName: 'sku__name',
    displayName: 'Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const ECOMM_SKU: CubedField = {
    id: 'ecomm_sku',
    rawName: 'sku__sku',
    displayName: 'SKU',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_CAMPAIGN_NAME: CubedField = {
    id: 'shopping_campaign_name',
    rawName: 'shopping_campaign_name',
    displayName: 'Campaign Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_AD_GROUP_NAME: CubedField = {
    id: 'shopping_ad_group_name',
    rawName: 'shopping_ad_group_name',
    displayName: 'Ad Group Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_PRODUCT_ITEM_ID: CubedField = {
    id: 'product_item_id',
    rawName: 'shopping_product__product_item_id',
    displayName: 'Product Item ID',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_PRODUCT_TYPE1: CubedField = {
    id: 'shopping_product__product_type1',
    rawName: 'shopping_product__product_type1',
    displayName: 'Product Type1',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_PRODUCT_TYPE2: CubedField = {
    id: 'shopping_product__product_type2',
    rawName: 'shopping_product__product_type2',
    displayName: 'Product Type2',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_PRODUCT_TYPE3: CubedField = {
    id: 'shopping_product__product_type3',
    rawName: 'shopping_product__product_type3',
    displayName: 'Product Type3',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_PRODUCT_TYPE4: CubedField = {
    id: 'shopping_product__product_type4',
    rawName: 'shopping_product__product_type4',
    displayName: 'Product Type4',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_PRODUCT_TYPE5: CubedField = {
    id: 'shopping_product__product_type5',
    rawName: 'shopping_product__product_type5',
    displayName: 'Product Type5',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_CATEGORY1: CubedField = {
    id: 'shopping_product__category1',
    rawName: 'shopping_product__category1',
    displayName: 'Category1',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_CATEGORY2: CubedField = {
    id: 'shopping_product__category2',
    rawName: 'shopping_product__category2',
    displayName: 'Category2',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_CATEGORY3: CubedField = {
    id: 'shopping_product__category3',
    rawName: 'shopping_product__category3',
    displayName: 'Category3',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_CATEGORY4: CubedField = {
    id: 'shopping_product__category4',
    rawName: 'shopping_product__category4',
    displayName: 'Category4',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SHOPPING_CATEGORY5: CubedField = {
    id: 'shopping_product__category5',
    rawName: 'shopping_product__category5',
    displayName: 'Category5',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SALES_DATE_DATETIME: CubedField = {
    id: 'sales_date_datetime',
    rawName: 'sales_date',
    displayName: 'Sales Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const SALES_DATE_DATE: CubedField = {
    id: 'sales_date_date',
    rawName: 'sales_date',
    displayName: 'Sales Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE,
};

export const CREATED_DATETIME: CubedField = {
    id: 'created_datetime',
    rawName: 'created',
    displayName: 'Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const CREATED_DATE: CubedField = {
    id: 'created_date',
    rawName: 'created',
    displayName: 'Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE,
};

export const VISIT_DATE_DATETIME: CubedField = {
    id: 'visit_date_datetime',
    rawName: 'visit_date',
    displayName: 'Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const VISIT_DATE_DATE: CubedField = {
    id: 'visit_date_date',
    rawName: 'visit_date',
    displayName: 'Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE,
};

export const UPDATED_DATETIME: CubedField = {
    id: 'updated_datetime',
    rawName: 'updated',
    displayName: 'Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const UPDATED_DATE: CubedField = {
    id: 'updated_date',
    rawName: 'updated',
    displayName: 'Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE,
};

export const REFERER_NAME: CubedField = {
    id: 'referer_name',
    rawName: 'referer_name',
    displayName: 'Channel Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const CHANNEL_NAME: CubedField = {
    id: 'channel_name',
    rawName: 'channel_name',
    displayName: 'Channel Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const COST_DESCRIPTION: CubedField = {
    id: 'cost_description',
    rawName: 'description',
    displayName: 'Cost Description',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const CAMPAIGN_NAME: CubedField = {
    id: 'campaign_name',
    rawName: 'campaign_name',
    displayName: 'Campaign Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const UTM_SOURCE: CubedField = {
    id: 'utm_source',
    rawName: 'utm__utm_source',
    displayName: 'UTM Source',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const UTM_MEDIUM: CubedField = {
    id: 'utm_medium',
    rawName: 'utm__utm_medium',
    displayName: 'UTM Medium',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const UTM_CAMPAIGN: CubedField = {
    id: 'utm_campaign',
    rawName: 'utm__utm_campaign',
    displayName: 'UTM Campaign',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const UTM_TERM: CubedField = {
    id: 'utm_term',
    rawName: 'utm__utm_term',
    displayName: 'UTM Term',
    defaultOrderDir: 'asc',
    isDimension: true,
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

export const UTM_CONTENT: CubedField = {
    id: 'utm_content',
    rawName: 'utm__utm_content',
    displayName: 'UTM Content',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PPC_CAMPAIGN_NAME: CubedField = {
    id: 'ppc_campaign_name',
    rawName: 'ppc_campaign_name',
    displayName: 'Campaign Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PPC_AD_GROUP_NAME: CubedField = {
    id: 'ppc_ad_group_name',
    rawName: 'ppc_ad_group_name',
    displayName: 'Ad Group Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PPC_KEYWORD: CubedField = {
    id: 'ppc_keyword',
    rawName: 'ppc_keyword',
    displayName: 'Keyword',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const AD_GROUP_NAME: CubedField = {
    id: 'ad_group_name',
    rawName: 'ad_group_name',
    displayName: 'Ad Group Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const KEYWORD: CubedField = {
    id: 'keyword',
    rawName: 'keyword',
    displayName: 'Keyword',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const MARKET_KEYWORD_KEYWORD: CubedField = {
    id: 'market_keyword_keyword',
    rawName: 'keyword_market__keyword__keyword',
    displayName: 'Keyword',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH_PATH: CubedField = {
    id: 'landing_path_path',
    rawName: 'landing_path__path',
    displayName: 'Landing Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const PPC_LANDING_PATH_HOST: CubedField = {
    id: 'ppc_landing_path_host',
    rawName: 'ppc_landing_path__host',
    displayName: 'Landing Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const PPC_LANDING_PATH_PATH: CubedField = {
    id: 'ppc_landing_path_path',
    rawName: 'ppc_landing_path__path',
    displayName: 'Landing Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const KEYWORD_LANDING_PATH_HOST: CubedField = {
    id: 'keyword_landing_path_host',
    rawName: 'keyword_landing_path__host',
    displayName: 'Landing Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const KEYWORD_LANDING_PATH_PATH: CubedField = {
    id: 'keyword_landing_path_path',
    rawName: 'keyword_landing_path__path',
    displayName: 'Landing Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const RANKED_PATH_HOST: CubedField = {
    id: 'ranked_path_host',
    rawName: 'ranking_path__host',
    displayName: 'Ranking Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const RANKED_PATH_PATH: CubedField = {
    id: 'ranked_path_path',
    rawName: 'ranking_path__path',
    displayName: 'Ranking Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SALES_PATH_PATH: CubedField = {
    id: 'sales_path_path',
    rawName: 'sales_path__path',
    displayName: 'Sales Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SALES_PATH_HOST: CubedField = {
    id: 'sales_path_host',
    rawName: 'sales_path__host',
    displayName: 'Sales Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SALES_REFERRING_PATH: CubedField = {
    id: 'sales_referring_path',
    rawName: 'referring_path__path',
    displayName: 'Sales Referring Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SALES_REFERRING_HOST: CubedField = {
    id: 'sales_referring_host',
    rawName: 'referring_path__host',
    displayName: 'Sales Referring Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const LANDING_PATH_HOST: CubedField = {
    id: 'landing_path_host',
    rawName: 'landing_path__host',
    displayName: 'Landing Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PATH_LANDING_PATH: CubedField = {
    id: 'path_landing_path',
    rawName: 'path__path',
    displayName: 'Landing Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

export const PATH_LANDING_HOST: CubedField = {
    id: 'path_landing_host',
    rawName: 'path__host',
    displayName: 'Landing Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

export const PATH_HOST: CubedField = {
    id: 'path_host',
    rawName: 'path__host',
    displayName: 'Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

export const PATH_PATH: CubedField = {
    id: 'path_path',
    rawName: 'path__path',
    displayName: 'Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

// GOOGLE SEARCH TRENDS
export const MARKET_KEYWORD_COUNTRY: CubedField = {
    id: 'market_keyword_country',
    rawName: 'keyword_market__market__country',
    displayName: 'Market',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const CORE_PARENT_CAT: CubedField = {
    id: 'core_parent_cat',
    rawName: 'keyword_market__categories__core_parent_cat',
    displayName: 'Category',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const CORE_SUB_CAT: CubedField = {
    id: 'core_sub_cat',
    rawName: 'keyword_market__categories__core_sub_cat',
    displayName: 'Sub Category',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const CORE_PRODUCT: CubedField = {
    id: 'core_product',
    rawName: 'keyword_market__categories__core_product',
    displayName: 'Product',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LOCALE: CubedField = {
    id: 'locale',
    rawName: 'keyword_market__categories__locale',
    displayName: 'Locale',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const KEYWORD_MODIFIER: CubedField = {
    id: 'keyword_modifier',
    rawName: 'keyword_market__categories__keyword_modifier',
    displayName: 'Keyword Modifier',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANGUAGE: CubedField = {
    id: 'language',
    rawName: 'keyword_market__language__language',
    displayName: 'Language',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const CORE_CHILD_CAT: CubedField = {
    id: 'core_child_cat',
    rawName: 'keyword_market__categories__core_child_cat',
    displayName: 'Child Category',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const MARKET_KEYWORD_MAPPED_PATH: CubedField = {
    id: 'market_keyword_mapped_path',
    rawName: 'keyword_market__mapped_paths__path__path',
    displayName: 'Mapped Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const MARKET_KEYWORD_RANKED_PATH: CubedField = {
    id: 'market_keyword_ranked_path',
    rawName: 'keyword_market__ranked_paths__path__path',
    displayName: 'Ranked Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const MARKET_KEYWORD_MAPPED_HOST: CubedField = {
    id: 'market_keyword_mapped_host',
    rawName: 'keyword_market__mapped_paths__path__host',
    displayName: 'Mapped Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const MARKET_KEYWORD_RANKED_HOST: CubedField = {
    id: 'market_keyword_ranked_host',
    rawName: 'keyword_market__ranked_paths__path__host',
    displayName: 'Ranking Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const CAMPAIGN_ID: CubedField = {
    id: 'campaign_id',
    rawName: 'campaign_id',
    displayName: 'Campaign ID',
    defaultOrderDir: 'asc',
    isDimension: true,
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

export const REFERRER_DOMAIN: CubedField = {
    id: 'referrer_domain',
    rawName: 'referrer_domain__domain',
    displayName: 'Referrer Domain',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const DOMAIN: CubedField = {
    id: 'domain',
    rawName: 'domain',
    displayName: 'Domain',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const REFERRER_PATH: CubedField = {
    id: 'referrer_path',
    rawName: 'referrer_domain__path',
    displayName: 'Referrer Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const HOST: CubedField = {
    id: 'host',
    rawName: 'host',
    displayName: 'Host',
    defaultOrderDir: 'asc',
    isDimension: false,
    dataType: DataTypes.NUMBER,
};
export const LANDING_HOST: CubedField = {
    id: 'landing_host',
    rawName: 'landing_host',
    displayName: 'Landing Host',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH: CubedField = {
    id: 'landing_path',
    rawName: 'landing_path',
    displayName: 'Landing Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

export const SEO_MAPPED_PAGE: CubedField = {
    id: 'seo_mapped_page',
    rawName: 'mapped_page',
    displayName: 'Mapped Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

export const TYPE: CubedField = {
    id: 'type',
    rawName: 'type',
    displayName: 'Type',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PRODUCT: CubedField = {
    id: 'product',
    rawName: 'product',
    lookupTerm: 'name',
    displayName: 'Goal',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PRODUCT_DEFAULT: CubedField = {
    id: 'product_default',
    rawName: 'product',
    lookupTerm: 'default',
    displayName: 'Goal',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.BOOL,
};

export const IMPRESSIONS: CubedField = {
    id: 'impressions',
    rawName: 'impressions',
    displayName: 'Impressions',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
    displayProportion: true,
};

export const VISITS: CubedField = {
    id: 'visits',
    rawName: 'visits',
    displayName: 'Visits',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const NEW_VISITS: CubedField = {
    id: 'new_visits',
    rawName: 'new_visits',
    displayName: 'New Visits',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const NEW_VISITS_RATE: CubedField = {
    id: 'new_visits_rate',
    rawName: 'new_visits_rate',
    displayName: 'New Visit %',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    description: 'The percentage of visits from new visitors.',
    dataType: DataTypes.FLOAT,
};
export const REPEAT_VISITS_RATE: CubedField = {
    id: 'repeat_visits_rate',
    rawName: 'repeat_visits_rate',
    displayName: 'Repeat Visit %',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    description: 'The percentage of visits from repeat visitors.',
    dataType: DataTypes.FLOAT,
};
export const REPEAT_VISITS: CubedField = {
    id: 'repeat_visits',
    rawName: 'repeat_visits',
    displayName: 'Repeat Visits',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const BOUNCES: CubedField = {
    id: 'bounces',
    rawName: 'bounces',
    displayName: 'Bounces',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const BOUNCE_RATE: CubedField = {
    id: 'bounce_rate',
    rawName: 'bounce_rate',
    displayName: 'Bounce Rate',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    description: 'The percentage of visits who navigate away from the site after viewing only one page.',
    dataType: DataTypes.FLOAT,
};
export const LANDING_PAGE_BOUNCE_RATE: CubedField = {
    id: 'landing_page_bounce_rate',
    rawName: 'landing_page_bounce_rate',
    displayName: 'Bounce Rate',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    description: 'The percentage of visits who navigate away from the site after viewing only the landing page.',
    dataType: DataTypes.NUMBER,
};
export const AVG_DELTA: CubedField = {
    id: 'avg_delta',
    rawName: 'avg_delta',
    displayName: 'Average Delta',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    description: 'The average contribution to sale, calculated as the change in overall propensity between 0 and 1.',
    dataType: DataTypes.FLOAT,
};
export const SALES: CubedField = {
    id: 'sales',
    rawName: 'sales',
    displayName: 'Sales',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};
export const FM_SALES_ASSISTS: CubedField = {
    id: 'fm_sales_assists',
    rawName: 'fm_sales_assists',
    displayName: 'Cubed Sales Assists',
    defaultOrderDir: 'desc',
    description: 'Assists to the conversion.',
    dataType: DataTypes.NUMBER,
};
export const ASSISTS: CubedField = {
    id: 'assists',
    rawName: 'assists',
    displayName: 'Assists',
    defaultOrderDir: 'desc',
    description: 'Assists to the conversion.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const ASSISTS_RATE: CubedField = {
    id: 'assists_rate',
    rawName: 'assists_rate',
    displayName: 'Assist Rate',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    description: 'The percentage of visits that assisted a sale.',
    dataType: DataTypes.FLOAT,
};
export const ASSISTS_PAGE_VIEWS_RATE: CubedField = {
    id: 'assists_page_views_rate',
    rawName: 'assists_page_views_rate',
    displayName: 'Assist Rate',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    description: 'The percentage of visits that assisted a sale.',
    dataType: DataTypes.FLOAT,
};
export const LC_ROAS: CubedField = {
    id: 'lc_roas',
    rawName: 'lc_roas',
    displayName: 'Last Click ROAS',
    defaultOrderDir: 'asc',
    description: 'Last Click Return on Ad Spend',
    dataType: DataTypes.NUMBER,
};

export const FM_ROAS: CubedField = {
    id: 'fm_roas',
    rawName: 'fm_roas',
    displayName: 'Cubed ROAS',
    defaultOrderDir: 'asc',
    description: 'Cubed Return on Ad Spend',
    dataType: DataTypes.NUMBER,
};

export const ROAS_VARIANCE_PCT: CubedField = {
    id: 'roas_variance_pct',
    rawName: 'roas_var_pct',
    displayName: 'ROAS Variance (%)',
    defaultOrderDir: 'asc',
    description: 'Percentage variance between LC and Cubed Return on Ad Spend.',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const VISIT_IN_JOURNEY: CubedField = {
    id: 'visit_in_journey',
    rawName: 'visit_in_journey',
    displayName: 'Visit in Sale Journey',
    isDimension: true,
    defaultOrderDir: 'asc',
    description: 'Visit number in a sales journey.',
    dataType: DataTypes.NUMBER,
};
export const VISIT_NUMBER: CubedField = {
    id: 'visit_number',
    rawName: 'visit_number',
    displayName: 'Visit Number',
    isDimension: true,
    defaultOrderDir: 'asc',
    description: 'Visit number.',
    dataType: DataTypes.NUMBER,
};
export const VISIT_TYPE: CubedField = {
    id: 'visit_type',
    rawName: 'visit_type',
    displayName: 'New v Repeat',
    isDimension: true,
    defaultOrderDir: 'asc',
    description: 'Visit number.',
    dataType: DataTypes.NUMBER,
};
export const LC_SALES: CubedField = {
    id: 'lc_sales',
    rawName: 'lc_sales',
    displayName: 'LC Sales',
    defaultOrderDir: 'desc',
    description: 'Sales attributed to the last-click of a users journey.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const FM_SALES: CubedField = {
    id: 'fm_sales',
    rawName: 'fm_sales',
    displayName: 'Cubed Sales',
    defaultOrderDir: 'desc',
    description: "Sales attributed using Cubed's multi-touch algorithm.",
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const LC_REVENUE: CubedField = {
    id: 'lc_revenue',
    rawName: 'lc_revenue',
    displayName: 'LC Revenue',
    defaultOrderDir: 'desc',
    description: 'Revenue attributed to the last-click of a users journey.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const FM_REVENUE: CubedField = {
    id: 'fm_revenue',
    rawName: 'fm_revenue',
    displayName: 'Cubed Revenue',
    defaultOrderDir: 'desc',
    description: "Revenue attributed using Cubed's multi-touch algorithm.",
    displayProportion: true,
    dataType: DataTypes.MONEY,
};

export const FIRST_CLICK_SALES: CubedField = {
    id: 'first_click_sales',
    rawName: 'fc_sales',
    displayName: 'First Click Sales',
    defaultOrderDir: 'desc',
    description: 'Sales attributed to the first-click of a users journey.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const FIRST_CLICK_REVENUE: CubedField = {
    id: 'first_click_revenue',
    rawName: 'fc_revenue',
    displayName: 'First Click Revenue',
    defaultOrderDir: 'desc',
    description: 'Revenue attributed to the first-click of a users journey.',
    displayProportion: true,
    dataType: DataTypes.MONEY,
};

export const PPC_CTR: CubedField = {
    id: 'ppc_ctr',
    rawName: 'ppc_ctr',
    displayName: 'CTR',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const LC_ROI: CubedField = {
    id: 'lc_roi',
    rawName: 'lc_roi_pct',
    displayName: 'Last Click ROI',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const FM_ROI: CubedField = {
    id: 'fm_roi',
    rawName: 'fm_roi_pct',
    displayName: 'Cubed ROI',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const LC_CVR: CubedField = {
    id: 'lc_cvr',
    rawName: 'lc_cvr',
    displayName: 'Last Click Conversion Rate',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const FM_CVR: CubedField = {
    id: 'fm_cvr',
    rawName: 'fm_cvr',
    displayName: 'Cubed Conversion Rate',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const NEW_VISIT_FM_CVR: CubedField = {
    id: 'new_visits_fm_cvr',
    rawName: 'new_visits_fm_cvr',
    displayName: 'New Visit Conversion Rate',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const CONVERSIONS_CVR: CubedField = {
    id: 'conversions_cvr',
    rawName: 'sales_conversion_rate',
    displayName: 'Conversion Rate',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const LC_ATV: CubedField = {
    id: 'lc_atv',
    rawName: 'lc_atv',
    displayName: 'LC AOV',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const LC_AOV: CubedField = {
    id: 'lc_aov',
    rawName: 'lc_aov',
    displayName: 'LC AOV',
    defaultOrderDir: 'desc',
    description: 'Average Order Value, using Last Click.',
    dataType: DataTypes.FLOAT,
};

export const FM_AOV: CubedField = {
    id: 'fm_aov',
    rawName: 'fm_aov',
    displayName: 'Cubed AOV',
    defaultOrderDir: 'desc',
    description: "Average Order Value, using Cubed's algorithm.",
    dataType: DataTypes.MONEY,
};

export const FM_ATV: CubedField = {
    id: 'fm_atv',
    rawName: 'fm_atv',
    displayName: 'Average Order Value',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const COST: CubedField = {
    id: 'cost',
    rawName: 'cost',
    displayName: 'Cost',
    defaultOrderDir: 'desc',
    displayProportion: true,
    reverseVariance: true,
    description: 'The cost associated with the channel or campaign which drove the traffic to site.',
    dataType: DataTypes.MONEY,
};

// CORRIDOR FIELDS
export const FEES: CubedField = {
    id: 'fees',
    rawName: 'fees',
    displayName: 'Transfer Fees',
    defaultOrderDir: 'desc',
    displayProportion: true,
    description: "Sum of fee's associated with the money transfer.",
    reverseVariance: true,
    dataType: DataTypes.MONEY,
};
export const FEES_PER_TRANSFER: CubedField = {
    id: 'fees_per_transfer',
    rawName: 'fees_per_transfer',
    displayName: 'Avg Fees Per Transfer',
    defaultOrderDir: 'desc',
    displayProportion: true,
    description: 'The fees per transfer.',
    reverseVariance: true,
    dataType: DataTypes.MONEY,
};
export const COST_PER_TRANSFER: CubedField = {
    id: 'cost_per_transfer',
    rawName: 'cost_per_transfer',
    displayName: 'Cost Per Transfer',
    defaultOrderDir: 'desc',
    description: 'Cost per tranfer.',
    dataType: DataTypes.FLOAT,
};
export const TRANSFERS: CubedField = {
    id: 'payment_transfers',
    rawName: 'fm_sales',
    displayName: 'Transfers',
    defaultOrderDir: 'desc',
    description: 'Sum of transfers.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const TRANSFERS_PER_VISIT: CubedField = {
    id: 'transfers_per_visit',
    rawName: 'fm_cvr',
    displayName: 'Avg Transfers Per Visit',
    defaultOrderDir: 'desc',
    description: 'Average number of transfer per visit.',
    dataType: DataTypes.FLOAT,
};
export const AVG_TRANSFER_AMOUNT: CubedField = {
    id: 'avg_transfer_amount',
    rawName: 'fm_aov',
    displayName: 'Avg Transfer Amount',
    defaultOrderDir: 'desc',
    description: 'Average transfer amount per transfer.',
    dataType: DataTypes.FLOAT,
};
export const TRANSFER_AMOUNT: CubedField = {
    id: 'transfer_amount',
    rawName: 'fm_revenue',
    displayName: 'Transfer Amount',
    defaultOrderDir: 'desc',
    description: 'Sum of money transferred.',
    displayProportion: true,
    dataType: DataTypes.MONEY,
};

export const LC_CPA: CubedField = {
    id: 'lc_cpa',
    rawName: 'lc_cpa',
    displayName: 'LC CPA',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const FM_CPA: CubedField = {
    id: 'fm_cpa',
    rawName: 'fm_cpa',
    displayName: 'Cubed CPA',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const URL: CubedField = {
    id: 'url',
    rawName: 'url',
    displayName: 'Url',
    defaultOrderDir: 'asc',
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

export const RANKING_URL: CubedField = {
    id: 'ranking_url',
    rawName: 'url',
    displayName: 'Ranking Url',
    defaultOrderDir: 'asc',
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};

export const PATH: CubedField = {
    id: 'path',
    rawName: 'path',
    displayName: 'Path',
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};

export const PORT: CubedField = {
    id: 'port',
    rawName: 'port',
    displayName: 'Port',
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};

export const QUERY: CubedField = {
    id: 'query',
    rawName: 'query',
    displayName: 'Query',
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};

export const SCHEME: CubedField = {
    id: 'scheme',
    rawName: 'scheme',
    displayName: 'Scheme',
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};

export const RENEWALS: CubedField = {
    id: 'renewals',
    rawName: 'renewals',
    displayName: 'Renewals',
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};

export const RENEWAL_REVENUE: CubedField = {
    id: 'renewal_revenue',
    rawName: 'renewal_revenue',
    displayName: 'Renewal Revenue',
    defaultOrderDir: 'asc',
    dataType: DataTypes.MONEY,
};

export const RENEWAL_RATE: CubedField = {
    id: 'renewal_rate',
    rawName: 'renewal_rate',
    displayName: 'Renewal Rate',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};

export const CHURN_RATE: CubedField = {
    id: 'churn_rate',
    rawName: 'churn_rate',
    displayName: 'Churn Rate',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};

export const COST_PER: CubedField = {
    id: 'cost_per',
    rawName: 'per',
    displayName: 'Cost Per',
    defaultOrderDir: 'desc',
    dataType: DataTypes.STRING,
};

export const SALES_VARIANCE: CubedField = {
    id: 'sales_variance',
    rawName: 'sales_variance',
    displayName: 'Sales Variance',
    defaultOrderDir: 'desc',
    description: 'Variance between LC and Cubed Sales.',
    dataType: DataTypes.NUMBER,
};
export const REVENUE_VARIANCE: CubedField = {
    id: 'revenue_variance',
    rawName: 'revenue_variance',
    displayName: 'Revenue Variance',
    defaultOrderDir: 'desc',
    description: 'Variance between LC and Cubed Revenue.',
    dataType: DataTypes.NUMBER,
};
export const ROI_VARIANCE: CubedField = {
    id: 'roi_variance',
    rawName: 'roi_variance',
    displayName: 'ROI Variance',
    defaultOrderDir: 'desc',
    description: 'Variance between LC and Cubed ROI.',
    dataType: DataTypes.NUMBER,
};
export const SALES_VARIANCE_PCT: CubedField = {
    id: 'sales_variance_pct',
    rawName: 'sales_variance_pct',
    displayName: 'Sales Variance (%)',
    defaultOrderDir: 'desc',
    description: 'Percentage variance between LC and Cubed Sales.',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const REVENUE_VARIANCE_PCT: CubedField = {
    id: 'revenue_variance_pct',
    rawName: 'revenue_variance_pct',
    displayName: 'Revenue Variance (%)',
    defaultOrderDir: 'desc',
    description: 'Percentage variance between LC and Cubed Revenue.',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const ROI_VAR_PCT: CubedField = {
    id: 'roi_var_pct',
    rawName: 'roi_var_pct',
    displayName: 'ROI Variance (%)',
    defaultOrderDir: 'desc',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const CPA_VAR_PCT: CubedField = {
    id: 'cpa_var_pct',
    rawName: 'cpa_var_pct',
    displayName: 'CPA Variance (%)',
    defaultOrderDir: 'desc',
    reverseHeatmap: true,
    heatmap: {
        default: [0, 'grey'],
        red: [0.0000001, Infinity],
        green: [-Infinity, 0],
    },
    dataType: DataTypes.FLOAT,
};
//MBA
export const ECOMMERCE_TYPE: CubedField = {
    id: 'ecommerce_type',
    rawName: 'ecommerce_type__name',
    displayName: 'Ecommerce type',
    defaultOrderDir: 'desc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const CURRENT_BASKET_ITEM: CubedField = {
    id: 'current_basket_item',
    rawName: 'current_basket_items',
    displayName: 'Current Basket Item',
    defaultOrderDir: 'desc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PREDICTED_BASKET_ITEM: CubedField = {
    id: 'predicted_basket_item',
    rawName: 'predicted_basket_items',
    displayName: 'Predicted Basket Item',
    defaultOrderDir: 'desc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SEO_CATEGORY: CubedField = {
    id: 'seo_category',
    rawName: 'category',
    displayName: 'Category',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SEO_SUB_CATEGORY: CubedField = {
    id: 'seo_sub_category',
    rawName: 'sub_category',
    displayName: 'Subcategory',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SEO_KEYWORD: CubedField = {
    id: 'seo_keyword',
    rawName: 'keyword',
    displayName: 'Keyword',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SEO_TOPIC: CubedField = {
    id: 'seo_topic',
    rawName: 'topic',
    displayName: 'Topic',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const SEO_KEYWORD_VISIBILITY: CubedField = {
    id: 'seo_keyword_visibility',
    rawName: 'key_visibility',
    displayName: 'Visibility',
    defaultOrderDir: 'asc',
    description:
        "Calculated at a singular keyword level, using Current Ranking​, ​CTR​'s for that ranking and the keywords ​monthly Search Volume​.",
    dataType: DataTypes.FLOAT,
};
export const SEO_KEYWORD_MAX_VISIBILITY: CubedField = {
    id: 'seo_keyword_max_visibility',
    rawName: 'max_visibility',
    displayName: 'Max Visibility',
    defaultOrderDir: 'asc',
    description:
        'Sum of visibility scores for all rankings for a given keyword. Gives a sense of scale and also allows us to show current Visibility vs Max Visibility.',
    dataType: DataTypes.FLOAT,
};

export const DATE_DATETIME: CubedField = {
    id: 'date_datetime',
    rawName: 'date',
    displayName: 'Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const DATE_DATE: CubedField = {
    id: 'date_date',
    rawName: 'date',
    displayName: 'Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE,
};

export const EVENT_CREATED_DATE: CubedField = {
    id: 'event_created_date',
    rawName: 'event_date_count',
    displayName: 'Event Date Count',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE,
};

export const LAST_VISIT_VIEWS_DATETIME: CubedField = {
    id: 'last_visit_views_datetime',
    rawName: 'last_visit',
    displayName: 'Views Last Visit',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const IMPRESSIONS_DATE_DATETIME: CubedField = {
    id: 'impressions_date_datetime',
    rawName: 'first_visit',
    displayName: 'Impressions Created',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const TAG_ERROR_DATE_TIME_DATETIME: CubedField = {
    id: 'tag_error_date_time_datetime',
    rawName: 'created',
    displayName: 'Tag Error Created',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const SEO_TREND_DATE_DATE: CubedField = {
    id: 'seo_trend_date_date',
    rawName: 'trend_date',
    displayName: 'Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE,
};

export const SEO_TYPE: CubedField = {
    id: 'seo_type',
    rawName: 'type',
    displayName: 'Type',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SEO_LANDING_PATH: CubedField = {
    id: 'seo_landing_path',
    rawName: 'landing_path',
    displayName: 'Landing Path',
    defaultOrderDir: 'asc',
    isDimension: true,
    fixedWidth: 500,
    dataType: DataTypes.STRING,
};
export const SEARCH_VOLUME_WEEKLY: CubedField = {
    id: 'search_volume_weekly',
    rawName: 'search_volume',
    displayName: 'Search Volume',
    defaultOrderDir: 'desc',
    description: 'Weekly search volume from SEMRUSH.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const SEARCH_VOLUME: CubedField = {
    id: 'search_volume',
    rawName: 'search_volume',
    displayName: 'Search Volume',
    defaultOrderDir: 'desc',
    description: 'Monthly search volume from SEMRUSH.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const CURRENT_POSITION: CubedField = {
    id: 'current_position',
    rawName: 'current_position',
    displayName: 'Current Rank',
    defaultOrderDir: 'desc',
    description: 'The current rank of a keyword in the SERP from AWR.',
    flipYAxis: true,
    dataType: DataTypes.NUMBER,
};

export const POSITION: CubedField = {
    id: 'position',
    rawName: 'position',
    displayName: 'Rank',
    defaultOrderDir: 'desc',
    description: 'The average rank of a keyword in the SERP from AWR.',
    flipYAxis: true,
    dataType: DataTypes.STRING,
};

export const MARKET_SHARE: CubedField = {
    id: 'market_share',
    rawName: 'marketshare',
    displayName: 'Market Share',
    defaultOrderDir: 'desc',
    description:
        'Market Share is calculated using the Visibility / Max Visibility. It is the percentage of the marketplace that is controlled by a single website across a group of keywords.',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.NUMBER,
};

export const GLASS_CEILING: CubedField = {
    id: 'glass_ceiling',
    rawName: 'glass_ceiling',
    displayName: 'Glass Ceiling Rank',
    defaultOrderDir: 'desc',
    description: 'The highest rank we think you could reach in the SERP.',
    dataType: DataTypes.NUMBER,
};

export const COMPETITION: CubedField = {
    id: 'competition',
    rawName: 'competition',
    displayName: 'Competition',
    defaultOrderDir: 'desc',
    description: 'Keyword competition score from SEMRUSH.',
    dataType: DataTypes.FLOAT,
};

export const POSITION_EFFORT: CubedField = {
    id: 'position_effort',
    rawName: 'position_effort',
    displayName: 'Rank Effort',
    defaultOrderDir: 'desc',
    description:
        'Rank effort is calculated using the formula (Current Rank - Glass Ceiling Rank) * Competition. It is the effort of getting from your current rank to the glass ceiling rank.',
    dataType: DataTypes.FLOAT,
};
export const SEO_CLICK_PRIORITY: CubedField = {
    id: 'seo_click_priority',
    rawName: 'click_priority_score',
    displayName: 'Click Priority Score',
    defaultOrderDir: 'asc',
    description:
        'Priority Score calculations include Rank Effort and Opportunity Clicks. The higher the priority score, the better.',
    dataType: DataTypes.FLOAT,
};

export const SEO_SALE_PRIORITY: CubedField = {
    id: 'seo_sale_priority',
    rawName: 'sales_priority_score',
    displayName: 'Sales Priority Score',
    defaultOrderDir: 'asc',
    description:
        'Priority Score calculations include Rank Effort and Opportunity Sales. The higher the priority score, the better.',
    dataType: DataTypes.FLOAT,
};

export const SEO_REVENUE_PRIORITY: CubedField = {
    id: 'seo_revenue_priority',
    rawName: 'revenue_priority_score',
    displayName: 'Revenue Priority Score',
    defaultOrderDir: 'asc',
    description:
        'Priority Score calculations include Rank Effort and Opportunity Revenue. The higher the priority score, the better.',
    dataType: DataTypes.FLOAT,
};

export const SEO_KEYWORD_VISITS: CubedField = {
    id: 'seo_keyword_visits',
    rawName: 'keyword_visits',
    displayName: 'Traffic',
    defaultOrderDir: 'desc',
    description: 'Click-throughs to the website from the SERP.',
    dataType: DataTypes.FLOAT,
};

export const SEO_BRAND: CubedField = {
    id: 'seo_brand',
    rawName: 'brand',
    displayName: 'Brand',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const SEO_TREND_VOLUME: CubedField = {
    id: 'seo_trend_volume',
    rawName: 'monthly_search_vol_by_trend',
    displayName: 'Trended Search Volume',
    defaultOrderDir: 'asc',
    description: 'Monthly search volume by trend from SEMRUSH.',
    dataType: DataTypes.FLOAT,
};
export const SEO_KEYWORD_LC_SALES: CubedField = {
    id: 'seo_keyword_lc_sales',
    rawName: 'keyword_lc_sales',
    displayName: 'LC Sales',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const SEO_KEYWORD_FM_SALES: CubedField = {
    id: 'seo_keyword_fm_sales',
    rawName: 'keyword_fm_sales',
    displayName: 'Cubed Sales',
    defaultOrderDir: 'desc',
    description: "Total attributed sales, using Cubed's attribution algorithm.",
    dataType: DataTypes.NUMBER,
};

export const SEO_KEYWORD_LC_REVENUE: CubedField = {
    id: 'seo_keyword_lc_revenue',
    rawName: 'keyword_lc_revenue',
    displayName: 'LC Revenue',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};
export const SEO_KEYWORD_FM_REVENUE: CubedField = {
    id: 'seo_keyword_fm_revenue',
    rawName: 'keyword_fm_revenue',
    displayName: 'Cubed Revenue',
    defaultOrderDir: 'desc',
    description: "Total attributed revenue, using Cubed's attribution algorithm.",
    dataType: DataTypes.FLOAT,
};

export const CURRENT_CLICK_OPPORTUNITY: CubedField = {
    id: 'current_click_opportunity',
    rawName: 'current_click_opportunity',
    displayName: 'Current Clicks',
    defaultOrderDir: 'desc',
    description: 'Predicted monthly click-throughs with your current rank.',
    dataType: DataTypes.FLOAT,
};
export const GLASS_CEILING_CLICK_OPPORTUNITY: CubedField = {
    id: 'glass_ceiling_click_opportunity',
    rawName: 'glass_ceiling_click_opportunity',
    displayName: 'Glass Ceiling Clicks',
    defaultOrderDir: 'desc',
    description: 'Predicted monthly click-throughs if you reached the glass ceiling rank.',
    dataType: DataTypes.FLOAT,
};
export const INCREMENTAL_CLICK_OPPORTUNITY: CubedField = {
    id: 'incremental_click_opportunity',
    rawName: 'incremental_click_opportunity',
    displayName: 'Opportunity Clicks',
    defaultOrderDir: 'desc',
    description:
        'Incremental change in the predicted monthly click-throughs if you reached the glass ceiling rank, compared to your current rank.',
    dataType: DataTypes.FLOAT,
};
export const RANK_1_INCREMENTAL_CLICK_OPPORTUNITY: CubedField = {
    id: 'rank_1_incremental_click_opportunity',
    rawName: 'rank_1_incremental_click_opportunity',
    displayName: 'Max Opportunity Clicks',
    defaultOrderDir: 'desc',
    description:
        'Incremental change in the predicted monthly click-throughs if you reached the number 1 rank, compared to your current rank.',
    dataType: DataTypes.FLOAT,
};
export const INCREMENTAL_LC_SALES: CubedField = {
    id: 'incremental_lc_sales',
    rawName: 'incremental_lc_sales',
    displayName: 'Incremental Last Click Sales',
    defaultOrderDir: 'desc',
    description:
        'Incremental change in the predicted monthly last-click sales if you reached the glass ceiling rank, compared to your current rank.',
    dataType: DataTypes.FLOAT,
};
export const CURRENT_FM_SALES: CubedField = {
    id: 'current_fm_sales',
    rawName: 'current_fm_sales',
    displayName: 'Current Cubed Sales',
    defaultOrderDir: 'desc',
    description: 'Predicted monthly attributed sales with your current rank.',
    dataType: DataTypes.FLOAT,
};
export const GLASS_CEILING_FM_SALES: CubedField = {
    id: 'glass_ceiling_fm_sales',
    rawName: 'glass_ceiling_fm_sales',
    displayName: 'Glass Ceiling Sales',
    defaultOrderDir: 'desc',
    description: 'Predicted monthly attributed sales if you reached the glass ceiling rank.',
    dataType: DataTypes.FLOAT,
};

export const INCREMENTAL_FM_SALES: CubedField = {
    id: 'incremental_fm_sales',
    rawName: 'incremental_fm_sales',
    displayName: 'Opportunity Sales',
    defaultOrderDir: 'desc',
    description:
        'Incremental change in the predicted monthly attributed sales if you reached the glass ceiling rank, compared to your current rank.',
    dataType: DataTypes.FLOAT,
};
export const RANK_1_INCREMENTAL_FM_SALES_OPPORTUNITY: CubedField = {
    id: 'rank_1_incremental_fm_sales_opportunity',
    rawName: 'rank_1_incremental_fm_sales_opportunity',
    displayName: 'Max Opportunity Sales',
    defaultOrderDir: 'desc',
    description:
        'Incremental change in the predicted monthly attributed sales, if you reached the number 1 rank, compared to your current rank.',
    dataType: DataTypes.FLOAT,
};

export const GOOGLE_TRENDS_GEO: CubedField = {
    id: 'google_trends_geo',
    rawName: 'geo',
    displayName: 'Google Trends GEO',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const GOOGLE_TRENDS_HITS: CubedField = {
    id: 'google_trends_hits',
    rawName: 'hits',
    displayName: 'Search Interest',
    defaultOrderDir: 'desc',
    description: 'Trended search interest from Google Trends.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const GOOGLE_TRENDS_HITS_SD_YOY: CubedField = {
    id: 'google_trends_hits_sd_yoy',
    rawName: 'hits_startdate_year_on_year',
    displayName: 'Search Interest SD YoY',
    defaultOrderDir: 'desc',
    description:
        'The difference in search interest between the start date selected in the calendar and 52 weeks prior.',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};
export const GOOGLE_TRENDS_HITS_ED_YOY: CubedField = {
    id: 'google_trends_hits_ed_yoy',
    rawName: 'hits_enddate_year_on_year',
    displayName: 'Search Interest ED YoY',
    defaultOrderDir: 'desc',
    description: 'The difference in search interest between the end date selected in the calendar and 52 weeks prior.',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};
export const GOOGLE_TRENDS_CHANGE_OVER_TIME: CubedField = {
    id: 'google_trends_change_over_time',
    rawName: 'change_over_time',
    displayName: 'Change Over Time',
    defaultOrderDir: 'desc',
    description:
        'The incremental change over time in search interest for a given keyword (difference between start date and end date)',
    dataType: DataTypes.FLOAT,
};
export const GOOGLE_TRENDS_CHANGE_OVER_TIME_LAST_YEAR: CubedField = {
    id: 'google_trends_change_over_time_last_year',
    rawName: 'change_over_time_last_year',
    displayName: 'LY Change Over Time',
    defaultOrderDir: 'desc',
    description:
        'The incremental change over time in search interest for a given keyword (difference between selected start date and end date last year)',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};

export const GOOGLE_TRENDS_CHANGE_OVER_TIME_PCT: CubedField = {
    id: 'google_trends_change_over_time_pct',
    rawName: 'change_over_time_pct',
    displayName: 'Change Over Time (%)',
    defaultOrderDir: 'desc',
    description:
        'The search interest percentage change over time for a given keyword (difference between start date and end date)',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const MARKETSHARE_CHANGE_OVER_TIME: CubedField = {
    id: 'marketshare_change_over_time',
    rawName: 'change_over_time_marketshare',
    displayName: 'Change Over Time',
    defaultOrderDir: 'desc',
    description: 'The change over time in marketshare (between start date and end date).',
    dataType: DataTypes.FLOAT,
};

export const MARKETSHARE_CHANGE_OVER_TIME_PCT: CubedField = {
    id: 'marketshare_change_over_time_pct',
    rawName: 'change_over_time_pct_marketshare',
    displayName: 'Change Over Time (%)',
    defaultOrderDir: 'desc',
    description: 'The percentage change over time in marketshare (between start date and end date).',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};

export const SEARCHVOLUME_CHANGE_OVER_TIME: CubedField = {
    id: 'searchvolume_change_over_time',
    rawName: 'change_over_time_searchvolume',
    displayName: 'Change Over Time',
    defaultOrderDir: 'desc',
    description: 'The change over time in search volume (between start date and end date).',
    dataType: DataTypes.FLOAT,
};

export const SEARCHVOLUME_CHANGE_OVER_TIME_PCT: CubedField = {
    id: 'searchvolume_change_over_time_pct',
    rawName: 'change_over_time_pct_searchvolume',
    displayName: 'Change Over Time (%)',
    defaultOrderDir: 'desc',
    description: 'The percentage change over time in search volume (between start date and end date).',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const POSITION_CHANGE_OVER_TIME: CubedField = {
    id: 'position_change_over_time',
    rawName: 'change_over_time_average_rank',
    displayName: 'Change Over Time',
    defaultOrderDir: 'desc',
    description: 'The change over time in search engine rank (between start date and end date).',
    dataType: DataTypes.FLOAT,
};

export const POSITION_CHANGE_OVER_TIME_PCT: CubedField = {
    id: 'position_change_over_time_pct',
    rawName: 'change_over_time_pct_average_rank',
    displayName: 'Change Over Time (%)',
    defaultOrderDir: 'desc',
    description: 'The percentage change over time in search engine rank (between start date and end date).',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};

export const VISIBILITY_CHANGE_OVER_TIME: CubedField = {
    id: 'visibility_change_over_time',
    rawName: 'change_over_time_visibility',
    displayName: 'Change Over Time',
    defaultOrderDir: 'desc',
    description: 'The change over time in visibilty (between start date and end date).',
    dataType: DataTypes.FLOAT,
};

export const VISIBILITY_CHANGE_OVER_TIME_PCT: CubedField = {
    id: 'visibility_change_over_time_pct',
    rawName: 'change_over_time_pct_visibility',
    displayName: 'Change Over Time (%)',
    defaultOrderDir: 'desc',
    description: 'The percentage change over time in visibilty (between start date and end date).',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};

export const CURRENT_FM_REVENUE: CubedField = {
    id: 'current_fm_revenue',
    rawName: 'current_fm_revenue',
    displayName: 'Current Revenue',
    defaultOrderDir: 'desc',
    description: 'The predicted monthly attributed revenue based on your current rank.',
    dataType: DataTypes.FLOAT,
};
export const GLASS_CEILING_FM_REVENUE: CubedField = {
    id: 'glass_ceiling_fm_revenue',
    rawName: 'glass_ceiling_fm_revenue',
    displayName: 'Glass Ceiling Revenue',
    defaultOrderDir: 'desc',
    description: 'Predicted monthly attributed revenue if you reached the glass ceiling rank.',
    dataType: DataTypes.FLOAT,
};
export const INCREMENTAL_FM_REVENUE: CubedField = {
    id: 'incremental_fm_revenue',
    rawName: 'incremental_fm_revenue',
    displayName: 'Opportunity Revenue',
    defaultOrderDir: 'desc',
    description:
        'Incremental change in the predicted monthly attributed revenue, if you reached the glass ceiling rank, compared to your current rank.',
    dataType: DataTypes.FLOAT,
};
export const RANK_1_INCREMENTAL_FM_REVENUE_OPPORTUNITY: CubedField = {
    id: 'rank_1_incremental_fm_revenue_opportunity',
    rawName: 'rank_1_incremental_fm_revenue_opportunity',
    displayName: 'Max Opportunity Revenue',
    defaultOrderDir: 'desc',
    description:
        'Incremental change in the predicted monthly attributed revenue, if you reached the number 1 rank, compared to your current rank.',
    dataType: DataTypes.FLOAT,
};

export const INC_SALES_VARIANCE: CubedField = {
    id: 'inc_sales_variance',
    rawName: 'inc_sales_variance',
    displayName: 'Incremental Sales Variance',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};
export const INC_REVENUE_VARIANCE: CubedField = {
    id: 'inc_revenue_variance',
    rawName: 'inc_revenue_variance',
    displayName: 'Opportunity Revenue Variance',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};
export const INC_SALES_VARIANCE_PCT: CubedField = {
    id: 'inc_sales_variance_pct',
    rawName: 'inc_sales_variance_pct',
    displayName: 'Opportunity Sales (%)',
    defaultOrderDir: 'desc',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const INC_REVENUE_VARIANCE_PCT: CubedField = {
    id: 'inc_revenue_variance_pct',
    rawName: 'inc_revenue_variance_pct',
    displayName: 'Opportunity Revenue (%)',
    defaultOrderDir: 'desc',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 33.9999],
        amber: [34, 65.99999],
        green: [66, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const INC_CLICKS_VARIANCE_PCT: CubedField = {
    id: 'inc_clicks_variance_pct',
    rawName: 'inc_clicks_variance_pct',
    displayName: 'Opportunity Clicks (%)',
    defaultOrderDir: 'desc',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};

// ATL
export const ATL: CubedField = {
    id: 'atl',
    rawName: 'atl',
    displayName: 'ATL Name',
    defaultOrderDir: 'desc',
    isDimension: true,
    lookupTerm: 'name',
    dataType: DataTypes.STRING,
};
// DISPLAY IMPRESSIONS
export const IMPRESSION_MODEL: CubedField = {
    id: 'impression_model',
    rawName: 'impression_model',
    displayName: 'Impression Type',
    defaultOrderDir: 'asc',
    isDimension: true,
    lookupTerm: 'source',
    dataType: DataTypes.STRING,
};
export const IMPRESSION_MEDIUM: CubedField = {
    id: 'impression_medium',
    rawName: 'impression_medium',
    displayName: 'Medium',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const IMPRESSION_SOURCE: CubedField = {
    id: 'impression_source',
    rawName: 'impression_source',
    displayName: 'Source',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const COUNTRY_NAME: CubedField = {
    id: 'country_name',
    rawName: 'atl_region__country_name',
    displayName: 'Country',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const CITY_NAME: CubedField = {
    id: 'city_name',
    rawName: 'atl_region__city_name',
    displayName: 'City',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const NATIONWIDE: CubedField = {
    id: 'nationwide',
    rawName: 'atl_region__nationwide',
    displayName: 'Nationwide',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.BOOL,
};
export const BASELINE_TRAFFIC: CubedField = {
    id: 'baseline_traffic',
    rawName: 'baseline_traffic',
    displayName: 'Baseline Traffic',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};
export const IMPRESSIONS_BASELINE_TRAFFIC: CubedField = {
    id: 'impressions_baseline_traffic',
    rawName: 'impressions_baseline_traffic',
    displayName: 'Baseline Traffic',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};
export const ACTUAL_TRAFFIC: CubedField = {
    id: 'actual_traffic',
    rawName: 'actual_traffic',
    displayName: 'Total Traffic',
    defaultOrderDir: 'desc',
    description: 'Total Traffic (All Channels).',
    dataType: DataTypes.NUMBER,
};

export const INCREMENTAL_TRAFFIC: CubedField = {
    id: 'incremental_traffic',
    rawName: 'incremental_traffic',
    displayName: 'ATL Traffic',
    defaultOrderDir: 'desc',
    description: 'Attributed Traffic from ATL Media.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const INCREMENTAL_TRAFFIC_IMPRESSIONS: CubedField = {
    id: 'incremental_traffic_impressions',
    rawName: 'incremental_traffic',
    displayName: 'Impression Traffic',
    defaultOrderDir: 'desc',
    description: 'Attributed Traffic from Display Impressions.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const BASELINE_LC_SALES: CubedField = {
    id: 'baseline_lc_sales',
    rawName: 'baseline_lc_sales',
    displayName: 'Baseline LC Sales',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};
export const BASELINE_FM_SALES: CubedField = {
    id: 'baseline_fm_sales',
    rawName: 'baseline_fm_sales',
    displayName: 'Baseline Cubed Sales',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const ACTUAL_LC_SALES: CubedField = {
    id: 'actual_lc_sales',
    rawName: 'actual_lc_sales',
    displayName: 'Total LC Sales',
    defaultOrderDir: 'desc',
    description: 'Total last-click Sales including non ATL sales.',
    dataType: DataTypes.NUMBER,
};

export const ACTUAL_FM_SALES: CubedField = {
    id: 'actual_fm_sales',
    rawName: 'actual_fm_sales',
    displayName: 'Total Cubed Sales',
    defaultOrderDir: 'desc',
    description: 'Total cubed Sales including non ATL sales.',
    dataType: DataTypes.NUMBER,
};

export const BASELINE_LC_REVENUE: CubedField = {
    id: 'baseline_lc_revenue',
    rawName: 'baseline_lc_revenue',
    displayName: 'Baseline LC Revenue',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};
export const BASELINE_FM_REVENUE: CubedField = {
    id: 'baseline_fm_revenue',
    rawName: 'baseline_fm_revenue',
    displayName: 'Baseline Cubed Revenue',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const ACTUAL_LC_REVENUE: CubedField = {
    id: 'actual_lc_revenue',
    rawName: 'actual_lc_revenue',
    displayName: 'Total LC Revenue',
    defaultOrderDir: 'desc',
    description: 'Total Sales including non ATL sales.',
    dataType: DataTypes.FLOAT,
};
export const ACTUAL_FM_REVENUE: CubedField = {
    id: 'actual_fm_revenue',
    rawName: 'actual_fm_revenue',
    displayName: 'Total Cubed Revenue',
    defaultOrderDir: 'desc',
    description: 'Total Cubed Sales including non ATL sales.',
    dataType: DataTypes.FLOAT,
};

export const INCREMENTAL_LC_SALES_ATL: CubedField = {
    id: 'incremental_lc_sales_atl',
    rawName: 'incremental_lc_sales',
    displayName: 'LC ATL Sales',
    defaultOrderDir: 'desc',
    description: 'Attributed LC Sales from ATL Media.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};

export const INCREMENTAL_FM_SALES_ATL: CubedField = {
    id: 'incremental_fm_sales_atl',
    rawName: 'incremental_fm_sales',
    displayName: 'Cubed ATL Sales',
    defaultOrderDir: 'desc',
    description: 'Attributed Cubed Sales from ATL Media.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};
export const INCREMENTAL_FM_SALES_ATL_IMPRESSIONS: CubedField = {
    id: 'incremental_fm_sales_atl_impressions',
    rawName: 'incremental_fm_sales',
    displayName: 'Cubed Sales',
    defaultOrderDir: 'desc',
    description: 'Attributed Cubed Sales from Display Impressions.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};

export const INCREMENTAL_LC_REVENUE_ATL: CubedField = {
    id: 'incremental_lc_revenue_atl',
    rawName: 'incremental_lc_revenue',
    displayName: 'LC ATL Revenue',
    defaultOrderDir: 'desc',
    description: 'Attributed LC Revenue from ATL Media.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};

export const INCREMENTAL_FM_REVENUE_ATL: CubedField = {
    id: 'incremental_fm_revenue_atl',
    rawName: 'incremental_fm_revenue',
    displayName: 'Cubed ATL Revenue',
    defaultOrderDir: 'desc',
    description: 'Attributed Cubed Revenue from ATL Media.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};
export const INCREMENTAL_FM_REVENUE_ATL_IMPRESSIONS: CubedField = {
    id: 'incremental_fm_revenue_atl_impressions',
    rawName: 'incremental_fm_revenue',
    displayName: 'Cubed Revenue',
    defaultOrderDir: 'desc',
    description: 'Attributed Cubed Revenue from Display Impressions.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};

export const ATL_TRAFFIC_PROPORTION: CubedField = {
    id: 'atl_traffic_proportion',
    rawName: 'atl_traffic_proportion',
    displayName: 'Traffic Proportion',
    defaultOrderDir: 'desc',
    description: 'Proportion of total traffic which came from ATL Media.',
    dataType: DataTypes.FLOAT,
};

export const ATL_LC_SALES_PROPORTION: CubedField = {
    id: 'atl_lc_sales_proportion',
    rawName: 'atl_lc_sales_proportion',
    displayName: 'LC Sales Proportion',
    defaultOrderDir: 'desc',
    description: 'Proportion of total last-click sales which came from ATL Media.',
    dataType: DataTypes.FLOAT,
};

export const ATL_FM_SALES_PROPORTION: CubedField = {
    id: 'atl_fm_sales_proportion',
    rawName: 'atl_fm_sales_proportion',
    displayName: 'Cubed Sales Proportion',
    defaultOrderDir: 'desc',
    description: 'Proportion of total cubed sales which came from ATL Media.',
    dataType: DataTypes.FLOAT,
};

export const ATL_LC_REVENUE_PROPORTION: CubedField = {
    id: 'atl_lc_revenue_proportion',
    rawName: 'atl_lc_revenue_proportion',
    displayName: 'LC Revenue Proportion',
    defaultOrderDir: 'desc',
    description: 'Proportion of total last-click revenue which came from ATL Media.',
    dataType: DataTypes.FLOAT,
};

export const ATL_FM_REVENUE_PROPORTION: CubedField = {
    id: 'atl_fm_revenue_proportion',
    rawName: 'atl_fm_revenue_proportion',
    displayName: 'Cubed Revenue Proportion',
    defaultOrderDir: 'desc',
    description: 'Proportion of total cubed revenue which came from ATL Media.',
    dataType: DataTypes.FLOAT,
};

// PPC Impression Share Performance

export const QUALITY_SCORE: CubedField = {
    id: 'quality_score',
    rawName: 'quality_score',
    displayName: 'Quality Score',
    description:
        "Quality Score is Google's rating of the quality and relevance of both your keywords and PPC ads. This score is measured on a scale from 1–10 and available at the keyword level. A higher Quality Score means that your ad and landing page are more relevant and useful to someone searching for your keyword, compared to other advertisers. Quality Score is calculated based on the combined performance of three components: Ad Relevance, Landing Page Experience & Expected CTR",
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};
export const CREATIVE_QUALITY_SCORE: CubedField = {
    id: 'creative_quality_score',
    rawName: 'creative_quality_score',
    displayName: 'Ad Relevance Quality Score',
    description:
        'The quality score of the ad. This score is measured on a scale of 1-3 and available at the keyword level. The higher the Quality Score, the better.',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};
export const POSTCLICK_QUALITY_SCORE: CubedField = {
    id: 'postclick_quality_score',
    rawName: 'postclick_quality_score',
    displayName: 'Landing Page Quality Score',
    description:
        'How relevant and useful your landing page is to people who click your ad. This score is measured on a scale of 1-3 and available at the keyword level. The higher the Quality Score, the better.',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};
export const SEARCH_PREDICTED_CTR_QUALITY_SCORE: CubedField = {
    id: 'search_predicted_ctr_quality_score',
    rawName: 'search_predicted_ctr_quality_score',
    displayName: 'Expected CTR Quality Score',
    description:
        'The likelihood that your ad will be clicked when shown. This score is measured on a scale of 1-3 and available at the keyword level. The higher the Quality Score, the better.',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};
export const PPC_IMPRESSIONS: CubedField = {
    id: 'ppc_impressions',
    rawName: 'impressions',
    displayName: 'Impressions',
    description: 'Count of how often your ad has appeared on a search results page or website on the Google Network.',
    defaultOrderDir: 'desc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const PPC_CLICKS: CubedField = {
    id: 'ppc_clicks',
    rawName: 'clicks',
    displayName: 'Clicks',
    description: 'The number of clicks on your ads.',
    defaultOrderDir: 'desc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const PPC_COST: CubedField = {
    id: 'ppc_cost',
    rawName: 'cost',
    displayName: 'Cost',
    description: 'The sum of your cost-per-click (CPC) for the period.',
    defaultOrderDir: 'desc',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};
export const CPC_BID: CubedField = {
    id: 'cpc_bid',
    rawName: 'cpc_bid',
    displayName: 'CPC Bid',
    description: 'Cost per click bid.',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};
export const TOP_OF_PAGE_CPC_BID: CubedField = {
    id: 'top_of_page_cpc_bid',
    rawName: 'top_of_page_cpc',
    displayName: 'Top of Page CPC Bid',
    description:
        'Estimate of the CPC bid required in order to show your ad on the top of the first page of search results.',
    defaultOrderDir: 'desc',
    dataType: DataTypes.MONEY,
};
export const CTR: CubedField = {
    id: 'ctr',
    rawName: 'ctr',
    displayName: 'CTR',
    description: 'Click Through Rate of your Ads. CTR = (Clicks/Impressions)*100',
    defaultOrderDir: 'desc',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const ABSOLUTE_TOP_IMPRESSION_PERCENTAGE: CubedField = {
    id: 'absolute_top_impression_percentage',
    rawName: 'absolute_top_impression_percentage',
    displayName: 'Absolute Top Impression %',
    description:
        'The percent of your ad impressions that are shown as the very first ad above the organic search results.',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};
export const TOP_IMPRESSION_PERCENTAGE: CubedField = {
    id: 'top_impression_percentage',
    rawName: 'top_impression_percentage',
    displayName: 'Top Impression %',
    description: 'The percent of your ad impressions that are shown anywhere above the organic search results.',
    defaultOrderDir: 'desc',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const SEARCH_ABSOLUTE_TOP_IMPRESSION_SHARE: CubedField = {
    id: 'search_absolute_top_impression_share',
    rawName: 'search_absolute_top_impression_share',
    displayName: 'Search Absolute Top Impression %',
    description:
        "The impressions you've received in the absolute top location (the very first ad above the organic search results) divided by the estimated number of impressions you were eligible to receive in the top location. ",
    defaultOrderDir: 'desc',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 33.9999],
        amber: [34, 65.99999],
        green: [66, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const SEARCH_BUDGET_LOST_ABSOLUTE_TOP_IMPRESSION_SHARE: CubedField = {
    id: 'search_budget_lost_absolute_top_impression_share',
    rawName: 'search_budget_lost_absolute_top_impression_share',
    displayName: 'Search Budget Lost Absolute Top Impression %',
    description:
        "The number estimating how often your ad wasn't the very first ad above the organic search results due to a low budget.",
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};
export const SEARCH_RANK_LOST_ABSOLUTE_TOP_IMPRESSION_SHARE: CubedField = {
    id: 'search_rank_lost_absolute_top_impression_share',
    rawName: 'search_rank_lost_absolute_top_impression_share',
    displayName: 'Search Rank Lost Absolute Top Impression %',
    description:
        "The number estimating how often your ad wasn't the very first ad above the organic search results due to poor Ad Rank.",
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};
export const SEARCH_TOP_IMPRESSION_SHARE: CubedField = {
    id: 'search_top_impression_share',
    rawName: 'search_top_impression_share',
    displayName: 'Search Top Impression %',
    description:
        "The impressions you've received in the top location (anywhere above the organic search results) compared to the estimated number of impressions you were eligible to receive in the top location.",
    defaultOrderDir: 'desc',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 33.9999],
        amber: [34, 65.99999],
        green: [66, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const SEARCH_BUDGET_LOST_TOP_IMPRESSION_SHARE: CubedField = {
    id: 'search_budget_lost_top_impression_share',
    rawName: 'search_budget_lost_top_impression_share',
    displayName: 'Search Budget Lost Top Impression %',
    description:
        "The number estimating how often your ad didn't show anywhere above the organic search results due to a low budget.",
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};
export const SEARCH_RANK_LOST_TOP_IMPRESSION_SHARE: CubedField = {
    id: 'search_rank_lost_top_impression_share',
    rawName: 'search_rank_lost_top_impression_share',
    displayName: 'Search Rank Lost Top Impression %',
    description:
        "The number estimating how often your ad didn't show anywhere above the organic search results due to poor Ad Rank.",
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};
export const SEARCH_IMPRESSION_SHARE: CubedField = {
    id: 'search_impression_share',
    rawName: 'search_impression_share',
    displayName: 'Search Impression %',
    description:
        "The impressions you've received on the Search Network divided by the estimated number of impressions you were eligible to receive.",
    defaultOrderDir: 'desc',
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 33.9999],
        amber: [34, 65.99999],
        green: [66, Infinity],
    },
    dataType: DataTypes.FLOAT,
};
export const SEARCH_BUDGET_LOST_IMPRESSION_SHARE: CubedField = {
    id: 'search_budget_lost_impression_share',
    rawName: 'search_budget_lost_impression_share',
    displayName: 'Search Budget Lost Impression %',
    description:
        "The estimated percentage of impressions on the Search Network that your ads didn't receive due to low budget.",
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};
export const SEARCH_RANK_LOST_IMPRESSION_SHARE: CubedField = {
    id: 'search_rank_lost_impression_share',
    rawName: 'search_rank_lost_impression_share',
    displayName: 'Search Rank Lost Impression %',
    description:
        "The estimated percentage of impressions on the Search Network that your ads didn't receive due to poor Ad Rank.",
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const PREDICTED_CTR: CubedField = {
    id: 'predicted_ctr',
    rawName: 'predicted_ctr',
    displayName: 'Predicted CTR',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

// Performance vs Target Monthly Report

export const REPORT_DATE_DATETIME: CubedField = {
    id: 'report_date_datetime',
    rawName: 'report_date',
    displayName: 'Report Month',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};
export const VISITORS: CubedField = {
    id: 'visitors',
    rawName: 'visitors',
    displayName: 'Visitors',
    defaultOrderDir: 'asc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const FC_VISITS: CubedField = {
    id: 'fc_visits',
    rawName: 'fc_visits',
    displayName: 'Target Visits',
    defaultOrderDir: 'asc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const LY_VISITS: CubedField = {
    id: 'ly_visits',
    rawName: 'ly_visits',
    displayName: 'Prior Year Visits',
    defaultOrderDir: 'asc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const FC_SALES: CubedField = {
    id: 'fc_sales',
    rawName: 'fc_sales',
    displayName: 'Target Sales',
    defaultOrderDir: 'asc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const LY_SALES: CubedField = {
    id: 'ly_sales',
    rawName: 'ly_sales',
    displayName: 'Prior Year Sales',
    defaultOrderDir: 'asc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const LY_REVENUE: CubedField = {
    id: 'ly_revenue',
    rawName: 'ly_revenue',
    displayName: 'Prior Year Revenue',
    defaultOrderDir: 'asc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const FC_REVENUE: CubedField = {
    id: 'fc_revenue',
    rawName: 'fc_revenue',
    displayName: 'Target Revenue',
    defaultOrderDir: 'asc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};
export const BUDGET: CubedField = {
    id: 'budget',
    rawName: 'budget',
    displayName: 'Budget',
    defaultOrderDir: 'asc',
    displayProportion: true,
    dataType: DataTypes.MONEY,
};
export const VISITS_VARIANCE_VS_FC: CubedField = {
    id: 'visits_variance_vs_fc',
    rawName: 'visits_variance_vs_fc',
    displayName: 'Visits Variance vs Target',
    defaultOrderDir: 'desc',
    description: 'Variance between visits and target visits.',
    dataType: DataTypes.NUMBER,
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
};
export const VISITS_VARIANCE_VS_LY: CubedField = {
    id: 'visits_variance_vs_ly',
    rawName: 'visits_variance_vs_ly',
    displayName: 'Visits Variance vs Prior Year',
    defaultOrderDir: 'desc',
    description: "Variance between visits and prior year's visits.",
    dataType: DataTypes.NUMBER,
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
};
export const SALES_VARIANCE_FM_VS_FC: CubedField = {
    id: 'sales_variance_fm_vs_fc',
    rawName: 'sales_variance_fm_vs_fc',
    displayName: 'Sales Variance vs Target',
    defaultOrderDir: 'desc',
    description: 'Variance between Cubed sales and target sales.',
    dataType: DataTypes.FLOAT,
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
};
export const SALES_VARIANCE_FM_VS_LY: CubedField = {
    id: 'sales_variance_fm_vs_ly',
    rawName: 'sales_variance_fm_vs_ly',
    displayName: 'Sales Variance vs Prior Year',
    defaultOrderDir: 'desc',
    description: "Variance between Cubed sales and prior year's sales.",
    dataType: DataTypes.FLOAT,
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
};
export const REVENUE_VARIANCE_FM_VS_FC: CubedField = {
    id: 'revenue_variance_fm_vs_fc',
    rawName: 'revenue_variance_fm_vs_fc',
    displayName: 'Revenue Variance vs Target',
    defaultOrderDir: 'desc',
    description: 'Variance between Cubed revenue and target revenue.',
    dataType: DataTypes.MONEY,
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
};
export const REVENUE_VARIANCE_FM_VS_LY: CubedField = {
    id: 'revenue_variance_fm_vs_ly',
    rawName: 'revenue_variance_fm_vs_ly',
    displayName: 'Revenue Variance vs Prior Year',
    defaultOrderDir: 'desc',
    description: "Variance between Cubed revenue and prior year's revenue.",
    dataType: DataTypes.FLOAT,
    heatmap: {
        default: [0, 'grey'],
        red: [-Infinity, 0],
        green: [0.0000001, Infinity],
    },
};

// Display Impressions
export const DISPLAY_IMPRESSIONS: CubedField = {
    id: 'display_impressions',
    rawName: 'impressions',
    displayName: 'Impressions',
    defaultOrderDir: 'desc',
    description: 'The total number of impressions displayed.',
    dataType: DataTypes.NUMBER,
};

export const DISPLAY_IMPRESSION_VISITORS: CubedField = {
    id: 'display_impression_visitors',
    rawName: 'impression_visitors',
    displayName: 'Impression Visitors',
    defaultOrderDir: 'desc',
    displayProportion: true,
    description: 'The number of visitors who saw an impression.',
    dataType: DataTypes.NUMBER,
};
export const MCS_CONTINENT_NAME: CubedField = {
    id: 'mcs_continent_name',
    rawName: 'geolocation__continent_name',
    displayName: 'Continent',
    defaultOrderDir: 'desc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const MCS_COUNTRY_NAME: CubedField = {
    id: 'mcs_country_name',
    rawName: 'geolocation__country_name',
    displayName: 'Country',
    defaultOrderDir: 'desc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const MCS_CITY_NAME: CubedField = {
    id: 'mcs_city_name',
    rawName: 'geolocation__city_name',
    displayName: 'City',
    defaultOrderDir: 'desc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const REPEAT_SALE: CubedField = {
    id: 'repeat_sale',
    rawName: 'repeat_sale',
    displayName: 'Customer New v Repeat',
    isDimension: true,
    defaultOrderDir: 'asc',
    description: 'The status of a sale',
    dataType: DataTypes.STRING,
};
export const SALE_NUMBER: CubedField = {
    id: 'sale_number',
    rawName: 'sale_number',
    displayName: 'Sale Number',
    isDimension: true,
    defaultOrderDir: 'asc',
    description: "The sale number within a customer's journey.",
    dataType: DataTypes.NUMBER,
};

export const RANK_DIFFERENCE: CubedField = {
    id: 'rank_difference',
    rawName: 'rank_difference',
    displayName: 'Rank Difference',
    defaultOrderDir: 'desc',
    description: 'Rank Difference is the difference in rank between your glass ceiling and your current rank.',
    dataType: DataTypes.FLOAT,
};

// TECH SEO DASHBOARD

export const PAGE_TITLE: CubedField = {
    id: 'page_title',
    rawName: 'page_title',
    displayName: 'Page Title',
    defaultOrderDir: 'asc',
    description: 'The title of the web page.',
    dataType: DataTypes.STRING,
};
export const META_DESCRIPTION: CubedField = {
    id: 'meta_description',
    rawName: 'meta_description',
    displayName: 'Meta Description',
    description: 'The meta description of your web page.',
    dataType: DataTypes.STRING,
};
export const TITLE_LENGTH: CubedField = {
    id: 'title_length',
    rawName: 'title_length',
    displayName: 'Title Length',
    defaultOrderDir: 'asc',
    description: 'The count of characters in the page title.',
    dataType: DataTypes.NUMBER,
};
export const DESCRIPTION_LENGTH: CubedField = {
    id: 'description_length',
    rawName: 'description_length',
    displayName: 'Description Length',
    defaultOrderDir: 'asc',
    description: 'The count of characters in the meta description.',
    dataType: DataTypes.NUMBER,
};
export const H1: CubedField = {
    id: 'h1',
    rawName: 'h1_1',
    displayName: 'H1 Tag',
    defaultOrderDir: 'asc',
    description: 'The H1 tag of your web page.',
    dataType: DataTypes.STRING,
};
export const H2: CubedField = {
    id: 'h2',
    rawName: 'h2_1',
    displayName: 'H2 Tag',
    defaultOrderDir: 'asc',
    description: 'The H2 tag of your web page.',
    dataType: DataTypes.STRING,
};
export const H1_LENGTH: CubedField = {
    id: 'h1_length',
    rawName: 'h1_1_length',
    displayName: 'H1 Tag Length',
    defaultOrderDir: 'asc',
    description: 'The count of characters in your H1 tag.',
    dataType: DataTypes.NUMBER,
};
export const H1_DUPLICATE: CubedField = {
    id: 'h1_duplicate',
    rawName: 'h1_1_duplicate',
    displayName: 'H1 Duplicated',
    defaultOrderDir: 'asc',
    description: 'If the h1_1 tag is equal to the h1_2 tag.',
    dataType: DataTypes.BOOL,
};
export const H2_LENGTH: CubedField = {
    id: 'h2_length',
    rawName: 'h2_1_length',
    displayName: 'H2 Tag Length',
    defaultOrderDir: 'asc',
    description: 'The count of characters in your H2 tag.',
    dataType: DataTypes.NUMBER,
};
export const STATUS_CODE: CubedField = {
    id: 'status_code',
    rawName: 'status_code',
    displayName: 'Status Code',
    defaultOrderDir: 'asc',
    description:
        'The status code of your web page. Status code is the HTTP status code in a server response to a browsers request.',
    dataType: DataTypes.NUMBER,
};
export const INDEXED: CubedField = {
    id: 'indexed',
    rawName: 'indexed',
    displayName: 'Indexed',
    defaultOrderDir: 'asc',
    description:
        'The indexed status of your web page. A page is indexed by Google if it has been visited by the Google crawler and stored in the Google index.',
    dataType: DataTypes.BOOL,
};
export const INDEXED_VERDICT: CubedField = {
    id: 'indexed_verdict',
    rawName: 'indexed_verdict',
    displayName: 'Indexed Verdict',
    defaultOrderDir: 'asc',
    description:
        'The indexed status of your web page. A page is indexed by Google if it has been visited by the Google crawler and stored in the Google index.',
    dataType: DataTypes.STRING,
};
export const COVERAGE_STATE: CubedField = {
    id: 'coverage_state',
    rawName: 'coverage_state',
    displayName: 'Coverage State',
    defaultOrderDir: 'asc',
    description:
        'The indexed status of your web page. A page is indexed by Google if it has been visited by the Google crawler and stored in the Google index.',
    dataType: DataTypes.STRING,
};
export const ROBOTS_TEXT_STATE: CubedField = {
    id: 'robots_text_state',
    rawName: 'robots_txt_state',
    displayName: 'Robots Text State',
    defaultOrderDir: 'asc',
    description:
        'The indexed status of your web page. A page is indexed by Google if it has been visited by the Google crawler and stored in the Google index.',
    dataType: DataTypes.STRING,
};
export const PAGE_FETCH_STATE: CubedField = {
    id: 'page_fetch_state',
    isDimension: true,
    rawName: 'page_fetch_state',
    displayName: 'Page Fetch State',
    defaultOrderDir: 'asc',
    description:
        'The indexed status of your web page. A page is indexed by Google if it has been visited by the Google crawler and stored in the Google index.',
    dataType: DataTypes.STRING,
};
export const LAST_CRAWL_DATE: CubedField = {
    id: 'last_crawl_date',
    isDimension: true,
    rawName: 'last_crawl_time',
    displayName: 'Last Crawl Date',
    defaultOrderDir: 'asc',
    description:
        'The indexed status of your web page. A page is indexed by Google if it has been visited by the Google crawler and stored in the Google index.',
    dataType: DataTypes.DATE_TIME,
};
export const DAYS_SINCE_LAST_CRAWL: CubedField = {
    id: 'days_since_last_crawl',
    rawName: 'days_since_last_crawl',
    displayName: 'Days Since Last Crawl',
    defaultOrderDir: 'asc',
    dataType: DataTypes.NUMBER,
};
export const MOBILE_VERDICT: CubedField = {
    id: 'mobile_verdict',
    isDimension: true,
    rawName: 'mobile_verdict',
    displayName: 'Mobile Verdict',
    defaultOrderDir: 'asc',
    description:
        'The indexed status of your web page. A page is indexed by Google if it has been visited by the Google crawler and stored in the Google index.',
    dataType: DataTypes.STRING,
};
export const CANONICAL: CubedField = {
    id: 'canonical',
    rawName: 'canonical',
    displayName: 'Canonical',
    defaultOrderDir: 'asc',
    description:
        'A canonical URL is the URL of the page that Google thinks is most representative from a set of duplicate pages on your site.',
    dataType: DataTypes.STRING,
};
export const HEADER_CANONICAL: CubedField = {
    id: 'header_canonical',
    rawName: 'header_canonical',
    displayName: 'Header Canonical',
    defaultOrderDir: 'asc',
    description: 'A canonical link that sits within the header of your web page.',
    dataType: DataTypes.STRING,
};
export const SELF_REFERENCE: CubedField = {
    id: 'self_reference',
    rawName: 'self_reference',
    displayName: 'Self Reference',
    defaultOrderDir: 'asc',
    description: 'Self reference is when the canonical is equal to the current page.',
    dataType: DataTypes.BOOL,
};
export const META_ROBOTS: CubedField = {
    id: 'meta_robots',
    rawName: 'meta_robots',
    displayName: 'META ROBOTS',
    defaultOrderDir: 'asc',
    description:
        'Robots meta directives (sometimes called "meta tags") are pieces of code that provide crawlers instructions for how to crawl or index web page content.',
    dataType: DataTypes.STRING,
};
export const DOUBLE_CANONICAL: CubedField = {
    id: 'double_canonical',
    rawName: 'double_canonical',
    displayName: 'Double Canonical',
    defaultOrderDir: 'asc',
    description:
        'Double canonical is when there is a canonical link in the body and in the header of your website. It is considered best practice to only specify canonicals using a single method on any given URL.',
    dataType: DataTypes.BOOL,
};
export const OVERALL_SCORE: CubedField = {
    id: 'overall_score',
    rawName: 'overall_score',
    displayName: 'Overall Score',
    defaultOrderDir: 'asc',
    description: 'The overall performance.',
    dataType: DataTypes.FLOAT,
};
export const FCP: CubedField = {
    id: 'fcp',
    rawName: 'fcp',
    displayName: 'FCP',
    defaultOrderDir: 'asc',
    description:
        'First content paint (FCP) measures how long it takes (seconds) the browser to render the first piece of DOM content after a user navigates to your page.',
    dataType: DataTypes.FLOAT,
};
export const FID: CubedField = {
    id: 'fid',
    rawName: 'fid',
    displayName: 'FID',
    defaultOrderDir: 'asc',
    description:
        'First Input Delay (FID) measures the time (seconds) from when a user first interacts with a page to the time when the browser is actually able to begin processing event handlers in response to that interaction.',
    dataType: DataTypes.FLOAT,
};
export const LCP: CubedField = {
    id: 'lcp',
    rawName: 'lcp',
    displayName: 'LCP',
    defaultOrderDir: 'asc',
    description:
        'The Largest Contentful Paint (LCP) metric reports the render time (seconds) of the largest image or text block visible within the viewport, relative to when the page first started loading.',
    dataType: DataTypes.FLOAT,
};
export const CLS: CubedField = {
    id: 'cls',
    rawName: 'cls',
    displayName: 'CLS',
    defaultOrderDir: 'asc',
    description:
        'Cumulative Layout Shift (CLS) is a measure of the largest burst of layout shift scores for every unexpected layout shift that occurs during the entire lifespan of a page.',
    dataType: DataTypes.FLOAT,
};

export const INP: CubedField = {
    id: 'inp',
    rawName: 'inp',
    displayName: 'INP',
    defaultOrderDir: 'asc',
    description:
        'Interaction to Next Paint (INP) measures the time (seconds) from when a user first interacts with a page to the time when the browser is actually able to begin processing event handlers in response to that interaction.',
    dataType: DataTypes.FLOAT,
};

export const CLS_STATUS: CubedField = {
    id: 'cls_status',
    rawName: 'cls_status',
    displayName: 'CLS Status',
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};
export const FID_STATUS: CubedField = {
    id: 'fid_status',
    rawName: 'fid_status',
    displayName: 'FID Status',
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};
export const LCP_STATUS: CubedField = {
    id: 'lcp_status',
    rawName: 'lcp_status',
    displayName: 'LCP Status',
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};
export const INP_STATUS: CubedField = {
    id: 'inp_status',
    rawName: 'inp_status',
    displayName: 'INP Status',
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};
export const TIME_INTERACTIVE: CubedField = {
    id: 'time_interactive',
    rawName: 'time_interactive',
    displayName: 'Time to Interactive',
    defaultOrderDir: 'asc',
    description:
        'The Time to Interactive metric measures the time (seconds) from when the page starts loading to when its main sub-resources have loaded and it is capable of reliably responding to user input quickly.',
    dataType: DataTypes.FLOAT,
};
export const BLOCKING_TIME_DURATION: CubedField = {
    id: 'blocking_time_duration',
    rawName: 'blocking_time_duration',
    displayName: 'Blocking Time Duration',
    defaultOrderDir: 'asc',
    description:
        'The Blocking Time metric measures the total amount of time between First Contentful Paint (FCP) and Time to Interactive (TTI) where the main thread was blocked for long enough to prevent input responsiveness.',
    dataType: DataTypes.NUMBER,
};
export const TOTAL_TASKS: CubedField = {
    id: 'total_tasks',
    rawName: 'total_tasks',
    displayName: 'Total Tasks',
    defaultOrderDir: 'asc',
    description: 'The total number of tasks completed.',
    dataType: DataTypes.NUMBER,
};
export const TOTAL_TASKS_TIME: CubedField = {
    id: 'total_tasks_time',
    rawName: 'total_tasks_time',
    displayName: 'Total Tasks Time',
    defaultOrderDir: 'asc',
    description: 'The total task time for all tasks to complete.',
    dataType: DataTypes.NUMBER,
};
export const LONG_TASKS: CubedField = {
    id: 'long_tasks',
    rawName: 'long_tasks',
    displayName: 'Long Tasks',
    defaultOrderDir: 'asc',
    description:
        'Long tasks are considered those tasks which take over 50ms to load. Long tasks are JavaScript code which monopolizes the main thread for a long period of time and makes the UI “freeze”. It affects mainly the “Time to Interactive” metric.',
    dataType: DataTypes.NUMBER,
};
export const UNUSED_JS_SCORE: CubedField = {
    id: 'unused_js_score',
    rawName: 'unused_js_score',
    displayName: 'Unused JS Score',
    defaultOrderDir: 'asc',
    description:
        'A score rating your unused Javascript, including all JavaScripts files with more than 20 kibibytes of unused code. Unused JavaScript can slow down your page load speed.',
    dataType: DataTypes.FLOAT,
};
export const PAGE_SIZE: CubedField = {
    id: 'page_size',
    rawName: 'page_size',
    displayName: 'Page Size',
    defaultOrderDir: 'asc',
    description: 'The size of a web page, in bytes.',
    dataType: DataTypes.NUMBER,
};
export const CACHED_PAGE_SIZE: CubedField = {
    id: 'cached_page_size',
    rawName: 'cached_page_size',
    displayName: 'Cached Page Size',
    defaultOrderDir: 'asc',
    description: 'The cached size of a web page, in bytes.',
    dataType: DataTypes.FLOAT,
};
export const UNUSED_JS_SAVINGS: CubedField = {
    id: 'unused_js_savings',
    rawName: 'unused_js_savings',
    displayName: 'Unused JS',
    defaultOrderDir: 'asc',
    description:
        'The number of JavaScript files where there could be savings, including all JavaScripts files with more than 20 kibibytes of unused code. Unused JavaScript can slow down your page load speed.',
    dataType: DataTypes.FLOAT,
};

export const IMAGE_URL: CubedField = {
    id: 'image_url',
    rawName: 'image_url',
    displayName: 'Image URL',
    defaultOrderDir: 'desc',
    description: '',
    dataType: DataTypes.STRING,
};

export const ALT_TEXT: CubedField = {
    id: 'alt_text',
    rawName: 'alt_text',
    displayName: 'Alt Text',
    defaultOrderDir: 'desc',
    description: '',
    dataType: DataTypes.STRING,
};

export const FILE_TYPE: CubedField = {
    id: 'file_type',
    rawName: 'file_type',
    displayName: 'File Type',
    defaultOrderDir: 'desc',
    description: '',
    dataType: DataTypes.STRING,
};

export const CONTAINS_SCHEMA: CubedField = {
    id: 'contains_schema',
    rawName: 'contains_schema',
    displayName: 'Contains Schema',
    defaultOrderDir: 'desc',
    description: '',
    dataType: DataTypes.BOOL,
};

export const MICRODATA: CubedField = {
    id: 'microdata',
    rawName: 'microdata',
    displayName: 'Microdata',
    defaultOrderDir: 'desc',
    description: '',
    dataType: DataTypes.BOOL,
};

export const JSON: CubedField = {
    id: 'json',
    rawName: 'json',
    displayName: 'JSON',
    defaultOrderDir: 'desc',
    description: '',
    dataType: DataTypes.BOOL,
};

export const RDFA: CubedField = {
    id: 'rdfa',
    rawName: 'rdfa',
    displayName: 'RDFA',
    defaultOrderDir: 'desc',
    description: '',
    dataType: DataTypes.BOOL,
};

// Outreach Link Tracker Fields

export const IMPLEMENTED_DATE_DATETIME: CubedField = {
    id: 'implemented_date_datetime',
    rawName: 'implemented_date',
    displayName: 'Implemented Date',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const CLIENT: CubedField = {
    id: 'client',
    rawName: 'client',
    displayName: 'Client',
    defaultOrderDir: 'asc',
    description: 'The client with which the url is associated.',
    dataType: DataTypes.STRING,
};

export const CAMPAIGN: CubedField = {
    id: 'campaign',
    rawName: 'campaign',
    displayName: 'Campaign',
    defaultOrderDir: 'asc',
    description: 'The campaign with which the url is associated.',
    dataType: DataTypes.STRING,
};

export const SITE_NAME: CubedField = {
    id: 'site_name',
    rawName: 'site_name',
    displayName: 'Site Name',
    defaultOrderDir: 'asc',
    description: 'The site name with which the url is associated.',
    dataType: DataTypes.STRING,
};

export const BACKLINK: CubedField = {
    id: 'backlink',
    rawName: 'backlink',
    displayName: 'Backlink',
    defaultOrderDir: 'asc',
    fixedWidth: 500,
    description: 'The referer link that links back to the clients site.',
    dataType: DataTypes.STRING,
};

export const FOLLOW: CubedField = {
    id: 'follow',
    rawName: 'follow',
    displayName: 'Follow',
    defaultOrderDir: 'asc',
    fixedWidth: 500,
    description: 'Is the associated backlink a follow or no follow link?',
    dataType: DataTypes.BOOL,
};

export const DOMAIN_AUTHORITY: CubedField = {
    id: 'domain_authority',
    rawName: 'domain_authority',
    displayName: 'Domain Authority',
    defaultOrderDir: 'asc',
    fixedWidth: 500,
    description:
        'Search engine ranking score that predicts how likely a website is to rank in the search engine result pages.',
    dataType: DataTypes.NUMBER,
};

// PPC CAMPAIGN BUDGET RECOMMENDATIONS

export const PPC_CURRENT_BUDGET: CubedField = {
    id: 'current_budget',
    rawName: 'current_budget',
    displayName: 'Current Budget',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};
export const PPC_RECOMMENDED_BUDGET: CubedField = {
    id: 'recommended_budget',
    rawName: 'recommended_budget',
    displayName: 'Recommended Budget',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};
export const OPTIMIZATION_SCORE: CubedField = {
    id: 'optimization_score',
    rawName: 'optimization_score',
    displayName: 'Optimization Score',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};
export const PPC_INCREMENTAL_BUDGET: CubedField = {
    id: 'ppc_incremental_budget',
    rawName: 'incremental_budget',
    displayName: 'Incremental Budget',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};
export const PPC_INCREMENTAL_CLICKS: CubedField = {
    id: 'ppc_incremental_clicks',
    rawName: 'incremental_clicks',
    displayName: 'Incremental Clicks',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};
export const PPC_INCREMENTAL_LC_SALES: CubedField = {
    id: 'ppc_incremental_lc_sales',
    rawName: 'incremental_lc_sales',
    displayName: 'Incremental LC Sales',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};
export const PPC_INCREMENTAL_FM_SALES: CubedField = {
    id: 'ppc_incremental_fm_sales',
    rawName: 'incremental_lc_sales',
    displayName: 'Incremental Cubed Sales',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};
export const PPC_INCREMENTAL_LC_REVENUE: CubedField = {
    id: 'ppc_incremental_lc_revenue',
    rawName: 'incremental_lc_sales',
    displayName: 'Incremental LC Revenue',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};
export const PPC_INCREMENTAL_FM_REVENUE: CubedField = {
    id: 'ppc_incremental_fm_revenue',
    rawName: 'incremental_fm_sales',
    displayName: 'Incremental Cubed Revenue',
    defaultOrderDir: 'asc',
    dataType: DataTypes.FLOAT,
};

// Grid Carbon Usage
export const TOTAL_TRANSFER_B: CubedField = {
    id: 'total_transfer_b',
    rawName: 'total_transfer_b',
    displayName: 'Total Transfer (B)',
    defaultOrderDir: 'desc',
    description: 'Total data transferred in bytes.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};
export const TOTAL_TRANSFER_GB: CubedField = {
    id: 'total_transfer_gb',
    rawName: 'total_transfer_gb',
    displayName: 'Total Transfer (GB)',
    defaultOrderDir: 'desc',
    description: 'Total data transferred in gigabytes.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};
export const TOTAL_POWER_KWH: CubedField = {
    id: 'total_power_kwh',
    rawName: 'total_power_kwh',
    displayName: 'Total Power (KWH)',
    defaultOrderDir: 'desc',
    description: 'Total power used in running your website.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};
export const GRID_CARBON: CubedField = {
    id: 'grid_carbon',
    rawName: 'grid_carbon',
    displayName: 'Grid Carbon (KG)',
    defaultOrderDir: 'desc',
    description: 'Amount of carbon generated in kilograms.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};

export const UNIQUE_PAGE_VIEWS: CubedField = {
    id: 'unique_page_views',
    rawName: 'unique_page_views',
    displayName: 'Unique Page Views',
    defaultOrderDir: 'desc',
    description: 'Unique views of a page. A visit can view a page once per session.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const PAGE_VIEWS: CubedField = {
    id: 'page_views',
    rawName: 'page_views',
    displayName: 'Page Views',
    defaultOrderDir: 'desc',
    description: 'Views of a page. A visit can view a page several times per session.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const PAGE_EXITS: CubedField = {
    id: 'page_exits',
    rawName: 'page_exits',
    displayName: 'Page Exits',
    defaultOrderDir: 'desc',
    description: 'Exits from a page.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const PAGE_EXIT_RATE: CubedField = {
    id: 'page_exit_rate',
    rawName: 'page_exit_rate',
    displayName: 'Page Exit Rate',
    defaultOrderDir: 'desc',
    description: 'The percentage of visits who exit on this page vs continuing their session on-site.',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const LANDING_PAGE_VIEWS: CubedField = {
    id: 'landing_page_views',
    rawName: 'landing_page_views',
    displayName: 'Landing Page Views',
    defaultOrderDir: 'desc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const LANDING_PAGE_BOUNCES: CubedField = {
    id: 'landing_page_bounces',
    rawName: 'landing_page_bounces',
    displayName: 'Landing Page Bounces',
    defaultOrderDir: 'desc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const AVERAGE_GRID_CARBON: CubedField = {
    id: 'average_grid_carbon',
    rawName: 'avg_grid_carbon',
    displayName: 'Avg Page Carbon (KG)',
    defaultOrderDir: 'desc',
    description: 'Average grid carbon per page view.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};

export const AVERAGE_GRID_CARBON_VISITS: CubedField = {
    id: 'average_grid_carbon_visits',
    rawName: 'avg_grid_carbon_visit',
    displayName: 'Avg Visit Carbon (KG)',
    defaultOrderDir: 'desc',
    description: 'Average grid carbon per visit.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};

export const PRODUCTIVE_CARBON: CubedField = {
    id: 'productive_carbon',
    rawName: 'productive_carbon',
    displayName: 'Productive Carbon',
    defaultOrderDir: 'desc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const TRANSACTIONAL_CARBON: CubedField = {
    id: 'transactional_carbon',
    rawName: 'transactional_carbon',
    displayName: 'Transactional Carbon',
    defaultOrderDir: 'desc',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const PRODUCTIVE_CARBON_PERCENTAGE: CubedField = {
    id: 'productive_carbon_percentage',
    rawName: 'productive_carbon_percentage',
    displayName: 'Productive Carbon %',
    defaultOrderDir: 'desc',
    description: 'The percentage of visits which triggered an event.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};

export const TRANSACTIONAL_CARBON_PERCENTAGE: CubedField = {
    id: 'transactional_carbon_percentage',
    rawName: 'transactional_carbon_percentage',
    displayName: 'Transactional Carbon %',
    defaultOrderDir: 'desc',
    description: 'The percentage of visits which triggered a conversion event.',
    displayProportion: true,
    dataType: DataTypes.FLOAT,
};

// Segmenter
export const SEGMENTER: CubedField = {
    id: 'segmenter',
    rawName: 'segmenter',
    displayName: 'Segment',
    defaultOrderDir: 'desc',
    description: '',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const MONITORED_METRIC: CubedField = {
    id: 'monitored_metric',
    rawName: 'metric__display_name',
    displayName: 'Monitored Metric',
    isDimension: true,
    description: 'The current metric being monitored.',
    dataType: DataTypes.STRING,
};

export const VALUE: CubedField = {
    id: 'value',
    rawName: 'value',
    displayName: 'Metric Value',
    description: 'The value associated with the current monitored metric.',
    dataType: DataTypes.NUMBER,
};

export const SYNC_COUNT: CubedField = {
    id: 'sync_count',
    rawName: 'sync_count',
    displayName: 'Sync Count',
    description: 'The number of unique visitor syncs that have occurred within the date range specified.',
    dataType: DataTypes.NUMBER,
};

export const MODEL_NAME: CubedField = {
    id: 'model_name',
    rawName: 'name',
    displayName: 'Model Name',
    description: 'The name associated with the attribution model.',
    dataType: DataTypes.STRING,
};

export const PROPENSITY_MODEL_NAME: CubedField = {
    id: 'propensity_model_name',
    rawName: 'propensity_model_run',
    displayName: 'Model Name',
    description: 'The name associated with the attribution model.',
    dataType: DataTypes.STRING,
};

export const ACTIVE: CubedField = {
    id: 'active',
    rawName: 'active',
    displayName: 'Active',
    description: 'Highlights whether the associated metric is active or inactive.',
    dataType: DataTypes.BOOL,
};

export const MODEL_CREATED_DATETIME: CubedField = {
    id: 'model_created_datetime',
    rawName: 'created',
    displayName: 'Created',
    description: 'Date when the model was created.',
    dataType: DataTypes.DATE_TIME,
};

export const MODEL_UPDATED_DATETIME: CubedField = {
    id: 'model_updated_datetime',
    rawName: 'updated',
    displayName: 'Updated',
    description: 'Date when the model was updated.',
    dataType: DataTypes.DATE_TIME,
};

export const ADVERTISING_CHANNEL_TYPE: CubedField = {
    id: 'advertising_channel_type',
    rawName: 'advertising_channel_type',
    displayName: 'Advertising Channel Type',
    dataType: DataTypes.STRING,
};

export const REVENUE: CubedField = {
    id: 'revenue',
    rawName: 'revenue',
    displayName: 'Revenue',
    dataType: DataTypes.MONEY,
};

export const START_DATETIME: CubedField = {
    id: 'start_datetime',
    rawName: 'start_datetime',
    displayName: 'Start Date',
    dataType: DataTypes.DATE_TIME,
    isDimension: true,
};

export const END_DATETIME: CubedField = {
    id: 'end_datetime',
    rawName: 'end_datetime',
    displayName: 'End Date',
    dataType: DataTypes.DATE_TIME,
    isDimension: true,
};

export const ID: CubedField = {
    id: 'id',
    rawName: 'id',
    displayName: 'ID',
    dataType: DataTypes.NUMBER,
};

export const ACCURACY: CubedField = {
    id: 'accuracy',
    rawName: 'accuracy',
    displayName: 'Accuracy',
    dataType: DataTypes.NUMBER,
};

export const KAP: CubedField = {
    id: 'kap',
    rawName: 'kap',
    displayName: 'KAP',
    dataType: DataTypes.NUMBER,
};
export const TRUE_POSITIVE_RATE: CubedField = {
    id: 'true_positive_rate',
    rawName: 'true_positive_rate',
    displayName: 'True Positive Rate',
    dataType: DataTypes.FLOAT,
};
export const TRUE_NEGATIVE_RATE: CubedField = {
    id: 'true_negative_rate',
    rawName: 'true_negative_rate',
    displayName: 'True Negative Rate',
    dataType: DataTypes.FLOAT,
};
export const ROC_AUC: CubedField = {
    id: 'roc_auc',
    rawName: 'roc_auc',
    displayName: 'ROC-AUC',
    dataType: DataTypes.FLOAT,
};
export const PRECISION: CubedField = {
    id: 'precision',
    rawName: 'precision',
    displayName: 'Precision',
    dataType: DataTypes.FLOAT,
};
export const RECALL: CubedField = {
    id: 'recall',
    rawName: 'recall',
    displayName: 'Recall',
    dataType: DataTypes.FLOAT,
};
export const F1_SCORE: CubedField = {
    id: 'f1_score',
    rawName: 'f1_score',
    displayName: 'F1-Score',
    dataType: DataTypes.FLOAT,
};

export const PRODUCT_NAME: CubedField = {
    id: 'product_name',
    rawName: 'name',
    displayName: 'Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const JOURNEY_COMPLEXITY_VISITS: CubedField = {
    id: 'journey_complexity_visits',
    rawName: 'visits',
    displayName: 'Vists',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.NUMBER,
};

export const FIRST_VISIT_DATETIME: CubedField = {
    id: 'first_visit_datetime',
    rawName: 'first_visit',
    displayName: 'First Visit',
    isDimension: true,
    dataType: DataTypes.DATE_TIME,
};

export const LAPSED_DAYS_FROM_LAST_VISIT: CubedField = {
    id: 'lapsed_days_from_last_visit',
    rawName: 'lapsed_days_from_last_visit',
    displayName: 'Lapsed Days From Last Visit',
    isDimension: true,
    dataType: DataTypes.NUMBER,
};

// Channel Insights Dashboard
export const GOAL_COMPLETIONS: CubedField = {
    id: 'goal_completions',
    rawName: 'fm_sales',
    displayName: 'Goal Completions',
    defaultOrderDir: 'desc',
    description: "Sales attributed using Cubed's multi-touch algorithm.",
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const GOAL_VALUE: CubedField = {
    id: 'goal_value',
    rawName: 'fm_revenue',
    displayName: 'Goal Value',
    defaultOrderDir: 'desc',
    description: "Revenue attributed using Cubed's multi-touch algorithm.",
    displayProportion: true,
    dataType: DataTypes.MONEY,
};

export const GOAL_CVR: CubedField = {
    id: 'goal_cvr',
    rawName: 'fm_cvr',
    displayName: 'Conversion Rate',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const GOAL_CVR_GOAL_SNAPSHOT: CubedField = {
    id: 'goal_cvr_goal_snapshot',
    rawName: 'fm_cvr_goal_snapshot',
    displayName: 'Conversion Rate',
    defaultOrderDir: 'desc',
    dataType: DataTypes.FLOAT,
};

export const VISITS_GOAL_SNAPSHOT: CubedField = {
    id: 'visits_goal_snapshot',
    rawName: 'visits_goal_snapshot',
    displayName: 'Visits',
    defaultOrderDir: 'desc',
    alwaysShowPercentages: true,
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

// Channel Detail Dashboard
export const REFERER_ID: CubedField = {
    id: 'referer_id',
    rawName: 'referer',
    lookupTerm: 'id',
    displayName: 'Channel Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const AFFILIATE_PUBLISHER: CubedField = {
    id: 'affiliate_publisher',
    rawName: 'affiliate_publisher',
    displayName: 'Affiliate Publisher',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const AFFILIATE_CAMPAIGN: CubedField = {
    id: 'affiliate_campaign',
    rawName: 'affiliate_campaign',
    displayName: 'Affiliate Campaign',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const ROI: CubedField = {
    id: 'roi',
    rawName: 'fm_roi_pct',
    displayName: 'ROI',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};

// Content Universe Dashboard
export const CONVERSIONS: CubedField = {
    id: 'conversions',
    rawName: 'sales',
    displayName: 'Conversions',
    defaultOrderDir: 'desc',
    dataType: DataTypes.NUMBER,
};

export const CONVERSION_VALUE: CubedField = {
    id: 'conversion_value',
    rawName: 'revenue',
    displayName: 'Conversion Value',
    dataType: DataTypes.MONEY,
};

// PAGE CATEGORIES

export const LANDING_PATH_GEOGRAPHY: CubedField = {
    id: 'landing_path_geography',
    rawName: 'landing_path__categories__geography',
    displayName: 'Page Geography',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH_LANGUAGE: CubedField = {
    id: 'landing_path_language',
    rawName: 'landing_path__categories__language',
    displayName: 'Page Language',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH_CATEGORY: CubedField = {
    id: 'landing_path_category',
    rawName: 'landing_path__categories__category',
    displayName: 'Page Category',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH_CONTENT_TYPE: CubedField = {
    id: 'landing_path_content_type',
    rawName: 'landing_path__categories__content_type',
    displayName: 'Page Type',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH_CONTENT_LENGTH: CubedField = {
    id: 'landing_path_content_length',
    rawName: 'landing_path__categories__content_length',
    displayName: 'Content Length',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH_MARKET: CubedField = {
    id: 'landing_path_market',
    rawName: 'landing_path__categories__market',
    displayName: 'Market',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH_HOMEPAGE: CubedField = {
    id: 'landing_path_homepage',
    rawName: 'landing_path__categories__homepage',
    displayName: 'Homepage',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH_TOPIC: CubedField = {
    id: 'landing_path_topic',
    rawName: 'landing_path__categories__topic',
    displayName: 'Page Topic',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const LANDING_PATH_SUBDIRECTORY: CubedField = {
    id: 'landing_path_subdirectory',
    rawName: 'landing_path__categories__subdirectory',
    displayName: 'Page Type',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const LANDING_PATH_SUBDIRECTORY_LEVEL_1: CubedField = {
    id: 'landing_path_subdirectory_level_1',
    rawName: 'landing_path__categories__subdirectory_level_1',
    displayName: 'Page Subdirectory Level 1',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const LANDING_PATH_SUBDIRECTORY_LEVEL_2: CubedField = {
    id: 'landing_path_subdirectory_level_2',
    rawName: 'landing_path__categories__subdirectory_level_2',
    displayName: 'Page Subdirectory Level 2',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const LANDING_PATH_SUBDIRECTORY_LEVEL_3: CubedField = {
    id: 'landing_path_subdirectory_level_3',
    rawName: 'landing_path__categories__subdirectory_level_3',
    displayName: 'Page Subdirectory Level 3',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const PATH_GEOGRAPHY: CubedField = {
    id: 'path_geography',
    rawName: 'path__categories__geography',
    displayName: 'Page Geography',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PATH_LANGUAGE: CubedField = {
    id: 'path_language',
    rawName: 'path__categories__language',
    displayName: 'Page Language',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PATH_CATEGORY: CubedField = {
    id: 'path_category',
    rawName: 'path__categories__category',
    displayName: 'Page Category',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PATH_CONTENT_TYPE: CubedField = {
    id: 'path_content_type',
    rawName: 'path__categories__content_type',
    displayName: 'Page Type',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PATH_CONTENT_LENGTH: CubedField = {
    id: 'path_content_length',
    rawName: 'path__categories__content_length',
    displayName: 'Content Length',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PATH_MARKET: CubedField = {
    id: 'path_market',
    rawName: 'path__categories__market',
    displayName: 'Market',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PATH_HOMEPAGE: CubedField = {
    id: 'path_homepage',
    rawName: 'path__categories__homepage',
    displayName: 'Homepage',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PATH_TOPIC: CubedField = {
    id: 'path_topic',
    rawName: 'path__categories__topic',
    displayName: 'Page Topic',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PATH_SUBDIRECTORY: CubedField = {
    id: 'path_subdirectory',
    rawName: 'path__categories__subdirectory',
    displayName: 'Top URL Folder',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const PATH_SUBDIRECTORY_LEVEL_1: CubedField = {
    id: 'path_subdirectory_level_1',
    rawName: 'path__categories__subdirectory_level_1',
    displayName: 'Page Subdirectory Level 1',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const PATH_SUBDIRECTORY_LEVEL_2: CubedField = {
    id: 'path_subdirectory_level_2',
    rawName: 'path__categories__subdirectory_level_2',
    displayName: 'Page Subdirectory Level 2',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
export const PATH_SUBDIRECTORY_LEVEL_3: CubedField = {
    id: 'path_subdirectory_level_3',
    rawName: 'path__categories__subdirectory_level_3',
    displayName: 'Page Subdirectory Level 3',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const CATEGORY_MIX: CubedField = {
    id: 'category_mix',
    rawName: 'mix',
    displayName: 'Mix',
    defaultOrderDir: 'asc',
    isDimension: false,
    ignoreTotal: true,
    dataType: DataTypes.NUMBER,
};

export const INDEXED_PAGE_COUNT: CubedField = {
    id: 'indexed_page_count',
    rawName: 'indexed_page_count',
    displayName: 'Indexed Page Count',
    defaultOrderDir: 'asc',
    description: '',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const PAGES_VIEWED_COUNT: CubedField = {
    id: 'pages_viewed_count',
    rawName: 'pages_viewed_count',
    displayName: 'Pages Viewed Count',
    defaultOrderDir: 'asc',
    description: '',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

// Customer Insights Dashboard
export const GEOLOCATION_COUNTRY_NAME: CubedField = {
    id: 'geolocation_country_name',
    rawName: 'country_name',
    displayName: 'Country',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const GEOLOCATION_CITY_NAME: CubedField = {
    id: 'geolocation_city_name',
    rawName: 'city_name',
    displayName: 'City',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PAGES_CONVERSION_COUNT: CubedField = {
    id: 'pages_conversion_count',
    rawName: 'pages_conversion_count',
    displayName: 'Pages Conversion Count',
    defaultOrderDir: 'asc',
    description: '',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const ATTRIBUTED_PATH: CubedField = {
    id: 'attributed_path',
    rawName: 'path',
    displayName: 'Path',
    lookupTerm: 'path',
    isDimension: true,
    defaultOrderDir: 'asc',
    dataType: DataTypes.STRING,
};

export const ATTRIBUTED_VALUE: CubedField = {
    id: 'attributed_value',
    rawName: 'attributed_value',
    displayName: 'Attributed Value',
    defaultOrderDir: 'asc',
    description: '',
    displayProportion: true,
    dataType: DataTypes.NUMBER,
};

export const PAYMENTS_PAYMENT_TO: CubedField = {
    id: 'payments__payment_to',
    rawName: 'payments__payment_to',
    displayName: 'Payment To',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PAYMENTS_PAYMENT_FROM: CubedField = {
    id: 'payments__payment_from',
    rawName: 'payments__payment_from',
    displayName: 'Payment From',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PAYMENTS_SENDER_CURRENCY: CubedField = {
    id: 'payments__sender_currency',
    rawName: 'payments__sender_currency',
    displayName: 'Sender Currency',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};

export const PAYMENTS_RECEIVER_CURRENCY: CubedField = {
    id: 'payments__receiver_currency',
    rawName: 'payments__receiver_currency',
    displayName: 'Receiver Currency',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
};
