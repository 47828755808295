import { SectionDashboardRequest, TableComparisonTableRow } from '../types';

const sortByVariance = (request: SectionDashboardRequest, rows: TableComparisonTableRow[]) => {
    rows = rows.sort((a, b) => {
        const currentSortField = request.orderBy ? request.orderBy[0].field.rawName : null;
        const direction = request.orderBy ? request.orderBy[0].orderByDirection : null;

        if (currentSortField && direction) {
            if (a.variance && b.variance) {
                const aVariance = a.variance.values[currentSortField].rawValue || 0;
                const bVariance = b.variance.values[currentSortField].rawValue || 0;

                if (direction === 'desc') {
                    if (aVariance < bVariance) {
                        return 1;
                    }
                } else if (direction === 'asc') {
                    if (aVariance > bVariance) {
                        return 1;
                    }
                } else {
                    return -1;
                }
            }
        }
        return -1;
    });

    const offset = request.offset || 0;
    return rows.splice(offset, request.limit);
};

export default sortByVariance;
