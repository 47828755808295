// React Dependencies
import React, { useEffect, useState } from 'react';

// Libraries
import Axios from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setPopup, removePopup } from '../../redux/actions/popup';

// Helpers
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Components
import LayoutModalNavigation from '../../components/navigation/layout-modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

const LayoutModalPinterestAPI = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [closeButtonState, setCloseButtonState] = useState('close');
    const [removeButtonState, setRemoveButtonState] = useState(true);
    const [saveChangesButtonHidden, setSaveChangesButtonHidden] = useState(true);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState(false);
    const [manageAccountsAccordionOpen, setManageAccountsAccordionOpen] = useState(false);
    const [manageAccountsAccordionVisible, setManageAccountsAccordionVisible] = useState(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState(true);
    const [applyButtonDisabled, setApplyButtonDisabled] = useState(true);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
    const [disableConnectionSelect, setDisableConnectionSelect] = useState(false);
    const [connections, setConnections] = useState([]);
    const [selectedConnections, setSelectedConnections] = useState([]);
    const [removedConnections, setRemovedConnections] = useState([]);
    const [updatedConnections, setUpdatedConnections] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [updatedAccounts, setUpdatedAccounts] = useState([]);
    const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
    const [isApiOwner, setIsApiOwner] = useState(false);

    // Redux states and methods
    const user = useSelector(state => state.user);
    const accountToken = useSelector(state => state.account.token);
    const meta = useSelector(state => state.meta);

    const dispatch = useDispatch();

    useEffect(() => {
        //fetch all connection on initial load
        fetchConnections();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Enable the save changes and cancel when a user is added into either the revoked or updated arrays
        if (saveChangesButtonHidden && (updatedConnections.length > 0 || removedConnections.length > 0)) {
            setSaveChangesButtonHidden(false);
            setCloseButtonState('cancel');
        }
        // Enable apply accounts when an account is added to the update array
        if (applyButtonDisabled === true && updatedAccounts.length > 0) {
            setApplyButtonDisabled(false);
        }
    }, [updatedConnections, removedConnections, updatedAccounts]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchConnections = async () => {
        try {
            const connectionUrl = generateUrl('integrations', 'lite-pinterest-connection', [
                { key: 'active', value: 1 },
                { key: 'deleted', value: 0 },
                { key: 'limit', value: 0 },
            ]);
            const accountsUrl = generateUrl('integrations', 'lite-pinterest-account', [
                { key: 'deleted', value: 0 },
                { key: 'limit', value: 0 },
            ]);

            const connectionDataResponse = await Axios.get(connectionUrl, { withCredentials: true });
            const connectionData = connectionDataResponse.data.objects;
            const accountDataResponse = await Axios.get(accountsUrl, { withCredentials: true });
            const accountData = accountDataResponse.data.objects;

            let emailUrl;
            const emailDataPromises = connectionData.map(connection => {
                emailUrl = generateUrlDetail('config', 'lite-user-email', connection.cubed_user_id);
                return Axios.get(emailUrl, { withCredentials: true })
                    .then(response => response.data)
                    .catch(e => {
                        return {
                            email: 'Unknown',
                            id: connection.cubed_user_id,
                            resource_uri: '',
                        };
                    });
            });

            const rawEmail = await Promise.all(emailDataPromises);

            let tempObject = connectionData.map(rawConnection => {
                let userDetail = rawEmail.find(userData => userData.id === rawConnection.cubed_user_id);
                return {
                    id: rawConnection.id,
                    name: rawConnection.name,
                    user: userDetail.email,
                    pinterest_username: rawConnection.pinterest_username,
                    userId: rawConnection.cubed_user_id,
                    accounts: accountData.filter(account => account.connection.id === rawConnection.id),
                };
            });

            setConnections(tempObject);
            setIsLoading(false);
        } catch (e) {
            setPageError(true);
        }
    };

    const getAccounts = () => {
        var connection = selectedConnections[0];

        Axios({
            method: 'GET',
            url: generateUrl('integrations', 'lite-pinterest-account', [
                { key: 'connection__id', value: connection.id },
                { key: 'deleted', value: 0 },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            const tempAccounts = response.data.objects.map(account => {
                account.updated = false;
                account.deleted = false;
                return account;
            });
            setAccounts(tempAccounts);
            setIsLoadingAccounts(false);
        });
    };

    const saveConnections = () => {
        return new Promise((resolve, reject) => {
            const requests = [];

            updatedConnections.forEach(connection => {
                connection.accounts.forEach(account => {
                    if (account.updated !== true) {
                        return;
                    }

                    if (account.deleted === true) {
                        return requests.push(
                            Axios({
                                method: 'DELETE',
                                url: generateUrlDetail('integrations', 'pinterest-account', account.id),
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                        );
                    }

                    delete account.connection;
                    delete account.deleted;
                    delete account.updated;

                    requests.push(
                        Axios({
                            method: 'PUT',
                            url: generateUrlDetail('integrations', 'pinterest-account', account.id),
                            data: account,
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                });
            });

            removedConnections.forEach(connection => {
                requests.push(
                    Axios({
                        method: 'DELETE',
                        url: generateUrlDetail('integrations', 'pinterest-connection', connection.id),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );

                connection.accounts.forEach(account => {
                    requests.push(
                        Axios({
                            method: 'DELETE',
                            url: generateUrlDetail('integrations', 'pinterest-account', account.id),
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                });
            });

            Axios.all(requests)
                .then(
                    Axios.spread((...res) => {
                        dispatch(
                            addNotification({
                                copy: 'These changes have been successfully saved.',
                                type: NotificationMessageType.Success,
                            })
                        );
                        dispatch(removeModal());
                    })
                )
                .catch(error => {
                    dispatch(
                        addNotification({
                            copy: 'There was an issue trying to save your changes. Please try again later or contact Cubed Support.',
                            type: NotificationMessageType.Error,
                        })
                    );
                    setSaveChangesButtonLoading(false);
                    setSaveChangesButtonHidden(false);
                });
        });
    };

    const onSaveChangesClick = () => {
        setSaveChangesButtonLoading(true);
        setSaveChangesButtonHidden(true);
        saveConnections();
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(removeModal());
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    // PrimaryAction of the close popup
    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    // SecondaryAction of the close popup
    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onEnableToggle = accountId => {
        const tempUpdatedAccounts = [...updatedAccounts];

        // Check if account ID already exists in the updated array
        let accountInUpdated = tempUpdatedAccounts.filter(account => {
            return account.id === accountId;
        });

        let accountInConnectionUpdated = [];
        updatedConnections.forEach(connection => {
            if (connection.id !== selectedConnections[0].id) {
                return;
            }

            accountInConnectionUpdated = connection.accounts.filter(account => {
                return account.id === accountId;
            });
        });

        const indexOfAccount = tempUpdatedAccounts.indexOf(accountInUpdated);

        let selectedAccount = [];
        if (accountInUpdated.length === 1) {
            selectedAccount = accountInUpdated[0];
        } else if (accountInConnectionUpdated.length === 1) {
            selectedAccount = accountInConnectionUpdated[0];
        } else {
            selectedAccount = accounts.filter(account => {
                return account.id === accountId;
            })[0];
        }

        selectedAccount.user_consent = !selectedAccount.user_consent;
        selectedAccount.deleted = false;
        selectedAccount.updated = true;

        if (accountInUpdated.length === 1) {
            tempUpdatedAccounts[indexOfAccount] = selectedAccount;
        } else {
            tempUpdatedAccounts.push({ ...selectedAccount });
        }
        setUpdatedAccounts(tempUpdatedAccounts);
    };
    const onDeletedToggle = accountId => {
        const tempUpdatedAccounts = [...updatedAccounts];

        // Check if account ID already exists in the updated array
        let accountInUpdated = updatedAccounts.filter(account => {
            return account.id === accountId;
        });

        let accountInConnectionUpdated = [];
        updatedConnections.forEach(connection => {
            if (connection.id !== selectedConnections[0].id) {
                return;
            }

            accountInConnectionUpdated = connection.accounts.filter(account => {
                return account.id === accountId;
            });
        });

        const indexOfAccount = tempUpdatedAccounts.indexOf(accountInUpdated);

        let selectedAccount = [];
        if (accountInUpdated.length === 1) {
            selectedAccount = accountInUpdated[0];
        } else if (accountInConnectionUpdated.length === 1) {
            selectedAccount = accountInConnectionUpdated[0];
        } else {
            selectedAccount = accounts.filter(account => {
                return account.id === accountId;
            })[0];
        }

        selectedAccount.deleted = !selectedAccount.deleted;
        selectedAccount.active = !selectedAccount.deleted;
        selectedAccount.user_consent = false;
        selectedAccount.updated = true;

        if (accountInUpdated.length === 1) {
            tempUpdatedAccounts[indexOfAccount] = selectedAccount;
        } else {
            tempUpdatedAccounts.push({ ...selectedAccount });
        }
        setUpdatedAccounts(tempUpdatedAccounts);
    };

    const onCreateClick = () => {
        dispatch(
            setPopup({
                title: 'Create connection with Pinterest.',
                iconType: 'informative',
                contentType: 'simple',
                config: {
                    copy: 'You will be taken to Pinterest to authorise access to your data. Once you have authorised Cubed, our access to your data can be managed via the Cubed account configuration pages.',
                },
                buttons: [
                    {
                        value: 'SAVE & CONTINUE',
                        onClick: onCreatePopupContinue,
                    },
                    {
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                        onClick: onPopupStayHereClick,
                    },
                ],
            })
        );
    };

    const onCreatePopupContinue = () => {
        window.location = `${meta.apiDomain}/api/${accountToken}/integrations/pinterest/initialise`;
    };

    const onEditClick = () => {
        setManageAccountsAccordionOpen(true);
        setManageAccountsAccordionVisible(true);
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);
        setApplyButtonDisabled(true);
        setDisableConnectionSelect(true);
        setIsLoadingAccounts(true);
        getAccounts();
        setIsApiOwner(selectedConnections[0].userId === user.id);
    };

    const onCancelClick = () => {
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);
        setApplyButtonDisabled(true);
        setManageAccountsAccordionOpen(false);
        setManageAccountsAccordionVisible(false);
        setDisableConnectionSelect(false);
        setSelectedConnections([]);
        setAccounts([]);
        setUpdatedAccounts([]);
    };

    const onReimportClick = () => {
        dispatch(
            setPopup({
                title: 'Import Accounts',
                iconType: 'informative',
                contentType: 'importIntegrationAccounts',
                config: {
                    technology: 'pinterest',
                    connectionId: selectedConnections[0].id,
                    onImportFinishOverride: onReImportFinishOverride,
                },
                buttons: [],
            })
        );
    };

    const onReImportFinishOverride = () => {
        setIsLoadingAccounts(true);
        setApplyButtonDisabled(false);
        fetchConnections();
        getAccounts();
    };

    const onConnectionSelect = event => {
        if (disableConnectionSelect === true) {
            return;
        }

        const selectedConnectionId = parseInt(event.currentTarget.getAttribute('data-value'));
        let tempSelectedConnections = [];

        if (selectedConnections.filter(connection => connection.id === selectedConnectionId).length > 0) {
            tempSelectedConnections = selectedConnections.filter(connection => connection.id !== selectedConnectionId);
            setSelectedConnections(tempSelectedConnections);
            setEditButtonDisabled(tempSelectedConnections === 0);
            setRemoveButtonDisabled(tempSelectedConnections === 0);
        } else {
            tempSelectedConnections = selectedConnections.concat(
                connections.filter(connection => connection.id === selectedConnectionId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRemoved = false;
        let containsNonRemoved = false;
        let containsEdited = false;

        tempSelectedConnections.forEach(connection => {
            if (removedConnections.indexOf(connection) >= 0) {
                containsRemoved = true;
            } else {
                containsNonRemoved = true;
            }

            if (updatedConnections.indexOf(connection) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let tempEditButtonDisabled = true;
        let tempRemoveButtonDisabled = true;
        let tempRemoveButtonState = true;

        if (tempSelectedConnections.length > 0) {
            tempEditButtonDisabled = false;
            tempRemoveButtonDisabled = false;
        }

        if (tempSelectedConnections.length > 1) {
            tempEditButtonDisabled = true;
            tempRemoveButtonDisabled = false;
        }

        if (containsRemoved && !containsNonRemoved && !containsEdited) {
            tempEditButtonDisabled = true;
            tempRemoveButtonDisabled = false;
            tempRemoveButtonState = false;
        }

        if (containsRemoved) {
            tempEditButtonDisabled = true;
        }

        if (containsRemoved && containsNonRemoved) {
            tempRemoveButtonDisabled = true;
            tempRemoveButtonState = true;
        }
        setSelectedConnections(tempSelectedConnections);
        setEditButtonDisabled(tempEditButtonDisabled);
        setRemoveButtonDisabled(tempRemoveButtonDisabled);
        setRemoveButtonState(tempRemoveButtonState);
        setApplyButtonDisabled(true);
        setManageAccountsAccordionOpen(false);
    };

    const onDeleteConnection = () => {
        if (disableConnectionSelect === true) {
            return;
        }

        let tempRemovedConnections = [].concat(removedConnections);
        let tempUpdatedConnections = [].concat(updatedConnections);

        selectedConnections.forEach(connection => {
            if (removeButtonState === true) {
                tempRemovedConnections.push(connection);
            } else {
                tempRemovedConnections.splice(tempRemovedConnections.indexOf(connection), 1);
            }

            // If a connection was edited and then revoked in the same instance then the revoke should take priority
            const updatedConnectionIndex = tempUpdatedConnections.indexOf(connection);

            if (updatedConnectionIndex >= 0) {
                tempUpdatedConnections.splice(updatedConnectionIndex, 1);
            }
        });

        setRemovedConnections(tempRemovedConnections);
        setUpdatedConnections(tempUpdatedConnections);
        setSelectedConnections([]);
        setRemoveButtonState(true);
    };

    const onApplyClick = () => {
        const tempUpdatedConnections = [...updatedConnections];
        const tempSelectedConnection = { ...selectedConnections[0] };
        const tempConnectionInUpdated = tempUpdatedConnections.filter(
            filteredConnection => filteredConnection.id === tempSelectedConnection.id
        );
        // If connection has been updated then use updated connection instead of selected connection
        const connection =
            tempConnectionInUpdated.length === 1 ? { ...tempConnectionInUpdated[0] } : tempSelectedConnection;

        updatedAccounts.forEach(account => {
            const accountInConnection = connection.accounts.filter(
                filteredAccount => filteredAccount.id === account.id
            );
            let accountInSelectedIndex =
                accountInConnection.length === 1 ? connection.accounts.indexOf(accountInConnection[0]) : null;

            if (accountInSelectedIndex !== null || accountInSelectedIndex !== -1) {
                connection.accounts[accountInSelectedIndex] = account;
            } else {
                connection.accounts.push(account);
            }
        });

        if (tempConnectionInUpdated.length === 1) {
            const indexOfConnection = tempUpdatedConnections.indexOf(tempConnectionInUpdated[0]);
            tempUpdatedConnections[indexOfConnection] = connection;
        } else {
            tempUpdatedConnections.push(connection);
        }
        setUpdatedConnections(tempUpdatedConnections);

        // Close Accordion
        onCancelClick();
    };

    const RenderModalNavigation = () => {
        return (
            <LayoutModalNavigation
                isAddForm={false}
                updatedData={updatedConnections}
                deletedData={updatedConnections}
                handleSaveChanges={onSaveChangesClick}
                saveChangesButtonHidden={saveChangesButtonHidden}
                saveChangesButtonLoading={saveChangesButtonLoading}
                handleCloseClick={onCloseClick}
            />
        );
    };

    const RenderAccordion = () => {
        if (manageAccountsAccordionVisible === false) {
            return null;
        }

        let introText;
        if (isApiOwner) {
            introText =
                '<p>You have full control over the accounts which will be used by the Cubed Platform. If the required account does not appear in the list below, you can reimport it by clicking "REIMPORT".</p> <p>Access to individual accounts can also be removed below.</p>';
        } else {
            introText =
                '<p>You have full control over the accounts which will be used by the Cubed Platform. If the required account does not appear in the list below, you can login to Cubed with same account using which you created the connection to the Pinterest and you will be able to re-import the accounts.</p> <p>Access to individual accounts can also be removed below.</p>';
        }

        if (selectedConnections.length === 1) {
            introText += `<p>Currently Editing:</p><ul> ${selectedConnections
                .map(connection => '<li>' + connection.name + '</li>')
                .join('')} </ul>`;
        }

        // Preparing filters for the simple table
        const filters = [
            {
                name: 'Active',
                filter: row => {
                    return row.columns[1].toggleChecked === true;
                },
            },
        ];
        const tempAccounts = [];

        // Get the correct / most updated account details
        if (isLoadingAccounts === false && selectedConnections.length === 1) {
            const updatedConnection = updatedConnections.filter(
                filteredConnection => filteredConnection.id === selectedConnections[0].id
            );

            accounts.forEach(account => {
                const inUpdatedAccounts = updatedAccounts.filter(filteredAccount => filteredAccount.id === account.id);
                const inUpdatedConnection =
                    updatedConnection.length === 1
                        ? updatedConnection[0].tempAccounts.filter(filteredAccount => filteredAccount.id === account.id)
                        : [];

                if (inUpdatedAccounts.length === 1) {
                    account = inUpdatedAccounts[0];
                } else if (inUpdatedConnection.length === 1) {
                    account = inUpdatedConnection[0];
                }

                tempAccounts.push({
                    columns: [
                        {
                            copy: account['pinterest_display_name'],
                        },
                        {
                            type: 'toggle',
                            toggleDataName: account.id,
                            toggleChecked: account.user_consent,
                            toggleOnClick: () => onEnableToggle(account.id),
                        },
                        {
                            type: 'toggle',
                            toggleDataName: account.id,
                            toggleChecked: account.deleted,
                            toggleOnClick: () => onDeletedToggle(account.id),
                        },
                    ],
                });
            });
        }

        const accordionButtons = [];
        if (isApiOwner) {
            accordionButtons.push({
                value: 'RE-IMPORT',
                onClick: onReimportClick,
            });
        }
        accordionButtons.push({
            value: 'APPLY',
            onClick: onApplyClick,
            disabled: applyButtonDisabled,
        });
        accordionButtons.push({
            value: 'CANCEL',
            onClick: onCancelClick,
            buttonTheme: ButtonThemes.Secondary,
        });

        let extraError = 'You do not have permission to import accounts for this connection.';
        if (selectedConnections[0].userId === user.id) {
            extraError = 'This can be done by clicking on "RE-IMPORT" below.';
        }

        const accordions = [
            {
                header: 'Manage Accounts',
                required: false,
                open: manageAccountsAccordionOpen,
                type: 'simpleTable',
                intro: introText,
                config: {
                    enableSearch: true,
                    enableFilters: true,
                    searchableColumns: [0],
                    filters: filters,
                    isLoading: isLoadingAccounts,
                    tableHeader: {
                        columns: [
                            {
                                title: 'Account Name',
                            },
                            {
                                title: 'Enabled',
                            },
                            {
                                title: 'Deleted',
                            },
                        ],
                    },
                    tableRows: tempAccounts,
                    optionalButtonConfig: {
                        buttons: accordionButtons,
                    },
                    errorMessageOverride: 'No accounts have been imported for this connection.\n' + extraError,
                    isScrollable: true,
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    const RenderTable = () => {
        const errorMessageOverride = 'There are no connections assigned to this account.';

        const header = {
            columns: [
                {
                    title: 'Connection Name',
                },
                {
                    title: 'Accounts Linked',
                },
                {
                    title: 'User',
                },
            ],
        };

        const rows = connections.map(connection => {
            const rowProperty = {
                selected: selectedConnections.includes(connection),
                deleted: removedConnections.includes(connection),
                edited:
                    updatedConnections.filter(filteredConnection => filteredConnection.id === connection.id).length ===
                    1,
                disabled: disableConnectionSelect === true,
            };

            return {
                onClick: onConnectionSelect,
                keyValue: `domains__${connection.id}`,
                dataValue: connection.id,
                rowProperty,
                columns: [
                    {
                        copy: connection.name,
                    },
                    {
                        copy: connection.accounts.length,
                    },
                    {
                        copy: connection.user,
                    },
                ],
            };
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    };

    const Render = () => {
        if (isLoading) {
            return (
                <div className="modal__side-panel__manage-domains">
                    <RenderModalNavigation />
                    <h2>Pinterest</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (pageError) {
            return (
                <div className="modal__side-panel__manage-domains">
                    <RenderModalNavigation />

                    <h2>Pinterest</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__pinterest">
                <RenderModalNavigation />

                <h2>Pinterest</h2>
                <p>
                    This is where you can manage your Pinterest integration with the Cubed Platform.
                    {/* including creating and removing connections as well as managing the accounts imported into Cubed. */}
                </p>
                {/* <p>
                    Admins can only edit connections belonging to them but can delete any connections. API users can
                    only manage their connections. Both Admins and API Users can create new connections. For more help
                    see <a href="https://tag.docs.withcubed.com/onboarding/api-integrations/">api integrations</a>.
                </p> */}
                <div className="modal__side-panel__pinterest">
                    <RenderTable />
                    <InputButtonWrapper>
                        <InputButton value="CREATE" onClick={onCreateClick} />
                        <InputButton value="EDIT" disabled={editButtonDisabled} onClick={onEditClick} />
                        <InputButton
                            buttonTheme={removeButtonState && ButtonThemes.Red}
                            value={removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                            disabled={removeButtonDisabled}
                            onClick={onDeleteConnection}
                        />
                    </InputButtonWrapper>
                </div>
                <RenderAccordion />
            </div>
        );
    };

    return <Render />;
};

export default LayoutModalPinterestAPI;
