import React, { createContext, useState } from 'react';

// Fields
import { ATTRIBUTED_PATH, PRODUCT } from '../../configurations/fields';

// Types
import {
    DropdownOption,
    RequestFilter,
    SectionDashboardRequest,
    SelectOptionsRequest,
    WidgetMenuObj,
    WidgetMenuSectionMenu,
} from '../../section-dashboard/types';
import { CubedField } from '../../types';

// Config
import { pageTypeDropdownRequest } from '../../section-dashboard/configurations/page-type-dropdown-options';

// Query
import { goalsDropdownQuery } from '../../section-dashboard/queries';
import { contentDetailGoalsQuery, tierTwoContentDropdownInfiniteQuery, pathDropdownInfiniteQuery } from '../queries';

// Hooks
import useWidgetSectionQuerySelectInfinite from '../../section-dashboard/widgets/menus/hooks/use-widget-section-query-select-infinite';
import useWidgetMenuSectionMultiSelect from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-multi-select';
import useWidgetMenuSection from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section';
import useWidgetMenuSectionQuerySelect from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-query-select';

type CurrentFilters = {
    contentType: DropdownOption & { field: CubedField };
    tierTwoType?: string;
    request: SectionDashboardRequest;
};

export interface ContentDetailDashboardMenuContextValues {
    fieldToView: DropdownOption & { field: CubedField };
    handleFieldToViewChange: (option: string) => void;
    tierOneTypeDropdownOptions: SelectOptionsRequest;
    selectedTierOneType: DropdownOption & { field: CubedField };
    handleTierOneTypeChange: (option: string) => void;
    currentTierOneTypeField: CubedField;
    tierTwoMenu: WidgetMenuSectionMenu;
    tierTwoMenuCombinedRequests: WidgetMenuSectionMenu;
    onAddFilterClick: () => void;
    currentFilters: CurrentFilters[];
    setCurrentFilters: (filters: CurrentFilters[]) => void;
    goalsMenu: WidgetMenuObj;
    tierTwoAndGoalsMenuRequestMerge: SectionDashboardRequest;
    goalOverviewNormalisedValuesMenu: WidgetMenuObj;
}

type ContentDetailDashboardMenuContextProps = {
    children: JSX.Element;
};

export const ContentDetailDashboardMenuContext = createContext<ContentDetailDashboardMenuContextValues>(
    {} as ContentDetailDashboardMenuContextValues
);

const ContentDetailDashboardMenuProvider = ({ children }: ContentDetailDashboardMenuContextProps) => {
    const tierOneTypeDropdownOptions = pageTypeDropdownRequest;

    // Field To View
    const [fieldToView, setFieldToView] = useState(tierOneTypeDropdownOptions.options[2]);
    const handleFieldToViewChange = (selectedOption: string) => {
        const selectedFieldToView = tierOneTypeDropdownOptions.options.filter(
            option => option.value === selectedOption
        )[0];
        setFieldToView(selectedFieldToView);
    };

    // Tier One Type Dropdown
    const [selectedTierOneType, setSelectedTierOneType] = useState(tierOneTypeDropdownOptions.options[0]);
    const [currentTierOneTypeField, setCurrentTierOneTypeField] = useState(tierOneTypeDropdownOptions.options[0].field);

    const handleTierOneTypeChange = (selectedOption: string) => {
        const selectedTierOne = tierOneTypeDropdownOptions.options.filter(option => option.value === selectedOption)[0];
        setSelectedTierOneType(selectedTierOne);
        setCurrentTierOneTypeField(selectedTierOne.field);
    };

    // Tier Two Type Dropdown
    const tierTwoMenu = useWidgetSectionQuerySelectInfinite({
        query: tierTwoContentDropdownInfiniteQuery({
            field: selectedTierOneType.field,
            offset: 0,
            limit: 200,
        }),
        dropdownField: selectedTierOneType.field,
        includeAllOption: false,
        responsive: false,
        label: 'Tier Two Type',
        enableSearch: false,
    });

    // Current Filters
    const [currentFilters, setCurrentFilters] = useState<CurrentFilters[]>([]);

    const onAddFilterClick = () => {
        setCurrentFilters([
            ...currentFilters,
            {
                contentType: selectedTierOneType,
                tierTwoType: tierTwoMenu?.request?.sectionFilters
                    ? tierTwoMenu.request.sectionFilters[0].value
                    : undefined,
                request: tierTwoMenu.request,
            },
        ]);
    };

    // Goals Dropdown
    const goalsMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionMultiSelect({
                        query: contentDetailGoalsQuery,
                        dropdownField: PRODUCT,
                        label: 'Goals',
                    }),
                ],
            },
        ],
    });

    const currentSectionFilters = currentFilters.map(filter => filter.request.sectionFilters);

    const tierTwoMenuCombinedRequests = {
        ...tierTwoMenu,
        request: {
            ...tierTwoMenu.request,
            sectionFilters: [...((currentSectionFilters && currentSectionFilters.flat()) || [])] as RequestFilter[],
        },
    };

    const tierTwoAndGoalsMenuRequestMerge = {
        enabled: tierTwoMenu.request.enabled,
        showEmpty: goalsMenu.request.showEmpty,
        sectionFilters: [
            ...(((currentSectionFilters && currentSectionFilters.flat()) || []) as RequestFilter[]),
            ...(goalsMenu.request.sectionFilters ?? []),
        ],
    };

    // Goal Overview Normalised Values Menu
    const goalOverviewNormalisedValuesMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetSectionQuerySelectInfinite({
                        query: pathDropdownInfiniteQuery({
                            offset: 0,
                            limit: 20,
                        }),
                        dropdownField: ATTRIBUTED_PATH,
                        includeAllOption: true,
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: goalsDropdownQuery,
                        dropdownField: PRODUCT,
                        includeAllOption: true,
                    }),
                ],
            },
        ],
    });

    return (
        <ContentDetailDashboardMenuContext.Provider
            value={{
                // Field to View
                fieldToView,
                handleFieldToViewChange,

                // Tier One Type Dropdown
                tierOneTypeDropdownOptions,
                selectedTierOneType,
                handleTierOneTypeChange,
                currentTierOneTypeField,

                // Tier Two Type Dropdown
                tierTwoMenu,
                tierTwoMenuCombinedRequests,

                // Current Filters
                onAddFilterClick,
                currentFilters,
                setCurrentFilters,

                // Goals Dropdown
                goalsMenu,
                tierTwoAndGoalsMenuRequestMerge,

                goalOverviewNormalisedValuesMenu,
            }}
        >
            {children}
        </ContentDetailDashboardMenuContext.Provider>
    );
};

export default ContentDetailDashboardMenuProvider;
