// import styled from "styled-components"
import { DefaultTheme } from 'styled-components';
import { palette } from './palette';

export const theme: DefaultTheme = {
    name: 'light',
    textFontFamily: `'Work Sans', sans-serif`,
    colours: {
        black: '#000000',
        borderGrey: '#e4e4e4',
        cubedOrange: '#ff7300',
        danger: '#d2004a',
        dark: '#212121',
        darkGrey: '#8a8a8a',
        darkerGrey: '#4d4d4d',
        midGrey: '#bfbfbf',
        focusColour: '#6ad9eb',
        green: '#4caf50',
        hoverGrey: '#eeeeee',
        info: '#6ad9eb',
        inputColour: '#e9f0f5',
        light: '#eeeeee',
        lightGrey: '#f1f1f1',
        link: '#58b5c4',
        offBlack: '#0a0a0a',
        offBlackLighter: '#1e1c1c',
        offLight: '#f0f0f0',
        orange: '#ff9800',
        primary: '#58b5c4',
        primaryLight: '#aad2f3',
        primaryLighter: '#cae3f6',
        red: '#d2004a',
        secondary: ' #dadada',
        selectionGrey: '#e3e7e7',
        shadowGrey: '#cbcbcb',
        success: '#4caf50',
        textDark: '#212121',
        textDefault: '#6f6f6f',
        textLight: '#ffffff',
        warning: '#ff9800',
        white: '#ffffff',
        whiteBg: '#ffffff',
        lightBlue: '#c0eeff',
        skyBlue: '#75daff',
    },
    boxShadow: level => {
        if (level === 1) {
            return `box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);`;
        } else if (level === 2) {
            return `box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);`;
        } else if (level === 3) {
            return `box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);`;
        } else if (level === 4) {
            return `box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);`;
        } else if (level === 5) {
            return `box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);`;
        } else if (level === 0) {
            return `box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.05);`;
        } else if (level === 0.5) {
            return `box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.18);`;
        }
    },
    dropShadow: (xLength, yLength, blur, spread, colour) => {
        return `
        -webkit-box-shadow: ${xLength} ${yLength} ${blur} ${spread} ${colour};
        -moz-box-shadow: ${xLength} ${yLength} ${blur} ${spread} ${colour};
        box-shadow: ${xLength} ${yLength} ${blur} ${spread} ${colour};
    `;
    },
    no_select: () => {
        return `
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    `;
    },
    scrollbar: (width, height) => {
        return `
            &::-webkit-scrollbar {
                width: ${width}px;
                height: ${height}px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0.12);
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0.26);
            }
            &--dark::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.75);
            }
        `;
    },
    components: {
        message: {
            textColor: '#0a0a0a',
            icon: {
                warningColor: '#f68c1f',
                errorColor: '#d2004a',
                infoColor: '#000000',
                loadingColor: 'rgba(0, 0, 0, 0.2)',
            },
        },
    },
    filterBar: {
        backgroundColor: '#ffffff',
        button: {
            labelColor: '#4d4d4d',
            labelFontWeight: '600',
            backgroundColor: '#f1f1f1',
            borderHover: '#bfbfbf',
            iconColor: '#000000',
        },
        channelSignpost: {
            textColor: '#6f6f6f',
            tierTextColor: '#000000',
            tierFontWeight: '600',
        },
    },
    datePicker: {
        backgroundColor: '#ffffff',
        day: {
            textColor: '#1d2429',
            textColorDisabled: '#aeb9bf;',
            textColorPassive: '#d5dce0',
            textColorSelected: '#ffffff',
            disabledBackgroundColor: 'rgb(248, 248, 248)',
        },
        dateDisplay: {
            textColor: '#7d888d',
            backgroundColor: 'rgb(239, 242, 247)',
        },
        definedRanges: {
            textColor: '#6f6f6f',
            borderColor: '#eff2f7',
            backgroundColorHover: '#f1f1f1',
        },
        monthAndYearPicker: {
            textColor: '#3e484f',
            optionTextColor: '#3e484f',
            backgroundColor: '#ffffff',
        },
        nextPrevButton: {
            arrowColorNext: 'transparent transparent transparent rgb(52, 73, 94)',
            arrowColorPrev: 'transparent rgb(52, 73, 94) transparent transparent',
            backgroundColor: '#eff2f7',
            backgroundColorHover: '#e1e7f0',
        },
    },
    sectionDashboard: {
        grid: {
            row: {
                height: '160px',
            },
            breakpoints: {
                xl: '1440px',
                lg: '1240px',
                md: '905px',
                sm: '600px',
                xs: '0px',
            },
        },
        section: {
            headingTextColor: '#1e1c1c',
            headingFontWeight: '600',
            bodyTextColor: 'rgba(0, 0, 0, 0.6)',
            backgroundColor: '#ffffff',
            iconColor: '#ff7300',
            iconColorHover: '#ffad33',
            sectionMenu: {
                select: {
                    textColor: '#6f6f6f',
                    labelColor: '#1e1c1c',
                    labelFontWeight: '600',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#ffffff',
                    textColorEmpty: '#bfbfbf',
                    optionLabelColorEmpty: '#bfbfbf',
                    skeletonColor: 'rgba(0, 0, 0, 0.1)',
                    actionButton: {
                        borderColor: '#bfbfbf',
                        borderColorDisabled: '#f1f1f1',
                        textColorDisabled: '#bfbfbf',
                        iconColor: '#bfbfbf',
                    },
                },
            },
        },
        widget: {
            base: {
                backgroundColor: '#ffffff',
                textColorNoData: '#000000',
                borderColor: 'rgba(0, 0, 0, 0.1)',
                shadow: ' 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.1)',
                footer: {
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                },
                link: {
                    textColor: 'rgba(0, 0, 0, 0.6)',
                    fontWeight: '600',
                    fontWeightDropdown: '500',
                    focus: {
                        color: '#000000',
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    },
                    textPlaceholder: 'rgba(0, 0, 0, 0.1)',
                },
                loader: {
                    color: 'rgba(0, 0, 0, 0.2)',
                },
            },
            menu: {
                backgroundHover: 'rgba(0, 0, 0, 0.05)',
                textColor: 'rgba(0, 0, 0, 0.6)',
                textColorHover: '#0a0a0a',
                textColorDisabled: 'rgba(0, 0, 0, 0.3)',
                separatorColor: 'rgba(0, 0, 0, 0.1)',
                triggerBackgroundColor: '#ffffff',
                backgroundColor: '#ffffff',
                labelHover: '#1e1c1c',
                shadow: '0px 1px 25px -1px rgba(0, 0, 0, 0.1), 0px 1px 12px rgba(0, 0, 0, 0.1)',
                fontWeight: '500',
                skeletonColor: 'rgba(0, 0, 0, 0.1)',
                checkbox: {
                    iconBackgroundColorChecked: '#ffffff',
                    iconColor: '#000000',
                    iconColorHover: '#ffffff',
                },
                radio: {
                    iconColor: '#ffffff',
                    iconHover: '#000000',
                },
                title: {
                    fontWeight: '600',
                },
                pagination: {
                    textColor: '#6f6f6f',
                    iconHover: '#000000',
                    fontWeight: '400',
                    footerFontWeight: '600',
                },
            },
            dialog: {
                textColor: '#6f6f6f',
                backgroundColor: '#ffffff',
                overlayColor: 'rgba(0, 0, 0, 0.1)',
                shadow: '0px 1px 25px -1px rgba(0, 0, 0, 0.1), 0px 1px 12px rgba(0, 0, 0, 0.1)',
                titleFontWeight: '600',
                input: {
                    backgroundColor: '#e9f0f5',
                    backgroundColorDisabled: '#f1f1f1',
                },
                button: {
                    textColor: 'rgba(0, 0, 0, 0.6)',
                    disabledTextColor: '#bfbfbf',
                    backgroundColor: '#ffffff',
                    fontWeight: '500',
                },
                closeButton: {
                    backgroundColor: '#1e1c1c',
                    backgroundColorDisabled: 'rgba(0, 0, 0, 0.05)',
                    textColor: '#ffffff',
                    textColorSecondary: 'rgba(0, 0, 0, 0.6)',
                    textColorDisabled: '#bfbfbf',
                    fontWeight: '500',
                },
            },
            variance: {
                textColor: '#6f6f6f',
                textColorPositive: '#4caf50',
                textColorNegative: '#d2004a',
            },
            table: {
                textColor: '#6f6f6f',
                header: {
                    backgroundColor: '#f1f1f1',
                    borderColor: 'rgba(0, 0, 0, 0.05)',
                    iconColor: 'rgba(0, 0, 0, 0.6)',
                    fontWeight: '600',
                },
                cell: {
                    borderColor: '#f1f1f1',
                    backgroundColor: '#ffffff',
                    secondaryTextColor: '#bfbfbf',
                },
                column: {
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                },
                totals: {
                    backgroundColor: '#f1f1f1',
                },
                cardTable: {
                    textColor: '#6f6f6f',
                    backgroundColor: '#ffffff',
                    borderColor: '#e3e7e7',
                    backgroundColorOdd: '#f1f1f1',
                },
            },
            bigNumber: {
                textColor: '#212121',
                backgroundColor: '#f1f1f1',
                varianceBackgroundColor: 'rgba(255, 255, 255, 0.5)',
                bigNumberBar: {
                    textColor: '#1e1c1c',
                    titleFontWeight: '500',
                },
            },
            pie: {
                dataLabelColor: '#4d4d4d',
                ratioFontWeight: '600',
                ratioColor: '#bfbfbf',
                ratioDescriptionColor: '#4d4d4d',
            },
            barGraph: {
                bigNumber: {
                    backgroundColor: '#f1f1f1',
                    textColor: '#0a0a0a',
                },
            },
            barGraphComparison: {
                label: {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    color: '#6f6f6f',
                },
            },
            funnelChart: {
                legendColor: '#000000',
                legendPercentageBackgroundColor: '#ffffff',
            },
            lineGraph: {
                bigNumber: {
                    textColor: '#0a0a0a',
                },
            },
            packedBubbleChart: {
                bigNumber: {
                    textColor: '#0a0a0a',
                },
            },
            sparkLine: {
                varianceTextColor: '#6f6f6f',
                textColor: '#ffffff',
                totalsBackgroundColor: 'rgba(255,255,255,0.15)',
                fontWeight: '400',
                percentage: {
                    backgroundColor: '#ffffff',
                },
            },
            stackedArea: {
                title: {
                    textColor: '#1e1c1c',
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    fontWeight: '600',
                },
            },
            venn: {
                title: {
                    textColor: '#6f6f6f',
                },
                bigNumber: {
                    textColor: '#0a0a0a',
                },
            },
            pieTableComparison: {
                titleColor: '#6f6f6f',
            },
            nestedDonutTableComparison: {
                titleColor: '#1e1c1c',
                titleFontWeight: '600',
            },
            textBox: {
                textColorDefault: '#6f6f6f',
                textColor: '#1e1c1c',
            },
        },
        tooltip: {
            iconColor: '#ffffff',
            iconBackgroundColor: '#bfbfbf',
            triggerHoverColor: '#1e1c1c',
            contrastColor1: '#1e1c1c',
            contrastColor2: '#ffffff',
            contrastColor3: '#ff7300',
        },
        validation: {
            backgroundColor: '#ffffff',
            shadowColor: '#cbcbcb',
        },
        colour: {
            default: {
                gradientStart: '#ffffff',
                gradientEnd: '#ffffff',
                splineLine: palette.sky['200'],
                splineGradientStart: palette.sky['300'],
                splineGradientEnd: palette.sky['300'] + '00',
                solidGraphColour: palette.sky['300'],
            },
            red: {
                gradientStart: palette.red['400'],
                gradientEnd: palette.red['500'],
                splineLine: palette.red['200'],
                splineGradientStart: palette.red['300'],
                splineGradientEnd: palette.red['300'] + '00',
                solidGraphColour: palette.red['300'],
            },
            orange: {
                gradientStart: palette.orange['400'],
                gradientEnd: palette.orange['500'],
                splineLine: palette.orange['200'],
                splineGradientStart: palette.orange['300'],
                splineGradientEnd: palette.orange['300'] + '00',
                solidGraphColour: palette.orange['300'],
            },
            amber: {
                gradientStart: palette.amber['400'],
                gradientEnd: palette.amber['500'],
                splineLine: palette.amber['200'],
                splineGradientStart: palette.amber['300'],
                splineGradientEnd: palette.amber['300'] + '00',
                solidGraphColour: palette.amber['200'],
            },
            yellow: {
                gradientStart: palette.yellow['400'],
                gradientEnd: palette.yellow['500'],
                splineLine: palette.yellow['200'],
                splineGradientStart: palette.yellow['300'],
                splineGradientEnd: palette.yellow['300'] + '00',
                solidGraphColour: palette.yellow['300'],
            },
            lime: {
                gradientStart: palette.lime['400'],
                gradientEnd: palette.lime['500'],
                splineLine: palette.lime['200'],
                splineGradientStart: palette.lime['300'],
                splineGradientEnd: palette.lime['300'] + '00',
                solidGraphColour: palette.lime['300'],
            },
            green: {
                gradientStart: palette.green['400'],
                gradientEnd: palette.green['500'],
                splineLine: palette.green['200'],
                splineGradientStart: palette.green['300'],
                splineGradientEnd: palette.green['300'] + '00',
                solidGraphColour: palette.green['300'],
            },
            emerald: {
                gradientStart: palette.emerald['400'],
                gradientEnd: palette.emerald['500'],
                splineLine: palette.emerald['200'],
                splineGradientStart: palette.emerald['300'],
                splineGradientEnd: palette.emerald['300'] + '00',
                solidGraphColour: palette.emerald['300'],
            },
            teal: {
                gradientStart: palette.teal['400'],
                gradientEnd: palette.teal['500'],
                splineLine: palette.teal['200'],
                splineGradientStart: palette.teal['300'],
                splineGradientEnd: palette.teal['300'] + '00',
                solidGraphColour: palette.teal['200'],
            },
            cyan: {
                gradientStart: palette.cyan['400'],
                gradientEnd: palette.cyan['500'],
                splineLine: palette.cyan['200'],
                splineGradientStart: palette.cyan['300'],
                splineGradientEnd: palette.cyan['300'] + '00',
                solidGraphColour: palette.cyan['300'],
            },
            sky: {
                gradientStart: palette.sky['400'],
                gradientEnd: palette.sky['500'],
                splineLine: palette.sky['200'],
                splineGradientStart: palette.sky['300'],
                splineGradientEnd: palette.sky['300'] + '00',
                solidGraphColour: palette.sky['300'],
            },
            blue: {
                gradientStart: palette.blue['400'],
                gradientEnd: palette.blue['500'],
                splineLine: palette.blue['200'],
                splineGradientStart: palette.blue['300'],
                splineGradientEnd: palette.blue['300'] + '00',
                solidGraphColour: palette.blue['300'],
            },
            indigo: {
                gradientStart: palette.indigo['400'],
                gradientEnd: palette.indigo['500'],
                splineLine: palette.indigo['200'],
                splineGradientStart: palette.indigo['300'],
                splineGradientEnd: palette.indigo['300'] + '00',
                solidGraphColour: palette.indigo['300'],
            },
            violet: {
                gradientStart: palette.violet['400'],
                gradientEnd: palette.violet['500'],
                splineLine: palette.violet['200'],
                splineGradientStart: palette.violet['300'],
                splineGradientEnd: palette.violet['300'] + '00',
                solidGraphColour: palette.violet['300'],
            },
            purple: {
                gradientStart: palette.purple['400'],
                gradientEnd: palette.purple['500'],
                splineLine: palette.purple['200'],
                splineGradientStart: palette.purple['300'],
                splineGradientEnd: palette.purple['300'] + '00',
                solidGraphColour: palette.purple['200'],
            },
            fuchsia: {
                gradientStart: palette.fuchsia['400'],
                gradientEnd: palette.fuchsia['500'],
                splineLine: palette.fuchsia['200'],
                splineGradientStart: palette.fuchsia['300'],
                splineGradientEnd: palette.fuchsia['300'] + '00',
                solidGraphColour: palette.fuchsia['300'],
            },
            pink: {
                gradientStart: palette.pink['400'],
                gradientEnd: palette.pink['500'],
                splineLine: palette.pink['200'],
                splineGradientStart: palette.pink['300'],
                splineGradientEnd: palette.pink['300'] + '00',
                solidGraphColour: palette.pink['300'],
            },
            rose: {
                gradientStart: palette.rose['400'],
                gradientEnd: palette.rose['500'],
                splineLine: palette.rose['200'],
                splineGradientStart: palette.rose['300'],
                splineGradientEnd: palette.rose['300'] + '00',
                solidGraphColour: palette.rose['200'],
            },
            slate: {
                gradientStart: palette.slate['400'],
                gradientEnd: palette.slate['500'],
                splineLine: palette.slate['200'],
                splineGradientStart: palette.slate['300'],
                splineGradientEnd: palette.slate['300'] + '00',
                solidGraphColour: palette.slate['300'],
            },
        },
    },
    transition: '0.2s ease-in-out;',
    tabGradient: 'linear-gradient(#eaeaea, #e3e3e3, #e3e3e3, #e3e3e3, #cdcdd1);',
    skeletonLoader: {
        animationDuration: '5s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: 'skeletonGradient',
        animationTimingFunction: 'linear',
        background: '#f1f1f1',
        backgroundSize: '100vw 100vw',
    },
    skeletonLoaderGradient: {
        animationDuration: '5s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: 'skeletonGradient',
        animationTimingFunction: 'linear',
        background: 'linear-gradient(to right, #f1f1f1, #cbcbcb, #f1f1f1)',
        backgroundSize: '100vw 100vw',
    },
    buttons: {
        primary: {
            backgroundColour: 'rgba(10, 10, 10, 1)',
            backgroundColourDisabled: 'rgba(10, 10, 10, 0.4)',
            color: 'rgba(255, 255, 255, 1)',
            colorDisabled: 'rgba(255, 255, 255, 1)',
            fontWeight: '500',
        },
        secondary: {
            backgroundColour: 'transparent',
            backgroundColourDisabled: 'transparent',
            color: 'rgba(111, 111, 111, 1)',
            colorDisabled: 'rgba(111, 111, 111, 0.8)',
            fontWeight: '500',
        },
        secondaryLight: {
            backgroundColour: 'transparent',
            backgroundColourDisabled: 'transparent',
            color: 'rgba(238, 238, 238, 1)',
            colorDisabled: 'rgba(238, 238, 238, 1)',
            fontWeight: '500',
        },
        red: {
            backgroundColour: 'rgba(210, 0, 74, 1)',
            backgroundColourDisabled: 'rgba(210, 0, 74, 0.4)',
            color: 'rgba(255, 255, 255, 1)',
            colorDisabled: 'rgba(255, 255, 255, 1)',
            fontWeight: '500',
        },
        redSecondary: {
            backgroundColour: 'transparent',
            backgroundColourDisabled: 'transparent',
            color: 'rgba(210, 0, 74, 1)',
            colorDisabled: 'rgba(210, 0, 74, 0.4)',
            fontWeight: '500',
        },
        green: {
            backgroundColour: 'rgba(76, 175, 80, 1)',
            backgroundColourDisabled: 'rgba(76, 175, 80, 0.4)',
            color: 'rgba(255, 255, 255, 1)',
            colorDisabled: 'rgba(255, 255, 255, 1)',
            fontWeight: '500',
        },
        greenSecondary: {
            backgroundColour: 'transparent',
            backgroundColourDisabled: 'transparent',
            color: 'rgba(76, 175, 80, 1)',
            colorDisabled: 'rgba(76, 175, 80, 0.4)',
            fontWeight: '500',
        },
    },
    forms: {
        inputs: {
            backgroundColor: '#e9f0f5',
            backgroundColorDisabled: '#f1f1f1',
            textColor: '#6f6f6f',
            textColorDisabled: '#6f6f6f',
            placeHolderColor: '#8a8a8a',
            accentColor: '#1e1c1c',
            accentColorDisabled: '#bfbfbf',
            inputToggle: {
                backgroundColor: '#8a8a8a',
                backgroundColorChecked: '#1e1c1c',
                backgroundColorDisabled: '#bfbfbf',
                indicatorColor: '#ffffff',
                indicatorColorDisabled: '#ffffff',
            },
            inputCheckbox: {
                accentColor: '#1e1c1c',
            },
        },
        labels: {
            color: '#6f6f6f',
        },
        errorMessage: {
            textColor: '#d2004a',
        },
        sections: {
            titleColor: '#0a0a0a',
        },
    },
    popover: {
        trigger: {
            color: '#6f6f6f',
        },
        content: {
            color: '#6f6f6f',
            backgroundColor: '#ffffff',
            shadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;',
        },
    },
    dialog: {
        trigger: {
            backgroundColor: {
                primary: '#0a0a0a',
                loading: '#bfbfbf',
                warning: '#d2004a',
            },
        },
        content: {
            color: '#6f6f6f',
            backgroundColor: '#ffffff',
            shadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
        },
    },
    dashboardBuilder: {
        container: {
            color: '#6f6f6f',
            backgroundColor: '#ffffff',
        },
        createAWidget: {
            preview: {
                backgroundColor: '#f1f1f1',
            },
        },
        createADashboard: {
            preview: {
                borderColor: '#ffffff',
                removeButton: {
                    color: '#6f6f6f',
                    backgroundColor: '#ffffff',
                },
            },
            sidebar: {
                dashboardTitleColor: '#1e1c1c',
                droppable: {
                    color: '#6f6f6f',
                    backgroundColor: '#ffffff',
                },
                draggable: {
                    color: '#6f6f6f',
                    borderColor: '#e4e4e4',
                    backgroundColor: '#ffffff',
                },
                sections: {
                    color: '#6f6f6f',
                    titleColor: '#1e1c1c',
                    titleFontWeight: '600',
                },
            },
        },
        library: {
            libraryList: {
                categoryTitleColor: '#0a0a0a',
                categoryTitleFontWeight: '600',
                listItemBorderColor: '#e4e4e4',
                listItemTextColor: '#6f6f6f',
                listItemCellTitleColor: '#bfbfbf',
                listItemTitleColor: '#0a0a0a',
            },
        },
    },
};

export default theme;
