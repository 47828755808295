import { CubedResource } from '../../types';
import * as resources from '../../configurations/resources';

const getResource = (resource: string): CubedResource | undefined => {
    const selectedResource = Object.entries(resources).filter(res => {
        const id = res[1].id;
        return id === (resource as unknown as string);
    });

    if (selectedResource.length > 0) {
        return selectedResource[0][1] as CubedResource;
    }
};

export default getResource;
