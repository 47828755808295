import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useSectionDashboard } from '../../context/section-dashboard-context';
import { largeChartOptions } from '../../configurations/widget-line-graph-config';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Colour Helpers
import { newColourAsRgba } from '../../../helpers/colours';
import tinycolor from 'tinycolor2';

// Components
import WidgetBase from '../base/widget-base';
import WidgetLineGraphBigNumber from '../big-numbers/widget-line-graph-big-number';

// Types
import { LineGraphData } from '../../types';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export type WidgetLineGraphProps = {
    title: string;
    data: LineGraphData;
    href?: string;
    showFillColour?: boolean;
};

// Component
const WidgetLineGraph = ({ title, data, href, showFillColour }: WidgetLineGraphProps) => {
    const theme = useTheme();
    const { width, height } = useSectionDashboard();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    if (width === 1 || height === 1) {
        const numberOfBoxes = Math.max(width, height);
        const dataToDisplay = data.series.slice(0, numberOfBoxes);
        const direction = width > height ? 'horizontal' : 'vertical';
        return (
            <WidgetBase title={title} href={href}>
                <WidgetLineGraphBigNumber data={dataToDisplay} direction={direction} width={width} />
            </WidgetBase>
        );
    } else {
        let options = largeChartOptions(theme);
        const series = data.series;

        // set y axis labels
        if (height >= 3) {
            options = {
                ...options,
                yAxis: {
                    ...options.yAxis,
                    title: {
                        ...options.yAxis.title,
                        text: data.metric,
                    },
                },
            };
        }

        if (series.length < 2) {
            options = {
                ...options,
                legend: {
                    ...options.legend,
                    enabled: false,
                },
            };
        }

        const highchartsSeries = series.map(series => {
            let fillColor = null;
            if (showFillColour) {
                fillColor = {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, newColourAsRgba(tinycolor(series.colour), 0.4)],
                        [1, newColourAsRgba(tinycolor(series.colour), 0)],
                    ],
                };
            }
            return {
                name: series.name,
                data: series.data,
                marker: { fillColor: newColourAsRgba(tinycolor(series.colour), 1) },
                color: newColourAsRgba(tinycolor(series.colour), 0.8),
                fillColor,
            };
        });

        return (
            <WidgetBase title={title} href={href}>
                <StyledContainer>
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...options, series: highchartsSeries }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                </StyledContainer>
            </WidgetBase>
        );
    }
};

export default WidgetLineGraph;
