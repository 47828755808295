import React from 'react';
import styled from 'styled-components';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePostResourceWithPayload from '../../../react-query/hooks/use-post-resource-with-payload';

// Config
import { CONFIG_SEOGD_COMPETITOR, CONFIG_SEOGD_MARKET } from '../../../configurations/resources-config';

// Types
import { ConfigDataSuccess } from '../../../react-query/types';
import { FieldValues } from 'react-hook-form';
import { ButtonThemes } from '../../../enums/button-themes';

// Redux
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../redux/actions/notification';
import { setModal } from '../../../redux/actions/modal';

// Helpers
import { generatePath } from '../../../helpers/request-builder';

// Components
import CompetitorForm from './forms/competitor-form';
import Form from '../../../components/forms/form';
import ModalNavigation from '../../../components/modal-navigation';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const LayoutModalAddCompetitors = () => {
    const dispatch = useDispatch();
    const marketQuery = useFetchResource({
        resource: CONFIG_SEOGD_MARKET,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
            { key: 'order_by', value: 'country' },
        ],
        select: (data: ConfigDataSuccess) =>
            data.objects.map(market => {
                return {
                    label: market.country,
                    value: market.id,
                };
            }),
    });

    const competitorPostMutation = usePostResourceWithPayload({
        resource: CONFIG_SEOGD_COMPETITOR,
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'Competitor added successfully', type: 'success' }));
            dispatch(setModal('ManageCompetitors', {}));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was a problem adding this competitor. Please try again later.',
                    type: 'error',
                })
            );
        },
    });

    const handleSubmit = (data: FieldValues) => {
        competitorPostMutation.mutate({
            brand_name: data.brandName,
            domain: data.domainName,
            market: generatePath('config', 'seogd-market', String(data.market)),
        });
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(setModal('ManageCompetitors', {})),
                        disabled: false,
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Add Competitor</h2>
            <p>Add the competitors you'd like us to use to further optimize the automated KWR process.</p>

            <StyledFormContainer>
                <Form
                    status={marketQuery.status === 'pending' ? 'loading' : 'enabled'}
                    defaultValues={{ brandName: '', domainName: '', market: '' }}
                    onSubmit={handleSubmit}
                >
                    <CompetitorForm markets={marketQuery.data || []} />
                    <Form.Footer>
                        <Form.InputButton type="submit" value="Save" loading={competitorPostMutation.isPending} />
                        <Form.InputButton type="reset" value="Clear" buttonTheme={ButtonThemes.Secondary} />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </>
    );
};

export default LayoutModalAddCompetitors;
