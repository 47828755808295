import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import List from '../components/list';
import SidebarMenu from '../components/sidebar-menu';
import SearchBreadcrumbsWidget from '../breadcrumbs/widgets/search-breadcrumbs';

import useTaggingGuideConfig from '../configurations/tagging-guide';

//  Core Dependencies
import { setDescription } from '../redux/actions/page-meta';

import AnimeJS from 'animejs';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RootState } from '../redux/store';
import IconArrow from '../components/icons/arrow';

import '../sass/components/icons/_icon-back-arrow.scss';

const StyledDocumentationHeader = styled.div`
    background-color: rgb(240, 240, 240);
    text-align: left;
    cursor: pointer;
    display: flex;
`;

const StyledH3 = styled.h3`
    margin-left: 10px;
    margin-right: 5px;
    margin-top: 0;
    font-size: 1.1em;
`;
const StyledBackArrow = styled.div`
    margin-left: 5px;
`;

type BodyContent = {
    type: string;
    keyValue: string;
    listType?: string;
    listItems?: string[];
    copy?: string;
    code?: string;
    language?: string;
};
type Configuration = {
    type: string;
    keyValue: string;
    title: string;
    bodyContent: BodyContent[];
};

const LayoutTaggingGuide = () => {
    const accountToken = useSelector((state: RootState) => state.account.token);
    const config = useTaggingGuideConfig();
    const [searchTerm, setSearchTerm] = useState('');
    const [visibleTaggingConfigurations, setVisibleTaggingConfigurations] = useState<Configuration[]>(config);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setDescription(''));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchHomeClick = () => {
        setVisibleTaggingConfigurations(config);
    };

    const onClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        const selectedKey = event.currentTarget.getAttribute('data-key');

        try {
            if (selectedKey !== '') {
                const itemInList = document.querySelector(`.list-item__body[data-key="${selectedKey}"]`);

                const itemInListPosition = (itemInList as HTMLElement)?.offsetTop - 100;
                const scrollElement =
                    window.document.scrollingElement || window.document.body || window.document.documentElement;

                AnimeJS({
                    targets: scrollElement,
                    scrollTop: itemInListPosition,
                    duration: 500,
                    easing: 'easeInOutQuad',
                });
            }
        } catch (error) {}
    };

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        const searchValueRegex = new RegExp(searchValue, 'i');
        const visibleConfigsAfterSearch: Configuration[] = [];

        config.forEach(item => {
            let bodyContentWithSearchTerm: BodyContent[] = [];

            item.bodyContent.forEach((content: BodyContent) => {
                if (
                    searchValueRegex.test(content.copy ?? '') ||
                    searchValueRegex.test(content.listItems?.join('') || '') ||
                    searchValueRegex.test(content.code ?? '')
                ) {
                    bodyContentWithSearchTerm.push(content);
                }
            });
            if (bodyContentWithSearchTerm.length > 0) {
                visibleConfigsAfterSearch.push({
                    type: item.type,
                    keyValue: item.keyValue,
                    title: item.title,
                    bodyContent: bodyContentWithSearchTerm,
                });
            }
        });

        setVisibleTaggingConfigurations(visibleConfigsAfterSearch);
        setSearchTerm(event.target.value);
    };

    return (
        <div className="account-configuration">
            <div className="account-configuration__sidebar-container">
                <div className="account-configuration__side-bar">
                    <SearchBreadcrumbsWidget
                        onSearchChange={onSearchChange}
                        homeOnClick={onSearchHomeClick}
                        searchValue={searchTerm}
                    />
                    {(visibleTaggingConfigurations.length > 0 && (
                        <>
                            <Link
                                style={{
                                    textDecoration: 'none',
                                    height: '100%',
                                    width: '100%',
                                    position: 'relative',
                                    display: 'block',
                                    color: 'rgb(111, 111, 111)',
                                }}
                                to={`/${accountToken + '/account-management'}`}
                            >
                                <StyledDocumentationHeader>
                                    <StyledBackArrow>
                                        <IconArrow className={'icon-back-arrow'} />
                                    </StyledBackArrow>
                                    <StyledH3>{'Back to documentation'}</StyledH3>
                                </StyledDocumentationHeader>
                            </Link>
                            <SidebarMenu
                                onClick={onClick}
                                rows={[
                                    {
                                        title: 'Tagging Implementation Guide',
                                        rows: visibleTaggingConfigurations,
                                    },
                                ]}
                            />
                        </>
                    )) ||
                        'No results found'}
                </div>
            </div>
            <div className="account-configuration__list">
                <List
                    rows={visibleTaggingConfigurations}
                    keyValue={'list-items'}
                    errorMessageOverride={''}
                    isLoading={undefined}
                    header={{}}
                />
            </div>
        </div>
    );
};

export default LayoutTaggingGuide;
