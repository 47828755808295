import React, { useEffect } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { NotificationMessageType } from '../enums/notification-types';
import { useAppDispatch } from '../redux/hooks';
import { removeNotification } from '../redux/actions/notification';

const handleBackgroundColor = (type: NotificationMessageType, theme: DefaultTheme) => {
    switch (type) {
        case NotificationMessageType.Success:
            return theme.colours.green;
        case NotificationMessageType.Warn:
            return theme.colours.cubedOrange;
        case NotificationMessageType.Error:
            return theme.colours.red;
        default:
            return theme.colours.primary;
    }
};

const StyledNotification = styled.div<{ type: NotificationMessageType }>`
    box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 10px 10px;
    border-radius: 2px;
    margin-bottom: 10px;
    background-color: ${props => handleBackgroundColor(props.type, props.theme)};
    animation: fade 1s ease-in 3s;
    animation-fill-mode: forwards;
    @keyframes fade {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;

const StyledNotificationCopy = styled.div`
    color: ${props => props.theme.colours.light};
    margin: 0 10px 0 10px;
`;

const StyledNotificationWrapper = styled.div`
    width: calc(100% - 110px);
`;

export type NotificationProps = {
    id: string;
    copy: string;
    type: NotificationMessageType;
};

export const Notification = ({ id, copy, type }: NotificationProps) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(
                removeNotification({
                    id: id,
                })
            );
        }, 6000);

        return () => clearTimeout(timer);
    });

    return (
        <StyledNotification type={type} data-testid="notification">
            <StyledNotificationWrapper>
                <StyledNotificationCopy>{copy}</StyledNotificationCopy>
            </StyledNotificationWrapper>
        </StyledNotification>
    );
};

export default Notification;
