import React from 'react';
import { useFormContext } from 'react-hook-form';
import validators from '../../../../components/forms/validation/validators';
import {
    hasCapitalLetter,
    hasLowerCaseLetter,
    hasNumber,
    hasPasswordValidCharacters,
    hasSpecialCharacter,
} from '../../../../helpers/validator';

// Components
import Form from '../../../../components/forms/form';
import PasswordRequirements from '../../../../components/password-requirements';

const FormUpdatePassword = () => {
    const { watch } = useFormContext();
    const formValues = watch();

    return (
        <Form.Body>
            <Form.Section>
                <Form.Field>
                    <Form.InputLabel htmlFor="oldPassword" label="Current Password" required={true} />
                    <Form.InputPassword name="oldPassword" validators={[validators.required]} />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="password" label="New Password" required={true} />
                    <Form.InputPassword name="password" validators={[validators.required, validators.isPassword]} />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="repeatPassword" label="Repeat New Password" required={true} />
                    <Form.InputPassword
                        name="repeatPassword"
                        validators={[validators.required, validators.passwordsMatch]}
                    />
                </Form.Field>

                <PasswordRequirements
                    hasCharacterLength={formValues.password.length >= 8}
                    hasCapitalLetter={hasCapitalLetter(formValues.password)}
                    hasLowercaseLetter={hasLowerCaseLetter(formValues.password)}
                    hasNumber={hasNumber(formValues.password)}
                    hasSpecialCharacter={hasSpecialCharacter(formValues.password)}
                    hasValidCharacter={hasPasswordValidCharacters(formValues.password)}
                />
            </Form.Section>
        </Form.Body>
    );
};

export default FormUpdatePassword;
