import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

// Types & ENUMS
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';

// Resources
import { PATH_CATEGORY_CONFIG } from '../../configurations/resources-config';

// Redux
import { useDispatch } from 'react-redux';
import { setModal, removeModal } from '../../redux/actions/modal';
import { removePopup, setPopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';

// Components
import ModalNavigation from '../../components/modal-navigation';
import SimpleTable from '../../components/tables/components/simple-table';
import LoadingSpinner from '../../components/loading-spinner';
import Message from '../../components/message';
import TablePaginationPageList from '../../components/tables/components/pagination/table-pagination-page-list';
import FormTextInput from '../../components/form-fields/form-text-input';
import FormCheckBoxInput from '../../components/form-fields/form-checkbox-input';
import InputButton from '../../components/inputs/input-button';

// React Query
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess } from '../../react-query/types';
import usePatchResource from '../../react-query/hooks/use-patch-resource';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';

const StyledTableActionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 6px;
`;

const StyledEditContainer = styled.div`
    margin-top: 20px;
    background-color: white;
    padding: 1px 20px 20px 20px;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
`;

type PageCategoryTableRow = {
    id: string;
    category: string;
    lookup_include: string;
    lookup_exclude: string;
    is_regex: boolean;
    resource_uri: string;
};

const PageLayout = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch();

    const modalNavigationButtons = [
        {
            value: 'CLOSE',
            onClick: () => dispatch(removeModal()),
            disabled: false,
            buttonTheme: ButtonThemes.Secondary,
        },
    ];

    return (
        <div>
            <ModalNavigation buttons={modalNavigationButtons} />
            <h2>Manage Page Categories</h2>
            <p>Manage your Page Categories by configuring your urls into categories using regex rules.</p>
            {children}
        </div>
    );
};

const LayoutModalManagePageCategories = () => {
    const dispatch = useDispatch();

    // State - Dropdown & Table
    const maxPerPage = 25;
    const [selectedTableRows, setSelectedTableRows] = useState<PageCategoryTableRow[]>([]);
    const [page, setPage] = useState(1);

    // State - Edit Form Fields
    const [category, setCategory] = useState('');
    const [lookupInclude, setLookupInclude] = useState('');
    const [lookupExclude, setLookupExclude] = useState('');
    const [isRegex, setIsRegex] = useState(false);

    // Queries
    const pageCategoryConfigData = useFetchResource<ConfigDataSuccess>({
        resource: PATH_CATEGORY_CONFIG,
        params: [
            { key: 'limit', value: maxPerPage },
            { key: 'offset', value: maxPerPage * (page - 1) },
        ],
        isPaginated: true,
    });

    const pageCategoryPatchMutation = usePatchResource({
        resource: PATH_CATEGORY_CONFIG,
        resourceId: selectedTableRows.length === 1 ? selectedTableRows[0].id : '',
        data: { category: category, lookup_include: lookupInclude, lookup_exclude: lookupExclude, is_regex: isRegex },
        handleOnSuccess: () => {
            setSelectedTableRows([]);
            dispatch(
                addNotification({
                    copy: 'Page Category updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your Page Category. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const pageCategoryDeleteMutation = useDeleteResource({
        resource: PATH_CATEGORY_CONFIG,
        resourceIds: selectedTableRows.map(category => category.id),
        handleOnSuccess: () => {
            setSelectedTableRows([]);
            dispatch(
                addNotification({
                    copy: 'Page Category deleted successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your Page Category. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    // Loading
    if (pageCategoryConfigData.status === 'pending') {
        return (
            <PageLayout>
                <LoadingSpinner />
            </PageLayout>
        );
    }

    // Error
    if (pageCategoryConfigData.status === 'error') {
        return (
            <PageLayout>
                <Message
                    copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email."
                    type="error"
                />
            </PageLayout>
        );
    }

    // Success
    if (pageCategoryConfigData.status === 'success') {
        // Event Handlers
        const handleChangePage = (_: string, value: number) => {
            setSelectedTableRows([]);
            setPage(value);
        };

        const handleSelectTableRow = (pageCategory: PageCategoryTableRow) => {
            let selectedAllTableRows = [...selectedTableRows];

            const indexToRemove = selectedAllTableRows.findIndex(
                item => item.category === pageCategory.category && item.id === pageCategory.id
            );

            if (indexToRemove !== -1) {
                selectedAllTableRows.splice(indexToRemove, 1);
            } else {
                selectedAllTableRows = [...selectedTableRows, pageCategory];
            }

            if (selectedAllTableRows.length === 1) {
                // Table fields
                setCategory(selectedAllTableRows[0].category as string);
                setLookupInclude(selectedAllTableRows[0].lookup_include as string);
                setLookupExclude(selectedAllTableRows[0].lookup_exclude as string);
                setIsRegex(selectedAllTableRows[0].is_regex as boolean);
            }

            setSelectedTableRows(selectedAllTableRows);
        };

        const handleEditCategory = (event: ChangeEvent<HTMLInputElement>) => {
            setCategory(event.target.value);
        };

        const handleEditLookupInclude = (event: ChangeEvent<HTMLInputElement>) => {
            setLookupInclude(event.target.value);
        };

        const handleEditLookupExclude = (event: ChangeEvent<HTMLInputElement>) => {
            setLookupExclude(event.target.value);
        };

        const handleEditIsRegex = () => {
            setIsRegex(!isRegex);
        };

        const handleOnSaveClick = () => {
            pageCategoryPatchMutation.mutate();
        };

        const handleOnCancelClick = () => {
            setSelectedTableRows([]);
        };

        const handleOnAddNewClick = () => {
            dispatch(setModal('AddPageCategories', {}));
        };

        const handleOnDeleteClick = () => {
            const configMessage =
                selectedTableRows.length === 1
                    ? `Are you sure you would like to delete ${selectedTableRows[0].category}?`
                    : `Are you sure you would like to delete these page categories?`;

            dispatch(
                setPopup({
                    title: 'Remove Page Categories',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: configMessage,
                    },
                    buttons: [
                        {
                            value: 'DELETE',
                            buttonTheme: ButtonThemes.Red,
                            onClick: () => {
                                pageCategoryDeleteMutation.mutate();
                                dispatch(removePopup());
                            },
                        },
                        {
                            value: 'CANCEL',
                            onClick: () => dispatch(removePopup()),
                        },
                    ],
                })
            );
        };

        // Table Config
        const tableRows = pageCategoryConfigData.data.objects.map(pageCategory => {
            const rowProperty = {
                selected: selectedTableRows.includes(pageCategory as PageCategoryTableRow),
                disabled: false,
            };

            return {
                columns: [
                    {
                        copy: pageCategory.category as string,
                    },
                    {
                        copy: pageCategory.lookup_include as string,
                    },
                    {
                        copy: pageCategory.lookup_exclude as string,
                    },
                    {
                        copy: pageCategory.is_regex ? ('True' as string) : ('False' as string),
                    },
                ],
                keyValue: `page_category__${pageCategory.category}`,
                key: `page_category__${pageCategory.category}`,
                dataValue: pageCategory.id as string,
                rowProperty,
                onClick: () => handleSelectTableRow(pageCategory as PageCategoryTableRow),
            };
        });

        const tableHeader = {
            columns: [
                {
                    title: 'Category',
                },
                {
                    title: 'Lookup Include',
                },
                {
                    title: 'Lookup Exclude',
                },
                {
                    title: 'Is Regex',
                },
            ],
        };

        return (
            <PageLayout>
                <SimpleTable
                    isLoading={
                        pageCategoryConfigData.isFetching ||
                        pageCategoryPatchMutation.isPending ||
                        pageCategoryDeleteMutation.isPending
                    }
                    header={tableHeader}
                    rows={tableRows}
                    hasIcons={false}
                    selectDisabled={false}
                    isScrollable={true}
                    customHeight="300px"
                />
                <StyledTableActionsContainer>
                    <TablePaginationPageList
                        ownerCallback={handleChangePage}
                        currentRowCount={maxPerPage}
                        totalResults={pageCategoryConfigData.data.meta.total_count}
                        minPage={1}
                        maxPage={pageCategoryConfigData.data.meta.total_count / maxPerPage}
                        currentPage={page}
                        maxButtons={17}
                        disabled={false}
                    />

                    <StyledButtonContainer>
                        <InputButton value="ADD" onClick={handleOnAddNewClick} />
                        <InputButton
                            buttonTheme={ButtonThemes.Red}
                            value="REMOVE"
                            disabled={selectedTableRows.length === 0}
                            onClick={handleOnDeleteClick}
                        />
                    </StyledButtonContainer>
                </StyledTableActionsContainer>

                {selectedTableRows && selectedTableRows.length === 1 && (
                    <StyledEditContainer>
                        <h2>Edit Page Category</h2>

                        <FormTextInput
                            inputPlaceholder={category}
                            label="Category:"
                            inputValue={category}
                            inputOnChange={handleEditCategory}
                            disabled={pageCategoryPatchMutation.isPending || pageCategoryDeleteMutation.isPending}
                        />

                        <FormTextInput
                            inputPlaceholder={lookupInclude}
                            label="Lookup Include:"
                            inputValue={lookupInclude}
                            inputOnChange={handleEditLookupInclude}
                            disabled={pageCategoryPatchMutation.isPending || pageCategoryDeleteMutation.isPending}
                        />

                        <FormTextInput
                            inputPlaceholder={lookupExclude}
                            label="Lookup Exclude:"
                            inputValue={lookupExclude}
                            inputOnChange={handleEditLookupExclude}
                            disabled={pageCategoryPatchMutation.isPending || pageCategoryDeleteMutation.isPending}
                        />

                        <FormCheckBoxInput
                            inputKeyValue="isRegex"
                            inputName="isRegex"
                            label="Is Regex"
                            checked={isRegex}
                            inputOnChange={handleEditIsRegex}
                            disabled={pageCategoryPatchMutation.isPending || pageCategoryDeleteMutation.isPending}
                        />
                        <StyledButtonContainer>
                            <InputButton
                                value="SAVE"
                                onClick={handleOnSaveClick}
                                isLoading={pageCategoryPatchMutation.isPending || pageCategoryDeleteMutation.isPending}
                            />
                            <InputButton
                                value="CANCEL"
                                onClick={handleOnCancelClick}
                                buttonTheme={ButtonThemes.Secondary}
                            />
                        </StyledButtonContainer>
                    </StyledEditContainer>
                )}
            </PageLayout>
        );
    }
};

export default LayoutModalManagePageCategories;
