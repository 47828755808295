import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CubedConfigResource, CubedResource } from '../../types';
import generateQueryKey from '../helpers/generate-query-key';
import { generateUrl } from '../../helpers/request-builder';
import axios from 'axios';

type RequestResponseData = { [key: string]: string | number | boolean };

type UsePostResourceArgs = {
    resource: CubedResource | CubedConfigResource;
    data: RequestResponseData;
    handleOnSuccess?: (data: RequestResponseData) => void;
    handleOnError?: (error?: string) => void;
};

const usePostResource = ({ resource, data, handleOnSuccess, handleOnError }: UsePostResourceArgs) => {
    const queryClient = useQueryClient();
    const queryKey = generateQueryKey({ resource });

    const url = generateUrl(resource.category, resource.id);

    const mutation = useMutation({
        mutationFn: async () => {
            if (url) {
                return axios({
                    method: 'POST',
                    url: url,
                    data: data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .catch(error => {
                        if (error.response.data.includes('Duplicate entry')) {
                            throw new Error('duplicate entry');
                        }
                        throw new Error(error.response.data.message);
                    })
                    .finally(() => {
                        queryClient.invalidateQueries({
                            queryKey: queryKey,
                        });
                    });
            }
            throw new Error('Error when generating url');
        },
        onSuccess: response => {
            if (handleOnSuccess) {
                handleOnSuccess(response.data);
            }
        },
        onError: error => {
            if (handleOnError) {
                handleOnError(error.message);
            }
        },
    });

    return mutation;
};

export default usePostResource;
