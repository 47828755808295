import React from 'react';
import styled from 'styled-components';
import ButtonGroupButton from './button-group-button';

const StyledButtonGroup = styled.div`
    border: 2px solid ${props => props.theme.colours.offBlackLighter};
    display: inline-flex;
    height: 40px;
`;

const ButtonGroup = ({ options, onButtonClick, smallText }) => {
    return (
        <StyledButtonGroup data-testid="button-group">
            {options.map(option => {
                return (
                    <ButtonGroupButton
                        key={option.value}
                        active={option.active}
                        onButtonClick={() => onButtonClick(option)}
                        smallText={smallText}
                    >
                        {option.name}
                    </ButtonGroupButton>
                );
            })}
        </StyledButtonGroup>
    );
};

export default ButtonGroup;
