import { RequestStatus } from '../../../section-dashboard/types';
import * as fields from '../../../configurations/fields';

export const visitorTypeDropdownRequest = {
    status: RequestStatus.SUCCESS,
    options: [
        {
            label: 'First Visit',
            value: 'First Visit',
            field: fields.NEW_VISITS,
        },
        {
            label: 'Returning Visit',
            value: 'Returning Visit',
            field: fields.REPEAT_VISITS,
        },
    ],
};

export const selectLevelDropdownRequest = {
    status: RequestStatus.SUCCESS,
    options: [
        {
            label: 'Country',
            value: 'country',
            field: fields.MCS_COUNTRY_NAME,
            dropdownField: fields.GEOLOCATION_COUNTRY_NAME,
        },
        {
            label: 'City',
            value: 'city',
            field: fields.MCS_CITY_NAME,
            dropdownField: fields.GEOLOCATION_CITY_NAME,
        },
    ],
};
