import { css } from 'styled-components';

export const StyledDatePickerStyles = css`
    .rdrDateDisplayWrapper {
        background-color: ${props => props.theme.datePicker.dateDisplay.backgroundColor};
    }

    .rdrDefinedRangesWrapper {
        background-color: ${props => props.theme.datePicker.backgroundColor};
        border-right: 1px solid ${props => props.theme.datePicker.definedRanges.borderColor};
    }

    .rdrMonth {
        background-color: ${props => props.theme.datePicker.backgroundColor};
    }

    .rdrMonthPicker select,
    .rdrYearPicker select {
        color: ${props => props.theme.datePicker.monthAndYearPicker.textColor};
        background-color: ${props => props.theme.datePicker.monthAndYearPicker.backgroundColor};
    }

    .rdrMonthPicker select option,
    .rdrYearPicker select option {
        color: ${props => props.theme.datePicker.monthAndYearPicker.optionTextColor};
    }

    .rdrStaticRange {
        border-bottom: 1px solid ${props => props.theme.datePicker.definedRanges.borderColor};
        background-color: ${props => props.theme.datePicker.definedRanges.borderColor};
        color: ${props => props.theme.datePicker.definedRanges.textColor};
    }

    span.rdrStaticRangeLabel,
    .rdrStaticRangeLabel {
        background-color: ${props => props.theme.datePicker.backgroundColor};

        &:hover {
            background-color: ${props => props.theme.datePicker.definedRanges.backgroundColorHover};
        }
    }

    .rdrNextButton {
        background-color: ${props => props.theme.datePicker.nextPrevButton.backgroundColor};

        & i {
            border-color: ${props => props.theme.datePicker.nextPrevButton.arrowColorNext};
        }

        &:hover {
            background-color: ${props => props.theme.datePicker.nextPrevButton.backgroundColorHover};
        }
    }

    .rdrPprevButton {
        background-color: ${props => props.theme.datePicker.nextPrevButton.backgroundColor};

        & i {
            border-color: ${props => props.theme.datePicker.nextPrevButton.arrowColorPrev};
        }

        &:hover {
            background-color: ${props => props.theme.datePicker.nextPrevButton.backgroundColorHover};
        }
    }

    .rdrDateDisplayItem input {
        color: ${props => props.theme.datePicker.dateDisplay.textColor};
    }

    .rdrMonthAndYearWrapper {
        background-color: ${props => props.theme.datePicker.backgroundColor};
    }

    .rdrDateDisplayItem {
        background-color: ${props => props.theme.datePicker.backgroundColor};
    }

    .rdrDayDisabled {
        color: ${props => props.theme.datePicker.day.textColorDisabled};
        background-color: ${props => props.theme.datePicker.day.disabledBackgroundColor};
    }

    .rdDay .rdrDayNumber span,
    .rdrDay .rdrDayNumber span {
        color: ${props => props.theme.datePicker.day.textColor} !important;
    }

    .rdrDayDisabled .rdrDayNumber span {
        color: ${props => props.theme.datePicker.day.textColorDisabled} !important;
    }

    .rdrDayPassive .rdrDayNumber span {
        color: ${props => props.theme.datePicker.day.textColorPassive} !important;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
        color: ${props => props.theme.datePicker.day.textColorSelected} !important;
    }

    .rdrDayPassive .rdrDayStartPreview,
    .rdrDayPassive .rdrDayInPreview,
    .rdrDayPassive .rdrDayEndPreview {
        display: inline;
    }

    .rdrDayPassive .rdrInRange,
    .rdrDayPassive .rdrStartEdge,
    .rdrDayPassive .rdrEndEdge,
    .rdrDayPassive .rdrSelected {
        display: inline;
        background-color: rgba(185, 133, 96, 0.93);
    }

    .rdrDayStartOfMonth .rdrDayInPreview,
    .rdrDayStartOfMonth .rdrDayEndPreview {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 0;
    }
    .rdrDayStartOfMonth .rdrDayStartPreview {
        border-left-width: 1px;
    }
    .rdrDayEndOfMonth .rdrDayInPreview,
    .rdrDayEndOfMonth .rdrDayStartPreview {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
    }
    .rdrDayEndOfMonth .rdrDayEndPreview {
        border-right-width: 1px;
    }
    .rdrDayStartOfMonth.rdrDayStartOfWeek .rdrDayInPreview,
    .rdrDayStartOfMonth.rdrDayStartOfWeek .rdrDayEndPreview {
        border-top-left-radius: 1.033em;
        border-bottom-left-radius: 1.033em;
        border-left-width: 1px;
    }
    .rdrDayEndOfMonth.rdrDayEndOfWeek .rdrDayInPreview,
    .rdrDayEndOfMonth.rdrDayEndOfWeek .rdrDayStartPreview {
        border-top-right-radius: 1.033em;
        border-bottom-right-radius: 1.033em;
        border-right-width: 0;
    }
    .rdrDayStartPreview.rdrDayEndPreview {
        border-top-right-radius: 1.042em;
        border-bottom-right-radius: 1.042em;
        right: 2px;
        border-top-left-radius: 1.042em;
        border-bottom-left-radius: 1.042em;
        left: 2px;
    }
`;
