import { useTheme } from 'styled-components';
import { useMediaQuery } from '@react-hook/media-query';
import { SectionDashboardLayout } from '../../types';

const useGridLayout = (): SectionDashboardLayout => {
    const theme = useTheme();
    const matchXL = useMediaQuery(`(min-width: ${theme.sectionDashboard.grid.breakpoints.xl})`);
    const matchLG = useMediaQuery(`(min-width: ${theme.sectionDashboard.grid.breakpoints.lg})`);
    const matchMD = useMediaQuery(`(min-width: ${theme.sectionDashboard.grid.breakpoints.md})`);
    const matchSM = useMediaQuery(`(min-width: ${theme.sectionDashboard.grid.breakpoints.sm})`);

    if (matchXL) {
        return SectionDashboardLayout.XL;
    } else if (matchLG) {
        return SectionDashboardLayout.LG;
    } else if (matchMD) {
        return SectionDashboardLayout.MD;
    } else if (matchSM) {
        return SectionDashboardLayout.SM;
    } else {
        return SectionDashboardLayout.XS;
    }
};

export default useGridLayout;
