import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

// Config
import {
    CONFIG_CHANNELS,
    CONFIG_SUBCHANNELS,
    CONFIG_SUBCHANNEL_RULES_RESOURCE,
} from '../../configurations/resources-config';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { removePopup, setPopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';

// Hooks
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import usePostResource from '../../react-query/hooks/use-post-resource';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';
import usePatchResource from '../../react-query/hooks/use-patch-resource';

// Types & Enums
import { ButtonThemes } from '../../enums/button-themes';
import { DropdownOption } from '../../section-dashboard/types';
import { ConfigDataSuccess, ConfigResponseObject } from '../../react-query/types';
import { NotificationMessageType } from '../../enums/notification-types';

// Helpers
import { generateUrlDetail } from '../../helpers/request-builder';
import { isAlphaNumericString } from '../../helpers/validator';

// Components
import ModalNavigation from '../../components/modal-navigation';
import WarningMessage from '../../components/warning-message';
import ConfigTable from '../../components/tables/config-table/config-table';
import FormTextInput from '../../components/form-fields/form-text-input';
import LoadingSpinner from '../../components/loading-spinner';
import DropdownWithSearch from '../../components/common/dropdown-with-search';
import InputButton from '../../components/inputs/input-button';
import ManageSubchannelsRules from './manage-subchannels-rules';

const StyledContainer = styled.div`
    z-index: 500001;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: scroll;
    width: 100%;
    max-width: 900px;
    height: 100vh;
    background-color: ${props => props.theme.colours.lightGrey};
    float: right;
    padding: 20px;
    & h2 {
        color: ${props => props.theme.colours.offBlackLighter};
    }
`;

const StyledEditContainer = styled.div`
    margin-top: 20px;
    background-color: #ffffff;
    padding: 20px;
`;

const StyledH3 = styled.h3`
    margin: 0;
    font-weight: 700;
    color: ${props => props.theme.colours.offBlackLighter};
`;

const StyledFormContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

// Types
type ManageChannelsSubchannel = {
    name: string;
    referer: string;
};
type ManageChannelsSubchannelToEdit = ManageChannelsSubchannel & {
    id: number;
    selected: boolean;
};
interface SubchannelDataSuccess extends ConfigDataSuccess {
    objects: ConfigResponseObject & { id: number; name: string; referer: { name: string } }[];
}

// Layout
const Layout = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch();

    const onCloseClick = () => {
        dispatch(removeModal());
    };

    const modalNavigationButtons = [
        {
            value: 'CLOSE',
            onClick: onCloseClick,
            buttonTheme: ButtonThemes.Secondary,
        },
    ];

    return (
        <StyledContainer>
            <ModalNavigation buttons={modalNavigationButtons} />
            <h2>Manage Subchannels</h2>
            {children}
        </StyledContainer>
    );
};

const LayoutModalManageSubchannels = () => {
    const dispatch = useDispatch();
    // Subchannel State
    const [subchannels, setSubchannels] = useState<ManageChannelsSubchannelToEdit[]>([]);
    const [subchannelToEdit, setSubchannelToEdit] = useState<ManageChannelsSubchannelToEdit>();
    const [subchannelErrorMessage, setSubchannelErrorMessage] = useState<string>();
    const [subchannelsToDelete, setSubchannelsToDelete] = useState<number[]>([]);
    const [showAddSubchannel, setShowAddSubchannel] = useState(false);
    const [newSubchannel, setNewSubchannel] = useState<ManageChannelsSubchannel>({
        name: '',
        referer: '',
    });

    // Referer State
    const [refererDropdownOptions, setRefererDropdownOptions] = useState<DropdownOption[]>([]);
    const [selectedReferrer, setSelectedReferrer] = useState<DropdownOption>();
    const [referrerErrorMessage, setReferrerErrorMessage] = useState<string>();

    // Edit Rules State
    const [showEditRules, setShowEditRules] = useState(false);
    const [subchannelIdToEditRule, setSubchannelIdToEditRule] = useState<number | undefined>(undefined);

    // Queries & Mutations
    const subchannelQuery = useFetchResource({
        resource: CONFIG_SUBCHANNELS,
        params: [{ key: 'active', value: 1 }],
        select: (data: SubchannelDataSuccess) => {
            return data.objects.map(subchannel => {
                return {
                    id: subchannel.id,
                    name: subchannel.name,
                    referer: subchannel.referer.name,
                    selected: false,
                };
            });
        },
    });

    const refererQuery = useFetchResource({
        resource: CONFIG_CHANNELS,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(channel => {
                return {
                    id: channel.id,
                    name: channel.name,
                };
            });
        },
    });

    const subchannelPostMutation = usePostResource({
        resource: CONFIG_SUBCHANNELS,
        data: {
            name: newSubchannel.name,
            referer: generateUrlDetail('config', 'referer', selectedReferrer?.value.toString(), [], false)!,
        },
        handleOnSuccess: () => {
            setNewSubchannel({ name: '', referer: '' });
            setSelectedReferrer(undefined);
            setShowAddSubchannel(false);

            dispatch(
                addNotification({
                    copy: 'Subchannel successfully added.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your Subchannel. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const subchannelPatchMutation = usePatchResource({
        resource: CONFIG_SUBCHANNELS,
        resourceId: subchannelToEdit ? subchannelToEdit.id.toString() : '0',
        data:
            subchannelToEdit && selectedReferrer
                ? {
                      name: subchannelToEdit.name,
                      referer: generateUrlDetail('config', 'referer', selectedReferrer.value.toString(), [], false)!,
                  }
                : { name: '', referer: '' },
        handleOnSuccess: () => {
            setSubchannelToEdit(undefined);
            setSelectedReferrer(undefined);

            dispatch(
                addNotification({
                    copy: 'Subchannel successfully updated.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your subchannel. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const subchannelDeleteMutation = useDeleteResource({
        resource: CONFIG_SUBCHANNELS,
        resourceIds: subchannelsToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            setSubchannelsToDelete([]);
            dispatch(
                addNotification({
                    copy: 'Subchannel successfully deleted.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setSubchannelsToDelete([]);
            dispatch(
                addNotification({
                    copy: 'There was an issue while trying to delete your subchannel. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const subchannelRulesQuery = useFetchResource({
        resource: CONFIG_SUBCHANNEL_RULES_RESOURCE,
        params: [
            { key: 'subchannel', value: subchannelIdToEditRule as number },
            { key: 'order_by', value: 'priority' },
            { key: 'active', value: 1 },
        ],
        enabled: showEditRules && !!subchannelIdToEditRule,
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(ruler => {
                return {
                    id: ruler.id,
                    name: ruler.name,
                    rule: ruler.rule,
                    description: ruler.description,
                    priority: ruler.priority,
                    subchannel: ruler.subchannel.name,
                    subchannelId: ruler.subchannel.id,
                    referer: ruler.subchannel.referer.name,
                    refererId: ruler.subchannel.referer.id,
                    selected: false,
                    dragId: `subchannel-config-rule-${ruler.id}`,
                };
            });
        },
    });

    // Save the subchannels to state
    useEffect(() => {
        if (subchannelQuery.data) {
            setSubchannels(
                subchannelQuery.data.filter(
                    subchannel => subchannel.name !== 'Generic'
                ) as ManageChannelsSubchannelToEdit[]
            );
        }
    }, [subchannelQuery.data]);

    // Set the referrer dropdown options
    useEffect(() => {
        if (refererQuery.data) {
            setRefererDropdownOptions(
                refererQuery.data.map(referer => {
                    return { label: referer.name as string, value: referer.id as string };
                })
            );
        }
    }, [refererQuery.data]);

    // When editing a subchannel, set the selected referrer
    useEffect(() => {
        if (refererQuery.status === 'success' && subchannelToEdit) {
            setSelectedReferrer(refererDropdownOptions.find(referer => referer.label === subchannelToEdit.referer));
        }
    }, [subchannelToEdit]); // eslint-disable-line react-hooks/exhaustive-deps

    // When the subchannels change, update the subchannels to delete
    useEffect(() => {
        setSubchannelsToDelete(subchannels.filter(subchannel => subchannel.selected).map(subchannel => subchannel.id));

        const selectedSubChannel = subchannels.filter(subchannel => subchannel.selected);
        if (selectedSubChannel && selectedSubChannel.length === 1) {
            setSubchannelIdToEditRule(selectedSubChannel[0].id);
        } else {
            setSubchannelIdToEditRule(undefined);
        }
    }, [subchannels]);

    // Form Validation
    const subchannelFormIsValid = (subchannel?: ManageChannelsSubchannel | ManageChannelsSubchannelToEdit) => {
        if (subchannel) {
            let isValid = true;
            if (!isAlphaNumericString(subchannel.name) || subchannel.name.length === 0) {
                setSubchannelErrorMessage('Please enter a valid Channel Name.');
                isValid = false;
            } else {
                setSubchannelErrorMessage(undefined);
            }

            if (!selectedReferrer) {
                setReferrerErrorMessage('Please select a Referrer.');
                isValid = false;
            } else {
                setReferrerErrorMessage(undefined);
            }

            return isValid;
        }
    };

    // Handlers
    const handleCheckboxChange = (id: number) => {
        setSubchannels(
            subchannels.map(subchannel => {
                if (subchannel.id === id) {
                    return {
                        ...subchannel,
                        selected: !subchannel.selected,
                    };
                }
                return subchannel;
            })
        );
    };
    const handleEditClick = (id: number) => {
        const selectedSubchannel = subchannels.find(subchannel => subchannel.id === id);
        setSubchannelToEdit(selectedSubchannel);

        if (refererQuery.status === 'success' && subchannelToEdit) {
            setSelectedReferrer(refererDropdownOptions.find(referer => referer.label === subchannelToEdit.referer));
        }
    };

    const handleEditNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (subchannelToEdit) {
            setSubchannelToEdit({
                ...subchannelToEdit,
                name: event.target.value,
            });
        }
    };

    const handleDropdownChange = (option: DropdownOption) => {
        setSelectedReferrer(option);
    };

    const handleCancelEditClick = () => {
        setSubchannelToEdit(undefined);
        setSelectedReferrer(undefined);
    };

    const handleSaveEditClick = () => {
        if (!subchannelFormIsValid(subchannelToEdit)) {
            return;
        }
        subchannelPatchMutation.mutate();
    };

    const handleSingleDeleteClick = (rowId: number) => {
        setSubchannelsToDelete([rowId]);

        const handleDisardClick = () => {
            setSubchannelsToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            subchannelDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this subchannel?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkDeleteClick = () => {
        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setSubchannels(
                subchannels.map(subchannel => {
                    return {
                        ...subchannel,
                        selected: false,
                    };
                })
            );
            subchannelDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected subchannels?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleNewNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewSubchannel({
            ...newSubchannel,
            name: event.target.value,
        });
    };

    const handleSaveNewClick = () => {
        if (!subchannelFormIsValid(newSubchannel)) {
            return;
        }
        subchannelPostMutation.mutate();
    };

    const handleCancelNewClick = () => {
        setShowAddSubchannel(false);
        setSelectedReferrer(undefined);
    };

    const handleRuleEditClick = (id: number) => {
        setSubchannelIdToEditRule(id);
        setShowEditRules(true);
    };

    const handleCancleSubchannelRuleClick = () => {
        setShowEditRules(false);
    };

    // Page Success
    if (subchannelQuery.status === 'success' || subchannelQuery.status === 'pending') {
        return (
            <Layout>
                <ConfigTable
                    status={subchannelQuery.status}
                    isFetching={subchannelQuery.isFetching || subchannelDeleteMutation.isPending}
                    disabled={!!subchannelToEdit || showAddSubchannel || showEditRules}
                    empty={subchannels.length === 0}
                >
                    <ConfigTable.Table maxHeight="400px">
                        <ConfigTable.Header>
                            <ConfigTable.Row key="thead">
                                <ConfigTable.CellHeader />
                                <ConfigTable.CellHeader>Name</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Referer</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader />
                            </ConfigTable.Row>
                        </ConfigTable.Header>
                        <ConfigTable.Body>
                            {subchannels.map(subchannel => {
                                return (
                                    <ConfigTable.Row key={subchannel.id}>
                                        <ConfigTable.CellCheckbox
                                            rowId={subchannel.id}
                                            checked={subchannel.selected}
                                            onCheckedChange={handleCheckboxChange}
                                        />
                                        <ConfigTable.Cell>{subchannel.name}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{subchannel.referer}</ConfigTable.Cell>
                                        <ConfigTable.CellActions>
                                            <ConfigTable.ActionDropdownItem
                                                rowId={subchannel.id}
                                                type="edit-secondary"
                                                onClick={handleRuleEditClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={subchannel.id}
                                                type="edit"
                                                onClick={handleEditClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={subchannel.id}
                                                type="delete"
                                                onClick={handleSingleDeleteClick}
                                            />
                                        </ConfigTable.CellActions>
                                    </ConfigTable.Row>
                                );
                            })}
                        </ConfigTable.Body>
                    </ConfigTable.Table>
                    <ConfigTable.ActionBar>
                        <ConfigTable.ActionBarJustifyLeft>
                            <ConfigTable.ActionButton
                                type="delete"
                                label="Delete Subchannels"
                                onClick={handleBulkDeleteClick}
                                isDisabled={subchannelsToDelete.length === 0}
                            />
                        </ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionBarJustifyRight>
                            <ConfigTable.ActionButton
                                type="add"
                                label="Add Subchannel"
                                onClick={() => setShowAddSubchannel(true)}
                            />
                        </ConfigTable.ActionBarJustifyRight>
                    </ConfigTable.ActionBar>
                </ConfigTable>

                {showAddSubchannel ? (
                    <StyledEditContainer>
                        <StyledH3>Add Subchannel</StyledH3>

                        {refererQuery.status === 'pending' ? <LoadingSpinner /> : null}

                        {refererQuery.status === 'success' ? (
                            <StyledFormContainer>
                                <FormTextInput
                                    label="Name:"
                                    inputValue={newSubchannel?.name}
                                    requiredField={true}
                                    inputOnChange={handleNewNameChange}
                                    disabled={subchannelPostMutation.isPending}
                                    errorMessage={subchannelErrorMessage}
                                />

                                <DropdownWithSearch
                                    label="Channel:"
                                    placeholder="Select a Referrer"
                                    dropdownItems={refererQuery.data.map(referer => {
                                        return { label: referer.name as string, value: referer.id as string };
                                    })}
                                    onSelectedItemChange={handleDropdownChange}
                                    requiredField={true}
                                    isAccordionForm={false}
                                    selected={selectedReferrer}
                                    disabled={subchannelPostMutation.isPending}
                                    errorMessage={referrerErrorMessage}
                                />
                                <StyledButtonContainer>
                                    <InputButton
                                        value="Save"
                                        buttonTheme={ButtonThemes.Primary}
                                        onClick={handleSaveNewClick}
                                        isLoading={subchannelPostMutation.isPending}
                                    />

                                    <InputButton
                                        value="Cancel"
                                        buttonTheme={ButtonThemes.Secondary}
                                        onClick={handleCancelNewClick}
                                    />
                                </StyledButtonContainer>
                            </StyledFormContainer>
                        ) : null}
                    </StyledEditContainer>
                ) : null}

                {showEditRules && (
                    <ManageSubchannelsRules
                        subchannelIdToEditRule={subchannelIdToEditRule as number}
                        subchannelRulesQuery={subchannelRulesQuery}
                        handleCancleSubchannelRuleClick={handleCancleSubchannelRuleClick}
                    />
                )}

                {subchannelToEdit ? (
                    <StyledEditContainer>
                        <StyledH3>Edit Subchannel: {subchannelToEdit.name}</StyledH3>

                        {refererQuery.status === 'pending' ? <LoadingSpinner /> : null}

                        {refererQuery.status === 'success' ? (
                            <StyledFormContainer>
                                <FormTextInput
                                    label="Name:"
                                    inputValue={subchannelToEdit.name}
                                    requiredField={true}
                                    inputOnChange={handleEditNameChange}
                                    disabled={subchannelPatchMutation.isPending}
                                    errorMessage={subchannelErrorMessage}
                                />

                                <DropdownWithSearch
                                    label="Channel:"
                                    placeholder="Select a Referrer"
                                    dropdownItems={refererQuery.data.map(referer => {
                                        return { label: referer.name as string, value: referer.id as string };
                                    })}
                                    onSelectedItemChange={handleDropdownChange}
                                    requiredField={true}
                                    isAccordionForm={false}
                                    selected={selectedReferrer}
                                    disabled={subchannelPatchMutation.isPending}
                                    errorMessage={referrerErrorMessage}
                                />
                                <StyledButtonContainer>
                                    <InputButton
                                        value="Save"
                                        buttonTheme={ButtonThemes.Primary}
                                        onClick={handleSaveEditClick}
                                        isLoading={subchannelPatchMutation.isPending}
                                    />

                                    <InputButton
                                        value="Cancel"
                                        buttonTheme={ButtonThemes.Secondary}
                                        onClick={handleCancelEditClick}
                                    />
                                </StyledButtonContainer>
                            </StyledFormContainer>
                        ) : null}
                    </StyledEditContainer>
                ) : null}
            </Layout>
        );
    }

    // Page Error
    return (
        <Layout>
            <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
        </Layout>
    );
};

export default LayoutModalManageSubchannels;
