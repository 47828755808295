import { Moment } from 'moment';
import React, { createContext, useState, useEffect } from 'react';

// Types
import { CubedAnnotationResource } from '../../types';
import { AnnotationConfig, AnnotationResponseItem } from './types';

// Helpers
import Request from '../../helpers/request';
import { formatAnnotationResponse } from '../components/chart/helpers/format-annotations';
import { ChartDateGranularity } from '../../configurations/common/chart-types';

export type TableGraphContextDefinition = {
    annotations: AnnotationConfig;
    handleChangeAnnotationVisibility: (id: string) => void;
};

export type TableGraphProviderProps = {
    annotationConfig: CubedAnnotationResource[];
    chartData: any;
    startDate: Moment;
    endDate: Moment;
    chartDateGranularity: string;
    children: JSX.Element;
};

export const TableGraphContext = createContext<TableGraphContextDefinition>({} as TableGraphContextDefinition);

const TableGraphProvider = ({
    annotationConfig,
    chartData,
    startDate,
    endDate,
    chartDateGranularity,
    children,
}: TableGraphProviderProps) => {
    const [annotations, setAnnotations] = useState<AnnotationConfig>([]);
    const [annotationResponse, setAnnotationResponse] = useState<AnnotationResponseItem[]>([]);

    useEffect(() => {
        // if the chart date granularity is set to month, disable the annotations
        const updatedAnnotations = [...annotations].map(annotation => {
            return {
                ...annotation,
                checked: false,
                disabled: chartDateGranularity === ChartDateGranularity.Month ? true : false,
            };
        });

        setAnnotations(updatedAnnotations);
    }, [chartDateGranularity]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // when the config file changes (new report) set the new annotation menu items
        if (annotationConfig) {
            const config = annotationConfig.map(configItem => {
                return {
                    name: configItem.displayName,
                    id: configItem.id,
                    checked: false,
                    disabled: true,
                    data: [],
                };
            });

            setAnnotations(config);
            fetchAnnotations();
        } else {
            setAnnotations([]);
        }
    }, [annotationConfig]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // when the date changes, fetch the new annotation data
        if (annotationConfig) {
            fetchAnnotations();
        }
    }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // when we have the chart data from table-graph we can match the chart data with the annotation data

        if (
            chartDateGranularity !== ChartDateGranularity.Month &&
            chartData.length > 0 &&
            annotationConfig &&
            annotationResponse
        ) {
            let annotationsToUpdate = [...annotations];

            const updatedAnnotations = annotationsToUpdate.map(updateItem => {
                let annotationResponseItem = annotationResponse.filter(
                    responseItem => responseItem.id === updateItem.id
                )[0];
                return {
                    ...updateItem,
                    disabled: annotationResponseItem ? false : true,
                    data: annotationResponseItem ? formatAnnotationResponse(chartData, annotationResponseItem) : [],
                };
            });

            setAnnotations(updatedAnnotations);
        }
    }, [chartData, annotationResponse]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchAnnotations = () => {
        const annotationResponseItems: AnnotationResponseItem[] = [];

        annotationConfig.forEach(config => {
            let annotationResponseItem: AnnotationResponseItem;

            const annotationRequest = new Request();
            annotationRequest
                .get(config.category, config.id, [
                    { key: 'date__gte', value: startDate.format('YYYY-MM-DD') },
                    { key: 'date_lte', value: endDate.format('YYYY-MM-DD') },
                    { key: 'annotation_type', value: config.type },
                ])
                .then(response => {
                    annotationResponseItem = {
                        id: config.id,
                        data: response.data.objects.map((dataItem: any) => {
                            return {
                                name: dataItem.name,
                                date: dataItem.date,
                            };
                        }),
                    };

                    annotationResponseItems.push(annotationResponseItem);
                })
                .catch(() => {})
                .finally(() => {
                    setAnnotationResponse(annotationResponseItems);
                });
        });
    };

    const handleChangeAnnotationVisibility = (id: string) => {
        const updatedAnnotations = [...annotations].map(annotation => {
            if (annotation.id === id) {
                return {
                    ...annotation,
                    checked: !annotation.checked,
                };
            }
            return annotation;
        });

        setAnnotations(updatedAnnotations);
    };

    const value = { annotations, handleChangeAnnotationVisibility };
    return <TableGraphContext.Provider value={value}>{children}</TableGraphContext.Provider>;
};

export default TableGraphProvider;
