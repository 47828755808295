import * as resources from '../../configurations/resources';
import * as fields from '../../configurations/fields';
import { ChannelSubsectionConfig } from '../../types';

const defaultSubsectionConfig: ChannelSubsectionConfig = {
    tier2: fields.REFERRER_DOMAIN,
    tier3: fields.REFERRER_PATH,
    tier4: fields.LANDING_PATH_PATH,
    resource: resources.REPORT_MCS_LANDING_PATH_REFERRER,
};

export function getChannelSubsectionConfig(channelName: string): ChannelSubsectionConfig {
    switch (channelName) {
        case 'Affiliates':
            return {
                tier2: fields.AFFILIATE_TYPE,
                tier3: fields.AFFILIATE_PUBLISHER,
                tier4: fields.AFFILIATE_CAMPAIGN,
                resource: resources.REPORT_MCS_AFFILIATE,
            };
        case 'Shopping':
            return {
                tier2: fields.SHOPPING_CAMPAIGN_NAME,
                tier3: fields.SHOPPING_AD_GROUP_NAME,
                tier4: fields.SHOPPING_PRODUCT_TYPE1,
                resource: resources.REPORT_MCS_SHOPPING,
            };
        case 'Internal':
        case 'Partner':
        case 'Referral':
            return {
                tier2: fields.REFERRER_DOMAIN,
                tier3: fields.REFERRER_PATH,
                tier4: fields.LANDING_PATH_PATH,
                resource: resources.REPORT_MCS_LANDING_PATH_REFERRER,
            };
        case 'Offline':
        case 'Other':
            return {
                tier2: fields.REFERRER_DOMAIN,
                tier3: fields.LANDING_PATH_HOST,
                tier4: fields.LANDING_PATH_PATH,
                resource: resources.REPORT_MCS_LANDING_PATH_REFERRER,
            };
        case 'Direct':
        case 'Direct Mail':
        case 'SEO':
        case 'TV':
        case 'Radio':
            return {
                tier2: fields.LANDING_PATH_HOST,
                tier3: fields.LANDING_PATH_PATH,
                tier4: fields.REFERRER_DOMAIN,
                resource: resources.REPORT_MCS_LANDING_PATH_REFERRER,
            };
        case 'Display':
        case 'Email':
        case 'Social Organic':
        case 'Social Paid':
            return {
                tier2: fields.UTM_SOURCE,
                tier3: fields.UTM_MEDIUM,
                tier4: fields.UTM_CAMPAIGN,
                resource: resources.REPORT_MCS_UTM,
            };
        case 'PPC':
            return {
                tier2: fields.PPC_CAMPAIGN_NAME,
                tier3: fields.PPC_AD_GROUP_NAME,
                tier4: fields.PPC_KEYWORD,
                resource: resources.REPORT_MCS_PPC,
            };
        case 'Carousell':
            return {
                tier2: fields.UTM_SOURCE,
                tier3: fields.UTM_MEDIUM,
                tier4: fields.UTM_CAMPAIGN,
                resource: resources.REPORT_MCS_UTM,
            };
        default:
            return defaultSubsectionConfig;
    }
}
