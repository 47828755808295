import React from 'react';
import { createContext, useContext } from 'react';
import { FieldValues } from 'react-hook-form';

export type FormStatus = 'enabled' | 'disabled' | 'loading';

type FormContextValues = {
    status: FormStatus;
    defaultValues?: FieldValues;
};

const FormContext = createContext<FormContextValues | undefined>(undefined);

export const useCubedFormContext = () => {
    const context = useContext(FormContext);

    if (context === undefined) {
        throw new Error('"Form" child components cannot be rendered outside of the <Form> component');
    }
    return context;
};

type CubedFormContextProviderProps = {
    status?: FormStatus;
    defaultValues?: FieldValues;
    children: React.ReactNode;
};

export const CubedFormContextProvider = ({
    status = 'enabled',
    defaultValues,
    children,
}: CubedFormContextProviderProps) => {
    return <FormContext.Provider value={{ status, defaultValues }}>{children}</FormContext.Provider>;
};
