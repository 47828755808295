import React from 'react';
import styled from 'styled-components';
import { ChartType } from '../../../../configurations/common/chart-types';
import ChartMenu from '../chart-menu/chart-menu';
import ChartSelectorButtonWrapper from './chart-button-wrapper';

const StyledCharTypeSelectorWrapper = styled.div`
    display: flex;
`;

export type ChartSelectorProps = {
    selectedChartType: string;
    allowedChartTypes: string[];
    setChartConfig: (chartType: string) => void;
    onUpdateChartDateGranularity: () => void;
    chartDateGranularity: string;
    disableDateGranularity: boolean;
};

const ChartSelector = ({
    selectedChartType,
    allowedChartTypes,
    setChartConfig,
    onUpdateChartDateGranularity,
    chartDateGranularity,
    disableDateGranularity,
}: ChartSelectorProps) => {
    return (
        <StyledCharTypeSelectorWrapper>
            {allowedChartTypes.map(chartType => {
                return (
                    <ChartSelectorButtonWrapper
                        key={chartType}
                        chartType={chartType}
                        selectedChartType={selectedChartType}
                        onClick={() => setChartConfig(chartType)}
                    />
                );
            })}
            {allowedChartTypes.some(allowedType =>
                [ChartType.AreaSpline, ChartType.trendLine].includes(allowedType)
            ) && (
                <ChartMenu
                    chartDateGranularity={chartDateGranularity}
                    disableDateGranularity={disableDateGranularity}
                    onUpdateChartDateGranularity={onUpdateChartDateGranularity}
                    disabled={false}
                />
            )}
        </StyledCharTypeSelectorWrapper>
    );
};

export default ChartSelector;
