import React, { useState, useEffect } from 'react';
import Axios, { AxiosRequestConfig } from 'axios';

import { setModal } from '../../redux/actions/modal';
import { RootState } from '../../redux/reducers/core';
import { useDispatch, useSelector } from 'react-redux';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';

import ModalNavigation from '../../components/modal-navigation';

import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';

export type Connection = {
    active: boolean | undefined;
    id: string | undefined;
    resourceUri: string | undefined;
    semrushKey: string | undefined;
};

type Config = {
    config: Connection;
};

type ConnectionProps = {
    config: Config;
};

type ValidationError = {
    response: Response;
};

type Response = {
    data: ErrorMessage;
    status: number;
};

type ErrorMessage = {
    'semrush-connection': APIValidationError;
};

type APIValidationError = {
    semrush_key: string;
};

type DuplicateKeyError = {
    code: number;
    message: string;
};

const SEMRushKeyAddEditModal = (connectionToEdit: ConnectionProps | null = null) => {
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState(true);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState(false);
    const [closeButtonState, setCloseButtonState] = useState('close');
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
    const [formErrors, setFormErrors] = useState<APIValidationError | null>();
    const [semrushConnection, setSemrushConnection] = useState<Connection | null>();
    const [description, setDescription] = useState<string>();
    const [modalHeading, setModalHeading] = useState<string>();

    const userId = useSelector((state: RootState) => state.user.id);

    const dispatch = useDispatch();

    useEffect(() => {
        const connection = connectionToEdit?.config?.config;

        if (connection) {
            setSemrushConnection(connection);
            setDescription('Edit your SEMRush API key to allow the import of data from SEMRush.');
            setModalHeading('EDIT SEMRush Key');
        } else {
            setDescription('Add your SEMRush API key to allow the import of data from SEMRush.');
            setModalHeading('ADD SEMRush Key');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            connectionToEdit?.config?.config.active === semrushConnection?.active &&
            semrushConnection?.semrushKey === connectionToEdit?.config?.config.semrushKey
        ) {
            setCloseButtonState('close');
            setSaveChangesButtonDisabled(true);
        } else {
            setCloseButtonState('cancel');
            setSaveChangesButtonDisabled(false);
        }
    }, [semrushConnection]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleNavigateSemrushModal = () => {
        dispatch(setModal('SemrushAPI', {}));
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        handleNavigateSemrushModal();
    };

    // SecondaryAction of the close popup
    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            handleNavigateSemrushModal();
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                    },
                    buttons: [
                        {
                            onClick: onPopupDiscardChangesClick,
                            value: 'DISCARD CHANGES',
                        },
                        {
                            onClick: onPopupStayHereClick,
                            value: 'STAY HERE',
                            style: 'secondary',
                        },
                    ],
                })
            );
        }
    };

    const updateSemrushKey = async () => {
        const semrushConnectionPayload = {
            semrush_key: semrushConnection?.semrushKey,
        };

        const url: string | null = generateUrlDetail('integrations', 'semrush-connection', semrushConnection?.id);

        const semrushConnectionConfig: AxiosRequestConfig = {
            method: 'PATCH',
            url: url !== null ? url : undefined,
            data: semrushConnectionPayload,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            await Axios(semrushConnectionConfig);
            dispatch(
                addNotification({
                    copy: 'Your SEMRush key was updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );

            handleNavigateSemrushModal();
        } catch (error) {
            if (
                (error as ValidationError)?.response?.status === 400 ||
                (error as ValidationError)?.response?.status === 409
            ) {
                updateFormErrors((error as ValidationError).response?.data);
            }
            dispatch(
                addNotification({
                    copy: 'Something went wrong saving your keys, please try again later.',
                    type: NotificationMessageType.Error,
                })
            );

            setSaveChangesButtonDisabled(false);
            setSaveChangesButtonLoading(false);
            setCloseButtonDisabled(false);
        }
    };

    // Makes a POST request to the API with the data entered by the user.
    const saveNewSemrushKey = async () => {
        const semrushConnectionPayload = {
            cubed_user_id: userId,
            semrush_key: semrushConnection?.semrushKey,
            active: '1',
        };

        let semrushKeyConnectionRequestConfig: AxiosRequestConfig;

        semrushKeyConnectionRequestConfig = {
            method: 'POST',
            url: generateUrl('integrations', 'semrush-connection'),
            data: semrushConnectionPayload,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        try {
            await Axios(semrushKeyConnectionRequestConfig);
            dispatch(
                addNotification({
                    copy: 'Your SEMRush key was saved successfully.',
                    type: NotificationMessageType.Success,
                })
            );
            handleNavigateSemrushModal();
        } catch (error) {
            if (
                (error as ValidationError)?.response?.status === 400 ||
                (error as ValidationError)?.response?.status === 409
            ) {
                updateFormErrors((error as ValidationError).response?.data);
            }
            dispatch(
                addNotification({
                    copy: 'Something went wrong saving your keys.',
                    type: NotificationMessageType.Error,
                })
            );
            setSaveChangesButtonDisabled(false);
            setSaveChangesButtonLoading(false);
            setCloseButtonDisabled(false);
        }
    };

    const updateFormErrors = (error: ErrorMessage | DuplicateKeyError) => {
        if ((error as ErrorMessage)['semrush-connection']) {
            setFormErrors((error as ErrorMessage)['semrush-connection']);
        }

        if (error as DuplicateKeyError) {
            setFormErrors({ semrush_key: (error as DuplicateKeyError)?.message });
        } else {
            dispatch(
                addNotification({
                    copy: 'Something went wrong updating your key.',
                    type: NotificationMessageType.Error,
                })
            );
        }
    };

    // Saved changes clicked handler
    const onSaveChangesClick = async () => {
        setSaveChangesButtonDisabled(true);
        setSaveChangesButtonLoading(true);
        setCloseButtonDisabled(true);

        if (!semrushConnection?.id) {
            //ie, this is a new connection
            await saveNewSemrushKey();
        } else {
            await updateSemrushKey();
        }
    };

    // Manage the input fields changes and updating the state with the new value entered by the user
    const onSemrushKeyDetailsChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        let updatedSemrushConnection: Connection = {
            active: semrushConnection?.active,
            id: semrushConnection?.id,
            resourceUri: semrushConnection?.resourceUri,
            semrushKey: semrushConnection?.semrushKey,
        };

        updatedSemrushConnection.semrushKey = event.target.value;

        setSemrushConnection(updatedSemrushConnection);
        setFormErrors({ semrush_key: '' }); //reset this on change
    };

    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const renderAccordion = () => {
        const accordions = [
            {
                header: 'SEMRush API Key',
                required: false,
                open: true,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'SEMRush Key:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter the SEMRush Key.',
                                inputKeyValue: 'semrush_key',
                                inputPlaceholder: 'SEMRush Key...',
                                inputValue: semrushConnection?.semrushKey || '',
                                inputOnChange: onSemrushKeyDetailsChange,
                                errorMessage: formErrors?.semrush_key,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    };

    return (
        <div className="modal__side-panel">
            {renderModalNavigation()}

            <h2>{modalHeading}</h2>
            <p>{description}</p>
            {renderAccordion()}
        </div>
    );
};

export default SEMRushKeyAddEditModal;
