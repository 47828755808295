import { useAppSelector } from '../../../../redux/hooks';

// Types
import { CubedField } from '../../../../types';
import { ResourceData } from '../../../types';
import { unknownErrorData } from '../../../helpers/errors';
import { UseResourceDateRange } from '../../../types';

// Helpers
import { lineGraphDataSeriesInterpolated } from '../../../helpers/create-data-series';

export type UseResourceLineGraphInterpolatedArgs = {
    resourceData: ResourceData;
    dateDimension?: CubedField;
    seriesField?: CubedField;
    yAxis?: CubedField;
    dateRange?: UseResourceDateRange;
};

const useResourceLineGraphInterpolated = ({
    resourceData,
    dateDimension,
    seriesField,
    yAxis,
    dateRange,
}: UseResourceLineGraphInterpolatedArgs): ResourceData => {
    const dates = useAppSelector(state => state.date);
    const { startDate, endDate } = dateRange ? dateRange : dates;

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success' && dateDimension && seriesField && yAxis) {
        return lineGraphDataSeriesInterpolated(resourceData, startDate, endDate, dateDimension, seriesField, yAxis);
    }

    return unknownErrorData();
};

export default useResourceLineGraphInterpolated;
