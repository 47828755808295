import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

// Context
import { CreateAWidgetContext } from '../context/create-a-widget-context';
import { CreateAWidgetFormContext } from './context/create-a-widget-form-context';

// Hooks
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';

// Helpers
import getMetrics from '../helpers/get-metrics';
import getCurrentSelectedMetrics from '../helpers/get-current-selected-metrics';
import getAvailableResources from '../helpers/get-available-resources';
import validators from '../../../components/forms/validation/validators';

// Components
import Form from '../../../components/forms/form';
import IconTimes from '../../../components/icons/times';
import IconPlus from '../../../components/icons/plus';
import InputErrorMessage from '../../../components/forms/inputs/components/input-error-message';
import FormFieldDimension from './form-fields/form-field-dimension';

const StyledInputWrapper = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
`;

const StyledRemoveButton = styled.button<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    gap: 5px;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: ${props => props.disabled && props.theme.colours.midGrey};
    height: 40px;
    padding: 0px 10px;
`;

const StyledAddButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const StyledAddButton = styled.button<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    gap: 5px;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.9rem;
    color: ${props => props.disabled && props.theme.colours.midGrey};
`;

const StyledIconTimes = styled(IconTimes)`
    width: 14px;
`;

const StyledIconPlus = styled(IconPlus)`
    width: 15px;
`;

const FormTableColumnField = ({
    field,
    index,
    handleRemove,
    handleAddFirstColumn,
}: {
    field: Record<'id', string>;
    index: number;
    handleRemove: (index: number) => void;
    handleAddFirstColumn: () => void;
}) => {
    // Form Values
    const { watch } = useFormContext();
    const formValues = watch();

    // Current Selected Metrics
    const currentMetrics = getCurrentSelectedMetrics(formValues);
    const currentMetricsExcludingThisMetric = currentMetrics.filter(
        (metric: string) => metric !== formValues.fields[index]
    );

    // Current Available Resources
    const currentAvailableResources = getAvailableResources({
        dimension: formValues.groupBy,
        currentMetrics: currentMetrics,
    });

    // Current Available Metrics
    const fieldOptions = getMetrics({
        availableResources: currentAvailableResources,
    }).filter(metric => !currentMetricsExcludingThisMetric.includes(metric.value));

    const formDisabled = formValues.fields.length > index + 1;

    return (
        <Form.Field key={field.id}>
            <Form.InputLabel htmlFor={`fields.${index}`} label={`Column ${index + 1}`} />
            <StyledInputWrapper>
                <Form.InputSelect
                    name={`fields.${index}`}
                    options={fieldOptions}
                    placeholder="Please select a metric"
                    disabled={formDisabled || index === 0}
                    onValueChange={() => handleAddFirstColumn()}
                />
                {index !== 0 ? (
                    <StyledRemoveButton type="button" onClick={() => handleRemove(index)} disabled={formDisabled}>
                        <StyledIconTimes />
                    </StyledRemoveButton>
                ) : null}
            </StyledInputWrapper>
        </Form.Field>
    );
};

const CreateAWidgetFormPieChartTable = () => {
    // Context
    const { orderByDirectionDropdownOptions } = useContext(CreateAWidgetContext);
    const { currentSelectedMetrics, currentAvailableMetrics } = useContext(CreateAWidgetFormContext);

    // Form Values
    const {
        watch,
        formState: { errors },
        clearErrors,
        resetField,
        setValue,
    } = useFormContext();
    const formValues = watch();
    const { fields, append, remove } = useFieldArray({
        name: 'fields',
        rules: {
            validate: values => {
                return typeof values[0] === 'string' || 'Please select at least one column';
            },
        },
    });

    // When the dimension changes, reset the columns
    useEffect(() => {
        remove();
        if (formValues.groupBy !== '') {
            append({
                options: currentAvailableMetrics,
                placeholder: 'Please select a metric',
            });
        }
    }, [formValues.groupBy]); // eslint-disable-line react-hooks/exhaustive-deps

    // Manually clear fieldArray errors if a column is selected
    useEffect(() => {
        if (formValues.fields.length > 0 && typeof formValues.fields[0] === 'string') {
            clearErrors('fields');
        }
    }, [formValues.fields[0]]); // eslint-disable-line react-hooks/exhaustive-deps

    // When a chart metric is selected, set it as the first column
    useEffect(() => {
        resetField('fields');

        if (formValues.orderBy) {
            setValue('fields.0', formValues.orderBy);
        }
    }, [formValues.orderBy]); // eslint-disable-line react-hooks/exhaustive-deps

    // Table column options
    const fieldOptions = currentAvailableMetrics.filter(metric => currentSelectedMetrics.includes(metric.value));

    // States
    const addButtonDisabled =
        formValues.groupBy === '' ||
        (formValues.fields && formValues.fields.length > 0 && !(typeof formValues.fields.at(-1) === 'string')) ||
        currentAvailableMetrics.length === formValues.fields.length;

    const handleRemoveColumn = (index: number) => {
        remove(index);
    };

    // When the first field is selected, update the orderBy
    const handleAddFirstColumn = () => {
        if (formValues.fields.length === 1 && typeof formValues.fields[0] === 'string') {
            setValue('orderBy', formValues.fields[0]);
        }
    };

    return (
        <>
            <Form.Section>
                <Form.SectionTitle>Chart Settings</Form.SectionTitle>
                <FormFieldDimension />
                {formValues.groupBy ? (
                    <>
                        <Form.Field>
                            <Form.InputLabel htmlFor="orderBy" label="Chart Metric" />
                            <Form.InputSelect
                                name="orderBy"
                                options={currentAvailableMetrics}
                                placeholder="Please select a metric"
                                validators={[validators.required]}
                            />
                        </Form.Field>
                    </>
                ) : null}
            </Form.Section>
            {formValues.groupBy ? (
                <Form.Section>
                    <Form.SectionTitle>Table Settings</Form.SectionTitle>

                    {fields.map((field, index) => {
                        return (
                            <FormTableColumnField
                                key={field.id}
                                field={field}
                                index={index}
                                handleRemove={handleRemoveColumn}
                                handleAddFirstColumn={handleAddFirstColumn}
                            />
                        );
                    })}
                    {errors.fields && <InputErrorMessage error={errors.fields.root as FieldError} />}
                    <StyledAddButtonContainer>
                        <StyledAddButton
                            type="button"
                            onClick={() => {
                                append({
                                    defaultValue: '',
                                    options: fieldOptions,
                                    placeholder: 'Please select a metric',
                                });
                            }}
                            disabled={addButtonDisabled}
                        >
                            <StyledIconPlus />
                            Add a Column
                        </StyledAddButton>
                    </StyledAddButtonContainer>

                    <Form.Field>
                        <Form.InputLabel htmlFor="orderByDirection" label="Order By Direction" />
                        <Form.InputSelect
                            name="orderByDirection"
                            options={orderByDirectionDropdownOptions || []}
                            placeholder="Please select a direction"
                        />
                    </Form.Field>
                </Form.Section>
            ) : null}
        </>
    );
};

export default CreateAWidgetFormPieChartTable;
