import React, { useContext, useEffect } from 'react';

import SectionDashboard from '../../section-dashboard/components/section-dashboard';
import { config } from '../configurations/account-health-dashboard';
import { useDispatch } from 'react-redux';
import { setDescription, setTitle } from '../../redux/actions/page-meta';
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';
import { useLocation } from 'react-router';

const AccountHealthDashboard = () => {
    const dispatch = useDispatch();
    const { setFilterStatus, setDatePickerConfig } = useContext(FilterBarContext);

    const location = useLocation();
    location.isSectionDashboard = true;

    useEffect(() => {
        dispatch(setTitle(config.title));
        dispatch(setDescription(config.description));

        // Filter bar configurations
        setFilterStatus(config.filters.status);
        setDatePickerConfig({}); // Reset datepicker
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <SectionDashboard config={config} />;
};

export default AccountHealthDashboard;
