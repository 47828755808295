import React from 'react';
import Select, { ActionMeta } from 'react-select';

type CustomSelectProps<Option> = {
    value: Option;
    name: string;
    placeholder: string;
    options: Option[];
    classNamePrefix?: string;
    onChange: (newValue: Option | null, actionMeta: ActionMeta<Option>) => void;
    onBlur?: () => {};
    isClearable?: boolean;
};

const CustomStyles = {
    control: (provided: {}) => ({
        ...provided,
        border: 0,
        borderBottom: '2px solid #1E1C1C',
        borderRadius: 0,
        width: '100%',
        backgroundColor: '#E9F0F5',
        fontSize: '0.9rem',
        marginBottom: '0',
    }),
    menu: (provided: {}) => ({
        ...provided,
        zIndex: '5',
    }),
};

const CustomSelect = <Option,>({
    value,
    name,
    placeholder,
    options,
    classNamePrefix,
    onChange,
    onBlur,
    isClearable,
}: CustomSelectProps<Option>) => {
    return (
        <Select
            value={value}
            name={name}
            options={options}
            className={`basic-select`}
            classNamePrefix={classNamePrefix}
            placeholder={placeholder}
            styles={CustomStyles}
            onChange={onChange}
            onBlur={onBlur}
            isClearable={isClearable}
        />
    );
};

export default CustomSelect;
