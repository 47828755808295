import { RequestStatus } from '../types';
import * as fields from '../../configurations/fields';

export const pageTypeDropdownRequest = {
    status: RequestStatus.SUCCESS,
    options: [
        {
            label: 'Top URL Folder',
            value: 'topUrlFolder',
            field: fields.PATH_SUBDIRECTORY,
        },
        {
            label: 'Hosts',
            value: 'host',
            field: fields.PATH_HOST,
        },
        {
            label: 'Pages',
            value: 'page',
            field: fields.PATH_PATH,
        },
        {
            label: 'Page Category',
            value: 'pageCategory',
            field: fields.PATH_CATEGORY,
        },
        {
            label: 'Page Market',
            value: 'pageMarket',
            field: fields.PATH_MARKET,
        },
        {
            label: 'Page Language',
            value: 'pageLanguage',
            field: fields.PATH_LANGUAGE,
        },
        {
            label: 'Topic',
            value: 'topic',
            field: fields.PATH_TOPIC,
        },
        {
            label: 'Geography',
            value: 'geography',
            field: fields.PATH_GEOGRAPHY,
        },
        {
            label: 'Content Type',
            value: 'contentType',
            field: fields.PATH_CONTENT_TYPE,
        },
        {
            label: 'Path Content Length',
            value: 'contentLength',
            field: fields.PATH_CONTENT_LENGTH,
        },
        {
            label: 'Homepage(s)',
            value: 'pageHomepage',
            field: fields.PATH_HOMEPAGE,
        },
    ],
};
