import { useRef, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Higcharts
import HighchartsReact from 'highcharts-react-official';

const CommonHighcharts = props => {
    const highchartsContainerRef = useRef(undefined);
    const { showSidebar } = useSelector(state => state.nav);

    useEffect(() => {
        if (highchartsContainerRef.current !== null && highchartsContainerRef.current !== undefined) {
            highchartsContainerRef.current.chart.reflow(false);
        }
    }, [showSidebar, props.width]);

    const containerRef = highchartsContainerRef;
    return <HighchartsReact ref={containerRef} {...props} />;
};

export default CommonHighcharts;
