import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Redux
import { connect } from 'react-redux';
import { setModal, removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setPopup, removePopup } from '../../redux/actions/popup';

// Helpers
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { isInteger, isFloat, isString } from '../../helpers/validator';
import { CONDITION_HEADER, TABLE_REF } from '../../helpers/segmenter/reference-objects';
import { convertFromSeconds, convertToSeconds } from '../../helpers/segmenter/convert-to-seconds';
import { findButtonStates } from '../../helpers/segmenter/button-states';
import { generateTableRowProperty } from '../../helpers/generate-table-styles-property';

// Components
import CommonFormInput from '../../components/common/common-form-input';
import RenderDataTypeField from './render-datatype-field';
import DropdownWithSearch from '../../components/common/dropdown-with-search';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import WidgetActionSimpleTable from '../../components/tables/widgets/widget-action-simple-table';
import LayoutModalNavigation from '../../components/navigation/layout-modal-navigation';
import ActionButtons from '../../components/navigation/action-buttons';
import ToggleWithLabels from '../../components/inputs/toggle-with-labels';
import FunctionReference from './function-reference';

// Styled Components
import {
    StyledForm,
    StyledFormSection,
    StyledToggleContainer,
    StyledH1,
    StyledP,
    StyledErrorMessageP,
} from './create-conditions';

const LayoutModalManageConditions = props => {
    // Data returned from api
    const [dataTypeOperators, setDataTypeOperators] = useState();
    const [conditions, setConditions] = useState();
    const [conditionsToEdit, setConditionsToEdit] = useState([]);
    const [dataTypes, setDataTypes] = useState();
    const [relatedTableData, setRelatedTableData] = useState();
    const [durationItems, setDurationItems] = useState();

    // Selected values
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [updatedConditions, setUpdatedConditions] = useState([]);
    const [deletedConditions, setDeletedConditions] = useState([]);
    const [conditionCurrentlyEditing, setConditionCurrentlyEditing] = useState();
    const [relatedTableDataLoading, setRelatedTableDataLoading] = useState();
    const [currentDataType, setCurrentDateType] = useState();
    const [editingFunctionDataTypes, setEditingFunctionDataTypes] = useState();

    // Button States
    const [newButtonDisabled, setNewButtonDisabled] = useState(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState(true);
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
    const [deleteButtonUndo, setDeleteButtonUndo] = useState(false);
    const [saveChangesButtonHidden, setSaveChangedButtonHidden] = useState(true);
    const [toggleChecked, setToggleChecked] = useState(true);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState(false);

    // Page States
    const [showEditCondition, setShowEditCondition] = useState(false);
    const [pageError, setPageError] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [hideOperatorDropdown, setHideOperatorDropdown] = useState(false);
    const [disableTableClick, setDisableTableClick] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // on page load - fetch conditions & function data
    useEffect(() => {
        fetchAllData();
    }, []);

    // manage status of save button
    useEffect(() => {
        if (updatedConditions.length > 0 || deletedConditions.length > 0) {
            setSaveChangedButtonHidden(false);
        }
    }, [updatedConditions, deletedConditions]);

    // find relevant data types for current condition
    useEffect(() => {
        if (dataTypes && conditionCurrentlyEditing) {
            const dataType = dataTypes.find(
                dataType => dataType.id === conditionCurrentlyEditing.function.data_type.id
            );
            setCurrentDateType(dataType);
        }
    }, [dataTypes, conditionCurrentlyEditing]);

    // if the condition has a toggle set it to the current condition's value
    useEffect(() => {
        if (conditionCurrentlyEditing) {
            if (conditionCurrentlyEditing.value === 'True' || conditionCurrentlyEditing.value === 'False') {
                setToggleChecked(conditionCurrentlyEditing.value === 'True');
            }
        }
    }, [conditionCurrentlyEditing, setToggleChecked]);

    // find relevant operators
    useEffect(() => {
        if (selectedConditions.length === 1 && dataTypeOperators) {
            const relevantOperators = dataTypeOperators
                .filter(option => {
                    if (selectedConditions[0].function?.data_type?.id) {
                        return option.function_data_type.id === selectedConditions[0].function.data_type.id;
                    } else {
                        return option.function_data_type.id === selectedConditions[0].function.dataTypeId;
                    }
                })
                .map(filteredItem => {
                    return {
                        label: filteredItem.function_operator.display_name,
                        value: filteredItem.function_operator.id,
                    };
                });

            setEditingFunctionDataTypes(relevantOperators);

            if (relevantOperators.length === 1) {
                setHideOperatorDropdown(true);
            } else {
                setHideOperatorDropdown(false);
            }
        }
    }, [selectedConditions, dataTypeOperators]);

    // update button states depending on selectedConditions
    useEffect(() => {
        const buttonStates = findButtonStates(
            selectedConditions,
            deletedConditions,
            updatedConditions,
            showEditCondition
        );

        setEditButtonDisabled(buttonStates.editButtonDisabled);
        setDeleteButtonDisabled(buttonStates.deleteButtonDisabled);
        setDeleteButtonUndo(buttonStates.deleteButtonUndo);
        setNewButtonDisabled(buttonStates.newButtonDisabled);
    }, [selectedConditions, deletedConditions, updatedConditions, showEditCondition]);

    // api call - fetch conditions
    const fetchAllData = () => {
        const requests = [];

        // get condition durations
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter-condition-duration', []),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get durations
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter-duration', []),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get conditions
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter-condition', [{ key: 'active', value: true }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get data type operators
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('config', 'data-type-operator', []),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get data types
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('config', 'data-type', []),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    const conditionDurations = responses[0].data.objects;

                    // save durations
                    const durations = responses[1].data.objects.map(duration => {
                        return {
                            ...duration,
                            label: `${duration.name.charAt(0).toUpperCase()}${duration.name.slice(1)}s`,
                            value: duration.id,
                        };
                    });

                    setDurationItems(durations);

                    // find condition's duration unit if applicable and save conditions
                    const conditions = responses[2].data.objects.map(condition => {
                        let conditionDuration = null;
                        if (
                            conditionDurations.map(condition => condition.segmenter_condition.id).includes(condition.id)
                        ) {
                            const matchingCondition = conditionDurations.find(
                                item => item.segmenter_condition.id === condition.id
                            );
                            conditionDuration = matchingCondition.segmenter_duration;
                        }

                        return {
                            ...condition,
                            display_name: condition.display_name === null ? '' : condition.display_name,
                            function: {
                                ...condition.function,
                                label: condition.function.display_name,
                                value: condition.function.id,
                            },
                            function_operator: {
                                ...condition.function_operator,
                                label: condition.function_operator.display_name,
                                value: condition.function_operator.id,
                            },
                            duration_unit: {
                                ...conditionDuration,
                                label: conditionDuration
                                    ? `${conditionDuration.name.charAt(0).toUpperCase()}${conditionDuration.name.slice(
                                          1
                                      )}s`
                                    : '',
                                value: conditionDuration ? conditionDuration.name : '',
                            },
                        };
                    });

                    setConditions(conditions);
                    setConditionsToEdit(conditions);

                    // save data type operators
                    setDataTypeOperators(responses[3].data.objects);

                    // save data types
                    setDataTypes(responses[4].data.objects);
                })
            )
            .catch(e => {
                console.log(e);
                setPageError(true);
            })
            .finally(() => {
                setPageLoading(false);
            });
    };

    // api call - related table
    const getRelatedTableData = (tableName, displayName) => {
        setRelatedTableDataLoading(true);
        let tastypieName = TABLE_REF.find(ref => {
            return ref.tableName === tableName;
        }).tastypieName;

        axios({
            method: 'GET',
            url: generateUrl('config', tastypieName, []),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const relatedTableItems = response.data.objects.map(item => {
                    return {
                        label: item[displayName],
                        value: item.id,
                    };
                });
                setRelatedTableData(relatedTableItems);
                setRelatedTableDataLoading(false);
            })
            .catch(e => {
                console.log(e);
                setPageError(true);
            });
    };

    const handleCreateNew = () => {
        props.setModal('SegmenterCreateConditions', {});
    };

    const handleEditClick = () => {
        const conditionToEdit = { ...selectedConditions[0] };

        // convert duration value to seconds if applicable
        if (
            conditionToEdit?.duration_unit?.name?.length > 0 &&
            !updatedConditions.find(condition => condition.id === conditionToEdit.id)
        ) {
            conditionToEdit.value = convertFromSeconds(
                parseInt(conditionToEdit.value),
                conditionToEdit.duration_unit.name
            );
        }

        setConditionCurrentlyEditing(conditionToEdit);
        setShowEditCondition(true);
        setDisableTableClick(true);

        if (conditionToEdit?.function?.table_name) {
            getRelatedTableData(conditionToEdit.function.table_name, conditionToEdit.function.table_value_name);
        }
    };

    const handleSelectCondition = id => {
        if (disableTableClick) return;

        if (selectedConditions.filter(condition => condition.id === id).length > 0) {
            setSelectedConditions([...selectedConditions.filter(condition => condition.id !== id)]);
        } else {
            setSelectedConditions([...selectedConditions, conditionsToEdit.find(condition => condition.id === id)]);
        }
    };

    const handleDeleteClick = () => {
        if (deleteButtonUndo) {
            setDeletedConditions([...deletedConditions.filter(condition => !selectedConditions.includes(condition))]);
            setSelectedConditions([]);
        } else {
            // if the condition has been edited then delete should take priority
            const deletedIds = deletedConditions.map(condition => condition.id);
            const filteredUpdated = updatedConditions.filter(condition => !deletedIds.includes(condition.id));
            setUpdatedConditions(filteredUpdated);

            setDeletedConditions([...deletedConditions, ...selectedConditions]);
            setSelectedConditions([]);
        }
    };

    const handleInputConditionName = e => {
        setConditionCurrentlyEditing({ ...conditionCurrentlyEditing, display_name: e.target.value });
    };

    const handleSelectedRelatedTableItem = selectedOption => {
        setConditionCurrentlyEditing({
            ...conditionCurrentlyEditing,
            key: relatedTableData.find(item => item.value === selectedOption.value).value,
        });
    };

    const handleSelectedOperator = selectedOption => {
        setConditionCurrentlyEditing({
            ...conditionCurrentlyEditing,
            function_operator: editingFunctionDataTypes.find(item => item.value === selectedOption.value),
        });
    };

    const handleInputValue = e => {
        if (conditionCurrentlyEditing.function.data_type.name === 'string') {
            !isString(e.target.value) ? setErrorMessage('Please enter a valid string') : setErrorMessage('');
        }
        if (conditionCurrentlyEditing.function.data_type.name === 'float') {
            !isFloat(e.target.value) ? setErrorMessage('Please enter a valid decimal number') : setErrorMessage('');
        }
        if (conditionCurrentlyEditing.function.data_type.name === 'number') {
            !isInteger(e.target.value) ? setErrorMessage('Please enter a valid whole number') : setErrorMessage('');
        }
        if (conditionCurrentlyEditing.function.data_type.name === 'duration') {
            !isInteger(e.target.value) ? setErrorMessage('Please enter a valid whole number') : setErrorMessage('');
        }

        setConditionCurrentlyEditing({ ...conditionCurrentlyEditing, value: e.target.value });
    };

    const handleChangeToggle = () => {
        // convert toggle value to string for comparison with original value
        let newValue = '' + !toggleChecked;
        newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);
        setConditionCurrentlyEditing({ ...conditionCurrentlyEditing, value: newValue });
        setToggleChecked(!toggleChecked);
    };

    const handleDurationUnitChange = selectedOption => {
        setConditionCurrentlyEditing({ ...conditionCurrentlyEditing, duration_unit: selectedOption });
    };

    const checkForChanges = (originalCondition, editingCondition) => {
        const nameChanged = originalCondition.display_name !== editingCondition.display_name;
        const operatorChanged = originalCondition.function_operator !== editingCondition.function_operator;
        const valueChanged =
            originalCondition.duration_unit?.value?.length === 0 && originalCondition.value !== editingCondition.value;
        const durationValueChanged =
            originalCondition.duration_unit?.name?.length > 0 &&
            convertFromSeconds(originalCondition.value, originalCondition.duration_unit.name) !==
                editingCondition.value;
        const durationUnitChanged = originalCondition.duration_unit !== editingCondition.duration_unit;
        const relatedItemChanged = originalCondition.key !== editingCondition.key;

        const changed =
            nameChanged ||
            operatorChanged ||
            valueChanged ||
            durationValueChanged ||
            durationUnitChanged ||
            relatedItemChanged;
        return changed;
    };

    const handleApplyClick = e => {
        e.preventDefault();

        const originalCondition = conditions.find(condition => condition.id === conditionCurrentlyEditing.id);

        if (checkForChanges(originalCondition, conditionCurrentlyEditing)) {
            // check if the condition is already uin updated, if so update it
            if (updatedConditions.some(condition => condition.id === conditionCurrentlyEditing.id)) {
                const conditions = updatedConditions.map(condition =>
                    condition.id !== conditionCurrentlyEditing.id ? condition : conditionCurrentlyEditing
                );
                setUpdatedConditions(conditions);
            } else {
                // if the condition isn't already updated, add it to the updated conditions
                setUpdatedConditions([...updatedConditions, conditionCurrentlyEditing]);
            }
        } else {
            // if the condition has been updated but then reverted, remove it from updated conditions
            if (updatedConditions.some(condition => condition.id === conditionCurrentlyEditing.id)) {
                const updated = updatedConditions.filter(condition => condition.id !== conditionCurrentlyEditing.id);
                setUpdatedConditions(updated);
            }
        }

        // update to conditions to show changes
        const tempConditions = conditionsToEdit.map(condition => {
            if (conditionCurrentlyEditing.id === condition.id) {
                return conditionCurrentlyEditing;
            } else {
                return condition;
            }
        });

        setConditionsToEdit(tempConditions);
        setRelatedTableData(null);
        setShowEditCondition(false);
        setConditionCurrentlyEditing();
        setSelectedConditions([]);
        setDisableTableClick(false);
    };

    const handleCancelEdit = e => {
        e.preventDefault();
        setShowEditCondition(false);
        setRelatedTableData(null);
        setConditionCurrentlyEditing();
        setSelectedConditions([]);
        setDisableTableClick(false);
        setErrorMessage('');
    };

    const handleSaveChanges = e => {
        setSaveChangesButtonLoading(true);
        const requests = [];

        updatedConditions.forEach(condition => {
            // convert duration values to seconds
            if (condition?.duration_unit?.name?.length > 0) {
                condition.value = convertToSeconds(condition.value, condition.duration_unit.name);
            }
            // save selector types to value
            if (condition.function.data_type.name === 'selector') {
                condition.value = condition.key;
            }
        });

        if (updatedConditions.length > 0) {
            requests.push(
                axios({
                    method: 'PATCH',
                    url: generateUrl('segmenter', 'segmenter-condition'),
                    data: {
                        objects: updatedConditions,
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        }

        if (deletedConditions.length > 0) {
            deletedConditions.forEach(condition => {
                requests.push(
                    axios({
                        method: 'DELETE',
                        url: generateUrlDetail('segmenter', 'segmenter-condition', condition.id),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
        }

        axios
            .all(requests)
            .then(
                axios.spread(() => {
                    setSaveChangesButtonLoading(false);
                    props.addNotification({
                        copy: 'These changes have been successfully saved.',
                        type: NotificationMessageType.Success,
                    });

                    setUpdatedConditions([]);
                    setDeletedConditions([]);
                    setSaveChangedButtonHidden(true);

                    if (deletedConditions.length > 0) {
                        const filteredConditions = conditions.filter(
                            ({ id: id1 }) => !deletedConditions.some(({ id: id2 }) => id2 === id1)
                        );

                        setConditions(filteredConditions);
                        setConditionsToEdit(filteredConditions);
                    }
                })
            )
            .catch(e => {
                console.log(e);
                if (e.response.data.code === 409) {
                    props.addNotification({
                        copy: e.response.data.message,
                        type: NotificationMessageType.Error,
                    });
                    setSaveChangesButtonLoading(false);
                } else {
                    props.addNotification({
                        copy: 'There was an issue trying to save your condition. Please try again later or contact Cubed Support.',
                        type: NotificationMessageType.Error,
                    });
                }
            })
            .finally(() => {
                setConditions(updatedConditions);
            });
    };

    const handlePopupDiscardClick = () => {
        props.removePopup();
        props.removeModal();
    };

    const handlePopupStayClick = () => {
        props.removePopup();
    };

    const handleCloseClick = () => {
        if (updatedConditions.length === 0 && deletedConditions.length === 0) {
            props.removeModal();
        } else {
            props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost',
                },
                buttons: [
                    {
                        onClick: handlePopupDiscardClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: handlePopupStayClick,
                        value: 'STAY HERE',
                        style: 'secondary',
                    },
                ],
            });
        }
    };

    const renderRelevantInputField = () => {
        if (conditionCurrentlyEditing) {
            const dataType = dataTypes.find(type => type.id === conditionCurrentlyEditing.function.data_type.id).name;

            return (
                <RenderDataTypeField
                    dataType={dataType}
                    value={conditionCurrentlyEditing.value}
                    durationValue={conditionCurrentlyEditing.value}
                    onInputChange={handleInputValue}
                    durationItems={durationItems}
                    selectedDurationUnit={conditionCurrentlyEditing.duration_unit}
                    onDurationValueChange={handleInputValue}
                    onDurationInputUnitChange={handleDurationUnitChange}
                />
            );
        }
    };

    const rows = pageLoading
        ? {} // Wait for page to load and then do mapping
        : conditionsToEdit.map(data => {
              return {
                  onClick: () => handleSelectCondition(data.id),
                  keyValue: `segment__${data.id}`,
                  dataValue: data.id,
                  rowProperty: generateTableRowProperty(
                      data,
                      selectedConditions,
                      updatedConditions,
                      deletedConditions,
                      disableTableClick
                  ),
                  columns: [
                      {
                          copy: data?.display_name,
                      },
                      {
                          copy: data.function?.display_name,
                      },
                  ],
              };
          });

    if (pageError) {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={false}
                    updatedData={updatedConditions}
                    deletedData={deletedConditions}
                    handleSaveChanges={handleSaveChanges}
                    saveChangesButtonHidden={saveChangesButtonHidden}
                    saveChangesButtonLoading={saveChangesButtonLoading}
                    handleCloseClick={handleCloseClick}
                />

                <StyledH1>Create A Condition</StyledH1>

                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </>
        );
    }
    if (!pageLoading) {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={false}
                    updatedData={updatedConditions}
                    deletedData={deletedConditions}
                    handleSaveChanges={handleSaveChanges}
                    saveChangesButtonHidden={saveChangesButtonHidden}
                    saveChangesButtonLoading={saveChangesButtonLoading}
                    handleCloseClick={handleCloseClick}
                />

                <StyledH1>Manage Conditions</StyledH1>

                <StyledP>
                    Add, edit or delete existing conditions. Conditions are the building blocks of Segments, where one,
                    or many conditions are used to build Segments.
                </StyledP>

                <div>
                    {conditionsToEdit && (
                        <WidgetActionSimpleTable
                            isFrom="Condition"
                            rowData={rows}
                            header={CONDITION_HEADER}
                            errorMessageOverride="You have not created any segment conditions yet."
                            newButtonDisabled={newButtonDisabled}
                            handleCreateNew={handleCreateNew}
                            editButtonDisabled={editButtonDisabled}
                            handleEditClick={handleEditClick}
                            deleteButtonUndo={deleteButtonUndo}
                            deleteButtonDisabled={deleteButtonDisabled}
                            handleDeleteClick={handleDeleteClick}
                            isScrollable={false}
                        />
                    )}
                </div>
                {showEditCondition && (
                    <div>
                        <StyledForm>
                            <StyledFormSection>
                                <CommonFormInput
                                    label="Condition Name:"
                                    toolTipCopy="Enter a name for your condition"
                                    onChange={handleInputConditionName}
                                    value={conditionCurrentlyEditing.display_name}
                                    requiredField={true}
                                />
                            </StyledFormSection>

                            <StyledFormSection>
                                <CommonFormInput
                                    label="Function:"
                                    toolTipCopy="The selected function"
                                    value={conditionCurrentlyEditing.function.display_name}
                                    requiredField={true}
                                    disabled={true}
                                />

                                {currentDataType && currentDataType.name === 'bool' && (
                                    <StyledToggleContainer>
                                        <ToggleWithLabels
                                            trueLabel={'True'}
                                            falseLabel={'False'}
                                            checked={toggleChecked}
                                            onClick={handleChangeToggle}
                                        />
                                    </StyledToggleContainer>
                                )}
                            </StyledFormSection>

                            {!relatedTableDataLoading && relatedTableData && (
                                <StyledFormSection>
                                    <DropdownWithSearch
                                        dropdownItems={relatedTableData}
                                        placeholder={'Select an item:'}
                                        label={'Related Item'}
                                        toolTipCopy={'Select a related item'}
                                        requiredField={true}
                                        selected={relatedTableData.find(
                                            item => item.value === parseInt(conditionCurrentlyEditing.key)
                                        )}
                                        onSelectedItemChange={handleSelectedRelatedTableItem}
                                    />
                                </StyledFormSection>
                            )}
                            {relatedTableDataLoading && (
                                <StyledFormSection>
                                    <LoadingSpinner />
                                </StyledFormSection>
                            )}

                            <StyledFormSection>
                                <DropdownWithSearch
                                    dropdownItems={editingFunctionDataTypes}
                                    placeholder={'Select an operator'}
                                    label={'Operator:'}
                                    toolTipCopy={'Select an operator'}
                                    requiredField={true}
                                    selected={conditionCurrentlyEditing.function_operator}
                                    onSelectedItemChange={handleSelectedOperator}
                                    hidden={hideOperatorDropdown}
                                />

                                {currentDataType && currentDataType.name !== 'bool' && renderRelevantInputField()}
                            </StyledFormSection>

                            <ActionButtons
                                handleApplyClick={handleApplyClick}
                                applyButtonDisabled={errorMessage && errorMessage.length > 0}
                                handleCancelEdit={handleCancelEdit}
                            />
                            {errorMessage && (
                                <StyledFormSection>
                                    <StyledErrorMessageP>{errorMessage}</StyledErrorMessageP>
                                </StyledFormSection>
                            )}
                        </StyledForm>
                        <FunctionReference selectedFunction={conditionCurrentlyEditing.function} />
                    </div>
                )}
            </>
        );
    } else {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={false}
                    updatedData={updatedConditions}
                    deletedData={deletedConditions}
                    handleSaveChanges={handleSaveChanges}
                    saveChangesButtonHidden={saveChangesButtonHidden}
                    saveChangesButtonLoading={saveChangesButtonLoading}
                    handleCloseClick={handleCloseClick}
                />

                <StyledH1>Manage Conditions</StyledH1>

                <LoadingSpinner />
            </>
        );
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        removeModal: () => {
            dispatch(removeModal());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(null, mapDispatchToProps)(LayoutModalManageConditions);
