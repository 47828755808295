import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';
import { useLayoutContext } from '../../context/layout-context';

// Types
import { SelectOptionsRequest, RequestStatus, SectionDashboardLayout } from '../../../../types';

// Icons
import IconChevronUp from '../../../../../components/icons/chevron-up';
import IconCheck from '../../../../../components/icons/check';
import IconTimes from '../../../../../components/icons/times';
import IconCross from '../../../../../components/icons/cross';
import IconChevronLeftThin from '../../../../../components/icons/chevron-left-thin';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.textColor};
`;

const StyledLabel = styled.div`
    font-size: 0.9rem;
    font-weight: ${props => props.theme.sectionDashboard.section.sectionMenu.select.labelFontWeight};
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.labelColor};
    text-transform: uppercase;
`;

const StyledTrigger = styled(DropdownMenu.Trigger)`
    all: unset !important;
    max-width: 40px;
    background-color: unset;
    border: none;
    cursor: pointer !important;
`;

const StyledTriggerContent = styled.div<{ empty?: boolean }>`
    border: 2px solid ${props => props.theme.sectionDashboard.section.sectionMenu.select.borderColor};
    border-radius: 5px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    min-width: 150px;
    font-weight: 500;
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: ${props => props.theme.sectionDashboard.widget.menu.triggerBackgroundColor};
`;

const StyledSubTriggerBase = css`
    height: 18px;
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 20px 20px;
    outline: none;
    font-weight: 500;
`;

const StyledSubTrigger = styled(DropdownMenu.SubTrigger)`
    ${StyledSubTriggerBase}
    cursor: pointer;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
    }
`;

const StyledSubTriggerEmpty = styled.div`
    ${StyledSubTriggerBase}
    cursor: not-allowed;
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.textColorEmpty};
`;

const StyledOptionLabel = styled.div<{ empty?: boolean }>`
    font-size: 1rem;
    padding: 5px 10px;
    color: ${props => props.empty && props.theme.sectionDashboard.section.sectionMenu.select.optionLabelColorEmpty};
`;

const StyledIconContainer = styled.div`
    padding: 0px 10px;
    height: 100%;
    border-left: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
`;

const StyledIconChevronSmall = styled(IconChevronUp)<{ empty?: boolean }>`
    transform: rotate(180deg);
    height: 14px;
    margin-top: 6px;
    width: auto;
    & path {
        fill: ${props =>
            props.empty
                ? props.theme.sectionDashboard.widget.menu.separatorColor
                : props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledIconChevronLeftThin = styled(IconChevronLeftThin)`
    height: 18px;
    width: auto;
`;

const StyledContentBase = css`
    background-color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.backgroundColor};
    min-width: 240px;
    padding: 10px 0px;
    max-height: 50vh;
    overflow-y: auto;
    box-shadow: ${props => props.theme.sectionDashboard.widget.menu.shadow};
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.textColor};
`;

const StyledContent = styled(DropdownMenu.Content)`
    ${StyledContentBase}
    box-shadow: 0px 1px 25px -1px rgba(0, 0, 0, 0.1), 0px -65px 12px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 21; // Overlap the top bar
    margin-top: 62px;
`;

const StyledSubContent = styled(DropdownMenu.SubContent)<{ layout: SectionDashboardLayout }>`
    ${StyledContentBase}
    box-shadow: 0px 1px 25px -1px rgba(0, 0, 0, 0.1), 0px 1px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    z-index: 51; // Overlap the sidebar
    max-width: ${props =>
        (props.layout === SectionDashboardLayout.SM || props.layout === SectionDashboardLayout.XS) && '300px'};
`;

const StyledActionsContainer = styled.div<{ isSubMenu?: boolean }>`
    position: ${props => !props.isSubMenu && 'absolute'};
    top: ${props => !props.isSubMenu && 0};
    right: ${props => !props.isSubMenu && 0};
    left: ${props => !props.isSubMenu && 0};
    background-color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.backgroundColor};
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 15px 15px;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
`;

const StyledActionButton = styled.button<{ disabled: boolean }>`
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.backgroundColor};
    border: 1px solid
        ${props =>
            props.disabled
                ? props.theme.sectionDashboard.section.sectionMenu.select.actionButton.borderColorDisabled
                : props.theme.sectionDashboard.section.sectionMenu.select.actionButton.borderColor};
    cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
    font-size: 0.8rem;
    font-weight: 600;
    padding: 8px 10px;
    border-radius: 5px;
    color: ${props =>
        props.disabled
            ? props.theme.sectionDashboard.section.sectionMenu.select.actionButton.textColorDisabled
            : props.theme.sectionDashboard.widget.menu.textColor};
    text-transform: uppercase;

    &:hover {
        background-color: ${props => !props.disabled && props.theme.sectionDashboard.widget.menu.backgroundHover};
    }
`;

const StyledIconButtonSelectAll = styled(IconCheck)`
    width: 10px;
`;

const StyledIconButtonClear = styled(IconCross)`
    width: 7px;
`;

const StyledSelectedItems = styled.div`
    display: flex;
    gap: 5px;
`;

const StyledIconTimes = styled(IconTimes)`
    color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.actionButton.iconColor};
    width: 8px;
`;

const StyledSelectedItem = styled.div<{ isMore?: boolean }>`
    display: flex;
    gap: 10px;
    border: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    padding: 1px 5px;
    font-size: 0.8rem;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: ${props => (props.isMore ? 'default' : 'pointer')};
    ${props => props.theme.boxShadow(0.5)}

    &:hover {
        ${StyledIconTimes} {
            color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
        }
    }
`;

const StyledDropdownItem = styled(DropdownMenu.Item)`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 10px 15px;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
        border: none;
        outline: none;
    }
`;

const StyledIconCheck = styled(IconCheck)<{ visible?: boolean }>`
    width: 18px;
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const StyledLoadingContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledDropdownSkeleton = styled.div<{
    viewportSize: SectionDashboardLayout;
    smallViewports: SectionDashboardLayout[];
}>`
    height: 19px;
    width: ${props => (props.smallViewports.includes(props.viewportSize) ? '20px' : '150px')};
    background: ${props => props.theme.sectionDashboard.section.sectionMenu.select.skeletonColor};
    border-radius: ${props => (props.smallViewports.includes(props.viewportSize) ? '10px' : '5px')};
`;

export type WidgetMenuSectionMultiSelectProps = {
    label?: string;
    dropdownOptions: SelectOptionsRequest;
    onOptionChange: (option: string) => void;
    onSelectedItemClick: (option: string) => void;
    onClearClick: () => void;
    onSelectAllClick: () => void;
    emptyMessage: string;
};

const WidgetMenuSectionMultiSelect = ({
    label,
    dropdownOptions,
    onOptionChange,
    onSelectedItemClick,
    onClearClick,
    onSelectAllClick,
    emptyMessage,
}: WidgetMenuSectionMultiSelectProps) => {
    const { layout } = useLayoutContext();
    const largeViewports = [SectionDashboardLayout.XL, SectionDashboardLayout.LG];
    const smallViewports = [SectionDashboardLayout.MD, SectionDashboardLayout.SM, SectionDashboardLayout.XS];
    const selectedItems = dropdownOptions.options.filter(option => option.selected);
    const allSelected = dropdownOptions.options.every(option => option.selected);
    const noneSelected = dropdownOptions.options.every(option => !option.selected);

    const renderSelectedItems = () => {
        const maxDisplayNumber = [SectionDashboardLayout.XL, SectionDashboardLayout.LG].includes(layout) ? 2 : 1;

        if (selectedItems.every(item => item.selected === false)) {
            return 'Select...';
        }

        if (dropdownOptions.options.every(item => item.selected === true)) {
            return `All ${label}`;
        }

        return (
            <StyledSelectedItems>
                {selectedItems.slice(0, maxDisplayNumber).map(item => {
                    return (
                        <StyledSelectedItem
                            onClick={() => selectedItems.length > 1 && onSelectedItemClick(item.value)}
                            key={item.value}
                        >
                            {item.label}
                            {selectedItems.length > 1 && <StyledIconTimes />}
                        </StyledSelectedItem>
                    );
                })}
                {selectedItems.length > maxDisplayNumber && (
                    <StyledSelectedItem isMore={true}>
                        +{selectedItems.length - maxDisplayNumber} more
                    </StyledSelectedItem>
                )}
            </StyledSelectedItems>
        );
    };

    const renderTrigger = ({ isEmpty }: { isEmpty: boolean }) => {
        return (
            <>
                {layout === SectionDashboardLayout.XL && label && <StyledLabel>{label}:</StyledLabel>}
                {largeViewports.includes(layout) && (
                    <StyledTriggerContent empty={isEmpty}>
                        <StyledOptionLabel empty={isEmpty}>
                            {isEmpty ? emptyMessage || 'No items found' : renderSelectedItems()}
                        </StyledOptionLabel>
                        <StyledTrigger>
                            <StyledIconContainer>
                                <StyledIconChevronSmall empty={isEmpty} />
                            </StyledIconContainer>
                        </StyledTrigger>
                    </StyledTriggerContent>
                )}
            </>
        );
    };

    const renderContent = ({ isSubMenu }: { isSubMenu: boolean }) => {
        return (
            <>
                <StyledActionsContainer isSubMenu={isSubMenu}>
                    <StyledActionButton onClick={onSelectAllClick} disabled={allSelected}>
                        <StyledIconButtonSelectAll />
                        Select All
                    </StyledActionButton>
                    <StyledActionButton onClick={onClearClick} disabled={noneSelected}>
                        <StyledIconButtonClear />
                        Clear
                    </StyledActionButton>
                </StyledActionsContainer>

                {dropdownOptions.options.map(option => {
                    return (
                        <StyledDropdownItem
                            onClick={() => onOptionChange(option.value)}
                            onSelect={event => event.preventDefault()}
                            key={option.value}
                        >
                            <StyledIconCheck visible={option.selected} />
                            {option.label}
                        </StyledDropdownItem>
                    );
                })}
            </>
        );
    };

    if (dropdownOptions.status === RequestStatus.LOADING) {
        return (
            <StyledLoadingContainer>
                {layout === SectionDashboardLayout.XL && label && <StyledLabel>{label}:</StyledLabel>}
                <StyledDropdownSkeleton viewportSize={layout} smallViewports={smallViewports} />
            </StyledLoadingContainer>
        );
    }

    if (dropdownOptions.status === RequestStatus.EMPTY || dropdownOptions.status === RequestStatus.ERROR) {
        if (smallViewports.includes(layout)) {
            return (
                <StyledSubTriggerEmpty>
                    <StyledIconChevronLeftThin />
                    {label}
                </StyledSubTriggerEmpty>
            );
        }

        return (
            <StyledLoadingContainer>
                <StyledContainer>
                    <DropdownMenu.Root>{renderTrigger({ isEmpty: true })}</DropdownMenu.Root>
                </StyledContainer>
            </StyledLoadingContainer>
        );
    }

    if (dropdownOptions.status === RequestStatus.SUCCESS) {
        if (smallViewports.includes(layout)) {
            return (
                <DropdownMenu.Sub>
                    <StyledSubTrigger>
                        <StyledIconChevronLeftThin />
                        {label}
                    </StyledSubTrigger>
                    <DropdownMenu.Portal>
                        <StyledSubContent layout={layout}>{renderContent({ isSubMenu: true })}</StyledSubContent>
                    </DropdownMenu.Portal>
                </DropdownMenu.Sub>
            );
        }

        return (
            <StyledContainer>
                <DropdownMenu.Root>
                    {renderTrigger({ isEmpty: false })}

                    <DropdownMenu.Portal>
                        <StyledContent side="bottom" align="end">
                            {renderContent({ isSubMenu: false })}
                        </StyledContent>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            </StyledContainer>
        );
    }
    return null;
};

export default WidgetMenuSectionMultiSelect;
