import React, { Component } from 'react';

import IconPerson from '../icons/person';

class ListRowUserDetails extends Component {
    render() {
        return (
            <div data-key={this.props.keyValue} className="list-item__user-details" onClick={this.props.onClick}>
                <div className="list-item__user-details__icon">
                    <div className="icon icon-person">
                        <IconPerson />
                    </div>
                </div>

                <div className="list-item__user-details__content">
                    <h4>Name:</h4>
                    <p>{this.props.name}</p>
                    <h4>Email:</h4>
                    <p>{this.props.email}</p>
                </div>
            </div>
        );
    }
}
export default ListRowUserDetails;
