import React, { useEffect, useState } from 'react';
import NavDropdownGroup from './nav-dropdown-group';
import NavDropdownItems from './nav-dropdown-items';
import NavItem from './nav-item';

const NavAccordion = ({ navItem, sidebarVisible, toggleSidebar, selectedItem, setSelectedItem, onHomeClick }) => {
    const [dropdownIsActive, setDropdownIsActive] = useState(false);

    useEffect(() => {
        if (navItem.dropdownItems || navItem.dropdownGroups) {
            if (navItem.displayName !== selectedItem.group) {
                setDropdownIsActive(false);
            }
        }
    }, [selectedItem, navItem.dropdownItems, navItem.dropdownGroups, navItem.displayName]);

    const handleItemEnterPress = e => {
        if (e.key === 'Enter') {
            setSelectedItem({ group: navItem.displayName });
            setDropdownIsActive(!dropdownIsActive);
        }
    };

    const handleItemClick = () => {
        setSelectedItem({ group: navItem.displayName });
        setDropdownIsActive(!dropdownIsActive);
    };

    const handleCollapsedItemClick = () => {
        setSelectedItem({ group: navItem.displayName });
        toggleSidebar();
        setDropdownIsActive(!dropdownIsActive);
    };

    return (
        <li key={navItem.displayName}>
            <NavItem
                navItem={navItem}
                dropdownIsActive={dropdownIsActive}
                onItemClick={handleItemClick}
                onItemEnterPress={handleItemEnterPress}
                sidebarVisible={sidebarVisible}
                onCollapsedItemClick={handleCollapsedItemClick}
                onHomeClick={onHomeClick}
                toggleSidebar={toggleSidebar}
            />

            {navItem.hasDropdown && dropdownIsActive && (
                <NavDropdownItems dropdownItems={navItem.dropdownItems} sidebarVisible={sidebarVisible} />
            )}

            {navItem.hasSubGroups && dropdownIsActive && (
                <NavDropdownGroup
                    dropdownGroups={navItem.dropdownGroups}
                    sidebarVisible={sidebarVisible}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    hasSiblingMenuItems={navItem.hasDropdown}
                />
            )}
        </li>
    );
};

export default NavAccordion;
