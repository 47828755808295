import { v4 as uuidv4 } from 'uuid';
import { OPERATORS } from './reference-objects';
import { isNumber } from '../../helpers/validator';

export class GroupRule {
    constructor(operator) {
        this.id = uuidv4();
        this.operator = operator;
        this.selectedConditions = [];
        this.groupItems = [];
    }

    clone() {
        const newGroupRule = new GroupRule(this.operator);

        newGroupRule.id = this.id;
        newGroupRule.selectedConditions = this.selectedConditions;
        newGroupRule.groupItems = this.groupItems.map(item => {
            return item.clone();
        });

        return newGroupRule;
    }

    addCondition(condition) {
        this.selectedConditions = [...this.selectedConditions, condition];
    }

    removeCondition(removedCondition) {
        this.selectedConditions = this.selectedConditions.filter(condition => condition.id !== removedCondition.id);
    }

    addGroup(operator) {
        const newGroup = new GroupRule(operator);
        this.groupItems = [...this.groupItems, newGroup];
        return newGroup;
    }

    updateOperator(newOperator) {
        this.operator = newOperator;
    }

    serialize() {
        let rule = '';
        const hasConditions = this.selectedConditions.length > 0;

        if (hasConditions) {
            const conditionIds = this.selectedConditions.map(condition => condition.id);
            rule = conditionIds.join(' ' + this.operator.value + ' ');
        }

        this.groupItems.forEach((groupItem, index) => {
            let subRule = groupItem.serialize();
            if (subRule !== '') {
                if (hasConditions || index > 0) {
                    rule += ` ${this.operator.value} `;
                }
                rule += `(${subRule})`;
            }
        });
        return rule;
    }

    deserialize(pattern, conditions) {
        const newPattern = pattern.toLowerCase();
        const groups = [];
        const previousGroup = [];

        let currentGroup;

        // used to skip "idx" in the forEach() loop
        let skipTillId = null;
        newPattern.split('').forEach((chara, idx) => {
            if (skipTillId && idx < skipTillId) {
                return;
            }
            if (chara === '(') {
                let subPattern = newPattern.substring(idx).split(')')[0];
                let andDistance = subPattern.indexOf('and');
                let orDistance = subPattern.indexOf('or');

                let tmpOperator = OPERATORS[0];
                if (andDistance === -1 || (orDistance > 0 && orDistance < andDistance)) {
                    tmpOperator = OPERATORS[1];
                }

                let tmpGroup;

                if (!currentGroup) {
                    currentGroup = new GroupRule(tmpOperator);
                    return;
                } else {
                    tmpGroup = currentGroup.addGroup(tmpOperator);
                }

                previousGroup.push(currentGroup);
                currentGroup = tmpGroup;

                return;
            }

            if (chara === ')') {
                if (groups.length === 0 || !previousGroup.length <= 1) {
                    groups.push(currentGroup);
                }

                let tmpPrevious = previousGroup.pop();
                if (tmpPrevious) currentGroup = tmpPrevious;
                return;
            }

            let conditionId = null;
            let operatorType = null;

            if (isNumber(chara)) {
                conditionId = '' + chara; // force this to be a string
            } else {
                if (chara === 'a') {
                    operatorType = OPERATORS[0];
                }
                if ('chara' === 'o') {
                    operatorType = OPERATORS[1];
                }
                if (operatorType && currentGroup) {
                    currentGroup.operator = operatorType;
                    operatorType = null;
                }
            }

            if (conditionId) {
                let tempIdx = idx + 1;
                while (true) {
                    let tempChara = newPattern.substring(tempIdx, tempIdx + 1);
                    if (tempChara !== '' && isNumber(tempChara)) {
                        conditionId += '' + tempChara;
                        tempIdx += 1;
                    } else {
                        // if we've finished finding our conditionId, set the skipIdx to the last seen non-number chara
                        skipTillId = tempIdx;
                        break;
                    }
                }
                let nextCondition = conditions.find(condition => condition.id === parseInt(conditionId));
                if (nextCondition) currentGroup.addCondition(nextCondition);
            }
        });

        return groups.pop();
    }
}
