import React from 'react';
import { WidgetConfig } from '../../types';
import WidgetTextBox from '../../../section-dashboard/widgets/widget/widget-text-box';
import CreateAWidgetPreviewMessage from '../../create-a-widget/components/create-a-widget-preview-message';
import { WidgetColour } from '../../../section-dashboard/types';

const WidgetPreviewTextBox = ({ config }: { config: WidgetConfig }) => {
    const widgetColor = config.widget_color;

    if (config.copy) {
        return (
            <WidgetTextBox
                copy={config.copy}
                buttonCopy={config.button_copy}
                buttonHref={config.button_href}
                colour={WidgetColour[widgetColor as keyof typeof WidgetColour]}
                textJustify={config.text_justify}
                fontSize={config.font_size}
            />
        );
    }

    return <CreateAWidgetPreviewMessage message="Please enter some text copy to preview the text box widget" />;
};

export default WidgetPreviewTextBox;
