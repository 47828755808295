/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// Components
import SimpleTable from '../components/simple-table';
import TableFilterSearch from '../components/filters/table-filter-search';

// Types
import { SimpleTableColumn, SimpleTableHeader, SimpleTableRow } from '../types';

type Config = {
    columns?: SimpleTableColumn[];
    rows: SimpleTableRow[];
    header?: SimpleTableHeader;
    disableSelectOverride?: boolean;
    hasTotals?: boolean;
    enableSearch?: boolean;
    searchableColumns?: number[];
    hasIcons?: boolean;
    errorMessageOverride?: string;
    disabled?: boolean;
    onSelectedItemsChange?: (selectedItems: SimpleTableRow[]) => void;
    filters?: [];
    isScrollable: boolean;
    hasPills?: boolean;
};

type OrderBy = {
    column: number | null;
    order: 'asc' | 'desc' | null;
};

type CurrentFilter = {
    filter?: undefined;
};

export type WidgetSelectableSimpleTableProps = {
    config: Config;
};

const WidgetSelectableSimpleTable = ({ config }: WidgetSelectableSimpleTableProps) => {
    const [listItems, setListItems] = useState(config.rows);
    const [visibleItems, setVisibleItems] = useState<SimpleTableRow[]>(config.rows);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState<SimpleTableRow[]>([]);
    const [currentFilter, setCurrentFilter] = useState<CurrentFilter>({});
    const [currentSearchValue, setCurrentSearchValue] = useState('');
    const [orderBy, setOrderBy] = useState<OrderBy>({
        column: null,
        order: null,
    });

    useEffect(() => {
        if (config.onSelectedItemsChange !== undefined) {
            config.onSelectedItemsChange(selectedItems);
        }
    }, [selectedItems]);

    useEffect(() => {
        setListItems(config.rows);
        setVisibleItems(handleOrderBy(searchRows(filterRows(config.rows, currentFilter), currentSearchValue), orderBy));
    }, [config]);

    const filterRows = (rows: SimpleTableRow[], selectedFilter: CurrentFilter) => {
        // If no filter is selected return rows
        if (
            Object.keys(selectedFilter).length === 0 ||
            selectedFilter === undefined ||
            selectedFilter.filter === undefined
        ) {
            return rows;
        }

        return rows.filter(selectedFilter.filter);
    };

    const searchRows = (rows: SimpleTableRow[], searchTerm: string) => {
        // If search term is empty return the array
        if (searchTerm === '' || searchTerm === undefined) {
            return rows;
        }

        const searchTermRegex = new RegExp(searchTerm, 'i');

        return rows.filter(row => {
            let searchTermFound = false;

            row.columns.forEach((column, index) => {
                if (config.searchableColumns === null || config.searchableColumns?.includes(index)) {
                    if (column.copy !== undefined && searchTermRegex.test(column.copy)) {
                        searchTermFound = true;
                    }
                }
            });

            return searchTermFound;
        });
    };

    const handleOrderBy = (rows: SimpleTableRow[], orderBy: OrderBy) => {
        if (orderBy.column === null) {
            return rows;
        }

        return rows.sort((a, b) => {
            const firstRow =
                a.columns[orderBy.column!].rawValue !== undefined
                    ? a.columns[orderBy.column!].rawValue
                    : a.columns[orderBy.column!].copy;
            const secondRow =
                b.columns[orderBy.column!].rawValue !== undefined
                    ? b.columns[orderBy.column!].rawValue
                    : b.columns[orderBy.column!].copy;

            if (firstRow! > secondRow!) return orderBy.order === 'asc' ? -1 : 1;
            else return orderBy.order === 'asc' ? 1 : -1;
        });
    };

    const handleParameterOnChange = (filter: CurrentFilter, searchTerm: string) => {
        setCurrentFilter(filter);
        setSearchTerm(searchTerm);
        setVisibleItems(handleOrderBy(searchRows(filterRows(listItems, filter), searchTerm), orderBy));
    };

    const handleColumnOrderOnChange = (column: number, order: 'asc' | 'desc') => {
        const orderBy = {
            column: column,
            order: order,
        };

        setOrderBy(orderBy);
        setVisibleItems(handleOrderBy(searchRows(filterRows(listItems, currentFilter), searchTerm), orderBy));
    };

    const handleItemOnClick = (event: React.MouseEvent<HTMLTableRowElement>, item: SimpleTableRow) => {
        const selectedItemsCopy: SimpleTableRow[] = [...selectedItems];
        const itemInSelectedItems = selectedItemsCopy.filter(filteredItem => filteredItem.id === item.id);

        // If the item had already been selected remove it
        if (itemInSelectedItems.length === 1) {
            const itemIndexInSelectedItems = selectedItemsCopy.indexOf(itemInSelectedItems[0]);
            selectedItemsCopy.splice(itemIndexInSelectedItems, 1);
        } else {
            selectedItemsCopy.push(item);
        }

        setSelectedItems(selectedItemsCopy);
    };

    const renderSimpleTable = () => {
        // Go through all the items and for any that are selected add the selected
        const items = visibleItems.map(item => {
            if (selectedItems.filter(filteredItem => filteredItem.id === item.id).length === 1) {
                item.selected = true;
            } else {
                item.selected = false;
            }

            return item;
        });

        // Older Simple Tables use to have props.config.header, this has now been replaced with props.config.columns
        let columns = config.columns;
        if (columns === undefined) {
            columns = config.header?.columns;
        }

        return (
            <div>
                <TableFilterSearch
                    enableSearch={config.enableSearch}
                    filters={config.filters}
                    onParameterChange={handleParameterOnChange}
                />

                <SimpleTable
                    rows={items}
                    columns={columns}
                    errorMessageOverride={config.errorMessageOverride}
                    onRowClick={handleItemOnClick}
                    selectDisabled={config.disabled}
                    selectableRows={config.disableSelectOverride !== undefined ? config.disableSelectOverride : true}
                    hasIcons={config.hasIcons}
                    onColumnOrderChanged={handleColumnOrderOnChange}
                    currentOrderBy={orderBy}
                    hasTotals={config.hasTotals}
                    isScrollable={config.isScrollable}
                    hasPills={config.hasPills}
                />
            </div>
        );
    };

    return <>{renderSimpleTable()}</>;
};

export default WidgetSelectableSimpleTable;
