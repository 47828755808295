import React, { useContext } from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

// Context
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

// Types
import { FieldValues } from 'react-hook-form';

// Components
import IconAdd from '../../../components/icons/add';
import CreateADashboardDraggableItem from './create-a-dashboard-draggable-item';
import Popover from '../../components/popover/popover';
import FormSection from '../forms/form-section';
import IconPenToSquare from '../../../components/icons/pen-to-square';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const StyledTitle = styled.h2`
    font-size: 1rem;
    color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.sections.titleColor};
    font-weight: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.sections.titleFontWeight};
    margin: 0;
`;

const StyledDescription = styled.p`
    font-size: 0.8rem;
    font-style: italic;
    margin: 0;
    color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.sections.color};
`;

const StyledIconAdd = styled(IconAdd)`
    width: 16px;
    height: 14px;
`;

const StyledOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const StyledIconPenToSquare = styled(IconPenToSquare)`
    width: 16px;
`;

const CreateADashboardSectionList = () => {
    const { sections, setSections, handleCreateSection } = useContext(CreateADashboardContext);

    const handleSubmit = (data: FieldValues) => {
        handleCreateSection(data);
    };

    const handleEdit = (sectionId: string, data: FieldValues) => {
        const updatedSections = sections.map(section => {
            if (section.sectionId === sectionId) {
                return { ...section, sectionName: data.sectionName };
            } else {
                return section;
            }
        });

        setSections(updatedSections);
    };

    return (
        <StyledContainer>
            <StyledTitle>Sections</StyledTitle>
            {sections.length > 0 && (
                <>
                    <Droppable droppableId="sectionList">
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <StyledOptionContainer>
                                    {sections.map((section, index) => {
                                        return (
                                            <CreateADashboardDraggableItem
                                                key={section.sectionId}
                                                itemId={section.sectionId}
                                                displayName={section.sectionName}
                                                iconType="tableRows"
                                                index={index}
                                                menu={
                                                    <Popover>
                                                        <Popover.Trigger>
                                                            <StyledIconPenToSquare />
                                                        </Popover.Trigger>
                                                        <Popover.Content>
                                                            <FormSection
                                                                sections={sections}
                                                                sectionId={section.sectionId}
                                                                submitValue="Edit Section"
                                                                onSubmit={data => handleEdit(section.sectionId, data)}
                                                            />
                                                        </Popover.Content>
                                                    </Popover>
                                                }
                                            />
                                        );
                                    })}
                                </StyledOptionContainer>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>

                    {sections.length > 1 && (
                        <StyledDescription>
                            Drag and drop sections above to reorder them in the dashboard layout
                        </StyledDescription>
                    )}
                </>
            )}
            <Popover>
                <Popover.Trigger>
                    <StyledIconAdd />
                    Add a Section
                </Popover.Trigger>
                <Popover.Content>
                    <FormSection sections={sections} submitValue="Create Section" onSubmit={handleSubmit} />
                </Popover.Content>
            </Popover>
        </StyledContainer>
    );
};

export default CreateADashboardSectionList;
