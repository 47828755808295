import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import moment from 'moment';
import { useSelector } from 'react-redux';

// Components
import ProgressBar from '../../components/progress-bar';
import VisitorInfoSkeleton from '../components/skeleton-visitor-info';
import VisitorInfoMetric from './visitor-info-metric';
import LocationMap from '../components/location-map';
import IconUser from '../../components/icons/user';

const StyledInfoContainer = styled.div`
    padding: 6px;
    max-width: 360px;
    background-color: ${props => props.theme.colours.white};
    ${props => props.theme.boxShadow(1)};
`;

const StyledProfile = styled.div`
    position: relative;
`;

const StyledAvatar = styled.div`
    ${props => props.theme.boxShadow(1)};
    border: 4px solid ${props => props.theme.colours.offBlackLighter};
    border-radius: 200px;
    width: 72px;
    height: 72px;
    position: relative;
    z-index: 2;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        height: 35px;

        & path {
            fill: ${props => props.theme.colours.offBlackLighter};
        }
    }
`;

const StyledVisitorId = styled.div`
    position: absolute;
    top: 19px;
    left: 57px;
    width: 70%;
    background-color: ${props => props.theme.colours.offBlackLighter};
    color: ${props => props.theme.colours.light};
    padding-left: 13px;
    padding: 12px 4px 12px 22px;
    border-radius: 0 63px 63px 0;
    z-index: 1;
    font-size: 13px;
`;

const StyledMetrics = styled.div`
    margin-bottom: 6px;
`;

const StyledDates = styled.div`
    margin-bottom: 6px;
`;

const StyledPropensityBars = styled.div`
    padding: 6px;
    margin-bottom: 6px;
`;

const StyledImpressionContainer = styled.div`
    margin: 10px 0px;
`;

const StyledMetricContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledTitle = styled.h1`
    text-transform: uppercase;
    text-align: center;
    color: ${props => props.theme.colours.offBlackLighter};
    padding: 6px 3px 3px;
    font-size: 12px;
    font-weight: 600;
`;

const VisitorInfo = ({ visitor }) => {
    const currencySymbol = useSelector(state => state.currency.symbol);
    if (!visitor) return <VisitorInfoSkeleton />;
    return (
        <StyledInfoContainer>
            <StyledProfile>
                <StyledAvatar>
                    <IconUser />
                </StyledAvatar>
                <StyledVisitorId data-testid="visitor-id">{`Visitor ID: ${visitor.id}`}</StyledVisitorId>
            </StyledProfile>
            <StyledMetrics>
                <VisitorInfoMetric label={'Sessions'} value={visitor.sessions_count} width={'25%'} />
                <VisitorInfoMetric label={'Goals'} value={visitor.goals_count} width={'25%'} />
                <VisitorInfoMetric
                    label={'Revenue'}
                    value={currencySymbol + `${numeral(visitor.total_revenue).format('0,0[.]00')}`}
                    width={'25%'}
                    colour="green"
                />
                <VisitorInfoMetric
                    label={'Cost'}
                    value={currencySymbol + `${numeral(visitor.total_cost).format('0,0[.]00')}`}
                    width={'25%'}
                    colour="red"
                />
            </StyledMetrics>
            <StyledDates>
                <VisitorInfoMetric
                    label="First Visit"
                    value={moment(visitor.first_visit).format("ddd, Do MMM 'YY")}
                    width={'50%'}
                />
                <VisitorInfoMetric
                    label="Last Visit"
                    value={moment(visitor.last_visit).format("ddd, Do MMM 'YY")}
                    width={'50%'}
                />
            </StyledDates>
            <StyledPropensityBars>
                {visitor.scores.map((score, index) => {
                    return <ProgressBar key={index} value={score.score} label={score.product.name} />;
                })}
            </StyledPropensityBars>
            {visitor.locations.length > 0 && visitor.locations[0].lat && visitor.locations[0].lng && (
                <LocationMap locations={visitor.locations} />
            )}
            {visitor.impression_items?.length > 0 &&
                visitor.impression_items.map((impressionItem, index) => {
                    return (
                        <StyledImpressionContainer key={index}>
                            <StyledTitle>Impressions</StyledTitle>
                            <StyledMetricContainer>
                                <VisitorInfoMetric label="Campaign" value={impressionItem.campaign_id} width={'33%'} />
                                <VisitorInfoMetric
                                    label="Viewed"
                                    value={`${
                                        impressionItem.impressions > 1 ? `${impressionItem.impressions} times` : 'Once'
                                    }`}
                                    width={'33%'}
                                />
                                <VisitorInfoMetric
                                    label="Last Seen"
                                    value={moment(impressionItem.last_visit).format('DD/MM/YY')}
                                    width={'33%'}
                                />
                            </StyledMetricContainer>
                        </StyledImpressionContainer>
                    );
                })}
        </StyledInfoContainer>
    );
};

export default VisitorInfo;
