import React from 'react';
import styled from 'styled-components';

const StyledFormField = styled.div<{ direction: 'column' | 'row' }>`
    width: 100%;
    display: flex;
    flex-direction: ${props => props.direction};
    align-items: baseline;
    gap: 5px;
`;

type FormFieldProps = {
    direction?: 'column' | 'row';
    children: React.ReactNode;
};

const FormField = ({ direction = 'column', children }: FormFieldProps) => {
    return <StyledFormField direction={direction}>{children}</StyledFormField>;
};

export default FormField;
