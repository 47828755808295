import React, { createContext, useContext, useEffect, useState } from 'react';

// Context
import { DashboardBuilderContext } from '../../context/dashboard-builder-context';

// Resources
import {
    DASHBOARD_BUILDER_DASHBOARD,
    DASHBOARD_BUILDER_SECTION_AREAS,
    DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON,
    DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
} from '../../config/resources-dashboard-builder';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';

// Helpers
import generateDashboardConfig from '../helpers/generate-dashboard-config';
import getWidgetsInUse from '../../libraries/widget-library/helpers/get-widgets-in-use';

// Types
import { ConfigDataSuccess } from '../../../react-query/types';
import { DashboardBuilderDashboard, DashboardBuilderDashboardConfig, DashboardBuilderSection } from '../types';
import { WidgetConfig } from '../../types';

export interface DisplayDashboardContextValues {
    dashboardConfig?: DashboardBuilderDashboardConfig;
    dashboardPrivate: boolean;
    dashboardEmpty: boolean;
}

export const DisplayDashboardContext = createContext<DisplayDashboardContextValues>(
    {} as DisplayDashboardContextValues
);

const DisplayDashboardContextProvider = ({
    dashboardId,
    isComparison,
    children,
}: {
    dashboardId?: string;
    isComparison: boolean;
    children: JSX.Element;
}) => {
    const { clientUserId } = useContext(DashboardBuilderContext);

    const [dashboardConfig, setDashboardConfig] = useState<DashboardBuilderDashboardConfig>();
    const [dashboardPrivate, setDashboardPrivate] = useState<boolean>(false);
    const [dashboardEmpty, setDashboardEmpty] = useState<boolean>(false);

    const dashboardDetails = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_DASHBOARD,
        params: [{ key: 'id', value: dashboardId || 0 }],
        enabled: !!dashboardId,
    });

    const sectionConfig = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_SECTION_AREAS,
        params: [{ key: 'section__dashboard__id', value: dashboardId || 0 }],
        enabled: !!dashboardId,
    });

    const sectionConfigComparison = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON,
        params: [{ key: 'section__dashboard__id', value: dashboardId || 0 }],
        enabled: !!dashboardId,
    });

    const widgetsInUse = sectionConfig.data ? getWidgetsInUse(sectionConfig.data) : undefined;

    const widgetConfig = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
        params: [{ key: 'widget__id__in', value: widgetsInUse ? widgetsInUse.join(',') : '' }],
        enabled: !!sectionConfig && !!widgetsInUse,
    });

    useEffect(() => {
        if (dashboardDetails.isSuccess && dashboardDetails.data.objects.length === 0) {
            setDashboardEmpty(true);
        } else if (
            dashboardDetails.isSuccess &&
            dashboardDetails.data.objects.length === 1 &&
            widgetConfig.isSuccess &&
            sectionConfig.isSuccess &&
            sectionConfig.data.objects.length > 0 &&
            sectionConfigComparison.isSuccess &&
            sectionConfigComparison.data.objects.length > 0
        ) {
            if (
                dashboardDetails.data.objects[0].private &&
                dashboardDetails.data.objects[0].author.id !== clientUserId &&
                sectionConfig.isSuccess
            ) {
                setDashboardPrivate(true);
            } else {
                const sections = sectionConfig.data.objects.sort(
                    (a, b) => a.section.display_order - b.section.display_order
                );

                const comparisonSections = sectionConfigComparison.data.objects.sort(
                    (a, b) => a.section.display_order - b.section.display_order
                );

                const config = generateDashboardConfig({
                    isComparison,
                    dashboardDetails: dashboardDetails.data.objects[0] as DashboardBuilderDashboard,
                    widgets: widgetConfig.data.objects as WidgetConfig[],
                    sections: isComparison
                        ? (comparisonSections as DashboardBuilderSection[])
                        : (sections as DashboardBuilderSection[]),
                });

                setDashboardConfig(config);
            }
        }
    }, [dashboardDetails.data, widgetConfig.data, sectionConfig.data, isComparison]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <DisplayDashboardContext.Provider value={{ dashboardConfig, dashboardPrivate, dashboardEmpty }}>
            {children}
        </DisplayDashboardContext.Provider>
    );
};

export default DisplayDashboardContextProvider;
