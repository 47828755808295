import React, { Component } from 'react';
import tinycolor from 'tinycolor2';
import { Stage, Layer, Shape, Text, Rect } from 'react-konva';
import numeral from 'numeral';

class FunnelSection extends Component {
    constructor(props) {
        super(props);
        this.drawCurve = this.drawCurve.bind(this);
        this.checkSize = this.checkSize.bind(this);
        this.renderBox = this.renderBox.bind(this);
        this.renderBackground = this.renderBackground.bind(this);
        this.renderCurve = this.renderCurve.bind(this);
        this.renderAllCurves = this.renderAllCurves.bind(this);
        this.renderTotalsText = this.renderTotalsText.bind(this);
        this.state = {
            stageWidth: 1,
            stageHeight: 1,
        };
    }
    static defaultProps = {
        boxWidthMultiplier: 50,
        cornerRadius: 2,
        backgroundColour: tinycolor('#F4F4F4'),
        textFormat: '0.0a',
    };
    checkSize() {
        const width = this.container.offsetWidth;
        const height = this.container.offsetHeight;
        this.setState({
            stageWidth: width,
            stageHeight: height,
        });
    }
    renderBackground() {
        const boxWidth = (this.state.stageWidth / 100) * this.props.boxWidthMultiplier;
        return (
            <Rect
                x={0}
                y={0}
                width={boxWidth}
                height={this.state.stageHeight}
                fill={this.props.backgroundColour.clone().toHexString()}
            />
        );
    }
    renderTotalsText() {
        const boxWidth = (this.state.stageWidth / 100) * this.props.boxWidthMultiplier;
        const total = numeral(this.props.data.count).format(this.props.data.count < 1000 ? '0' : this.props.textFormat);
        const percentage = numeral(this.props.data.count / this.props.max).format('0%');
        const totalColour =
            this.props.colour.getLuminance() > 0.45 || this.props.data.count / this.props.max < 0.05
                ? 'black'
                : 'white';
        const percentageColour =
            this.props.colour.getLuminance() > 0.45 || this.props.data.count / this.props.max < 0.95
                ? 'black'
                : 'white';
        return [
            <Text
                key={1}
                text={total}
                x={-6}
                width={boxWidth + 12}
                height={this.state.stageHeight}
                verticalAlign={'middle'}
                align={'center'}
                fill={totalColour}
            />,
            <Text
                key={2}
                text={percentage}
                width={boxWidth}
                height={this.state.stageHeight}
                verticalAlign={'top'}
                align={'center'}
                fill={percentageColour}
                y={10}
            />,
        ];
    }
    renderBox() {
        const midY = this.state.stageHeight / 2;
        const boxWidth = (this.state.stageWidth / 100) * this.props.boxWidthMultiplier;
        const startHeight = (this.props.data.count / this.props.max) * this.state.stageHeight;
        const startYTop = midY - startHeight / 2;

        return (
            <Rect
                ref={ref => (this.rect = ref)}
                x={0}
                y={startYTop}
                width={boxWidth}
                height={startHeight}
                fill={this.props.colour.clone().toHexString()}
                cornerRadius={[
                    this.props.cornerRadius,
                    this.props.cornerRadius,
                    this.props.cornerRadius,
                    this.props.cornerRadius,
                ]}
            />
        );
    }

    drawCurve(context, shape) {
        const boxWidth = (this.state.stageWidth / 100) * this.props.boxWidthMultiplier;
        const midX = (this.state.stageWidth + boxWidth) / 2;
        const midY = this.state.stageHeight / 2;

        const startHeight = (this.props.data.count / this.props.max) * this.state.stageHeight;
        const endHeight = (this.props.endCount / this.props.max) * this.state.stageHeight;
        // const curveOffsetX = (this.state.stageWidth - boxWidth) / 5;
        const curveOffsetY = (startHeight - endHeight) / 3.8;
        const startYTop = midY - startHeight / 2;
        const endYTop = midY - endHeight / 2;
        const startYBottom = midY + startHeight / 2;
        const endYBottom = midY + endHeight / 2;

        context.beginPath();
        context.moveTo(boxWidth, startYTop);
        context.quadraticCurveTo(midX, (startYTop + endYTop) / 2 + curveOffsetY, this.state.stageWidth, endYTop);
        context.lineTo(this.state.stageWidth, endYBottom);
        context.quadraticCurveTo(midX, (startYBottom + endYBottom) / 2 - curveOffsetY, boxWidth, startYBottom);
        context.closePath();
        context.fillStrokeShape(shape);
    }

    renderCurve(opacity, scale, key) {
        const offsetY = ((1 - scale) / 2) * this.state.stageHeight;
        return (
            <Shape
                key={key}
                sceneFunc={this.drawCurve}
                fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                fillLinearGradientEndPoint={{ x: this.state.stageWidth, y: 0 }}
                fillLinearGradientColorStops={[
                    0,
                    this.props.colour.clone().lighten(20).toHexString(),
                    1,
                    this.props.colour.clone().lighten(5).toHexString(),
                ]}
                stroke="white"
                strokeWidth={0}
                opacity={opacity}
                scaleY={scale}
                y={offsetY}
            />
        );
    }
    renderAllCurves() {
        return [
            this.renderCurve(0.2, 0.9, 1),
            this.renderCurve(0.6, 0.8, 2),
            this.renderCurve(0.8, 0.6, 3),
            this.renderCurve(1, 0.4, 4),
        ];
    }
    componentDidMount() {
        this.checkSize();
        window.addEventListener('resize', this.checkSize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.checkSize, true);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.stageWidth !== this.state.stageWidth || prevState.stageHeight !== this.state.stageHeight) {
            this.stage.draw();
        }
        if (prevProps.data !== this.props.data) {
            this.checkSize();
        }
    }
    render() {
        return (
            <div
                className="funnel__section"
                style={{ width: `${100 / this.props.dataSize}%` }}
                ref={ref => {
                    this.container = ref;
                }}
            >
                <Stage
                    width={this.state.stageWidth}
                    height={this.state.stageHeight}
                    ref={ref => {
                        this.stage = ref;
                    }}
                >
                    <Layer>
                        <this.renderBackground />
                    </Layer>
                    <Layer>
                        <this.renderBox />
                        <this.renderAllCurves />
                    </Layer>
                    <Layer>
                        <this.renderTotalsText />
                    </Layer>
                </Stage>
            </div>
        );
    }
}

export default FunnelSection;
