import React from 'react';
import styled, { css } from 'styled-components';

// Icons
import IconTable from '../../../components/icons/table';
import IconTableRows from '../../../components/icons/table-rows';
import IconBarColumnChart from '../../../components/icons/bar-column-chart';
import IconPieChart from '../../../components/icons/pie-chart';
import IconArrowTrendUp from '../../../components/icons/arrow-trend-up';
import IconText from '../../../components/icons/text';
import IconSquare1 from '../../../components/icons/square-1';
import IconTrendLineChart from '../../../components/icons/trend-line-chart';

const StyledIcon = css`
    width: 16px;
    min-width: 16px;
`;

const StyledIconTable = styled(IconTable)`
    ${StyledIcon}
`;

const StyledIconTableRows = styled(IconTableRows)`
    ${StyledIcon}
`;

const StyledIconBarColumnChart = styled(IconBarColumnChart)`
    ${StyledIcon}
`;

const StyledIconPieChart = styled(IconPieChart)`
    ${StyledIcon}
`;

const StyledIconArrowTrendUp = styled(IconArrowTrendUp)`
    ${StyledIcon}
`;

const StyledIconTrendLineChart = styled(IconTrendLineChart)`
    ${StyledIcon}
`;

const StyledIconText = styled(IconText)`
    ${StyledIcon}
`;

const StyledIconSquare1 = styled(IconSquare1)`
    ${StyledIcon}
`;

const WidgetIcon = ({ widgetType }: { widgetType: string }) => {
    switch (widgetType) {
        case 'table':
            return <StyledIconTable />;
        case 'tableRows':
            return <StyledIconTableRows />;
        case 'barGraph':
            return <StyledIconBarColumnChart />;
        case 'pieChart':
        case 'pieChartTable':
            return <StyledIconPieChart />;
        case 'sparkLine':
            return <StyledIconArrowTrendUp />;
        case 'lineGraph':
            return <StyledIconTrendLineChart />;
        case 'textBox':
            return <StyledIconText />;
        case 'bigNumber':
            return <StyledIconSquare1 />;
        default:
            return null;
    }
};

export default WidgetIcon;
