import { Layout } from 'react-grid-layout';
import { template } from 'css-grid-template-parser';

// Convert the react-grid-layout object into a grid layout string ready to save to the db
const parseGridAreas = (breakpoint: string, layouts: Layout[]) => {
    const getBreakpointWidth = (breakpoint: string) => {
        switch (breakpoint) {
            case 'xl':
                return 8;
            case 'lg':
                return 6;
            case 'md':
                return 4;
            case 'sm':
                return 2;
            default:
                return 1;
        }
    };

    const lastWidget = layouts.length > 0 && layouts.reduce((max, layout) => (max.y > layout.y ? max : layout));
    const sectionHeight = lastWidget ? lastWidget.y + lastWidget.h : 0;

    const layoutAreas = layouts.map(layout => {
        return {
            [layout.i]: {
                row: { start: layout.y + 1, end: layout.y + (layout.h + 1), span: layout.w },
                column: { start: layout.x + 1, end: layout.x + (layout.w + 1), span: layout.h },
            },
        };
    });

    const areas = template({
        width: getBreakpointWidth(breakpoint),
        height: sectionHeight,
        areas: Object.assign({}, ...layoutAreas),
    })
        .replaceAll('"', "'")
        .split('\n')
        .join(',');

    return areas;
};

export default parseGridAreas;
