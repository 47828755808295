import { isEqual } from 'lodash';
import { SectionDashboardRequest } from '../types';

export const hasRequestChanged = (requestObject1: SectionDashboardRequest, requestObject2: SectionDashboardRequest) => {
    if (
        !isEqual(requestObject1.orderBy, requestObject2.orderBy) ||
        !isEqual(requestObject1.filters, requestObject2.filters) ||
        !isEqual(requestObject1.pageFilters, requestObject2.pageFilters) ||
        !isEqual(requestObject1.sectionFilters, requestObject2.sectionFilters) ||
        !isEqual(requestObject1.offset, requestObject2.offset) ||
        !isEqual(requestObject1.setFilters, requestObject2.setFilters) ||
        !isEqual(requestObject1.setOrderBy, requestObject2.setOrderBy)
    ) {
        return true;
    }
    return false;
};
