import React, { useState } from 'react';
import styled from 'styled-components';

// Resources
import { CONFIG_ACCOUNT_USER, CONFIG_GROUPS, CONFIG_USERS } from '../../../configurations/resources-config';

// Redux
import { RootState } from '../../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../redux/actions/modal';
import { addNotification } from '../../../redux/actions/notification';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePostResourceWithPayload from '../../../react-query/hooks/use-post-resource-with-payload';

// Types & Enums
import { FieldValues } from 'react-hook-form';
import { ButtonThemes } from '../../../enums/button-themes';
import UserGroups from '../../../enums/user-groups';
import { ConfigDataSuccess } from '../../../react-query/types';

// Helpers
import { generatePath } from '../../../helpers/request-builder';

// Components
import Form from '../../../components/forms/form';
import FormCreateUser from './../manage-users/forms/form-create-user';
import ModalNavigation from '../../../components/modal-navigation';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 10px 20px;
`;

const StyledH3 = styled.h3`
    font-size: 1.1rem;
    color: ${props => props.theme.colours.offBlack};
    font-weight: 600;
    margin-bottom: 30px;
`;

const { ADMIN, VIEWER, API } = UserGroups;

const LayoutModalCreateUser = () => {
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);

    // eslint-disable-next-line
    const [formDefaultValues, _] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        password: '',
        repeatPassword: '',
    });
    const [formData, setFormData] = useState<FieldValues>(formDefaultValues);

    // Queries
    const groupQuery = useFetchResource({
        resource: CONFIG_GROUPS,
        params: [
            {
                key: 'active',
                value: 1,
            },
            {
                key: 'id__in',
                value: `${ADMIN}, ${VIEWER}, ${API}`,
            },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    value: object.id,
                    label: object.name,
                };
            });
        },
    });

    // Mutations
    const userGroupPostMutation = usePostResourceWithPayload({
        resource: CONFIG_ACCOUNT_USER,
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'User created successfully.', type: 'success' }));
            dispatch(setModal('ManageUsers'));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while creating this user. Please try again later.',
                    type: 'error',
                })
            );
        },
    });

    const userPostMutation = usePostResourceWithPayload({
        resource: CONFIG_USERS,
        handleOnSuccess: response => {
            userGroupPostMutation.mutate({
                account: generatePath('config', 'account', account.id),
                user: generatePath('config', 'lite-users', String(response.id)),
                group: generatePath('config', 'groups', formData.role),
            });
        },
        handleOnError: error => {
            if (error && error.includes('409')) {
                dispatch(
                    addNotification({
                        copy: 'A user with this email already exists.',
                        type: 'error',
                    })
                );
                return;
            }
            dispatch(
                addNotification({
                    copy: 'There was an issue while creating this user. Please try again later.',
                    type: 'error',
                })
            );
        },
    });

    // Handlers
    const handleFormSubmit = (data: FieldValues) => {
        setFormData(data);

        userPostMutation.mutate({
            username: data.email,
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            password: data.password,
        });
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(setModal('ManageUsers')),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Create User</h2>
            <StyledFormContainer>
                <StyledH3>User Details</StyledH3>
                <Form
                    status={
                        groupQuery.status === 'pending'
                            ? 'loading'
                            : userPostMutation.isPending
                            ? 'disabled'
                            : 'enabled'
                    }
                    defaultValues={formDefaultValues}
                    onSubmit={handleFormSubmit}
                >
                    <FormCreateUser groupQuery={groupQuery} />
                </Form>
            </StyledFormContainer>
        </>
    );
};

export default LayoutModalCreateUser;
