import * as segmenterTableFields from './fields';
import * as resources from './resources';

export const SEGMENTER_CONFIG = {
    MANAGE_MODAL_TITLE: 'Manage Segments',
    MANAGE_MODAL_DESCRIPTION:
        'Add, edit or delete existing segments. Customer segments are built using a combination of one, or many conditions',
    ADD_FORM_MODAL_TITLE: 'Create A Segment',
    ADD_FORM_MODAL_DESCRIPTION:
        'Create a new Segment using the wizard below. Customer segments are built using a combination of one, or many conditions. An example of a Segment could be "Visitors from Cardiff who have bought within the last 30 days".',
    OPERATORS: [
        {
            label: 'All',
            value: 'and',
        },
        {
            label: 'Any',
            value: 'or',
        },
    ],
    RESOURCES: [resources.SEGMENTER_RESOURCE],
    TABLE: {
        METRICS: [segmenterTableFields.SEGMENT_NAME, segmenterTableFields.SEGMENT_DESCRIPTION],
    },
};

export const CONDITION_CONFIG = {
    MANAGE_MODAL_TITLE: 'Manage Conditions',
    MANAGE_MODAL_DESCRIPTION:
        'Add, edit or delete existing conditions. Conditions are the building blocks of Segments, where one, or many conditions are used to build Segments.',
    ADD_FORM_MODAL_TITLE: 'Create A Condition',
    ADD_FORM_MODAL_DESCRIPTION:
        'Create a new Condition using the wizard below. Conditions are the building blocks of Segments, where one, or many Conditions are used to build simple, or complex Segments. Examples of a Condition could be "Time since last sale is less than 30 days" or "Visitors from Cardiff".',
    TABLE_REF: [
        {
            tableName: 'attrib_event',
            tastypieName: 'event',
        },
        {
            tableName: 'attrib_product',
            tastypieName: 'product',
        },
        {
            tableName: 'attrib_referer',
            tastypieName: 'referer',
        },
    ],
    DURATION_DROPDOWN_ITEMS: [
        {
            label: 'seconds',
            value: 'seconds',
        },
        {
            label: 'minutes',
            value: 'minutes',
        },
        {
            label: 'hours',
            value: 'hours',
        },
        {
            label: 'days',
            value: 'days',
        },
        {
            label: 'months',
            value: 'months',
        },
    ],
    RESOURCES: [resources.CONDITION_RESOURCE],
    TABLE: {
        METRICS: [segmenterTableFields.CONDITION_NAME, segmenterTableFields.CONDITION_DESCRIPTION],
    },
};
