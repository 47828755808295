import React from 'react';

import HealthDashboard from '../../components/health-dashboard/health-dashboard';
import HealthDashboardProvider from './health-dashboard-context';

const LayoutHealthDashboard = () => {
    return (
        <HealthDashboardProvider>
            <HealthDashboard />
        </HealthDashboardProvider>
    );
};

export default LayoutHealthDashboard;
