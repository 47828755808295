import React, { createContext, useContext } from 'react';
import { SectionDashboardLayout } from '../../../types';
import useGridLayout from '../../../hooks/base/use-grid-layout';

export interface LayoutContextValues {
    layout: SectionDashboardLayout;
}

export const LayoutContext = createContext<LayoutContextValues>({} as LayoutContextValues);

const LayoutContextProvider = ({ children }: { children: JSX.Element }) => {
    const layout = useGridLayout();

    return (
        <LayoutContext.Provider
            value={{
                layout,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

export const useLayoutContext = (): LayoutContextValues => {
    const context = useContext(LayoutContext);

    if (context === undefined) {
        throw new Error('Menu component must be rendered within the LayoutContextProvider');
    }
    return context;
};

export default LayoutContextProvider;
