import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import tinycolor from 'tinycolor2';
import moment from 'moment';
import numeral from 'numeral';
import styled from 'styled-components';

// Helpers
import Request from '../../helpers/request';
import { intToColour } from '../../helpers/colours';

// Components
import PageInfo from './page-info';
import ProgressBar from '../../components/progress-bar';
import TimeData from './visit-info-timedata';
import Timeline from './timeline';
import VisitorInfoMetric from './visitor-info-metric';
import BlockSkeleton from '../components/block-skeleton';

const StyledVisitInfo = styled.div`
    min-height: 30px;
    width: 100%;
    position: relative;
    background-color: ${props => props.backgroundColor};
    ${props => props.theme.boxShadow(1)};
`;

const StyledWrapper = styled.div`
    cursor: pointer;
`;

const StyledMoreDetails = styled.div`
    transition: ${props => props.theme.transition};
    background-color: ${props => props.theme.colours.white};
`;

const DetailsContainer = styled.div`
    display: flex;
`;

const StyledDetails = styled.div`
    border-right: 1px solid ${props => props.theme.colours.borderGrey};
    flex: 0 1 360px;
    padding: 6px;
`;

const StyledTimeline = styled.div`
    flex: 1 1 auto;
`;

const StyledPropensityDetails = styled.div`
    right: 0;
    display: inline-block;
    height: 100%;
    background-color: ${props => props.theme.colours.white};
    padding: 0 15px;
    max-width: 100%;
    min-width: 100%;
    border: none;
    float: none;
    position: relative;
`;

const VisitInfo = ({ visit }) => {
    const [visitToDisplay, setVisitToDisplay] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [pages, setPages] = useState([]);
    const [moreDetails, setMoreDetails] = useState([]);

    const currencySymbol = useSelector(state => state.currency.symbol);

    useEffect(() => {
        if (!visit.is_impression) {
            fetchVisit();
        } else {
            setVisitToDisplay(visit);
        }
    }, [visit.id]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchMoreVisitDetails = () => {
        const controller = new AbortController();
        const fetchPromise = new Request();
        fetchPromise.get('report', 'scv-visit-more-details', [{ key: 'id', value: visit.id }]).then(response => {
            setMoreDetails(response.data.objects[0]);
        });
        return () => controller.abort();
    };

    const fetchPageIds = () => {
        const controller = new AbortController();
        const fetchPromise = new Request();
        fetchPromise.get('report', 'scv-page-ids', [{ key: 'id', value: visit.id }]).then(response => {
            setPages(response.data.objects[0].pages);
        });
        return () => controller.abort();
    };

    const toggleMoreDetails = () => {
        if (!showDetails && !visit.is_impression) {
            fetchMoreVisitDetails();
            fetchPageIds();
        }
        setShowDetails(!showDetails);
    };

    const fetchVisit = () => {
        const controller = new AbortController();
        const fetchPromise = new Request();
        fetchPromise.get('report', 'scv-visit', [{ key: 'id', value: visit.id }]).then(response => {
            setVisitToDisplay(response.data.objects[0]);
        });
        return () => controller.abort();
    };

    const generatePagesAndEvents = () => {
        return pages.map((item, index) => {
            const itemColours = {
                page: '#AAAAAA',
                event: '#58b5c4',
                sale: '#4CAF50',
            };
            return {
                component: <PageInfo pageId={item.id} key={index} />,
                icon: item.type,
                start: item.date,
                end: item.date,
                colour: tinycolor(itemColours[item.type]),
                key: index,
            };
        });
    };

    const renderPropensityBars = () => {
        return (
            <div>
                {visitToDisplay.scores.map((score, index) => {
                    return (
                        <StyledPropensityDetails key={index}>
                            <ProgressBar
                                key={index}
                                value={score.score}
                                difference={score.delta}
                                label={score.product.name}
                            />
                        </StyledPropensityDetails>
                    );
                })}
            </div>
        );
    };

    const renderMoreDetailsItems = () => {
        if (!moreDetails) return null;
        const revenue = moreDetails.total_revenue;
        const first = moreDetails.first_visit;
        const last = moreDetails.last_visit;
        return (
            <div key={moreDetails.id} style={{ width: '100%' }}>
                <VisitorInfoMetric label="Visit ID" value={moreDetails.id} width={'100%'} />
                {!first ? null : (
                    <VisitorInfoMetric
                        label="Start of Visit"
                        value={[
                            <div key="firstDate">{moment(first).format('MMMM Do YYYY')}</div>,
                            <div key="firstTime">{moment(first).format('h:mm:ssa')}</div>,
                        ]}
                        width={'50%'}
                    />
                )}
                {!last ? null : (
                    <VisitorInfoMetric
                        label="End of Visit"
                        value={[
                            <div key="lastDate">{moment(last).format('MMMM Do YYYY')}</div>,
                            <div key="lastTime">{moment(last).format('h:mm:ssa')}</div>,
                        ]}
                        width={'50%'}
                    />
                )}
                <VisitorInfoMetric label="Page Views" value={moreDetails.views} width={'25%'} />
                <VisitorInfoMetric label="Events" value={moreDetails.total_events} width={'25%'} />
                <VisitorInfoMetric label="Goals" value={moreDetails.total_sales} width={'25%'} />
                {!revenue ? (
                    <VisitorInfoMetric label="Revenue" value={currencySymbol + '0'} width={'25%'} />
                ) : (
                    <VisitorInfoMetric
                        label="Revenue"
                        value={currencySymbol + numeral(revenue).format('0,0[.]00')}
                        width={'25%'}
                    />
                )}
            </div>
        );
    };

    const renderMoreDetails = () => {
        return (
            <StyledMoreDetails>
                {showDetails && !visitToDisplay.is_impression && (
                    <DetailsContainer>
                        <StyledDetails>
                            {renderMoreDetailsItems()}
                            {renderPropensityBars()}
                        </StyledDetails>
                        <StyledTimeline>
                            <Timeline items={generatePagesAndEvents()} />
                        </StyledTimeline>
                    </DetailsContainer>
                )}
                {showDetails && visitToDisplay.is_impression && (
                    <DetailsContainer>
                        <StyledDetails>
                            <VisitorInfoMetric label="Campaign" value={visitToDisplay.campaign} width={'100%'} />
                            <VisitorInfoMetric label="Medium" value={visitToDisplay.medium} width={'50%'} />
                            <VisitorInfoMetric label="Content" value={visitToDisplay.content} width={'50%'} />
                            <VisitorInfoMetric label="Term" value={visitToDisplay.term} width={'50%'} />
                            <VisitorInfoMetric label="Source" value={visitToDisplay.source} width={'50%'} />
                        </StyledDetails>
                    </DetailsContainer>
                )}
            </StyledMoreDetails>
        );
    };

    if (!visitToDisplay) return <BlockSkeleton />;

    return (
        <StyledVisitInfo
            backgroundColor={
                visitToDisplay.total_sales > 0
                    ? tinycolor(intToColour(visitToDisplay.channel.colour)).toHexString()
                    : '#FFFFFF'
            }
        >
            <StyledWrapper isImpression={visitToDisplay.is_impression} onClick={toggleMoreDetails}>
                <TimeData visit={visitToDisplay} />
            </StyledWrapper>
            {renderMoreDetails()}
        </StyledVisitInfo>
    );
};

export default VisitInfo;
