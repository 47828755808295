import DataTypes from '../../filter-bar/enums/data-types';

export const VISITOR_ID = {
    rawName: 'visitor_id',
    requestParam: 'visitor__id',
    displayName: 'Visitor ID',
    defaultOrderDir: 'desc',
    isDimension: true,
    fixedWidth: '',
};

export const FIRST_VISIT = {
    rawName: 'first_visit',
    displayName: 'Visit First Seen At',
    defaultOrderDir: 'desc',
    isDimension: false,
    dataType: DataTypes.DATE_TIME,
    metricWidth: '',
};

export const LAST_VISIT = {
    rawName: 'last_visit',
    displayName: 'Visit Last Seen At',
    defaultOrderDir: 'desc',
    isDimension: false,
    sortBy: true,
    dataType: DataTypes.DATE_TIME,
    metricWidth: '',
};

export const HAS_SALE = {
    rawName: 'has_sale',
    displayName: 'Has Sale',
    defaultOrderDir: 'desc',
    isDimension: false,
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const HAS_SYNCED = {
    rawName: 'has_synced',
    displayName: 'Has Synced',
    defaultOrderDir: 'desc',
    isDimension: false,
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const HAS_CLIENT_CUSTOMER_ID = {
    rawName: 'has_client_customer_id',
    displayName: 'Has Customer ID',
    defaultOrderDir: 'desc',
    isDimension: false,
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const CLIENT_CUSTOMER_ID = {
    rawName: 'client_customer_id',
    displayName: 'Customer ID',
    defaultOrderDir: 'desc',
    isDimension: false,
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const LATEST_SALE_DATE = {
    rawName: 'latest_sale_date',
    displayName: 'Latest Sale Date',
    defaultOrderDir: 'asc',
    isDimension: false,
    dataType: DataTypes.DATE,
    metricWidth: '',
};

export const TRANSACTION_ID = {
    rawName: 'transaction_id',
    requestParam: 'transaction_id',
    displayName: 'Transaction ID',
    defaultOrderDir: 'desc',
    isDimension: true,
    fixedWidth: '',
};

export const SALES_DATE = {
    rawName: 'sales_date',
    displayName: 'Sales Date',
    defaultOrderDir: 'asc',
    isDimension: false,
    sortBy: true,
    dataType: DataTypes.DATE,
    metricWidth: '',
};

export const REVENUE = {
    rawName: 'revenue',
    displayName: 'Revenue',
    defaultOrderDir: 'asc',
    isDimension: false,
    dataType: DataTypes.STRING,
    metricWidth: '',
};

export const REFERER = {
    rawName: 'referer',
    lookupTerm: 'name',
    displayName: 'Channel Name',
    defaultOrderDir: 'asc',
    isDimension: true,
    dataType: DataTypes.STRING,
    fixedWidth: '',
};
