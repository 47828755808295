import { CubedResource } from '../../../types';
import { comparisonRequestError } from '../../helpers/errors';
import { getMultiRequestStatus } from '../../helpers/get-multi-request-status';
import { ResourceComparisonData, SectionDashboardRequest } from '../../types';
import useResource from '../widget/use-resource';

export type UseResourceComparisonArgs = {
    resource?: CubedResource;
    request: SectionDashboardRequest;
    comparisonRequest?: SectionDashboardRequest;
};

const useResourceComparison = ({
    resource,
    request,
    comparisonRequest,
}: UseResourceComparisonArgs): ResourceComparisonData => {
    const data = useResource({ resource, request });

    const comparisonDataRequest = comparisonRequest ? comparisonRequest : request;
    const comparisonData = useResource({ resource, request: comparisonDataRequest, isComparison: true });

    const status = getMultiRequestStatus({ requests: [data, comparisonData] });

    if (status === 'loading' || status === 'empty') {
        return {
            type: 'resourceComparison',
            status: status,
            request: request,
        };
    }

    if (status === 'error') {
        return {
            type: 'resourceComparison',
            status: status,
            request: request,
            error: {
                message: comparisonRequestError(data, comparisonData),
            },
        };
    }

    return {
        type: 'resourceComparison',
        status: status,
        request: request,
        resources: [
            {
                ...data,
                type: 'resource',
            },
            {
                ...comparisonData,
                type: 'resource',
            },
        ],
    };
};

export default useResourceComparison;
