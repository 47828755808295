// Helpers
import { unknownErrorData } from '../../helpers/errors';
import { graphColours } from '../../helpers/graph-colours';
import { histogramChartDataSeries } from '../../helpers/create-data-series';

// Types
import { CubedField } from '../../../types';
import { HistogramChartComparisonData, ResourceComparisonData } from '../../types';

export type UseResourceComparisonHistogramChartArgs = {
    category: CubedField;
    metric: CubedField;
    resourceData: ResourceComparisonData;
};

const useResourceComparisonHistogramChart = ({
    category,
    metric,
    resourceData,
}: UseResourceComparisonHistogramChartArgs): HistogramChartComparisonData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        const sparkLineData: HistogramChartComparisonData = {
            type: 'histogramChart',
            status: 'success',
            metric: metric.displayName,
            histogramColour: graphColours[0].solidGraphColour,
            scatterColour: graphColours[1].solidGraphColour,
            series: [],
            comparisonSeries: [],
        };

        // insert data
        if (resource.status === 'success' && resource.objects.length > 0) {
            sparkLineData.series = histogramChartDataSeries(resource, metric, category);
        }

        // Insert comparison data
        if (comparisonResource.status === 'success' && comparisonResource.objects.length > 0) {
            sparkLineData.comparisonSeries = histogramChartDataSeries(comparisonResource, metric, category);
        }

        return sparkLineData;
    }

    return unknownErrorData();
};

export default useResourceComparisonHistogramChart;
