import moment, { Moment } from 'moment';

import { ChartDateGranularity } from '../../configurations/common/chart-types';
import { getDateGranularity } from '../../helpers/get-date-granularity';

export const createDateRange = (startDate: Moment, endDate: Moment): string[] => {
    const range = [];
    let currentDate = startDate.clone();

    const granularity = getDateGranularity(startDate, endDate);

    if (granularity === ChartDateGranularity.Day) {
        while (currentDate <= endDate) {
            range.push(moment({ ...currentDate }).format('YYYY-MM-DD'));
            currentDate = currentDate.add(1, 'days');
        }
    } else {
        while (currentDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
            range.push(moment({ ...currentDate }).format());
            currentDate = currentDate.add(1, 'hours');
        }
    }

    return range;
};
