// React Dependencies
import React from 'react';
import styled from 'styled-components';
import validators from '../../../components/forms/validation/validators';

// Hooks
import usePostResourceWithPayload from '../../../react-query/hooks/use-post-resource-with-payload';

// Config
import { CONFIG_DOMAINS } from '../../../configurations/resources-config';

// Types
import { ButtonThemes } from '../../../enums/button-themes';
import { FieldValues } from 'react-hook-form';

// Redux
import { useDispatch } from 'react-redux';
import { setModal } from '../../../redux/actions/modal';
import { addNotification } from '../../../redux/actions/notification';
import { NotificationMessageType } from '../../../enums/notification-types';

// Components
import ModalNavigation from '../../../components/modal-navigation';
import Form from '../../../components/forms/form';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const LayoutModalAddDomain = () => {
    const dispatch = useDispatch();

    const domainPostMutation = usePostResourceWithPayload({
        resource: CONFIG_DOMAINS,
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'A domain was successfully added.',
                    type: NotificationMessageType.Success,
                })
            );
            dispatch(setModal('ManageDomains', {}));
        },
        handleOnError: error => {
            dispatch(
                addNotification({
                    copy:
                        error && error.includes('duplicate')
                            ? 'This domain already exists'
                            : 'There was an issue trying to add your domain, please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const onSaveChangesClick = (data: FieldValues) => {
        domainPostMutation.mutate({
            domain: data.domainName,
        });
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(setModal('ManageDomains', {})),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Add a Domain</h2>
            <p>
                Enter your account domain below. The Cubed tag will only accept data for domains configured in your
                account.
            </p>

            <StyledFormContainer>
                <Form status={domainPostMutation.isPending ? 'disabled' : 'enabled'} onSubmit={onSaveChangesClick}>
                    <Form.Body>
                        <Form.Section>
                            <Form.Field>
                                <Form.InputLabel
                                    htmlFor="domainName"
                                    label="Domain Name"
                                    tooltipCopy="Enter the domain name that the Cubed Tag will be firing on"
                                    required={true}
                                />
                                <Form.InputText
                                    name="domainName"
                                    disabled={domainPostMutation.isPending}
                                    validators={[validators.required, validators.isDomain]}
                                />
                            </Form.Field>
                        </Form.Section>
                    </Form.Body>
                    <Form.Footer>
                        <Form.InputButton type="submit" value="Save" loading={domainPostMutation.isPending} />
                        <Form.InputButton type="reset" value="Clear" buttonTheme={ButtonThemes.Secondary} />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </>
    );
};

export default LayoutModalAddDomain;
