import React, { useContext } from 'react';
import styled from 'styled-components';
import SectionDashboardMenuSelect from '../../../../section-dashboard/components/menus/section-dashboard-menu-select';
import { TechSEODashboardMenuContext } from '../../../tech-seo-context/tech-seo-dashboard-menu-context';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.sectionDashboard.widget.base.backgroundColor};
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};
    height: 48px;
    padding: 0px 20px;
`;

const BeforeSectionPageTypeDropdown = () => {
    const { pageTypeDropdownOptions, selectedPageType, handlePageTypeChange } = useContext(TechSEODashboardMenuContext);

    return (
        <StyledContainer>
            <SectionDashboardMenuSelect
                label="Page"
                dropdownOptions={pageTypeDropdownOptions}
                selectedOption={selectedPageType}
                onOptionChange={handlePageTypeChange}
                emptyMessage="No page types found"
            />
        </StyledContainer>
    );
};

export default BeforeSectionPageTypeDropdown;
