import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

// Styled Component
import { theme } from '../../../styled-components/theme.styled';

// Components
import LoadingSpinner from '../../loading-spinner';
import Message from '../../message';

// Enums & Types
import { DragAndDropTableStatus } from '../enums/drag-and-drop-table-status';
import { DragAndDropTableHeader, DragAndDropTableRow } from '../types';

// Icon
import GripDotsVertical from '../../icons/grip-dots-vertical';

// Styles
const StyledTableContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 15px;
`;

const StyledTable = styled.table`
    width: 100%;
    background-color: ${props => props.theme.colours.white};
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 2px;
    text-align: left;
    border-collapse: collapse;
    table-layout: fixed;
`;

const StyledTableHeader = styled.th`
    font-size: 1rem;
    font-weight: 500;
    color: ${props => props.theme.colours.offBlack};
    border-bottom: 1px solid ${props => props.theme.colours.lightGrey};
    padding: 0 5px 7px 5px;
`;

const StyledIconTH = styled.th`
    border-bottom: 1px solid ${props => props.theme.colours.lightGrey};
    width: 20px;
`;

const StyledTR = styled.tr<{
    isDragging: boolean;
    selected: boolean;
    dragDisabled: boolean;
    rowClickDisabled: boolean;
}>`
    table-layout: 'initial';
    user-select: 'none';
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colours.lightGrey};
    background: ${props =>
        props.isDragging
            ? props.theme.colours.offBlackLighter
            : props.selected
            ? props.theme.colours.lightGrey
            : props.theme.colours.white};
    color: ${props => (props.isDragging ? props.theme.colours.white : props.theme.colours.offBlackLighter)};
    display: ${props => (props.isDragging ? 'table' : 'table-row')};
    border-radius: ${props => (props.isDragging ? '5px 5px 6px 6px' : '0px')};
    cursor: ${props =>
        props.dragDisabled && props.rowClickDisabled
            ? 'default'
            : props.dragDisabled
            ? 'pointer'
            : props.rowClickDisabled
            ? 'grab'
            : props.isDragging
            ? 'grabbing'
            : 'grab'};
    &:hover td {
        background-color: ${props =>
            props.rowClickDisabled
                ? props.selected
                    ? props.theme.colours.lightGrey
                    : props.theme.colours.white
                : props.theme.colours.lightGrey};
    }
`;

const StyledIconTD = styled.td`
    width: 15px;
    padding: 3px 0px 0px 5px;
`;

const StyledIconContainer = styled.div<{ isDragging: boolean }>`
    width: 8px;

    & svg path {
        fill: ${props => (props.isDragging ? props.theme.colours.white : props.theme.colours.midGrey)};
    }
`;

const StyledTD = styled.td`
    width: 100%;
    padding: 8px;
    overflow: hidden;
`;

const StyledTDValue = styled.p`
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    font-weight: 300;
    word-wrap: break-word;
    margin: 0;
`;

const LoadingSpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 20px;
`;

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? theme.colours.lightGrey : theme.colours.white,
});

// Types
export type DragAndDropTableProps = {
    headers: DragAndDropTableHeader[];
    rows: DragAndDropTableRow[];
    onReorderRows: (rows: DragAndDropTableRow[], sourceIndex: number, destinationIndex: number) => void;
    handleRowClick: (id: number) => void;
    dragDisabled: boolean;
    rowClickDisabled: boolean;
    status: number | null;
};

const DragAndDropTable = ({
    headers,
    rows,
    onReorderRows,
    handleRowClick,
    dragDisabled,
    rowClickDisabled,
    status,
}: DragAndDropTableProps) => {
    // reorder the rows on drop and update indexed column if applicable
    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        onReorderRows(rows, result.source.index, result.destination.index);
    };

    const renderMessage = () => {
        switch (status) {
            case DragAndDropTableStatus.Loading:
                return (
                    <LoadingSpinnerContainer>
                        <LoadingSpinner />
                    </LoadingSpinnerContainer>
                );
            case DragAndDropTableStatus.Saving:
                return <Message title="Saving" copy="Your changes are being saved..." type="loading" size="small" />;
            case DragAndDropTableStatus.SaveError:
                return (
                    <Message title="Error" copy="There was an error saving your changes." type="error" size="small" />
                );
            case DragAndDropTableStatus.GenericError:
                return (
                    <Message
                        title="Error"
                        copy="There was an error retrieving your data. Please try again later or contact support@cubed.email."
                        type="error"
                        size="small"
                    />
                );
            case DragAndDropTableStatus.NoData:
                return <Message title="No Data" copy="" type="info" size="small" />;
            default:
                return null;
        }
    };

    if (headers && rows) {
        return (
            <StyledTableContainer>
                <DragDropContext onDragEnd={onDragEnd}>
                    <StyledTable>
                        <thead>
                            <tr>
                                <StyledIconTH />
                                {headers.map(header => {
                                    return <StyledTableHeader key={header.value}>{header.label}</StyledTableHeader>;
                                })}
                            </tr>
                        </thead>

                        {!(status && status in DragAndDropTableStatus) && (
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <tbody
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {rows.map((row, index) => (
                                            <Draggable
                                                key={row.id}
                                                draggableId={row.dragId}
                                                index={index}
                                                isDragDisabled={dragDisabled}
                                            >
                                                {(provided, snapshot) => (
                                                    <StyledTR
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            left: '30',
                                                            top: '5',
                                                            tableLayout: 'fixed',
                                                        }}
                                                        onClick={() => handleRowClick(row.id)}
                                                        selected={row.selected}
                                                        dragDisabled={dragDisabled}
                                                        rowClickDisabled={rowClickDisabled}
                                                        isDragging={snapshot.isDragging}
                                                    >
                                                        <StyledIconTD>
                                                            {!dragDisabled && (
                                                                <StyledIconContainer isDragging={snapshot.isDragging}>
                                                                    <GripDotsVertical />
                                                                </StyledIconContainer>
                                                            )}
                                                        </StyledIconTD>
                                                        {headers.map(header => {
                                                            return (
                                                                <StyledTD key={header.value}>
                                                                    <StyledTDValue
                                                                        title={row.columns[header.value] as string}
                                                                    >
                                                                        {row.columns[header.value]}
                                                                    </StyledTDValue>
                                                                </StyledTD>
                                                            );
                                                        })}
                                                    </StyledTR>
                                                )}
                                            </Draggable>
                                        ))}

                                        {provided.placeholder}
                                    </tbody>
                                )}
                            </Droppable>
                        )}
                    </StyledTable>
                </DragDropContext>

                {status ? status in DragAndDropTableStatus && renderMessage() : null}
            </StyledTableContainer>
        );
    }

    return null;
};

export default DragAndDropTable;
