import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.div`
    padding: 10px 20px;
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.title.fontWeight};
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
`;

export type WidgetMenuSectionTitleProps = {
    title: string;
};

const WidgetMenuSectionTitle = ({ title }: WidgetMenuSectionTitleProps) => {
    return <StyledTitle>{title}</StyledTitle>;
};

export default WidgetMenuSectionTitle;
