import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Context
import DisplayDashboardContextProvider, { DisplayDashboardContext } from '../context/display-dashboard-context';
import { FilterBarContext } from '../../../filter-bar/context/filter-bar-context';

// Redux
import { setDescription, setTitle } from '../../../redux/actions/page-meta';

// Hooks
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';

// Types
import { RouteLocation } from '../../../styled-components/theme-context/cubed-theme-context';
import { FilterBarContextValues } from '../../../filter-bar/types';

// Components
import SectionDashboard from '../../../section-dashboard/components/section-dashboard';
import LayoutLoading from '../../layouts/layout-loading';
import FilterBar from '../../../filter-bar/components/filter-bar';
import IconLock from '../../../components/icons/lock';
import IconQuestionMark from '../../../components/icons/question-mark';

const StyledLockedOrEmptyContainer = styled.div`
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    font-size: 0.9rem;
    font-style: italic;
`;

const StyledIconLock = styled(IconLock)`
    width: 30px;
`;

const StyledIconQuestionMark = styled(IconQuestionMark)`
    width: 30px;
`;

const DisplayDashboard = () => {
    const dispatch = useDispatch();

    // Location
    const location = useLocation() as RouteLocation;
    location.isSectionDashboard = true;

    // PDF Download
    const wrapperRef = useRef(null);
    const [loadingPdfDownload, setLoadingPdfDownload] = useState(false);

    // Filter Bar Context
    const {
        setComparisonFilterStatus,
        setFilterStatus,
        setDatePickerConfig,
        setFilterMetricsOptions,
        setComparisonDatePickerConfig,
    } = useContext(FilterBarContext) as FilterBarContextValues;

    // Dashboard Context
    const { dashboardConfig, dashboardPrivate, dashboardEmpty } = useContext(DisplayDashboardContext);

    useEffect(() => {
        if (dashboardConfig) {
            dispatch(setTitle(dashboardConfig.title));
            dispatch(setDescription(dashboardConfig.description));

            // Filter bar configurations
            setFilterStatus(dashboardConfig.filters.status);
            setComparisonFilterStatus(dashboardConfig.filters.comparisonStatus); // Comparison status
            setFilterMetricsOptions([]); // reset metrics
            setDatePickerConfig(dashboardConfig.filters.datePicker); // reset datepicker
            setComparisonDatePickerConfig(dashboardConfig.filters.datePicker); // reset datepicker
        }
    }, [dashboardConfig]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dashboardPrivate) {
            dispatch(setTitle('Private Dashboard'));
            dispatch(setDescription('This dashboard has been set to private by the author.'));
        }
    }, [dashboardPrivate]); // eslint-disable-line react-hooks/exhaustive-deps

    if (dashboardPrivate) {
        return (
            <StyledLockedOrEmptyContainer>
                <StyledIconLock />
                This dashboard has been set to private by the author.
            </StyledLockedOrEmptyContainer>
        );
    }

    if (dashboardEmpty) {
        return (
            <StyledLockedOrEmptyContainer>
                <StyledIconQuestionMark />
                Sorry, this dashboard doesn't exist.
            </StyledLockedOrEmptyContainer>
        );
    }

    if (dashboardConfig) {
        return (
            <div ref={wrapperRef}>
                <FilterBar
                    reportRef={wrapperRef}
                    loadingPdfDownload={loadingPdfDownload}
                    setLoadingPdfDownload={setLoadingPdfDownload}
                />
                <SectionDashboard config={dashboardConfig} />
            </div>
        );
    }

    return <LayoutLoading copy="Loading dashboard..." />;
};

const LayoutDisplayDashboard = ({ isComparison = false }: { isComparison?: boolean }) => {
    const { dashboardId } = useParams();

    return (
        <DisplayDashboardContextProvider dashboardId={dashboardId} isComparison={isComparison}>
            <DisplayDashboard />
        </DisplayDashboardContextProvider>
    );
};

export default LayoutDisplayDashboard;
