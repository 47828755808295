import React from 'react';
import styled from 'styled-components';
import DatePicker from '../../components/date-picker/date-picker';
import { DatePickerType } from '../../configurations/enums/date-picker-type';

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    align-items: center;
    justify-content: right;
`;

const StyledDatePickerContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 305px;
`;

const StyledCompareP = styled.p`
    font-size: 13px;
    font-weight: ${props => props.theme.filterBar.button.labelFontWeight};
    text-transform: uppercase;
    display: contents;
    color: ${props => props.theme.filterBar.button.labelColor};
`;

const PreviewDatepicker = ({ showComparison }: { showComparison: boolean }) => {
    return (
        <StyledContainer>
            <StyledDatePickerContainer>
                <DatePicker isEnabled={true} config={{}} />
            </StyledDatePickerContainer>
            {showComparison && (
                <>
                    <StyledCompareP>Compare To:</StyledCompareP>
                    <StyledDatePickerContainer>
                        <DatePicker
                            isEnabled={true}
                            isComparison={true}
                            config={{ datePickerType: DatePickerType.Comparison }}
                        />
                    </StyledDatePickerContainer>
                </>
            )}
        </StyledContainer>
    );
};

export default PreviewDatepicker;
