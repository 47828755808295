import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../../../redux/actions/notification';
import { RootState } from '../../../../redux/store';

// Types & Enums
import { WidgetConfig } from '../../../types';
import { TriggerType } from '../../../components/dialog/dialog-trigger';
import { NotificationMessageType } from '../../../../enums/notification-types';

// Helpers
import getField from '../../../helpers/get-field';
import generateQueryKey from '../../../../react-query/helpers/generate-query-key';

// Components
import Dialog from '../../../components/dialog/dialog';

// Resources
import {
    DASHBOARD_BUILDER_WIDGET,
    DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
} from '../../../config/resources-dashboard-builder';

// Hooks
import useDeleteResource from '../../../../react-query/hooks/use-delete-resource';
import { useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { WidgetLibraryContext } from '../context/widget-library-context';
import LibraryList from '../../components/library-list/library-list';

const StyledWidgetName = styled.span`
    font-weight: 600;
    cursor: pointer;
    color: ${props => props.theme.dashboardBuilder.library.libraryList.listItemTitleColor};
`;

const StyledButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
`;

const StyledEditButton = styled.button`
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: ${props => props.theme.colours.offBlack};
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 3px;
    color: ${props => props.theme.colours.white};
    text-align: center;
    text-transform: uppercase;
    line-height: 32px;
    padding: 0 12px;
`;

const WidgetLibraryListItem = ({ widget }: { widget: WidgetConfig }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accountToken = useSelector((state: RootState) => state.account.token);
    const queryClient = useQueryClient();

    const { dashboardWidgetsQuery } = useContext(WidgetLibraryContext);

    const [widgetIdToDelete, setWidgetIdToDelete] = useState<number>(0);

    useEffect(() => {
        if (widgetIdToDelete !== 0) {
            widgetPatchMutation.mutate();
        }
    }, [widgetIdToDelete]); // eslint-disable-line react-hooks/exhaustive-deps

    const widgetPatchMutation = useDeleteResource({
        resource: DASHBOARD_BUILDER_WIDGET,
        resourceIds: [widgetIdToDelete.toString()],
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'Widget successfully deleted.', type: NotificationMessageType.Success }));
            setWidgetIdToDelete(0);
            queryClient.invalidateQueries({
                queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG }),
            });
        },
        handleOnError: () => {
            setWidgetIdToDelete(0);
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting this widget.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const handleDelete = (widgetId: number) => {
        setWidgetIdToDelete(widgetId);
    };

    const deleteButtonLoading = widgetPatchMutation.isPending && widgetIdToDelete === widget.widget.id;

    return (
        <LibraryList.ListItem>
            <LibraryList.ListItemCell>
                <StyledWidgetName
                    onClick={() => navigate(`/${accountToken}/dashboard-builder/edit-widget/${widget.widget.id}`)}
                >
                    {widget.widget.title}
                </StyledWidgetName>
            </LibraryList.ListItemCell>
            {!widget.copy && (
                <>
                    <LibraryList.ListItemCell>
                        <LibraryList.CellFlex>
                            <LibraryList.CellTitle>Dimension</LibraryList.CellTitle>
                            {widget.group_by ? getField(widget.group_by)?.displayName : 'N/A'}
                        </LibraryList.CellFlex>
                    </LibraryList.ListItemCell>
                    <LibraryList.ListItemCell>
                        <LibraryList.CellFlex>
                            <LibraryList.CellTitle>Metric(s)</LibraryList.CellTitle>
                            {widget.fields
                                ? widget.fields
                                      .split(',')
                                      .map(field => getField(field)?.displayName)
                                      .join(', ')
                                : widget.graph_metrics
                                ? widget.graph_metrics
                                      .split(',')
                                      .map(field => getField(field)?.displayName)
                                      .join(', ')
                                : getField(widget.order_by)?.displayName}
                        </LibraryList.CellFlex>
                    </LibraryList.ListItemCell>
                </>
            )}
            <LibraryList.ListItemCell>
                <LibraryList.CellFlex>
                    <LibraryList.CellTitle>Created</LibraryList.CellTitle>
                    {widget.widget.created && new Date(widget.widget.created).toDateString()}
                </LibraryList.CellFlex>
            </LibraryList.ListItemCell>
            <LibraryList.ListItemCell>
                <LibraryList.CellFlex>
                    <LibraryList.CellTitle>Updated</LibraryList.CellTitle>
                    {widget.widget.updated && new Date(widget.widget.updated).toDateString()}
                </LibraryList.CellFlex>
            </LibraryList.ListItemCell>
            <LibraryList.ListItemCell>
                <StyledButtonContainer>
                    <StyledEditButton
                        onClick={() => navigate(`/${accountToken}/dashboard-builder/edit-widget/${widget.widget.id}`)}
                    >
                        Edit
                    </StyledEditButton>
                    <Dialog>
                        <Dialog.Trigger type={TriggerType.BUTTON_WARNING} loading={deleteButtonLoading}>
                            {deleteButtonLoading ? 'Deleting' : 'Delete'}
                        </Dialog.Trigger>
                        {dashboardWidgetsQuery.data && dashboardWidgetsQuery.data.includes(widget.widget.id) ? (
                            <Dialog.Content>
                                <Dialog.Title iconType="warning">Widget In Use</Dialog.Title>
                                <Dialog.Description>
                                    This widget is currently displayed on one or more dashboards. You must remove the
                                    widget from all dashboards before you can delete it.
                                </Dialog.Description>
                                <Dialog.ButtonGroup>
                                    <Dialog.Cancel>Ok</Dialog.Cancel>
                                </Dialog.ButtonGroup>
                            </Dialog.Content>
                        ) : (
                            <Dialog.Content>
                                <Dialog.Title iconType="warning">Delete</Dialog.Title>
                                <Dialog.Description>
                                    Are you sure you would like to permanently delete this widget?
                                </Dialog.Description>
                                <Dialog.ButtonGroup>
                                    <Dialog.Cancel>Cancel</Dialog.Cancel>
                                    <Dialog.Action onClick={() => handleDelete(widget.widget.id)}>Delete</Dialog.Action>
                                </Dialog.ButtonGroup>
                            </Dialog.Content>
                        )}
                    </Dialog>
                </StyledButtonContainer>
            </LibraryList.ListItemCell>
        </LibraryList.ListItem>
    );
};

export default WidgetLibraryListItem;
