import React from 'react';
import styled from 'styled-components';

// Context
import { useConfigDragAndDropTableContext } from '../../../context/config-drag-and-drop-table-context';

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 15px;
    border-top: 1px solid ${props => props.theme.colours.borderGrey};
`;

const ActionBar = ({ children }: { children: React.ReactNode }) => {
    const { status } = useConfigDragAndDropTableContext();

    if (status) {
        return <StyledContainer>{children}</StyledContainer>;
    }
    return null;
};

export default ActionBar;
