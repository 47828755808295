import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import NavCollapseExpandLink from '../components/nav-collapse-expand-link';
import NavAccordion from '../components/navigation/nav-accordion';

import { navigationConfig } from '../configurations/dashboard-navigation';

// Context
import { CubedThemeContext } from '../styled-components/theme-context/cubed-theme-context';
import { HomepageContext } from '../context/homepage-context';

// Images
import imageLogoOrange from '../assets/images/orange-cubed-logo.png';
import imageLogoOrangeFull from '../assets/images/orange-cubed-logo-full.png';
import ThemeToggle from '../section-dashboard/components/theme-toggle';

const StyledSidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    width: ${props => (props.showSidebar ? '300px' : '90px')};
    height: 100vh;
    background-color: ${props => props.theme.colours.offBlack};
    padding: 0;
    padding-top: 30px;
    z-index: 50;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: ${props => (props.showSidebar ? 'auto' : 'pointer')};
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const StyledLogoContainer = styled.div`
    height: 35px;
    z-index: 2;
    margin-left: ${props => (props.showSidebar ? '24px' : 0)};
    display: block;
    float: ${props => (props.showSidebar ? 'left' : null)};
`;

const StyledLogo = styled.img`
    width: 114%;
    height: 114%;
    object-fit: contain;
    position: relative;
    left: ${props => (props.expanded ? '-14px' : '-8px')};
`;

const StyledMenu = styled.div`
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: ${props => (props.showSidebar ? '63px' : 0)};
    padding-top: ${props => (props.showSidebar ? 0 : '15px')};
`;

const StyledToggleContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
`;

const Nav = ({ showSidebar, toggleSidebar }) => {
    const { account } = useSelector(state => state);
    const { homepage } = useContext(HomepageContext);

    const { enableThemeToggle } = useContext(CubedThemeContext);

    const [selectedItem, setSelectedItem] = useState({
        group: null,
        subgroup: null,
    });

    const handleHomeClick = () => {
        setSelectedItem({ group: null, subgroup: null });
    };

    return (
        <>
            <NavCollapseExpandLink showSidebar={showSidebar} onToggleSidebar={toggleSidebar} />
            <StyledSidebarContainer showSidebar={showSidebar}>
                <div>
                    <StyledLogoContainer showSidebar={showSidebar}>
                        {showSidebar && (
                            <NavLink
                                to={`/${account.token + navigationConfig(homepage)[0].path}`}
                                onClick={handleHomeClick}
                            >
                                <StyledLogo expanded rel="preload" src={imageLogoOrangeFull} alt="Cubed" />
                            </NavLink>
                        )}
                        {!showSidebar && (
                            <NavLink
                                to={`/${account.token + navigationConfig(homepage)[0].path}`}
                                onClick={handleHomeClick}
                            >
                                <StyledLogo rel="preload" src={imageLogoOrange} alt="Cubed" />
                            </NavLink>
                        )}
                    </StyledLogoContainer>

                    <StyledMenu showSidebar={showSidebar} data-testid="nav-content">
                        <ul>
                            {navigationConfig(homepage)
                                .filter(menuItem => !menuItem.sectionExcludedAccountTypes?.includes(account.group))
                                .map(navItem => {
                                    return (
                                        <NavAccordion
                                            navItem={navItem}
                                            sidebarVisible={showSidebar}
                                            toggleSidebar={toggleSidebar}
                                            selectedItem={selectedItem}
                                            setSelectedItem={setSelectedItem}
                                            onHomeClick={handleHomeClick}
                                            key={navItem.displayName}
                                        />
                                    );
                                })}
                        </ul>
                    </StyledMenu>
                </div>
                {enableThemeToggle ? (
                    <StyledToggleContainer>
                        <ThemeToggle />
                    </StyledToggleContainer>
                ) : null}
            </StyledSidebarContainer>
        </>
    );
};

export default Nav;
