import { createGlobalStyle } from 'styled-components';

const ResetStyles = createGlobalStyle`
body {
    font-family: ${props => props.theme.textFontFamily};
    font-size: 1rem;
    color: ${props => props.theme.colours.textDefault};
    background-color: ${props => props.theme.colours.offLight};
}

h1 {
    font-size: 1.5em;
    font-weight: 700;
}

h2 {
    font-size: 1.4em;
    font-weight: 700;
}

h3 {
    font-size: 1.3em;
    font-weight: 400;
}

h4 {
    font-size: 1.2em;
    font-weight: 400;
}

p {
    margin-top: 0;
    font-weight: 300;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    margin-top: 0;
    font-weight: 300;
}

a {
    color: ${props => props.theme.colours.link};
}
`;

export default ResetStyles;
