import React from 'react';
import styled from 'styled-components';

const StyledMetric = styled.div`
    display: inline-block;
    position: relative;
    padding: 6px 0;
    width: ${props => props.width};
`;

const StyledLabel = styled.div`
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    color: ${props => props.theme.colours.offBlackLighter};
    padding: 6px 3px 3px;
    font-size: 12px;
    font-weight: 600;
`;

const StyledValue = styled.div`
    text-align: center;
    width: 100%;
    font-style: ${props => (props.isLoading ? 'italic' : 'normal')};
    font-size: 14px;
    font-weight: 600;
    color: ${props =>
        props.colour === 'red'
            ? props.theme.colours.danger
            : props.colour === 'green'
            ? props.theme.colours.success
            : props.theme.colours.textDefault};
`;

const VisitorInfoMetric = ({ label, value, width, colour }) => {
    const loading = value === undefined;
    return (
        <StyledMetric width={width}>
            <StyledLabel>{label}</StyledLabel>
            <StyledValue isLoading={loading} colour={colour}>
                {loading ? 'Loading...' : value}
            </StyledValue>
        </StyledMetric>
    );
};

export default VisitorInfoMetric;
