import React from 'react';
import WidgetMenuDropdown from '../widget-menu-dropdown';
import WidgetMenuRadio from '../widget-menu-radio';
import WidgetMenuSectionTitle from '../widget-menu-section-title';
import { MultipleCharts } from '../../../widget-comparison/widget-pie-chart-table-comparison-multiple';

type WidgetMenuPieMultipleProps = {
    data: MultipleCharts;
};

const WidgetMenuPieComparisonMultiple = ({ data }: WidgetMenuPieMultipleProps) => {
    const selectedComparisonChartData = data.comparisonCharts.find(chart => chart.chartField === data.chartField)?.data;
    const loading = selectedComparisonChartData?.status === 'loading';
    const currentChartField = data.chartField;
    const chartMetrics = data.chartMetrics;
    const setChartField = data?.setChartField;

    if (currentChartField && chartMetrics) {
        return (
            <WidgetMenuDropdown loading={loading}>
                <WidgetMenuSectionTitle title="Chart Metric" />
                <WidgetMenuRadio
                    options={chartMetrics.map(metric => {
                        return {
                            value: metric.rawName,
                            label: metric.displayName,
                        };
                    })}
                    value={currentChartField.rawName}
                    onValueChange={option => {
                        const newField = chartMetrics.find(metric => metric.rawName === option);

                        if (newField && setChartField) {
                            setChartField(newField);
                        }
                        return;
                    }}
                />
            </WidgetMenuDropdown>
        );
    }
    return null;
};

export default WidgetMenuPieComparisonMultiple;
