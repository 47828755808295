import { DragAndDropTableStatus } from '../../../../components/tables/enums/drag-and-drop-table-status';

const useButtonStates = ({
    tableRows,
    showEditCreateSegmenter,
    editingTableOrder,
    tableStatus,
    segmentCurrentlyEditing,
    isNotUniqueRule,
    segmentCurrentlyEditingStatus,
}) => {
    const selected = tableRows.filter(row => row.selected).length > 0 && !showEditCreateSegmenter;
    const statuses = [DragAndDropTableStatus.Saving, DragAndDropTableStatus.SaveError, DragAndDropTableStatus.Loading];
    const disableWhileTableStatus = statuses.includes(tableStatus);

    return {
        priorityOrderButtonDisabled:
            showEditCreateSegmenter || disableWhileTableStatus || tableStatus === DragAndDropTableStatus.NoData,
        createButtonDisabled: showEditCreateSegmenter || editingTableOrder || disableWhileTableStatus,
        editButtonDisabled: !selected || disableWhileTableStatus || editingTableOrder,
        deleteButtonDisabled: !selected || disableWhileTableStatus || editingTableOrder,
        saveButtonDisabled: !segmentCurrentlyEditing?.name || !segmentCurrentlyEditingStatus || isNotUniqueRule,
        applyButtonDisabled: !segmentCurrentlyEditing?.name || !segmentCurrentlyEditingStatus || isNotUniqueRule,
    };
};

export default useButtonStates;
