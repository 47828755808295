//eslint-disable import/first
// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import moment from 'moment';
import Axios from 'axios';
import Papa from 'papaparse';
import parse from 'html-react-parser';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';
import { isAlphaNumericString, isAlphaString, isDate, isPrice, isString } from '../../helpers/validator';
import { generateUrl, generatePath, generateUrlDetail } from '../../helpers/request-builder';
import Request from '../../helpers/request';

// Redux Actions
import { setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

// Widget Dependencies
import WidgetAccordion from '../../widgets/accordion';
import ProgressBar from '../../components/progress-bar';
import { ButtonThemes } from '../../enums/button-themes';

class LayoutModalSetFixedCost extends Component {
    constructor(props) {
        super(props);

        const internalOptions = [
            {
                keyValue: 'interval-options__daily',
                value: 'day', // computer-readable value, used in sp_agg_fixed_costs_update
                name: 'Daily', // human-readable version
            },
            {
                keyValue: 'interval-options__weekly',
                value: 'week',
                name: 'Weekly',
            },
            {
                keyValue: 'interval-options__monthly',
                value: 'month',
                name: 'Monthly',
            },
        ];

        this.state = {
            isLoading: true,
            pageError: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            uploadButtonDisabled: true,
            saveChangesButtonHidden: false,
            saveChangesButtonDisabled: false,
            saveChangesButtonLoading: false,
            costDetailsAccordionOpen: true,
            uploadAccordionOpen: false,
            selectedFileIsValid: false,
            onCloseButtonDisabled: false,
            isDownloading: false,
            showLoading: false,
            uploadError: null,
            fileUploadId: null,
            showUploadProgress: false,
            uploadProgress: null,
            uploadLoading: false,
            processId: null,
            returningMessage: null,
            fileInputErrorMessage: null,
            selectedFile: null,
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            costDescription: '',
            channelName: '',
            patternName: '',
            cost: 0,
            interval: '',
            channelNameOptions: [],
            patternOptions: [],
            intervalOptions: internalOptions,
            channelNameForUpload: '',
            patternNameForUpload: '',
            channelNameErrorMessage: '',
            costDescriptionErrorMessage: '',
            costErrorMessage: '',
            intervalErrorMessage: '',
            startDateErrorMessage: '',
            endDateErrorMessage: '',
        };

        this.fileHeaders = ['cost', 'cost_description', 'currency', 'end_date', 'start_date'];

        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.renderAccordion = this.renderAccordion.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.saveCost = this.saveCost.bind(this);
        this.formValidator = this.formValidator.bind(this);
        this.handleNavigateManageEventModal = this.handleNavigateManageEventModal.bind(this);
    }

    CubedRequest = new Request();

    componentDidMount() {
        Axios({
            method: 'GET',
            url: generateUrl('config', 'file-upload', [
                { key: 'account_id', value: this.props.account.id },
                { key: 'processing', value: true },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length > 0) {
                    this.setState({
                        saveChangesButtonHidden: true,
                        showUploadProgress: true,
                        uploadProgress: response.data.objects[0].progress,
                        fileUploadId: response.data.objects[0].id,
                        processId: response.data.objects[0].process_id,
                        returningMessage: 'There is currently a file upload in progress for this account.',
                    });
                }
            })
            .catch(() => {
                this.setState({
                    pageError: true,
                });
            });

        const config = {
            resourceGroup: 'config',
            resourceName: 'referer',
            resourceParams: [
                {
                    key: 'active',
                    value: 1,
                },
            ],
        };

        this.props.getRequest(config);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fileUploadId !== this.state.fileUploadId && this.state.fileUploadId) {
            this.fetchUploadProgress();
        }
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                const channelNameOptions = this.props.request.data.objects.map(channel => {
                    return {
                        id: channel.id,
                        keyValue: `channel-name_${channel.name.replace(/ /g, '')}`,
                        value: channel.name,
                        name: channel.name,
                        patterns: channel.patterns,
                    };
                });

                this.setState({
                    isLoading: false,
                    channelNameOptions: channelNameOptions,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }

            this.props.delRequest();
        }
    }

    formValidator() {
        let hasFormError = false;
        let errorMessageObject = {
            channelNameErrorMessage: '',
            costDescriptionErrorMessage: '',
            costErrorMessage: '',
            intervalErrorMessage: '',
            startDateErrorMessage: '',
            endDateErrorMessage: '',
        };

        if (!isAlphaNumericString(this.state.costDescription) || this.state.costDescription.length === 0) {
            hasFormError = true;
            errorMessageObject.costDescriptionErrorMessage = 'Please enter a valid Cost Description.';
        }

        if (!isString(this.state.channelName) || this.state.channelName.length === 0) {
            hasFormError = true;
            errorMessageObject.channelNameErrorMessage = 'Please select a Channel Name.';
        }

        if (!isPrice(this.state.cost) || this.state.cost.length === 0) {
            hasFormError = true;
            errorMessageObject.costErrorMessage = 'Please enter a valid Cost.';
        }

        if (!isAlphaString(this.state.interval) || this.state.interval.length === 0) {
            hasFormError = true;
            errorMessageObject.intervalErrorMessage = 'Please select an Interval.';
        }

        if (!isDate(this.state.endDate) || this.state.endDate.length === 0) {
            hasFormError = true;
            errorMessageObject.endDateErrorMessage = 'Please select an valid Date.';
        }

        if (this.state.startDate > this.state.endDate) {
            hasFormError = true;
            errorMessageObject.endDateErrorMessage = 'Please select a end date after the start date';
        }

        if (!isDate(this.state.startDate) || this.state.startDate.length === 0) {
            hasFormError = true;
            errorMessageObject.startDateErrorMessage = 'Please select an valid Date.';
        }

        if (hasFormError) {
            this.setState({
                channelNameErrorMessage: errorMessageObject.channelNameErrorMessage,
                costDescriptionErrorMessage: errorMessageObject.costDescriptionErrorMessage,
                costErrorMessage: errorMessageObject.costErrorMessage,
                intervalErrorMessage: errorMessageObject.intervalErrorMessage,
                startDateErrorMessage: errorMessageObject.startDateErrorMessage,
                endDateErrorMessage: errorMessageObject.endDateErrorMessage,
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    }

    hasAllUploadOptionsSelected() {
        return !(
            this.state.channelNameForUpload &&
            this.state.patternNameForUpload &&
            this.state.intervalForUpload &&
            this.state.selectedFile
        );
    }

    saveCost() {
        return new Promise(async (resolve, reject) => {
            const channelId = this.state.channelNameOptions.filter(
                channel => channel.name === this.state.channelName
            )[0]?.id;

            const costPayload = {
                description: this.state.costDescription,
                referer: generatePath('config', 'referer', channelId),
                pattern_id: null,
                cost: Number(this.state.cost),
                per: this.state.interval,
                currency: 'GBP',
                start_date: moment(this.state.startDate).format('YYYY-MM-DD hh:mm:ss'),
                end_date: moment(this.state.endDate).format('YYYY-MM-DD hh:mm:ss'),
            };

            try {
                const costConfig = {
                    method: 'POST',
                    url: generateUrl('config', 'fixed-cost'),
                    data: costPayload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const costRequest = await Axios(costConfig);
                if (costRequest.status !== 201) {
                    throw new Error('Status not 201');
                }
            } catch (error) {
                console.log(error);
                reject('Error making a connection to API.');
            }

            resolve('Fixed Cost Created');
        });
    }

    onSaveChangesClick() {
        // If there is an error reopen the goals details tab as the error will be there
        if (!this.formValidator()) {
            this.setState({
                costDetailsAccordionOpen: true,
            });
            return;
        }

        this.setState({
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: true,
            onCloseButtonDisabled: true,
        });

        this.saveCost()
            .then(() => {
                this.props.addNotification({
                    copy: 'A Fixed Cost was successfully created.',
                    type: NotificationMessageType.Success,
                });
                this.handleNavigateManageEventModal();
            })
            .catch(error => {
                this.props.addNotification({
                    copy: 'There was an issue adding a Fixed Cost.',
                    type: NotificationMessageType.Error,
                });

                this.setState({
                    saveChangesButtonDisabled: false,
                    saveChangesButtonLoading: false,
                    onCloseButtonDisabled: false,
                });
            });
    }

    getPatterns = channel_name => {
        let patternOptions = [];

        let patterns = this.state.channelNameOptions.filter(channel => channel.name === channel_name)[0]?.patterns;

        patternOptions = patterns.map(pattern => {
            return {
                value: pattern.id,
                name: pattern.pattern,
            };
        });

        return patternOptions;
    };

    onSelectChannelNameChange = event => {
        this.setState({
            channelName: event.target.value,
        });
    };
    onSelectIntervalChange = event => {
        this.setState({
            interval: event.target.value,
        });
    };

    onCostDescriptionChange = event => {
        this.setState({
            costDescription: event.target.value,
        });
    };
    onCostChange = event => {
        this.setState({
            cost: event.target.value,
        });
    };

    handleChangeStartDate = event => {
        this.setState({ startDate: event.target.value });
    };

    handleChangeEndDate = event => {
        this.setState({ endDate: event.target.value });
    };

    onChannelNameForUploadChange = event => {
        this.setState({
            channelNameForUpload: event.target.value,
        });
    };
    onPatternNameForUploadChange = event => {
        this.setState({
            patternNameForUpload: event.target.value,
        });
    };
    onIntervalForUploadChange = event => {
        this.setState({
            intervalForUpload: event.target.value,
        });
    };

    handleNavigateManageEventModal() {
        this.props.setModal('ManageFixedCost', {});
    }

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
            this.handleNavigateManageEventModal();
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD FIXED COST',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        style: 'secondary',
                    },
                ],
            });
        }
    }

    validateDataCSVFile = (headerData, data) => {
        // check for the correct columns in csv
        const csvData = data;
        const fileHeaders = headerData;
        const fileHeadersSorted = Object.keys(fileHeaders)
            .sort()
            .map(header => {
                return header;
            });

        let invalidCols = [];
        fileHeadersSorted.map(header => {
            let checkError = true;
            csvData.forEach(data => {
                if (header === 'cost') {
                    if (data[header] === '') {
                        if (checkError) {
                            invalidCols.push(header);
                            checkError = false;
                            return header;
                        }
                    }
                } else if (header === 'cost_description') {
                    if (data[header] === '') {
                        if (checkError) {
                            invalidCols.push(header);
                            checkError = false;
                            return header;
                        }
                    }
                } else if (header === 'currency') {
                    if (data[header] === '') {
                        if (checkError) {
                            invalidCols.push(header);
                            checkError = false;
                            return header;
                        }
                    }
                } else if (header === 'start_date') {
                    if (data[header] === '') {
                        let isDate = moment(data[header], 'YYYY-MM-DD hh:mm:ss', true).isValid();
                        if (checkError) {
                            if (!isDate) {
                                invalidCols.push(header);
                                checkError = false;
                                return header;
                            }
                        }
                    }
                } else if (header === 'end_date') {
                    if (data[header] === '') {
                        let isDate = moment(data[header], 'YYYY-MM-DD hh:mm:ss', true).isValid();
                        if (checkError) {
                            if (!isDate) {
                                invalidCols.push(header);
                                checkError = false;
                                return header;
                            }
                        }
                    }
                }
            });
            return checkError;
        });

        if (invalidCols.length > 0) {
            this.setState({
                uploadButtonDisabled: true,
                selectedFileIsValid: false,
                selectedFile: null,
                backButtonDisabled: false,
                backButtonHidden: false,
                fileInputErrorMessage: parse(
                    '<b>Error(s) during upload:</b>' +
                        '<br>' +
                        'Following columns can not be empty. Please check all rows and re-upload.' +
                        invalidCols
                            .map(col => {
                                return `<li style='margin-left: 20px;'>${col}</li>`;
                            })
                            .join('') +
                        'Please check your CSV file and try to upload again. If you are still having issues please contact us at: support@cubed.email'
                ),
            });
        }
    };

    validateHeadersCSVFile = data => {
        // check for the correct columns in csv
        const fileHeaders = data;
        const fileHeadersSorted = Object.keys(fileHeaders)
            .sort()
            .map(header => {
                return header.toLowerCase();
            });

        return (
            this.fileHeaders.length === fileHeadersSorted.length &&
            this.fileHeaders.every((header, index) => {
                return header === fileHeadersSorted[index];
            })
        );
    };

    onFileInputChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();
        let firstRowData;
        let dataCsv;

        if (file?.name.split('.').at(-1) === 'csv' || 'xlsx' || 'xls') {
            try {
                reader.readAsText(file);
                reader.onload = event => {
                    const csvData = event.target.result;
                    // read file headers with Papaparse
                    Papa.parse(csvData, {
                        skipEmptyLines: true,
                        header: true,
                        complete: result => {
                            firstRowData = result.data[0];
                            dataCsv = result.data;
                        },
                    });
                    if (firstRowData === undefined) {
                        this.setState({
                            selectedFileIsValid: false,
                            selectedFile: null,
                            fileInputErrorMessage:
                                "Sorry, this file doesn't appear to have data. Please check it and try again.",
                        });
                    } else {
                        // validate the file
                        if (this.validateHeadersCSVFile(firstRowData)) {
                            this.setState({
                                selectedFileIsValid: true,
                                selectedFile: file,
                                fileInputErrorMessage: null,
                            });
                            this.validateDataCSVFile(firstRowData, dataCsv);
                        } else {
                            this.setState({
                                selectedFileIsValid: false,
                                selectedFile: null,
                                fileInputErrorMessage:
                                    "Sorry, this file doesn't appear to be in the correct format. Please check it and try again.",
                            });
                        }
                    }
                };
                reader.onerror = () => {
                    this.setState({
                        selectedFileIsValid: false,
                        selectedFile: null,
                        fileInputErrorMessage:
                            'Sorry, there was a problem reading this file. Please check it and try again.',
                    });
                };
            } catch (error) {
                this.setState({
                    selectedFileIsValid: false,
                    selectedFile: null,
                    fileInputErrorMessage:
                        'Sorry, there was a problem reading this file. Please check it and try again.',
                });
            }
        } else {
            this.setState({
                fileInputErrorMessage: 'Please select appropriate file format to upload.',
            });
        }
    };

    onUploadClick = () => {
        if (this.state.selectedFile === null) {
            this.setState({
                costDetailsAccordionOpen: true,
                fileInputErrorMessage: 'Please select appropriate file format to upload.',
            });
            return;
        }
        this.setState({
            uploadLoading: true,
            showLoading: true,
        });
        this.uploadCSVFile(this.state.selectedFile);
    };

    uploadCSVFile = file => {
        const refererId = this.state.channelNameOptions.filter(
            channel => channel.name === this.state.channelNameForUpload
        )[0]?.id;

        const data = new FormData();
        data.append('upload_type', 'fixed_costs_csv');
        data.append('file', file);
        data.append('account_id', this.props.account.id);
        data.append('referer_id', Number(refererId));
        data.append('pattern_id', Number(this.state.patternNameForUpload));
        data.append('interval', this.state.intervalForUpload);

        Axios({
            method: 'POST',
            url: generateUrlDetail(this.props.account.token, 'upload', 'csv-importer', [], false),
            data: data,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        saveChangesButtonHidden: true,
                        uploadId: response.data.upload_id,
                        showUploadProgress: true,
                        backButtonDisabled: true,
                        processId: response.data.pid,
                        fileUploadId: response.data.file_upload_id,
                        uploadProgress: 0.0,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    showLoading: false,
                    uploadError: true,
                });
            });
    };

    fetchUploadProgress = () => {
        const getProgress = () => {
            Axios({
                method: 'GET',
                url: generateUrl('config', 'file-upload', [{ key: 'id', value: this.state.fileUploadId }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.data.objects[0].error) {
                        this.setState({
                            showLoading: false,
                            uploadError: true,
                        });
                    } else {
                        this.setState({
                            showLoading: false,
                            uploadProgress: response.data.objects[0].progress,
                        });
                    }
                })
                .catch(() => {
                    this.setState({
                        showLoading: false,
                        pageError: true,
                    });
                });

            if (this.state.uploadProgress !== '100.00' && !this.state.uploadError) {
                setTimeout(getProgress, 2000);
            } else {
                this.setState({
                    returningMessage: null,
                });
            }
        };

        if (this.state.uploadProgress !== '100.00') {
            getProgress();
        }
    };

    renderModalNavigation() {
        const modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
                isLoading: this.state.saveChangesButtonLoading,
                hidden: this.state.saveChangesButtonHidden,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.onCloseButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    }

    renderAccordion() {
        const accordions = [
            {
                header: 'Cost Details',
                required: true,
                open: this.state.costDetailsAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Channel Name:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select from a list of your channels.',
                                inputKeyValue: 'cost-details__channel-name',
                                inputOptions: this.state.channelNameOptions,
                                inputValue: this.state.channelName,
                                inputOnChange: this.onSelectChannelNameChange,
                                errorMessage: this.state.channelNameErrorMessage,
                            },
                            {
                                label: 'Cost Description:',
                                type: 'textarea',
                                requiredField: true,
                                toolTipCopy: 'Enter a description for your fixed cost, e.g. SEO agency monthly fee.',
                                inputKeyValue: 'cost-details__cost-description',
                                inputPlaceholder: 'Cost Description...',
                                inputValue: this.state.costDescription,
                                inputOnChange: this.onCostDescriptionChange,
                                errorMessage: this.state.costDescriptionErrorMessage,
                            },
                            {
                                label: 'Interval:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a desired interval.',
                                inputKeyValue: 'cost-details__interval',
                                inputOptions: this.state.intervalOptions,
                                inputValue: this.state.interval,
                                inputOnChange: this.onSelectIntervalChange,
                                errorMessage: this.state.intervalErrorMessage,
                            },
                            {
                                label: `Cost (${this.props.currency.symbol}):`,
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Your fixed cost for the interval.',
                                inputKeyValue: 'cost-details__cost',
                                inputPlaceholder: 'Cost...',
                                inputValue: this.state.cost,
                                inputOnChange: this.onCostChange,
                                errorMessage: this.state.costErrorMessage,
                            },
                            {
                                label: 'Start Date:',
                                type: 'datePicker',
                                requiredField: true,
                                toolTipCopy: 'Select the date when the fixed cost should come into effect.',
                                inputValue: this.state.startDate,
                                inputOnChange: this.handleChangeStartDate,
                                errorMessage: this.state.startDateErrorMessage,
                            },
                            {
                                label: 'End Date:',
                                type: 'datePicker',
                                requiredField: true,
                                toolTipCopy: 'Select the date when the fixed cost should end.',
                                inputValue: this.state.endDate,
                                inputOnChange: this.handleChangeEndDate,
                                errorMessage: this.state.endDateErrorMessage,
                            },
                        ],
                        columns: 2,
                    },
                },
            },
            {
                header: 'Upload Fixed Costs CSV',
                required: false,
                open: this.state.uploadAccordionOpen,
                intro: `<p> If you would like to manually upload fixed costs in a CSV format, please use the <b>Upload Fixed Costs CSV</b> section below.
                        Download <a href="/files/fixed_costs_template.csv">Fixed Costs CSV template</a></p>`,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Channel Name:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select from a list of your channels.',
                                inputKeyValue: 'upload-cost-details__channel-name',
                                inputOptions: this.state.channelNameOptions,
                                inputValue: this.state.channelNameForUpload,
                                inputOnChange: this.onChannelNameForUploadChange,
                            },
                            {
                                label: 'Patterns:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy:
                                    'The pattern selected will determine where the cost is shared across visits',
                                inputKeyValue: 'upload-cost-details__pattern-name',
                                inputOptions: this.state.channelNameForUpload
                                    ? this.getPatterns(this.state.channelNameForUpload)
                                    : [],
                                inputValue: this.state.patternNameForUpload,
                                inputOnChange: this.onPatternNameForUploadChange,
                            },
                            {
                                label: 'Interval:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a desired interval.',
                                inputKeyValue: 'upload-cost-details__interval',
                                inputOptions: this.state.intervalOptions,
                                inputValue: this.state.intervalForUpload,
                                inputOnChange: this.onIntervalForUploadChange,
                            },
                            {
                                label: 'Fixed Costs CSV File:',
                                type: 'file-upload',
                                requiredField: true,
                                inputKeyValue: 'add-fixed_costs',
                                inputOnChange: this.onFileInputChange,
                                isValid: this.state.selectedFileIsValid,
                                uploadedFile: this.state.selectedFile?.name,
                                errorMessage: this.state.fileInputErrorMessage,
                            },
                        ],
                        buttons: [
                            {
                                value: 'UPLOAD',
                                onClick: this.onUploadClick,
                                disabled: !(
                                    this.state.channelNameForUpload &&
                                    this.state.patternNameForUpload &&
                                    this.state.intervalForUpload &&
                                    this.state.selectedFile
                                ),
                                isLoading: this.state.uploadLoading,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__set-fixed-cost">
                    <this.renderModalNavigation />
                    <h2>Set Fixed Cost</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__add-csv manage-modals">
                    <this.renderModalNavigation />
                    <h2>Set Fixed Cost</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        if (this.state.uploadError) {
            return (
                <div className="modal__side-panel__add-csv manage-modals">
                    <this.renderModalNavigation />
                    <h2>Set Fixed Cost</h2>
                    <WarningMessage copy="There was a problem with your file upload. Please check your file, try again later or contact support@cubed.email." />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__set-fixed-cost">
                    <this.renderModalNavigation />
                    <h2>Set Fixed Cost</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        if (this.state.showUploadProgress) {
            const progressCopy = `File upload ${
                this.state.uploadProgress === '100.00' ? 'complete!' : 'in progress...'
            }`;
            return (
                <div className="modal__side-panel__add-csv manage-modals">
                    <this.renderModalNavigation />
                    <h2>Set Fixed Cost</h2>
                    <div className="file-upload-progress">
                        {this.state.returningMessage && <p>{this.state.returningMessage}</p>}
                        <p>{progressCopy}</p>
                        <ProgressBar
                            value={parseFloat(this.state.uploadProgress) / 100}
                            difference={100}
                            label="Upload Progress"
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className="modal__side-panel__set-fixed-cost">
                <this.renderModalNavigation />
                <h2>Set Fixed Cost</h2>
                <p>
                    Use the menu below to add fixed costs such as agency fees, platform costs etc. For more help see{' '}
                    <a href="https://tag.docs.withcubed.com/onboarding/general/#fixed-costs">fixed costs</a>.
                </p>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        request: state.request,
        account: state.account,
        currency: state.currency,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        cancelRequest: request => {
            dispatch(cancelRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalSetFixedCost);
