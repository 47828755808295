import React from 'react';
import styled from 'styled-components';
import IconCross from '../../components/icons/cross';
import { StyledDeleteButton } from './create-segment-add-condition';

// Styled Components
const StyledConditionContainer = styled.div`
    display: flex;
`;

const StyledMargin = styled.div`
    width: 80px;
    display: block;
    text-align: center;
    & p {
        font-size: 0.8rem;
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        top: -10px;
    }
`;

const StyledCondition = styled.div`
    background-color: #ffffff;
    color: ${props => (props.active ? props.theme.colours.textDefault : props.theme.colours.red)};
    width: 100%;
    border-left: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    height: 80px;
    padding: 30px 20px 0px 20px;
`;

const StyledConditionId = styled.p`
    color: ${props => (props.active ? '#0a0a0a' : props.theme.colours.red)};
    display: inline-block;
    font-weight: 600;
    margin: 0px 10px 0px 0px;
`;

const StyledConditionBody = styled.p`
    display: inline-block;
    margin: 0;
`;

const StyledConditionFlag = styled.span`
    z-index: 1;
    padding: 5px;
    margin-left: 10px;
    border-radius: 5px;
    font-size: 12px;
    color: ${props => props.theme.colours.offBlack};
    background-color: rgb(255 3 3 / 10%);
`;

const StyledConditionNav = styled.div`
    position: relative;
    top: -15px;
    float: right;
`;

const StyledIconContainer = styled.div`
    width: 14px;
    height: 14px;
`;

const CreateSegmentCondition = ({ index, active, condition, nested, operator, onRemoveCondition }) => {
    const handleRemoveCondition = () => {
        onRemoveCondition(condition);
    };
    return (
        <StyledConditionContainer>
            <StyledMargin>
                <p>{index !== 0 && operator.value}</p>
            </StyledMargin>
            <StyledCondition active={active}>
                <StyledConditionId nested={nested} active={active}>
                    {condition.id}
                </StyledConditionId>
                <StyledConditionBody> - {condition.label}</StyledConditionBody>
                {!active && <StyledConditionFlag>Deleted</StyledConditionFlag>}
                <StyledConditionNav>
                    <StyledDeleteButton onClick={handleRemoveCondition}>
                        <StyledIconContainer>
                            <IconCross />
                        </StyledIconContainer>
                    </StyledDeleteButton>
                </StyledConditionNav>
            </StyledCondition>
        </StyledConditionContainer>
    );
};

export default CreateSegmentCondition;
