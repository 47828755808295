import React from 'react';

// Types
import { CubedField } from '../../../../types';
import { SelectQuery, WidgetMenuType } from '../../../types';

// Hook
import useWidgetMenuSelectState from './use-widget-menu-select-state';

// Component
import WidgetMenuFooterSelect from '../components/widget-menu/widget-menu-footer-select';

type UseWidgetMenuFooterQuerySelectArgs = {
    query: SelectQuery;
    dropdownField: CubedField;
    includeAllOption?: boolean;
    selectedOverride?: string;
};

const useWidgetMenuFooterQuerySelect = ({
    query,
    dropdownField,
    includeAllOption = false,
    selectedOverride,
}: UseWidgetMenuFooterQuerySelectArgs) => {
    const { dropdownOptions, selectedOption, handleChangeSelectedOption, filters, enabled, showEmpty, showLoading } =
        useWidgetMenuSelectState({
            query,
            dropdownField,
            includeAllOption,
            selectedOverride,
        });

    return {
        type: WidgetMenuType.SELECT,
        request: {
            filters: filters,
            enabled: enabled,
            showEmpty: showEmpty,
            showLoading: showLoading,
        },
        menu: (
            <WidgetMenuFooterSelect
                label={dropdownField.displayName}
                dropdownOptions={dropdownOptions}
                selectedOption={selectedOption}
                onOptionChange={handleChangeSelectedOption}
            />
        ),
    };
};

export default useWidgetMenuFooterQuerySelect;
