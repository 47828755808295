import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CubedConfigResource, CubedResource } from '../../types';
import generateQueryKey from '../helpers/generate-query-key';
import { generateUrl } from '../../helpers/request-builder';
import axios from 'axios';

type UseBulkDeleteWithParamsArgs = {
    resource: CubedResource | CubedConfigResource;
    params: { key: string; value: string | number | boolean }[];
    handleOnSuccess?: () => void;
    handleOnError?: () => void;
};

const useBulkDeleteWithParams = ({ resource, params, handleOnSuccess, handleOnError }: UseBulkDeleteWithParamsArgs) => {
    const queryClient = useQueryClient();
    const queryKey = generateQueryKey({ resource });

    const mutation = useMutation({
        mutationFn: async () => {
            params.forEach(async param => {
                const url = generateUrl(resource.category, resource.id, [param]);

                if (url) {
                    return axios({
                        method: 'DELETE',
                        url: url,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).catch(error => {
                        throw new Error(error.response.data.message);
                    });
                }
                throw new Error('Error when generating url');
            });
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries({
                    queryKey: queryKey,
                })
                .then(() => {
                    handleOnSuccess && handleOnSuccess();
                });
        },
        onError: handleOnError,
    });

    return mutation;
};

export default useBulkDeleteWithParams;
