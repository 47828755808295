import React from 'react';
import styled from 'styled-components';

// Component
import DataTableHeaderCell from '../cells/data-table-header-cell';

// Types
import { DataTableColumn, orderByDir, DataTableSelectedRows } from '../../types';
import { CubedField } from '../../../../types';

// Styles
const StyledTableRow = styled.tr`
    position: relative;
`;

// Types
type DataTableHeaderRowProps = {
    columns: DataTableColumn[];
    orderBy: CubedField;
    orderByDir: orderByDir;
    setOwnerState: (key: string, value: DataTableSelectedRows | orderByDir | DataTableColumn) => void;
    scrollPosX: number;
};

const DataTableHeaderRow = ({ columns, orderBy, orderByDir, setOwnerState, scrollPosX }: DataTableHeaderRowProps) => {
    return (
        <StyledTableRow>
            {columns.map(column => (
                <DataTableHeaderCell
                    key={column.rawName}
                    column={column}
                    activeColumn={orderBy}
                    isOrderBy={orderBy === column}
                    orderByDir={orderByDir}
                    setOwnerState={setOwnerState}
                    scrollPosX={scrollPosX}
                />
            ))}
        </StyledTableRow>
    );
};

export default DataTableHeaderRow;
