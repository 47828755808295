import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`;

type InputContainerProps = {
    children: React.ReactNode;
};

const InputContainer = ({ children }: InputContainerProps) => {
    return <StyledContainer>{children}</StyledContainer>;
};

export default InputContainer;
