import React from 'react';

// Component
import ContributionTableHeaderCell from '../cells/contribution-table-header-cell';

// Types
import { ContributionTableHeaders, orderByDir } from '../../types';

type ContributionTableHeaderRowProps = {
    orderByState: string;
    orderDirState: orderByDir;
    headerColumns: ContributionTableHeaders[];
    handleSetCurrentOrder: (name: string) => void;
};

const ContributionTableHeaderRow = ({
    orderByState,
    orderDirState,
    headerColumns,
    handleSetCurrentOrder,
}: ContributionTableHeaderRowProps) => {
    return (
        <thead>
            <tr>
                {headerColumns.map(header => (
                    <ContributionTableHeaderCell
                        key={header.name}
                        header={header}
                        orderByState={orderByState}
                        orderDirState={orderDirState}
                        handleSetCurrentOrder={handleSetCurrentOrder}
                    />
                ))}
            </tr>
        </thead>
    );
};

export default ContributionTableHeaderRow;
