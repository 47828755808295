import { DefaultTheme } from 'styled-components';

type TooltipFormatter = Highcharts.TooltipFormatterContextObject & {
    point: {
        percent: string;
        description: string;
    };
};

export const funnelChartConfig = (theme: DefaultTheme) => {
    return {
        chart: {
            type: 'funnel',
            style: {
                fontFamily: 'Work Sans',
            },
            backgroundColor: 'transparent',
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            outside: true,
            useHTML: true,
            enabled: true,
            headerFormat: null,
            formatter: function (this: TooltipFormatter): string {
                return `
                    <div style="width: 300px; white-space: normal; ">
                        <span style="font-weight: 500;">${this.point.name}: </span>
                        <span style="font-weight: 600; padding-left: 0; padding-top: 15px">${this.point.y} - ${
                    this.point.percent
                }%<span>
                    <span style="display: block; font-weight: 500; margin-top: 10px;">${
                        this.point.description ? this.point.description : ''
                    }</span>
                    </div>
                `;
            },
        },
        plotOptions: {
            series: {
                dataLabels: {
                    allowOverlap: false,
                    overflow: 'allow',
                    enabled: true,
                    useHTML: true,
                    format: `
                        <div style="display: flex; flex-direction: column; align-items: center;">
                            <div style="font-weight: 500; font-size: 11px; border: 2px solid {point.color}; background-color: rgba(255, 255, 255, 0.6); padding: 0px 8px; border-radius: 10px;">{point.name}</div>
                            <div style="font-weight: 700; font-size: 13px;">{point.y} - {point.percent}%</div>
                        </div>
                    `,
                    softConnector: true,
                    inside: true,
                },
                center: ['50%', '50%'],
                neckWidth: '30%',
                neckHeight: '25%',
                width: '80%',
                borderColor: theme.name === 'dark' && '#605f5f',
            },
        },
        legend: {
            enabled: false,
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    inside: true,
                                },
                                center: ['50%', '50%'],
                                width: '100%',
                            },
                        },
                    },
                },
            ],
        },
    };
};
