import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Enum
import { ButtonThemes } from '../../../enums/button-themes';

// Components
import SimpleTable from '../components/simple-table';
import InputButton from '../../inputs/input-button';

// Types
import { SimpleTableColumn, SimpleTableRow } from '../types';

// Styles
const StyledActionButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 10px;
`;

// Types
type WidgetActionSimpleTableProps = {
    isFrom: string;
    rowData: SimpleTableRow[];
    header: { columns: SimpleTableColumn[] };
    errorMessageOverride: string;
    newButtonDisabled: boolean;
    handleCreateNew: () => {};
    editButtonDisabled: boolean;
    handleEditClick: () => {};
    deleteButtonUndo: boolean;
    deleteButtonDisabled: boolean;
    handleDeleteClick: () => {};
    isScrollable: boolean;
    hasEndAction: boolean;
};

const WidgetActionSimpleTable = ({
    isFrom,
    rowData,
    header,
    errorMessageOverride,
    newButtonDisabled,
    handleCreateNew,
    editButtonDisabled,
    handleEditClick,
    deleteButtonUndo,
    deleteButtonDisabled,
    handleDeleteClick,
    isScrollable,
    hasEndAction,
}: WidgetActionSimpleTableProps) => {
    return (
        <>
            <SimpleTable
                header={header}
                rows={rowData}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={isScrollable}
                hasEndAction={hasEndAction}
            />

            <StyledActionButtonsContainer>
                <InputButton value={`Create New ${isFrom}`} disabled={newButtonDisabled} onClick={handleCreateNew} />
                <InputButton value="EDIT" disabled={editButtonDisabled} onClick={handleEditClick} />
                <InputButton
                    buttonTheme={ButtonThemes.Red}
                    value={deleteButtonUndo ? 'UNDO DELETE' : 'DELETE'}
                    disabled={deleteButtonDisabled}
                    onClick={handleDeleteClick}
                />
            </StyledActionButtonsContainer>
        </>
    );
};

export default memo(WidgetActionSimpleTable, isEqual);
