import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import IconExternalLink from '../icons/external-link';
import IconInternalLink from '../icons/internal-link';

//
// 	Class ListRowTitleCopy
//		props.keyValue :    String
//		props.title :       String
//      props.copy :        String
// 		props.link :        String
// 		props.external :    boolean (True will use an A tag whereas False will use a Router Link)
//      props.icon :        boolean
// 		props.rel :         String ( Optional: Only used in external links)
// 		props.target :      String ( Optional: Only used in external links)
//

class ListRowTitleCopyLink extends Component {
    constructor(props) {
        super(props);

        this.renderIcon = this.renderIcon.bind(this);
    }

    renderIcon() {
        if (this.props.icon) {
            return this.props.external ? (
                <div className="icon icon-external-link">
                    <IconExternalLink />
                </div>
            ) : (
                <div className="icon icon-internal-link">
                    <IconInternalLink />
                </div>
            );
        }
    }

    render() {
        if (this.props.external) {
            return (
                <a
                    key={this.props.keyValue}
                    data-key={this.props.keyValue}
                    href={this.props.link}
                    rel={this.props.rel}
                    target={this.props.target}
                >
                    <div className="list-item__title-copy-link" data-key={this.props.keyValue}>
                        <div className="list-item__title-copy-link__content">
                            <h3>{this.props.title}</h3>
                            <p>{this.props.copy}</p>
                        </div>
                        <this.renderIcon />
                    </div>
                </a>
            );
        }

        return (
            <Link to={this.props.link}>
                <div className="list-item__title-copy-link" data-key={this.props.keyValue}>
                    <div className="list-item__title-copy-link__content">
                        <h3>{this.props.title}</h3>
                        <p>{this.props.copy}</p>
                    </div>
                    <this.renderIcon />
                </div>
            </Link>
        );
    }
}

export default ListRowTitleCopyLink;
