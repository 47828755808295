import { useAppSelector } from '../../../../redux/hooks';

// Types
import { CubedField } from '../../../../types';
import { ResourceComparisonData } from '../../../types';
import { unknownErrorData } from '../../../helpers/errors';
import { UseResourceDateRange } from '../../../types';

// Helpers
import { lineGraphDataSeriesInterpolated } from '../../../helpers/create-data-series';

export type UseResourceLineGraphInterpolatedComparisonArgs = {
    resourceData: ResourceComparisonData;
    dateDimension?: CubedField;
    seriesField?: CubedField;
    yAxis?: CubedField;
    dateRange?: UseResourceDateRange;
};

const useResourceLineGraphInterpolatedComparison = ({
    resourceData,
    dateDimension,
    seriesField,
    yAxis,
    dateRange,
}: UseResourceLineGraphInterpolatedComparisonArgs): ResourceComparisonData => {
    const dates = useAppSelector(state => state.date);
    const comparisonDates = useAppSelector(state => state.comparisonDate);

    const { startDate, endDate } = dateRange ? dateRange : dates;
    const { startDate: comparisonStartDate, endDate: comparisonEndDate } = comparisonDates;

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success' && dateDimension && seriesField && yAxis) {
        let resource = resourceData.resources[0];
        let resourceComparison = resourceData.resources[1];

        if (resource.status === 'success') {
            resource = lineGraphDataSeriesInterpolated(resource, startDate, endDate, dateDimension, seriesField, yAxis);
        }

        if (resourceComparison.status === 'success') {
            resourceComparison = lineGraphDataSeriesInterpolated(
                resourceComparison,
                comparisonStartDate,
                comparisonEndDate,
                dateDimension,
                seriesField,
                yAxis
            );
        }

        return {
            ...resourceData,
            resources: [resource, resourceComparison],
        };
    }

    return unknownErrorData();
};

export default useResourceLineGraphInterpolatedComparison;
