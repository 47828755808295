import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const useTaggingGuideConfig = () => {
    const accountName = useSelector((state: RootState) => state.account.name);
    const accountToken = useSelector((state: RootState) => state.account.token);

    const config = [
        {
            type: 'ListRowBody',
            keyValue: 'basic-implementation',
            title: 'Basic Script Implementation Guide',
            bodyContent: [
                {
                    type: 'text',
                    keyValue: 'basic-implementation-1',
                    copy: 'To get Cubed up and running on your website, you will need the following tags:',
                },
                {
                    type: 'list',
                    keyValue: 'basic-implementation-2',
                    listType: 'ordered',
                    listItems: [
                        'The base tag, which must fire on every page.',
                        'The page specific events.',
                        'The fire tag, which must fire on every page.',
                    ],
                },
                {
                    type: 'text',
                    keyValue: 'basic-implementation-3',
                    copy: 'Note that for Cubed to work as intended, the tags must be fired in that order – Base Tag first, then page specific scripts, and then the Fire Tag.',
                },
            ],
        },
        {
            type: 'ListRowBody',
            keyValue: 'base-tag',
            title: 'Adding the Base Tag',
            bodyContent: [
                {
                    type: 'text',
                    keyValue: 'base-tag-1',
                    copy: 'The first code to add to your site is the Base Tag. This code should be placed on all pages and must include the relevant account token.',
                },
                {
                    type: 'text',
                    keyValue: 'base-tag-2',
                    copy: 'Account name: ' + accountName + ', Account Token: ' + accountToken,
                },
                {
                    type: 'codeBlock',
                    keyValue: 'base-tag-3',
                    language: 'javascript',
                    code: `(function() {
	var script = document.createElement("script");  
	script.type = "text/javascript";
	script.async = true;
	script.src = "//d2hkbi3gan6yg6.cloudfront.net/visscore.tag.min.js";
	var s = document.getElementsByTagName("script")[0];
	s.parentNode.insertBefore(script, s);
	})();
	var vscr = window.vscr || [];
	vscr.push(["setAccount", "${accountToken}"]);`,
                },
                {
                    type: 'text',
                    keyValue: 'base-tag-4',
                    copy: 'This will asynchronously load in the CUBED script, adding the CUBED functionality to your webpage and allowing us to move on to the next step.',
                },
            ],
        },
        {
            type: 'ListRowBody',
            keyValue: 'specific-events',
            title: 'Adding Specific Events',
            bodyContent: [
                {
                    type: 'text',
                    keyValue: 'specific-events-1',
                    copy: 'After the Base Tag has been added, we now need to add the page/trigger specific functionality. Cubed will automatically use Page Views as Events, however if you would like other triggers to fire as Events, then the following code should be added: ',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'specific-events-2',
                    langauge: 'javascript',
                    code: `vscr.push(["addEvent", {name: "<Event Name>" }])`,
                },
                {
                    type: 'text',
                    keyValue: 'specific-events-3',
                    copy: ' This functionality must be added in between the Base Tag, and the Fire Tag.  ',
                },
            ],
        },
        {
            type: 'ListRowBody',
            keyValue: 'conversion-tag',
            title: 'The Conversion Tag ',
            bodyContent: [
                {
                    type: 'text',
                    keyValue: 'conversion-tag-1',
                    copy: 'The conversion tag is the same as the Event tag in the previous section, with the addition of two fields - transactionId, which should be passed in as a string, and revenue, which should be passed in either as a float or an integer. If the value is a float, the value will be treated as pounds - if it is passed in as an integer, it will be treated as pence (eg. 123.45 will be considered equal to 12345). The following is an example of the basic conversion tag',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'conversion-tag-2',
                    langauge: 'javascript',
                    code: `vscr.push(["addEvent", {name: "E-Commerce Sale", transactionId: "<your-order-ID>", revenue: <order-value>}]) `,
                },
                {
                    type: 'text',
                    keyValue: 'conversion-tag-3',
                    copy: 'This tag is the minimum requirement in order for a conversion to be tracked - you may however wish to capture additional information to supplement this. The following tag shows an example of each of these options in use: ',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'conversion-tag-4',
                    langauge: 'javascript',
                    code: `
vscr.push(["addEvent", { 
	name: "E-Commerce Sale",
	transactionId: "AB12345",
	revenue: 50.99, 
	currency: "GBP", //The currency code for the revenue above 
	details: [ //An array of basket item objects 
	{name: "Blue Hat", sku: "SK123", category: "hats", price: 20.99, quantity: 1}, 
	{name: "Red Coat", sku: "SK456", category: "coats", price: 1000, quantity: 3} 
	], 
	shippings: [ //An array of shipping cost objects 
	{name: "Next Day", code: "ND12",price: 5.00} 
	], 
	discounts: [ //An array of applied discounts 
	{name: "£5 Off", code: "DISCOUNT123", price: 5.00} 
	]   
	}]) `,
                },
            ],
        },
        {
            type: 'ListRowBody',
            keyValue: 'Sync ID',
            title: 'Firing Sync IDs',
            bodyContent: [
                {
                    type: 'text',
                    keyValue: 'sync-id-1',
                    copy: 'The Cubed product allows the capture of Sync IDs to join customer records across multiple devices and platforms. These sync IDs are stored in a hashed format, but are passed in the JavaScript call in standard format. You can use any unique identifier as a Sync ID, such as telephone number or email address. ',
                },
                {
                    type: 'text',
                    keyValue: 'sync-id-2',
                    copy: 'The standard syntax for capturing sync IDs is as follows:',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'sync-id-3',
                    langauge: 'javascript',
                    code: `vscr.push(["addSyncId", "Sync ID Value"])`,
                },
                {
                    type: 'text',
                    keyValue: 'sync-id-4',
                    copy: 'For example, if you are using email address as a Sync ID, the call would appear as:',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'sync-id-5',
                    langauge: 'javascript',
                    code: `vscr.push(["addSyncId", "support@withcubed.com"])`,
                },
                {
                    type: 'text',
                    keyValue: 'sync-id-6',
                    copy: 'In the same way as our other tag calls, you can fire multiple Sync IDs in a single hit by calling the relevant push function twice before firing the tag. For example: ',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'sync-id-7',
                    langauge: 'javascript',
                    code: `vscr.push(["addSyncId", "support@withcubed.com"])
vscr.push(["addSyncId", "vs34541122"])`,
                },
            ],
        },
        {
            type: 'ListRowBody',
            keyValue: 'customer id',
            title: 'Customer ID ',
            bodyContent: [
                {
                    type: 'text',
                    keyValue: 'customer-id-1',
                    copy: 'Cubed also allows you to link external Customer IDs to Cubed, for use within the Cubed platform. You can do this by adding the following code: ',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'customer-id-2',
                    langauge: 'javascript',
                    code: `vscr.push(["addCustomerId", "12345"]);`,
                },
                {
                    type: 'text',
                    keyValue: 'customer-id-3',
                    copy: 'This differs from Sync IDs in that the value will not be hashed, and therefore allows users to use their external unique customer identifiers within the Cubed platform. ',
                },
            ],
        },
        {
            type: 'ListRowBody',
            keyValue: 'firing-labels',
            title: 'Firing Labels - Optional',
            bodyContent: [
                {
                    type: 'text',
                    keyValue: 'firing-labels-1',
                    copy: "As well as capturing events, you are also able to capture labels. Labels are very versatile and can be used to capture a wide variety of data. As an example, you can use labels to capture a visitor's gender and date of birth, as well as their non-event related activities, such as their choices on a product configuration page. You must define the label type, the label's key, and the label's value. Label Types There are 5 label types, and the type must be defined in the label push. The label type commands are as follows: ",
                },
                {
                    type: 'list',
                    keyValue: 'firing-labels-2',
                    listType: 'unordered',
                    listItems: [
                        'addIntLabel - Adds an integer label. Can be captured as either string or number.',
                        'addFloatLabel - Adds a float label. Can be captured as either string or number. ',
                        'addStringLabel - Adds a string label. Must be captured as a string. ',
                        'addDatetimeLabel - Adds a Date/Time label. Must be an ISO 8601 formatted date.',
                        'addBoolLabel - Adds a boolean label. Can be either a boolean or a string - any value other than `false` resolves as true.',
                    ],
                },
                {
                    type: 'text',
                    keyValue: 'firing-labels-3',
                    copy: 'The syntax for capturing labels is as follows: ',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'firing-labels-4',
                    langauge: 'javascript',
                    code: `vscr.push(["Label Type", "Key", "Value"]) `,
                },
                {
                    type: 'text',
                    keyValue: 'firing-labels-5',
                    copy: 'For example:',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'firing-labels-6',
                    langauge: 'javascript',
                    code: `vscr.push(["addIntLabel", "my_intLabel1", "42"])
vscr.push(["addIntLabel", " my_intLabel2", 42])
vscr.push(["addFloatLabel", " my_floatLabel1", 123.45])
vscr.push(["addFloatLabel", " my_floatLabel2", "123.45"])
vscr.push(["addStringLabel", " my_stringLabel", "str value"])
vscr.push(["addDatetimeLabel", " my_dateLabel1", "2015-11-16"])
vscr.push(["addDatetimeLabel", " my_dateLabel2", "2015-11-16T22:23:48+00:00"])[Text Wrapping Break]vscr.push(["addBoolLabel", "my_boolLabel", "true"])`,
                },
            ],
        },
        {
            type: 'ListRowBody',
            keyValue: 'fire-tag',
            title: 'Adding the Fire Tag',
            bodyContent: [
                {
                    type: 'text',
                    keyValue: 'fire-tag-1',
                    copy: 'Whilst the previous steps will add CUBED functionality to your website and select the information that you wish to send to CUBED, until this point, no data will have been sent up or captured. To send up the information you’ve chosen, you will need to have the following tag at the end: ',
                },
                {
                    type: 'codeBlock',
                    keyValue: 'fire-tag-2',
                    langauge: 'javascript',
                    code: `vscr.push(["fire"]) `,
                },
                {
                    type: 'text',
                    keyValue: 'fire-tag-3',
                    copy: 'Once the Fire Tag has been added, this will then make a request which provides CUBED will the information it needs. As with the Base Tag, it is important that the Fire Tag fires on every single page, and that everything is fired in the correct order. ',
                },
            ],
        },
        {
            type: 'ListRowBody',
            keyValue: 'additional-information',
            title: 'Additional Information',
            bodyContent: [
                {
                    type: 'text',
                    keyValue: 'additional-information-1',
                    copy: 'Additional tag implementation support can be found by visiting http://tag.docs.withcubed.com',
                },
            ],
        },
    ];
    return config;
};

export default useTaggingGuideConfig;
