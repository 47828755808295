import React from 'react';
import styled from 'styled-components';
import Toggle from './toggle';

const StyledToggleContainer = styled.div`
    display: flex;
    grid-gap: 10px;
`;

const StyledAPIToggleContainer = styled.div`
    display: flex;
    grid-gap: 10px;
    flex-direction: row;
    margin-right: 3rem;
`;

const StyledP = styled.p`
    margin-top: 8px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
`;
const StyledApiP = styled.p`
    margin-top: 8px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
`;

export type ToggleWithLabelsProps = {
    trueLabel?: string;
    falseLabel?: string;
    checked: boolean;
    onClick: () => void;
    singleLabel?: string;
};

const ToggleWithLabels = ({ trueLabel, falseLabel, checked, onClick, singleLabel }: ToggleWithLabelsProps) => {
    if (!singleLabel) {
        return (
            <StyledToggleContainer>
                <StyledP>{falseLabel}</StyledP>
                <Toggle checked={checked} onClick={onClick} />
                <StyledP>{trueLabel}</StyledP>
            </StyledToggleContainer>
        );
    } else {
        return (
            <StyledAPIToggleContainer>
                <StyledApiP>{singleLabel}</StyledApiP>
                <Toggle checked={checked} onClick={onClick} />
            </StyledAPIToggleContainer>
        );
    }
};

export default ToggleWithLabels;
