import React from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

const StyledPopoverClose = styled(Popover.Close)`
    color: ${props => props.theme.popover.content.color};
`;

const PopoverClose = ({ asChild, children }: { asChild: boolean; children: React.ReactNode }) => {
    return <StyledPopoverClose asChild>{children}</StyledPopoverClose>;
};

export default PopoverClose;
