const layoutConfiguration = {
    xl: {
        x: 0,
        y: 0,
        w: 8,
        h: 2,
        minW: 1,
        maxW: 12,
        minH: 1,
        maxH: 4,
    },

    lg: {
        x: 0,
        y: 0,
        w: 6,
        h: 2,
        minW: 1,
        maxW: 6,
        minH: 1,
        maxH: 4,
    },

    md: {
        x: 0,
        y: 0,
        w: 4,
        h: 2,
        minW: 1,
        maxW: 4,
        minH: 1,
        maxH: 4,
    },

    sm: {
        x: 0,
        y: 0,
        w: 2,
        h: 2,
        minW: 1,
        maxW: 2,
        minH: 1,
        maxH: 4,
    },

    xs: {
        x: 0,
        y: 0,
        w: 1,
        h: 2,
        minW: 1,
        maxW: 1,
        minH: 1,
        maxH: 4,
    },
};

export default layoutConfiguration;
