// React
import React, { Component } from 'react';
// Helpers
import { isNumber } from '../helpers/validator';
import { intToColour } from '../helpers/colours';
import { ButtonThemes } from '../enums/button-themes';
// Components
import IconChevronUp from '../components/icons/chevron-up';
import DatePicker from '../components/date-picker/date-picker';
import FormSelect from '../components/form-fields/form-select';
import InputButton from '../components/inputs/input-button';
import MultiSelectDropDown from '../components/multi-select-dropdown';
import InputTag from '../components/inputs/input-tag';
import ButtonGroup from '../components/button-group';
import MinMaxSelect from '../components/common/min-max-select';

class JourneyAnalysisFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            goal: this.props.goal,
            journeys: this.props.journeys,
            journeyBuilderMenuItems: [],
            activeSaleOption: '',
            revenueMin: '',
            revenueMax: '',
            salesMin: '',
            salesMax: '',
            showFilters: false,
            disableJourneyDropdown: false,
            revenueErrorMessage: '',
            salesErrorMessage: '',
            filtersApplied: false,
            datePickerConfig: {},
        };
    }

    componentDidMount() {
        const journeyBuilderMenuItems = this.buildJourneyMenuItems();

        let activeSaleOption = this.props.saleTypeOptions.filter(option => {
            return option.active;
        });

        this.setState({
            activeSaleOption: activeSaleOption[0].name,
            journeyBuilderMenuItems: journeyBuilderMenuItems,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.journeys !== this.props.journeys) {
            this.setState((_, props) => ({
                journeys: props.journeys,
            }));
        }

        if (prevProps.saleTypeOptions !== this.props.saleTypeOptions) {
            let activeSaleOption = this.props.saleTypeOptions.filter(option => {
                return option.active;
            });
            this.setState({
                activeSaleOption: activeSaleOption[0].name,
            });
        }
    }

    buildJourneyMenuItems = () => {
        let journeyBuilderMenuItems = [
            {
                type: 'channels',
                name: 'Channels',
                channels: this.props.referers,
            },
        ];

        if (this.props.subchannels.length > 0) {
            // group the subchannels by their channel
            let subchannels = new Map();
            this.props.subchannels.forEach(subchannel => {
                let channel;
                if (subchannel.channel) {
                    channel = subchannel.channel;
                } else {
                    channel = 'Any';
                }

                const grouped = subchannels.get(channel);
                if (!grouped) {
                    subchannels.set(channel, [subchannel]);
                } else {
                    grouped.push(subchannel);
                }
            });

            journeyBuilderMenuItems.push({
                type: 'subchannels',
                name: 'Subchannels',
                channels: Array.from(subchannels, ([channel, items]) => ({ channel, items })),
            });
        }

        return journeyBuilderMenuItems;
    };

    renderGoalDropDown = () => {
        const inputOptions = [];
        this.props.goals.forEach(goal => {
            inputOptions.push({
                keyValue: goal.id,
                name: goal.name,
                value: goal.name,
            });
        });
        return (
            <FormSelect
                inputValue={this.state.goal.value}
                inputOnChange={this.onGoalChange}
                label="Goals"
                inputOptions={inputOptions}
            />
        );
    };

    onGoalChange = event => {
        const selectedGoal = this.props.goals.filter(goal => goal.value === event.target.value)[0];
        this.setState({
            goal: selectedGoal,
        });
    };

    onApplyClick = () => {
        if (this.validateInput() === false) {
            let revenueMinValue = parseFloat(this.state.revenueMin) || '';
            let revenueMaxValue = parseFloat(this.state.revenueMax) || '';
            let salesMinValue = parseFloat(this.state.salesMin) || '';
            let salesMaxValue = parseFloat(this.state.salesMax) || '';

            const filters = {
                filterRevenue: {
                    minValue: revenueMinValue,
                    maxValue: revenueMaxValue,
                },
                filterSales: {
                    minValue: salesMinValue,
                    maxValue: salesMaxValue,
                },

                filterJourney: this.state.journeys,
                goal: this.state.goal,
            };

            this.setState({
                filtersApplied: true,
            });

            this.props.onFilterApply(filters);
        }
    };

    onClearClick = () => {
        // Clear all filters , call parent with blank filters
        this.setState({
            goal: this.props.goal,
            journeys: [
                {
                    id: '*',
                    type: 'any',
                    name: 'Any',
                    colour: intToColour(16777216),
                },
            ],
            revenueMin: '',
            revenueMax: '',
            salesMin: '',
            salesMax: '',
            revenueErrorMessage: '',
            salesErrorMessage: '',
            disableJourneyDropdown: false,
        });

        const filters = {
            filterRevenue: {
                minValue: '',
                maxValue: '',
            },
            filterSales: {
                minValue: '',
                maxValue: '',
            },
            filterJourney: [
                {
                    id: '*',
                    type: 'any',
                    name: 'Any',
                    colour: intToColour(16777216),
                },
            ],
            goal: this.props.goal,
        };

        this.setState({
            filtersApplied: false,
        });

        this.props.onFilterApply(filters);
    };

    onJourneyDropdownClick = event => {
        let disableJourneyDropdown = false;
        const filteredJourneys = [...this.state.journeys];

        // Get current clicked item.
        let clickedListItem = event.target.dataset.key;
        if (clickedListItem !== '*') {
            clickedListItem = parseInt(clickedListItem);
        }

        // Check props for current clicked item and set it to state.
        if (event.target.dataset.type === 'subchannel') {
            this.props.subchannels.forEach(item => {
                if (item.id === clickedListItem) {
                    filteredJourneys.push(item);
                }
            });
        } else {
            this.props.referers.forEach(item => {
                if (item.id === clickedListItem) {
                    filteredJourneys.push(item);
                }
            });
        }

        if (filteredJourneys.length >= 5) {
            disableJourneyDropdown = true;
        }

        this.setState({
            journeys: filteredJourneys,
            disableJourneyDropdown: disableJourneyDropdown,
        });
    };

    validateInput = () => {
        let revenueErrorMessage = '',
            salesErrorMessage = '',
            failValidation = false;

        let revenueMin = this.state.revenueMin;
        let revenueMax = this.state.revenueMax;
        let salesMin = this.state.salesMin;
        let salesMax = this.state.salesMax;

        // Check each input is a number
        const errorMessageValidNumber = 'Please enter a valid number.';
        const errorMessageValidRange = 'Please enter a valid range.';

        if (salesMin && !isNumber(salesMin)) {
            failValidation = true;
            salesErrorMessage = errorMessageValidNumber;
        }

        if (salesMax && !isNumber(salesMax)) {
            failValidation = true;
            salesErrorMessage = errorMessageValidNumber;
        }

        if (revenueMin && !isNumber(revenueMin)) {
            failValidation = true;
            revenueErrorMessage = errorMessageValidNumber;
        }

        if (revenueMax && !isNumber(revenueMax)) {
            failValidation = true;
            revenueErrorMessage = errorMessageValidNumber;
        }

        // Check for a valid range
        if (revenueMax !== '' && parseInt(revenueMax) < parseInt(revenueMin)) {
            failValidation = true;
            revenueErrorMessage = errorMessageValidRange;
        }

        if (salesMax !== '' && parseInt(salesMax) < parseInt(salesMin)) {
            failValidation = true;
            salesErrorMessage = errorMessageValidRange;
        }

        this.setState({
            revenueErrorMessage,
            salesErrorMessage,
        });

        return failValidation;
    };

    onInputChange = event => {
        const currentInput = event.currentTarget.getAttribute('data-key');

        this.setState({
            [currentInput]: event.target.value,
        });
    };

    onFilterToggleClick = () => {
        this.setState(state => ({
            showFilters: !state.showFilters,
        }));
    };

    onTagDelete = index => {
        let disableJourneyDropdown = false;

        const journeys = [...this.state.journeys];
        journeys.splice(index, 1);

        if (journeys.length >= 5) {
            disableJourneyDropdown = true;
        }

        this.setState({
            journeys: journeys,
            disableJourneyDropdown: disableJourneyDropdown,
        });
    };

    renderJourneyTags = () => {
        return (
            <div className="journeys__filters__container__tags">
                {this.state.journeys.map((item, index) => {
                    const subchannelCopy = item.channel ? `${item.channel} ${item.name}` : item.name;

                    return (
                        <InputTag
                            onChange={this.onTagDelete}
                            dataKey={index}
                            borderColour={item.colour}
                            disableEdit={true}
                            value={item.type === 'subchannel' ? subchannelCopy : item.name}
                        />
                    );
                })}
            </div>
        );
    };

    renderFilterPreview = () => {
        const revenueMin = this.state.revenueMin;
        const revenueMax = this.state.revenueMax;
        const salesMin = this.state.salesMin;
        const salesMax = this.state.salesMax;

        return (
            <div className="journeys__filters__container__preview__values">
                <div className="journeys__filters__container__preview__wrapper">
                    <div>
                        <h3>Goal</h3>
                        <p>{this.state.goal.name}</p>
                    </div>
                    <div>
                        <h3>Sale Type</h3>
                        <p>{this.state.activeSaleOption}</p>
                    </div>
                    {this.state.filtersApplied === true &&
                    !this.state.revenueErrorMessage &&
                    !this.state.salesErrorMessage ? (
                        <>
                            {/* {Between min and max} */}
                            {revenueMin !== '' && revenueMax !== '' && revenueMin !== revenueMax && (
                                <div>
                                    <h3>Revenue</h3>
                                    <p>Between {revenueMin + ' → ' + revenueMax}</p>
                                </div>
                            )}
                            {salesMin !== '' && salesMax !== '' && salesMin !== salesMax && (
                                <div>
                                    <h3>Sales</h3>
                                    <p>Between {salesMin + ' → ' + salesMax}</p>
                                </div>
                            )}
                            {/* Min Only */}
                            {revenueMin !== '' && revenueMax === '' && (
                                <div>
                                    <h3>Revenue</h3>
                                    <p>Greater than {revenueMin}</p>
                                </div>
                            )}
                            {salesMin !== '' && salesMax === '' && (
                                <div>
                                    <h3>Sales</h3>
                                    <p>Greater than {salesMin}</p>
                                </div>
                            )}
                            {/* Max Only */}
                            {revenueMax !== '' && revenueMin === '' && (
                                <div>
                                    <h3>Revenue</h3>
                                    <p>Less than {revenueMax}</p>
                                </div>
                            )}
                            {salesMax !== '' && salesMin === '' && (
                                <div>
                                    <h3>Sales</h3>
                                    <p>Less than {salesMax}</p>
                                </div>
                            )}
                            {/* Equals */}
                            {!isNaN(parseInt(revenueMin)) && revenueMin === revenueMax && (
                                <div>
                                    <h3>Revenue</h3>
                                    <p>{revenueMin}</p>
                                </div>
                            )}
                            {!isNaN(parseInt(salesMin)) && salesMin === salesMax && (
                                <div>
                                    <h3>Sales</h3>
                                    <p>{salesMin}</p>
                                </div>
                            )}
                        </>
                    ) : null}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="journeys__filters__container container">
                <div className="journeys__filters__container__preview row">
                    <DatePicker isEnabled={true} config={this.state.datePickerConfig} />
                    <div
                        className={`journeys__filters__container__preview__accordion ${
                            this.state.showFilters === true ? 'show' : ''
                        }`}
                        onClick={this.onFilterToggleClick}
                    >
                        <div className="icon icon-chevron-up">
                            <IconChevronUp />
                        </div>
                        <p>Filters</p>
                    </div>
                    <ButtonGroup
                        options={this.props.channelTypeOptions}
                        onButtonClick={this.props.onChannelTypeSelect}
                    />
                    {!this.state.showFilters && <this.renderFilterPreview />}
                </div>

                {this.state.showFilters && (
                    <div className="journeys__filters__container__filters row">
                        <div className="journeys__filters__container__filters row">
                            <div className="col-12 col-sm-6 col-lg-2">
                                <this.renderGoalDropDown />
                            </div>
                            <MinMaxSelect
                                label="Revenue"
                                inputType="text"
                                minInputName="min"
                                maxInputName="max"
                                minPlaceHolder="min"
                                maxPlaceHolder="max"
                                handleInputOnChange={this.onInputChange}
                                minDataKey="revenueMin"
                                maxDataKey="revenueMax"
                                minInputValue={this.state.revenueMin}
                                maxInputValue={this.state.revenueMax}
                                inputError={this.state.revenueErrorMessage}
                            />
                            <MinMaxSelect
                                label="Sales"
                                inputType="text"
                                minInputName="min"
                                maxInputName="max"
                                minPlaceHolder="min"
                                maxPlaceHolder="max"
                                handleInputOnChange={this.onInputChange}
                                minDataKey="salesMin"
                                maxDataKey="salesMax"
                                minInputValue={this.state.salesMin}
                                maxInputValue={this.state.salesMax}
                                inputError={this.state.salesErrorMessage}
                            />
                            <div className="journeys__filters__button-group">
                                <p className="journeys__filters__container__title">Sale Type</p>
                                <ButtonGroup
                                    options={this.props.saleTypeOptions}
                                    onButtonClick={this.props.onSaleTypeSelect}
                                />
                            </div>
                        </div>

                        <p className="journeys__filters__container__title">Journey Builder</p>
                        <div className="journeys__filters__container__journey row">
                            <this.renderJourneyTags />
                            {this.state.disableJourneyDropdown === false && (
                                <MultiSelectDropDown
                                    dropdownItems={this.state.journeyBuilderMenuItems}
                                    onClick={this.onJourneyDropdownClick}
                                />
                            )}
                        </div>
                        <div className="journeys__filters__container__buttons row">
                            <InputButton onClick={this.onApplyClick} value="Apply" />
                            <InputButton
                                onClick={this.onClearClick}
                                value="Clear"
                                buttonTheme={ButtonThemes.Secondary}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default JourneyAnalysisFilters;
