import React, { useContext, useEffect } from 'react';

import SectionDashboard from '../../section-dashboard/components/section-dashboard';
import { config } from '../configurations/tech-seo-dashboard';
import { useDispatch } from 'react-redux';
import { setDescription, setTitle } from '../../redux/actions/page-meta';
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';
import { useLocation } from 'react-router';
import TechSEODashboardMenuProvider from '../tech-seo-context/tech-seo-dashboard-menu-context';

const TechSeoDashboard = () => {
    const dispatch = useDispatch();
    const { setFilterStatus, setDatePickerConfig, setFilterMetricsOptions } = useContext(FilterBarContext);

    const location = useLocation();
    location.isSectionDashboard = true;

    useEffect(() => {
        dispatch(setTitle(config.title));
        dispatch(setDescription(config.description));

        // Filter bar configurations
        setFilterStatus(config.filters.status);
        setFilterMetricsOptions([]); // reset metrics
        setDatePickerConfig(config.filters.datePicker); // reset datepicker
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <TechSEODashboardMenuProvider>
            <SectionDashboard config={config} />
        </TechSEODashboardMenuProvider>
    );
};

export default TechSeoDashboard;
