import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea`
    font-family: inherit;
    width: 100%;
    height: 100px;
    resize: none;
    background-color: ${props => props.theme.colours.inputColour};
    border: none;
    border-bottom: solid 2px ${props => props.theme.colours.offBlack};
    padding: 0 5px;
    margin-bottom: 5px;
    border-radius: 2px;

    &:focus {
        outline: none;
    }
`;

export type InputTextAreaProps = {
    value?: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
    disabled?: boolean;
};

const InputTextArea = ({ value, onChange, disabled }: InputTextAreaProps) => {
    return <StyledTextArea defaultValue={value} onChange={onChange} disabled={disabled} />;
};

export default InputTextArea;
