import { ConfigDataSuccess } from '../../../../react-query/types';

const getWidgetsInUse = (data: ConfigDataSuccess) => {
    let widgetIds: any[] = [];

    data.objects.forEach((section: any) => {
        widgetIds.push([...new Set(section.grid_xs.replaceAll("'", '').replaceAll(',', ' ').split(' '))]);
        widgetIds.push([...new Set(section.grid_sm.replaceAll("'", '').replaceAll(',', ' ').split(' '))]);
        widgetIds.push([...new Set(section.grid_md.replaceAll("'", '').replaceAll(',', ' ').split(' '))]);
        widgetIds.push([...new Set(section.grid_lg.replaceAll("'", '').replaceAll(',', ' ').split(' '))]);
        widgetIds.push([...new Set(section.grid_xl.replaceAll("'", '').replaceAll(',', ' ').split(' '))]);
    });

    widgetIds = [...new Set(widgetIds.flat())];
    return widgetIds.map(widget => parseInt(widget.replace('widget', ''))).filter(id => !isNaN(id));
};

export default getWidgetsInUse;
