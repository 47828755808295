import React from 'react';
import styled from 'styled-components';

// Types
import { WidgetDirection, VennSeriesObjectData } from '../../types';
import { graphColours } from '../../helpers/graph-colours';

const StyledBoxContainer = styled.div<{ direction: WidgetDirection }>`
    display: flex;
    flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
    height: 100%;
`;

const StyledBox = styled.div<{ backgroundColor: string }>`
    flex-grow: 1;
    position: relative;
    text-align: center;
    background-color: ${props => props.backgroundColor};
`;

const StyledValueContainer = styled.div`
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
`;

const StyledCentralValueContainer = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: ${props => props.theme.sectionDashboard.widget.venn.bigNumber.textColor};
`;

const StyledTopValueContainer = styled.div`
    text-align: center;
    width: 100%;
`;

const StyledSmallText = styled.p`
    color: ${props => props.theme.sectionDashboard.widget.venn.bigNumber.textColor};
    font-size: 0.7rem;
    font-weight: 500;
    margin-bottom: 5px;
`;

const StyledPillContainer = styled.div`
    margin: 5px 0px;
    border-radius: 15px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 2px 8px;
    color: ${props => props.theme.sectionDashboard.widget.venn.bigNumber.textColor};
    background-color: rgba(255, 255, 255, 0.4);
`;

const StyledP = styled.p`
    margin: 0;
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 5px;
    font-weight: 600;
`;

export type WidgetVennBigNumberProps = {
    data: VennSeriesObjectData[];
    direction: WidgetDirection;
};

const WidgetVennBigNumber = ({ data, direction }: WidgetVennBigNumberProps) => {
    return (
        <StyledBoxContainer direction={direction}>
            {data.map((data, index) => {
                return (
                    <StyledBox backgroundColor={graphColours[index].splineGradientStart} key={`${data.value}${index}`}>
                        <StyledValueContainer>
                            {data.sets && (
                                <StyledTopValueContainer>
                                    <StyledSmallText>{data.sets.join(' & ')}</StyledSmallText>
                                </StyledTopValueContainer>
                            )}
                            <StyledCentralValueContainer>
                                {data.value}
                                <StyledP>visits</StyledP>
                            </StyledCentralValueContainer>
                            {index === 0 && <StyledPillContainer>Top</StyledPillContainer>}
                        </StyledValueContainer>
                    </StyledBox>
                );
            })}
        </StyledBoxContainer>
    );
};

export default WidgetVennBigNumber;
