import * as resources from '../configurations/resources';

export const firstBreadcrumbResources = [
    resources.REPORT_MCS_CHANNEL,
    resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
];

export const excludeBreadcrumbsConfig = [
    {
        channels: ['Direct', 'SEO', 'Email', 'Social Paid', 'Social Organic', 'Display'],
        exclude: [
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
        ],
    },
    {
        channels: ['PPC'],
        exclude: [
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
        ],
    },
    {
        resources: [resources.REPORT_MCS_REFERRER, resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_DEVICE,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.REPORT_MCS_SALES_PATH,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,

            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
        ],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_DEVICE,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_SALES_PATH,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_SKU_LANDING,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
        ],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_DEVICE,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.REPORT_MCS_SALES_PATH,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_SKU_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
        ],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_DEVICE,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.REPORT_MCS_SALES_PATH,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [resources.REPORT_MCS_REGION, resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.REPORT_MCS_SALES_PATH,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
        ],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_DEVICE,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.REPORT_MCS_SALES_PATH,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [resources.REPORT_MCS_SHOPPING, resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_DEVICE,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.REPORT_MCS_SALES_PATH,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [
            resources.REPORT_SEOPT_KEYWORDS_LANDING,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
        ],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_DEVICE,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_SALES_PATH,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [resources.REPORT_MCS_DEVICE, resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.REPORT_MCS_SALES_PATH,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [resources.REPORT_MCS_SALES_JOURNEY, resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_DEVICE,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.REPORT_MCS_SALES_PATH,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
        ],
    },
    {
        resources: [resources.REPORT_MCS_SALES_PATH, resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH],
        exclude: [
            resources.REPORT_MCS_CHANNEL,
            resources.REPORT_MCS_PATTERN,
            resources.REPORT_MCS_UTM,
            resources.REPORT_MCS_REFERRER,
            resources.REPORT_MCS_LANDING,
            resources.REPORT_MCS_PPC,
            resources.REPORT_MCS_PPC_LANDING,
            resources.REPORT_MCS_SHOPPING,
            resources.REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.REPORT_MCS_REGION,
            resources.REPORT_MCS_DEVICE,
            resources.REPORT_MCS_LANDING_PATH_REFERRER,
            resources.REPORT_MCS_SALES_JOURNEY,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_ADOBE_CAMPAIGN_LABELS,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
            resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
        ],
    },
];
