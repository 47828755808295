import React from 'react';
import styled from 'styled-components';

// Styles
const StyledTabSkeletonWrapper = styled.div`
    width: 50%;
    display: inline-block;
    margin-right: auto;
`;

const StyledTabSkeleton = styled.div`
    height: 32px;
    position: relative;
`;

const StyledTabSkeletonItem = styled.div`
    display: inline-block;
    position: relative;
    height: 100%;
    width: calc(25% - 12px);
    margin: 0 6px;
    border-radius: 12px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const StyledTabListWrapper = styled.div`
    margin-right: auto;
`;

const StyledTabList = styled.div`
    display: flex;
    user-select: none;
    flex-wrap: wrap;
    gap: 5px 0;
`;

const StyledTabListItem = styled.div<{ isSelected: boolean }>`
    user-select: none;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 500;
    line-height: 36px;
    color: ${props => props.theme.colours.textDefault};
    min-width: 48px;
    margin-right: 6px;
    padding: 0 16px;
    background-image: ${props => (props.isSelected ? `none` : props.theme.tabGradient)};
    background-color: ${props => props.isSelected && props.theme.colours.white};
    border: 1px solid ${props => props.theme.colours.borderGrey};
    border-bottom: 1px solid ${props => props.theme.colours.white};
    border-radius: 8px 8px 0 0;
    transition: all 0.2s ease-in-out;

    &:hover {
        opacity: 0.76;
        color: ${props => props.theme.colours.textDark};
    }

    &:active {
        opacity: 0.68;
        color: ${props => props.theme.colours.black};
    }
`;

// Types
export type TabListObject = {
    displayName: string;
    id: number;
    isSelected: boolean;
    key: number;
    linkedResourceKey: number;
    tabName: string;
};

export type TabListProps = {
    noData: boolean;
    tabLists: TabListObject[];
    loading: boolean;
    handleTabElementClick: (tabId: number) => void;
};

const TabList = ({ noData, tabLists, loading, handleTabElementClick }: TabListProps) => {
    const skeletonElement = (
        <StyledTabSkeletonWrapper>
            <StyledTabSkeleton>
                <StyledTabSkeletonItem />
                <StyledTabSkeletonItem />
                <StyledTabSkeletonItem />
                <StyledTabSkeletonItem />
            </StyledTabSkeleton>
        </StyledTabSkeletonWrapper>
    );

    const tabElement = (
        <StyledTabListWrapper>
            <StyledTabList data-testid="tab-list">
                {tabLists.map(tab => (
                    <StyledTabListItem
                        key={tab.linkedResourceKey}
                        isSelected={tab.isSelected}
                        onClick={() => handleTabElementClick(tab.linkedResourceKey)}
                        data-testid="tab-list-item"
                    >
                        {tab.displayName}
                    </StyledTabListItem>
                ))}
            </StyledTabList>
        </StyledTabListWrapper>
    );

    return <>{noData && loading ? skeletonElement : tabElement}</>;
};

export default TabList;
