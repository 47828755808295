// React Dependencies
import React, { useEffect, useState } from 'react';

// Libraries
import axios from 'axios';
import styled from 'styled-components';

// Redux
import { connect } from 'react-redux';
import { setModal, removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

// Helpers & Reference Objects
import { generateUrl } from '../../helpers/request-builder';
import { GroupRule } from '../../helpers/segmenter/group-rule';
import { SEGMENTER_CONFIG } from '../../configurations/account-configuration/segmenter';

// Components
import CreateSegmentGroup from './create-segment-group';
import LoadingSpinner from '../../components/loading-spinner';
import FormTextInput from '../../components/form-fields/form-text-input';
import FormTextArea from '../../components/form-fields/form-text-area';
import WarningMessage from '../../components/warning-message';
import LayoutModalNavigation from '../../components/navigation/layout-modal-navigation';
import FormLabel from '../../components/form-fields/form-label';
import ToggleWithLabels from '../../components/inputs/toggle-with-labels';
import InputButton from '../../components/inputs/input-button';

// Styled Components
import { StyledH1, StyledP } from './create-conditions';

export const StyledFormContainer = styled.div`
    background-color: #ffffff;
    padding: 20px;
`;

export const StyledLabelContainer = styled.div`
    margin-top: 1.5rem;
`;

export const StyledPatternContainer = styled.div`
    background-color: #ffffff;
    border: 1px solid #d3d3d3;
    border-bottom: 0;
    padding: 20px;
    margin-top: 20px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
`;

export const StyledSegmentContainer = styled.div`
    border: 1px solid #d3d3d3;
`;

export const StyledApiSelectorContainer = styled.div`
    display: flex;
    margin-top: 1.5rem;
    margin-left: 0.5rem;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
`;

export const StyledAddConditionButtonContainer = styled.div`
    text-align: left;
    margin-bottom: 20px;
    margin-top: 10.5px;
`;

const LayoutModalCreateSegment = props => {
    const { ADD_FORM_MODAL_TITLE, ADD_FORM_MODAL_DESCRIPTION, OPERATORS } = SEGMENTER_CONFIG;

    const [dataLoading, setDataLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [saveButtonLoading, setSaveButtonLoading] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [conditions, setConditions] = useState();
    const [pattern, setPattern] = useState();
    const [displayPattern, setDisplayPattern] = useState('No pattern');
    const [rule, setRule] = useState(new GroupRule(OPERATORS[0]));
    const [segmentName, setSegmentName] = useState('');
    const [segmentDescription, setSegmentDescription] = useState('');
    const [apiTargetList, setApiTargetList] = useState();
    const [checkedDataIndexList, setCheckedDataIndexList] = useState([]);

    // arbitrary state to use to ensure a rerender when the conditions and rules are updated in the GroupRule class
    const [count, setCount] = useState(0);

    // update final pattern every time there is a change to rules
    useEffect(() => {
        const pattern = rule.serialize();
        setPattern(pattern);
        setDisplayPattern(
            pattern.length > 0 ? `${segmentName || 'Segment'}: ${pattern}` : 'Start building your segment below...'
        );
    }, [rule, segmentName, count]);

    // api call - fetch all conditions
    useEffect(() => {
        fetchConditions();
        getListOfTargets();
    }, []);

    // enable save button when all data present
    useEffect(() => {
        if (segmentName.length > 0 && pattern.length > 0) {
            setSaveButtonDisabled(false);
        }
    }, [segmentName, pattern]);

    const getListOfTargets = () => {
        axios({
            method: 'GET',
            url: generateUrl('segmenter', 'segmenter-target'),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length > 0) {
                    const targets = response.data.objects.map(target => {
                        return {
                            id: target.id,
                            name: target.name,
                            resource_uri: target.resource_uri,
                            checked: false,
                        };
                    });
                    setApiTargetList(targets);
                }
            })
            .catch(error => {
                setPageError(true);
            });
    };

    const handleAddConditions = (groupItem, condition) => {
        groupItem.addCondition(condition);
        // trigger rerender
        setCount(count + 1);
    };

    const handleRemoveCondition = (groupItem, condition) => {
        groupItem.removeCondition(condition);
        // trigger rerender
        setCount(count + 1);
    };

    const handleAddGroup = groupItem => {
        groupItem.addGroup(OPERATORS[0]);
        // trigger rerender
        setCount(count + 1);
    };

    const handleRemoveGroup = groupItem => {
        const iterateGroupItem = obj => {
            obj.groupItems = obj.groupItems.filter(item => item.id !== groupItem.id);
            obj.groupItems.forEach(groupItem => iterateGroupItem(groupItem));
        };
        const tempRules = rule.clone();
        iterateGroupItem(tempRules);
        setRule(tempRules);
    };

    const handleUpdateOperator = (groupItem, operator) => {
        groupItem.updateOperator(operator);
        // trigger rerender
        setCount(count + 1);
    };

    const handleChangeSegmentName = e => {
        setSegmentName(e.target.value);
    };

    const handleChangeSegmentDescription = e => {
        setSegmentDescription(e.target.value);
    };

    const handleFormSave = e => {
        e.preventDefault();
        setSaveButtonDisabled(true);
        setSaveButtonLoading(true);
        saveSegment();
    };

    const handleCloseClick = () => {
        props.setModal('SegmenterEditSegment', {});
    };

    const handleChangeToggle = index => {
        apiTargetList[index].checked = !apiTargetList[index].checked;
        let tempCheckedDataIndexList = checkedDataIndexList;
        if (apiTargetList[index].checked) {
            if (!tempCheckedDataIndexList.includes(index)) {
                tempCheckedDataIndexList.push(index);
            }
        } else {
            if (tempCheckedDataIndexList.includes(index)) {
                tempCheckedDataIndexList.pop(index);
            }
        }
        setCheckedDataIndexList(tempCheckedDataIndexList);
    };

    const fetchConditions = () => {
        axios({
            method: 'GET',
            url: generateUrl('segmenter', 'segmenter-condition', [{ key: 'active', value: '1' }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const conditions = response.data.objects.map(condition => {
                    return {
                        id: condition.id,
                        functionName: condition.function.display_name,
                        relatedTable: condition.function.table_name,
                        relatedTableKey: condition.key,
                        operator: condition.function_operator.name,
                        inputValue: condition.value,
                        label: `${condition.display_name}`,
                        value: condition.id,
                        active: condition.active,
                    };
                });

                setConditions(conditions);
                setDataLoading(false);
            })

            .catch(error => {
                setPageError(true);
            });
    };

    const saveSegmenterTarget = async segementer_resource_uri => {
        apiTargetList.forEach(item => {
            if (item.checked) {
                const data = {
                    segmenter_target: item.resource_uri,
                    segmenter: segementer_resource_uri,
                };
                axios({
                    method: 'POST',
                    url: generateUrl('segmenter', 'segmenter-exports'),
                    data: data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            }
        });
    };

    const saveSegment = async () => {
        const data = {
            pattern: `(${pattern})`,
            active: true,
            segmenter: {
                name: segmentName,
                description: segmentDescription,
                active: true,
                deleted: false,
            },
        };

        axios({
            method: 'POST',
            url: generateUrl('segmenter', 'segmenter-rule'),
            data: data,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (checkedDataIndexList.length > 0) {
                    saveSegmenterTarget(response.data.segmenter.resource_uri);
                }

                setTimeout(() => {
                    setSaveButtonLoading(false);
                    props.addNotification({
                        copy: 'This segment have been successfully saved.',
                        type: NotificationMessageType.Success,
                    });
                    props.setModal('SegmenterEditSegment');
                }, 2000);
            })
            .catch(e => {
                props.addNotification({
                    copy: 'There was an issue trying to save your segment. Please try again later or contact Cubed Support.',
                    type: NotificationMessageType.Error,
                });
                props.removeModal();
            });
    };

    if (pageError) {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={true}
                    handleSaveChanges={handleFormSave}
                    saveChangesButtonLoading={saveButtonLoading}
                    handleCloseClick={handleCloseClick}
                    saveButtonDisabled={saveButtonDisabled}
                />
                <StyledH1>{ADD_FORM_MODAL_TITLE}</StyledH1>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </>
        );
    }

    if (!dataLoading && !pageError) {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={true}
                    handleSaveChanges={handleFormSave}
                    saveChangesButtonLoading={saveButtonLoading}
                    handleCloseClick={handleCloseClick}
                    saveButtonDisabled={saveButtonDisabled}
                />
                <StyledH1>{ADD_FORM_MODAL_TITLE}</StyledH1>

                <StyledP>{ADD_FORM_MODAL_DESCRIPTION}</StyledP>

                <StyledFormContainer>
                    <FormTextInput
                        inputPlaceholder="Enter a name for your Segment"
                        label="Segment Name:"
                        toolTipCopy="Enter a name for your Segment"
                        inputOnChange={handleChangeSegmentName}
                        inputValue={segmentName}
                        requiredField={true}
                    />
                    <FormTextArea
                        label="Description:"
                        toolTipCopy="Enter a description for your Segment"
                        inputValue={segmentDescription}
                        inputOnChange={handleChangeSegmentDescription}
                    />
                    <StyledLabelContainer>
                        <FormLabel
                            label={'EXPORTS:'}
                            toolTipCopy={'Select all the apis where you want to export the current segment.'}
                        />
                    </StyledLabelContainer>
                    {/* The API Selection Toggles */}
                    <StyledApiSelectorContainer>
                        {apiTargetList &&
                            apiTargetList.map((target, index) => (
                                <ToggleWithLabels
                                    checked={target.checked}
                                    onClick={() => handleChangeToggle(index)}
                                    singleLabel={target.name}
                                />
                            ))}
                    </StyledApiSelectorContainer>
                </StyledFormContainer>

                <StyledPatternContainer>{displayPattern}</StyledPatternContainer>

                <StyledSegmentContainer>
                    <CreateSegmentGroup
                        key={rule.id}
                        rules={rule}
                        groupItem={rule}
                        operators={OPERATORS}
                        conditions={conditions}
                        nested={false}
                        onAddCondition={handleAddConditions}
                        onRemoveCondition={handleRemoveCondition}
                        onAddGroup={handleAddGroup}
                        onUpdateOperator={handleUpdateOperator}
                        onRemoveGroup={handleRemoveGroup}
                    />
                </StyledSegmentContainer>
                <StyledButtonContainer>
                    <StyledAddConditionButtonContainer>
                        <InputButton
                            value={`CREATE NEW CONDITION`}
                            onClick={() => props.setModal('SegmenterEditConditions')}
                        />
                    </StyledAddConditionButtonContainer>
                </StyledButtonContainer>
            </>
        );
    } else {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={true}
                    handleSaveChanges={handleFormSave}
                    saveChangesButtonLoading={saveButtonLoading}
                    handleCloseClick={handleCloseClick}
                    saveButtonDisabled={saveButtonDisabled}
                />
                <StyledH1>{ADD_FORM_MODAL_TITLE}</StyledH1>
                <LoadingSpinner />
            </>
        );
    }
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
    };
};

export default connect(null, mapDispatchToProps)(LayoutModalCreateSegment);
