export const OPERATORS = [
    {
        label: 'All',
        value: 'and',
    },
    {
        label: 'Any',
        value: 'or',
    },
];

export const TABLE_REF = [
    {
        tableName: 'attrib_event',
        tastypieName: 'event',
    },
    {
        tableName: 'attrib_product',
        tastypieName: 'product',
    },
    {
        tableName: 'attrib_referer',
        tastypieName: 'referer',
    },
];

export const DURATION_DROPDOWN_ITEMS = [
    {
        label: 'seconds',
        value: 'seconds',
    },
    {
        label: 'minutes',
        value: 'minutes',
    },
    {
        label: 'hours',
        value: 'hours',
    },
    {
        label: 'days',
        value: 'days',
    },
    {
        label: 'months',
        value: 'months',
    },
];

export const SEGMENTER_HEADER = {
    columns: [
        {
            title: 'Segment Name',
        },
        {
            title: 'Segment Description',
        },
        {
            title: 'Segment ID',
        },
        {
            title: 'Export',
        },
    ],
};

export const CONDITION_HEADER = {
    columns: [
        {
            title: 'Condition Name',
        },
        {
            title: 'Function',
        },
    ],
};
export const SUBCHANNELS = [
    { value: 'name', label: 'Rule Name' },
    { value: 'description', label: 'Rule Description' },
    { value: 'subchannel', label: 'SubChannel' },
];
export const RULE_SEGMENTER_PATTERN = [
    { value: 'name', label: 'Rule Name' },
    { value: 'description', label: 'Rule Description' },
    { value: 'referer', label: 'Channel' },
];
