import React from 'react';
import styled from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useConfigTableContext } from '../../context/config-table-context';

// Icons
import IconEllipsis from '../../../../icons/ellipsis';

const StyledCellActions = styled.td`
    width: 50px;
`;

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledIconEllipsis = styled(IconEllipsis)`
    color: ${props => props.theme.colours.midGrey};
    height: 20px;
`;

export const StyledTrigger = styled(DropdownMenu.Trigger)`
    border: none;
    background: none;
    padding: 5px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => !props.disabled && 'pointer'};

    :hover,
    &[data-state='open'] {
        background-color: ${props => !props.disabled && 'rgba(0, 0, 0, 0.05)'};

        ${StyledIconEllipsis} {
            color: ${props => !props.disabled && props.theme.colours.darkGrey};
        }
    }
`;

const StyledContent = styled(DropdownMenu.Content)`
    background-color: #fff;
    z-index: 500002;
    box-shadow: 0px 1px 25px -1px rgba(0, 0, 0, 0.1), 0px 1px 12px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 5px 0px;
`;

const CellActions = ({ children }: { children: React.ReactNode }) => {
    const { status, tableDisabled } = useConfigTableContext();

    if (status === 'success') {
        return (
            <StyledCellActions>
                <StyledContainer>
                    <DropdownMenu.Root>
                        <StyledTrigger disabled={tableDisabled} data-testid="config-table-action-trigger">
                            <StyledIconEllipsis />
                        </StyledTrigger>

                        <DropdownMenu.Portal>
                            <StyledContent side="left" align="center">
                                <DropdownMenu.Group>{children}</DropdownMenu.Group>
                            </StyledContent>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                </StyledContainer>
            </StyledCellActions>
        );
    }

    return null;
};

export default CellActions;
