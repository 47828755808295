import React, { Component } from 'react';

import InputButton from '../input-button';
import ListBuilderList from './list-builder-list';

class ListBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            leftList: this.props.leftList !== undefined ? this.props.leftList.slice(0) : [],
            rightList: this.props.rightList !== undefined ? this.props.rightList.slice(0) : [],
            leftSelected: [],
            rightSelected: [],
            noItems: true,
        };
        this.onLeftSelectedItemsChanged = this.onLeftSelectedItemsChanged.bind(this);
        this.onRightSelectedItemsChanged = this.onRightSelectedItemsChanged.bind(this);
        this.onSelectAllClick = this.onSelectAllClick.bind(this);
        this.onSelectClick = this.onSelectClick.bind(this);
        this.onDeSelectAllClick = this.onDeSelectAllClick.bind(this);
    }

    componentDidMount() {
        if (this.state.leftList.length > 0 || this.state.rightList.length > 0) {
            this.setState({
                noItems: false,
            });
        }
    }

    onSelectAllClick() {
        this.setState(state => {
            let updatedRightList = state.rightList;
            state.leftList.forEach(item => {
                updatedRightList.push(item);
            });

            return {
                leftList: state.leftList.splice(0, state.leftList.length),
                rightList: updatedRightList,
                leftSelected: state.leftSelected.splice(0, state.leftSelected.length),
            };
        });
    }

    onSelectClick() {
        this.setState(state => {
            let leftList = state.leftList.filter(item => !state.leftSelected.includes(item));
            let rightList = state.rightList.concat(state.leftSelected);

            return {
                leftList,
                rightList,
                leftSelected: [],
            };
        });
    }

    onDeSelectAllClick() {
        this.setState(state => {
            let updatedLeftList = state.leftList;
            state.rightList.forEach(item => {
                updatedLeftList.push(item);
            });

            return {
                leftList: updatedLeftList,
                rightList: state.rightList.splice(0, state.rightList.length),
                rightSelected: state.rightSelected.splice(0, state.rightSelected.length),
            };
        });
    }

    onDeSelectClick() {}

    onLeftSelectedItemsChanged(selectedItems) {
        this.setState({
            leftSelected: selectedItems,
        });
    }

    onRightSelectedItemsChanged(selectedItems) {
        this.setState({
            rightSelected: selectedItems,
        });
    }

    render() {
        return (
            <div className="list-builder row">
                <div className="list-builder__list-left">
                    <h3>{this.props.leftListTitle}</h3>
                    <ListBuilderList
                        items={this.state.leftList}
                        noItems={false}
                        onSelectedItemsChanged={this.onLeftSelectedItemsChanged}
                    />
                    <InputButton
                        styleClass="list-builder__button select"
                        value="ASSIGN ALL"
                        onClick={this.onSelectAllClick}
                    />
                </div>
                <div className="list-builder__central-buttons">
                    <InputButton
                        styleClass="list-builder__button select thin"
                        value={[<i className="fas fa-chevron-right"></i>]}
                        onClick={this.onSelectClick}
                    />
                    <InputButton
                        styleClass="list-builder__button deselect thin"
                        value={[<i className="fas fa-chevron-left"></i>]}
                    />
                </div>
                <div className="list-builder__list-right">
                    <h3>{this.props.rightListTitle}</h3>
                    <ListBuilderList
                        items={this.state.rightList}
                        noItems={false}
                        onSelectedItemsChanged={this.onRightSelectedItemsChanged}
                    />
                    <InputButton
                        styleClass="list-builder__button deselect"
                        value="UN-ASSIGN ALL"
                        onClick={this.onDeSelectAllClick}
                    />
                </div>
            </div>
        );
    }
}

export default ListBuilder;
