import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal, setModal } from '../../../redux/actions/modal';
import { removePopup, setPopup } from '../../../redux/actions/popup';
import { addNotification } from '../../../redux/actions/notification';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { ConfigDataSuccess } from '../../../react-query/types';
import { FieldValues } from 'react-hook-form';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePatchMultiResource from '../../../react-query/hooks/use-patch-multi-resources';
import usePatchMultiResourceWithPayload from '../../../react-query/hooks/use-patch-multi-resource-with-payload';

// Config
import { CONFIG_BRAND_SPLIT_CONFIG } from '../../../configurations/resources-config';

// Helpers
import { generatePath } from '../../../helpers/request-builder';

// Components
import ModalNavigation from '../../../components/modal-navigation';
import ConfigTable from '../../../components/tables/config-table/config-table';
import FormAddBrandSplitConfig from './forms/form-add-brand-split-config';
import Form from '../../../components/forms/form';

const StyledEditContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 10px 20px;
    margin-top: 20px;
`;

type BrandSplitConfig = {
    id: number;
    sectorId: number;
    sector: string;
    purchasedId: number;
    purchased: string;
    priceId: number;
    price: string;
    innovationId: number;
    innovation: string;
    categoryId: number;
    category: string;
    sizeId: number;
    size: string;
    selected: boolean;
};

const LayoutModalManageBrandSplitConfig = () => {
    const dispatch = useDispatch();

    const [brandSplitConfig, setBrandSplitConfig] = useState<BrandSplitConfig[]>([]);
    const [brandSplitConfigToEdit, setBrandSplitConfigToEdit] = useState<BrandSplitConfig>();

    // Queries
    const brandSplitQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_BRAND_SPLIT_CONFIG,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
    });

    useEffect(() => {
        if (brandSplitQuery.data) {
            setBrandSplitConfig(
                brandSplitQuery?.data?.objects.map(object => {
                    return {
                        id: object.id,
                        sectorId: object.sector.id,
                        sector: object.sector.sector,
                        purchasedId: object.purchased.id,
                        purchased: object.purchased.purchased,
                        priceId: object.price.id,
                        price: object.price.price,
                        innovationId: object.innovation.id,
                        innovation: object.innovation.innovation,
                        categoryId: object.category.id,
                        category: object.category.category,
                        sizeId: object.size.id,
                        size: object.size.size,
                        selected: false,
                    };
                })
            );
        }
    }, [brandSplitQuery.data]);

    // Mutations
    const brandSplitBulkDeleteMutation = usePatchMultiResource({
        resource: CONFIG_BRAND_SPLIT_CONFIG,
        resourceId: brandSplitConfig.filter(config => config.selected).map(config => config.id.toString()),
        data: brandSplitConfig.filter(config => config.selected).map(_ => ({ active: 0 })),
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'Brand split configurations removed successfully', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({ copy: 'An error occurred while removing brand split configurations', type: 'error' })
            );
        },
    });

    const brandSplitSingleDeleteMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_BRAND_SPLIT_CONFIG,
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'Brand split configuration removed successfully', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'An error occurred while removing the brand split configuration',
                    type: 'error',
                })
            );
        },
    });

    const brandSplitEditMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_BRAND_SPLIT_CONFIG,
        handleOnSuccess: () => {
            setBrandSplitConfigToEdit(undefined);
            dispatch(addNotification({ copy: 'Brand split configuration successfully updated', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'An error occurred while updating the brand split configuration',
                    type: 'error',
                })
            );
        },
    });

    // Handlers
    const handleCheckboxChange = (rowId: number) => {
        setBrandSplitConfig(
            brandSplitConfig.map(config => {
                if (config.id === rowId) {
                    return { ...config, selected: !config.selected };
                }
                return config;
            })
        );
    };

    const handleBulkDelete = () => {
        dispatch(removePopup());
        brandSplitBulkDeleteMutation.mutate();
    };

    const handleBulkDeleteClick = () => {
        dispatch(
            setPopup({
                title: 'Remove Brand Split Configuration',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove these brand split configurations?',
                },
                buttons: [
                    {
                        value: 'Yes, Remove',
                        onClick: handleBulkDelete,
                    },
                    {
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                        onClick: () => dispatch(removePopup()),
                    },
                ],
            })
        );
    };

    const handleSingleDelete = (rowId: number) => {
        dispatch(removePopup());
        brandSplitSingleDeleteMutation.mutate({
            payload: [{ active: 0 }],
            resourceId: [brandSplitConfig.find(config => config.id === rowId)!.id.toString()],
        });
    };

    const handleSingleDeleteClick = (rowId: number) => {
        dispatch(
            setPopup({
                title: 'Remove Brand Split Configuration',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove this brand split configuration?',
                },
                buttons: [
                    {
                        value: 'Yes, Remove',
                        onClick: () => handleSingleDelete(rowId),
                    },
                    {
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                        onClick: () => dispatch(removePopup()),
                    },
                ],
            })
        );
    };

    const handleSubmit = (data: FieldValues) => {
        brandSplitEditMutation.mutate({
            payload: [
                {
                    ...(data.sector && {
                        sector: generatePath('config', 'brand-split-sector', String(data.sector)),
                    }),
                    ...(data.purchased && {
                        purchased: generatePath('config', 'brand-split-purchased', String(data.purchased)),
                    }),
                    ...(data.price && {
                        price: generatePath('config', 'brand-split-price', String(data.price)),
                    }),
                    ...(data.innovation && {
                        innovation: generatePath('config', 'brand-split-innovation', String(data.innovation)),
                    }),
                    ...(data.category && {
                        category: generatePath('config', 'brand-split-category', String(data.category)),
                    }),
                    ...(data.size && {
                        size: generatePath('config', 'brand-split-size', String(data.size)),
                    }),
                },
            ],
            resourceId: [brandSplitConfigToEdit!.id.toString()],
        });
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(removeModal()),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Manage Brand Split Configuration</h2>

            <ConfigTable
                status={
                    brandSplitBulkDeleteMutation.isPending ||
                    brandSplitSingleDeleteMutation.isPending ||
                    brandSplitEditMutation.isPending
                        ? 'pending'
                        : brandSplitQuery.status
                }
                isFetching={brandSplitQuery.isFetching}
                disabled={
                    brandSplitBulkDeleteMutation.isPending ||
                    brandSplitSingleDeleteMutation.isPending ||
                    brandSplitEditMutation.isPending
                }
                empty={brandSplitQuery.data?.objects.length === 0}
            >
                <ConfigTable.Table>
                    <ConfigTable.Header>
                        <ConfigTable.Row key="brand-split-header">
                            <ConfigTable.CellHeader />
                            <ConfigTable.CellHeader>Sector</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Purchased</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Price</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Innovation</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Category</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Size</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader />
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {brandSplitConfig.map(config => {
                            return (
                                <ConfigTable.Row key={config.id}>
                                    <ConfigTable.CellCheckbox
                                        rowId={config.id}
                                        checked={config.selected}
                                        onCheckedChange={handleCheckboxChange}
                                    />
                                    <ConfigTable.Cell>{config.sector}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{config.purchased}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{config.price}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{config.innovation}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{config.category}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{config.size}</ConfigTable.Cell>
                                    <ConfigTable.CellActions>
                                        <ConfigTable.ActionDropdownItem
                                            rowId={config.id}
                                            type="edit"
                                            onClick={() => setBrandSplitConfigToEdit(config)}
                                        />
                                        <ConfigTable.ActionDropdownItem
                                            rowId={config.id}
                                            type="delete"
                                            label="Remove"
                                            onClick={handleSingleDeleteClick}
                                        />
                                    </ConfigTable.CellActions>
                                </ConfigTable.Row>
                            );
                        })}
                    </ConfigTable.Body>
                </ConfigTable.Table>
                <ConfigTable.ActionBar>
                    <ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionButton
                            type="delete"
                            label="Remove"
                            onClick={handleBulkDeleteClick}
                            isDisabled={brandSplitConfig.filter(config => config.selected).length === 0}
                        />
                    </ConfigTable.ActionBarJustifyLeft>
                    <ConfigTable.ActionBarJustifyRight>
                        <ConfigTable.ActionButton
                            type="add"
                            label="Add Brand Split Configuration"
                            onClick={() => dispatch(setModal('AddBrandSplitConfig', {}))}
                        />
                    </ConfigTable.ActionBarJustifyRight>
                </ConfigTable.ActionBar>
            </ConfigTable>

            {brandSplitConfigToEdit ? (
                <StyledEditContainer>
                    <h2>Edit Brand Split Config</h2>

                    <Form
                        status={brandSplitEditMutation.isPending ? 'disabled' : 'enabled'}
                        onSubmit={handleSubmit}
                        defaultValues={{
                            sector: brandSplitConfigToEdit.sectorId,
                            purchased: brandSplitConfigToEdit.purchasedId,
                            price: brandSplitConfigToEdit.priceId,
                            innovation: brandSplitConfigToEdit.innovationId,
                            category: brandSplitConfigToEdit.categoryId,
                            size: brandSplitConfigToEdit.sizeId,
                        }}
                    >
                        <FormAddBrandSplitConfig />
                        <Form.Footer>
                            <Form.InputButton type="submit" value="Save" loading={brandSplitEditMutation.isPending} />
                            <Form.InputButton
                                type="button"
                                value="Cancel"
                                onClick={() => setBrandSplitConfigToEdit(undefined)}
                                buttonTheme={ButtonThemes.Secondary}
                            />
                        </Form.Footer>
                    </Form>
                </StyledEditContainer>
            ) : null}
        </>
    );
};

export default LayoutModalManageBrandSplitConfig;
