/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import TablePaginationPageListItem from './table-pagination-page-list-item';
import TablePaginationPageIncreaseItem from './table-pagination-page-increase-item';

// Styles
const StyledPaginationList = styled.ul`
    list-style: none;
    display: inline-box;
    position: relative;
`;

// Types
type Pages = {
    pageNumber?: number;
    isCurrent?: boolean;
}[];

type TablePaginationPageListProps = {
    currentPage: number;
    currentRowCount: number;
    disabled?: boolean;
    maxButtons: number;
    maxPage: number;
    minPage: number;
    ownerCallback: (key: string, value: number) => void;
    totalResults: number;
};

const TablePaginationPageList = ({
    currentPage,
    currentRowCount,
    disabled,
    maxButtons,
    maxPage,
    minPage,
    ownerCallback,
    totalResults,
}: TablePaginationPageListProps) => {
    const [currentPageState, setCurrentPageState] = useState(currentPage);
    const [pages, setPages] = useState<Pages>([]);

    useEffect(() => {
        generateButtonList();
    }, []);

    useEffect(() => {
        generateButtonList();
    }, [currentRowCount, totalResults, maxPage, currentPageState]);

    const getMaxPage = () => {
        return Math.ceil(totalResults / currentRowCount);
    };

    const initialiseButtonSides = (currentPage: number, maxPage: number, idealSideLength: number) => {
        let prePages = [];
        let postPages = [];

        for (let i = 1; i <= idealSideLength; i++) {
            if (currentPage - i >= 1) {
                prePages.unshift({ pageNumber: currentPage - i });
            }
            if (currentPage + i <= maxPage) {
                postPages.push({ pageNumber: currentPage + i });
            }
        }

        if (prePages.length < idealSideLength) {
            let postPageCount = postPages.length;
            for (let i = 1; i <= idealSideLength - prePages.length; i++) {
                postPages.push({ pageNumber: currentPage + i + postPageCount });
            }
        }

        if (postPages.length < idealSideLength) {
            let prePageCount = prePages.length;
            for (let i = 1; i <= idealSideLength - postPages.length; i++) {
                prePages.unshift({ pageNumber: currentPage - i - prePageCount });
            }
        }

        if (prePages.length > 0) prePages[0] = { pageNumber: 1 };

        if (prePages[1] && prePages[1]['pageNumber'] > 2) prePages[1] = {};

        if (postPages.length > 0) postPages[postPages.length - 1] = { pageNumber: maxPage };

        if (postPages[postPages.length - 2] && postPages[postPages.length - 2]['pageNumber'] < maxPage - 1)
            postPages[postPages.length - 2] = {};

        return [prePages, postPages];
    };

    const generateButtonList = () => {
        let pages = [];
        let prePages = [];
        let postPages = [];
        let currentPage = currentPageState;
        let idealSideLength = Math.floor(maxButtons / 2);

        if (getMaxPage() === 1) {
            pages = [{ pageNumber: 1, isCurrent: true }];
            setCurrentPageState(1);
        } else if (getMaxPage() < maxButtons) {
            pages = Array.from(Array(getMaxPage() - minPage + 1).keys()).map(function (value) {
                return { pageNumber: value + 1, isCurrent: currentPage === value + 1 };
            });
        } else {
            [prePages, postPages] = initialiseButtonSides(currentPage, getMaxPage(), idealSideLength);
            pages = [...prePages, { pageNumber: currentPage, isCurrent: true }, ...postPages];
        }

        setPages(pages);
    };

    const renderPageList = () => {
        return pages.map(page => {
            return (
                <TablePaginationPageListItem
                    key={page.pageNumber}
                    isCurrent={page.isCurrent}
                    pageNumber={page.pageNumber}
                    ownerCallback={setCurrentPage}
                    disabled={disabled!}
                />
            );
        });
    };

    const setCurrentPage = (page: number) => {
        ownerCallback('page', page);
        setCurrentPageState(page);
        generateButtonList();
    };

    if (pages.length < 1) {
        return null;
    }

    return (
        <StyledPaginationList>
            <TablePaginationPageIncreaseItem
                ownerCallback={setCurrentPage}
                minPage={minPage}
                maxPage={maxPage}
                currentPage={currentPage}
                increment={-1}
                disabled={disabled!}
            >
                {'<'}
            </TablePaginationPageIncreaseItem>
            {renderPageList()}
            <TablePaginationPageIncreaseItem
                ownerCallback={setCurrentPage}
                minPage={minPage}
                maxPage={maxPage}
                currentPage={currentPage}
                increment={1}
                disabled={disabled!}
            >
                {'>'}
            </TablePaginationPageIncreaseItem>
        </StyledPaginationList>
    );
};

export default TablePaginationPageList;
