import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

//Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Context
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Hooks
import { useSelector } from 'react-redux';
import useWidgetTheme from '../../hooks/base/use-widget-theme';

// Component
import WidgetBase from '../base/widget-base';

// Configurations
import { ChartDateGranularity } from '../../../configurations/common/chart-types';
import { sparkLineConfig } from '../../configurations/widget-spark-line-config';

// Helper
import { getRawValueAsNumber } from '../../helpers/table-data';
import { formatNumber, formatValue } from '../../helpers/format-number';

// Types
import { SparkLineData, TotalData, WidgetColour } from '../../types';
import dataTypes from '../../../filter-bar/enums/data-types';
import { RootState } from '../../../redux/store';

const StyledContainer = styled.div<{ colour: string; direction: 'horizontal' | 'vertical' }>`
    width: 100%;
    height: 100%;
    background-color: ${props => props.colour};
    display: flex;
    flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
`;

const StyledWidgetChart = styled.div<{ height: number; isDisabledSparkLine: boolean }>`
    width: 100%;
    height: 100%;
`;

const StyledTextContainer = styled.div<{
    direction: string;
    width: number;
    height: number;
    isDisabledSparkLine: boolean;
}>`
    width: ${props => (props.isDisabledSparkLine || props.direction === 'vertical' ? '100%' : '250px')};
    height: ${props =>
        props.isDisabledSparkLine || props.direction === 'horizontal' ? '100%' : props.height < 3 ? '120px' : '200px'};
    display: flex;
    flex-direction: ${props =>
        props.direction === 'horizontal' && props.isDisabledSparkLine
            ? 'row'
            : props.width >= 2 && props.height > 1
            ? 'row'
            : 'column'};
    justify-content: space-evenly;
    align-items: 'center';
    background-color: ${props =>
        !props.isDisabledSparkLine && props.theme.sectionDashboard.widget.sparkLine.totalsBackgroundColor};
`;

const StyledTextSection = styled.div<{ width: number; height: number; direction: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px;
    flex-grow: ${props => props.direction === 'horizontal' && 1};
    flex-basis: ${props => props.direction === 'horizontal' && 0};
    width: ${props => props.direction === 'vertical' && props.width > 1 && '50%'};
    border-top: ${props => (props.height > 1 && props.width > 1 ? '1px solid rgba(255, 255, 255, 0.3)' : null)};
    border-left: ${props => (props.height === 1 && props.width > 5 ? '1px solid rgba(255, 255, 255, 0.3)' : null)};
`;

const StyledValue = styled.h1<{ colour: string; colourCode: number; width: number; height: number }>`
    margin: 0;
    padding: 0;
    font-size: ${props =>
        props.height === 1 && (props.width < 2 || props.width > 5)
            ? '1rem'
            : props.height === 1 && (props.width >= 3 || props.width <= 5)
            ? '1.3rem'
            : '1.2rem'};
    color: ${props =>
        props.colourCode === 0 ? props.colour : props.theme.sectionDashboard.widget.sparkLine.textColor};
`;

const StyledText = styled.p<{ colour: string; colourCode: number; width: number; height: number }>`
    margin: 0;
    padding: 0;
    font-size: ${props => (props.height <= 2 ? '0.8rem' : '0.9rem')};
    font-weight: ${props => props.theme.sectionDashboard.widget.sparkLine.fontWeight};
    color: ${props =>
        props.colourCode === 0 ? props.colour : props.theme.sectionDashboard.widget.sparkLine.textColor};
`;

const StyledDivider = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(255, 255, 255, 0.3);
`;

export type WidgetSparkLineProps = {
    title: string;
    data: SparkLineData;
    totalData: TotalData;
    chartDateGranularity?: string;
    href?: string;
    colour?: WidgetColour;
};

const WidgetSparkLine = ({
    title,
    data,
    totalData,
    chartDateGranularity = ChartDateGranularity.Day,
    href,
    colour = WidgetColour.Default,
}: WidgetSparkLineProps) => {
    const { width, height } = useSectionDashboard();
    const dates = useSelector((state: RootState) => state.date);

    const widgetTheme = useWidgetTheme(colour);

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const direction = height === 1 ? (width === 1 ? 'vertical' : 'horizontal') : 'vertical';
    const isDisabledSparkLine = width === 1 || (height === 1 && width < 6);
    const thisPeriodDateRange = moment(dates.endDate).diff(moment(dates.startDate), 'days') + 1;

    const chartOptions = {
        ...sparkLineConfig,
        chart: {
            ...sparkLineConfig.chart,
        },
        series: [
            {
                name: data.metric.displayName,
                data: data.series,
                color: colour === 0 ? widgetTheme.splineGradientStart : '#FFFFFF',
            },
        ],
    };

    let totalForDateRange: string | number = 'No Data';
    if (totalData && totalData.status === 'success') {
        totalForDateRange = formatNumber(getRawValueAsNumber(totalData.totals, data.metric.rawName), width);

        if (data.metric.dataType === dataTypes.MONEY) {
            totalForDateRange = formatValue(totalForDateRange, 'currency');
        }
    }

    let recentTotal = 'No Data';

    if (data.mostRecentTotal) {
        recentTotal = formatNumber(data.mostRecentTotal, width);

        if (data.metric.dataType === dataTypes.MONEY) {
            recentTotal = formatValue(recentTotal, 'currency');
        }
    }

    return (
        <WidgetBase title={title} href={href}>
            <StyledContainer colour={widgetTheme.gradientEnd} direction={direction}>
                {!isDisabledSparkLine && (
                    <StyledWidgetChart height={height} isDisabledSparkLine={isDisabledSparkLine}>
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={chartOptions}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    </StyledWidgetChart>
                )}
                <StyledTextContainer
                    direction={direction}
                    width={width}
                    height={height}
                    isDisabledSparkLine={isDisabledSparkLine}
                >
                    <StyledTextSection width={width} height={height} direction={direction}>
                        <StyledValue
                            colour={widgetTheme.splineGradientStart}
                            colourCode={colour}
                            width={width}
                            height={height}
                        >
                            {totalForDateRange}
                        </StyledValue>
                        <StyledText
                            colour={widgetTheme.splineGradientStart}
                            colourCode={colour}
                            width={width}
                            height={height}
                        >
                            {chartDateGranularity === ChartDateGranularity.Hour
                                ? 'Total for selected date range'
                                : `Total for date range (${thisPeriodDateRange} ${chartDateGranularity}${
                                      thisPeriodDateRange > 1 ? 's' : ''
                                  })`}
                        </StyledText>
                    </StyledTextSection>
                    <StyledDivider />
                    <StyledTextSection width={width} height={height} direction={direction}>
                        <StyledValue
                            colour={widgetTheme.splineGradientStart}
                            colourCode={colour}
                            width={width}
                            height={height}
                        >
                            {recentTotal}
                        </StyledValue>
                        <StyledText
                            colour={widgetTheme.splineGradientStart}
                            colourCode={colour}
                            width={width}
                            height={height}
                        >
                            {chartDateGranularity === ChartDateGranularity.Hour
                                ? 'Most recent value'
                                : `Most recent ${chartDateGranularity}'s total`}
                        </StyledText>
                    </StyledTextSection>
                </StyledTextContainer>
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetSparkLine;
