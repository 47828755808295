import { useEffect } from 'react';
import { useState } from 'react';
import { VennComparisonData, VennData, VennSeriesObject, WidgetTheme } from '../../../types';
import theme from '../../../../styled-components/theme.styled';

export type VennMenuConfig = {
    label: string;
    colour: WidgetTheme;
    checked: boolean;
};

type UseWidgetVennStateArgs = {
    data: VennData | VennComparisonData;
};

const useWidgetVennState = ({ data }: UseWidgetVennStateArgs) => {
    const [menuConfig, setMenuConfig] = useState<VennMenuConfig[]>();

    const generateMenuConfig = (seriesNames: string[], series: VennSeriesObject) => {
        const config: VennMenuConfig[] = [];

        seriesNames.forEach(seriesName => {
            const matchingSet =
                series &&
                series.data.find(dataItem => dataItem.sets.length === 1 && dataItem.sets.includes(seriesName));

            if (matchingSet) {
                config.push({
                    label: seriesName,
                    colour: matchingSet?.themeColour || theme.sectionDashboard.colour.indigo,
                    checked: true,
                });
            }
        });

        return config;
    };

    const resetMenuConfig = () => {
        if (data.status === 'success') {
            let names;
            let comparisonNames;
            let config: VennMenuConfig[] = [];

            if (data.series && data.series.data.length > 0) {
                names = Array.from(new Set(data.series.data.map(dataItem => dataItem.sets.map(set => set)).flat()));
            }
            if (
                'comparisonSeries' in data &&
                data.type === 'vennComparison' &&
                data.comparisonSeries &&
                data.comparisonSeries.data.length > 0
            ) {
                comparisonNames = Array.from(
                    new Set(data.comparisonSeries.data.map(dataItem => dataItem.sets.map(set => set)).flat())
                );
            }

            let seriesNames = Array.from(
                new Set([...(names ? names : []), ...(comparisonNames ? comparisonNames : [])])
            );

            if ('series' in data && data.series && data.series.data.length > 0) {
                config = generateMenuConfig(seriesNames, data.series);
            }
            if ('comparisonSeries' in data && data.comparisonSeries && data.comparisonSeries.data.length > 0) {
                const comparisonMenuConfig = generateMenuConfig(seriesNames, data.comparisonSeries);
                comparisonMenuConfig.forEach(comparisonItem => {
                    const matchingItemIndex = config.findIndex(item => item.label === comparisonItem.label);

                    if (matchingItemIndex !== -1) {
                        config[matchingItemIndex] = comparisonItem;
                    } else {
                        config.push(comparisonItem);
                    }
                });
            }

            return config;
        }
    };

    useEffect(() => {
        if (data.status === 'success') {
            const config = resetMenuConfig();
            if (
                config &&
                !config.every((configItem, index) => menuConfig && configItem?.label === menuConfig[index]?.label)
            ) {
                setMenuConfig(config);
            }
        }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCheckboxChange = (itemLabel: string) => {
        if (data.status === 'success' && menuConfig && data.series) {
            setMenuConfig(
                menuConfig.map(menuItem =>
                    menuItem.label === itemLabel ? { ...menuItem, checked: !menuItem.checked } : menuItem
                )
            );
        }
    };

    return { menuConfig, handleCheckboxChange };
};

export default useWidgetVennState;
