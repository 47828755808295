// Core Dependencies
import Axios from 'axios';
import { generateUrlDetail } from '../../helpers/request-builder';
import Request from '../../helpers/request';

class APISearchConsoleSiteURL {
    constructor(data, owner) {
        // store tp model
        this.obj = data;

        this.owner = owner;

        this.resource_uri = 'api-searchconsole-siteurl';

        this.onToggle = this.onToggle.bind(this);
        this.save = this.save.bind(this);
        this.onEnableToggle = this.onEnableToggle.bind(this);
        this.onDeleteToggle = this.onDeleteToggle.bind(this);

        this.name = data.site_url || data.siteUrl || '';
        this.account_name = this.name;
        this.active = data.active || false;
        this.deleted = data.deleted || false;

        this.request = new Request();
    }

    save() {
        let data = {
            site_url: this.obj.siteUrl,
            active: this.active,
            deleted: this.deleted || false,
            oauth_connection: `/api/integrations/api-google-connection/${this.owner.obj.id}/`,
        };
        let methodType = 'POST';
        if (this.obj.id) {
            methodType = 'PUT';
            data.id = this.obj.id;
        }
        var url = generateUrlDetail('integrations', 'api-searchconsole-siteurl', this.obj.id);
        return Axios({
            method: methodType,
            url: url,
            data: data,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(resp => {
            if (resp.headers.location) {
                this.obj.resource_uri = resp.headers.location;

                // get id from location
                this.obj.id = resp.headers.location.split('/').slice(-2, -1);
            }
        });
    }

    delete() {
        Axios({
            method: 'DELETE',
            url: this.obj.resource_uri,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(resp => {
            if (resp.headers.location) {
                this.obj.resource_uri = resp.headers.location;

                // get id from location
                this.obj.id = resp.headers.location.split('/').slice(-2, -1);
            }
        });
    }

    onDeleteToggle() {
        this.deleted = !this.deleted;
        this.save();
    }

    // handle client accounts
    onEnableToggle() {
        this.active = !this.active;
        this.save();
    }

    // handle imports
    onToggle() {
        this.active = !this.active;
        //this.save();
    }
}
export class APISearchConsole {
    constructor(data, owner) {
        this.owner = owner;
        this.obj = data;
        this.siteURLs = [];

        // hold site urls we could import
        this.importSiteUrls = [];
        this.updated = false;

        this.request = new Request();

        this.handleImport = this.handleImport.bind(this);
        this.checkState = this.checkState.bind(this);
    }

    handleImport() {
        return new Promise((resolve, reject) => {
            let savingRequests = [];
            this.importSiteUrls.forEach(siteUrl => {
                if (siteUrl.active) {
                    savingRequests.push(siteUrl.save());
                }
            });
            Axios.all(savingRequests).then(
                Axios.spread((...res) => {
                    resolve(this.importSiteUrls);
                })
            );
        });
    }
    reimportdata(apiDomain, accountToken) {
        let fetchURL = `${apiDomain}/api/${accountToken}/integrations/google/fetch-site-urls/${this.obj.id}/`;
        return this.request.getUrl(fetchURL).then(res => {
            this.importSiteUrls = res.data.objects.map(siteurl => {
                let apiSiteUrl = new APISearchConsoleSiteURL(siteurl, this);
                return apiSiteUrl;
            });
            return this.importSiteUrls;
        });
    }

    checkState() {
        let disableApply = true;
        this.siteURLs.forEach(siteurl => {
            if (siteurl.active !== siteurl.obj.active || siteurl.deleted !== siteurl.obj.deleted) {
                disableApply = false;
            }
        });
        this.owner.setState({
            applyButtonDisabled: disableApply,
        });
    }
}
