/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

// Type
import { CubedField, CubedFieldHeatmap } from '../../../../types';
import { DataTableCellProperties, DataTableColumn } from '../../types';
import DataTypes from '../../../../filter-bar/enums/data-types';

// Styles
const StyledStickyColumn = css`
    position: sticky !important;
    left: 0;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.16);
    z-index: 2;
`;

const StyledTableBodyCell = styled('td')<{ isDimension: boolean; scrollPosX: number; columnWidth: number | string }>`
    ${props => props.isDimension && props.scrollPosX > 0 && StyledStickyColumn};

    ${props => props.theme.no_select()};
    line-height: 17px;
    font-size: 13px;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    transition: 0.2s ease-in-out !important;
    background-color: ${props => props.theme.colours.white};
    background-clip: padding-box;
    position: relative;
    cursor: pointer;

    max-width: ${props => props.columnWidth};
    min-width: ${props => props.columnWidth};
    width: ${props => props.isDimension && props.columnWidth};

    &:hover {
        background-color: ${props => props.theme.colours.hoverGrey} !important;
    }
`;

const StyledTableCellDisplayTextWrapper = styled.div<{
    isDimension: boolean;
    heatMap: string;
    loading: boolean;
    columnDataAlign: 'left' | 'right';
}>`
    position: relative;
    width: 100%;
    text-align: ${props => props.columnDataAlign};
    display: flex;

    white-space: ${props => props.isDimension && 'nowrap'};
    overflow: ${props => props.isDimension && 'hidden'};
    text-overflow: ${props => props.isDimension && 'ellipsis'};

    padding: 3px;
    font-size: 13px;
    text-transform: ${props => props.loading && 'italic'};

    color: ${props =>
        props.heatMap === 'red'
            ? props.theme.colours.red
            : props.heatMap === 'amber'
            ? props.theme.colours.orange
            : props.heatMap === 'green'
            ? props.theme.colours.green
            : ''};
`;

const StyledTableCellText = css`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledTableCellDisplayTextLink = styled.a`
    ${StyledTableCellText};
    color: ${props => props.theme.colours.primary};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const StyledTableCellDisplayTextSpan = styled.span`
    ${StyledTableCellText};
`;

const StyledTableCellDisplayTextProportion = styled.div`
    float: right;
    min-width: 60px;
    text-align: right;
`;

const StyledTableCellHeatmap = styled.div<{ heatMap: string; heatmapOpacity: number }>`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    background-color: ${props =>
        props.heatMap === 'red'
            ? props.theme.colours.red
            : props.heatMap === 'amber'
            ? props.theme.colours.orange
            : props.heatMap === 'green'
            ? props.theme.colours.green
            : ''};

    opacity: ${props => props.heatmapOpacity};
`;

const StyledTableCellRercentage = styled.div`
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;

    &:hover > span {
        opacity: 1;
    }
`;

const StyledTableCellRercentageText = styled.span<{ alwaysShow: boolean }>`
    line-height: 22px;
    padding: 2px 6px;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => (props.alwaysShow ? 0.75 : 0)};
    transition: 0.2s ease-in-out;
`;

const StyledTableCellRercentageBar = styled.div<{ width: string }>`
    opacity: 0.2;
    background-color: ${props => props.theme.colours.green};
    width: ${props => (props.width ? props.width : 0)}%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: 0.2s ease-in-out;
`;

// Types
type DataTableBodyCellProps = {
    loading: boolean;
    handleDimensionClick: (
        event: React.MouseEvent<HTMLElement>,
        column: CubedField,
        properties: DataTableCellProperties
    ) => void;
    column: DataTableColumn;
    properties: DataTableCellProperties | number;
    scrollPosX: number;
};

const DataTableBodyCell = ({
    loading,
    handleDimensionClick,
    column,
    properties,
    scrollPosX,
}: DataTableBodyCellProps) => {
    const [showProportion, setShowProportion] = useState(false);

    const { isDimension, fixedWidth, metricWidth, alwaysShowPercentages } = column;

    const goToChild = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (!column.children) return;
        handleDimensionClick(event, column, properties as DataTableCellProperties);
    };

    const handleCalculateHeatmap = (value: number, ranges: CubedFieldHeatmap) => {
        if (!ranges) {
            return;
        }

        if (ranges.default && value === ranges.default[0]) {
            return ranges.default[1];
        }

        for (let rangeName in ranges) {
            var range = ranges[rangeName as keyof CubedFieldHeatmap];
            if (range && value >= range[0] && value <= (range[1] as number)) return rangeName;
        }

        // fail safe
        return ranges.default![1];
    };

    const handleValueMouseOver = () => {
        if (isDimension) {
            return;
        }
        setShowProportion(true);
    };

    const handleValueMouseLeave = () => {
        if (isDimension) {
            return;
        }
        setShowProportion(false);
    };

    const renderDisplayData = () => {
        if (!properties) {
            return null;
        }

        const heatMap = column.heatmap
            ? handleCalculateHeatmap((properties as DataTableCellProperties).raw_value, column.heatmap!)
            : '';
        const trimmedValue =
            typeof properties === 'object' &&
            (properties.value !== undefined && properties.value.length > 100
                ? `${properties.value.substring(0, 100)} ...`
                : properties.value);

        const columnDataAlign = isDimension
            ? 'left'
            : column.dataType
            ? [DataTypes.NUMBER, DataTypes.MONEY, DataTypes.BOOL, DataTypes.FLOAT].includes(column.dataType)
                ? 'right'
                : 'left'
            : 'right';

        if (column.children && !loading && typeof properties === 'object') {
            return (
                <StyledTableCellDisplayTextWrapper
                    isDimension={isDimension!}
                    heatMap={heatMap!}
                    loading={loading}
                    columnDataAlign={columnDataAlign}
                >
                    <StyledTableCellDisplayTextLink href="#" title={properties.value} onClick={goToChild}>
                        {loading ? 'Loading...' : properties.value || '(None)'}
                    </StyledTableCellDisplayTextLink>
                </StyledTableCellDisplayTextWrapper>
            );
        } else if (loading && isDimension) {
            return (
                <StyledTableCellDisplayTextWrapper
                    isDimension={isDimension!}
                    heatMap={heatMap!}
                    loading={loading}
                    columnDataAlign={columnDataAlign}
                >
                    {typeof properties === 'object' ? (
                        <StyledTableCellDisplayTextSpan title={properties.value}>
                            {'Loading...'}
                        </StyledTableCellDisplayTextSpan>
                    ) : (
                        <StyledTableCellDisplayTextSpan title={properties.toString()}>
                            {properties.toString() || '(None)'}
                        </StyledTableCellDisplayTextSpan>
                    )}
                </StyledTableCellDisplayTextWrapper>
            );
        } else {
            return (
                <StyledTableCellDisplayTextWrapper
                    isDimension={isDimension!}
                    heatMap={heatMap!}
                    loading={loading}
                    columnDataAlign={columnDataAlign}
                >
                    {typeof properties === 'object' ? (
                        <StyledTableCellDisplayTextSpan title={properties.value}>
                            {trimmedValue || '(None)'}
                        </StyledTableCellDisplayTextSpan>
                    ) : (
                        <StyledTableCellDisplayTextSpan title={properties.toString()}>
                            {properties.toString() || '(None)'}
                        </StyledTableCellDisplayTextSpan>
                    )}

                    {showProportion && typeof properties === 'object' && properties.proportion && (
                        <StyledTableCellDisplayTextProportion
                            title={`${properties.value}  (${properties.proportion}%)`}
                        >
                            ({properties.proportion}%)
                        </StyledTableCellDisplayTextProportion>
                    )}
                </StyledTableCellDisplayTextWrapper>
            );
        }
    };

    const renderPercentage = () => {
        if (!properties || !(properties as DataTableCellProperties).percentage) {
            return null;
        }

        return (
            <StyledTableCellRercentage>
                <StyledTableCellRercentageText alwaysShow={alwaysShowPercentages!}>
                    {'(' + (properties as DataTableCellProperties).percentage + '%)'}
                </StyledTableCellRercentageText>
                <StyledTableCellRercentageBar width={(properties as DataTableCellProperties).percentage!} />
            </StyledTableCellRercentage>
        );
    };

    const renderHeatmap = () => {
        if (!column.heatmap) {
            return null;
        }

        const heatMap = handleCalculateHeatmap((properties as DataTableCellProperties).raw_value, column.heatmap);
        const heatmapOpacity = Math.min(0.33, Math.abs((properties as DataTableCellProperties).heatmap) / 3);
        return <StyledTableCellHeatmap heatMap={heatMap!} heatmapOpacity={heatmapOpacity!} />;
    };

    const renderCell = () => {
        const columnWidth = isDimension
            ? fixedWidth !== undefined
                ? fixedWidth
                : '270px'
            : metricWidth !== undefined
            ? metricWidth
            : '12em';

        return (
            <StyledTableBodyCell
                key="1"
                data-testid="table-body-cell"
                isDimension={isDimension!}
                scrollPosX={scrollPosX}
                columnWidth={columnWidth}
                onMouseOver={handleValueMouseOver}
                onMouseLeave={handleValueMouseLeave}
            >
                {renderDisplayData()}
                {renderPercentage()}
                {renderHeatmap()}
            </StyledTableBodyCell>
        );
    };

    return <>{renderCell()}</>;
};

export default DataTableBodyCell;
