import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CubedConfigResource, CubedResource } from '../../types';
import axios from 'axios';
import { generateUrlDetail } from '../../helpers/request-builder';
import generateQueryKey from '../helpers/generate-query-key';

type RequestResponseData = { [key: string]: string | number | boolean };

type UsePutMultiResourceWithPayloadArgs = {
    resource: CubedResource | CubedConfigResource;
    handleOnSuccess?: () => void;
    handleOnError?: () => void;
};

type MutationArgs = {
    payload: RequestResponseData[];
    resourceId: string[];
};

const usePutMultiResourceWithPayload = ({
    resource,
    handleOnSuccess,
    handleOnError,
}: UsePutMultiResourceWithPayloadArgs) => {
    const queryClient = useQueryClient();
    const queryKey = generateQueryKey({ resource });

    const mutation = useMutation({
        mutationFn: async ({ payload, resourceId }: MutationArgs) => {
            const requests = payload.map((resourceData, index) => {
                const url = generateUrlDetail(resource.category, resource.id, resourceId[index]);

                if (url) {
                    return axios({
                        method: 'PUT',
                        url: url,
                        data: resourceData,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).catch(error => {
                        throw new Error(error.response.data.message);
                    });
                }

                throw new Error('Error when generating url');
            });

            await Promise.all(requests);
        },
        onSuccess: () => {
            queryClient
                .invalidateQueries({
                    queryKey: queryKey,
                })
                .then(() => {
                    handleOnSuccess && handleOnSuccess();
                });
        },
        onError: handleOnError,
    });

    return mutation;
};

export default usePutMultiResourceWithPayload;
