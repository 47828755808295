import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';

// Component
import DraggableItem from './draggable-item';

// Styles
const StyledDragableList = styled.div<{ isDragging: boolean }>`
    background-color: ${props => props.theme.colours.light};
    width: 100%;
    padding: 6px;
    border: 1px solid ${props => (props.isDragging ? props.theme.colours.info : props.theme.colours.light)};
`;

// Types
type DragableItem = {
    id: string;
    itemId: number;
    content?: string;
};

export type DraggableListProps = {
    handleChange: (item: DragableItem[]) => void;
    items: DragableItem[];
    removeItem: (id: number) => void;
};

const DraggableList = ({ handleChange, items, removeItem }: DraggableListProps) => {
    const [dragableItems, setDragableItems] = useState<DragableItem[]>(items);

    useEffect(() => {
        setDragableItems(items);
    }, [items]);

    const handleRemoveItem = (id: number) => {
        removeItem(id);
    };

    const handleReorder = (list: DragableItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const items = handleReorder(dragableItems, result.source.index, result.destination.index);

        setDragableItems(items);

        handleChange(items);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <StyledDragableList
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDraggingOver}
                    >
                        {dragableItems.map((item, index) => (
                            <DraggableItem
                                key={item.id}
                                id={item.id}
                                index={index}
                                content={item.content}
                                removeItem={handleRemoveItem}
                            />
                        ))}

                        {provided.placeholder}
                    </StyledDragableList>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DraggableList;
