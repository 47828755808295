import { ResourceDataObject } from '../../react-query/types';
import { CubedField } from '../../types';
import { getValue } from './resource-data';

type JoinDimensionsArgs = {
    resourceObject: ResourceDataObject;
    displayField: CubedField;
    joiningField: CubedField;
};

const joinDimensions = ({ resourceObject, displayField, joiningField }: JoinDimensionsArgs) => {
    try {
        const label = getValue(resourceObject, displayField.rawName);
        const valueToJoin = getValue(resourceObject, joiningField.rawName);

        if (label !== 'unknown' && valueToJoin !== 'unknown') {
            return {
                ...resourceObject,
                values: {
                    ...resourceObject.values,
                    [displayField.rawName]: {
                        ...resourceObject.values[displayField.rawName],
                        value: `${label || `Unknown ${displayField.displayName}`} (${
                            valueToJoin || `Unknown ${joiningField.displayName}`
                        })`,
                    },
                },
            };
        }
        return resourceObject;
    } catch (error) {
        return resourceObject;
    }
};

export default joinDimensions;
