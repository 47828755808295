import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

// Helpers
import { generateUrl } from '../../helpers/request-builder';

// Components
import LoadingSpinner from '../../components/loading-spinner';
import VisitorDetails from './visitor-details';
import { useDispatch } from 'react-redux';
import { setDescription, setTitle } from '../../redux/actions/page-meta';

// Filter context
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';

const queryString = require('query-string');

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    max-width: 400px;
    margin: 0 auto;
    margin-top: 30px;
    padding: 20px;
    ${props => props.theme.boxShadow(1)};
`;

const StyledLoadingContainer = styled.div`
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
`;

const StyledTitle = styled.h1`
    text-align: center;
    color: ${props => props.theme.colours.offBlackLighter};
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 30px;
`;

const StyledP = styled.p`
    margin: 20px 0;
    line-height: 25px;
`;

const StyledFormSection = styled.div`
    margin-bottom: 25px;
`;

const StyledInput = styled.input`
    background-color: ${props => props.theme.colours.inputColour};
    width: 100%;
    height: 40px;
    margin: 12px 0 6px;
    padding: 0 5px;
    border: none;
    border-bottom: 2px solid ${props => props.theme.colours.offBlackLighter};
    border-radius: 2px;
`;

const StyledButton = styled.button`
    border: none;
    min-width: 40px;
    padding: 0 16px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 36px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.offBlack};
    text-align: center;
    width: 100%;

    &:disabled {
        cursor: not-allowed;
        background-color: ${props => props.theme.colours.darkerGrey};
    }
`;

const StyledErrorMessage = styled.p`
    color: ${props => props.theme.colours.danger};
    margin-top: 24px;
    text-align: center;
`;

const SingleCustomerFinder = ({ location, history }) => {
    const dispatch = useDispatch();

    const { setFilterStatus, setFilterMetricsOptions, setDatePickerConfig } = useContext(FilterBarContext);

    // form imputs
    const [formVisitorId, setFormVisitorId] = useState('');
    const [formTransactionId, setFormTransactionId] = useState('');
    const [formCustomerId, setFormCustomerId] = useState('');

    // visitor info
    const [visitorId, setVisitorId] = useState(null);
    const [visitor, setVisitor] = useState(null);

    // page states
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    // button states
    const visitorButtonDisabled = formVisitorId.length < 1;
    const transactionButtonDisabled = formTransactionId.length < 1;
    const customerButtonDisabled = formCustomerId.length < 1;

    useEffect(() => {
        dispatch(setTitle('Single Customer View'));
        dispatch(setDescription(''));

        // Filter bar configurations
        setFilterStatus({
            isEnableDatePicker: false,
            isEnableProductSelect: false,
            isEnableMetrics: false,
        });
        setFilterMetricsOptions([]); // reset metrics
        setDatePickerConfig({}); // reset datepicker

        if (location.search.includes('vid')) {
            setLoading(true);
            const vid = queryString.parse(location.search).vid;
            setVisitorId(vid);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (visitorId) {
            fetchVisitor(visitorId);
        }
    }, [visitorId]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchVisitor = () => {
        axios({
            method: 'GET',
            url: generateUrl('report', 'scv-visitor', [{ key: 'id', value: visitorId }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length > 0) {
                    setVisitor(response.data.objects[0]);
                    setLoading(false);
                    setErrorMessage(null);
                    history(location.pathname + '?vid=' + visitorId);
                } else {
                    setErrorMessage('No matching Cubed Visitor ID found');
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage('No matching Cubed Visitor ID found');
                setLoading(false);
            });
    };

    const fetchVisitorFromInputId = isTransaction => {
        const resource = isTransaction ? 'scv-transaction' : 'scv-visitor-details';
        const resourceId = isTransaction ? formTransactionId : formCustomerId;

        setLoading(true);

        axios({
            method: 'GET',
            url: generateUrl('report', resource, [
                { key: isTransaction ? 'transaction_id' : 'client_customer_id', value: resourceId },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length < 1) {
                    setErrorMessage(`No matching ${isTransaction ? 'Transaction' : 'Customer'} ID found`);
                    setLoading(false);
                } else {
                    setVisitorId(response.data.objects[0].visitor_id);
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(`No matching ${isTransaction ? 'Transaction' : 'Customer'} ID found`);
                setLoading(false);
            });
    };

    const handleVisitorIdInputChange = event => {
        setFormTransactionId('');
        setFormCustomerId('');
        setFormVisitorId(event.target.value);
    };

    const handleTransactionIdInputChange = event => {
        setFormVisitorId('');
        setFormCustomerId('');
        setFormTransactionId(event.target.value);
    };

    const handleCustomerIdInputChange = event => {
        setFormVisitorId('');
        setFormTransactionId('');
        setFormCustomerId(event.target.value);
    };

    const handleSubmitVisitorId = () => {
        setVisitorId(formVisitorId);
        setLoading(true);
    };

    const handleSubmitTransactionId = () => {
        fetchVisitorFromInputId(true);
    };

    const handleSubmitCustomerId = () => {
        fetchVisitorFromInputId();
    };

    const handleAdvancedSearchClick = () => {
        history('user-behaviour/journeys/customer-explorer');
    };

    const handleBackClick = () => {
        setVisitorId(null);
        setVisitor(null);
        setFormVisitorId('');
        setFormTransactionId('');
        setFormCustomerId('');
        setErrorMessage(null);

        history(location.pathname);
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            if (formVisitorId.length > 0) {
                handleSubmitVisitorId();
            } else if (formTransactionId.length > 0) {
                handleSubmitTransactionId();
            } else {
                handleSubmitCustomerId();
            }
        }
    };

    if (loading) {
        return (
            <StyledLoadingContainer>
                <LoadingSpinner />
            </StyledLoadingContainer>
        );
    }

    if (visitor) {
        return <VisitorDetails visitor={visitor} onBackClick={handleBackClick} />;
    }

    return (
        <StyledFormContainer>
            <StyledTitle>Single Customer View</StyledTitle>
            <StyledP>
                You can use a Cubed Visitor ID, Transaction ID or Customer ID to search for a visitor, or use the
                advanced search below:
            </StyledP>
            <StyledFormSection>
                <StyledInput
                    type="text"
                    placeholder="Cubed Visitor ID"
                    value={formVisitorId}
                    onChange={handleVisitorIdInputChange}
                    onKeyDown={handleKeyDown}
                    data-testid="cubed-visitor-id-input"
                ></StyledInput>
                <StyledButton
                    onClick={handleSubmitVisitorId}
                    disabled={visitorButtonDisabled}
                    data-testid="cubed-visitor-id-button"
                >
                    Find Cubed Visitor
                </StyledButton>
            </StyledFormSection>
            <StyledFormSection>
                <StyledInput
                    type="text"
                    placeholder="Transaction ID"
                    value={formTransactionId}
                    onChange={handleTransactionIdInputChange}
                    onKeyDown={handleKeyDown}
                    data-testid="transaction-id-input"
                ></StyledInput>
                <StyledButton
                    onClick={handleSubmitTransactionId}
                    disabled={transactionButtonDisabled}
                    data-testid="transaction-id-button"
                >
                    Find Transaction ID
                </StyledButton>
            </StyledFormSection>
            <StyledFormSection>
                <StyledInput
                    type="text"
                    placeholder="Customer ID"
                    value={formCustomerId}
                    onChange={handleCustomerIdInputChange}
                    onKeyDown={handleKeyDown}
                    data-testid="customer-id-input"
                ></StyledInput>
                <StyledButton
                    onClick={handleSubmitCustomerId}
                    disabled={customerButtonDisabled}
                    data-testid="customer-id-button"
                >
                    Find Customer ID
                </StyledButton>
            </StyledFormSection>
            <StyledFormSection>
                <StyledButton onClick={handleAdvancedSearchClick}>Advanced Search</StyledButton>
            </StyledFormSection>
            {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        </StyledFormContainer>
    );
};

export default SingleCustomerFinder;
