import React from 'react';
import styled from 'styled-components';

// Styles
const StyledContributionAnalysisTableWrapper = styled.div`
    ${props => props.theme.boxShadow(1)}
    ${props => props.theme.scrollbar(8, 8)}
    background-color: ${props => props.theme.colours.white};
    position: relative;
    width: 100%;
    padding: 7px;
    margin: 5px;
    float: right;
    height: 78vh;
    overflow-y: auto;
`;

const StyledContributionAnalysisTable = styled.table`
    position: relative;
    width: 100%;
    transition: ${props => props.theme.transition};
`;

const StyledContributionAnalysisTableSkeleton = styled.table`
    width: 100%;
    margin: 40px 0;
`;

const StyledContributionAnalysisTableRowSkeleton = styled.tr`
    ${props => props.theme.skeletonLoaderGradient};
    width: 40%;
    border-radius: 2px;
    display: block;
    height: 12px;
    margin: 24px;
    &:nth-child(2n) {
        width: 60%;
    }
    &:nth-child(3n) {
        width: 80%;
    }
`;

const TableContributionSkeleton = () => {
    const tableSkeleton = (
        <StyledContributionAnalysisTableWrapper>
            <StyledContributionAnalysisTable>
                <div>
                    <StyledContributionAnalysisTableSkeleton>
                        <tbody>
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                            <StyledContributionAnalysisTableRowSkeleton />
                        </tbody>
                    </StyledContributionAnalysisTableSkeleton>
                </div>
            </StyledContributionAnalysisTable>
        </StyledContributionAnalysisTableWrapper>
    );

    return <>{tableSkeleton}</>;
};

export default TableContributionSkeleton;
