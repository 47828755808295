import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

// Types & ENUMS
import { ButtonThemes } from '../../enums/button-themes';
import { ConfigDataSuccess, ResourceDataSuccess } from '../../react-query/types';
import { NotificationMessageType } from '../../enums/notification-types';

// Resources
import { PATH_SUBDIRECTORY_DOMAIN } from '../../configurations/resources';
import { PATH_SUBDIRECTORY_CONFIG } from '../../configurations/resources-config';

// Redux & Hooks
import { useDispatch } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import usePatchResource from '../../react-query/hooks/use-patch-resource';

// Components
import DropdownWithSearch, { DropdownOptions } from '../../components/common/dropdown-with-search';
import SimpleTable from '../../components/tables/components/simple-table';
import TablePaginationPageList from '../../components/tables/components/pagination/table-pagination-page-list';
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import Message from '../../components/message';
import FormTextInput from '../../components/form-fields/form-text-input';
import FormCheckBoxInput from '../../components/form-fields/form-checkbox-input';
import InputButton from '../../components/inputs/input-button';
import { SimpleTableRow } from '../../components/tables/types';

const StyledPaginationContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const StyledDropdownContainer = styled.div`
    margin-bottom: 20px;
`;

const StyledEditContainer = styled.div`
    margin-top: 20px;
    background-color: white;
    padding: 1px 20px 20px 20px;
`;
const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
`;

type PageTypeTableRow = {
    id: string;
    subdirectory_name: string;
    display_name: string;
    homepage: boolean;
    daily_pageviews: number;
    dataValue?: string;
};

const PageLayout = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch();

    const modalNavigationButtons = [
        {
            value: 'CLOSE',
            onClick: () => dispatch(removeModal()),
            disabled: false,
            buttonTheme: ButtonThemes.Secondary,
        },
    ];

    return (
        <div>
            <ModalNavigation buttons={modalNavigationButtons} />
            <h2>Page Type - URL Subdirectories</h2>
            <p>Manage your Page Types by configuring your URL subdirectories into groups.</p>
            {children}
        </div>
    );
};

const LayoutModalManagePageTypesUrlSubdirectories = () => {
    const dispatch = useDispatch();

    // State - Dropdown & Table
    const maxPerPage = 25;
    const [selectedDomain, setSelectedDomain] = useState<DropdownOptions | undefined>();
    const [selectedTableRow, setSelectedTableRow] = useState<PageTypeTableRow | undefined>();
    const [page, setPage] = useState(1);

    // State - Edit Form Fields
    const [displayName, setDisplayName] = useState('');
    const [isHomepage, setIsHomepage] = useState(false);

    // Queries
    const subdirectoryConfigItems = useFetchResource<ConfigDataSuccess>({
        resource: PATH_SUBDIRECTORY_CONFIG,
        params: [
            { key: 'limit', value: maxPerPage },
            { key: 'offset', value: maxPerPage * (page - 1) },
            { key: 'domain', value: selectedDomain?.value || '' },
        ],
        isPaginated: true,
    });

    const domains = useFetchResource({
        resource: PATH_SUBDIRECTORY_DOMAIN,
        params: [
            { key: 'group_by', value: 'domain' },
            { key: 'order_by', value: 'domain' },
        ],
        select: (data: ResourceDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.values.domain.value,
                    value: object.values.domain.value,
                };
            });
        },
    });

    const pageTypeMutation = usePatchResource({
        resource: PATH_SUBDIRECTORY_CONFIG,
        resourceId: selectedTableRow?.id || '',
        data: { display_name: displayName, homepage: isHomepage },
        handleOnSuccess: () => {
            setSelectedTableRow(undefined);
            dispatch(
                addNotification({
                    copy: 'Page type updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your page type. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    // Loading
    if (subdirectoryConfigItems.status === 'pending' || domains.status === 'pending') {
        return (
            <PageLayout>
                <LoadingSpinner />
            </PageLayout>
        );
    }

    // Error
    if (subdirectoryConfigItems.status === 'error' || domains.status === 'error') {
        return (
            <PageLayout>
                <Message
                    copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email."
                    type="error"
                />
            </PageLayout>
        );
    }

    // Success
    if (subdirectoryConfigItems.status === 'success' && domains.status === 'success') {
        // Event Handlers
        const handleChangePage = (_: string, value: number) => {
            setSelectedTableRow(undefined);
            setPage(value);
        };

        const handleSelectDropdownItem = (item: DropdownOptions) => {
            setSelectedDomain(item);
            setSelectedTableRow(undefined);
            setPage(1);
        };

        const handleSelectTableRow = (_: React.MouseEvent<HTMLTableRowElement>, row: SimpleTableRow) => {
            const selectedItem = subdirectoryConfigItems.data.objects.find(item => item.id === row.dataValue);

            setSelectedTableRow(selectedItem as PageTypeTableRow);

            if (selectedItem) {
                setDisplayName(selectedItem.display_name as string);
                setIsHomepage(selectedItem.homepage as boolean);
            }
        };

        const handleEditDisplayName = (event: ChangeEvent<HTMLInputElement>) => {
            setDisplayName(event.target.value);
        };

        const handleEditHomepage = () => {
            setIsHomepage(!isHomepage);
        };

        const onSaveClick = () => {
            pageTypeMutation.mutate();
        };

        const onCancelClick = () => {
            setSelectedTableRow(undefined);
        };

        // Table Config
        const tableRows = subdirectoryConfigItems.data.objects.map(item => {
            const rowProperty = {
                selected: selectedTableRow?.id === item.id,
                disabled: false,
            };

            return {
                columns: [
                    {
                        copy: item.subdirectory_name as string,
                    },
                    {
                        copy: item.display_name as string,
                    },
                    {
                        copy: item.homepage ? ('True' as string) : ('False' as string),
                    },
                    {
                        copy: item.daily_pageviews as string,
                    },
                ],
                keyValue: `item__${item.subdirectory_name}`,
                key: `item__${item.subdirectory_name}`,
                dataValue: item.id as string,
                rowProperty,
                onClick: handleSelectTableRow,
            };
        });

        const tableHeader = {
            columns: [
                {
                    title: 'Subdirectory Name',
                },
                {
                    title: 'Display Name',
                },
                {
                    title: 'Homepage',
                },
                {
                    title: 'Daily Page Views',
                },
            ],
        };

        return (
            <PageLayout>
                <StyledDropdownContainer>
                    <DropdownWithSearch
                        dropdownItems={domains.data}
                        selected={selectedDomain}
                        placeholder="Filter by Domain"
                        onSelectedItemChange={handleSelectDropdownItem}
                        requiredField={false}
                        isAccordionForm={false}
                        isClearable={true}
                    />
                </StyledDropdownContainer>
                <SimpleTable
                    isLoading={subdirectoryConfigItems.isFetching || pageTypeMutation.isPending}
                    header={tableHeader}
                    rows={tableRows}
                    hasIcons={false}
                    selectDisabled={false}
                    isScrollable={true}
                    customHeight="300px"
                />
                <StyledPaginationContainer>
                    <TablePaginationPageList
                        ownerCallback={handleChangePage}
                        currentRowCount={maxPerPage}
                        totalResults={subdirectoryConfigItems.data.meta.total_count}
                        minPage={1}
                        maxPage={subdirectoryConfigItems.data.meta.total_count / maxPerPage}
                        currentPage={page}
                        maxButtons={17}
                        disabled={false}
                    />
                </StyledPaginationContainer>
                {selectedTableRow && (
                    <StyledEditContainer>
                        <h2>Edit Page Type</h2>

                        <FormTextInput
                            inputPlaceholder={displayName}
                            label="Display Name:"
                            inputValue={displayName}
                            inputOnChange={handleEditDisplayName}
                            disabled={pageTypeMutation.isPending}
                        />

                        <FormCheckBoxInput
                            inputKeyValue="homepage"
                            inputName="homepage"
                            label="Homepage"
                            checked={isHomepage}
                            inputOnChange={handleEditHomepage}
                            disabled={pageTypeMutation.isPending}
                        />
                        <StyledButtonContainer>
                            <InputButton value="SAVE" onClick={onSaveClick} isLoading={pageTypeMutation.isPending} />
                            <InputButton value="CANCEL" onClick={onCancelClick} buttonTheme={ButtonThemes.Secondary} />
                        </StyledButtonContainer>
                    </StyledEditContainer>
                )}
            </PageLayout>
        );
    }
};

export default LayoutModalManagePageTypesUrlSubdirectories;
