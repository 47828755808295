import React, { useEffect, useReducer } from 'react';
import { WidgetConfig } from '../../types';
import getResource from '../../helpers/get-resource';
import getField from '../../helpers/get-field';
import useResourceComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-comparison';
import useResourceBarGraphComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-bar-graph-comparison';
import WidgetBarGraphComparison from '../../../section-dashboard/widgets/widget-comparison/widget-bar-graph-comparison';
import CreateAWidgetPreviewMessage from '../../create-a-widget/components/create-a-widget-preview-message';

const WidgetPreviewBarGraphComparison = ({ config }: { config: WidgetConfig }) => {
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    // Get the request values from the widget
    const resource = getResource(config.resource);
    const groupByField = getField(config.group_by);
    const orderByField = getField(config.order_by);
    const orderByDirection = config.order_by_direction.direction;

    const resourceData = useResourceComparison({
        resource: groupByField && resource,
        request: {
            orderBy:
                typeof orderByField !== 'undefined'
                    ? [{ field: orderByField, orderByDirection: orderByDirection }]
                    : [],
            groupBy: groupByField,
            limit: 15,
            enabled: !!resource && !!groupByField && !!orderByField,
        },
    });

    const barGraphData = useResourceBarGraphComparison({
        category: groupByField,
        metric: orderByField,
        resourceData: resourceData,
    });

    // Force a re-render to trigger a graph redraw when the data changes
    useEffect(() => {
        forceUpdate();
    }, [resourceData.status]);

    if (config.group_by.length > 0 && config.order_by.length > 0) {
        return <WidgetBarGraphComparison title={config.widget.title} data={barGraphData} />;
    }

    return (
        <CreateAWidgetPreviewMessage message="Please select a dimension and metric to preview the bar graph widget" />
    );
};

export default WidgetPreviewBarGraphComparison;
