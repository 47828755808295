import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import IconCheck from '../../../../components/icons/check';
import styled from 'styled-components';

const StyledCheckbox = styled.div<{ checked: boolean }>`
    width: 18px;
    height: 18px;
    background-color: ${props =>
        props.checked
            ? props.theme.sectionDashboard.widget.menu.textColor
            : props.theme.sectionDashboard.widget.menu.separatorColor};
    border: 2px solid
        ${props =>
            props.checked
                ? props.theme.sectionDashboard.widget.menu.backgroundHover
                : props.theme.sectionDashboard.widget.menu.textColor};
    border-radius: 5px;
    display: flex;
    padding-top: 2px;
    justify-content: center;
    align-items: center;
`;

const StyledIconCheck = styled(IconCheck)<{ checked: boolean }>`
    height: 12px;
    width: auto;
    & path {
        fill: ${props =>
            props.checked
                ? props.theme.sectionDashboard.widget.menu.checkbox.iconBackgroundColorChecked
                : props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledCheckboxItem = styled(DropdownMenu.CheckboxItem)`
    display: flex;
    align-items: center;
    gap: 10px;
    outline: none;
    padding: 10px 20px;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
    cursor: pointer;

    &:hover,
    &[data-highlighted] {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
        color: ${props => props.theme.sectionDashboard.widget.menu.textColorHover};
    }

    &:hover ${StyledCheckbox}, :focus ${StyledCheckbox} {
        border-color: ${props => props.checked && props.theme.sectionDashboard.widget.menu.textColorHover};
        background-color: ${props => props.checked && props.theme.sectionDashboard.widget.menu.textColorHover};
    }

    &:hover,
    :focus ${StyledIconCheck} {
        & path {
            fill: ${props => props.theme.sectionDashboard.widget.menu.checkbox.iconColorHover};
        }
    }
`;

const StyledLabel = styled.label`
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.fontWeight};
    cursor: pointer;
`;

export type WidgetMenuCheckboxProps = {
    label: string;
    checked: boolean;
    onCheckedChange: () => void;
};

const WidgetMenuCheckbox = ({ label, checked, onCheckedChange }: WidgetMenuCheckboxProps) => {
    const handleSelect = (event: Event): void => {
        event.preventDefault();
        onCheckedChange();
    };

    return (
        <StyledCheckboxItem checked={checked} onSelect={handleSelect}>
            <StyledCheckbox checked={checked}>
                <DropdownMenu.ItemIndicator>
                    <StyledIconCheck checked={checked} />
                </DropdownMenu.ItemIndicator>
            </StyledCheckbox>
            <StyledLabel>{label}</StyledLabel>
        </StyledCheckboxItem>
    );
};

export default WidgetMenuCheckbox;
