import React, { Component } from 'react';

class ListBuilderListItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.selected !== undefined ? this.props.selected : false,
        };

        this.onItemClick = this.onItemClick.bind(this);
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDragStart(e) {
        e.dataTransfer.setData('ListDragging', e.target.getAttribute('data-value'));
    }

    onItemClick(e) {
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(e, this.props.selected);
        }
    }

    render() {
        return (
            <li
                className={`list-builder__list__item ${this.props.selected ? 'selected' : ''} `}
                draggable={this.props.draggable}
                key={this.props.id}
                data-value={this.props.value}
                onClick={this.onItemClick}
                onDragStart={this.onDragStart}
                onDrop={this.props.onDrop}
                onDragOver={this.onDragOver}
            >
                {this.props.name}
            </li>
        );
    }
}

export default ListBuilderListItem;
