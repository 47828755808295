import React from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';

const StyledError = styled.span`
    font-size: 0.8rem;
    color: ${props => props.theme.forms.errorMessage.textColor};
`;

type InputErrorMessageProps = {
    error?: FieldError;
};

const InputErrorMessage = ({ error }: InputErrorMessageProps) => {
    if (error) {
        return <StyledError>{error.message}</StyledError>;
    }
    return null;
};

export default InputErrorMessage;
