export const formatDimensionFilters = (dimensionFilters, resource) => {
    const formattedFilters = [];
    for (let filter of dimensionFilters) {
        let rawName = filter.field.rawName;

        // if we are to ignore referer__name lookup on this resource
        if (rawName === 'referer' && resource.ignoreRefererDimension) continue;

        let key = `${filter.field.rawName}${filter.field.lookupTerm ? '__' + filter.field.lookupTerm : ''}`;
        if (resource.dimensions.find(dimension => dimension.rawName === filter.field.rawName)) {
            // NOTE: URIEncode use to encode value
            let filterValue = filter.value;
            if (typeof filter.raw_value == 'boolean') filterValue = filter.raw_value;

            formattedFilters.push({ key: filter.fuzzy ? `${key}__icontains` : key, value: filterValue });
        }
    }

    return formattedFilters;
};
