import React, { memo } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';

// Images
import SearchImage from '../../assets/images/icons/search.png';

type CommonInputProps = {
    inputType: 'text' | 'number' | 'date' | 'time' | 'datetime-local';
    name: string;
    placeholder: string;
    value?: string | number;
    dataKey?: string;
    required?: boolean;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    onKeyPress?: () => {};
    autocomplete?: string;
    step?: string;
    min?: string;
    max?: string;
    showSearchIcon?: boolean;
};

const StyledInput = styled('input')<{ showSearchIcon?: boolean }>`
    font-family: inherit;
    width: 100%;
    height: 40px;
    border: none;
    padding: 0 5px;
    margin-bottom: 5px;
    border-radius: 2px;

    ::placeholder,
    ::-webkit-input-placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }
    :-ms-input-placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }

    background-color: ${props => (props.disabled ? props.theme.colours.lightGrey : props.theme.colours.inputColour)};
    border-bottom: ${props =>
        props.disabled ? '2px solid #8c8c8c' : `solid 2px ${props.theme.colours.offBlackLighter}`};
    color: ${props => props.disabled && '#8c8c8c'};
    cursor: ${props => props.disabled && 'not-allowed!important'};

    background-image: ${props => props.showSearchIcon && `url(${SearchImage})`};
    background-position: ${props => props.showSearchIcon && '12px'};
    background-repeat: ${props => props.showSearchIcon && 'no-repeat'};
    background-size: ${props => props.showSearchIcon && '18px'};
    max-width: ${props => props.showSearchIcon && 'none'};
    padding-left: ${props => props.showSearchIcon && '42px'};
`;

const CommonInput = ({
    inputType,
    name,
    placeholder,
    value,
    dataKey,
    required,
    checked,
    disabled,
    onChange,
    onKeyPress,
    autocomplete,
    step,
    min,
    max,
    showSearchIcon,
}: CommonInputProps): JSX.Element => {
    return (
        <StyledInput
            data-key={dataKey}
            data-required={required}
            type={inputType}
            name={name}
            placeholder={placeholder}
            value={value}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            onKeyPress={onKeyPress}
            autoComplete={autocomplete}
            step={step}
            min={min}
            max={max}
            showSearchIcon={showSearchIcon}
        />
    );
};

export default memo(CommonInput, isEqual);
