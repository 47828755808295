import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

// Types & ENUMS
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';

// Resources
import { PATH_CATEGORY_CONFIG } from '../../configurations/resources-config';

// Redux
import { useDispatch } from 'react-redux';
import { setModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';

// Components
import ModalNavigation from '../../components/modal-navigation';
import FormTextInput from '../../components/form-fields/form-text-input';
import FormCheckBoxInput from '../../components/form-fields/form-checkbox-input';
import InputButton from '../../components/inputs/input-button';

// React Query
import usePostResource from '../../react-query/hooks/use-post-resource';

const StyledAddContainer = styled.div`
    margin-top: 20px;
    background-color: white;
    padding: 1px 20px 20px 20px;
`;
const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
`;

const PageLayout = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch();

    const modalNavigationButtons = [
        {
            value: 'CLOSE',
            onClick: () => dispatch(setModal('ManagePageCategories', {})),
            disabled: false,
            buttonTheme: ButtonThemes.Secondary,
        },
    ];

    return (
        <div>
            <ModalNavigation buttons={modalNavigationButtons} />
            <h2>Add Page Categories</h2>
            <p>Add your Page Categories by configuring your urls into categories using regex rules.</p>
            {children}
        </div>
    );
};

const LayoutModalAddPageCategories = () => {
    const dispatch = useDispatch();

    // State - Edit Form Fields
    const [category, setCategory] = useState('');
    const [lookupInclude, setLookupInclude] = useState('');
    const [lookupExclude, setLookupExclude] = useState('');
    const [isRegex, setIsRegex] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        category: '',
        lookupInclude: '',
        lookupExclude: '',
    });

    const pageCategoryAddMutation = usePostResource({
        resource: PATH_CATEGORY_CONFIG,
        data: { category: category, lookup_include: lookupInclude, lookup_exclude: lookupExclude, is_regex: isRegex },
        handleOnSuccess: () => {
            dispatch(setModal('ManagePageCategories', {}));
            dispatch(
                addNotification({
                    copy: 'Page Category Added successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while adding your Page Category. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const handleAddCategory = (event: ChangeEvent<HTMLInputElement>) => {
        setErrorMessage({
            ...errorMessage,
            category: event.target.value === '' ? 'Category is required.' : '',
        });
        setCategory(event.target.value);
    };

    const handleAddLookupInclude = (event: ChangeEvent<HTMLInputElement>) => {
        setErrorMessage({
            ...errorMessage,
            lookupInclude: event.target.value === '' ? 'Lookup Include is required.' : '',
        });
        setLookupInclude(event.target.value);
    };

    const handleAddLookupExclude = (event: ChangeEvent<HTMLInputElement>) => {
        setErrorMessage({
            ...errorMessage,
            lookupExclude: event.target.value === '' ? 'Lookup Exclude is required.' : '',
        });
        setLookupExclude(event.target.value);
    };

    const handleAddIsRegex = () => {
        setIsRegex(!isRegex);
    };

    const handleOnSaveClick = () => {
        if (category === '' || lookupInclude === '' || lookupExclude === '') {
            setErrorMessage({
                ...errorMessage,
                category: category === '' ? 'Category is required.' : '',
                lookupInclude: lookupInclude === '' ? 'Lookup Include is required.' : '',
                lookupExclude: lookupExclude === '' ? 'Lookup Exclude is required.' : '',
            });

            return;
        }

        pageCategoryAddMutation.mutate();
    };

    return (
        <PageLayout>
            <StyledAddContainer>
                <h2>Add Page Category</h2>

                <FormTextInput
                    inputPlaceholder={category}
                    label="Category:"
                    inputValue={category}
                    inputOnChange={handleAddCategory}
                    disabled={pageCategoryAddMutation.isPending}
                    requiredField={true}
                    errorMessage={errorMessage.category}
                />

                <FormTextInput
                    inputPlaceholder={lookupInclude}
                    label="Lookup Include:"
                    inputValue={lookupInclude}
                    inputOnChange={handleAddLookupInclude}
                    disabled={pageCategoryAddMutation.isPending}
                    requiredField={true}
                    errorMessage={errorMessage.lookupInclude}
                />

                <FormTextInput
                    inputPlaceholder={lookupExclude}
                    label="Lookup Exclude:"
                    inputValue={lookupExclude}
                    inputOnChange={handleAddLookupExclude}
                    disabled={pageCategoryAddMutation.isPending}
                    requiredField={true}
                    errorMessage={errorMessage.lookupExclude}
                />

                <FormCheckBoxInput
                    inputKeyValue="isRegex"
                    inputName="isRegex"
                    label="Is Regex"
                    checked={isRegex}
                    inputOnChange={handleAddIsRegex}
                    disabled={pageCategoryAddMutation.isPending}
                />
                <StyledButtonContainer>
                    <InputButton
                        value="SAVE"
                        onClick={handleOnSaveClick}
                        isLoading={pageCategoryAddMutation.isPending}
                    />
                </StyledButtonContainer>
            </StyledAddContainer>
        </PageLayout>
    );
};

export default LayoutModalAddPageCategories;
