import React from 'react';
import styled from 'styled-components';
import { grid } from 'css-grid-template-parser';

// Hooks, ENUMNS, Types and Validators
import useGridLayout from '../hooks/base/use-grid-layout';
import { SectionDashboardConfig, SectionDashboardLayout, SectionDashboardSectionConfig } from '../types';
import { validateDashboardConfig } from '../validators/validate-dashboard-config';

// Components
import ValidationMessage from './validation-message';
import SectionDashboardSection from './sections/section-dashboard-section';
import SectionDashboardSectionAccordion from './sections/section-dashboard-section-accordion';

export type SectionDashboardProps = {
    config: SectionDashboardConfig;
};

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const SectionDashboard = ({ config }: SectionDashboardProps) => {
    let layout = useGridLayout();

    const getCurrentLayoutArea = (layout: SectionDashboardLayout) => {
        const createSection = (section: SectionDashboardSectionConfig, index: number) => {
            return {
                title: section.title,
                menu: section.menu,
                collapsed: section.collapsed,
                beforeSection: section.beforeSection,
                beforeGrid: section.beforeGrid,
                areas: grid(section.grid),
                sectionIndex: index,
            };
        };

        switch (layout) {
            case SectionDashboardLayout.XL:
                return config.layouts.xl.sections.map((section, index) => {
                    return createSection(section, index);
                });
            case SectionDashboardLayout.LG:
                return config.layouts.lg.sections.map((section, index) => {
                    return createSection(section, index);
                });
            case SectionDashboardLayout.MD:
                return config.layouts.md.sections.map((section, index) => {
                    return createSection(section, index);
                });
            case SectionDashboardLayout.SM:
                return config.layouts.sm.sections.map((section, index) => {
                    return createSection(section, index);
                });
            default:
                return config.layouts.xs.sections.map((section, index) => {
                    return createSection(section, index);
                });
        }
    };

    const sections = getCurrentLayoutArea(layout);

    // Get section titles for setting default open values in accordion
    const defaultValues: string[] = [];
    sections.forEach(section => {
        if (section.title && !section.collapsed) {
            defaultValues.push(`sd-section-${section.sectionIndex}`);
        }
    });

    if (process.env.NODE_ENV === 'development') {
        const components = config.components();
        const validationMessages = validateDashboardConfig(config, components);

        if (validationMessages.length > 0) {
            return <ValidationMessage messages={validationMessages} />;
        }
    }

    return (
        <StyledContainer>
            {sections.map(section => {
                if (section.title) {
                    return (
                        <React.Fragment key={`${section.title}-${section.sectionIndex}`}>
                            {section.beforeSection && section.beforeSection}
                            <SectionDashboardSectionAccordion
                                sectionIndex={section.sectionIndex}
                                title={section.title}
                                menu={section.menu}
                                defaultValues={defaultValues}
                            >
                                <>
                                    {section.beforeGrid && section.beforeGrid}
                                    <SectionDashboardSection
                                        sectionIndex={section.sectionIndex}
                                        config={config}
                                        layout={layout}
                                        areas={section.areas}
                                    />
                                </>
                            </SectionDashboardSectionAccordion>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment key={section.sectionIndex}>
                            {section.beforeGrid && section.beforeGrid}
                            <SectionDashboardSection
                                sectionIndex={section.sectionIndex}
                                config={config}
                                layout={layout}
                                areas={section.areas}
                            />
                        </React.Fragment>
                    );
                }
            })}
        </StyledContainer>
    );
};

export default SectionDashboard;
