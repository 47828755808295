import { v4 as uuidv4 } from 'uuid';

import { unknownErrorData } from '../../../helpers/errors';
import {
    ErrorData,
    PieSeriesData,
    PieTableComparisonData,
    TableComparisonTableRow,
    TotalComparisonData,
} from '../../../types';
import theme from '../../../../styled-components/theme.styled';
import { CubedField } from '../../../../types';
import { alternativeGraphColours, graphColours } from '../../../helpers/graph-colours';
import { calculateVariance } from '../../../helpers/variance-helpers';
import { pieChartSeriesValidator } from '../../../helpers/pie-chart-table-helpers';

type UseResourcePieChartTableLimitComparisonArgs = {
    resourceData: PieTableComparisonData;
    totalData?: TotalComparisonData;
    ratioField: CubedField;
    limit: number;
};

const useResourcePieChartTableLimitComparison = ({
    resourceData,
    totalData,
    ratioField,
    limit,
}: UseResourcePieChartTableLimitComparisonArgs): PieTableComparisonData => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        try {
            // remove any rows where the ratioField value/comparison value is 0
            const filteredRows: TableComparisonTableRow[] = [];

            resourceData.rows.forEach(row => {
                let returnRow = false;
                if (row.data) {
                    if (row.data?.values[ratioField.rawName]?.rawValue !== 0) {
                        returnRow = true;
                    }
                }
                if (row.comparisonData) {
                    if (row.comparisonData?.values[ratioField.rawName]?.rawValue !== 0) {
                        returnRow = true;
                    }
                }

                if (returnRow) {
                    filteredRows.push(row);
                }
            });

            if (resourceData.rows && filteredRows.length > limit) {
                if (!resourceData?.request?.fields) {
                    throw new Error('Expected fields in the request');
                }

                // Limit the rows and group the others into a single 'other' row
                const topRows = filteredRows.slice(0, limit);
                const otherRows = filteredRows.slice(limit);

                const otherRow = {
                    __id: uuidv4(),
                    colour: theme.colours.borderGrey,
                    data: {
                        values: {},
                    },
                    comparisonData: {
                        values: {},
                    },
                    variance: {
                        values: {},
                    },
                    dimension: {
                        values: {
                            [resourceData.request.fields[0].rawName]: {
                                rawValue: 'Others',
                                value: 'Others',
                            },
                        },
                    },
                } as TableComparisonTableRow;

                resourceData?.request?.fields.forEach(field => {
                    if (!field.isDimension) {
                        const value = otherRows
                            .map(row => {
                                if (row.data) {
                                    return +row?.data?.values[field.rawName]?.rawValue;
                                }
                                return 0;
                            })
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2);

                        const comparisonValue = otherRows
                            .map(row => {
                                if (row.comparisonData) {
                                    return +row?.comparisonData?.values[field.rawName]?.rawValue;
                                }
                                return 0;
                            })
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2);

                        otherRow.data!.values[field.rawName] = {
                            rawValue: value,
                            value: String(value),
                        };

                        otherRow.comparisonData!.values[field.rawName] = {
                            rawValue: comparisonValue,
                            value: String(comparisonValue),
                        };

                        const variance = calculateVariance(+value, +comparisonValue)?.toFixed(2);

                        otherRow.variance!.values.variance = {
                            rawValue: variance || 'N/A',
                            value: variance ? String(variance) : 'N/A',
                        };
                    }
                });

                // Set up the series and ratio
                const topSeries: PieSeriesData[] = [];
                const topSeriesComparison = [];

                const otherSeries = {
                    name: 'Others',
                    color: theme.colours.borderGrey,
                    value: 0,
                };

                const otherSeriesComparison = {
                    name: 'Others',
                    color: theme.colours.borderGrey,
                    value: 0,
                };

                const ratio = {
                    ratioLeft: {
                        colour: graphColours[0].solidGraphColour,
                        value: 0,
                    },
                    ratioRight: {
                        colour: theme.colours.borderGrey,
                        value: 0,
                    },
                };

                const comparisonRatio = {
                    ratioLeft: {
                        colour: graphColours[0].solidGraphColour,
                        value: 0,
                    },
                    ratioRight: {
                        colour: theme.colours.borderGrey,
                        value: 0,
                    },
                };

                if (resourceData?.series[0]?.data) {
                    const topSeriesData = resourceData?.series[0]?.data.slice(0, limit);
                    topSeries.push(...topSeriesData);

                    const otherSeriesData = resourceData?.series[0]?.data.slice(limit);
                    otherSeriesData.forEach(series => {
                        otherSeries.value += series.value;
                    });
                    otherSeries.value = parseFloat(otherSeries.value.toFixed(2));

                    if (totalData && totalData.status === 'success') {
                        const total = totalData.totals ? +totalData.totals?.values[ratioField.rawName]?.rawValue : 0;

                        ratio.ratioLeft.value = Math.round(
                            (topSeriesData.reduce((a, b) => a + +b.value, 0) / total) * 100
                        );
                        ratio.ratioRight.value = 100 - ratio.ratioLeft.value;
                    }
                }

                if (resourceData?.comparisonSeries[0]?.data) {
                    const topSeriesComparisonData = resourceData?.comparisonSeries[0]?.data.slice(0, limit);
                    topSeriesComparison.push(...topSeriesComparisonData);

                    topSeriesComparisonData.forEach((series, index) => {
                        const matchingData = topSeries.find(topSeries => topSeries.name === series.name);
                        const coloursToUse = resourceData.series.length > 0 ? alternativeGraphColours : graphColours;
                        const colour = coloursToUse[index % coloursToUse.length]?.solidGraphColour;
                        series.color = matchingData ? matchingData.color : colour;
                    });

                    const otherSeriesComparisonData = resourceData?.comparisonSeries[0]?.data.slice(limit);
                    otherSeriesComparisonData.forEach(series => {
                        otherSeriesComparison.value += series.value;
                    });
                    otherSeriesComparison.value = parseFloat(otherSeriesComparison.value.toFixed(2));

                    if (totalData?.status === 'success') {
                        const comparisonTotal = totalData.comparisonTotals
                            ? +totalData?.comparisonTotals?.values[ratioField.rawName]?.rawValue
                            : 0;

                        comparisonRatio.ratioLeft.value = Math.round(
                            (topSeriesComparisonData.reduce((a, b) => a + +b.value, 0) / comparisonTotal) * 100
                        );
                        comparisonRatio.ratioRight.value = 100 - comparisonRatio.ratioLeft.value;
                    }
                }

                const seriesData =
                    pieChartSeriesValidator(resourceData.series).length > 0
                        ? [
                              {
                                  ...resourceData.series[0],
                                  data: [...topSeries, otherSeries],
                              },
                          ]
                        : [];

                const comparisonSeriesData =
                    pieChartSeriesValidator(resourceData.comparisonSeries).length > 0
                        ? [
                              {
                                  ...resourceData.comparisonSeries[0],
                                  data: [...topSeriesComparison, otherSeriesComparison],
                              },
                          ]
                        : [];

                return {
                    ...resourceData,
                    rows: [...topRows, otherRow],
                    series: seriesData,
                    comparisonSeries: comparisonSeriesData,
                    ratio: totalData ? ratio : undefined,
                    comparisonRatio: totalData ? comparisonRatio : undefined,
                    limit: limit,
                };
            }

            return {
                ...resourceData,
                rows: filteredRows,
                series: pieChartSeriesValidator(resourceData.series),
                comparisonSeries: pieChartSeriesValidator(resourceData.comparisonSeries),
            };
        } catch (e) {
            return {
                type: 'pieChartTableComparison',
                status: 'error',
                request: resourceData.request,
            } as ErrorData;
        }
    }

    return unknownErrorData();
};

export default useResourcePieChartTableLimitComparison;
