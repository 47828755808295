export const SEGMENT_NAME = {
    rawName: 'name',
    displayName: 'Segment Name',
    defaultOrderDir: 'none',
    isDimension: false,
    sortBy: false,
    sort: 'none',
};

export const SEGMENT_DESCRIPTION = {
    rawName: 'description',
    displayName: 'Segment Description',
    defaultOrderDir: 'none',
    isDimension: false,
    sortBy: false,
    sort: 'none',
};

export const CONDITION_NAME = {
    rawName: 'name',
    displayName: 'Condition Name',
    defaultOrderDir: 'none',
    isDimension: false,
    sortBy: false,
    sort: 'none',
};

export const CONDITION_DESCRIPTION = {
    rawName: 'description',
    displayName: 'Condition Description',
    defaultOrderDir: 'none',
    isDimension: false,
    sortBy: false,
    sort: 'none',
};
