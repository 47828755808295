// React
import React, { useEffect, useRef, useState, ChangeEvent, KeyboardEvent } from 'react';

// Components
import InputTag from '../inputs/input-tag';
import IconTimes from '../icons/times';
import styled from 'styled-components';

const StyledFormFieldTags = styled.div<{ errors: boolean }>`
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: #e9f0f5;
    border-bottom: solid 2px ${props => (props.errors ? props.theme.colours.red : props.theme.colours.offBlackLighter)};
    width: 100%;
    overflow-x: hidden;
    cursor: text;
`;

const StyledTagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 38px);
    order: 1;
`;

const StyledInput = styled.input`
    font-family: inherit;
    margin: 3px;
    border: 0;
    background-color: ${props => props.theme.colours.inputColour};
    height: 30px;
    flex-grow: 2;
    flex-shrink: 1;
    min-width: 250px;

    &:focus {
        outline: none;
        border: 0;
    }
`;

const StyledIconContainer = styled.div`
    z-index: 5;
    align-self: center;
    color: ${props => props.theme.colours.offBlackLighter};
    order: 2;
    padding: 6px;
    width: 26px;
    & svg {
        height: 20px;
    }
`;

const StyledErrorMessage = styled.p`
    color: ${props => props.theme.colours.red};
`;

type Tag = {
    hasErrors?: boolean;
    isDuplicate?: boolean;
    value: string;
};

export type FormTagsProps = {
    tags: Tag[];
    onChange: (newTags: Tag[], fieldName: string) => void;
    fieldName: string;
    splitOnOverride?: (value: string) => void;
    errorMessage: string;
    errors: boolean;
    inputPlaceholder?: string;
    label?: string;
    copy?: string;
};

const FormTags = ({
    tags,
    onChange,
    fieldName,
    splitOnOverride,
    errorMessage,
    errors,
    inputPlaceholder,
    label,
    copy,
}: FormTagsProps) => {
    const [tagsList, setTagsList] = useState<Tag[]>([]);
    const [inputValue, setInputValue] = useState('');

    const textInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!arraysAreEqual(tags, tagsList)) {
            setTagsList(tags);
        }
    }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

    const onInputBlur = () => {
        if (inputValue) {
            const tags = [];
            tags.push({ value: inputValue, hasErrors: false, isDuplicate: false });
            const newTags = [...tagsList, ...tags];

            setTagsList(newTags);
            setInputValue('');

            // Call parent onChange and pass the most up to date tags.
            onChange(newTags, fieldName);
        }
    };

    const arraysAreEqual = (prevArray: Tag[], currentArray: Tag[]) => {
        if (prevArray.length !== currentArray.length) {
            return false;
        }

        prevArray.forEach((prev, index) => {
            if (prev.value !== currentArray[index].value) {
                return false;
            }
        });

        return true;
    };

    const onInputClick = () => {
        // Focus the main input when user clicks anywhere in the field to make it seem like a text area input
        textInput.current && textInput.current.focus();
    };

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        // Handle initial user input. Pasting/manual typing and hitting enter.

        setInputValue(event.target.value);

        if (event.target.value.length - inputValue.length > 1) {
            // Checks if input has been pasted or not
            const inputValue = event.target.value;

            const cleanedString = splitOn(inputValue); // Split on given character
            const tags: Tag[] = [];

            if (cleanedString) {
                cleanedString.forEach((value: string) => {
                    tags.push({ value: value, hasErrors: false, isDuplicate: false });
                });
            }

            const newTags = tagsList.concat(tags);

            setTagsList(newTags);
            setInputValue('');

            // Call parent onChange and pass the most up to date tags.
            onChange(newTags, fieldName);
        }
    };

    const splitOn = (value: string) => {
        // By default split on commas and semi-colons. Otherwise use props.splitOnOverride.
        if (splitOnOverride) {
            return splitOnOverride(value);
        }

        return value.split(/,|;/);
    };

    const deleteAll = () => {
        onChange([], fieldName);
        setTagsList([]);
    };

    const onInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        // Handle manually typing email address and pressing enter (does not handle editing tags)
        const tags = [...tagsList];
        if (event.key === 'Enter') {
            if (inputValue) {
                tags.push({ value: inputValue });

                onChange(tags, fieldName);

                setTagsList(tags);
                setInputValue('');
            }
        }
    };

    const onTagInputEdit = (index: string, value: string | null) => {
        // Handle editing individual tags
        const tags = [...tagsList];

        if (!value) {
            // Handle individual tag deleting
            tags.splice(+index, 1);
        } else {
            // Handle individual tag editing
            const tag = tags[+index];
            tags[+index] = { ...tag, value: value };
        }

        setTagsList(tags);

        // Call parent onChange and pass the most up to date tags
        onChange(tags, fieldName);
    };

    return (
        <div>
            <h3>{label}</h3>
            <p>{copy}</p>
            <StyledFormFieldTags onClick={onInputClick} errors={errors}>
                <StyledTagsContainer data-testid={'tags-container'}>
                    {tagsList.map((tag, index) => {
                        return (
                            <InputTag
                                dataKey={String(index)}
                                value={tag.value}
                                hasErrors={tag.hasErrors}
                                isDuplicate={tag.isDuplicate}
                                onChange={onTagInputEdit}
                            />
                        );
                    })}
                    <StyledInput
                        onBlur={onInputBlur}
                        ref={textInput}
                        className="tag-input"
                        onKeyDown={onInputKeyDown}
                        type="text"
                        placeholder={inputPlaceholder}
                        value={inputValue}
                        onChange={onInputChange}
                    />
                </StyledTagsContainer>
                <StyledIconContainer onClick={deleteAll}>
                    <IconTimes />
                </StyledIconContainer>
            </StyledFormFieldTags>
            {errors && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        </div>
    );
};

export default FormTags;
