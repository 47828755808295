import { Moment } from 'moment';
import { UseResourceDateRangeFixed } from '../types';

export const fixed = ({ startDate, endDate }: { startDate: Moment; endDate: Moment }): UseResourceDateRangeFixed => {
    return {
        type: 'fixed',
        startDate: startDate,
        endDate: endDate,
    };
};
