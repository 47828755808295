import React, { useContext, useEffect } from 'react';
import Form from '../../../../components/forms/form';
import { WidgetLibraryContext } from '../context/widget-library-context';
import { FieldValues, useFormContext } from 'react-hook-form';

const FormWidgetLibraryWidgetSearch = () => {
    const { widgets, handleFilterWidgets } = useContext(WidgetLibraryContext);
    const { handleSubmit, watch } = useFormContext();

    const onSubmit = (data: FieldValues) => {
        handleFilterWidgets(data.search);
    };

    // Handle form submit on dropdown change
    useEffect(() => {
        const subscription = watch(() => handleSubmit(onSubmit)());
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]); // eslint-disable-line react-hooks/exhaustive-deps

    const searchDisabled = !widgets;

    return (
        <Form.Body>
            <Form.Section>
                <Form.Field direction="row">
                    <Form.InputLabel htmlFor="search" label="Search" />
                    <Form.InputSearch name="search" disabled={searchDisabled} />
                </Form.Field>
            </Form.Section>
        </Form.Body>
    );
};

export default FormWidgetLibraryWidgetSearch;
