import { DefaultTheme } from 'styled-components';

export const histogramChartConfig = (theme: DefaultTheme) => {
    return {
        chart: {
            type: 'histogram',
            style: {
                fontFamily: 'Work Sans',
            },
            reflow: true,
            backgroundColor: 'transparent',
        },
        title: {
            text: null,
        },
        xAxis: [
            {
                title: {
                    text: null,
                },
                labels: {
                    style: {
                        whitespace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.3)' : '#666666',
                    },
                },
            },
            {
                title: {
                    text: null,
                },
                opposite: true,
                labels: {
                    style: {
                        whitespace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.3)' : '#666666',
                    },
                },
                lineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#e6e6e6',
            },
        ],
        yAxis: [
            {
                title: {
                    text: null,
                    style: {
                        color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.3)' : '#e6e6e6',
                        fill: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.3)' : '#e6e6e6',
                    },
                },
                labels: {
                    style: {
                        whitespace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.3)' : '#666666',
                    },
                },
                gridLineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#e6e6e6',
            },
            {
                title: {
                    text: null,
                },
                opposite: true,
                labels: {
                    style: {
                        whitespace: 'nowrap',
                        textOverflow: 'ellipsis',
                        color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.3)' : '#666666',
                    },
                },
                gridLineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#e6e6e6',
            },
        ],
        plotOptions: {
            series: {
                cropThreshold: 9e9,
            },
        },
        series: [
            {
                name: 'Histogram',
                type: 'histogram',
                xAxis: 1,
                yAxis: 1,
                baseSeries: 's1',
                zIndex: -1,
            },
            {
                name: 'Data',
                type: 'scatter',
                data: [],
                id: 's1',
                marker: {
                    radius: 3,
                    symbol: 'circle',
                    fillColor: null,
                    lineWidth: null,
                    lineColor: null,
                },
            },
        ],
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };
};
