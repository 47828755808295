import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import CommonInput from './common-input';

type MinMaxSelectProps = {
    label: string;
    inputType: 'text' | 'number' | 'date' | 'time' | 'datetime-local';
    minInputName: string;
    maxInputName: string;
    minPlaceHolder: string;
    maxPlaceHolder: string;
    handleInputOnChange: (event: React.FormEvent<HTMLInputElement>) => void;
    minDataKey: string;
    maxDataKey: string;
    minInputValue: string;
    maxInputValue: string;
    inputError: string;
};

// Styles
const StyledMinMaxContainer = styled.div`
    display: inline-block;
`;

const StyledLabel = styled.label`
    display: block;
    font-size: 1.1em;
    font-weight: 400;
    margin: 0 6px 6px 0;
`;

const StyledInputWrapper = styled.div`
    display: inline-block;
    margin-right: 6px;
    max-width: 100px;
`;

const StyledErrorMessage = styled.p`
    color: #d2004a;
    font-size: 0.9rem;
`;

const MinMaxSelect = ({
    label,
    inputType,
    minInputName,
    maxInputName,
    minPlaceHolder,
    maxPlaceHolder,
    handleInputOnChange,
    minDataKey,
    maxDataKey,
    minInputValue,
    maxInputValue,
    inputError,
}: MinMaxSelectProps) => {
    return (
        <StyledMinMaxContainer>
            <StyledLabel>{label}</StyledLabel>
            <StyledInputWrapper>
                <CommonInput
                    inputType={inputType}
                    name={minInputName}
                    placeholder={minPlaceHolder}
                    onChange={handleInputOnChange}
                    dataKey={minDataKey}
                    value={minInputValue}
                />
            </StyledInputWrapper>
            <StyledInputWrapper>
                <CommonInput
                    inputType={inputType}
                    name={maxInputName}
                    placeholder={maxPlaceHolder}
                    onChange={handleInputOnChange}
                    dataKey={maxDataKey}
                    value={maxInputValue}
                />
            </StyledInputWrapper>
            {inputError !== '' && <StyledErrorMessage>{inputError}</StyledErrorMessage>}
        </StyledMinMaxContainer>
    );
};

export default memo(MinMaxSelect, isEqual);
