import { useEffect, useState } from 'react';

import { DragAndDropTableStatus } from '../enums/drag-and-drop-table-status';

type UseTableStatusArgs = {
    tableLoading: boolean;
    changesSaving: boolean;
    saveError: boolean;
    noData: boolean;
};

type TableStatus = number | null;

const useTableStatus = ({ tableLoading, changesSaving, saveError, noData }: UseTableStatusArgs): TableStatus => {
    const [tableStatus, setTableStatus] = useState<TableStatus>(null);

    // table message
    useEffect(() => {
        if (tableLoading) {
            setTableStatus(DragAndDropTableStatus.Loading);
        } else if (changesSaving) {
            setTableStatus(DragAndDropTableStatus.Saving);
        } else if (saveError) {
            setTableStatus(DragAndDropTableStatus.SaveError);
        } else if (noData) {
            setTableStatus(DragAndDropTableStatus.NoData);
        } else {
            setTableStatus(null);
        }
    }, [tableLoading, changesSaving, saveError, noData]);

    return tableStatus;
};

export default useTableStatus;
