import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { StyledDashboardView } from './dashboard';

// Required Components
import List from '../components/list';
import SidebarMenu from '../components/sidebar-menu';
import Nav from './nav';

// Required Widgets
import SearchBreadcrumbsWidget from '../breadcrumbs/widgets/search-breadcrumbs';

// Required Views
import ViewMenu from './menu';
import ViewModal from './modal';
import ViewNotification from './notifications';
import ViewPopup from './popup';

// Redux Actions
import { setModal } from '../redux/actions/modal';
import { setDescription, setTitle } from '../redux/actions/page-meta';

class ViewAdminConfiguration extends Component {
    constructor(props) {
        super(props);

        this.searchBarOnChange = this.searchBarOnChange.bind(this);
        this.listComponentOnClickHandler = this.listComponentOnClickHandler.bind(this);

        const adminConfigurations = [
            {
                header: {
                    type: 'Title',
                    title: 'Account',
                },
                type: 'List',
                key: 'api',
                rows: [
                    {
                        key: 'request_account_setup',
                        keyValue: 'request_account_setup',
                        type: 'ListRowTitleCopy',
                        title: 'Request Account Setup',
                        copy: 'Request Account Setup',
                        chevron: true,
                        onClick: this.listComponentOnClickHandler,
                    },
                ],
            },
        ];

        this.state = {
            searchValue: '',
            visibleAdminConfigurations: adminConfigurations,
            allAdminConfigurations: adminConfigurations,
            showSidebar: false,
        };
    }

    toggleSidebar = () => {
        this.setState({ showSidebar: !this.state.showSidebar });
    };

    searchBarOnChange(event) {
        const searchValue = event.target.value;
        const searchValueRegex = new RegExp(searchValue, 'i');
        const visibleConfigsAfterSearch = [];

        this.state.allAdminConfigurations.forEach(item => {
            const rowsWithSearchTerm = item.rows
                .filter(row => {
                    return searchValueRegex.test(row.title) || searchValueRegex.test(row.copy);
                })
                .map(row => {
                    return row;
                });

            if (rowsWithSearchTerm.length > 0 || item.header.title.includes(event.target.value)) {
                visibleConfigsAfterSearch.push({
                    header: item.header,
                    type: item.type,
                    key: item.key,
                    rows: rowsWithSearchTerm,
                });
            }
        });

        this.setState({
            searchValue: searchValue,
            visibleAdminConfigurations: visibleConfigsAfterSearch,
        });
    }

    listComponentOnClickHandler(event) {
        switch (event.currentTarget.getAttribute('data-key')) {
            case 'request_account_setup':
                this.props.setModal('AccountSetupRequest', {});
                break;
            default:
                break;
        }
    }

    componentDidMount() {
        this.props.setTitle('Admin');
        this.props.setDescription('');
    }

    render() {
        if (!this.props.accountId) {
            return <Navigate to="/account-selection" />;
        }

        if (!this.props.user.isLoggedIn) {
            return <Navigate to="/" />;
        }

        return (
            <div id="view-admin">
                <Nav showSidebar={this.state.showSidebar} toggleSidebar={this.toggleSidebar} />

                <StyledDashboardView showSidebar={this.state.showSidebar}>
                    <ViewMenu sidebarExpanded={this.state.showSidebar} history={this.props.history} />
                    <div className="admin-configuration row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                            <div className="account-configuration__side-bar">
                                <SearchBreadcrumbsWidget
                                    onSearchChange={this.searchBarOnChange}
                                    homeOnClick={this.breadCrumbHomeOnClickHandler}
                                    searchValue={this.state.searchValue}
                                />
                                <SidebarMenu
                                    rows={this.state.visibleAdminConfigurations}
                                    onClick={this.sideBarMenuOnClickHandler}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8 col-xl-9">
                            <List
                                rows={this.state.visibleAdminConfigurations}
                                header={{}}
                                errorMessageOverride="The search term you have entered does not match with any of the User Configurations."
                            />
                        </div>
                    </div>
                    <ViewModal />
                    <ViewNotification />
                    <ViewPopup />
                </StyledDashboardView>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        accountId: state.account.id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setTitle: title => {
            dispatch(setTitle(title));
        },
        setDescription: description => {
            dispatch(setDescription(description));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAdminConfiguration);
