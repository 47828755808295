export function setModal(modalType, modalConfig) {
    return {
        type: 'SET_MODAL',
        payload: {
            type: modalType,
            config: modalConfig,
        },
    };
}

export function removeModal() {
    return {
        type: 'DEL_MODAL',
    };
}

export function updateModalConfig(config) {
    return {
        type: 'UPDATE_MODAL_CONFIG',
        payload: {
            config: config,
        },
    };
}
