import React, { createContext, useEffect, useState } from 'react';

// Fields
import { PATH_SUBDIRECTORY, PRODUCT } from '../../configurations/fields';

// Hooks
import useWidgetMenuSectionQuerySelect from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-query-select';
import useWidgetMenuSection from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section';
import useWidgetMenuSectionCheckbox from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-checkbox';

// Types
import { DropdownOption, FilterOperator, SelectOptionsRequest, WidgetMenuObj } from '../../section-dashboard/types';
import { CubedField } from '../../types';

// Config
import { pageTypeDropdownRequest } from '../../section-dashboard/configurations/page-type-dropdown-options';
import { filterUnknownRegex } from '../../section-dashboard/helpers/get-resource-filters';

// Queries
import { goalsDropdownQuery } from '../../section-dashboard/queries';

export interface ContentInsightsDashboardMenuContextValues {
    pageTypeDropdownOptions: SelectOptionsRequest;
    selectedPageType: DropdownOption;
    handlePageTypeChange: (option: string) => void;
    currentPageTypeField: CubedField;
    contentOverviewTrafficSectionMenu: WidgetMenuObj;
    contentOverviewTrafficByPageTypeSectionMenu: WidgetMenuObj;
    homepageRelianceGoalsMenu: WidgetMenuObj;
    contentOverviewConversionMixSectionMenu: WidgetMenuObj;
    contentOverviewConversionMixByGoalCompletionsSectionMenu: WidgetMenuObj;
    contentOverViewConversionsByPageTypeSectionMenu: WidgetMenuObj;
    topPerformingPagesSectionMenu: WidgetMenuObj;
    goalCompletionsGoalSummarySectionMenu: WidgetMenuObj;
    pageTypePerformanceSummaryByGoalSectionMenu: WidgetMenuObj;
    pageTypePerformanceOverTimeTrafficSectionMenu: WidgetMenuObj;
    pageTypePerformanceOverTimeConversionsSectionMenu: WidgetMenuObj;
}

type ContentInsightsDashboardMenuContextProps = {
    children: JSX.Element;
};

export const ContentInsightsDashboardMenuContext = createContext<ContentInsightsDashboardMenuContextValues>(
    {} as ContentInsightsDashboardMenuContextValues
);

const ContentInsightsDashboardMenuProvider = ({ children }: ContentInsightsDashboardMenuContextProps) => {
    // Page Type Dropdown
    const pageTypeDropdownOptions = pageTypeDropdownRequest;
    const [selectedPageType, setSelectedPageType] = useState(pageTypeDropdownOptions.options[0]);
    const [currentPageTypeField, setCurrentPageTypeField] = useState(PATH_SUBDIRECTORY);

    const handlePageTypeChange = (selectedOption: string) => {
        setSelectedPageType(pageTypeDropdownOptions.options.filter(option => option.value === selectedOption)[0]);
    };

    useEffect(() => {
        setCurrentPageTypeField(selectedPageType.field);
    }, [selectedPageType]);

    const filterNoneConfig = {
        label: 'Exclude Unknown',
        filterField: currentPageTypeField,
        filterOperator: FilterOperator.IREGEX,
        filterValue: filterUnknownRegex,
        defaultChecked: false,
    };

    const filterNoJSBrowsersConfig = {
        label: 'Exclude No-JS Browsers',
        filterField: currentPageTypeField,
        filterOperator: FilterOperator.IDOES_NOT_CONTAIN,
        filterValue: 'render|gtm-msr.appspot.com',
        defaultChecked: false,
    };

    // Content Overview - Page Type Traffic Mix (by pageviews) - Section Menu
    const contentOverviewTrafficSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionCheckbox(filterNoneConfig),
                    useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig),
                ],
            },
        ],
    });

    // Content Overview - Traffic by Page Type - Section Menu
    const contentOverviewTrafficByPageTypeSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionCheckbox(filterNoneConfig),
                    useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig),
                ],
            },
        ],
    });

    // Homepage Reliance Goals Dropdown
    const homepageRelianceGoalsMenu = useWidgetMenuSectionQuerySelect({
        query: goalsDropdownQuery,
        dropdownField: PRODUCT,
        includeAllOption: true,
        responsive: false,
    });

    // Content Overview - Page Type Conversion Mix (by value) - Section Menu
    const contentOverviewConversionMixSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionCheckbox(filterNoneConfig),
                    useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig),
                ],
            },
        ],
    });

    // Content Overview - Page Type Conversion Mix (by goal completions) - Section Menu
    const contentOverviewConversionMixByGoalCompletionsSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionCheckbox(filterNoneConfig),
                    useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig),
                ],
            },
        ],
    });

    // Content Overview - Conversions By Page Type - Section Menu
    const contentOverViewConversionsByPageTypeSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionQuerySelect({
                        query: goalsDropdownQuery,
                        dropdownField: PRODUCT,
                        includeAllOption: true,
                    }),
                    useWidgetMenuSectionCheckbox(filterNoneConfig),
                    useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig),
                ],
            },
        ],
    });

    // Top Performing Pages - Section Menu
    const topPerformingPagesSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionCheckbox(filterNoneConfig),
                    useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig),
                ],
            },
        ],
    });

    // Goal Completions - Goal Summary by Page Type - Section Menu
    const goalCompletionsGoalSummarySectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionCheckbox(filterNoneConfig),
                    useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig),
                ],
            },
        ],
    });

    // Page Type Performance - Page Type Summary By Goal - Section Menu
    const pageTypePerformanceSummaryByGoalSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionCheckbox(filterNoneConfig),
                    useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig),
                ],
            },
        ],
    });

    // Page Type Performance Over Time - Traffic - Section Menu
    const pageTypePerformanceOverTimeTrafficSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig)],
            },
        ],
    });

    // Page Type Performance Over Time - Conversions - Section Menu
    const pageTypePerformanceOverTimeConversionsSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [useWidgetMenuSectionCheckbox(filterNoJSBrowsersConfig)],
            },
        ],
    });

    return (
        <ContentInsightsDashboardMenuContext.Provider
            value={{
                // Page Type Dropdown
                pageTypeDropdownOptions,
                selectedPageType,
                handlePageTypeChange,
                currentPageTypeField,
                // Content Overview - Page Type Traffic Mix (by pageviews) - Section Menu
                contentOverviewTrafficSectionMenu,
                // Content Overview - Traffic by Page Type - Section Menu
                contentOverviewTrafficByPageTypeSectionMenu,
                // Homepage Reliance Goals Dropdown
                homepageRelianceGoalsMenu,
                // Content Overview - Page Type Conversion Mix (by value) - Section Menu
                contentOverviewConversionMixSectionMenu,
                // Content Overview - Page Type Conversion Mix (by goal completions) - Section Menu
                contentOverviewConversionMixByGoalCompletionsSectionMenu,
                // Content Overview - Conversions By Page Type - Goals Dropdown
                contentOverViewConversionsByPageTypeSectionMenu,
                // Top Performing Pages - Section Menu
                topPerformingPagesSectionMenu,
                // Goal Completions - Goal Summary by Page Type - Section Menu
                goalCompletionsGoalSummarySectionMenu,
                // Page Type Performance - Page Type Summary By Goal - Section Menu
                pageTypePerformanceSummaryByGoalSectionMenu,
                // Page Type Performance Over Time - Traffic - Section Menu
                pageTypePerformanceOverTimeTrafficSectionMenu,
                // Page Type Performance Over Time - Conversions - Section Menu
                pageTypePerformanceOverTimeConversionsSectionMenu,
            }}
        >
            {children}
        </ContentInsightsDashboardMenuContext.Provider>
    );
};

export default ContentInsightsDashboardMenuProvider;
