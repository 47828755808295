/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

// Configurations
import {
    LAST_VISIT_VIEWS_DATETIME,
    CREATED_DATETIME,
    IMPRESSIONS_DATE_DATETIME,
    TAG_ERROR_DATE_TIME_DATETIME,
} from '../../configurations/fields';
import { ChartDateGranularity } from '../../configurations/common/chart-types';
import { PAGE_CONFIGURATION } from '../../configurations/health-dashboard/page-config';

// Helpers & Redux Actions
import Request from '../../helpers/request';
import { setDescription, setTitle } from '../../redux/actions/page-meta';
import {
    ACTIONS,
    ReducerActions,
    ActionFilterReset,
    ActionFilterCategoryChange,
    ActionLiveDataEnable,
    ActionLiveDataDatePickerSelect,
    ActionLiveDataDatePickerApply,
    ActionFilterNameChange,
    ActionFilterSubmit,
} from '../../helpers/health-dashboard/actions';
import { tableFilterReducer, tableLiveDataReducer } from '../../helpers/health-dashboard/reducer';
import {
    generateSparkParams,
    generateTableParams,
    generateTablePaginations,
} from '../../helpers/health-dashboard/utils';
import { selectionRange } from '../../helpers/date-picker';
import {
    createArrayOfDates,
    createEventSeries,
    createViewSeries,
    createImpressionSeries,
    createTagSeries,
} from '../../helpers/utils';

// Hooks
import UseComponentVisible from '../../hooks/use-component-visible';

// Types
import {
    HealthDashboardProviderProps,
    Tab,
    FetchTableDataProps,
    FormatSparklineDataProps,
    Selection,
    TableLiveDataStateObjectProps,
    FilterObject,
    Pagination,
    HealthDashboardContextValues,
    DatasType,
    DateRange,
    TableTabType,
    SparkValues,
} from './types';
import { TableFields } from '../../types';

export const HealthDashboardContext = createContext<HealthDashboardContextValues | undefined>(undefined);

type FetchSparkLineData = {
    tableLiveDataStateObject: TableLiveDataStateObjectProps;
    shouldSparkLoading: boolean;
};

const HealthDashboardProvider = ({ children }: HealthDashboardProviderProps) => {
    const DEFAULT_RESPONSE = {
        meta: {},
        objects: [],
    };
    const { SPARK_ITEMS, PAGE_TITLE, RESOURCES, TABLE, TABLE_API_KEYS, PAGINATIONS } = PAGE_CONFIGURATION;

    const TODAY = moment.utc().toDate();
    const TABLE_PROMISES: Array<object> = [];
    const VISIT_PROMISES: [] = [];
    const EVENT_PROMISES: [] = [];
    const IMPRESSION_PROMISES: Array<object> = [];
    const TAG_VALIDATION_PROMISES: [] = [];

    const DEFAULT_FILTER_CATEGORY_VALUE = '';
    const DEFAULT_FILTER_CATEGORY_TEXT = 'Select an option';

    const FILTER_OBJECT: FilterObject = {
        tempSearchCategory: '',
        tempSearchTerm: '',
        isFilterSubmited: false,
        searchCategory: '',
        searchTerm: '',
    };

    const DATE_OBJECT = {
        isLive: true,
        dateRange: {
            startDate: moment.utc().toDate(),
            endDate: moment.utc().toDate(),
        },
        tempDateRange: {
            startDate: moment.utc().toDate(),
            endDate: moment.utc().toDate(),
        },
    };

    const reduxDispatch = useDispatch();

    // Date Picker states
    const [selectionDatePickerRange, setSelectionDatePickerRange] = useState<DateRange>(selectionRange());
    const [isSingleDay, setIsSingleDay] = useState<boolean>(true);
    const [isDatePickerApplied, setIsDatePickerApplied] = useState<boolean>(false);

    const {
        ref: dateRangePickerRef,
        isComponentVisible: expendDatePicker,
        setIsComponentVisible: setExpendDatePicker,
    } = UseComponentVisible(false);

    // Table States
    const [tableData, setTableData] = useState<SparkValues>(DEFAULT_RESPONSE);
    const [tableNoData, setTableNoData] = useState(true);
    const [tableLoading, setTableLoading] = useState<boolean>(true);
    const [selectedTabId, setSelectedTabId] = useState<number>(0);
    const [tablePaginations, setTablePaginations] = useState<Pagination>(PAGINATIONS);
    const [dimensionFilters, setDimensionFilters] = useState<[]>([]);
    const [tableTabConfig, setTableTabConfig] = useState<Tab[]>(TABLE.tabListItems);
    const [tableMetrics, setTableMetrics] = useState(TABLE.tabListItems[0].metrics);
    const [tableDimensions, setTableDimensions] = useState(RESOURCES[0].dimensions);
    const [orderBy, setOrderBy] = useState(RESOURCES[0].defaultOrderBy);
    const [orderByDir, setOrderByDir] = useState(RESOURCES[0].defaultOrderBy.defaultOrderDir);

    const [tableResources, setTableResources] = useState(RESOURCES[0]);
    const [tableFilterState, tableFilterDispatch] = useReducer<(state: typeof ACTIONS, action: ReducerActions) => any>(
        tableFilterReducer,
        FILTER_OBJECT
    );
    const [tableLiveDataState, tableLiveDataDispatch] = useReducer<
        (state: typeof ACTIONS, action: ReducerActions) => any
    >(tableLiveDataReducer, DATE_OBJECT);

    // Sparkline States
    const [visitsSparkData, setVisitsSparkData] = useState<SparkValues>(DEFAULT_RESPONSE);
    const [eventsSparkData, setEventsSparkData] = useState<SparkValues>(DEFAULT_RESPONSE);
    const [impressionsSparkData, setImpressionsSparkData] = useState<SparkValues>(DEFAULT_RESPONSE);
    const [tagValidationSparkData, setTagValidationSparkData] = useState<SparkValues>(DEFAULT_RESPONSE);
    const [visitsSparkDataLoading, setVisitsSparkDataLoading] = useState<boolean>(true);
    const [eventsSparkDataLoading, setEventsSparkDataLoading] = useState<boolean>(true);
    const [impressionsSparkDataLoading, setImpressionsSparkDataLoading] = useState<boolean>(true);
    const [tagValidationSparkDataLoading, setTagValidationSparkDataLoading] = useState<boolean>(true);
    const [chartDateGranularity, setChartDateGranularity] = useState<string>(ChartDateGranularity.Minute);
    const [sparkLineSelectedDateRange, setSparkLineSelectedDateRange] = useState<DateRange>(selectionRange());

    const HealthDashboardSparkLines = [
        {
            // Views Sparkline
            setSparkDataLoading: setVisitsSparkDataLoading,
            setSparkData: setVisitsSparkData,
            createSeries: createViewSeries,
            sparkLinePromise: VISIT_PROMISES,
            sparkItems: SPARK_ITEMS[0],
            dimension: LAST_VISIT_VIEWS_DATETIME,
            metric: 'views_sum',
        },
        {
            // Events Sparkline
            setSparkDataLoading: setEventsSparkDataLoading,
            setSparkData: setEventsSparkData,
            createSeries: createEventSeries,
            sparkLinePromise: EVENT_PROMISES,
            sparkItems: SPARK_ITEMS[1],
            dimension: CREATED_DATETIME,
            metric: 'event_counts',
        },
        {
            // Impressions Sparkline
            setSparkDataLoading: setImpressionsSparkDataLoading,
            setSparkData: setImpressionsSparkData,
            createSeries: createImpressionSeries,
            sparkLinePromise: IMPRESSION_PROMISES,
            sparkItems: SPARK_ITEMS[2],
            dimension: IMPRESSIONS_DATE_DATETIME,
            metric: 'impressions_count',
        },
        {
            // Tag Validation Sparkline
            setSparkDataLoading: setTagValidationSparkDataLoading,
            setSparkData: setTagValidationSparkData,
            createSeries: createTagSeries,
            sparkLinePromise: TAG_VALIDATION_PROMISES,
            sparkItems: SPARK_ITEMS[3],
            dimension: TAG_ERROR_DATE_TIME_DATETIME,
            metric: 'tag_error_count',
        },
    ];

    useEffect(() => {
        // Set the first tab as the default selected tab
        handleTabElementClick(0);
    }, []);

    useEffect(() => {
        const endDate = moment.utc(selectionDatePickerRange.endDate);
        const startDate = moment.utc(selectionDatePickerRange.startDate);
        setIsSingleDay(endDate.diff(startDate, 'days', true) < 1);
    }, [selectionDatePickerRange]);

    const fetchTableData = useCallback(
        async ({
            tabId,
            orderBy,
            orderByDir,
            tablePaginations,
            tableFilterStateObject,
            tableLiveDataStateObject,
            shouldTableLoading,
            shouldTableDataReset,
        }: FetchTableDataProps) => {
            const params = generateTableParams(
                tabId,
                orderBy,
                orderByDir,
                tablePaginations,
                tableFilterStateObject,
                tableLiveDataStateObject,
                RESOURCES,
                TABLE_API_KEYS,
                dimensionFilters,
                selectedTabId
            );

            setTableLoading(shouldTableLoading);

            if (shouldTableDataReset) {
                setTableData(DEFAULT_RESPONSE);
                setTableNoData(true);
            }

            const fetchPromise = new Request();
            // TABLE_PROMISES.forEach(promise => promise.cancelRequest('aborted'));
            TABLE_PROMISES.push(fetchPromise);
            fetchPromise
                .get(RESOURCES[tabId].category, RESOURCES[tabId].id, params)
                .then(response => {
                    setTableLoading(false);
                    setTableData(response.data);
                    setTableNoData(response.data.objects && response.data.objects.length === 0);
                    setTablePaginations({
                        ...tablePaginations,
                        totalResults: response.data.meta ? parseInt(response.data.meta.total_count, 10) : 0,
                    });
                })
                .catch(error => {
                    if (error.message !== 'aborted') {
                        console.error(error.message);
                        setTableData(DEFAULT_RESPONSE);
                        setTableNoData(true);
                    }
                });
        },
        [
            DEFAULT_RESPONSE,
            RESOURCES,
            TABLE_API_KEYS,
            TABLE_PROMISES,
            dimensionFilters,
            selectedTabId,
            tableResources,
            tableTabConfig,
        ]
    );

    const formatSparklineData = ({
        dataPoints = [],
        datas,
        dimension,
        metric,
        graphGranularity,
    }: FormatSparklineDataProps) => {
        const series = [];
        if (moment.isMoment(dataPoints[0])) {
            for (const date of dataPoints) {
                let matchFound = false;
                let validDate = true;
                const formattedDate = moment.utc(date);

                if (formattedDate <= moment.utc()) {
                    for (const data of datas) {
                        const newData: DatasType = data;
                        if (graphGranularity === ChartDateGranularity.Minute) {
                            if (formattedDate.isSame(newData[dimension].raw_value, 'minute')) {
                                matchFound = true;
                                series.push(data);
                                break;
                            }
                        } else {
                            if (graphGranularity === ChartDateGranularity.Hour) {
                                if (formattedDate.isSame(newData[dimension].raw_value, 'hour')) {
                                    matchFound = true;
                                    series.push(data);
                                    break;
                                }
                            } else {
                                if (formattedDate.isSame(newData[dimension].raw_value, 'day')) {
                                    matchFound = true;
                                    series.push(data);
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    validDate = false;
                }
                if (!matchFound && validDate) {
                    series.push({
                        [dimension]: {
                            raw_value: formattedDate,
                            value: formattedDate,
                        },
                        [metric]: { raw_value: 0, value: '0' },
                    });
                }
            }
        }
        return series;
    };

    const fetchSparkLineData = useCallback(
        async ({ tableLiveDataStateObject, shouldSparkLoading }: FetchSparkLineData) => {
            HealthDashboardSparkLines.forEach(sparklineChart => {
                if (tableLiveDataStateObject.isLive) {
                    sparklineChart.sparkItems.periodName = 'minute';
                } else if (isSingleDay) {
                    sparklineChart.sparkItems.periodName = 'hour';
                } else {
                    sparklineChart.sparkItems.periodName = 'day';
                }

                setChartDateGranularity(sparklineChart.sparkItems.periodName);
                const params = generateSparkParams(
                    tableLiveDataStateObject,
                    sparklineChart.sparkItems.dateDimension,
                    sparklineChart.dimension.rawName,
                    sparklineChart.sparkItems.periodName
                );
                sparklineChart.setSparkDataLoading(shouldSparkLoading);
                sparklineChart.setSparkData(DEFAULT_RESPONSE);

                const promise = new Request();
                sparklineChart.sparkLinePromise.forEach((promise: any) => promise.cancelRequest('aborted'));
                sparklineChart.sparkLinePromise.push(promise);

                const xAxisDataPoints = createArrayOfDates(
                    moment(sparkLineSelectedDateRange.startDate),
                    moment(sparkLineSelectedDateRange.endDate),
                    sparklineChart.sparkItems.periodName
                );

                promise.get(sparklineChart.sparkItems.group, sparklineChart.sparkItems.name, params).then(response => {
                    const sparklineData = formatSparklineData({
                        dataPoints: xAxisDataPoints,
                        datas: response.data.objects,
                        dimension: sparklineChart.dimension.rawName,
                        metric: sparklineChart.metric,
                        graphGranularity: sparklineChart.sparkItems.periodName,
                    });

                    sparklineChart.setSparkData({
                        objects: sparklineChart.createSeries(
                            sparklineData,
                            sparklineChart.dimension,
                            sparklineChart.metric
                        ),
                        meta: response.data.meta,
                    });
                    sparklineChart.setSparkDataLoading(false);
                });
            });
        },
        [DEFAULT_RESPONSE, SPARK_ITEMS, VISIT_PROMISES]
    );

    useEffect(() => {
        reduxDispatch(setTitle(PAGE_TITLE));
        reduxDispatch(setDescription(''));

        fetchTableData({
            tabId: selectedTabId,
            orderBy,
            orderByDir,
            tablePaginations: tablePaginations,
            tableFilterStateObject: tableFilterState,
            tableLiveDataStateObject: tableLiveDataState,
            shouldTableLoading: true,
            shouldTableDataReset: true,
        });
        fetchSparkLineData({ tableLiveDataStateObject: tableLiveDataState, shouldSparkLoading: true });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (expendDatePicker || !tableLiveDataState.isLive) return;

        const interval = setInterval(() => {
            const SparklineLiveDateRange = {
                startDate: moment.utc().subtract(1, 'hour'),
                endDate: moment.utc(),
            };
            const tableLiveDataStateObject = {
                isLive: true,
                dateRange: SparklineLiveDateRange,
                tempDateRange: null,
            };
            setSparkLineSelectedDateRange(SparklineLiveDateRange);
            fetchTableData({
                tabId: selectedTabId,
                orderBy,
                orderByDir,
                tablePaginations: tablePaginations,
                tableFilterStateObject: tableFilterState,
                tableLiveDataStateObject: tableLiveDataState,
                shouldTableLoading: false,
                shouldTableDataReset: false,
            });
            fetchSparkLineData({ tableLiveDataStateObject: tableLiveDataStateObject, shouldSparkLoading: false });
        }, 5000);

        return () => clearInterval(interval);
    }, [
        selectedTabId,
        tableMetrics,
        tableDimensions,
        tablePaginations,
        tableFilterState,
        tableLiveDataState,
        expendDatePicker,
        fetchTableData,
        fetchSparkLineData,
    ]);

    const handleTabElementClick = useCallback(
        (tabId: number) => {
            setSelectedTabId(tabId);
            setTableResources(RESOURCES[tabId]);

            const tabConfig = tableTabConfig.map(tabData => {
                if (tabData.linkedResourceKey === tabId) tabData.isSelected = true;
                else tabData.isSelected = false;
                return tabData;
            });

            const resetTablePaginations = {
                ...tablePaginations,
                currentRowCount: 25,
                currentRange: [1, 25],
                currentPage: 1,
            };
            const resetTableFilters = {
                isFilterSubmited: false,
                searchCategory: '',
                searchTerm: '',
            };

            const resetTableMetrics = TABLE.tabListItems[tabId].metrics;
            const resetTableDimensions = RESOURCES[tabId].dimensions;

            tableFilterDispatch({ type: ACTIONS.FILTER_RESET as ActionFilterReset['type'] });

            setTableMetrics(resetTableMetrics);
            setTableDimensions(resetTableDimensions);
            setOrderBy(RESOURCES[tabId].defaultOrderBy);
            setOrderByDir(RESOURCES[tabId].defaultOrderBy.defaultOrderDir);

            setTableTabConfig(tabConfig);
            setTablePaginations(resetTablePaginations);
            if (tabId !== selectedTabId) {
                fetchTableData({
                    tabId: tabId,
                    orderBy: RESOURCES[tabId].defaultOrderBy,
                    orderByDir: RESOURCES[tabId].defaultOrderBy.defaultOrderDir,
                    tablePaginations: resetTablePaginations,
                    tableFilterStateObject: resetTableFilters,
                    tableLiveDataStateObject: tableLiveDataState,
                    shouldTableLoading: true,
                    shouldTableDataReset: true,
                });
            }
        },
        [tableTabConfig, tablePaginations, tableLiveDataState, RESOURCES, TABLE, fetchTableData]
    );

    // Live data handler
    const handleLiveDataOnClick = useCallback(() => {
        const dateRange = {
            startDate: moment.utc().subtract(1, 'hour'),
            endDate: moment.utc(),
        };

        const tableLiveDataStateObject = {
            isLive: true,
            dateRange,
            tempDateRange: null,
        };

        tableLiveDataDispatch({
            type: ACTIONS.LIVE_DATA_ENABLE as ActionLiveDataEnable['type'],
            payload: { dateRange },
        });

        fetchTableData({
            tabId: selectedTabId,
            orderBy,
            orderByDir,
            tablePaginations: tablePaginations,
            tableFilterStateObject: tableFilterState,
            tableLiveDataStateObject: tableLiveDataStateObject,
            shouldTableLoading: false,
            shouldTableDataReset: true,
        });

        fetchSparkLineData({ tableLiveDataStateObject: tableLiveDataStateObject, shouldSparkLoading: false });
        setExpendDatePicker(false);
        setSelectionDatePickerRange(selectionRange());
        setSparkLineSelectedDateRange(selectionRange());
        setIsDatePickerApplied(false);
    }, [
        selectedTabId,
        tableMetrics,
        tableDimensions,
        tablePaginations,
        tableFilterState,
        fetchTableData,
        fetchSparkLineData,
        setExpendDatePicker,
    ]);

    // Filter Date Picker handlers
    const toggleDatePickerCalender = useCallback(() => {
        setExpendDatePicker(!expendDatePicker);
    }, [setExpendDatePicker, expendDatePicker]);

    const handleDatePickerSelect = useCallback((ranges: Selection) => {
        setSelectionDatePickerRange(ranges.selection);
        setSparkLineSelectedDateRange(ranges.selection);
        const endDate = new Date(ranges.selection.endDate.setHours(23, 59, 59));
        // const end = ranges.selection.endDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        const dateRange = {
            startDate: ranges.selection.startDate,
            endDate: endDate,
        };
        tableLiveDataDispatch({
            type: ACTIONS.LIVE_DATA_DATE_PICKER_DATE_SELECT as ActionLiveDataDatePickerSelect['type'],
            payload: {
                tempDateRange: dateRange,
            },
        });
    }, []);

    const handleDatePickerCancel = useCallback(() => {
        setExpendDatePicker(false);
    }, [setExpendDatePicker]);

    const handleDatePickerApply = useCallback(() => {
        const tableLiveDataStateObject = {
            ...tableLiveDataState,
            isLive: false,
            dateRange: selectionDatePickerRange,
        };
        const resetTablePaginations = {
            ...tablePaginations,
            currentRowCount: 25,
            currentRange: [1, 25],
            currentPage: 1,
        };
        tableLiveDataDispatch({
            type: ACTIONS.LIVE_DATA_DATE_PICKER_APPLY as ActionLiveDataDatePickerApply['type'],
            payload: {
                dateRange: selectionDatePickerRange,
            },
        });

        fetchTableData({
            tabId: selectedTabId,
            orderBy,
            orderByDir,
            tablePaginations: resetTablePaginations,
            tableFilterStateObject: tableFilterState,
            tableLiveDataStateObject: tableLiveDataStateObject,
            shouldTableLoading: true,
            shouldTableDataReset: true,
        });
        fetchSparkLineData({ tableLiveDataStateObject: tableLiveDataStateObject, shouldSparkLoading: true });
        setTablePaginations(resetTablePaginations);
        setExpendDatePicker(false);
        setIsDatePickerApplied(true);
    }, [
        selectionDatePickerRange,
        selectedTabId,
        tableMetrics,
        tableDimensions,
        tablePaginations,
        tableFilterState,
        fetchTableData,
        fetchSparkLineData,
        tableLiveDataState,
        setExpendDatePicker,
    ]);

    // Filter select handler
    const handleFilterSelectChange = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            const target = event.target as HTMLInputElement;
            const targetValue = target.value;
            let value: string | undefined = '';

            if (targetValue !== '' && tableTabConfig[selectedTabId].metrics) {
                const newTableTabConfig: TableTabType = tableTabConfig;
                value = newTableTabConfig[selectedTabId].metrics.filter(item => item.rawName === targetValue)[0]
                    .rawName;
            } else {
                value = '';
            }

            tableFilterDispatch({
                type: ACTIONS.FILTER_CATEGORY_CHANGE as ActionFilterCategoryChange['type'],
                payload: { category: value },
            });
        },
        [tableTabConfig, selectedTabId]
    );

    const handleFilterTextChange = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;

        tableFilterDispatch({
            type: ACTIONS.FILTER_NAME_CHANGE as ActionFilterNameChange['type'],
            payload: { name: value },
        });
    }, []);

    const handleOnSubmitClick = useCallback(() => {
        if (tableFilterState.tempSearchCategory === '' && tableFilterState.tempSearchTerm === '') return;

        tableFilterDispatch({
            type: ACTIONS.FILTER_SUBMIT as ActionFilterSubmit['type'],
            payload: { submited: true },
        });
        const filterObject = {
            ...tableFilterState,
            isFilterSubmited: true,
            searchCategory: tableFilterState.tempSearchCategory,
            searchTerm: tableFilterState.tempSearchTerm,
        };
        const resetTablePaginations = {
            ...tablePaginations,
            currentRowCount: 25,
            currentRange: [1, 25],
            currentPage: 1,
        };

        fetchTableData({
            tabId: selectedTabId,
            orderBy,
            orderByDir,
            tablePaginations: resetTablePaginations,
            tableFilterStateObject: filterObject,
            tableLiveDataStateObject: tableLiveDataState,
            shouldTableLoading: true,
            shouldTableDataReset: true,
        });
        setTablePaginations(resetTablePaginations);
    }, [
        tableFilterState,
        selectedTabId,
        tableMetrics,
        tableDimensions,
        tablePaginations,
        fetchTableData,
        tableLiveDataState,
    ]);

    const setOwnerState = (
        key: 'tableOrderBy' | 'tableOrderDir' | 'rowCount' | 'page',
        value: TableFields | string | number
    ) => {
        let modifiedTablePaginations;

        switch (key) {
            // Table order by handler
            case 'tableOrderBy':
                setOrderBy(value as TableFields);
                fetchTableData({
                    tabId: selectedTabId,
                    orderBy: value as TableFields,
                    orderByDir,
                    tablePaginations: tablePaginations,
                    tableFilterStateObject: tableFilterState,
                    tableLiveDataStateObject: tableLiveDataState,
                    shouldTableLoading: true,
                    shouldTableDataReset: true,
                });
                break;
            // Table order direction handler
            case 'tableOrderDir':
                setOrderByDir(value as string);
                fetchTableData({
                    tabId: selectedTabId,
                    orderBy,
                    orderByDir: value as string,
                    tablePaginations: tablePaginations,
                    tableFilterStateObject: tableFilterState,
                    tableLiveDataStateObject: tableLiveDataState,
                    shouldTableLoading: true,
                    shouldTableDataReset: true,
                });
                break;
            // Pagination page change handler
            case 'rowCount':
                const DEFAULT_START_VALUE = 1;
                modifiedTablePaginations = generateTablePaginations(
                    DEFAULT_START_VALUE,
                    value as number,
                    tablePaginations
                );

                setTablePaginations(modifiedTablePaginations);
                fetchTableData({
                    tabId: selectedTabId,
                    orderBy,
                    orderByDir,
                    tablePaginations: modifiedTablePaginations,
                    tableFilterStateObject: tableFilterState,
                    tableLiveDataStateObject: tableLiveDataState,
                    shouldTableLoading: true,
                    shouldTableDataReset: true,
                });
                break;
            // Pagination page item click event handler
            case 'page':
                if (value === tablePaginations.currentPage) return;
                modifiedTablePaginations = generateTablePaginations(
                    value as number,
                    tablePaginations.currentRowCount,
                    tablePaginations
                );
                setTablePaginations(modifiedTablePaginations);
                fetchTableData({
                    tabId: selectedTabId,
                    orderBy,
                    orderByDir,
                    tablePaginations: modifiedTablePaginations,
                    tableFilterStateObject: tableFilterState,
                    tableLiveDataStateObject: tableLiveDataState,
                    shouldTableLoading: true,
                    shouldTableDataReset: true,
                });
                break;
            default:
                break;
        }
    };

    return (
        <HealthDashboardContext.Provider
            value={{
                // Sparkline Data States
                SPARK_ITEMS,
                visitsSparkData,
                eventsSparkData,
                impressionsSparkData,
                tagValidationSparkData,

                // Sparkline Loading States
                visitsSparkDataLoading,
                eventsSparkDataLoading,
                impressionsSparkDataLoading,
                tagValidationSparkDataLoading,
                chartDateGranularity,

                // Dimension
                dimensionFilters,
                setDimensionFilters,

                // Live States
                handleLiveDataOnClick,
                tableLiveDataState,

                // Table States
                tableData,
                tableNoData,
                tableLoading,
                tableTabConfig,
                handleTabElementClick,
                tableMetrics,
                tableDimensions,
                orderBy,
                orderByDir,
                selectedTabId,
                tablePaginations,
                handleOnSubmitClick,

                // Filter States
                tableFilterState,
                handleFilterSelectChange,
                DEFAULT_FILTER_CATEGORY_VALUE,
                DEFAULT_FILTER_CATEGORY_TEXT,
                handleFilterTextChange,

                // Date States
                TODAY,
                selectionDatePickerRange,
                expendDatePicker,
                isDatePickerApplied,
                toggleDatePickerCalender,
                handleDatePickerSelect,
                handleDatePickerCancel,
                handleDatePickerApply,
                dateRangePickerRef,

                // Set owner status
                setOwnerState,
            }}
        >
            {children}
        </HealthDashboardContext.Provider>
    );
};

export default HealthDashboardProvider;
