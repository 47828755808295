import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';
import { DropdownOption } from '../../../../../types';
import IconChevronUp from '../../../../../../components/icons/chevron-up';

const StyledDropdownContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledTrigger = styled(DropdownMenu.Trigger)<{ disabled?: boolean }>`
    margin: 5px !important;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')}!important;
`;

const StyledTriggerContent = styled.div`
    border: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    background-color: ${props => props.theme.sectionDashboard.widget.menu.triggerBackgroundColor};
    border-radius: 5px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    min-width: 150px;
    min-height: 35px;
    font-size: 0.9rem;
    cursor: pointer;
`;

const StyledOptionLabel = styled.div`
    font-size: 1rem;
    padding: 5px 10px;
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.fontWeight};
`;

const StyledIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px 0px 10px;
    height: 100%;
    border-left: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
`;

const StyledIconChevronSmall = styled(IconChevronUp)`
    transform: rotate(180deg);
    height: 14px;
    width: auto;
    & path {
        fill: ${props => props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledContent = styled(DropdownMenu.Content)`
    background-color: ${props => props.theme.sectionDashboard.section.sectionMenu.select.backgroundColor};
    min-width: 273px;
    padding: 10px 0px;
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.menu.shadow};
    max-height: 50vh;
    z-index: 500005; // overlap the dialog
    overflow-y: auto;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.fontWeight};
`;

const StyledDropdownItem = styled(DropdownMenu.Item)`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 10px 15px;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
        border: none;
        outline: none;
    }
`;

export type DropdownOptions = DropdownOption[];

export type DialogDropdownProps = {
    options: DropdownOptions;
    selected?: DropdownOption | null;
    placeholder?: string;
    isDisabled?: boolean;
    onChange: (value: DropdownOption) => void;
};

const DialogDropdown = ({ options, placeholder, selected, isDisabled, onChange }: DialogDropdownProps) => {
    return (
        <StyledDropdownContainer>
            <DropdownMenu.Root>
                <StyledTrigger asChild disabled={isDisabled}>
                    <StyledTriggerContent>
                        <StyledOptionLabel>{selected?.label || placeholder}</StyledOptionLabel>
                        <StyledIconContainer>
                            <StyledIconChevronSmall />
                        </StyledIconContainer>
                    </StyledTriggerContent>
                </StyledTrigger>
                <DropdownMenu.Portal>
                    <StyledContent side="bottom" align="end">
                        <div>
                            {options.map(option => {
                                return (
                                    <StyledDropdownItem onClick={() => onChange(option)} key={option.value}>
                                        {option.label}
                                    </StyledDropdownItem>
                                );
                            })}
                        </div>
                    </StyledContent>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </StyledDropdownContainer>
    );
};

export default DialogDropdown;
