export const ACTIONS = {
    FILTER_EXPAND_COLLAPSE: 'filter-expand-collapse',
    FILTER_APPLY: 'filter-apply',
    FILTER_CLEAR: 'filter-clear',
    FILTER_CHANNEL_CLEAR: 'filter-channel-clear',

    METRICS_FILTER_CHANGE_METRIC_DATA: 'metrics-filter-change-metric-data',
    METRICS_FILTER_CHANGE_OPERATOR_DATA: 'metrics-filter-change-operator-data',
    METRICS_FILTER_CHANGE_VALUE_DATA: 'metrics-filter-change-value-data',
    METRICS_FILTER_SELECT_NEW_FILTER: 'metrics-filter-select-new-filter',
    METRICS_FILTER_EDIT_SELECTED_FILTER: 'metrics-filter-edit-selected-filter',
    METRICS_FILTER_REMOVE_SELECTED_FILTER: 'metrics-filter-remove-selected-filter',
    METRICS_FILTER_ADD_NEW_FILTER: 'metrics-filter-add-new-filter',
    METRICS_FILTER_REMOVE_NEW_FILTER: 'metrics-filter-remove-new-filter',

    PRODUCT_FILTER_DATA_INITIAL_SET: 'product-filter-initial-data-set',
    PRODUCT_FILTER_DATA_SET: 'product-filter-data-set',

    CHANNEL_FILTER_DATA_INITIAL_SET: 'channel-filter-initial-data-set',
    CHANNEL_FILTER_DATA_SET: 'channel-filter-data-set',

    BUTTON_GROUP_SET: 'view-button-group-set',

    COMPARISON_DATE_RANGE_TOGGLE: 'comparison-date-range-toggle',
};
