import React from 'react';
import { QueryStatus } from '@tanstack/react-query';

// Context
import { ConfigTableProvider } from './context/config-table-context';

// Components
import Table from './components/table';
import Header from './components/header';
import Body from './components/body';
import Row from './components/row';
import CellHeader from './components/cells/cell-header';
import Cell from './components/cells/cell';
import CellActions from './components/cells/cell-actions';
import ActionDropdownItem from './components/actions/action-dropdown/action-dropdown-item';
import CellCheckbox from './components/cells/cell-checkbox';
import ActionBar from './components/actions/action-bar/action-bar';
import ActionButton from './components/actions/action-bar/action-button';
import ActionBarJustifyLeft from './components/actions/action-bar/action-bar-justify-left';
import ActionBarJustifyRight from './components/actions/action-bar/action-bar-justify-right';
import TableSearch from './components/table-search';

export type ConfigTableProps = {
    status: QueryStatus;
    children: React.ReactNode;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
};

const ConfigTable = ({ status, isFetching, disabled, empty, children }: ConfigTableProps) => {
    return (
        <ConfigTableProvider status={status} isFetching={isFetching} disabled={disabled} empty={empty}>
            {children}
        </ConfigTableProvider>
    );
};

ConfigTable.TableSearch = TableSearch;
ConfigTable.Table = Table;
ConfigTable.Header = Header;
ConfigTable.Body = Body;
ConfigTable.Row = Row;
ConfigTable.CellHeader = CellHeader;
ConfigTable.Cell = Cell;
ConfigTable.CellActions = CellActions;
ConfigTable.ActionDropdownItem = ActionDropdownItem;
ConfigTable.CellCheckbox = CellCheckbox;
ConfigTable.ActionBar = ActionBar;
ConfigTable.ActionBarJustifyLeft = ActionBarJustifyLeft;
ConfigTable.ActionBarJustifyRight = ActionBarJustifyRight;
ConfigTable.ActionButton = ActionButton;

export default ConfigTable;
