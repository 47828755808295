import React, { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate, useMatch, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ReportLcVsAttribution from './lc-vs-attribution';
import ReportSkuPerformance from './sku-performance';
import ReportCorridorPerformance from './corridor-performance';
import ReportSEOKeyword from './seo-keyword-prioritisation';
import PageSpeedResource from './page-speed';
import PageTagsResource from './page-tags';
import TechSEOResource from './tech-seo';
import ReportContributionAnalysis from './contribution-analysis';
import ReportEventFunnels from './event-funnels';
import LayoutJourneyAnalysis from './journey-analysis';
import LayoutGoogleTrendsTraffic from './google-trends';
import ReportSeogdKeywordVisibility from './seo-keyword-marketshare';
import ReportATLImpressionTrafficForecastResource from './atl-impression-traffic-forecast';
import LayoutPPCImpressionSharePerformance from './ppc-impression-share';
import ReportPathPerformance from './page-performance';
import ReportLandingPathPerformance from './landing-page-performance';
import ReportValidationErrors from './error-reporting';
import ReportRegion from './region-performance';
import ReportMCSSalesJourney from './mcs-sales-journey';
import ReportGridCarbonPathResource from './carbon-usage';
import ReportLcVsAttributionReallocationATL from './lc-vs-attribution-reallocation-atl';
import ReportMonthlyChannelPerformance from './monthly-channel-performance';
import SegmenterVenn from './segmenter/segmenter-venn';

import SegmenterDashboard from '../segmenter/layouts/segmenter-dashboard';

import TechSeoDashboard from '../tech-seo/layouts/tech-seo-dashboard';
import AccountHealthDashboard from '../account-health-dashboard/layouts/account-health-dashboard';
import CustomerExplorer from '../single-customer-view/layouts/customer-explorer-layout';
import SingleCustomerFinder from '../single-customer-view/layouts/single-customer-finder';
import ReportAttributionComparison from './attribution-comparison';

import ChannelInsightsDashboard from '../channel-insights/channel-insights-dashboard/layouts/channel-insights-dashboard';
import ChannelInsightsDashboardComparison from '../channel-insights/channel-insights-dashboard-comparison/layouts/channel-insights-dashboard-comparison';
import ChannelDetailDashboard from '../channel-detail/channel-detail-dashboard/layouts/channel-detail-dashboard';
import ChannelDetailDashboardComparison from '../channel-detail/channel-detail-dashboard-comparison/layouts/channel-detail-dashboard-comparison';

import ContentInsightsDashboard from '../content-insights/content-insights-dashboard/layouts/content-insights-dashboard';
import ContentInsightsDashboardComparison from '../content-insights/content-insights-dashboard-comparison/layouts/content-insights-dashboard-comparison';
import ContentDetailDashboard from '../content-detail/content-detail-dashboard/layouts/content-detail-dashboard';
import ContentDetailDashboardComparison from '../content-detail/content-detail-dashboard-comparison/layouts/content-detail-dashboard-comparison';

import CustomerInsightsDashboard from '../customer-insights/customer-insights-dashboard/layouts/customer-insights-dashboard';
import CustomerInsightsDashboardComparison from '../customer-insights/customer-insights-dashboard-comparison/layouts/customer-insights-dashboard-comparison';

//Filter-bar Context
import { FilterBarContext } from '../filter-bar/context/filter-bar-context';
import FilterBar from '../filter-bar/components/filter-bar';

const LayoutReport = () => {
    const { startDate, endDate } = useSelector(state => state.date);
    const [loadingPdfDownload, setLoadingPdfDownload] = useState(false);

    const location = useLocation();

    const pageProps = {
        history: useNavigate(location.pathname),
        location: location,
        match: useMatch(location.pathname),
    };

    // eslint-disable-next-line
    let description = '';

    const wrapperRef = useRef(null);

    const {
        // Product
        selectedProductsData,

        // Metrics
        selectedMetricsData,

        // Channel
        selectedChannelData,
    } = useContext(FilterBarContext);

    const setOwnerState = (key, value) => {
        switch (key) {
            case 'descriptionStatus':
                description = value;
                break;
            default:
                throw Error(`Unknown status ${key}`);
        }
    };

    const reportRoutes = useRoutes([
        {
            path: '',
            children: [
                {
                    path: 'attribution',
                    children: [
                        {
                            path: 'lc-vs-attribution',
                            element: (
                                <ReportLcVsAttribution
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                    selectedMetrics={selectedMetricsData}
                                />
                            ),
                        },
                        {
                            path: 'attribution-comparison',
                            element: (
                                <ReportAttributionComparison
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                    selectedMetrics={selectedMetricsData}
                                />
                            ),
                        },
                        {
                            path: 'reallocation-atl',
                            element: (
                                <ReportLcVsAttributionReallocationATL
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                />
                            ),
                        },
                        {
                            path: 'sku-performance',
                            element: (
                                <ReportSkuPerformance
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                    selectedMetrics={selectedMetricsData}
                                />
                            ),
                        },
                        {
                            path: 'corridor-performance',
                            element: (
                                <ReportCorridorPerformance
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                    selectedMetrics={selectedMetricsData}
                                />
                            ),
                        },
                        {
                            path: 'monthly-performance',
                            element: (
                                <ReportMonthlyChannelPerformance
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                    selectedMetrics={selectedMetricsData}
                                />
                            ),
                        },
                        {
                            path: 'contribution-analysis',
                            element: (
                                <ReportContributionAnalysis
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                />
                            ),
                        },
                        {
                            path: 'landing-page-performance',
                            element: (
                                <ReportLandingPathPerformance
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                    selectedMetrics={selectedMetricsData}
                                />
                            ),
                        },
                        {
                            path: 'page-performance',
                            element: (
                                <ReportPathPerformance
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                    selectedMetrics={selectedMetricsData}
                                />
                            ),
                        },
                        {
                            path: 'region-performance',
                            element: (
                                <ReportRegion
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                />
                            ),
                        },
                        {
                            path: 'new-vs-repeat-sales',
                            element: (
                                <ReportMCSSalesJourney
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                    selectedMetrics={selectedMetricsData}
                                />
                            ),
                        },
                        {
                            path: 'channel-insights-dashboard',
                            element: (
                                <ChannelInsightsDashboard
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                        {
                            path: 'channel-insights-comparison-dashboard',
                            element: (
                                <ChannelInsightsDashboardComparison
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                        {
                            path: 'channel-detail-dashboard',
                            element: (
                                <ChannelDetailDashboard
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                        {
                            path: 'channel-detail-comparison-dashboard',
                            element: (
                                <ChannelDetailDashboardComparison
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                        {
                            path: 'content-insights-dashboard',
                            element: (
                                <ContentInsightsDashboard
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                        {
                            path: 'content-insights-comparison-dashboard',
                            element: (
                                <ContentInsightsDashboardComparison
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                        {
                            path: 'content-detail-dashboard',
                            element: (
                                <ContentDetailDashboard
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                        {
                            path: 'content-detail-comparison-dashboard',
                            element: (
                                <ContentDetailDashboardComparison
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                        {
                            path: 'customer-insights-dashboard',
                            element: (
                                <CustomerInsightsDashboard
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                        {
                            path: 'customer-insights-comparison-dashboard',
                            element: (
                                <CustomerInsightsDashboardComparison
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            ),
                        },
                    ],
                },
                {
                    path: 'channels',
                    children: [
                        {
                            path: 'ppc',
                            children: [
                                {
                                    path: 'keyword-impression-share',
                                    element: (
                                        <LayoutPPCImpressionSharePerformance
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            path: 'seo',
                            children: [
                                {
                                    path: 'keywords',
                                    element: (
                                        <ReportSEOKeyword
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                            selectedMetrics={selectedMetricsData}
                                        />
                                    ),
                                },
                                {
                                    path: 'keyword-marketshare',
                                    element: (
                                        <ReportSeogdKeywordVisibility
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                            selectedMetrics={selectedMetricsData}
                                        />
                                    ),
                                },
                                {
                                    path: 'google-trends',
                                    element: (
                                        <LayoutGoogleTrendsTraffic
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                            selectedMetrics={selectedMetricsData}
                                        />
                                    ),
                                },
                                {
                                    path: 'tech-seo/dashboard',
                                    element: (
                                        <TechSeoDashboard
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    ),
                                },
                                {
                                    path: 'page-speed',
                                    element: (
                                        <PageSpeedResource
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                            selectedMetrics={selectedMetricsData}
                                        />
                                    ),
                                },
                                {
                                    path: 'page-tags',
                                    element: (
                                        <PageTagsResource
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                        />
                                    ),
                                },
                                {
                                    path: 'tech-seo',
                                    element: (
                                        <TechSEOResource
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                        />
                                    ),
                                },
                            ],
                        },
                        {
                            path: 'atl',
                            children: [
                                {
                                    path: 'impression-traffic-forecast',
                                    element: (
                                        <ReportATLImpressionTrafficForecastResource
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                            selectedMetrics={selectedMetricsData}
                                        />
                                    ),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'user-behaviour',
                    children: [
                        {
                            path: 'journeys',
                            children: [
                                {
                                    path: 'single-customer-view',
                                    element: (
                                        <SingleCustomerFinder
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                        />
                                    ),
                                },
                                {
                                    path: 'journey-analysis',
                                    element: <LayoutJourneyAnalysis status={setOwnerState} />,
                                },
                                {
                                    path: 'event-funnels',
                                    element: (
                                        <ReportEventFunnels
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                        />
                                    ),
                                },
                                {
                                    path: 'customer-explorer',
                                    element: (
                                        <CustomerExplorer
                                            status={setOwnerState}
                                            {...pageProps}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedProducts={selectedProductsData}
                                            selectedChannelData={selectedChannelData}
                                            setOwnerState={setOwnerState}
                                        />
                                    ),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'segmenter',
                    children: [
                        {
                            path: 'dashboard',
                            element: (
                                <SegmenterDashboard status={setOwnerState} startDate={startDate} endDate={endDate} />
                            ),
                        },
                        {
                            path: 'venn',
                            element: <SegmenterVenn status={setOwnerState} startDate={startDate} endDate={endDate} />,
                        },
                    ],
                },
                {
                    path: 'sustainability',
                    children: [
                        {
                            path: 'carbon/carbon-usage-report',
                            element: (
                                <ReportGridCarbonPathResource
                                    status={setOwnerState}
                                    {...pageProps}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProducts={selectedProductsData}
                                    selectedMetrics={selectedMetricsData}
                                />
                            ),
                        },
                    ],
                },
                {
                    path: 'health/account-health-dashboard',
                    element: (
                        <AccountHealthDashboard
                            status={setOwnerState}
                            {...pageProps}
                            startDate={startDate}
                            endDate={endDate}
                            selectedProducts={selectedProductsData}
                            selectedMetrics={selectedMetricsData}
                        />
                    ),
                },
                {
                    path: 'health/error-reporting',
                    element: (
                        <ReportValidationErrors
                            status={setOwnerState}
                            {...pageProps}
                            startDate={startDate}
                            endDate={endDate}
                            selectedProducts={selectedProductsData}
                            selectedMetrics={selectedMetricsData}
                        />
                    ),
                },
            ],
        },
    ]);

    return (
        <div ref={wrapperRef} id="report">
            <FilterBar
                reportRef={wrapperRef}
                loadingPdfDownload={loadingPdfDownload}
                setLoadingPdfDownload={setLoadingPdfDownload}
                setOwnerState={setOwnerState}
            />
            {reportRoutes}
        </div>
    );
};

export default LayoutReport;
