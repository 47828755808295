import moment from 'moment';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_DATE':
            return Object.assign({}, state, action.payload);
        default:
            break;
    }
    return state;
}

const initialEndDate = moment(moment().format('YYYY-MM-DDT23:59:59')).subtract(1, 'days');
const initialStartDate = moment(moment().format('YYYY-MM-DDT00:00:00')).subtract(7, 'days');

const initialState = {
    startDate: initialStartDate,
    endDate: initialEndDate,
};
