import React from 'react';

//Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';
import useWidgetTheme from '../../hooks/base/use-widget-theme';

// Types & Helpers
import { BigNumberComparisonData, WidgetColour } from '../../types';
import { calculateVariance, formatVariance } from '../../helpers/variance-helpers';

// Components
import WidgetBase from '../base/widget-base';
import styled from 'styled-components';
import { formatNumber, formatValue } from '../../helpers/format-number';

const StyledContainer = styled.div<{ width: number; height: number }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => (props.height === 1 && props.width > 1 ? 'row' : 'column')};
    align-items: center;
    justify-content: ${props => (props.height > 1 ? 'center' : 'space-evenly')};
    gap: ${props => props.height > 1 && '30px'};
`;

const StyledNumberContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
`;

const StyledNumber = styled.h1<{ height: number }>`
    color: ${props => props.theme.sectionDashboard.widget.bigNumber.textColor};
    text-align: center;
    z-index: 1;
    font-size: ${props => (props.height > 2 ? '2.5rem' : props.height > 1 ? '2rem' : '1.8rem')};
    margin: 0px;
`;

const StyledComparisonNumber = styled.p<{ height: number }>`
    color: ${props => props.theme.sectionDashboard.widget.bigNumber.textColor};
    font-size: ${props => (props.height > 2 ? '1.3rem' : props.height > 1 ? '1rem' : '0.9rem')};
    font-weight: 500;
    margin: 0;
    text-align: center;
    z-index: 1;
`;

const StyledVariance = styled.div<{
    variance: number;
    colour: WidgetColour;
    width: number;
    height: number;
    invert: boolean;
}>`
    color: ${props => props.theme.sectionDashboard.widget.bigNumber.textColor};
    background-color: ${props => props.theme.sectionDashboard.widget.bigNumber.varianceBackgroundColor};
    padding: ${props => (props.height === 1 ? '2px 10px' : '2px 20px')};
    border-radius: 50px;
    font-size: ${props => (props.width > 1 && props.height < 2 ? '1.2rem' : props.height === 1 ? '1rem' : '1.2rem')};
    font-weight: 600;
    color: ${props =>
        props.variance > 0
            ? props.theme.sectionDashboard.widget.variance.textColorPositive
            : props.variance < 0
            ? props.theme.sectionDashboard.widget.variance.textColorNegative
            : props.theme.sectionDashboard.widget.variance.textColor};
    border: ${props =>
        !props.colour &&
        `1px solid ${
            props.variance > 0
                ? props.theme.sectionDashboard.widget.variance.textColorPositive
                : props.variance < 0
                ? props.theme.sectionDashboard.widget.variance.textColorNegative
                : props.theme.sectionDashboard.widget.variance.textColor
        }`};
    display: inline-block;
    z-index: 1;
`;

const StyledWidgetChart = styled.div`
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
`;

export type WidgetBigNumberComparisonProps = {
    title: string;
    data: BigNumberComparisonData;
    colour?: WidgetColour;
    comparisonColour?: WidgetColour;
    invertVariance?: boolean;
    href?: string;
    menu?: React.ReactElement;
};

const WidgetBigNumberComparison = ({
    title,
    data,
    colour = WidgetColour.Default,
    comparisonColour = WidgetColour.Green,
    invertVariance,
    href,
    menu,
}: WidgetBigNumberComparisonProps) => {
    const { width, height } = useSectionDashboard();
    const widgetTheme = useWidgetTheme(colour);
    const comparisonTheme = useWidgetTheme(comparisonColour);

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} colour={colour} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} colour={colour} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} colour={colour} href={href} />;
    }

    const { formatting, rounding, series, comparisonSeries } = data;

    // This Period
    const formattedNumber = typeof data.value === 'number' ? formatNumber(data.value, width, rounding) : null;
    const formattedRatioNumber = data.ratioValue ? formatNumber(data.ratioValue, width, rounding) : undefined;

    // Comparison Period
    const formattedComparisonNumber =
        typeof data.comparisonValue === 'number' ? formatNumber(data.comparisonValue, width, rounding) : null;
    const formattedComparisonRatioNumber = data.comparisonRatioValue
        ? formatNumber(data.comparisonRatioValue, width, rounding)
        : undefined;

    // Numbers To Display
    // This Period
    const thisPeriodNumber =
        formattedNumber && formattedRatioNumber
            ? `${formatting ? formatValue(formattedNumber, formatting) : formattedNumber} : ${
                  formatting ? formatValue(formattedRatioNumber, formatting) : formattedRatioNumber
              }`
            : formattedNumber
            ? formatting
                ? formatValue(formattedNumber, formatting)
                : formattedNumber
            : 'No Data';

    // Comparison Period
    const comparisonPeriodNumber =
        formattedComparisonNumber && formattedComparisonRatioNumber
            ? `(vs ${formatting ? formatValue(formattedComparisonNumber, formatting) : formattedComparisonNumber} : ${
                  formatting ? formatValue(formattedComparisonRatioNumber, formatting) : formattedComparisonRatioNumber
              })`
            : formattedComparisonNumber
            ? `(vs ${formatting ? formatValue(formattedComparisonNumber, formatting) : formattedComparisonNumber})`
            : '(no data for comparison)';

    // Variance
    let variance;
    let varianceFormatted;

    if (!formattedRatioNumber && !formattedComparisonRatioNumber && data.value && data.comparisonValue) {
        variance = calculateVariance(data.value, data.comparisonValue);
        varianceFormatted = formatVariance(variance, true);
    }

    const chartOptions = {
        chart: {
            type: 'areaspline',
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            borderRadius: 0,
            animation: false,
        },
        plotOptions: {
            series: {
                animation: false,
                marker: {
                    enabled: false,
                    fillColor: '#FFFFFF',
                    lineWidth: 1,
                    lineColor: '#FFFFFF',
                },
                enableMouseTracking: false,
            },
        },
        title: {
            text: '',
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                data: comparisonSeries,
                lineColor: comparisonTheme.splineLine,
                color: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, comparisonTheme.splineGradientStart],
                        [1, comparisonTheme.splineGradientEnd],
                    ],
                },
                opacity: 0.7,
            },
            {
                data: series,
                lineColor: widgetTheme.splineLine,
                color: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, widgetTheme.splineGradientStart],
                        [1, widgetTheme.splineGradientEnd],
                    ],
                },
                opacity: 0.7,
            },
        ],
        xAxis: {
            visible: false,
            minPadding: 0,
            maxPadding: 0,
        },
        yAxis: {
            visible: false,
            minPadding: 0,
            maxPadding: 0,
        },
    };

    return (
        <WidgetBase title={title} href={href} colour={colour} menu={menu}>
            <StyledContainer width={width} height={height}>
                <StyledNumberContainer>
                    <StyledNumber height={height}>{thisPeriodNumber}</StyledNumber>
                    <StyledComparisonNumber height={height}>{comparisonPeriodNumber}</StyledComparisonNumber>
                    {!formattedRatioNumber && !formattedComparisonRatioNumber && (series || comparisonSeries) && (
                        <StyledWidgetChart>
                            <CommonHighcharts
                                highcharts={Highcharts}
                                options={chartOptions}
                                containerProps={{ style: { height: '100%', width: '100%' } }}
                            />
                        </StyledWidgetChart>
                    )}
                </StyledNumberContainer>
                {varianceFormatted ? (
                    varianceFormatted !== 'N/A' ? (
                        <StyledVariance
                            variance={variance ? variance : 0}
                            colour={colour}
                            width={width}
                            height={height}
                            invert={invertVariance ? true : false}
                        >
                            {varianceFormatted}
                        </StyledVariance>
                    ) : null
                ) : null}
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetBigNumberComparison;
