import React from 'react';
import { CubedIcon } from './types';

const IconInternalLink: CubedIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
            <path
                d="M432,320h-32c-8.8,0-16,7.2-16,16v112H64V128h144c8.8,0,16-7.2,16-16V80c0-8.8-7.2-16-16-16H48C21.5,64,0,85.5,0,112v352
						c0,26.5,21.5,48,48,48l0,0h352c26.5,0,48-21.5,48-48l0,0V336C448,327.2,440.8,320,432,320z M152.6,384.6l128-0.4
						c21.4-0.1,32-26,16.9-41.1l-35.8-35.6L504.5,63.1c9.4-9.4,9.3-24.6-0.1-33.9c0,0,0,0-0.1-0.1L481.7,6.5c-9.4-9.4-24.6-9.3-33.9,0.1
						c0,0,0,0-0.1,0.1L204.9,251.1L169,215.5c-15-15-41-4.4-40.9,17.1l0.4,128C128.5,373.9,139.3,384.6,152.6,384.6z"
            />
        </svg>
    );
};

export default IconInternalLink;
