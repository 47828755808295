import { ResourceComparisonData } from '../../../types';

// Types
type useResourceCombineBigNumberRequestsComparisonArgs = {
    resourceData: ResourceComparisonData[];
};

const useResourceCombineBigNumberRequestsComparison = ({
    resourceData,
}: useResourceCombineBigNumberRequestsComparisonArgs) => {
    let initialResource = {
        status: 'success',
        request: {},
        type: 'resource',
        meta: {},
        objects: [],
        resource: {},
    };
    let newResourceData = { ...initialResource };
    let newResourceComparisonData = { ...initialResource };
    let resultComparisonResource = {
        status: 'success',
        request: {},
        type: 'resourceComparison',
        resources: [newResourceData, newResourceComparisonData],
    };

    let hasSuccess = false;
    let hasLoading = false;
    let hasEmpty = false;
    let hasError = false;

    resourceData.forEach(item => {
        if (item.status === 'success') {
            const resource = item.resources[0];
            const resourceComparison = item.resources[1];

            hasSuccess = true;

            if (resource.status === 'loading' || resource.status === 'error' || resource.status === 'empty') {
                newResourceData.status = resource.status;
            }

            if (
                resourceComparison.status === 'loading' ||
                resourceComparison.status === 'error' ||
                resourceComparison.status === 'empty'
            ) {
                newResourceComparisonData.status = resourceComparison.status;
            }

            if (resource.status === 'success') {
                newResourceData.request = { ...newResourceData.request, ...resource.request };
                newResourceData.type = resource.type;
                newResourceData.meta = { ...newResourceData.meta, ...resource.meta };
                newResourceData.objects = newResourceData.objects.concat(resource.objects as []);
                newResourceData.resource = { ...newResourceData.resource, ...resource.resource };
            }

            if (resourceComparison.status === 'success') {
                newResourceComparisonData.request = {
                    ...newResourceComparisonData.request,
                    ...resourceComparison.request,
                };
                newResourceComparisonData.type = resourceComparison.type;
                newResourceComparisonData.meta = { ...newResourceComparisonData.meta, ...resourceComparison.meta };
                newResourceComparisonData.objects = newResourceComparisonData.objects.concat(
                    resourceComparison.objects as []
                );
                newResourceComparisonData.resource = {
                    ...newResourceComparisonData.resource,
                    ...resourceComparison.resource,
                };
            }

            resultComparisonResource.request = { ...resultComparisonResource.request, ...item.request };
            resultComparisonResource.type = item.type;
            resultComparisonResource.resources = [newResourceData, newResourceComparisonData];
        } else if (item.status === 'loading') {
            hasLoading = true;
        } else if (item.status === 'empty') {
            hasEmpty = true;
        } else if (item.status === 'error') {
            hasError = true;
        }
    });

    if (hasLoading) {
        resultComparisonResource.status = 'loading';
    } else if (hasEmpty && !hasSuccess && !hasError) {
        resultComparisonResource.status = 'empty';
    } else if (hasError && !hasSuccess) {
        resultComparisonResource.status = 'error';
    }

    return resultComparisonResource as ResourceComparisonData;
};

export default useResourceCombineBigNumberRequestsComparison;
