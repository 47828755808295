export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_GLOBALSALESTITLE':
            return Object.assign({}, state, {
                globalSalesTitle: action.payload.globalSalesTitle,
            });
        default:
            return state;
    }
}

const initialState = {
    globalSalesTitle: '',
};
