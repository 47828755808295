// React Dependencies
import React, { memo, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

// Styles
const StyledColourPicker = styled.div`
    position: relative;
`;

const StyledColourPickerSelect = styled.div`
    width: 100%;
    height: 40px;
    background-color: ${props => props.theme.colours.inputColour};
    border: none;
    border-bottom: solid 2px ${props => props.theme.colours.offBlack};
    margin-bottom: 5px;
    border-radius: 2px;
    cursor: pointer;
`;

const StyledColourPickerSelectColourPreview = styled('div')<{ selectedColour: tinycolor.Instance }>`
    width: 40px;
    height: 40px;
    display: table-cell;

    background-color: ${props =>
        props.selectedColour !== null && props.selectedColour !== undefined && `#${props.selectedColour.toHex()}`};
    background-image: ${props =>
        (props.selectedColour === null || props.selectedColour === undefined) &&
        `linear-gradient(
        135deg,
        ${props.theme.colours.light} 0%,
        ${props.theme.colours.light} 48%,
        ${props.theme.colours.red} 48%,
        ${props.theme.colours.red} 52%,
        ${props.theme.colours.light} 52%,
        ${props.theme.colours.light} 100%
    )`};
    background-size: ${props => props.selectedColour === null && '40px 40px'};
`;

const StyledColourPickerPlaceHolder = styled('p')<{ selectedColour: tinycolor.Instance }>`
    display: inline-block;
    display: table-cell;
    font-weight: ${props => (props.selectedColour === null ? '300' : '400')};
    font-size: ${props => props.selectedColour === null && '0.9em'};
    padding: ${props => (props.selectedColour === null ? '12px' : '10px 12px')};
`;

const StyledColourPickerHolder = styled('div')<{ isShowColourPickerHolder: boolean }>`
    ${props => props.theme.dropShadow('5px', '5px', '3px', '0px', 'rgba(0, 0, 0, 0.1)')};
    transition: ${props => props.theme.transition};
    visibility: ${props => (props.isShowColourPickerHolder ? 'visible' : 'hidden')};
    opacity: ${props => (props.isShowColourPickerHolder ? '1' : '0')};
    position: absolute;
    height: auto;
    z-index: 5;
    width: 100%;
    max-width: 310px;
    margin-left: 40px;
    background-color: ${props => props.theme.colours.white};
    padding: 2px;
    margin-top: -5px;

    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
`;

const StyledColourPickerHolderTile = styled('div')<{ colour: tinycolor.Instance }>`
    transition: ${props => props.theme.transition};
    height: 40px;
    flex: 0 0 calc(16.66667% - 4px);
    max-width: calc(16.66667% - 4px);
    display: inline-block;
    margin: 2px;
    cursor: pointer;
    &:hover {
        filter: saturate(0.5);
    }
    background-color: ${props => `#${props.colour.toHex()}`};
`;

// Types
type Colour = { name: string; colour: tinycolor.Instance };

export type InputColourPickerProps = {
    selectedColour: Colour;
    placeholderTextOverride?: string;
    onSelectedColourChanged: (colour: Colour) => void;
    colours: Colour[];
};

const InputColourPicker = ({
    selectedColour,
    placeholderTextOverride,
    onSelectedColourChanged,
    colours,
}: InputColourPickerProps) => {
    const [selectedPickerColour, setSelectedPickerColour] = useState(
        selectedColour !== undefined ? selectedColour : { name: null, colour: null }
    );
    const [isShowColourPickerHolder, setIsShowColourPickerHolder] = useState(false);

    const placeholderText = placeholderTextOverride !== undefined ? placeholderTextOverride : 'Select a colour...';

    useEffect(() => {
        setSelectedPickerColour(selectedColour !== undefined ? selectedColour : { name: null, colour: null });
    }, [selectedColour]);

    // Changes the selected Colour based on what the user selects
    const handleOnTileClick = (event: React.MouseEvent<HTMLInputElement>) => {
        const selectedColourName = event.currentTarget.getAttribute('data-name');
        const colour = colours.filter(colour => colour.name === selectedColourName)[0];

        setSelectedPickerColour(colour);

        if (onSelectedColourChanged !== undefined) {
            onSelectedColourChanged(colour);
        }
    };

    // Renders each individual colour tile
    const renderColourTile = (colour: Colour) => {
        if (colour.colour === undefined) {
            return null;
        }

        return (
            <StyledColourPickerHolderTile
                data-testid={`colour-picker-tile-${colour.name.toLowerCase()}`}
                key={colour.colour.toHex()}
                colour={colour.colour}
                onClick={handleOnTileClick}
                data-name={colour.name}
            />
        );
    };

    return (
        <StyledColourPicker
            onMouseEnter={() => setIsShowColourPickerHolder(true)}
            onMouseLeave={() => setIsShowColourPickerHolder(false)}
        >
            <StyledColourPickerSelect>
                <StyledColourPickerSelectColourPreview selectedColour={selectedPickerColour.colour!} />
                <StyledColourPickerPlaceHolder selectedColour={selectedPickerColour.name as any}>
                    {selectedPickerColour.name !== null ? selectedPickerColour.name : placeholderText}
                </StyledColourPickerPlaceHolder>
            </StyledColourPickerSelect>
            <StyledColourPickerHolder isShowColourPickerHolder={isShowColourPickerHolder}>
                {colours.map(colour => renderColourTile(colour))}
            </StyledColourPickerHolder>
        </StyledColourPicker>
    );
};

export default memo(InputColourPicker, isEqual);
