import { useEffect, useRef } from 'react';

// A custom hook to check the previous value of a prop
// Similar to prevProps
export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};
