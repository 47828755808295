import React from 'react';
import { CubedIcon } from './types';

const IconCalendarsRegular: CubedIcon = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M224 0c13.3 0 24 10.7 24 24V64H360V24c0-13.3 10.7-24 24-24s24 10.7 24 24V64h40c35.3 0 64 28.7 64 64v48 16V352c0 35.3-28.7 64-64 64H160c-35.3 0-64-28.7-64-64V192 176 128c0-35.3 28.7-64 64-64h40V24c0-13.3 10.7-24 24-24zM144 192V352c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V192H144zm-96-8V392c0 39.8 32.2 72 72 72H392c13.3 0 24 10.7 24 24s-10.7 24-24 24H120C53.7 512 0 458.3 0 392V184c0-13.3 10.7-24 24-24s24 10.7 24 24z" />
        </svg>
    );
};

export default IconCalendarsRegular;
