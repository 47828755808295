import { useAppSelector } from '../../../redux/hooks';

// Helpers
import { unknownErrorData } from '../../helpers/errors';
import { getValue } from '../../helpers/table-data';
import { getRawValueAsNumber } from '../../helpers/resource-data';
import { getUniqueYAxisValues, mapAreaSeriesData } from '../../helpers/widget-stacked-area-helpers';
import dateStringToUnix from '../../../helpers/date-string-to-unix';

// Types
import { CubedField } from '../../../types';
import {
    OptionalSeriesConfig,
    ResourceData,
    StackedAreaChartData,
    StackedAreaChartOptionalSeries,
    WidgetTheme,
} from '../../types';
import { graphColours } from '../../helpers/graph-colours';

type useResourceStackedAreaChartArgs = {
    areaSeriesMetric: CubedField;
    resourceData: ResourceData;
    dateDimension: CubedField;
    optionalSeries?: OptionalSeriesConfig[];
    seriesLimit?: number;
};

const useResourceStackedAreaChart = ({
    areaSeriesMetric,
    resourceData,
    dateDimension,
    optionalSeries,
    seriesLimit,
}: useResourceStackedAreaChartArgs): StackedAreaChartData => {
    const dates = useAppSelector(state => state.date);

    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const yAxisOptions = resourceData.request?.yAxisOptions || [];
        const stackedAreaChartData: StackedAreaChartData = {
            type: 'stackedAreaChart',
            status: 'success',
            areaTitle: (yAxisOptions && yAxisOptions.find(option => option.active)?.name) || '',
            areaSeriesMetric: areaSeriesMetric,
            areaYAxisOptions: yAxisOptions,
            areaSeries: [],
            optionalSeries: [],
        };

        // Find all the unique Y axis values
        const uniqueYAxisValues = getUniqueYAxisValues(resourceData, areaSeriesMetric);
        const yAxisValues = uniqueYAxisValues.map((value, index) => {
            return {
                name: value,
                colour: graphColours[index % graphColours.length] as WidgetTheme,
            };
        });

        const series = mapAreaSeriesData(
            yAxisValues,
            resourceData,
            areaSeriesMetric,
            yAxisOptions,
            dateDimension,
            dates,
            seriesLimit
        );

        stackedAreaChartData.areaSeries = series;

        if (optionalSeries) {
            const optionalSeriesData: StackedAreaChartOptionalSeries[] = [];

            optionalSeries.forEach(series => {
                if (series.resourceData.status === 'success') {
                    const seriesData = series.resourceData.objects.map(data => {
                        const rawValue = getRawValueAsNumber(data, series.yAxis.rawName);
                        return {
                            x: dateStringToUnix(getValue(data, dateDimension.rawName)),
                            y: rawValue,
                        };
                    });

                    optionalSeriesData.push({
                        name: series.title,
                        chartType: 'line',
                        data: seriesData,
                        colour: series.colour,
                        visible: true,
                    });
                }
            });

            stackedAreaChartData.optionalSeries = optionalSeriesData;
        }

        return stackedAreaChartData;
    }

    return unknownErrorData();
};

export default useResourceStackedAreaChart;
