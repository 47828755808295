import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';
import dateStringToUnix from '../helpers/date-string-to-unix';

export const config = {
    pageTitle: 'Carbon Footprint',
    reportDescription: '',
    dateDimension: fields.DATE_DATE,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: true,
        },
    },
    resources: [
        resources.REPORT_GRID_CARBON_PATH,
        resources.REPORT_GRID_CARBON_REGION,
        resources.REPORT_GRID_CARBON_UTM,
    ],
    ignoreProducts: true,
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'report-grid-carbon-path',
        allYMetrics: [
            fields.VISITS,
            fields.UNIQUE_PAGE_VIEWS,
            fields.PAGE_VIEWS,
            fields.PAGE_EXITS,
            fields.TOTAL_TRANSFER_B,
            fields.TOTAL_TRANSFER_GB,
            fields.TOTAL_POWER_KWH,
            fields.GRID_CARBON,
        ],
        initialYMetrics: [fields.GRID_CARBON],
        dataFormatter: function (dates, datas, dimension, metric) {
            return datas.map(data => {
                return [dateStringToUnix(data[dimension.rawName].raw_value), data[metric].raw_value];
            });
        },
    },
    table: {
        tabListItems: [
            {
                tabName: 'carbon-footprint',
                displayName: 'Carbon Footprint',
                isSelected: true,
                metrics: [
                    fields.TOTAL_TRANSFER_B,
                    fields.TOTAL_TRANSFER_GB,
                    fields.TOTAL_POWER_KWH,
                    fields.GRID_CARBON,
                    fields.PRODUCTIVE_CARBON_PERCENTAGE,
                    fields.TRANSACTIONAL_CARBON_PERCENTAGE,
                ],
            },
            {
                tabName: 'page-metrics',
                displayName: 'Page Metrics',
                isSelected: true,
                metrics: [
                    fields.VISITS,
                    fields.PAGE_VIEWS,
                    fields.UNIQUE_PAGE_VIEWS,
                    fields.PAGE_EXITS,
                    fields.GRID_CARBON,
                    fields.AVERAGE_GRID_CARBON,
                    fields.AVERAGE_GRID_CARBON_VISITS,
                ],
            },
        ],
        fieldDataOverrides: {
            referer: [
                {
                    property: 'subchannels',
                    condition: function (subchannels) {
                        return Array.isArray(subchannels) ? subchannels.length > 1 : false;
                    },
                    field: fields.SUBCHANNEL,
                },
            ],
        },
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.REFERER.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_PATH,
                        field: fields.PATH_HOST,
                    },
                ],
            },
            [fields.SUBCHANNEL.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_PATH,
                        field: fields.PATH_HOST,
                    },
                ],
            },
            [fields.PATH_HOST.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_PATH,
                        field: fields.PATH_PATH,
                    },
                ],
            },
            [fields.PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_PATH,
                        field: fields.REFERER,
                    },
                ],
            },
            [fields.UTM_SOURCE.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_UTM,
                        field: fields.UTM_MEDIUM,
                    },
                ],
            },
            [fields.UTM_MEDIUM.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_UTM,
                        field: fields.UTM_CAMPAIGN,
                    },
                ],
            },
            [fields.UTM_CAMPAIGN.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_UTM,
                        field: fields.UTM_TERM,
                    },
                ],
            },
            [fields.UTM_TERM.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_UTM,
                        field: fields.UTM_CONTENT,
                    },
                ],
            },
            [fields.MCS_CONTINENT_NAME.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_REGION,
                        field: fields.MCS_COUNTRY_NAME,
                    },
                ],
            },
            [fields.MCS_COUNTRY_NAME.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_REGION,
                        field: fields.MCS_CITY_NAME,
                    },
                ],
            },
            [fields.MCS_CITY_NAME.id]: {
                default: [
                    {
                        resource: resources.REPORT_GRID_CARBON_REGION,
                        field: fields.REFERER,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
