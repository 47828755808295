import React from 'react';
import LayoutContextProvider from '../context/layout-context';

// Types
import { CubedField } from '../../../../types';
import { SelectQuery, WidgetMenuSectionMenu, WidgetMenuType } from '../../../types';

// Hook
import useWidgetMenuSelectState from './use-widget-menu-select-state';

// Component
import WidgetMenuSectionSelect from '../components/section-menu/widget-menu-section-select';

type UseWidgetMenuSectionQuerySelectArgs = {
    query: SelectQuery;
    dropdownField: CubedField;
    includeAllOption?: boolean;
    selectedOverride?: string;
    emptyMessage?: string;
    label?: string;
    customIcon?: React.ReactNode;
    menuContentAlign?: 'start' | 'end';
    responsive?: boolean;
};

const useWidgetMenuSectionQuerySelect = ({
    query,
    dropdownField,
    includeAllOption,
    selectedOverride,
    emptyMessage,
    label,
    customIcon,
    menuContentAlign = 'end',
    responsive,
}: UseWidgetMenuSectionQuerySelectArgs): WidgetMenuSectionMenu => {
    const { dropdownOptions, selectedOption, handleChangeSelectedOption, filters, enabled } = useWidgetMenuSelectState({
        query,
        dropdownField,
        includeAllOption,
        selectedOverride,
        label,
    });

    return {
        type: WidgetMenuType.SELECT,
        request: {
            sectionFilters: filters,
            enabled: enabled,
        },
        menu: (
            <LayoutContextProvider>
                <WidgetMenuSectionSelect
                    label={label || dropdownField.displayName}
                    dropdownOptions={dropdownOptions}
                    selectedOption={selectedOption}
                    onOptionChange={handleChangeSelectedOption}
                    emptyMessage={emptyMessage || 'No options available'}
                    customIcon={customIcon}
                    menuContentAlign={menuContentAlign}
                    responsive={responsive}
                />
            </LayoutContextProvider>
        ),
        status: dropdownOptions.status,
    };
};

export default useWidgetMenuSectionQuerySelect;
