import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled, { css } from 'styled-components';

// Common
import CustomSelect from '../../components/common/custom-select';

// Component
import FilterBarMetricValueField from './filter-bar-metric-value-field';

// Types
import { FilterBarMetricFilterProps } from '../types';

// Images
import imageIconPenLight from '../../assets/images/icons/pen-light.svg';
import imageIconXmarkLarge from '../../assets/images/icons/xmark-large-regular.svg';
import imagePlusSolid from '../../assets/images/icons/circle-plus-solid.svg';
import imageCancelRed from '../../assets/images/cancel.svg';
import imagePlus from '../../assets/images/icons/plus.svg';
import { validateFilterData } from '../helpers/helper';

// Styled Components
const StyledBoldLabel = css`
    font-weight: 500;
    color: #423d3d;
    width: 100%;
    cursor: pointer;
`;

const StyledSelectedLabel = styled.label`
    ${StyledBoldLabel}
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`;

const StyledAddAndEditLabel = styled.label`
    ${StyledBoldLabel}
    margin-bottom: 0.5rem;
    cursor: pointer;
`;

const StyledSelectedFilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;
    gap: 6px;
`;

const StyledFilterSelectContainer = styled.div`
    width: 100%;
`;

const StyledSelectedFilter = styled.div`
    border: solid 1px;
    padding: 15px;
    background: #e9f0f5;

    & label {
        font-weight: 500;
        color: #423d3d;
    }

    & span {
        margin-right: 0.5rem;
    }
`;

const StyledSelectedFilterIcon = css`
    display: inline-block;
    margin-left: 15px;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
`;

const StyledEditIcon = styled.div`
    ${StyledSelectedFilterIcon}
    background-image: url(${imageIconPenLight});
`;

const StyledRemoveIcon = styled.div`
    ${StyledSelectedFilterIcon}
    background-image: url(${imageIconXmarkLarge});
`;

const StyledForm = styled.form`
    background-color: #ffffff;
    padding: 15px 0 0 0;
`;

const StyledFormSection = styled.div`
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr auto;
    grid-gap: 20px;
    margin-bottom: 15px;
    margin-top: 0.5rem;
`;

const StyledCircleCheckIcon = styled('div')<{ disabled: boolean }>`
    display: inline-block;
    width: 38px;
    background-image: url(${imagePlusSolid});
    background-size: contain;
    background-repeat: no-repeat;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

    :hover {
        transform: scale(1.1);
        transition: 0.2s ease-in-out;
    }
`;

const StyledCircleXmarkIcon = styled('div')`
    display: inline-block;
    width: 38px;
    background-image: url(${imageCancelRed});
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;

    :hover {
        transform: scale(1.1);
        transition: 0.2s ease-in-out;
    }
`;

const StyledReportFilterAddIcon = css`
    display: inline-block;
    margin-left: 8px;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
`;

const StyledReportFilterAddExpandIcon = styled.div`
    ${StyledReportFilterAddIcon}
    background-image: url(${imagePlus});
`;

const FilterBarMetricFilter = ({
    selectedMetricsFilterData,
    handleFilterSelectedMetricEditOnClick,
    handleFilterSelectedMetricRemoveOnClick,
    filterStatus,
    filterMetricsOptions,
    handleMetricsFilterMetricOnChange,
    handleMetricsFilterOperatorOnChange,
    handleMetricsFilterValueOnChange,
    handleMetricsSelectFilterOnClick,
    filterOperatorOptions,
    handleMetricsAddFilterOnClick,
    handleMetricsRemoveFilterOnClick,
    showAddMetricFilter,
}: FilterBarMetricFilterProps) => {
    return (
        <>
            {selectedMetricsFilterData?.length > 1 && (
                <StyledSelectedLabel className="row">Selected Filters</StyledSelectedLabel>
            )}
            <StyledSelectedFilterContainer>
                {selectedMetricsFilterData &&
                    selectedMetricsFilterData.map(filterData => {
                        return (
                            <React.Fragment key={filterData.id}>
                                {filterData.status === 'view' && (
                                    <StyledSelectedFilter>
                                        <label>{filterData?.metric?.label}: </label>
                                        <span>
                                            {filterData?.operator?.label} {filterData?.value}
                                        </span>
                                        <StyledEditIcon
                                            onClick={() => handleFilterSelectedMetricEditOnClick(filterData)}
                                        />
                                        <StyledRemoveIcon
                                            onClick={() => handleFilterSelectedMetricRemoveOnClick(filterData)}
                                        />
                                    </StyledSelectedFilter>
                                )}
                            </React.Fragment>
                        );
                    })}
            </StyledSelectedFilterContainer>

            <StyledFilterSelectContainer>
                <div className="col-md-10">
                    {filterStatus.isEnableMetrics &&
                        filterMetricsOptions.length > 0 &&
                        selectedMetricsFilterData &&
                        selectedMetricsFilterData.map(filterData => {
                            return (
                                <React.Fragment key={filterData.id}>
                                    {(filterData.status === 'add' || filterData.status === 'edit') && (
                                        <StyledForm>
                                            <StyledAddAndEditLabel
                                                onClick={() => !showAddMetricFilter && handleMetricsAddFilterOnClick()}
                                            >
                                                {filterData.status === 'add' ? 'Add a Filter' : 'Edit Filter'}
                                                {!showAddMetricFilter && <StyledReportFilterAddExpandIcon />}
                                            </StyledAddAndEditLabel>
                                            {showAddMetricFilter && (
                                                <StyledFormSection>
                                                    <CustomSelect
                                                        value={filterData.metric}
                                                        name="metric"
                                                        placeholder="Select a metric"
                                                        options={filterMetricsOptions}
                                                        classNamePrefix="report__filters__react-select"
                                                        onChange={(selectedData, actionMeta) =>
                                                            handleMetricsFilterMetricOnChange(selectedData!, filterData)
                                                        }
                                                    />
                                                    <CustomSelect
                                                        value={filterData.operator}
                                                        name="operator"
                                                        placeholder="Select an operator"
                                                        options={filterOperatorOptions}
                                                        classNamePrefix="report__filters__react-select"
                                                        onChange={(selectedData, actionMeta) =>
                                                            handleMetricsFilterOperatorOnChange(
                                                                selectedData!,
                                                                filterData
                                                            )
                                                        }
                                                    />

                                                    <FilterBarMetricValueField
                                                        handleMetricsFilterValueOnChange={
                                                            handleMetricsFilterValueOnChange
                                                        }
                                                        filterData={filterData}
                                                    />

                                                    <StyledCircleXmarkIcon
                                                        onClick={() => handleMetricsRemoveFilterOnClick(filterData)}
                                                    />
                                                    {Object.keys(filterData.metric).length > 0 &&
                                                        Object.keys(filterData.operator).length > 0 &&
                                                        filterData.value !== '' && (
                                                            <StyledCircleCheckIcon
                                                                disabled={
                                                                    !(
                                                                        Object.keys(filterData.metric).length > 0 &&
                                                                        Object.keys(filterData.operator).length > 0 &&
                                                                        validateFilterData(filterData)
                                                                    )
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        Object.keys(filterData.metric).length > 0 &&
                                                                        Object.keys(filterData.operator).length > 0 &&
                                                                        validateFilterData(filterData)
                                                                    )
                                                                        handleMetricsSelectFilterOnClick(filterData);
                                                                }}
                                                            />
                                                        )}
                                                </StyledFormSection>
                                            )}
                                        </StyledForm>
                                    )}
                                </React.Fragment>
                            );
                        })}
                </div>
            </StyledFilterSelectContainer>
        </>
    );
};

export default memo(FilterBarMetricFilter, isEqual);
