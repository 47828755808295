import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Config
import { CONFIG_CHANNELS, CONFIG_PATTERN } from '../../configurations/resources-config';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal, setModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { removePopup, setPopup } from '../../redux/actions/popup';

// Enums & Helpers
import { ButtonThemes } from '../../enums/button-themes';
import { channelColours, colourToInt, intToColour } from '../../helpers/colours';
import { isAlphaNumericString } from '../../helpers/validator';
import { generatePath } from '../../helpers/request-builder';

// Hooks
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import usePatchResource from '../../react-query/hooks/use-patch-resource';
import usePostResource from '../../react-query/hooks/use-post-resource';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';

// Types
import { NotificationMessageType } from '../../enums/notification-types';
import { ConfigDataSuccess } from '../../react-query/types';
import { Instance } from 'tinycolor2';

// Components
import ModalNavigation from '../../components/modal-navigation';
import WarningMessage from '../../components/warning-message';
import WidgetAccordion from '../../widgets/accordion';
import ManageFindPattern from './manage-find-pattern';
import ConfigTable from '../../components/tables/config-table/config-table';

const StyledContainer = styled.div`
    z-index: 500001;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: scroll;
    width: 100%;
    max-width: 900px;
    height: 100vh;
    background-color: ${props => props.theme.colours.lightGrey};
    float: right;
    padding: 20px;
    & h2 {
        color: ${props => props.theme.colours.offBlackLighter};
    }
`;

const StyledSubContainer = styled.div`
    margin-top: 20px;
`;

type ManageChannelsChannel = {
    id: number;
    name: string;
    colour: number;
    selected: boolean;
};

export type ChannelColour = {
    name: string;
    colour: Instance;
};

type ManageChannelsChannelToEdit = {
    id: number;
    name: string;
    colour?: ChannelColour;
};

type ManageChannelsPattern = {
    id: string;
    name: string;
    deleted: boolean;
    updated: boolean;
    selected: boolean;
};

// Layout
const Layout = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch();

    const onCloseClick = () => {
        dispatch(removeModal());
    };

    const modalNavigationButtons = [
        {
            value: 'CLOSE',
            onClick: onCloseClick,
            buttonTheme: ButtonThemes.Secondary,
        },
    ];

    return (
        <StyledContainer>
            <ModalNavigation buttons={modalNavigationButtons} />
            <h2>Manage Channels and Patterns</h2>
            {children}
        </StyledContainer>
    );
};

// Modal
const LayoutModalManageChannels = () => {
    const dispatch = useDispatch();

    // Channel States
    const [channels, setChannels] = useState<ManageChannelsChannel[]>([]);
    const [channelToEdit, setChannelToEdit] = useState<ManageChannelsChannelToEdit>();
    const [channelToAddPatternId, setChannelToAddPatternId] = useState<number>();
    const [channelNameToEditErrorMessage, setChannelNameToEditErrorMessage] = useState<string>();
    const [channelColourToEditErrorMessage, setChannelColourToEditErrorMessage] = useState<string>();
    const [channelsToDelete, setChannelsToDelete] = useState<number[]>([]);

    // Pattern States
    const [patterns, setPatterns] = useState<ManageChannelsPattern[]>([]);
    const [patternNameToAdd, setPatternNameToAdd] = useState<string>('');
    const [patternNameToAddErrorMessage, setPatternNameToAddErrorMessage] = useState<string>();
    const [enablePatternExistsQuery, setEnablePatternExistsQuery] = useState(false);
    const [patternNameToEdit, setPatternNameToEdit] = useState<string>();
    const [patternNameToEditErrorMessage, setPatternNameToEditErrorMessage] = useState<string>();
    const [enableSinglePatternExistsQuery, setEnableSinglePatternExistsQuery] = useState(false);
    const [enablePatternsToDeleteQuery, setEnablePatternsToDeleteQuery] = useState(false);

    // Accordion States
    const [editChannelAccordionOpen, setEditChannelAccordionOpen] = useState(false);
    const [editPatternsAccordionOpen, setEditPatternsAccordionOpen] = useState(false);
    const [editSinglePatternAccordionOpen, setEditSinglePatternAccordionOpen] = useState(false);
    const [testUrlAccordionOpen, setTestUrlAccordionOpen] = useState(false);

    // Table States
    const [disableTable, setDisableTable] = useState(false);

    // Queries & Mutations
    const channelQuery = useFetchResource({
        resource: CONFIG_CHANNELS,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(channel => {
                return {
                    id: channel.id,
                    name: channel.name,
                    colour: channel.colour,
                    selected: false,
                };
            });
        },
    });

    const channelPatchMutation = usePatchResource({
        resource: CONFIG_CHANNELS,
        resourceId: channelToEdit ? channelToEdit?.id.toString() : '0',
        data: channelToEdit
            ? { name: channelToEdit.name, colour: channelToEdit.colour && colourToInt(channelToEdit.colour.colour) }
            : { name: '', colour: '' },
        handleOnSuccess: () => {
            setEditChannelAccordionOpen(false);
            setChannelToEdit(undefined);
            setDisableTable(false);

            setChannels(
                channels.map(channel => {
                    return {
                        ...channel,
                        selected: false,
                    };
                })
            );

            dispatch(
                addNotification({
                    copy: 'Channel updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your channel. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const channelDeleteMutation = useDeleteResource({
        resource: CONFIG_CHANNELS,
        resourceIds: channelsToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            setEnablePatternsToDeleteQuery(true);
            setDisableTable(false);
            dispatch(
                addNotification({
                    copy: 'Channels successfully deleted.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setDisableTable(false);
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your channels. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const patternQuery = useFetchResource({
        resource: CONFIG_PATTERN,
        params: [
            { key: 'referer__id', value: channelToAddPatternId?.toString() || '0' },
            { key: 'active', value: 1 },
        ],
        enabled: editPatternsAccordionOpen,
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(pattern => {
                return {
                    id: pattern.id,
                    name: pattern.pattern,
                    deleted: false,
                    updated: false,
                    selected: false,
                };
            });
        },
    });

    const patternPostMutation = usePostResource({
        resource: CONFIG_PATTERN,
        data: {
            pattern: patternNameToAdd ? patternNameToAdd : '',
            referer: generatePath('config', 'referer', channelToAddPatternId?.toString()),
        },
        handleOnSuccess: () => {
            setPatternNameToAdd('');
            setPatternNameToAddErrorMessage(undefined);

            dispatch(
                addNotification({
                    copy: 'Pattern added successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your pattern. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const patternPatchMutation = usePatchResource({
        resource: CONFIG_PATTERN,
        resourceId: patterns.find(pattern => pattern.selected)?.id as string,
        data: {
            pattern: patternNameToEdit ? patternNameToEdit : '',
        },
        handleOnSuccess: () => {
            setPatternNameToEdit(undefined);
            setPatternNameToEditErrorMessage(undefined);
            setEditSinglePatternAccordionOpen(false);

            dispatch(
                addNotification({
                    copy: 'Pattern updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your pattern. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const patternExistsQuery = useFetchResource({
        resource: CONFIG_PATTERN,
        params: [
            { key: 'pattern__contains', value: patternNameToAdd ? patternNameToAdd : '' },
            { key: 'active', value: 1 },
            { key: 'referer__active', value: 1 },
        ],
        enabled: enablePatternExistsQuery,
    });

    const singlePatternExistsQuery = useFetchResource({
        resource: CONFIG_PATTERN,
        params: [
            { key: 'pattern__contains', value: patternNameToEdit ? patternNameToEdit : '' },
            { key: 'active', value: 1 },
            { key: 'referer__active', value: 1 },
        ],
        enabled: enableSinglePatternExistsQuery,
    });

    const patternDeleteMutation = useDeleteResource({
        resource: CONFIG_PATTERN,
        resourceIds: patterns.filter(pattern => pattern.selected).map(pattern => pattern.id),
        handleOnSuccess: () => {
            setChannelsToDelete([]);
            setEnablePatternsToDeleteQuery(false);
            dispatch(
                addNotification({
                    copy: 'Patterns successfully deleted.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your patterns. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const patternsToDeleteQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_PATTERN,
        params: [
            {
                key: 'referer__id__in',
                value: channelsToDelete && channelsToDelete.map(channel => channel).join(','),
            },
        ],
        enabled: enablePatternsToDeleteQuery,
    });

    const channelPatternsDeleteMutation = useDeleteResource({
        resource: CONFIG_PATTERN,
        resourceIds: patternsToDeleteQuery.data?.objects.map(pattern => pattern.id) as string[],
    });

    // Fetch Patterns when Edit Patterns Accordion is Open
    useEffect(() => {
        if (editPatternsAccordionOpen && patternQuery.data) {
            setPatterns(patternQuery.data as ManageChannelsPattern[]);
        }
    }, [editPatternsAccordionOpen, patternQuery.data]);

    // Fetch Channels
    useEffect(() => {
        if (channelQuery.data) {
            setChannels(channelQuery.data as ManageChannelsChannel[]);
        }
    }, [channelQuery.data]);

    // Validate New Patterns
    useEffect(() => {
        if (patternNameToAdd && patternNameToAdd.length > 0) {
            if (patternExistsQuery.data && patternExistsQuery.data.objects.length > 0) {
                setPatternNameToAddErrorMessage(
                    'This pattern already exists. Please provide a unique pattern for this channel.'
                );
            } else {
                setPatternNameToAddErrorMessage(undefined);
            }
        }
    }, [patternNameToAdd, patternExistsQuery.data]);

    // Validate Edit Patterns
    useEffect(() => {
        if (patternNameToEdit && patternNameToEdit.length > 0) {
            if (singlePatternExistsQuery.data && singlePatternExistsQuery.data.objects.length > 0) {
                setPatternNameToEditErrorMessage(
                    'This pattern already exists. Please provide a unique pattern for this channel.'
                );
            } else {
                setPatternNameToEditErrorMessage(undefined);
            }
        }
    }, [patternNameToEdit, singlePatternExistsQuery.data]);

    // Re-enable table after test accordion is closed
    useEffect(() => {
        if (!testUrlAccordionOpen) {
            setDisableTable(false);
        }
    }, [testUrlAccordionOpen]);

    // When a channel is deleted, remove the patterns associated with it
    useEffect(() => {
        if (enablePatternsToDeleteQuery && patternsToDeleteQuery.data) {
            channelPatternsDeleteMutation.mutate();
        }
    }, [enablePatternsToDeleteQuery, patternsToDeleteQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    // When the channels change, update the subchannels to delete
    useEffect(() => {
        setChannelsToDelete(channels.filter(channel => channel.selected).map(channel => channel.id));
    }, [channels]);

    // Handlers - Channels
    const handleCreateChannelClick = () => {
        dispatch(setModal('CreateChannelWizard', {}));
    };

    const handleCheckboxChange = (id: number) => {
        setChannels(
            channels.map(channel => {
                if (channel.id === id) {
                    return {
                        ...channel,
                        selected: !channel.selected,
                    };
                }
                return channel;
            })
        );
    };

    const handleEditChannelClick = (rowId: number) => {
        let channel = channels.find(channel => channel.id === rowId);

        if (channel) {
            const selectedColourHex = intToColour(channel.colour);
            let selectedColour = channelColours.find(colour => colour.colour.toHex() === selectedColourHex.toHex());

            setChannelToEdit({
                id: channel.id,
                name: channel.name,
                colour: selectedColour,
            });

            // Accordion States
            setEditChannelAccordionOpen(true);
            setEditPatternsAccordionOpen(false);
            setTestUrlAccordionOpen(false);

            // Table States
            setDisableTable(true);
        }
    };

    const channelFormIsValid = () => {
        let isValid = true;
        if (channelToEdit) {
            if (!isAlphaNumericString(channelToEdit.name) || channelToEdit.name.length === 0) {
                setChannelNameToEditErrorMessage('Please enter a valid Channel Name.');
                isValid = false;
            } else {
                setChannelNameToEditErrorMessage(undefined);
            }

            if (!channelToEdit.colour) {
                setChannelColourToEditErrorMessage('Please select a colour for the Channel.');
                isValid = false;
            } else {
                setChannelColourToEditErrorMessage(undefined);
            }
        }

        return isValid;
    };

    const handleEditChannelApplyClick = () => {
        if (!channelFormIsValid()) {
            return;
        }
        channelPatchMutation.mutate();
    };

    const handleSingleChannelDeleteClick = (rowId: number) => {
        setChannelsToDelete([rowId]);

        const handleDisardClick = () => {
            setChannelsToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setDisableTable(true);
            channelDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this channel?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkDeleteClick = () => {
        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setChannels(
                channels.map(channel => {
                    return {
                        ...channel,
                        selected: false,
                    };
                })
            );
            setDisableTable(true);
            channelDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected channels?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    // Handlers - Test Pattern
    const handleTestPatternClick = () => {
        // Accordion States
        setTestUrlAccordionOpen(true);
        setEditChannelAccordionOpen(false);
        setEditPatternsAccordionOpen(false);

        // Table States
        setDisableTable(true);
    };

    // Handlers - Patterns
    const handleAddPatternClick = () => {
        setEnablePatternExistsQuery(false);
        patternPostMutation.mutate();
    };

    const handleEditPatternClick = () => {
        setEnableSinglePatternExistsQuery(false);
        patternPatchMutation.mutate();
    };

    const handleEditChannelPatternsClick = (rowId: number) => {
        setChannelToAddPatternId(rowId);

        setEditChannelAccordionOpen(false);
        setEditPatternsAccordionOpen(true);
        setTestUrlAccordionOpen(false);

        // Table States
        setDisableTable(true);
    };

    const handlePatternSelect = (patternId: string) => {
        setPatterns(
            patterns.map(pattern => {
                if (pattern.id === patternId) {
                    return {
                        ...pattern,
                        selected: !pattern.selected,
                    };
                }
                return pattern;
            })
        );
    };

    const handlePatternEditPatternClick = () => {
        setEditSinglePatternAccordionOpen(true);
        let pattern = patterns.find(pattern => pattern.selected);

        if (pattern) {
            setPatternNameToEdit(pattern.name);
        }
    };

    const handlePatternDeletePatternClick = () => {
        const handleConfirm = () => {
            patternDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: `Remove ${patterns.filter(channel => channel.selected).length > 1 ? 'Patterns' : 'Pattern'}`,
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to remove the selected ${
                        patterns.filter(channel => channel.selected).length > 1 ? 'patterns' : 'pattern'
                    }?`,
                },
                buttons: [
                    {
                        onClick: () => handleConfirm(),
                        value: 'YES, REMOVE',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleAddPatternChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnablePatternExistsQuery(true);
        setPatternNameToAdd(event.target.value);
    };

    const handleEditPatternChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnableSinglePatternExistsQuery(true);
        setPatternNameToEdit(event.target.value);
    };

    const handleAccordionCloseClick = () => {
        // Close Accordions
        setEditChannelAccordionOpen(false);
        setEditPatternsAccordionOpen(false);
        setTestUrlAccordionOpen(false);

        // Reset Channels
        setChannels(channels.map(channel => ({ ...channel, selected: false })));
        setChannelToEdit(undefined);
        setChannelToAddPatternId(undefined);
        setChannelNameToEditErrorMessage(undefined);
        setChannelColourToEditErrorMessage(undefined);

        // Reset Patterns
        setPatternNameToAdd('');
        setPatternNameToAddErrorMessage(undefined);
        setEnablePatternExistsQuery(false);
        setPatternNameToEdit(undefined);
        setPatternNameToEditErrorMessage(undefined);
        setEnableSinglePatternExistsQuery(false);

        // Reset Table
        setDisableTable(false);
    };

    // Page Success
    if (channelQuery.status === 'success' || channelQuery.status === 'pending') {
        const patternTableRows = patterns
            ? patterns
                  .filter(pattern => !pattern.deleted)
                  .map(pattern => {
                      return {
                          columns: [
                              {
                                  copy: pattern.name,
                              },
                          ],
                          keyValue: `pattern__${pattern.id}`,
                          dataValue: pattern.name,
                          selected: pattern.selected,
                          onClick: () => handlePatternSelect(pattern.id),
                      };
                  })
            : [];

        const editChannelAccordion = channelToEdit && [
            {
                header: 'Edit Channel',
                required: false,
                open: editChannelAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Channel Name:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter the friendly name for this custom channel.',
                                inputKeyValue: 'edit-channel__name',
                                inputPlaceholder: 'Channel Name...',
                                inputValue: channelToEdit.name,
                                inputOnChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                                    setChannelToEdit({
                                        ...channelToEdit,
                                        name: event.target.value,
                                    }),
                                errorMessage: channelNameToEditErrorMessage,
                                disabled: false, //channelPatchMutation.isPending,
                            },
                            {
                                label: 'Channel Colour:',
                                type: 'colourPicker',
                                requiredField: true,
                                toolTipCopy: 'Select a colour you would like the channel to use in the Cubed System.',
                                inputKeyValue: 'edit-channel__colour',
                                inputSelectedColour: channelToEdit?.colour,
                                inputColours: channelColours,
                                onInputChange: (newColour: ChannelColour) => {
                                    setChannelToEdit({ ...channelToEdit, colour: newColour });
                                },
                                errorMessage: channelColourToEditErrorMessage,
                            },
                        ],
                        buttons: [
                            {
                                value: 'APPLY',
                                onClick: handleEditChannelApplyClick,
                                isLoading: channelPatchMutation.isPending,
                            },
                            {
                                value: 'CANCEL',
                                ButtonThemes: ButtonThemes.SecondaryLight,
                                onClick: handleAccordionCloseClick,
                            },
                        ],
                    },
                },
            },
        ];

        const editPatternsAccordion = [
            {
                header: 'Edit Patterns',
                required: false,
                open: true,
                type: 'formSimpleTable',
                config: {
                    copy: 'Add relevant patterns to identify visits for this channel. Patterns can be configured using wildcards and regex and should be unique.',
                    formConfig: {
                        fields: [
                            {
                                label: 'Add a Pattern:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy:
                                    'Enter the query parameter pattern that will be use to identify this Channel.',
                                inputKeyValue: 'edit-pattern__name',
                                inputPlaceholder: 'Add a Pattern...',
                                inputValue: patternNameToAdd,
                                inputOnChange: handleAddPatternChange,
                                errorMessage: patternNameToAddErrorMessage,
                            },
                        ],
                        buttons: [
                            {
                                value: patternExistsQuery.isFetching ? 'CHECKING...' : 'ADD',
                                onClick: handleAddPatternClick,
                                disabled:
                                    patternExistsQuery.isFetching || patternNameToAddErrorMessage || !patternNameToAdd,
                                isLoading: patternPostMutation.isPending,
                            },
                        ],
                    },
                    tableConfig: {
                        header: { columns: [{ title: 'Patterns' }] },
                        rows: patternTableRows,
                        errorMessageOverride: 'Please add a Pattern.',
                        isLoading: patternQuery.isFetching || patternPostMutation.isPending,
                        isScrollable: true,
                    },

                    optionalButtonConfig: {
                        buttons: [
                            {
                                value: 'EDIT',
                                onClick: handlePatternEditPatternClick,
                                disabled: patterns.filter(pattern => pattern.selected).length !== 1,
                            },
                            {
                                value: 'DELETE',
                                onClick: handlePatternDeletePatternClick,
                                theme: ButtonThemes.Red,
                                disabled:
                                    patternDeleteMutation.isPending ||
                                    patterns.filter(pattern => pattern.selected).length === 0,
                                loading: patternDeleteMutation.isPending,
                            },
                            {
                                value: 'CANCEL',
                                onClick: handleAccordionCloseClick,
                                theme: ButtonThemes.Secondary,
                            },
                        ],
                    },
                },
            },
        ];

        const editSinglePatternAccordion = [
            {
                header: `Edit Pattern - ${patterns.find(pattern => pattern.selected)?.name}`,
                required: false,
                open: true,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Pattern:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy:
                                    'Enter the query parameter pattern that will be use to identify this Channel.',
                                inputKeyValue: 'edit-pattern__pattern',
                                inputPlaceholder: 'Pattern...',
                                inputValue: patternNameToEdit,
                                inputOnChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleEditPatternChange(event),
                                errorMessage: patternNameToEditErrorMessage,
                            },
                        ],
                        buttons: [
                            {
                                value: 'UPDATE',
                                onClick: handleEditPatternClick,
                                isLoading: patternPatchMutation.isPending,
                                disabled: patternNameToEditErrorMessage || !patternNameToEdit,
                            },
                            {
                                value: 'CANCEL',
                                onClick: () => setEditSinglePatternAccordionOpen(false),
                                buttonTheme: ButtonThemes.Secondary,
                            },
                        ],
                    },
                },
            },
        ];

        return (
            <Layout>
                <p>Manage previously configured channels.</p>
                <ConfigTable
                    status={channelQuery.status}
                    isFetching={channelQuery.isFetching}
                    disabled={disableTable}
                    empty={channels.length === 0}
                >
                    <ConfigTable.Table maxHeight="400px">
                        <ConfigTable.Header>
                            <ConfigTable.Row key="thead">
                                <ConfigTable.CellHeader />
                                <ConfigTable.CellHeader>Channel</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader />
                            </ConfigTable.Row>
                        </ConfigTable.Header>
                        <ConfigTable.Body>
                            {channels.map(channel => {
                                return (
                                    <ConfigTable.Row key={channel.id}>
                                        <ConfigTable.CellCheckbox
                                            rowId={channel.id}
                                            checked={channel.selected}
                                            onCheckedChange={handleCheckboxChange}
                                        />
                                        <ConfigTable.Cell>{channel.name}</ConfigTable.Cell>
                                        <ConfigTable.CellActions>
                                            <ConfigTable.ActionDropdownItem
                                                rowId={channel.id}
                                                type="edit"
                                                onClick={handleEditChannelClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={channel.id}
                                                type="edit"
                                                label="Edit Patterns"
                                                onClick={handleEditChannelPatternsClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={channel.id}
                                                type="delete"
                                                onClick={handleSingleChannelDeleteClick}
                                            />
                                        </ConfigTable.CellActions>
                                    </ConfigTable.Row>
                                );
                            })}
                        </ConfigTable.Body>
                    </ConfigTable.Table>
                    <ConfigTable.ActionBar>
                        <ConfigTable.ActionBarJustifyLeft>
                            <ConfigTable.ActionButton
                                type="delete"
                                label="Delete Channels"
                                onClick={handleBulkDeleteClick}
                                isDisabled={channelsToDelete.length === 0}
                            />
                        </ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionBarJustifyRight>
                            <ConfigTable.ActionButton
                                type="primary"
                                label="Test Pattern"
                                onClick={handleTestPatternClick}
                                isDisabled={disableTable}
                            />
                            <ConfigTable.ActionButton
                                type="add"
                                label="Create New Channel"
                                onClick={handleCreateChannelClick}
                                isDisabled={disableTable}
                            />
                        </ConfigTable.ActionBarJustifyRight>
                    </ConfigTable.ActionBar>
                </ConfigTable>
                {channelToEdit && (
                    <StyledSubContainer>
                        <WidgetAccordion accordions={editChannelAccordion} />
                    </StyledSubContainer>
                )}
                {testUrlAccordionOpen && (
                    <StyledSubContainer>
                        <ManageFindPattern setAccordionOpen={setTestUrlAccordionOpen} />
                    </StyledSubContainer>
                )}
                {editPatternsAccordionOpen && !editSinglePatternAccordionOpen && (
                    <StyledSubContainer>
                        <WidgetAccordion accordions={editPatternsAccordion} />
                    </StyledSubContainer>
                )}
                {editSinglePatternAccordionOpen && (
                    <StyledSubContainer>
                        <WidgetAccordion accordions={editSinglePatternAccordion} />
                    </StyledSubContainer>
                )}
            </Layout>
        );
    }

    // Page Error
    return (
        <Layout>
            <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
        </Layout>
    );
};

export default LayoutModalManageChannels;
