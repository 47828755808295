import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';

export const config = {
    pageTitle: 'Attribution Comparison',
    dateDimension: fields.SALES_DATE_DATETIME,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: true,
            isEnableMetrics: true,
        },
    },
    resources: [
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_CHANNEL,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PATTERN,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
        resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
    ],
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'lc-vs-attribution-chart',
        allYMetrics: [
            fields.VISITS,
            fields.NEW_VISITS,
            fields.BOUNCES,
            fields.BOUNCE_RATE,
            fields.ASSISTS,
            fields.ASSISTS_RATE,
            fields.AVG_DELTA,
            fields.FM_SALES,
            fields.LC_SALES,
            fields.FIRST_CLICK_SALES,
            fields.FIRST_CLICK_REVENUE,
            fields.LC_CVR,
            fields.FM_CVR,
            fields.SALES_VARIANCE,
            fields.SALES_VARIANCE_PCT,
            fields.LC_REVENUE,
            fields.FM_REVENUE,
            fields.REVENUE_VARIANCE,
            fields.REVENUE_VARIANCE_PCT,
            fields.LC_AOV,
            fields.FM_AOV,
            fields.COST,
            fields.LC_CPA,
            fields.FM_CPA,
            fields.CPA_VAR_PCT,
            fields.LC_ROI,
            fields.FM_ROI,
            fields.ROI_VAR_PCT,
            fields.LC_ROAS,
            fields.FM_ROAS,
            fields.ROAS_VARIANCE_PCT,
        ],
        initialYMetrics: [fields.FM_SALES],
        annotations: [resources.ANNOTATIONS_GOOGLE_UPDATES],
    },
    table: {
        tabListItems: [
            {
                tabName: 'overview',
                displayName: 'Overview',
                isSelected: true,
                metrics: [
                    fields.VISITS,
                    fields.LC_SALES,
                    fields.FM_SALES,
                    fields.LC_REVENUE,
                    fields.FM_REVENUE,
                    fields.LC_AOV,
                    fields.FM_AOV,
                ],
            },
            {
                tabName: 'traffic',
                displayName: 'Traffic',
                isSelected: true,
                metrics: [
                    fields.VISITS,
                    fields.NEW_VISITS,
                    fields.ASSISTS,
                    fields.ASSISTS_RATE,
                    fields.AVG_DELTA,
                    fields.BOUNCES,
                    fields.BOUNCE_RATE,
                ],
            },
            {
                tabName: 'conversion_rates',
                displayName: 'Conversion Rates',
                metrics: [fields.VISITS, fields.LC_SALES, fields.FM_SALES, fields.LC_CVR, fields.FM_CVR],
            },
            {
                tabName: 'sales_var',
                displayName: 'Sales Variance',
                metrics: [fields.LC_SALES, fields.FM_SALES, fields.SALES_VARIANCE, fields.SALES_VARIANCE_PCT],
            },
            {
                tabName: 'rev_var',
                displayName: 'Revenue Variance',
                metrics: [fields.LC_REVENUE, fields.FM_REVENUE, fields.REVENUE_VARIANCE, fields.REVENUE_VARIANCE_PCT],
            },
            {
                tabName: 'cost',
                displayName: 'Cost Analysis',
                metrics: [
                    fields.LC_REVENUE,
                    fields.FM_REVENUE,
                    fields.COST,
                    fields.LC_CPA,
                    fields.FM_CPA,
                    fields.CPA_VAR_PCT,
                    fields.LC_ROI,
                    fields.FM_ROI,
                    fields.ROI_VAR_PCT,
                    fields.LC_ROAS,
                    fields.FM_ROAS,
                    fields.ROAS_VARIANCE_PCT,
                ],
            },
            {
                tabName: 'all',
                displayName: 'All',
                metrics: [
                    fields.VISITS,
                    fields.NEW_VISITS,
                    fields.ASSISTS,
                    fields.ASSISTS_RATE,
                    fields.AVG_DELTA,
                    fields.BOUNCES,
                    fields.BOUNCE_RATE,
                    fields.FIRST_CLICK_SALES,
                    fields.LC_SALES,
                    fields.FM_SALES,
                    fields.LC_CVR,
                    fields.FM_CVR,
                    fields.SALES_VARIANCE,
                    fields.SALES_VARIANCE_PCT,
                    fields.FIRST_CLICK_REVENUE,
                    fields.LC_REVENUE,
                    fields.FM_REVENUE,
                    fields.REVENUE_VARIANCE,
                    fields.REVENUE_VARIANCE_PCT,
                    fields.LC_AOV,
                    fields.FM_AOV,
                    fields.COST,
                    fields.LC_CPA,
                    fields.FM_CPA,
                    fields.CPA_VAR_PCT,
                    fields.LC_ROI,
                    fields.FM_ROI,
                    fields.ROI_VAR_PCT,
                    fields.LC_ROAS,
                    fields.FM_ROAS,
                    fields.ROAS_VARIANCE_PCT,
                ],
            },
        ],
        fieldDataOverrides: {
            referer: [
                {
                    property: 'subchannels',
                    condition: function (subchannels) {
                        return Array.isArray(subchannels) ? subchannels.length > 1 : false;
                    },
                    field: fields.SUBCHANNEL_NAME,
                },
            ],
        },
        fieldParentOverrides: {
            subchannel_name: fields.REFERER,
        },
        fieldChildren: {
            [fields.REFERER.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
                        field: fields.REFERRER_DOMAIN,
                    },
                ],
                PPC: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
                        field: fields.PPC_CAMPAIGN_NAME,
                    },
                ],
                SEO: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
                Direct: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
                Email: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
                        field: fields.UTM_SOURCE,
                    },
                ],
                Shopping: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_CAMPAIGN_NAME,
                    },
                ],
            },
            [fields.SUBCHANNEL_NAME.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REFERRER,
                        field: fields.REFERRER_DOMAIN,
                    },
                ],
                PPC: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
                        field: fields.PPC_CAMPAIGN_NAME,
                    },
                ],
                SEO: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
                        field: fields.LANDING_HOST,
                    },
                ],
                Direct: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
                        field: fields.LANDING_HOST,
                    },
                ],
            },
            [fields.UTM_SOURCE.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
                        field: fields.UTM_MEDIUM,
                    },
                ],
            },
            [fields.UTM_MEDIUM.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
                        field: fields.UTM_CAMPAIGN,
                    },
                ],
            },
            [fields.UTM_CAMPAIGN.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
                        field: fields.UTM_TERM,
                    },
                ],
            },
            [fields.UTM_TERM.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_UTM,
                        field: fields.UTM_CONTENT,
                    },
                ],
            },
            [fields.REFERRER_DOMAIN.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
                        field: fields.REFERRER_PATH,
                    },
                ],
            },
            [fields.LANDING_HOST.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
                        field: fields.LANDING_PATH_PATH,
                    },
                ],
            },
            [fields.PATH_LANDING_HOST.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.PATH_LANDING_PATH,
                    },
                ],
            },
            [fields.PATH_GEOGRAPHY.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.PATH_LANGUAGE,
                    },
                ],
            },
            [fields.PATH_LANGUAGE.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.PATH_CATEGORY,
                    },
                ],
            },
            [fields.PATH_CATEGORY.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.PATH_CONTENT_TYPE,
                    },
                ],
            },
            [fields.PATH_CONTENT_TYPE.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.PATH_CONTENT_LENGTH,
                    },
                ],
            },
            [fields.LANDING_PATH.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
                        field: fields.REFERRER_DOMAIN,
                    },
                ],
            },
            [fields.REFERRER_PATH.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
            },
            [fields.PPC_CAMPAIGN_NAME.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
                        field: fields.PPC_AD_GROUP_NAME,
                    },
                ],
            },
            [fields.PPC_AD_GROUP_NAME.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
                        field: fields.PPC_KEYWORD,
                    },
                ],
            },
            [fields.SHOPPING_CAMPAIGN_NAME.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_AD_GROUP_NAME,
                    },
                ],
            },
            [fields.SHOPPING_AD_GROUP_NAME.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_PRODUCT_ITEM_ID,
                    },
                ],
            },
            [fields.SHOPPING_PRODUCT_ITEM_ID.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_CATEGORY1,
                    },
                ],
            },
            [fields.SHOPPING_CATEGORY1.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_CATEGORY2,
                    },
                ],
            },
            [fields.SHOPPING_CATEGORY2.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_CATEGORY3,
                    },
                ],
            },
            [fields.SHOPPING_CATEGORY3.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_CATEGORY4,
                    },
                ],
            },
            [fields.SHOPPING_CATEGORY4.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_CATEGORY5,
                    },
                ],
            },
            [fields.SHOPPING_PRODUCT_TYPE1.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_PRODUCT_TYPE2,
                    },
                ],
            },
            [fields.SHOPPING_PRODUCT_TYPE2.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_PRODUCT_TYPE3,
                    },
                ],
            },
            [fields.SHOPPING_PRODUCT_TYPE3.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_PRODUCT_TYPE4,
                    },
                ],
            },
            [fields.SHOPPING_PRODUCT_TYPE4.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SHOPPING,
                        field: fields.SHOPPING_PRODUCT_TYPE5,
                    },
                ],
            },
            [fields.MCS_CONTINENT_NAME.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
                        field: fields.MCS_COUNTRY_NAME,
                    },
                ],
            },
            [fields.MCS_COUNTRY_NAME.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
                        field: fields.MCS_CITY_NAME,
                    },
                ],
            },
            [fields.MCS_CITY_NAME.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_REGION,
                        field: fields.REFERER,
                    },
                ],
            },
            [fields.VISIT_IN_JOURNEY.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
                        field: fields.REFERER,
                    },
                ],
            },
            [fields.REPEAT_SALE.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
                        field: fields.SALE_NUMBER,
                    },
                ],
            },
            [fields.SALE_NUMBER.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
                        field: fields.VISIT_IN_JOURNEY,
                    },
                ],
            },
            [fields.VISIT_NUMBER.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_JOURNEY,
                        field: fields.REFERER,
                    },
                ],
            },
            [fields.DEVICE.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
                        field: fields.DEVICE_FAMILY,
                    },
                ],
            },
            [fields.DEVICE_FAMILY.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
                        field: fields.OS_FAMILY,
                    },
                ],
            },
            [fields.OS_FAMILY.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_DEVICE,
                        field: fields.BROWSER_FAMILY,
                    },
                ],
            },
            [fields.MARKET_KEYWORD_COUNTRY.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.CORE_PARENT_CAT.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
                        field: fields.CORE_CHILD_CAT,
                    },
                ],
            },
            [fields.CORE_CHILD_CAT.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
                        field: fields.CORE_SUB_CAT,
                    },
                ],
            },
            [fields.CORE_SUB_CAT.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
                        field: fields.MARKET_KEYWORD_KEYWORD,
                    },
                ],
            },
            [fields.MARKET_KEYWORD_KEYWORD.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
                        field: fields.LANDING_PATH_HOST,
                    },
                ],
            },
            [fields.KEYWORD_MODIFIER.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.LOCALE,
                    },
                ],
            },
            [fields.LOCALE.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.LANDING_PATH_HOST.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
                        field: fields.LANDING_PATH_PATH,
                    },
                ],
            },
            [fields.LANDING_PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING_PATH_REFERRER,
                        field: fields.REFERRER_DOMAIN,
                    },
                ],
            },
            [fields.KEYWORD_LANDING_PATH_HOST.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
                        field: fields.KEYWORD_LANDING_PATH_PATH,
                    },
                ],
            },
            [fields.KEYWORD_LANDING_PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SEOGD_LANDING_PATH_KEYWORD,
                        field: fields.MARKET_KEYWORD_KEYWORD,
                    },
                ],
            },
            [fields.SALES_PATH_HOST.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
                        field: fields.SALES_PATH_PATH,
                    },
                ],
            },
            [fields.SALES_PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
                        field: fields.SALES_REFERRING_HOST,
                    },
                ],
            },
            [fields.SALES_REFERRING_HOST.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
                        field: fields.SALES_REFERRING_PATH,
                    },
                ],
            },
            [fields.SALES_REFERRING_PATH.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_SALES_PATH,
                        field: fields.SALES_PATH_HOST,
                    },
                ],
            },
            [fields.ADVERTISING_CHANNEL_TYPE.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_PPC_LANDING,
                        field: fields.PPC_CAMPAIGN_NAME,
                    },
                ],
            },
            [fields.LANDING_PATH_GEOGRAPHY.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_LANGUAGE,
                    },
                ],
            },
            [fields.LANDING_PATH_MARKET.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_LANGUAGE,
                    },
                ],
            },
            [fields.LANDING_PATH_MARKET.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_LANGUAGE,
                    },
                ],
            },
            [fields.LANDING_PATH_LANGUAGE.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_CATEGORY,
                    },
                ],
            },
            [fields.LANDING_PATH_CONTENT_TYPE.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_CONTENT_LENGTH,
                    },
                ],
            },
            [fields.LANDING_PATH_CATEGORY.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_CONTENT_TYPE,
                    },
                ],
            },
            [fields.LANDING_PATH_CONTENT_LENGTH.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_CATEGORY,
                    },
                ],
            },
            [fields.LANDING_PATH_TOPIC.id]: {
                default: [
                    {
                        resource: resources.ATTRIBUTION_COMPARISON_REPORT_MCS_LANDING,
                        field: fields.LANDING_PATH_CATEGORY,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
