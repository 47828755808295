import React, { useEffect } from 'react';
import Form from '../../../components/forms/form';
import { FieldValues, useFormContext } from 'react-hook-form';

const FormShowComparison = ({ onSubmit }: { onSubmit: (data: FieldValues) => void }) => {
    const { handleSubmit, watch } = useFormContext();

    // Handle form submit on dropdown change
    useEffect(() => {
        const subscription = watch(() => handleSubmit(onSubmit)());
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form.Body>
            <Form.Field>
                <Form.InputCheckbox name="showComparison" label="Show Comparison View" />
            </Form.Field>
        </Form.Body>
    );
};

export default FormShowComparison;
