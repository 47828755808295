import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { ButtonThemes } from '../../enums/button-themes';
import InputButtonLoadingSpindle from './input-button-loading-spindle';

const StyledMessageText = styled.div<{ dangerouslySetInnerHTML: any }>`
    margin-bottom: 20px;
    position: fixed;
    right: 46px;
    width: 390px;
    text-align: justify;
    margin-top: 5px;
`;

const StyledButton = styled.button<{ disabled?: boolean; buttonTheme: ButtonThemes }>`
    cursor: ${props => (props.disabled ? 'not-allowed!important' : 'pointer')};
    border: none;
    min-width: 40px;
    min-height: 36px;
    padding: 0px 16px;
    border-radius: 2px;
    font-size: 14px;
    line-height: 36px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    position: relative;
    background-color: ${props =>
        props.disabled
            ? props.theme.buttons[props.buttonTheme].backgroundColourDisabled
            : props.theme.buttons[props.buttonTheme].backgroundColour};
    color: ${props =>
        props.disabled
            ? props.theme.buttons[props.buttonTheme].colorDisabled
            : props.theme.buttons[props.buttonTheme].color};
`;

export type InputButtonProps = {
    buttonTheme?: ButtonThemes;
    value: string;
    buttonMessage?: string;
    isLoading?: boolean;
    hidden?: boolean;
    disabled?: boolean;
    onClick: () => void;
};

const InputButton = ({ buttonTheme, value, buttonMessage, isLoading, hidden, disabled, onClick }: InputButtonProps) => {
    const renderMessageText = () => {
        if (buttonMessage) {
            const html = parse(buttonMessage);
            return <StyledMessageText dangerouslySetInnerHTML={html} />;
        }
    };

    if (isLoading) {
        return (
            <StyledButton buttonTheme={buttonTheme ? buttonTheme : ButtonThemes.Primary}>
                {<InputButtonLoadingSpindle buttonTheme={buttonTheme ? buttonTheme : ButtonThemes.Primary} />}
            </StyledButton>
        );
    } else {
        return (
            <>
                {!hidden && (
                    <StyledButton
                        onClick={onClick}
                        disabled={disabled}
                        buttonTheme={buttonTheme ? buttonTheme : ButtonThemes.Primary}
                    >
                        {value}
                    </StyledButton>
                )}
                {renderMessageText()}
            </>
        );
    }
};

export default InputButton;
