import { ResourceDataObject } from '../../react-query/types';
import { CubedField } from '../../types';

export default function getMixValue(
    data: ResourceDataObject,
    mixField: CubedField,
    mixingTotalValue: string | number | undefined
) {
    let mixingValue = ((data.values[mixField.rawName].rawValue as number) / (mixingTotalValue as number)) * 100;
    mixingValue = Math.round(mixingValue * 10) / 10;
    return {
        rawValue: mixingValue,
        value: mixingValue ? `${mixingValue}%` : '0%',
    };
}
