import { v4 as uuidv4 } from 'uuid';

// Types
import { ResourceComparisonData, TotalData, TotalDataValue, TotalValue } from '../../types';

// Helper
import { mapObject } from '../../helpers/map-object';
import { calculateVariance, formatVariance } from '../../helpers/variance-helpers';
import { unknownErrorData } from '../../helpers/errors';

export type UseResourceTotalComparisonArgs = {
    resourceData: ResourceComparisonData;
};

const useResourceTotalComparison = ({ resourceData }: UseResourceTotalComparisonArgs): TotalData => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        let totalResource = {
            type: 'totalComparison',
            status: 'success',
            totals: {} as TotalDataValue,
            comparisonTotals: {} as TotalDataValue,
            varianceTotals: {} as TotalDataValue,
        };

        if (resource.status === 'success') {
            totalResource = {
                ...totalResource,
                totals: mapObject(resource.meta.totals),
            };
        }

        if (comparisonResource.status === 'success') {
            totalResource = {
                ...totalResource,
                comparisonTotals: mapObject(comparisonResource.meta.totals),
            };
        }

        if (resource.status === 'success' && comparisonResource.status === 'success') {
            let values: Record<string, TotalValue> = {};

            for (const [key, value] of Object.entries(totalResource.totals.values)) {
                const variance = calculateVariance(
                    +value.rawValue,
                    +totalResource.comparisonTotals.values[key].rawValue
                );
                values[key] = {
                    value: formatVariance(variance),
                    rawValue: variance ? variance : 'N/A',
                };
            }

            totalResource.varianceTotals = {
                __id: uuidv4(),
                values: values,
            };
        }

        return totalResource as TotalData;
    }

    return unknownErrorData();
};

export default useResourceTotalComparison;
