import React from 'react';
import styled from 'styled-components';

// Component
import DataTableBodyCell from '../cells/data-table-body-cell';

// Helper
import { assignColour } from '../../../../helpers/colours';

// Types
import { CubedField } from '../../../../types';
import { DataTableColumn, ReportConfig, DataTableSelectedRows, DataTableDataObject, DataTableData } from '../../types';

// Styles
const StyledTableBodyRow = styled.tr<{ isSelected: DataTableSelectedRows }>`
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease-in-out !important;
    position: relative;

    &:hover > td {
        background-color: ${props => props.theme.colours.hoverGrey} !important;
    }

    & td {
        background-color: ${props => props.isSelected !== null && props.theme.colours.selectionGrey};
    }
`;

// Types
type DataTableBodyRowProps = {
    loading: boolean;
    handleDimensionClick: () => void;
    dimensions: CubedField[];
    selectedRows: DataTableSelectedRows[] | [];
    columns: DataTableColumn[];
    rowId: number;
    data: DataTableDataObject;
    setOwnerState: (key: string, value: DataTableSelectedRows) => void;
    disableRowClick: (columns: CubedField[]) => {};
    config: ReportConfig;
    dataMeta: DataTableData;
    scrollPosX: number;
};

const DataTableBodyRow = (props: DataTableBodyRowProps) => {
    const {
        loading,
        handleDimensionClick,
        dimensions,
        selectedRows,
        columns,
        rowId,
        data,
        setOwnerState,
        disableRowClick,
        config,
        scrollPosX,
    } = props;

    const selectionLimit = config?.chart?.selectionLimit || 5;

    const renderBodyCells = () => {
        return columns.map(column => {
            return (
                <DataTableBodyCell
                    loading={loading}
                    handleDimensionClick={handleDimensionClick}
                    key={column.rawName}
                    column={column}
                    properties={data[column.rawName]}
                    scrollPosX={scrollPosX}
                />
            );
        });
    };

    const toggleRowSelected = () => {
        if (disableRowClick) {
            if (disableRowClick(columns)) {
                return null;
            }
        }

        let updatedRows = [...selectedRows];
        let isSelected = !!findRowInSelected();
        if (isSelected && selectedRows.length <= selectionLimit) {
            updatedRows = updatedRows.filter(row => {
                return row.rowId !== rowId;
            }, this);
        } else {
            updatedRows.push({
                ...props,
                colour: assignColour(data, dimensions, selectedRows),
            });
        }

        while (updatedRows.length > selectionLimit) {
            updatedRows.shift();
        }

        setOwnerState('selectedRows', updatedRows as unknown as DataTableSelectedRows);
    };

    const findRowInSelected = () => {
        for (let row of selectedRows) {
            if (row.rowId === rowId) {
                return row;
            }
        }

        return null;
    };

    if (selectedRows.length > selectionLimit) {
        toggleRowSelected();
    }

    return (
        <StyledTableBodyRow data-testid="table-body-row" isSelected={findRowInSelected()!} onClick={toggleRowSelected}>
            {renderBodyCells()}
        </StyledTableBodyRow>
    );
};

export default DataTableBodyRow;
