import React, { createContext } from 'react';

// React Query
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import { UseQueryResult } from '@tanstack/react-query';
import { DropdownOption } from '../../../section-dashboard/types';
import { ConfigDataSuccess } from '../../../react-query/types';

// Resources
import {
    DASHBOARD_BUILDER_ORDER_BY_DIRECTION,
    DASHBOARD_BUILDER_WIDGET_TYPE,
} from '../../config/resources-dashboard-builder';

export interface CreateAWidgetContextValues {
    visualisations: UseQueryResult<ConfigDataSuccess, Error>;
    visualisationDropdownOptions: DropdownOption[];
    orderByDirection: UseQueryResult<ConfigDataSuccess, Error>;
    orderByDirectionDropdownOptions: DropdownOption[];
}

export const CreateAWidgetContext = createContext<CreateAWidgetContextValues>({} as CreateAWidgetContextValues);

const CreateAWidgetContextProvider = ({ children }: { children: JSX.Element }) => {
    // Visualisations
    const visualisations = useFetchResource<ConfigDataSuccess>({ resource: DASHBOARD_BUILDER_WIDGET_TYPE, params: [] });
    const visualisationDropdownOptions = visualisations.data?.objects
        ? visualisations.data?.objects
              .map(vis => ({
                  label: vis.display_name,
                  value: vis.name,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
        : [];

    // Order By Direction
    const orderByDirection = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_ORDER_BY_DIRECTION,
        params: [],
    });
    const orderByDirectionDropdownOptions = orderByDirection.data?.objects
        ? orderByDirection.data?.objects.map(orderBy => {
              return {
                  label: orderBy.display_name,
                  value: orderBy.direction,
              };
          })
        : [];

    return (
        <CreateAWidgetContext.Provider
            value={{
                visualisations,
                visualisationDropdownOptions,
                orderByDirection,
                orderByDirectionDropdownOptions,
            }}
        >
            {children}
        </CreateAWidgetContext.Provider>
    );
};

export default CreateAWidgetContextProvider;
