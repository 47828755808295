import { ACTIONS } from './actions';

export const tableFilterReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.FILTER_CATEGORY_CHANGE:
            return onFilterCategoryChange(state, action.payload.category);
        case ACTIONS.FILTER_NAME_CHANGE:
            return onFilterNameChange(state, action.payload.name);
        case ACTIONS.FILTER_SUBMIT:
            return onFilterSubmit(state, action.payload.submited);
        case ACTIONS.FILTER_RESET:
            return onFilterReset(state);
        default:
            return state;
    }
};

export const tableLiveDataReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.LIVE_DATA_ENABLE:
            return onLiveDataEnable(state, action.payload.dateRange);
        case ACTIONS.LIVE_DATA_DATE_PICKER_DATE_SELECT:
            return onDatePickerDateSelect(state, action.payload.tempDateRange);
        case ACTIONS.LIVE_DATA_DATE_PICKER_APPLY:
            return onDatePickerApply(state, action.payload.dateRange);
        case ACTIONS.LIVE_DATA_DATE_PICKER_CANCEL:
            return onDatePickerCancel(state, action.payload.dateRange);
        default:
            return state;
    }
};

const onFilterCategoryChange = (state, value) => {
    return {
        ...state,
        tempSearchCategory: value,
    };
};

const onFilterNameChange = (state, value) => {
    return {
        ...state,
        tempSearchTerm: value,
    };
};

const onFilterSubmit = (state, value) => {
    return {
        ...state,
        isFilterSubmited: value,
        searchCategory: state.tempSearchCategory,
        searchTerm: state.tempSearchTerm,
    };
};

const onFilterReset = state => {
    return {
        ...state,
        tempSearchCategory: '',
        tempSearchTerm: '',
        isFilterSubmited: false,
        searchCategory: '',
        searchTerm: '',
    };
};

const onLiveDataEnable = (state, value) => {
    return {
        ...state,
        isLive: true,
        dateRange: value,
        tempDateRange: null,
    };
};

const onDatePickerCancel = (state, value) => {
    return {
        ...state,
        isLive: true,
        dateRange: value,
        tempDateRange: null,
    };
};

const onDatePickerApply = (state, value) => {
    return {
        ...state,
        isLive: false,
        dateRange: value,
    };
};

const onDatePickerDateSelect = (state, value) => {
    return {
        ...state,
        tempDateRange: value,
    };
};
