import React, { useContext, useEffect, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { CreateADashboardContext } from '../context/create-a-dashboard-context';
import { useAppSelector } from '../../../redux/hooks';
import viewportOptions from '../../config/viewport-options';
import { debounce } from 'lodash';
import Form from '../../../components/forms/form';

const FormViewport = () => {
    const { handleSubmit, watch, setValue } = useFormContext();
    const { breakpoint, setBreakpoint } = useContext(CreateADashboardContext);
    const { showSidebar } = useAppSelector(state => state.nav);

    const [currentViewportOptions, setCurrentViewportOptions] = useState(viewportOptions);

    const onSubmit = (data: FieldValues) => {
        if (data.viewportSize) setBreakpoint(data.viewportSize);
    };

    const handleUpdateOptions = () => {
        // Account for the sidebar width and layout padding in the viewport size calculation
        const windowWidth = window.innerWidth - 400 - (showSidebar ? 300 : 90);
        setCurrentViewportOptions(
            viewportOptions.map(option => {
                return {
                    ...option,
                    disabled:
                        (option.value === 'sm' && windowWidth < 600) ||
                        (option.value === 'md' && windowWidth < 905) ||
                        (option.value === 'lg' && windowWidth < 1240) ||
                        (option.value === 'xl' && windowWidth < 1440),
                };
            })
        );
    };

    // Listen for the sidebar state change and update the breakpoint options
    useEffect(() => {
        handleUpdateOptions();
    }, [showSidebar]); // eslint-disable-line react-hooks/exhaustive-deps

    // Listen to the window resize event and update the breakpoint options
    useEffect(() => {
        const handleResize = debounce(() => handleUpdateOptions(), 500);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // Set the breakpoint options on page load
    useEffect(() => {
        handleUpdateOptions();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // If the breakpoint changes, update the dropdown value
    useEffect(() => {
        setValue('viewportSize', breakpoint);
    }, [breakpoint]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle form submit on dropdown change
    useEffect(() => {
        const subscription = watch(() => handleSubmit(onSubmit)());
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form.Body direction="column">
            <Form.Field direction="row">
                <Form.InputLabel htmlFor="viewportSize" label="Viewport Size" />
                <Form.InputSelect name="viewportSize" options={currentViewportOptions}></Form.InputSelect>
            </Form.Field>
        </Form.Body>
    );
};

export default FormViewport;
