// React Dependencies
import React from 'react';
import styled from 'styled-components';
import InputCheckBox from '../inputs/input-check-box';
import Tooltip from '../tooltip';

const StyledFormField = styled.div`
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 15px;
`;

const StyledFormFieldTop = styled.div`
    margin-bottom: 5px;
`;

const StyledCheckboxLabel = styled.label`
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
`;

const StyledCheckbox = styled.div`
    cursor: pointer;
    display: inline-block;
    position: relative;
    top: -15px;
    left: 10px;
`;

export type FormCheckBoxInputProps = {
    toolTipCopy?: string;
    inputKeyValue: string;
    inputName: string;
    checked: boolean;
    inputOnChange: () => void;
    disabled?: boolean;
    label?: string;
};

const FormCheckBoxInput = ({
    toolTipCopy,
    inputKeyValue,
    inputName,
    checked,
    inputOnChange,
    disabled = false,
    label,
}: FormCheckBoxInputProps) => {
    return (
        <StyledFormField key={inputKeyValue}>
            <StyledFormFieldTop>
                <StyledCheckboxLabel>{label}</StyledCheckboxLabel>
                <StyledCheckbox>
                    <InputCheckBox name={inputName} checked={checked} onChange={inputOnChange} disabled={disabled} />
                </StyledCheckbox>
                {toolTipCopy && <Tooltip copy={toolTipCopy} />}
            </StyledFormFieldTop>
        </StyledFormField>
    );
};

export default FormCheckBoxInput;
