import React, { Component } from 'react';

import List from '../list';

class AccordionList extends Component {
    render() {
        return (
            <div className="accordion__item-content__list">
                <p className="accordion__item-content__list__copy">{this.props.config.copy}</p>
                <List
                    header={this.props.config.header}
                    rows={this.props.config.rows}
                    isLoading={this.props.isLoading}
                    errorMessageOverride={this.props.config.errorMessageOverride}
                />
            </div>
        );
    }
}

export default AccordionList;
