// React Dependencies
import React, { memo } from 'react';
import { isEqual } from 'lodash';

// Component
import CheckboxItem from '../checkbox-item';

// Types
export type InputCheckBoxProps = {
    onChange: (name: string) => void;
    name: string;
    checked: boolean;
    disabled: boolean;
};

const InputCheckBox = ({ onChange, name, checked, disabled }: InputCheckBoxProps) => {
    const handleOnClick = () => {
        // If there is a onChange callback from parent call it.
        if (onChange !== undefined) {
            onChange(name);
        }
    };

    return (
        <div onClick={handleOnClick}>
            <CheckboxItem checked={checked} isDisabled={disabled} />
        </div>
    );
};

export default memo(InputCheckBox, isEqual);
