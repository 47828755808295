import React from 'react';
import styled from 'styled-components';
import { configuredGoalsQuery } from '../../../queries';
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess } from '../../../../react-query/types';

// Components
import IconBullseyePointer from '../../../widgets/menus/icons/bullseye-pointer';
import SectionDashboardTooltip from '../../section-dashboard-tooltip';

const StyledLoading = styled.div`
    height: 19px;
    width: 100px;
    background-color: ${props => props.theme.sectionDashboard.widget.menu.skeletonColor};
    border-radius: 5px;
`;

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.sectionDashboard.section.backgroundColor};
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};
    height: 45px;
    margin-bottom: 16px;
    padding: 0px 20px;
`;

const StyledFlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 0.85rem;
    font-weight: ${props => props.theme.sectionDashboard.section.headingFontWeight};
    text-transform: uppercase;
    color: ${props => props.theme.sectionDashboard.section.headingTextColor};
`;

const StyledDefaultGoalsContainer = styled.div`
    display: flex;
`;

const StyledContainerLG = styled(StyledFlexContainer)`
    @media (max-width: ${props => props.theme.sectionDashboard.grid.breakpoints.lg}) {
        display: none;
    }
`;

const StyledContainerMD = styled(StyledFlexContainer)`
    @media (min-width: ${props => props.theme.sectionDashboard.grid.breakpoints.lg}),
        (max-width: ${props => props.theme.sectionDashboard.grid.breakpoints.md}) {
        display: none;
    }
`;

const StyledContainerSM = styled(StyledFlexContainer)`
    @media (min-width: ${props => props.theme.sectionDashboard.grid.breakpoints.md}) {
        display: none;
    }
`;

const StyledIcon = styled(IconBullseyePointer)`
    width: 15px;
    min-width: 15px;
    color: ${props => props.theme.sectionDashboard.section.iconColor};
    position: relative;
    top: 2px;
    margin-right: 10px;
`;

const StyledGoalsCount = styled.span`
    font-size: 1rem;
    font-weight: 700;
`;

const StyledDefaultGoals = styled.span`
    color: ${props => props.theme.sectionDashboard.section.bodyTextColor};
    margin-left: 5px;
`;

const StyledTooltipContainer = styled.div`
    background-color: ${props => props.theme.sectionDashboard.section.iconColor};
    padding: 1px 5px;
    border-radius: 10px;
    font-size: 0.75rem;
    margin-left: 10px;

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.section.iconColorHover};
    }
`;

const BeforeGridConfiguredGoals = () => {
    const goals = useFetchResource<ConfigDataSuccess>(configuredGoalsQuery);

    if (goals.status === 'pending') {
        return (
            <StyledContainer>
                <StyledFlexContainer>
                    <StyledLoading />
                    <StyledLoading />
                </StyledFlexContainer>
            </StyledContainer>
        );
    }

    if (goals.status === 'success') {
        const goalsCount = goals.data.objects.length;
        const defaultGoals = goals.data.objects.filter(object => object.default === true);

        const goalsCountSmall =
            goalsCount > 0 ? (
                <span>
                    <StyledIcon />
                    <StyledGoalsCount>{goalsCount}</StyledGoalsCount> goal{goalsCount > 1 && 's'}
                </span>
            ) : (
                <span>
                    <StyledIcon />
                    No configured goals
                </span>
            );

        return (
            <StyledContainer>
                {/* Dashboard Layout Size LG+ */}
                <StyledContainerLG>
                    {goalsCount > 0 ? (
                        <span>
                            <StyledIcon />
                            <StyledGoalsCount>{goalsCount}</StyledGoalsCount> goal{goalsCount > 1 && 's'} configured in
                            your account
                        </span>
                    ) : (
                        <span>
                            <StyledIcon />
                            No configured goals
                        </span>
                    )}
                    {defaultGoals.length > 0 ? (
                        defaultGoals.length === 1 ? (
                            <span>
                                Your default goal is:
                                <StyledDefaultGoals>{defaultGoals[0].name as string}</StyledDefaultGoals>
                            </span>
                        ) : (
                            <StyledDefaultGoalsContainer>
                                <span>
                                    Your default goals are:
                                    <StyledDefaultGoals>
                                        {defaultGoals
                                            .map(goal => goal.name)
                                            .slice(0, 3)
                                            .join(', ')}
                                    </StyledDefaultGoals>
                                </span>
                                <span>
                                    {defaultGoals.length > 3 ? (
                                        <StyledTooltipContainer>
                                            <SectionDashboardTooltip
                                                copy={defaultGoals
                                                    .map(goal => goal.name)
                                                    .slice(3)
                                                    .join(', ')}
                                                usePlaceholder={false}
                                                triggerColor="#000"
                                            >
                                                +{defaultGoals.length - 3} more
                                            </SectionDashboardTooltip>
                                        </StyledTooltipContainer>
                                    ) : null}
                                </span>
                            </StyledDefaultGoalsContainer>
                        )
                    ) : null}
                </StyledContainerLG>

                {/* Dashboard Layout Size MD - LG */}
                <StyledContainerMD>
                    {goalsCountSmall}
                    {defaultGoals.length > 0 ? (
                        defaultGoals.length === 1 ? (
                            <span>
                                Default: <StyledDefaultGoals>{defaultGoals[0].name as string}</StyledDefaultGoals>
                            </span>
                        ) : (
                            <StyledDefaultGoalsContainer>
                                <span>
                                    Default:
                                    <StyledDefaultGoals>
                                        {defaultGoals
                                            .map(goal => goal.name)
                                            .slice(0, 2)
                                            .join(', ')}
                                    </StyledDefaultGoals>
                                </span>
                                <span>
                                    {defaultGoals.length > 2 && (
                                        <StyledTooltipContainer>
                                            <SectionDashboardTooltip
                                                copy={defaultGoals
                                                    .map(goal => goal.name)
                                                    .slice(2)
                                                    .join(', ')}
                                                usePlaceholder={false}
                                                triggerColor="#000"
                                            >
                                                +{defaultGoals.length - 2} more
                                            </SectionDashboardTooltip>
                                        </StyledTooltipContainer>
                                    )}
                                </span>
                            </StyledDefaultGoalsContainer>
                        )
                    ) : null}
                </StyledContainerMD>

                {/* Dashboard Layout Size XS - SM */}
                <StyledContainerSM>
                    {goalsCountSmall}
                    {defaultGoals.length > 0 ? (
                        defaultGoals.length === 1 ? (
                            <span>
                                Default: <StyledDefaultGoals>{defaultGoals[0].name as string}</StyledDefaultGoals>
                            </span>
                        ) : (
                            <StyledTooltipContainer>
                                <SectionDashboardTooltip
                                    copy={defaultGoals.map(goal => goal.name).join(', ')}
                                    usePlaceholder={false}
                                    triggerColor="#000"
                                >
                                    {defaultGoals.length} Default Goals
                                </SectionDashboardTooltip>
                            </StyledTooltipContainer>
                        )
                    ) : null}
                </StyledContainerSM>
            </StyledContainer>
        );
    }

    return null;
};

export default BeforeGridConfiguredGoals;
