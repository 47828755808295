import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

// Context
import { useConfigDragAndDropTableContext } from '../context/config-drag-and-drop-table-context';

// Theme
import theme from '../../../../styled-components/theme.styled';

const StyledBody = styled.tbody`
    & tr:not(:last-child) > td {
        border-bottom: 1px solid ${props => props.theme.colours.borderGrey};
    }

    tr:has(button[data-state='open']) {
        background-color: #f9f9f9;
    }
`;

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? theme.colours.lightGrey : theme.colours.white,
});

const Body = ({ children }: { children: React.ReactNode }) => {
    const { status } = useConfigDragAndDropTableContext();

    if (status === 'success') {
        return (
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <StyledBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {children}
                        {provided.placeholder}
                    </StyledBody>
                )}
            </Droppable>
        );
    }

    return null;
};

export default Body;
