// React Dependencies
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonThemes } from '../../enums/button-themes';
import { RootState } from '../../redux/reducers/core';

// Libraries
import Axios, { AxiosRequestConfig } from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { setModal } from '../../redux/actions/modal';

// Helpers
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';

// Components
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

// Types
import { SimpleTableRow } from '../../components/tables/types';

const StyledButtonGroup = styled.div`
    display: flex;
    gap: 4px;
`;

export type Connection = {
    active: boolean;
    cubedUserId: number;
    id: string;
    resourceUri: string;
    semrushKey: string;
    email: string;
};

const LayoutModalSEMRushAPI = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
    const [editButtonDisabled, setEditButtonDisabled] = useState(true);
    const [connections, setConnections] = useState<Connection[]>([]);
    const [selectedConnection, setSelectedConnection] = useState<Connection | null>(null);
    const [modalDescription, setModalDescription] = useState<string>();

    const userGroup = useSelector((state: RootState) => state.account.group);
    const userId = useSelector((state: RootState) => state.user.id);
    const dispatch = useDispatch();

    const modalHeading = 'SEMRUsh';

    useEffect(() => {
        userGroup === 'Admin'
            ? setModalDescription(
                  `This is where you can manage your SEMRush integration with the Cubed Platform. This includes creating and removing secret keys. Please note, if no SEMRush keys are provided, we will push keywords to our Cubed SEMRush account to pull the data required. \nYou can view and delete other user's connection but you have full control to add, view, edit and delete your connection.
                  `
              )
            : setModalDescription(
                  `This is where you can manage your SEMRush Keys integrated with the Cubed Platform. You can view other user's connection but you have full control to add, view, edit and delete your connection.`
              );

        //fetch all connection on initial load
        fetchConnections();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchConnections = async () => {
        try {
            const accountConnectionUrl = generateUrl('integrations', 'semrush-connection', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
            ]);
            const accountConnectionDataResponse = await Axios.get(accountConnectionUrl, { withCredentials: true });

            const flattenedConnections = accountConnectionDataResponse.data.objects.map((connection: Connection) =>
                Object.values(connection).map(value => value)
            );
            const accountConnectionData = flattenedConnections.map(
                (connection: [number, number, number, string, string, string]) => {
                    return {
                        active: connection[0],
                        cubedUserId: connection[1],
                        id: connection[2],
                        resourceUri: connection[3],
                        semrushKey: connection[4],
                        email: '',
                    };
                }
            );

            let emailUrl: string | null;

            const emailDataPromises = accountConnectionData.map((connection: Connection) => {
                emailUrl = generateUrlDetail('config', 'lite-user-email', connection.cubedUserId.toString());
                return Axios.get(String(emailUrl), { withCredentials: true })
                    .then(response => response.data)
                    .catch(e => {
                        return {
                            email: 'Unknown',
                            id: connection.cubedUserId,
                            resource_uri: '',
                        };
                    });
            });
            const rawEmail = await Promise.all(emailDataPromises);

            let tempObject: Connection[] = [];
            tempObject = accountConnectionData.map((rawConnection: Connection) => {
                let user = rawEmail.find(user => user.id.toString() === rawConnection.cubedUserId.toString());
                return {
                    id: rawConnection.id.toString(),
                    email: user?.email,
                    cubedUserId: rawConnection.cubedUserId.toString(),
                    resourceUri: rawConnection.resourceUri,
                    semrushKey: rawConnection.semrushKey,
                };
            });

            setConnections(tempObject);
            setIsLoading(false);
        } catch (e) {
            setPageError(true);
        }
    };

    const deleteConnections = () => {
        setConnections([]);
        setSelectedConnection(null);
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);
        setIsLoading(true);
        const url: string | null = generateUrlDetail('integrations', 'semrush-connection', selectedConnection?.id);
        const semrushConnectionConfig: AxiosRequestConfig = {
            method: 'DELETE',
            url: url !== null ? url : undefined,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        Axios(semrushConnectionConfig)
            .then(() => {
                fetchConnections();
            })
            .finally(() => {
                dispatch(removePopup());
                dispatch(
                    addNotification({
                        copy: 'These changes have been successfully saved.',
                        type: 'success',
                    })
                );
            })
            .catch(() => setPageError(true));
    };

    const onDeleteClick = () => {
        dispatch(
            setPopup({
                title: 'Confirm delete?',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you want to delete this semrush key?',
                },
                buttons: [
                    {
                        onClick: deleteConnections,
                        value: 'CONFIRM DELETE',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'CANCEL',
                        style: 'secondary',
                    },
                ],
            })
        );
    };

    const onCloseClick = () => {
        dispatch(removeModal());
    };

    const onAddClick = () => {
        dispatch(setModal('AddSEMRush'));
    };

    const onEditClick = () => {
        dispatch(setModal('AddSEMRush', { config: selectedConnection }));
    };

    const onConnectionSelect = (connection: Connection) => {
        if (selectedConnection === connection) {
            setSelectedConnection(null);
            setEditButtonDisabled(true);
            setRemoveButtonDisabled(true);
        } else {
            const isCurrentUserConnection = Number(connection.cubedUserId) === Number(userId);
            const isAdmin = userGroup === 'Admin';

            setSelectedConnection(isAdmin || isCurrentUserConnection ? connection : null);
            setEditButtonDisabled(!isCurrentUserConnection);
            setRemoveButtonDisabled(!(isAdmin || isCurrentUserConnection));
        }
    };

    const RenderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'close',
                onClick: onCloseClick,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };
    const renderTable = () => {
        const errorMessageOverride = 'There are no connections assigned to this account.';
        const header = {
            columns: [
                {
                    title: 'SEMRush Key',
                },
                {
                    title: 'USER',
                },
            ],
        };

        const rows: SimpleTableRow[] = connections.map(connection => {
            const rowProperty = {
                selected: selectedConnection === connection,
            };

            return {
                onClick: () => onConnectionSelect(connection),
                key: `semrush-connection__${connection.id}`,
                dataValue: connection.id,
                rowProperty,
                columns: [
                    {
                        copy: connection.semrushKey,
                    },
                    {
                        copy: connection.email,
                    },
                ],
            };
        });
        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    };

    if (isLoading) {
        return (
            <div className="modal__side-panel__manage-domains">
                <RenderModalNavigation />
                <h2>{modalHeading}</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div className="modal__side-panels">
                <RenderModalNavigation />
                <h2>{modalHeading}</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div className="modal__side-panel__pinterest">
            <RenderModalNavigation />
            <h2>{modalHeading}</h2>
            {modalDescription?.split('\n').map((oneLineText, index) => (
                <p key={'paragraph-' + index}>{oneLineText}</p>
            ))}
            <div>
                {renderTable()}
                <InputButtonWrapper>
                    <StyledButtonGroup>
                        <InputButton value="Add SEMRush Key" onClick={onAddClick} />
                        <InputButton value="Edit SEMRush Key" disabled={editButtonDisabled} onClick={onEditClick} />
                        <InputButton
                            buttonTheme={ButtonThemes.Red}
                            value={'REMOVE'}
                            disabled={removeButtonDisabled}
                            onClick={onDeleteClick}
                        />
                    </StyledButtonGroup>
                </InputButtonWrapper>
            </div>
        </div>
    );
};

export default LayoutModalSEMRushAPI;
