/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import theme from '../../../styled-components/theme.styled';

// Fields & Resources
import * as fields from '../../../configurations/fields';
import * as resources from '../../../configurations/resources';

// Hooks
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourceTotal from '../../../section-dashboard/hooks/widget/use-resource-total';
import useResourceSparkLine from '../../../section-dashboard/hooks/widget/use-resource-spark-line';
import useWidgetMenuFooterQuerySelect from '../../../section-dashboard/widgets/menus/hooks/use-widget-menu-footer-query-select';
import useResourceBigNumber from '../../../section-dashboard/hooks/widget/big-number/use-resource-big-number';
import useResourcePieChart from '../../../section-dashboard/hooks/widget/pie/use-resource-pie-chart';
import useRequestTable from '../../../section-dashboard/hooks/request/use-request-table';
import useResourceTable from '../../../section-dashboard/hooks/widget/table/use-resource-table';
import useRequestStackedAreaChart from '../../../section-dashboard/hooks/request/use-request-stacked-area-chart';
import useResourceStackedAreaChart from '../../../section-dashboard/hooks/widget/use-resource-stacked-area-chart';
import useResourcePieChartTable from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table';
import useResourcePieChartTableLimit from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table-limit';
import useResourceCombineBigNumberRequests from '../../../section-dashboard/hooks/widget/big-number/use-resource-combine-big-number-requests';
import useResourceHistogramChart from '../../../section-dashboard/hooks/widget/use-resource-histogram-chart';
import { useDatesFormatted } from '../../../hooks/use-dates';

// Widgets
import WidgetSparkLine from '../../../section-dashboard/widgets/widget/widget-spark-line';
import WidgetBigNumber from '../../../section-dashboard/widgets/widget/widget-big-number';
import WidgetTable from '../../../section-dashboard/widgets/widget/widget-table';
import WidgetPieChart from '../../../section-dashboard/widgets/widget/widget-pie-chart';
import WidgetStackedAreaChart from '../../../section-dashboard/widgets/widget/widget-stacked-area-chart';
import WidgetMenuTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';
import WidgetMenuStackedAreaChart from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-stacked-area-chart';
import WidgetPieChartTable from '../../../section-dashboard/widgets/widget/widget-pie-chart-table';
import WidgetHistogram from '../../../section-dashboard/widgets/widget/widget-histogram-chart';

// Types
import { FilterOperator, WidgetColour, WidgetMenuObj } from '../../../section-dashboard/types';

// Queries
import { defaultGoalsDropdownQuery, goalsDropdownQuery } from '../../../section-dashboard/queries';

// Components
import BeforeGridConfiguredGoals from '../../../section-dashboard/components/sections/before-grid/before-grid-configured-goals';
import BeforeSectionContentUniverseFilter from '../../components/sections/before-section/before-section-content-universe-filter';

// Context
import { ContentDetailDashboardMenuContext } from '../../content-detail-context/content-detail-dashboard-menu-context';

export const config = {
    title: 'Content Detail Dashboard',
    description: '', // TODO description
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableComparisonDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnableButtonGroup: false,
            isEnableChannelMultiSelect: false,
            isEnableChannelSingleSelect: {
                enabled: false,
            },
            isEnablePdfExport: true,
        },
        comparisonStatus: {
            showComparisonDatePicker: false,
            redirectUrl: 'content-detail-comparison-dashboard',
        },
        datePicker: {},
    },
    context: () => {
        // Context
        const menuContext = useContext(ContentDetailDashboardMenuContext);
        return { menuContext };
    },
    layouts: {
        xs: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a'
                    'b'
                    'c'
                    'd'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'e'
                    'f'
                    'g'
                    'h'
                    'h'
                    'i'
                    'j'
                    'k'
                    'l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm'
                    `,
                },
                {
                    title: 'Traffic Overview',
                    beforeSection: <BeforeSectionContentUniverseFilter />,
                    grid: `
                    'n'
                    'o'
                    'p'
                    'q'
                    `,
                },
                {
                    title: 'Goal Overview',
                    menu: (): JSX.Element => config.context().menuContext.goalsMenu.menu,
                    grid: `
                    'r'
                    's'
                    't'
                    'u'
                    `,
                },
                {
                    title: 'Goals Overview - Normalised Values (Deltas)',
                    menu: (): JSX.Element => config.context().menuContext.goalOverviewNormalisedValuesMenu.menu,
                    grid: `
                    'v'
                    `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a b'
                    'c d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'e f'
                    'g g'
                    'h h'
                    'h h'
                    'i i'
                    'i i'
                    'j j'
                    'k k'
                    'l l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm m'
                    `,
                },
                {
                    title: 'Traffic Overview',
                    beforeSection: <BeforeSectionContentUniverseFilter />,
                    grid: `
                    'n n'
                    'o p'
                    'q q'
                    `,
                },
                {
                    title: 'Goal Overview',
                    menu: (): JSX.Element => config.context().menuContext.goalsMenu.menu,
                    grid: `
                    'r r'
                    's t'
                    'u u'
                    `,
                },
                {
                    title: 'Goals Overview - Normalised Values (Deltas)',
                    menu: (): JSX.Element => config.context().menuContext.goalOverviewNormalisedValuesMenu.menu,
                    grid: `
                    'v v'
                    `,
                },
            ],
        },
        md: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b'
                    'c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'i h h e'
                    'i h h g'
                    'j f f k'
                    'l l l l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm m m m'
                    'm m m m'
                    `,
                },
                {
                    title: 'Traffic Overview',
                    beforeSection: <BeforeSectionContentUniverseFilter />,
                    grid: `
                    'n n n n'
                    'n n n n'
                    'o o p p'
                    'q q q q'
                    'q q q q'
                    `,
                },
                {
                    title: 'Goal Overview',
                    menu: (): JSX.Element => config.context().menuContext.goalsMenu.menu,
                    grid: `
                    'r r r r'
                    'r r r r'
                    's s t t'
                    'u u u u'
                    'u u u u'
                    `,
                },
                {
                    title: 'Goals Overview - Normalised Values (Deltas)',
                    menu: (): JSX.Element => config.context().menuContext.goalOverviewNormalisedValuesMenu.menu,
                    grid: `
                    'v v v v'
                    'v v v v'
                    `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a a b b b'
                    'a a a b b b'
                    'c c c d d d'
                    'c c c d d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'i i h h e j'
                    'i i h h f k'
                    'i i h h g l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm m m m m m'
                    'm m m m m m'
                    `,
                },
                {
                    title: 'Traffic Overview',
                    beforeSection: <BeforeSectionContentUniverseFilter />,
                    grid: `
                    'n n n n n n'
                    'n n n n n n'
                    'n n n n n n'
                    'o o o p p p'
                    'q q q q q q'
                    'q q q q q q'
                    'q q q q q q'
                    `,
                },
                {
                    title: 'Goal Overview',
                    menu: (): JSX.Element => config.context().menuContext.goalsMenu.menu,
                    grid: `
                    'r r r r r r'
                    'r r r r r r'
                    'r r r r r r'
                    's s s t t t'
                    'u u u u u u'
                    'u u u u u u'
                    'u u u u u u'
                    `,
                },
                {
                    title: 'Goals Overview - Normalised Values (Deltas)',
                    menu: (): JSX.Element => config.context().menuContext.goalOverviewNormalisedValuesMenu.menu,
                    grid: `
                    'v v v v v v'
                    'v v v v v v'
                    'v v v v v v'
                    `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b c c d d'
                    'a a b b c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'i i h h h h e j'
                    'i i h h h h f k'
                    'i i h h h h g l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm m m m m m m m'
                    'm m m m m m m m'
                    'm m m m m m m m'
                    `,
                },
                {
                    title: 'Traffic Overview',
                    beforeSection: <BeforeSectionContentUniverseFilter />,
                    grid: `
                    'n n n n n n n n'
                    'n n n n n n n n'
                    'n n n n n n n n'
                    'o o o o p p p p'
                    'o o o o p p p p'
                    'q q q q q q q q'
                    'q q q q q q q q'
                    'q q q q q q q q'
                    `,
                },
                {
                    title: 'Goal Overview',
                    menu: (): JSX.Element => config.context().menuContext.goalsMenu.menu,
                    grid: `
                    'r r r r r r r r'
                    'r r r r r r r r'
                    'r r r r r r r r'
                    's s s s t t t t'
                    's s s s t t t t'
                    'u u u u u u u u'
                    'u u u u u u u u'
                    'u u u u u u u u'
                    `,
                },
                {
                    title: 'Goals Overview - Normalised Values (Deltas)',
                    menu: (): JSX.Element => config.context().menuContext.goalOverviewNormalisedValuesMenu.menu,
                    grid: `
                    'v v v v v v v v'
                    'v v v v v v v v'
                    'v v v v v v v v'
                    `,
                },
            ],
        },
    },
    components: () => {
        const { endDate } = useDatesFormatted({ resource: resources.REPORT_CONTENT_DASHBOARD_PATH });

        const {
            fieldToView,
            tierTwoMenuCombinedRequests,
            tierTwoAndGoalsMenuRequestMerge,
            goalOverviewNormalisedValuesMenu,
        } = useContext(ContentDetailDashboardMenuContext);

        /***
         Title: Overall Profile
         Widget: Big Numbers
        */
        // Sparklines
        const goalSnapshotData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                isGraph: true,
                groupBy: fields.SALES_DATE_DATE,
                graphGroupBy: [fields.SALES_DATE_DATE],
                graphMetrics: [
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
            },
        });

        const sparkTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                totals: true,
            },
        });

        const sparkTotals = useResourceTotal({
            resourceData: sparkTotalData,
        });

        // Visits Sparkline
        const visitsSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.VISITS,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Sales Sparkline
        const salesSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.FM_SALES,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Revenue Sparkline
        const revenueSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.FM_REVENUE,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Cost Sparkline
        const costSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.COST,
            dateDimension: fields.SALES_DATE_DATE,
        });

        /***
         Title: Goal Snapshot
         Widget: Big Numbers, Pie Chart, Table
        */
        const defaultGoalTotalGoalsFooterMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const defaultGoalTotalRevenueFooterMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const defaultGoalCRFooterMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalSnapshotDefaultGoalTotalsData = (menu: WidgetMenuObj) =>
            useResource({
                resource: resources.REPORT_MCS_CHANNEL,
                request: {
                    isGraph: true,
                    graphGroupBy: [fields.SALES_DATE_DATETIME],
                    graphMetrics: [fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                    ...(menu.request.filters ?? []),
                    filters: [
                        ...(menu.request.filters ?? []),
                        { field: fields.PRODUCT_DEFAULT, operator: FilterOperator.EQUALS, value: '1' },
                    ],
                },
            });

        const defaultGoalTotalGoalsBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalGoalsFooterMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        const defaultGoalTotalRevenueBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalRevenueFooterMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        const defaultGoalCRBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalCRFooterMenu),
            field: fields.FM_CVR,
            aggregation: 'avg',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Non 'Default Goal' Big Numbers
        const totalGoalsCompletedFooterMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const totalAttributedValueFooterMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const totalCVRFooterMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalSnapshotBigNumberData = (menu: WidgetMenuObj) =>
            useResource({
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
                request: {
                    isGraph: true,
                    groupBy: fields.SALES_DATE_DATE,
                    graphGroupBy: [fields.SALES_DATE_DATE],
                    graphMetrics: [
                        fields.VISITS,
                        fields.COST,
                        fields.FM_SALES,
                        fields.FM_REVENUE,
                        fields.GOAL_CVR_GOAL_SNAPSHOT,
                    ],
                    ...menu.request,
                },
            });

        const totalGoalsCompletedBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalGoalsCompletedFooterMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        const totalAttributedValueBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalAttributedValueFooterMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        const totalCVRBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalCVRFooterMenu),
            field: fields.GOAL_CVR_GOAL_SNAPSHOT,
            aggregation: 'avg',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Goals Snapshot Pie
        const goalSnapshotGoalsPieData = useResource({
            resource: resources.REPORT_MCS_CHANNEL,
            request: {
                groupBy: fields.PRODUCT,
                orderBy: [{ field: fields.FM_SALES, orderByDirection: 'desc' }],
                chartField: fields.FM_SALES,
            },
        });

        let goalSnapshotPie = useResourcePieChart({
            resourceData: goalSnapshotGoalsPieData,
            dimension: fields.PRODUCT,
        });

        // Goal Snapshot Table
        const goalSnapshotTableRequest = useRequestTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
        });

        const goalSnapshotTableData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: goalSnapshotTableRequest,
        });

        const goalSnapshotTableTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                ...goalSnapshotTableRequest,
                totals: true,
            },
        });

        const goalSnapshotTable = useResourceTable({
            resourceData: goalSnapshotTableData,
        });

        const goalSnapshotTableTotals = useResourceTotal({
            resourceData: goalSnapshotTableTotalData,
        });

        /***
         Title: Goal Completions Over Time
         Widget: Stacked Area Chart
        */
        const goalCompletionStackedAreaChartRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Count of Completions', field: fields.FM_SALES, active: true },
                { name: 'Value of Completions', field: fields.FM_REVENUE, active: false },
            ],
            groupBy: fields.PRODUCT,
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' },
            ],
        });

        const goalCompletionsData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: goalCompletionStackedAreaChartRequest,
        });

        const goalCompletionsAdditionalSeries = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
            },
        });

        const goalCompletionStackedAreaChart = useResourceStackedAreaChart({
            areaSeriesMetric: fields.PRODUCT,
            resourceData: goalCompletionsData,
            dateDimension: fields.SALES_DATE_DATE,
            optionalSeries: [
                {
                    title: 'Unique Visits',
                    yAxis: fields.VISITS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.purple,
                },
                {
                    title: 'Unique Visitors',
                    yAxis: fields.VISITORS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.emerald,
                },
            ],
        });

        /***
         Title: Traffic Overview
         Widget: Pie Chart Table
        */
        const trafficOverviewOfUniquePageviewRequest = {
            groupBy: fieldToView.field,
            orderBy: [{ field: fields.UNIQUE_PAGE_VIEWS, orderByDirection: 'desc' }],
            fields: [fieldToView.field, fields.UNIQUE_PAGE_VIEWS],
            chartField: fields.UNIQUE_PAGE_VIEWS,
            ...tierTwoMenuCombinedRequests.request,
        };

        const trafficOverviewOfUniquePageviewData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: trafficOverviewOfUniquePageviewRequest,
        });

        const trafficOverviewOfUniquePageviewTotalsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...trafficOverviewOfUniquePageviewRequest,
                totals: true,
            },
        });

        const trafficOverviewOfUniquePageviewDonutPieChartTableTotal = useResourceTotal({
            resourceData: trafficOverviewOfUniquePageviewTotalsData,
        });

        const trafficOverviewOfUniquePageviewDonutPieChartTable = useResourcePieChartTable({
            resourceData: trafficOverviewOfUniquePageviewData,
            chartDimension: fieldToView.field,
        });

        const trafficOverviewOfUniquePageviewDonutPieChartTableLimit = useResourcePieChartTableLimit({
            resourceData: trafficOverviewOfUniquePageviewDonutPieChartTable,
            totalData: trafficOverviewOfUniquePageviewDonutPieChartTableTotal,
            ratioField: fields.UNIQUE_PAGE_VIEWS,
            limit: 10,
        });

        /***
         Title: Traffic Overview
         Widget: Big Number
        */
        //  Total Count of Indexed Pages Resource
        const totalCountOfIndexedPagesTotalsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.INDEXED_PAGE_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: endDate,
                    },
                ],
                ...tierTwoMenuCombinedRequests.request,
            },
        });

        // Count of Pages Conversion Resource
        const totalCountOfPagesConversionTotalsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.PAGES_CONVERSION_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: endDate,
                    },
                ],
                ...tierTwoMenuCombinedRequests.request,
            },
        });

        // Home Page Resource
        const homepageHomeRelianceAnalysisTrafficData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_SUBDIRECTORY],
                graphMetrics: [fields.PAGES_VIEWED_COUNT],
                filters: [{ field: fields.PATH_SUBDIRECTORY, operator: FilterOperator.EQUALS, value: 'home' }],
            },
        });

        // '/' Root page Resource
        const homepageRootRelianceAnalysisTrafficData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.PAGES_VIEWED_COUNT],
                filters: [{ field: fields.PATH_PATH, operator: FilterOperator.EQUALS, value: '/' }],
            },
        });

        // Combine the Home and '/' Root page resources
        const totalCountOfHomePageViewedTotalsData = useResourceCombineBigNumberRequests({
            resourceData: [homepageHomeRelianceAnalysisTrafficData, homepageRootRelianceAnalysisTrafficData],
        });

        // All Page Resource
        const totalCountOfAllPagesTotalsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_SUBDIRECTORY],
                graphMetrics: [fields.PAGES_VIEWED_COUNT],
            },
        });

        // Count of Pages with zero Page Views
        const countOfPagesWithZeroPageViewsBigNumber = useResourceBigNumber({
            resourceData: totalCountOfIndexedPagesTotalsData,
            aggregationSumData: totalCountOfPagesConversionTotalsData,
            field: fields.INDEXED_PAGE_COUNT,
            aggregationSumField: fields.PAGES_CONVERSION_COUNT,
            aggregation: 'difference',
            displayGraph: false,
        });

        // Homepage reliance %
        const homePageReliancePercentageBigNumber = useResourceBigNumber({
            resourceData: totalCountOfHomePageViewedTotalsData,
            aggregationSumData: totalCountOfAllPagesTotalsData,
            field: fields.PAGES_VIEWED_COUNT,
            aggregationSumField: fields.PAGES_VIEWED_COUNT,
            aggregation: 'ratio',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 0,
            },
            displayGraph: false,
        });

        /***
         Title: Traffic Overview
         Widget: Table
        */
        const trafficOverviewByPageTypeRequest = useRequestTable({
            groupBy: fieldToView.field,
            fields: [
                fieldToView.field,
                fields.UNIQUE_PAGE_VIEWS,
                fields.PAGE_VIEWS,
                fields.PAGE_EXITS,
                fields.PAGE_EXIT_RATE,
                fields.LANDING_PAGE_VIEWS,
                fields.LANDING_PAGE_BOUNCES,
                fields.LANDING_PAGE_BOUNCE_RATE,
            ],
            orderBy: [{ field: fields.UNIQUE_PAGE_VIEWS, orderByDirection: 'desc' }],
        });

        const trafficOverviewByPageTypeData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: { ...trafficOverviewByPageTypeRequest, ...tierTwoMenuCombinedRequests.request },
        });

        const trafficOverviewByPageTypeTotalData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...trafficOverviewByPageTypeRequest,
                totals: true,
                ...tierTwoMenuCombinedRequests.request,
            },
        });

        const trafficOverviewByPageTypeTable = useResourceTable({
            resourceData: trafficOverviewByPageTypeData,
        });

        const trafficOverviewByPageTypeTableTotals = useResourceTotal({
            resourceData: trafficOverviewByPageTypeTotalData,
        });

        /***
         Title: Goal Overview
         Widget: Pie Chart Table
        */
        const goalOverviewOfGoalCompletionCountRequest = {
            groupBy: fieldToView.field,
            orderBy: [{ field: fields.SALES, orderByDirection: 'desc' }],
            fields: [fieldToView.field, fields.SALES],
            chartField: fields.SALES,
            ...tierTwoAndGoalsMenuRequestMerge,
        };

        const goalOverviewOfGoalCompletionCountData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: { ...goalOverviewOfGoalCompletionCountRequest },
        });

        const goalOverviewOfGoalCompletionCountTotalsData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...goalOverviewOfGoalCompletionCountRequest,
                totals: true,
            },
        });

        const goalOverviewOfGoalCompletionCountDonutPieChartTableTotal = useResourceTotal({
            resourceData: goalOverviewOfGoalCompletionCountTotalsData,
        });

        const goalOverviewOfGoalCompletionCountDonutPieChartTable = useResourcePieChartTable({
            resourceData: goalOverviewOfGoalCompletionCountData,
            chartDimension: fieldToView.field,
        });

        const goalOverviewOfGoalCompletionCountDonutPieChartTableLimit = useResourcePieChartTableLimit({
            resourceData: goalOverviewOfGoalCompletionCountDonutPieChartTable,
            totalData: goalOverviewOfGoalCompletionCountDonutPieChartTableTotal,
            ratioField: fields.SALES,
            limit: 10,
        });

        /***
         Title: Goal Overview
         Widget: Big Number
        */
        // Total Count of Indexed Pages Resource
        const totalCountOfIndexedPagesTotalsDataForGoalCompletion = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.INDEXED_PAGE_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: endDate,
                    },
                ],
                ...tierTwoAndGoalsMenuRequestMerge,
            },
        });

        // Count of Pages Conversion Resource
        const totalCountOfPagesConversionTotalsDataForGoalCompletion = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.PAGES_CONVERSION_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: endDate,
                    },
                ],
                ...tierTwoAndGoalsMenuRequestMerge,
            },
        });

        // Home Page Resource
        const homepageHomeRelianceAnalysisTrafficDataForGoalCompletion = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_SUBDIRECTORY],
                graphMetrics: [fields.PAGES_CONVERSION_COUNT],
                filters: [{ field: fields.PATH_SUBDIRECTORY, operator: FilterOperator.EQUALS, value: 'home' }],
                ...tierTwoAndGoalsMenuRequestMerge,
            },
        });

        // '/' Root page Resource
        const homepageRootRelianceAnalysisTrafficDataForGoalCompletion = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.PAGES_CONVERSION_COUNT],
                filters: [{ field: fields.PATH_PATH, operator: FilterOperator.EQUALS, value: '/' }],
                ...tierTwoAndGoalsMenuRequestMerge,
            },
        });

        // Combine the Home and '/' Root page resources
        const totalCountOfHomePageViewedTotalsDataForGoalCompletion = useResourceCombineBigNumberRequests({
            resourceData: [
                homepageHomeRelianceAnalysisTrafficDataForGoalCompletion,
                homepageRootRelianceAnalysisTrafficDataForGoalCompletion,
            ],
        });

        // All Page Resource
        const totalCountOfAllPagesTotalsDataForGoalCompletion = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH_PERFORMANCE,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_SUBDIRECTORY],
                graphMetrics: [fields.PAGES_CONVERSION_COUNT],
            },
        });

        // Count of Pages with zero goal completions
        const countOfPagesWithZeroGoalCompletionsBigNumber = useResourceBigNumber({
            resourceData: totalCountOfIndexedPagesTotalsDataForGoalCompletion,
            aggregationSumData: totalCountOfPagesConversionTotalsDataForGoalCompletion,
            field: fields.INDEXED_PAGE_COUNT,
            aggregationSumField: fields.PAGES_CONVERSION_COUNT,
            aggregation: 'difference',
            displayGraph: false,
        });

        // Homepage goal completion %
        const homePageGoalCompletionsPercentageBigNumber = useResourceBigNumber({
            resourceData: totalCountOfHomePageViewedTotalsDataForGoalCompletion,
            aggregationSumData: totalCountOfAllPagesTotalsDataForGoalCompletion,
            field: fields.PAGES_CONVERSION_COUNT,
            aggregationSumField: fields.PAGES_CONVERSION_COUNT,
            aggregation: 'ratio',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 0,
            },
            displayGraph: false,
        });

        /***
         Title: Goal Overview
         Widget: Table
        */
        const goalOverviewByPageTypeRequest = useRequestTable({
            groupBy: fieldToView.field,
            fields: [
                fieldToView.field,
                fields.UNIQUE_PAGE_VIEWS,
                fields.PAGE_VIEWS,
                fields.PAGE_EXITS,
                fields.PAGE_EXIT_RATE,
                fields.LANDING_PAGE_VIEWS,
                fields.LANDING_PAGE_BOUNCES,
                fields.LANDING_PAGE_BOUNCE_RATE,
                fields.SALES,
                fields.REVENUE,
                fields.ASSISTS,
                fields.ASSISTS_PAGE_VIEWS_RATE,
                fields.CONVERSIONS,
                fields.CONVERSIONS_CVR,
                fields.CONVERSION_VALUE,
            ],
            orderBy: [{ field: fields.UNIQUE_PAGE_VIEWS, orderByDirection: 'desc' }],
        });

        const goalOverviewByPageTypeData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: { ...goalOverviewByPageTypeRequest, ...tierTwoAndGoalsMenuRequestMerge },
        });

        const goalOverviewByPageTypeTotalData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...goalOverviewByPageTypeRequest,
                totals: true,
                ...tierTwoAndGoalsMenuRequestMerge,
            },
        });

        const goalOverviewByPageTypeTable = useResourceTable({
            resourceData: goalOverviewByPageTypeData,
        });

        const goalOverviewByPageTypeTableTotals = useResourceTotal({
            resourceData: goalOverviewByPageTypeTotalData,
        });

        /***
         Title: Goals Overview: Normalised Values (Deltas)
         Widget: Histogram Chart
        */
        const goalOverviewNormalizedValueRequest = {
            groupBy: fields.SALES_DATE_DATE,
            orderBy: [{ field: fields.ATTRIBUTED_VALUE, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.SALES_DATE_DATE],
            chartField: fields.SALES_DATE_DATE,
            ...goalOverviewNormalisedValuesMenu.request,
        };

        const goalOverviewNormalizedValueResourceData = useResource({
            resource: resources.REPORT_PAGE_ATTRIBUTION_VALUE,
            request: { ...goalOverviewNormalizedValueRequest },
        });

        const goalOverviewNormalizedValueHistogramData = useResourceHistogramChart({
            resourceData: goalOverviewNormalizedValueResourceData,
            category: fields.PRODUCT,
            metric: fields.ATTRIBUTED_VALUE,
        });

        return {
            a: (
                <WidgetSparkLine
                    title="Visits by Day"
                    data={visitsSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Teal}
                />
            ),
            b: (
                <WidgetSparkLine
                    title="Sales by Day"
                    data={salesSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Orange}
                />
            ),
            c: (
                <WidgetSparkLine
                    title="Revenue by Day"
                    data={revenueSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Green}
                />
            ),
            d: (
                <WidgetSparkLine
                    title="Cost by Day"
                    data={costSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Rose}
                />
            ),
            e: (
                <WidgetBigNumber
                    title="Default Goal Goals Completed"
                    data={defaultGoalTotalGoalsBigNumber}
                    menu={defaultGoalTotalGoalsFooterMenu.menu}
                />
            ),
            f: (
                <WidgetBigNumber
                    title="Default Goal Attributed Value"
                    data={defaultGoalTotalRevenueBigNumber}
                    menu={defaultGoalTotalRevenueFooterMenu.menu}
                />
            ),
            g: (
                <WidgetBigNumber
                    title="Default Goal CR%"
                    data={defaultGoalCRBigNumber}
                    menu={defaultGoalCRFooterMenu.menu}
                />
            ),
            h: (
                <WidgetTable
                    title="Goals Snapshot"
                    data={goalSnapshotTable}
                    totalData={goalSnapshotTableTotals}
                    menu={<WidgetMenuTable data={goalSnapshotTable} />}
                />
            ),
            i: <WidgetPieChart title={'Goals Completed'} data={goalSnapshotPie} display="donut" />,
            j: (
                <WidgetBigNumber
                    title="Total Goals Completed"
                    data={totalGoalsCompletedBigNumber}
                    menu={totalGoalsCompletedFooterMenu.menu}
                />
            ),
            k: (
                <WidgetBigNumber
                    title="Total Attributed Value"
                    data={totalAttributedValueBigNumber}
                    menu={totalAttributedValueFooterMenu.menu}
                />
            ),
            l: <WidgetBigNumber title="All Goals CR%" data={totalCVRBigNumber} menu={totalCVRFooterMenu.menu} />,
            m: (
                <WidgetStackedAreaChart
                    title="Goal Completions Over Time"
                    data={goalCompletionStackedAreaChart}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={goalCompletionStackedAreaChart}
                            request={goalCompletionStackedAreaChartRequest}
                        />
                    }
                />
            ),
            n: (
                <WidgetPieChartTable
                    title="Traffic Overview"
                    data={trafficOverviewOfUniquePageviewDonutPieChartTableLimit}
                    totalData={trafficOverviewOfUniquePageviewDonutPieChartTableTotal}
                    href=""
                    display="donut"
                />
            ),
            o: (
                <WidgetBigNumber
                    title="Count of Pages with Zero Page Views"
                    data={countOfPagesWithZeroPageViewsBigNumber}
                />
            ),
            p: <WidgetBigNumber title="Homepage Reliance %" data={homePageReliancePercentageBigNumber} />,
            q: (
                <WidgetTable
                    title="Traffic Overview"
                    data={trafficOverviewByPageTypeTable}
                    totalData={trafficOverviewByPageTypeTableTotals}
                    href=""
                    menu={<WidgetMenuTable data={trafficOverviewByPageTypeTable} />}
                />
            ),
            r: (
                <WidgetPieChartTable
                    title="Goal Overview"
                    data={goalOverviewOfGoalCompletionCountDonutPieChartTableLimit}
                    totalData={goalOverviewOfGoalCompletionCountDonutPieChartTableTotal}
                    href=""
                    display="donut"
                />
            ),
            s: (
                <WidgetBigNumber
                    title="Count of Pages with Zero Goal Completions"
                    data={countOfPagesWithZeroGoalCompletionsBigNumber}
                />
            ),
            t: <WidgetBigNumber title="Homepage Goal Completion %" data={homePageGoalCompletionsPercentageBigNumber} />,
            u: (
                <WidgetTable
                    title="Goal Overview"
                    data={goalOverviewByPageTypeTable}
                    totalData={goalOverviewByPageTypeTableTotals}
                    href=""
                    menu={<WidgetMenuTable data={goalOverviewByPageTypeTable} />}
                />
            ),
            v: (
                <WidgetHistogram
                    title="Goals Overview - Normalised Values (Deltas)"
                    data={goalOverviewNormalizedValueHistogramData}
                    href=""
                />
            ),
        };
    },
};
