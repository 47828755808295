// Helpers
import { unknownErrorData } from '../../../helpers/errors';

// Types
import { PieData } from '../../../types';
import { graphColours } from '../../../helpers/graph-colours';

type UsePieDataSetSeriesColourArgs = {
    resourceData: PieData;
    name: string;
    colorCode: number;
};

const usePieDataSetSeriesColour = ({ resourceData, name, colorCode }: UsePieDataSetSeriesColourArgs): PieData => {
    if (resourceData?.status === 'loading' || resourceData?.status === 'empty' || resourceData?.status === 'error') {
        return resourceData;
    }

    if (resourceData?.status === 'success') {
        const pieCharData = resourceData.series[0].data.map(data => {
            return {
                ...data,
                color: data.name === name ? graphColours[colorCode].solidGraphColour : data.color,
            };
        });

        return {
            ...resourceData,
            series: [
                {
                    ...resourceData.series[0],
                    data: pieCharData,
                },
            ],
        };
    }

    return unknownErrorData();
};

export default usePieDataSetSeriesColour;
