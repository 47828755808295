// config for network charts: https://www.highcharts.com/docs/chart-and-series-types/network-graph
export const networkChartConfig = {
    options: {
        plotOptions: {
            networkgraph: {
                keys: ['from', 'to', 'lift'],
                layoutAlgorithm: {
                    enableSimulation: false,
                    linkLength: 150,
                    integration: 'verlet',
                },
                marker: {
                    enabled: true,
                    radius: 4,
                },
            },
        },
        title: {
            text: null,
        },
        chart: {
            type: 'networkgraph',
            style: {
                fontFamily: 'Work Sans',
            },
            animation: false,
            margin: 20,
            events: {
                redraw: function (e) {
                    if (e.target.series) {
                        e.target.series.forEach(series => {
                            series.data.forEach(dataItem => {
                                dataItem.toNode.color = '#8dd44f';
                                dataItem.fromNode.color = '#4da5ed';
                            });
                        });
                    }
                },
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        seriesConfig: {
            dataLabels: {
                enabled: true,
                linkFormat: '',
                allowOverlap: false,
                padding: 10,
                style: {
                    width: 100,
                    textOverflow: 'ellipsis',
                },
            },
        },
    },
};
