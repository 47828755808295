import React from 'react';
import styled from 'styled-components';

const StyledListItemCell = styled.td<{ customWidth?: string }>`
    width: ${props => props.customWidth || 'auto'};
    padding: 16px;
    font-size: 0.9rem;
    color: ${props => props.theme.dashboardBuilder.library.libraryList.listItemTextColor};
`;

const LibraryListCell = ({ customWidth, children }: { customWidth?: string; children?: React.ReactNode }) => {
    return <StyledListItemCell customWidth={customWidth}>{children}</StyledListItemCell>;
};

export default LibraryListCell;
