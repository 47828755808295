import React from 'react';
import styled from 'styled-components';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

const StyledDialogAction = styled(AlertDialog.Action)`
    border: 0;
    outline: 0;
    background-color: ${props => props.theme.buttons.primary.backgroundColour};
    border-radius: 2px;
    line-height: 32px;
    padding: 0px 12px;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: ${props => props.theme.buttons.primary.fontWeight};
    color: ${props => props.theme.buttons.primary.color};
`;

const DialogAction = ({ onClick, children }: { onClick?: () => void; children: React.ReactNode }) => {
    return <StyledDialogAction onClick={onClick}>{children}</StyledDialogAction>;
};

export default DialogAction;
