import React from 'react';
import validators from '../../../components/forms/validation/validators';

// Types
import { WidgetColour } from '../../../section-dashboard/types';

// Components
import Form from '../../../components/forms/form';

const CreateAWidgetFormTextBox = () => {
    // Context

    const colourOptions = Object.keys(WidgetColour).filter(v => isNaN(Number(v)));
    const colourDropdownOptions = colourOptions.map(colour => {
        return {
            label: colour,
            value: colour,
        };
    });

    const textOptions = [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' },
    ];

    return (
        <>
            <Form.Section>
                <Form.SectionTitle>Display Settings</Form.SectionTitle>
                <Form.Field>
                    <Form.InputLabel htmlFor="copy" label="Copy" />
                    <Form.InputTextArea name="copy" />
                </Form.Field>
                <Form.Field>
                    <Form.InputLabel htmlFor="buttonCopy" label="Button Copy" />
                    <Form.InputText name="buttonCopy" validators={[validators.maxLength(50)]} />
                </Form.Field>
                <Form.Field>
                    <Form.InputLabel htmlFor="buttonHref" label="Button Link " />
                    <Form.InputText name="buttonHref" validators={[validators.maxLength(200), validators.isURL]} />
                </Form.Field>
            </Form.Section>
            <Form.Section>
                <Form.SectionTitle>Appearance</Form.SectionTitle>
                <Form.Field>
                    <Form.InputLabel htmlFor="widgetColor" label="Background Colour" />
                    <Form.InputSelect
                        name="widgetColor"
                        options={colourDropdownOptions}
                        placeholder="Please select a colour"
                    />
                </Form.Field>
                <Form.Field>
                    <Form.InputLabel htmlFor="textJustify" label="Text Justify" />
                    <Form.InputSelect name="textJustify" options={textOptions} placeholder="Default" />
                </Form.Field>
                <Form.Field>
                    <Form.InputLabel htmlFor="fontSize" label="Font Size (e.g. 16px, 1.5rem)" />
                    <Form.InputText name="fontSize" validators={[validators.maxLength(10)]} />
                </Form.Field>
            </Form.Section>
        </>
    );
};

export default CreateAWidgetFormTextBox;
