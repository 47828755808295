import React from 'react';
import { CubedIcon } from './types';

const IconErrorTriangle: CubedIcon = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 512 512">
            <polygon
                fill="none"
                stroke="#d2004a"
                strokeMiterlimit="10"
                strokeWidth="20px"
                points="256 57.48 21 464.52 491 464.52 256 57.48"
            />
            <path
                fill="#d2004a"
                d="M289.11,376.67a31.26,31.26,0,1,1-31.26-31.26A31.29,31.29,0,0,1,289.11,376.67Zm-58.9-158.9,5.32,106.28a9.38,9.38,0,0,0,9.36,8.91h25.92a9.38,9.38,0,0,0,9.37-8.91l5.31-106.28a9.37,9.37,0,0,0-9.36-9.85H239.58A9.39,9.39,0,0,0,230.21,217.77Z"
            />
        </svg>
    );
};

export default IconErrorTriangle;
