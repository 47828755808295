import React from 'react';
import styled, { useTheme } from 'styled-components';
import { pieChartConfig } from '../../configurations/widget-pie-chart-config';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Helpers
import { formatNumber } from '../../helpers/format-number';

// Components
import WidgetBase from '../base/widget-base';
import WidgetPieChartBigNumber from '../big-numbers/widget-pie-chart-big-number';

// Types
import { PieData } from '../../types';

const StyledContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
`;

const StyledTitleContainer = styled.div<{ height: number }>`
    text-align: center;
    font-size: ${props => (props.height > 1 ? '0.9rem ' : '0.8rem')};
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    padding: ${props => (props.height > 1 ? '10px 0px' : '3px 0px')};
    color: ${props => props.theme.sectionDashboard.widget.pieTableComparison.titleColor};
`;

export type WidgetPieChartProps = {
    title: string;
    data: PieData;
    href?: string;
    display: 'pie' | 'donut' | 'semidonut';
    menu?: React.ReactNode;
};

const WidgetPieChart = ({ title, data, href, display, menu }: WidgetPieChartProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const series = data.series;

    if (width === 1 || height === 1) {
        const numberOfBoxes = Math.max(width, height);
        const firstSeries = series[0];
        const dataToDisplay = firstSeries.data.slice(0, numberOfBoxes);
        const firstSeriesTotal = firstSeries.total;
        const direction = width > height ? 'horizontal' : 'vertical';

        return (
            <WidgetBase title={title} href={href} menu={menu}>
                {data?.request?.chartMetrics ? (
                    <WidgetPieChartBigNumber
                        data={dataToDisplay}
                        direction={direction}
                        total={firstSeriesTotal}
                        chartLabel={data?.request?.chartField?.displayName}
                    />
                ) : (
                    <WidgetPieChartBigNumber data={dataToDisplay} direction={direction} total={firstSeriesTotal} />
                )}
            </WidgetBase>
        );
    }

    const chartOptions = pieChartConfig(theme);

    const renderChartLabel = () => {
        const displayName = data?.request?.chartField?.displayName;
        return displayName && <StyledTitleContainer height={height}>{displayName}</StyledTitleContainer>;
    };

    const chartConfig = {
        ...chartOptions,
        plotOptions: {
            ...chartOptions.plotOptions,
            pie: {
                ...chartOptions.plotOptions.pie,
                startAngle: display === 'semidonut' ? -90 : 0,
                endAngle: display === 'semidonut' ? 90 : 360,
                center:
                    display === 'semidonut'
                        ? width > 3
                            ? ['50%', '70%']
                            : height > 2
                            ? ['50%', '60%']
                            : ['50%', '65%']
                        : ['50%', '50%'],
            },
        },
        legend: {
            ...chartOptions.legend,
            itemMarginTop: height > 2 ? 5 : 1,
            itemMarginBottom: height > 2 ? 5 : 1,
            itemStyle: {
                ...chartOptions.legend.itemStyle,
                width: width > 5 ? '300px' : '70px',
                fontSize: width > 4 ? '14px' : '12px',
                fontWeight: '500',
            },
            symbolPadding: width > 4 ? 15 : 5,
            labelFormatter: function (this: any) {
                return `${this.name} - <b>${formatNumber(this.value, 1)}</b>`;
            },
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b> - {point.value}',
        },
        // Map to Highcharts format
        series: series.map(series => {
            return {
                name: series.name,
                data: series.data.map(data => {
                    return {
                        name: data.name,
                        value: data.value,
                        color: data.color,
                        y: (data.value / series.total) * 100,
                    };
                }),
                innerSize: display === 'donut' || display === 'semidonut' ? '50%' : 0,
            };
        }),
    };

    return (
        <WidgetBase title={title} href={href} menu={menu}>
            <StyledContainer>
                <CommonHighcharts
                    highcharts={Highcharts}
                    options={{ ...chartConfig }}
                    containerProps={{ style: { height: '100%', width: '100%' } }}
                />
                {data?.request?.chartMetrics && renderChartLabel()}
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetPieChart;
