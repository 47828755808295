import React from 'react';
import { useConfigTableContext } from '../../../context/config-table-context';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
`;

const ActionBarJustifyLeft = ({ children }: { children: React.ReactNode }) => {
    const { status } = useConfigTableContext();

    if (status) {
        return <StyledContainer>{children}</StyledContainer>;
    }
    return null;
};

export default ActionBarJustifyLeft;
