import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';
import IconCircleDot from '../../../../components/icons/circle-dot';
import IconCircle from '../../../../components/icons/circle';
import { WidgetMenuRadioOption } from '../../../types';

export const StyledItemIndicator = styled(DropdownMenu.ItemIndicator)`
    height: 18px;
    width: 18px;
`;

const BaseIconStyles = css`
    width: 18px;
    height: auto;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
    border-radius: 50%;
`;

export const StyledIconCircle = styled(IconCircle)`
    ${BaseIconStyles}
    background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
`;

export const StyledIconCircleDot = styled(IconCircleDot)`
    ${BaseIconStyles}
    background-color: ${props => props.theme.sectionDashboard.widget.menu.radio.iconColor};
`;

const StyledLabel = styled.div`
    position: relative;
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.fontWeight};
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
`;

export const StyledRadioItem = styled(DropdownMenu.RadioItem)`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;

    &:hover,
    &[data-highlighted] {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
    }

    &:hover,
    :focus ${StyledIconCircleDot} {
        color: ${props => props.theme.sectionDashboard.widget.menu.radio.iconHover};
        fill: ${props => props.theme.sectionDashboard.widget.menu.radio.iconHover};
    }

    &:hover,
    :focus ${StyledLabel} {
        color: ${props => props.theme.sectionDashboard.widget.menu.textColorHover};
    }

    &[data-state='checked'] ${StyledIconCircle} {
        display: none;
    }
`;

export type WidgetMenuRadioProps = {
    options: WidgetMenuRadioOption[];
    value: string | undefined;
    onValueChange: ((value: string) => void) | undefined;
};

const WidgetMenuRadio = ({ options, value, onValueChange }: WidgetMenuRadioProps) => {
    const handleSelect = (event: Event): void => {
        event.preventDefault();
    };

    return (
        <DropdownMenu.RadioGroup value={value} onValueChange={onValueChange}>
            {options.map(option => {
                return (
                    <StyledRadioItem value={option.value} onSelect={handleSelect} key={option.value}>
                        <StyledIconCircle />
                        <StyledItemIndicator>
                            <StyledIconCircleDot />
                        </StyledItemIndicator>
                        <StyledLabel>{option.label}</StyledLabel>
                    </StyledRadioItem>
                );
            })}
        </DropdownMenu.RadioGroup>
    );
};

export default WidgetMenuRadio;
