import React from 'react';
import { useSelector } from 'react-redux';
import NavGroupAccordion from './nav-group-accordion';

const NavDropdownGroup = ({ dropdownGroups, sidebarVisible, selectedItem, setSelectedItem, hasSiblingMenuItems }) => {
    const { account } = useSelector(state => state);

    return (
        sidebarVisible && (
            <div>
                <ul data-testid="nav-section-subgroups">
                    {dropdownGroups
                        .filter(group => !group.subGroupExcludedAccountTypes?.includes(account.group))
                        .map(group => {
                            return (
                                <NavGroupAccordion
                                    group={group}
                                    selectedItem={selectedItem}
                                    setSelectedItem={setSelectedItem}
                                    key={group.displayName}
                                    hasSiblingMenuItems={hasSiblingMenuItems}
                                />
                            );
                        })}
                </ul>
            </div>
        )
    );
};

export default NavDropdownGroup;
