import React from 'react';
import styled, { css } from 'styled-components';

import { ComparisonTableData, PieTableComparisonData, PieTableData, TableData } from '../../../types';
import { defaultRequestLimit } from '../../../configurations/default-request-limit';

import IconChevronLeftThin from '../../../../components/icons/chevron-left-thin';
import IconChevronRightThin from '../../../../components/icons/chevron-right-thin';
import IconChevronsLeft from '../../../../components/icons/chevrons-left';
import IconChevronsRight from '../../../../components/icons/chevrons-right';

export enum WidgetMenuPaginationDisplay {
    DROPDOWN = 'dropdown',
    FOOTER = 'footer',
}

const StyledContainer = styled.div<{ display: WidgetMenuPaginationDisplay; $loading?: boolean }>`
    display: flex;
    justify-content: space-between;
    gap: ${props => (props.$loading ? '20px' : '20px')};
    align-items: center;
    padding: ${props => (props.display === WidgetMenuPaginationDisplay.DROPDOWN ? '10px 20px' : '0px 15px')};
    color: ${props => props.theme.sectionDashboard.widget.menu.pagination.textColor};
`;

const StyledChevronContainer = styled.div<{ $loading?: boolean }>`
    display: flex;
    align-items: center;
    gap: ${props => (props.$loading ? '5px' : '10px')};
`;

const StyledChevron = css`
    height: 17px;
    width: 10px;
    position: relative;
    top: 2px;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
`;

const StyledLeftChevron = styled(IconChevronLeftThin)`
    ${StyledChevron}
`;

const StyledLeftChevrons = styled(IconChevronsLeft)`
    ${StyledChevron}
    width: 15px;
`;

const StyledRightChevron = styled(IconChevronRightThin)`
    ${StyledChevron}
`;

const StyledRightChevrons = styled(IconChevronsRight)`
    ${StyledChevron}
    width: 15px;
`;

const StyledButton = css`
    height: 100%;
    border: none;
    background-color: unset;
`;

const StyledButtonLeft = styled.button<{ disabled?: boolean; display: WidgetMenuPaginationDisplay }>`
    ${StyledButton}
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')} !important;

    & ${StyledLeftChevron}, ${StyledLeftChevrons} {
        color: ${props => props.disabled && props.theme.sectionDashboard.widget.menu.separatorColor};
        top: ${props => props.display === WidgetMenuPaginationDisplay.FOOTER && '0px'};
    }

    &:hover ${StyledLeftChevron}, &:hover ${StyledLeftChevrons} {
        color: ${props => !props.disabled && props.theme.colours.black};
    }
`;

const StyledButtonRight = styled.button<{ disabled?: boolean; display: WidgetMenuPaginationDisplay }>`
    ${StyledButton}
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')} !important;

    & ${StyledRightChevron}, ${StyledRightChevrons} {
        color: ${props => props.disabled && props.theme.sectionDashboard.widget.menu.separatorColor};
        top: ${props => props.display === WidgetMenuPaginationDisplay.FOOTER && '0px'};
    }

    &:hover ${StyledRightChevron}, &:hover ${StyledRightChevrons} {
        color: ${props => !props.disabled && props.theme.sectionDashboard.widget.menu.pagination.iconHover};
    }
`;

const StyledPageInfo = styled.p<{ display: WidgetMenuPaginationDisplay }>`
    margin: 0;
    font-weight: ${props =>
        props.display === WidgetMenuPaginationDisplay.FOOTER
            ? props.theme.sectionDashboard.widget.menu.pagination.footerFontWeight
            : props.theme.sectionDashboard.widget.menu.pagination.fontWeight};
    white-space: nowrap;
`;

const StyledIconSkeleton = styled.div`
    padding: 10px;
    background-color: ${props => props.theme.sectionDashboard.widget.menu.skeletonColor};
    border-radius: 50%;
`;

const StyledTextSkeleton = styled.div`
    padding: 10px;
    width: 70px;
    background-color: ${props => props.theme.sectionDashboard.widget.menu.skeletonColor};
    border-radius: 6px;
`;

export type WidgetMenuPaginationProps = {
    data: TableData | ComparisonTableData | PieTableData | PieTableComparisonData;
    display: WidgetMenuPaginationDisplay;
};

const WidgetMenuPagination = ({ data, display }: WidgetMenuPaginationProps) => {
    if (
        !data.request?.increaseOffset ||
        !data.request?.decreaseOffset ||
        !data.request?.jumpToFirst ||
        !data.request?.jumpToLast
    ) {
        return null;
    }

    if (data.status === 'loading' && display === WidgetMenuPaginationDisplay.FOOTER) {
        return (
            <StyledContainer display={display} $loading={true}>
                <StyledChevronContainer $loading={true}>
                    <StyledIconSkeleton />
                    <StyledIconSkeleton />
                </StyledChevronContainer>
                <StyledTextSkeleton />
                <StyledChevronContainer $loading={true}>
                    <StyledIconSkeleton />
                    <StyledIconSkeleton />
                </StyledChevronContainer>
            </StyledContainer>
        );
    }

    if (data.status === 'success') {
        const offset = data.request.rowsPerPage || defaultRequestLimit;
        const totalPages = Math.ceil(data.totalRows / offset);
        const currentPage = data.request.offset ? data.request.offset / offset + 1 : 1;

        return (
            <StyledContainer display={display}>
                <StyledChevronContainer>
                    <StyledButtonLeft onClick={data.request.jumpToFirst} disabled={currentPage === 1} display={display}>
                        <StyledLeftChevrons />
                    </StyledButtonLeft>
                    <StyledButtonLeft
                        onClick={data.request.decreaseOffset}
                        disabled={currentPage === 1}
                        display={display}
                    >
                        <StyledLeftChevron />
                    </StyledButtonLeft>
                </StyledChevronContainer>

                <StyledPageInfo display={display}>{`Page ${currentPage} of ${totalPages}`}</StyledPageInfo>

                <StyledChevronContainer>
                    <StyledButtonRight
                        onClick={data.request.increaseOffset}
                        disabled={currentPage === totalPages}
                        display={display}
                    >
                        <StyledRightChevron />
                    </StyledButtonRight>
                    <StyledButtonRight
                        onClick={() => data?.request?.jumpToLast && data.request.jumpToLast(totalPages)}
                        disabled={currentPage === totalPages}
                        display={display}
                    >
                        <StyledRightChevrons />
                    </StyledButtonRight>
                </StyledChevronContainer>
            </StyledContainer>
        );
    }

    return null;
};

export default WidgetMenuPagination;
