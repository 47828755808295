import { ResourceData, ViewData } from '../types';
import { RequestStatus } from '../types';

type GetMultiRequestStatusArgs = {
    requiredRequests?: ResourceData[] | ViewData[];
    requests: ResourceData[] | ViewData[];
};

export const getMultiRequestStatus = ({ requiredRequests, requests }: GetMultiRequestStatusArgs): RequestStatus => {
    try {
        const requiredStatuses = requiredRequests ? requiredRequests.map(request => request.status) : null;
        const statuses = requests.map(request => request.status);

        if (requiredStatuses) {
            if (requiredStatuses.includes(RequestStatus.LOADING)) return RequestStatus.LOADING;
            if (requiredStatuses.includes(RequestStatus.ERROR)) return RequestStatus.ERROR;
            if (requiredStatuses.includes(RequestStatus.EMPTY)) return RequestStatus.EMPTY;
        }

        if (statuses.includes(RequestStatus.LOADING)) return RequestStatus.LOADING;
        if (statuses.includes(RequestStatus.ERROR)) return RequestStatus.ERROR;
        if (statuses.every(status => status === RequestStatus.EMPTY)) return RequestStatus.EMPTY;

        return RequestStatus.SUCCESS;
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error.message);
        } else {
            throw new Error('An unknown error occurred while getting multi request status');
        }
    }
};
