import React from 'react';
import Select, { GroupBase } from 'react-select';
import styled from 'styled-components';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

import Tooltip from '../tooltip';

// Types

type FormFieldWrapperProps = {
    isAccordionForm: boolean;
    children: JSX.Element | JSX.Element[];
};

export type DropdownOptions = {
    label: string;
    value: string | number;
    dataType?: string;
    dataTypeId?: number;
    relatedTable?: null;
    relatedTableDisplayName?: null;
};

type DropdownWithSearchProps = {
    inputKeyValue?: string;
    dropdownItems: DropdownOptions[] | LoadOptions<DropdownOptions, GroupBase<DropdownOptions>, unknown>;
    placeholder: string;
    selected?: DropdownOptions | DropdownOptions[];
    onSelectedItemChange: (selectedOption: any) => void; // Note: any is used here for compatibility with older components
    hidden?: boolean;
    isSearchable?: boolean;
    isClearable?: boolean;
    isMulti?: boolean;
    label?: string;
    requiredField: boolean;
    toolTipCopy?: string;
    name?: string;
    isAccordionForm: boolean;
    errorMessage?: string;
    disabled?: boolean;
    displaySingleOption?: boolean;
};

// Styles
const StyledLabelWrapper = styled.div`
    display: block;
    width: 100%;
`;
const StyledLabel = styled.div`
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
    margin: 0;
    margin-bottom: 5px;
`;

const StyledRequiredField = styled.div`
    display: inline-block;
    color: ${props => props.theme.colours.danger};
    margin-left: 5px;
    font-size: 1.1em;
`;

const StyledToolTip = styled.div`
    float: right;
`;

const StyledFieldWrapper = styled.div`
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 18px;
`;

const StyledErrorMessage = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colours.danger};
    margin-bottom: 1em;
    margin-top: 10px;
`;

const CustomStyles = {
    control: (provided: {}) => ({
        ...provided,
        border: 0,
        borderBottom: '2px solid #1E1C1C',
        borderRadius: 0,
        width: '100%',
        backgroundColor: '#E9F0F5',
        minHeight: '39px',
        fontSize: '0.9rem',
        marginBottom: '0',
    }),
    menu: (provided: {}) => ({
        ...provided,
        zIndex: 600000,
    }),
};

const FormFieldWrapper = ({ isAccordionForm, children }: FormFieldWrapperProps) => {
    return (
        <>
            {isAccordionForm !== undefined && isAccordionForm ? (
                <StyledFieldWrapper>{children}</StyledFieldWrapper>
            ) : (
                <div>{children}</div>
            )}
        </>
    );
};

const customTheme = (theme: any) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: '#1E1C1C',
    },
});

const DropdownWithSearch = ({
    inputKeyValue,
    dropdownItems,
    placeholder,
    selected,
    onSelectedItemChange,
    hidden,
    isSearchable,
    isClearable,
    isMulti,
    label,
    requiredField,
    toolTipCopy,
    name,
    isAccordionForm,
    errorMessage,
    disabled,
    displaySingleOption = false,
}: DropdownWithSearchProps) => {
    if (dropdownItems && Array.isArray(dropdownItems) && dropdownItems.length === 1 && !displaySingleOption) {
        selected = dropdownItems[0];
    }

    const renderErrorMessage = () => {
        if (errorMessage === undefined || errorMessage === '') {
            return null;
        }

        return <StyledErrorMessage> {errorMessage} </StyledErrorMessage>;
    };

    return (
        <>
            {!hidden && (
                <FormFieldWrapper isAccordionForm={isAccordionForm} key={inputKeyValue}>
                    <StyledLabelWrapper>
                        {label && <StyledLabel>{label}</StyledLabel>}
                        {requiredField && <StyledRequiredField>*</StyledRequiredField>}
                        {toolTipCopy && (
                            <StyledToolTip>
                                <Tooltip copy={toolTipCopy} />
                            </StyledToolTip>
                        )}
                    </StyledLabelWrapper>

                    {typeof dropdownItems === 'function' ? (
                        <AsyncPaginate
                            value={selected || null}
                            loadOptions={dropdownItems}
                            placeholder={placeholder}
                            isMulti={isMulti}
                            isClearable={isClearable}
                            onChange={onSelectedItemChange}
                            styles={CustomStyles}
                            theme={customTheme}
                        />
                    ) : (
                        <>
                            {dropdownItems && Array.isArray(dropdownItems) && (
                                <Select
                                    value={selected || null}
                                    styles={CustomStyles}
                                    options={dropdownItems}
                                    placeholder={placeholder}
                                    isClearable={isClearable}
                                    isSearchable={isSearchable}
                                    isMulti={isMulti}
                                    name={name}
                                    onChange={onSelectedItemChange}
                                    theme={customTheme}
                                    isDisabled={disabled}
                                />
                            )}
                        </>
                    )}

                    {renderErrorMessage()!}
                </FormFieldWrapper>
            )}
        </>
    );
};

export default DropdownWithSearch;
