import { useEffect, useState } from 'react';

import { isEqual } from 'lodash';

import { CubedField } from '../../../types';
import { PieTableComparisonData, TotalData } from '../../types';

type ComparisonCharts = {
    chartField: CubedField;
    data: PieTableComparisonData;
    totalData: TotalData;
};
type MultipleCharts = {
    comparisonCharts: ComparisonCharts[];
    chartField: CubedField;
    chartMetrics: CubedField[];
    setChartField?: (chartField: CubedField) => void;
};

const useRequestPieComparisonMultiple = (request: MultipleCharts) => {
    const [pieRequest, setPieRequest] = useState<MultipleCharts>(request);

    const actions = () => {
        return {
            setChartField: (chartField: CubedField) => {
                setPieRequest(prevPieRequest => ({ ...prevPieRequest, chartField }));
            },
        };
    };

    useEffect(() => {
        setPieRequest({ ...pieRequest, ...actions() });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const selectedRequest = request.comparisonCharts.find(
            chart => chart.chartField === pieRequest.chartField
        )?.data;

        const existingRequest = pieRequest.comparisonCharts.find(
            chart => chart.chartField === pieRequest.chartField
        )?.data;

        if (!selectedRequest || !existingRequest) {
            setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
            return;
        }
        if (!selectedRequest.request || !existingRequest.request) {
            setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
            return;
        }
        if ('comparisonSeries' in selectedRequest && 'comparisonSeries' in existingRequest) {
            if (!isEqual(selectedRequest.comparisonSeries, existingRequest.comparisonSeries)) {
                setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
                return;
            }
        }
        if ('series' in selectedRequest && 'series' in existingRequest) {
            if (!isEqual(selectedRequest.comparisonSeries, existingRequest.comparisonSeries)) {
                setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
                return;
            }
        }
        if ('comparisonRatio' in selectedRequest && 'comparisonRatio' in existingRequest) {
            if (!isEqual(selectedRequest.comparisonRatio, existingRequest.comparisonRatio)) {
                setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
                return;
            }
        }
        if ('ratio' in selectedRequest && 'ratio' in existingRequest) {
            if (!isEqual(selectedRequest.ratio, existingRequest.ratio)) {
                setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
                return;
            }
        }
        if (selectedRequest.request.filters) {
            if (!isEqual(selectedRequest.request.filters, existingRequest.request.filters)) {
                setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
                return;
            }
        }

        if (selectedRequest.request.sectionFilters) {
            if (!isEqual(selectedRequest.request.sectionFilters, existingRequest.request.sectionFilters)) {
                setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
                return;
            }
        }

        if (selectedRequest.request.pageFilters) {
            if (!isEqual(selectedRequest.request.pageFilters, existingRequest.request.pageFilters)) {
                setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
                return;
            }
        }

        if (selectedRequest.request.showLoading !== existingRequest.request.showLoading) {
            setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
            return;
        }

        if (selectedRequest.request.showEmpty !== existingRequest.request.showEmpty) {
            setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
            return;
        }
        if (selectedRequest.status !== existingRequest.status) {
            setPieRequest({ ...request, chartField: pieRequest.chartField, ...actions() });
            return;
        }
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

    return pieRequest;
};

export default useRequestPieComparisonMultiple;
