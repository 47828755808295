import Papa from 'papaparse';
import { FileData } from '../types';

const validateContainsData = (file: File) => {
    return new Promise<boolean>(resolve => {
        const reader = new FileReader();
        let fileData: FileData[];

        reader.readAsText(file);
        reader.onload = fileEvent => {
            const csvData: string = fileEvent.target?.result as string;

            Papa.parse(csvData, {
                skipEmptyLines: true,
                header: true,
                preview: 1,
                complete: result => {
                    fileData = result.data as FileData[];
                },
            });

            const result = fileData.length > 0;
            resolve(result);
        };
    });
};

export default validateContainsData;
