import React from 'react';
import styled, { useTheme } from 'styled-components';

// Configuration
import { funnelChartConfig } from '../../configurations/widget-funnel-chart-config';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Widgets
import WidgetBase from '../base/widget-base';
import FunnelModule from 'highcharts/modules/funnel';
import WidgetFunnelChartBigNumber from '../big-numbers/widget-funnel-chart-big-number';

// Types
import { FunnelChartData } from '../../types';

const StyledContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px 30px;
`;

export type WidgetFunnelChartProps = {
    title: string;
    data: FunnelChartData;
    href?: string;
    menu?: React.ReactElement;
};

FunnelModule(Highcharts);

const WidgetFunnelChart = ({ title, data, href, menu }: WidgetFunnelChartProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const { series, total, name } = data;

    if (width === 1 || height === 1) {
        const numberOfBoxes = Math.max(width, height);
        const dataToDisplay = series.slice(0, numberOfBoxes);
        const firstSeriesTotal = total;
        const direction = width > height ? 'horizontal' : 'vertical';

        return (
            <WidgetBase title={title} href={href} menu={menu}>
                <WidgetFunnelChartBigNumber data={dataToDisplay} direction={direction} total={firstSeriesTotal} />
            </WidgetBase>
        );
    }

    const chartConfig = {
        ...funnelChartConfig(theme),
        series: [
            {
                name: name,
                data: series,
            },
        ],
    };

    return (
        <WidgetBase title={title} href={href} menu={menu}>
            <StyledContainer>
                <CommonHighcharts
                    highcharts={Highcharts}
                    options={{ ...chartConfig }}
                    containerProps={{ style: { height: '100%', width: '100%' } }}
                />
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetFunnelChart;
