import React, { Component } from 'react';
import Request from './../helpers/request';
import { connect } from 'react-redux';
import { setDescription, setTitle } from '../redux/actions/page-meta';
import tinycolor from 'tinycolor2';
import { intToColour } from './../helpers/colours';
import TableGraphSkeleton from './../components/table-graph-skeleton';
import WidgetFunnel from './../widgets/funnel';
import WidgetEventSequencer from './../widgets/event-sequencer';
import { FilterBarContext } from '../filter-bar/context/filter-bar-context';

class ReportEventFunnels extends Component {
    static contextType = FilterBarContext;

    constructor(props) {
        super(props);
        this.funnelRequests = [];
        this.channelRequests = [];
        this.eventRequests = [];
        this.state = {
            eventsReceived: false,
            channelsReceived: false,
            dataReceived: false,
            allChannels: [],
            events: {},
            channels: {},
            path: [],
            oldPath: [],
            channelFunnels: [],
            exactPaths: false,
            oldExactPaths: false,
            oldStartDate: this.props.startDate,
            oldEndDate: this.props.endDate,
            showVisitor: false,

            //path: [35,10,35,10,35]
            // path: [35,10]
            viewsTypeOptions: [
                {
                    name: 'Visit',
                    value: 'events-funnel-visit',
                    active: true,
                },
                {
                    name: 'Visitor',
                    value: 'events-funnel-visitor',
                    active: false,
                },
            ],
        };
        this.setNewPath = this.setNewPath.bind(this);
        this.fetchFunnelData = this.fetchFunnelData.bind(this);
        this.buildPaths = this.buildPaths.bind(this);
        this.buildRequests = this.buildRequests.bind(this);
        this.fetchChannels = this.fetchChannels.bind(this);
        this.fetchEvents = this.fetchEvents.bind(this);
        this.formatEventNames = this.formatEventNames.bind(this);
        this.formatChannelData = this.formatChannelData.bind(this);
        this.clearChannelData = this.clearChannelData.bind(this);
        this.hasPathChanged = this.hasPathChanged.bind(this);
        this.hasDateChanged = this.hasDateChanged.bind(this);
        this.generateChannelFunnels = this.generateChannelFunnels.bind(this);
        this.toggleExactPaths = this.toggleExactPaths.bind(this);
        this.renderNoneSelected = this.renderNoneSelected.bind(this);
    }
    setNewPath(path) {
        this.setState({
            path: path,
        });
    }

    toggleExactPaths(state) {
        this.setState(prevState => ({ exactPaths: !prevState.exactPaths }));
    }
    hasDateChanged(oldStartDate, startDate, oldEndDate, endDate) {
        if (!oldStartDate.isSame(startDate) || oldEndDate.isSame(!endDate)) {
            return true;
        } else {
            return false;
        }
    }
    hasPathChanged(oldPath, newPath) {
        let hasChanged = false;
        if (this.state.exactPaths !== this.state.oldExactPaths) {
            return true;
        }
        for (let item in oldPath) {
            if (oldPath[item] !== newPath[item]) {
                hasChanged = true;
            }
        }
        for (let item in newPath) {
            if (oldPath[item] !== newPath[item]) {
                hasChanged = true;
            }
        }
        return hasChanged;
    }
    formatEventNames() {
        const eventNames = [];
        for (let i in this.state.path) {
            eventNames.push({ id: this.state.events[i].id, name: this.state.events[i].display_name });
        }
        return eventNames;
    }
    fetchEvents() {
        const fetchPromise = new Request();
        for (let promise of this.eventRequests) {
            promise.cancelRequest('aborted');
        }
        fetchPromise.get('config', 'dash-event', [{ key: 'active', value: 1 }]).then(response => {
            const formattedEvents = {};
            for (let event of response.data.objects) {
                formattedEvents[event.id] = event;
            }
            this.setState({
                events: formattedEvents,
                eventsReceived: true,
            });
        });
    }
    fetchChannels() {
        const fetchPromise = new Request();
        for (let promise of this.channelRequests) {
            promise.cancelRequest('aborted');
        }
        fetchPromise.get('config', 'dash-referer', [{ key: 'active', value: 1 }]).then(response => {
            const formattedChannels = {};
            for (let channel of response.data.objects) {
                formattedChannels[channel.id] = channel;
            }
            this.setState({
                channels: formattedChannels,
                channelsReceived: true,
            });
        });
    }

    buildPaths() {
        const paths = [];
        let eventString = '';
        for (let event of this.state.path) {
            eventString += `(${event.toString()})${this.state.exactPaths ? ',' : '%'}`;
            paths.push('%' + eventString.substring(0, eventString.length - 1) + '%');
        }
        return paths;
    }

    buildRequests() {
        const paths = this.buildPaths();
        const requests = [];
        const requestType = this.state.viewsTypeOptions.filter(item => {
            return item.active === true ? item : '';
        });
        for (let path of paths) {
            requests.push({
                resourceGroup: 'reports',
                resourceName: `${requestType[0].value}/${this.props.account.id}`,
                params: [
                    { key: 'startdate', value: this.props.startDate.toISOString(true) },
                    { key: 'enddate', value: this.props.endDate.toISOString(true) },
                    { key: 'path', value: path },
                ],
            });
        }
        return requests;
    }

    clearChannelData(channelList) {
        const channels = { ...channelList };
        for (let channel in channels) {
            channels[channel].data = [];
        }
        return channels;
    }
    formatChannelData(responses) {
        const channels = this.clearChannelData(this.state.channels);
        for (let response in responses) {
            const event = this.state.events[this.state.path[response]];
            const data = responses[response].data;
            for (let channelId in channels) {
                let exists = false;
                for (let item of data) {
                    if (parseInt(item.referer_id, 10) === parseInt(channelId, 10)) {
                        channels[channelId].data.push({ id: event.id, name: event.name, count: item.count || 0 });
                        exists = true;
                    }
                }
                if (!exists) channels[channelId].data.push({ id: event.id, name: event.name, count: 0 });
            }
        }
        this.generateChannelFunnels(channels);
        return channels;
    }
    renderNoneSelected() {
        return (
            <div style={{ padding: '18px' }}>
                <p style={{ textAlign: 'center' }}>
                    You currently have no Event(s) selected. Please add the events required for your funnel, using the
                    button above.
                </p>
                <p style={{ textAlign: 'center' }}>
                    You can select a single event or a combination of any number of Events to understand repeat
                    occurrences.
                </p>
            </div>
        );
    }
    formatAllChannelsData(responses) {
        const allChannels = [];
        for (let response in responses) {
            const event = this.state.events[this.state.path[response]];
            let sum = 0;
            for (let channel of responses[response].data) {
                sum += channel.count;
            }
            allChannels.push({ id: event.id, name: event.display_name, count: sum });
        }
        return allChannels;
    }
    fetchFunnelData() {
        const fetchPromise = new Request();
        for (let promise of this.funnelRequests) {
            promise.cancelRequest('aborted');
        }
        this.setState({
            isLoading: true,
            hasNoData: false,
            oldPath: this.state.path,
            oldExactPaths: this.state.exactPaths,
            oldStartDate: this.props.startDate,
            oldEndDate: this.props.endDate,
        });
        fetchPromise.all(this.buildRequests()).then(responses => {
            this.formatChannelData(responses);
            this.setState({
                allChannels: this.formatAllChannelsData(responses),
                channels: this.formatChannelData(responses),
                isLoading: false,
                hasNoData: false,
                dataReceived: true,
            });
        });
    }
    componentDidMount() {
        this.props.setTitle('Event Funnels');
        this.props.setDescription('');

        // Hide the default report productSelect and datePicker filter as custom one has been implemented
        // Filter bar configurations
        this.context.setFilterStatus({
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnableButtonGroup: true,
        });

        this.context.setButtonGroupOptions(this.state.viewsTypeOptions);
        this.context.setFilterMetricsOptions([]); // Reset metrics
        this.context.setDatePickerConfig({}); // Reset datepicker

        this.fetchChannels();
        this.fetchEvents();
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.viewsTypeOptions !== this.context.buttonGroupOptions ||
            this.props.startDate !== prevProps.startDate ||
            this.props.endDate !== prevProps.endDate
        ) {
            let showViewType = !this.state.showVisitor;
            this.setState(
                {
                    showVisitor: showViewType,
                    viewsTypeOptions: this.context.buttonGroupOptions,
                },
                () => {
                    this.buildRequests();
                    this.fetchFunnelData();
                }
            );
        }
    }
    generateChannelFunnels(channelDict) {
        let channels = { ...channelDict };
        let channelFunnels = [];
        for (let channel in channels) {
            if (channels[channel].data) {
                if (channels[channel].data.length > 0 && channels[channel].data[0].count > 0) {
                    channelFunnels.push(
                        <WidgetFunnel
                            key={channel}
                            isChannel={true}
                            max={channels[channel].data[0].count}
                            data={channels[channel].data}
                            colour={tinycolor(intToColour(channels[channel].colour))}
                            showNames={false}
                            title={channels[channel].name}
                        />
                    );
                }
            }
        }
        this.setState({ channelFunnels });
    }

    render() {
        if (!this.state.channelsReceived || !this.state.eventsReceived) {
            return <TableGraphSkeleton />;
        }
        const channelFunnels = this.state.channelFunnels;
        return (
            <div>
                <div className="funnel__event-selection">
                    {this.state.eventsReceived ? (
                        <WidgetEventSequencer
                            isLoading={this.state.isLoading}
                            allEvents={this.state.events}
                            path={this.state.path}
                            onChange={this.setNewPath}
                            pathChanged={this.hasPathChanged(this.state.oldPath, this.state.path)}
                            dateChanged={this.hasDateChanged(
                                this.state.oldStartDate,
                                this.props.startDate,
                                this.state.oldEndDate,
                                this.props.endDate
                            )}
                            applyChanges={this.fetchFunnelData}
                            exact={this.state.exactPaths}
                            toggleExact={this.toggleExactPaths}
                            applyText={'Apply Changes'}
                            loadingText={'Fetching Funnel Data...'}
                            noneSelectedJsx={this.renderNoneSelected}
                        />
                    ) : null}
                </div>
                {this.state.dataReceived ? (
                    <WidgetFunnel
                        max={this.state.allChannels[0] ? this.state.allChannels[0].count : 0}
                        data={this.state.allChannels}
                        isLoading={this.state.isLoading}
                    />
                ) : null}
                {!this.state.isLoading ? channelFunnels : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTitle: title => {
            dispatch(setTitle(title));
        },
        setDescription: description => {
            dispatch(setDescription(description));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportEventFunnels);
