export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'PUSH_NOTIFICATION':
            return Object.assign({}, state, {
                notifications: state.notifications.concat(action.payload),
            });
        case 'POP_NOTIFICATION':
            return Object.assign({}, state, {
                notifications: state.notifications.filter(notification => notification.id !== action.payload.id),
            });
        default:
            return state;
    }
}

const initialState = {
    notifications: [],
};
