export const DEFAULT_METRIC = {
    dataType: 0,
    defaultOrderDir: '',
    description: '',
    displayName: '',
    displayProportion: true,
    label: '',
    rawName: '',
    value: '',
};

export const DEFAULT_OPERATOR = {
    display_name: '',
    id: 0,
    label: '',
    name: '',
    resource_uri: '',
    symbol: '',
    value: 0,
};

export const FILTER_CONFIG = {
    FILTER: {
        filterExpanded: false,
        showAddMetricFilter: false,
        selectedMetricsFilterData: [
            {
                id: 1,
                metric: DEFAULT_METRIC,
                metricType: 0,
                operator: DEFAULT_OPERATOR,
                value: '',
                status: 'add', // 'add', 'edit', and 'view'
            },
        ],
        selectedProductsFilterData: [],
        selectedChannelFilterData: [],
        enableFilterApplyButton: false,
        enableComparisonDateRange: false,
    },
    DEFAULT_FILTER_STATUS: {
        isEnableDatePicker: false,
        isEnableComparisonDatePicker: false,
        isEnableProductSelect: false,
        isEnableMetrics: false,
        isEnableButtonGroup: false,
        isEnablePdfExport: false,
        isEnableChannelMultiSelect: false,
        isEnableChannelSingleSelect: {
            enabled: false,
        },
        isDashboardBuilder: false,
    },
    DEFAULT_COMPARISON_FILTER_STATUS: {
        showComparisonDatePicker: false,
        redirectUrl: '',
    },
    FILTERS_OBJECT: {
        metric: '',
        operator: '',
        value: '',
        status: 'add', // 'add', 'edit',
    },
};
