import React from 'react';
import { useConfigTableContext } from '../context/config-table-context';

const Header = ({ children }: { children: React.ReactNode }) => {
    const { status } = useConfigTableContext();
    if (status) {
        return <thead>{children}</thead>;
    }
    return null;
};

export default Header;
