// React Dependencies
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import AnimeJs from 'animejs';

// Layout Dependencies
import ModalBackdrop from './modal-backdrop';
import LayoutModalManageGoals from '../layouts/modals/manage-goals';
import LayoutModalManageIpBlacklist from '../layouts/modals/manage-ip-blacklisting';
import LayoutModalAddDomain from '../layouts/modals/manage-domains/add-domain';
import LayoutModalCreateChannel from '../layouts/modals/create-channel';
import LayoutModalCreateEvent from '../layouts/modals/create-event';
import LayoutModalManageChannels from '../layouts/modals/manage-channels';
import LayoutModalManageDomains from '../layouts/modals/manage-domains/manage-domains';
import LayoutModalManageVisitorBlackList from '../layouts/modals/manage-visitor-blacklist';
import LayoutModalManageEvents from '../layouts/modals/manage-events';
import LayoutModalSetFixedCost from '../layouts/modals/set-fixed-cost';
import LayoutModalManageFixedCost from '../layouts/modals/manage-fixed-cost';
import LayoutModalGoogleAPI from '../layouts/modals/google-unified-oauth-api';
import LayoutModalBingApi from '../layouts/modals/bing-api';
import LayoutModalFacebookApi from '../layouts/modals/facebook-api';
import KlaviyoAPIAddEditModal from '../layouts/modals/add-klaviyo';
import ManageKlaviyoConnections from '../layouts/modals/manage-klaviyo';
import LayoutModalUpdateUserDetails from '../layouts/modals/manage-users/update-user-details';
import LayoutModalResetUserPassword from '../layouts/modals/manage-users/reset-user-password';
import LayoutModalManageTimezoneAndCurrency from '../layouts/modals/manage-timezone-currency';
import LayoutModalCreateUser from '../layouts/modals/manage-users/create-user';
import LayoutModalManageUsers from '../layouts/modals/manage-users/manage-users';
import LayoutModalCreateBlacklistRule from '../layouts/modals/create-blacklist-rule';
import LayoutModalInviteUsers from '../layouts/modals/manage-users/invite-users';
import LayoutModalAddKeywords from '../layouts/modals/add-keywords';
import LayoutModalAccountSetupRequest from '../layouts/modals/account-setup-request';
import LayoutModalMagento from '../layouts/modals/add-magento';
import LayoutModalManageMagento from '../layouts/modals/manage-magento';
import LayoutModalPinterestAPI from '../layouts/modals/pinterest-api';
import LayoutModalAddExcludeDates from '../layouts/modals/add-exclude-dates';
import LayoutModalAddForecasts from '../layouts/modals/add-forecasts';
import LayoutModalAddTVSlots from '../layouts/modals/add-tv-slots';
import LayoutModalCreateConditions from '../layouts/segmenter/create-conditions';
import LayoutModalManageConditions from '../layouts/segmenter/manage-conditions';
import LayoutModalCreateSegment from '../layouts/segmenter/create-segment';
import LayoutModalManageSegments from '../layouts/segmenter/manage-segments';
import LayoutModalSetUpAboveTheLine from '../layouts/modals/manage-above-the-line/set-up-above-the-line';
import LayoutModalManageAboveTheLine from '../layouts/modals/manage-above-the-line/manage-above-the-line';
import LayoutModalManageSEOKeywords from '../layouts/modals/manage-seo-keywords';
import LayoutModalZendeskApi from '../layouts/modals/zendesk-api';
import LayoutModalZendesk from '../layouts/modals/add-zendesk';
import LayoutModalReplayOfflineSales from '../layouts/modals/replay-offline-sales';
import LayoutModalTikTokApi from '../layouts/modals/tiktok-api';
import LayoutModalManageSegmenterPattern from '../layouts/segmenter/advanced-rule-builder/manage-segmenter-pattern';
import LayoutAutomatedKWRConfig from '../layouts/modals/automated-kwr-config';
import LayoutAddAutomatedKWRConfig from '../layouts/modals/add-automated-kwr-config';
import LayoutAddPagesToMarket from '../layouts/modals/add-pages-to-markets';
import LayoutManagePagesToMarket from '../layouts/modals/manage-pages-to-markets';
import LayoutModalSEMRushAPI from '../layouts/modals/manage-semrush-connection';
import SEMRushKeyAddEditModal from '../layouts/modals/add-semrush';
import LayoutModalManageBlacklistedKeywords from '../layouts/modals/manage-blacklisted-keywords/manage-blacklisted-keywords';
import LayoutModalAddBlackListedKeywords from '../layouts/modals/manage-blacklisted-keywords/add-blacklisted-keywords';
import LayoutExcludedURLsForOxylabs from '../layouts/modals/manage-excluded-urls-for-oxylabs';
import LayoutModalAddCompetitors from '../layouts/modals/manage-competitors/add-competitor';
import LayoutManageCompetitors from '../layouts/modals/manage-competitors/manage-competitors';

import LayoutModalManageAttributionModels from '../layouts/modals/manage-attribution-models/manage-attribution-models';
import LayoutManageCrawling from '../layouts/modals/manage-crawling';
import LayoutManageImpressionModelling from '../layouts/modals/manage-impresssion-modelling';
import LayoutAddImpressionModelling from '../layouts/modals/add-impression-modelling';
import LayoutModalManageBrandSplitConfig from '../layouts/modals/manage-brand-split-config/manage-brand-split-config';
import LayoutModalAddBrandSplitConfig from '../layouts/modals/manage-brand-split-config/add-brand-split-config';
import LayoutModalManagePageTypesUrlSubdirectories from '../layouts/modals/manage-page-types-url-subdirectories';
import LayoutModalManagePageCategories from '../layouts/modals/manage-page-categories';
import LayoutModalAddPageCategories from '../layouts/modals/add-page-categories';
import LayoutModalReallocationChannels from '../layouts/modals/add-reallocation-channels';
import LayoutModalManageSubchannels from '../layouts/modals/manage-subchannels';
import LayoutModalAddPageSegments from '../layouts/modals/add-page-segments';
import LayoutAutoEmailConfigModal from '../layouts/modals/add-auto-email-config';
import LayoutModalCreateGoal from '../layouts/modals/manage-goals/create-goal';
import LayoutOfflineSalesModal from '../layouts/modals/add-offline-sales';

const ViewModal = props => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isVisible]);

    useEffect(() => {
        if (!props.modal.isVisible && isVisible) {
            AnimeJs({
                targets: '.modal__side-panel',
                translateX: ['0%', '100%'],
                easing: 'easeOutQuad',
                duration: 300,
                complete: function () {
                    setIsVisible(false);
                },
            });
        } else if (props.modal.isVisible && !isVisible) {
            setIsVisible(true);
        } else if (isVisible) {
            AnimeJs({
                targets: '.modal__side-panel',
                translateX: ['100%', '0%'],
                easing: 'easeOutQuad',
                duration: 300,
            });
        }
    }, [props.modal.isVisible, isVisible]);

    const renderModal = () => {
        switch (props.modal.type) {
            case 'Unified-googleAPI':
                return <LayoutModalGoogleAPI />;
            case 'BingAPI':
                return <LayoutModalBingApi />;
            case 'AddKlaviyo':
                return <KlaviyoAPIAddEditModal config={props.modal.config} />;
            case 'ManageKlaviyo':
                return <ManageKlaviyoConnections />;
            case 'SemrushAPI':
                return <LayoutModalSEMRushAPI />;
            case 'AddSEMRush':
                return <SEMRushKeyAddEditModal config={props.modal.config} />;
            case 'PinterestAPI':
                return <LayoutModalPinterestAPI />;
            case 'FacebookAPI':
                return <LayoutModalFacebookApi />;
            case 'ManageIpBlacklist':
                return <LayoutModalManageIpBlacklist />;
            case 'ManageVisitorBlacklist':
                return <LayoutModalManageVisitorBlackList />;
            case 'AddDomain':
                return <LayoutModalAddDomain />;
            case 'ManageDomains':
                return <LayoutModalManageDomains />;
            case 'CreateChannelWizard':
                return <LayoutModalCreateChannel />;
            case 'ManageChannels':
                return <LayoutModalManageChannels />;
            case 'CreateGoal':
                return <LayoutModalCreateGoal />;
            case 'CreateEventWizard':
                return <LayoutModalCreateEvent />;
            case 'ManageEvents':
                return <LayoutModalManageEvents />;
            case 'SetFixedCost':
                return <LayoutModalSetFixedCost />;
            case 'ManageFixedCost':
                return <LayoutModalManageFixedCost />;
            case 'ManageGoals':
                return <LayoutModalManageGoals />;
            case 'CreateVisitorBlacklistRule':
                return <LayoutModalCreateBlacklistRule />;
            case 'CreateIPBlacklistRule':
                return <LayoutModalCreateBlacklistRule />;
            case 'UpdateUserDetails':
                return <LayoutModalUpdateUserDetails />;
            case 'ResetUserPassword':
                return <LayoutModalResetUserPassword />;
            case 'CreateUser':
                return <LayoutModalCreateUser />;
            case 'InviteUser':
                return <LayoutModalInviteUsers />;
            case 'ManageTimezoneAndCurrency':
                return <LayoutModalManageTimezoneAndCurrency />;
            case 'ManageUsers':
                return <LayoutModalManageUsers />;
            case 'AccountSetupRequest':
                return <LayoutModalAccountSetupRequest />;
            case 'AddKeywords':
                return <LayoutModalAddKeywords />;
            case 'ReplayOfflineSales':
                return <LayoutModalReplayOfflineSales />;
            case 'AddMagento':
                return <LayoutModalMagento />;
            case 'ManageMagento':
                return <LayoutModalManageMagento />;
            case 'AddExcludeDates':
                return <LayoutModalAddExcludeDates />;
            case 'AddForecasts':
                return <LayoutModalAddForecasts />;
            case 'AddTVSlots':
                return <LayoutModalAddTVSlots />;
            case 'ManageExcludedUrlsOxylabs':
                return <LayoutExcludedURLsForOxylabs />;
            case 'ManageCrawling':
                return <LayoutManageCrawling />;
            case 'SegmenterCreateConditions':
                return <LayoutModalCreateConditions />;
            case 'SegmenterEditConditions':
                return <LayoutModalManageConditions />;
            case 'SegmenterCreateSegment':
                return <LayoutModalCreateSegment />;
            case 'SegmenterEditSegment':
                return <LayoutModalManageSegments />;
            case 'SetupAboveTheLine':
                return <LayoutModalSetUpAboveTheLine />;
            case 'ManageAboveTheLine':
                return <LayoutModalManageAboveTheLine />;
            case 'ManageImpressionModelling':
                return <LayoutManageImpressionModelling />;
            case 'AddImpressionModelling':
                return <LayoutAddImpressionModelling />;
            case 'ManageSEOKeywords':
                return <LayoutModalManageSEOKeywords />;
            case 'ZendeskAPI':
                return <LayoutModalZendeskApi />;
            case 'AddZendesk':
                return <LayoutModalZendesk />;
            case 'TiktokAPI':
                return <LayoutModalTikTokApi />;
            case 'ManageSegmenterPattern':
                return <LayoutModalManageSegmenterPattern />;
            case 'AutomatedKWRConfig':
                return <LayoutAutomatedKWRConfig />;
            case 'AddAutomatedKWRConfig':
                return <LayoutAddAutomatedKWRConfig />;
            case 'ManageBlacklistedKeywords':
                return <LayoutModalManageBlacklistedKeywords />;
            case 'AddBlacklistedKeywords':
                return <LayoutModalAddBlackListedKeywords />;
            case 'ManagePagesToMarket':
                return <LayoutManagePagesToMarket />;
            case 'AddPagesToMarket':
                return <LayoutAddPagesToMarket />;
            case 'AddCompetitors':
                return <LayoutModalAddCompetitors />;
            case 'ManageCompetitors':
                return <LayoutManageCompetitors />;
            case 'ManageAttributionModels':
                return <LayoutModalManageAttributionModels />;
            case 'AddReallocationChannels':
                return <LayoutModalReallocationChannels />;
            case 'BrandSplitConfig':
                return <LayoutModalManageBrandSplitConfig />;
            case 'AddBrandSplitConfig':
                return <LayoutModalAddBrandSplitConfig />;
            case 'ManagePageTypeURLSubdirectories':
                return <LayoutModalManagePageTypesUrlSubdirectories />;
            case 'ManagePageCategories':
                return <LayoutModalManagePageCategories />;
            case 'AddPageCategories':
                return <LayoutModalAddPageCategories />;
            case 'ManageSubchannels':
                return <LayoutModalManageSubchannels />;
            case 'AddPageSegments':
                return <LayoutModalAddPageSegments />;
            case 'AddAutoEmailConfig':
                return <LayoutAutoEmailConfigModal />;
            case 'AddOfflineSales':
                return <LayoutOfflineSalesModal />;
            default:
                return null;
        }
    };
    return (
        <>
            {isVisible && (
                <>
                    <ModalBackdrop />
                    <div className="modal__side-panel">{renderModal()}</div>
                </>
            )}
        </>
    );
};

const mapStateToProps = state => {
    return {
        modal: state.modal,
    };
};

export default connect(mapStateToProps, null)(ViewModal);
