import { donutChartConfig } from '../charts/donut-chart-config';
import { barChartConfig } from '../charts/bar-chart-config';
import { areaSplineChartConfig } from '../charts/areaspline-chart-config';
import { lineChartConfig } from '../charts/line-chart-config';
import { pieChartConfig } from '../charts/pie-chart-config';
import { networkChartConfig } from '../charts/network-chart-config';
import { funnelChartConfig } from '../charts/funnel-chart';

export const ChartType = {
    Line: 'line',
    AreaSpline: 'areaSpline',
    Donut: 'donut',
    Bar: 'bar',
    BarHorizontal: 'barHorizontal',
    Pie: 'pie',
    Network: 'network',
    trendLine: 'trendLine',
    funnelChart: 'funnel',
};

export const ChartSelectionLimit = {
    line: 5,
    areaSpline: 5,
    network: 3,
    donut: 10,
    bar: 10,
    barHorizontal: 10,
    pie: 10,
};

export const ChartOptions = {
    line: lineChartConfig,
    areaSpline: areaSplineChartConfig,
    donut: donutChartConfig,
    bar: barChartConfig,
    barHorizontal: barChartConfig,
    pie: pieChartConfig,
    network: networkChartConfig,
    trendLine: areaSplineChartConfig,
    funnelChart: funnelChartConfig,
};

// The order in allChartTypesWithLine will be the order the chart buttons are display on frontend.
export const allChartTypesWithLine = [
    ChartType.Line,
    ChartType.trendLine,
    ChartType.Bar,
    ChartType.BarHorizontal,
    ChartType.Pie,
    ChartType.Donut,
];

// The order in allChartTypesWithAreaSpline will be the order the chart buttons are display on frontend.
export const allChartTypesWithAreaSpline = [
    ChartType.AreaSpline,
    ChartType.trendLine,
    ChartType.Bar,
    ChartType.BarHorizontal,
    ChartType.Pie,
    ChartType.Donut,
];

export const ChartDateGranularity = {
    Second: 'second',
    Minute: 'minute',
    Hour: 'hour',
    Day: 'day',
    Month: 'month',
};
