/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import theme from '../../../styled-components/theme.styled';

// Fields & Resources
import * as fields from '../../../configurations/fields';
import * as resources from '../../../configurations/resources';

// Hooks
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourceTotal from '../../../section-dashboard/hooks/widget/use-resource-total';
import useResourceSparkLine from '../../../section-dashboard/hooks/widget/use-resource-spark-line';
import useResourceBigNumber from '../../../section-dashboard/hooks/widget/big-number/use-resource-big-number';
import useResourcePieChart from '../../../section-dashboard/hooks/widget/pie/use-resource-pie-chart';
import useRequestTable from '../../../section-dashboard/hooks/request/use-request-table';
import useResourceTable from '../../../section-dashboard/hooks/widget/table/use-resource-table';
import useWidgetMenuFooterQuerySelect from '../../../section-dashboard/widgets/menus/hooks/use-widget-menu-footer-query-select';
import useRequestStackedAreaChart from '../../../section-dashboard/hooks/request/use-request-stacked-area-chart';
import useResourceStackedAreaChart from '../../../section-dashboard/hooks/widget/use-resource-stacked-area-chart';
import useResourcePieChartTable from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table';
import useResourceNestedDonutChartTable from '../../../section-dashboard/hooks/widget/use-resource-nested-donut-chart-table';
import useRequestPie from '../../../section-dashboard/hooks/request/use-request-pie';
import useResourceJoinDimensions from '../../../section-dashboard/hooks/widget/use-resource-join-dimensions';
import useRequestPieChartTable from '../../../section-dashboard/hooks/request/use-request-pie-chart-table';
import useResourcePieChartTableLimit from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table-limit';

// Widgets
import WidgetSparkLine from '../../../section-dashboard/widgets/widget/widget-spark-line';
import WidgetBigNumber from '../../../section-dashboard/widgets/widget/widget-big-number';
import WidgetTable from '../../../section-dashboard/widgets/widget/widget-table';
import WidgetPieChart from '../../../section-dashboard/widgets/widget/widget-pie-chart';
import WidgetMenuTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';
import WidgetMenuStackedAreaChart from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-stacked-area-chart';
import WidgetStackedAreaChart from '../../../section-dashboard/widgets/widget/widget-stacked-area-chart';
import WidgetPieChartTable from '../../../section-dashboard/widgets/widget/widget-pie-chart-table';
import WidgetNestedDonutChartTable from '../../../section-dashboard/widgets/widget/widget-nested-donut-chart-table';
import WidgetMenuPie from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie';
import WidgetMenuPieChartTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie-chart-table';

// Components
import BeforeGridConfiguredGoals from '../../../section-dashboard/components/sections/before-grid/before-grid-configured-goals';

// Types
import { FilterOperator, WidgetColour, WidgetMenuObj } from '../../../section-dashboard/types';

// Queries
import { defaultGoalsDropdownQuery, goalsDropdownQuery } from '../../../section-dashboard/queries';

// Context
import { CustomerInsightsDashboardMenuContext } from '../../customer-insights-context/customer-insights-dashboard-menu-context';

export const config = {
    title: 'Customer Insights Dashboard',
    description: '', // TODO description
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableComparisonDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnableButtonGroup: false,
            isEnableChannelMultiSelect: false,
            isEnableChannelSingleSelect: {
                enabled: false,
            },
            isEnablePdfExport: true,
        },
        comparisonStatus: {
            showComparisonDatePicker: false,
            redirectUrl: 'customer-insights-comparison-dashboard',
        },
        datePicker: {},
    },
    context: () => {
        // Context
        const menuContext = useContext(CustomerInsightsDashboardMenuContext);
        return { menuContext };
    },
    layouts: {
        xs: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a'
                    'b'
                    'c'
                    'd'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'e'
                    'f'
                    'g'
                    'h'
                    'h'
                    'i'
                    'j'
                    'k'
                    'l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm'
                    `,
                },
                {
                    title: 'Customer Type: Traffic Overview - New vs Returning',
                    grid: `
                    'n'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Goals by Customer Type',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByCustomerTypeSectionMenu.menu,
                    grid: `
                    'p'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Customer Type by Goal',
                    grid: `
                    'r'
                    `,
                },
                {
                    title: 'Geographical: Traffic Overview - Geographic Splits',
                    menu: (): JSX.Element =>
                        config.context().menuContext.trafficOverviewGeographicSplitsSectionMenu.menu,
                    grid: `
                    'o'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Goals by Location',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByLocationSectionMenu.menu,
                    grid: `
                    't'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Locations by Goal',
                    menu: (): JSX.Element => config.context().menuContext.countLocationsByGoalSectionMenu.menu,
                    grid: `
                    'v'
                    `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a b'
                    'c d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'e f'
                    'g g'
                    'h h'
                    'h h'
                    'i i'
                    'i i'
                    'j j'
                    'k k'
                    'l l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm m'
                    `,
                },
                {
                    title: 'Customer Type: Traffic Overview - New vs Returning',
                    grid: `
                    'n n'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Goals by Customer Type',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByCustomerTypeSectionMenu.menu,
                    grid: `
                    'p p'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Customer Type by Goal',
                    grid: `
                    'r r'
                    `,
                },
                {
                    title: 'Geographical: Traffic Overview - Geographic Splits',
                    menu: (): JSX.Element =>
                        config.context().menuContext.trafficOverviewGeographicSplitsSectionMenu.menu,
                    grid: `
                    'o o'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Goals by Location',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByLocationSectionMenu.menu,
                    grid: `
                    't t'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Locations by Goal',
                    menu: (): JSX.Element => config.context().menuContext.countLocationsByGoalSectionMenu.menu,
                    grid: `
                    'v v'
                    `,
                },
            ],
        },
        md: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b'
                    'c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'i h h e'
                    'i h h g'
                    'j f f k'
                    'l l l l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm m m m'
                    'm m m m'
                    `,
                },
                {
                    title: 'Customer Type: Traffic Overview - New vs Returning',
                    grid: `
                    'n n n n'
                    'n n n n'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Goals by Customer Type',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByCustomerTypeSectionMenu.menu,
                    grid: `
                    'p p p p'
                    'p p p p'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Customer Type by Goal',
                    grid: `
                    'r r r r'
                    'r r r r'
                    `,
                },
                {
                    title: 'Geographical: Traffic Overview - Geographic Splits',
                    menu: (): JSX.Element =>
                        config.context().menuContext.trafficOverviewGeographicSplitsSectionMenu.menu,
                    grid: `
                    'o o o o'
                    'o o o o'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Goals by Location',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByLocationSectionMenu.menu,
                    grid: `
                    't t t t'
                    't t t t'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Locations by Goal',
                    menu: (): JSX.Element => config.context().menuContext.countLocationsByGoalSectionMenu.menu,
                    grid: `
                    'v v v v'
                    'v v v v'
                    `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a a b b b'
                    'a a a b b b'
                    'c c c d d d'
                    'c c c d d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'i i h h e j'
                    'i i h h f k'
                    'i i h h g l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm m m m m m'
                    'm m m m m m'
                    `,
                },
                {
                    title: 'Customer Type: Traffic Overview - New vs Returning',
                    grid: `
                    'n n n n n n'
                    'n n n n n n'
                    'n n n n n n'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Goals by Customer Type',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByCustomerTypeSectionMenu.menu,
                    grid: `
                    'p p p p p p'
                    'p p p p p p'
                    'p p p p p p'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Customer Type by Goal',
                    grid: `
                    'r r r r r r'
                    'r r r r r r'
                    'r r r r r r'
                    `,
                },
                {
                    title: 'Geographical: Traffic Overview - Geographic Splits',
                    menu: (): JSX.Element =>
                        config.context().menuContext.trafficOverviewGeographicSplitsSectionMenu.menu,
                    grid: `
                    'o o o o o o'
                    'o o o o o o'
                    'o o o o o o'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Goals by Location',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByLocationSectionMenu.menu,
                    grid: `
                    't t t t t t'
                    't t t t t t'
                    't t t t t t'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Locations by Goal',
                    menu: (): JSX.Element => config.context().menuContext.countLocationsByGoalSectionMenu.menu,
                    grid: `
                    'v v v v v v'
                    'v v v v v v'
                    'v v v v v v'
                    `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b c c d d'
                    'a a b b c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'i i h h h h e j'
                    'i i h h h h f k'
                    'i i h h h h g l'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'm m m m m m m m'
                    'm m m m m m m m'
                    'm m m m m m m m'
                    `,
                },
                {
                    title: 'Customer Type: Traffic Overview - New vs Returning',
                    grid: `
                    'n n n n n n n n'
                    'n n n n n n n n'
                    'n n n n n n n n'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Goals by Customer Type',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByCustomerTypeSectionMenu.menu,
                    grid: `
                    'p p p p p p p p'
                    'p p p p p p p p'
                    'p p p p p p p p'
                    `,
                },
                {
                    title: 'Customer Type: Goal Completion Overview - Count & Value of Customer Type by Goal',
                    grid: `
                    'r r r r r r r r'
                    'r r r r r r r r'
                    'r r r r r r r r'
                    `,
                },
                {
                    title: 'Geographical: Traffic Overview - Geographic Splits',
                    menu: (): JSX.Element =>
                        config.context().menuContext.trafficOverviewGeographicSplitsSectionMenu.menu,
                    grid: `
                    'o o o o o o o o'
                    'o o o o o o o o'
                    'o o o o o o o o'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Goals by Location',
                    menu: (): JSX.Element => config.context().menuContext.countGoalsByLocationSectionMenu.menu,
                    grid: `
                    't t t t t t t t'
                    't t t t t t t t'
                    't t t t t t t t'
                    `,
                },
                {
                    title: 'Geographical: Goal Completion Overview - Count & Value of Locations by Goal',
                    menu: (): JSX.Element => config.context().menuContext.countLocationsByGoalSectionMenu.menu,
                    grid: `
                    'v v v v v v v v'
                    'v v v v v v v v'
                    'v v v v v v v v'
                    `,
                },
            ],
        },
    },
    components: () => {
        const menuContext = useContext(CustomerInsightsDashboardMenuContext);
        const {
            // Traffic Overview
            trafficOverviewSelectedLevelField,
            trafficOverviewGeographicSplitsSectionMenu,

            // Goal Completion Overview
            countGoalsByCustomerTypeSectionMenu,

            countGoalsByLocationSelectedLevelField,
            countGoalsByLocationSectionMenu,

            countLocationsByGoalSectionMenu,
        } = menuContext;

        /***
         Title: Overall Profile
         Widget: Big Numbers
        */
        // Sparklines
        const goalSnapshotData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                isGraph: true,
                groupBy: fields.SALES_DATE_DATE,
                graphGroupBy: [fields.SALES_DATE_DATE],
                graphMetrics: [
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
                selectedFields: [fields.VISITS, fields.COST, fields.FM_SALES, fields.FM_REVENUE],
            },
        });

        const sparkTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                totals: true,
                selectedFields: [fields.VISITS, fields.COST, fields.FM_SALES, fields.FM_REVENUE],
            },
        });

        const sparkTotals = useResourceTotal({
            resourceData: sparkTotalData,
        });

        // Visits Sparkline
        const visitsSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.VISITS,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Sales Sparkline
        const salesSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.FM_SALES,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Revenue Sparkline
        const revenueSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.FM_REVENUE,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Cost Sparkline
        const costSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.COST,
            dateDimension: fields.SALES_DATE_DATE,
        });

        /***
         Title: Goal Snapshot
         Widget: Big Numbers, Pie Chart, Table
        */
        const goalSnapshotDefaultGoalTotalsData = (menu: WidgetMenuObj) =>
            useResource({
                resource: resources.REPORT_MCS_CHANNEL,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                    ...(menu.request.filters ?? []),
                    filters: [
                        ...(menu.request.filters ?? []),
                        { field: fields.PRODUCT_DEFAULT, operator: FilterOperator.EQUALS, value: '1' },
                    ],
                    selectedFields: [fields.SALES_DATE_DATETIME, fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                },
            });

        // Default Goal Goals Completed
        const defaultGoalTotalGoalsBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalTotalGoalsBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalGoalsBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Default Goal Attributed Value
        const defaultGoalTotalRevenueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalTotalRevenueBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalRevenueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // Default Goal CR%
        const defaultGoalCRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const defaultGoalCRBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalCRBigNumberMenu),
            field: fields.FM_CVR,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        const goalSnapshotBigNumberData = (menu: WidgetMenuObj) =>
            useResource({
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [
                        fields.VISITS,
                        fields.COST,
                        fields.FM_SALES,
                        fields.FM_REVENUE,
                        fields.GOAL_CVR_GOAL_SNAPSHOT,
                    ],
                    ...menu.request,
                    selectedFields: [
                        fields.SALES_DATE_DATE,
                        fields.VISITS,
                        fields.COST,
                        fields.FM_SALES,
                        fields.FM_REVENUE,
                        fields.GOAL_CVR_GOAL_SNAPSHOT,
                    ],
                },
            });

        // Total Goals Completed
        const totalGoalsCompletedBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalGoalsCompletedBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalGoalsCompletedBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Attributed Value
        const totalAttributedValueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalAttributedValueBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalAttributedValueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // All Goals CR%
        const totalCVRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalCVRBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalCVRBigNumberMenu),
            field: fields.GOAL_CVR_GOAL_SNAPSHOT,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Goals Snapshot Pie
        const goalSnapshotGoalsPieRequest = useRequestPie({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
            selectedFields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
        });

        const goalSnapshotGoalsPieData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: goalSnapshotGoalsPieRequest,
        });

        let goalSnapshotPie = useResourcePieChart({
            resourceData: goalSnapshotGoalsPieData,
            dimension: fields.PRODUCT,
        });

        // Goal Snapshot Table
        const goalSnapshotTableRequest = useRequestTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
            selectedFields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
        });

        const goalSnapshotTableData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: goalSnapshotTableRequest,
        });

        const goalSnapshotTableTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                ...goalSnapshotTableRequest,
                totals: true,
            },
        });

        const goalSnapshotTable = useResourceTable({
            resourceData: goalSnapshotTableData,
        });

        const goalSnapshotTableTotals = useResourceTotal({
            resourceData: goalSnapshotTableTotalData,
        });

        /***
         Title: Goal Completions
         Widget: Stacked Area Chart
        */
        const goalCompletionStackedAreaChartRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Count of Completions', field: fields.FM_SALES, active: true },
                { name: 'Value of Completions', field: fields.FM_REVENUE, active: false },
            ],
            groupBy: fields.PRODUCT,
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' },
            ],
            selectedFields: [
                fields.SALES_DATE_DATE,
                fields.PRODUCT,
                fields.GOAL_COMPLETIONS,
                fields.FM_SALES,
                fields.FM_REVENUE,
            ],
        });

        const goalCompletionsData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: goalCompletionStackedAreaChartRequest,
        });

        const goalCompletionsAdditionalSeries = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                fields: [fields.SALES_DATE_DATE, fields.VISITS, fields.VISITORS],
            },
        });

        const goalCompletionStackedAreaChart = useResourceStackedAreaChart({
            areaSeriesMetric: fields.PRODUCT,
            resourceData: goalCompletionsData,
            dateDimension: fields.SALES_DATE_DATE,
            optionalSeries: [
                {
                    title: 'Unique Visits',
                    yAxis: fields.VISITS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.purple,
                },
                {
                    title: 'Unique Visitors',
                    yAxis: fields.VISITORS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.emerald,
                },
            ],
        });

        /***
         Title: Customer Type: Traffic Overview - New vs Returning
         Widget: Nested Donut Chart Table
        */

        const trafficOverviewNewVsReturningVisitsData = useResource({
            resource: resources.REPORT_MCS_SALES_JOURNEY,
            request: {
                fields: [fields.VISITS],
                groupBy: fields.VISIT_TYPE,
                orderBy: [{ field: fields.VISITS, orderByDirection: 'desc' }],
                selectedFields: [fields.VISIT_TYPE, fields.VISITS],
            },
        });

        const trafficOverviewNewVsReturningSalesData = useResource({
            resource: resources.REPORT_MCS_SALES_JOURNEY,
            request: {
                fields: [fields.FM_SALES],
                groupBy: fields.VISIT_TYPE,
                orderBy: [{ field: fields.FM_SALES, orderByDirection: 'desc' }],
                selectedFields: [fields.VISIT_TYPE, fields.FM_SALES],
            },
        });

        const trafficOverviewNewVsReturningCRData = useResource({
            resource: resources.REPORT_MCS_SALES_JOURNEY,
            request: {
                fields: [fields.FM_CVR],
                groupBy: fields.VISIT_TYPE,
                orderBy: [{ field: fields.VISIT_TYPE, orderByDirection: 'desc' }],
                selectedFields: [fields.FM_CVR, fields.NEW_VISIT_FM_CVR],
            },
        });

        const trafficOverviewNewVsReturning = useResourceNestedDonutChartTable({
            categories: [
                {
                    title: 'Visits',
                    field: fields.VISITS,
                    resourceData: trafficOverviewNewVsReturningVisitsData,
                    showInGraph: true,
                },
                {
                    title: 'Sales',
                    field: fields.FM_SALES,
                    resourceData: trafficOverviewNewVsReturningSalesData,
                    showInGraph: true,
                },
                {
                    title: 'Cubed CR%',
                    field: fields.FM_CVR,
                    resourceData: trafficOverviewNewVsReturningCRData,
                    showInGraph: false,
                },
            ],
            columns: {
                columnFields: [fields.VISIT_TYPE, fields.REPEAT_SALE],
                columnConfig: [
                    {
                        rawName: 'new',
                        displayName: 'New',
                        flags: ['First Visit'],
                    },
                    {
                        rawName: 'returning',
                        displayName: 'Returning',
                        flags: ['Returning Visit'],
                    },
                ],
            },
        });

        /***
         Title: Geographical: Traffic Overview - Geographic Splits (Visitors)
         Widget: Pie Chart Table
        */

        const trafficOverviewGeographicsVisitorsRequest = {
            groupBy: [
                trafficOverviewSelectedLevelField,
                ...(trafficOverviewSelectedLevelField === fields.MCS_CITY_NAME ? [fields.MCS_COUNTRY_NAME] : []),
            ],
            orderBy: [{ field: fields.VISITS, orderByDirection: 'desc' }],
            fields: [trafficOverviewSelectedLevelField, fields.VISITS],
            chartField: fields.VISITS,
            ...trafficOverviewGeographicSplitsSectionMenu.request,
            selectedFields: [
                trafficOverviewSelectedLevelField,
                fields.VISITS,
                ...(trafficOverviewSelectedLevelField === fields.MCS_CITY_NAME ? [fields.MCS_COUNTRY_NAME] : []),
            ],
        };

        const trafficOverviewGeographicsVisitorsData = useResource({
            resource: resources.REPORT_MCS_REGION_SALES_JOURNEY,
            request: trafficOverviewGeographicsVisitorsRequest,
        });

        const trafficOverviewGeographicsVisitorsJoinedLabelsData = useResourceJoinDimensions({
            resourceData: trafficOverviewGeographicsVisitorsData,
            displayField: fields.MCS_CITY_NAME,
            joiningField: fields.MCS_COUNTRY_NAME,
        });

        const trafficOverviewGeographicsVisitorsTotalsData = useResource({
            resource: resources.REPORT_MCS_REGION_SALES_JOURNEY,
            request: {
                ...trafficOverviewGeographicsVisitorsRequest,
                totals: true,
            },
        });

        const trafficOverviewGeographicsVisitorsDonutPieChartTableTotal = useResourceTotal({
            resourceData: trafficOverviewGeographicsVisitorsTotalsData,
        });

        const trafficOverviewGeographicsVisitorsDonutPieChartTable = useResourcePieChartTable({
            resourceData: trafficOverviewGeographicsVisitorsJoinedLabelsData,
            chartDimension: trafficOverviewSelectedLevelField,
        });

        const trafficOverviewGeographicsVisitorsDonutPieChartTableLimit = useResourcePieChartTableLimit({
            resourceData: trafficOverviewGeographicsVisitorsDonutPieChartTable,
            totalData: trafficOverviewGeographicsVisitorsDonutPieChartTableTotal,
            ratioField: fields.VISITS,
            limit: 10,
        });

        /***
         Title: Customer Type: Goal Completion Overview - Count & Value of Goals by Customer Type
         Widget: Pie Chart Table
        */

        const goalCompletionOverviewCountOfGoalByCustomerRequest = useRequestPieChartTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.FM_SALES, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.FM_SALES, fields.FM_REVENUE],
            chartField: fields.FM_SALES,
            chartMetrics: [fields.FM_SALES, fields.FM_REVENUE],
            rowsPerPage: 10,
            ...countGoalsByCustomerTypeSectionMenu.request,
            selectedFields: [fields.PRODUCT, fields.FM_SALES, fields.FM_REVENUE],
        });

        const goalCompletionOverviewCountOfGoalByCustomerData = useResource({
            resource: resources.REPORT_MCS_SALES_JOURNEY,
            request: goalCompletionOverviewCountOfGoalByCustomerRequest,
        });

        const goalCompletionOverviewCountOfGoalByCustomerTotalsData = useResource({
            resource: resources.REPORT_MCS_SALES_JOURNEY,
            request: {
                ...goalCompletionOverviewCountOfGoalByCustomerRequest,
                totals: true,
            },
        });

        const goalCompletionOverviewCountOfGoalByCustomerDonutPieChartTableTotal = useResourceTotal({
            resourceData: goalCompletionOverviewCountOfGoalByCustomerTotalsData,
        });

        const goalCompletionOverviewCountOfGoalByCustomerDonutPieChartTable = useResourcePieChartTable({
            resourceData: goalCompletionOverviewCountOfGoalByCustomerData,
            chartDimension: fields.PRODUCT,
        });

        /***
         Title: Customer Type: Goal Completion Overview - Count & Value of Customer Type by Goal
         Widget: Pie Chart Table
        */
        const goalCompletionOverviewGoalByGoalMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalCompletionOverviewCountOfCustomerByGoalRequest = useRequestPieChartTable({
            groupBy: fields.VISIT_TYPE,
            orderBy: [{ field: fields.FM_SALES, orderByDirection: 'desc' }],
            fields: [fields.VISIT_TYPE, fields.FM_SALES, fields.FM_REVENUE],
            chartField: fields.FM_SALES,
            chartMetrics: [fields.FM_SALES, fields.FM_REVENUE],
            rowsPerPage: 10,
            ...goalCompletionOverviewGoalByGoalMenu.request,
            selectedFields: [fields.VISIT_TYPE, fields.FM_SALES, fields.FM_REVENUE],
        });

        const goalCompletionOverviewCountOfCustomerByGoalData = useResource({
            resource: resources.REPORT_MCS_SALES_JOURNEY,
            request: goalCompletionOverviewCountOfCustomerByGoalRequest,
        });

        const goalCompletionOverviewCountOfCustomerByGoalTotalsData = useResource({
            resource: resources.REPORT_MCS_SALES_JOURNEY,
            request: {
                ...goalCompletionOverviewCountOfCustomerByGoalRequest,
                totals: true,
            },
        });

        const goalCompletionOverviewCountOfCustomerByGoalDonutPieChartTableTotal = useResourceTotal({
            resourceData: goalCompletionOverviewCountOfCustomerByGoalTotalsData,
        });

        const goalCompletionOverviewCountOfCustomerByGoalDonutPieChartTable = useResourcePieChartTable({
            resourceData: goalCompletionOverviewCountOfCustomerByGoalData,
            chartDimension: fields.VISIT_TYPE,
        });

        /***
         Title: Geographical: Goal Completion Overview - Count & Value of Goals by Location
         Widget: Pie Chart Table
        */
        const goalCompletionOverviewCountOfGoalsByLocationRequest = useRequestPieChartTable({
            groupBy: [
                countGoalsByLocationSelectedLevelField,
                ...(countGoalsByLocationSelectedLevelField === fields.MCS_CITY_NAME ? [fields.MCS_COUNTRY_NAME] : []),
            ],
            orderBy: [{ field: fields.FM_SALES, orderByDirection: 'desc' }],
            fields: [countGoalsByLocationSelectedLevelField, fields.FM_SALES, fields.FM_REVENUE],
            chartField: fields.FM_SALES,
            chartMetrics: [fields.FM_SALES, fields.FM_REVENUE],
            rowsPerPage: 10,
            ...countGoalsByLocationSectionMenu.request,
            selectedFields: [
                countGoalsByLocationSelectedLevelField,
                fields.FM_SALES,
                fields.FM_REVENUE,
                ...(countGoalsByLocationSelectedLevelField === fields.MCS_CITY_NAME ? [fields.MCS_COUNTRY_NAME] : []),
            ],
        });

        const goalCompletionOverviewCountOfGoalsByLocationData = useResource({
            resource: resources.REPORT_MCS_REGION_SALES_JOURNEY,
            request: goalCompletionOverviewCountOfGoalsByLocationRequest,
        });

        const goalCompletionOverviewCountOfGoalsByLocationJoinedLabelsData = useResourceJoinDimensions({
            resourceData: goalCompletionOverviewCountOfGoalsByLocationData,
            displayField: fields.MCS_CITY_NAME,
            joiningField: fields.MCS_COUNTRY_NAME,
        });

        const goalCompletionOverviewCountOfGoalsByLocationTotalsData = useResource({
            resource: resources.REPORT_MCS_REGION_SALES_JOURNEY,
            request: {
                ...goalCompletionOverviewCountOfGoalsByLocationRequest,
                totals: true,
            },
        });

        const goalCompletionOverviewCountOfGoalsByLocationDonutPieChartTableTotal = useResourceTotal({
            resourceData: goalCompletionOverviewCountOfGoalsByLocationTotalsData,
        });

        const goalCompletionOverviewCountOfGoalsByLocationDonutPieChartTable = useResourcePieChartTable({
            resourceData: goalCompletionOverviewCountOfGoalsByLocationJoinedLabelsData,
            chartDimension: countGoalsByLocationSelectedLevelField,
        });

        /***
         Title: Geographical: Goal Completion Overview - Count & Value of Locations by Goal
         Widget: Pie Chart Table
        */
        const goalCompletionOverviewCountOfLocationsByGoalRequest = useRequestPieChartTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.FM_SALES, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.FM_SALES, fields.FM_REVENUE],
            chartField: fields.FM_SALES,
            chartMetrics: [fields.FM_SALES, fields.FM_REVENUE],
            rowsPerPage: 10,
            ...countLocationsByGoalSectionMenu.request,
            selectedFields: [fields.PRODUCT, fields.FM_SALES, fields.FM_REVENUE],
        });

        const goalCompletionOverviewCountOfLocationsByGoalData = useResource({
            resource: resources.REPORT_MCS_REGION_SALES_JOURNEY,
            request: goalCompletionOverviewCountOfLocationsByGoalRequest,
        });

        const goalCompletionOverviewCountOfLocationsByGoalTotalsData = useResource({
            resource: resources.REPORT_MCS_REGION_SALES_JOURNEY,
            request: {
                ...goalCompletionOverviewCountOfLocationsByGoalRequest,
                totals: true,
            },
        });

        const goalCompletionOverviewCountOfLocationsByGoalDonutPieChartTableTotal = useResourceTotal({
            resourceData: goalCompletionOverviewCountOfLocationsByGoalTotalsData,
        });

        const goalCompletionOverviewCountOfLocationsByGoalDonutPieChartTable = useResourcePieChartTable({
            resourceData: goalCompletionOverviewCountOfLocationsByGoalData,
            chartDimension: fields.PRODUCT,
        });

        return {
            a: (
                <WidgetSparkLine
                    title="Visits by Day"
                    data={visitsSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Teal}
                />
            ),
            b: (
                <WidgetSparkLine
                    title="Sales by Day"
                    data={salesSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Orange}
                />
            ),
            c: (
                <WidgetSparkLine
                    title="Revenue by Day"
                    data={revenueSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Green}
                />
            ),
            d: (
                <WidgetSparkLine
                    title="Cost by Day"
                    data={costSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Rose}
                />
            ),
            e: (
                <WidgetBigNumber
                    title="Default Goal Goals Completed"
                    data={defaultGoalTotalGoalsBigNumber}
                    menu={defaultGoalTotalGoalsBigNumberMenu.menu}
                />
            ),
            f: (
                <WidgetBigNumber
                    title="Default Goal Attributed Value"
                    data={defaultGoalTotalRevenueBigNumber}
                    menu={defaultGoalTotalRevenueBigNumberMenu.menu}
                />
            ),
            g: (
                <WidgetBigNumber
                    title="Default Goal CR%"
                    data={defaultGoalCRBigNumber}
                    menu={defaultGoalCRBigNumberMenu.menu}
                />
            ),
            h: (
                <WidgetTable
                    title="Goals Snapshot"
                    data={goalSnapshotTable}
                    totalData={goalSnapshotTableTotals}
                    menu={<WidgetMenuTable data={goalSnapshotTable} />}
                />
            ),
            i: (
                <WidgetPieChart
                    title={'Goals Completed'}
                    data={goalSnapshotPie}
                    display="donut"
                    menu={<WidgetMenuPie data={goalSnapshotPie} />}
                />
            ),
            j: (
                <WidgetBigNumber
                    title="Total Goals Completed"
                    data={totalGoalsCompletedBigNumber}
                    menu={totalGoalsCompletedBigNumberMenu.menu}
                />
            ),
            k: (
                <WidgetBigNumber
                    title="Total Attributed Value"
                    data={totalAttributedValueBigNumber}
                    menu={totalAttributedValueBigNumberMenu.menu}
                />
            ),
            l: <WidgetBigNumber title="All Goals CR%" data={totalCVRBigNumber} menu={totalCVRBigNumberMenu.menu} />,
            m: (
                <WidgetStackedAreaChart
                    title="Goal Completions Over Time"
                    data={goalCompletionStackedAreaChart}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={goalCompletionStackedAreaChart}
                            request={goalCompletionStackedAreaChartRequest}
                        />
                    }
                />
            ),
            n: (
                <WidgetNestedDonutChartTable
                    title="Customer Type: Traffic Overview - New vs Returning"
                    data={trafficOverviewNewVsReturning}
                />
            ),
            o: (
                <WidgetPieChartTable
                    title="Geographical: Traffic Overview - Geographic Splits"
                    data={trafficOverviewGeographicsVisitorsDonutPieChartTableLimit}
                    totalData={trafficOverviewGeographicsVisitorsDonutPieChartTableTotal}
                    href=""
                    display="donut"
                />
            ),
            p: (
                <WidgetPieChartTable
                    title="Customer Type: Goal Completion Overview - Count & Value of Goals by Customer Type"
                    data={goalCompletionOverviewCountOfGoalByCustomerDonutPieChartTable}
                    totalData={goalCompletionOverviewCountOfGoalByCustomerDonutPieChartTableTotal}
                    href=""
                    display="donut"
                    menu={
                        <WidgetMenuPieChartTable data={goalCompletionOverviewCountOfGoalByCustomerDonutPieChartTable} />
                    }
                />
            ),
            r: (
                <WidgetPieChartTable
                    title="Customer Type: Goal Completion Overview - Count & Value of Customer Type by Goal"
                    data={goalCompletionOverviewCountOfCustomerByGoalDonutPieChartTable}
                    totalData={goalCompletionOverviewCountOfCustomerByGoalDonutPieChartTableTotal}
                    href=""
                    display="donut"
                    menu={
                        <>
                            {goalCompletionOverviewGoalByGoalMenu.menu}
                            <WidgetMenuPieChartTable
                                data={goalCompletionOverviewCountOfCustomerByGoalDonutPieChartTable}
                            />
                        </>
                    }
                />
            ),
            t: (
                <WidgetPieChartTable
                    title="Geographical: Goal Completion Overview - Count & Value of Goals by Location"
                    data={goalCompletionOverviewCountOfGoalsByLocationDonutPieChartTable}
                    totalData={goalCompletionOverviewCountOfGoalsByLocationDonutPieChartTableTotal}
                    href=""
                    display="donut"
                    menu={
                        <WidgetMenuPieChartTable
                            data={goalCompletionOverviewCountOfGoalsByLocationDonutPieChartTable}
                        />
                    }
                />
            ),
            v: (
                <WidgetPieChartTable
                    title="Geographical: Goal Completion Overview - Count & Value of Locations by Goal"
                    data={goalCompletionOverviewCountOfLocationsByGoalDonutPieChartTable}
                    totalData={goalCompletionOverviewCountOfLocationsByGoalDonutPieChartTableTotal}
                    href=""
                    display="donut"
                    menu={
                        <WidgetMenuPieChartTable
                            data={goalCompletionOverviewCountOfLocationsByGoalDonutPieChartTable}
                        />
                    }
                />
            ),
        };
    },
};
