import React, { useEffect } from 'react';
import Form from '../../../../components/forms/form';
import validators from '../../../../components/forms/validation/validators';

import { useFormContext } from 'react-hook-form';
import { DropdownOption } from '../../../../section-dashboard/types';
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess } from '../../../../react-query/types';
import { CONFIG_PRODUCT_TYPE, CONFIG_SEOGD_MARKET } from '../../../../configurations/resources-config';
import buildDropdownOptions from '../../../../react-query/select-functions/build-dropdown-options';
import styled from 'styled-components';
import LoadingSpinner from '../../../../components/loading-spinner';

const StyledLoadingContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const FormCreateGoal = ({ disabled }: { disabled: boolean }) => {
    const { watch, setValue } = useFormContext();
    const formValues = watch();

    const productTypeQuery = useFetchResource<ConfigDataSuccess, DropdownOption[]>({
        resource: CONFIG_PRODUCT_TYPE,
        params: [{ key: 'active', value: 'true' }],
        select: data => buildDropdownOptions({ data: data, labelField: 'name', valueField: 'id' }),
    });

    const marketsQuery = useFetchResource<ConfigDataSuccess, DropdownOption[]>({
        resource: CONFIG_SEOGD_MARKET,
        params: [
            { key: 'active', value: 'true' },
            { key: 'limit', value: '0' },
            { key: 'order_by', value: 'country' },
        ],
        select: data => {
            return data.objects.map(object => {
                return {
                    label: `${String(object.country)} - ${object.alpha3}`,
                    value: String(object.id),
                };
            });
        },
    });

    useEffect(() => {
        setValue('isDefault', false);
        setValue('assignedToMarket', false);
    }, [formValues.goalType]); // eslint-disable-line react-hooks/exhaustive-deps

    if (productTypeQuery.isLoading || marketsQuery.isLoading) {
        return (
            <StyledLoadingContainer>
                <LoadingSpinner />
            </StyledLoadingContainer>
        );
    }

    return (
        <Form.Body>
            <Form.Section>
                <Form.SectionTitle>Goal Details</Form.SectionTitle>

                <Form.Field>
                    <Form.InputLabel htmlFor="name" label="Goal Name" required={true} />
                    <Form.InputText
                        name="name"
                        placeholder="Enter a name for your goal"
                        validators={[validators.required, validators.maxLength(200)]}
                        disabled={disabled}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="productType" label="Goal Type" required={true} />
                    <Form.InputSelect
                        name="productType"
                        options={productTypeQuery.data || []}
                        validators={[validators.required]}
                        disabled={disabled}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.InputLabel htmlFor="revenueOverride" label="Revenue Override" />
                    <Form.InputNumber name="revenueOverride" placeholder="Enter a revenue override" />
                </Form.Field>

                <Form.Field>
                    <Form.InputCheckbox
                        name="default"
                        label="Set Goal as Default"
                        tooltipCopy="Marking a goal as default auto-selects it to be viewed in reports. Non default goals will still be available but not selected by default."
                        disabled={formValues.productType === '4' || formValues.productType === '5' || disabled}
                    />
                </Form.Field>

                <Form.Field>
                    <Form.InputCheckbox name="assignedToMarket" label="Assign Goal to a Market" disabled={disabled} />
                </Form.Field>

                {formValues.assignedToMarket && (
                    <Form.Field>
                        <Form.InputLabel htmlFor="assignedMarketList" label="Market" required={true} />
                        <Form.InputSelect
                            name="assignedMarketList"
                            options={marketsQuery.data || []}
                            isMulti={true}
                            disabled={disabled}
                            validators={[formValues.assignedToMarket && [validators.required]]}
                        />
                    </Form.Field>
                )}
            </Form.Section>
        </Form.Body>
    );
};

export default FormCreateGoal;
