// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Papa from 'papaparse';
import Axios from 'axios';

// Helpers
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { validateFileType } from '../../helpers/validate-csv-file';

// Redux Actions
import { removeModal, setModal } from '../../redux/actions/modal';
import { getRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import ProgressBar from '../../components/progress-bar';
import * as resources from '../../configurations/resources';
import InputButton from '../../components/inputs/input-button';
import { ButtonThemes } from '../../enums/button-themes';

class LayoutModalAddKeywords extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resources: [resources.REPORT_SEOPT_KEYWORDS_LANDING],
            isLoading: true,
            pageError: false,
            goals: [],
            selectedGoalId: null,
            selectedFile: null,
            selectedFileIsValid: false,
            accordionVisible: true,
            accordionOpen: true,
            uploadButtonDisabled: true,
            closeButtonDisabled: false,
            showUploadProgress: false,
            uploadProgress: null,
            keywordUploadId: null,
            fileUploadId: null,
            processId: null,
            returningMessage: null,
            fileInputErrorMessage: null,
            uploadError: null,
            marketsError: null,
            showLoading: false,
            isDownloading: false,
        };

        // this.fileHeaders need to be in alphabetical order
        this.fileHeaders = [
            'active',
            'child_category',
            'keyword',
            'keyword_modifier',
            'language',
            'locale',
            'mapped_page',
            'market',
            'parent_category',
            'product',
            'sub_category',
            'translation',
        ];
    }

    componentDidMount() {
        // check if there is already an upload in progress for this account
        Axios({
            method: 'GET',
            url: generateUrl('config', 'file-upload', [
                { key: 'account_id', value: this.props.account.id },
                { key: 'processing', value: true },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length > 0) {
                    this.setState({
                        showUploadProgress: true,
                        uploadProgress: response.data.objects[0].progress,
                        fileUploadId: response.data.objects[0].id,
                        processId: response.data.objects[0].process_id,
                        returningMessage: 'There is currently a file upload in progress for this account.',
                    });
                }
            })
            .catch(() => {
                this.setState({
                    pageError: true,
                });
            });

        // set up product
        const requestConfig = [
            {
                resourceGroup: 'config',
                resourceName: 'product',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
        ];

        this.props.getRequest(requestConfig);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fileUploadId !== this.state.fileUploadId && this.state.fileUploadId) {
            this.fetchUploadProgress();
        }
        if (this.state.isLoading && !this.props.request.isLoading) {
            if (!this.props.request.hasError) {
                const goals = this.props.request.data[0].objects.map(goal => {
                    return {
                        id: goal.id,
                        keyValue: goal.id,
                        name: goal.name,
                    };
                });

                this.setState({
                    isLoading: false,
                    goals: goals,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }
        }
    }

    handleNavigateManageEventModal = () => {
        this.props.setModal('ManageSEOKeywords', {});
    };

    onCloseClick = () => {
        this.props.removeModal();
        this.handleNavigateManageEventModal();
    };

    validateForm = () => {
        if (this.state.selectedGoalId && this.state.selectedFileIsValid) {
            this.setState({
                uploadButtonDisabled: false,
            });
        }
    };

    onProductInputChange = event => {
        const selectedGoalId = this.state.goals.filter(goal => goal.name === event.target.value)[0].id;

        this.setState(
            {
                selectedGoalId: selectedGoalId,
            },
            () => this.validateForm()
        );
    };

    onFileInputChange = event => {
        const file = event.target.files[0];

        // Check the file extension
        const { isValid, selectedFile, message } = validateFileType(file, 'csv');
        if (!isValid) {
            this.setState({
                selectedFileIsValid: isValid,
                selectedFile: selectedFile,
                fileInputErrorMessage: message,
            });

            return;
        }

        const reader = new FileReader();
        let firstRowData;
        let dataCsv;
        try {
            reader.readAsText(file);
            reader.onload = event => {
                const csvData = event.target.result;

                // read file headers with Papaparse
                Papa.parse(csvData, {
                    skipEmptyLines: true,
                    header: true,
                    complete: result => {
                        firstRowData = result.data[0];
                        dataCsv = result.data;
                    },
                });

                if (firstRowData === undefined) {
                    this.setState({
                        selectedFileIsValid: false,
                        selectedFile: null,
                        fileInputErrorMessage:
                            "Sorry, this file doesn't appear to have data. Please check it and try again.",
                    });
                } else {
                    // validate the file
                    if (this.validateHeadersCSVFile(firstRowData)) {
                        this.setState(
                            {
                                selectedFileIsValid: true,
                                selectedFile: file,
                                fileInputErrorMessage: null,
                            },
                            () => this.validateForm()
                        );
                        this.validateDataCSVFile(firstRowData, dataCsv);
                    } else {
                        this.setState({
                            selectedFileIsValid: false,
                            selectedFile: null,
                            fileInputErrorMessage:
                                "Sorry, this file doesn't appear to be in the correct format. Please check it and try again.",
                        });
                    }
                }
            };
            reader.onerror = () => {
                this.setState({
                    selectedFileIsValid: false,
                    selectedFile: null,
                    fileInputErrorMessage:
                        'Sorry, there was a problem reading this file. Please check it and try again.',
                });
            };
        } catch (error) {
            this.setState({
                selectedFileIsValid: false,
                selectedFile: null,
                fileInputErrorMessage: 'Sorry, there was a problem reading this file. Please check it and try again.',
            });
        }
    };

    uploadCSVFile = file => {
        const data = new FormData();
        data.append('upload_type', 'keywords');
        data.append('file', file);
        data.append('product', this.state.selectedGoalId.toString());
        data.append('account_id', this.props.account.id);

        Axios({
            method: 'POST',
            url: generateUrlDetail(this.props.account.token, 'upload', 'csv-importer', [], false),
            data: data,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        keywordUploadId: response.data.upload_id,
                        showUploadProgress: true,
                        processId: response.data.pid,
                        fileUploadId: response.data.file_upload_id,
                        uploadProgress: 0.0,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    showLoading: false,
                    uploadError: true,
                    marketsError: false,
                });
            });
    };

    validateHeadersCSVFile = data => {
        // check for the correct columns in csv
        const fileHeaders = data;
        const fileHeadersSorted = Object.keys(fileHeaders)
            .sort()
            .map(header => {
                return header.toLowerCase();
            });
        return (
            this.fileHeaders.length === fileHeadersSorted.length &&
            this.fileHeaders.every((header, index) => {
                return header === fileHeadersSorted[index];
            })
        );
    };

    validateDataCSVFile = (headerData, data) => {
        // check for the correct columns in csv
        const csvData = data;

        const fileHeaders = headerData;
        const fileHeadersSorted = Object.keys(fileHeaders)
            .sort()
            .map(header => {
                return header;
            });

        fileHeadersSorted.map(header => {
            let checkError = true;
            csvData.map(data => {
                let isError = false;
                if (header === 'Keyword' || header === 'Market') {
                    if (data[header] === '') {
                        if (checkError) {
                            isError = true;
                            checkError = false;
                        }
                    }
                }

                if (header === 'Active') {
                    if (data[header] === '0' || data[header] === '1') {
                        let isNumber = Number.isNaN(parseInt(data[header]));
                        if (checkError) {
                            if (isNumber) {
                                isError = true;
                                checkError = false;
                            }
                        }
                    } else {
                        isError = true;
                        checkError = false;
                    }
                }
                if (isError) {
                    this.setState({
                        selectedFileIsValid: false,
                        selectedFile: null,
                        fileInputErrorMessage:
                            'Sorry, the column ' +
                            header +
                            ' doesn’t appear to be in the correct format. Please check our documentation and re-upload.',
                    });
                }
                return isError;
            });
            return checkError;
        });
    };

    onUploadClick = () => {
        this.setState({
            uploadButtonDisabled: true,
            showLoading: true,
        });
        this.uploadCSVFile(this.state.selectedFile);
    };

    fetchUploadProgress = () => {
        const getProgress = () => {
            if (this.state.uploadProgress !== '100.00' && !this.state.pageError) {
                Axios({
                    method: 'GET',
                    url: generateUrl('config', 'file-upload', [{ key: 'id', value: this.state.fileUploadId }]),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(response => {
                        if (response.data.objects[0].error) {
                            this.setState({
                                showLoading: false,
                                marketsError: false,
                                uploadError: true,
                            });
                            if (response.data.objects[0].stage === 'check_markets') {
                                this.setState({
                                    showLoading: false,
                                    marketsError: true,
                                    uploadError: false,
                                    uploadProgress: response.data.objects[0].progress,
                                });
                            }
                        } else {
                            this.setState({
                                showLoading: false,
                                uploadProgress: response.data.objects[0].progress,
                            });
                        }
                    })
                    .catch(() => {
                        this.setState({
                            showLoading: false,
                            pageError: true,
                        });
                    });
            }

            if (this.state.uploadProgress !== '100.00' && !this.state.uploadError && !this.state.marketsError) {
                setTimeout(getProgress, 2000);
            } else {
                this.setState({
                    returningMessage: null,
                });
            }
        };

        if (this.state.uploadProgress !== '100.00') {
            getProgress();
        }
    };

    renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    renderUploadAccordion = () => {
        const accordion = [
            {
                header: 'Add Keywords',
                required: false,
                open: this.state.accordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Goal:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy:
                                    'The goal selected will be the default when predicting SEO Opportunity Sales and Revenue.',
                                inputKeyValue: 'add-keywords__product',
                                inputOptions: this.state.goals,
                                inputOnChange: this.onProductInputChange,
                            },
                            {
                                label: 'Keyword CSV File:',
                                type: 'file-upload',
                                requiredField: true,
                                toolTipCopy:
                                    'Select your CSV to upload, assuring it is in the same format as the template.',
                                inputKeyValue: 'add-keywords__file',
                                inputOnChange: this.onFileInputChange,
                                isValid: this.state.selectedFileIsValid,
                                uploadedFile: this.state.selectedFile?.name,
                                errorMessage: this.state.fileInputErrorMessage,
                            },
                        ],
                        columns: 1,
                        buttons: [
                            {
                                value: 'UPLOAD',
                                onClick: this.onUploadClick,
                                disabled: this.state.uploadButtonDisabled,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordion} />;
    };

    downloadKeywordsMarketCSV = async () => {
        this.setState({
            isDownloading: true,
        });

        const url = generateUrlDetail(this.props.account.token, 'download', 'kwmarket', [], false);
        const filename = this.props.account.token + '-kwmarket.csv';
        return await Axios({
            method: 'GET',
            url: url,
            responseType: 'blob',
            withCredentials: true,
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            this.setState({
                isDownloading: false,
            });
        });
    };

    render() {
        if (this.state.isLoading || this.state.showLoading) {
            return (
                <div className="modal__side-panel__add-csv manage-modals">
                    <this.renderModalNavigation />
                    <h2>Add Keywords</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__add-csv manage-modals">
                    <this.renderModalNavigation />
                    <h2>Add Keywords</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        if (this.state.uploadError) {
            return (
                <div className="modal__side-panel__add-csv manage-modals">
                    <this.renderModalNavigation />
                    <h2>Add Keywords</h2>
                    <WarningMessage copy="There was a problem with your file upload. Please check your file, try again later or contact support@cubed.email." />
                </div>
            );
        }

        if (this.state.marketsError) {
            return (
                <div className="modal__side-panel__add-new-csv manage-modals">
                    <this.renderModalNavigation />
                    <h2>Add Keywords</h2>
                    <WarningMessage copy="CSV failed due to incorrect formatting within the Market column. Please re-upload, assuring Markets are in the required format, for example GB." />
                </div>
            );
        }

        if (this.state.showUploadProgress) {
            const progressCopy = `File upload ${
                this.state.uploadProgress === '100.00' ? 'complete!' : 'in progress...'
            }`;
            return (
                <div className="modal__side-panel__add-csv manage-modals">
                    <this.renderModalNavigation />
                    <h2>Add Keywords</h2>
                    <div className="file-upload-progress">
                        {this.state.returningMessage && <p>{this.state.returningMessage}</p>}
                        <p>{progressCopy}</p>
                        <ProgressBar
                            value={parseFloat(this.state.uploadProgress) / 100}
                            difference={100}
                            label="Upload Progress"
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <this.renderModalNavigation />
                <h2>Add Keywords</h2>
                <p>
                    Import your keyword research data into Cubed to enable advanced SEO reporting.{' '}
                    <b>It is important to use the defined CSV format</b> - you can download it{' '}
                    <a href="/files/kwr_template.csv">here</a>.
                </p>
                <p>Below, a goal must be selected to enable Cubed to model sales and revenue.</p>
                <p>
                    For more information on uploading your keywords and the CSV template, please see our{' '}
                    <a href="https://tag.docs.withcubed.com/onboarding/seo">documentation</a>.
                </p>
                <this.renderUploadAccordion />
                <p className="last-paragrph">
                    To download your keyword research document, please use the export button below.
                </p>{' '}
                <br />
                <InputButton
                    value={'Export'}
                    onClick={this.downloadKeywordsMarketCSV}
                    isLoading={this.state.isDownloading}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        removeModal: () => {
            dispatch(removeModal());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalAddKeywords);
