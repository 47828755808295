import tinycolor, { Instance } from 'tinycolor2';

// Resources
import {
    EVENTS_RESOURCE,
    IMPRESSION_RESOURCE,
    VISITS_RESOURCE,
    VIEWS_RESOURCE,
    ResourceFields,
    TAG_VALIDATION_RESOURCE,
} from './resources';

// Fields
import { URL } from '../common/table-fields';
import {
    CAMPAIGN,
    CONTENT,
    EVENT_NAME,
    FIRST_VISIT,
    IMPRESSION_FIRST_VISIT,
    MEDIUM,
    LAST_VISIT,
    REVENUE,
    SOURCE,
    TERM,
    TRANSACTION_ID,
    VALIDATION_BROWSER,
    VALIDATION_HOST,
    VALIDATION_PATH,
    VALIDATION_OS,
    VALIDATION_MESSAGE,
    VALIDATION_NAME,
    VALIDATION_VALUE,
    VALIDATION_ERRORED_NAME,
} from './table-fields';

// Types
import { Pagination } from '../../layouts/health-dashboard/types';
import { TableFields } from '../../types';

type TabListItems = {
    tabName: string;
    linkedResourceKey: number;
    displayName: string;
    isSelected: boolean;
    metrics: Array<TableFields>;
};

type Table = {
    tabListItems: Array<TabListItems>;
};

type TableApiKeys = {
    GROUP_BY: string;
    ORDER_BY: string;
    PRODUCT_IN: string;
    LIMIT: string;
    OFFSET: string;
};

type SparkItemsOptions = {
    HIDE_TOTAL: boolean;
};
export type SparkItems = {
    id: string;
    group: string;
    name: string;
    colour: Instance;
    dimension: string;
    periodName: string;
    options: SparkItemsOptions;
    dateDimension: string;
};
type PageConfiguration = {
    PAGE_TITLE: string;
    RESOURCES: Array<ResourceFields>;
    TABLE: Table;
    PAGINATIONS: Pagination;
    MAX_RECORDS_AMOUNTS: number;
    TABLE_TRIGGERS: Array<string>;
    TABLE_API_KEYS: TableApiKeys;
    SPARK_ITEMS: Array<SparkItems>;
};

export const PAGE_CONFIGURATION: PageConfiguration = {
    PAGE_TITLE: 'Account Health',
    RESOURCES: [VISITS_RESOURCE, VIEWS_RESOURCE, EVENTS_RESOURCE, IMPRESSION_RESOURCE, TAG_VALIDATION_RESOURCE],
    TABLE: {
        tabListItems: [
            {
                tabName: 'visits',
                linkedResourceKey: 0,
                displayName: 'Visits',
                isSelected: true,
                metrics: [FIRST_VISIT, LAST_VISIT],
            },
            {
                tabName: 'pages',
                linkedResourceKey: 1,
                displayName: 'Pages',
                isSelected: false,
                metrics: [URL],
            },
            {
                tabName: 'events',
                linkedResourceKey: 2,
                displayName: 'Events',
                isSelected: false,
                metrics: [EVENT_NAME, TRANSACTION_ID, REVENUE],
            },

            {
                tabName: 'impressions',
                linkedResourceKey: 3,
                displayName: 'Impressions',
                isSelected: false,
                metrics: [IMPRESSION_FIRST_VISIT, MEDIUM, SOURCE, CAMPAIGN, CONTENT, TERM],
            },
            {
                tabName: 'tag-validation',
                linkedResourceKey: 4,
                displayName: 'Errors',
                isSelected: false,
                metrics: [
                    VALIDATION_NAME,
                    VALIDATION_ERRORED_NAME,
                    VALIDATION_MESSAGE,
                    VALIDATION_VALUE,
                    VALIDATION_OS,
                    VALIDATION_BROWSER,
                    VALIDATION_HOST,
                    VALIDATION_PATH,
                ],
            },
        ],
    },
    PAGINATIONS: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        currentRowCount: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
    MAX_RECORDS_AMOUNTS: 1000,
    TABLE_TRIGGERS: [
        'startDate',
        'endDate',
        'tableGroupBy',
        'dimensions',
        'resource',
        'page',
        'tableOrderBy',
        'tableOrderDir',
        'rowCount',
        'searchCategory',
        'searchTerm',
        'selectedProducts',
        'dimensionFilters',
        'limit',
    ],
    TABLE_API_KEYS: {
        GROUP_BY: 'group_by',
        ORDER_BY: 'order_by',
        PRODUCT_IN: 'product__in',
        LIMIT: 'limit',
        OFFSET: 'offset',
    },
    SPARK_ITEMS: [
        {
            id: 'visitsSpark',
            group: 'health',
            name: 'health-visits-count',
            colour: new tinycolor('#58b5c4'),
            dimension: 'Views',
            periodName: 'minute',
            options: {
                HIDE_TOTAL: true,
            },
            dateDimension: 'last_visit',
        },
        {
            id: 'eventsSpark',
            group: 'health',
            name: 'health-events-count',
            colour: new tinycolor('#FF9800'),
            dimension: 'Events',
            periodName: 'minute',
            options: {
                HIDE_TOTAL: true,
            },
            dateDimension: 'created',
        },
        {
            id: 'impressionSpark',
            group: 'health',
            name: 'health-impression-count',
            colour: new tinycolor('#4CAF50'),
            dimension: 'Impressions',
            periodName: 'minute',
            options: {
                HIDE_TOTAL: true,
            },
            dateDimension: 'first_visit',
        },
        {
            id: 'errorssSpark',
            group: 'health',
            name: 'health-tagvalidation-count',
            colour: new tinycolor('#D2004A'),
            dimension: 'Tag Validation Errors',
            periodName: 'minute',
            options: {
                HIDE_TOTAL: true,
            },
            dateDimension: 'created',
        },
    ],
};
