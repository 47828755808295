import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Redux

import { useDispatch } from 'react-redux';
import { removeModal, setModal } from '../../../redux/actions/modal';
import { removePopup, setPopup } from '../../../redux/actions/popup';
import { addNotification } from '../../../redux/actions/notification';

// Helpers
import { generatePath } from '../../../helpers/request-builder';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { ConfigDataSuccess } from '../../../react-query/types';
import { FieldValues } from 'react-hook-form';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePatchMultiResourceWithPayload from '../../../react-query/hooks/use-patch-multi-resource-with-payload';
import usePatchResource from '../../../react-query/hooks/use-patch-resource';

// Config
import { CONFIG_SEOGD_COMPETITOR, CONFIG_SEOGD_MARKET } from '../../../configurations/resources-config';

// Components
import ModalNavigation from '../../../components/modal-navigation';
import ConfigTable from '../../../components/tables/config-table/config-table';
import CompetitorForm from './forms/competitor-form';
import Form from '../../../components/forms/form';

const StyledEditContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 10px 20px;
    margin-top: 20px;
`;

type Competitor = {
    id: number;
    brandName: string;
    domain: string;
    market: string;
    selected: boolean;
};

const LayoutModalManageCompetitors = () => {
    const dispatch = useDispatch();

    const [competitors, setCompetitors] = useState<Competitor[]>([]);
    const [competitorToDelete, setCompetitorToDelete] = useState<number>();
    const [competitorToEdit, setCompetitorToEdit] = useState<Competitor>();

    // Queries
    const competitorQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_SEOGD_COMPETITOR,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
    });

    useEffect(() => {
        if (competitorQuery.data) {
            setCompetitors(
                competitorQuery.data.objects.map(competitor => ({
                    id: competitor.id,
                    brandName: competitor.brand_name,
                    domain: competitor.domain,
                    market: competitor.market.country,
                    selected: false,
                }))
            );
        }
    }, [competitorQuery.data]);

    const marketQuery = useFetchResource({
        resource: CONFIG_SEOGD_MARKET,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
            { key: 'order_by', value: 'country' },
        ],
        select: (data: ConfigDataSuccess) =>
            data.objects.map(market => {
                return {
                    label: market.country,
                    value: market.id,
                };
            }),
    });

    // Mutations
    const competitorBulkDeleteMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_SEOGD_COMPETITOR,
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'Competitors successfully removed', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while removing these competitors. Please try again later.',
                    type: 'error',
                })
            );
        },
    });

    const competitorSingleDeleteMutation = usePatchResource({
        resource: CONFIG_SEOGD_COMPETITOR,
        resourceId: competitorToDelete?.toString() || '0',
        data: { active: 0 },
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'Competitor successfully removed', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while removing this competitor. Please try again later.',
                    type: 'error',
                })
            );
        },
    });

    const competitorEditMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_SEOGD_COMPETITOR,
        handleOnSuccess: () => {
            setCompetitorToEdit(undefined);
            dispatch(addNotification({ copy: 'Competitor successfully updated', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while updating this competitor. Please try again later.',
                    type: 'error',
                })
            );
        },
    });

    // Handlers
    const handleCheckboxChange = (rowId: number) => {
        setCompetitors(
            competitors.map(competitor => {
                if (competitor.id === rowId) {
                    return {
                        ...competitor,
                        selected: !competitor.selected,
                    };
                }

                return competitor;
            })
        );
    };

    // Bulk Delete
    const handleBulkDelete = () => {
        competitors
            .filter(competitor => competitor.selected)
            .forEach(competitor => {
                competitorBulkDeleteMutation.mutate({
                    payload: [{ active: 0 }],
                    resourceId: [competitor.id.toString()],
                });
            });
        dispatch(removePopup());
    };

    const handleBulkDeleteClick = () => {
        dispatch(
            setPopup({
                title: 'Remove Competitors',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove these competitors?',
                },
                buttons: [
                    {
                        value: 'Yes, Remove',
                        onClick: handleBulkDelete,
                    },
                    {
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                        onClick: () => dispatch(removePopup()),
                    },
                ],
            })
        );
    };

    // Single Delete
    const handleSingleDelete = () => {
        dispatch(removePopup());
        competitorSingleDeleteMutation.mutate();
    };

    const handleSingleDeleteClick = (rowId: number) => {
        setCompetitorToDelete(rowId);

        dispatch(
            setPopup({
                title: 'Remove Competitor',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove this competitor?',
                },
                buttons: [
                    {
                        value: 'Yes, Remove',
                        onClick: handleSingleDelete,
                    },
                    {
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                        onClick: () => dispatch(removePopup()),
                    },
                ],
            })
        );
    };

    //Submit Form
    const handleFormSubmit = (data: FieldValues) => {
        competitorEditMutation.mutate({
            payload: [
                {
                    brand_name: data.brandName,
                    domain: data.domainName,
                    market: generatePath('config', 'seogd-market', data.market),
                },
            ],
            resourceId: [competitorToEdit?.id.toString() || '0'],
        });
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(removeModal()),
                        disabled: false,
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />

            <h2>Manage Competitors</h2>

            <ConfigTable
                status={competitorQuery.status}
                isFetching={
                    competitorQuery.isFetching ||
                    competitorBulkDeleteMutation.isPending ||
                    competitorSingleDeleteMutation.isPending
                }
                disabled={competitorBulkDeleteMutation.isPending}
                empty={competitorQuery.data?.objects.length === 0}
            >
                <ConfigTable.Table>
                    <ConfigTable.Header>
                        <ConfigTable.Row key="competitor-header">
                            <ConfigTable.CellHeader />
                            <ConfigTable.CellHeader>Brand Name</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Domain</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Market</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader />
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {competitors.map(competitor => {
                            return (
                                <ConfigTable.Row key={competitor.id}>
                                    <ConfigTable.CellCheckbox
                                        rowId={competitor.id}
                                        checked={competitor.selected}
                                        onCheckedChange={handleCheckboxChange}
                                    />

                                    <ConfigTable.Cell>{competitor.brandName}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{competitor.domain}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{competitor.market}</ConfigTable.Cell>
                                    <ConfigTable.CellActions>
                                        <ConfigTable.ActionDropdownItem
                                            rowId={competitor.id}
                                            type="edit"
                                            label="Edit"
                                            onClick={() => setCompetitorToEdit(competitor)}
                                        />
                                        <ConfigTable.ActionDropdownItem
                                            rowId={competitor.id}
                                            type="delete"
                                            label="Remove"
                                            onClick={handleSingleDeleteClick}
                                        />
                                    </ConfigTable.CellActions>
                                </ConfigTable.Row>
                            );
                        })}
                    </ConfigTable.Body>
                </ConfigTable.Table>
                <ConfigTable.ActionBar>
                    <ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionButton
                            type="delete"
                            label="Remove"
                            onClick={handleBulkDeleteClick}
                            isDisabled={competitors.filter(competitor => competitor.selected).length === 0}
                        />
                    </ConfigTable.ActionBarJustifyLeft>
                    <ConfigTable.ActionBarJustifyRight>
                        <ConfigTable.ActionButton
                            type="add"
                            label="Add New Competitor"
                            onClick={() => dispatch(setModal('AddCompetitors', {}))}
                            isDisabled={false}
                        />
                    </ConfigTable.ActionBarJustifyRight>
                </ConfigTable.ActionBar>
            </ConfigTable>

            {competitorToEdit && (
                <StyledEditContainer>
                    <h2>Edit Competitor</h2>

                    <Form
                        defaultValues={{
                            brandName: competitorToEdit.brandName,
                            domainName: competitorToEdit.domain,
                            market:
                                marketQuery?.data?.find(market => String(market.label) === competitorToEdit.market)
                                    ?.value || '',
                        }}
                        status={
                            marketQuery.status === 'pending'
                                ? 'loading'
                                : competitorEditMutation.isPending
                                ? 'disabled'
                                : 'enabled'
                        }
                        onSubmit={handleFormSubmit}
                    >
                        <CompetitorForm markets={marketQuery.data || []} />
                        <Form.Footer>
                            <Form.InputButton type="submit" value="Save" loading={competitorEditMutation.isPending} />
                            <Form.InputButton
                                type="button"
                                value="Cancel"
                                buttonTheme={ButtonThemes.Secondary}
                                onClick={() => setCompetitorToEdit(undefined)}
                            />
                        </Form.Footer>
                    </Form>
                </StyledEditContainer>
            )}
        </>
    );
};

export default LayoutModalManageCompetitors;
