// React Dependencies
import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    font-family: inherit;
    width: 100%;
    height: 40px;
    background-color: ${props => props.theme.colours.inputColour};
    border: none;
    border-bottom: solid 2px ${props => props.theme.colours.offBlackLighter};
    padding: 0 5px;
    margin-bottom: 5px;
    border-radius: 2px;

    &:focus {
        outline: none;
    }

    &::placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }
`;

export type InputDatePickerProps = {
    value: string;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
};

const InputDatePicker = ({ value, onChange }: InputDatePickerProps) => {
    return <StyledInput type="date" value={value} onChange={onChange} />;
};

export default InputDatePicker;
