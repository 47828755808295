import React from 'react';

import { useConfigDragAndDropTableContext } from '../context/config-drag-and-drop-table-context';

const Header = ({ children }: { children: React.ReactNode }) => {
    const { status } = useConfigDragAndDropTableContext();

    if (status) {
        return <thead>{children}</thead>;
    }

    return null;
};

export default Header;
