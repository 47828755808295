import React from 'react';
import styled from 'styled-components';

// Styles
const StyledPaginationListItem = styled.li<{ disabled: boolean; hidden: boolean }>`
    display: inline-block;
    font-size: 13px;
    margin: 0 2px;
    padding: 0 10px;
    user-select: none;
    text-align: center;
    border: 1px solid ${props => props.theme.colours.shadowGrey};
    background-color: ${props => (props.hidden ? props.theme.colours.offLight : props.theme.colours.borderGrey)};
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    min-width: 38px;
    border-radius: 3px;
    line-height: 36px;
    cursor: ${props => (props.disabled ? `not-allowed !important` : props.hidden ? 'auto' : 'pointer')};
    border-color: ${props => props.hidden && props.theme.colours.borderGrey};
    color: ${props => props.hidden && props.theme.colours.borderGrey};

    &:hover {
        background-color: ${props =>
            !props.hidden ? (props.disabled ? props.theme.colours.borderGrey : props.theme.colours.midGrey) : ''};
    }

    &:active {
        background-color: ${props => !props.hidden && props.theme.colours.primaryLight};
    }
`;

// Types
type TablePaginationPageIncreaseItemTypeProps = {
    ownerCallback: (page: number) => void;
    minPage: number;
    maxPage: number;
    currentPage: number;
    increment: number;
    disabled: boolean;
    children: string;
};

const TablePaginationPageIncreaseItem = ({
    ownerCallback,
    minPage,
    maxPage,
    currentPage,
    increment,
    disabled,
    children,
}: TablePaginationPageIncreaseItemTypeProps) => {
    const checkIfInactive = () => {
        if (increment < 0) {
            if (currentPage <= minPage) {
                return true;
            }
        } else if (increment > 0) {
            if (currentPage >= maxPage) {
                return true;
            }
        }
    };

    const handleClick = () => {
        if (checkIfInactive() || (disabled !== undefined && disabled)) return;
        ownerCallback(currentPage + increment);
    };

    return (
        <StyledPaginationListItem onClick={handleClick} disabled={disabled} hidden={checkIfInactive()!}>
            {children}
        </StyledPaginationListItem>
    );
};

export default TablePaginationPageIncreaseItem;
