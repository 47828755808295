import React, { ChangeEvent } from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';
import InputSelect, { SelectOption } from '../inputs/input-select';
import Tooltip from '../tooltip';

const StyledFormField = styled.div`
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 15px;
`;

const StyledFormFieldTop = styled.div`
    margin-bottom: 5px;
`;

const StyledLabel = styled.label`
    font-size: 1em;
    font-weight: 400;
`;

const StyledRequiredField = styled.span`
    color: ${props => props.theme.colours.red};
`;

const StyledErrorMessage = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colours.red};
    margin-bottom: 1em;
`;

export type FormSelectProps = {
    inputKeyValue: string;
    label?: string;
    customPlaceholder?: string;
    inputOptions: SelectOption[];
    inputOnChange: (() => void) | ((event: ChangeEvent<HTMLInputElement>) => void);
    requiredField?: boolean;
    inputValue: string | number;
    infoMessage?: string;
    errorMessage?: string;
    toolTipCopy?: string;
};

const FormSelect = ({
    inputKeyValue,
    label,
    customPlaceholder,
    inputOptions,
    inputOnChange,
    requiredField,
    inputValue,
    infoMessage,
    errorMessage,
    toolTipCopy,
}: FormSelectProps) => {
    return (
        <StyledFormField key={inputKeyValue}>
            <StyledFormFieldTop>
                <StyledLabel>
                    {label} {requiredField && <StyledRequiredField>*</StyledRequiredField>}
                </StyledLabel>
                {toolTipCopy && <Tooltip copy={toolTipCopy} />}
            </StyledFormFieldTop>

            <InputSelect
                customPlaceholder={customPlaceholder}
                options={inputOptions}
                onChange={inputOnChange}
                required={requiredField}
                value={inputValue}
            />

            {errorMessage && <StyledErrorMessage>{parse(errorMessage)}</StyledErrorMessage>}
            {infoMessage && <p>{parse(infoMessage)}</p>}
        </StyledFormField>
    );
};

export default FormSelect;
