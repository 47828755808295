import React from 'react';
import styled from 'styled-components';

import TableSkeleton from './table-skeleton';

const StyledSkeletonBreadcrumbsWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
`;

const StyledSkeletonBreadcrumbs = styled.div`
    width: 100%;
    margin: 12px 0;
    height: 36px;
    border-radius: 12px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const StyledSkeletonChartOptions = styled.div`
    display: inline-block;
    min-height: 400px;
    border-radius: 6px;
    position: relative;
    width: 320px;
    z-index: 2;
`;

const StyledSkeletonChartOptionsItem = styled.div`
    width: 100%;
    position: relative;
    height: 42px;
    margin-bottom: 12px;
    border-radius: 12px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const StyledSkeletonChart = styled.div`
    display: inline-block;
    width: calc(100% - 332px);
    min-height: 320px;
    border-radius: 12px;
    margin: 0 0 80px 12px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const TableGraphSkeleton = () => {
    return (
        <div>
            <StyledSkeletonBreadcrumbsWrapper>
                <StyledSkeletonBreadcrumbs />
            </StyledSkeletonBreadcrumbsWrapper>

            <div>
                <StyledSkeletonChartOptions>
                    <StyledSkeletonChartOptionsItem />
                    <StyledSkeletonChartOptionsItem />
                    <StyledSkeletonChartOptionsItem />
                    <StyledSkeletonChartOptionsItem />
                    <StyledSkeletonChartOptionsItem />
                    <StyledSkeletonChartOptionsItem />
                </StyledSkeletonChartOptions>

                <StyledSkeletonChart />
            </div>

            <TableSkeleton />
        </div>
    );
};

export default TableGraphSkeleton;
