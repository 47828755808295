import React from 'react';
import ConfigTable from '../../../../components/tables/config-table/config-table';
import { QueryStatus } from '@tanstack/react-query';
import styled from 'styled-components';
import { ButtonSpaces } from '../../../../components/tables/config-table/components/actions/action-bar/action-bar';

const StyledEventTitle = styled.h3`
    font-size: 1.1rem;
    color: ${props => props.theme.colours.offBlackLighter};
    font-weight: 600;
`;

export type Event = {
    id: number;
    name: string;
    sale: boolean;
    selected: boolean;
};

type TableAssignToMarketProps = {
    status: QueryStatus;
    disabled: boolean;
    empty: boolean;
    events: Event[];
    handleEventSelectChange: (rowId: number) => void;
};

const TableAssignToMarket = ({
    status,
    disabled,
    empty,
    events,
    handleEventSelectChange,
}: TableAssignToMarketProps) => {
    return (
        <>
            <StyledEventTitle>Assign Sale Event</StyledEventTitle>
            <p>Choose the event(s) which signify the completion of your goal.</p>

            <ConfigTable status={status} isFetching={false} disabled={disabled} empty={empty}>
                <ConfigTable.Table maxHeight="400px">
                    <ConfigTable.Header>
                        <ConfigTable.Row key={'events-header'}>
                            <ConfigTable.CellHeader />

                            <ConfigTable.CellHeader>Event</ConfigTable.CellHeader>
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {events.map(event => {
                            return (
                                <ConfigTable.Row key={event.id}>
                                    <ConfigTable.CellCheckbox
                                        rowId={event.id}
                                        checked={event.selected!}
                                        onCheckedChange={handleEventSelectChange}
                                    />
                                    <ConfigTable.Cell>{event.name}</ConfigTable.Cell>
                                </ConfigTable.Row>
                            );
                        })}
                    </ConfigTable.Body>
                </ConfigTable.Table>
                <ConfigTable.ActionBar spacing={ButtonSpaces.FLEX_END}>
                    <ConfigTable.ActionBarJustifyRight>
                        <ConfigTable.ActionButton
                            type="primary"
                            label="Save Goal"
                            onClick={() => {}}
                            isDisabled={disabled}
                        />
                    </ConfigTable.ActionBarJustifyRight>
                </ConfigTable.ActionBar>
            </ConfigTable>
        </>
    );
};

export default TableAssignToMarket;
