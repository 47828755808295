import {
    Connection,
    FormattedAdwordsConnections,
    AccountLookupResponse,
} from '../../layouts/modals/google-unified-oauth-api';

import Axios from 'axios';
import { generateUrl, generateUrlDetail } from '../request-builder';

type GenericResponseForRemoveItems = {
    id: string;
};

const delete_connection_data = async (url: string) => {
    await Axios.delete(url, { withCredentials: true });
};

// const removeItems(url: string, resourceName: string) {
const removeItems = async (url: string, resourceName: string) => {
    const items = await Axios.get(url, { withCredentials: true });
    await Promise.all(
        items.data.objects.map(async (item: GenericResponseForRemoveItems) => {
            const itemUrl = generateUrlDetail('integrations', resourceName, item.id.toString())!;
            await delete_connection_data(itemUrl);
        })
    );
};

export const removeParentConnection = async (parentConnection: Connection | FormattedAdwordsConnections) => {
    // remove adwords account, this will fire a set of requests to remove adwords account
    // mcc account, and lookup table too
    await removeAdwordConnectionAccount(parentConnection.id.toString());

    // remove youtube status, gsc, service and deactivated
    const youtubeStatusUrl = generateUrl('integrations', 'api-youtube-connection-detail', [
        { key: 'connection__id', value: parentConnection.id },
    ])!;
    await removeItems(youtubeStatusUrl, 'api-youtube-connection-detail');

    const gscUrl = generateUrl('integrations', 'api-searchconsole-siteurl', [
        { key: 'oauth_connection__id', value: parentConnection.id },
    ])!;
    await removeItems(gscUrl, 'api-searchconsole-siteurl');

    const deactivatedUrl = generateUrl('integrations', 'api-google-connection-deactivated', [
        { key: 'connection__id', value: parentConnection.id },
    ])!;
    await removeItems(deactivatedUrl, 'api-google-connection-deactivated');

    const serviceUrl = generateUrl('integrations', 'api-google-connection-service', [
        { key: 'connection__id', value: parentConnection.id },
    ])!;
    await removeItems(serviceUrl, 'api-google-connection-service');

    // remove google connection
    const url = generateUrlDetail('integrations', 'api-google-connection', parentConnection.id.toString())!;

    await delete_connection_data(url);
};

const removeAdwordConnectionAccount = async (parentConnectionId: string) => {
    const url = generateUrl('integrations', 'api-adwords-connection-detail', [
        { key: 'connection__id', value: parentConnectionId },
    ])!;
    const adwordsConnectionResponse = await Axios.get(url, { withCredentials: true });
    const accounts = adwordsConnectionResponse.data.objects;

    // remove lookup, mcc account, and normal account first
    await Promise.all(
        accounts.map((account: FormattedAdwordsConnections) => {
            return removeAdwordAccountAndMCCAccount(account.id.toString());
        })
    );

    // remove adwords connection detail
    removeItems(url, 'api-adwords-connection-detail');
};

const removeAdwordAccountAndMCCAccount = async (parentAdwordAccountId: string) => {
    const mccAccountUrl = generateUrl('integrations', 'api-adwords-connection-mcc-account', [
        { key: 'connection_detail__id', value: parentAdwordAccountId.toString() },
    ])!;
    const mccAccountResponse = await Axios.get(mccAccountUrl, { withCredentials: true });
    const accounts = mccAccountResponse.data.objects;

    // remove lookup table first
    await Promise.all(
        accounts.map(async (account: AccountLookupResponse) => {
            const mccAccountId = account.id.toString();
            await removeLookupAccountFromMCCAccount(mccAccountId);
        })
    );

    // remove mcc account
    await removeItems(mccAccountUrl, 'api-adwords-connection-mcc-account');

    // remove normal account
    const normalAccountUrl = generateUrl('integrations', 'api-adwords-connection-account', [
        { key: 'connection_detail__id', value: parentAdwordAccountId.toString() },
    ])!;
    await removeItems(normalAccountUrl, 'api-adwords-connection-account');
};

export const removeAdwordAccountConnectionDetail = async (customerAccountId: string) => {
    await removeAdwordAccountAndMCCAccount(customerAccountId);
    const url = generateUrlDetail('integrations', 'api-adwords-connection-detail', customerAccountId.toString())!;
    await delete_connection_data(url);
};

const removeLookupAccountFromMCCAccount = async (mccAccountId: string) => {
    const url = generateUrl('integrations', 'api-adwords-connection-account-lookup', [
        { key: 'mcc_account__id', value: mccAccountId.toString() },
    ])!;
    await removeItems(url, 'api-adwords-connection-account-lookup');
};
