import React from 'react';
import styled from 'styled-components';
import { Instance } from 'tinycolor2';

import Funnel from '../components/funnel';

const StyledFunnelWidget = styled.div<{ isChannel: boolean }>`
    display: inline-block;
    position: relative;
    width: ${props => (props.isChannel ? `calc(25% - 6px)` : `calc(75% - 6px)`)};
    height: ${props => (props.isChannel ? `240px` : `480px`)};
    max-height: 480px;
    padding: 6px;
    margin: 3px;
    ${props => props.theme.boxShadow(1)};
`;

const StyledFunnelWidgetLoader = styled.div`
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(4px);
`;

const StyledFunnelWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledFunnelNamesHolder = styled.div`
    position: relative;
    width: 100%;
    flex: 0 1 auto;
    min-height: 66px;
`;

const StyledFunnelName = styled.div`
    position: relative;
    display: inline-block;
    text-align: center;
    padding: 6px 0;
    vertical-align: middle;

    & div {
        text-transform: uppercase;
        padding-right: 50%;
        font-size: 12px;
    }
`;

const StyledFunnelTitle = styled.div`
    text-align: center;
    width: 100%;
    font-size: 18px;
    flex: 0 1 30px;
`;

type FunnelData = { id: number; name: string; count: number };

export type WidgetFunnelProps = {
    max: number;
    data: FunnelData[];
    isLoading?: boolean;
    colour?: Instance;
    showNames?: boolean;
    title?: string;
    isChannel?: boolean;
};

const WidgetFunnel = ({ max, data, isLoading, colour, showNames = true, title, isChannel }: WidgetFunnelProps) => {
    return (
        <StyledFunnelWidget isChannel={isChannel!}>
            {isLoading && <StyledFunnelWidgetLoader />}
            <StyledFunnelWrapper>
                {title && <StyledFunnelTitle>{title}</StyledFunnelTitle>}
                <Funnel max={max} data={[...data]} colour={colour} showNames={showNames} />
                {showNames && (
                    <StyledFunnelNamesHolder>
                        {data.map((item, index) => {
                            return (
                                <StyledFunnelName key={index} style={{ width: `${100 / data.length}%` }}>
                                    <div>{item.name}</div>
                                </StyledFunnelName>
                            );
                        })}
                    </StyledFunnelNamesHolder>
                )}
            </StyledFunnelWrapper>
        </StyledFunnelWidget>
    );
};

export default WidgetFunnel;
