// React Dependencies
import React, { useState } from 'react';

// Redux
import { removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { useDispatch } from 'react-redux';

// Helpers & Enums
import { isDomain, isNumber, isString } from '../../helpers/validator';
import { ButtonThemes } from '../../enums/button-themes';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WidgetAccordion from '../../widgets/accordion';
import usePostResource from '../../react-query/hooks/use-post-resource';
import { ACCOUNT_SETUP_REQUEST } from '../../configurations/resources-config';

const LayoutModalAccountSetupRequest = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [closeButtonState, setCloseButtonState] = useState('close');
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState(true);
    const [accountDetailsAccordionOpen, setAccountDetailsAccordionOpen] = useState(true);
    const [accountName, setAccountName] = useState('');
    const [token, setToken] = useState('');
    const [domain, setDomain] = useState('');
    const [pageViews, setPageViews] = useState('');
    const [impressions, setImpressions] = useState('');
    const [accountNameErrorMessage, setAccountNameErrorMessage] = useState('');
    const [tokenErrorMessage, setTokenErrorMessage] = useState('');
    const [domainErrorMessage, setDomainErrorMessage] = useState('');
    const [pageViewsErrorMessage, setPageViewsErrorMessage] = useState('');
    const [impressionsErrorMessage, setImpressionsErrorMessage] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [accountNameForToken, setAccountNameForToken] = useState('');

    const accountRequestPostMutation = usePostResource({
        resource: ACCOUNT_SETUP_REQUEST,
        data: {
            account_name: accountName,
            token: 'c-a-' + token,
            domain: domain,
            page_views: pageViews,
            impressions: impressions,
        },
        handleOnSuccess: () => {
            onResetClick();
            dispatch(removeModal());
            dispatch(
                addNotification({
                    copy: 'The account details has been successfully sent to our staff email.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setSaveChangesButtonDisabled(false);

            dispatch(
                addNotification({
                    copy: 'There was an issue sending the details, please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const formValidator = () => {
        let hasFormError = false;
        let errorMessageObject = {
            accountNameErrorMessage: '',
            tokenErrorMessage: '',
            domainErrorMessage: '',
            pageViewsErrorMessage: '',
            impressionsErrorMessage: '',
        };

        if (!isString(accountName) || accountName.length === 0) {
            hasFormError = true;
            errorMessageObject.accountNameErrorMessage = 'Please enter a valid account name.';
        }

        if (!isDomain(domain) || domain.length === 0) {
            hasFormError = true;
            errorMessageObject.domainErrorMessage = 'Please enter a valid domain address.';
        }

        if (!isString(token) || token.length === 0) {
            hasFormError = true;
            errorMessageObject.tokenErrorMessage = 'Please enter a valid token.';
        }

        if (!isNumber(pageViews) || pageViews.length === 0) {
            hasFormError = true;
            errorMessageObject.pageViewsErrorMessage = 'Please enter a valid page views number.';
        }

        if (!isNumber(impressions) || impressions.length === 0) {
            hasFormError = true;
            errorMessageObject.impressionsErrorMessage = 'Please enter a valid impressions.';
        }

        if (hasFormError) {
            setDomainErrorMessage(errorMessageObject.domainErrorMessage);
            setAccountNameErrorMessage(errorMessageObject.accountNameErrorMessage);
            setTokenErrorMessage(errorMessageObject.tokenErrorMessage);
            setPageViewsErrorMessage(errorMessageObject.pageViewsErrorMessage);
            setImpressionsErrorMessage(errorMessageObject.impressionsErrorMessage);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    const onSaveChangesClick = () => {
        setSaveChangesButtonDisabled(true);

        if (formValidator()) {
            accountRequestPostMutation.mutate();
        } else {
            setSaveChangesButtonDisabled(false);
        }
    };

    const tokenGenerator = (accountNameForToken: string, countryCode: string) => {
        let tokenSuggestName = accountNameForToken + '-' + countryCode;
        setToken(tokenSuggestName);
    };

    const tokenAccountName = (str: string) => {
        let accountName = str.split(' ');
        let name = accountName[0];
        let abbreviation = accountName[1] ? accountName[1].charAt(0) : '';
        let nameForToken = name + abbreviation;
        tokenGenerator(nameForToken, countryCode);
        setAccountNameForToken(nameForToken);
    };

    const countryCodeFromDomain = (url: string) => {
        url = url.indexOf('://') === -1 ? 'http://' + url : url;
        let domain = new URL(url);
        let hostName = domain.hostname;
        let split = hostName.split('.');
        let suffix = split.at(-1);
        let code;

        switch (suffix) {
            case 'uk':
                code = suffix;
                break;
            case 'au':
                code = suffix;
                break;
            case 'fr':
                code = suffix;
                break;
            case 'es':
                code = suffix;
                break;
            case 'de':
                code = suffix;
                break;
            case 'nz':
                code = suffix;
                break;
            case 'eu':
                code = suffix;
                break;
            case 'ca':
                code = suffix;
                break;
            case 'us':
                code = suffix;
                break;
            case 'nl':
                code = suffix;
                break;
            default:
                code = 'uk';
                break;
        }

        tokenGenerator(accountNameForToken, code);
        setCountryCode(code);
    };

    // PrimaryAction of the close popup
    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    // SecondaryAction of the close popup
    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const checkCloseState = () => {
        if (closeButtonState === 'close') {
            setCloseButtonState('cancel');
            setSaveChangesButtonDisabled(false);
        }
    };

    const onAccountNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkCloseState();
        tokenAccountName(event.target.value);

        setAccountName(event.target.value);
    };
    const onDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkCloseState();
        let currentDomain = event.target.value;
        if (currentDomain) {
            countryCodeFromDomain(currentDomain);
        }

        setDomain(currentDomain);
    };
    const onTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkCloseState();

        setToken(event.target.value);
    };
    const onPageViewsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkCloseState();

        setPageViews(event.target.value);
    };
    const onImpressionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkCloseState();

        setImpressions(event.target.value);
    };

    const onResetClick = () => {
        setIsLoading(false);
        setCloseButtonState('close');
        setSaveChangesButtonDisabled(true);
        setAccountDetailsAccordionOpen(true);
        setAccountName('');
        setToken('');
        setDomain('');
        setPageViews('');
        setImpressions('');
        setAccountNameErrorMessage('');
        setTokenErrorMessage('');
        setDomainErrorMessage('');
        setPageViewsErrorMessage('');
        setImpressionsErrorMessage('');
        setCountryCode('');
        setAccountNameForToken('');
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(removeModal());
        } else {
            dispatch(
                setPopup({
                    title: 'Send Request',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                    },
                    buttons: [
                        {
                            onClick: onPopupDiscardChangesClick,
                            value: 'DISCARD CHANGES',
                        },
                        {
                            onClick: onPopupStayHereClick,
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                        },
                    ],
                })
            );
        }
    };

    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'Send Request',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: accountRequestPostMutation.isPending,
            },
            {
                value: 'Reset',
                onClick: onResetClick,
                disabled: false,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: false,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const renderAccordion = () => {
        const accordions = [
            {
                header: 'Account Details',
                required: true,
                open: accountDetailsAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Account Name:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy:
                                    'Account Name, is the name we will see in the Dashboard. Example: My Client',
                                inputKeyValue: 'account-details__account-name',
                                inputPlaceholder: 'Account Name...',
                                inputValue: accountName,
                                inputOnChange: onAccountNameChange,
                                errorMessage: accountNameErrorMessage,
                            },
                            {
                                label: 'Domain:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy:
                                    'Domain is used to generate Patterns for a client. Example: myclient.com - this will then be used to generate patterns for channels like PPC (*myclient.com*gclid=*)',
                                inputKeyValue: 'account-details__domain',
                                inputPlaceholder: 'www.example.com',
                                inputValue: domain,
                                inputOnChange: onDomainChange,
                                errorMessage: domainErrorMessage,
                            },
                            {
                                label: 'Token:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy:
                                    'Token is used for the tag on the client’s site This will usually have a suffix for location. Example: my-client-uk',
                                inputKeyValue: 'account-details__token',
                                inputPlaceholder: 'example-uk',
                                inputValue: token,
                                inputOnChange: onTokenChange,
                                errorMessage: tokenErrorMessage,
                                prefix: 'c-a-',
                            },
                            {
                                label: 'Page Views:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy:
                                    'Page Views is the average number of views the site experiences over a month.',
                                inputKeyValue: 'account-details__page-views',
                                inputPlaceholder: 'Page views...',
                                inputValue: pageViews,
                                inputOnChange: onPageViewsChange,
                                errorMessage: pageViewsErrorMessage,
                            },
                            {
                                label: 'Impressions:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy:
                                    'Impressions is the average number of impressions the client expects to serve to users.',
                                inputKeyValue: 'account-details__impressions',
                                inputPlaceholder: 'Impressions...',
                                inputValue: impressions,
                                inputOnChange: onImpressionsChange,
                                errorMessage: impressionsErrorMessage,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Account Request</h2>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Account Request</h2>
            {renderAccordion()}
        </div>
    );
};

export default LayoutModalAccountSetupRequest;
