import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ButtonThemes } from '../../enums/button-themes';
import IconSpinner from '../icons/spinner';

const spinAnimation = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const StyledIconContainer = styled.div<{ buttonTheme: ButtonThemes }>`
    transform-origin: 50% 38%;
    animation: ${spinAnimation} 2s infinite linear;
    position: relative;
    top: 4px;

    & svg {
        fill: ${props => props.theme.buttons[props.buttonTheme].colorDisabled};
        height: 18px;
    }
`;

export type InputButtonLoadingSpindleProps = { buttonTheme: ButtonThemes };

const InputButtonLoadingSpindle = ({ buttonTheme }: InputButtonLoadingSpindleProps) => {
    return (
        <StyledIconContainer buttonTheme={buttonTheme}>
            <IconSpinner />
        </StyledIconContainer>
    );
};

export default InputButtonLoadingSpindle;
