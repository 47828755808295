import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CubedField } from '../../../../types';
import {
    DropdownOption,
    SelectOptionsRequest,
    SelectQuery,
    FilterOperator,
    RequestStatus,
    SectionDashboardRequest,
    WidgetMenuType,
} from '../../../types';
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess, ResourceDataSuccess } from '../../../../react-query/types';
import buildDropdownResource from '../../../../react-query/helpers/build-dropdown-resource';
import WidgetMenuSectionMultiSelect from '../components/section-menu/widget-menu-section-multi-select';
import LayoutContextProvider from '../context/layout-context';

type UseWidgetMenuSectionMultiSelectArgs = {
    query: SelectQuery;
    dropdownField: CubedField;
    emptyMessage?: string;
    label?: string;
    customIcon?: React.ReactNode;
};

const useWidgetMenuSectionMultiSelect = ({
    query,
    dropdownField,
    emptyMessage,
    label,
}: UseWidgetMenuSectionMultiSelectArgs) => {
    const [dropdownOptions, setDropdownOptions] = useState<SelectOptionsRequest>({
        status: RequestStatus.LOADING,
        options: [],
    });
    const [request, setRequest] = useState<SectionDashboardRequest>({ sectionFilters: [], showEmpty: false });
    const items = useFetchResource<ConfigDataSuccess | ResourceDataSuccess, DropdownOption[]>(query);

    useEffect(() => {
        const dropdownResource = buildDropdownResource(items);

        if (dropdownResource.options.length > 0) {
            setDropdownOptions({
                ...dropdownResource,
                options: dropdownResource.options.map(option => {
                    return {
                        ...option,
                        selected: true,
                    };
                }),
            });
        } else {
            setDropdownOptions(dropdownResource);
        }
    }, [items.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const sectionFilters = [];

        const allGoalsAreSelected = dropdownOptions.options.every(option => option.selected);
        const allGoalsAreDeselected = dropdownOptions.options.every(option => !option.selected);
        const selectedOptions = dropdownOptions.options.filter(option => option.selected);

        if (selectedOptions.length > 0 && !allGoalsAreSelected) {
            sectionFilters.push({
                field: dropdownField,
                operator: FilterOperator.IN,
                value: selectedOptions.map(goal => String(goal.value)).join(','),
            });
        }

        setRequest({
            sectionFilters: sectionFilters,
            showEmpty: dropdownOptions.status !== RequestStatus.LOADING && allGoalsAreDeselected,
        });
    }, [dropdownOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOptionChange = (option: string) => {
        setDropdownOptions({
            ...dropdownOptions,
            options: dropdownOptions.options.map(optionItem => {
                if (optionItem.value === option) {
                    return { ...optionItem, selected: !optionItem.selected };
                }
                return optionItem;
            }),
        });
    };

    const handleSingleItemRemove = (option: string) => {
        setDropdownOptions({
            ...dropdownOptions,
            options: dropdownOptions.options.map(optionItem => {
                if (optionItem.value === option) {
                    return { ...optionItem, selected: false };
                }
                return optionItem;
            }),
        });
    };

    const handleClearAll = () => {
        setDropdownOptions({
            ...dropdownOptions,
            options: dropdownOptions.options.map(optionItem => {
                return { ...optionItem, selected: false };
            }),
        });
    };

    const handleSelectAll = () => {
        setDropdownOptions({
            ...dropdownOptions,
            options: dropdownOptions.options.map(optionItem => {
                return { ...optionItem, selected: true };
            }),
        });
    };

    return {
        type: WidgetMenuType.SELECT,
        request: request,
        menu: (
            <LayoutContextProvider>
                <WidgetMenuSectionMultiSelect
                    label={label || dropdownField.displayName}
                    dropdownOptions={dropdownOptions}
                    onOptionChange={handleOptionChange}
                    onSelectedItemClick={handleSingleItemRemove}
                    onSelectAllClick={handleSelectAll}
                    onClearClick={handleClearAll}
                    emptyMessage={emptyMessage || 'No options available'}
                    key={uuidv4()}
                />
            </LayoutContextProvider>
        ),
        status: dropdownOptions.status,
    };
};

export default useWidgetMenuSectionMultiSelect;
