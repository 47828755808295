import { Moment } from 'moment';
import dataTypes from '../filter-bar/enums/data-types';
import { CubedResource } from '../types';

export const formatDates = (
    startDate: Moment,
    endDate: Moment,
    resource?: CubedResource
): { startDate: string; endDate: string } => {
    if (resource && resource.dateDimension?.dataType === dataTypes.DATE) {
        // check if we should only use Date format
        return {
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
        };
    }
    // passing Z to tell server to ignore offset
    return {
        startDate: startDate.format('YYYY-MM-DDT00:00:00') + 'Z',
        endDate: endDate.format('YYYY-MM-DDT23:59:59') + 'Z',
    };
};
