import { FilterOperator, RequestFilter } from '../types';
import { matchType } from '../../helpers/request-builder';

type UseResourceRequestParam = {
    key: string;
    value: string;
};

export const getResourceFilterSuffix = (filter: RequestFilter): string => {
    switch (filter.operator) {
        case FilterOperator.EQUALS:
            return '';
        case FilterOperator.GREATER_THAN:
            return '__gt';
        case FilterOperator.GREATER_THAN_OR_EQUALS:
            return '__gte';
        case FilterOperator.LESS_THAN_OR_EQUALS:
            return '__lte';
        case FilterOperator.LESS_THAN:
            return '__lt';
        case FilterOperator.ICONTAINS:
            return '__icontains';
        case FilterOperator.ISTARTS_WITH:
            return '__istartswith';
        case FilterOperator.IENDS_WITH:
            return '__iendswith';
        case FilterOperator.IN:
            return '__in';
        case FilterOperator.IREGEX:
            return '__iregex';
        case FilterOperator.IDOES_NOT_CONTAIN:
            return '__idoes_not_contain';
        case FilterOperator.DOES_NOT_CONTAIN:
            return '__does_not_contain';
        default:
            throw new Error('Please provide a valid operator');
    }
};

export const getResourceFilters = (
    filters: RequestFilter[],
    combinationMethod: matchType = matchType.AND
): UseResourceRequestParam[] => {
    const dimensionFilters: { key: string; value: string; matchType: string }[] = [];
    const metricFilters: string[] = [];

    filters.forEach(filter => {
        if (filter.field.isDimension) {
            dimensionFilters.push({
                key: `${filter.field.rawName}${filter.field.lookupTerm ? '__' + filter.field.lookupTerm : ''}${
                    filter.operator ? getResourceFilterSuffix(filter) : ''
                }`,
                value: filter.value,
                matchType: combinationMethod,
            });
        } else {
            metricFilters.push(
                `${filter.field.rawName}${
                    filter.field.lookupTerm ? '__' + filter.field.lookupTerm : ''
                }${getResourceFilterSuffix(filter)}=${filter.value}`
            );
        }
    });

    const havingFilters = [
        {
            key: 'having',
            value: metricFilters.join(','),
        },
    ];

    if (metricFilters.length > 0) {
        return [...havingFilters, ...dimensionFilters];
    } else {
        return dimensionFilters;
    }
};

// Regex to exclude (None) and empty strings
export const filterUnknownRegex = '^(?!\\(None\\)).*\\S.*$';
