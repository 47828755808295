import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generatePath, generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isAlphaNumericSpecialCharacterString } from '../../helpers/validator';
import { MarketResponse } from '../types';
import { DropdownOption } from '../../types';

type ErrorMessageObject = {
    editRegexTextErrorMessage: string;
    editMarketErrorMessage: string;
};

type PatternMarketLookup = {
    id: number;
    regex: string;
    inverse: boolean;
    market: string;
    marketId: number;
};

type KeywordMarketLookupResponse = {
    keyword: string;
    regex: string;
    inverse: boolean;
    market: MarketResponse;
    active: number;
    id: number;
    resource_uri: string;
};

const LayoutManagePagesToMarket = () => {
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(true);
    const [closeButtonState, setCloseButtonState] = useState<string>('close');
    const [disableSelectedPattern, setDisableSelectedConnection] = useState<boolean>(false);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState<boolean>(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState<boolean>(true);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState<boolean>(true);
    const [addNewButtonDisabled, setAddNewButtonDisabled] = useState<boolean>(false);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState<boolean>(true);
    const [editConnectionAccordionOpen, setEditKeywordAccordionOpen] = useState<boolean>(false);
    const [editConnectionAccordionVisible, setEditKeywordAccordionVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageError, setPageError] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [removeButtonState, setRemoveButtonState] = useState(true);

    const [selectedPattern, setSelectedPattern] = useState<PatternMarketLookup[]>([]);
    const [updatedPattern, setUpdatedPattern] = useState<PatternMarketLookup[]>([]);
    const [deletedPattern, setDeletedPattern] = useState<PatternMarketLookup[]>([]);

    const [pageToMarketList, setPageToMarketList] = useState<PatternMarketLookup[]>([]);
    const [marketList, setMarketList] = useState<DropdownOption[]>([]);
    const [editRegexText, setEditRegexText] = useState<string>('');
    const [editIsInversePattern, setEditIsInversePattern] = useState<boolean>(false);

    const [editSelectedMarket, setEditSelectedMarket] = useState<DropdownOption | undefined>(undefined);

    const [errorMessageObject, setErrorMessageObject] = useState<ErrorMessageObject>({
        editRegexTextErrorMessage: '',
        editMarketErrorMessage: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        setCloseButtonState('close');
        fetchAll();
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        setSaveChangesButtonDisabled(true);
        setCloseButtonState('close');
        if (editRegexText || editSelectedMarket) {
            setSaveChangesButtonDisabled(false);
            setErrorMessageObject({
                editRegexTextErrorMessage: '',
                editMarketErrorMessage: '',
            });
            setCloseButtonState('cancel');
        }
    }, [editRegexText, editSelectedMarket]);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            editRegexTextErrorMessage: '',
            editMarketErrorMessage: '',
        };

        if (!isAlphaNumericSpecialCharacterString(editRegexText) || editRegexText.length === 0) {
            hasFormError = true;
            errorMessageObj.editRegexTextErrorMessage = 'Please enter valid pattern.';
        }

        if (!editSelectedMarket) {
            hasFormError = true;
            errorMessageObj.editMarketErrorMessage = 'Please select a market.';
        }

        if (hasFormError) {
            setSaveChangesButtonDisabled(true);
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const handleEditRegexText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditRegexText(event?.target?.value);
    };

    const handleEditMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
        let marketId = parseInt(event?.target?.value);
        let selectMarket = marketList.find(market => market?.value === marketId);
        setEditSelectedMarket(selectMarket);
    };

    const onCheckBoxClick = (checkBoxName: React.ChangeEvent<HTMLInputElement>) => {
        if (String(checkBoxName) === 'isInverseRegexPattern') {
            setEditIsInversePattern(!editIsInversePattern);
        } else {
            setEditIsInversePattern(editIsInversePattern);
        }
    };

    const fetchAll = () => {
        const resources = ['path-market-config', 'seogd-market'];
        const allRequests: AxiosPromise[] = [];

        resources.forEach(resource => {
            allRequests.push(
                axios({
                    method: 'GET',
                    url: generateUrl('config', resource, [
                        { key: 'active', value: 1 },
                        { key: 'limit', value: 0 },
                        ...(resource === 'seogd-market' ? [{ key: 'order_by', value: 'country' }] : []),
                    ]),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        });
        axios
            .all(allRequests)
            .then(
                axios.spread((...response) => {
                    const pageToMarket = response[0].data.objects.map((pattern: KeywordMarketLookupResponse) => {
                        return {
                            id: pattern.id,
                            regex: pattern.regex,
                            inverse: pattern.inverse,
                            marketId: pattern.market.id,
                            market: pattern.market.country,
                        };
                    });

                    const allMarkets = response[1].data.objects.map((data: MarketResponse) => {
                        return {
                            label: data.alpha2,
                            value: data.id,
                            name: data.country,
                        };
                    });

                    setPageToMarketList(pageToMarket);
                    setMarketList(allMarkets);
                    setIsLoading(false);
                })
            )
            .catch(error => {
                setIsLoading(false);
                setPageError(true);
                errorHandling(error);
            });
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onDeleteConnection = () => {
        setSaveChangesButtonDisabled(false);
        setCloseButtonState('cancel');
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);

        if (disableSelectedPattern) {
            return;
        }

        let tempRemovedPattern = Array.prototype.concat(deletedPattern);
        let tempUpdatedPattern = Array.prototype.concat(updatedPattern);

        selectedPattern.forEach(pattern => {
            if (removeButtonState) {
                tempRemovedPattern.push(pattern);
            } else {
                tempRemovedPattern.splice(tempRemovedPattern.indexOf(pattern), 1);
            }

            // If a pattern was edited and then revoked in the same instance then the revoke should take priority
            const updatedPatternIndex = tempUpdatedPattern.indexOf(pattern);

            if (updatedPatternIndex >= 0) {
                tempUpdatedPattern.splice(updatedPatternIndex, 1);
            }
        });

        setDeletedPattern(tempRemovedPattern);
        setUpdatedPattern(tempUpdatedPattern);
        setSelectedPattern([]);
        setRemoveButtonState(true);
    };

    const onPatternSelect = (keyword: PatternMarketLookup) => {
        if (disableSelectedPattern) {
            return;
        }

        const selectedPatternId = keyword.id;
        let selectedPatterns = [];

        if (selectedPattern.filter(event => event.id === selectedPatternId).length > 0) {
            selectedPatterns = selectedPattern.filter(event => event.id !== selectedPatternId);
            setSelectedPattern(selectedPatterns);
            setEditButtonDisabled(selectedPatterns.length === 0);
            setRemoveButtonDisabled(selectedPatterns.length === 0);
            setAddNewButtonDisabled(selectedPatterns.length === 0);
        } else {
            selectedPatterns = selectedPattern.concat(
                pageToMarketList.filter(pattern => pattern.id === selectedPatternId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRevoked = false;
        let containsNonRevoked = false;
        let containsEdited = false;

        selectedPatterns.forEach(event => {
            if (deletedPattern.indexOf(event) >= 0) {
                containsRevoked = true;
            } else {
                containsNonRevoked = true;
            }

            if (updatedPattern.indexOf(event) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;
        let addButtonDisabled = false;

        if (selectedPatterns.length > 0) {
            editButtonDisabled = false;
            removeButtonDisabled = false;
            addButtonDisabled = true;
        }

        if (selectedPatterns.length > 1) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRevoked && !containsNonRevoked && !containsEdited) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRevoked) {
            editButtonDisabled = true;
        }

        if (containsRevoked && containsNonRevoked) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        setAddNewButtonDisabled(addButtonDisabled);
        setSelectedPattern(selectedPatterns);
        setEditButtonDisabled(editButtonDisabled);
        setRemoveButtonDisabled(removeButtonDisabled);
        setRemoveButtonState(removeButtonState);
        setUpdateButtonDisabled(true);
        setEditKeywordAccordionOpen(false);
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(removeModal());
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const handleAddNew = () => {
        dispatch(setModal('AddPagesToMarket', {}));
    };

    const onEditClick = () => {
        const market = marketList.find(market => market.value === selectedPattern[0].marketId);

        setCloseButtonState('cancel');
        setEditKeywordAccordionVisible(true);
        setEditKeywordAccordionOpen(true);
        setRemoveButtonDisabled(true);
        setUpdateButtonDisabled(false);
        setDisableSelectedConnection(true);
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setEditRegexText(selectedPattern[0].regex);
        setEditIsInversePattern(selectedPattern[0].inverse);
        setEditSelectedMarket(market);

        setErrorMessageObject({
            editRegexTextErrorMessage: '',
            editMarketErrorMessage: '',
        });
    };

    const updateKeyword = () => {
        return new Promise(async (resolve, reject) => {
            const payload = {
                regex: editRegexText,
                inverse: editIsInversePattern,
                market: generatePath('config', 'seogd-market', String(editSelectedMarket?.value)),
            };

            try {
                const requestConfig: AxiosRequestConfig = {
                    method: 'PATCH',
                    url: generateUrlDetail('config', 'path-market-config', String(selectedPattern[0].id))!,
                    data: payload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const requestResponse = await axios(requestConfig);

                if (requestResponse.status !== 202) {
                    throw new Error('Status not 202');
                }
            } catch (error) {
                console.log(error);
                reject('Error making a connection to API.');
            }
            resolve('Map Page to Market is Updated');
        });
    };

    const handleNavigateManageConnectionModal = () => {
        dispatch(removeModal());
    };

    const onUpdateClick = () => {
        if (!formValidator()) {
            return;
        }
        setIsUpdating(true);
        updateKeyword()
            .then(() => {
                dispatch(
                    addNotification({
                        copy: 'Regex Pattern added successfully.',
                        type: NotificationMessageType.Success,
                    })
                );
                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
                setIsUpdating(false);
                handleNavigateManageConnectionModal();
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue adding Regex Pattern.',
                        type: NotificationMessageType.Error,
                    })
                );

                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
            });
    };

    const onSaveChangesClick = () => {
        setSaveChangesButtonLoading(true);
        const requests: AxiosPromise[] = [];

        if (deletedPattern.length > 0) {
            const deletePatterns = {
                active: 0,
            };

            deletedPattern.forEach(keyword => {
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'path-market-config', String(keyword.id))!,
                        data: deletePatterns,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
        }

        axios
            .all(requests)
            .then(
                axios.spread((...res) => {
                    dispatch(
                        addNotification({
                            copy: 'These changes have been successfully saved.',
                            type: NotificationMessageType.Success,
                        })
                    );
                    setSaveChangesButtonLoading(false);
                    dispatch(removeModal());
                })
            )
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue trying to save your changes. Please try again later or contact Cubed Support.',
                        type: NotificationMessageType.Error,
                    })
                );
                setSaveChangesButtonLoading(false);
            });
    };

    const renderModalNavigation = () => {
        let numberOfChanges = updatedPattern.length + deletedPattern.length;
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    };

    const renderAccordion = () => {
        if (editConnectionAccordionVisible) {
            const accordions = [
                {
                    header: 'Edit Map Pages to Markets',
                    required: false,
                    open: editConnectionAccordionOpen,
                    type: 'form',
                    intro: '',
                    config: {
                        formConfig: {
                            fields: [
                                {
                                    label: 'Regex:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: `A regex pattern to identify the pages to assign to a specific market. If you'd like to assign all pages to a specific market, please use (.*?).`,
                                    inputKeyValue: 'regexText',
                                    inputValue: editRegexText,
                                    inputOnChange: handleEditRegexText,
                                    errorMessage: errorMessageObject.editRegexTextErrorMessage,
                                },
                                {
                                    label: 'Inverse:',
                                    type: 'checkbox',
                                    toolTipCopy:
                                        'Inverse is false as default. Set True for the inverse of the regex pattern.',
                                    inputKeyValue: 'editIsInversePattern',
                                    checked: editIsInversePattern,
                                    inputOnChange: onCheckBoxClick,
                                },
                                {
                                    label: 'Market:',
                                    type: 'select',
                                    requiredField: true,
                                    toolTipCopy: `The market you'd like to assign the paths identified.`,
                                    inputKeyValue: 'market',
                                    inputValue: editSelectedMarket?.value,
                                    inputOptions: marketList,
                                    inputOnChange: handleEditMarket,
                                    errorMessage: errorMessageObject.editMarketErrorMessage,
                                },
                            ],
                            buttons: [
                                {
                                    value: 'UPDATE',
                                    onClick: onUpdateClick,
                                    disabled: updateButtonDisabled,
                                    isLoading: isUpdating,
                                },
                            ],
                        },
                    },
                },
            ];

            return <WidgetAccordion accordions={accordions} />;
        }
    };

    const renderTable = () => {
        const errorMessageOverride = 'No Page is mapped to Market';

        const header = {
            columns: [
                {
                    title: 'Pattern',
                },
                {
                    title: 'Market',
                },
            ],
        };

        const rows = pageToMarketList.map(pattern => {
            const rowProperty = {
                selected: selectedPattern.includes(pattern),
                deleted: deletedPattern.includes(pattern),
                edited: updatedPattern.includes(pattern),
                disabled: disableSelectedPattern,
            };

            return {
                onClick: () => onPatternSelect(pattern),
                key: `page_to_market__${pattern.id}`,
                dataValue: pattern.id,
                rowProperty,
                columns: [
                    {
                        copy: pattern.regex,
                    },
                    {
                        copy: pattern.market,
                    },
                ],
            };
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Map Pages to Markets</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Map Pages to Markets</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Manage Map Pages to Markets</h2>
            <p>You can add and manage Map Pages to Markets from here.</p>
            <div className="">
                {renderTable()}
                <InputButtonWrapper>
                    <InputButton value="ADD" disabled={addNewButtonDisabled} onClick={handleAddNew} />
                    <InputButton value="EDIT" disabled={editButtonDisabled} onClick={onEditClick} />
                    <InputButton
                        buttonTheme={ButtonThemes.Red}
                        value={removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                        disabled={removeButtonDisabled}
                        onClick={onDeleteConnection}
                    />
                </InputButtonWrapper>
            </div>
            {renderAccordion()}
        </div>
    );
};

export default LayoutManagePagesToMarket;
