import React from 'react';
import styled, { useTheme } from 'styled-components';
import { pieChartConfig, pieChartDataLabelsConfig } from '../../configurations/widget-pie-chart-config';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Helpers
import { formatNumber } from '../../helpers/format-number';
import { buildPieRatio } from '../../helpers/build-pie-ratio';

// Components
import WidgetBase from '../base/widget-base';
import WidgetPieChartBigNumber from '../big-numbers/widget-pie-chart-big-number';
import SectionDashboardPieTable from '../../components/tables/section-dashboard-pie-table';

// Types
import { PieTableData, TotalData } from '../../types';

const StyledContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0px 30px;
`;

const StyledDualContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const StyledChartLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
`;

const StyledTitleContainer = styled.div<{ height: number }>`
    text-align: center;
    font-size: ${props => (props.height > 1 ? '0.9rem ' : '0.8rem')};
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    padding: ${props => (props.height > 1 ? '10px 0px' : '3px 0px')};
    color: ${props => props.theme.sectionDashboard.widget.pieTableComparison.titleColor};
    margin: 0;
`;

const StyledTableWrapper = styled.div`
    width: 100%;
    max-height: 100%;
    overflow: auto;
    align-self: center;
    margin-right: 15px;
`;

export type WidgetPieChartTableProps = {
    title: string;
    data: PieTableData;
    totalData?: TotalData;
    href?: string;
    display?: 'pie' | 'donut' | 'semidonut';
    showLabels?: boolean;
    menu?: React.ReactElement;
};

const WidgetPieChartTable = ({
    title,
    data,
    totalData,
    href,
    display = 'pie',
    showLabels = true,
    menu,
}: WidgetPieChartTableProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const { series, columns, rows, request, ratio, limit } = data;

    const isEnableTable = height > 1 && width > 3;

    if (width === 1 || height === 1) {
        const numberOfBoxes = Math.max(width, height);
        const firstSeries = series[0];
        const dataToDisplay = firstSeries.data.slice(0, numberOfBoxes);
        const firstSeriesTotal = firstSeries.total;
        const direction = width > height ? 'horizontal' : 'vertical';

        return (
            <WidgetBase title={title} href={href} menu={menu}>
                {data?.request?.chartMetrics ? (
                    <WidgetPieChartBigNumber
                        data={dataToDisplay}
                        direction={direction}
                        total={firstSeriesTotal}
                        chartLabel={data?.request?.chartField?.displayName}
                    />
                ) : (
                    <WidgetPieChartBigNumber data={dataToDisplay} direction={direction} total={firstSeriesTotal} />
                )}
            </WidgetBase>
        );
    }

    const configOptions = pieChartConfig(theme);

    const renderChartLabel = () => {
        const displayName = data?.request?.chartField?.displayName;
        return displayName && <StyledTitleContainer height={height}>{displayName}</StyledTitleContainer>;
    };

    const chartConfig = {
        ...configOptions,
        plotOptions: {
            ...configOptions.plotOptions,
            pie: {
                ...configOptions.plotOptions.pie,
                startAngle: display === 'semidonut' ? -90 : 0,
                endAngle: display === 'semidonut' ? 90 : 360,
                center:
                    display === 'semidonut'
                        ? width > 3
                            ? ['50%', '70%']
                            : height > 2
                            ? ['50%', '60%']
                            : ['50%', '65%']
                        : ['50%', '50%'],
                dataLabels: pieChartDataLabelsConfig(showLabels, width, height),
            },
        },
        title: {
            useHTML: true,
            text: isEnableTable && ratio && limit ? buildPieRatio(theme, width, height, ratio, limit) : '',
            verticalAlign: 'middle',
            floating: true,
        },
        legend: {
            ...configOptions.legend,
            enabled: !isEnableTable,
            itemMarginTop: height > 2 ? 5 : 1,
            itemMarginBottom: height > 2 ? 5 : 1,
            itemStyle: {
                ...configOptions.legend.itemStyle,
                width: width > 5 ? '300px' : '70px',
                fontSize: width > 4 ? '14px' : '12px',
                fontWeight: '500',
            },
            symbolPadding: width > 4 ? 15 : 5,
            labelFormatter: function (this: { name: string; value: number }) {
                return `${this.name} - <b>${formatNumber(this.value, 1)}</b>`;
            },
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b> - {point.value}',
        },
        // Map to Highcharts format
        series: series.map(series => {
            return {
                name: series.name,
                data: series.data.map(data => {
                    return {
                        name: data.name,
                        value: data.value,
                        color: data.color,
                        y: (data.value / series.total) * 100,
                    };
                }),
                innerSize: display === 'donut' || display === 'semidonut' ? '50%' : 0,
            };
        }),
    };

    if (isEnableTable) {
        return (
            <WidgetBase title={title} href={href} menu={menu}>
                <StyledDualContainer>
                    <StyledChartLabelWrapper>
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...chartConfig }}
                            containerProps={{
                                style: { height: '100%', width: '100%', flexShrink: width > 4 ? 1.5 : 2 },
                            }}
                        />
                        {data?.request?.chartMetrics && renderChartLabel()}
                    </StyledChartLabelWrapper>

                    <StyledTableWrapper>
                        <SectionDashboardPieTable
                            request={request}
                            columns={columns}
                            rows={rows}
                            totalData={totalData}
                            width={width}
                            height={height}
                        />
                    </StyledTableWrapper>
                </StyledDualContainer>
            </WidgetBase>
        );
    }

    return (
        <WidgetBase title={title} href={href} menu={menu}>
            <StyledContainer>
                <StyledChartLabelWrapper>
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...chartConfig }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                    {data?.request?.chartMetrics && renderChartLabel()}
                </StyledChartLabelWrapper>
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetPieChartTable;
