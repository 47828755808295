import React, { Component } from 'react';
import CodeBlock from '../code-block';

class ListRowBody extends Component {
    renderList(content) {
        if (content.listType === 'ordered') {
            return (
                <ol>
                    {content.listItems.map(listItem => {
                        return <li key={content.keyValue}>{listItem}</li>;
                    })}
                </ol>
            );
        } else {
            return (
                <ul>
                    {content.listItems.map(listItem => {
                        return <li key={content.keyValue}>{listItem}</li>;
                    })}
                </ul>
            );
        }
    }

    renderCode(content) {
        return <CodeBlock language={content.language} code={content.code} />;
    }

    renderText(content) {
        return <p>{content.copy}</p>;
    }

    render() {
        return (
            <div className="list-item__body" key={this.props.keyValue} data-key={this.props.keyValue}>
                {this.props.title !== undefined && <h3>{this.props.title}</h3>}

                {this.props.bodyContent.map(content => {
                    switch (content.type) {
                        case 'text':
                            return this.renderText(content);
                        case 'codeBlock':
                            return this.renderCode(content);
                        case 'list':
                            return this.renderList(content);

                        default:
                            return null;
                    }
                })}
            </div>
        );
    }
}

export default ListRowBody;
