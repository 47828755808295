import moment from 'moment';
import { createStaticRanges } from 'react-date-range';
import { DatePickerType } from '../configurations/enums/date-picker-type';

const defaultRanges = [
    {
        label: 'Yesterday',
        range: () => ({
            startDate: moment().subtract(1, 'days').toDate(),
            endDate: moment().subtract(1, 'days').toDate(),
        }),
    },
    {
        label: 'Last 7 Days',
        range: () => ({
            startDate: moment().subtract(7, 'days').toDate(),
            endDate: moment().subtract(1, 'days').toDate(),
        }),
    },
    {
        label: 'Last 30 Days',
        range: () => ({
            startDate: moment().subtract(30, 'days').toDate(),
            endDate: moment().subtract(1, 'days').toDate(),
        }),
    },
    {
        label: 'This Month',
        range: () => ({
            startDate: moment().startOf('month').toDate(),
            endDate: moment().endOf('month').toDate(),
        }),
    },
    {
        label: 'Last Month',
        range: () => ({
            startDate: moment().subtract(1, 'months').startOf('month').toDate(),
            endDate: moment().subtract(1, 'months').endOf('month').toDate(),
        }),
    },
];

export const getStaticRanges = (type, thisPeriodDates) => {
    switch (type) {
        case DatePickerType.Year:
            return [
                ...createStaticRanges([
                    {
                        label: 'This Year',
                        range: () => ({
                            startDate: moment().startOf('year').toDate(),
                            endDate: moment().endOf('year').toDate(),
                        }),
                    },
                    {
                        label: 'Last Year',
                        range: () => ({
                            startDate: moment().subtract(1, 'year').startOf('year').toDate(),
                            endDate: moment().subtract(1, 'year').endOf('year').toDate(),
                        }),
                    },
                    {
                        label: 'Last 12 Months',
                        range: () => ({
                            startDate: moment().subtract(12, 'months').startOf('month').toDate(),
                            endDate: moment().subtract(0, 'months').startOf('month').toDate(),
                        }),
                    },
                ]),
            ];
        case DatePickerType.Day:
            return [
                ...createStaticRanges([
                    {
                        label: 'Yesterday',
                        range: () => ({
                            startDate: moment().subtract(1, 'days').toDate(),
                            endDate: moment().subtract(1, 'days').toDate(),
                        }),
                    },
                ]),
            ];
        case DatePickerType.Comparison:
            const daysDiff = moment(thisPeriodDates.endDate).diff(moment(thisPeriodDates.startDate), 'days') + 1;

            const comparisonRanges = [
                {
                    label: 'Last Year',
                    range: () => ({
                        startDate: moment(thisPeriodDates.startDate).subtract(1, 'years').toDate(),
                        endDate: moment(thisPeriodDates.endDate).subtract(1, 'years').toDate(),
                    }),
                },
                {
                    label: 'Prior Period',
                    range: () => ({
                        startDate: moment(thisPeriodDates.startDate).subtract(daysDiff, 'days').toDate(),
                        endDate: moment(thisPeriodDates.endDate).subtract(daysDiff, 'days').toDate(),
                    }),
                },
            ];
            return [...createStaticRanges([...defaultRanges, ...comparisonRanges])];
        default:
            return [...createStaticRanges(defaultRanges)];
    }
};
