import React, { useState, useEffect, RefObject } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styled, { useTheme } from 'styled-components';

// Components
import IconDownloadFile from './icons/download-file-icon';
import WidgetBaseLoader from '../section-dashboard/widgets/base/widget-base-loader';

type PDFDownloadProps = {
    componentRef: React.RefObject<HTMLElement> | null;
    filename: string;
    isLoadingDownloadButton?: boolean;
    customButtonName?: string;
    onError?: (error: Error) => void; // Optional error handler
    isLoading: boolean;
    setLoadingPdfDownload: () => void;
    unSetLoadingPdfDownload: () => void;
};

const StyledButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: ${props => props.theme.filterBar.button.backgroundColor};
    border: 1px solid ${props => props.theme.filterBar.button.backgroundColor};
    padding: 9px 15px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: ${props => props.theme.filterBar.button.labelFontWeight};
    text-transform: uppercase;
    color: ${props => props.theme.filterBar.button.labelColor};

    &:hover {
        border: 1px solid ${props => props.theme.filterBar.button.borderHover};
    }
`;

const StyledIconDownloadFile = styled(IconDownloadFile)`
    width: 18px;
    height: 18px;
    color: ${props => props.theme.filterBar.button.iconColor};
`;

const PDFDownload: React.FC<PDFDownloadProps> = ({
    componentRef,
    filename,
    isLoading,
    onError,
    setLoadingPdfDownload,
    unSetLoadingPdfDownload,
}) => {
    const theme = useTheme();
    const [parentRef, setParentRef] = useState<RefObject<HTMLElement> | null>();

    useEffect(() => {
        setParentRef(componentRef);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDownload = async () => {
        // This calls parent components set state which will re-render the
        // parent component and set the loading spinner.
        await setLoadingPdfDownload();

        if (parentRef?.current) {
            html2canvas(parentRef.current, {
                scale: 2,
                allowTaint: true,
                useCORS: true,
                logging: false,
            })
                .then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF('p', 'mm');

                    const imgWidth = 200;
                    const pageHeight = 295;

                    const imageHeight = (canvas.height * imgWidth) / canvas.width;

                    // keeping track of the height to add new pages
                    let heightRemaining = imageHeight;
                    let position = 0;

                    pdf.addImage(imgData, 'PNG', 5, 0, imgWidth, imageHeight);
                    heightRemaining -= pageHeight;

                    while (heightRemaining >= 0) {
                        position = heightRemaining - imageHeight;
                        pdf.addPage();
                        pdf.addImage(imgData, 'PNG', 5, position, imgWidth, imageHeight);
                        heightRemaining -= pageHeight;
                    }

                    pdf.save(`${filename}.pdf`);
                })
                .catch(error => {
                    if (onError) {
                        onError(error);
                    } else {
                        console.error('Error exporting PDF:', error);
                    }
                })
                .finally(() => {
                    unSetLoadingPdfDownload();
                });
        }
    };

    return (
        <StyledButton onClick={handleDownload}>
            {isLoading ? (
                <WidgetBaseLoader
                    width={16}
                    height={16}
                    margin={0}
                    color={theme.name === 'dark' ? 'rgba(255, 255, 255, 0.8)' : undefined}
                />
            ) : (
                <StyledIconDownloadFile />
            )}
            Download PDF
        </StyledButton>
    );
};

export default PDFDownload;
