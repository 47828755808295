import React from 'react';
import { WidgetConfig } from '../../types';
import getResource from '../../helpers/get-resource';
import getField from '../../helpers/get-field';
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourcePieChart from '../../../section-dashboard/hooks/widget/pie/use-resource-pie-chart';
import WidgetPieChart from '../../../section-dashboard/widgets/widget/widget-pie-chart';
import CreateAWidgetPreviewMessage from '../../create-a-widget/components/create-a-widget-preview-message';

const WidgetPreviewPieChart = ({ config }: { config: WidgetConfig }) => {
    // Get the request values from the widget
    const resource = getResource(config.resource);
    const groupByField = getField(config.group_by);
    const orderByField = getField(config.order_by);
    const orderByDirection = config.order_by_direction.direction;

    const resourceData = useResource({
        resource: groupByField && resource,
        request: {
            orderBy:
                typeof orderByField !== 'undefined'
                    ? [{ field: orderByField, orderByDirection: orderByDirection }]
                    : [],
            groupBy: groupByField,
            chartField: orderByField,
            limit: 15,
            enabled: !!resource && !!groupByField && !!orderByField,
        },
    });

    const pieData = useResourcePieChart({
        resourceData: resourceData,
        dimension: groupByField,
    });

    if (config.group_by.length > 0 && config.order_by.length > 0) {
        return (
            <WidgetPieChart display={config.pie_chart_display || 'pie'} title={config.widget.title} data={pieData} />
        );
    }

    return (
        <CreateAWidgetPreviewMessage message="Please select a dimension and metric to preview the pie chart widget" />
    );
};

export default WidgetPreviewPieChart;
