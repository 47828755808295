// React Dependencies
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import { isUrl } from '../../helpers/validator';
import { generateUrl } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { cancelRequest } from '../../redux/slices/request';

// Import Widgets
import WidgetAccordion from '../../widgets/accordion';

// Import Components
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

const LayoutModalZendesk = () => {
    const { meta, account, request } = useSelector(state => state);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState(true);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState(false);
    const [closeButtonState, setCloseButtonState] = useState('close');
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
    const [zendeskConnectionAccordionOpen, setZendeskConnectionAccordionOpen] = useState(true);
    const [zendesk, setZendesk] = useState({
        subdomain: '',
    });
    const [isSubdomainExist, setIsSubdomainExist] = useState(false);
    const [subdomainErrorMessage, setSubdomainErrorMessage] = useState('');
    const [isPageError, setIsPageError] = useState(false);

    const controller = new AbortController();

    useEffect(() => {
        setIsLoading(false);
        setSaveChangesButtonDisabled(false);
        if (closeButtonState === 'close') {
            if (zendesk.subdomain !== '') {
                setCloseButtonState('cancel');
            }
        }
    }, [zendesk, closeButtonState]);

    useEffect(() => {
        Axios({
            method: 'GET',
            url: generateUrl('integrations', 'zendesk-config', [{ key: 'active', value: 1 }]),
            signal: controller.signal,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length > 0) {
                    if (response.data.objects.some(obj => obj.subdomain === zendesk.subdomain)) {
                        setIsSubdomainExist(true);
                        setSubdomainErrorMessage('This subdomain already exist.');
                        setSaveChangesButtonDisabled(true);
                    } else {
                        setIsSubdomainExist(false);
                        setSubdomainErrorMessage('');
                    }
                }
            })
            .catch(() => {
                setIsPageError(true);
            });
    }, [zendesk.subdomain, controller.signal]);

    const handleNavigateZendeskKeyManageModal = () => {
        dispatch(setModal('ZendeskAPI', {}));
    };

    // CLOSE - CANCEL click handler
    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            handleNavigateZendeskKeyManageModal();
            if (request.isLoading !== null) {
                dispatch(cancelRequest());
            }
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                    },
                    buttons: [
                        {
                            onClick: onPopupDiscardChangesClick,
                            value: 'DISCARD ZENDESK KEY',
                        },
                        {
                            onClick: onPopupStayHereClick,
                            value: 'STAY HERE',
                            style: 'secondary',
                        },
                    ],
                })
            );
        }
    };

    // Checks if the form fields have valid details
    const zendeskConnectionFormValidator = () => {
        setZendesk({
            subdomain: '',
        });

        let hasFormError = false;
        let errorMessageObject = {
            subdomainErrorMessage: '',
        };

        // Validate and ensure subdomain
        if (!isUrl(zendesk.subdomain) || zendesk.subdomain.length === 0) {
            hasFormError = true;
            errorMessageObject.subdomainErrorMessage = 'Please enter a valid subdomain.';
        }

        if (hasFormError) {
            setSubdomainErrorMessage(errorMessageObject['subdomainErrorMessage']);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    // PrimaryAction of the close popup
    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        handleNavigateZendeskKeyManageModal();
    };

    // SecondaryAction of the close popup
    const onPopupStayHereClick = () => {
        dispatch(removePopup());
        setSaveChangesButtonLoading(false);
        setCloseButtonState('close');
        setCloseButtonDisabled(false);
        setIsPageError(false);
        dispatch(
            addNotification({
                copy: 'Your Zendesk connection OAuth process has been aborted.',
                type: NotificationMessageType.Error,
            })
        );
    };

    const onCreatePopupContinue = () => {
        window.location = `${meta.apiDomain}/api/${account.token}/integrations/zendesk/initialise/${zendesk.subdomain}`;
    };

    // Saved changes clicked handler
    const onConnectClick = () => {
        // If there is an error reopen the zendesk details tab as the error will be there
        if (!zendeskConnectionFormValidator() || isSubdomainExist) {
            setZendeskConnectionAccordionOpen(true);
            return;
        }
        setSaveChangesButtonDisabled(true);
        setSaveChangesButtonLoading(true);
        setCloseButtonDisabled(true);

        dispatch(
            setPopup({
                title: 'Create connection with Zendesk.',
                iconType: 'informative',
                contentType: 'simple',
                config: {
                    copy: 'You will be taken to Zendesk to authorize access to your data. Once you have authorized Cubed, our access to your data can be managed via the Cubed account configuration pages.',
                },
                buttons: [
                    {
                        value: 'SAVE & CONTINUE',
                        onClick: onCreatePopupContinue,
                    },
                    {
                        value: 'STAY HERE',
                        style: 'secondary',
                        onClick: onPopupStayHereClick,
                    },
                ],
            })
        );
    };

    // Manage the input fields changes and updating the state with the new value entered by the user
    const onZendeskKeyDetailsChange = event => {
        const { name, value } = event.target;
        setZendesk(prevState => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'CONNECT',
                onClick: onConnectClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const renderAccordion = () => {
        const accordions = [
            {
                header: 'Zendesk Connection Details',
                required: true,
                open: zendeskConnectionAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Subdomain:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter your zendesk subdomain.',
                                inputKeyValue: 'subdomain',
                                inputPlaceholder: 'yoursubdomain.zendesk.com',
                                inputValue: zendesk.subdomain,
                                inputOnChange: onZendeskKeyDetailsChange,
                                errorMessage: subdomainErrorMessage,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    };

    if (isLoading) {
        return (
            <div className="modal__side-panel__add-zendesk">
                {renderModalNavigation()}
                <h2>Add Zendesk Key</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (isPageError) {
        return (
            <div className="modal__side-panel__add-zendesk">
                {renderModalNavigation()}
                <h2>Add Zendesk Key</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email. " />
            </div>
        );
    }

    return (
        <div className="modal__side-panel__add-zendesk">
            {renderModalNavigation()}
            <h2>Add Zendesk Key</h2>
            <p>Add your Zendesk subdomain below to create a Zendesk connection.</p>
            {renderAccordion()}
        </div>
    );
};

export default LayoutModalZendesk;
