import React from 'react';
import styled from 'styled-components';

const StyledListItemContainer = styled.table`
    width: 100%;
    background-color: ${props => props.theme.dashboardBuilder.container.backgroundColor};
    ${props => props.theme.boxShadow(1)}
    border-radius: 6px;
`;

const LibraryListList = ({ children }: { children: React.ReactNode }) => {
    return (
        <StyledListItemContainer>
            <tbody>{children}</tbody>
        </StyledListItemContainer>
    );
};

export default LibraryListList;
