// React Dependencies
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import moment from 'moment';
import { CONFIG_EXCLUDE_DATES } from '../../configurations/resources-config';
import usePostResource from '../../react-query/hooks/use-post-resource';
import { ButtonThemes } from '../../enums/button-themes';
import validators from '../../components/forms/validation/validators';
import { FieldValues } from 'react-hook-form';

// Redux Actions
import { setModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import Form from '../../components/forms/form';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const LayoutModalAddExcludeDates = () => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState<FieldValues>();

    useEffect(() => {
        if (formData && formData.description && formData.startDate && formData.endDate) {
            excludeDatePostMutation.mutate();
        }
    }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

    const excludeDatePostMutation = usePostResource({
        resource: CONFIG_EXCLUDE_DATES,
        data: {
            description: formData?.description,
            start_date: moment(formData?.startDate).format('YYYY-MM-DD'),
            end_date: moment(formData?.endDate).format('YYYY-MM-DD'),
        },
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'A Exclude Date was successfully created.',
                    type: NotificationMessageType.Success,
                })
            );
            handleNavigateManageEventModal();
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue adding this Exclude Date.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const handleNavigateManageEventModal = () => {
        dispatch(setModal('ManageAttributionModels', {}));
    };

    const onCloseClick = () => {
        handleNavigateManageEventModal();
    };

    const handleSubmitForm = (data: FieldValues) => {
        setFormData(data);
    };

    return (
        <div>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: onCloseClick,
                        disabled: false,
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Add Exclude Date</h2>
            <p>Use the form below to add an exclude date</p>

            <StyledFormContainer>
                <Form onSubmit={handleSubmitForm}>
                    <Form.Body>
                        <Form.Section>
                            <Form.Field>
                                <Form.InputLabel
                                    htmlFor="description"
                                    label="Description"
                                    tooltipCopy="Enter a description for your Exclude Date"
                                />
                                <Form.InputTextArea
                                    name="description"
                                    validators={[validators.required, validators.isAlphaNumericString]}
                                />
                            </Form.Field>
                        </Form.Section>
                        <Form.Section>
                            <Form.Field>
                                <Form.InputLabel
                                    htmlFor="startDate"
                                    label="Start Date"
                                    tooltipCopy="Select Exclude Date start date"
                                />
                                <Form.InputDatePicker name="startDate" validators={[validators.isDate]} />
                            </Form.Field>
                            <Form.Field>
                                <Form.InputLabel
                                    htmlFor="endDate"
                                    label="End Date"
                                    tooltipCopy="Select Exclude Date end date"
                                />
                                <Form.InputDatePicker name="endDate" validators={[validators.isDate]} />
                            </Form.Field>
                        </Form.Section>
                    </Form.Body>
                    <Form.Footer>
                        <Form.InputButton
                            value="Save"
                            loading={excludeDatePostMutation.isPending}
                            disabled={excludeDatePostMutation.isPending}
                        />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </div>
    );
};

export default LayoutModalAddExcludeDates;
