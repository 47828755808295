import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
    width: 100%;
    height: 40px;
    background-color: ${props => props.theme.colours.inputColour};
    border: none;
    border-bottom: solid 2px ${props => props.theme.colours.offBlackLighter};
    padding: 0 5px;
    margin-bottom: 5px;
    border-radius: 2px;

    &:focus {
        outline: none;
    }

    &::placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }
`;
export type InputPasswordProps = {
    value: string;
    placeholder: string;
    autocomplete?: string;
    onChange: () => void;
    onKeyDown: () => void;
};

const InputPassword = ({ value, placeholder, autocomplete, onChange, onKeyDown }: InputPasswordProps) => {
    return (
        <StyledInput
            type="password"
            placeholder={placeholder || 'Password...'}
            autoComplete={autocomplete || 'password'}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
        />
    );
};

export default InputPassword;
