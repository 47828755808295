import React from 'react';
import { Calendar } from 'react-date-range';
import classnames from 'classnames';
import {
    subMonths,
    differenceInCalendarMonths,
    startOfMonth,
    endOfMonth,
    addDays,
    setMonth,
    addYears,
    min,
    max,
    setYear,
} from 'date-fns';
import ReactList from 'react-list';
import CubedMonth from './cubed-month';
import CubedDateInput from './cubed-date-input';

class CubedCalendar extends Calendar {
    renderDateDisplay = () => {
        const {
            focusedRange,
            color,
            ranges,
            rangeColors,
            dateDisplayFormat,
            editableDateInputs,
            startDatePlaceholder,
            endDatePlaceholder,
            ariaLabels,
        } = this.props;
        const defaultColor = rangeColors[focusedRange[0]] || color;
        const styles = this.styles;

        return (
            <div className={styles.dateDisplayWrapper}>
                {ranges.map((range, i) => {
                    const endMonthDate = endOfMonth(range.endDate);
                    if (range.showDateDisplay === false || (range.disabled && !range.showDateDisplay)) return null;
                    return (
                        <div className={styles.dateDisplay} key={i} style={{ color: range.color || defaultColor }}>
                            <CubedDateInput
                                className={classnames(styles.dateDisplayItem, {
                                    [styles.dateDisplayItemActive]: focusedRange[0] === i && focusedRange[1] === 0,
                                })}
                                readOnly={!editableDateInputs}
                                disabled={range.disabled}
                                value={range.startDate}
                                placeholder={startDatePlaceholder}
                                dateOptions={this.dateOptions}
                                dateDisplayFormat={dateDisplayFormat}
                                ariaLabel={
                                    ariaLabels.dateInput &&
                                    ariaLabels.dateInput[range.key] &&
                                    ariaLabels.dateInput[range.key].startDate
                                }
                                onChange={this.onDragSelectionEnd}
                                onFocus={() => this.handleRangeFocusChange(i, 0)}
                            />
                            <CubedDateInput
                                className={classnames(styles.dateDisplayItem, {
                                    [styles.dateDisplayItemActive]: focusedRange[0] === i && focusedRange[1] === 1,
                                })}
                                readOnly={!editableDateInputs}
                                disabled={range.disabled}
                                value={endMonthDate}
                                placeholder={endDatePlaceholder}
                                dateOptions={this.dateOptions}
                                dateDisplayFormat={dateDisplayFormat}
                                ariaLabel={
                                    ariaLabels.dateInput &&
                                    ariaLabels.dateInput[range.key] &&
                                    ariaLabels.dateInput[range.key].endDate
                                }
                                onChange={this.onDragSelectionEnd}
                                onFocus={() => this.handleRangeFocusChange(i, 1)}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };
    onDragSelectionStart = date => {
        const { onChange, dragSelectionEnabled } = this.props;

        if (dragSelectionEnabled) {
            this.setState({
                drag: {
                    status: true,
                    range: { startDate: date, endDate: date },
                    disablePreview: true,
                },
            });
        } else {
            onChange && onChange(date);
        }
    };
    changeShownDate = (value, mode = 'set') => {
        const { focusedDate } = this.state;
        const { onShownDateChange, minDate, maxDate } = this.props;
        const modeMapper = {
            monthOffset: () => addYears(focusedDate, value),
            setMonth: () => setMonth(focusedDate, value),
            setYear: () => setYear(focusedDate, value),
            set: () => value,
        };

        const newDate = min([max([modeMapper[mode](), minDate]), maxDate]);
        this.focusToDate(newDate, this.props, false);
        onShownDateChange && onShownDateChange(newDate);
    };
    renderMonthAndYear = (focusedDate, changeShownDate, props) => {
        const { showMonthArrow, minDate, maxDate, showMonthAndYearPickers, ariaLabels } = props;
        const upperYearLimit = (maxDate || Calendar.defaultProps.maxDate).getFullYear();
        const lowerYearLimit = (minDate || Calendar.defaultProps.minDate).getFullYear();
        const styles = this.styles;
        return (
            <div onMouseUp={e => e.stopPropagation()} className={styles.monthAndYearWrapper}>
                {showMonthArrow ? (
                    <button
                        type="button"
                        className={classnames(styles.nextPrevButton, styles.prevButton)}
                        onClick={() => changeShownDate(-1, 'monthOffset')}
                        aria-label={ariaLabels.prevButton}
                    >
                        <i />
                    </button>
                ) : null}
                {showMonthAndYearPickers ? (
                    <span className={styles.monthAndYearPickers}>
                        <span className={styles.monthAndYearDivider} />
                        <span className={styles.yearPicker}>
                            <select
                                value={focusedDate.getFullYear()}
                                onChange={e => changeShownDate(e.target.value, 'setYear')}
                                aria-label={ariaLabels.yearPicker}
                            >
                                {new Array(upperYearLimit - lowerYearLimit + 1).fill(upperYearLimit).map((val, i) => {
                                    const year = val - i;
                                    return (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    );
                                })}
                            </select>
                        </span>
                    </span>
                ) : (
                    <span className={styles.monthAndYearPickers}>
                        {this.state.monthNames[focusedDate.getMonth()]} {focusedDate.getFullYear()}
                    </span>
                )}
                {showMonthArrow ? (
                    <button
                        type="button"
                        className={classnames(styles.nextPrevButton, styles.nextButton)}
                        onClick={() => changeShownDate(+1, 'monthOffset')}
                        aria-label={ariaLabels.nextButton}
                    >
                        <i />
                    </button>
                ) : null}
            </div>
        );
    };
    render() {
        const {
            showDateDisplay,
            onPreviewChange,
            scroll,
            direction,
            disabledDates,
            disabledDay,
            maxDate,
            minDate,
            rangeColors,
            color,
            navigatorRenderer,
            className,
            preview,
        } = this.props;
        const { scrollArea, focusedDate } = this.state;
        const isVertical = direction === 'vertical';
        const monthAndYearRenderer = navigatorRenderer || this.renderMonthAndYear;

        const ranges = this.props.ranges.map((range, i) => ({
            ...range,
            color: range.color || rangeColors[i] || color,
        }));
        return (
            <div
                className={classnames(this.styles.calendarWrapper, className)}
                onMouseUp={() => this.setState({ drag: { status: false, range: {} } })}
                onMouseLeave={() => {
                    this.setState({ drag: { status: false, range: {} } });
                }}
            >
                {showDateDisplay && this.renderDateDisplay()}
                {monthAndYearRenderer(focusedDate, this.changeShownDate, this.props)}
                {scroll.enabled ? (
                    <div>
                        {isVertical && this.renderWeekdays(this.dateOptions)}
                        <div
                            className={classnames(
                                this.styles.infiniteMonths,
                                isVertical ? this.styles.monthsVertical : this.styles.monthsHorizontal
                            )}
                            onMouseLeave={() => onPreviewChange && onPreviewChange()}
                            style={{
                                width: scrollArea.calendarWidth + 11,
                                height: scrollArea.calendarHeight + 11,
                            }}
                            onScroll={this.handleScroll}
                        >
                            <ReactList
                                length={differenceInCalendarMonths(
                                    endOfMonth(maxDate),
                                    addDays(startOfMonth(minDate), -1),
                                    this.dateOptions
                                )}
                                treshold={500}
                                type="variable"
                                ref={target => (this.list = target)}
                                itemSizeEstimator={this.estimateMonthSize}
                                axis={isVertical ? 'y' : 'x'}
                                itemRenderer={(index, key) => {
                                    const monthStep = addYears(minDate, index);
                                    return (
                                        <CubedMonth
                                            {...this.props}
                                            onPreviewChange={onPreviewChange || this.updatePreview}
                                            preview={preview || this.state.preview}
                                            ranges={ranges}
                                            key={key}
                                            drag={this.state.drag}
                                            dateOptions={this.dateOptions}
                                            disabledDates={disabledDates}
                                            disabledDay={disabledDay}
                                            month={monthStep}
                                            onDragSelectionStart={this.onDragSelectionStart}
                                            onDragSelectionEnd={this.onDragSelectionEnd}
                                            onDragSelectionMove={this.onDragSelectionMove}
                                            onMouseLeave={() => onPreviewChange && onPreviewChange()}
                                            styles={this.styles}
                                            style={
                                                isVertical
                                                    ? { height: this.estimateMonthSize(index) }
                                                    : {
                                                          height: scrollArea.monthHeight,
                                                          width: this.estimateMonthSize(index),
                                                      }
                                            }
                                            showMonthName
                                            showWeekDays={!isVertical}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        className={classnames(
                            this.styles.months,
                            isVertical ? this.styles.monthsVertical : this.styles.monthsHorizontal
                        )}
                    >
                        {new Array(this.props.months).fill(null).map((_, i) => {
                            let monthStep = addYears(this.state.focusedDate, i);
                            if (this.props.calendarFocus === 'backwards') {
                                monthStep = subMonths(this.state.focusedDate, this.props.months - 1 - i);
                            }
                            return (
                                <CubedMonth
                                    {...this.props}
                                    onPreviewChange={onPreviewChange || this.updatePreview}
                                    preview={preview || this.state.preview}
                                    ranges={ranges}
                                    key={i}
                                    drag={this.state.drag}
                                    dateOptions={this.dateOptions}
                                    disabledDates={disabledDates}
                                    disabledDay={disabledDay}
                                    month={monthStep}
                                    onDragSelectionStart={this.onDragSelectionStart}
                                    onDragSelectionEnd={this.onDragSelectionEnd}
                                    onDragSelectionMove={this.onDragSelectionMove}
                                    onMouseLeave={() => onPreviewChange && onPreviewChange()}
                                    styles={this.styles}
                                    showWeekDays={!isVertical || i === 0}
                                    showMonthName={!isVertical || i > 0}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

export default CubedCalendar;
