import React, { useState } from 'react';
import styled from 'styled-components';
import validators from '../../../components/forms/validation/validators';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { removeModal } from '../../../redux/actions/modal';
import { addNotification } from '../../../redux/actions/notification';
import { setUser } from '../../../redux/actions/user';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { FieldValues } from 'react-hook-form';

// Config
import { CONFIG_USERS } from '../../../configurations/resources-config';

// Hooks
import usePutMultiResourceWithPayload from '../../../react-query/hooks/use-put-multi-resource-with-payload';

// Components
import Form from '../../../components/forms/form';
import ModalNavigation from '../../../components/modal-navigation';

type User = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
};

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const LayoutModalUpdateUserDetails = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);

    const [userDetails, setUserDetails] = useState<User>();

    const userMutation = usePutMultiResourceWithPayload({
        resource: CONFIG_USERS,
        handleOnSuccess: () => {
            dispatch(setUser({ ...userDetails }));
            dispatch(addNotification({ copy: 'User details updated successfully', type: 'success' }));
            dispatch(removeModal());
        },
        handleOnError: () => {
            dispatch(addNotification({ copy: 'There was an issue while updating your details.', type: 'error' }));
        },
    });

    const handleSubmit = (data: FieldValues) => {
        setUserDetails({ ...user, ...(data as User), username: data.email });

        userMutation.mutate({
            payload: [
                {
                    first_name: data.firstName,
                    last_name: data.lastName,
                    email: data.email,
                },
            ],
            resourceId: [user.id],
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [formDefaultValues, _] = useState<FieldValues>({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
    });

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(removeModal()),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Update Details</h2>

            <StyledFormContainer>
                <Form onSubmit={handleSubmit} defaultValues={formDefaultValues}>
                    <Form.Body>
                        <Form.Section>
                            <Form.Field>
                                <Form.InputLabel htmlFor="firstName" label="First Name" required={true} />
                                <Form.InputText
                                    name="firstName"
                                    validators={[validators.required]}
                                    disabled={userMutation.isPending}
                                />
                            </Form.Field>

                            <Form.Field>
                                <Form.InputLabel htmlFor="lastName" label="Last Name" required={true} />
                                <Form.InputText
                                    name="lastName"
                                    validators={[validators.required]}
                                    disabled={userMutation.isPending}
                                />
                            </Form.Field>

                            <Form.Field>
                                <Form.InputLabel htmlFor="email" label="Email" required={true} />
                                <Form.InputText
                                    name="email"
                                    validators={[validators.required, validators.isEmail]}
                                    disabled={userMutation.isPending}
                                />
                            </Form.Field>
                        </Form.Section>
                    </Form.Body>
                    <Form.Footer>
                        <Form.InputButton type="submit" value="Update" loading={userMutation.isPending} />
                        <Form.InputButton type="reset" value="Reset" buttonTheme={ButtonThemes.Secondary} />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </>
    );
};

export default LayoutModalUpdateUserDetails;
