import React from 'react';
import styled from 'styled-components';

import LoadingSpinner from '../components/loading-spinner';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import imageLogoWhiteFull from '../assets/images/white-cubed-logo-full.png';

const StyledSplashLoadingView = styled.div`
    position: relative;
    height: 100vh;
`;

const StyledSplashLoadingImage = styled.img`
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    object-position: top;
    display: block;
`;

const StyledSplashLoadingContent = styled.div`
    position: absolute;
    text-align: center;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`;

const StyledSplashLoadingContentImage = styled.img`
    height: 50px;
    width: auto;
    object-fit: initial;
    object-position: initial;
    margin-bottom: 15px;
    display: initial;
`;

const ViewLoadingSplash = () => {
    return (
        <StyledSplashLoadingView>
            <StyledSplashLoadingImage src={imageLoginBackground} alt="Cubed" />
            <StyledSplashLoadingContent>
                <StyledSplashLoadingContentImage src={imageLogoWhiteFull} alt="Cubed" />
                <LoadingSpinner light={true} />
            </StyledSplashLoadingContent>
        </StyledSplashLoadingView>
    );
};

export default ViewLoadingSplash;
