import React, { useContext } from 'react';
import styled from 'styled-components';

// Context
import SectionDashboardProvider from '../../../section-dashboard/context/section-dashboard-context';
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

// Types
import { SectionDashboardLayout } from '../../../section-dashboard/types';
import { Layout } from 'react-grid-layout';

// Helpers
import getWidgetPreview from '../../widgets/helpers/get-widget-preview';

const StyledGrid = styled.div`
    width: 100%;
    height: 100%;
    display: grid;

    > div {
        grid-row: 1;
        grid-column: 1;
    }
`;

const CreateADashboardWidgetWrapper = ({ layout }: { layout: Layout }) => {
    const { breakpoint, currentWidgets, showComparison } = useContext(CreateADashboardContext);

    const widgetConfig = currentWidgets.find(widget => `widget${widget.widget.id}` === layout.i);

    const renderWidget = () => {
        if (widgetConfig) {
            return getWidgetPreview({
                type: widgetConfig.widget.type.name,
                config: widgetConfig,
                showComparison: showComparison,
            });
        }
    };

    if (breakpoint) {
        return (
            <SectionDashboardProvider
                sectionIndex={0}
                x={0}
                y={0}
                width={layout ? layout.w : 0}
                height={layout ? layout.h : 0}
                area={'a'}
                key={'a'}
                layout={breakpoint as SectionDashboardLayout}
            >
                <StyledGrid>{renderWidget()}</StyledGrid>
            </SectionDashboardProvider>
        );
    }

    return null;
};

export default CreateADashboardWidgetWrapper;
