import React from 'react';
import { useState } from 'react';
import { CubedField } from '../../../../types';
import { FilterOperator, RequestStatus, WidgetMenuType } from '../../../types';
import WidgetMenuSectionCheckbox from '../components/section-menu/widget-menu-section-checkbox';

type UseWidgetMenuSectionCheckboxArgs = {
    label: string;
    filterField: CubedField;
    filterOperator: FilterOperator;
    filterValue: string;
    defaultChecked?: boolean;
};

const useWidgetMenuSectionCheckbox = ({
    label,
    filterField,
    filterOperator,
    filterValue,
    defaultChecked = false,
}: UseWidgetMenuSectionCheckboxArgs) => {
    const [checked, setChecked] = useState<boolean>(defaultChecked);

    const handleCheckedChange = () => {
        setChecked(!checked);
    };

    const filter = checked ? [{ field: filterField, operator: filterOperator, value: filterValue }] : [];

    return {
        type: WidgetMenuType.CHECKBOX,
        menu: <WidgetMenuSectionCheckbox label={label} checked={checked} handleCheckedChange={handleCheckedChange} />,
        request: {
            sectionFilters: filter,
        },
        status: RequestStatus.SUCCESS,
        checked: checked,
    };
};

export default useWidgetMenuSectionCheckbox;
