import { CubedField } from '../../types';
import DataTypes from '../../filter-bar/enums/data-types';
import { FilterOperator } from '../types';

export const getFilterTypeOptions = (field: CubedField) => {
    switch (field.dataType) {
        case DataTypes.NUMBER:
        case DataTypes.FLOAT:
        case DataTypes.MONEY:
            return [
                { label: 'Equals', value: FilterOperator.EQUALS },
                { label: 'Greater Than', value: FilterOperator.GREATER_THAN },
                { label: 'Greater Than or Equals', value: FilterOperator.GREATER_THAN_OR_EQUALS },
                { label: 'Less Than or Equals', value: FilterOperator.LESS_THAN_OR_EQUALS },
                { label: 'Less Than', value: FilterOperator.LESS_THAN },
            ];
        case DataTypes.STRING:
            return [
                { label: 'Contains', value: FilterOperator.ICONTAINS },
                { label: 'Does Not Contain', value: FilterOperator.IDOES_NOT_CONTAIN },
                { label: 'Starts With', value: FilterOperator.ISTARTS_WITH },
                { label: 'Ends With', value: FilterOperator.IENDS_WITH },
            ];
        case DataTypes.BOOL:
            return [
                { label: 'True', value: FilterOperator.BOOLEAN_TRUE },
                { label: 'False', value: FilterOperator.BOOLEAN_FALSE },
            ];
        default:
            throw new Error(`Please provide a valid data type - provided: ${field.dataType}`);
    }
};
