import dataTypes from '../../filter-bar/enums/data-types';
import { CubedField } from '../../types';
import { ComparisonTableRow, TableColumn, TableComparisonTableRow, TableRow } from '../types';

type SortOrderBy = {
    column?: CubedField | TableColumn;
    direction?: string;
};

export const sortPieTableData = (orderBy: SortOrderBy, rows: TableRow[]) => {
    const sortedRows = rows.sort((a, b) => {
        if (orderBy.column && orderBy.direction) {
            if (orderBy.column.dataType === dataTypes.STRING) {
                const aValue = a.values[orderBy.column.rawName].value;
                const bValue = b.values[orderBy.column.rawName].value;

                if (orderBy.direction === 'asc') {
                    if (aValue < bValue) {
                        return 1;
                    }
                    return -1;
                } else {
                    if (aValue > bValue) {
                        return 1;
                    }
                    return -1;
                }
            }

            const aValue = a.values[orderBy.column.rawName].rawValue;
            const bValue = b.values[orderBy.column.rawName].rawValue;

            if (orderBy.direction === 'asc') {
                return +aValue - +bValue;
            }
            return +bValue - +aValue;
        }
        return 0;
    });

    return sortedRows;
};

export const sortPieTableComparisonData = (orderBy: SortOrderBy, rows: TableComparisonTableRow[]) => {
    if (orderBy.column && orderBy.direction && orderBy.column.rawName) {
        let orderByColumn: string = 'dimension';

        if (orderBy.column.displayName === 'This Period') {
            orderByColumn = 'data';
        } else if (orderBy.column.displayName === 'Comparison Period') {
            orderByColumn = 'comparisonData';
        } else if (orderBy.column.displayName === 'Variance %') {
            orderByColumn = 'variance';
        }

        rows.sort((a, b) => {
            if (orderBy.column && orderBy.direction) {
                const aData = a[orderByColumn as keyof TableComparisonTableRow] as ComparisonTableRow;
                const bData = b[orderByColumn as keyof TableComparisonTableRow] as ComparisonTableRow;

                if (!aData?.values || !aData.values[orderBy.column.rawName]) {
                    return 0;
                }
                if (!bData?.values || !bData.values[orderBy.column.rawName]) {
                    return 0;
                }
                const aValue =
                    aData.values[orderBy.column.rawName][orderByColumn === 'dimension' ? 'value' : 'rawValue'];
                const bValue =
                    bData.values[orderBy.column.rawName][orderByColumn === 'dimension' ? 'value' : 'rawValue'];

                if (orderByColumn === 'dimension') {
                    if (orderBy.direction === 'asc') {
                        if (aValue < bValue) {
                            return 1;
                        }
                        return -1;
                    } else {
                        if (aValue > bValue) {
                            return 1;
                        }
                        return -1;
                    }
                }

                if (orderBy.direction === 'asc') {
                    if (!bValue) {
                        return 1;
                    }
                    if (!aValue) {
                        return -1;
                    }

                    return +aValue - +bValue;
                }
                if (!aValue) {
                    return 1;
                }
                if (!bValue) {
                    return -1;
                }
                return +bValue - +aValue;
            }
            return 0;
        });
    }
};
