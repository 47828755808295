import React from 'react';

// Icons
import IconChartLine from '../components/icons/chart-line';
import IconChartUser from '../components/icons/chart-user';
import IconCog from '../components/icons/cog';
import IconDesktop from '../components/icons/desktop';
import IconHouse from '../components/icons/house';
import IconLaptopMedical from '../components/icons/laptop-medical';
import IconScrewdriverWrench from '../components/icons/screwdriver-wrench';
import IconTimeline from '../components/icons/timeline';

// Types & Enumns
import { UserTypes } from '../components/routes/enum/user-types';
import { HomepageDashboard } from '../context/homepage-context';

export const navigationConfig = (homepage: HomepageDashboard) => {
    return [
        {
            displayName: homepage && homepage.dashboardTitle ? homepage.dashboardTitle : 'Dashboard',
            sectionExcludedAccountTypes: [UserTypes.Api],
            path:
                homepage && homepage.dashboardId
                    ? `/dashboard-builder/dashboard/${homepage.dashboardId}`
                    : '/reports/attribution/channel-insights-dashboard',
            icon: <IconHouse />,
        },
        {
            displayName: 'Channels',
            sectionExcludedAccountTypes: [UserTypes.Api],
            icon: <IconTimeline />,
            hasDropdown: false,
            hasSubGroups: true,
            dropdownItems: [],
            dropdownGroups: [
                {
                    displayName: 'Insights',
                    icon: <IconChartLine />,
                    hasSubGroups: true,
                    groupItems: [
                        {
                            displayName: 'Channel Insights Overview',
                            description: 'Summarises the attributed count and value of all goals by marketing channel.',
                            path: '/reports/attribution/channel-insights-dashboard',
                        },
                        {
                            isSectionTitle: true,
                            displayName: 'Multichannel',
                        },
                        {
                            displayName: 'Channel Detail Overview',
                            description:
                                'Select a channel to see the attributed count and value of all goals by subchannel (e.g. campaign, keyword or referring site).',
                            path: '/reports/attribution/channel-detail-dashboard',
                        },
                        {
                            displayName: 'Attribution Explorer',
                            description: 'Explore the entire suite of attribution data across all dimensions.',
                            path: '/reports/attribution/lc-vs-attribution/',
                        },
                        {
                            displayName: 'Performance vs Targets',
                            description:
                                'Understand the performance of your marketing activity against your uploaded target data.',
                            path: '/reports/attribution/monthly-performance/',
                        },
                        {
                            isSectionTitle: true,
                            displayName: 'Single-channel',
                        },
                        {
                            displayName: 'PPC',
                            hasDropdown: true,
                            dropDownSubGroups: [
                                {
                                    displayName: 'PPC Performance',
                                    description:
                                        'Compare the Last Click and Attributed performances of your PPC marketing.',
                                    path: `/reports/attribution/channel-detail-dashboard#channel=${encodeURIComponent(
                                        'PPC'
                                    )}`,
                                },
                                {
                                    displayName: 'Google Ads Impression Share Overview',
                                    description:
                                        'Analyse the impression share performance of your Ads by understanding your quality scores and budgeting.',
                                    path: '/reports/channels/ppc/keyword-impression-share',
                                },
                            ],
                        },
                        {
                            displayName: 'SEO Performance',
                            description:
                                'See the attributed traffic and conversions from your SEO Markets, Category, Sub Category, Keywords & Landing Page.',
                            path: `/reports/attribution/channel-detail-dashboard#channel=${encodeURIComponent('SEO')}`,
                        },
                        {
                            displayName: 'Impressions Performance',
                            description:
                                'Analyse the impact of above-the-line Impression activity, including Display, VOD etc.',
                            path: '/reports/channels/atl/impression-traffic-forecast',
                        },
                        {
                            displayName: 'Social Paid Performance',
                            description: 'Compare the LC and attributed performance of your Social Paid Activity.',
                            path: `/reports/attribution/channel-detail-dashboard#channel=${encodeURIComponent(
                                'Social Paid'
                            )}`,
                        },
                        {
                            displayName: 'ATL Performance',
                            description:
                                'Explore attribution data across all dimensions, with ATL marketing integration and reallocation.',
                            path: '/reports/attribution/reallocation-atl/',
                        },
                        {
                            displayName: 'Email Performance',
                            description: 'See the attributed traffic and conversions from your Email Campaigns.',
                            path: `/reports/attribution/channel-detail-dashboard#channel=${encodeURIComponent(
                                'Email'
                            )}`,
                        },
                    ],
                },
                {
                    displayName: 'Tools',
                    icon: <IconScrewdriverWrench />,
                    groupItems: [
                        {
                            isSectionTitle: true,
                            displayName: 'Multichannel',
                        },
                        {
                            displayName: 'Channel Journeys',
                            description:
                                'Understand how channels work together to drive conversions, for new and repeat customers.',
                            path: '/reports/user-behaviour/journeys/journey-analysis',
                        },
                        {
                            displayName: 'Attribution Comparison',
                            hasDropdown: true,
                            dropDownSubGroups: [
                                {
                                    displayName: 'Comparison Overview',
                                    description:
                                        'Compare the results of the Cubed attribution model against last-click.',
                                    path: '/reports/attribution/attribution-comparison/',
                                },
                                {
                                    displayName: 'Contribution Analysis',
                                    description: 'Visualise the flow of attribution data across channels.',
                                    path: '/reports/attribution/contribution-analysis/',
                                },
                            ],
                        },
                        {
                            isSectionTitle: true,
                            displayName: 'SEO',
                        },
                        {
                            displayName: 'SEO Rank & Marketshare',
                            description:
                                'See the Rank & Marketshare of your SEO Keywords by Market, Category & Sub Category.',
                            path: '/reports/channels/seo/keyword-marketshare',
                        },
                        {
                            displayName: 'SEO Prioritisation Tool',
                            description:
                                'Identify and prioritise your SEO keywords globally to focus your efforts in order to maximise return on investment.',
                            path: '/reports/channels/seo/keywords',
                        },
                        {
                            displayName: 'SEO Search Trends',
                            description: 'Explore search trends from Google Trends by keyword Market and Category.',
                            path: '/reports/channels/seo/google-trends',
                        },
                        {
                            displayName: 'Tech SEO Dashboard',
                            description: 'Explore tech SEO data to optimise your site pages.',
                            path: '/reports/channels/seo/tech-seo/dashboard',
                        },
                    ],
                },
            ],
        },
        {
            displayName: 'Content',
            sectionExcludedAccountTypes: [UserTypes.Api],
            hasSubGroups: true,
            icon: <IconDesktop />,
            dropdownGroups: [
                {
                    displayName: 'Insights',
                    icon: <IconChartLine />,
                    groupItems: [
                        {
                            displayName: 'Content Insights Overview',
                            description:
                                'Summarises the attributed count and value of all goals by the type of page on your site.',
                            path: '/reports/attribution/content-insights-dashboard',
                        },
                        // {
                        //     displayName: 'Content Detail Overview',
                        //     description: 'Select a page segment to see the attributed count and value of all goals by pages within the segment (e.g. Page Category, Page Topic or Content Length).',
                        //     path: '/reports/attribution/content-detail-dashboard',
                        // },
                        {
                            displayName: 'Page Performance',
                            description: 'Measure the attributed performance of your pages.',
                            path: '/reports/attribution/page-performance/',
                        },
                        {
                            displayName: 'Landing Page Performance',
                            description: 'Measure the attributed performance of your landing pages.',
                            path: '/reports/attribution/landing-page-performance/',
                        },
                        {
                            displayName: 'SKU Performance',
                            description:
                                'Measure the attributed performance of your products, including SKU reporting.',
                            path: '/reports/attribution/sku-performance',
                        },
                        {
                            displayName: 'Corridor Performance',
                            description: 'Measure the attributed performance of your corridors.',
                            path: '/reports/attribution/corridor-performance',
                        },
                        {
                            displayName: 'Event Funnels',
                            description:
                                'Build custom funnels to visualise and map the flow of visitors across your events',
                            path: '/reports/user-behaviour/journeys/event-funnels',
                        },
                    ],
                },
                {
                    displayName: 'Tools',
                    icon: <IconScrewdriverWrench />,
                    groupItems: [
                        {
                            displayName: 'Carbon Usage Analysis',
                            description:
                                'Measure and understand the carbon footprint of your website and digital activities.',
                            path: '/reports/sustainability/carbon/carbon-usage-report',
                        },
                    ],
                },
            ],
        },
        {
            displayName: 'Customer',
            sectionExcludedAccountTypes: [UserTypes.Api],
            hasSubGroups: true,
            icon: <IconChartUser />,
            dropdownGroups: [
                {
                    displayName: 'Insights',
                    icon: <IconChartLine />,
                    groupItems: [
                        {
                            displayName: 'Customer Insights Overview',
                            description:
                                'Summarises the attributed count and value of all goals by the type and segment of the visitors to your site.',
                            path: '/reports/attribution/customer-insights-dashboard',
                        },
                        {
                            displayName: 'New v Repeat Customers',
                            description:
                                'Understand which channels and campaigns are driving first time sales v. repeat customer sales.',
                            path: '/reports/attribution/new-vs-repeat-sales/',
                        },
                        {
                            displayName: 'Geographical Performance',
                            description: 'Measure the attributed performance of countries and cities.',
                            path: '/reports/attribution/region-performance/',
                        },
                        // {
                        //     displayName: 'Segments Dashboard',
                        //     description:
                        //         'Explore your Segment data, including customers in segments and the most popular Segments over time.',
                        //     path: '/reports/segmenter/dashboard',
                        // },
                    ],
                },
                {
                    displayName: 'Tools',
                    icon: <IconScrewdriverWrench />,
                    groupItems: [
                        {
                            displayName: 'Single Customer View',
                            description: "View a customer's journey throughout your site, across multiple visits.",
                            path: '/reports/user-behaviour/journeys/single-customer-view',
                        },
                    ],
                },
            ],
        },
        {
            displayName: 'Account',
            hasDropdown: true,
            hasSubGroups: true,
            icon: <IconCog />,
            dropdownItems: [
                {
                    displayName: 'Account Configuration',
                    itemExcludedAccountTypes: [UserTypes.Viewer],
                    description: 'Configure your account for data collection and manage your user settings.',
                    path: '/account-configuration',
                },

                {
                    displayName: 'Documentation',
                    itemExcludedAccountTypes: [UserTypes.Viewer],
                    description: 'See our documentation for onboarding Cubed.',
                    path: '/account-management',
                },
            ],
            dropdownGroups: [
                {
                    displayName: 'Account Health',
                    subGroupExcludedAccountTypes: [UserTypes.Api],
                    icon: <IconLaptopMedical />,
                    groupItems: [
                        {
                            displayName: 'Account Health Dashboard',
                            description: 'Monitor active propensity models, sync data and tag validation errors.',
                            path: '/reports/health/account-health-dashboard/',
                        },
                        {
                            displayName: 'Real Time Reporting',
                            description: 'Monitor Page Views, Event Triggers and Tag Validation Errors in real time.',
                            path: '/health',
                        },
                    ],
                },
            ],
        },
    ];
};
