// Helpers
import { unknownErrorData } from '../../../helpers/errors';
import { getRawValueAsNumber } from '../../../helpers/resource-data';

// Types
import { CubedField } from '../../../../types';
import { ResourceData, BigNumberData, BigNumberFormatting, BigNumberDecimalRounding } from '../../../types';

export type UseResourceBigNumberArgs = {
    totalsResourceData: ResourceData;
    graphResourceData: ResourceData;
    field: CubedField;
    formatting?: BigNumberFormatting;
    rounding?: BigNumberDecimalRounding;
    displayGraph?: boolean;
};

const useResourceBigNumberSeparateTotalsGraph = ({
    totalsResourceData,
    graphResourceData,
    field,
    formatting,

    rounding = {
        applyRounding: true,
    },

    displayGraph = true,
}: UseResourceBigNumberArgs): BigNumberData => {
    if (
        totalsResourceData.status === 'loading' ||
        totalsResourceData.status === 'empty' ||
        totalsResourceData.status === 'error'
    ) {
        return totalsResourceData;
    }

    if (
        graphResourceData.status === 'loading' ||
        graphResourceData.status === 'empty' ||
        graphResourceData.status === 'error'
    ) {
        return graphResourceData;
    }

    if (totalsResourceData.status === 'success' && graphResourceData.status === 'success') {
        try {
            const totalValueString = totalsResourceData.meta.totals
                ? totalsResourceData.meta.totals[field.rawName].value
                : '0';
            const stripped = totalValueString.replace(/,/g, '');
            const totalValue = parseFloat(stripped);

            const graphSeries = graphResourceData.objects.map(x => getRawValueAsNumber(x, field.rawName));

            return {
                type: 'bigNumber',
                status: 'success',
                title: field.displayName,
                series: displayGraph ? graphSeries : undefined,
                value: totalValue,
                formatting: formatting,
                rounding: rounding,
            };
        } catch (error) {
            const message = error instanceof Error ? error.message : String(error);
            return {
                type: 'bigNumber',
                status: 'error',
                error: {
                    message: message,
                },
            };
        }
    }
    return unknownErrorData();
};

export default useResourceBigNumberSeparateTotalsGraph;
