/* eslint-disable react-hooks/exhaustive-deps */
// React Dependencies
import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// Types
import { ConfigDataSuccess } from '../../react-query/types';

// Redux
import { RootState } from '../../redux/reducers/core';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../redux/actions/notification';

// Layouts
import { ErrorModalLayout, ModalLayout } from './modal-layout';

// Queries
import useFetchResource from '../../react-query/hooks/use-fetch-resource';

// Configurations
import { CONFIG_FIXED_COST, CONFIG_LITE_FIXED_COST } from '../../configurations/resources-config';

// Components
import ConfigTable from '../../components/tables/config-table/config-table';

// Enums
import { NotificationMessageType } from '../../enums/notification-types';

// Helpers
import { removePopup, setPopup } from '../../redux/actions/popup';
import { ButtonThemes } from '../../enums/button-themes';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';
import { setModal } from '../../redux/actions/modal';

// Types
type FixedCostData = {
    id: number;
    description: string;
    channelName: string;
    cost: string;
    costPer: string;
    startDate: string;
    endDate: string;
    selected: boolean;
};

const LayoutModalManageTimezoneAndCurrency = () => {
    const dispatch = useDispatch();

    const { currency } = useSelector((state: RootState) => state);

    // Fixed cost data
    const [fixedCostData, setFixedCostData] = useState<FixedCostData[]>([]);
    const [fixedCostDataToDelete, setFixedCostDataToDelete] = useState<number[]>([]);

    // Table States
    const [disableTable, setDisableTable] = useState(false);

    // Queries & Mutations
    const fixedCostQuery = useFetchResource({
        resource: CONFIG_LITE_FIXED_COST,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(cost => {
                return {
                    id: cost.id,
                    description: cost.description,
                    channelName: cost.referer,
                    cost: `${currency.symbol}${cost.cost}`,
                    costPer: cost.per,
                    startDate: Moment(cost.start_date).format('DD/MM/YYYY'),
                    endDate: Moment(cost.end_date).format('DD/MM/YYYY'),
                    selected: false,
                };
            });
        },
    });

    const fixedCostDataDeleteMutation = useDeleteResource({
        resource: CONFIG_FIXED_COST,
        resourceIds: fixedCostDataToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            setDisableTable(false);
            fixedCostQuery.refetch();
            dispatch(
                addNotification({
                    copy: 'Fixed Cost successfully deleted.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setDisableTable(false);
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your Fixed Costs. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    // Refetch data when the modal is opened
    useEffect(() => {
        fixedCostQuery.refetch();
    }, []);

    // Fetch Fixed cost data
    useEffect(() => {
        if (fixedCostQuery.data) {
            setFixedCostData(fixedCostQuery.data);
        }
    }, [fixedCostQuery.data]);

    // Update the deleted Fixed Cost data
    useEffect(() => {
        setFixedCostDataToDelete(fixedCostData.filter(cost => cost.selected).map(cost => cost.id));
    }, [fixedCostData]);

    // Handelers
    const handleCheckboxChange = (rowId: number) => {
        setFixedCostData(
            fixedCostData.map(cost => {
                if (cost.id === rowId) {
                    return {
                        ...cost,
                        selected: !cost.selected,
                    };
                }

                return cost;
            })
        );
    };

    const handleSingleCostDeleteClick = (rowId: number) => {
        setFixedCostDataToDelete([rowId]);

        const handleDisardClick = () => {
            setFixedCostDataToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setDisableTable(true);
            fixedCostDataDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this Fixed Cost?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkDeleteClick = () => {
        if (fixedCostDataToDelete.length === 0) return;

        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setFixedCostData(
                fixedCostData.map(cost => {
                    return {
                        ...cost,
                        selected: !cost.selected,
                    };
                })
            );
            setDisableTable(true);
            fixedCostDataDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected Fixed Costs?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleCreateFixedCostClick = () => {
        dispatch(setModal('SetFixedCost', {}));
    };

    if (fixedCostQuery.status === 'success' || fixedCostQuery.status === 'pending') {
        return (
            <ModalLayout modalHeader="Manage Fixed Costs">
                <p>
                    Manage previously configured fixed costs below. For more help see{' '}
                    <a href="https://tag.docs.withcubed.com/onboarding/general/#fixed-costs">fixed costs</a>.
                </p>

                <ConfigTable
                    status={fixedCostQuery.status}
                    isFetching={fixedCostQuery.isFetching}
                    disabled={disableTable}
                    empty={fixedCostData.length === 0}
                >
                    <ConfigTable.Table maxHeight="400px">
                        <ConfigTable.Header>
                            <ConfigTable.Row key="thead">
                                <ConfigTable.CellHeader />
                                <ConfigTable.CellHeader>Cost Description</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Channel Name</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Cost</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Cost Per</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>Start Date</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader>End Date</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader />
                            </ConfigTable.Row>
                        </ConfigTable.Header>
                        <ConfigTable.Body>
                            {fixedCostData.map(cost => {
                                return (
                                    <ConfigTable.Row key={cost.id}>
                                        <ConfigTable.CellCheckbox
                                            rowId={cost.id}
                                            checked={cost.selected}
                                            onCheckedChange={handleCheckboxChange}
                                        />
                                        <ConfigTable.Cell>{cost.description}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{cost.channelName}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{cost.cost}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{cost.costPer}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{cost.startDate}</ConfigTable.Cell>
                                        <ConfigTable.Cell>{cost.endDate}</ConfigTable.Cell>
                                        <ConfigTable.CellActions>
                                            <ConfigTable.ActionDropdownItem
                                                rowId={cost.id}
                                                type="delete"
                                                onClick={handleSingleCostDeleteClick}
                                            />
                                        </ConfigTable.CellActions>
                                    </ConfigTable.Row>
                                );
                            })}
                        </ConfigTable.Body>
                    </ConfigTable.Table>
                    <ConfigTable.ActionBar>
                        <ConfigTable.ActionBarJustifyLeft>
                            <ConfigTable.ActionButton
                                type="delete"
                                label="Delete Fixed Costs"
                                onClick={handleBulkDeleteClick}
                                isDisabled={fixedCostDataToDelete.length === 0}
                            />
                        </ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionBarJustifyRight>
                            <ConfigTable.ActionButton
                                type="add"
                                label="Create New Fixed Costs"
                                onClick={handleCreateFixedCostClick}
                                isDisabled={disableTable}
                            />
                        </ConfigTable.ActionBarJustifyRight>
                    </ConfigTable.ActionBar>
                </ConfigTable>
            </ModalLayout>
        );
    }

    // Page Error
    return <ErrorModalLayout modalHeader="Manage Fixed Costs" />;
};

export default LayoutModalManageTimezoneAndCurrency;
