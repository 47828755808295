import { createStaticRanges } from 'react-date-range';
import moment from 'moment';

const START_DATE = moment.utc().subtract(1, 'hour');
const END_DATE = moment.utc();

export const STATIC_DATE_RANGE = [
    ...createStaticRanges([
        {
            label: 'Yesterday',
            range: () => ({
                startDate: moment().subtract(1, 'days').toDate(),
                endDate: moment().subtract(1, 'days').toDate(),
            }),
        },
        {
            label: 'Last 7 Days',
            range: () => ({
                startDate: moment().subtract(7, 'days').toDate(),
                endDate: moment().subtract(1, 'days').toDate(),
            }),
        },
        {
            label: 'Last 30 Days',
            range: () => ({
                startDate: moment().subtract(30, 'days').toDate(),
                endDate: moment().subtract(1, 'days').toDate(),
            }),
        },
        {
            label: 'This Month',
            range: () => ({
                startDate: moment().startOf('month').toDate(),
                endDate: moment().endOf('month').toDate(),
            }),
        },
        {
            label: 'Last Month',
            range: () => ({
                startDate: moment().subtract(1, 'months').startOf('month').toDate(),
                endDate: moment().subtract(1, 'months').endOf('month').toDate(),
            }),
        },
    ]),
];

export const selectionRange = () => {
    let initialStartDate = '';
    let initialEndDate = '';
    const currentTimezoneOffset = new Date().getTimezoneOffset();

    if (Math.sign(currentTimezoneOffset) === 1) {
        initialStartDate = START_DATE.clone().add(currentTimezoneOffset, 'minutes');
        initialEndDate = END_DATE.clone().add(currentTimezoneOffset, 'minutes');
    } else {
        initialStartDate = START_DATE.clone().subtract(currentTimezoneOffset * -1, 'minutes');
        initialEndDate = END_DATE.clone().subtract(currentTimezoneOffset * -1, 'minutes');
    }

    return {
        startDate: initialStartDate.toDate(),
        endDate: initialEndDate.toDate(),
        key: 'selection',
    };
};
