import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import IconCog from '../../../../components/icons/cog';
import styled from 'styled-components';
import WidgetBaseLink from '../../base/widget-base-link';

const StyledIconCog = styled(IconCog)`
    height: 20px;
    min-width: 20px;
    & path {
        fill: ${props => props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledDropdownMenuTrigger = styled(DropdownMenu.Trigger)<{ $loading: boolean }>`
    border: none;
    cursor: ${props => !props.$loading && 'pointer'}!important;
    outline: none;
    padding: 0;

    &:hover,
    :focus {
        background-color: ${props => !props.$loading && props.theme.sectionDashboard.widget.menu.backgroundHover};
    }

    &:hover,
    :focus ${StyledIconCog} {
        & path {
            fill: ${props => props.theme.sectionDashboard.widget.menu.textColorHover};
        }
    }
`;

const StyledDropdownMenuContent = styled(DropdownMenu.Content)`
    background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundColor};
    min-width: 210px;
    padding: 10px 0px;
    border-radius: 6px;
    box-shadow: 0px 1px 25px -1px rgba(0, 0, 0, 0.1), 0px 1px 12px rgba(0, 0, 0, 0.1);
    z-index: 50;
    max-height: 80vh;
    overflow-y: auto;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
`;

export enum DropdownSide {
    TOP = 'top',
    BOTTOM = 'bottom',
    RIGHT = 'right',
    LEFT = 'left',
}

export type WidgetMenuDropdownProps = {
    loading: boolean;
    children?: React.ReactNode;
    dropdownSide?: DropdownSide;
};

const WidgetMenuDropdown = ({ loading, children, dropdownSide = DropdownSide.TOP }: WidgetMenuDropdownProps) => {
    return (
        <DropdownMenu.Root modal={false}>
            <StyledDropdownMenuTrigger $loading={loading}>
                <WidgetBaseLink icon={<StyledIconCog />} loading={loading} />
            </StyledDropdownMenuTrigger>

            {!loading && (
                <DropdownMenu.Portal>
                    <StyledDropdownMenuContent side={dropdownSide} align="end">
                        {children}
                    </StyledDropdownMenuContent>
                </DropdownMenu.Portal>
            )}
        </DropdownMenu.Root>
    );
};

export default WidgetMenuDropdown;
