import React, { useContext } from 'react';
import styled from 'styled-components';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { RouteLocation } from '../../styled-components/theme-context/cubed-theme-context';

// Context
import { DashboardBuilderContext } from '../context/dashboard-builder-context';
import CreateADashboardContextProvider from '../create-a-dashboard/context/create-a-dashboard-context';

// Icons
import IconDisplaySlash from '../../components/icons/display-slash';

// Layouts
import LayoutCreateAWidget from '../create-a-widget/layouts/layout-create-a-widget';
import LayoutCreateADashboard from '../create-a-dashboard/layouts/layout-create-a-dashboard';
import LayoutDisplayDashboard from '../display-dashboard/layouts/layout-display-dashboard';
import LayoutWidgetLibrary from '../libraries/widget-library/layouts/layout-widget-library';
import LayoutDashboardLibrary from '../libraries/dashboard-library/layouts/layout-dashboard-library';
import LayoutEditWidget from '../create-a-widget/layouts/layout-edit-a-widget';
import LayoutEmpty from './layout-empty';

const StyledIconDisplaySlash = styled(IconDisplaySlash)`
    width: 40px;
`;

const LayoutDashboardBuilder = () => {
    const location = useLocation() as RouteLocation;
    location.isSectionDashboard = true;

    const { clientUserQuery, clientUserId } = useContext(DashboardBuilderContext);

    if (clientUserQuery.isFetched && !clientUserId) {
        return (
            <LayoutEmpty
                icon={<StyledIconDisplaySlash />}
                copy="Your account has not yet been activated to use the dashboard builder. Please check back later."
            />
        );
    }

    return (
        <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="create-a-widget" element={<LayoutCreateAWidget />} />
            <Route path="edit-widget/:widgetId" element={<LayoutEditWidget />} />
            <Route
                path="create-a-dashboard"
                element={
                    <CreateADashboardContextProvider>
                        <LayoutCreateADashboard />
                    </CreateADashboardContextProvider>
                }
            />
            <Route
                path="edit-dashboard/:dashboardId"
                element={
                    <CreateADashboardContextProvider>
                        <LayoutCreateADashboard />
                    </CreateADashboardContextProvider>
                }
            />
            <Route path="dashboard/:dashboardId" element={<LayoutDisplayDashboard />} />
            <Route path="dashboard-comparison/:dashboardId" element={<LayoutDisplayDashboard isComparison={true} />} />
            <Route path="widget-library" element={<LayoutWidgetLibrary />} />
            <Route path="dashboard-library" element={<LayoutDashboardLibrary />} />
        </Routes>
    );
};

export default LayoutDashboardBuilder;
