import React from 'react';
import styled from 'styled-components';

// Images
import cancelImage from '../assets/images/cancel.svg';
import cancelImageWhite from '../assets/images/cancel-white.svg';

// Styles
const StyledCancelButton = styled('span')<{ backgroundImage: string }>`
    cursor: pointer;
    opacity: 0.8;
    height: 24px;
    width: 24px;
    border-radius: 24px;
    display: inline;
    background-image: url(${props => props.backgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.63;
    transition: 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
`;

const StyledButtonContainer = styled('span')<{ floatRight: boolean }>`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    float: ${props => props.floatRight && 'right'};
    padding-right: ${props => props.floatRight && '8px !important'};
`;

// Types
export type CancelProps = {
    onClick: () => void;
    colour: 'white' | 'red';
    floatRight: boolean;
};

const Cancel = ({ onClick, colour, floatRight }: CancelProps) => {
    return (
        <StyledButtonContainer floatRight={floatRight} onClick={onClick}>
            <StyledCancelButton backgroundImage={colour === 'white' ? cancelImageWhite : cancelImage} />
        </StyledButtonContainer>
    );
};

export default Cancel;
