import React from 'react';
import styled, { useTheme } from 'styled-components';

// Context
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Config
import { barGraphConfig, columnBarGraphConfig } from '../../configurations/widget-bar-graph-config';

// Types
import { BarGraphComparisonData } from '../../types';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Components
import WidgetBase from '../base/widget-base';
import Message from '../../../components/message';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const StyledDivider = styled.div`
    width: 1px;
    height: 100%;
    background-color: ${props => props.theme.sectionDashboard.widget.barGraphComparison.label.backgroundColor};
`;

const StyledChartContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledLabel = styled.div`
    background-color: ${props => props.theme.sectionDashboard.widget.barGraphComparison.label.backgroundColor};
    color: ${props => props.theme.sectionDashboard.widget.barGraphComparison.label.color};
    text-align: center;
    padding: 3px 5px;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
`;

const StyledMessageContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export type WidgetBarGraphComparisonProps = {
    title: string;
    data: BarGraphComparisonData;
    href?: string;
};

const WidgetBarGraphComparison = ({ title, data, href }: WidgetBarGraphComparisonProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    const columnConfig = columnBarGraphConfig(theme);
    const barConfig = barGraphConfig(theme);

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const series = data.bars.map(bar => bar.value);
    const seriesComparison = data.barsComparison.map(bar => bar.value);

    const categories = data.bars.map(bar => bar.name);
    const categoriesComparison = data.barsComparison.map(bar => bar.name);

    const colours = data.bars.map(bar => bar.colour);
    const coloursComparison = data.barsComparison.map(bar => bar.colour);

    const seriesName = data.metric;

    const renderMessage = () => {
        return (
            <StyledMessageContainer>
                <Message
                    title="No Data"
                    shortTitle="No Data"
                    copy="Try adjusting your filters."
                    type="info"
                    size={width > 3 && height > 2 ? 'small' : 'xsmall'}
                    display="vertical"
                />
            </StyledMessageContainer>
        );
    };

    if (width === 1 && height === 1) {
        const config = {
            ...barConfig,
            colors: colours,
            xAxis: {
                ...barConfig.xAxis,
                categories: categories,
                labels: {
                    enabled: false,
                },
            },
            yAxis: {
                ...barConfig.yAxis,
                labels: {
                    enabled: false,
                },
            },
            series: [
                {
                    data: series,
                },
            ],
        };

        return (
            <WidgetBase title={title} href={href}>
                <StyledContainer>
                    <StyledChartContainer>
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...config, series: [{ data: series }] }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                        <StyledLabel>This Period</StyledLabel>
                    </StyledChartContainer>
                </StyledContainer>
            </WidgetBase>
        );
    }

    if (height > width) {
        const baseConfig = {
            ...barConfig,
            xAxis: {
                ...barConfig.xAxis,
                labels: {
                    ...barConfig.xAxis.labels,
                    enabled: width > 1 && height > 1,
                },
            },
            yAxis: {
                ...barConfig.yAxis,
                labels: {
                    ...barConfig.yAxis.labels,
                    enabled: width > 1 && height > 1,
                },
            },
        };

        const config = {
            ...baseConfig,
            colors: colours,
            xAxis: {
                ...baseConfig.xAxis,
                categories: categories,
            },
            series: [
                {
                    data: series,
                },
            ],
        };

        const configComparison = {
            ...baseConfig,
            colors: coloursComparison,
            xAxis: {
                ...baseConfig.xAxis,
                categories: categoriesComparison,
            },
            series: [
                {
                    data: seriesComparison,
                },
            ],
        };

        return (
            <WidgetBase title={title} href={href}>
                <StyledContainer>
                    <StyledChartContainer>
                        {series.length > 0 ? (
                            <CommonHighcharts
                                highcharts={Highcharts}
                                options={{ ...config, series: [{ data: series }] }}
                                containerProps={{ style: { height: '100%', width: '100%' } }}
                            />
                        ) : (
                            renderMessage()
                        )}
                        <StyledLabel>This Period</StyledLabel>
                    </StyledChartContainer>
                    <StyledDivider />
                    <StyledChartContainer>
                        {seriesComparison.length > 0 ? (
                            <CommonHighcharts
                                highcharts={Highcharts}
                                options={{ ...configComparison }}
                                containerProps={{ style: { height: '100%', width: '100%' } }}
                            />
                        ) : (
                            renderMessage()
                        )}
                        <StyledLabel>Comparison Period</StyledLabel>
                    </StyledChartContainer>
                </StyledContainer>
            </WidgetBase>
        );
    }

    const baseConfig = {
        ...columnConfig,
        xAxis: {
            ...columnConfig.xAxis,
            labels: {
                ...columnConfig.xAxis.labels,
                enabled: width > 1 && height > 1,
            },
        },
        yAxis: {
            ...columnConfig.yAxis,
            title: {
                ...columnConfig.yAxis.title,
                text: data.metric,
            },
            labels: {
                ...columnConfig.yAxis.labels,
                enabled: width > 1 && height > 1,
            },
        },
    };

    const config = {
        ...baseConfig,
        colors: colours,
        xAxis: {
            ...baseConfig.xAxis,
            categories: categories,
        },
        series: {
            name: seriesName,
            data: series,
        },
    };

    const configComparison = {
        ...baseConfig,
        colors: coloursComparison,
        xAxis: {
            ...baseConfig.xAxis,
            categories: categoriesComparison,
        },
        series: {
            name: seriesName,
            data: seriesComparison,
        },
    };

    return (
        <WidgetBase title={title} href={href}>
            <StyledContainer>
                <StyledChartContainer>
                    {series.length > 0 ? (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...config }}
                            containerProps={{ style: { width: '100%', height: '100%' } }}
                        />
                    ) : (
                        renderMessage()
                    )}
                    <StyledLabel>This Period</StyledLabel>
                </StyledChartContainer>
                <StyledDivider />
                <StyledChartContainer>
                    {seriesComparison.length > 0 ? (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...configComparison }}
                            containerProps={{ style: { width: '100%', height: '100%' } }}
                        />
                    ) : (
                        renderMessage()
                    )}
                    <StyledLabel>Comparison Period</StyledLabel>
                </StyledChartContainer>
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetBarGraphComparison;
