import React from 'react';
import styled from 'styled-components';
import { Instance } from 'tinycolor2';
import InputColourPicker from '../inputs/input-colour-picker';
import Tooltip from '../tooltip';

const StyledFormField = styled.div`
    padding-right: 15px;
    position: relative;
    display: block;
    margin-bottom: 15px;
`;

const StyledFormFieldTop = styled.div`
    margin-bottom: 5px;
`;

const StyledLabel = styled.label`
    font-size: 1em;
    font-weight: 400;
`;

const StyledErrorMessage = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.colours.red};
    margin-bottom: 1em;
`;

const StyledRequiredField = styled.span`
    color: ${props => props.theme.colours.red};
`;

type InputColour = { name: string; colour: Instance };

export type FormColourPickerProps = {
    toolTipCopy?: string;
    errorMessage?: string;
    requiredField?: boolean;
    inputKeyValue: string;
    label?: string;
    inputColours: InputColour[];
    inputSelectedColour: InputColour;
    onInputChange: () => void;
};

const FormColourPicker = ({
    toolTipCopy,
    errorMessage,
    requiredField,
    inputKeyValue,
    label,
    inputColours,
    inputSelectedColour,
    onInputChange,
}: FormColourPickerProps) => {
    return (
        <StyledFormField key={inputKeyValue} data-testid="form-colour-picker">
            <StyledFormFieldTop>
                <StyledLabel>
                    {label} {requiredField && <StyledRequiredField>*</StyledRequiredField>}
                </StyledLabel>
                {toolTipCopy && <Tooltip copy={toolTipCopy} />}
            </StyledFormFieldTop>

            <InputColourPicker
                colours={inputColours}
                selectedColour={inputSelectedColour}
                onSelectedColourChanged={onInputChange}
            />

            {errorMessage && <StyledErrorMessage> {errorMessage} </StyledErrorMessage>}
        </StyledFormField>
    );
};

export default FormColourPicker;
