// Helpers
import { unknownErrorData } from '../../../helpers/errors';
import { getRawValueAsNumber } from '../../../helpers/resource-data';
import { aggregateBigNumber } from '../../../helpers/aggregate-big-number';

// Types
import { CubedField } from '../../../../types';
import {
    ResourceData,
    BigNumberAggregation,
    BigNumberData,
    BigNumberFormatting,
    BigNumberDecimalRounding,
} from '../../../types';

export type UseResourceBigNumberArgs = {
    resourceData: ResourceData;
    aggregationSumData?: ResourceData;
    field?: CubedField;
    aggregationSumField?: CubedField;
    aggregation?: BigNumberAggregation;
    formatting?: BigNumberFormatting;
    rounding?: BigNumberDecimalRounding;
    displayGraph?: boolean;
};

const useResourceBigNumber = ({
    resourceData,
    aggregationSumData,
    field,
    aggregationSumField,
    aggregation = 'avg',
    formatting,
    rounding = {
        applyRounding: true,
    },
    displayGraph = true,
}: UseResourceBigNumberArgs): BigNumberData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success' && field) {
        try {
            const values = resourceData.objects.map(x => getRawValueAsNumber(x, field.rawName));
            const aggregationSumValues =
                aggregationSumData && aggregationSumData?.status === 'success'
                    ? aggregationSumData.objects.map(x =>
                          getRawValueAsNumber(x, (aggregationSumField && aggregationSumField.rawName) || field.rawName)
                      )
                    : undefined;

            const value = aggregateBigNumber(values, aggregation, aggregationSumValues);

            return {
                type: 'bigNumber',
                status: 'success',
                title: field.displayName,
                series: displayGraph ? values : undefined,
                value,
                ratioValue: aggregationSumValues && aggregation === 'ratio' ? 100 - value : undefined,
                formatting: formatting,
                rounding: rounding,
            };
        } catch (error) {
            const message = error instanceof Error ? error.message : String(error);
            return {
                type: 'bigNumber',
                status: 'error',
                error: {
                    message: message,
                },
            };
        }
    }

    return unknownErrorData();
};

export default useResourceBigNumber;
