import React from 'react';
import styled from 'styled-components';

// Chart Config
import { vennConfig } from '../../../configurations/charts/venn-diagram-config';

// Highcharts Dependencies
import Highcharts from 'highcharts';
import VennModule from 'highcharts/modules/venn.js';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Components
import WidgetBase from '../base/widget-base';
import WidgetVennBigNumberComparison from './widget-venn-big-number-comparison';
import Message from '../../../components/message';
import WidgetMenuVenn from '../menus/components/widget-menu/widget-menu-venn';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';
import useWidgetVennState from '../menus/hooks/use-widget-menu-venn-state';

// Types
import { VennComparisonData, VennSeriesObject } from '../../types';

VennModule(Highcharts);

const StyledBigNumberContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const StyledContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0px;
`;

const StyledDiagramContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledTitleContainer = styled.div<{ height: number }>`
    color: ${props => props.theme.sectionDashboard.widget.venn.title.textColor};
    text-align: center;
    font-size: ${props => (props.height > 1 ? '0.9rem ' : '0.8rem')};
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    padding: ${props => (props.height > 1 ? '10px 0px' : '3px 0px')};
`;

const StyledNoDataContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export type WidgetVennComparisonProps = {
    title: string;
    data: VennComparisonData;
    href?: string;
};

const WidgetVennComparison = ({ title, data, href }: WidgetVennComparisonProps) => {
    const { width, height } = useSectionDashboard();
    const { menuConfig, handleCheckboxChange } = useWidgetVennState({ data });

    const empty = data.status === 'empty' || (menuConfig && menuConfig.every(config => !config.checked));

    if (data.status === 'loading') {
        return (
            <WidgetBase
                title={title}
                loading={true}
                menu={<WidgetMenuVenn data={data} menuConfig={menuConfig} handleMenuChange={handleCheckboxChange} />}
            />
        );
    }

    if (empty) {
        return (
            <WidgetBase
                title={title}
                noData={true}
                href={href}
                menu={<WidgetMenuVenn data={data} menuConfig={menuConfig} handleMenuChange={handleCheckboxChange} />}
            />
        );
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    let { series, comparisonSeries } = data;

    const updateSeriesVisibility = (series?: VennSeriesObject) => {
        if (series) {
            series = {
                ...series,
                data: series.data.map(item => {
                    return {
                        ...item,
                        value:
                            menuConfig && menuConfig.find(config => item.sets.includes(config.label) && config.checked)
                                ? item.value
                                : 0,
                    };
                }),
            };
            return series;
        }
    };

    if (menuConfig) {
        series = updateSeriesVisibility(series);
        comparisonSeries = updateSeriesVisibility(comparisonSeries);
    }

    if (width === 1 || height === 1) {
        // get the top crossovers for display in big numbers
        const list = series
            ? series.data.filter(item => item.sets && item.sets.length > 1).sort((a, b) => b.value - a.value)
            : [];

        const comparisonList = comparisonSeries
            ? comparisonSeries.data.filter(item => item.sets && item.sets.length > 1).sort((a, b) => b.value - a.value)
            : [];

        const direction = width > height ? 'horizontal' : 'vertical';

        return (
            <WidgetBase
                title={title}
                href={href}
                menu={<WidgetMenuVenn data={data} menuConfig={menuConfig} handleMenuChange={handleCheckboxChange} />}
            >
                <StyledBigNumberContainer>
                    <WidgetVennBigNumberComparison
                        data={list}
                        comparisonData={comparisonList}
                        width={width}
                        height={height}
                        direction={direction}
                    />
                </StyledBigNumberContainer>
            </WidgetBase>
        );
    }

    const renderNoDataMessage = () => (
        <StyledNoDataContainer>
            <Message
                copy={`Try adjusting your filters. `}
                title="No Data"
                type="info"
                size="small"
                display="vertical"
            />
        </StyledNoDataContainer>
    );

    return (
        <WidgetBase
            title={title}
            href={href}
            menu={<WidgetMenuVenn data={data} menuConfig={menuConfig} handleMenuChange={handleCheckboxChange} />}
        >
            <StyledContainer>
                <StyledDiagramContainer>
                    <StyledTitleContainer height={height}>This Period</StyledTitleContainer>
                    {series && series.data.length > 0 ? (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ series: [series], title: 'Segments', ...vennConfig }}
                            immutable={true}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    ) : (
                        renderNoDataMessage()
                    )}
                </StyledDiagramContainer>
                <StyledDiagramContainer>
                    <StyledTitleContainer height={height}>Comparison Period</StyledTitleContainer>
                    {comparisonSeries && comparisonSeries.data.length > 0 ? (
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ series: [comparisonSeries], title: 'Segments', ...vennConfig }}
                            immutable={true}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    ) : (
                        renderNoDataMessage()
                    )}
                </StyledDiagramContainer>
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetVennComparison;
