// React Dependencies
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, NavLink } from 'react-router-dom';

// Core Dependencies
import Axios from 'axios';
import { isEmail } from '../helpers/validator';

// Redux Actions
import { addNotification } from '../redux/actions/notification';
import { NotificationMessageType } from '../enums/notification-types';

// Import Components
import FormEmail from '../components/form-fields/form-email';
import InputButton from '../components/inputs/input-button';
import IconArrow from '../components/icons/arrow';

// Import Views
import ViewNotifications from './notifications';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import imageLogoWhiteFull from '../assets/images/white-cubed-logo-full.png';

const ViewForgottenPassword = () => {
    const { user, meta } = useSelector(state => state);

    const dispatch = useDispatch();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formValue, setFormValue] = useState('');
    const [serverErrorMessage] = useState(''); // eslint-disable-next-line
    const [emailErrorMessage, setEmailErrorMessage] = useState('');

    const formValidator = () => {
        let hasFormError = false;
        let errorMessageObject = {
            'email__error-message': '',
        };

        if (!isEmail(formValue) || formValue.length === 0) {
            hasFormError = true;
            errorMessageObject['email__error-message'] = 'Please enter a valid email address.';
        }

        if (hasFormError) {
            setEmailErrorMessage(errorMessageObject['email__error-message']);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    const passwordReset = () => {
        const resetConfig = {
            method: 'POST',
            url: `${meta.apiDomain}/api/user/password/request-reset/`,
            data: {
                email: formValue,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        Axios(resetConfig)
            .then(response => {
                dispatch(
                    addNotification({
                        copy: 'An Email was sent to reset your password.',
                        type: NotificationMessageType.Success,
                    })
                );
                setIsLoading(false);
                setIsLoggedIn(false);
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue trying to reset your password. Please try again later',
                        type: NotificationMessageType.Error,
                    })
                );
                setIsLoading(false);
                setIsLoggedIn(false);
            });
    };

    const onResetPasswordClick = () => {
        setIsLoading(true);
        setEmailErrorMessage('');
        if (formValidator() === true) {
            passwordReset();
        } else {
            setIsLoading(false);
        }
    };

    const onInputChange = event => {
        setFormValue(event.target.value);
    };

    if (isLoggedIn || user.isLoggedIn) {
        return <Navigate push to="/account-selection" />;
    }

    return (
        <div id="forgotten-password__view">
            <img src={imageLoginBackground} alt="Background" />
            <div className="forgotten-password__content">
                <img src={imageLogoWhiteFull} alt="Cubed" />
                <div className="forgotten-password__content__form">
                    <NavLink to="/">
                        <div className="icon icon-arrow">
                            <IconArrow />
                        </div>
                    </NavLink>
                    <h3>Forgotten Password</h3>
                    <FormEmail inputValue={formValue} inputOnChange={onInputChange} errorMessage={emailErrorMessage} />
                    <div className="forgotten-password__content__form__error">
                        <span>{serverErrorMessage}</span>
                    </div>
                    <div className="forgotten-password__content__form__buttons">
                        <InputButton isLoading={isLoading} value="Reset Password" onClick={onResetPasswordClick} />
                    </div>
                </div>
            </div>
            <ViewNotifications />
        </div>
    );
};

export default ViewForgottenPassword;
