import React from 'react';
import styled, { useTheme } from 'styled-components';
import { pieChartConfig } from '../../configurations/widget-pie-chart-config';

// Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Components
import WidgetBase from '../base/widget-base';
import WidgetPieChartBigNumber from '../big-numbers/widget-pie-chart-big-number';

// Types
import { PieDataComparison } from '../../types';
import Message from '../../../components/message';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const StyledChartContainer = styled.div`
    width: 50%;
    height: 100%;
`;

const StyledMessageContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export type WidgetPieChartComparisonProps = {
    title: string;
    data: PieDataComparison;
    href?: string;
    display: 'pie' | 'donut' | 'semidonut';
    menu?: React.ReactNode;
};

const WidgetPieChartComparison = ({ title, data, href, display, menu }: WidgetPieChartComparisonProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    if (width === 1 || height === 1) {
        const series = data.series;

        const numberOfBoxes = Math.max(width, height);
        const firstSeries = series[0];
        const dataToDisplay = firstSeries.data.slice(0, numberOfBoxes);
        const firstSeriesTotal = firstSeries.total;
        const direction = width > height ? 'horizontal' : 'vertical';

        return (
            <WidgetBase title={title} href={href} menu={menu}>
                {data?.request?.chartMetrics ? (
                    <WidgetPieChartBigNumber
                        data={dataToDisplay}
                        direction={direction}
                        total={firstSeriesTotal}
                        chartLabel={data?.request?.chartField?.displayName}
                    />
                ) : (
                    <WidgetPieChartBigNumber data={dataToDisplay} direction={direction} total={firstSeriesTotal} />
                )}
            </WidgetBase>
        );
    }

    const chartOptions = pieChartConfig(theme);

    const renderMessage = () => {
        return (
            <StyledMessageContainer>
                <Message
                    title="No Data"
                    shortTitle="No Data"
                    copy="Try adjusting your filters."
                    type="info"
                    size={width > 3 && height > 2 ? 'small' : 'xsmall'}
                    display="vertical"
                />
            </StyledMessageContainer>
        );
    };

    const chartConfig = {
        ...chartOptions,
        plotOptions: {
            ...chartOptions.plotOptions,
            pie: {
                ...chartOptions.plotOptions.pie,
                startAngle: display === 'semidonut' ? -90 : 0,
                endAngle: display === 'semidonut' ? 90 : 360,
                center:
                    display === 'semidonut'
                        ? width > 3
                            ? ['50%', '70%']
                            : height > 2
                            ? ['50%', '60%']
                            : ['50%', '65%']
                        : ['50%', '50%'],
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b> - {point.value}',
        },
    };

    const series = data.series.map(series => {
        return {
            name: series.name,
            data: series.data.map(data => {
                return {
                    name: data.name,
                    value: data.value,
                    color: data.color,
                    y: (data.value / series.total) * 100,
                };
            }),
            innerSize: display === 'donut' || display === 'semidonut' ? '50%' : 0,
        };
    });

    const comparisonSeries = data.comparisonSeries.map(series => {
        return {
            name: series.name,
            data: series.data.map(data => {
                return {
                    name: data.name,
                    value: data.value,
                    color: data.color,
                    y: (data.value / series.total) * 100,
                };
            }),
            innerSize: display === 'donut' || display === 'semidonut' ? '50%' : 0,
        };
    });

    return (
        <WidgetBase title={title} href={href} menu={menu}>
            <StyledContainer>
                {series.length > 0 ? (
                    <StyledChartContainer>
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...chartConfig, series: series }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    </StyledChartContainer>
                ) : (
                    renderMessage()
                )}
                {comparisonSeries.length > 0 ? (
                    <StyledChartContainer>
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...chartConfig, series: comparisonSeries }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    </StyledChartContainer>
                ) : (
                    renderMessage()
                )}
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetPieChartComparison;
