import React, { useContext, useEffect } from 'react';
import Form from '../../../../components/forms/form';
import { FieldValues, useFormContext } from 'react-hook-form';
import { DashboardLibraryContext } from '../context/dashboard-library-context';

const FormDashboardLibraryDashboardSearch = () => {
    const { currentUserDashboards, accountDashboards, handleFilterDashboards } = useContext(DashboardLibraryContext);
    const { handleSubmit, watch } = useFormContext();

    const onSubmit = (data: FieldValues) => {
        handleFilterDashboards(data.search);
    };

    // Handle form submit on dropdown change
    useEffect(() => {
        const subscription = watch(() => handleSubmit(onSubmit)());
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]); // eslint-disable-line react-hooks/exhaustive-deps

    const searchDisabled = !currentUserDashboards || !accountDashboards;

    return (
        <Form.Body>
            <Form.Section>
                <Form.Field direction="row">
                    <Form.InputLabel htmlFor="search" label="Search" />
                    <Form.InputSearch name="search" disabled={searchDisabled} />
                </Form.Field>
            </Form.Section>
        </Form.Body>
    );
};

export default FormDashboardLibraryDashboardSearch;
