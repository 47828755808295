import React, { Component } from 'react';

//
// 	Class ListRowCopy
//		props.keyValue :    String
//      props.copy :        String
// 		props.onClick :     function
//

class ListRowCopy extends Component {
    render() {
        return (
            <div data-key={this.props.keyValue} className="list-item__copy" onClick={this.props.onClick}>
                <p>{this.props.copy}</p>
            </div>
        );
    }
}

export default ListRowCopy;
