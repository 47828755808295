import validateColumnValues from './helpers/validate-column-values';
import validateContainsData from './helpers/validate-contains-data';
import validateHeaders from './helpers/validate-headers';
import { FileFormatConfig, FileType } from './types';

const validateFileUpload = {
    required: {
        required: 'This field is required',
    },
    validateFileType: (expectedFileType: FileType) => {
        return {
            validateFileType: (value: FileList) => {
                const fileType = value[0].type as FileType;
                const acceptedFileTypes = expectedFileType.split(', ');
                const message = expectedFileType === FileType.CSV ? 'The file type must be CSV' : 'Incorrect file type';

                return acceptedFileTypes.includes(fileType) || message;
            },
        };
    },
    validateContainsData: () => {
        return {
            validateContainsData: (value: FileList) => {
                const file = value[0];

                return validateContainsData(file).then(result => {
                    return result || 'The file does not contain any data';
                });
            },
        };
    },
    validateHeaders: (columnConfig: FileFormatConfig) => {
        return {
            validateHeaders: async (value: FileList) => {
                const file = value[0];

                return validateHeaders(file, columnConfig).then(result => {
                    return (
                        result ||
                        'The headers in the file do not match the expected headers. Please check the file and try again.'
                    );
                });
            },
        };
    },
    validateColumnValues: (columnConfig: FileFormatConfig) => {
        return {
            validateColumnValues: async (value: FileList) => {
                const file = value[0];

                return validateColumnValues(file, columnConfig).then(messages => {
                    return messages.length === 0 || messages.join(', ');
                });
            },
        };
    },
};

export default validateFileUpload;
