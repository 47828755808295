// React
import React from 'react';
import InputButton from '../components/inputs/input-button';
import { NavLink, Navigate, useLocation } from 'react-router-dom';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import imageLogoWhiteFull from '../assets/images/white-cubed-logo-full.png';
import styled from 'styled-components';

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const ViewUserInvite = () => {
    const location = useLocation();
    // Check that the query param has the correct key
    const params = location.search.substring(1).split('=');
    const queryParamKey = params.filter(param => /client/.test(param));
    const clientName = decodeURIComponent(params[1]);

    if (queryParamKey.length !== 1) {
        // Navigate to login query param isnt there or is incorrect.
        return <Navigate to="/" />;
    }

    return (
        <div className="invite-users">
            <img className="invite-users__background" src={imageLoginBackground} alt="Background" />
            <div className="invite-users__wrapper">
                <img src={imageLogoWhiteFull} alt="Yard" />
                <div className="invite-users__content">
                    <h3>Invited to {clientName} successfully </h3>
                    <p>You have accepted the invitation sent by a member of the {clientName} team.</p>
                    <StyledButtonContainer>
                        <NavLink to="/">
                            <InputButton value="Continue" />
                        </NavLink>
                    </StyledButtonContainer>
                </div>
            </div>
        </div>
    );
};

export default ViewUserInvite;
