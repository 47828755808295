import React, { useContext, useState } from 'react';
import styled from 'styled-components';

// Context
import { DashboardBuilderContext } from '../../context/dashboard-builder-context';
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

// Types
import { RootState } from '../../../redux/store';

// Hooks
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

// Forms
import FormViewport from '../forms/form-viewport';
import FormShowComparison from '../forms/form-show-comparison';

// Components
import Form from '../../../components/forms/form';
import Dialog from '../../components/dialog/dialog';
import PreviewDatepicker from '../../components/preview-datepicker';

// Icons
import IconArrow from '../../../components/icons/arrow';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background-color: ${props => props.theme.dashboardBuilder.container.backgroundColor};
    ${props => props.theme.boxShadow(1)}
    padding: 8px 16px;
    border-radius: 6px;
`;

const StyledRightContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    gap: 16px;
`;

const StyledInnerContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 16px;
`;

const StyledBackContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const StyledBackButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0;
    color: ${props => props.theme.buttons.secondary.color};
    white-space: nowrap;
`;

const StyledIconArrow = styled(IconArrow)`
    width: 12px;
`;

const StyledBackText = styled.span`
    @media (max-width: 1200px) {
        display: none;
    }
`;

const LayoutCreateADashboardTopBar = () => {
    const navigate = useNavigate();
    const accountToken = useSelector((state: RootState) => state.account.token);

    // Form default values must be saved in state to prevent unnecessary re-rendering of the form
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [formDefaultValues, _] = useState({ showComparison: false });

    // Context
    const { userDashboards } = useContext(DashboardBuilderContext);
    const {
        dashboardId,
        dashboardDetails,
        sections,
        breakpoint,
        showComparison,
        handleComparisonFormSubmit,
        handleSaveDashboard,
        saveDashboardMutation,
        updateDashboardMutation,
    } = useContext(CreateADashboardContext);

    return (
        <StyledContainer>
            <StyledBackContainer>
                <StyledBackButton onClick={() => navigate(`/${accountToken}/dashboard-builder/dashboard-library`)}>
                    <StyledIconArrow />
                    <StyledBackText>Dashboard Library</StyledBackText>
                </StyledBackButton>
            </StyledBackContainer>
            <StyledRightContainer>
                <StyledInnerContainer>
                    {dashboardDetails.enableComparison && (
                        <Form defaultValues={formDefaultValues} onSubmit={() => {}}>
                            <FormShowComparison onSubmit={handleComparisonFormSubmit} />
                        </Form>
                    )}
                    <PreviewDatepicker showComparison={showComparison} />
                </StyledInnerContainer>
                <StyledInnerContainer>
                    {breakpoint && (
                        <Form onSubmit={() => {}} defaultValues={{ breakpoint: breakpoint }}>
                            <FormViewport />
                        </Form>
                    )}
                    <Dialog>
                        <Dialog.Trigger loading={saveDashboardMutation.isPending || updateDashboardMutation.isPending}>
                            {saveDashboardMutation.isPending || updateDashboardMutation.isPending ? 'Saving' : 'Save'}
                        </Dialog.Trigger>
                        <Dialog.Content>
                            {!(
                                sections.length > 0 &&
                                sections.every(section =>
                                    Object.values(section.layouts).every(layout => layout.length > 0)
                                )
                            ) ? (
                                <>
                                    <Dialog.Title iconType="warning">Error</Dialog.Title>
                                    <Dialog.Description>
                                        You have one or more empty sections in your dashboard. Please ensure there is at
                                        least one widget in each section, or remove any empty sections.
                                    </Dialog.Description>
                                    <Dialog.ButtonGroup>
                                        <Dialog.Cancel>Cancel</Dialog.Cancel>
                                        <Dialog.Action>OK</Dialog.Action>
                                    </Dialog.ButtonGroup>
                                </>
                            ) : !dashboardId &&
                              userDashboards &&
                              userDashboards.map(dashboard => dashboard.title).includes(dashboardDetails.title) ? (
                                <>
                                    <Dialog.Title iconType="warning">Error</Dialog.Title>
                                    <Dialog.Description>
                                        You already have a dashboard with the name '{dashboardDetails.title}'. Please
                                        choose a different name.
                                    </Dialog.Description>
                                    <Dialog.ButtonGroup>
                                        <Dialog.Cancel>Cancel</Dialog.Cancel>
                                        <Dialog.Action>OK</Dialog.Action>
                                    </Dialog.ButtonGroup>
                                </>
                            ) : (
                                <>
                                    <Dialog.Title iconType="save">Save</Dialog.Title>
                                    <Dialog.Description>
                                        Are you sure you would like to save this dashboard?
                                    </Dialog.Description>
                                    <Dialog.ButtonGroup>
                                        <Dialog.Cancel>Cancel</Dialog.Cancel>
                                        <Dialog.Action onClick={handleSaveDashboard}>Save</Dialog.Action>
                                    </Dialog.ButtonGroup>
                                </>
                            )}
                        </Dialog.Content>
                    </Dialog>
                </StyledInnerContainer>
            </StyledRightContainer>
        </StyledContainer>
    );
};

export default LayoutCreateADashboardTopBar;
