import { v4 as uuidv4 } from 'uuid';
import { unknownErrorData } from '../../helpers/errors';
import {
    DataType,
    NestedDonutCategory,
    NestedDonutChartComparisonData,
    NestedDonutChartSeries,
    NestedDonutColumn,
    TableRowValue,
} from '../../types';

type useResourceNestedDonutChartTableComparisonArgs = {
    categories: NestedDonutCategory[];
    columns: NestedDonutColumn;
};

const useResourceNestedDonutChartTableComparison = ({
    categories,
    columns,
}: useResourceNestedDonutChartTableComparisonArgs): NestedDonutChartComparisonData => {
    const loading = categories.map(category => category.resourceData.status).some(status => status === 'loading');
    const error = categories.map(category => category.resourceData.status).some(status => status === 'error');
    const success = categories.map(category => category.resourceData.status).every(status => status === 'success');
    const empty = categories.map(category => category.resourceData.status).every(status => status === 'empty');

    const errorObject = (message?: string) => ({
        type: 'nestedDonutChart' as DataType,
        status: 'error' as 'error',
        error: { message: message || 'Error loading data' },
    });

    if (loading) {
        return {
            type: 'nestedDonutChart',
            status: 'loading',
        };
    }

    if (error) return errorObject();

    if (empty) {
        return {
            type: 'nestedDonutChart',
            status: 'empty',
        };
    }

    if (success) {
        try {
            // Build the chart series
            const series: NestedDonutChartSeries[] = [];
            const comparisonSeries: NestedDonutChartSeries[] = [];

            columns.columnConfig.forEach(column => {
                // Set up 'this period' series
                const columnSeries = {
                    name: column.displayName,
                    data: [] as number[],
                };
                let value = 0;
                let thisSeriesEmpty = false;

                // Set up 'comparison period' series
                const columnSeriesComparison = {
                    name: column.displayName,
                    data: [] as number[],
                };
                let comparisonValue = 0;
                let comparisonSeriesEmpty = false;

                categories.forEach(category => {
                    if (category.showInGraph) {
                        if ('resources' in category.resourceData) {
                            // Build 'this period' series
                            let thisPeriod = category.resourceData.resources[0];
                            if (thisPeriod.status === 'success') {
                                if ('objects' in thisPeriod) {
                                    thisPeriod.objects.forEach(object => {
                                        columns.columnFields.forEach(field => {
                                            if (
                                                field.rawName in object.values &&
                                                column.flags.includes(object.values[field.rawName].value)
                                            ) {
                                                value = object.values[category.field.rawName].rawValue as number;
                                            }
                                        });
                                    });
                                }
                                columnSeries.data.push(value);
                            } else thisSeriesEmpty = true;

                            // Build 'comparison period' series
                            let comparisonPeriod = category.resourceData.resources[1];
                            if (comparisonPeriod.status === 'success') {
                                if ('objects' in comparisonPeriod) {
                                    comparisonPeriod.objects.forEach(object => {
                                        columns.columnFields.forEach(field => {
                                            if (
                                                field.rawName in object.values &&
                                                column.flags.includes(object.values[field.rawName].value)
                                            ) {
                                                comparisonValue = object.values[category.field.rawName]
                                                    .rawValue as number;
                                            }
                                        });
                                    });
                                }
                                columnSeriesComparison.data.push(comparisonValue);
                            } else comparisonSeriesEmpty = true;
                        }
                    }
                });

                if (!thisSeriesEmpty) {
                    series.push(columnSeries);
                }
                if (!comparisonSeriesEmpty) {
                    comparisonSeries.push(columnSeriesComparison);
                }
            });

            // Build the table rows
            // Se up the 'this period' row
            let row;
            let thisPeriodEmpty = false;

            // Set up the 'comparison period' row
            let comparisonRow;
            let comparisonPeriodEmpty = false;

            // Build the 'this period' row
            const rowValues = columns.columnConfig.map(column => {
                const columnValues = categories.map(category => {
                    let thisPeriodValue = 0;

                    if ('resources' in category.resourceData) {
                        let thisPeriod = category.resourceData.resources[0];
                        if (thisPeriod.status === 'success') {
                            thisPeriod.objects.forEach(object => {
                                columns.columnFields.forEach(field => {
                                    if (
                                        field.rawName in object.values &&
                                        column.flags.includes(object.values[field.rawName].value)
                                    ) {
                                        thisPeriodValue += object.values[category.field.rawName].rawValue as number;
                                    }
                                });
                            });
                        } else thisPeriodEmpty = true;
                    }

                    return {
                        [category.field.rawName]: {
                            rawValue: thisPeriodValue,
                            value: category.showInGraph ? String(thisPeriodValue) : `${String(thisPeriodValue)}%`,
                        },
                    };
                });

                return {
                    [column.rawName]: columnValues.reduce((acc, val) => Object.assign(acc, val), {}),
                };
            });

            if (!thisPeriodEmpty) {
                row = {
                    __id: uuidv4(),
                    values: rowValues.reduce((acc, val) => Object.assign(acc, val), {}),
                };
            }

            // Build the 'comparison period' row
            const comparisonRowValues = columns.columnConfig.map(column => {
                const columnValues = categories.map(category => {
                    let comparisonPeriodValue = 0;

                    if ('resources' in category.resourceData) {
                        let comparisonPeriod = category.resourceData.resources[1];
                        if (comparisonPeriod.status === 'success') {
                            comparisonPeriod.objects.forEach(object => {
                                columns.columnFields.forEach(field => {
                                    if (
                                        field.rawName in object.values &&
                                        column.flags.includes(object.values[field.rawName].value)
                                    ) {
                                        comparisonPeriodValue += object.values[category.field.rawName]
                                            .rawValue as number;
                                    }
                                });
                            });
                        } else comparisonPeriodEmpty = true;
                    }

                    return {
                        [category.field.rawName]: {
                            rawValue: comparisonPeriodValue,
                            value: category.showInGraph
                                ? String(comparisonPeriodValue)
                                : `${String(comparisonPeriodValue)}%`,
                        },
                    };
                });

                return {
                    [column.rawName]: columnValues.reduce((acc, val) => Object.assign(acc, val), {}),
                };
            });

            if (!comparisonPeriodEmpty) {
                comparisonRow = {
                    __id: uuidv4(),
                    values: comparisonRowValues.reduce((acc, val) => Object.assign(acc, val), {}),
                };
            }

            // Build the variance row
            let varianceRow;
            if (row && comparisonRow) {
                const vRow = {
                    __id: uuidv4(),
                    values: {} as { [key: string]: Record<string, TableRowValue> },
                };

                for (const [key, value] of Object.entries(row.values)) {
                    const comparisonValue = comparisonRow.values[key];

                    const values = Object.entries(value).map(([subKey, subValue]) => {
                        const variance = Math.round(
                            ((subValue.rawValue - comparisonValue[subKey].rawValue) / subValue.rawValue) * 100
                        );

                        return {
                            [subKey]: {
                                rawValue: variance || 0,
                                value: variance ? String(variance) : '0',
                            },
                        };
                    });

                    vRow.values[key] = values.reduce((acc, val) => Object.assign(acc, val), {});
                }

                varianceRow = vRow;
            }

            return {
                type: 'nestedDonutChartComparison',
                status: 'success',
                categories: categories.map(category => category.title),
                ...(series.length > 0 && { series }),
                ...(comparisonSeries.length > 0 && { comparisonSeries }),
                columns: columns.columnConfig.map(column => {
                    return {
                        __id: uuidv4(),
                        rawName: column.rawName,
                        displayName: column.displayName,
                    };
                }),
                subColumns: categories.map(column => {
                    return {
                        __id: uuidv4(),
                        rawName: column.field.rawName,
                        displayName: column.showInGraph ? column.field.displayName : column.title,
                    };
                }),
                ...(row && { row }),
                ...(comparisonRow && { comparisonRow }),
                ...(varianceRow && { varianceRow }),
            };
        } catch (error) {
            let message;
            if (error instanceof Error) {
                message = error.message;
            } else {
                message = String(error);
            }
            return errorObject(message);
        }
    }

    return unknownErrorData();
};

export default useResourceNestedDonutChartTableComparison;
