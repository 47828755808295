import React from 'react';
import styled from 'styled-components';

const StyledFormSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

type FormSectionProps = {
    children: React.ReactNode;
};

const FormSection = ({ children }: FormSectionProps) => {
    return <StyledFormSection>{children}</StyledFormSection>;
};

export default FormSection;
