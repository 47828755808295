import React from 'react';
import styled from 'styled-components';

import IconTimesCircle from '../assets/images/icons/times-circle.svg';
import IconCheckCircle from '../assets/images/icons/check-circle.svg';

const StyledP = styled.p`
    margin-bottom: 5px;
`;

const StyledList = styled.ul`
    margin-top: 0;
    padding-left: 1em;
    list-style: none;
`;

const StyledListItem = styled.li<{ passedCheck: boolean }>`
    color: ${props => (props.passedCheck ? props.theme.colours.green : props.theme.colours.red)};
    &:before {
        content: '';
        display: inline-block;
        height: 0.9em;
        width: 0.9em;
        margin-right: 0.5em;
        background-image: ${props => (props.passedCheck ? `url(${IconCheckCircle})` : `url(${IconTimesCircle})`)};
        background-size: contain;
        background-repeat: no-repeat;
    }
`;

type PasswordRequirementsProps = {
    hasCharacterLength: boolean;
    hasCapitalLetter: boolean;
    hasLowercaseLetter: boolean;
    hasNumber: boolean;
    hasSpecialCharacter: boolean;
    hasValidCharacter: boolean;
};

const PasswordRequirements = ({
    hasCharacterLength,
    hasCapitalLetter,
    hasLowercaseLetter,
    hasNumber,
    hasSpecialCharacter,
    hasValidCharacter,
}: PasswordRequirementsProps) => {
    return (
        <div>
            <StyledP>Your password must contain the following:</StyledP>
            <StyledList>
                <StyledListItem passedCheck={hasCharacterLength}>Contain 8 or more characters</StyledListItem>
                <StyledListItem passedCheck={hasCapitalLetter}>
                    Contain at least 1 or more capital letter
                </StyledListItem>
                <StyledListItem passedCheck={hasLowercaseLetter}>
                    Contain at least 1 or more lowercase letter
                </StyledListItem>
                <StyledListItem passedCheck={hasNumber}>Contain at least 1 or more number</StyledListItem>
                <StyledListItem passedCheck={hasSpecialCharacter}>
                    Contain at least 1 or more special character from the following @ $ ! % * # ? &
                </StyledListItem>
                <StyledListItem passedCheck={hasValidCharacter}>
                    Contain at least one uppercase letter, one lowercase letter, one number, and one of the following
                    special characters: @ $ ! % * # ^ ? &
                </StyledListItem>
            </StyledList>
        </div>
    );
};

export default PasswordRequirements;
