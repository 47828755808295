import { User, UserAction } from '../types';

export default function reducer(state = initialState, action: UserAction) {
    switch (action.type) {
        case 'SET_USER':
            return Object.assign({}, state, {
                id: action.payload.id,
                username: action.payload.username,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                accounts: action.payload.accounts,
                superUser: action.payload.superUser,
                staff: action.payload.staff,
                isLoggedIn: true,
                strongPassword: action.payload.strongPassword,
                usernameIsEmail: action.payload.usernameIsEmail,
            });
        case 'UPDATE_ACCOUNTS':
            return Object.assign({}, state, {
                id: state.id,
                username: state.username,
                firstName: state.firstName,
                lastName: state.lastName,
                email: state.email,
                accounts: action.payload.accounts,
                superUser: state.superUser,
                staff: state.staff,
                isLoggedIn: state.isLoggedIn,
                strongPassword: state.strongPassword,
                usernameIsEmail: state.usernameIsEmail,
            });
        case 'DEL_USER':
            return Object.assign({}, state, {
                id: '',
                username: '',
                firstName: '',
                lastName: '',
                email: '',
                accounts: [],
                superUser: false,
                staff: false,
                isLoggedIn: false,
                strongPassword: false,
                usernameIsEmail: false,
            });
        default:
            return state;
    }
}

const initialState: User = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    accounts: [],
    superUser: false,
    staff: false,
    isLoggedIn: false,
    strongPassword: false,
    usernameIsEmail: false,
};
