import React, { Component } from 'react';
import styled from 'styled-components';

import DraggableList from './../components/draggable-list';
import Button from './../components/button';
import AddItem from './../components/add-item';
import SimpleToggle from '../components/simple-toggle';

import './../sass/widgets/event-sequencer.scss';

// Styles
const StyledEventSequencer = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledEventSequencerAddItems = styled.ul`
    margin-top: -5px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-around;
    -webkit-box-align: center;
    align-items: center;
    gap: 0px 10px;

    & li {
        flex: 0 0 auto;
        margin-left: 0 !important;
    }
`;

const StyledEventSequencerListHolder = styled.div`
    flex: 1 1 auto;
    overflow-x: auto;
    ${props => props.theme.scrollbar(6, 6)}
    height: 100%;
`;

const StyledEventSequencerButtons = styled.div`
    width: 100%;
    flex: 0 1 40px;
    text-align: right;

    & button {
        margin-right: 0px;
        margin-top: 5px;
    }
`;

class WidgetEventSequencer extends Component {
    constructor(props) {
        super(props);

        this.formatEvents = this.formatEvents.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.changeItems = this.changeItems.bind(this);
    }
    addItem(item) {
        const newPath = [...this.props.path];
        newPath.push(item.id);
        this.props.onChange(newPath);
    }
    changeItems(itemList) {
        const newList = itemList.map(item => item.itemId);
        this.props.onChange(newList);
    }
    removeItem(index) {
        const newPath = [...this.props.path];
        newPath.splice(index, 1);
        this.props.onChange(newPath);
    }
    formatEvents() {
        return this.props.path.map((event, index) => {
            return { id: index.toString(), itemId: event, content: this.props.allEvents[event].display_name };
        });
    }
    render() {
        const items = this.formatEvents();
        return (
            <StyledEventSequencer>
                <StyledEventSequencerAddItems>
                    <AddItem
                        itemList={Object.values(this.props.allEvents)}
                        handleAdd={this.addItem}
                        buttonText={'Add Event'}
                        style={{ width: '25%', float: 'right', minWidth: '160px' }}
                        dropdownStyle={{ minWidth: '400px', left: '-20%' }}
                        disabled={this.props.path.length > 9}
                        classNameAddItem="add-items__item"
                        noItemMessage="No Events"
                    />
                    <SimpleToggle checked={this.props.exact} labelText={'Exact'} onClick={this.props.toggleExact} />
                </StyledEventSequencerAddItems>

                <StyledEventSequencerListHolder>
                    {this.props.path.length < 1 ? (
                        this.props.noneSelectedJsx()
                    ) : (
                        <DraggableList
                            handleChange={this.changeItems}
                            isLoading={this.props.isLoading}
                            items={items}
                            removeItem={this.removeItem}
                        />
                    )}
                </StyledEventSequencerListHolder>

                <StyledEventSequencerButtons>
                    <Button
                        onClick={this.props.applyChanges}
                        disabled={
                            (this.props.dateChanged || this.props.pathChanged) && this.props.path.length > 0
                                ? false
                                : true
                        }
                    >
                        {this.props.applyText}
                    </Button>
                </StyledEventSequencerButtons>

                <div className={`event-sequencer__loading ${this.props.isLoading ? 'show' : ''}`}>
                    <div className="event-sequencer__loading__message">
                        <span>{this.props.loadingText}</span>
                        <div className="event-sequencer__loading__bar"></div>
                    </div>

                    <div className="event-sequencer__loading__cover"></div>
                </div>
            </StyledEventSequencer>
        );
    }
}

export default WidgetEventSequencer;
