import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../../styled-components/theme.styled';

import { addNotification } from '../../../../redux/actions/notification';

// Context
import { DashboardLibraryContext } from '../context/dashboard-library-context';

// Hooks
import useDeleteResource from '../../../../react-query/hooks/use-delete-resource';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Components
import LibraryList from '../../components/library-list/library-list';
import Dialog from '../../../components/dialog/dialog';

// Types
import { TriggerType } from '../../../components/dialog/dialog-trigger';
import { DashboardConfig } from '../../../types';
import { RootState } from '../../../../redux/store';
import { NotificationMessageType } from '../../../../enums/notification-types';

// Resources
import { DASHBOARD_BUILDER_DASHBOARD } from '../../../config/resources-dashboard-builder';

// Icons
import IconLock from '../../../../components/icons/lock';
import IconUnlock from '../../../../components/icons/unlock';
import IconEye from '../../../../section-dashboard/widgets/menus/icons/eye';
import IconEyeSlash from '../../../../section-dashboard/widgets/menus/icons/eye-slash';

const StyledDashboardName = styled.span`
    font-weight: 600;
    color: ${props => props.theme.dashboardBuilder.library.libraryList.listItemTitleColor};
    cursor: pointer;
`;

const StyledNoDescription = styled.span`
    font-style: italic;
`;

const StyledButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
`;

const StyledEditButton = styled.button`
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: ${props => props.theme.colours.offBlack};
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 3px;
    color: ${props => props.theme.colours.white};
    text-align: center;
    text-transform: uppercase;
    line-height: 32px;
    padding: 0 12px;
`;

const StyledPrivateContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const StyledIcon = css`
    width: 16px;
`;

const StyledIconLock = styled(IconLock)`
    ${StyledIcon}
`;

const StyledIconUnlock = styled(IconUnlock)`
    ${StyledIcon}
`;

const StyledEnabledContainer = styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
`;

const StyledIconEye = styled(IconEye)<{ id: string }>`
    ${StyledIcon}
    fill: url(${props => props.id});
`;

const StyledIconEyeSlash = styled(IconEyeSlash)<{ id: string }>`
    ${StyledIcon}
    fill: url(${props => props.id});
`;

const DashboardLibraryListItem = ({
    dashboard,
    isCurrentUser,
}: {
    dashboard: DashboardConfig;
    isCurrentUser?: boolean;
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accountToken = useSelector((state: RootState) => state.account.token);

    const { handleRemoveAsHomepage, handleSetAsHomepage } = useContext(DashboardLibraryContext);

    const [dashboardIdToDelete, setDashboardIdToDelete] = useState<number>(0);

    useEffect(() => {
        if (dashboardIdToDelete !== 0) {
            dashboardPatchMutation.mutate();
        }
    }, [dashboardIdToDelete]); // eslint-disable-line react-hooks/exhaustive-deps

    const dashboardPatchMutation = useDeleteResource({
        resource: DASHBOARD_BUILDER_DASHBOARD,
        resourceIds: [dashboardIdToDelete.toString()],
        handleOnSuccess: () => {
            dispatch(
                addNotification({ copy: 'Dashboard successfully deleted.', type: NotificationMessageType.Success })
            );
            setDashboardIdToDelete(0);
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting this dashboard.',
                    type: NotificationMessageType.Error,
                })
            );
            setDashboardIdToDelete(0);
        },
    });

    const handleDelete = (dashboardId: number) => {
        setDashboardIdToDelete(dashboardId);
    };

    const deleteButtonLoading = dashboardPatchMutation.isPending && dashboardIdToDelete === dashboard.id;

    return (
        <LibraryList.ListItem>
            <LibraryList.ListItemCell>
                <StyledDashboardName
                    onClick={() => navigate(`/${accountToken}/dashboard-builder/dashboard/${dashboard.id}`)}
                >
                    {dashboard.title}
                </StyledDashboardName>
            </LibraryList.ListItemCell>

            <LibraryList.ListItemCell>
                <LibraryList.CellFlex>
                    <LibraryList.CellTitle>Description</LibraryList.CellTitle>
                    {dashboard.description || <StyledNoDescription>No description</StyledNoDescription>}
                </LibraryList.CellFlex>
            </LibraryList.ListItemCell>

            {!isCurrentUser ? (
                <LibraryList.ListItemCell>
                    <LibraryList.CellFlex>
                        <LibraryList.CellTitle>Author</LibraryList.CellTitle>
                        {dashboard.author.first_name} {dashboard.author.last_name}
                    </LibraryList.CellFlex>
                </LibraryList.ListItemCell>
            ) : null}

            <LibraryList.ListItemCell>
                <LibraryList.CellFlex>
                    <LibraryList.CellTitle>Created</LibraryList.CellTitle>
                    {new Date(dashboard.created).toDateString()}
                </LibraryList.CellFlex>
            </LibraryList.ListItemCell>

            <LibraryList.ListItemCell>
                <LibraryList.CellFlex>
                    <LibraryList.CellTitle>Updated</LibraryList.CellTitle>
                    {new Date(dashboard.updated).toDateString()}
                </LibraryList.CellFlex>
            </LibraryList.ListItemCell>

            {isCurrentUser ? (
                <>
                    <LibraryList.ListItemCell>
                        <LibraryList.CellFlex>
                            <LibraryList.CellTitle>Private</LibraryList.CellTitle>
                            {dashboard.private ? (
                                <StyledPrivateContainer>
                                    <StyledIconLock />
                                    Yes
                                </StyledPrivateContainer>
                            ) : (
                                <StyledPrivateContainer>
                                    <StyledIconUnlock />
                                    No
                                </StyledPrivateContainer>
                            )}
                        </LibraryList.CellFlex>
                    </LibraryList.ListItemCell>

                    <LibraryList.ListItemCell>
                        <LibraryList.CellFlex>
                            <LibraryList.CellTitle>Comparison View</LibraryList.CellTitle>
                            {dashboard.comparison_enabled ? (
                                <StyledEnabledContainer>
                                    <StyledIconEye
                                        id="#linear-gradient-icon-eye-slash-enabled"
                                        colour={theme.sectionDashboard.colour.green}
                                    />
                                    Enabled
                                </StyledEnabledContainer>
                            ) : (
                                <StyledEnabledContainer>
                                    <StyledIconEyeSlash
                                        id="#linear-gradient-icon-eye-slash-disabled"
                                        colour={theme.sectionDashboard.colour.red}
                                    />
                                    Disabled
                                </StyledEnabledContainer>
                            )}
                        </LibraryList.CellFlex>
                    </LibraryList.ListItemCell>
                </>
            ) : null}

            <LibraryList.ListItemCell>
                <LibraryList.CellFlex>
                    <LibraryList.CellTitle>Homepage</LibraryList.CellTitle>
                    <Dialog>
                        <Dialog.Trigger type={TriggerType.WRAPPER}>
                            <LibraryList.Checkbox checked={dashboard.isHomepage || false} onCheckedChange={() => {}} />
                        </Dialog.Trigger>
                        {dashboard.isHomepage && (
                            <Dialog.Content>
                                <Dialog.Title iconType="warning">Remove as Homepage</Dialog.Title>
                                <Dialog.Description>
                                    Are you sure you would like to remove this dashboard as your Cubed homepage?
                                </Dialog.Description>
                                <Dialog.ButtonGroup>
                                    <Dialog.Cancel>Cancel</Dialog.Cancel>
                                    <Dialog.Action onClick={() => handleRemoveAsHomepage()}>Proceed</Dialog.Action>
                                </Dialog.ButtonGroup>
                            </Dialog.Content>
                        )}
                        {!dashboard.isHomepage && (
                            <Dialog.Content>
                                <Dialog.Title iconType="warning">Set as Homepage</Dialog.Title>
                                <Dialog.Description>
                                    Are you sure you would like to set this dashboard to be your Cubed homepage?
                                </Dialog.Description>
                                <Dialog.ButtonGroup>
                                    <Dialog.Cancel>Cancel</Dialog.Cancel>
                                    <Dialog.Action onClick={() => handleSetAsHomepage(dashboard.id)}>
                                        Proceed
                                    </Dialog.Action>
                                </Dialog.ButtonGroup>
                            </Dialog.Content>
                        )}
                    </Dialog>
                </LibraryList.CellFlex>
            </LibraryList.ListItemCell>

            {isCurrentUser ? (
                <LibraryList.ListItemCell customWidth="200px">
                    <StyledButtonContainer>
                        <StyledEditButton
                            onClick={() =>
                                navigate(`/${accountToken}/dashboard-builder/edit-dashboard/${dashboard.id}`)
                            }
                        >
                            Edit
                        </StyledEditButton>
                        <Dialog>
                            <Dialog.Trigger type={TriggerType.BUTTON_WARNING} loading={deleteButtonLoading}>
                                {deleteButtonLoading ? 'Deleting' : 'Delete'}
                            </Dialog.Trigger>
                            <Dialog.Content>
                                <Dialog.Title iconType="warning">Delete</Dialog.Title>
                                <Dialog.Description>
                                    Are you sure you would like to permanently delete this dashboard?
                                </Dialog.Description>
                                <Dialog.ButtonGroup>
                                    <Dialog.Cancel>Cancel</Dialog.Cancel>
                                    <Dialog.Action onClick={() => handleDelete(dashboard.id)}>Delete</Dialog.Action>
                                </Dialog.ButtonGroup>
                            </Dialog.Content>
                        </Dialog>
                    </StyledButtonContainer>
                </LibraryList.ListItemCell>
            ) : (
                <LibraryList.ListItemCell customWidth="200px">&nbsp;</LibraryList.ListItemCell>
            )}
        </LibraryList.ListItem>
    );
};

export default DashboardLibraryListItem;
