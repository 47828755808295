import React, { useContext } from 'react';
import Form from '../../../components/forms/form';
import FormFieldDimension from './form-fields/form-field-dimension';
import { CreateAWidgetFormContext } from './context/create-a-widget-form-context';
import validators from '../../../components/forms/validation/validators';

const CreateAWidgetFormPieChart = () => {
    const { currentAvailableMetrics } = useContext(CreateAWidgetFormContext);

    return (
        <>
            <Form.Section>
                <Form.SectionTitle>Display Settings</Form.SectionTitle>
                <FormFieldDimension />
                <Form.Field>
                    <Form.InputLabel htmlFor="orderBy" label="Metric" />
                    <Form.InputSelect
                        name="orderBy"
                        options={currentAvailableMetrics}
                        placeholder="Please select a metric"
                        validators={[validators.required]}
                    />
                </Form.Field>
            </Form.Section>
            <Form.Section>
                <Form.SectionTitle>Additional Settings</Form.SectionTitle>
                <Form.Field>
                    <Form.InputLabel htmlFor="pieChartDisplay" label="Display" />
                    <Form.InputSelect
                        name="pieChartDisplay"
                        options={[
                            { label: 'Pie', value: 'pie' },
                            { label: 'Donut', value: 'donut' },
                            { label: 'Semi-Donut', value: 'semidonut' },
                        ]}
                    />
                </Form.Field>
            </Form.Section>
        </>
    );
};

export default CreateAWidgetFormPieChart;
