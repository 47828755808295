// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import { generateUrlDetail } from '../../helpers/request-builder';
import Request from '../../helpers/request';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

class LayoutModalBingApi extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageError: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            removeButtonState: true,
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: false,
            manageAccountsAccordionOpen: false,
            manageAccountsAccordionVisible: false,
            editButtonDisabled: true,
            applyButtonDisabled: true,
            removeButtonDisabled: true,
            disableConnectionSelect: false,
            connections: [],
            'selected-connections': [],
            'removed-connections': [],
            'updated-connections': [],
            accounts: [],
            'updated-accounts': [],
            isLoadingAccounts: false,
            isApiOwner: false,
        };

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.onConnectionSelect = this.onConnectionSelect.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onCreateClick = this.onCreateClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onApplyClick = this.onApplyClick.bind(this);
        this.onCreatePopupContinue = this.onCreatePopupContinue.bind(this);
        this.getConnection = this.getConnection.bind(this);
        this.onReimportClick = this.onReimportClick.bind(this);
        this.onEnableToggle = this.onEnableToggle.bind(this);
        this.onDeleteToggle = this.onDeleteToggle.bind(this);
        this.updateConnections = this.updateConnections.bind(this);
        this.getAccounts = this.getAccounts.bind(this);
        this.onReImportFinishOverride = this.onReImportFinishOverride.bind(this);
        this.onDeleteConnection = this.onDeleteConnection.bind(this);

        this.renderAccordion = this.renderAccordion.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.renderTable = this.renderTable.bind(this);
    }

    request = new Request();

    componentDidMount() {
        const config = [
            {
                resourceGroup: 'integrations',
                resourceName: 'lite-bing-connection',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                    {
                        key: 'deleted',
                        value: 0,
                    },
                ],
            },
            {
                resourceGroup: 'integrations',
                resourceName: 'lite-bing-account',
                params: [
                    {
                        key: 'limit',
                        value: 0,
                    },
                ],
            },
        ];

        this.props.getRequest(config);
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                this.getConnection().then(connections => {
                    this.setState({
                        connections: connections,
                        isLoading: false,
                    });
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }

            this.props.delRequest();
        }

        // Enable the save changes and cancel when a user is added into either the revoked or updated arrays
        if (
            this.state.saveChangesButtonDisabled &&
            (this.state['updated-connections'].length > 0 || this.state['removed-connections'].length > 0)
        ) {
            this.setState({
                saveChangesButtonDisabled: false,
                closeButtonState: 'cancel',
            });
        }

        // Enable apply accounts when an account is added to the update array
        if (this.state.applyButtonDisabled === true && this.state['updated-accounts'].length > 0) {
            this.setState({
                applyButtonDisabled: false,
            });
        }
    }

    getConnection() {
        return new Promise(async (resolve, reject) => {
            try {
                const rawConnections = this.props.request.data[0].objects;
                const rawAccounts = this.props.request.data[1].objects;
                const connections = [];

                for (let index = 0; index < rawConnections.length; index++) {
                    let userEmail = '';
                    let user = await this.request.getDetail(
                        'config',
                        'lite-user-email',
                        rawConnections[index].cubed_user_id
                    );

                    if (user.status === 200) {
                        userEmail = user.data.email;
                    } else {
                        userEmail = 'No Account Details';
                    }

                    connections.push({
                        id: rawConnections[index].id,
                        name: rawConnections[index].name,
                        user: userEmail,
                        userId: rawConnections[index].cubed_user_id,
                        accounts: rawAccounts.filter(account => account.connection.id === rawConnections[index].id),
                    });
                }

                resolve(connections);
            } catch (e) {
                reject(e);
            }
        });
    }

    getAccounts() {
        this.request
            .get('integrations', 'lite-bing-account', [
                { key: 'connection__id', value: this.state['selected-connections'][0].id },
                { key: 'limit', value: 0 },
            ])
            .then(res => {
                this.setState({
                    accounts: res.data.objects.map(account => {
                        account.updated = false;
                        account.deleted = false;
                        return account;
                    }),
                    isLoadingAccounts: false,
                });
            });
    }

    updateConnections() {
        return new Promise((resolve, reject) => {
            const requests = [];

            this.state['updated-connections'].forEach(connection => {
                connection.accounts.forEach(account => {
                    if (account.updated !== true) {
                        return;
                    }

                    if (account.deleted === true) {
                        return requests.push(
                            Axios({
                                method: 'DELETE',
                                url: generateUrlDetail('integrations', 'bing-account', account.id),
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                        );
                    }

                    delete account.connection;
                    delete account.updated;
                    delete account.deleted;

                    requests.push(
                        Axios({
                            method: 'PUT',
                            url: generateUrlDetail('integrations', 'bing-account', account.id),
                            data: account,
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                });
            });

            this.state['removed-connections'].forEach(connection => {
                requests.push(
                    Axios({
                        method: 'DELETE',
                        url: generateUrlDetail('integrations', 'bing-connection', connection.id),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });

            Axios.all(requests)
                .then(
                    Axios.spread((...res) => {
                        this.props.addNotification({
                            copy: 'These changes have been successfully saved.',
                            type: NotificationMessageType.Success,
                        });
                        this.props.removeModal();
                    })
                )
                .catch(error => {
                    this.props.addNotification({
                        copy: 'There was an issue trying to save your changes. Please try again later or contact Cubed Support.',
                        type: NotificationMessageType.Error,
                    });

                    this.setState({
                        saveChangesButtonLoading: false,
                        saveChangesButtonDisabled: false,
                        closeButtonDisabled: false,
                    });
                });
        });
    }

    onSaveChangesClick() {
        this.setState({
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: true,
            closeButtonDisabled: true,
        });

        this.updateConnections();
    }

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            this.props.removeModal();

            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to proceed without saving your changes?',
                },
                buttons: [
                    {
                        value: 'DISCARD CHANGES',
                        onClick: this.onPopupDiscardChangesClick,
                    },
                    {
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                        onClick: this.onPopupStayHereClick,
                    },
                ],
            });
        }
    }

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.props.removeModal();
    }

    // SecondaryAction of the close popup
    onPopupStayHereClick() {
        this.props.removePopup();
    }

    onEnableToggle(event) {
        const accountId = parseInt(event.currentTarget.getAttribute('data-name'));

        const updatedAccounts = [...this.state['updated-accounts']];

        // Check if account ID already exists in the updated array
        let accountInUpdated = updatedAccounts.filter(account => {
            return account.id === accountId;
        });

        let accountInConnectionUpdated = [];
        this.state['updated-connections'].forEach(connection => {
            if (connection.id !== this.state['selected-connections'][0].id) {
                return;
            }

            accountInConnectionUpdated = connection.accounts.filter(account => {
                return account.id === accountId;
            });
        });

        const indexOfAccount = updatedAccounts.indexOf(accountInUpdated);

        let selectedAccount = [];
        if (accountInUpdated.length === 1) {
            selectedAccount = accountInUpdated[0];
        } else if (accountInConnectionUpdated.length === 1) {
            selectedAccount = accountInConnectionUpdated[0];
        } else {
            selectedAccount = this.state.accounts.filter(account => {
                return account.id === accountId;
            })[0];
        }

        selectedAccount.active = !selectedAccount.active;
        selectedAccount.deleted = false;
        selectedAccount.updated = true;

        if (accountInUpdated.length === 1) {
            updatedAccounts[indexOfAccount] = selectedAccount;
        } else {
            updatedAccounts.push({ ...selectedAccount });
        }

        this.setState({
            'updated-accounts': updatedAccounts,
        });
    }

    onDeleteToggle(event) {
        const accountId = parseInt(event.currentTarget.getAttribute('data-name'));

        const updatedAccounts = [...this.state['updated-accounts']];

        // Check if account ID already exists in the updated array
        let accountInUpdated = updatedAccounts.filter(account => {
            return account.id === accountId;
        });

        let accountInConnectionUpdated = [];
        this.state['updated-connections'].forEach(connection => {
            if (connection.id !== this.state['selected-connections'][0].id) {
                return;
            }

            accountInConnectionUpdated = connection.accounts.filter(account => {
                return account.id === accountId;
            });
        });

        const indexOfAccount = updatedAccounts.indexOf(accountInUpdated);

        let selectedAccount = [];
        if (accountInUpdated.length === 1) {
            selectedAccount = accountInUpdated[0];
        } else if (accountInConnectionUpdated.length === 1) {
            selectedAccount = accountInConnectionUpdated[0];
        } else {
            selectedAccount = this.state.accounts.filter(account => {
                return account.id === accountId;
            })[0];
        }

        selectedAccount.deleted = !selectedAccount.deleted;
        selectedAccount.active = false;
        selectedAccount.updated = true;

        if (accountInUpdated.length === 1) {
            updatedAccounts[indexOfAccount] = selectedAccount;
        } else {
            updatedAccounts.push({ ...selectedAccount });
        }

        this.setState({
            'updated-accounts': updatedAccounts,
        });
    }

    onCreateClick() {
        this.props.setPopup({
            title: 'Create connection with Bing.',
            iconType: 'informative',
            contentType: 'simple',
            config: {
                copy: 'You will be taken to Bing to authorise access to your data. Once you have authorised Cubed, our access to your data can be managed via the Cubed account configuration pages.',
            },
            buttons: [
                {
                    value: 'SAVE & CONTINUE',
                    onClick: this.onCreatePopupContinue,
                },
                {
                    value: 'STAY HERE',
                    buttonTheme: ButtonThemes.Secondary,
                    onClick: this.onPopupStayHereClick,
                },
            ],
        });
    }

    onCreatePopupContinue() {
        window.location = `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/bing/initialise`;
    }

    onEditClick() {
        this.setState({
            manageAccountsAccordionOpen: true,
            manageAccountsAccordionVisible: true,
            editButtonDisabled: true,
            removeButtonDisabled: true,
            applyButtonDisabled: true,
            disableConnectionSelect: true,
            isLoadingAccounts: true,
            isApiOwner: this.state['selected-connections'][0].userId === this.props.user.id,
        });

        this.getAccounts();
    }

    onCancelClick() {
        this.setState({
            editButtonDisabled: true,
            removeButtonDisabled: true,
            applyButtonDisabled: true,
            manageAccountsAccordionOpen: false,
            manageAccountsAccordionVisible: false,
            disableConnectionSelect: false,
            'selected-connections': [],
            accounts: [],
            'updated-accounts': [],
        });
    }

    onReimportClick() {
        this.props.setPopup({
            title: 'Import Accounts',
            iconType: 'informative',
            contentType: 'importIntegrationAccounts',
            config: {
                technology: 'bing',
                connectionId: this.state['selected-connections'][0].id,
                onImportFinishOverride: this.onReImportFinishOverride,
            },
            buttons: [],
        });
    }

    onReImportFinishOverride() {
        this.setState({
            isLoadingAccounts: true,
            applyButtonDisabled: false,
        });

        this.getAccounts();
    }

    onConnectionSelect(event) {
        if (this.state.disableConnectionSelect === true) {
            return;
        }

        const selectedConnectionId = parseInt(event.currentTarget.getAttribute('data-value'));
        let selectedConnections = [];

        if (
            this.state['selected-connections'].filter(connection => connection.id === selectedConnectionId).length > 0
        ) {
            selectedConnections = this.state['selected-connections'].filter(
                connection => connection.id !== selectedConnectionId
            );
            this.setState({
                'selected-connections': selectedConnections,
                editButtonDisabled: selectedConnections === 0,
                removeButtonDisabled: selectedConnections === 0,
            });
        } else {
            selectedConnections = this.state['selected-connections'].concat(
                this.state.connections.filter(connection => connection.id === selectedConnectionId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRemoved = false;
        let containsNonRemoved = false;
        let containsEdited = false;

        selectedConnections.forEach(connection => {
            if (this.state['removed-connections'].indexOf(connection) >= 0) {
                containsRemoved = true;
            } else {
                containsNonRemoved = true;
            }

            if (this.state['updated-connections'].indexOf(connection) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;

        if (selectedConnections.length > 0) {
            editButtonDisabled = false;
            removeButtonDisabled = false;
        }

        if (selectedConnections.length > 1) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRemoved && !containsNonRemoved && !containsEdited) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRemoved) {
            editButtonDisabled = true;
        }

        if (containsRemoved && containsNonRemoved) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        this.setState({
            'selected-connections': selectedConnections,
            editButtonDisabled: editButtonDisabled,
            removeButtonDisabled: removeButtonDisabled,
            removeButtonState: removeButtonState,
            applyButtonDisabled: true,
            manageAccountsAccordionOpen: false,
        });
    }

    onDeleteConnection() {
        if (this.state.disableConnectionSelect === true) {
            return;
        }

        let removedConnections = [].concat(this.state['removed-connections']);
        let updatedConnections = [].concat(this.state['updated-connections']);

        this.state['selected-connections'].forEach(connection => {
            if (this.state.removeButtonState === true) {
                removedConnections.push(connection);
            } else {
                removedConnections.splice(removedConnections.indexOf(connection), 1);
            }

            // If a connection was edited and then revoked in the same instance then the revoke should take priority
            const updatedConnectionIndex = updatedConnections.indexOf(connection);

            if (updatedConnectionIndex >= 0) {
                updatedConnections.splice(updatedConnectionIndex, 1);
            }
        });

        this.setState({
            'removed-connections': removedConnections,
            'updated-connections': updatedConnections,
            'selected-connections': [],
            removeButtonState: true,
        });
    }

    onApplyClick() {
        const updatedConnections = [...this.state['updated-connections']];
        const selectedConnection = { ...this.state['selected-connections'][0] };
        const connectionInUpdated = updatedConnections.filter(
            filteredConnection => filteredConnection.id === selectedConnection.id
        );
        // If connection has been updated then use updated connection instead of selected connection
        const connection = connectionInUpdated.length === 1 ? { ...connectionInUpdated[0] } : selectedConnection;

        this.state['updated-accounts'].forEach(account => {
            const accountInConnection = connection.accounts.filter(
                filteredAccount => filteredAccount.id === account.id
            );
            let accountInSelectedIndex =
                accountInConnection.length === 1 ? connection.accounts.indexOf(accountInConnection[0]) : null;

            if (accountInSelectedIndex !== null || accountInSelectedIndex !== -1) {
                connection.accounts[accountInSelectedIndex] = account;
            } else {
                connection.accounts.push(account);
            }
        });

        if (connectionInUpdated.length === 1) {
            const indexOfConnection = updatedConnections.indexOf(connectionInUpdated[0]);
            updatedConnections[indexOfConnection] = connection;
        } else {
            updatedConnections.push(connection);
        }

        this.setState({
            'updated-connections': updatedConnections,
        });

        // Close Accordion
        this.onCancelClick();
    }

    renderModalNavigation() {
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    }

    renderAccordion() {
        if (this.state.manageAccountsAccordionVisible === false) {
            return null;
        }
        let introText;

        if (this.state.isApiOwner) {
            introText =
                '<p>You have full control over the accounts which will be used by the Cubed Platform. If the required account does not appear in the list below, you can reimport it by clicking "REIMPORT".</p> <p>Access to individual accounts can also be removed below.</p>';
        } else {
            introText =
                '<p>You have full control over the accounts which will be used by the Cubed Platform. If the required account does not appear in the list below, you can login to Cubed with same account using which you created the connection to the Bing and you will be able to re-import the accounts.</p> <p>Access to individual accounts can be removed below.</p>';
        }
        if (this.state['selected-connections'].length === 1) {
            introText += `<p>Currently Editing:</p><ul> ${this.state['selected-connections']
                .map(connection => '<li>' + connection.name + '</li>')
                .join('')} </ul>`;
        }

        // Preparing filters for the simple table
        const filters = [
            {
                name: 'Active',
                filter: row => {
                    return row.columns[1].toggleChecked === true;
                },
            },
        ];
        const accounts = [];

        // Get the correct / most updated account details
        if (this.state.isLoadingAccounts === false && this.state['selected-connections'].length === 1) {
            const updatedConnection = this.state['updated-connections'].filter(
                filteredConnection => filteredConnection.id === this.state['selected-connections'][0].id
            );

            this.state.accounts.forEach(account => {
                const inUpdatedAccounts = this.state['updated-accounts'].filter(
                    filteredAccount => filteredAccount.id === account.id
                );
                const inUpdatedConnection =
                    updatedConnection.length === 1
                        ? updatedConnection[0].accounts.filter(filteredAccount => filteredAccount.id === account.id)
                        : [];

                if (inUpdatedAccounts.length === 1) {
                    account = inUpdatedAccounts[0];
                } else if (inUpdatedConnection.length === 1) {
                    account = inUpdatedConnection[0];
                }

                accounts.push({
                    columns: [
                        {
                            copy: account['account_name'],
                        },
                        {
                            type: 'toggle',
                            toggleChecked: account.active,
                            toggleOnClick: this.onEnableToggle,
                            toggleDataName: account.id,
                        },
                        {
                            type: 'toggle',
                            toggleChecked: account.deleted,
                            toggleOnClick: this.onDeleteToggle,
                            toggleDataName: account.id,
                        },
                    ],
                });
            });
        }

        const accordionButtons = [];
        if (this.state.isApiOwner) {
            accordionButtons.push({
                value: 'RE-IMPORT',
                onClick: this.onReimportClick,
            });
        }
        accordionButtons.push({
            value: 'APPLY',
            onClick: this.onApplyClick,
            disabled: this.state.applyButtonDisabled,
        });
        accordionButtons.push({
            value: 'CANCEL',
            onClick: this.onCancelClick,
            buttonTheme: ButtonThemes.Secondary,
        });

        const accordions = [
            {
                header: 'Manage Accounts',
                required: false,
                open: this.state.manageAccountsAccordionOpen,
                type: 'simpleTable',
                intro: introText,
                config: {
                    enableSearch: true,
                    enableFilters: true,
                    searchableColumns: [0],
                    filters: filters,
                    isLoading: this.state.isLoadingAccounts,
                    tableHeader: {
                        columns: [
                            {
                                title: 'Account Name',
                            },
                            {
                                title: 'Enabled',
                            },
                            {
                                title: 'Deleted',
                            },
                        ],
                    },
                    tableRows: accounts,
                    optionalButtonConfig: {
                        buttons: accordionButtons,
                    },
                    errorMessageOverride: 'No accounts have been imported for this connection.\n',
                    isScrollable: true,
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    }

    renderTable() {
        const errorMessageOverride = 'There are no connections assigned to this account.';

        const header = {
            columns: [
                {
                    title: 'Connection Name',
                },
                {
                    title: 'Accounts Linked',
                },
                {
                    title: 'User',
                },
            ],
        };

        const rows = this.state.connections.map(connection => {
            const rowProperty = {
                selected: this.state['selected-connections'].includes(connection),
                deleted: this.state['removed-connections'].includes(connection),
                edited:
                    this.state['updated-connections'].filter(
                        filteredConnection => filteredConnection.id === connection.id
                    ).length === 1,
                disabled: this.state.disableConnectionSelect,
            };

            return {
                onClick: this.onConnectionSelect,
                keyValue: `domains__${connection.id}`,
                dataValue: connection.id,
                rowProperty,
                columns: [
                    {
                        copy: connection.name,
                    },
                    {
                        copy: connection.accounts.length,
                    },
                    {
                        copy: connection.user,
                    },
                ],
            };
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__manage-domains">
                    <this.renderModalNavigation />
                    <h2>Bing</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__manage-domains">
                    <this.renderModalNavigation />
                    <h2>Bing</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__bing">
                <this.renderModalNavigation />
                <h2>Bing</h2>
                <p>
                    This is where you can manage your Bing integration with the Cubed Platform, including creating and
                    removing connections as well as managing the accounts imported into Cubed.
                </p>
                <p>
                    Admins can only edit connections belonging to them but can delete any connections. API users can
                    only manage their connections. Both Admins and API Users can create new connections. For more help
                    see <a href="https://tag.docs.withcubed.com/onboarding/api-integrations/">api integrations</a>.
                </p>
                <div className="modal__side-panel__bing">
                    <this.renderTable />
                    <InputButtonWrapper>
                        <InputButton value="CREATE" onClick={this.onCreateClick} />
                        <InputButton value="EDIT" disabled={this.state.editButtonDisabled} onClick={this.onEditClick} />
                        <InputButton
                            buttonTheme={this.state.removeButtonState && ButtonThemes.Red}
                            value={this.state.removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                            disabled={this.state.removeButtonDisabled}
                            onClick={this.onDeleteConnection}
                        />
                    </InputButtonWrapper>
                </div>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.account,
        request: state.request,
        meta: state.meta,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalBingApi);
