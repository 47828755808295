import React, { useState } from 'react';
import styled from 'styled-components';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal } from '../../../redux/actions/modal';
import { addNotification } from '../../../redux/actions/notification';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { FieldValues } from 'react-hook-form';
import { NotificationMessageType } from '../../../enums/notification-types';

// Helpers
import { generateUrl } from '../../../helpers/request-builder';

// Hooks
import usePostResourceWithPayload from '../../../react-query/hooks/use-post-resource-with-payload';

// Config
import { CONFIG_USER_PASSWORD } from '../../../configurations/resources-config';

// Components
import Form from '../../../components/forms/form';
import FormUpdatePassword from './forms/form-update-password';
import ModalNavigation from '../../../components/modal-navigation';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const LayoutModalResetUserPassword = () => {
    const dispatch = useDispatch();

    const passwordMutation = usePostResourceWithPayload({
        resource: CONFIG_USER_PASSWORD,
        url: generateUrl('user', 'password', [], false),
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'Password has been successfully updated..',
                    type: NotificationMessageType.Success,
                })
            );
            dispatch(removeModal());
        },
        handleOnError: error => {
            if (error) {
                dispatch(
                    addNotification({
                        copy: 'Password update failed. Please ensure you have entered your correct current password.',
                        type: NotificationMessageType.Error,
                    })
                );
            } else {
                dispatch(
                    addNotification({
                        copy: 'There was an issue trying to update your password. Please try again later',
                        type: NotificationMessageType.Error,
                    })
                );
            }
        },
    });

    const handleSubmit = (data: FieldValues) => {
        passwordMutation.mutate({
            new_password1: data.password,
            new_password2: data.repeatPassword,
            current_password: data.oldPassword,
        });
    };

    // eslint-disable-next-line
    const [formDefaultValues, _] = useState<FieldValues>({
        oldPassword: '',
        password: '',
        repeatPassword: '',
    });

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(removeModal()),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Reset Password</h2>
            <StyledFormContainer>
                <Form onSubmit={handleSubmit} defaultValues={formDefaultValues}>
                    <FormUpdatePassword />
                    <Form.Footer>
                        <Form.InputButton type="submit" value="Update" loading={passwordMutation.isPending} />
                        <Form.InputButton type="reset" value="Clear" buttonTheme={ButtonThemes.Secondary} />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </>
    );
};

export default LayoutModalResetUserPassword;
