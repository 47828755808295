import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Image from '../../assets/images/icons/sort.png';

const StyledSelect = styled.select`
    font-family: inherit;
    width: 100%;
    height: 40px;
    background-color: ${props => props.theme.colours.inputColour};
    border: none;
    border-bottom: solid 2px ${props => props.theme.colours.offBlackLighter};
    padding: 0 5px;
    margin-bottom: 5px;
    border-radius: 2px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: ${props => props.theme.colours.inputColour};
    background-image: url(${Image});
    background-size: 0.9em;
    background-repeat: no-repeat;
    background-position: right 5px center;
`;

export type SelectOption = {
    id?: number;
    value?: string | number;
    label?: string | number;
    name?: string;
    keyValue?: string | number;
};

export type InputSelectProps = {
    options: SelectOption[];
    required?: boolean;
    value?: string | number;
    customPlaceholder?: string;
    onChange: (() => void) | ((event: ChangeEvent<HTMLInputElement>) => void);
};

const InputSelect = ({ options, required, value, customPlaceholder, onChange }: InputSelectProps) => {
    return (
        <StyledSelect
            onChange={onChange as (event: React.ChangeEvent<HTMLSelectElement>) => void}
            data-required={required}
            defaultValue={value}
        >
            {customPlaceholder ? (
                <option value={customPlaceholder} hidden>
                    {customPlaceholder}
                </option>
            ) : (
                <option value="defaultValue" hidden>
                    {'Select an option'}
                </option>
            )}
            {options &&
                options.map(option => (
                    <option key={option.id} value={option.value}>
                        {option.name || option.label}
                    </option>
                ))}
        </StyledSelect>
    );
};

export default InputSelect;
