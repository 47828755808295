export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_TITLE':
            return Object.assign({}, state, {
                title: action.title,
                displayMenu: state.displayMenu,
            });
        case 'SET_DISPLAY_MENU':
            return Object.assign({}, state, {
                title: state.title,
                displayMenu: action.displayMenu,
            });
        case 'SET_DESCIPTION':
            return Object.assign({}, state, {
                description: action.description,
            });
        default:
            return state;
    }
}

const initialState = {
    title: 'Cubed Attribution',
    displayMenu: true,
};
