import React from 'react';
import { WidgetConfig } from '../../types';
import getResource from '../../helpers/get-resource';
import getField from '../../helpers/get-field';
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourceLineGraph from '../../../section-dashboard/hooks/widget/line-graph/use-resource-line-graph';
import WidgetLineGraph from '../../../section-dashboard/widgets/widget/widget-line-graph';
import CreateAWidgetPreviewMessage from '../../create-a-widget/components/create-a-widget-preview-message';
import useResourceLineGraphInterpolated from '../../../section-dashboard/hooks/widget/line-graph/use-resource-line-graph-interpolated';

const WidgetPreviewLineGraph = ({ config }: { config: WidgetConfig }) => {
    // Get the request values from the widget
    const resource = getResource(config.resource);
    const graphMetricField = config.graph_metrics && getField(config.graph_metrics);
    const groupByField = getField(config.group_by);

    const resourceData = useResource({
        resource: groupByField && resource,
        request: {
            groupBy: groupByField && resource ? [groupByField, resource.dateDimension] : [],
            orderBy: resource ? [{ field: resource?.dateDimension, orderByDirection: 'asc' }] : [],
        },
    });

    const resourceDataInterpolated = useResourceLineGraphInterpolated({
        resourceData: resourceData,
        dateDimension: resource?.dateDimension,
        seriesField: groupByField,
        yAxis: graphMetricField || undefined,
    });

    const lineGraphData = useResourceLineGraph({
        resourceData: config.interpolated ? resourceDataInterpolated : resourceData,
        dateDimension: resource?.dateDimension,
        seriesField: groupByField,
        yAxis: graphMetricField || undefined,
    });

    if (config.graph_metrics && config.graph_metrics.length > 0) {
        return (
            <WidgetLineGraph title={config.widget.title} data={lineGraphData} showFillColour={config.show_fill_color} />
        );
    }

    return (
        <CreateAWidgetPreviewMessage message="Please select a dimension and metric to preview the line graph widget" />
    );
};

export default WidgetPreviewLineGraph;
