import React, { createContext, useEffect, useState } from 'react';

// React Query
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess } from '../../react-query/types';
import { UseQueryResult } from '@tanstack/react-query';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

// Types
import { DashboardConfig } from '../types';

// Resources
import { DASHBOARD_BUILDER_CLIENT_USERS, DASHBOARD_BUILDER_DASHBOARD } from '../config/resources-dashboard-builder';

export interface DashboardBuilderContextValues {
    clientUserQuery: UseQueryResult<ConfigDataSuccess, Error>;
    clientUserId: number;
    clientUserFirstName: string;
    userDashboardsQuery: UseQueryResult<ConfigDataSuccess, Error>;
    userDashboards: DashboardConfig[];
}

export const DashboardBuilderContext = createContext<DashboardBuilderContextValues>(
    {} as DashboardBuilderContextValues
);

const DashboardBuilderContextProvider = ({ children }: { children: JSX.Element }) => {
    const user = useSelector((state: RootState) => state.user);

    // Client Users
    const clientUserQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_CLIENT_USERS,
        params: [{ key: 'email', value: user.email }],
    });

    const clientUserId = clientUserQuery.data?.objects[0]?.id;
    const clientUserFirstName = clientUserQuery.data?.objects[0]?.first_name;

    // Dashboards
    const [userDashboards, setUserDashboards] = useState<DashboardConfig[]>([]);

    const userDashboardsQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_DASHBOARD,
        params: [
            { key: 'author', value: clientUserId },
            { key: 'active', value: true },
        ],
        enabled: !!clientUserId,
    });

    useEffect(() => {
        if (userDashboardsQuery.data) {
            setUserDashboards(userDashboardsQuery.data.objects.map(dashboard => dashboard as DashboardConfig));
        }
    }, [userDashboardsQuery.data]);

    return (
        <DashboardBuilderContext.Provider
            value={{
                clientUserQuery,
                clientUserId,
                clientUserFirstName,
                userDashboardsQuery,
                userDashboards,
            }}
        >
            {children}
        </DashboardBuilderContext.Provider>
    );
};

export default DashboardBuilderContextProvider;
