/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import theme from '../../../styled-components/theme.styled';

// Fields & Resources
import * as fields from '../../../configurations/fields';
import * as resources from '../../../configurations/resources';

// Helpers
import getCurrentTier from '../../helpers/get-current-tier';

// Types
import { WidgetColour, WidgetMenuObj } from '../../../section-dashboard/types';

// Context
import { FilterBarContext } from '../../../filter-bar/context/filter-bar-context';

import {
    ChannelDetailDashboardMenuContext,
    ChannelDetailDashboardMenuContextValues,
} from '../../channel-detail-context/channel-detail-dashboard-menu-context';

// Hooks
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourceTotal from '../../../section-dashboard/hooks/widget/use-resource-total';
import useResourceSparkLine from '../../../section-dashboard/hooks/widget/use-resource-spark-line';
import useResourceBigNumber from '../../../section-dashboard/hooks/widget/big-number/use-resource-big-number';
import useResourceTable from '../../../section-dashboard/hooks/widget/table/use-resource-table';
import useRequestTable from '../../../section-dashboard/hooks/request/use-request-table';
import useResourcePieChartTable from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table';
import useResourcePieChartTableLimit from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table-limit';
import useResourceStackedAreaChart from '../../../section-dashboard/hooks/widget/use-resource-stacked-area-chart';
import useRequestStackedAreaChart from '../../../section-dashboard/hooks/request/use-request-stacked-area-chart';
import useRequestPie from '../../../section-dashboard/hooks/request/use-request-pie';

// Components
import BeforeGridConfiguredGoals from '../../../section-dashboard/components/sections/before-grid/before-grid-configured-goals';

import WidgetSparkLine from '../../../section-dashboard/widgets/widget/widget-spark-line';
import WidgetBigNumber from '../../../section-dashboard/widgets/widget/widget-big-number';
import WidgetTable from '../../../section-dashboard/widgets/widget/widget-table';
import WidgetMenuTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';
import WidgetPieChartTable from '../../../section-dashboard/widgets/widget/widget-pie-chart-table';
import WidgetStackedAreaChart from '../../../section-dashboard/widgets/widget/widget-stacked-area-chart';
import WidgetMenuStackedAreaChart from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-stacked-area-chart';
import useWidgetMenuFooterQuerySelect from '../../../section-dashboard/widgets/menus/hooks/use-widget-menu-footer-query-select';
import WidgetMenuPie from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie';

// Queries
import { goalsDropdownQuery } from '../../../section-dashboard/queries';

export type SectionMenuArgs = {
    context: { menuContext: ChannelDetailDashboardMenuContextValues };
};

export const config = {
    title: 'Channel Detail Dashboard',
    description: '', // TODO description
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableComparisonDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnableButtonGroup: false,
            isEnablePdfExport: true,
            isEnableChannelMultiSelect: false,
            isEnableChannelSingleSelect: {
                enabled: true,
                channelFilterResource: {
                    resource: resources.REPORT_MCS_CHANNEL,
                    dimension: fields.CHANNEL_NAME,
                    preselectMetric: fields.FM_SALES,
                },
            },
        },
        comparisonStatus: {
            showComparisonDatePicker: false,
            redirectUrl: 'channel-detail-comparison-dashboard',
        },
        datePicker: {},
    },
    context: () => {
        // Context
        const menuContext = useContext(ChannelDetailDashboardMenuContext);
        return { menuContext };
    },
    sectionTitles: (tier: string) => {
        const { selectedChannel } = useContext(FilterBarContext);
        const currentTier = getCurrentTier(tier, selectedChannel);

        if (typeof currentTier !== 'string' && 'displayName' in currentTier) {
            return currentTier.displayName;
        }

        return currentTier;
    },
    layouts: {
        xs: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a'
                    'b'
                    'c'
                    'd'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'o'
                    'p'
                    'q'
                    'r'
                    's'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h'
                    'i'
                    'j'
                    'k'
                    'l'
                    'm'
                    'u'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    't'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'x'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value- By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'w'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'bb'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'z'
                    `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a b'
                    'c d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'o o'
                    'p p'
                    'q q'
                    'r r'
                    's s'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h i'
                    'j k'
                    'l m'
                    'u u'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    't t'

                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'x x'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'w w'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'bb bb'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'z z'
                    `,
                },
            ],
        },
        md: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a a b b'
                    'c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'o o o p'
                    'o o o q'
                    'o o o r'
                    's s s s'
                    's s s s'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h h i i'
                    'j j k k'
                    'l l m m'
                    'u u u u'
                    'u u u u'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    't t t t'
                    't t t t'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'x x x x'
                    'x x x x'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value- By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'w w w w'
                    'w w w w'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'bb bb bb bb'
                    'bb bb bb bb'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value  - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'z z z z'
                    'z z z z'
                    `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a a a b b b'
                    'a a a b b b'
                    'c c c d d d'
                    'c c c d d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'o o o o o p'
                    'o o o o o q'
                    'o o o o o r'
                    's s s s s s'
                    's s s s s s'
                    's s s s s s'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h i j k l m'
                    'u u u u u u'
                    'u u u u u u'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    't t t t t t'
                    't t t t t t'
                    't t t t t t'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'x x x x x x'
                    'x x x x x x'
                     `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'w w w w w w'
                    'w w w w w w'
                    'w w w w w w'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'bb bb bb bb bb bb'
                    'bb bb bb bb bb bb'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'z z z z z z'
                    'z z z z z z'
                    'z z z z z z'
                    `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    title: 'Overall Channel Profile',
                    grid: `
                    'a a b b c c d d'
                    'a a b b c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'o o o o o o p p'
                    'o o o o o o q q'
                    'o o o o o o r r'
                    's s s s s s s s'
                    's s s s s s s s'
                    's s s s s s s s'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier2')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier2ProfileSectionMenu.menu,
                    grid: `
                    'h n cc i j k l m'
                    'u u u u u u u u'
                    'u u u u u u u u'
                    'u u u u u u u u'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier2')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier2GoalCompletionSectionMenu.menu,
                    grid: `
                    't t t t t t t t'
                    't t t t t t t t'
                    't t t t t t t t'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier3')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier3ProfileSectionMenu.menu,
                    grid: `
                    'x x x x x x x x'
                    'x x x x x x x x'
                    'x x x x x x x x'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier3')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier3GoalCompletionSectionMenu.menu,
                    grid: `
                    'w w w w w w w w'
                    'w w w w w w w w'
                    'w w w w w w w w'
                    `,
                },
                {
                    title: (): string => `${config.sectionTitles('tier4')} Profile`,
                    menu: (): JSX.Element => config.context().menuContext.tier4ProfileSectionMenu.menu,
                    grid: `
                    'bb bb bb bb bb bb bb bb'
                    'bb bb bb bb bb bb bb bb'
                    'bb bb bb bb bb bb bb bb'
                    `,
                },
                {
                    title: (): string => `Goal Completion Count & Value - By ${config.sectionTitles('tier4')}`,
                    menu: (): JSX.Element => config.context().menuContext.tier4GoalCompletionSectionMenu.menu,
                    grid: `
                    'z z z z z z z z'
                    'z z z z z z z z'
                    'z z z z z z z z'
                    `,
                },
            ],
        },
    },
    components: () => {
        const menuContext = useContext(ChannelDetailDashboardMenuContext);

        const {
            currentResource,
            selectedChannelFilter,
            tier2Field,
            tier3Field,
            tier4Field,
            tier2ProfileSectionMenu,
            tier2GoalCompletionSectionMenu,
            tier3ProfileSectionMenu,
            tier3GoalCompletionSectionMenu,
            tier4ProfileSectionMenu,
            tier4GoalCompletionSectionMenu,
        } = menuContext;

        // Widget Links
        const links = {
            attributionExplorer: '/reports/attribution/lc-vs-attribution',
        };

        // Sparklines

        const sparkData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
            request: {
                isGraph: true,
                groupBy: fields.SALES_DATE_DATE,
                graphGroupBy: [fields.SALES_DATE_DATE],
                graphMetrics: [fields.VISITS, fields.FM_SALES, fields.FM_REVENUE, fields.COST],
                pageFilters: selectedChannelFilter,
                enabled: !!selectedChannelFilter,
                selectedFields: [
                    fields.SALES_DATE_DATE,
                    fields.VISITS,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.COST,
                ],
            },
        });

        const sparkTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                totals: true,
                pageFilters: selectedChannelFilter,
                enabled: !!selectedChannelFilter,
                selectedFields: [
                    fields.SALES_DATE_DATE,
                    fields.VISITS,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.COST,
                ],
            },
        });

        const sparkTotals = useResourceTotal({
            resourceData: sparkTotalData,
        });

        // Visits Sparkline
        const visitsSpark = useResourceSparkLine({
            resourceData: sparkData,
            metric: fields.VISITS,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Sales Sparkline
        const salesSpark = useResourceSparkLine({
            resourceData: sparkData,
            metric: fields.FM_SALES,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Revenue Sparkline
        const revenueSpark = useResourceSparkLine({
            resourceData: sparkData,
            metric: fields.FM_REVENUE,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Cost Sparkline
        const costSpark = useResourceSparkLine({
            resourceData: sparkData,
            metric: fields.COST,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Goal Snapshot Table
        const goalSnapshotRequest = useRequestTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            selectedFields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
        });

        const goalSnapshotData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
            request: goalSnapshotRequest,
        });

        const goalSnapshotTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
            request: {
                ...goalSnapshotRequest,
                totals: true,
            },
        });

        const goalSnapshotTable = useResourceTable({
            resourceData: goalSnapshotData,
        });

        const goalSnapshotTableTotals = useResourceTotal({
            resourceData: goalSnapshotTotalData,
        });

        // BIG NUMBERS
        const goalSnapshotBigNumberGoalCompletionsData = (menu: WidgetMenuObj) =>
            useResource({
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT_CHANNEL,
                request: {
                    isGraph: true,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
                    pageFilters: selectedChannelFilter,
                    ...menu.request,
                    enabled: !!selectedChannelFilter,
                    selectedFields: [
                        fields.PRODUCT,
                        fields.GOAL_COMPLETIONS,
                        fields.GOAL_VALUE,
                        fields.GOAL_CVR_GOAL_SNAPSHOT,
                    ],
                },
            });

        // Total Goals Completed
        const goalSnapshotBigNumberGoalCompletionsMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalSnapshotBigNumberGoalCompletions = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberGoalCompletionsData(goalSnapshotBigNumberGoalCompletionsMenu),
            field: fields.GOAL_COMPLETIONS,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Attributed Value
        const goalSnapshotBigNumberGoalValueMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalSnapshotBigNumberGoalValue = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberGoalCompletionsData(goalSnapshotBigNumberGoalValueMenu),
            field: fields.GOAL_VALUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // All Goals CR%
        const goalSnapshotBigNumberGoalCVRMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalSnapshotBigNumberGoalCVR = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberGoalCompletionsData(goalSnapshotBigNumberGoalCVRMenu),
            field: fields.GOAL_CVR_GOAL_SNAPSHOT,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Goal Completions Over Time
        const goalCompletionRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Count of Completions', field: fields.FM_SALES, active: true },
                { name: 'Value of Completions', field: fields.FM_REVENUE, active: false },
            ],
            groupBy: fields.PRODUCT,
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' },
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            selectedFields: [fields.SALES_DATE_DATE, fields.FM_SALES, fields.FM_REVENUE],
        });

        const goalCompletionData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION_CHANNEL,
            request: goalCompletionRequest,
        });

        const goalCompletionsAdditionalSeries = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION_CHANNEL,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                pageFilters: selectedChannelFilter,
                enabled: !!selectedChannelFilter,
                selectedFields: [fields.SALES_DATE_DATE, fields.VISITS, fields.VISITORS],
            },
        });

        const goalCompletionStackedAreaChart = useResourceStackedAreaChart({
            areaSeriesMetric: fields.PRODUCT,
            resourceData: goalCompletionData,
            dateDimension: fields.SALES_DATE_DATE,
            optionalSeries: [
                {
                    title: 'Unique Visits',
                    yAxis: fields.VISITS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.purple,
                },
                {
                    title: 'Unique Visitors',
                    yAxis: fields.VISITORS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.emerald,
                },
            ],
        });

        // 2nd Tier Profile Big Numbers
        const tier2ProfileBigNumbersData = useResource({
            resource: currentResource,
            request: {
                isGraph: true,
                graphGroupBy: currentResource && [currentResource?.dateDimension],
                graphMetrics: [
                    fields.VISITS,
                    fields.NEW_VISITS,
                    fields.REPEAT_VISITS,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.BOUNCES,
                    fields.COST,
                    fields.FM_ROI,
                ],
                selectedFields: [
                    fields.VISITS,
                    fields.NEW_VISITS,
                    fields.REPEAT_VISITS,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.BOUNCES,
                    fields.COST,
                    fields.FM_ROI,
                ],
                pageFilters: selectedChannelFilter,
                enabled: !!selectedChannelFilter,
                ...tier2ProfileSectionMenu.request,
            },
        });

        const tier2BigNumberVisits = useResourceBigNumber({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.VISITS,
            aggregation: 'sum',
            rounding: {
                applyRounding: false,
            },
        });

        const tier2BigNumberRepeatVisits = useResourceBigNumber({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.REPEAT_VISITS,
            aggregation: 'sum',
            rounding: {
                applyRounding: false,
            },
        });

        const tier2BigNumberNewVisits = useResourceBigNumber({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.NEW_VISITS,
            aggregation: 'sum',
            rounding: {
                applyRounding: false,
            },
        });

        const tier2BigNumberGoalCompletions = useResourceBigNumber({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.FM_SALES,
            aggregation: 'sum',
        });

        const tier2BigNumberCompletionValue = useResourceBigNumber({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
        });

        const tier2BigNumberBounces = useResourceBigNumber({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.BOUNCES,
            aggregation: 'sum',
        });

        const tier2BigNumberCost = useResourceBigNumber({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.COST,
            aggregation: 'sum',
            formatting: 'currency',
        });

        const tier2BigNumberROI = useResourceBigNumber({
            resourceData: tier2ProfileBigNumbersData,
            field: fields.FM_ROI,
            aggregation: 'sum',
            formatting: 'percentage',
        });

        // Detailed View Table
        const goalCompletionCountByTier2TableRequest = useRequestTable({
            groupBy: tier2Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier2Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier2ProfileSectionMenu.request,
            selectedFields: [
                tier2Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const goalCompletionCountByTier2TableData = useResource({
            resource: currentResource,
            request: goalCompletionCountByTier2TableRequest,
        });

        const goalCompletionCountByTier2TableTotalData = useResource({
            resource: currentResource,
            request: {
                ...goalCompletionCountByTier2TableRequest,
                totals: true,
            },
        });

        const goalCompletionCountByTier2Table = useResourceTable({
            resourceData: goalCompletionCountByTier2TableData,
        });

        const goalCompletionCountByTier2TableTotals = useResourceTotal({
            resourceData: goalCompletionCountByTier2TableTotalData,
        });

        // Goal Completion Count & Value - By Tier 2
        // Goal Completion Count - By Tier 2 Pie
        const goalCompletionCountByTier2PieTableRequest = useRequestPie({
            groupBy: tier2Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [tier2Field, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
            ...tier2GoalCompletionSectionMenu.request,
            selectedFields: [tier2Field, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
        });

        const goalCompletionCountByTier2PieTableData = useResource({
            resource: currentResource,
            request: goalCompletionCountByTier2PieTableRequest,
        });

        const goalCompletionCountByTier2PieTableTotalData = useResource({
            resource: currentResource,
            request: {
                ...goalCompletionCountByTier2PieTableRequest,
                totals: true,
            },
        });

        const goalCompletionCountByTier2PieTable = useResourcePieChartTable({
            resourceData: goalCompletionCountByTier2PieTableData,
            chartDimension: tier2Field,
        });

        const goalCompletionCountByTier2PieTableTotals = useResourceTotal({
            resourceData: goalCompletionCountByTier2PieTableTotalData,
        });

        const goalCompletionCountByTier2PieTableLimit = useResourcePieChartTableLimit({
            resourceData: goalCompletionCountByTier2PieTable,
            totalData: goalCompletionCountByTier2PieTableTotals,
            ratioField: fields.GOAL_COMPLETIONS,
            limit: 10,
        });

        // Tier 3 Profile
        // Detailed View Table - Tier 3
        const goalCompletionCountByTier3TableRequest = useRequestTable({
            groupBy: tier3Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                tier3Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier3ProfileSectionMenu.request,
            selectedFields: [
                tier3Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const goalCompletionCountByTier3TableData = useResource({
            resource: currentResource,
            request: goalCompletionCountByTier3TableRequest,
        });

        const goalCompletionCountByTier3TableTotalData = useResource({
            resource: currentResource,
            request: {
                ...goalCompletionCountByTier3TableRequest,
                totals: true,
            },
        });

        const goalCompletionCountByTier3Table = useResourceTable({
            resourceData: goalCompletionCountByTier3TableData,
        });

        const goalCompletionCountByTier3TableTotals = useResourceTotal({
            resourceData: goalCompletionCountByTier3TableTotalData,
        });

        // Goal Completion Count & Value - By Tier 3
        // Goal Completion Count - By Tier 3 Pie
        const goalCompletionCountByTier3PieTableRequest = useRequestPie({
            groupBy: tier3Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [tier3Field, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
            ...tier3GoalCompletionSectionMenu.request,
            selectedFields: [tier3Field, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
        });

        const goalCompletionCountByTier3PieTableData = useResource({
            resource: currentResource,
            request: goalCompletionCountByTier3PieTableRequest,
        });

        const goalCompletionCountByTier3PieTableTotalData = useResource({
            resource: currentResource,
            request: {
                ...goalCompletionCountByTier3PieTableRequest,
                totals: true,
            },
        });

        const goalCompletionCountByTier3PieTable = useResourcePieChartTable({
            resourceData: goalCompletionCountByTier3PieTableData,
            chartDimension: tier3Field,
        });

        const goalCompletionCountByTier3PieTableTotals = useResourceTotal({
            resourceData: goalCompletionCountByTier3PieTableTotalData,
        });

        const goalCompletionCountByTier3PieTableLimit = useResourcePieChartTableLimit({
            resourceData: goalCompletionCountByTier3PieTable,
            totalData: goalCompletionCountByTier3PieTableTotals,
            ratioField: fields.GOAL_COMPLETIONS,
            limit: 10,
        });

        // Tier 4 Profile
        // Goal Completion Value - By Tier 4
        // Table
        const goalCompletionCountByTier4TableRequest = useRequestTable({
            groupBy: tier4Field,
            orderBy: [{ field: fields.GOAL_VALUE, orderByDirection: 'desc' }],
            fields: [
                tier4Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            ...tier4ProfileSectionMenu.request,
            selectedFields: [
                tier4Field,
                fields.VISITS,
                fields.NEW_VISITS,
                fields.REPEAT_VISITS,
                fields.BOUNCES,
                fields.BOUNCE_RATE,
                fields.ASSISTS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
                fields.COST,
            ],
        });

        const goalCompletionValueByTier4TableData = useResource({
            resource: currentResource,
            request: goalCompletionCountByTier4TableRequest,
        });

        const goalCompletionValueByTier4TableTotalData = useResource({
            resource: currentResource,
            request: {
                ...goalCompletionCountByTier4TableRequest,
                totals: true,
            },
        });

        const goalCompletionValueByTier4Table = useResourceTable({
            resourceData: goalCompletionValueByTier4TableData,
        });

        const goalCompletionValueByTier4TableTotals = useResourceTotal({
            resourceData: goalCompletionValueByTier4TableTotalData,
        });

        // Goal Completion Count & Value - By Tier 4
        //  Goal Completion Count - By Tier 4 Pie
        const goalCompletionCountByTier4PieTableRequest = useRequestPie({
            groupBy: tier4Field,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [tier4Field, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
            pageFilters: selectedChannelFilter,
            enabled: !!selectedChannelFilter,
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
            ...tier4GoalCompletionSectionMenu.request,
            selectedFields: [tier4Field, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
        });

        const goalCompletionCountByTier4PieTableData = useResource({
            resource: currentResource,
            request: goalCompletionCountByTier4PieTableRequest,
        });

        const goalCompletionCountByTier4PieTableTotalData = useResource({
            resource: currentResource,
            request: {
                ...goalCompletionCountByTier4PieTableRequest,
                totals: true,
            },
        });

        const goalCompletionCountByTier4PieTable = useResourcePieChartTable({
            resourceData: goalCompletionCountByTier4PieTableData,
            chartDimension: tier4Field,
        });

        const goalCompletionCountByTier4PieTableTotals = useResourceTotal({
            resourceData: goalCompletionCountByTier4PieTableTotalData,
        });

        const goalCompletionCountByTier4PieTableLimit = useResourcePieChartTableLimit({
            resourceData: goalCompletionCountByTier4PieTable,
            totalData: goalCompletionCountByTier4PieTableTotals,
            ratioField: fields.GOAL_COMPLETIONS,
            limit: 10,
        });

        return {
            a: (
                <WidgetSparkLine
                    title="Visits by Day"
                    data={visitsSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Teal}
                />
            ),
            b: (
                <WidgetSparkLine
                    title="Sales by Day"
                    data={salesSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Orange}
                />
            ),
            c: (
                <WidgetSparkLine
                    title="Revenue by Day"
                    data={revenueSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Green}
                />
            ),
            d: (
                <WidgetSparkLine
                    title="Cost by Day"
                    data={costSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Rose}
                />
            ),
            h: <WidgetBigNumber title="Visits" data={tier2BigNumberVisits} href={links.attributionExplorer} />,
            n: <WidgetBigNumber title="New Visits" data={tier2BigNumberNewVisits} href={links.attributionExplorer} />,
            cc: (
                <WidgetBigNumber
                    title="Repeat Visits"
                    data={tier2BigNumberRepeatVisits}
                    href={links.attributionExplorer}
                />
            ),
            i: (
                <WidgetBigNumber
                    title="Goal Completions"
                    data={tier2BigNumberGoalCompletions}
                    href={links.attributionExplorer}
                />
            ),
            j: (
                <WidgetBigNumber
                    title="Completion Value"
                    data={tier2BigNumberCompletionValue}
                    href={links.attributionExplorer}
                />
            ),
            k: <WidgetBigNumber title="Bounces" data={tier2BigNumberBounces} href={links.attributionExplorer} />,
            l: <WidgetBigNumber title="Cost" data={tier2BigNumberCost} href={links.attributionExplorer} />,
            m: <WidgetBigNumber title="ROI" data={tier2BigNumberROI} href={links.attributionExplorer} />,
            o: (
                <WidgetTable
                    title="Goal Snapshot"
                    data={goalSnapshotTable}
                    totalData={goalSnapshotTableTotals}
                    href={links.attributionExplorer}
                />
            ),
            p: (
                <WidgetBigNumber
                    title="Goal Completions"
                    data={goalSnapshotBigNumberGoalCompletions}
                    menu={goalSnapshotBigNumberGoalCompletionsMenu.menu}
                    href={links.attributionExplorer}
                />
            ),
            q: (
                <WidgetBigNumber
                    title="Goal Value"
                    data={goalSnapshotBigNumberGoalValue}
                    menu={goalSnapshotBigNumberGoalValueMenu.menu}
                    href={links.attributionExplorer}
                />
            ),
            r: (
                <WidgetBigNumber
                    title="Conversion Rate"
                    data={goalSnapshotBigNumberGoalCVR}
                    menu={goalSnapshotBigNumberGoalCVRMenu.menu}
                    href={links.attributionExplorer}
                />
            ),
            s: (
                <WidgetStackedAreaChart
                    title="Goal Completions Over Time"
                    data={goalCompletionStackedAreaChart}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={goalCompletionStackedAreaChart}
                            request={goalCompletionRequest}
                        />
                    }
                    href={links.attributionExplorer}
                />
            ),
            t: (
                <WidgetPieChartTable
                    title={`Goal Completion Count & Value - ${
                        tier2Field.displayName ? tier2Field.displayName : '2nd Tier'
                    }`}
                    data={goalCompletionCountByTier2PieTableLimit}
                    totalData={goalCompletionCountByTier2PieTableTotals}
                    display="donut"
                    href={links.attributionExplorer}
                    menu={<WidgetMenuPie data={goalCompletionCountByTier2PieTable} />}
                />
            ),
            u: (
                <WidgetTable
                    title={`${tier2Field.displayName ? tier2Field.displayName : '2nd Tier'} - Detailed View`}
                    data={goalCompletionCountByTier2Table}
                    totalData={goalCompletionCountByTier2TableTotals}
                    menu={<WidgetMenuTable data={goalCompletionCountByTier2Table} />}
                    href={links.attributionExplorer}
                />
            ),
            w: (
                <WidgetPieChartTable
                    title={`Goal Completion Count & Value - ${
                        tier3Field.displayName ? tier3Field.displayName : '3rd Tier'
                    }`}
                    data={goalCompletionCountByTier3PieTableLimit}
                    totalData={goalCompletionCountByTier3PieTableTotals}
                    display="donut"
                    href={links.attributionExplorer}
                    menu={<WidgetMenuPie data={goalCompletionCountByTier3PieTable} />}
                />
            ),
            x: (
                <WidgetTable
                    title={`${tier3Field.displayName ? tier3Field.displayName : '3rd Tier'} - Detailed View`}
                    data={goalCompletionCountByTier3Table}
                    totalData={goalCompletionCountByTier3TableTotals}
                    menu={<WidgetMenuTable data={goalCompletionCountByTier3Table} />}
                    href={links.attributionExplorer}
                />
            ),
            z: (
                <WidgetPieChartTable
                    title={`Goal Completion Count & Value - ${
                        tier4Field.displayName ? tier4Field.displayName : '4th Tier'
                    }`}
                    data={goalCompletionCountByTier4PieTableLimit}
                    totalData={goalCompletionCountByTier4PieTableTotals}
                    display="donut"
                    href={links.attributionExplorer}
                    menu={<WidgetMenuPie data={goalCompletionCountByTier4PieTable} />}
                />
            ),
            bb: (
                <WidgetTable
                    title={`${tier4Field.displayName ? tier4Field.displayName : '4th Tier'} - Detailed View`}
                    data={goalCompletionValueByTier4Table}
                    totalData={goalCompletionValueByTier4TableTotals}
                    menu={<WidgetMenuTable data={goalCompletionValueByTier4Table} />}
                    href={links.attributionExplorer}
                />
            ),
        };
    },
};
