import { unknownErrorData } from '../../helpers/errors';

// Types
import { ResourceData, TotalData } from '../../types';
import { mapObject } from '../../helpers/map-object';

export type UseResourceTotalArgs = {
    resourceData: ResourceData;
};

const useResourceTotal = ({ resourceData }: UseResourceTotalArgs): TotalData => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        return {
            type: 'total',
            status: 'success',
            request: resourceData.request,
            totals: mapObject(resourceData.meta.totals),
        };
    }

    return unknownErrorData();
};

export default useResourceTotal;
