import React, { Component } from 'react';
import { connect } from 'react-redux';

import Axios from 'axios';

import { setModal } from '../../redux/actions/modal';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';

import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';

class KlaviyoAPIAddEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            pageError: false,
            formErrors: { private_key: '', public_key: '', account_name: '' },
            klaviyoConnection: { id: '', private_key: '', public_key: '', account_name: '' },
        };
    }

    componentDidMount() {
        const editableConnection = this.props.config.config;
        if (this.props.config.config) {
            this.setState({
                klaviyoConnection: editableConnection,
            });
        }
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                this.setState({
                    isLoading: false,
                });
            } else {
                this.setState({
                    pageError: true,
                    isLoading: false,
                });
            }
        }

        if (this.state.closeButtonState === 'close') {
            if (
                this.state.klaviyoConnection.private_key !== '' ||
                this.state.klaviyoConnection.public_key !== '' ||
                this.state.klaviyoConnection.account_name !== ''
            ) {
                this.setState({
                    closeButtonState: 'cancel',
                    saveChangesButtonDisabled: false,
                });
            }
        }
    }

    handleNavigateKlaviyoModal = () => {
        this.props.setModal('ManageKlaviyo', {});
    };

    onPopupDiscardChangesClick = () => {
        this.props.removePopup();
        this.handleNavigateKlaviyoModal();
    };

    // SecondaryAction of the close popup
    onPopupStayHereClick = () => {
        this.props.removePopup();
    };

    onCloseClick = () => {
        if (this.state.closeButtonState === 'close') {
            this.handleNavigateKlaviyoModal();
            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        style: 'secondary',
                    },
                ],
            });
        }
    };

    updateKlaviyoKey = () => {
        return new Promise(async (resolve, reject) => {
            const klaviyoConnectionPayload = {
                public_key: this.state.klaviyoConnection.public_key,
                private_key: this.state.klaviyoConnection.private_key,
                account_name: this.state.klaviyoConnection.account_name,
            };

            const klaviyoConnectionConfig = {
                method: 'PATCH',
                url: generateUrlDetail('config', 'klaviyo-connection', this.state.klaviyoConnection.id),
                data: klaviyoConnectionPayload,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            try {
                await Axios(klaviyoConnectionConfig);
            } catch (error) {
                if (error.message.includes('400')) {
                    reject(error.response);
                } else {
                    reject('Error updating your Klaviyo connection.');
                }
            }

            resolve('Klaviyo Connection Updated');
        });
    };

    // Makes a POST request to the API with the data entered by the user.
    saveNewKlaviyoKey = () => {
        return new Promise(async (resolve, reject) => {
            const klaviyoConnectionPayload = {
                public_key: this.state.klaviyoConnection.public_key,
                private_key: this.state.klaviyoConnection.private_key,
                account_name: this.state.klaviyoConnection.account_name,
            };

            const klaviyoConnectionConfig = {
                method: 'POST',
                url: generateUrl('config', 'klaviyo-connection'),
                data: klaviyoConnectionPayload,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            try {
                const klaviyoConnectionRequest = await Axios(klaviyoConnectionConfig);
                if (klaviyoConnectionRequest.status !== 201) {
                    throw new Error('Status not 201');
                }
            } catch (error) {
                if (error.message.includes('400')) {
                    reject(error.response);
                } else {
                    reject('Error making a Klaviyo connection.');
                }
            }

            resolve('Klaviyo Connection Created');
        });
    };

    updateFormErrors = err => {
        if (err.data['klaviyo-connection']) {
            this.setState(prevState => {
                const updatedFormErrors = { ...prevState.formErrors };
                for (const obj of Object.entries(err.data['klaviyo-connection'])) {
                    updatedFormErrors[obj[0]] = obj[1][0];
                }
                this.setState({ formErrors: updatedFormErrors });
            });
        } else {
            this.props.addNotification({
                copy: 'Something went wrong updating your key, please try again later.',
                type: NotificationMessageType.Error,
            });
        }
    };

    // Saved changes clicked handler
    onSaveChangesClick = () => {
        this.setState({
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: true,
            closeButtonDisabled: true,
        });

        if (this.state.klaviyoConnection['id'] === '') {
            //ie, this is a new connection
            this.saveNewKlaviyoKey()
                .then(response => {
                    this.props.addNotification({
                        copy: 'Your Klaviyo key was saved successfully.',
                        type: NotificationMessageType.Success,
                    });
                    this.setState({
                        klaviyoConnectionAccordionOpen: false,
                    });

                    if (response.status === 400) {
                        throw new Error('400');
                    }
                    this.handleNavigateKlaviyoModal();
                })
                .catch(err => {
                    if (err.status === 400) {
                        this.updateFormErrors(err);
                    } else {
                        this.props.addNotification({
                            copy: 'Something went wrong saving your key, please try again later.',
                            type: NotificationMessageType.Error,
                        });
                    }

                    this.setState({
                        saveChangesButtonDisabled: false,
                        saveChangesButtonLoading: false,
                        closeButtonDisabled: false,
                    });
                });
        } else {
            this.updateKlaviyoKey()
                .then(response => {
                    this.props.addNotification({
                        copy: 'Your Klaviyo key was updated successfully.',
                        type: NotificationMessageType.Success,
                    });

                    if (response.status === 400) {
                        throw new Error('400');
                    }

                    this.handleNavigateKlaviyoModal();
                })
                .catch(err => {
                    if (err.status === 400) {
                        this.updateFormErrors(err);
                    } else {
                        this.props.addNotification({
                            copy: 'Something went wrong saving your keys, please try again later.',
                            type: NotificationMessageType.Error,
                        });
                    }

                    this.setState({
                        saveChangesButtonDisabled: false,
                        saveChangesButtonLoading: false,
                        closeButtonDisabled: false,
                    });
                });
        }
    };

    resetFormError = () => {
        this.setState({
            formErrors: { private_key: '', public_key: '', account_name: '' }, //reset this on change
        });
    };

    // Manage the input fields changes and updating the state with the new value entered by the user
    onKlaviyoAccountNameChange = event => {
        let updatedKlaviyoConnection = { ...this.state.klaviyoConnection };
        updatedKlaviyoConnection.account_name = event.target.value;
        this.setState({
            klaviyoConnection: updatedKlaviyoConnection,
        });
        this.resetFormError();
    };
    onKlaviyoPublicKeyChange = event => {
        let updatedKlaviyoConnection = { ...this.state.klaviyoConnection };
        updatedKlaviyoConnection.public_key = event.target.value;
        this.setState({
            klaviyoConnection: updatedKlaviyoConnection,
        });
        this.resetFormError();
    };
    onKlaviyoPrivateKeyChange = event => {
        let updatedKlaviyoConnection = { ...this.state.klaviyoConnection };
        updatedKlaviyoConnection.private_key = event.target.value;
        this.setState({
            klaviyoConnection: updatedKlaviyoConnection,
        });
        this.resetFormError();
    };

    renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    renderAccordion = () => {
        const accordions = [
            {
                header: 'Klaviyo API Keys',
                required: false,
                open: true,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Account Name:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter the name you want to associate with your Klaviyo account.',
                                inputKeyValue: 'account_name',
                                inputPlaceholder: 'Account name...',
                                inputValue: this.state.klaviyoConnection.account_name,
                                inputOnChange: this.onKlaviyoAccountNameChange,
                                errorMessage: this.state.formErrors.account_name,
                            },
                            {
                                label: 'Public Key:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: 'Enter your Klaviyo public key.',
                                inputKeyValue: 'public_key',
                                inputPlaceholder: 'eg. DBffET',
                                inputValue: this.state.klaviyoConnection.public_key,
                                inputOnChange: this.onKlaviyoPublicKeyChange,
                                errorMessage: this.state.formErrors.public_key,
                            },
                            {
                                label: 'Private Key:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter your Klaviyo private key.',
                                inputKeyValue: 'private_key',
                                inputPlaceholder: 'eg. pk_d1cc972c0a284069984a69025a339b05 ',
                                inputValue: this.state.klaviyoConnection.private_key,
                                inputOnChange: this.onKlaviyoPrivateKeyChange,
                                errorMessage: this.state.formErrors.private_key,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel">
                    <this.renderModalNavigation />
                    <h2>Klaviyo Keys</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel">
                    <this.renderModalNavigation />
                    <h2>Klaviyo Keys</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email. " />
                </div>
            );
        }

        return (
            <div className="modal__side-panel">
                <this.renderModalNavigation />

                <h2>Klaviyo Keys</h2>
                <p>
                    Add your Klaviyo API keys to allow the import of Klaviyo campaign and profile data. For more
                    information on where to set-up and find your Klaviyo API keys please visit: &nbsp;
                    <a href="https://help.klaviyo.com/hc/en-us/articles/115005062267-How-to-Manage-Your-Account-s-API-Keys#:~:text=Private%20API%20keys%20are%20used,private%20API%20keys%20as%20needed.">
                        the Klavio API documentation
                    </a>
                    .
                </p>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meta: state.meta,
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KlaviyoAPIAddEditModal);
