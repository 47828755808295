import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import { SectionDashboardTheme } from '../../filter-bar/enums/section-dashboard-theme';
import { Location, useLocation } from 'react-router';

export type RouteLocation = Location & { isSectionDashboard: boolean };

type CubedThemeContextValues = {
    enableThemeToggle: boolean;
    sectionDashboardTheme: SectionDashboardTheme;
    toggleSectionDashboardTheme: () => void;
};

export const CubedThemeContext = createContext<CubedThemeContextValues>({} as CubedThemeContextValues);

const CubedThemeProvider = ({ children }: { children: JSX.Element }) => {
    const location = useLocation() as RouteLocation;

    const [enableThemeToggle, setEnableThemeToggle] = useState(false);
    const [sectionDashboardTheme, setSectionDashboardTheme] = useState(
        localStorage.getItem('sectionDashboardTheme') === 'dark'
            ? SectionDashboardTheme.Dark
            : SectionDashboardTheme.Light
    );

    useEffect(() => {
        if (location.isSectionDashboard) {
            setEnableThemeToggle(true);
        } else {
            setEnableThemeToggle(false);
        }
    }, [location]);

    const toggleSectionDashboardTheme = () => {
        if (sectionDashboardTheme === SectionDashboardTheme.Light) {
            localStorage.setItem('sectionDashboardTheme', SectionDashboardTheme.Dark);
            setSectionDashboardTheme(SectionDashboardTheme.Dark);
        } else {
            localStorage.setItem('sectionDashboardTheme', SectionDashboardTheme.Light);
            setSectionDashboardTheme(SectionDashboardTheme.Light);
        }
    };

    return (
        <CubedThemeContext.Provider value={{ enableThemeToggle, sectionDashboardTheme, toggleSectionDashboardTheme }}>
            {children}
        </CubedThemeContext.Provider>
    );
};

export default CubedThemeProvider;
