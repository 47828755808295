import React from 'react';
import styled from 'styled-components';

// Context
import { useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
`;

const StyledLabel = styled.label`
    margin: 0;
    font-size: 0.9em;
    font-weight: 500;
    color: ${props => props.theme.forms.labels.color};
`;

const StyledToggle = styled.label`
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
`;

const StyledSpan = styled.span<{ disabled?: boolean }>`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props =>
        props.disabled
            ? props.theme.forms.inputs.inputToggle.backgroundColorDisabled
            : props.theme.forms.inputs.inputToggle.backgroundColor};
    transition: 0.4s;
    border-radius: 34px;

    :before {
        position: absolute;
        content: '';
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: ${props =>
            props.disabled
                ? props.theme.forms.inputs.inputToggle.indicatorColorDisabled
                : props.theme.forms.inputs.inputToggle.indicatorColor};
        transition: 0.4s;
        border-radius: 50%;
    }
`;

const StyledInput = styled.input`
    display: none;

    :checked + ${StyledSpan} {
        background-color: ${props => props.theme.forms.inputs.inputToggle.backgroundColorChecked};
        :before {
            transform: translateX(26px);
        }
    }
`;

export type InputToggleProps = {
    name: string;
    label: string;
    defaultOn?: boolean;
    disabled?: boolean;
};

const InputToggle = ({ name, label, defaultOn, disabled }: InputToggleProps) => {
    const { status } = useCubedFormContext();
    const { register } = useFormContext();

    const isDisabled = !!disabled || status === 'disabled' || status === 'loading';

    return (
        <StyledContainer>
            <StyledLabel htmlFor={name}>{label}:</StyledLabel>
            <StyledToggle>
                <StyledInput type="checkbox" defaultChecked={defaultOn} disabled={isDisabled} {...register(name)} />
                <StyledSpan disabled={isDisabled} />
            </StyledToggle>
        </StyledContainer>
    );
};

export default InputToggle;
