import React from 'react';
import IconQuestionMark from './icons/question-mark';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import styled from 'styled-components';

const StyledIconContainer = styled.div`
    background-color: ${props => props.theme.colours.midGrey};
    border-radius: 50%;
    width: 20px;
    height: 20px;
`;

const StyledTrigger = styled(TooltipPrimitive.Trigger)<{
    $tooltipPlacement: TooltipPlacement;
    $usePlaceholder: boolean;
}>`
    font-family: inherit;
    background-color: transparent;
    padding: 0;
    outline: none;
    border: none;
    width: ${props => (props.$usePlaceholder ? '20px' : '100%')};
    float: ${props => (props.$tooltipPlacement ? props.$tooltipPlacement : 'none')};
    cursor: pointer;

    &:hover ${StyledIconContainer} {
        background-color: ${props => props.theme.colours.offBlack};
    }
`;

const StyledTooltipContent = styled(TooltipPrimitive.Content)<{ $invertColours: boolean }>`
    background-color: ${props => (props.$invertColours ? props.theme.colours.white : props.theme.colours.offBlack)};
    color: ${props => (props.$invertColours ? props.theme.colours.offBlack : props.theme.colours.white)};
    border: 2px solid
        ${props => (props.$invertColours ? props.theme.colours.cubedOrange : props.theme.colours.offBlack)};
    font-size: 0.75rem;
    padding: 10px;
    border-radius: 5px;
    max-width: 210px;
    text-align: center;
    z-index: 500001;
`;

const StyledIconQuestionMark = styled(IconQuestionMark)`
    width: 16px;
    height: 16px;
    color: ${props => props.theme.colours.white};
    position: relative;
    top: 2px;
`;

const StyledArrow = styled(TooltipPrimitive.Arrow)<{ $invertColours: boolean }>`
    fill: ${props => (props.$invertColours ? props.theme.colours.cubedOrange : props.theme.colours.offBlack)};
`;

export enum TooltipPlacement {
    Right = 'right',
    Left = 'left',
}

export enum TooltipPopUpSide {
    Top = 'top',
    Right = 'right',
    Bottom = 'bottom',
    Left = 'left',
}

export type TooltipProps = {
    copy: string;
    tooltipPlacement?: TooltipPlacement;
    tooltipPopUpSide?: TooltipPopUpSide;
    preventClick?: boolean;
    usePlaceholder?: boolean;
    invertColours?: boolean;
    children?: React.ReactNode;
};

const Tooltip = ({
    copy,
    tooltipPlacement = TooltipPlacement.Right,
    tooltipPopUpSide = TooltipPopUpSide.Top,
    preventClick = true,
    usePlaceholder = true,
    invertColours = false,
    children,
}: TooltipProps) => {
    const handleTriggerClick = (event: React.UIEvent) => {
        preventClick && event.preventDefault();
    };

    return (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root delayDuration={0}>
                <StyledTrigger
                    onClick={handleTriggerClick}
                    $tooltipPlacement={tooltipPlacement}
                    $usePlaceholder={usePlaceholder}
                >
                    {usePlaceholder && (
                        <StyledIconContainer>
                            <StyledIconQuestionMark />
                        </StyledIconContainer>
                    )}
                    {!usePlaceholder && children}
                </StyledTrigger>
                <TooltipPrimitive.Portal>
                    <StyledTooltipContent side={tooltipPopUpSide} $invertColours={invertColours} arrowPadding={-50}>
                        <StyledArrow $invertColours={invertColours} />
                        {copy}
                    </StyledTooltipContent>
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    );
};

export default Tooltip;
