import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import validators from '../../../components/forms/validation/validators';

// Context
import { useFormContext } from 'react-hook-form';
import { CreateAWidgetContext } from '../context/create-a-widget-context';
import CreateAWidgetFormContextProvider from './context/create-a-widget-form-context';

// Enums
import { ButtonThemes } from '../../../enums/button-themes';

// Hooks
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

// Types
import { RootState } from '../../../redux/store';

// Components
import Form from '../../../components/forms/form';
import CreateAWidgetPreview from '../components/create-a-widget-preview';
import IconArrow from '../../../components/icons/arrow';

// Forms
import CreateAWidgetFormTable from './create-a-widget-form-table';
import CreateAWidgetFormBarGraph from './create-a-widget-form-bar-graph';
import CreateAWidgetFormPieChart from './create-a-widget-form-pie-chart';
import CreateAWidgetFormSparkline from './create-a-widget-form-sparkline';
import CreateAWidgetFormPieChartTable from './create-a-widget-form-pie-chart-table';
import CreateAWidgetFormBigNumber from './create-a-widget-form-big-number';
import CreateAWidgetFormLineGraph from './create-a-widget-form-line-graph';
import CreateAWidgetFormTextBox from './create-a-widget-form-text-box';

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    background-color: ${props => props.theme.dashboardBuilder.container.backgroundColor};
    ${props => props.theme.boxShadow(1)}
    padding: 16px;
    border-radius: 6px;

    @media (max-width: 1600px) {
        flex-direction: column;
        flex-direction: column-reverse;
    }
`;

const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 450px;

    @media (max-width: 1600px) {
        min-width: 100%;
    }
`;

const StyledButton = css`
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 8px;
    padding: 0;
    color: ${props => props.theme.buttons.secondary.color};
`;

const StyledBackButtonLargeScreen = styled.button`
    ${StyledButton}

    @media (max-width: 1600px) {
        display: none;
    }
`;

const StyledBackButtonSmallScreen = styled.button`
    ${StyledButton}

    @media (min-width: 1601px) {
        display: none;
    }
`;

const StyledIconArrow = styled(IconArrow)`
    width: 12px;
`;

const CreateAWidgetForm = () => {
    const navigate = useNavigate();
    const accountToken = useSelector((state: RootState) => state.account.token);

    // Form Values
    const { watch, resetField } = useFormContext();
    const formValues = watch();

    // Visualisations
    const createAWidgetContext = useContext(CreateAWidgetContext);
    const { visualisationDropdownOptions } = createAWidgetContext;

    const renderVisualisationForm = () => {
        switch (formValues.visualisation) {
            case 'table':
                return <CreateAWidgetFormTable />;
            case 'barGraph':
                return <CreateAWidgetFormBarGraph />;
            case 'pieChart':
                return <CreateAWidgetFormPieChart />;
            case 'sparkLine':
                return <CreateAWidgetFormSparkline />;
            case 'pieChartTable':
                return <CreateAWidgetFormPieChartTable />;
            case 'bigNumber':
                return <CreateAWidgetFormBigNumber />;
            case 'lineGraph':
                return <CreateAWidgetFormLineGraph />;
            case 'textBox':
                return <CreateAWidgetFormTextBox />;
            default:
                return null;
        }
    };

    // When the visualisation changes, reset the request related formValues
    const handleVisualisationChange = () => {
        resetField('groupBy');
        resetField('orderBy');
        resetField('fields');
        resetField('graphMetric');
        resetField('widgetColor');
        resetField('pieChartDisplay');
        resetField('aggregation');
        resetField('formatting');
        resetField('applyRounding');
        resetField('roundingDecimals');
        resetField('interpolated');
        resetField('showFillColor');
        resetField('copy');
        resetField('buttonCopy');
        resetField('buttonHref');
        resetField('textJustify');
        resetField('fontSize');
    };

    const handleNavigate = (event: React.FormEvent): void => {
        event.preventDefault();
        navigate(`/${accountToken}/dashboard-builder/widget-library`);
    };

    return (
        <StyledContainer>
            <CreateAWidgetFormContextProvider>
                <StyledFormContainer>
                    <StyledBackButtonLargeScreen onClick={handleNavigate}>
                        <StyledIconArrow />
                        Widget Library
                    </StyledBackButtonLargeScreen>
                    <Form.Body direction="column">
                        <Form.Section>
                            <Form.SectionTitle>Widget Settings</Form.SectionTitle>
                            <Form.Field>
                                <Form.InputLabel htmlFor="visualisation" label="Visualisation" />
                                <Form.InputSelect
                                    name="visualisation"
                                    options={visualisationDropdownOptions || []}
                                    placeholder="Please select a visualisation"
                                    validators={[validators.required]}
                                    onValueChange={handleVisualisationChange}
                                />
                            </Form.Field>

                            <Form.Field>
                                <Form.InputLabel htmlFor="widgetName" label="Widget Name" />
                                <Form.InputText
                                    name="widgetName"
                                    placeholder="Enter a name for your widget"
                                    validators={[validators.required, validators.maxLength(100)]}
                                />
                            </Form.Field>
                        </Form.Section>
                        {renderVisualisationForm()}
                    </Form.Body>
                    <Form.Footer>
                        <Form.InputButton value="Reset" type="reset" buttonTheme={ButtonThemes.Secondary} />
                        <Form.InputButton value="Save Widget" type="submit" />
                    </Form.Footer>
                </StyledFormContainer>
            </CreateAWidgetFormContextProvider>
            <CreateAWidgetPreview />
            <StyledBackButtonSmallScreen onClick={handleNavigate}>
                <StyledIconArrow />
                Widget Library
            </StyledBackButtonSmallScreen>
        </StyledContainer>
    );
};

export default CreateAWidgetForm;
