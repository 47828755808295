/* eslint-disable import/no-anonymous-default-export */

export default {
    DATE: 1,
    TIME: 2,
    DATE_TIME: 3,
    NUMBER: 4,
    FLOAT: 5,
    STRING: 6,
    DURATION: 7,
    MONEY: 8,
    BOOL: 9,
    SELECTOR: 10,
};
