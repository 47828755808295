import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import Request from '../../helpers/request';

let request = new Request();

export const getRequest = createAsyncThunk('request/getRequest', async (requestConfig: any, { dispatch }) => {
    async function getRequest() {
        let response;

        if (typeof requestConfig === 'string') {
            response = request.getUrl(requestConfig);
        } else if (requestConfig.resourceGroup) {
            response = request.get(
                requestConfig.resourceGroup,
                requestConfig.resourceName,
                requestConfig.resourceParams
            );
        } else if (typeof requestConfig[0] === 'string') {
            response = request.allUrl(requestConfig);
        } else if (typeof requestConfig[0] === 'object') {
            response = request.all(requestConfig);
        }

        return response;
    }

    const data: any = await getRequest();

    if (data) {
        let returnData;
        if (data.length !== undefined) {
            returnData = data.map((responseData: any) => {
                return responseData.data;
            });
        } else {
            returnData = data.data;
        }
        return returnData;
    } else if (data.error) {
        return isRejectedWithValue(data.error);
    }
});

export interface RequestState {
    isLoading: null | boolean;
    hasError: null | boolean;
    data: [];
}

const initialState = {
    isLoading: null,
    hasError: null,
    data: [],
} as RequestState;

const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        delRequest(state) {
            state.isLoading = null;
            state.hasError = null;
            state.data = [];
        },
        cancelRequest(state) {
            request.cancelRequest('Request has been cancelled');
            state.isLoading = null;
            state.hasError = null;
            state.data = [];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getRequest.pending, state => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(getRequest.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                state.hasError = false;
            })
            .addCase(getRequest.rejected, state => {
                state.isLoading = false;
                state.hasError = true;
                state.data = [];
            });
    },
});

export const { delRequest, cancelRequest } = requestSlice.actions;
export default requestSlice.reducer;
