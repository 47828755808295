/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import LayoutContextProvider from '../context/layout-context';

// Types
import { CubedField } from '../../../../types';
import {
    DropdownOption,
    SelectOptionsRequest,
    RequestFilter,
    RequestStatus,
    WidgetMenuSectionMenu,
    WidgetMenuType,
} from '../../../types';

// Component
import WidgetMenuSectionSelect from '../components/section-menu/widget-menu-section-select';

type UseWidgetMenuSectionSelectArgs = {
    dropdownRequestOption: SelectOptionsRequest;
    dropdownField: CubedField;
    includeAllOption?: boolean;
    selectedOverride?: string;
    emptyMessage?: string;
    label?: string;
    customIcon?: React.ReactNode;
    menuContentAlign?: 'start' | 'end';
    responsive?: boolean;
};

const useWidgetMenuSectionSelect = ({
    dropdownRequestOption,
    dropdownField,
    includeAllOption,
    selectedOverride,
    emptyMessage,
    label,
    customIcon,
    menuContentAlign = 'end',
    responsive,
}: UseWidgetMenuSectionSelectArgs): WidgetMenuSectionMenu => {
    const [dropdownOptions, setDropdownOptions] = useState<SelectOptionsRequest>({
        status: RequestStatus.LOADING,
        options: [],
    });
    const [selectedOption, setSelectedOption] = useState<DropdownOption>();
    const [filters, setFilters] = useState<RequestFilter[]>([]);

    const dropdownResource = dropdownRequestOption;

    useEffect(() => {
        if (dropdownResource.options.length > 0) {
            const allOption = {
                label: `All ${label || dropdownField.displayName}s`,
                value: 'all',
            } as DropdownOption;

            if (includeAllOption) {
                setDropdownOptions({
                    ...dropdownResource,
                    options: [allOption, ...dropdownResource.options],
                });
                setSelectedOption(allOption);
            } else {
                setDropdownOptions(dropdownResource);
                setSelectedOption(dropdownResource.options[0]);
            }
        } else {
            setDropdownOptions(dropdownResource);
            setSelectedOption(undefined);
        }
    }, [dropdownResource.status, dropdownResource.options]);

    useEffect(() => {
        if (selectedOption && selectedOption.value !== 'all') {
            setFilters([{ field: dropdownField, value: selectedOption.label }]);
        } else {
            setFilters([]);
        }
    }, [selectedOption]);

    // If there is a selected override, set it as the selected option
    useEffect(() => {
        if (selectedOverride) {
            if (dropdownOptions) {
                const selectedItem = dropdownOptions.options.find(object => object.label === selectedOverride);

                if (selectedItem) {
                    setSelectedOption(selectedItem);
                }
            }
        }
    }, [dropdownOptions, selectedOverride]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeSelectedOption = (selectedOption: string) => {
        setSelectedOption(dropdownOptions.options.find(option => option.value === selectedOption));
    };

    return {
        type: WidgetMenuType.SELECT,
        request: {
            sectionFilters: filters,
            enabled: dropdownOptions.status === RequestStatus.EMPTY || !!selectedOption,
        },
        menu: (
            <LayoutContextProvider>
                <WidgetMenuSectionSelect
                    label={label || dropdownField.displayName}
                    dropdownOptions={dropdownOptions}
                    selectedOption={selectedOption}
                    onOptionChange={handleChangeSelectedOption}
                    emptyMessage={emptyMessage || 'No options available'}
                    customIcon={customIcon}
                    menuContentAlign={menuContentAlign}
                    responsive={responsive}
                />
            </LayoutContextProvider>
        ),
        status: dropdownOptions.status,
    };
};

export default useWidgetMenuSectionSelect;
