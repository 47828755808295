import React from 'react';
import numeral from 'numeral';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const StyledLabel = styled.div`
    font-size: 11px;
    font-style: italic;
    display: inline-block;
    margin-left: 12px;
    color: ${props => props.theme.colours.textDefault};
`;

const StyledDetails = styled.div`
    ${props => props.theme.boxShadow(1)};
    color: ${props => props.theme.colours.textDefault};
    border: 1px solid red;
    padding: 6px;
    margin: 6px;
    background-color: $white;
`;

const StyledEvent = styled.div`
    margin-bottom: 12px;
`;

const StyledEventName = styled.div`
    margin: 0 6px;
    padding: 12px 24px;
    border: 2px solid ${props => props.theme.colours.offBlackLighter};
    display: inline-block;
    font-weight: 600;
    color: ${props => props.theme.colours.offBlackLighter};
`;

const StyledDetailsContainer = styled.div`
    display: inline-block;
    margin: 6px;
    padding: 6px;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    background-color: ${props => props.theme.colours.borderGrey};
`;

const StyledSale = styled.div`
    border: 2px solid ${props => props.theme.colours.success};
    width: 100%;
`;

const StyledSaleTitle = styled.div`
    color: ${props => props.theme.colours.light};
    background-color: ${props => props.theme.colours.success};
    width: 100%;
    padding: 12px 24px;
`;

const StyledRevenueTransaction = styled.div`
    padding: 12px 18px;
`;

const StyledRevenueTransactionTitle = styled.div`
    font-weight: 600;
    color: ${props => props.theme.colours.success};
`;

const Event = ({ event }) => {
    const currencySymbol = useSelector(state => state.currency.symbol);
    const labels = event.labels.map((label, index) => {
        const typeLookup = ['int', 'float', 'string', 'datetime', 'bool'];
        return <StyledLabel key={index}>{`${label.name}: ${label[typeLookup[label.type] + '_value']}`}</StyledLabel>;
    });
    const discount = event.discounts.map((discount, index) => {
        return (
            <StyledDetails>
                {discount.name && <div>{`Name: ${discount.name}`}</div>}
                {discount.code && <div>{`Code: ${discount.code}`}</div>}
                {discount.price && (
                    <div>{`Savings: ${currencySymbol + numeral(discount.price / 100).format('0,0[.]00')}`}</div>
                )}
            </StyledDetails>
        );
    });
    const shipping = event.shippings.map((shipping, index) => {
        return (
            <StyledDetails>
                {shipping.name && <div>{`Name: ${shipping.name}`}</div>}
                {shipping.code && <div>{`Code: ${shipping.code}`}</div>}
                {shipping.price && (
                    <div>{`Cost: ${currencySymbol + numeral(shipping.price / 100).format('0,0[.]00')}`}</div>
                )}
            </StyledDetails>
        );
    });
    const details = event.details.map((detail, index) => {
        return (
            <StyledDetails>
                {detail.name && <div>{`Name: ${detail.name}`}</div>}
                {detail.category && <div>{`Category: ${detail.category}`}</div>}
                {detail.quantity && <div>{`Quantity: ${detail.quantity}`}</div>}
                {detail.price && (
                    <div>{`Price: ${currencySymbol + numeral(detail.price / 100).format('0,0[.]00')}`}</div>
                )}
            </StyledDetails>
        );
    });
    if (event.sales.length < 1) {
        return (
            <StyledEvent>
                <StyledEventName>
                    <div>
                        <span>{event.event.name}</span>
                        {labels}
                    </div>
                    {event.shippings && event.shippings.length > 0 && (
                        <StyledDetailsContainer>{shipping}</StyledDetailsContainer>
                    )}
                    {event.discounts && event.discounts.length > 0 && (
                        <StyledDetailsContainer>{discount}</StyledDetailsContainer>
                    )}
                    {event.details && event.details.length > 0 && (
                        <StyledDetailsContainer>{details}</StyledDetailsContainer>
                    )}
                </StyledEventName>
            </StyledEvent>
        );
    } else {
        return event.sales.map((sale, index) => {
            return (
                <StyledSale key={index}>
                    <StyledSaleTitle>
                        <span>{sale.product.name}</span>
                        <span>{labels}</span>
                    </StyledSaleTitle>
                    {!!sale.revenue && (
                        <StyledRevenueTransaction>
                            <StyledRevenueTransactionTitle>Revenue: </StyledRevenueTransactionTitle>
                            <span>{currencySymbol + numeral(sale.revenue / 100).format('0,0[.]00')}</span>
                        </StyledRevenueTransaction>
                    )}
                    {!!sale.transaction_id && (
                        <StyledRevenueTransaction>
                            <StyledRevenueTransactionTitle>Transaction ID: </StyledRevenueTransactionTitle>
                            <span>{sale.transaction_id}</span>
                        </StyledRevenueTransaction>
                    )}
                    {event.shippings && event.shippings.length > 0 && (
                        <StyledDetailsContainer>{shipping}</StyledDetailsContainer>
                    )}
                    {event.discounts && event.discounts.length > 0 && (
                        <StyledDetailsContainer>{discount}</StyledDetailsContainer>
                    )}
                    {event.details && event.details.length > 0 && (
                        <StyledDetailsContainer>{details}</StyledDetailsContainer>
                    )}
                </StyledSale>
            );
        });
    }
};

export default Event;
