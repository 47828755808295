import React, { useContext, useEffect, useState } from 'react';
import { config } from '../configurations/segmenter-dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { setDescription, setTitle } from '../../redux/actions/page-meta';
import styled from 'styled-components';
import { useLocation } from 'react-router';

// Components
import Message from '../../components/message';
import SectionDashboard from '../../section-dashboard/components/section-dashboard';
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';

const StyledErrorContainer = styled.div`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    width: 100%;
`;

const SegmenterDashboard = () => {
    const dispatch = useDispatch();
    const { setFilterStatus, setFilterMetricsOptions, setDatePickerConfig } = useContext(FilterBarContext);

    const location = useLocation();
    location.isSectionDashboard = true;

    const { startDate, endDate } = useSelector(state => state.date);
    const [showDateError, setShowDateError] = useState(false);

    useEffect(() => {
        dispatch(setTitle(config.title));
        dispatch(setDescription(config.description));

        // Filter bar configurations
        setFilterStatus(config.filters.status);
        setFilterMetricsOptions([]); // reset metrics
        setDatePickerConfig(config.filters.datePicker);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setShowDateError(startDate.format('L') !== endDate.format('L'));
    }, [startDate, endDate]);

    if (showDateError) {
        return (
            <StyledErrorContainer>
                <Message
                    title="Date Error"
                    copy="Please select a single day at a time to view segment data"
                    type="error"
                />
            </StyledErrorContainer>
        );
    }

    return <SectionDashboard config={config} />;
};

export default SegmenterDashboard;
