import React from 'react';
import useFetchResource from '../../../../../react-query/hooks/use-fetch-resource';
import { CONFIG_REGION_LIST } from '../../../../../configurations/resources-config';
import { ConfigDataSuccess } from '../../../../../react-query/types';
import Form from '../../../../../components/forms/form';

const FormFieldCity = ({ country, fieldId, index }: { country: string; fieldId: string; index: number }) => {
    const regionListCitiesQuery = useFetchResource({
        resource: CONFIG_REGION_LIST,
        params: [
            { key: 'geolocation__country_name', value: country },
            { key: 'group_by', value: 'geolocation__city_name' },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.geolocation__city_name.value,
                    value: object.geolocation__city_name.value,
                };
            });
        },
    });

    if (country) {
        return (
            <Form.InputSelect
                key={fieldId}
                name={`regions.${index}.city`}
                options={regionListCitiesQuery?.data || []}
                placeholder={regionListCitiesQuery.isLoading ? 'Loading cities...' : 'Please select a city'}
            />
        );
    }

    return null;
};

export default FormFieldCity;
