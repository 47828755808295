import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Layout } from 'react-grid-layout';

// Redux
import { useDispatch } from 'react-redux';
import { setTitle } from '../../../redux/actions/page-meta';

// Context
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

// Components
import LayoutCreateADashboardTopBar from './layout-create-a-dashboard-top-bar';
import LayoutCreateADashboardSidebar from './layout-create-a-dashboard-sidebar';
import LayoutCreateADashboardPreview from './layout-create-a-dashboard-preview';
import LayoutLoading from '../../layouts/layout-loading';
import LayoutEmpty from '../../layouts/layout-empty';
import IconDisplaySlash from '../../../components/icons/display-slash';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const StyledBuilderContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

const StyledSaveOverlay = styled.div<{ visible: boolean }>`
    display: ${props => (props.visible ? 'block' : 'none')};
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 52; // Overlap all components;
`;

const StyledIconDisplaySlash = styled(IconDisplaySlash)`
    width: 40px;
`;

const LayoutCreateADashboard = () => {
    const dispatch = useDispatch();
    // Context
    const {
        dashboardQuery,
        widgetQuery,
        breakpoint,
        handleAddWidgetToSection,
        sections,
        setSections,
        handleReorderSections,
        showComparison,
        saveDashboardMutation,
    } = useContext(CreateADashboardContext);

    useEffect(() => {
        dispatch(setTitle('Create A Dashboard'));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleLayoutChange = (sectionId: string, newLayout: Layout[]) => {
        if (breakpoint) {
            setSections(
                sections.map(section => {
                    if (section.sectionId === sectionId) {
                        return {
                            ...section,
                            ...(!showComparison && {
                                layouts: {
                                    ...section.layouts,
                                    [breakpoint]: newLayout,
                                },
                            }),
                            ...(showComparison && {
                                comparisonLayouts: {
                                    ...section.comparisonLayouts,
                                    [breakpoint]: newLayout,
                                },
                            }),
                        };
                    } else {
                        return section;
                    }
                })
            );
        }
    };

    // Handle the drag and drop events
    const onDragEnd = (result: DropResult) => {
        if (
            result.source.droppableId === 'sectionList' &&
            result.destination &&
            result.destination.droppableId === 'sectionList'
        ) {
            handleReorderSections(result);
        }

        if (
            result.source.droppableId.split('-')[0] === 'widgetList' &&
            result.destination &&
            result.destination.droppableId !== 'sectionList' &&
            result.destination.droppableId.split('-')[0] !== 'widgetList'
        ) {
            handleAddWidgetToSection(result);
        }
    };

    if (dashboardQuery.isLoading) {
        return <LayoutLoading copy="Loading dashboard data..." />;
    }

    if (dashboardQuery.isSuccess && dashboardQuery.data.objects.length === 0) {
        return <LayoutEmpty icon={<StyledIconDisplaySlash />} copy="This dashboard could not be found." />;
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <StyledSaveOverlay visible={saveDashboardMutation.isPending} />
            <StyledContainer>
                {!widgetQuery.isLoading ? <LayoutCreateADashboardTopBar /> : null}
                <StyledBuilderContainer>
                    {!widgetQuery.isLoading ? <LayoutCreateADashboardSidebar /> : null}
                    <LayoutCreateADashboardPreview sections={sections} handleLayoutChange={handleLayoutChange} />
                </StyledBuilderContainer>
            </StyledContainer>
        </DragDropContext>
    );
};

export default LayoutCreateADashboard;
