/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import theme from '../../../styled-components/theme.styled';

// Fields & Resources
import * as fields from '../../../configurations/fields';
import * as resources from '../../../configurations/resources';

// Hooks
import { useDatesFormatted } from '../../../hooks/use-dates';
import useView from '../../../section-dashboard/hooks/view/use-view';
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourceSparkLine from '../../../section-dashboard/hooks/widget/use-resource-spark-line';
import useResourceTotal from '../../../section-dashboard/hooks/widget/use-resource-total';
import useResourceStackedAreaChart from '../../../section-dashboard/hooks/widget/use-resource-stacked-area-chart';
import useResourceBigNumber from '../../../section-dashboard/hooks/widget/big-number/use-resource-big-number';
import useResourceTable from '../../../section-dashboard/hooks/widget/table/use-resource-table';
import useResourcePieChartTable from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-chart-table';
import useResourceVennResource from '../../../section-dashboard/hooks/widget/venn/use-resource-venn-resource';
import useResourceVenn from '../../../section-dashboard/hooks/widget/venn/use-resource-venn';
import useRequestTable from '../../../section-dashboard/hooks/request/use-request-table';
import useResourcePieChart from '../../../section-dashboard/hooks/widget/pie/use-resource-pie-chart';
import useResourceFunnelChart from '../../../section-dashboard/hooks/widget/use-resource-funnel-chart';
import useRequestStackedAreaChart from '../../../section-dashboard/hooks/request/use-request-stacked-area-chart';
import useWidgetMenuFooterQuerySelect from '../../../section-dashboard/widgets/menus/hooks/use-widget-menu-footer-query-select';
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import useRequestPie from '../../../section-dashboard/hooks/request/use-request-pie';
import useRequestPieChartTable from '../../../section-dashboard/hooks/request/use-request-pie-chart-table';

// Types
import { FilterOperator, WidgetColour, WidgetMenuObj } from '../../../section-dashboard/types';

// Components
import BeforeGridConfiguredGoals from '../../../section-dashboard/components/sections/before-grid/before-grid-configured-goals';

// Widgets
import WidgetSparkLine from '../../../section-dashboard/widgets/widget/widget-spark-line';
import WidgetStackedAreaChart from '../../../section-dashboard/widgets/widget/widget-stacked-area-chart';
import WidgetTable from '../../../section-dashboard/widgets/widget/widget-table';
import WidgetBigNumber from '../../../section-dashboard/widgets/widget/widget-big-number';
import WidgetPieChartTable from '../../../section-dashboard/widgets/widget/widget-pie-chart-table';
import WidgetVenn from '../../../section-dashboard/widgets/widget/widget-venn';
import WidgetFunnelChart from '../../../section-dashboard/widgets/widget/widget-funnel-chart';
import WidgetPieChart from '../../../section-dashboard/widgets/widget/widget-pie-chart';
import WidgetMenuPie from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie';

// Widget Menus
import WidgetMenuTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';
import WidgetMenuStackedAreaChart from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-stacked-area-chart';
import WidgetMenuPieChartTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie-chart-table';

// Queries
import { channelInsightsTopChannelQuery } from '../../queries';
import {
    channelsDropdownQuery,
    defaultGoalsDropdownQuery,
    goalsDropdownQuery,
} from '../../../section-dashboard/queries';

export const config = {
    title: 'Channel Insights Dashboard',
    description: '', // TODO description
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableComparisonDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnablePdfExport: true,
        },
        comparisonStatus: {
            showComparisonDatePicker: false,
            redirectUrl: 'channel-insights-comparison-dashboard',
        },
        datePicker: {},
    },
    layouts: {
        xs: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a'
                    'b'
                    'c'
                    'd'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'f'
                    'q'
                    'g'
                    'h'
                    'h'
                    'r'
                    'i'
                    'j'
                    'k'
                    'l'
                    'm'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    grid: `
                    'o'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    grid: `
                    'p'
                    `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a b'
                    'c d'
                    'n n'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'f q'
                    'g g'
                    'h h'
                    'h h'
                    'r r'
                    'r r'                   
                    'i i'
                    'j j'
                    'k k'
                    'l l'
                    'l l'
                    'm m'
                    'm m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    grid: `
                    'o o'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    grid: `
                    'p p'
                    `,
                },
            ],
        },
        md: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b'
                    'c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'r h h f'
                    'r h h g'
                    'i q q j'
                    'k k k k'
                    'l l l l'
                    'l l l l'
                    'm m m m'
                    'm m m m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n n n n'
                    'n n n n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    grid: `
                    'o o o o'
                    'o o o o'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    grid: `
                    'p p p p'
                    'p p p p'
                    `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a a b b b'
                    'a a a b b b'
                    'c c c d d d'
                    'c c c d d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'r r h h f i'
                    'r r h h q j'
                    'r r h h g k'
                    'l l l m m m'
                    'l l l m m m'
                    'l l l m m m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n n n n n n'
                    'n n n n n n'
                    'n n n n n n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    grid: `
                    'o o o o o o'
                    'o o o o o o'
                    'o o o o o o'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    grid: `
                    'p p p p p p'
                    'p p p p p p'
                    'p p p p p p'
                    `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b c c d d'
                    'a a b b c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'r r h h h h f i'
                    'r r h h h h q j'
                    'r r h h h h g k'
                    'l l l l m m m m'
                    'l l l l m m m m'
                    'l l l l m m m m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n n n n n n n n'
                    'n n n n n n n n'
                    'n n n n n n n n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    grid: `
                    'o o o o o o o o'
                    'o o o o o o o o'
                    'o o o o o o o o'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    grid: `
                    'p p p p p p p p'
                    'p p p p p p p p'
                    'p p p p p p p p'
                    `,
                },
            ],
        },
    },
    components: () => {
        // Dates for Venn & Funnel Filters
        const { startDate, endDate } = useDatesFormatted({ resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN });

        // Fetch Top Channel for Period
        const topChannel = useFetchResource(channelInsightsTopChannelQuery({ startDate, endDate })).data;

        // Widget Links
        const links = {
            attributionExplorer: '/reports/attribution/lc-vs-attribution',
        };

        // Sparklines
        const goalSnapshotData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                isGraph: true,
                groupBy: fields.SALES_DATE_DATE,
                graphGroupBy: [fields.SALES_DATE_DATE],
                graphMetrics: [
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
                selectedFields: [
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
            },
        });

        const sparkTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                totals: true,
                selectedFields: [fields.VISITS, fields.COST, fields.FM_SALES, fields.FM_REVENUE],
            },
        });

        const sparkTotals = useResourceTotal({
            resourceData: sparkTotalData,
        });

        // Visits Sparkline
        const visitsSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.VISITS,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Sales Sparkline
        const salesSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.FM_SALES,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Revenue Sparkline
        const revenueSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.FM_REVENUE,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Cost Sparkline
        const costSpark = useResourceSparkLine({
            resourceData: goalSnapshotData,
            metric: fields.COST,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Big Numbers
        const goalSnapshotDefaultGoalTotalsData = (menu: WidgetMenuObj) =>
            useResource({
                resource: resources.REPORT_MCS_CHANNEL,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                    ...menu.request,
                    filters: [
                        ...(menu.request.filters ?? []),
                        { field: fields.PRODUCT_DEFAULT, operator: FilterOperator.EQUALS, value: '1' },
                    ],
                    selectedFields: [fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                },
            });

        // Default Goal Goals Completed
        const defaultGoalTotalGoalsBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalTotalGoalsBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalGoalsBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Default Goal Attributed Value
        const defaultGoalTotalRevenueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalTotalRevenueBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalRevenueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // Default Goal CR%
        const defaultGoalCRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalCRBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalCRBigNumberMenu),
            field: fields.FM_CVR,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        const goalSnapshotBigNumberData = (menu: WidgetMenuObj) =>
            useResource({
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [fields.VISITS, fields.FM_SALES, fields.FM_REVENUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
                    selectedFields: [fields.VISITS, fields.FM_SALES, fields.FM_REVENUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
                    ...menu.request,
                },
            });

        // Total Goals Completed
        const totalGoalsCompletedBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalGoalsCompletedBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalGoalsCompletedBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Attributed Value
        const totalAttributedValueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalAttributedValueBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalAttributedValueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // All Goals CR%
        const totalCVRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalCVRBigNumber = useResourceBigNumber({
            resourceData: goalSnapshotBigNumberData(totalCVRBigNumberMenu),
            field: fields.GOAL_CVR_GOAL_SNAPSHOT,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Goals Snapshot Pie
        const goalSnapshotGoalsPieRequest = useRequestPie({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
            selectedFields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE],
        });

        const goalSnapshotGoalsPieData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: goalSnapshotGoalsPieRequest,
        });

        let goalSnapshotPie = useResourcePieChart({
            resourceData: goalSnapshotGoalsPieData,
            dimension: fields.PRODUCT,
        });

        // Goal Snapshot Table
        const goalSnapshotTableRequest = useRequestTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
            selectedFields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
        });

        const goalSnapshotTableData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: goalSnapshotTableRequest,
        });

        const goalSnapshotTableTotalData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                ...goalSnapshotTableRequest,
                totals: true,
            },
        });

        const goalSnapshotTable = useResourceTable({
            resourceData: goalSnapshotTableData,
        });

        const goalSnapshotTableTotals = useResourceTotal({
            resourceData: goalSnapshotTableTotalData,
        });

        // Goal Completion Crossover Venn
        const goalCrossoverVennData = useResourceVennResource({
            dimensionResource: {
                resource: resources.REPORT_DASH_PRODUCT,
                request: {
                    ignoreDate: true,
                    groupBy: fields.PRODUCT_NAME,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                },
            },
            vennResource: {
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
                request: {
                    ignoreDate: true,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                    groupBy: fields.ID,
                    filters: [
                        { field: fields.START_DATETIME, operator: FilterOperator.EQUALS, value: startDate },
                        { field: fields.END_DATETIME, operator: FilterOperator.EQUALS, value: endDate },
                    ],
                },
            },
        });

        const goalCrossoverVenn = useResourceVenn({
            resourceData: goalCrossoverVennData,
            metric: fields.VISITORS,
            diagramTitle: 'Goal Completion Crossover',
        });

        // Goal Completion Complexity Funnel
        const goalCompletionComplexityFooterMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalCompletionComplexityFunnelData = useView({
            resource: resources.AGG_CUSTOMER_JOURNEY_COMPLEXITY,
            request: goalCompletionComplexityFooterMenu.request,
        });

        const goalCompletionComplexityFunnel = useResourceFunnelChart({
            resourceData: goalCompletionComplexityFunnelData,
            metric: fields.SALES,
            name: 'Goal Completion Complexity',
        });

        // Goal Completions Stacked Area Chart
        const goalCompletionStackedAreaChartRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Count of Completions', field: fields.FM_SALES, active: true },
                { name: 'Value of Completions', field: fields.FM_REVENUE, active: false },
            ],
            groupBy: fields.PRODUCT,
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.FM_SALES, orderByDirection: 'desc' },
            ],
            selectedFields: [fields.SALES_DATE_DATE, fields.PRODUCT, fields.FM_SALES, fields.FM_REVENUE],
        });

        const goalCompletionsData = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: goalCompletionStackedAreaChartRequest,
        });

        const goalCompletionsAdditionalSeries = useResource({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
            },
        });

        const goalCompletionStackedAreaChart = useResourceStackedAreaChart({
            areaSeriesMetric: fields.PRODUCT,
            resourceData: goalCompletionsData,
            dateDimension: fields.SALES_DATE_DATE,
            optionalSeries: [
                {
                    title: 'Unique Visits',
                    yAxis: fields.VISITS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.purple,
                },
                {
                    title: 'Unique Visitors',
                    yAxis: fields.VISITORS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.emerald,
                },
            ],
        });

        // Goal Completions - Summary by Channel
        const goalCompletionFooterMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
        });

        const goalCompletionRequest = useRequestPieChartTable({
            groupBy: fields.REFERER,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                fields.REFERER,
                fields.VISITS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.VISITS, fields.GOAL_VALUE, fields.GOAL_CVR],
            rowsPerPage: 10,
            ...goalCompletionFooterMenu.request,
            selectedFields: [
                fields.REFERER,
                fields.VISITS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
        });

        const goalCompletionData = useResource({
            resource: resources.REPORT_MCS_CHANNEL,
            request: goalCompletionRequest,
        });

        const goalCompletionByChannelTotalData = useResource({
            resource: resources.REPORT_MCS_CHANNEL,
            request: {
                ...goalCompletionRequest,
                totals: true,
            },
        });

        const goalCompletionDonutPieChartTable = useResourcePieChartTable({
            resourceData: goalCompletionData,
            chartDimension: fields.REFERER,
        });

        const goalCompletionDonutPieChartTableTotal = useResourceTotal({
            resourceData: goalCompletionByChannelTotalData,
        });

        // Channel Performance - Summary By Goal - Donut Table
        const channelPerformanceMenu = useWidgetMenuFooterQuerySelect({
            query: channelsDropdownQuery,
            dropdownField: fields.REFERER,
            selectedOverride: topChannel,
        });

        const channelPerformanceRequest = useRequestPieChartTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                fields.PRODUCT,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR],
            rowsPerPage: 10,
            ...channelPerformanceMenu.request,
            selectedFields: [
                fields.PRODUCT,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
        });

        const channelPerformanceData = useResource({
            resource: resources.REPORT_MCS_CHANNEL,
            request: channelPerformanceRequest,
        });

        const channelPerformanceTotalData = useResource({
            resource: resources.REPORT_MCS_CHANNEL,
            request: {
                ...channelPerformanceRequest,
                totals: true,
            },
        });

        const channelPerformanceDonutTable = useResourcePieChartTable({
            resourceData: channelPerformanceData,
            chartDimension: fields.PRODUCT,
        });

        const channelPerformanceDonutTableTotals = useResourceTotal({
            resourceData: channelPerformanceTotalData,
        });

        return {
            a: (
                <WidgetSparkLine
                    title="Visits by Day"
                    data={visitsSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Teal}
                />
            ),
            b: (
                <WidgetSparkLine
                    title="Sales by Day"
                    data={salesSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Orange}
                />
            ),
            c: (
                <WidgetSparkLine
                    title="Revenue by Day"
                    data={revenueSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Green}
                />
            ),
            d: (
                <WidgetSparkLine
                    title="Cost by Day"
                    data={costSpark}
                    totalData={sparkTotals}
                    href={links.attributionExplorer}
                    colour={WidgetColour.Rose}
                />
            ),
            f: (
                <WidgetBigNumber
                    title="Default Goal Goals Completed"
                    data={defaultGoalTotalGoalsBigNumber}
                    menu={defaultGoalTotalGoalsBigNumberMenu.menu}
                    href={links.attributionExplorer}
                />
            ),
            q: (
                <WidgetBigNumber
                    title="Default Goal Attributed Value"
                    data={defaultGoalTotalRevenueBigNumber}
                    menu={defaultGoalTotalRevenueBigNumberMenu.menu}
                    href={links.attributionExplorer}
                />
            ),
            g: (
                <WidgetBigNumber
                    title="Default Goal CR%"
                    data={defaultGoalCRBigNumber}
                    menu={defaultGoalCRBigNumberMenu.menu}
                    href={links.attributionExplorer}
                />
            ),
            h: (
                <WidgetTable
                    title="Goals Snapshot"
                    data={goalSnapshotTable}
                    totalData={goalSnapshotTableTotals}
                    menu={<WidgetMenuTable data={goalSnapshotTable} />}
                    href={links.attributionExplorer}
                />
            ),
            r: (
                <WidgetPieChart
                    title={'Goals Completed'}
                    data={goalSnapshotPie}
                    href={links.attributionExplorer}
                    display="donut"
                    menu={<WidgetMenuPie data={goalSnapshotPie} />}
                />
            ),
            i: (
                <WidgetBigNumber
                    title="Total Goals Completed"
                    data={totalGoalsCompletedBigNumber}
                    menu={totalGoalsCompletedBigNumberMenu.menu}
                    href={links.attributionExplorer}
                />
            ),
            j: (
                <WidgetBigNumber
                    title="Total Attributed Value"
                    data={totalAttributedValueBigNumber}
                    menu={totalAttributedValueBigNumberMenu.menu}
                    href={links.attributionExplorer}
                />
            ),
            k: (
                <WidgetBigNumber
                    title="All Goals CR%"
                    data={totalCVRBigNumber}
                    href={links.attributionExplorer}
                    menu={totalCVRBigNumberMenu.menu}
                />
            ),
            l: <WidgetVenn title="Goal Completion Crossover" data={goalCrossoverVenn} />,
            m: (
                <WidgetFunnelChart
                    title="Goal Completion Complexity"
                    data={goalCompletionComplexityFunnel}
                    menu={goalCompletionComplexityFooterMenu.menu}
                />
            ),
            n: (
                <WidgetStackedAreaChart
                    title="Goal Completions Over Time"
                    data={goalCompletionStackedAreaChart}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={goalCompletionStackedAreaChart}
                            request={goalCompletionStackedAreaChartRequest}
                        />
                    }
                    href={links.attributionExplorer}
                />
            ),
            o: (
                <WidgetPieChartTable
                    title={'Goal Completions - Summary by Channel'}
                    data={goalCompletionDonutPieChartTable}
                    totalData={goalCompletionDonutPieChartTableTotal}
                    href={links.attributionExplorer}
                    display="donut"
                    menu={
                        <>
                            {goalCompletionFooterMenu.menu}
                            <WidgetMenuPieChartTable data={goalCompletionDonutPieChartTable} />
                        </>
                    }
                />
            ),
            p: (
                <WidgetPieChartTable
                    title={'Channel Performance - Summary by Goal'}
                    data={channelPerformanceDonutTable}
                    totalData={channelPerformanceDonutTableTotals}
                    display="donut"
                    menu={
                        <>
                            {channelPerformanceMenu.menu}
                            <WidgetMenuPieChartTable data={channelPerformanceDonutTable} />
                        </>
                    }
                    href={links.attributionExplorer}
                />
            ),
        };
    },
};
