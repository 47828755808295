// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import AnimeJS from 'animejs';

// Redux Actions
import { setModal, updateModalConfig } from '../redux/actions/modal';

// Import Components
import List from '../components/list';
import SidebarMenu from '../components/sidebar-menu';
import SearchBreadcrumbsWidget from '../breadcrumbs/widgets/search-breadcrumbs';
import { setDescription } from '../redux/actions/page-meta';

class LayoutAccountManagement extends Component {
    constructor(props) {
        super(props);

        this.listComponentOnClickHandler = this.listComponentOnClickHandler.bind(this);

        const accountConfigurations = [
            {
                header: {
                    type: 'Title',
                    title: 'Documentation',
                    keyValue: 'documentation',
                },
                type: 'List',
                keyValue: 'documentation',
                rows: [
                    {
                        keyValue: 'documentation_tagging-guide',
                        type: 'ListRowTitleCopyLink',
                        title: 'Tagging Implementation Guide',
                        copy: 'A get started Tagging Guide on how to implement the Cubed Tag on your website.',
                        external: false,
                        link: '/' + this.props.account.token + '/tagging-guide',
                        icon: true,
                    },
                    {
                        keyValue: 'documentation_tag',
                        type: 'ListRowTitleCopyLink',
                        title: 'Tag Documentation',
                        copy: 'General documentation about how to set up the Cubed tag on your website. ',
                        external: true,
                        link: 'http://tag.docs.withcubed.com/',
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        icon: true,
                        onClick: this.listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'documentation_impression',
                        type: 'ListRowTitleCopyLink',
                        title: 'Impression Documentation',
                        copy: 'General documentation about how to set up the Third Party impression hits to the Cubed Platform.',
                        external: true,
                        link: 'http://tag.docs.withcubed.com/impression/',
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        icon: true,
                        onClick: this.listComponentOnClickHandler,
                    },
                    {
                        keyValue: 'documentation_onboarding',
                        type: 'ListRowTitleCopyLink',
                        title: 'Onboarding Documentation',
                        copy: 'Onboarding documentation including setting up goals, events and APIs.',
                        external: true,
                        link: 'http://tag.docs.withcubed.com/onboarding/general/',
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        icon: true,
                        onClick: this.listComponentOnClickHandler,
                    },
                    // {
                    //     keyValue: 'documentation_impression-snippet',
                    //     type: 'ListRowTitleCopy',
                    //     title: 'Impression Snippets',
                    //     copy: 'Sample snippets of code to be used for firing impressions to the Cubed Platform',
                    //     chevron: true,
                    //     onClick: this.listComponentOnClickHandler
                    // },
                ],
            },
        ];

        this.state = {
            listErrorMessageOverride:
                'The search term you have entered does not match with any of the Account Management.',
            visibleAccountConfigurations: accountConfigurations,
            allAccountConfigurations: accountConfigurations,
            searchValue: '',
        };

        this.sideBarMenuOnClickHandler = this.sideBarMenuOnClickHandler.bind(this);
        this.searchBarOnChange = this.searchBarOnChange.bind(this);
        this.breadCrumbHomeOnClickHandler = this.breadCrumbHomeOnClickHandler.bind(this);
    }

    listComponentOnClickHandler(event) {
        switch (event.currentTarget.getAttribute('data-key')) {
            case 'account-health_view-health':
                this.props.setModal('AccountHealth', {});
                break;
            case 'documentation_impression-snippet':
                this.props.setModal('ImpressionSnippets', {});
                break;
            default:
                break;
        }
    }

    sideBarMenuOnClickHandler(event) {
        event.stopPropagation();

        const selectedKey = event.currentTarget.getAttribute('data-key');

        if (selectedKey !== '') {
            const itemInList = document.querySelector(`.list-widget [data-key="${selectedKey}"]`);
            const documentationDataKey = itemInList.querySelector(`[data-key="${selectedKey}"]`);
            const items = documentationDataKey != null ? documentationDataKey : itemInList;
            const itemInListPosition = itemInList.offsetTop;

            const scrollElement =
                window.document.scrollingElement || window.document.body || window.document.documentElement;

            AnimeJS({
                targets: scrollElement,
                scrollTop: itemInListPosition,
                duration: 500,
                easing: 'easeInOutQuad',
            });
            if (this.state.prevItem) {
                this.state.prevItem.style.backgroundColor = 'white'; // eslint-disable-line react/no-direct-mutation-state
                AnimeJS.remove(this.state.prevItem);
            }

            AnimeJS({
                targets: items,
                backgroundColor: ['#FF7300', '#e6e6e6'],
                duration: 1500,
                easing: 'easeInOutQuad',
            });
            this.setState({ prevItem: items });
        }
    }

    breadCrumbHomeOnClickHandler() {
        this.setState({
            visibleAccountConfigurations: this.state.allAccountConfigurations,
            searchValue: '',
        });
    }

    filterSearchResults(item, searchValue) {
        return item.header.title.includes(searchValue);
    }

    searchBarOnChange(e) {
        const searchValue = e.target.value;
        const searchValueRegex = new RegExp(searchValue, 'i');
        const visibleConfigsAfterSearch = [];

        this.state.allAccountConfigurations.forEach(item => {
            const rowsWithSearchTerm = item.rows
                .filter(row => {
                    return searchValueRegex.test(row.title) || searchValueRegex.test(row.copy);
                })
                .map(row => {
                    return row;
                });

            if (rowsWithSearchTerm.length > 0 || item.header.title.includes(e.target.value)) {
                visibleConfigsAfterSearch.push({
                    header: item.header,
                    type: item.type,
                    keyValue: item.key,
                    rows: rowsWithSearchTerm,
                });
            }
        });

        this.setState({
            searchValue: searchValue,
            visibleAccountConfigurations: visibleConfigsAfterSearch,
        });
    }

    componentDidMount() {
        this.props.setDescription('');
    }

    render() {
        return (
            <div className="account-management">
                <div className="account-management__sidebar-container">
                    <div className="account-management__side-bar">
                        <SearchBreadcrumbsWidget
                            onSearchChange={this.searchBarOnChange}
                            homeOnClick={this.breadCrumbHomeOnClickHandler}
                            searchValue={this.state.searchValue}
                        />
                        <SidebarMenu
                            rows={this.state.visibleAccountConfigurations}
                            onClick={this.sideBarMenuOnClickHandler}
                        />
                    </div>
                </div>
                <div className="account-management__list">
                    <List
                        rows={this.state.visibleAccountConfigurations}
                        header={{}}
                        errorMessageOverride={this.state.listErrorMessageOverride}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        modal: state.modal,
        account: state.account,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        updateModalConfig: config => {
            dispatch(updateModalConfig(config));
        },
        setDescription: description => {
            dispatch(setDescription(description));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAccountManagement);
