export const isValidUrl = url => {
    try {
        new URL(url);
        const urlConfig = new URL(url);
        const diff = urlConfig.href.split(url).join('');
        const newUrl = `${url}${diff}`;
        if (newUrl !== urlConfig.href) return false;
    } catch (e) {
        return false;
    }

    return true;
};
