import React, { useContext, useEffect } from 'react';
import CustomerExplorer from './customer-explorer';
import CustomerExplorerProvider from '../context/customer-explorer-context';
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';

const CustomerExplorerLayout = ({
    status,
    history,
    location,
    startDate,
    endDate,
    products,
    selectedProducts,
    selectedChannelData,
    showProductDropdown,
    setOwnerState,
}) => {
    const { setFilterStatus } = useContext(FilterBarContext);

    useEffect(() => {
        setFilterStatus({ isEnableChannelMultiSelect: true });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CustomerExplorerProvider
            status={status}
            history={history}
            location={location}
            startDate={startDate}
            endDate={endDate}
            products={products}
            selectedProducts={selectedProducts}
            selectedChannelData={selectedChannelData}
            showProductDropdown={showProductDropdown}
            setOwnerState={setOwnerState}
        >
            <CustomerExplorer />
        </CustomerExplorerProvider>
    );
};

export default CustomerExplorerLayout;
