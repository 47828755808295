export function setUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    return {
        type: 'SET_USER',
        payload: user,
    };
}

export function updateUser(user) {
    return {
        type: 'SET_USER',
        payload: user,
    };
}

export function setUserAccounts(accounts) {
    return {
        type: 'UPDATE_ACCOUNTS',
        payload: {
            accounts: accounts,
        },
    };
}

export function loadUser(localUser) {
    return {
        type: 'SET_USER',
        payload: localUser,
    };
}

export function removeUser() {
    return {
        type: 'DEL_USER',
    };
}
