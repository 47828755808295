import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Types
import { CubedField } from '../../../../types';
import DataTypes from '../../../../filter-bar/enums/data-types';

// Components
import DialogDropdown from './form-fields/dialog/dialog-dropdown';
import DialogInputText from './form-fields/dialog/dialog-input-text';
import IconTimes from '../../../../components/icons/times';
import DialogInputNumber from './form-fields/dialog/dialog-input-number';
import { DropdownOption } from '../../../types';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    padding: 20px;
`;

const StyledLabel = styled.div`
    margin-bottom: 5px;
`;

const StyledFormContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const StyledIs = styled.div`
    line-height: 2.5;
`;

const StyledIconContainer = styled.div<{ disabled: boolean }>`
    flex: 0 0 15px;
    margin-top: 10px;
    cursor: ${props => (props.disabled ? 'auto' : 'pointer')};

    & svg path {
        fill: ${props =>
            props.disabled
                ? props.theme.sectionDashboard.widget.menu.separatorColor
                : props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

export type WidgetDialogFormFilterRowProps = {
    options: CubedField[];
    id: number;
    field?: CubedField;
    operator?: string;
    operatorOptions?: DropdownOption[];
    value?: string;
    onUpdateFilterField: (id: number, value: DropdownOption) => void;
    onUpdateFilterOperator: (id: number, value: DropdownOption) => void;
    onUpdateBooleanDropdown: (id: number, value: DropdownOption) => void;
    onUpdateInputValue: (id: number, value: string) => void;
    onRemoveFilter: (id: number) => void;
};

const WidgetDialogFormFilterRow = ({
    options,
    id,
    field,
    operator,
    operatorOptions,
    value,
    onUpdateFilterField,
    onUpdateFilterOperator,
    onUpdateBooleanDropdown,
    onUpdateInputValue,
    onRemoveFilter,
}: WidgetDialogFormFilterRowProps) => {
    const dropdownOptions = options.map(option => {
        return {
            label: option.displayName,
            value: option.rawName,
        };
    });

    const [selectedField, setSelectedField] = useState<null | DropdownOption>();
    const [selectedOperator, setSelectedOperator] = useState<null | DropdownOption>();
    const [selectedBooleanValue, setSelectedBooleanValue] = useState<null | DropdownOption>();
    const [inputValue, setInputValue] = useState<string | undefined>('');

    useEffect(() => {
        if (field) {
            setSelectedField(dropdownOptions.filter(option => option.value === field.rawName)[0]);
        }
    }, [field]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (operator && operatorOptions) {
            if (field?.dataType === DataTypes.BOOL) {
                setSelectedBooleanValue(operatorOptions.filter(option => option.value === value)[0]);
            } else {
                setSelectedOperator(operatorOptions.filter(option => option.value === operator)[0]);
            }
        }
    }, [operator, operatorOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleUpdateField = (value: DropdownOption) => {
        if (value) {
            onUpdateFilterField(id, value);
        }
    };

    const handleUpdateOperator = (value: DropdownOption) => {
        if (value) {
            onUpdateFilterOperator(id, value);
        }
    };

    const handleUpdateBooleanDropdown = (value: DropdownOption) => {
        if (value) {
            onUpdateBooleanDropdown(id, value);
        }
    };

    const handleUpdateInputValue = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLButtonElement;
        const value = target.value;
        onUpdateInputValue(id, value);
    };

    const handleRemoveFilter = () => {
        onRemoveFilter(id);
    };

    return (
        <StyledContainer>
            <StyledLabel>Column</StyledLabel>
            <StyledFormContainer>
                <DialogDropdown
                    options={dropdownOptions}
                    selected={selectedField}
                    placeholder={'Select a column'}
                    onChange={handleUpdateField}
                />
                {field?.dataType === DataTypes.BOOL && (
                    <>
                        <StyledIs>is</StyledIs>
                        <DialogDropdown
                            options={operatorOptions || []}
                            selected={selectedBooleanValue}
                            placeholder={'Select an operator'}
                            onChange={handleUpdateBooleanDropdown}
                        />
                    </>
                )}
                {field?.dataType !== DataTypes.BOOL && (
                    <DialogDropdown
                        options={operatorOptions || []}
                        selected={selectedOperator}
                        placeholder={'Select an operator'}
                        isDisabled={!selectedField}
                        onChange={handleUpdateOperator}
                    />
                )}
                {field?.dataType === DataTypes.STRING && (
                    <DialogInputText value={inputValue} placeholder={'Value'} onChange={handleUpdateInputValue} />
                )}
                {(field?.dataType === DataTypes.NUMBER ||
                    field?.dataType === DataTypes.FLOAT ||
                    field?.dataType === DataTypes.MONEY) && (
                    <DialogInputNumber value={inputValue} onChange={handleUpdateInputValue} />
                )}
                <StyledIconContainer disabled={id === 1 && !selectedField} onClick={handleRemoveFilter}>
                    <IconTimes />
                </StyledIconContainer>
            </StyledFormContainer>
        </StyledContainer>
    );
};

export default WidgetDialogFormFilterRow;
