import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import validators from '../../../../components/forms/validation/validators';

// Redux
import { useDispatch } from 'react-redux';
import { removePopup, setPopup } from '../../../../redux/actions/popup';
import { setModal } from '../../../../redux/actions/modal';
import { addNotification } from '../../../../redux/actions/notification';

// Hooks

import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import useDeleteResource from '../../../../react-query/hooks/use-delete-resource';
import usePatchMultiResourceWithPayload from '../../../../react-query/hooks/use-patch-multi-resource-with-payload';

// Config
import { EXCLUDE_DATES_CONFIG } from '../../../../configurations/resources-config';

// Types & Enums
import { ConfigDataSuccess } from '../../../../react-query/types';
import { ButtonThemes } from '../../../../enums/button-themes';
import { FieldValues } from 'react-hook-form';

// Components
import ConfigTable from '../../../../components/tables/config-table/config-table';
import Form from '../../../../components/forms/form';

const StyledH2 = styled.h2`
    font-size: 1.1rem;
`;

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

type ExcludeDate = {
    id: number;
    description: string;
    startDate: string;
    endDate: string;
    selected: boolean;
};

const LayoutDateExclusions = () => {
    const dispatch = useDispatch();

    const [excludeDates, setExcludeDates] = useState<ExcludeDate[]>([]);
    const [excludeDatesToDelete, setExcludeDatesToDelete] = useState<number[]>([]);
    const [excludeDateToEdit, setExcludeDateToEdit] = useState<ExcludeDate>();
    const [showEditForm, setShowEditForm] = useState(false);

    const excludeDateQuery = useFetchResource({
        resource: EXCLUDE_DATES_CONFIG,
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    id: object.id,
                    description: object.description,
                    startDate: moment(object.start_date).format('YYYY-MM-DD'),
                    endDate: moment(object.end_date).format('YYYY-MM-DD'),
                    selected: false,
                };
            });
        },
    });

    useEffect(() => {
        if (excludeDateQuery.data) {
            setExcludeDates(excludeDateQuery.data);
        }
    }, [excludeDateQuery.data]);

    const excludeDateDeleteMutation = useDeleteResource({
        resource: EXCLUDE_DATES_CONFIG,
        resourceIds: excludeDatesToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            setExcludeDatesToDelete([]);
            setExcludeDates(
                excludeDates.map(excludeDate => {
                    return {
                        ...excludeDate,
                        selected: false,
                    };
                })
            );
            dispatch(addNotification({ copy: 'Date exclusion successfully deleted', type: 'success' }));
        },
        handleOnError: () => {
            setExcludeDatesToDelete([]);
            setExcludeDates(
                excludeDates.map(excludeDate => {
                    return {
                        ...excludeDate,
                        selected: false,
                    };
                })
            );
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting, please try again later or contact support@cubed.email',
                    type: 'error',
                })
            );
        },
    });

    const excludeDatePatchMutation = usePatchMultiResourceWithPayload({
        resource: EXCLUDE_DATES_CONFIG,
        handleOnSuccess: () => {
            setShowEditForm(false);
            dispatch(addNotification({ copy: 'Date exclusion successfully updated', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while updating, please try again later or contact support@cubed.email',
                    type: 'error',
                })
            );
        },
    });

    const handleCheckboxChange = (rowId: number) => {
        const updatedExcludeDates = excludeDates.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    selected: !row.selected,
                };
            }
            return row;
        });
        setExcludeDates(updatedExcludeDates);

        const excludeDatesToDelete: number[] = [];
        updatedExcludeDates.forEach(row => {
            if (row.selected) {
                excludeDatesToDelete.push(row.id);
            }
        });
        setExcludeDatesToDelete(excludeDatesToDelete);
    };

    const handleSingleDeleteClick = (rowId: number) => {
        setExcludeDatesToDelete([rowId]);

        const handleDisardClick = () => {
            setExcludeDatesToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            excludeDateDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this date exclusion?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleBulkDeleteClick = () => {
        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            excludeDateDeleteMutation.mutate();
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected date exclusions?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleEditClick = (rowId: number) => {
        let dateExclusion = excludeDates.find(date => date.id === rowId);

        if (dateExclusion) {
            setExcludeDateToEdit(dateExclusion);
            setShowEditForm(true);
        }
    };

    const handleFormSubmit = (data: FieldValues) => {
        excludeDatePatchMutation.mutate({
            payload: [
                {
                    description: data.description,
                    start_date: data.startDate,
                    end_date: data.endDate,
                },
            ],
            resourceId: [excludeDateToEdit!.id.toString()],
        });
    };
    return (
        <div>
            <StyledH2>Date Exclusions</StyledH2>

            <ConfigTable
                status={excludeDateQuery.status}
                isFetching={excludeDateQuery.isFetching || excludeDateDeleteMutation.isPending}
                disabled={excludeDateDeleteMutation.isPending}
                empty={excludeDateQuery.data?.length === 0}
            >
                <ConfigTable.Table maxHeight="200px" emptyMessage="No date exclusions found">
                    <ConfigTable.Header>
                        <ConfigTable.Row key="date-exclusion-header">
                            <ConfigTable.CellHeader />
                            <ConfigTable.CellHeader>Description</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Start Date</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>End Date</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader />
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {excludeDates.map(row => {
                            return (
                                <ConfigTable.Row key={`date-exclusion-${row.id}`}>
                                    <ConfigTable.CellCheckbox
                                        rowId={row.id}
                                        checked={row.selected}
                                        onCheckedChange={handleCheckboxChange}
                                    />
                                    <ConfigTable.Cell>{row.description}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{moment(row.startDate).format('DD/MM/YYYY')}</ConfigTable.Cell>
                                    <ConfigTable.Cell>{moment(row.endDate).format('DD/MM/YYYY')}</ConfigTable.Cell>
                                    <ConfigTable.CellActions>
                                        <ConfigTable.ActionDropdownItem
                                            rowId={row.id}
                                            type="edit"
                                            label="Edit"
                                            onClick={handleEditClick}
                                        />
                                        <ConfigTable.ActionDropdownItem
                                            rowId={row.id}
                                            type="delete"
                                            label="Delete"
                                            onClick={handleSingleDeleteClick}
                                        />
                                    </ConfigTable.CellActions>
                                </ConfigTable.Row>
                            );
                        })}
                    </ConfigTable.Body>
                </ConfigTable.Table>
                <ConfigTable.ActionBar>
                    <ConfigTable.ActionButton type="delete" label="Delete" onClick={handleBulkDeleteClick} />
                    <ConfigTable.ActionButton
                        type="add"
                        label="Add Date Exclusion"
                        onClick={() => dispatch(setModal('AddExcludeDates', {}))}
                    />
                </ConfigTable.ActionBar>
            </ConfigTable>

            {showEditForm && excludeDateToEdit && (
                <StyledFormContainer>
                    <Form
                        status={excludeDatePatchMutation.status === 'pending' ? 'loading' : 'enabled'}
                        onSubmit={handleFormSubmit}
                        defaultValues={excludeDateToEdit}
                    >
                        <Form.Body>
                            <Form.Section>
                                <Form.SectionTitle>Edit Exclude Date</Form.SectionTitle>

                                <Form.Field>
                                    <Form.InputLabel htmlFor="description" label="Description" required={true} />
                                    <Form.InputTextArea name="description" validators={[validators.required]} />
                                </Form.Field>

                                <Form.Field>
                                    <Form.InputLabel htmlFor="startDate" label="Start Date" required={true} />
                                    <Form.InputDatePicker name="startDate" validators={[validators.required]} />
                                </Form.Field>

                                <Form.Field>
                                    <Form.InputLabel htmlFor="endDate" label="End Date" required={true} />
                                    <Form.InputDatePicker name="endDate" validators={[validators.required]} />
                                </Form.Field>
                            </Form.Section>
                        </Form.Body>
                        <Form.Footer>
                            <Form.InputButton
                                value="Apply"
                                type="submit"
                                loading={excludeDatePatchMutation.isPending}
                            />
                            <Form.InputButton
                                value="Cancel"
                                type="button"
                                buttonTheme={ButtonThemes.Secondary}
                                onClick={() => setShowEditForm(false)}
                            />
                        </Form.Footer>
                    </Form>
                </StyledFormContainer>
            )}
        </div>
    );
};

export default LayoutDateExclusions;
