import { useEffect, useState } from 'react';
import { SectionDashboardRequest } from '../../types';
import { CubedField } from '../../../types';
import { isEqual } from 'lodash';

const useRequestPie = (request: SectionDashboardRequest) => {
    const [pieRequest, setPieRequest] = useState<SectionDashboardRequest>(request);

    const actions = () => {
        return {
            setChartField: (chartField: CubedField) => {
                setPieRequest(prevPieRequest => ({ ...prevPieRequest, chartField }));
            },
        };
    };

    useEffect(() => {
        setPieRequest({ ...pieRequest, ...actions() });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (request.filters) {
            if (!isEqual(request.filters, pieRequest.filters)) {
                setPieRequest({ ...request, ...actions() });
            }
        }

        if (request.sectionFilters) {
            if (!isEqual(request.sectionFilters, pieRequest.sectionFilters)) {
                setPieRequest({ ...request, ...actions() });
            }
        }

        if (request.pageFilters) {
            if (!isEqual(request.pageFilters, pieRequest.pageFilters)) {
                setPieRequest({ ...request, ...actions() });
            }
        }

        if (request.groupBy) {
            if (!isEqual(request.groupBy, pieRequest.groupBy)) {
                setPieRequest({ ...request, ...actions() });
            }
        }

        if (request.showLoading !== pieRequest.showLoading) {
            setPieRequest({ ...request, ...actions() });
        }

        if (request.showEmpty !== pieRequest.showEmpty) {
            setPieRequest({ ...request, ...actions() });
        }
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

    return pieRequest;
};

export default useRequestPie;
