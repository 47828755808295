import React from 'react';
import WidgetMenuCheckbox from '../widget-menu-checkbox';

type WidgetMenuSectionCheckboxProps = {
    label: string;
    checked: boolean;
    handleCheckedChange: () => void;
};

const WidgetMenuSectionCheckbox = ({ label, checked, handleCheckedChange }: WidgetMenuSectionCheckboxProps) => {
    return <WidgetMenuCheckbox label={label} checked={checked} onCheckedChange={handleCheckedChange} />;
};

export default WidgetMenuSectionCheckbox;
