import { TooltipFormatter } from '../helpers/tooltip-formatter';
import theme from '../../styled-components/theme.styled';
import { DefaultTheme } from 'styled-components';

const tooltipFormatter = () => {
    return function (this: TooltipFormatter) {
        return this.y !== 0 && this.percentage > 5
            ? `<div style="text-align:center;">
                    ${this.point.name.slice(0, 20)}${this.point.name.length > 20 ? '...' : ''}
                    <div style="font-size: 12px; font-weight: 800;">${this.percentage.toFixed(1)}%</div>
                </div>`
            : '';
    };
};

export const pieChartDataLabelsConfig = (showLabels: boolean, width: number, height: number) => {
    if (showLabels && width > 5 && height > 1) {
        return {
            distance: height < 3 || width < 6 ? -30 : height > 3 && width > 6 ? -60 : -50,
            formatter: tooltipFormatter(),
            useHTML: true,
            style: {
                textOutline: '0px',
                fontSize: height > 3 && width > 6 ? '11px' : '10px',
                fontWeight: 600,
                color: theme.sectionDashboard.widget.pie.dataLabelColor,
            },
        };
    }
    return {
        enabled: false,
    };
};

export const pieChartConfig = (theme: DefaultTheme) => {
    return {
        chart: {
            type: 'pie',
            style: {
                fontFamily: 'Work Sans',
            },
            backgroundColor: 'transparent',
        },
        title: {
            text: '',
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        legend: {
            align: 'right',
            verticalAlign: 'middle',
            layout: 'horizontal',
            itemStyle: {
                color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.6)',
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                borderColor: theme.name === 'dark' && '#605f5f',
                borderRadius: 0,
            },
            series: {
                animation: false,
            },
        },
    };
};
