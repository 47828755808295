// React Dependencies
import React, { Component } from 'react';

// Import Components
import ListBuilderListItem from './list-builder-list-item';

// 		class ListBuilderList
// 			props.items 						Array < List Builder Object >
// 			props.noItems						boolean
// 			props.emptyListMessageOverride		string | undefined
// 			props.onSelectedItemsChanged		function
// 			props.onItemsChanged				function
// 			props.dragOrdering					boolean
//
// 		< List Builder Object >
//			id   	string		Used for key attribute
// 			value   string 		Used for the value attribute
// 			name    string 		Text displayed to the user
//

class ListBuilderList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItems: [],
            items: [],
        };

        this.emptyListMessage = this.emptyListMessage.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.onItemDrop = this.onItemDrop.bind(this);
        this.onListDrop = this.onListDrop.bind(this);
        this.renderListBuilderListItem = this.renderListBuilderListItem.bind(this);
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.selectedItems !== nextState.selectedItems) {
            this.props.onSelectedItemsChanged(nextState.selectedItems);
        }

        const propsItemIds = nextProps.items.map(item => item.id);
        const stateItemIds = this.state.items.map(item => item.id);

        stateItemIds.filter(item => !propsItemIds.includes(item));
        // if(changesInProps.length > 0){
        // 	this.setState({
        // 		items: this.props.items
        // 	})
        // }
    }

    // Returns the correct empty list message based if the override is present.
    emptyListMessage() {
        return this.props.emptyListMessageOverride !== undefined
            ? this.props.emptyListMessageOverride
            : 'This list is empty.';
    }

    // Event Handler to move an item to and from the selectedItems based on if it has been clicked
    onItemClick(e, selected) {
        const currentSelectedValue = e.currentTarget.getAttribute('data-value');
        let updatedSelectedItems = [];

        if (selected) {
            updatedSelectedItems = this.state.selectedItems.filter(item => {
                return item.value !== currentSelectedValue;
            });
        } else {
            updatedSelectedItems = this.state.selectedItems.slice(0);
            updatedSelectedItems.push(
                this.state.items.filter(item => {
                    return item.value === currentSelectedValue;
                })[0]
            );
        }

        this.setState({
            selectedItems: updatedSelectedItems,
        });
    }

    // Event Handler for individual items in the list to handle what happens when an item is dropped on another
    onItemDrop(e) {
        e.stopPropagation();

        if (e.dataTransfer.getData('ListDragging') === e.target.getAttribute('data-value')) {
            return;
        }

        const itemToMove = this.state.items.filter(item => {
            return item.value === e.dataTransfer.getData('ListDragging');
        })[0];
        const itemAfter = this.state.items.filter(item => {
            return item.value === e.target.getAttribute('data-value');
        })[0];

        this.setState(state => {
            state.items.move(state.items.indexOf(itemToMove), state.items.indexOf(itemAfter));
            return {};
        });
    }

    // Prevents default on the Drag Over event listener
    onDragOver(e) {
        e.preventDefault();
    }

    // Event Handler on the list to handle what happens when a user drops an item on the list itself
    onListDrop(e) {
        const itemToMove = this.state.items.filter(item => {
            return item.value === e.dataTransfer.getData('ListDragging');
        })[0];

        this.setState(state => {
            const updatedItems = state.items.slice(0);
            updatedItems.move(state.items.indexOf(itemToMove), state.items.length);
            return {
                items: updatedItems,
            };
        });
    }

    // Render the List Item section
    renderListBuilderListItem(item) {
        const isItemSelected = this.state.selectedItems.some(selectedItem => {
            return selectedItem.id === item.id;
        });

        return (
            <ListBuilderListItem
                id={item.id}
                value={item.value}
                name={item.name}
                selected={isItemSelected}
                onDrop={this.onItemDrop}
                onClick={this.onItemClick}
                draggable={this.props.dragOrdering}
            />
        );
    }

    render() {
        if (this.props.noItems) {
            return (
                <ul className="list-builder__list">
                    <li className="list-builder__list__item empty">{this.emptyListMessage()}</li>
                </ul>
            );
        }

        return (
            <ul className="list-builder__list" onDrop={this.onListDrop} onDragOver={this.onDragOver}>
                {this.state.items.map(item => {
                    return this.renderListBuilderListItem(item);
                })}
            </ul>
        );
    }
}

export default ListBuilderList;
