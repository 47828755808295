// convert a number into seconds based on duration unit
export const convertToSeconds = (value, unit) => {
    let seconds;
    if (unit === 'second') {
        seconds = value;
    } else if (unit === 'minute') {
        seconds = value * 60;
    } else if (unit === 'hour') {
        seconds = value * 60 * 60;
    } else if (unit === 'day') {
        seconds = value * 24 * 60 * 60;
    } else if (unit === 'week') {
        seconds = value * 7 * 24 * 60 * 60;
    } else if (unit === 'month') {
        seconds = value * 2628002.88;
    }

    return parseInt(seconds);
};

// convert seconds back to number based on duration unit
export const convertFromSeconds = (seconds, unit) => {
    let value;
    if (unit === 'second') {
        value = seconds;
    } else if (unit === 'minute') {
        value = seconds / 60;
    } else if (unit === 'hour') {
        value = seconds / 60 / 60;
    } else if (unit === 'day') {
        value = seconds / 24 / 60 / 60;
    } else if (unit === 'week') {
        value = seconds / 7 / 24 / 60 / 60;
    } else if (unit === 'month') {
        value = seconds / 2628002.88;
    }

    return parseInt(value);
};
