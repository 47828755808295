// React Dependencies
import React, { useState, useEffect } from 'react';

// Core Dependencies
import Papa from 'papaparse';
import Axios from 'axios';

// Helpers
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { validateFileType } from '../../helpers/validate-csv-file';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
// import { getRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import ProgressBar from '../../components/progress-bar';
import moment from 'moment';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { ButtonThemes } from '../../enums/button-themes';

const LayoutModalAddTVSlots = () => {
    const [isLoading, setIsloading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileIsValid, setSelectedFileIsValid] = useState(false);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [showUploadProgress, setShowUploadProgress] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(null);
    const [fileUploadId, setFileUploadId] = useState(null);
    const [returningMessage, setReturningMessage] = useState(null);
    const [fileInputErrorMessage, setFileInputErrorMessage] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const { account, request } = useSelector(state => state);

    const dispatch = useDispatch();

    const fileHeaders = ['datetime', "impacts ('000's)", 'programme', 'secs', 'station'];

    useEffect(() => {
        Axios({
            method: 'GET',
            url: generateUrl('config', 'file-upload', [
                { key: 'account_id', value: account.id },
                { key: 'processing', value: true },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length > 0) {
                    setShowUploadProgress(true);
                    setUploadProgress(response.data.objects[0].progress);
                    setFileUploadId(response.data.objects[0].id);
                    setReturningMessage('There is currently a file upload in progress for account.');
                    fetchUploadProgress();
                    setIsloading(false);
                }
            })
            .catch(() => {
                setPageError(true);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fileUploadId) {
            fetchUploadProgress();
        }
    }, [fileUploadId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoading && !request.isLoading) {
            if (!request.hasError) {
                setIsloading(false);
            } else {
                setIsloading(false);
                setPageError(true);
            }
        }
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

    const onCloseClick = () => {
        dispatch(removeModal());
    };
    const preProcessTheDate = (dataCsv, file) => {
        // Pre-processing the date so it will be in correct format to post to the DB
        let tempDate;
        const dateTimeAreValid = dataCsv.every((row, index) => {
            if (moment(row['DateTime'], 'YYYY-MM-DD HH:m:s', true).isValid()) {
                tempDate = moment(row['DateTime'], 'YYYY-MM-DD HH:m:s');
            } else {
                return false;
            }
            dataCsv[index].DateTime = tempDate.format('YYYY-MM-DD HH:mm:ss');
            return true;
        });
        if (dateTimeAreValid) {
            const csvString = convertToCSV({
                data: dataCsv,
                delimiter: ',',
            });
            const csvBlob = new Blob([csvString], { type: 'text/csv' });
            // converting blob to file object
            const newFile = new File([csvBlob], file.name, { lastModifiedDate: file.lastModifiedDate });
            setSelectedFile(newFile);
            return dataCsv;
        } else {
            return 'Invalid Date';
        }
    };

    const validateFile = (firstRowData, csvData, csvFile) => {
        const fileHeadersSorted = Object.keys(firstRowData)
            .sort()
            .map(header => {
                return header.toLowerCase();
            });
        const headersAreValid =
            fileHeaders.length === fileHeadersSorted.length &&
            fileHeaders.every((header, index) => {
                return header === fileHeadersSorted[index];
            });

        if (!headersAreValid) {
            setSelectedFileIsValid(false);
            setSelectedFile(null);
            setFileInputErrorMessage(
                "The headers in this file don't appear to be in the correct format, so we can't process it. Please check our documentation and try again."
            );
            return false;
        }
        const newDataCsv = preProcessTheDate(csvData, csvFile);

        if (newDataCsv === 'Invalid Date') {
            setSelectedFileIsValid(false);
            setSelectedFile(null);
            setFileInputErrorMessage(
                "The dates and times in this file don't appear to be in the correct format, so we can't process it. Please check our documentation and try again."
            );
            return false;
        } else if (validateDataCSVFile(firstRowData, newDataCsv)) {
            return true;
        } else {
            return false;
        }
    };

    const onFileInputChange = event => {
        const file = event.target.files[0];

        const { isValid, selectedFile, message } = validateFileType(file, 'csv');
        if (!isValid) {
            setSelectedFileIsValid(isValid);
            setSelectedFile(selectedFile);
            setFileInputErrorMessage(message);

            return;
        }

        const reader = new FileReader();
        let dataCsv;
        let firstRowData;
        try {
            reader.readAsText(file);
            reader.onload = event => {
                const csvData = event.target.result;
                // read file headers with Papaparse
                Papa.parse(csvData, {
                    skipEmptyLines: true,
                    header: true,
                    complete: result => {
                        firstRowData = result.data[0];
                        dataCsv = result.data;
                    },
                });
                if (validateFile(firstRowData, dataCsv, file)) {
                    setSelectedFileIsValid(true);
                    setFileInputErrorMessage(null);
                    setUploadButtonDisabled(false);
                } else {
                    setSelectedFileIsValid(false);
                    setSelectedFile(null);
                }
            };
            reader.onerror = () => {
                setSelectedFileIsValid(false);
                setSelectedFile(null);
                setFileInputErrorMessage('Sorry, there was a problem reading file. Please check it and try again.');
            };
        } catch (error) {
            setSelectedFileIsValid(false);
            setSelectedFile(null);
            setFileInputErrorMessage('Sorry, there was a problem reading file. Please check it and try again.');
        }
    };

    const uploadCSVFile = file => {
        const data = new FormData();
        data.append('upload_type', 'tv_slot');
        data.append('file', file);
        data.append('account_id', account.id);

        Axios({
            method: 'POST',
            url: generateUrlDetail(account.token, 'upload', 'csv-importer', [], false),
            data: data,
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                if (response.status === 200) {
                    setShowUploadProgress(true);
                    setFileUploadId(response.data.file_upload_id);
                    setUploadProgress(0.0);
                }
            })
            .catch(() => {
                setShowLoading(false);
                setUploadError(true);
            });
    };

    const validateDataCSVFile = (headerData, data) => {
        const csvData = data;
        const fileHeaders = headerData;
        const fileHeadersSorted = Object.keys(fileHeaders)
            .sort()
            .map(header => {
                return header;
            });
        const isValid = fileHeadersSorted.every(header => {
            const headerIsValid = csvData.every(data => {
                if (header === 'Secs' || header === "Impacts ('000's)") {
                    if (data[header] === '' || isNaN(data[header])) {
                        setFileInputErrorMessage(
                            'Sorry, the column ' +
                                header +
                                ' doesn’t have data in a numerical format. Please re-check the data and re-upload.'
                        );
                        setSelectedFileIsValid(false);
                        setSelectedFile(null);
                        return false;
                    }
                }
                return true;
            });
            return headerIsValid;
        });
        return isValid;
    };

    const onUploadClick = () => {
        setUploadButtonDisabled(true);
        setShowLoading(true);
        uploadCSVFile(selectedFile);
    };
    const getProgress = () => {
        if (uploadProgress !== '100.00' && !pageError) {
            Axios({
                method: 'GET',
                url: generateUrl('config', 'file-upload', [{ key: 'id', value: fileUploadId }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    if (response.data.objects[0].error) {
                        setShowLoading(false);
                        setUploadError(true);
                    } else {
                        setShowLoading(false);
                        setUploadProgress(response.data.objects[0].progress);
                    }
                })
                .catch(() => {
                    setShowLoading(false);
                    setPageError(true);
                });
        }

        if (uploadProgress !== '100.00' && !uploadError) {
            setTimeout(getProgress, 2000);
        } else {
            setReturningMessage(null);
        }
    };
    const fetchUploadProgress = () => {
        if (uploadProgress !== '100.00' && fileUploadId) {
            getProgress();
        }
    };

    const RenderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: onCloseClick,
                disabled: false,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const RenderUploadAccordion = () => {
        const accordion = [
            {
                header: 'Add TV Slot',
                required: false,
                open: true,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'TV Slot CSV File:',
                                type: 'file-upload',
                                requiredField: true,
                                toolTipCopy: 'Upload A Forecast CSV File.',
                                inputKeyValue: 'add-tv-slots__file',
                                inputOnChange: onFileInputChange,
                                isValid: selectedFileIsValid,
                                uploadedFile: selectedFile?.name,
                                errorMessage: fileInputErrorMessage,
                            },
                        ],
                        columns: 1,
                        buttons: [
                            {
                                value: 'UPLOAD',
                                onClick: onUploadClick,
                                disabled: uploadButtonDisabled,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordion} />;
    };

    if (isLoading || showLoading) {
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add TV Slots</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add TV Slots</h2>
                <WarningMessage copy="There was a server issue getting page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    if (uploadError) {
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>ADD TV Slots</h2>
                <WarningMessage copy="There was a problem with your file upload. Please check your file, try again later or contact support@cubed.email." />
            </div>
        );
    }

    if (showUploadProgress) {
        const progressCopy = `File upload ${uploadProgress === '100.00' ? 'complete!' : 'in progress...'}`;
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>ADD TV Slots</h2>
                <div className="file-upload-progress">
                    {returningMessage && <p>{returningMessage}</p>}
                    <p>{progressCopy}</p>
                    <ProgressBar value={parseFloat(uploadProgress) / 100} difference={100} label="Upload Progress" />
                </div>
            </div>
        );
    }

    return (
        <div className="modal__side-panel__add-csv manage-modals">
            <RenderModalNavigation />
            <h2>ADD TV Slots</h2>
            <p>
                Import your TV Slots data into Cubed to enable advanced ATL reporting. It is important to use the
                defined CSV format - you can download it from here: <a href="/files/atl_tv_slot.csv">CSV Template</a>.
            </p>
            <p>
                For more description about the data and file format you can check the documentation here:{' '}
                <a href="http://tag.docs.withcubed.com/onboarding/atl-tv-slot/">Documentation</a>.
            </p>
            <p>TV slot times should be uploaded to the hour, example format 2022-09-01 21:00:00.</p>
            <RenderUploadAccordion />
        </div>
    );
};

export default LayoutModalAddTVSlots;
