import React from 'react';
import styled, { useTheme } from 'styled-components';
import tinycolor from 'tinycolor2';

// Highcharts
import CommonHighcharts from '../../../components/common/common-highcharts';
import Highcharts from 'highcharts';

// Config
import { largeChartOptions } from '../../configurations/widget-line-graph-config';

// Types
import { LineGraphComparisonData } from '../../types';

// Hooks
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Helpers
import { newColourAsRgba } from '../../../helpers/colours';

// Components
import WidgetBase from '../base/widget-base';
import WidgetLineGraphBigNumber from '../big-numbers/widget-line-graph-big-number';

const StyledContainer = styled.div<{ width: number }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => (props.width > 5 ? 'row' : 'column')};
`;

const StyledChartContainer = styled.div<{ width: number }>`
    width: ${props => (props.width > 5 ? '50%' : '100%')};
    height: 100%;
`;

export type WidgetLineGraphComparisonProps = {
    title: string;
    data: LineGraphComparisonData;
    href?: string;
    showFillColour?: boolean;
};

const WidgetLineGraphComparison = ({ title, data, href, showFillColour }: WidgetLineGraphComparisonProps) => {
    const theme = useTheme();
    const { width, height } = useSectionDashboard();

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    if (width === 1 || height === 1) {
        const numberOfBoxes = Math.max(width, height);
        const dataToDisplay = data.series.slice(0, numberOfBoxes);
        const direction = width > height ? 'horizontal' : 'vertical';
        return (
            <WidgetBase title={title} href={href}>
                <WidgetLineGraphBigNumber data={dataToDisplay} direction={direction} width={width} />
            </WidgetBase>
        );
    } else {
        let options = largeChartOptions(theme);

        const series = data.series;
        const comparisonSeries = data.comparisonSeries;

        const highchartsSeries = series.map(series => {
            let fillColor = null;
            if (showFillColour) {
                fillColor = {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, newColourAsRgba(tinycolor(series.colour), 0.4)],
                        [1, newColourAsRgba(tinycolor(series.colour), 0)],
                    ],
                };
            }
            return {
                name: series.name,
                data: series.data,
                marker: { fillColor: newColourAsRgba(tinycolor(series.colour), 1) },
                color: newColourAsRgba(tinycolor(series.colour), 0.8),
                fillColor,
            };
        });

        const highchartsComparisonSeries = comparisonSeries.map(series => {
            let fillColor = null;
            if (showFillColour) {
                fillColor = {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, newColourAsRgba(tinycolor(series.colour), 0.4)],
                        [1, newColourAsRgba(tinycolor(series.colour), 0)],
                    ],
                };
            }
            return {
                name: series.name,
                data: series.data,
                marker: { fillColor: newColourAsRgba(tinycolor(series.colour), 1) },
                color: newColourAsRgba(tinycolor(series.colour), 0.8),
                fillColor,
            };
        });

        return (
            <WidgetBase title={title} href={href}>
                <StyledContainer width={width}>
                    <StyledChartContainer width={width}>
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...options, legend: { enabled: false }, series: highchartsSeries }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    </StyledChartContainer>
                    <StyledChartContainer width={width}>
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={{ ...options, legend: { enabled: false }, series: highchartsComparisonSeries }}
                            containerProps={{ style: { height: '100%', width: '100%' } }}
                        />
                    </StyledChartContainer>
                </StyledContainer>
            </WidgetBase>
        );
    }
};

export default WidgetLineGraphComparison;
