import React from 'react';
import { CubedIcon } from './types';

const IconDoughnutChart: CubedIcon = ({ className }) => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt"
            height="512.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
            className={className}
        >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path
                    d="M2765 5008 c-3 -7 -4 -238 -3 -512 l3 -500 105 -22 c545 -115 974
-548 1087 -1096 l18 -88 511 0 512 0 -5 43 c-34 318 -109 581 -238 842 -299
606 -843 1060 -1496 1250 -124 36 -284 69 -384 80 -33 3 -70 8 -83 11 -13 2
-25 -1 -27 -8z"
                />
                <path
                    d="M2238 4985 c-286 -39 -530 -115 -790 -247 -240 -121 -429 -258 -624
-453 -380 -379 -620 -866 -700 -1420 -24 -165 -24 -488 0 -645 107 -713 489
-1313 1091 -1714 133 -89 389 -214 551 -269 549 -189 1162 -176 1701 35 215
84 522 260 648 371 l40 36 -367 367 -366 367 -68 -43 c-159 -100 -344 -178
-514 -215 -117 -26 -376 -31 -499 -11 -145 25 -261 60 -381 117 -418 199 -707
564 -806 1020 -26 119 -27 438 -1 559 62 291 187 522 396 731 204 204 426 325
701 383 l105 22 0 509 c0 331 -3 509 -10 511 -5 1 -54 -4 -107 -11z"
                />
                <path
                    d="M3995 2348 c-2 -7 -12 -44 -21 -83 -40 -168 -120 -351 -215 -492
l-51 -76 366 -366 367 -366 40 45 c69 77 212 299 281 435 103 203 167 387 212
608 26 125 47 286 39 300 -2 4 -231 7 -509 7 -395 0 -506 -3 -509 -12z"
                />
            </g>
        </svg>
    );
};

export default IconDoughnutChart;
