import React from 'react';

import WidgetPieChartTableComparison from './widget-pie-chart-table-comparison';

import { CubedField } from '../../../types';
import { PieTableComparisonData, TotalData } from '../../types';

type ComparisonCharts = {
    chartField: CubedField;
    data: PieTableComparisonData;
    totalData: TotalData;
};
export type MultipleCharts = {
    comparisonCharts: ComparisonCharts[];
    chartField: CubedField;
    chartMetrics: CubedField[];
    setChartField?: (chartField: CubedField) => void;
};

type WidgetMultiplePieChartTableComparisonProps = {
    title: string;
    comparisonPieChartTables: MultipleCharts;
    display?: 'pie' | 'donut' | 'semidonut';
    menu?: React.ReactElement;
};

const WidgetPieChartTableComparisonMultiple = ({
    title,
    comparisonPieChartTables,
    display,
    menu,
}: WidgetMultiplePieChartTableComparisonProps) => {
    const selectedChart = comparisonPieChartTables.comparisonCharts.find(
        (chart: ComparisonCharts) => chart.chartField === comparisonPieChartTables.chartField
    );
    const selectedData = { ...selectedChart, title, display, menu };

    if (selectedData?.data) {
        return (
            <WidgetPieChartTableComparison
                title={title}
                data={selectedData?.data}
                totalData={selectedData?.totalData}
                display={display}
                menu={menu}
            />
        );
    }
    return <></>;
};

export default WidgetPieChartTableComparisonMultiple;
