import React, { useContext } from 'react';
import styled from 'styled-components';
import ChartMenuRadio from './chart-menu-radio';
import ChartMenuBase from './chart-menu-base';
import { TableGraphContext } from '../../../context/table-graph-context';
import ChartMenuCheckbox from './chart-menu-checkbox';

const StyledTitle = styled.div`
    padding: 10px 20px;
    font-weight: 500;
`;

const StyledSeparator = styled.div`
    width: 100%;
    height: 1px;
    border: none;
    background: ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    margin: 15px 0px 5px 0px;
`;

export type ChartMenuProps = {
    chartDateGranularity: string;
    disableDateGranularity: boolean;
    onUpdateChartDateGranularity: () => void;
    disabled: boolean;
};

const ChartMenu = ({
    chartDateGranularity,
    disableDateGranularity,
    onUpdateChartDateGranularity,
    disabled,
}: ChartMenuProps) => {
    const tableGraphContext = useContext(TableGraphContext);
    const { annotations, handleChangeAnnotationVisibility } = tableGraphContext;

    const radioOptions = [
        { label: 'By Day', value: 'day' },
        { label: 'By Month', value: 'month' },
    ];

    return (
        <ChartMenuBase disabled={disabled}>
            <StyledTitle>Date Granularity</StyledTitle>
            <ChartMenuRadio
                options={radioOptions}
                value={chartDateGranularity}
                disabled={disableDateGranularity}
                onValueChange={onUpdateChartDateGranularity}
            />
            {annotations && annotations.length > 0 && (
                <>
                    <StyledSeparator />
                    <StyledTitle>Annotations</StyledTitle>
                    {annotations.map(annotation => {
                        return (
                            <ChartMenuCheckbox
                                key={annotation.id}
                                id={annotation.id}
                                label={annotation.name}
                                checked={annotation.checked}
                                disabled={annotation.disabled}
                                onCheckedChange={() => handleChangeAnnotationVisibility(annotation.id)}
                            />
                        );
                    })}
                </>
            )}
        </ChartMenuBase>
    );
};

export default ChartMenu;
