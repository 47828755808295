import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import ModalNavigation from '../../../components/modal-navigation';
import Form from '../../../components/forms/form';
import FormCreateAtl from './forms/form-create-atl';

// Redux
import { setModal } from '../../../redux/actions/modal';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../redux/actions/notification';

// Enums & Types
import { ButtonThemes } from '../../../enums/button-themes';
import { FieldValues } from 'react-hook-form';
import { ConfigDataSuccess } from '../../../react-query/types';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePostResourceWithPayload from '../../../react-query/hooks/use-post-resource-with-payload';
import usePostMultiResourcesWithPayload from '../../../react-query/hooks/use-post-multi-resource-with-payload';

// Config
import {
    CONFIG_ATL,
    CONFIG_ATL_CONFIG,
    CONFIG_ATL_REGION,
    CONFIG_CHANNELS,
} from '../../../configurations/resources-config';

// Helpers
import { generatePath } from '../../../helpers/request-builder';

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

type Region = {
    atl_config: string;
    country_name: string;
    city_name: string;
    nationwide: boolean;
};

const LayoutModalSetUpAboveTheLine = () => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState<FieldValues>();
    const [atlId, setAtlId] = useState<number>();

    // Queries
    const channelQuery = useFetchResource({
        resource: CONFIG_CHANNELS,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(object => {
                return {
                    label: object.name,
                    value: object.id,
                };
            });
        },
    });

    // Mutations
    const atlMutation = usePostResourceWithPayload({
        resource: CONFIG_ATL,
        handleOnSuccess: response => {
            setAtlId(response.id as number);
        },
        handleOnError: () => {
            dispatch(addNotification({ copy: 'Above the line set up failed.', type: 'error' }));
        },
    });

    const atlConfigMutation = usePostResourceWithPayload({
        resource: CONFIG_ATL_CONFIG,
    });

    useEffect(() => {
        if (formData && atlId) {
            atlConfigMutation.mutate({
                base_activity_start_date: formData.startDate,
                base_activity_end_date: formData.endDate,
                atl: generatePath('config', 'atl', String(atlId)),
                referer: generatePath('config', 'referer', String(formData.channel)),
            });
        }
    }, [formData, atlId]); // eslint-disable-line react-hooks/exhaustive-deps

    const atlRegionMutation = usePostMultiResourcesWithPayload({
        resource: CONFIG_ATL_REGION,
        handleOnSuccess: () => {
            setAtlId(undefined);
            dispatch(addNotification({ copy: 'Above the line set up successfully.', type: 'success' }));
            dispatch(setModal('ManageAboveTheLine', {}));
        },
        handleOnError: () => {
            dispatch(addNotification({ copy: 'Above the line set up failed.', type: 'error' }));
        },
    });

    useEffect(() => {
        if (atlId && formData && formData.regional === false) {
            atlRegionMutation.mutate([
                {
                    atl_config: generatePath('config', 'atl', String(atlId)),
                    nationwide: true,
                },
            ]);
        }
        if (atlId && formData && formData.regional === true && formData?.regions?.length > 0) {
            const regions: Region[] = [];

            formData.regions.forEach((region: any) => {
                if (region.country && region.city) {
                    regions.push({
                        atl_config: generatePath('config', 'atl', String(atlId)),
                        country_name: region.country,
                        city_name: region.city,
                        nationwide: false,
                    });
                }
            });

            atlRegionMutation.mutate(regions);
        }
    }, [atlId, formData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (data: FieldValues) => {
        setFormData(data);

        atlMutation.mutate({
            name: data.atlName,
        });
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(setModal('ManageAboveTheLine', {})),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Set Up Above The Line</h2>

            <p>
                Our ATL reporting is built upon modelling a baseline during a period where no ATL activity is running.
                We model a baseline for each ATL activity, such as TV or Display.
            </p>

            <StyledFormContainer>
                <Form
                    status={
                        atlMutation.isPending || atlConfigMutation.isPending || atlRegionMutation.isPending
                            ? 'disabled'
                            : 'enabled'
                    }
                    onSubmit={handleSubmit}
                    defaultValues={{
                        atlName: '',
                        startDate: '',
                        endDate: '',
                        channel: '',
                        regional: false,
                        regions: [],
                    }}
                >
                    <FormCreateAtl channels={channelQuery.data || []} />

                    <Form.Footer>
                        <Form.InputButton
                            type="submit"
                            value="Save"
                            loading={
                                atlMutation.isPending || atlConfigMutation.isPending || atlRegionMutation.isPending
                            }
                        />
                        <Form.InputButton type="reset" value="Clear" buttonTheme={ButtonThemes.Secondary} />
                    </Form.Footer>
                </Form>
            </StyledFormContainer>
        </>
    );
};

export default LayoutModalSetUpAboveTheLine;
