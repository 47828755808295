import axios, { AxiosError } from 'axios';

import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { mapObject } from '../../section-dashboard/helpers/map-object';

const REPORT_CATEGORIES = ['report', 'segmenter'];

export const fetchInfiniteResource = async ({ queryKey, pageParam }: { queryKey: any; pageParam: number }) => {
    const { accountToken, category, id, params } = queryKey[0];

    const indexToUpdate = params.findIndex((param: any) => param.key === 'offset');

    if (indexToUpdate !== -1) {
        params[indexToUpdate] = { ...params[indexToUpdate], value: pageParam };
    }

    const url =
        category === 'report-view'
            ? generateUrlDetail(accountToken, category, id, params, false)!
            : generateUrl(category, id, params);

    return axios({
        method: 'GET',
        url: url,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (category === 'report-view') {
                return response.data;
            }

            return {
                ...response.data,
                objects: REPORT_CATEGORIES.includes(category)
                    ? response.data.objects.map(mapObject)
                    : response.data.objects,
            };
        })
        .catch((error: AxiosError) => {
            throw new Error(error.message);
        });
};

export default fetchInfiniteResource;
