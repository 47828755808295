import { FieldValues } from 'react-hook-form';

const getCurrentSelectedMetrics = (formValues: FieldValues) => {
    if (formValues.graphMetric) {
        return [formValues.graphMetric];
    }

    return formValues.fields && formValues.fields.length > 0
        ? formValues.fields
              .filter((field: string | object) => typeof field === 'string')
              .filter((field: string) => field !== '')
        : [];
};

export default getCurrentSelectedMetrics;
