import React, { Component } from 'react';
import LayoutTableGraph from './../table-graph/layouts/table-graph';
import { config } from './../configurations/carbon-usage';

class ReportCarbonUsagePathResource extends Component {
    constructor(props) {
        super(props);
        this.config = config;
    }

    componentDidMount() {
        this.props.status('descriptionStatus', this.config.reportDescription ? 'show' : 'hidden');
    }

    render() {
        return (
            <LayoutTableGraph
                hash={this.props.location.hash}
                fetchProducts={this.props.fetchProducts}
                config={this.config}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedProducts={this.props.selectedProducts}
                selectedMetrics={this.props.selectedMetrics}
            />
        );
    }
}

export default ReportCarbonUsagePathResource;
