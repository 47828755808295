import React, { useState } from 'react';
import styled from 'styled-components';

// Helpers
import { columnDataTypeIsNumber, getValue } from '../../helpers/table-data';
import { formatVariance } from '../../helpers/variance-helpers';
import { sortPieTableComparisonData } from '../../helpers/sort-pie-table-data';

// Types
import { TableComparisonTableRow, TableColumn, TotalComparisonData } from '../../types';

// Icons
import IconArrowUpLong from '../../../components/icons/arrow-up-long';
import IconArrowDownLong from '../../../components/icons/arrow-down-long';

// Styles
const StyledTable = styled.table<{ height: number; width: number }>`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: ${props => (props.height > 1 && props.width > 1 ? '0.9rem' : '0.7rem')};
    color: ${props => props.theme.sectionDashboard.widget.table.textColor};
`;

const StyledTHead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 3;
`;

const StyledTH = styled.th`
    background: ${props => props.theme.sectionDashboard.widget.table.header.backgroundColor};
    font-weight: ${props => props.theme.sectionDashboard.widget.table.header.fontWeight};
    left: 0;
    z-index: 2;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.header.borderColor};
`;

const StyledHeaderContainer = styled.div<{ dataType?: number; height: number; width: number }>`
    padding: ${props => (props.height > 1 && props.width > 1 ? '15px' : '5px 15px')};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: ${props => props.dataType && (columnDataTypeIsNumber(props.dataType) ? 'flex-end' : 'flex-start')};
    text-align: ${props => props.dataType && (columnDataTypeIsNumber(props.dataType) ? 'right' : 'left')};
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
    }
`;

const StyledIconContainer = styled.div`
    & svg {
        width: 10px;
    }
    & path {
        fill: ${props => props.theme.sectionDashboard.widget.table.header.iconColor};
    }
`;

const StyledTD = styled.td<{
    width: number;
    height: number;
    colCount: number;
    isTotal?: boolean;
    variance?: number;
    dataType?: number;
}>`
    padding: ${props => (props.height > 1 ? '10px 15px' : '5px 15px')};
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.cell.borderColor};
    background: ${props =>
        props.isTotal
            ? props.theme.sectionDashboard.widget.table.totals.backgroundColor
            : props.theme.sectionDashboard.widget.table.cell.backgroundColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: ${props => (props.colCount > 3 ? '200px' : props.width > 4 ? '400px' : '130px')};
    color: ${props =>
        props.variance
            ? props.variance > 0
                ? props.theme.sectionDashboard.widget.variance.textColorPositive
                : props.variance < 0
                ? props.theme.sectionDashboard.widget.variance.textColorNegative
                : props.theme.sectionDashboard.widget.table.textColor
            : props.theme.sectionDashboard.widget.table.textColor};
    font-weight: ${props => props.variance && 600};
    text-align: ${props => (props.dataType && columnDataTypeIsNumber(props.dataType) ? 'right' : 'left')};
`;

const StyledGraphColourIndicator = styled.div<{ colour: string }>`
    display: inline-block;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    background-color: ${props => props.colour};
    border-radius: 50%;
`;

const StyledTotalTR = styled.tr`
    position: sticky;
    bottom: 0;
    z-index: 1;
    font-weight: 600;
`;

const StyledValue = styled.span<{ width: number; height: number; value: string }>`
    color: ${props =>
        (props.value === 'No Data' || props.value === 'N/A') &&
        props.theme.sectionDashboard.widget.table.cell.secondaryTextColor};
    font-size: ${props =>
        (props.value === 'No Data' || props.value === 'N/A') &&
        (props.height > 1 && props.width > 1 ? '0.8rem' : '0.7rem')};
`;

const StyledTotalValue = styled.span<{ width: number; height: number; value: string }>`
    font-size: ${props => props.value === 'No Data' && (props.height > 1 && props.width > 1 ? '0.9rem' : '0.7rem')};
`;

export type SectionDashboardPieComparisonTableProps = {
    columns: TableColumn[];
    rows: TableComparisonTableRow[];
    totalData?: TotalComparisonData;
    width: number;
    height: number;
    ascendingSorts: string[];
    descendingSorts: string[];
    withFilters: string[];
};

const SectionDashboardPieComparisonTable = ({
    columns,
    rows,
    totalData,
    width,
    height,
}: SectionDashboardPieComparisonTableProps) => {
    const [orderBy, setOrderBy] = useState({
        column: rows.some(row => row.data) ? columns[0] : columns[-1],
        direction: 'desc',
    });

    sortPieTableComparisonData(orderBy, rows);

    const onHeaderClick = (column: TableColumn) => {
        const currentColumn = orderBy.column;
        setOrderBy({
            column: column,
            direction: currentColumn === column && orderBy.direction === 'desc' ? 'asc' : 'desc',
        });
    };

    return (
        <StyledTable width={width} height={height}>
            <StyledTHead>
                <tr>
                    {columns.map((column, index) => {
                        return (
                            <StyledTH key={`${column.rawName}-${index}`}>
                                <StyledHeaderContainer
                                    dataType={column.dataType}
                                    width={width}
                                    height={height}
                                    onClick={() => onHeaderClick(column)}
                                >
                                    {column.displayName}
                                    {orderBy.column === column && orderBy.direction === 'asc' && (
                                        <StyledIconContainer>
                                            <IconArrowUpLong />
                                        </StyledIconContainer>
                                    )}
                                    {orderBy.column === column && orderBy.direction === 'desc' && (
                                        <StyledIconContainer>
                                            <IconArrowDownLong />
                                        </StyledIconContainer>
                                    )}
                                </StyledHeaderContainer>
                            </StyledTH>
                        );
                    })}
                </tr>
            </StyledTHead>
            <tbody>
                {rows.map(row => {
                    return (
                        <tr key={row.__id}>
                            {columns.map(column => {
                                if (column.displayName === 'This Period') {
                                    let value = 'No Data';
                                    if (row.data) {
                                        value = getValue(row.data, column.rawName);
                                    }
                                    return (
                                        <StyledTD
                                            key={`${column.rawName}_now`}
                                            width={width}
                                            height={height}
                                            colCount={columns.length}
                                            title={value}
                                            dataType={column.dataType}
                                        >
                                            <StyledValue width={width} height={height} value={value}>
                                                {value}
                                            </StyledValue>
                                        </StyledTD>
                                    );
                                } else if (column.displayName === 'Comparison Period') {
                                    let value = 'No Data';
                                    if (row.comparisonData) {
                                        value = getValue(row.comparisonData, column.rawName);
                                    }
                                    return (
                                        <StyledTD
                                            key={`${column.rawName}_then`}
                                            width={width}
                                            height={height}
                                            colCount={columns.length}
                                            title={value}
                                            dataType={column.dataType}
                                        >
                                            <StyledValue width={width} height={height} value={value}>
                                                {value}
                                            </StyledValue>
                                        </StyledTD>
                                    );
                                } else if (column.isDimension) {
                                    return (
                                        <StyledTD
                                            key={column.rawName}
                                            width={width}
                                            height={height}
                                            colCount={columns.length}
                                            title={getValue(row.dimension, column.rawName)}
                                        >
                                            {row.colour ? <StyledGraphColourIndicator colour={row.colour} /> : null}
                                            {getValue(row.dimension, column.rawName)}
                                        </StyledTD>
                                    );
                                } else if (column.isVariance) {
                                    let value = 'No Data';
                                    if (row.variance) {
                                        value = getValue(row.variance, 'variance');
                                    }
                                    return (
                                        <StyledTD
                                            key={column.rawName}
                                            width={width}
                                            height={height}
                                            colCount={columns.length}
                                            title={value}
                                            variance={+value}
                                            dataType={column.dataType}
                                        >
                                            <StyledValue width={width} height={height} value={value}>
                                                {value}
                                            </StyledValue>
                                        </StyledTD>
                                    );
                                } else {
                                    throw new Error(`Unknown column type for this table: ${column.displayName}`);
                                }
                            })}
                        </tr>
                    );
                })}
                {totalData && totalData.status === 'success' && (
                    <StyledTotalTR key="total-row">
                        {columns.map(column => {
                            if (column.displayName === 'This Period') {
                                let value = 'No Data';
                                if (totalData?.totals && totalData.totals.values) {
                                    value = column.ignoreTotal ? '' : getValue(totalData.totals, column.rawName);
                                }
                                return (
                                    <StyledTD
                                        key={`total-${column.rawName}-this-period`}
                                        width={width}
                                        height={height}
                                        colCount={columns.length}
                                        title={value}
                                        isTotal={true}
                                        dataType={column.dataType}
                                    >
                                        <StyledTotalValue width={width} height={height} value={value}>
                                            {value}
                                        </StyledTotalValue>
                                    </StyledTD>
                                );
                            } else if (column.displayName === 'Comparison Period') {
                                let value = 'No Data';
                                if (totalData.comparisonTotals) {
                                    value = column.ignoreTotal
                                        ? ''
                                        : getValue(totalData.comparisonTotals, column.rawName);
                                }
                                return (
                                    <StyledTD
                                        key={`total-${column.rawName}-comparison-period`}
                                        width={width}
                                        height={height}
                                        colCount={columns.length}
                                        title={value}
                                        isTotal={true}
                                        dataType={column.dataType}
                                    >
                                        <StyledTotalValue width={width} height={height} value={value}>
                                            {value}
                                        </StyledTotalValue>
                                    </StyledTD>
                                );
                            } else if (column.isDimension) {
                                return (
                                    <StyledTD
                                        key={`total-${column.rawName}-dimension`}
                                        width={width}
                                        height={height}
                                        colCount={columns.length}
                                        title="totals"
                                        isTotal={true}
                                    >
                                        TOTALS
                                    </StyledTD>
                                );
                            } else if (column.isVariance) {
                                let totalVariance = 'No Data';

                                if (totalData && column.varianceField) {
                                    const dimensionTotalNow =
                                        totalData?.totals?.values[column.varianceField.rawName]?.rawValue;
                                    const dimensionTotalComparison =
                                        totalData?.comparisonTotals?.values[column.varianceField.rawName]?.rawValue;

                                    if (dimensionTotalNow && dimensionTotalComparison) {
                                        totalVariance = formatVariance(
                                            ((+dimensionTotalNow - +dimensionTotalComparison) /
                                                +dimensionTotalComparison) *
                                                100
                                        );
                                    }
                                }
                                return (
                                    <StyledTD
                                        key={`total-${column.rawName}-dimension`}
                                        width={width}
                                        height={height}
                                        colCount={columns.length}
                                        title={totalVariance}
                                        isTotal={true}
                                        variance={+totalVariance}
                                        dataType={column.dataType}
                                    >
                                        <StyledTotalValue width={width} height={height} value={totalVariance}>
                                            {totalVariance}
                                        </StyledTotalValue>
                                    </StyledTD>
                                );
                            } else {
                                throw new Error(`Unknown column type for this table: ${column.displayName}`);
                            }
                        })}
                    </StyledTotalTR>
                )}
            </tbody>
        </StyledTable>
    );
};

export default SectionDashboardPieComparisonTable;
