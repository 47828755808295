import { DropdownOption } from '../../section-dashboard/types';
import { ConfigDataSuccess } from '../types';

type BuildDropdownOptionsArgs = {
    data: ConfigDataSuccess;
    labelField: string;
    valueField: string;
};

const buildDropdownOptions = ({ data, labelField, valueField }: BuildDropdownOptionsArgs): DropdownOption[] => {
    // Check the labelField and valueField exist in all objects
    if (data.objects.filter(object => object[labelField] === undefined).length > 0) {
        console.info(`buildDropdownOptions: ${labelField}  is undefined for some objects`);
        return [];
    }
    if (data.objects.filter(object => object[valueField] === undefined).length > 0) {
        console.info(`buildDropdownOptions:  ${valueField} is undefined for some objects`);
        return [];
    }

    // Return the dropdown options
    return data.objects.map(object => {
        return {
            label: String(object[labelField]),
            value: String(object[valueField]),
        };
    });
};

export default buildDropdownOptions;
