import { validateConfigChart } from './validate-config-chart';
import { validateConfigTable } from './validate-config-table';

export const validateConfig = config => {
    const messages = [...validateConfigChart(config), ...validateConfigTable(config)];

    // check for a pageTitle
    if (!config.pageTitle) {
        messages.push('The configuration file does not contain a pageTitle');
    }

    // check for resources
    if (!config.resources) {
        messages.push('The configuration file does not contain a resources');
    }

    return messages;
};
