// import styled from "styled-components"
import { DefaultTheme } from 'styled-components';
import { palette } from './palette';

const darkThemeColours = {
    background: '#1c1c1c',
    menuBackground: '#0a0a0a',
    shadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.5), 0px 1px 4px rgba(0, 0, 0, 0.5)',
    dark100: '#141414',
    dark200: '#292929',
    dark300: '#404040',
};

export const darkTheme: DefaultTheme = {
    name: 'dark',
    textFontFamily: `'Work Sans', sans-serif`,
    colours: {
        black: '#000000',
        borderGrey: '#e4e4e4',
        cubedOrange: '#ff7300',
        danger: '#d2004a',
        dark: '#212121',
        darkGrey: '#8a8a8a',
        darkerGrey: '#4d4d4d',
        midGrey: '#bfbfbf',
        focusColour: '#6ad9eb',
        green: '#4caf50',
        hoverGrey: '#eeeeee',
        info: '#6ad9eb',
        inputColour: '#e9f0f5',
        light: '#eeeeee',
        lightGrey: '#f1f1f1',
        link: '#58b5c4',
        offBlack: '#0a0a0a',
        offBlackLighter: '#1e1c1c',
        offLight: '#f0f0f0',
        orange: '#ff9800',
        primary: '#58b5c4',
        primaryLight: '#aad2f3',
        primaryLighter: '#cae3f6',
        red: '#d2004a',
        secondary: ' #dadada',
        selectionGrey: '#e3e7e7',
        shadowGrey: '#cbcbcb',
        success: '#4caf50',
        textDark: '#212121',
        textDefault: '#6f6f6f',
        textLight: '#ffffff',
        warning: '#ff9800',
        white: '#ffffff',
        whiteBg: '#ffffff',
        lightBlue: '#c0eeff',
        skyBlue: '#75daff',
    },
    boxShadow: level => {
        if (level === 1) {
            return `box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);`;
        } else if (level === 2) {
            return `box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.23);`;
        } else if (level === 3) {
            return `box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);`;
        } else if (level === 4) {
            return `box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);`;
        } else if (level === 5) {
            return `box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);`;
        } else if (level === 0) {
            return `box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.05);`;
        } else if (level === 0.5) {
            return `box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.18);`;
        }
    },
    dropShadow: (xLength, yLength, blur, spread, colour) => {
        return `
        -webkit-box-shadow: ${xLength} ${yLength} ${blur} ${spread} ${colour};
        -moz-box-shadow: ${xLength} ${yLength} ${blur} ${spread} ${colour};
        box-shadow: ${xLength} ${yLength} ${blur} ${spread} ${colour};
    `;
    },
    no_select: () => {
        return `
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    `;
    },
    scrollbar: (width, height) => {
        return `
            &::-webkit-scrollbar {
                width: ${width}px;
                height: ${height}px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0.12);
            }
            
            &::-webkit-scrollbar-thumb {
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0.26);
            }
            &--dark::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.75);
            }
        `;
    },

    components: {
        message: {
            textColor: 'rgba(255, 255, 255, 0.7)',
            icon: {
                warningColor: '#ffb37d',
                errorColor: '#ff4d79',
                infoColor: 'rgba(255, 255, 255, 0.7)',
                loadingColor: 'rgba(255, 255, 255, 0.7)',
            },
        },
    },
    filterBar: {
        backgroundColor: darkThemeColours.dark300,
        button: {
            labelColor: 'rgba(255, 255, 255, 0.8)',
            labelFontWeight: '500',
            backgroundColor: darkThemeColours.dark200,
            borderHover: 'rgba(255, 255, 255, 0.3)',
            iconColor: 'rgba(255, 255, 255, 0.8)',
        },
        channelSignpost: {
            textColor: 'rgba(255, 255, 255, 0.6)',
            tierTextColor: 'rgba(255, 255, 255, 0.8)',
            tierFontWeight: '500',
        },
    },
    datePicker: {
        backgroundColor: 'rgb(10, 10, 10)',
        day: {
            textColor: '#ffffff',
            textColorDisabled: 'rgba(255, 255, 255, 0.3)',
            textColorPassive: 'rgba(255, 255, 255, 0.3)',
            textColorSelected: '#ffffff',
            disabledBackgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        dateDisplay: {
            textColor: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },
        definedRanges: {
            textColor: 'rgba(255, 255, 255, 0.8)',
            borderColor: 'rgba(255, 255, 255, 0.2)',
            backgroundColorHover: 'rgba(0, 0, 0, 0.1)',
        },
        monthAndYearPicker: {
            textColor: '#ffffff',
            optionTextColor: '#3e484f',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        nextPrevButton: {
            arrowColorNext: 'transparent transparent transparent rgba(255, 255, 255, 0.8)',
            arrowColorPrev: 'transparent rgba(255, 255, 255, 0.8) transparent transparent',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            backgroundColorHover: 'rgba(255, 255, 255, 0.4)',
        },
    },
    sectionDashboard: {
        grid: {
            row: {
                height: '160px',
            },
            breakpoints: {
                xl: '1440px',
                lg: '1240px',
                md: '905px',
                sm: '600px',
                xs: '0px',
            },
        },
        section: {
            headingTextColor: 'rgba(255, 255, 255, 0.8)',
            headingFontWeight: '500',
            bodyTextColor: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: darkThemeColours.dark200,
            iconColor: '#ff842d',
            iconColorHover: '#ffa464',
            sectionMenu: {
                select: {
                    textColor: 'rgba(255, 255, 255, 0.8)',
                    labelColor: 'rgba(255, 255, 255, 0.8)',
                    labelFontWeight: '500',
                    borderColor: darkThemeColours.dark300,
                    backgroundColor: darkThemeColours.menuBackground,
                    textColorEmpty: 'rgba(191, 191, 191, 0.5)',
                    optionLabelColorEmpty: 'rgba(191, 191, 191, 0.5)',
                    skeletonColor: darkThemeColours.dark200,
                    actionButton: {
                        borderColor: '#bfbfbf',
                        borderColorDisabled: '#f1f1f1',
                        textColorDisabled: '#bfbfbf',
                        iconColor: '#bfbfbf',
                    },
                },
            },
        },
        widget: {
            base: {
                backgroundColor: darkThemeColours.dark200,
                textColorNoData: '#000000',
                borderColor: darkThemeColours.dark200,
                shadow: darkThemeColours.shadow,
                footer: {
                    borderColor: 'rgba(255, 255, 255, 0.1)',
                },
                link: {
                    textColor: 'rgba(255, 255, 255, 0.8)',
                    fontWeight: '400',
                    fontWeightDropdown: '400',
                    focus: {
                        color: '#ffffff',
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    },
                    textPlaceholder: darkThemeColours.dark300,
                },
                loader: {
                    color: 'rgba(255, 255, 255, 0.2)',
                },
            },
            menu: {
                backgroundHover: 'rgba(255, 255, 255, 0.05)',
                textColor: 'rgba(255, 255, 255, 0.8)',
                textColorHover: '#ffffff',
                textColorDisabled: 'rgba(255, 255, 255, 0.5)',
                separatorColor: 'rgba(255, 255, 255, 0.2)',
                triggerBackgroundColor: darkThemeColours.dark100,
                backgroundColor: '#0a0a0a',
                labelHover: '#ffffff',
                shadow: '0px 1px 25px -1px rgba(0, 0, 0, 0.5), 0px 1px 12px rgba(0, 0, 0, 0.5)',
                fontWeight: '400',
                skeletonColor: darkThemeColours.dark300,
                checkbox: {
                    iconBackgroundColorChecked: darkThemeColours.dark100,
                    iconColor: darkThemeColours.dark100,
                    iconColorHover: darkThemeColours.dark100,
                },
                radio: {
                    iconColor: darkThemeColours.dark100,
                    iconHover: 'rgba(255, 255, 255, 0.8)',
                },
                title: {
                    fontWeight: '400',
                },
                pagination: {
                    textColor: 'rgba(255, 255, 255, 0.8)',
                    iconHover: '#ffffff',
                    fontWeight: '400',
                    footerFontWeight: '400',
                },
            },
            dialog: {
                textColor: 'rgba(255, 255, 255, 0.8)',
                backgroundColor: darkThemeColours.background,
                overlayColor: 'rgba(0, 0, 0, 0.7)',
                shadow: '0px 1px 25px -1px rgba(0, 0, 0, 0.3), 0px 1px 12px rgba(0, 0, 0, 0.3)',
                titleFontWeight: '500',
                input: {
                    backgroundColor: darkThemeColours.dark100,
                    backgroundColorDisabled: '#f1f1f1',
                },
                button: {
                    textColor: 'rgba(255, 255, 255, 0.8)',
                    disabledTextColor: 'rgba(255, 255, 255, 0.4)',
                    backgroundColor: '#ff842d',
                    fontWeight: '400',
                },
                closeButton: {
                    backgroundColor: '#ff842d',
                    backgroundColorDisabled: '#929191',
                    textColor: darkThemeColours.background,
                    textColorSecondary: '#797777',
                    textColorDisabled: darkThemeColours.background,
                    fontWeight: '600',
                },
            },
            variance: {
                textColor: 'rgba(255, 255, 255, 0.8)',
                textColorPositive: '#63b266',
                textColorNegative: '#c13e58',
            },
            table: {
                textColor: 'rgba(255, 255, 255, 0.7)',
                header: {
                    backgroundColor: darkThemeColours.background,
                    borderColor: darkThemeColours.dark300,
                    iconColor: 'rgba(255, 255, 255, 0.8)',
                    fontWeight: '500',
                },
                cell: {
                    borderColor: '#494747',
                    backgroundColor: darkThemeColours.dark200,
                    secondaryTextColor: '#bfbfbf',
                },
                column: {
                    borderColor: '#605f5f',
                },
                totals: {
                    backgroundColor: darkThemeColours.background,
                },
                cardTable: {
                    textColor: 'rgba(255, 255, 255, 0.7)',
                    backgroundColor: darkThemeColours.background,
                    borderColor: darkThemeColours.dark300,
                    backgroundColorOdd: darkThemeColours.dark300,
                },
            },
            bigNumber: {
                textColor: 'rgba(255, 255, 255, 0.95)',
                backgroundColor: '#929191',
                varianceBackgroundColor: darkThemeColours.background,
                bigNumberBar: {
                    textColor: darkThemeColours.background,
                    titleFontWeight: '600',
                },
            },
            pie: {
                dataLabelColor: '#4d4d4d',
                ratioFontWeight: '500',
                ratioColor: 'rgba(255, 255, 255, 0.8)',
                ratioDescriptionColor: 'rgba(255, 255, 255, 0.8)',
            },
            barGraph: {
                bigNumber: {
                    backgroundColor: '#605f5f',
                    textColor: darkThemeColours.background,
                },
            },
            barGraphComparison: {
                label: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    color: 'rgba(255, 255, 255, 0.8)',
                },
            },
            funnelChart: {
                legendColor: '#000000',
                legendPercentageBackgroundColor: darkThemeColours.background,
            },
            lineGraph: {
                bigNumber: {
                    textColor: '#0a0a0a',
                },
            },
            packedBubbleChart: {
                bigNumber: {
                    textColor: '#0a0a0a',
                },
            },
            sparkLine: {
                varianceTextColor: '#6f6f6f',
                textColor: '#ffffff',
                totalsBackgroundColor: 'rgba(255, 255, 255, 0.2)',
                fontWeight: '500',
                percentage: {
                    backgroundColor: '#ffffff',
                },
            },
            stackedArea: {
                title: {
                    textColor: 'rgba(255, 255, 255, 0.8)',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    fontWeight: '500',
                },
            },
            venn: {
                title: {
                    textColor: 'rgba(255, 255, 255, 0.8)',
                },
                bigNumber: {
                    textColor: '#0a0a0a',
                },
            },
            pieTableComparison: {
                titleColor: 'rgba(255, 255, 255, 0.8)',
            },
            nestedDonutTableComparison: {
                titleColor: 'rgba(255, 255, 255, 0.8)',
                titleFontWeight: '500',
            },
            textBox: {
                textColorDefault: '#ffffff',
                textColor: '#000000',
            },
        },
        tooltip: {
            iconColor: '#ffffff',
            iconBackgroundColor: '#bfbfbf',
            triggerHoverColor: '#1e1c1c',
            contrastColor1: '#1e1c1c',
            contrastColor2: '#ffffff',
            contrastColor3: '#ff7300',
        },
        validation: {
            backgroundColor: '#ffffff',
            shadowColor: '#cbcbcb',
        },
        colour: {
            default: {
                gradientStart: darkThemeColours.dark200,
                gradientEnd: darkThemeColours.dark200,
                splineLine: palette.sky['200'] + '66',
                splineGradientStart: palette.sky['300'] + '66',
                splineGradientEnd: palette.sky['300'] + '00',
                solidGraphColour: palette.sky['700'] + '66',
            },
            red: {
                gradientStart: palette.red['400'] + '66',
                gradientEnd: palette.red['500'] + 'cc',
                splineLine: palette.red['200'] + '4D',
                splineGradientStart: palette.red['300'] + '4D',
                splineGradientEnd: palette.red['300'] + '00',
                solidGraphColour: palette.red['700'] + '66',
            },
            orange: {
                gradientStart: palette.orange['400'] + '66',
                gradientEnd: palette.orange['500'] + 'cc',
                splineLine: palette.orange['200'] + '4D',
                splineGradientStart: palette.orange['300'] + '4D',
                splineGradientEnd: palette.orange['300'] + '00',
                solidGraphColour: palette.orange['700'] + '66',
            },
            amber: {
                gradientStart: palette.amber['400'] + '66',
                gradientEnd: palette.amber['500'] + 'cc',
                splineLine: palette.amber['200'] + '4D',
                splineGradientStart: palette.amber['300'] + '4D',
                splineGradientEnd: palette.amber['300'] + '00',
                solidGraphColour: palette.amber['700'] + '66',
            },
            yellow: {
                gradientStart: palette.yellow['400'] + '66',
                gradientEnd: palette.yellow['500'] + 'cc',
                splineLine: palette.yellow['200'] + '4D',
                splineGradientStart: palette.yellow['300'] + '4D',
                splineGradientEnd: palette.yellow['300'] + '00',
                solidGraphColour: palette.yellow['700'] + '66',
            },
            lime: {
                gradientStart: palette.lime['400'] + '66',
                gradientEnd: palette.lime['500'] + 'cc',
                splineLine: palette.lime['200'] + '4D',
                splineGradientStart: palette.lime['300'] + '4D',
                splineGradientEnd: palette.lime['300'] + '00',
                solidGraphColour: palette.lime['700'] + '66',
            },
            green: {
                gradientStart: palette.green['400'] + '66',
                gradientEnd: palette.green['500'] + 'cc',
                splineLine: palette.green['200'] + '4D',
                splineGradientStart: palette.green['300'] + '4D',
                splineGradientEnd: palette.green['300'] + '00',
                solidGraphColour: palette.green['700'] + '66',
            },
            emerald: {
                gradientStart: palette.emerald['400'] + '66',
                gradientEnd: palette.emerald['500'] + 'cc',
                splineLine: palette.emerald['200'] + '4D',
                splineGradientStart: palette.emerald['300'] + '4D',
                splineGradientEnd: palette.emerald['300'] + '00',
                solidGraphColour: palette.emerald['700'] + '66',
            },
            teal: {
                gradientStart: palette.teal['400'] + '66',
                gradientEnd: palette.teal['500'] + 'cc',
                splineLine: palette.teal['200'] + '4D',
                splineGradientStart: palette.teal['300'] + '4D',
                splineGradientEnd: palette.teal['300'] + '00',
                solidGraphColour: palette.teal['700'] + '66',
            },
            cyan: {
                gradientStart: palette.cyan['400'] + '66',
                gradientEnd: palette.cyan['500'] + 'cc',
                splineLine: palette.cyan['200'] + '4D',
                splineGradientStart: palette.cyan['300'] + '4D',
                splineGradientEnd: palette.cyan['300'] + '00',
                solidGraphColour: palette.cyan['700'] + '66',
            },
            sky: {
                gradientStart: palette.sky['400'] + '66',
                gradientEnd: palette.sky['500'] + 'cc',
                splineLine: palette.sky['200'] + '4D',
                splineGradientStart: palette.sky['300'] + '4D',
                splineGradientEnd: palette.sky['300'] + '00',
                solidGraphColour: palette.sky['700'] + '66',
            },
            blue: {
                gradientStart: palette.blue['400'] + '66',
                gradientEnd: palette.blue['500'] + 'cc',
                splineLine: palette.blue['200'] + '4D',
                splineGradientStart: palette.blue['300'] + '4D',
                splineGradientEnd: palette.blue['300'] + '00',
                solidGraphColour: palette.blue['300'],
            },
            indigo: {
                gradientStart: palette.indigo['400'] + '66',
                gradientEnd: palette.indigo['500'] + 'cc',
                splineLine: palette.indigo['200'] + '4D',
                splineGradientStart: palette.indigo['300'] + '4D',
                splineGradientEnd: palette.indigo['300'] + '00',
                solidGraphColour: palette.indigo['300'] + '66',
            },
            violet: {
                gradientStart: palette.violet['400'] + '66',
                gradientEnd: palette.violet['500'] + 'cc',
                splineLine: palette.violet['200'] + '4D',
                splineGradientStart: palette.violet['300'] + '4D',
                splineGradientEnd: palette.violet['300'] + '00',
                solidGraphColour: palette.violet['300'] + '66',
            },
            purple: {
                gradientStart: palette.purple['400'] + '66',
                gradientEnd: palette.purple['500'] + 'cc',
                splineLine: palette.purple['200'] + '4D',
                splineGradientStart: palette.purple['300'] + '4D',
                splineGradientEnd: palette.purple['300'] + '00',
                solidGraphColour: palette.purple['200'] + '66',
            },
            fuchsia: {
                gradientStart: palette.fuchsia['400'] + '66',
                gradientEnd: palette.fuchsia['500'] + 'cc',
                splineLine: palette.fuchsia['200'] + '4D',
                splineGradientStart: palette.fuchsia['300'] + '4D',
                splineGradientEnd: palette.fuchsia['300'] + '00',
                solidGraphColour: palette.fuchsia['300'] + '66',
            },
            pink: {
                gradientStart: palette.pink['400'] + '66',
                gradientEnd: palette.pink['500'] + 'cc',
                splineLine: palette.pink['200'] + '4D',
                splineGradientStart: palette.pink['300'] + '4D',
                splineGradientEnd: palette.pink['300'] + '00',
                solidGraphColour: palette.pink['300'] + '66',
            },
            rose: {
                gradientStart: palette.rose['400'] + '66',
                gradientEnd: palette.rose['500'] + 'cc',
                splineLine: palette.rose['200'] + '4D',
                splineGradientStart: palette.rose['300'] + '4D',
                splineGradientEnd: palette.rose['300'] + '00',
                solidGraphColour: palette.rose['200'] + '66',
            },
            slate: {
                gradientStart: palette.slate['400'] + '66',
                gradientEnd: palette.slate['500'] + 'cc',
                splineLine: palette.slate['200'] + '4D',
                splineGradientStart: palette.slate['300'] + '4D',
                splineGradientEnd: palette.slate['300'] + '00',
                solidGraphColour: palette.slate['300'] + '66',
            },
        },
    },
    transition: '0.2s ease-in-out;',
    tabGradient: 'linear-gradient(#eaeaea, #e3e3e3, #e3e3e3, #e3e3e3, #cdcdd1);',
    skeletonLoader: {
        animationDuration: '5s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: 'skeletonGradient',
        animationTimingFunction: 'linear',
        background: '#f1f1f1',
        backgroundSize: '100vw 100vw',
    },
    skeletonLoaderGradient: {
        animationDuration: '5s',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
        animationName: 'skeletonGradient',
        animationTimingFunction: 'linear',
        background: 'linear-gradient(to right, #f1f1f1, #cbcbcb, #f1f1f1)',
        backgroundSize: '100vw 100vw',
    },
    buttons: {
        primary: {
            backgroundColour: '#ff842d',
            backgroundColourDisabled: 'rgba(255, 255, 255, 0.4)',
            color: '#1e1c1c',
            colorDisabled: 'rgba(255, 255, 255, 0.7)',
            fontWeight: '600',
        },
        secondary: {
            backgroundColour: 'transparent',
            backgroundColourDisabled: 'transparent',
            color: 'rgba(255, 255, 255, 0.7)',
            colorDisabled: 'rgba(255, 255, 255, 0.4)',
            fontWeight: '600',
        },
        secondaryLight: {
            backgroundColour: 'transparent',
            backgroundColourDisabled: 'transparent',
            color: 'rgba(238, 238, 238, 1)',
            colorDisabled: 'rgba(255, 255, 255, 0.4)',
            fontWeight: '600',
        },
        red: {
            backgroundColour: 'rgba(210, 0, 74, 1)',
            backgroundColourDisabled: 'rgba(210, 0, 74, 0.4)',
            color: 'rgba(255, 255, 255, 1)',
            colorDisabled: 'rgba(255, 255, 255, 1)',
            fontWeight: '600',
        },
        redSecondary: {
            backgroundColour: 'transparent',
            backgroundColourDisabled: 'transparent',
            color: 'rgba(210, 0, 74, 1)',
            colorDisabled: 'rgba(210, 0, 74, 0.4)',
            fontWeight: '600',
        },
        green: {
            backgroundColour: 'rgba(76, 175, 80, 1)',
            backgroundColourDisabled: 'rgba(76, 175, 80, 0.4)',
            color: 'rgba(255, 255, 255, 1)',
            colorDisabled: 'rgba(255, 255, 255, 1)',
            fontWeight: '600',
        },
        greenSecondary: {
            backgroundColour: 'transparent',
            backgroundColourDisabled: 'transparent',
            color: 'rgba(76, 175, 80, 1)',
            colorDisabled: 'rgba(76, 175, 80, 0.4)',
            fontWeight: '600',
        },
    },
    forms: {
        inputs: {
            backgroundColor: darkThemeColours.dark300,
            backgroundColorDisabled: darkThemeColours.dark200,
            textColor: '#ffffff',
            textColorDisabled: 'rgba(255, 255, 255, 0.6)',
            placeHolderColor: 'rgba(255, 255, 255, 0.6)',
            accentColor: 'rgba(255, 255, 255, 0.5)',
            accentColorDisabled: 'rgba(255, 255, 255, 0.2)',
            inputToggle: {
                backgroundColor: '#8a8a8a',
                backgroundColorChecked: darkThemeColours.dark300,
                backgroundColorDisabled: darkThemeColours.dark300,
                indicatorColor: '#ffffff',
                indicatorColorDisabled: '#bfbfbf',
            },
            inputCheckbox: {
                accentColor: '#ffffff',
            },
        },
        labels: {
            color: '#ffffff',
        },
        errorMessage: {
            textColor: '#ff6666',
        },
        sections: {
            titleColor: '#ffffff',
        },
    },
    popover: {
        trigger: {
            color: 'rgba(255, 255, 255, 0.8)',
        },
        content: {
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: darkThemeColours.menuBackground,
            shadow: '0 1px 5px rgba(255, 255, 255, 0.05), 0 1px 10px rgba(255, 255, 255, 0.05)',
        },
    },
    dialog: {
        trigger: {
            backgroundColor: {
                primary: '#0a0a0a',
                loading: '#bfbfbf',
                warning: '#d2004a',
            },
        },
        content: {
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: darkThemeColours.menuBackground,
            shadow: '0 1px 5px rgba(255, 255, 255, 0.05), 0 1px 10px rgba(255, 255, 255, 0.05)',
        },
    },
    dashboardBuilder: {
        container: {
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: darkThemeColours.dark200,
        },
        createAWidget: {
            preview: {
                backgroundColor: darkThemeColours.dark100,
            },
        },
        createADashboard: {
            preview: {
                borderColor: darkThemeColours.dark300,
                removeButton: {
                    color: '#ffffff',
                    backgroundColor: 'transparent',
                },
            },
            sidebar: {
                dashboardTitleColor: 'rgba(255, 255, 255, 0.9)',
                droppable: {
                    color: 'rgba(255, 255, 255, 0.8)',
                    backgroundColor: darkThemeColours.dark200,
                },
                draggable: {
                    color: 'rgba(255, 255, 255, 0.8)',
                    borderColor: darkThemeColours.dark300,
                    backgroundColor: darkThemeColours.dark100,
                },
                sections: {
                    color: 'rgba(255, 255, 255, 0.8)',
                    titleColor: 'rgba(255, 255, 255, 0.9)',
                    titleFontWeight: '500',
                },
            },
        },
        library: {
            libraryList: {
                categoryTitleColor: '#ffffff',
                categoryTitleFontWeight: '500',
                listItemBorderColor: darkThemeColours.dark100,
                listItemTextColor: 'rgba(255, 255, 255, 0.8)',
                listItemCellTitleColor: 'rgba(255, 255, 255, 0.4)',
                listItemTitleColor: 'rgba(255, 255, 255, 0.8)',
            },
        },
    },
};

export default darkTheme;
