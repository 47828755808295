import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType } from './common/chart-types';

export const config = {
    pageTitle: 'Error Reporting',
    dateDimension: fields.DATE_DATETIME,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: true,
        },
    },
    resources: [resources.REPORT_VALIDATION_MESSAGE],
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: [],
        chartId: 'report-validation-message',
        allYMetrics: [
            fields.DATE_DATETIME,
            fields.ERROR_NAME,
            fields.ERROR_MESSAGE,
            fields.HOST,
            fields.PATH,
            fields.ERROR_COUNT,
        ],
        initialYMetrics: [fields.ERROR_COUNT],
    },
    table: {
        tabListItems: [
            {
                tabName: 'overview',
                displayName: 'Overview',
                isSelected: true,
                metrics: [fields.ERROR_COUNT],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.ERROR_NAME.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_VALIDATION_MESSAGE,
                        field: fields.ERROR_MESSAGE,
                    },
                ],
            },
            [fields.ERROR_MESSAGE.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_VALIDATION_MESSAGE,
                        field: fields.HOST,
                    },
                ],
            },
            [fields.HOST.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_VALIDATION_MESSAGE,
                        field: fields.PATH,
                    },
                ],
            },
            [fields.PATH.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_VALIDATION_MESSAGE,
                        field: fields.PATH,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
