import tinycolor from 'tinycolor2';
import { Moment } from 'moment';

// Helpers
import { newColourAsRgba } from '../../helpers/colours';
import { getRawValueAsNumber, getValue } from './resource-data';
import { createDateRange } from './create-date-range';
import dateStringToUnix from '../../helpers/date-string-to-unix';

// Types
import { AreaYAxisOption, StackedAreaChartAreaSeries, StackedAreaChartOptionalSeries, SuccessData } from '../types';
import { ResourceDataSuccess } from '../../react-query/types';
import { CubedField } from '../../types';
import { StackedAreaComparisonYAxisValue } from '../hooks/widget-comparison/use-resource-stacked-area-chart-comparison';
import { DefaultTheme } from 'styled-components';

export type StackedAreaOptionalChartSeries = {
    name: string;
    data: { x: number; y: number }[];
    type: string;
    color: string;
    marker: {
        fillColor: string;
    };
    visible: boolean;
};

// Gets the unique Y Axis values from the resource data
export const getUniqueYAxisValues = (resource: SuccessData<ResourceDataSuccess>, areaSeriesMetric: CubedField) => {
    return [
        ...new Set(
            resource.objects.map(data => {
                return getValue(data, areaSeriesMetric.rawName);
            })
        ),
    ];
};

// Maps the data to the unique Y Axis values
export const mapAreaSeriesData = (
    uniqueYAxisValues: StackedAreaComparisonYAxisValue[],
    resource: SuccessData<ResourceDataSuccess>,
    areaSeriesMetric: CubedField,
    yAxisOptions: AreaYAxisOption[],
    dateDimension: CubedField,
    dates: {
        startDate: Moment;
        endDate: Moment;
    },
    seriesLimit?: number
) => {
    const { startDate, endDate } = dates;
    const areaSeries: StackedAreaChartAreaSeries[] = [];

    if (seriesLimit) {
        uniqueYAxisValues = uniqueYAxisValues.slice(0, seriesLimit);
    }

    uniqueYAxisValues.forEach(value => {
        areaSeries.push({
            name: value.name,
            chartType: 'area',
            data: [],
            total: 0,
            colour: value.colour,
            visible: true,
        });
    });

    resource.objects.forEach(data => {
        areaSeries.forEach(series => {
            if (series.name === getValue(data, areaSeriesMetric.rawName)) {
                const rawValue = getRawValueAsNumber(
                    data,
                    yAxisOptions.filter(option => option.active)[0].field.rawName
                );
                series.data.push({
                    x: dateStringToUnix(getValue(data, dateDimension.rawName)),
                    y: rawValue,
                });
                series.total = series.total ? (series.total += rawValue) : 0;
            }
        });
    });

    // interpolate each series as required
    const dateRange = createDateRange(startDate, endDate);

    areaSeries.forEach(series => {
        dateRange.forEach(date => {
            const dateUnix = dateStringToUnix(date);
            const matchingDate = series.data.find(data => data.x === dateUnix);

            if (!matchingDate) {
                series.data.push({
                    x: dateUnix,
                    y: 0,
                });
            }
        });
    });

    return areaSeries;
};

// Builds the area series for display in the chart
export const buildAreaSeries = (series: StackedAreaChartAreaSeries[], theme: DefaultTheme) => {
    return series.map(series => {
        let fillColor = {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
                [0, newColourAsRgba(tinycolor(series.colour.solidGraphColour), theme.name === 'dark' ? 0.2 : 0.6)],
                [1, newColourAsRgba(tinycolor(series.colour.solidGraphColour), 0.2)],
            ],
        };

        return {
            name: series.name,
            data: series.data,
            type: series.chartType,
            marker: { fillColor: series.colour.solidGraphColour },
            color: series.colour.solidGraphColour,
            fillColor,
            visible: series.visible,
            yAxis: 0,
        };
    });
};

// Builds the optional series for display in the chart
export const buildOptionalSeries = (series: StackedAreaChartOptionalSeries[]) => {
    return series.map(series => {
        return {
            name: series.name,
            data: series.data,
            type: series.chartType,
            color: series.colour.gradientStart,
            marker: { fillColor: series.colour.gradientStart },
            visible: series.visible,
            yAxis: 1,
        };
    });
};

// Builds the title for display on the Y Axis depending on the visible series
const buildYAxisTitle = (series: StackedAreaOptionalChartSeries[]): string[] => {
    let titles: string[] = [];
    series.forEach(series => {
        if (series.visible) {
            titles.push(series.name);
        }
    });
    return titles;
};

// Builds the configuration for the stacked area chart Y Axis
export const buildYAxisConfig = (series: StackedAreaOptionalChartSeries[], theme: DefaultTheme) => {
    if (series.length > 0) {
        return {
            title: {
                text: buildYAxisTitle(series).join(', '),
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: '0.7em',
                },
            },
            labels: {
                enabled: true,
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: '0.7em',
                },
            },
            gridLineWidth: 0,
            opposite: true,
        };
    } else {
        return { title: null };
    }
};

// Handles setting the series visible status when clicking chart legend
export const handleUpdateVisible = <T extends { name: string; visible: boolean }>(series: T[], seriesName: string) => {
    const updatedSeries = series.map(series => {
        if (series.name === seriesName) {
            return {
                ...series,
                visible: !series.visible,
            };
        }
        return series;
    });

    return updatedSeries;
};
