import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled, { css } from 'styled-components';

// Types
import { FilterBarHandlerProps } from '../types';

// Images
import imageFilterRegular from '../../assets/images/icons/filter-regular.svg';
import imagePlus from '../../assets/images/icons/plus.svg';
import imageMinus from '../../assets/images/icons/minus.svg';

// Styled Components
const StyledReportFilterHandler = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledReportFilterStatus = styled.div`
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
`;

const StyledReportFilterStatusIcon = styled.div`
    display: inline-block;
    margin-right: 8px;
    width: 15px;
    height: 15px;
    background-image: url(${imageFilterRegular});
    background-size: contain;
    background-repeat: no-repeat;
`;

const StyledReportFilterAdd = styled.div`
    display: flex;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
`;

const StyledReportFilterAddIcon = css`
    display: inline-block;
    margin-left: 8px;
    width: 15px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
`;

const StyledReportFilterAddExpandIcon = styled.div`
    ${StyledReportFilterAddIcon}
    background-image: url(${imagePlus});
`;

const StyledReportFilterAddCollapseIcon = styled.div`
    ${StyledReportFilterAddIcon}
    background-image: url(${imageMinus});
`;

const StyledReportFilterClearButton = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
`;

const FilterBarHandler = ({
    filterStatus,
    selectedMetricsArray,
    handleFilterToggle,
    filterExpanded,
    handleFilterClearOnClick,
}: FilterBarHandlerProps) => {
    return (
        <StyledReportFilterHandler>
            {filterStatus.isEnableMetrics && (
                <>
                    <StyledReportFilterStatus onClick={() => handleFilterToggle()}>
                        <StyledReportFilterStatusIcon />
                        {selectedMetricsArray.length} Filters
                    </StyledReportFilterStatus>
                    <span>|</span>

                    <StyledReportFilterAdd onClick={() => handleFilterToggle()}>
                        Add
                        {!filterExpanded ? <StyledReportFilterAddExpandIcon /> : <StyledReportFilterAddCollapseIcon />}
                    </StyledReportFilterAdd>
                </>
            )}

            {filterStatus.isEnableMetrics && selectedMetricsArray.length > 0 && !filterExpanded && (
                <>
                    <span>|</span>
                    <StyledReportFilterClearButton onClick={handleFilterClearOnClick}>
                        Clear
                    </StyledReportFilterClearButton>
                </>
            )}
        </StyledReportFilterHandler>
    );
};

export default memo(FilterBarHandler, isEqual);
