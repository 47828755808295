import React from 'react';
import styled from 'styled-components';

// Helpers
import { formatNumber } from '../helpers/format-number';

// Types
import { WidgetDirection } from '../types';

const StyledBox = styled.div`
    flex-grow: 1;
    position: relative;
    background: ${props => props.theme.sectionDashboard.widget.bigNumber.backgroundColor};
`;

const StyledBoxBackgroundPercentage = styled.div<{ direction: WidgetDirection; percentageHeight: string }>`
    background-color: ${props => props.color};
    height: ${props => (props.direction === 'horizontal' ? props.percentageHeight : '100%')};
    width: ${props => (props.direction === 'vertical' ? props.percentageHeight : '100%')};
    position: absolute;
    bottom: 0;
`;

const StyledValueContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const StyledBigNumber = styled.div`
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    width: 100%;
    color: ${props => props.theme.sectionDashboard.widget.bigNumber.bigNumberBar.textColor};
`;

const StyledTitleContainer = styled.div`
    text-align: center;
    width: 100%;
`;

const StyledTitle = styled.p`
    margin: 2px 0px;
    color: ${props => props.theme.sectionDashboard.widget.bigNumber.bigNumberBar.textColor};
    font-size: 0.8rem;
    font-weight: ${props => props.theme.sectionDashboard.widget.bigNumber.bigNumberBar.titleFontWeight};
`;

export type SectionDashboardBigNumberBarProps = {
    index: number;
    percentage: number;
    direction: WidgetDirection;
    name: string;
    color: string;
    value: number;
    chartLabel?: string;
};

const SectionDashboardBigNumberBar = ({
    index,
    percentage,
    direction,
    name,
    color,
    value,
    chartLabel,
}: SectionDashboardBigNumberBarProps) => {
    const renderChartLabel = (chartLabel: string) => (
        <StyledTitleContainer>
            <StyledTitle>{chartLabel}</StyledTitle>
        </StyledTitleContainer>
    );

    return (
        <StyledBox key={index}>
            <StyledBoxBackgroundPercentage percentageHeight={`${percentage}%`} direction={direction} color={color} />
            <StyledValueContainer>
                {renderChartLabel(name)}
                <StyledBigNumber>{formatNumber(value, 1)}</StyledBigNumber>
                {chartLabel && renderChartLabel(chartLabel)}
            </StyledValueContainer>
        </StyledBox>
    );
};

export default SectionDashboardBigNumberBar;
