import React, { useState } from 'react';
import tinycolor from 'tinycolor2';
import moment from 'moment';
import styled from 'styled-components';
import { intToColour } from '../../helpers/colours';

// Components
import LocationMap from '../components/location-map';
import ProgressBar from '../../components/progress-bar';

// Icons
import IconWorldGrid from '../../assets/images/icons/001-grid-world.svg';
import IconMobile from '../../assets/images/icons/mobile-icon.svg';
import IconPC from '../../assets/images/icons/pc-icon.svg';

const StyledOverviewTimes = styled.div`
    display: inline-block;
    height: 100%;
    line-height: 24px;
`;

const StyledOverviewCopy = styled.span`
    padding: 0 6px;
    font-size: ${props => props.small && '10px'};
    color: ${props => props.sale && props.theme.colours.light};
`;

const StyledOverviewLocation = styled.div`
    transition: ${props => props.theme.transition};
    background-color: red;
    background: url(${IconWorldGrid}) no-repeat center center;
    background-size: 24px;
    height: 24px;
    width: 24px;
    display: inline-block;
    position: relative;
    top: 6px;
`;

const StyledPropensityBarContainer = styled.div`
    right: 0;
    max-width: 280px;
    min-width: 280px;
    display: inline-block;
    height: 100%;
    background-color: white;
    position: absolute;
    padding: 0 15px;
`;

const StyledChannel = styled.div`
    display: inline-block;
    height: 100%;
    min-width: 180px;
    padding: 6px;
    line-height: 24px;
    background-color: ${props => props.backgroundColor && props.backgroundColor};
`;

const StyledLocationMap = styled.div`
    ${props => props.theme.boxShadow(1)};
    position: absolute;
    width: 360px;
    height: 200px;
    z-index: 11;
    top: 0px;
    left: 0px;
`;

const StyledDevice = styled.div`
    color: ${props => props.theme.colours.white};
    transition: $transition;
    height: 24px;
    width: 24px;
    background: ${props =>
        props.device === 'PC'
            ? `url(${IconPC}) no-repeat center center`
            : `url(${IconMobile}) no-repeat center center`};
    background-size: 24px;
    margin-right: 12px;
    float: left;
    position: relative;
`;

const StyledDeviceInfo = styled.div`
    padding: 12px;
    ${props => props.theme.boxShadow(1)}
    background-color: ${props => props.theme.colours.light};
    color: ${props => props.theme.colours.textDefault};
    position: absolute;
    z-index: 11;
    top: 0px;
    left: 0px;
`;

const StyledDeviceLabel = styled.span`
    display: block;
    color: ${props => props.theme.colours.offBlackLighter};
    font-weight: 600;
`;

const StyledDeviceCopy = styled.span`
    white-space: nowrap;
`;

const StyledChannelName = styled.div`
    display: inline-block;
    color: ${props => props.theme.colours.white};
`;

const TimeData = React.memo(({ visit }) => {
    const [showMap, setShowMap] = useState(false);
    const [shouldShowDevices, setShouldShowDevices] = useState(false);

    const showLocationMap = () => setShowMap(true);
    const hideLocationMap = () => setShowMap(false);
    const showDeviceInfo = () => {
        setShouldShowDevices(true);
    };
    const hideDeviceInfo = () => {
        setShouldShowDevices(false);
    };

    const RenderMap = () => {
        const duration = moment.duration(moment(visit.last_visit).diff(visit.first_visit)).asMilliseconds();
        const format = duration > 60 * 60 * 1000 ? 'h[h], m[m], s[s]' : 'm[m], s[s]';
        return (
            <StyledOverviewTimes>
                <StyledOverviewCopy small sale={visit.total_sales > 0 ? true : false}>
                    on
                </StyledOverviewCopy>
                <StyledOverviewCopy sale={visit.total_sales > 0 ? true : false}>
                    {moment(visit.first_visit).format("ddd, Do MMM 'YY")}
                </StyledOverviewCopy>
                <StyledOverviewCopy small sale={visit.total_sales > 0 ? true : false}>
                    {visit.is_impression ? 'at' : 'for'}
                </StyledOverviewCopy>
                <StyledOverviewCopy sale={visit.total_sales > 0 ? true : false}>
                    {visit.is_impression
                        ? moment(visit.first_visit).format('h:mm:ss a')
                        : moment(duration).format(format)}
                </StyledOverviewCopy>

                {visit.location?.lat && visit.location?.lng && (
                    <>
                        <StyledOverviewCopy small sale={visit.total_sales > 0 ? true : false}>
                            from
                        </StyledOverviewCopy>
                        <StyledOverviewLocation onMouseEnter={showLocationMap} onMouseLeave={hideLocationMap}>
                            {showMap ? (
                                <StyledLocationMap onClick={e => e.stopPropagation()}>
                                    <LocationMap locations={[visit.location]} />
                                </StyledLocationMap>
                            ) : null}
                        </StyledOverviewLocation>
                    </>
                )}
            </StyledOverviewTimes>
        );
    };
    const RenderAveragePropensity = () => {
        if (!visit || !visit.score) return null;

        return (
            <StyledPropensityBarContainer>
                <ProgressBar value={visit.score.max_score} difference={visit.score.delta} label="Visit Performance" />
            </StyledPropensityBarContainer>
        );
    };

    const RenderChannelData = () => {
        return (
            <StyledChannel
                backgroundColor={
                    visit.is_impression
                        ? tinycolor(intToColour(visit.colour)).toHexString()
                        : tinycolor(intToColour(visit.channel.colour)).toHexString()
                }
            >
                <StyledDevice device={visit.device_type} onMouseEnter={showDeviceInfo} onMouseLeave={hideDeviceInfo}>
                    {shouldShowDevices ? (
                        <StyledDeviceInfo>
                            <div>
                                <StyledDeviceLabel>Device: </StyledDeviceLabel>
                                <StyledDeviceCopy>{visit.device}</StyledDeviceCopy>
                            </div>
                            <div>
                                <StyledDeviceLabel>Operating System:</StyledDeviceLabel>
                                <StyledDeviceCopy>{visit.os}</StyledDeviceCopy>
                            </div>
                            <div>
                                <StyledDeviceLabel>Browser:</StyledDeviceLabel>
                                <StyledDeviceCopy>{visit.browser}</StyledDeviceCopy>
                            </div>
                        </StyledDeviceInfo>
                    ) : null}
                </StyledDevice>

                <StyledChannelName>
                    <StyledOverviewCopy small>{visit.is_impression ? 'saw' : 'via'}</StyledOverviewCopy>
                    <StyledOverviewCopy>{visit.is_impression ? visit.campaign : visit.channel.name}</StyledOverviewCopy>
                    {visit.is_impression && (
                        <StyledOverviewCopy small>{`x ${visit.impression_count}`}</StyledOverviewCopy>
                    )}
                    {visit?.channel?.has_subchannels && visit?.subchannel && visit?.subchannel.subchannel ? (
                        <StyledOverviewCopy>{`(${visit.subchannel.subchannel.name})`}</StyledOverviewCopy>
                    ) : null}
                </StyledChannelName>
            </StyledChannel>
        );
    };

    return (
        <>
            <RenderChannelData />
            <RenderMap />
            <RenderAveragePropensity />
        </>
    );
});

export default TimeData;
