import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import SimpleTableHeaderRow from './rows/simple-table-header-row';
import SimpleTableBody from './body/simple-table-body';
import LoadingSpinner from '../../loading-spinner';
import Message from '../../message';

// Helpers
import { getTotal } from '../helpers/table-utils';

// Types
import { orderByDir, SimpleTableColumn, SimpleTableHeader, SimpleTableRow } from '../types';

// Styles
const StyledSimpleTableWrapper = styled.div<{ customHeight?: string }>`
    background-color: ${props => props.theme.colours.whiteBg};
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 2px;
    height: ${props => props.customHeight && props.customHeight};
`;

const StyledSimpleTable = styled.table`
    width: 100%;
    text-align: left;
`;

const StyledSimpleTableError = styled.div`
    margin-top: 20px;
`;

// Types
export type SimpleTableProps = {
    id?: string;
    hasTotals?: boolean;
    rows: SimpleTableRow[];
    isLoading?: boolean;
    hasIcons?: boolean;
    allowOrdering?: boolean;
    onRowClick?: (event: React.MouseEvent<HTMLTableRowElement>, row: SimpleTableRow) => void;
    selectDisabled?: boolean;
    selectableRows?: boolean;
    disableOrdering?: boolean;
    onColumnOrderChanged?: (index: number, order: orderByDir) => void;
    currentOrderBy?: { column: number | null; order: orderByDir | null };
    header?: SimpleTableHeader;
    columns?: SimpleTableColumn[];
    onOrderChanged?: (rows: SimpleTableRow[], itemMoved: SimpleTableRow) => void;
    errorMessageOverride?: string;
    isScrollable: boolean;
    hasEndAction?: boolean;
    hasPills?: boolean;
    customHeight?: string;
};

const SimpleTable = ({
    id,
    hasTotals,
    rows,
    isLoading,
    hasIcons,
    allowOrdering,
    onRowClick,
    selectDisabled,
    selectableRows,
    disableOrdering,
    onColumnOrderChanged,
    currentOrderBy,
    header,
    columns,
    onOrderChanged,
    errorMessageOverride,
    isScrollable = false,
    hasEndAction,
    hasPills,
    customHeight,
}: SimpleTableProps) => {
    const [rowsState, setRowsState] = useState(rows);
    const [errorMessage, setErrorMessage] = useState('There are no items in this table.');
    const [totalSales, setTotalSales] = useState(
        rowsState.length > 0 && hasTotals ? getTotal(rowsState, 'forSales') : 0
    );
    const [totalRevenue, setTotalRevenue] = useState(
        rowsState.length > 0 && hasTotals ? getTotal(rowsState, 'forRevenue') : 0
    );

    useEffect(() => {
        setRowsState(rows);
    }, [rows]);

    useEffect(() => {
        if (hasTotals) {
            setTotalSales(getTotal(rows, 'forSales'));
            setTotalRevenue(getTotal(rows, 'forRevenue'));
        }
    }, [rows, hasTotals]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setErrorMessage(
            errorMessageOverride !== undefined ? errorMessageOverride : 'There are no items in this table.'
        );
    }, [errorMessageOverride]);

    const reorderRows = (oldIndex: number, newIndex: number) => {
        let rows = [...rowsState];
        const itemMoved = rows[oldIndex];

        if (newIndex >= rows.length) {
            var k = newIndex - rows.length + 1;
            while (k--) {
                rows.push(undefined!);
            }
        }

        rows.splice(newIndex, 0, rows.splice(oldIndex, 1)[0]);

        setRowsState(rows);

        if (hasTotals) {
            setTotalSales(getTotal(rows, 'forSales'));
            setTotalRevenue(getTotal(rows, 'forRevenue'));
        }

        if (onOrderChanged !== undefined) {
            onOrderChanged(rows, itemMoved);
        }
    };

    // Prevents default on the Drag Over event listener
    const handleOnTableDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleOnTableDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const data = JSON.parse(event.dataTransfer.getData('text/plain'));

        // Clear event DataTransfer
        event.dataTransfer.clearData();

        if (data.id !== id) {
            return;
        }

        reorderRows(data.rowNumber, rowsState.length - 1);
    };

    const renderHeader = () => (
        <SimpleTableHeaderRow
            header={header}
            columns={columns}
            hasIcons={hasIcons}
            hasEndAction={hasEndAction}
            hasPills={hasPills}
            allowOrdering={allowOrdering}
            currentOrderBy={currentOrderBy}
            handleOnColumnOrderChanged={onColumnOrderChanged}
        />
    );

    if (isLoading) {
        return (
            <StyledSimpleTableWrapper customHeight={customHeight}>
                <StyledSimpleTable>{renderHeader()}</StyledSimpleTable>
                <StyledSimpleTableError>
                    <LoadingSpinner />
                </StyledSimpleTableError>
            </StyledSimpleTableWrapper>
        );
    }

    if (rows.length === 0) {
        return (
            <StyledSimpleTableWrapper customHeight={customHeight}>
                <StyledSimpleTable>{renderHeader()}</StyledSimpleTable>
                <StyledSimpleTableError>
                    <Message title="" type="info" copy={errorMessage} size="small" />
                </StyledSimpleTableError>
            </StyledSimpleTableWrapper>
        );
    }

    return (
        <StyledSimpleTableWrapper customHeight={customHeight}>
            <StyledSimpleTable id={id}>
                {renderHeader()}

                <SimpleTableBody
                    id={id}
                    rowsState={rowsState}
                    handleOnTableDrop={handleOnTableDrop}
                    handleOnTableDragOver={handleOnTableDragOver}
                    isScrollable={isScrollable}
                    hasPills={hasPills}
                    hasTotals={hasTotals}
                    totalSales={totalSales}
                    totalRevenue={totalRevenue}
                    hasIcons={hasIcons}
                    selectableRows={selectableRows}
                    disableOrdering={disableOrdering}
                    selectDisabled={selectDisabled}
                    allowOrdering={allowOrdering}
                    hasEndAction={hasEndAction}
                    onRowClick={onRowClick}
                    reorderRows={reorderRows}
                />
            </StyledSimpleTable>
        </StyledSimpleTableWrapper>
    );
};

export default SimpleTable;
