import React from 'react';
import Form from '../../../../components/forms/form';
import validators from '../../../../components/forms/validation/validators';
import { DropdownOption } from '../../../../section-dashboard/types';

const CompetitorForm = ({ markets }: { markets: DropdownOption[] }) => {
    return (
        <>
            <Form.Body>
                <Form.Section>
                    <Form.Field>
                        <Form.InputLabel
                            htmlFor="brandName"
                            label="Brand Name"
                            required={true}
                            tooltipCopy="The brand name of your competitor"
                        />
                        <Form.InputText name="brandName" validators={[validators.required]} />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputLabel
                            htmlFor="domainName"
                            label="Domain"
                            required={true}
                            tooltipCopy='The domain of your competitor in the format "<domain>.<top level domain>" e.g. competitor.com'
                        />
                        <Form.InputText name="domainName" validators={[validators.required]} />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputLabel htmlFor="market" label="Market" required={true} />
                        <Form.InputSelect name="market" options={markets || []} validators={[validators.required]} />
                    </Form.Field>
                </Form.Section>
            </Form.Body>
        </>
    );
};

export default CompetitorForm;
