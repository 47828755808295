export const funnelChartConfig = {
    chart: {
        type: 'funnel',
    },

    title: {
        text: '',
    },

    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,

                format: '',

                softConnector: true,

                position: 'left',
            },

            center: ['50%', '50%'],

            neckWidth: '30%',

            neckHeight: '25%',

            width: '80%',
        },
    },

    legend: {
        enabled: true,
    },

    series: [],

    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 500,
                },

                chartOptions: {
                    plotOptions: {
                        series: {
                            dataLabels: {
                                inside: true,
                            },

                            center: ['50%', '50%'],

                            width: '100%',

                            height: '100%',
                        },
                    },
                },
            },
        ],
    },

    credits: {
        enabled: false,
    },
};
