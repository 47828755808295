import React from 'react';
import { useContext } from 'react';
import { CreateAWidgetFormContext } from './context/create-a-widget-form-context';
import Form from '../../../components/forms/form';
import validators from '../../../components/forms/validation/validators';
import { WidgetColour } from '../../../section-dashboard/types';
import { useFormContext } from 'react-hook-form';

const CreateAWidgetFormBigNumber = () => {
    // Form Values
    const { watch } = useFormContext();
    const formValues = watch();

    const { allMetrics } = useContext(CreateAWidgetFormContext);

    const colourOptions = Object.keys(WidgetColour).filter(v => isNaN(Number(v)));
    const colourDropdownOptions = colourOptions.map(colour => {
        return {
            label: colour,
            value: colour,
        };
    });

    const aggregationDropdownOptions = [
        {
            label: 'Average',
            value: 'avg',
        },
        {
            label: 'Minimum Number',
            value: 'min',
        },
        {
            label: 'Maximum Number',
            value: 'max',
        },
        {
            label: 'Sum',
            value: 'sum',
        },
    ];

    const formattingDropdownOptions = [
        {
            label: 'None',
            value: '',
        },
        {
            label: 'Currency',
            value: 'currency',
        },
    ];

    return (
        <>
            <Form.Section>
                <Form.SectionTitle>Display Settings</Form.SectionTitle>
                <Form.Field>
                    <Form.InputLabel htmlFor="graphMetric" label="Metric" />
                    <Form.InputSelect
                        name="graphMetric"
                        options={allMetrics}
                        placeholder="Please select a metric"
                        validators={[validators.required]}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.InputLabel htmlFor="aggregation" label="Aggregation" />
                    <Form.InputSelect name="aggregation" options={aggregationDropdownOptions} />
                </Form.Field>
            </Form.Section>
            <Form.Section>
                <Form.SectionTitle>Additional Settings</Form.SectionTitle>
                <Form.Field>
                    <Form.InputLabel htmlFor="widgetColor" label="Color" />
                    <Form.InputSelect
                        name="widgetColor"
                        options={colourDropdownOptions}
                        placeholder="Please select a color"
                    />
                </Form.Field>
                <Form.Field>
                    <Form.InputLabel htmlFor="formatting" label="Formatting" />
                    <Form.InputSelect name="formatting" options={formattingDropdownOptions} />
                </Form.Field>
                <Form.Field>
                    <Form.InputCheckbox name="applyRounding" label="Apply rounding?" />
                </Form.Field>
                {formValues.applyRounding && (
                    <Form.Field>
                        <Form.InputLabel htmlFor="roundingDecimals" label="Round to how many decimals?" />
                        <Form.InputNumber
                            name="roundingDecimals"
                            validators={[validators.min(0), validators.max(10)]}
                        />
                    </Form.Field>
                )}
            </Form.Section>
        </>
    );
};

export default CreateAWidgetFormBigNumber;
