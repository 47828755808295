// React Dependencies
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

// React Query Dependencies
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Redux Dependencies
import { Provider } from 'react-redux';
import Store from './redux/store';

// Import App
import App from './App';

const container = document.getElementById('root');

if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
    Sentry.init({
        environment: process.env.REACT_APP_ENVIRONMENT,
        dsn: 'https://015fc562e9e84bc4af97c4df93d2ee5d@sentry.cubed.engineering/3',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({ maskAllText: false, maskAllInputs: true }),
        ],
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

// Intialize the google tag manager
if (process.env.REACT_APP_ENVIRONMENT === 'PROD') {
    TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
    });
}

// Create a React Query Client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

// Create a root.
const root = createRoot(container);

// Initial render: Render an element to the root.
root.render(
    <Provider store={Store}>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
