/* eslint-disable react-hooks/rules-of-hooks */

import React, { useContext } from 'react';
import theme from '../../../styled-components/theme.styled';

// Context
import { ChannelInsightsDashboardMenuContext } from '../../channel-insights-context/channel-insights-dashboard-menu-context';

// Fields & Resources
import * as fields from '../../../configurations/fields';
import * as resources from '../../../configurations/resources';

// Components
import BeforeGridConfiguredGoals from '../../../section-dashboard/components/sections/before-grid/before-grid-configured-goals';

// Widgets
import WidgetSparkLineComparison from '../../../section-dashboard/widgets/widget-comparison/widget-spark-line-comparison';
import WidgetBigNumberComparison from '../../../section-dashboard/widgets/widget-comparison/widget-big-number-comparison';
import WidgetVennComparison from '../../../section-dashboard/widgets/widget-comparison/widget-venn-comparison';
import WidgetStackedAreaChartComparison from '../../../section-dashboard/widgets/widget-comparison/widget-stacked-area-chart-comparison';
import WidgetPieChartTableComparison from '../../../section-dashboard/widgets/widget-comparison/widget-pie-chart-table-comparison';
import WidgetTableComparison from '../../../section-dashboard/widgets/widget-comparison/widget-table-comparison';

// Widget Menus
import WidgetMenuStackedAreaChart from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-stacked-area-chart';
import WidgetFunnelChartComparison from '../../../section-dashboard/widgets/widget-comparison/widget-funnel-chart-comparison';
import WidgetMenuTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';
import WidgetMenuPieChartTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie-chart-table';

// Hooks
import { useDatesFormatted } from '../../../hooks/use-dates';
import useViewComparison from '../../../section-dashboard/hooks/view/use-view-comparison';
import useResourceComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-comparison';
import useResourceBigNumberComparison from '../../../section-dashboard/hooks/widget-comparison/big-number/use-resource-big-number-comparison';
import useResourceSparkLineComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-spark-line-comparison';
import useResourceTotalComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-total-comparison';
import useResourceVennResourceComparison from '../../../section-dashboard/hooks/widget-comparison/venn/use-resource-venn-resource-comparison';
import useResourceVennComparison from '../../../section-dashboard/hooks/widget-comparison/venn/use-resource-venn-comparison';
import useResourceStackedAreaChartComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-stacked-area-chart-comparison';
import useResourcePieChartTableComparison from '../../../section-dashboard/hooks/widget-comparison/pie-chart-table/use-resource-pie-chart-table-comparison';
import useResourceFunnelComparisonChart from '../../../section-dashboard/hooks/widget-comparison/use-resource-funnel-comparison-chart';
import useResourceTableComparison from '../../../section-dashboard/hooks/widget-comparison/table/use-resource-table-comparison';
import useResourcePieTableMatchColours from '../../../section-dashboard/hooks/widget/pie-chart-table/use-resource-pie-table-match-colours';
import useRequestTable from '../../../section-dashboard/hooks/request/use-request-table';
import useWidgetMenuFooterQuerySelect from '../../../section-dashboard/widgets/menus/hooks/use-widget-menu-footer-query-select';
import useRequestPieChartTable from '../../../section-dashboard/hooks/request/use-request-pie-chart-table';

// Types & Enums
import { FilterOperator, WidgetColour, WidgetMenuObj } from '../../../section-dashboard/types';
import { DatePickerType } from '../../../configurations/enums/date-picker-type';
import useRequestStackedAreaChart from '../../../section-dashboard/hooks/request/use-request-stacked-area-chart';

// Queries
import { defaultGoalsDropdownQuery, goalsDropdownQuery } from '../../../section-dashboard/queries';

export const config = {
    title: 'Channel Insights Dashboard',
    description: '', // TODO description
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableComparisonDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnableButtonGroup: false,
            isEnablePdfExport: true,
            isEnableChannelMultiSelect: false,
            isEnableChannelSingleSelect: {
                enabled: false,
            },
        },
        comparisonStatus: {
            showComparisonDatePicker: true,
            redirectUrl: 'channel-insights-dashboard',
        },
        datePicker: {},
        comparisonDatePicker: {
            datePickerType: DatePickerType.Comparison,
        },
    },
    context: () => {
        // Context
        const menuContext = useContext(ChannelInsightsDashboardMenuContext);
        return { menuContext };
    },
    layouts: {
        xs: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a'
                    'b'
                    'c'
                    'd'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'f'
                    's'
                    'g'
                    'h'
                    'h'
                    'i'
                    'j'
                    'k'
                    'l'
                    'm'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsSectionMenu.menu,
                    grid: `
                    'o'
                    'p'
                    'p'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    menu: (): JSX.Element => config.context().menuContext.channelPerformanceSectionMenu.menu,
                    grid: `
                    'q'
                    'r'
                    'r'
                    `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a b'
                    'c d'

                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'f g'
                    's s'
                    'h h'
                    'h h'
                    'i i'
                    'j j'
                    'k k'
                    'l l'
                    'l l'
                    'm m'
                    'm m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsSectionMenu.menu,
                    grid: `
                    'o o'
                    'o o'
                    'p p'
                    'p p'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    menu: (): JSX.Element => config.context().menuContext.channelPerformanceSectionMenu.menu,
                    grid: `
                    'q q'
                    'q q'
                    'r r'
                    'r r'
                    `,
                },
            ],
        },
        md: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b'
                    'c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'h h h h'
                    'h h h h'
                    'i i f s'
                    'k k g g'
                    'l l l l'
                    'l l l l'
                    'm m m m'
                    'm m m m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n n n n'
                    'n n n n'
                    'n n n n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsSectionMenu.menu,
                    grid: `
                    'o o o o'
                    'o o o o'
                    'p p p p'
                    'p p p p'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    menu: (): JSX.Element => config.context().menuContext.channelPerformanceSectionMenu.menu,
                    grid: `
                    'q q q q'
                    'q q q q'
                    'r r r r'
                    'r r r r'
                   `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a a b b b'
                    'a a a b b b'
                    'c c c d d d'
                    'c c c d d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'h h h h f i'
                    'h h h h s j'
                    'h h h h g k'
                    'l l l l l l'
                    'l l l l l l'
                    'l l l l l l'
                    'm m m m m m'
                    'm m m m m m'
                    'm m m m m m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n n n n n n'
                    'n n n n n n'
                    'n n n n n n'
                    'n n n n n n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsSectionMenu.menu,
                    grid: `
                    'o o o o o o'
                    'o o o o o o'
                    'o o o o o o'
                    'p p p p p p'
                    'p p p p p p'
                    'p p p p p p'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    menu: (): JSX.Element => config.context().menuContext.channelPerformanceSectionMenu.menu,
                    grid: `
                    'q q q q q q'
                    'q q q q q q'
                    'q q q q q q'
                    'r r r r r r'
                    'r r r r r r'
                    'r r r r r r'
                   `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b c c d d'
                    'a a b b c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    grid: `
                    'h h h h h h f i'
                    'h h h h h h s j'
                    'h h h h h h g k'
                    'l l l l l l l l'
                    'l l l l l l l l'
                    'l l l l l l l l'
                    'm m m m m m m m'
                    'm m m m m m m m'
                    'm m m m m m m m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    grid: `
                    'n n n n n n n n'
                    'n n n n n n n n'
                    'n n n n n n n n'
                    'n n n n n n n n'
                    `,
                },
                {
                    title: 'Goal Completions - Summary by Channel',
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsSectionMenu.menu,
                    grid: `
                    'o o o o o o o o'
                    'o o o o o o o o'
                    'o o o o o o o o'
                    'p p p p p p p p'
                    'p p p p p p p p'
                    'p p p p p p p p'
                    `,
                },
                {
                    title: 'Channel Performance - Summary by Goal',
                    menu: (): JSX.Element => config.context().menuContext.channelPerformanceSectionMenu.menu,
                    grid: `
                    'q q q q q q q q'
                    'q q q q q q q q'
                    'q q q q q q q q'
                    'r r r r r r r r'
                    'r r r r r r r r'
                    'r r r r r r r r'
                   `,
                },
            ],
        },
    },
    components: () => {
        // Context
        const { menuContext } = config.context();

        const { goalCompletionsSectionMenu, channelPerformanceSectionMenu } = menuContext;

        // Dates for Venn & Funnel Filters
        // This Period Dates
        const { startDate, endDate } = useDatesFormatted({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
        });

        // Comparison Period Dates
        const { startDate: comparisonStartDate, endDate: comparisonEndDate } = useDatesFormatted({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
            isComparison: true,
        });

        // Sparklines
        const goalSnapshotData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                isGraph: true,
                groupBy: fields.SALES_DATE_DATE,
                graphGroupBy: [fields.SALES_DATE_DATE],
                graphMetrics: [
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
                selectedFields: [
                    fields.SALES_DATE_DATE,
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
            },
        });

        // Sparkline Totals
        const sparkTotalData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                totals: true,
                selectedFields: [
                    fields.SALES_DATE_DATE,
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
            },
        });

        const sparkTotals = useResourceTotalComparison({
            resourceData: sparkTotalData,
        });

        // Visits Sparkline
        const visitsSpark = useResourceSparkLineComparison({
            resourceData: goalSnapshotData,
            metric: fields.VISITS,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Sales Sparkline
        const salesSpark = useResourceSparkLineComparison({
            resourceData: goalSnapshotData,
            metric: fields.FM_SALES,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Revenue Sparkline
        const revenueSpark = useResourceSparkLineComparison({
            resourceData: goalSnapshotData,
            metric: fields.FM_REVENUE,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Cost Sparkline
        const costSpark = useResourceSparkLineComparison({
            resourceData: goalSnapshotData,
            metric: fields.COST,
            dateDimension: fields.SALES_DATE_DATE,
        });

        //Big Numbers
        const goalSnapshotDefaultGoalTotalsData = (menu: WidgetMenuObj) =>
            useResourceComparison({
                resource: resources.REPORT_MCS_CHANNEL,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                    ...menu.request,
                    filters: [
                        ...(menu.request.filters ?? []),
                        { field: fields.PRODUCT_DEFAULT, operator: FilterOperator.EQUALS, value: '1' },
                    ],
                    selectedFields: [fields.PRODUCT, fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                },
            });

        // Default Goal Goals Completed
        const defaultGoalTotalGoalsBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const defaultGoalTotalGoalsBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalGoalsBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Default Goal Attributed Value
        const defaultGoalTotalRevenueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalTotalRevenueBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalRevenueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // Default Goal CR%
        const defaultGoalCRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const defaultGoalCRBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalCRBigNumberMenu),
            field: fields.FM_CVR,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        const goalSnapshotBigNumberData = (menu: WidgetMenuObj) =>
            useResourceComparison({
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [
                        fields.VISITS,
                        fields.COST,
                        fields.FM_SALES,
                        fields.FM_REVENUE,
                        fields.GOAL_CVR_GOAL_SNAPSHOT,
                    ],
                    selectedFields: [
                        fields.PRODUCT,
                        fields.VISITS,
                        fields.COST,
                        fields.FM_SALES,
                        fields.FM_REVENUE,
                        fields.GOAL_CVR_GOAL_SNAPSHOT,
                    ],
                    ...menu.request,
                },
            });

        // Total Goals Completed
        const totalGoalsCompletedBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalGoalsCompletedBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotBigNumberData(totalGoalsCompletedBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Attributed Value
        const totalAttributedValueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalAttributedValueBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotBigNumberData(totalAttributedValueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // All Goals CR%
        const totalCVRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalCVRBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotBigNumberData(totalCVRBigNumberMenu),
            field: fields.GOAL_CVR_GOAL_SNAPSHOT,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Goal Snapshot Table
        const goalSnapshotRequest = useRequestTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
            selectedFields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
        });

        const goalSnapshotTableData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: goalSnapshotRequest,
        });

        const goalSnapshotTotalData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                ...goalSnapshotRequest,
                totals: true,
            },
        });

        const goalSnapshotTable = useResourceTableComparison({
            resourceData: goalSnapshotTableData,
            request: goalSnapshotRequest,
            dimension: fields.PRODUCT,
        });

        const goalSnapshotTableTotals = useResourceTotalComparison({
            resourceData: goalSnapshotTotalData,
        });

        // Goal Completion Crossover Venn
        const goalCrossoverVennData = useResourceVennResourceComparison({
            dimensionResource: {
                resource: resources.REPORT_DASH_PRODUCT,
                request: {
                    ignoreDate: true,
                    groupBy: fields.PRODUCT_NAME,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                },
            },
            vennResource: {
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
                request: {
                    ignoreDate: true,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                    groupBy: fields.ID,
                    filters: [
                        { field: fields.START_DATETIME, operator: FilterOperator.EQUALS, value: startDate },
                        { field: fields.END_DATETIME, operator: FilterOperator.EQUALS, value: endDate },
                    ],
                },
            },
            vennComparisonResource: {
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
                request: {
                    ignoreDate: true,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                    groupBy: fields.ID,
                    filters: [
                        { field: fields.START_DATETIME, operator: FilterOperator.EQUALS, value: comparisonStartDate },
                        { field: fields.END_DATETIME, operator: FilterOperator.EQUALS, value: comparisonEndDate },
                    ],
                },
            },
        });

        const goalCrossoverVenn = useResourceVennComparison({
            resourceData: goalCrossoverVennData,
            metric: fields.VISITORS,
            diagramTitle: 'Goal Completion Crossover',
        });

        // Goals Completion Complexity Funnel
        const goalCompletionComplexityFooterMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalCompletionComplexityFunnelData = useViewComparison({
            resource: resources.AGG_CUSTOMER_JOURNEY_COMPLEXITY,
            request: goalCompletionComplexityFooterMenu.request,
        });

        const goalCompletionComplexityFunnel = useResourceFunnelComparisonChart({
            resourceData: goalCompletionComplexityFunnelData,
            metric: fields.SALES,
        });

        // Goal Completions Stacked Area Chart
        const goalCompletionStackedAreaChartRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Count of Completions', field: fields.FM_SALES, active: true },
                { name: 'Value of Completions', field: fields.FM_REVENUE, active: false },
            ],
            groupBy: fields.PRODUCT,
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.FM_SALES, orderByDirection: 'desc' },
            ],
            selectedFields: [fields.PRODUCT, fields.SALES_DATE_DATE, fields.FM_SALES, fields.FM_REVENUE],
        });

        const goalCompletionsData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: goalCompletionStackedAreaChartRequest,
        });

        const goalCompletionsAdditionalSeries = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                selectedFields: [fields.SALES_DATE_DATE, fields.VISITS, fields.VISITORS],
            },
        });

        const goalCompletionStackedAreaChart = useResourceStackedAreaChartComparison({
            areaSeriesMetric: fields.PRODUCT,
            resourceData: goalCompletionsData,
            dateDimension: fields.SALES_DATE_DATE,
            optionalSeries: [
                {
                    title: 'Unique Visits',
                    yAxis: fields.VISITS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.purple,
                },
                {
                    title: 'Unique Visitors',
                    yAxis: fields.VISITORS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.emerald,
                },
            ],
        });

        // Goal Completions - Summary by Channel
        // Donut / Table
        const goalCompletionRequest = useRequestPieChartTable({
            groupBy: fields.REFERER,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                fields.REFERER,
                fields.VISITS,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.FM_SALES, fields.VISITS, fields.GOAL_VALUE, fields.GOAL_CVR],
            rowsPerPage: 10,
            ...goalCompletionsSectionMenu.request,
            selectedFields: [
                fields.REFERER,
                fields.VISITS,
                fields.GOAL_VALUE,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
        });

        const goalCompletionData = useResourceComparison({
            resource: resources.REPORT_MCS_CHANNEL,
            request: goalCompletionRequest,
        });

        const goalCompletionByChannelTotalData = useResourceComparison({
            resource: resources.REPORT_MCS_CHANNEL,
            request: {
                ...goalCompletionRequest,
                totals: true,
            },
        });

        const goalCompletionDonutPieChartTable = useResourcePieChartTableComparison({
            resourceData: goalCompletionData,
            chartDimension: fields.REFERER,
        });

        const goalCompletionDonutPieChartTableTotal = useResourceTotalComparison({
            resourceData: goalCompletionByChannelTotalData,
        });

        // Detailed View Table
        const goalCompletionDetailedViewRequest = useRequestTable({
            groupBy: fields.REFERER,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                fields.REFERER,
                fields.VISITS,
                fields.GOAL_VALUE,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
            ...goalCompletionsSectionMenu.request,
            selectedFields: [
                fields.REFERER,
                fields.VISITS,
                fields.GOAL_VALUE,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
        });

        const goalCompletionDetailedViewData = useResourceComparison({
            resource: resources.REPORT_MCS_CHANNEL,
            request: goalCompletionDetailedViewRequest,
        });

        const goalCompletionByChannelDetailedViewTotalData = useResourceComparison({
            resource: resources.REPORT_MCS_CHANNEL,
            request: {
                ...goalCompletionDetailedViewRequest,
                totals: true,
            },
        });

        const goalCompletionsDetailedView = useResourceTableComparison({
            resourceData: goalCompletionDetailedViewData,
            request: goalCompletionDetailedViewRequest,
            dimension: fields.REFERER,
        });

        const goalCompletionsDetailedViewTotal = useResourceTotalComparison({
            resourceData: goalCompletionByChannelDetailedViewTotalData,
        });

        const goalCompletionsDetailedViewWithGraphColours = useResourcePieTableMatchColours({
            resourceData: goalCompletionsDetailedView,
            graphResource: goalCompletionDonutPieChartTable,
            dimension: fields.REFERER,
        });

        // Channel Performance - Summary By Goal
        // Donut / Table
        const channelPerformanceRequest = useRequestPieChartTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                fields.PRODUCT,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
            chartField: fields.GOAL_COMPLETIONS,
            chartMetrics: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR],
            rowsPerPage: 10,
            ...channelPerformanceSectionMenu.request,
            selectedFields: [
                fields.PRODUCT,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
        });

        const channelPerformanceData = useResourceComparison({
            resource: resources.REPORT_MCS_CHANNEL,
            request: channelPerformanceRequest,
        });

        const channelPerformanceTotalData = useResourceComparison({
            resource: resources.REPORT_MCS_CHANNEL,
            request: {
                ...channelPerformanceRequest,
                totals: true,
            },
        });

        const channelPerformanceDonutTable = useResourcePieChartTableComparison({
            resourceData: channelPerformanceData,
            chartDimension: fields.PRODUCT,
        });

        const channelPerformanceDonutTableTotals = useResourceTotalComparison({
            resourceData: channelPerformanceTotalData,
        });

        // Detailed View Table
        const channelPerformanceDetailedViewRequest = useRequestTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [
                fields.PRODUCT,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
            ...channelPerformanceSectionMenu.request,
            selectedFields: [
                fields.PRODUCT,
                fields.GOAL_COMPLETIONS,
                fields.GOAL_VALUE,
                fields.GOAL_CVR,
                fields.NEW_VISIT_FM_CVR,
            ],
        });

        const channelPerformanceDetailedViewData = useResourceComparison({
            resource: resources.REPORT_MCS_CHANNEL,
            request: channelPerformanceDetailedViewRequest,
        });

        const channelPerformanceDetailedViewTotalData = useResourceComparison({
            resource: resources.REPORT_MCS_CHANNEL,
            request: {
                ...channelPerformanceDetailedViewRequest,
                totals: true,
            },
        });

        const channelPerformanceDetailedView = useResourceTableComparison({
            resourceData: channelPerformanceDetailedViewData,
            request: channelPerformanceDetailedViewRequest,
            dimension: fields.PRODUCT,
        });

        const channelPerformanceDetailedViewTotal = useResourceTotalComparison({
            resourceData: channelPerformanceDetailedViewTotalData,
        });

        const channelPerformanceDetailedViewWithGraphColours = useResourcePieTableMatchColours({
            resourceData: channelPerformanceDetailedView,
            graphResource: channelPerformanceDonutTable,
            dimension: fields.PRODUCT,
        });

        return {
            a: (
                <WidgetSparkLineComparison
                    title="Visits by Day"
                    data={visitsSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Teal}
                />
            ),
            b: (
                <WidgetSparkLineComparison
                    title="Sales by Day"
                    data={salesSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Orange}
                />
            ),
            c: (
                <WidgetSparkLineComparison
                    title="Revenue by Day"
                    data={revenueSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Green}
                />
            ),
            d: (
                <WidgetSparkLineComparison
                    title="Cost by Day"
                    data={costSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Rose}
                />
            ),
            f: (
                <WidgetBigNumberComparison
                    title="Default Goal Goals Completed"
                    data={defaultGoalTotalGoalsBigNumber}
                    menu={defaultGoalTotalGoalsBigNumberMenu.menu}
                />
            ),
            s: (
                <WidgetBigNumberComparison
                    title="Default Goal Attributed Value"
                    data={defaultGoalTotalRevenueBigNumber}
                    menu={defaultGoalTotalRevenueBigNumberMenu.menu}
                />
            ),
            g: (
                <WidgetBigNumberComparison
                    title="Default Goal CR%"
                    data={defaultGoalCRBigNumber}
                    menu={defaultGoalCRBigNumberMenu.menu}
                />
            ),
            h: (
                <WidgetTableComparison
                    title="Goals Snapshot"
                    data={goalSnapshotTable}
                    totalData={goalSnapshotTableTotals}
                    menu={<WidgetMenuTable data={goalSnapshotTable} />}
                />
            ),
            i: (
                <WidgetBigNumberComparison
                    title="Total Goals Completed"
                    data={totalGoalsCompletedBigNumber}
                    menu={totalGoalsCompletedBigNumberMenu.menu}
                />
            ),
            j: (
                <WidgetBigNumberComparison
                    title="Total Attributed Value"
                    data={totalAttributedValueBigNumber}
                    menu={totalAttributedValueBigNumberMenu.menu}
                />
            ),
            k: (
                <WidgetBigNumberComparison
                    title="All Goals CR%"
                    data={totalCVRBigNumber}
                    menu={totalCVRBigNumberMenu.menu}
                />
            ),
            l: <WidgetVennComparison title="Goal Completion Crossover" data={goalCrossoverVenn} />,
            m: (
                <WidgetFunnelChartComparison
                    title="Goal Completion Complexity"
                    data={goalCompletionComplexityFunnel}
                    menu={goalCompletionComplexityFooterMenu.menu}
                />
            ),
            n: (
                <WidgetStackedAreaChartComparison
                    title="Goal Completions Over Time"
                    data={goalCompletionStackedAreaChart}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={goalCompletionStackedAreaChart}
                            request={goalCompletionStackedAreaChartRequest}
                        />
                    }
                />
            ),
            o: (
                <WidgetPieChartTableComparison
                    title="Goal Completions - Summary by Channel - Quick View"
                    data={goalCompletionDonutPieChartTable}
                    totalData={goalCompletionDonutPieChartTableTotal}
                    href=""
                    display="donut"
                    menu={<WidgetMenuPieChartTable data={goalCompletionDonutPieChartTable} />}
                />
            ),
            p: (
                <WidgetTableComparison
                    title="Goal Completions - Summary By Channel - Detailed View"
                    data={goalCompletionsDetailedViewWithGraphColours}
                    totalData={goalCompletionsDetailedViewTotal}
                    menu={<WidgetMenuTable data={goalCompletionsDetailedViewWithGraphColours} />}
                />
            ),
            q: (
                <WidgetPieChartTableComparison
                    title="Channel Performance - Summary by Goal - Quick View"
                    data={channelPerformanceDonutTable}
                    totalData={channelPerformanceDonutTableTotals}
                    display="donut"
                    menu={<WidgetMenuPieChartTable data={channelPerformanceDonutTable} />}
                />
            ),
            r: (
                <WidgetTableComparison
                    title="Channel Performance - Summary by Goal - Detailed View"
                    data={channelPerformanceDetailedViewWithGraphColours}
                    totalData={channelPerformanceDetailedViewTotal}
                    menu={<WidgetMenuTable data={channelPerformanceDetailedViewWithGraphColours} />}
                />
            ),
        };
    },
};
