import { useEffect, useState } from 'react';
import { AreaYAxisOption, SectionDashboardRequest } from '../../types';
import { SALES_DATE_DATE } from '../../../configurations/fields';
import { isEqual } from 'lodash';

const useRequestStackedAreaChart = (request: SectionDashboardRequest) => {
    if (!request.yAxisOptions) {
        throw new Error('Expected y axis options');
    }

    const [stackedAreaChartRequest, setStackedAreaChartRequest] = useState<SectionDashboardRequest>({ ...request });
    const [yAxisOptions, setYAxisOptions] = useState<AreaYAxisOption[]>(request.yAxisOptions);

    const actions = () => {
        return {
            handleSetYAxis: (value: string) => {
                if (stackedAreaChartRequest.yAxisOptions) {
                    const updatedOptions = stackedAreaChartRequest.yAxisOptions.map(option => {
                        if (option.field.rawName === value) {
                            return {
                                ...option,
                                active: true,
                            };
                        }
                        return {
                            ...option,
                            active: false,
                        };
                    });

                    setYAxisOptions(updatedOptions);
                }
            },
        };
    };

    useEffect(() => {
        setStackedAreaChartRequest({ ...stackedAreaChartRequest, ...actions() });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (request.groupBy) {
            if (!isEqual(request.groupBy, stackedAreaChartRequest.groupBy)) {
                setStackedAreaChartRequest({ ...request, ...actions() });
            }
        }
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (yAxisOptions) {
            const activeField =
                yAxisOptions.filter((option: AreaYAxisOption) => option.active).length > 0
                    ? yAxisOptions.filter(option => option.active)[0].field
                    : null;
            if (activeField) {
                const orderByField = yAxisOptions.filter(option => option.field === activeField)[0].field;
                setStackedAreaChartRequest({
                    ...stackedAreaChartRequest,
                    ...actions(),
                    orderBy: [
                        { field: SALES_DATE_DATE, orderByDirection: 'asc' },
                        { field: orderByField, orderByDirection: 'desc' },
                    ],
                });
            }
        }
    }, [yAxisOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (request.pageFilters) {
            if (request.pageFilters !== stackedAreaChartRequest.pageFilters) {
                setStackedAreaChartRequest({ ...request, ...actions() });
            }
        }
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        ...stackedAreaChartRequest,
        yAxisOptions: yAxisOptions,
    };
};

export default useRequestStackedAreaChart;
