import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';
import WidgetBaseLink from '../base/widget-base-link';
import IconFilter from '../../../components/icons/filter';
import WidgetDialogCloseButton, { WidgetDialogCloseButtonTheme } from './forms/form-fields/widget-dialog-close-button';
import { RequestFilter, WidgetDialogLocation } from '../../types';
import { CubedField } from '../../../types';
import WidgetDialogFormFilter from './forms/widget-dialog-form-filter';

const StyledDialogTrigger = styled(Dialog.Trigger)`
    font-size: 16px;
    font-family: inherit;
    background-color: transparent;
    padding: 0;
    border: 0;
    width: 100%;
`;

const StyledDialogOverlay = styled(Dialog.Overlay)`
    background-color: ${props => props.theme.sectionDashboard.widget.dialog.overlayColor};
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 500000;
`;

const StyledDialogContent = styled(Dialog.Content)`
    background-color: ${props => props.theme.sectionDashboard.widget.dialog.backgroundColor};
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.dialog.shadow};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 800px;
    max-height: 60vh;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 500001;
    overflow-y: auto;
    color: ${props => props.theme.sectionDashboard.widget.dialog.textColor};
    font-weight: 400;
`;

const StyledDialogTitle = styled(Dialog.Title)`
    font-size: 16px;
    font-weight: ${props => props.theme.sectionDashboard.widget.dialog.titleFontWeight};
    padding: 20px;
    margin: 0;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
`;

const StyledActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px;
`;

const StyledActionButtonsContainer = styled.div`
    display: flex;
    gap: 5px;
`;

export type RequestFilterWithId = RequestFilter & { id: number };

export type WidgetDialogFilterProps = {
    filters?: [] | RequestFilter[];
    options?: CubedField[];
    location: WidgetDialogLocation;
    loading?: boolean;
    onApplyClick?: (updatedFilters: RequestFilter[]) => void;
};

const WidgetDialogFilter = ({
    filters = [],
    options = [],
    location,
    loading,
    onApplyClick,
}: WidgetDialogFilterProps) => {
    const [currentFilters, setCurrentFilters] = useState(
        filters.map((filter, index) => {
            return {
                ...filter,
                id: index + 1,
            };
        })
    );

    const handleApplyClick = () => {
        const updatedFilters = currentFilters.map(filter => {
            return {
                field: filter.field,
                operator: filter.operator,
                value: filter.value,
            };
        });

        onApplyClick && onApplyClick(updatedFilters);
    };

    return (
        <Dialog.Root>
            <>
                {!loading && (
                    <StyledDialogTrigger>
                        <WidgetBaseLink text="Filter" icon={<IconFilter />} location={location} loading={loading} />
                    </StyledDialogTrigger>
                )}
                {loading && <WidgetBaseLink text="Loading" location={location} loading={loading} />}
            </>
            <Dialog.Portal>
                <StyledDialogOverlay />

                <StyledDialogContent>
                    <StyledDialogTitle>Filter</StyledDialogTitle>
                    <WidgetDialogFormFilter
                        filters={currentFilters}
                        handleUpdateFilters={setCurrentFilters}
                        options={options}
                    />
                    <StyledActionsContainer>
                        <StyledActionButtonsContainer>
                            <WidgetDialogCloseButton
                                value="Apply"
                                theme={WidgetDialogCloseButtonTheme.Primary}
                                onClick={handleApplyClick}
                            />
                            <WidgetDialogCloseButton value="Cancel" theme={WidgetDialogCloseButtonTheme.Secondary} />
                        </StyledActionButtonsContainer>
                    </StyledActionsContainer>
                </StyledDialogContent>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default WidgetDialogFilter;
