import React, { createContext, useContext, useEffect, useState } from 'react';
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import { WidgetConfig, WidgetConfigDataSuccess } from '../../../types';
import {
    DASHBOARD_BUILDER_SECTION_AREAS,
    DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
    DASHBOARD_BUILDER_WIDGET_TYPE,
} from '../../../config/resources-dashboard-builder';
import { DashboardBuilderContext } from '../../../context/dashboard-builder-context';
import { UseQueryResult } from '@tanstack/react-query';
import { ConfigDataSuccess } from '../../../../react-query/types';
import getWidgetsInUse from '../helpers/get-widgets-in-use';

export interface WidgetLibraryContextValues {
    // Widgets
    dashboardWidgetsQuery: UseQueryResult<number[]>;
    widgetQuery: UseQueryResult<WidgetConfigDataSuccess>;
    widgets: WidgetConfig[];
    widgetTypeQuery: UseQueryResult<WidgetConfigDataSuccess>;
    handleFilterWidgets: (searchTerm: string) => void;
}

export const WidgetLibraryContext = createContext<WidgetLibraryContextValues>({} as WidgetLibraryContextValues);

const WidgetLibraryContextProvider = ({ children }: { children: JSX.Element }) => {
    const { clientUserId } = useContext(DashboardBuilderContext);

    // Widgets currently in use on a dashboard
    const dashboardWidgetsQuery = useFetchResource({
        resource: DASHBOARD_BUILDER_SECTION_AREAS,
        select: (data: ConfigDataSuccess) => {
            return getWidgetsInUse(data);
        },
    });

    // Widgets created by the current user
    const widgetQuery = useFetchResource<WidgetConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
        params: [{ key: 'widget__author', value: clientUserId }],
        enabled: !!clientUserId,
    });

    const [widgets, setWidgets] = useState<WidgetConfig[]>([]);

    const handleFilterWidgets = (searchTerm: string) => {
        if (widgetQuery.isSuccess && searchTerm) {
            const updatedWidgets = widgetQuery.data.objects.filter(widget =>
                widget.widget.title.toLowerCase().includes(searchTerm.toLowerCase())
            );

            setWidgets(updatedWidgets as WidgetConfig[]);
        }
    };

    useEffect(() => {
        if (widgetQuery.isSuccess) {
            setWidgets(widgetQuery.data.objects);
        }
    }, [widgetQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    // Widget Types
    const widgetTypeQuery = useFetchResource<WidgetConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_WIDGET_TYPE,
    });

    return (
        <WidgetLibraryContext.Provider
            value={{ dashboardWidgetsQuery, widgetQuery, widgets, widgetTypeQuery, handleFilterWidgets }}
        >
            {children}
        </WidgetLibraryContext.Provider>
    );
};

export default WidgetLibraryContextProvider;
