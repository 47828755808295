import React, { useContext } from 'react';
import styled from 'styled-components';

// Icon
import IconChevronsRight from '../../../../components/icons/chevrons-right';

// Components
import SectionDashboardMenuSelect from '../../../../section-dashboard/components/menus/section-dashboard-menu-select';

// Context
import { ContentDetailDashboardMenuContext } from '../../../content-detail-context/content-detail-dashboard-menu-context';

// Types
import { RequestStatus } from '../../../../section-dashboard/types';

// Icons
import IconPlus from '../../../../components/icons/plus';
import IconCross from '../../../../components/icons/cross';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 5px;
    width: 100%;
    min-height: 53px;
    padding: 10px 16px;
    margin: 12px 0px 8px 0px;
    background-color: ${props => props.theme.sectionDashboard.widget.base.backgroundColor};
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};
    color: ${props => props.theme.filterBar.channelSignpost.textColor};

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const StyledTierWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
`;

const StyledFilterWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
`;

const StyledIconChevrons = styled(IconChevronsRight)`
    width: 10px;
    color: ${props => props.theme.colours.cubedOrange};
`;

const StyledMenuWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledAddButton = styled.button<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    outline: none;
    background-color: transparent;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: ${props => props.disabled && props.theme.sectionDashboard.widget.menu.textColorDisabled};
    cursor: ${props => props.disabled && 'not-allowed'}!important;

    &:hover {
        background-color: ${props => !props.disabled && props.theme.sectionDashboard.widget.menu.backgroundHover};
    }
`;

const StyledRemoveButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    padding: 2px 5px;
    background-color: transparent;
`;

const StyledIconPlus = styled(IconPlus)`
    width: 12px;
`;

const StyledIconCross = styled(IconCross)`
    width: 10px;
`;

const StyledHR = styled.hr`
    width: 100%;
    border: 0;
    border-top: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
`;

const StyledFilterContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
`;

const StyledFilterPill = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
    border-radius: 30px;
    padding: 5px 12px;
    font-size: 0.9rem;
`;

const StyledTierTwoType = styled.span`
    font-weight: 600;
`;

const BeforeSectionContentUniverseFilter = () => {
    const {
        fieldToView,
        handleFieldToViewChange,
        tierOneTypeDropdownOptions,
        selectedTierOneType,
        handleTierOneTypeChange,
        tierTwoMenu,
        tierTwoMenuCombinedRequests,
        onAddFilterClick,
        currentFilters,
        setCurrentFilters,
    } = useContext(ContentDetailDashboardMenuContext);

    const loading = tierOneTypeDropdownOptions.status === RequestStatus.LOADING;

    const handleRemoveFilterClick = (contentType: string, value?: string) => {
        if (value) {
            const updatedFilters = currentFilters.filter(currentFilter => {
                return currentFilter.contentType.label !== contentType || currentFilter.tierTwoType !== value;
            });

            setCurrentFilters(updatedFilters);
        }
    };

    if (loading || selectedTierOneType) {
        return (
            <StyledContainer>
                <StyledTierWrapper>
                    <StyledFilterWrapper>
                        <SectionDashboardMenuSelect
                            label="Content Type"
                            dropdownOptions={tierOneTypeDropdownOptions}
                            selectedOption={selectedTierOneType}
                            onOptionChange={handleTierOneTypeChange}
                            emptyMessage="No Content Types found"
                        />

                        <StyledIconChevrons />
                        <StyledMenuWrapper>{tierTwoMenuCombinedRequests.menu}</StyledMenuWrapper>
                        <StyledAddButton
                            onClick={onAddFilterClick}
                            disabled={tierTwoMenu.status !== RequestStatus.SUCCESS}
                        >
                            <StyledIconPlus />
                            Add filter
                        </StyledAddButton>
                    </StyledFilterWrapper>
                    <SectionDashboardMenuSelect
                        label="Field To View"
                        dropdownOptions={tierOneTypeDropdownOptions}
                        selectedOption={fieldToView}
                        onOptionChange={handleFieldToViewChange}
                        emptyMessage="No fields found"
                    />
                </StyledTierWrapper>

                {currentFilters.length > 0 && (
                    <>
                        <StyledHR />
                        <StyledFilterContainer>
                            {currentFilters.map(filter => {
                                return (
                                    <StyledFilterPill key={`${filter.contentType.label}-${filter.tierTwoType}`}>
                                        {filter.contentType.label}:
                                        <StyledTierTwoType>{filter.tierTwoType}</StyledTierTwoType>
                                        <StyledRemoveButton
                                            onClick={() =>
                                                handleRemoveFilterClick(filter.contentType.label, filter.tierTwoType)
                                            }
                                        >
                                            <StyledIconCross />
                                        </StyledRemoveButton>
                                    </StyledFilterPill>
                                );
                            })}
                        </StyledFilterContainer>
                    </>
                )}
            </StyledContainer>
        );
    }

    return null;
};

export default BeforeSectionContentUniverseFilter;
