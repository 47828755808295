import React from 'react';
import styled from 'styled-components';

const StyledH2 = styled.h2`
    margin: 16px 0px;
    font-size: 1.1rem;
    font-weight: 600;
    color: ${props => props.theme.forms.sections.titleColor};
`;

type FormSectionTitleProps = {
    children: React.ReactNode;
};

const FormSectionTitle = ({ children }: FormSectionTitleProps) => {
    return <StyledH2>{children}</StyledH2>;
};

export default FormSectionTitle;
