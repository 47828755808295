import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

// Dialog Components
import DialogTrigger from './dialog-trigger';
import DialogContent from './dialog-content';
import DialogTitle from './dialog-title';
import DialogDescription from './dialog-description';
import DialogButtonGroup from './dialog-button-group';
import DialogCancel from './dialog-cancel';
import DialogAction from './dialog-action';

const Dialog = ({ children }: { children: React.ReactNode }) => {
    return <AlertDialog.Root>{children}</AlertDialog.Root>;
};

// Dialog Components
Dialog.Trigger = DialogTrigger;
Dialog.Content = DialogContent;
Dialog.Title = DialogTitle;
Dialog.Description = DialogDescription;
Dialog.ButtonGroup = DialogButtonGroup;
Dialog.Cancel = DialogCancel;
Dialog.Action = DialogAction;

export default Dialog;
