export const calculateVariance = (value: number, comparisonValue: number): number | null => {
    if (value === 0 || comparisonValue === 0) {
        return null;
    }

    const variance = ((value - comparisonValue) / comparisonValue) * 100;

    if (isNaN(variance) || String(variance) === 'Infinity' || String(variance) === '-Infinity') {
        return null;
    }

    return variance;
};

export const formatVariance = (variance: number | null, showPercentage: boolean = false): string => {
    if (variance === null || isNaN(variance)) {
        return 'N/A';
    }

    let varianceFormatted = variance.toFixed(2);

    if (variance >= 0) {
        return `+${varianceFormatted}${showPercentage ? '%' : ''}`;
    }
    return `${varianceFormatted}${showPercentage ? '%' : ''}`;
};
