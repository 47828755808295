/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Types & Helpers
import { CubedField } from '../../../../types';
import { getFilterTypeOptions } from '../../../helpers/get-filter-type-options';

import DataTypes from '../../../../filter-bar/enums/data-types';
import { RequestFilterWithId } from '../widget-dialog-filter';
import { DropdownOption, FilterOperator } from '../../../types';

// Components
import Button from './form-fields/button';
import WidgetDialogFormFilterRow from './widget-dialog-form-filter-row';

const StyledContainer = styled.div`
    width: 100%;
`;

const StyledButtonContainer = styled.div`
    padding: 20px;
`;

export type WidgetDialogFormFilterProps = {
    filters: [] | RequestFilterWithId[];
    handleUpdateFilters: (updatedFilters: RequestFilterWithId[]) => void;
    options: CubedField[];
};

const WidgetDialogFormFilter = ({ filters, handleUpdateFilters, options }: WidgetDialogFormFilterProps) => {
    const [showAddFilter, setShowAddFilter] = useState(false);

    useEffect(() => {
        if (filters.length === 0) {
            setShowAddFilter(true);
        }
    }, [filters]);

    const handleUpdateFilterField = (id: number, value: DropdownOption) => {
        if (filters.some(filter => filter.id === id)) {
            const updatedFilters = filters.map(filter => {
                if (filter.id === id) {
                    const field = options.filter(option => option.rawName === value.value)[0];
                    return {
                        ...filter,
                        field: field,
                        operator:
                            field.dataType === DataTypes.BOOL
                                ? FilterOperator.EQUALS
                                : getFilterTypeOptions(field)[0].value,
                        value: field.dataType === DataTypes.BOOL ? getFilterTypeOptions(field)[0].value : '',
                    };
                }
                return filter;
            });
            handleUpdateFilters(updatedFilters);
        } else {
            const field = options.filter(option => option.rawName === value.value)[0];
            const updatedFilters: RequestFilterWithId[] = [...filters];

            updatedFilters.push({
                id: id,
                field: field,
                operator:
                    field.dataType === DataTypes.BOOL ? FilterOperator.EQUALS : getFilterTypeOptions(field)[0].value,
                value: field.dataType === DataTypes.BOOL ? getFilterTypeOptions(field)[0].value : '',
            });
            handleUpdateFilters(updatedFilters);
            setShowAddFilter(false);
        }
    };

    const handleUpdateFilterOperator = (id: number, value: DropdownOption) => {
        const updatedFilters = filters.map(filter => {
            if (filter.id === id) {
                return {
                    ...filter,
                    operator: value.value as FilterOperator,
                    value: filter.value,
                };
            }
            return filter;
        });
        handleUpdateFilters(updatedFilters);
    };

    const handleUpdateBooleanDropdown = (id: number, value: DropdownOption) => {
        const updatedFilters = filters.map(filter => {
            if (filter.id === id) {
                return {
                    ...filter,
                    value: value.value,
                    operator: FilterOperator.EQUALS,
                };
            }
            return filter;
        });
        handleUpdateFilters(updatedFilters);
    };

    const handleUpdateInputValue = (id: number, value: string) => {
        const updatedFilters = filters.map(filter => {
            if (filter.id === id) {
                return {
                    ...filter,
                    value: value,
                };
            }
            return filter;
        });
        handleUpdateFilters(updatedFilters);
    };

    const handleRemoveFilter = (id: number) => {
        if (id !== 1 && !filters.map(filter => filter.id).includes(id)) {
            setShowAddFilter(false);
        } else {
            let updatedFilters = filters.filter(filter => filter.id !== id);

            updatedFilters = updatedFilters.map((filter, index) => {
                return {
                    ...filter,
                    id: index + 1,
                };
            });

            handleUpdateFilters(updatedFilters);
        }
    };

    return (
        <StyledContainer>
            {filters.map((filter: RequestFilterWithId) => {
                return (
                    <WidgetDialogFormFilterRow
                        key={filter.id}
                        options={options}
                        id={filter.id}
                        field={filter.field}
                        operator={filter.operator}
                        operatorOptions={getFilterTypeOptions(filter.field)}
                        value={filter.value}
                        onUpdateFilterField={handleUpdateFilterField}
                        onUpdateFilterOperator={handleUpdateFilterOperator}
                        onUpdateBooleanDropdown={handleUpdateBooleanDropdown}
                        onUpdateInputValue={handleUpdateInputValue}
                        onRemoveFilter={handleRemoveFilter}
                    />
                );
            })}

            {showAddFilter && (
                <WidgetDialogFormFilterRow
                    options={options}
                    id={filters.length + 1}
                    onUpdateFilterField={handleUpdateFilterField}
                    onUpdateFilterOperator={handleUpdateFilterOperator}
                    onUpdateBooleanDropdown={handleUpdateBooleanDropdown}
                    onUpdateInputValue={handleUpdateInputValue}
                    onRemoveFilter={handleRemoveFilter}
                />
            )}

            <StyledButtonContainer>
                <Button
                    value="Add Filter"
                    disabled={showAddFilter || options.length === 0}
                    onClick={() => setShowAddFilter(true)}
                />
            </StyledButtonContainer>
        </StyledContainer>
    );
};

export default WidgetDialogFormFilter;
