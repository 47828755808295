import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Request from '../helpers/request';
import { addNotification } from '../redux/actions/notification';
import styled from 'styled-components';

// Hooks
import { useDatesFormatted } from '../hooks/use-dates';

// Types
import { RequestParams } from '../helpers/request-builder';
import { CubedResource } from '../types';
import { NotificationMessageType } from '../enums/notification-types';

// Components
import DownloadIcon from './icons/download';
import LoadingBar from './loading-bar';
import InputButton from './inputs/input-button';

const StyledContainer = styled.div`
    ${props => props.theme.no_select()};
    padding: 2px;
    float: right;
    cursor: pointer;
    margin: 0 12px 0 12px;
    position: relative;
    display: flex;
`;

const StyledButtonWrapper = styled.div`
    border: 1px solid ${props => props.theme.colours.borderGrey};
    padding: 8px;
    font-size: 13px;
    display: inline-block;
`;

const StyledButton = styled.span`
    position: relative;
`;

const StyledButtonArrow = styled.span`
    font-size: 9px;
    position: relative;
`;

const StyledDropdown = styled.div<{ hidden: boolean }>`
    ${props => props.theme.boxShadow(1)}
    padding-top: 6px;
    width: 200px;
    visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
    position: absolute;
    right: 0;
    top: 32px;
    background-color: ${props => props.theme.colours.white};
    z-index: 50000;
    ${props => props.theme.transition}
    overflow: hidden;
    max-height: ${props => props.hidden && 0};
`;

const StyledDropdownList = styled.div`
    position: relative;
    cursor: auto;
`;

const StyledIcon = styled(DownloadIcon)`
    float: right;
    fill: ${props => props.theme.colours.textDefault};
    margin-top: 3px;
    height: 12px;
    ${props => props.theme.transition}
`;

const StyledDropdownListItem = styled.div`
    width: 100%;
    height: 30px;
    padding: 6px;
    margin-bottom: 3px;
    ${props => props.theme.transition}
    cursor: pointer;
    color: ${props => props.theme.colours.textDefault};
    &:hover {
        color: ${props => props.theme.colours.primary};
    }
    &:hover ${StyledIcon} {
        fill: ${props => props.theme.colours.primary};
    }
`;

type CSVDownloadProps = {
    params: {
        resourceGroup: string;
        resourceName: string;
        params: RequestParams;
    };
    filename: string;
    allDataExportConfig?: {
        fileName: string;
        resource: CubedResource;
    };
    totalResults?: number;
    isLoadingDownloadButton?: boolean;
    customButtonName?: string;
    disableDropdown?: boolean;
    noData: boolean;
};

const CSVDownload = ({
    params,
    filename,
    allDataExportConfig,
    totalResults,
    isLoadingDownloadButton,
    customButtonName,
    disableDropdown,
    noData,
}: CSVDownloadProps) => {
    const dispatch = useDispatch();
    const { startDate, endDate } = useDatesFormatted({ resource: allDataExportConfig?.resource });
    const [showDropdown, setShowDropdown] = useState(false);
    const [downloadingCurrent, setDownloadingCurrent] = useState(false);
    const [downloadingAllRows, setDownloadingAllRows] = useState(false);
    const [downloadingAllData, setDownloadingAllData] = useState(false);

    const exportCurrent = () => {
        const requester = new Request();
        const exportParams = params.params;
        exportParams.push({ key: 'format', value: 'csv' });
        setDownloadingCurrent(true);

        requester
            .download(params.resourceGroup, params.resourceName, [exportParams], 'current', `${filename}.csv`)
            .then(() => {
                addNotification({ copy: 'Data export complete.', type: NotificationMessageType.Success });
                setDownloadingCurrent(false);
            })
            .catch(() => {
                addNotification({ copy: 'Export has failed.', type: NotificationMessageType.Error });
                setDownloadingCurrent(false);
            });
    };

    const exportAllData = () => {
        if (!allDataExportConfig) return;

        setDownloadingAllData(true);

        const resourceGroup = allDataExportConfig.resource.category;
        const resourceName = allDataExportConfig.resource.id;

        const exportParams = [
            { key: `${allDataExportConfig.resource.dateDimension.rawName}__gte`, value: startDate },
            { key: `${allDataExportConfig.resource.dateDimension.rawName}__lte`, value: endDate },
            { key: 'limit', value: 0 },
            { key: 'format', value: 'csv' },
        ];

        exportAll(resourceGroup, resourceName, exportParams);
    };

    const exportAllRows = () => {
        setDownloadingAllRows(true);

        const resourceGroup = params.resourceGroup;
        const resourceName = params.resourceName;
        const exportParams = [
            ...params.params.filter(item => item.key !== 'limit' && item.key !== 'offset'),
            { key: 'limit', value: 0 },
            { key: 'format', value: 'csv' },
        ];

        exportAll(resourceGroup, resourceName, exportParams);
    };

    const exportAll = (resourceGroup: string, resourceName: string, params: RequestParams) => {
        // Calculate total number rows to calculate batch size
        const offsetSize = 1000;
        let offsetVal = 0;
        const rowCount = totalResults;

        // Download in one batch if total is undefined
        let batchCount = rowCount === undefined ? 1 : Math.ceil(rowCount / offsetSize);

        const requester = new Request();
        let multipleParamsWithMultipleOffsets = [];

        while (batchCount) {
            multipleParamsWithMultipleOffsets.push([
                ...params,
                { key: 'limit', value: offsetSize },
                { key: 'offset', value: offsetVal },
            ]);
            batchCount--;
            offsetVal += offsetSize;
        }

        requester
            .download(resourceGroup, resourceName, multipleParamsWithMultipleOffsets, 'all', `${filename}.csv`)
            .then(res => {
                dispatch(addNotification({ copy: 'Data export complete.', type: NotificationMessageType.Success }));
                setDownloadingAllRows(false);
                setDownloadingAllData(false);
            })
            .catch(error => {
                dispatch(addNotification({ copy: 'Export has failed.', type: NotificationMessageType.Error }));
                setDownloadingAllRows(false);
                setDownloadingAllData(false);
            });
    };

    if (!noData) {
        return (
            <>
                {isLoadingDownloadButton && (
                    <InputButton
                        value={'EXPORT'}
                        disabled={false}
                        onClick={exportAllRows}
                        isLoading={downloadingAllRows}
                        data-testid={'export-button'}
                    />
                )}
                <StyledContainer>
                    {!isLoadingDownloadButton && (
                        <StyledButtonWrapper onClick={exportAllRows}>
                            <StyledButton data-testid={'export-button'}>
                                {customButtonName ? customButtonName : 'Export'}
                            </StyledButton>
                        </StyledButtonWrapper>
                    )}
                    {!disableDropdown && (
                        <StyledButtonWrapper
                            onMouseEnter={() => setShowDropdown(true)}
                            onMouseLeave={() => setShowDropdown(false)}
                        >
                            <StyledButtonArrow>▼</StyledButtonArrow>
                            <StyledDropdown
                                hidden={!showDropdown && !downloadingAllRows && !downloadingCurrent ? true : false}
                            >
                                <StyledDropdownList>
                                    <StyledDropdownListItem onClick={exportAllRows}>
                                        <span>{downloadingAllRows ? <LoadingBar /> : 'Export All Rows'}</span>
                                        {!downloadingAllRows && <StyledIcon />}
                                    </StyledDropdownListItem>
                                    <StyledDropdownListItem onClick={exportCurrent}>
                                        <span>{downloadingCurrent ? <LoadingBar /> : 'Export Current Rows'}</span>
                                        {!downloadingCurrent && <StyledIcon />}
                                    </StyledDropdownListItem>
                                    {allDataExportConfig && (
                                        <StyledDropdownListItem onClick={exportAllData}>
                                            <span>{downloadingAllData ? <LoadingBar /> : 'Export All Data'}</span>
                                            {!downloadingAllData && <StyledIcon />}
                                        </StyledDropdownListItem>
                                    )}
                                </StyledDropdownList>
                            </StyledDropdown>
                        </StyledButtonWrapper>
                    )}
                </StyledContainer>
            </>
        );
    }

    return null;
};

export default CSVDownload;
