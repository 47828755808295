import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';

export const config = {
    pageTitle: 'PPC Impression Share Performance',
    dateDimension: fields.DATE_DATETIME,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: true,
        },
    },
    resources: [resources.REPORT_PPC_IMPRESSION_SHARE_PERFORMANCE],
    ignoreProducts: true,
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'ppc-impression-share-performance',
        allYMetrics: [
            fields.PPC_IMPRESSIONS,
            fields.PPC_CLICKS,
            fields.PPC_COST,
            fields.CPC_BID,
            fields.TOP_OF_PAGE_CPC_BID,
            fields.CTR,
            fields.QUALITY_SCORE,
            fields.CREATIVE_QUALITY_SCORE,
            fields.POSTCLICK_QUALITY_SCORE,
            fields.SEARCH_PREDICTED_CTR_QUALITY_SCORE,
            fields.ABSOLUTE_TOP_IMPRESSION_PERCENTAGE,
            fields.TOP_IMPRESSION_PERCENTAGE,
            fields.SEARCH_ABSOLUTE_TOP_IMPRESSION_SHARE,
            fields.SEARCH_BUDGET_LOST_ABSOLUTE_TOP_IMPRESSION_SHARE,
            fields.SEARCH_RANK_LOST_ABSOLUTE_TOP_IMPRESSION_SHARE,
            fields.SEARCH_TOP_IMPRESSION_SHARE,
            fields.SEARCH_BUDGET_LOST_TOP_IMPRESSION_SHARE,
            fields.SEARCH_RANK_LOST_TOP_IMPRESSION_SHARE,
            fields.SEARCH_IMPRESSION_SHARE,
            fields.SEARCH_BUDGET_LOST_IMPRESSION_SHARE,
            fields.SEARCH_RANK_LOST_IMPRESSION_SHARE,
        ],
        initialYMetrics: [fields.PPC_IMPRESSIONS],
    },
    table: {
        tabListItems: [
            {
                tabName: 'overview',
                displayName: 'Overview',
                isSelected: true,
                metrics: [
                    fields.PPC_IMPRESSIONS,
                    fields.PPC_CLICKS,
                    fields.CTR,
                    fields.PPC_COST,
                    fields.CPC_BID,
                    fields.TOP_OF_PAGE_CPC_BID,
                ],
            },
            {
                tabName: 'quality_scores',
                displayName: 'Quality Scores',
                isSelected: false,
                metrics: [
                    fields.QUALITY_SCORE,
                    fields.CREATIVE_QUALITY_SCORE,
                    fields.POSTCLICK_QUALITY_SCORE,
                    fields.SEARCH_PREDICTED_CTR_QUALITY_SCORE,
                ],
            },
            {
                tabName: 'impressions',
                displayName: 'Overall Impression Share',
                isSelected: false,
                metrics: [
                    fields.SEARCH_IMPRESSION_SHARE,
                    fields.SEARCH_BUDGET_LOST_IMPRESSION_SHARE,
                    fields.SEARCH_RANK_LOST_IMPRESSION_SHARE,
                ],
            },
            {
                tabName: 'absolute impressions',
                displayName: 'Rank 1 Impression Share',
                isSelected: false,
                metrics: [
                    fields.SEARCH_ABSOLUTE_TOP_IMPRESSION_SHARE,
                    fields.SEARCH_BUDGET_LOST_ABSOLUTE_TOP_IMPRESSION_SHARE,
                    fields.SEARCH_RANK_LOST_ABSOLUTE_TOP_IMPRESSION_SHARE,
                ],
            },
            {
                tabName: 'top impressions',
                displayName: 'Above SEO Impression Share',
                isSelected: false,
                metrics: [
                    fields.SEARCH_TOP_IMPRESSION_SHARE,
                    fields.SEARCH_BUDGET_LOST_TOP_IMPRESSION_SHARE,
                    fields.SEARCH_RANK_LOST_TOP_IMPRESSION_SHARE,
                ],
            },
            {
                tabName: 'impressions',
                displayName: 'All',
                isSelected: false,
                metrics: [
                    fields.PPC_IMPRESSIONS,
                    fields.PPC_CLICKS,
                    fields.CTR,
                    fields.PPC_COST,
                    fields.CPC_BID,
                    fields.TOP_OF_PAGE_CPC_BID,
                    fields.QUALITY_SCORE,
                    fields.CREATIVE_QUALITY_SCORE,
                    fields.POSTCLICK_QUALITY_SCORE,
                    fields.SEARCH_PREDICTED_CTR_QUALITY_SCORE,
                    fields.SEARCH_IMPRESSION_SHARE,
                    fields.SEARCH_BUDGET_LOST_IMPRESSION_SHARE,
                    fields.SEARCH_RANK_LOST_IMPRESSION_SHARE,
                    fields.SEARCH_ABSOLUTE_TOP_IMPRESSION_SHARE,
                    fields.SEARCH_BUDGET_LOST_ABSOLUTE_TOP_IMPRESSION_SHARE,
                    fields.SEARCH_RANK_LOST_ABSOLUTE_TOP_IMPRESSION_SHARE,
                    fields.SEARCH_TOP_IMPRESSION_SHARE,
                    fields.SEARCH_BUDGET_LOST_TOP_IMPRESSION_SHARE,
                    fields.SEARCH_RANK_LOST_TOP_IMPRESSION_SHARE,
                ],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.CAMPAIGN_NAME.id]: {
                default: [
                    {
                        resource: resources.REPORT_PPC_IMPRESSION_SHARE_PERFORMANCE,
                        field: fields.AD_GROUP_NAME,
                    },
                ],
            },
            [fields.AD_GROUP_NAME.id]: {
                default: [
                    {
                        resource: resources.REPORT_PPC_IMPRESSION_SHARE_PERFORMANCE,
                        field: fields.KEYWORD,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
