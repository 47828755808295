import React, { useContext } from 'react';
import styled from 'styled-components';

// Context
import { HealthDashboardContext } from '../../layouts/health-dashboard/health-dashboard-context';

// Components
import TabList from '../common/tab-items/tab-list';
import LiveDatePicker from '../live-date-picker';
import CommonSelect from '../common/common-select';
import CommonInput from '../common/common-input';
import CommonButton from '../common/common-button';
import TablePagination from '../tables/components/pagination/table-pagination';
import TableSkeleton from '../table-skeleton';
import SparkSkeleton from '../spark-skeleton';
import SparkLine from '../spark-line';
import DataTable from '../tables/components/data-table';

const StyledContainer = styled.div`
    display: flex;
`;

const StyledContentContainer = styled.div`
    ${props => props.theme.boxShadow(1)}
    padding: 12px 12px 0 12px;
    background-color: ${props => props.theme.colours.white};
    min-height: 660px;
`;

const StyledTableGraph = styled.div`
    min-height: 420px;
    width: 100%;
`;

const StyledSearchContainer = styled.div`
    display: inline-flex;
    width: 100%;
`;

const StyledSearchTerms = styled.div`
    flex-grow: 2;
`;

const StyledSearchBar = styled.div`
    flex-grow: 15;
    margin-bottom: 18px;
    margin: 0px 10px;
`;

const StyledSearchSubmit = styled.div`
    max-width: 85px;
    flex-grow: 1;
    margin-top: 2px;
`;

const HealthDashboard = () => {
    const {
        // Sparkline Data States
        SPARK_ITEMS,
        visitsSparkData,
        eventsSparkData,
        impressionsSparkData,
        tagValidationSparkData,

        // Sparkline Loading States
        visitsSparkDataLoading,
        eventsSparkDataLoading,
        impressionsSparkDataLoading,
        tagValidationSparkDataLoading,
        chartDateGranularity,

        // Live States
        handleLiveDataOnClick,
        tableLiveDataState,

        // Table States
        tableData,
        tableNoData,
        tableLoading,
        tableTabConfig,
        handleTabElementClick,
        tableMetrics,
        tableDimensions,
        orderBy,
        orderByDir,
        selectedTabId,
        tablePaginations,
        handleOnSubmitClick,

        // Filter States
        tableFilterState,
        handleFilterSelectChange,
        DEFAULT_FILTER_CATEGORY_VALUE,
        DEFAULT_FILTER_CATEGORY_TEXT,
        handleFilterTextChange,

        // Date States
        TODAY,
        selectionDatePickerRange,
        expendDatePicker,
        isDatePickerApplied,
        toggleDatePickerCalender,
        handleDatePickerSelect,
        handleDatePickerCancel,
        handleDatePickerApply,
        dateRangePickerRef,

        // Set owner status
        setOwnerState,
    } = useContext(HealthDashboardContext);

    return (
        <>
            <StyledContainer>
                {!visitsSparkDataLoading ? (
                    <SparkLine
                        loading={visitsSparkDataLoading}
                        dimension={SPARK_ITEMS[0].dimension}
                        periodName={chartDateGranularity}
                        data={visitsSparkData.objects}
                        colour={SPARK_ITEMS[0].colour}
                        id={SPARK_ITEMS[0].id}
                        hideTotals={SPARK_ITEMS[0].options.HIDE_TOTAL}
                        chartDateGranularity={chartDateGranularity}
                        hideUTCDateTime={false}
                    />
                ) : (
                    <SparkSkeleton />
                )}
                {!eventsSparkDataLoading ? (
                    <SparkLine
                        loading={eventsSparkDataLoading}
                        data={eventsSparkData.objects}
                        id={SPARK_ITEMS[1].id}
                        dimension={SPARK_ITEMS[1].dimension}
                        periodName={chartDateGranularity}
                        colour={SPARK_ITEMS[1].colour}
                        hideTotals={SPARK_ITEMS[1].options.HIDE_TOTAL}
                        chartDateGranularity={chartDateGranularity}
                        hideUTCDateTime={false}
                    />
                ) : (
                    <SparkSkeleton />
                )}
                {!impressionsSparkDataLoading ? (
                    <SparkLine
                        loading={impressionsSparkDataLoading}
                        data={impressionsSparkData.objects}
                        id={SPARK_ITEMS[2].id}
                        dimension={SPARK_ITEMS[2].dimension}
                        periodName={chartDateGranularity}
                        colour={SPARK_ITEMS[2].colour}
                        hideTotals={SPARK_ITEMS[2].options.HIDE_TOTAL}
                        chartDateGranularity={chartDateGranularity}
                        hideUTCDateTime={false}
                    />
                ) : (
                    <SparkSkeleton />
                )}
                {!tagValidationSparkDataLoading ? (
                    <SparkLine
                        loading={tagValidationSparkDataLoading}
                        data={tagValidationSparkData.objects}
                        id={SPARK_ITEMS[3].id}
                        dimension={SPARK_ITEMS[3].dimension}
                        periodName={chartDateGranularity}
                        colour={SPARK_ITEMS[3].colour}
                        hideTotals={SPARK_ITEMS[3].options.HIDE_TOTAL}
                        chartDateGranularity={chartDateGranularity}
                        hideUTCDateTime={false}
                    />
                ) : (
                    <SparkSkeleton />
                )}
            </StyledContainer>

            <StyledContentContainer>
                <StyledTableGraph>
                    {!tableLoading ? (
                        <>
                            <StyledSearchContainer>
                                <LiveDatePicker
                                    searchDateRange={tableLiveDataState.tempDateRange}
                                    maxDate={TODAY}
                                    range={selectionDatePickerRange}
                                    isDisabled={false}
                                    isExpended={expendDatePicker}
                                    isDateApplied={isDatePickerApplied}
                                    togglePicker={toggleDatePickerCalender}
                                    handlePickerSelect={handleDatePickerSelect}
                                    handlePickerCancel={handleDatePickerCancel}
                                    handlePickerApply={handleDatePickerApply}
                                    handleLiveDataOnClick={handleLiveDataOnClick}
                                    dateRangePickerRef={dateRangePickerRef}
                                />

                                <StyledSearchTerms>
                                    <CommonSelect
                                        handleSelectChange={handleFilterSelectChange}
                                        options={tableTabConfig[selectedTabId].metrics}
                                        defaultValue={DEFAULT_FILTER_CATEGORY_VALUE}
                                        value={tableFilterState.tempSearchCategory}
                                        defaultText={DEFAULT_FILTER_CATEGORY_TEXT}
                                    />
                                </StyledSearchTerms>
                                <StyledSearchBar>
                                    <CommonInput
                                        inputType="text"
                                        name="table-filter"
                                        placeholder="Search..."
                                        value={tableFilterState.tempSearchTerm}
                                        onChange={handleFilterTextChange}
                                        showSearchIcon
                                    />
                                </StyledSearchBar>
                                <StyledSearchSubmit>
                                    <CommonButton
                                        value="Submit"
                                        disabled={
                                            tableFilterState.tempSearchCategory === '' ||
                                            tableFilterState.tempSearchTerm === ''
                                        }
                                        onClick={handleOnSubmitClick}
                                    />
                                </StyledSearchSubmit>
                            </StyledSearchContainer>

                            <TabList
                                noData={false}
                                tabLists={tableTabConfig}
                                loading={false}
                                handleTabElementClick={handleTabElementClick}
                            />

                            <DataTable
                                noData={tableNoData}
                                isLive={tableLiveDataState.isLive}
                                error={''}
                                loading={tableLoading}
                                dimensions={tableDimensions}
                                data={tableData}
                                selectedRows={[]}
                                metrics={tableMetrics}
                                orderBy={orderBy}
                                orderByDir={orderByDir}
                                setOwnerState={setOwnerState}
                                hideTotals={true}
                                selectedKey={0}
                            />

                            {!tableLiveDataState.isLive && tableData.objects.length > 0 && (
                                <TablePagination
                                    noData={tableNoData}
                                    currentPage={tablePaginations.currentPage}
                                    currentRowCount={tablePaginations.currentRowCount}
                                    totalResults={tablePaginations.totalResults}
                                    setOwnerState={setOwnerState}
                                    selectedRowCount={tablePaginations.currentRowCount}
                                    config={tablePaginations}
                                />
                            )}
                        </>
                    ) : (
                        <TableSkeleton />
                    )}
                </StyledTableGraph>
            </StyledContentContainer>
        </>
    );
};

export default HealthDashboard;
