import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { StyledDashboardView } from './dashboard';
import styled from 'styled-components';

// Required Components
import List from '../components/list';
import SidebarMenu from '../components/sidebar-menu';
import Nav from './nav';

// Required Widgets
import SearchBreadcrumbsWidget from '../breadcrumbs/widgets/search-breadcrumbs';

// Required Views
import ViewMenu from './menu';
import ViewModal from './modal';
import ViewNotification from './notifications';
import ViewPopup from './popup';

// Redux Actions
import { setModal } from '../redux/actions/modal';
import { setDescription, setTitle } from '../redux/actions/page-meta';

const StyledViewUser = styled.div`
    padding: 138px 30px 24px 30px;
    display: flex;
`;

const StyledSideBarContainer = styled.div`
    width: 25%;
    @media (min-width: 768px) and (max-width: 1200px) {
        width: 50%;
    }
    @media (min-width: 1200px) and (max-width: 1650px) {
        width: 35%;
    }
`;

const StyledSideBar = styled.div`
    overflow: auto;
    position: sticky;
    top: 100px;
    padding-bottom: 30px;
    max-height: 90vh;

    & .bread-crumbs__search {
        margin-bottom: 30px;
    }

    & .bread-crumb__home {
        background-color: ${props => props.theme.colours.white};
    }
`;

const StyledList = styled.div`
    width: 80%;
`;

const ViewUserConfiguration = () => {
    const user = useSelector(state => state.user);
    const account = useSelector(state => state.account);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useNavigate(location.pathname);

    // eslint-disable-next-line
    const userConfigurations = useMemo(() => {
        return [
            {
                header: {
                    type: 'Title',
                    title: 'User',
                },
                type: 'List',
                key: 'api',
                rows: [
                    {
                        key: 'user_details',
                        keyValue: 'user_details',
                        type: 'ListRowUserDetails',
                        title: 'Your Details',
                        copy: 'Your details',
                        name: `${user.firstName} ${user.lastName}`,
                        email: `${user.email}`,
                        onClick: () => listComponentOnClickHandler('user_details'),
                    },
                    {
                        key: 'user_update-details',
                        keyValue: 'user_update-details',
                        type: 'ListRowTitleCopy',
                        title: 'Update Details',
                        copy: 'Update your personal details like your name and email address.',
                        chevron: true,
                        onClick: () => listComponentOnClickHandler('user_update-details'),
                    },
                    {
                        key: 'user_reset-password',
                        keyValue: 'user_reset-password',
                        type: 'ListRowTitleCopy',
                        title: 'Reset Password',
                        copy: 'Update your password to something new and secure.',
                        chevron: true,
                        onClick: () => listComponentOnClickHandler('user_reset-password'),
                    },
                ],
            },
        ];
    }); // eslint-disable-next-line

    const [searchValue, setSearchValue] = useState('');
    const [visibleUserConfigurations, setVisibleUserConfigurations] = useState(userConfigurations);
    const [allUserConfigurations, setAllUserConfigurations] = useState(userConfigurations);
    const [showSidebar, setShowSidebar] = useState(false);

    useEffect(() => {
        setVisibleUserConfigurations(userConfigurations);
        setAllUserConfigurations(userConfigurations);
        setSearchValue('');
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        dispatch(setTitle('Profile'));
        dispatch(setDescription(''));
        // eslint-disable-next-line
    }, []);

    const listComponentOnClickHandler = useCallback(
        dataKey => {
            switch (dataKey) {
                case 'user_update-details':
                    dispatch(setModal('UpdateUserDetails', {}));
                    break;
                case 'user_reset-password':
                    dispatch(setModal('ResetUserPassword', {}));
                    break;
                default:
                    break;
            }
        },
        [dispatch]
    );

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const breadCrumbHomeOnClickHandler = e => {
        setVisibleUserConfigurations(allUserConfigurations);
        setSearchValue('');
    };

    const searchBarOnChange = event => {
        const searchValue = event.target.value;
        const searchValueRegex = new RegExp(searchValue, 'i');
        const visibleConfigsAfterSearch = [];

        allUserConfigurations.forEach(item => {
            const rowsWithSearchTerm = item.rows
                .filter(row => {
                    return searchValueRegex.test(row.title) || searchValueRegex.test(row.copy);
                })
                .map(row => {
                    return row;
                });

            if (rowsWithSearchTerm.length > 0 || item.header.title.includes(searchValue)) {
                visibleConfigsAfterSearch.push(item);
            }
        });

        setSearchValue(searchValue);
        setVisibleUserConfigurations(visibleConfigsAfterSearch);
    };

    if (!account.id) {
        return <Navigate to="/account-selection" />;
    }

    if (user.isLoggedIn !== true) {
        return <Navigate to="/" />;
    }

    return (
        <>
            <Nav showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
            <StyledDashboardView showSidebar={showSidebar}>
                <ViewMenu sidebarExpanded={showSidebar} history={history} />
                <StyledViewUser>
                    <StyledSideBarContainer>
                        <StyledSideBar>
                            <SearchBreadcrumbsWidget
                                onSearchChange={searchBarOnChange}
                                homeOnClick={breadCrumbHomeOnClickHandler}
                                searchValue={searchValue}
                            />
                            <SidebarMenu rows={visibleUserConfigurations} onClick={() => {}} />
                        </StyledSideBar>
                    </StyledSideBarContainer>
                    <StyledList>
                        <List
                            rows={visibleUserConfigurations}
                            header={{}}
                            errorMessageOverride="The search term you have entered does not match with any of the User Configurations."
                        />
                    </StyledList>
                </StyledViewUser>
                <ViewModal />
                <ViewNotification />
                <ViewPopup />
            </StyledDashboardView>
        </>
    );
};

export default ViewUserConfiguration;

/*
    
*/
