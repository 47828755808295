import { useAppSelector } from '../../../redux/hooks';

// Helpers
import { getUniqueYAxisValues, mapAreaSeriesData } from '../../helpers/widget-stacked-area-helpers';
import { unknownErrorData } from '../../helpers/errors';
import { getRawValueAsNumber, getValue } from '../../helpers/resource-data';
import dateStringToUnix from '../../../helpers/date-string-to-unix';

// Types
import { CubedField } from '../../../types';
import {
    OptionalSeriesComparisonConfig,
    ResourceComparisonData,
    StackedAreaChartComparisonData,
    StackedAreaChartOptionalSeries,
    WidgetTheme,
} from '../../types';
import { graphColours } from '../../helpers/graph-colours';

export type StackedAreaComparisonYAxisValue = {
    name: string;
    colour: WidgetTheme;
};

type useResourceStackedAreaChartComparisonArgs = {
    areaSeriesMetric: CubedField;
    resourceData: ResourceComparisonData;
    dateDimension: CubedField;
    optionalSeries?: OptionalSeriesComparisonConfig[];
    seriesLimit?: number;
};

const useResourceStackedAreaChartComparison = ({
    areaSeriesMetric,
    resourceData,
    dateDimension,
    optionalSeries,
    seriesLimit,
}: useResourceStackedAreaChartComparisonArgs): StackedAreaChartComparisonData => {
    const dates = useAppSelector(state => state.date);
    const comparisonDates = useAppSelector(state => state.comparisonDate);
    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];
        const yAxisOptions = resourceData.request?.yAxisOptions || [];

        let thisPeriodYAxisValues: StackedAreaComparisonYAxisValue[] = [];

        const stackedAreaChartComparisonData: StackedAreaChartComparisonData = {
            type: 'stackedAreaChartComparison',
            status: 'success',
            areaTitle: (yAxisOptions && yAxisOptions.find(option => option.active)?.name) || '',
            areaSeriesMetric: areaSeriesMetric,
            areaYAxisOptions: yAxisOptions,
            areaSeries: [],
            optionalSeries: [],
            areaComparisonSeries: [],
            optionalComparisonSeries: [],
        };

        if (resource.status === 'success') {
            // Find all the unique Y axis values and assign colours
            const uniqueYAxisValues = getUniqueYAxisValues(resource, areaSeriesMetric);

            thisPeriodYAxisValues = uniqueYAxisValues.map((value, index) => {
                return {
                    name: value,
                    colour: graphColours[index % graphColours.length] as WidgetTheme,
                };
            });

            const series = mapAreaSeriesData(
                thisPeriodYAxisValues,
                resource,
                areaSeriesMetric,
                yAxisOptions,
                dateDimension,
                dates,
                seriesLimit
            );

            stackedAreaChartComparisonData.areaSeries = series;

            if (optionalSeries) {
                const optionalSeriesData: StackedAreaChartOptionalSeries[] = [];

                optionalSeries.forEach(series => {
                    if (series.resourceData.status === 'success') {
                        if (series.resourceData.resources[0].status === 'success') {
                            const seriesData = series.resourceData.resources[0].objects.map(data => {
                                const rawValue = getRawValueAsNumber(data, series.yAxis.rawName);
                                return {
                                    x: dateStringToUnix(getValue(data, dateDimension.rawName)),
                                    y: rawValue,
                                };
                            });
                            optionalSeriesData.push({
                                name: series.title,
                                chartType: 'line',
                                data: seriesData,
                                colour: series.colour,
                                visible: true,
                            });
                        }
                    }
                });

                stackedAreaChartComparisonData.optionalSeries = optionalSeriesData;
            }
        }

        if (comparisonResource.status === 'success') {
            // Find all the unique Y axis values and assign colours
            const uniqueYAxisValues = getUniqueYAxisValues(comparisonResource, areaSeriesMetric);

            let yAxisValues: StackedAreaComparisonYAxisValue[] = [];

            if (thisPeriodYAxisValues.length > 0) {
                uniqueYAxisValues.forEach((value, index) => {
                    const matchingThisPeriodValue = thisPeriodYAxisValues.find(thisValue => thisValue.name === value);

                    if (matchingThisPeriodValue) {
                        yAxisValues.push({
                            name: value,
                            colour: matchingThisPeriodValue.colour,
                        });
                    }
                });
            } else {
                yAxisValues = uniqueYAxisValues.map((value, index) => {
                    return {
                        name: value,
                        colour: graphColours[index % graphColours.length] as WidgetTheme,
                    };
                });
            }

            const series = mapAreaSeriesData(
                yAxisValues,
                comparisonResource,
                areaSeriesMetric,
                yAxisOptions,
                dateDimension,
                comparisonDates,
                seriesLimit
            );

            stackedAreaChartComparisonData.areaComparisonSeries = series;

            if (optionalSeries) {
                const optionalSeriesData: StackedAreaChartOptionalSeries[] = [];

                optionalSeries.forEach(series => {
                    if (series.resourceData.status === 'success') {
                        if (series.resourceData.resources[1].status === 'success') {
                            const seriesData = series.resourceData.resources[1].objects.map(data => {
                                const rawValue = getRawValueAsNumber(data, series.yAxis.rawName);
                                return {
                                    x: dateStringToUnix(getValue(data, dateDimension.rawName)),
                                    y: rawValue,
                                };
                            });

                            optionalSeriesData.push({
                                name: series.title,
                                chartType: 'line',
                                data: seriesData,
                                colour: series.colour,
                                visible: true,
                            });
                        }
                    }
                });

                stackedAreaChartComparisonData.optionalComparisonSeries = optionalSeriesData;
            }
        }

        return stackedAreaChartComparisonData;
    }

    return unknownErrorData();
};

export default useResourceStackedAreaChartComparison;
