import React from 'react';
import { CubedIcon } from './types';

const IconTable: CubedIcon = ({ className }) => {
    return (
        <svg className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M48 264V160H232V264H48zm0 48H232V432H64c-8.8 0-16-7.2-16-16V312zM280 432V312H464V416c0 8.8-7.2 16-16 16H280zM464 264H280V160H464V264zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
        </svg>
    );
};

export default IconTable;
