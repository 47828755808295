/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Component
import InputSearch from '../../../inputs/input-search';

const StyledSearchContainer = styled.div`
    margin-bottom: 18px;
`;

const FilterContainer = styled.div`
    margin-bottom: 18px;
`;

const FilterParagraph = styled.p`
    display: inline-block;
    margin-bottom: 0;
`;

const FilterList = styled.ul`
    display: inline-block;
    padding-left: 12px;
    margin: 0px;
`;

const FilterListItem = styled.li<{ isActive: boolean }>`
    transition: ${props => props.theme.transition};
    display: inline-block;
    height: 20px;
    padding: 4px 10px 0px 10px;
    border-radius: 20px;
    background-color: ${props => (props.isActive ? props.theme.colours.primary : props.theme.colours.inputColour)};
    color: ${props => props.isActive && props.theme.colours.light};
    font-size: 0.8em;
    margin: 0;
    cursor: pointer;
    margin-right: 6px;

    &:hover {
        transition: ${props => props.theme.transition};
        background-color: ${props => (props.isActive ? '#45adbd' : '#d7e4ed')};
    }
`;

type CurrentFilter = {
    name: string;
    filter?: undefined;
};

export type TableFilterSearchProps = {
    enableSearch?: boolean;
    filters?: CurrentFilter[];
    currentFilter?: CurrentFilter | undefined;
    currentSearchValue?: string;
    onParameterChange: (filter: CurrentFilter, searchTerm: string) => void;
};

const TableFilterSearch = ({
    enableSearch,
    filters,
    onParameterChange,
    currentFilter,
    currentSearchValue,
}: TableFilterSearchProps) => {
    const [searchValue, setSearchValue] = useState('');
    const [filterValue, setFilterValue] = useState<CurrentFilter>({ name: '' });

    useEffect(() => {
        if (currentFilter) {
            setFilterValue(currentFilter);
        }

        if (currentSearchValue) {
            setSearchValue(currentSearchValue);
        }
    }, []);

    useEffect(() => {
        onParameterChange && onParameterChange(filterValue, searchValue);
    }, [searchValue, filterValue]);

    const handleOnSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    };

    const handleOnFilterClick = (filter: CurrentFilter, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setFilterValue(filter.name === filterValue.name ? { name: '' } : filter);
    };

    const renderSearch = () => {
        if (!enableSearch) {
            return null;
        }

        return (
            <StyledSearchContainer>
                <InputSearch value={searchValue} onChange={handleOnSearchValueChange} />
            </StyledSearchContainer>
        );
    };

    const renderFilters = () => {
        if (filters !== undefined && filters.length > 0) {
            return (
                <FilterContainer>
                    <FilterParagraph>Filters:</FilterParagraph>
                    <FilterList>
                        {filters.map(filter => {
                            const isActive = filter.name === filterValue.name;

                            return (
                                <FilterListItem
                                    isActive={isActive}
                                    key={filter.name}
                                    data-key={filter.name}
                                    onClick={event => handleOnFilterClick(filter, event)}
                                >
                                    {filter.name}
                                </FilterListItem>
                            );
                        })}
                    </FilterList>
                </FilterContainer>
            );
        }

        return null;
    };

    return (
        <div>
            {renderSearch()}
            {renderFilters()}
        </div>
    );
};

export default TableFilterSearch;
