import React from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled.span<{ checked: boolean; disabled: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: ${props =>
        props.checked
            ? props.theme.sectionDashboard.widget.menu.textColor
            : props.theme.sectionDashboard.widget.menu.separatorColor};
    border: 2px solid
        ${props =>
            props.disabled
                ? props.theme.colours.midGrey
                : props.checked
                ? props.theme.sectionDashboard.widget.menu.backgroundHover
                : props.theme.sectionDashboard.widget.menu.textColor};
    border-radius: 5px;

    :after {
        content: '';
        position: absolute;
        display: ${props => (props.checked ? 'block' : 'none')};
        left: 4px;
        top: 1px;
        width: 6px;
        height: 10px;
        border-radius: 1px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

const StyledContainer = styled.div<{ checked: boolean }>`
    display: block;
    position: relative;
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const StyledInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const LibraryListCheckbox = ({
    checked,
    disabled = false,
    onCheckedChange,
}: {
    checked: boolean;
    disabled?: boolean;
    onCheckedChange: () => void;
}) => {
    return (
        <StyledContainer checked={checked}>
            <StyledInput type="checkbox" checked={checked} onChange={onCheckedChange} disabled={disabled} />
            <StyledCheckbox checked={checked} disabled={disabled} />
        </StyledContainer>
    );
};

export default LibraryListCheckbox;
