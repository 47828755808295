import React from 'react';
import styled from 'styled-components';
import { useConfigTableContext } from '../../../context/config-table-context';

export enum ButtonSpaces {
    SPACE_BETWEEN = 'space-between',
    SPACE_AROUND = 'space-around',
    SPACE_EVENLY = 'space-evenly',
    FLEX_START = 'flex-start',
    FLEX_END = 'flex-end',
}

const StyledContainer = styled('div')<{ spacing: string }>`
    display: flex;
    justify-content: ${props => props.spacing};
    background-color: #ffffff;
    padding: 15px;
    border-top: 1px solid ${props => props.theme.colours.borderGrey};
    gap: 5px;
`;

type ActionBarProps = {
    spacing?: ButtonSpaces;
    children: React.ReactNode;
};

const ActionBar = ({ spacing = ButtonSpaces.SPACE_BETWEEN, children }: ActionBarProps) => {
    const { status } = useConfigTableContext();

    if (status) {
        return <StyledContainer spacing={spacing}>{children}</StyledContainer>;
    }
    return null;
};

export default ActionBar;
