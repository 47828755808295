import React, { memo } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Icon
import IconCalendar from './icons/calendar';

// Helper
import { STATIC_DATE_RANGE } from '../helpers/date-picker';

// Components
import CommonButton from './common/common-button';
import InputButtonWrapper from './inputs/input-button-wrapper';

// Styles
const StyledDatePicker = styled.div`
    position: relative;
    float: left;
    z-index: 10;
    margin-right: 10px;
`;

const StyledDatePickerPreviewHolder = styled.div`
    overflow: hidden;
`;

const StyledDatePickerPreview = styled('div')<{ isDisabled: boolean }>`
    background-color: ${props => props.theme.colours.offBlackLighter};
    box-sizing: border-box;
    color: ${props => props.theme.colours.white};
    cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
    opacity: ${props => props.isDisabled && 0.5};
    float: right;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    padding: 0 18px;
    position: relative;
`;

const StyledCalenderIcon = styled.div`
    display: inline-block;
    margin: 3px 12px 5px 0;

    & svg {
        width: 18px;
        height: 18px;
        fill: ${props => props.theme.colours.light};
    }
`;

const StyledCalenderDate = styled.p`
    display: inline-block;
    margin-bottom: 0px;
    transform: translateY(-3px);
`;

const StyledDatePickerCalenderHolder = styled.div`
    ${props => props.theme.boxShadow(3)}
    top: 36px;
    position: absolute;
    background-color: ${props => props.theme.colours.white};
    padding: 0 0 12px 0;

    & .rdrInputRanges {
        display: none;
    }
`;

const StyledDatePickerCalender = styled.div`
    border-radius: 2px;
    position: relative;
`;

const StyledButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const StyledLiveButton = styled.div`
    padding-left: 20px;
`;

// Types
type DatePickerRange = {
    key: string;
    startDate: Date;
    endDate: Date;
};

export type LiveDatePickerProps = {
    isDisabled: boolean;
    maxDate: Date;
    range: DatePickerRange;
    isExpended: boolean;
    isDateApplied: boolean;
    togglePicker: () => void;
    handlePickerSelect: () => void;
    handlePickerCancel: () => void;
    handlePickerApply: () => void;
    handleLiveDataOnClick: () => void;
    dateRangePickerRef: (instance: HTMLDivElement | null) => void;
};

const LiveDatePicker = ({
    isDisabled,
    maxDate,
    range,
    isExpended,
    isDateApplied,
    togglePicker,
    handlePickerSelect,
    handlePickerCancel,
    handlePickerApply,
    handleLiveDataOnClick,
    dateRangePickerRef,
}: LiveDatePickerProps) => {
    const datePickerElement = (
        <StyledDatePickerCalenderHolder ref={dateRangePickerRef} data-testid="date-picker-picker">
            <StyledDatePickerCalender>
                <DateRangePicker
                    onChange={handlePickerSelect}
                    moveRangeOnFirstSelection={false}
                    maxDate={maxDate}
                    ranges={[range]}
                    staticRanges={STATIC_DATE_RANGE}
                    rangeColors={['#FF7300']}
                />
            </StyledDatePickerCalender>
            <StyledButtons>
                {handleLiveDataOnClick !== undefined && (
                    <StyledLiveButton>
                        <CommonButton onClick={() => handleLiveDataOnClick()} value="Live" disabled={false} />
                    </StyledLiveButton>
                )}
                <InputButtonWrapper>
                    <CommonButton onClick={() => handlePickerApply()} value="Apply" />
                    <CommonButton onClick={() => handlePickerCancel()} value="Cancel" type="secondary" />
                </InputButtonWrapper>
            </StyledButtons>
        </StyledDatePickerCalenderHolder>
    );

    return (
        <StyledDatePicker>
            <StyledDatePickerPreviewHolder
                ref={dateRangePickerRef}
                onClick={() => togglePicker()}
                data-testid="datepicker-preview-holder"
            >
                <StyledDatePickerPreview isDisabled={isDisabled}>
                    <StyledCalenderIcon>
                        <IconCalendar />
                    </StyledCalenderIcon>
                    {isDateApplied ? (
                        <StyledCalenderDate>
                            {moment(range.startDate).format('Do MMM YYYY')} →{' '}
                            {moment(range.endDate).format('Do MMM YYYY')}
                        </StyledCalenderDate>
                    ) : (
                        <>
                            {handleLiveDataOnClick !== undefined ? (
                                <StyledCalenderDate>Live</StyledCalenderDate>
                            ) : (
                                <StyledCalenderDate>Select a Date</StyledCalenderDate>
                            )}
                        </>
                    )}
                </StyledDatePickerPreview>
            </StyledDatePickerPreviewHolder>

            {isExpended && datePickerElement}
        </StyledDatePicker>
    );
};

export default memo(LiveDatePicker, isEqual);
