// Helpers
import { unknownErrorData } from '../../../helpers/errors';
import { vennChartDataSeries, VENN_CHART_SCALE_FACTOR } from '../../../helpers/create-data-series';

// Types
import { CubedField } from '../../../../types';
import { ResourceComparisonData, VennData } from '../../../types';

export type UseResourceVennArgs = {
    resourceData: ResourceComparisonData;
    metric: CubedField;
    diagramTitle: string;
};

const useResourceVenn = ({ resourceData, metric, diagramTitle }: UseResourceVennArgs): VennData => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        const dimensionResourceData = resourceData.resources[0];
        const vennResourceData = resourceData.resources[1];

        if (!dimensionResourceData || !vennResourceData) {
            throw new Error('Missing dimension or venn data');
        }

        let data = vennChartDataSeries(dimensionResourceData, vennResourceData, metric);

        const vennData: VennData = {
            type: 'venn',
            status: 'success',
            series: {
                type: 'venn',
                name: diagramTitle,
                data: data,
                tooltip: {
                    pointFormat: `{point.name} : {divide point.value ${VENN_CHART_SCALE_FACTOR}}`,
                },
            },
            title: {
                text: diagramTitle,
            },
        };
        return vennData;
    }

    return unknownErrorData();
};

export default useResourceVenn;
