import Papa from 'papaparse';
import { FileColumnDataType, FileData, FileFormatConfig } from '../types';
import moment from 'moment';

const validateColumnValues = (file: File, columnConfig: FileFormatConfig) => {
    return new Promise<string[]>(resolve => {
        const reader = new FileReader();
        let fileData: FileData[];

        reader.readAsText(file);
        reader.onload = fileEvent => {
            const messages: string[] = [];
            const csvData: string = fileEvent.target?.result as string;

            Papa.parse(csvData, {
                skipEmptyLines: true,
                header: true,
                complete: result => {
                    fileData = result.data as FileData[];
                },
            });

            const invalidColumns: string[] = [];

            fileData.forEach(row => {
                for (const [key, value] of Object.entries(row)) {
                    const matchingConfig = columnConfig.find(config => config.header === key);

                    if (matchingConfig?.format === FileColumnDataType.NUMBER) {
                        if (isNaN(Number(value))) {
                            invalidColumns.push(key);
                        }
                    }

                    if (matchingConfig?.format === FileColumnDataType.DATE) {
                        if (!moment(value, 'YYYY-MM-DD', true).isValid()) {
                            invalidColumns.push(key);
                        }
                    }

                    if (matchingConfig?.format === FileColumnDataType.STRING_IN_ARRAY) {
                        if (!matchingConfig.comparisonArray?.includes(value as string)) {
                            invalidColumns.push(key);
                        }
                    }
                }
            });

            if (invalidColumns.length > 0) {
                messages.push(
                    `The following columns contain invalid values: ${[...new Set(invalidColumns)].join(
                        ', '
                    )}. Please check the file and try again.`
                );
            }

            resolve(messages);
        };
    });
};

export default validateColumnValues;
