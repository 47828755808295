import React from 'react';
import styled from 'styled-components';
import { useConfigTableContext } from '../../context/config-table-context';

const StyledCell = styled.td<{
    textAlign?: 'left' | 'center' | 'right';
    fitContent: boolean;
    isFetching: boolean;
    disabled: boolean;
}>`
    padding: 8px 15px;
    text-align: ${props => props.textAlign};
    width: ${props => props.fitContent && 0};
    min-width: ${props => props.fitContent && 'fit-content'};
    color: ${props => (props.disabled ? props.theme.colours.midGrey : props.theme.colours.textDefault)};
`;

const StyledLoading = styled.div`
    width: 70%;
    height: 15px;
    border-radius: 6px;
    background-color: ${props => props.theme.colours.lightGrey};
`;

type CellProps = {
    textAlign?: 'left' | 'center' | 'right';
    fitContent?: boolean;
    children: React.ReactNode;
};
const Cell = ({ textAlign = 'left', fitContent = false, children }: CellProps) => {
    const { status, isFetching, tableDisabled } = useConfigTableContext();

    if (status === 'success') {
        return (
            <StyledCell textAlign={textAlign} fitContent={fitContent} isFetching={isFetching} disabled={tableDisabled}>
                {isFetching ? <StyledLoading /> : children}
            </StyledCell>
        );
    }
    return null;
};

export default Cell;
