import React from 'react';
import styled from 'styled-components';

const StyledCategoryTitle = styled.h1`
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 1rem;
    font-weight: ${props => props.theme.dashboardBuilder.library.libraryList.categoryTitleFontWeight};
    margin: 0;
    color: ${props => props.theme.dashboardBuilder.library.libraryList.categoryTitleColor};
`;

const LibraryListCategoryTitle = ({ children }: { children: React.ReactNode }) => {
    return <StyledCategoryTitle>{children}</StyledCategoryTitle>;
};

export default LibraryListCategoryTitle;
