import { CONFIG_CHANNELS, CONFIG_GOALS, GEOLOCATION_CONFIG } from '../configurations/resources-config';
import buildDropdownOptions from '../react-query/select-functions/build-dropdown-options';
import { ConfigDataSuccess } from '../react-query/types';
import { CubedField } from '../types';

export const goalsDropdownQuery = {
    resource: CONFIG_GOALS,
    params: [
        { key: 'active', value: 1 },
        { key: 'order_by', value: '-default' },
    ],
    staleTime: 1000 * 60 * 5, // 5 minutes,
    select: (data: ConfigDataSuccess) => {
        return buildDropdownOptions({ data: data, labelField: 'name', valueField: 'id' });
    },
};

export const defaultGoalsDropdownQuery = {
    resource: CONFIG_GOALS,
    params: [
        { key: 'active', value: 1 },
        { key: 'order_by', value: '-name' },
        { key: 'default', value: 1 },
    ],
    staleTime: 1000 * 60 * 5, // 5 minutes,
    select: (data: ConfigDataSuccess) => {
        return buildDropdownOptions({ data: data, labelField: 'name', valueField: 'id' });
    },
};

export const channelsDropdownQuery = {
    resource: CONFIG_CHANNELS,
    params: [
        { key: 'active', value: 1 },
        { key: 'order_by', value: 'name' },
    ],
    staleTime: 1000 * 60 * 5, // 5 minutes,
    select: (data: ConfigDataSuccess) => {
        return buildDropdownOptions({ data: data, labelField: 'name', valueField: 'id' });
    },
};

export const configuredGoalsQuery = {
    resource: CONFIG_GOALS,
    params: [
        { key: 'active', value: 'true' },
        { key: 'group_by', value: 'name' },
        { key: 'order_by', value: 'name' },
    ],
};

export const geolocationDropdownQuery = ({
    field,
    offset = 0,
    limit = 100,
    search = '',
}: {
    field: CubedField;
    offset: number;
    limit: number;
    search?: string;
}) => {
    return {
        resource: GEOLOCATION_CONFIG,
        params: [
            { key: 'offset', value: offset },
            { key: 'limit', value: limit },
            { key: 'order_by', value: field.rawName },
            { key: `${field.rawName}__icontains`, value: search },
        ],
        limit: limit,
    };
};
