import React, { Component } from 'react';

import Message from './message';

//
// 	============================= DO NOT USE!! =============================
// 	If you want a warning message please use component/message.js
//

class WarningMessage extends Component {
    render() {
        return <Message copy={this.props.copy} title={this.props.title} type="error" size="small" />;
    }
}

export default WarningMessage;
