import React, { Component } from 'react';

import List from '../list';
import Form from '../form';
import InputButton from '../inputs/input-button';
import InputButtonWrapper from '../inputs/input-button-wrapper';

class AccordionFormList extends Component {
    constructor(props) {
        super(props);

        this.renderOptionalButtons = this.renderOptionalButtons.bind(this);
    }

    renderOptionalButtons() {
        if (this.props.config.optionalButtonConfig === undefined) {
            return null;
        }

        return (
            <InputButtonWrapper>
                {this.props.config.optionalButtonConfig.buttons.map(button => (
                    <InputButton
                        value={button.value}
                        buttonTheme={button.theme}
                        disabled={button.disabled}
                        onClick={button.onClick}
                    />
                ))}
            </InputButtonWrapper>
        );
    }

    render() {
        return (
            <div className="accordion__item-content__form-list">
                <p className="accordion__item-content__form-list__copy">{this.props.config.copy}</p>

                <Form config={this.props.config.formConfig} />

                <List
                    header={this.props.config.listConfig.header}
                    rows={this.props.config.listConfig.rows}
                    isLoading={this.props.config.listConfig.isLoading}
                    errorMessageOverride={this.props.config.listConfig.errorMessageOverride}
                />

                <this.renderOptionalButtons />
            </div>
        );
    }
}

export default AccordionFormList;
