import React from 'react';
import styled from 'styled-components';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { QueryStatus } from '@tanstack/react-query';

// Loader
import WidgetBaseLoader from '../../../../section-dashboard/widgets/base/widget-base-loader';

// Component
import Message from '../../../message';

// Context
import { useConfigDragAndDropTableContext } from '../context/config-drag-and-drop-table-context';

// Styles
const StyledContainer = styled.div<{ maxHeight?: string; status: QueryStatus; empty: boolean }>`
    max-height: ${props => props.maxHeight};
    overflow: ${props => (props.status !== 'success' || props.empty ? 'hidden' : 'auto')};
`;

const StyledTable = styled.table`
    width: 100%;
    background-color: #ffffff;
    border-collapse: collapse;
`;

const StyledStatusContainer = styled.div<{ maxHeight?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    background-color: #ffffff;
    width: 100%;
    min-height: 200px;
    height: ${props => (props.maxHeight ? props.maxHeight : '100%')};
`;

const StyledMessageContainer = styled.div`
    max-width: 500px;
`;

type TableProps = {
    maxHeight?: string;
    emptyMessage?: string;
    onDragEnd: (result: DropResult) => void;
    children: React.ReactNode;
};

const Table = ({ maxHeight = '100%', emptyMessage = 'No data available.', onDragEnd, children }: TableProps) => {
    const { status, empty } = useConfigDragAndDropTableContext();

    return (
        <StyledContainer maxHeight={maxHeight} status={status} empty={empty}>
            <DragDropContext onDragEnd={onDragEnd}>
                <StyledTable>{children}</StyledTable>
            </DragDropContext>

            {status === 'pending' ? (
                <StyledStatusContainer maxHeight={maxHeight}>
                    <div>
                        <WidgetBaseLoader width={40} height={40} margin={0} />
                    </div>
                </StyledStatusContainer>
            ) : null}

            {status === 'error' ? (
                <StyledMessageContainer>
                    <Message
                        type="error"
                        size="small"
                        copy="There was an error retrieving your data. Please try again later or contact support@cubed.email"
                    />
                </StyledMessageContainer>
            ) : null}

            {empty && (
                <StyledStatusContainer maxHeight={maxHeight}>
                    <StyledMessageContainer>
                        <Message type="info" size="small" copy={emptyMessage} />
                    </StyledMessageContainer>
                </StyledStatusContainer>
            )}
        </StyledContainer>
    );
};

export default Table;
