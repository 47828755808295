// Helpers
import { unknownErrorData } from '../../../helpers/errors';

// Types
import { CubedField } from '../../../../types';
import { ResourceData, LineGraphData, LineGraphLegendOrder } from '../../../types';

import { lineGraphDataSeries } from '../../../helpers/create-data-series';

export type UseResourceLineGraphArgs = {
    resourceData: ResourceData;
    dateDimension?: CubedField;
    seriesField?: CubedField;
    yAxis?: CubedField;
    legendOrder?: LineGraphLegendOrder;
};

const useResourceLineGraph = ({
    resourceData,
    dateDimension,
    seriesField,
    yAxis,
    legendOrder,
}: UseResourceLineGraphArgs): LineGraphData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success' && dateDimension && seriesField && yAxis) {
        const lineSeries = lineGraphDataSeries(resourceData, seriesField, dateDimension, yAxis, legendOrder);

        return {
            type: 'lineGraph',
            status: 'success',
            metric: yAxis.displayName,
            series: lineSeries || [],
        };
    }

    return unknownErrorData();
};

export default useResourceLineGraph;
