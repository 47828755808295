import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';

export const config = {
    pageTitle: 'New v Repeat Visits & Sales',
    dateDimension: fields.SALES_DATE_DATETIME,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: true,
            isEnableMetrics: true,
        },
    },
    resources: [resources.REPORT_MCS_SALES_JOURNEY],
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'report-sales-journey',
        allYMetrics: [
            fields.VISITS,
            fields.AVG_DELTA,
            fields.LC_SALES,
            fields.LC_REVENUE,
            fields.ASSISTS,
            fields.BOUNCES,
            fields.COST,
        ],
        initialYMetrics: [fields.LC_SALES],
    },
    table: {
        tabListItems: [
            {
                tabName: 'Overview',
                displayName: 'Overview',
                isSelected: true,
                metrics: [fields.VISITS, fields.LC_SALES, fields.LC_REVENUE, fields.LC_AOV],
            },
            {
                tabName: 'traffic',
                displayName: 'Traffic',
                isSelected: true,
                metrics: [
                    fields.VISITS,
                    fields.ASSISTS,
                    fields.ASSISTS_RATE,
                    fields.AVG_DELTA,
                    fields.BOUNCES,
                    fields.BOUNCE_RATE,
                ],
            },
            {
                tabName: 'conversion_rates',
                displayName: 'Conversion Rates',
                metrics: [fields.VISITS, fields.LC_SALES, fields.LC_CVR],
            },
            {
                tabName: 'cost',
                displayName: 'Cost Analysis',
                metrics: [fields.LC_REVENUE, fields.COST, fields.LC_CPA, fields.LC_ROI, fields.LC_ROAS],
            },
            {
                tabName: 'all',
                displayName: 'All',
                metrics: [
                    fields.VISITS,
                    fields.ASSISTS,
                    fields.ASSISTS_RATE,
                    fields.AVG_DELTA,
                    fields.BOUNCES,
                    fields.BOUNCE_RATE,
                    fields.LC_SALES,
                    fields.LC_CVR,
                    fields.LC_REVENUE,
                    fields.LC_AOV,
                    fields.COST,
                    fields.LC_CPA,
                    fields.LC_ROI,
                    fields.LC_ROAS,
                ],
            },
        ],
        fieldDataOverrides: {
            referer: [
                {
                    property: 'subchannels',
                    condition: function (subchannels) {
                        return Array.isArray(subchannels) ? subchannels.length > 1 : false;
                    },
                    field: fields.SUBCHANNEL_NAME,
                },
            ],
        },
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.REFERER.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SALES_JOURNEY,
                        field: fields.REPEAT_SALE,
                    },
                ],
            },
            [fields.SUBCHANNEL_NAME.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SALES_JOURNEY,
                        field: fields.REPEAT_SALE,
                    },
                ],
            },
            [fields.VISIT_IN_JOURNEY.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SALES_JOURNEY,
                        field: fields.REFERER,
                    },
                ],
            },
            [fields.REPEAT_SALE.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SALES_JOURNEY,
                        field: fields.SALE_NUMBER,
                    },
                ],
            },
            [fields.SALE_NUMBER.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SALES_JOURNEY,
                        field: fields.VISIT_IN_JOURNEY,
                    },
                ],
            },
            [fields.VISIT_NUMBER.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_SALES_JOURNEY,
                        field: fields.REFERER,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
