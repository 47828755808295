import React from 'react';
import { CubedIcon } from './types';

const GripDotsVertical: CubedIcon = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
            <path d="M48 144c26.5 0 48-21.5 48-48s-21.5-48-48-48S0 69.5 0 96s21.5 48 48 48zm0 160c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zM96 416c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM208 144c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm48 112c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM208 464c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48z" />
        </svg>
    );
};

export default GripDotsVertical;
