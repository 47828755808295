import React from 'react';
import styled from 'styled-components';
import { useConfigTableContext } from '../context/config-table-context';

const StyledBody = styled.tbody`
    & tr:not(:last-child) > td {
        border-bottom: 1px solid ${props => props.theme.colours.borderGrey};
    }

    tr:has(button[data-state='open']) {
        background-color: #f9f9f9;
    }
`;

const Body = ({ children }: { children: React.ReactNode }) => {
    const { status } = useConfigTableContext();

    if (status === 'success') {
        return <StyledBody>{children}</StyledBody>;
    }
    return null;
};

export default Body;
