import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import IconCheck from '../../../../components/icons/check';
import styled from 'styled-components';

const StyledCheckbox = styled.div<{ checked: boolean; disabled: boolean }>`
    width: 18px;
    height: 18px;
    background-color: ${props =>
        props.checked
            ? props.theme.sectionDashboard.widget.menu.textColor
            : props.theme.sectionDashboard.widget.menu.separatorColor};
    border: 2px solid
        ${props =>
            props.disabled
                ? props.theme.colours.midGrey
                : props.checked
                ? props.theme.sectionDashboard.widget.menu.backgroundHover
                : props.theme.sectionDashboard.widget.menu.textColor};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledIconCheck = styled(IconCheck)<{ checked: boolean }>`
    height: 12px;
    width: auto;
    position: relative;
    top: 1px;
    & path {
        fill: ${props =>
            props.checked ? props.theme.colours.white : props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledCheckboxItem = styled(DropdownMenu.CheckboxItem)<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    gap: 10px;
    outline: none;
    padding: 5px 20px;
    color: ${props =>
        props.disabled ? props.theme.colours.midGrey : props.theme.sectionDashboard.widget.menu.textColor};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

    &:hover,
    &[data-highlighted] {
        background-color: ${props => !props.disabled && props.theme.sectionDashboard.widget.menu.backgroundHover};
        color: ${props => !props.disabled && props.theme.colours.black};
    }

    &:hover ${StyledCheckbox}, :focus ${StyledCheckbox} {
        border-color: ${props => props.checked && props.theme.colours.black};
        background-color: ${props => props.checked && props.theme.colours.black};
    }

    &:hover,
    :focus ${StyledIconCheck} {
        & path {
            fill: ${props => props.theme.colours.white};
        }
    }
`;

const StyledLabel = styled.label<{ disabled: boolean }>`
    font-weight: 500;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export type ChartMenuCheckboxProps = {
    id: string;
    label: string;
    checked: boolean;
    disabled: boolean;
    onCheckedChange: () => void;
};

const ChartMenuCheckbox = ({ id, label, checked, disabled, onCheckedChange }: ChartMenuCheckboxProps) => {
    const handleSelect = (event: Event): void => {
        event.preventDefault();
        onCheckedChange();
    };

    return (
        <StyledCheckboxItem checked={checked} disabled={disabled} onSelect={handleSelect} data-testid={id}>
            <StyledCheckbox checked={checked} disabled={disabled}>
                <DropdownMenu.ItemIndicator>
                    <StyledIconCheck checked={checked} />
                </DropdownMenu.ItemIndicator>
            </StyledCheckbox>
            <StyledLabel disabled={disabled}>{label}</StyledLabel>
        </StyledCheckboxItem>
    );
};

export default ChartMenuCheckbox;
