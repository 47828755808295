import React from 'react';
import styled from 'styled-components';

const StyledSeparatorHorizontal = styled.div<{ spacingTop: boolean; spacingBottom: boolean }>`
    width: 100%;
    height: 1px;
    border: none;
    background: ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    margin: 0;
    margin-top: ${props => (props.spacingTop === false ? 0 : '5px')};
    margin-bottom: ${props => (props.spacingBottom === false ? 0 : '5px')};
`;

export type WidgetMenuSeparatorProps = {
    spacingTop?: boolean;
    spacingBottom?: boolean;
};

const WidgetMenuSeparatorHorizontal = ({ spacingTop = true, spacingBottom = true }: WidgetMenuSeparatorProps) => {
    return <StyledSeparatorHorizontal spacingTop={spacingTop} spacingBottom={spacingBottom} />;
};

export default WidgetMenuSeparatorHorizontal;
