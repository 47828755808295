import React from 'react';

// Types
import {
    AreaYAxisOption,
    SectionDashboardRequest,
    StackedAreaChartAreaSeries,
    StackedAreaChartComparisonData,
    StackedAreaChartData,
    StackedAreaChartOptionalSeries,
    StackedAreaChartSuccess,
} from '../../../../types';

// Components
import WidgetMenuDropdown from '../widget-menu-dropdown';
import WidgetMenuSeriesCheckbox from '../widget-menu-series-checkbox';
import WidgetMenuSectionTitle from '../widget-menu-section-title';
import WidgetMenuSeparatorHorizontal from '../widget-menu-separator-horizontal';
import WidgetMenuRadio from '../widget-menu-radio';
import WidgetMenuCheckbox from '../widget-menu-checkbox';

export type WidgetMenuStackedAreaChartProps = {
    data: StackedAreaChartData | StackedAreaChartComparisonData;
    request: SectionDashboardRequest;
    setData?: React.Dispatch<React.SetStateAction<StackedAreaChartSuccess>>;
};

const WidgetMenuStackedAreaChart = ({ data, request, setData }: WidgetMenuStackedAreaChartProps) => {
    const loading = data.status === 'loading';
    const success = data.status === 'success';

    const handleUpdateArea = (seriesName: string) => {
        if (success) {
            let updatedData = {
                ...data,
                areaSeries: data.areaSeries.map(series => {
                    if (series.name === seriesName) {
                        return {
                            ...series,
                            visible: !series.visible,
                        };
                    }
                    return series;
                }),
            };

            const comparisonData = data as StackedAreaChartComparisonData;
            if (comparisonData.status === 'success' && comparisonData.hasOwnProperty('areaComparisonSeries')) {
                updatedData = {
                    ...updatedData,
                    areaComparisonSeries: comparisonData.areaComparisonSeries.map(series => {
                        if (series.name === seriesName) {
                            return {
                                ...series,
                                visible: !series.visible,
                            };
                        }
                        return series;
                    }),
                };
            }

            setData && setData(updatedData);
        }
    };

    const handleUpdateOverlays = (seriesName: string) => {
        if (success && data.optionalSeries) {
            let updatedData = {
                ...data,
                optionalSeries: data.optionalSeries
                    ? data.optionalSeries.map((series: StackedAreaChartOptionalSeries) => {
                          if (series.name === seriesName) {
                              return {
                                  ...series,
                                  visible: !series.visible,
                              };
                          }
                          return series;
                      })
                    : [],
            };

            const comparisonData = data as StackedAreaChartComparisonData;
            if (comparisonData.status === 'success' && comparisonData.hasOwnProperty('optionalComparisonSeries')) {
                updatedData = {
                    ...updatedData,
                    optionalComparisonSeries: comparisonData.optionalComparisonSeries
                        ? comparisonData.optionalComparisonSeries.map((series: StackedAreaChartOptionalSeries) => {
                              if (series.name === seriesName) {
                                  return {
                                      ...series,
                                      visible: !series.visible,
                                  };
                              }
                              return series;
                          })
                        : [],
                };
            }
            setData && setData(updatedData);
        }
    };

    if (loading) {
        return <WidgetMenuDropdown loading={loading} />;
    }

    if (success) {
        let seriesOptions: StackedAreaChartAreaSeries[] = [];

        if (data.areaSeries) {
            seriesOptions = data.areaSeries;

            if ('areaComparisonSeries' in data) {
                data.areaComparisonSeries.forEach(series => {
                    const matchingSeries = seriesOptions.find(option => option.name === series.name);
                    if (!matchingSeries) {
                        seriesOptions.push(series);
                    }
                });
            }
        }

        return (
            <>
                <WidgetMenuDropdown loading={loading}>
                    <WidgetMenuSectionTitle title={`Select ${data.areaSeriesMetric.displayName} to display:`} />

                    {seriesOptions.map((option, index) => {
                        return (
                            <WidgetMenuSeriesCheckbox
                                key={option.name}
                                index={index}
                                label={option.name}
                                checked={option.visible}
                                onCheckedChange={() => handleUpdateArea(option.name)}
                                colour={option.colour}
                            />
                        );
                    })}
                    <WidgetMenuSeparatorHorizontal />

                    <WidgetMenuSectionTitle title={'Select metric (Y Axis):'} />
                    <WidgetMenuRadio
                        options={
                            success && request.yAxisOptions
                                ? request.yAxisOptions.map(option => {
                                      return {
                                          label: option.name,
                                          value: option.field.rawName,
                                      };
                                  })
                                : []
                        }
                        value={
                            request.yAxisOptions
                                ? request.yAxisOptions.filter((option: AreaYAxisOption) => option.active)[0].field
                                      .rawName
                                : undefined
                        }
                        onValueChange={request.handleSetYAxis}
                    />

                    {data.optionalSeries && data.optionalSeries.length > 0 ? (
                        <>
                            <WidgetMenuSeparatorHorizontal />
                            <WidgetMenuSectionTitle title={'Select overlays:'} />
                            {data.optionalSeries.map(option => {
                                return (
                                    <WidgetMenuCheckbox
                                        key={option.name}
                                        label={option.name}
                                        checked={option.visible}
                                        onCheckedChange={() => handleUpdateOverlays(option.name)}
                                    />
                                );
                            })}
                        </>
                    ) : null}
                </WidgetMenuDropdown>
            </>
        );
    }

    return null;
};

export default WidgetMenuStackedAreaChart;
