import React, { memo } from 'react';

// Core Dependencies
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Components
import BreadcrumbHome from '../components/breadcrumb-home';
import SimpleBreadcrumbItem from '../components/simple-breadcrumb-item';
import BreadcrumbSearchBar from '../components/breadcrumb-search-bar';

// Types
import { SimpleBreadCrumb, SimpleBreadcrumbsWidgetProps } from '../types';

// Styles
const StyledBreadcrumbsContainer = styled.div`
    display: flex;
`;

const StyledBreadcrumbsWrapper = styled.div`
    text-transform: uppercase;
    margin: -12px 0 4px 22px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
`;

const SimpleBreadcrumbsWidget = ({ breadcrumbs = [], homeOnClick }: SimpleBreadcrumbsWidgetProps) => {
    const renderBreadcrumb = (breadcrumb: SimpleBreadCrumb) => {
        switch (breadcrumb.type) {
            case 'search':
                return (
                    <BreadcrumbSearchBar
                        key={breadcrumb.id}
                        onChange={breadcrumb.onChange!}
                        value={breadcrumb.value!}
                    />
                );
            default:
                return (
                    <SimpleBreadcrumbItem
                        onClick={breadcrumb.onClick}
                        key={breadcrumb.id}
                        id={breadcrumb.id}
                        copy={breadcrumb.copy}
                        styleOverride={breadcrumb.styleOverride}
                    />
                );
        }
    };

    return (
        <StyledBreadcrumbsContainer>
            <StyledBreadcrumbsWrapper>
                {breadcrumbs.map(breadcrumb => renderBreadcrumb(breadcrumb))}
                <BreadcrumbHome onClick={homeOnClick} />
            </StyledBreadcrumbsWrapper>
        </StyledBreadcrumbsContainer>
    );
};

export default memo(SimpleBreadcrumbsWidget, isEqual);
