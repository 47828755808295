import React from 'react';
import styled from 'styled-components';

// Helpers
import { channelColours } from '../helpers/colours';

//Types
import { CubedField } from '../types';
import { Instance } from 'tinycolor2';
import { DimensionsDefault } from '../breadcrumbs/types';

// Components
import SelectionLegendItem from './selection-legend-item';

const StyledSelectionLegendDiv = styled.div`
    display: inline-block;
    width: 100%;
    position: relative;
    z-index: 2;
`;

const StyledSelectionList = styled.ul`
    list-style: none;
    font-size: 14px;
    max-height: 310px;
    overflow-y: auto;
`;

const StyledEmptyList = styled.li`
    background-color: ${props => props.theme.colours.offLight};
    padding: 3px;
    margin-bottom: 12px;
    border-radius: 12px;
    padding: 23px;
`;

type ResponseData = {
    [key: string]: DimensionsDefault;
};

export type SelectedRow = {
    rowId: number;
    colour: Instance;
    columns: CubedField[];
    data: ResponseData;
};

export type SelectionLegendProps = {
    dimension: CubedField[];
    maxLength: number;
    selectedRows: SelectedRow[];
    onUpdateRows: () => void;
};

const SelectionLegend = ({ dimension, maxLength = 5, selectedRows, onUpdateRows }: SelectionLegendProps) => {
    const count = selectedRows.length < 1 ? maxLength - 1 : maxLength - selectedRows.length;
    const emptyItems = Array.from({ length: count }, (_, index) => <StyledEmptyList key={index} />);

    const renderLegendItems = () => {
        if (selectedRows.length < 1) {
            return (
                <SelectionLegendItem
                    key={'all'}
                    text={'All'}
                    colour={channelColours[6].colour}
                    selectedRows={selectedRows}
                    rowId={null}
                    isAll={true}
                    onUpdateRows={onUpdateRows}
                />
            );
        }
        return selectedRows.map(row => {
            return (
                <SelectionLegendItem
                    key={row.rowId}
                    text={(row.data as ResponseData)[dimension[0].rawName].value}
                    colour={row.colour}
                    selectedRows={selectedRows}
                    rowId={row.rowId}
                    onUpdateRows={onUpdateRows}
                />
            );
        }, this);
    };

    return (
        <StyledSelectionLegendDiv>
            <StyledSelectionList>
                {renderLegendItems()}
                {emptyItems}
            </StyledSelectionList>
        </StyledSelectionLegendDiv>
    );
};

export default SelectionLegend;
