import React from 'react';
import { RULE_SEGMENTER_PATTERN } from '../../../helpers/segmenter/reference-objects';
import LayoutAdvancedRuleBuilder from './advanced-rule-builder';
import LayoutModalNavigation from '../../../components/navigation/layout-modal-navigation';
import LoadingSpinner from '../../../components/loading-spinner';
import WarningMessage from '../../../components/warning-message';

import { StyledH1, StyledP } from '../create-conditions';

import { useDispatch } from 'react-redux';
import { removeModal } from '../../../redux/actions/modal';

import {
    SEGMENTER_PATTERN_RULES_FK_RESOURCE,
    SEGMENTER_PATTERN_RULES_RESOURCE,
} from '../../../configurations/resources-advanced-rules';

const LayoutModalManageSegmenterPattern = () => {
    const dispatch = useDispatch();
    const heading = 'Manage Segmenter Pattern';
    const subHeading =
        'Add, edit, or remove Segmenter Patterns, rules, and specify their priority. Each Rule must contain a unique combination of conditions.';

    const renderModalNavigation = (pageError: boolean, pageLoading: boolean) => {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={false}
                    handleSaveChanges={() => {}}
                    saveChangesButtonLoading={false}
                    saveButtonDisabled={false}
                    updatedData={[]}
                    deletedData={[]}
                    saveChangesButtonHidden={true}
                    handleCloseClick={() => dispatch(removeModal())}
                />
                <StyledH1>{heading}</StyledH1>
                {!pageError && <StyledP>{subHeading}</StyledP>}
                {!pageError && pageLoading && <LoadingSpinner />}
                {pageError && (
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                )}
            </>
        );
    };
    return (
        <LayoutAdvancedRuleBuilder
            resource={SEGMENTER_PATTERN_RULES_RESOURCE}
            fkResource={SEGMENTER_PATTERN_RULES_FK_RESOURCE}
            fkColumnName={'pattern'}
            ruleObject={RULE_SEGMENTER_PATTERN}
            segmentName={'pattern'}
            segmentType={'Channel'}
            segmentTypeKey={'referer'}
            modalNavigation={renderModalNavigation}
        />
    );
};
export default LayoutModalManageSegmenterPattern;
