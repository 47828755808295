/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Helper
import { formatSalesTitle } from '../helpers/formatGlobalSaleTitle';

// Types
import { RootState } from '../redux/reducers/core';

// Styles
const StyledDropdown = styled.div<{ isMetric: boolean }>`
    display: inline-block;

    width: ${props => props.isMetric && `100%`};
    border: ${props => props.isMetric && `none`};
    font-size: ${props => props.isMetric && `14px`};
`;

const StyledDropdownTitle = styled.div`
    font-weight: 700;
    margin: 15px 0px 0px 12px;
    letter-spacing: 0.04rem;
`;

const StyledDropdownLegend = styled.div`
    display: inline-block;
    width: 10%;
    padding-top: 15px;
`;

const StyledSolidDropdownLegend = styled('hr')`
    border-top: 2px solid;
`;

const StyledDashedDropdownLegend = styled('hr')`
    border-top: 2px dashed;
    border-bottom: 0;
`;

const StyledDropdownList = styled.select<{ showDropdownLegend: boolean }>`
    line-height: 40px;
    width: ${props => (props.showDropdownLegend ? `80%` : `100%`)};
    float: ${props => props.showDropdownLegend && `right`};
    max-width: 300px;
    margin: 12px;
    font-size: 16px;
    background-color: transparent;
    color: ${props => props.theme.colours.textDefault};
    border: 0;
    border-bottom: 1px solid ${props => props.theme.colours.borderGrey};
`;

const StyledDropdownListItem = styled.option`
    background-color: ${props => props.theme.colours.white};
`;

type DropdownStringOptions = {
    alwaysShowPercentages?: boolean;
    dataType?: number;
    defaultOrderDir?: 'desc' | 'asc';
    description?: string;
    displayName?: string;
    displayProportion?: boolean;
    rawName?: string;
    value?: number;
};

export type DropdownProps = {
    defaultValue: number | string;
    indexId: number;
    legendStyle: 'solid' | 'dashed';
    options: DropdownStringOptions[];
    ownerCallback?: (value: string, indexId: number) => void;
    showDropdownLegend?: boolean;
    showTitle: boolean;
    isMetric?: boolean;
    title: string;
    selectedRowCount?: number;
};

const Dropdown = ({
    defaultValue,
    indexId,
    legendStyle,
    options,
    ownerCallback,
    showDropdownLegend,
    showTitle,
    isMetric,
    title,
    selectedRowCount,
}: DropdownProps) => {
    const { saleTitle } = useSelector((state: RootState) => state);

    const [selected, setSelected] = useState(selectedRowCount || defaultValue);

    const handleOwnerCallback = ownerCallback || function () {};

    const getAllMetricsDropDownList = () => {
        let optionList = options.map(option => {
            return (
                <StyledDropdownListItem value={option.value || option.rawName} key={option.value || option.rawName}>
                    {formatSalesTitle(option.displayName, saleTitle.globalSalesTitle) || option.value}
                </StyledDropdownListItem>
            );
        });

        return optionList;
    };

    const onSelectChange = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            setSelected(event.target.value);
            handleOwnerCallback(event.target.value, indexId);
        },
        [selected]
    );

    return (
        <StyledDropdown isMetric={isMetric!}>
            {showTitle && <StyledDropdownTitle>{title}</StyledDropdownTitle>}
            {showDropdownLegend && (
                <StyledDropdownLegend>
                    {legendStyle === 'solid' ? <StyledSolidDropdownLegend /> : <StyledDashedDropdownLegend />}
                </StyledDropdownLegend>
            )}
            <StyledDropdownList onChange={onSelectChange} value={selected} showDropdownLegend={showDropdownLegend!}>
                {getAllMetricsDropDownList()}
            </StyledDropdownList>
        </StyledDropdown>
    );
};

export default Dropdown;
