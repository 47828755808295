import React from 'react';

// Component
import SimpleTableHeaderCell from '../cells/simple-table-header-cell';

// Types
import { orderByDir, SimpleTableColumn, SimpleTableHeader } from '../../types';

type SimpleTableHeaderRowProps = {
    header?: SimpleTableHeader;
    columns?: SimpleTableColumn[];
    hasIcons?: boolean;
    hasEndAction?: boolean;
    hasPills?: boolean;
    allowOrdering?: boolean;
    currentOrderBy?: { column: number | null; order: orderByDir | null };
    handleOnColumnOrderChanged?: (index: number, order: orderByDir) => void;
};

const SimpleTableHeaderRow = ({
    header,
    columns,
    hasIcons,
    hasEndAction,
    hasPills,
    allowOrdering,
    currentOrderBy,
    handleOnColumnOrderChanged,
}: SimpleTableHeaderRowProps) => {
    return (
        <thead>
            <SimpleTableHeaderCell
                header={header}
                columns={columns}
                hasIcons={hasIcons}
                hasEndAction={hasEndAction}
                hasPills={hasPills}
                allowOrdering={allowOrdering}
                currentOrderBy={currentOrderBy}
                handleOnColumnOrderChanged={handleOnColumnOrderChanged}
            />
        </thead>
    );
};

export default SimpleTableHeaderRow;
