import { CurrencyAction } from '../types';

type CurrencyIsoMap = {
    [key: string]: string;
};

const isoCurrencyMap: CurrencyIsoMap = {
    GBP: '£',
    USD: '$',
    EUR: '€',
    AUD: '$',
    HKD: '$',
    SGD: '$',
    BRL: '$',
};

export default function reducer(state = initialState, action: CurrencyAction) {
    switch (action.type) {
        case 'SET_CURRENCY':
            const currencySymbol = action.payload.iso in isoCurrencyMap ? isoCurrencyMap[action.payload.iso] : '£';
            return Object.assign({}, state, {
                symbol: currencySymbol,
            });
        default:
            return state;
    }
}

const initialState = {
    symbol: '£',
};
