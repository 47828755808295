import * as resources from '../../../configurations/resources';
import { CubedField } from '../../../types';

// Get all the resources that are available for the dashboard builder
export const resourceObjects = () => {
    return Object.entries(resources).filter(resource => {
        if ('dimensions' in resource[1] && resource[1].dimensions && resource[1].dashboardBuilderEnabled) {
            return true;
        }
        return false;
    });
};

// Get all the available dimensions from the resources
const getDimensions = () => {
    const resources = resourceObjects();

    const dimensions: CubedField[][] = [];

    resources.forEach(res => {
        if ('dimensions' in res[1] && res[1].dimensions && res[1].dashboardBuilderEnabled) {
            dimensions.push(res[1].dimensions);
        }
    });

    const dimensionsFlat = [...new Set(dimensions.flat())];

    const dimensionDropdownOptions = dimensionsFlat
        .map(dim => {
            return {
                value: dim.id,
                label: dim.displayName,
            };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    return dimensionDropdownOptions;
};

export default getDimensions;
