import React from 'react';
import styled from 'styled-components';
import IconPlus from '../../components/icons/plus';

// Styled Components
const StyledAddButtonContainer = styled.div`
    height: 80px;
    padding: 20px 20px 0px 20px;
    border-bottom: ${props => (props.nested ? '1px solid #d3d3d3' : 'none')};
`;

const StyledAddConditionButton = styled.button`
    height: 40px;
    border: 0;
    color: ${props => (props.disabled ? '#d3d3d3' : '#0a0a0a')};
    font-weight: 500;
    padding: 0px 10px;
    margin-right: 10px;
    font-size: 0.9rem;
`;

const StyledAddGroupButton = styled.button`
    height: 40px;
    border: 0;
    color: ${props => (props.disabled ? '#d3d3d3' : '#0a0a0a')};
    font-weight: 500;
    padding: 0px 10px;
    margin-right: 10px;
    font-size: 0.9rem;
`;

const StyledIconContainer = styled.div`
    width: 14px;
    height: 14px;
    margin-right: 5px;
    position: relative;
    top: 3px;
    display: inline-block;
    & svg {
        path {
            fill: ${props => (props.disabled ? '#d3d3d3' : '#0a0a0a')};
        }
    }
`;

const CreateSegmentFooter = ({
    nested,
    disableAddConditionButton,
    onShowAddCondition,
    disableAddGroupButton,
    onAddGroup,
}) => {
    return (
        <StyledAddButtonContainer nested={nested}>
            <StyledAddConditionButton disabled={disableAddConditionButton} onClick={onShowAddCondition}>
                <StyledIconContainer disabled={disableAddConditionButton}>
                    <IconPlus />
                </StyledIconContainer>
                Add a Condition
            </StyledAddConditionButton>
            {!disableAddGroupButton && (
                <StyledAddGroupButton onClick={onAddGroup}>
                    <StyledIconContainer>
                        <IconPlus />
                    </StyledIconContainer>
                    Add a Group of Conditions
                </StyledAddGroupButton>
            )}
        </StyledAddButtonContainer>
    );
};

export default CreateSegmentFooter;
