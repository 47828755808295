import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

// HighCharts
import Message from '../../../components/message';
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Helper
import { assignColour } from '../../../helpers/colours';
import { sortChartData } from './helpers/chart-helper';
import { calculateProportion, getTotals } from '../../../components/tables/helpers/table-helper';

const StyledDonutChart = styled.div`
    min-height: 310px;
    min-width: 0px;
    postion: relative;
    opacity: ${props => props.isLoading && 0.5};
    transition: ${props => props.isLoading && props.theme.transition};
    flex-grow: 1;
    flex-basis: 20em;
`;

const DonutChart = ({
    config,
    data,
    dimensions,
    metrics,
    selectedMetric,
    selectedRows,
    messageTitle = 'No data.',
    messageCopy = '',
    messageType = 'info',
    loading,
    noData,
}) => {
    const columns = [...dimensions, ...metrics];
    const [sortedData, setSortedData] = useState([]);
    const [isPercentage, setIsPercentage] = useState(true);
    const [hasNoData, setHasNoData] = useState(noData);

    const currencySymbol = useSelector(state => state.currency.symbol);

    useEffect(() => {
        getDonutChartData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedMetric, selectedRows]);

    const getDonutChartData = () => {
        let donutData = [];

        if (selectedRows.length === 0) {
            columns.forEach(column => {
                if (data.objects !== undefined) {
                    data.objects.map(row => {
                        if (selectedMetric[0].displayProportion) {
                            let donutValue = parseFloat(row[selectedMetric[0].rawName].proportion);

                            if (!loading) {
                                if (Number.isNaN(donutValue)) {
                                    setIsPercentage(false);
                                } else {
                                    setIsPercentage(true);
                                }
                            }

                            if (column.children && row[column.rawName] !== undefined) {
                                donutData.push({
                                    name: row[column.rawName].value,
                                    y: donutValue,
                                    color: assignColour(row, dimensions, selectedRows)._originalInput,
                                });
                            }
                        } else {
                            setIsPercentage(false);
                        }
                        return donutData;
                    });
                }
                return donutData;
            });
        }

        if (selectedRows.length > 0) {
            let newData = { objects: [] };
            let newProportionsData = [];
            let totals = [];
            let newTotal = 0;

            selectedRows.forEach(row => {
                totals = getTotals(row.dataMeta, dimensions, selectedMetric);
                newTotal = newTotal + row.data[selectedMetric[0].rawName].raw_value;
                totals[1].displayData = currencySymbol + newTotal;
                row.data.colour = row.colour._originalInput;
                newData.objects.push(row.data);
            });

            if (selectedMetric[0].displayProportion) {
                newProportionsData = calculateProportion(selectedMetric[0], totals, newData);

                newProportionsData.objects.forEach(row => {
                    let donutValue = parseFloat(row[selectedMetric[0].rawName].proportion);
                    if (!loading) {
                        if (Number.isNaN(donutValue)) {
                            setIsPercentage(false);
                        } else {
                            setIsPercentage(true);
                        }
                    }

                    donutData.push({
                        name: row[dimensions[0].rawName].value,
                        y: donutValue,
                        color: row.colour,
                    });
                });
            } else {
                setIsPercentage(false);
            }
        }

        let dataSorted = donutData.sort(sortChartData);
        // return top 10 from data
        setSortedData(dataSorted.slice(0, 10));

        if (!loading) {
            setHasNoData(noData);
        }
    };

    if (!loading) {
        config.chart.options.series[1].data = sortedData;
    }
    if (hasNoData) {
        return (
            <StyledDonutChart isLoading={loading}>
                <Message title={messageTitle} copy={messageCopy} type={messageType} />
            </StyledDonutChart>
        );
    }

    if (!isPercentage) {
        return (
            <StyledDonutChart isLoading={loading}>
                <Message
                    title={'Column not in the correct format to display on Doughnut Chart'}
                    copy={''}
                    type={'info'}
                />
            </StyledDonutChart>
        );
    }
    return (
        <StyledDonutChart isLoading={loading}>
            <CommonHighcharts
                highcharts={Highcharts}
                options={{ ...config.chart.options }}
                containerProps={{
                    style: {
                        height: '100 %',
                    },
                }}
            />
        </StyledDonutChart>
    );
};

export default DonutChart;
