import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';
import IconEye from '../icons/eye';
import IconEyeSlash from '../icons/eye-slash';
import { WidgetTheme } from '../../../types';

const StyledBaseContainer = css`
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledIconContainer = styled.div<{ checked: boolean }>`
    ${StyledBaseContainer}
    display: ${props => props.checked && 'none'};
`;

const StyledIndicator = styled(DropdownMenu.ItemIndicator)`
    ${StyledBaseContainer}
`;

const StyledIconEye = styled(IconEye)<{ colour: WidgetTheme; id: string }>`
    width: 18px;
    height: auto;
    fill: url(${props => props.id});
`;

const StyledIconEyeSlash = styled(IconEyeSlash)<{ colour: WidgetTheme; id: string }>`
    width: 20px;
    height: auto;
    fill: url(${props => props.id});
`;

const StyledLabel = styled.label`
    margin-left: 10px;
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.fontWeight};
    cursor: pointer;
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
`;

const StyledCheckboxItem = styled(DropdownMenu.CheckboxItem)`
    display: flex;
    align-items: center;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;

    &:hover,
    :focus {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
    }

    &:hover,
    :focus ${StyledLabel} {
        color: ${props => props.theme.sectionDashboard.widget.menu.labelHover};
    }
`;

export type WidgetMenuSeriesCheckboxProps = {
    index: number;
    label: string;
    checked: boolean;
    colour: WidgetTheme;
    onCheckedChange: () => void;
};

const WidgetMenuSeriesCheckbox = ({
    index,
    label,
    checked,
    colour,
    onCheckedChange,
}: WidgetMenuSeriesCheckboxProps) => {
    const handleSelect = (event: Event): void => {
        event.preventDefault();
        onCheckedChange();
    };

    return (
        <StyledCheckboxItem checked={checked} onSelect={handleSelect}>
            <StyledIconContainer checked={checked}>
                <StyledIconEyeSlash colour={colour} id={`#linear-gradient-icon-eye-slash-${index}`} />
            </StyledIconContainer>
            <StyledIndicator>
                <StyledIconEye colour={colour} id={`#linear-gradient-icon-eye-${index}`} />
            </StyledIndicator>

            <StyledLabel>{label}</StyledLabel>
        </StyledCheckboxItem>
    );
};

export default WidgetMenuSeriesCheckbox;
