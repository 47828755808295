import React, { createContext } from 'react';

// Hooks
import { useFormContext } from 'react-hook-form';

// Helpers
import getCurrentSelectedMetrics from '../../helpers/get-current-selected-metrics';
import getAvailableResources from '../../helpers/get-available-resources';
import getMetrics from '../../helpers/get-metrics';

// Types
import { DropdownOption } from '../../../../section-dashboard/types';

export interface CreateAWidgetFormContextValues {
    allMetrics: DropdownOption[];
    currentSelectedMetrics: string[];
    currentAvailableMetrics: DropdownOption[];
}

export const CreateAWidgetFormContext = createContext<CreateAWidgetFormContextValues>(
    {} as CreateAWidgetFormContextValues
);

const CreateAWidgetFormContextProvider = ({ children }: { children: JSX.Element }) => {
    // Form Values
    const { watch } = useFormContext();
    const formValues = watch();

    // All Metrics
    const allMetrics = getMetrics({ availableResources: getAvailableResources({}) });

    // Current Selected Metrics
    const currentSelectedMetrics = getCurrentSelectedMetrics(formValues);

    // Current Available Resources
    const currentAvailableResources = getAvailableResources({
        dimension: formValues.groupBy,
        currentMetrics: currentSelectedMetrics,
    });

    // Current Available Metrics
    const currentAvailableMetrics = getMetrics({
        availableResources: currentAvailableResources,
    });

    return (
        <CreateAWidgetFormContext.Provider value={{ allMetrics, currentSelectedMetrics, currentAvailableMetrics }}>
            {children}
        </CreateAWidgetFormContext.Provider>
    );
};

export default CreateAWidgetFormContextProvider;
