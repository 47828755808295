import React from 'react';
import styled from 'styled-components';
import tinyColor from 'tinycolor2';

import theme from '../styled-components/theme.styled';

// Types
type ButtonType = 'primary' | 'secondary' | 'warning' | 'danger' | 'success' | 'info' | 'link' | 'addItem';

export type ButtonProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    level: ButtonType;
    value: string;
    fullWidth: boolean;
    children: React.ReactNode;
    disabled: boolean;
};

type IsDisabledProps = {
    disabled: boolean;
    theme: typeof theme;
};

type DefaultHoverActiveProps = {
    theme: typeof theme;
};

type StylesForLevelProps = {
    level: ButtonType;
    theme: typeof theme;
};

const edge = (colour: string) => `
    border: none;
    border-top: 1px solid ${tinyColor(colour).lighten(12).toString()};
    border-bottom: 1px solid ${tinyColor(colour).darken(12).toString()};
`;

const hover = (colour: string) => `
    &:hover {
        background-color: ${tinyColor(colour).darken(6).toString()};
    }
    &:active {
        background-color: ${tinyColor(colour).lighten(12).toString()};
    }
`;

const isDisabled = (props: IsDisabledProps) => {
    if (props.disabled) {
        return `
            cursor: not-allowed;
            color: ${props.theme.colours.white};
            ${edge(props.theme.colours.darkGrey)};
            ${hover(props.theme.colours.darkGrey)};
            background-color: ${props.theme.colours.darkGrey};
        `;
    }

    return;
};

const defaultHoverActive = (props: DefaultHoverActiveProps) => {
    return `
            &:hover {
                ${props.theme.boxShadow(0.5)};
            }
            &:active {
                ${props.theme.boxShadow(0)};
            }
        `;
};

const stylesForLevel = (props: StylesForLevelProps) => {
    if (props.level === undefined) {
        return defaultHoverActive(props);
    }

    switch (props.level) {
        case 'primary':
            return `
                color: ${props.theme.colours.white};
                ${edge(props.theme.colours.offBlack)};
                ${hover(props.theme.colours.offBlack)};
                background-color: ${props.theme.colours.offBlack};
            `;
        case 'secondary':
            return `
                color: ${props.theme.colours.textDefault};
                ${edge(props.theme.colours.secondary)};
                ${hover(props.theme.colours.secondary)};
                background-color: ${props.theme.colours.secondary};
            `;
        case 'warning':
            return `
                ${edge(props.theme.colours.warning)};
                ${hover(props.theme.colours.warning)};
                background-color: ${props.theme.colours.warning};
            `;

        case 'danger':
            return `
                ${edge(props.theme.colours.danger)};
                ${hover(props.theme.colours.danger)};
                background-color: ${props.theme.colours.danger};
            `;

        case 'success':
            return `
                ${edge(props.theme.colours.success)};
                ${hover(props.theme.colours.success)};
                background-color: ${props.theme.colours.success};
            `;

        case 'info':
            return `
                ${edge(props.theme.colours.info)};
                ${hover(props.theme.colours.info)};
                background-color: ${props.theme.colours.info};
            `;

        case 'link':
            return `
                border: 1px solid ${props.theme.colours.link};
                ${hover(props.theme.colours.link)};
                background-color: transparent;
                color: ${props.theme.colours.link};
            `;
        case 'addItem':
            return `
                border: none;
                color: ${props.theme.colours.textDark};
                position: relative;
                cursor: pointer;
                background-color: ${props.theme.colours.hoverGrey};
                transition: 0.2s ease-in;
                ${hover(props.theme.colours.midGrey)};
            `;
        default:
            return defaultHoverActive(props);
    }
};

export const StyledButtonShared = styled('button')<{
    fullWidth: boolean;
    level: ButtonType;
}>`
    ${props => edge(props.theme.colours.offBlack)};
    ${props => props.theme.no_select()};
    background-color: ${props => props.theme.colours.offBlack};
    box-sizing: border-box;
    font-size: 13px;
    cursor: pointer;
    border-radius: 3px;
    color: ${props => props.theme.colours.white};
    text-align: center;
    margin-right: 5px;
    text-transform: uppercase;
    line-height: 32px;
    padding: 0 12px;
    transition: 0.2s ease-in;
    flex-shrink: 0;
    ${(props: any) => stylesForLevel(props)}
    ${(props: any) => isDisabled(props)};
    ${props => props.fullWidth && `width: 100%;`};
`;

export const Button = ({ onClick, level, value, fullWidth, children, disabled = false }: ButtonProps) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (onClick && !disabled) onClick(event);
    };

    if (level === 'link') {
        return (
            <StyledButtonShared
                value={value}
                level={level}
                onClick={() => !disabled && onClick}
                disabled={disabled}
                fullWidth={fullWidth}
            >
                {children}
            </StyledButtonShared>
        );
    } else {
        return (
            <StyledButtonShared
                value={value}
                level={level}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleClick(e)}
                disabled={disabled}
                fullWidth={fullWidth}
            >
                {children}
            </StyledButtonShared>
        );
    }
};

export default Button;
