import { unknownErrorData } from '../../../helpers/errors';
import { v4 as uuidv4 } from 'uuid';

// Types
import { ResourceData, TableData } from '../../../types';

export type UseResourceTableArgs = {
    resourceData: ResourceData;
};

const useResourceTable = ({ resourceData }: UseResourceTableArgs): TableData => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        if (!resourceData?.request?.fields) {
            throw new Error('Expected fields in the request');
        }

        if (new Set(resourceData.request.fields).size !== resourceData.request.fields.length) {
            throw new Error('Duplicate table columns detected - please check the fields array in the request');
        }

        return {
            type: 'table',
            status: 'success',
            resource: resourceData.resource,
            request: resourceData.request,
            totalRows: resourceData.meta.total_count,
            columns: resourceData.request.fields.map(field => {
                return {
                    __id: uuidv4(),
                    ...field,
                };
            }),
            rows: resourceData.objects,
        };
    }

    return unknownErrorData();
};

export default useResourceTable;
