import React, { useContext } from 'react';
import styled from 'styled-components';

// Components
import DatePicker from '../../components/date-picker/date-picker';
import RadioSelector from '../../components/radio-selector';
import FilterSelect from '../../components/filter-select/filter-select';

// Context
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';
import { CustomerExplorerContext } from '../context/customer-explorer-context';

const StyledPrimaryFilterBar = styled.div`
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    padding: 6px 6px 0px;
    background-color: ${props => props.theme.colours.white};
    margin-bottom: 6px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
`;

const StyledCalendarContainer = styled.div`
    min-width: 290px;
    max-height: 40px;
    margin: 0px 8px 6px 0px;
`;

const CustomerExplorerFilters = ({ datePickerConfig }) => {
    const { dateSelectorTypes, showSaleFilters, handleSelectedDateTypeChange, selectedTabId, showChannelFilters } =
        useContext(CustomerExplorerContext);

    const {
        productsOptions,
        handleProductSelect,
        handleProductClearAll,
        handleProductSelectAll,
        handleSelectedProductRemove,
        reportFilterState: { selectedProductsFilterData, enableFilterApplyButton },
        handleFilterApplyOnClick,
        filterProductName,
        channelOptions,
        handleChannelSelect,
        handleChannelClearAll,
        handleChannelSelectAll,
        handleSelectedChannelRemove,
        reportFilterState: { selectedChannelFilterData, enableChannelFilterApplyButton },
        handleChannelFilterApplyOnClick,
        filterChannelName,
    } = useContext(FilterBarContext);

    return (
        <>
            <StyledPrimaryFilterBar>
                <StyledCalendarContainer>
                    <DatePicker isEnabled={true} config={datePickerConfig} />
                </StyledCalendarContainer>

                {(selectedTabId === 0 || selectedTabId === 2) && (
                    <RadioSelector
                        label={'Date Type'}
                        options={dateSelectorTypes}
                        onSelectedItemChange={handleSelectedDateTypeChange}
                    />
                )}
                {showSaleFilters && (
                    <FilterSelect
                        selectedFilterData={selectedProductsFilterData}
                        filterOptions={productsOptions}
                        handleFilterSelect={handleProductSelect}
                        handleFilterClearAll={handleProductClearAll}
                        handleFilterSelectAll={handleProductSelectAll}
                        handleSelectedFilterRemove={handleSelectedProductRemove}
                        handleFilterApplyOnClick={handleFilterApplyOnClick}
                        enableFilterApplyButton={enableFilterApplyButton}
                        hideCapsules={true}
                        filterName={filterProductName}
                    />
                )}

                {showChannelFilters && (
                    <FilterSelect
                        selectedFilterData={selectedChannelFilterData}
                        filterOptions={channelOptions}
                        handleFilterSelect={handleChannelSelect}
                        handleFilterClearAll={handleChannelClearAll}
                        handleFilterSelectAll={handleChannelSelectAll}
                        handleSelectedFilterRemove={handleSelectedChannelRemove}
                        handleFilterApplyOnClick={handleChannelFilterApplyOnClick}
                        enableFilterApplyButton={enableChannelFilterApplyButton}
                        hideCapsules={true}
                        isChannel={true}
                        filterName={filterChannelName}
                    />
                )}
            </StyledPrimaryFilterBar>
        </>
    );
};

export default CustomerExplorerFilters;
