import React from 'react';
import styled, { css } from 'styled-components';

// Component
import SimpleTableBodyCell from '../cells/simple-table-body-cell';

// Icons
import IconOrder from '../../../icons/order';

// Images
import DeletedImage from '../../../../assets/images/icons/deleted.png';
import EditedImage from '../../../../assets/images/icons/edited.png';
import AddedImage from '../../../../assets/images/icons/added.png';
import WarningImage from '../../../../assets/images/icons/error-icon.png';
import RevokeUserImage from '../../../../assets/images/icons/revoke-user.png';
import TimesCircleImage from '../../../../assets/images/icons/times-circle.svg';
import CheckCircleImage from '../../../../assets/images/icons/check-circle.svg';
import LockedImage from '../../../../assets/images/icons/locked.png';

// Types
import { SimpleTableRow, SimpleTableRowColumn } from '../../types';

// Styles
const StyledSimpleTableRow = css`
    border-bottom: solid 1px ${props => props.theme.colours.lightGrey};
    background-color: ${props => props.theme.colours.whiteBg};
    cursor: pointer;
    display: table;
    width: 100%;
    table-layout: fixed;

    :hover {
        transition: ${props => props.theme.transition};
        background-color: ${props => props.theme.colours.lightGrey};
    }
`;

const StyledBodyPill = css`
    &:nth-child(2) {
        width: 130px;
    }
    &:nth-child(3) {
        width: 130px;
    }
    &:nth-last-child(1) {
        width: 130px;
    }
`;

const StyledTableData = css`
    content: '';
    height: 24px;
    width: 24px;
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
`;

const StyledSimpleTableRowSelected = css`
    background-color: ${props => props.theme.colours.inputColour};
    :hover {
        background-color: ${props => props.theme.colours.inputColour};
    }
`;

const StyledSimpleTableRowDeleted = css`
    & td {
        position: relative;
        &:first-child {
            &::before {
                ${StyledTableData}
                background-image: url(${DeletedImage});
            }
        }
    }
`;

const StyledSimpleTableRowEdited = css`
    & td {
        position: relative;
        &:first-child {
            &::before {
                ${StyledTableData}
                background-image: url(${EditedImage});
            }
        }
    }
`;

const StyledSimpleTableRowAdded = css`
    & td {
        position: relative;
        &:first-child {
            &::before {
                ${StyledTableData}
                background-image: url(${AddedImage});
            }
        }
    }
`;

const StyledSimpleTableRowWarning = css`
    & td {
        position: relative;
        &:first-child {
            &::before {
                ${StyledTableData}
                background-image: url(${WarningImage});
            }
        }
    }
`;

const StyledSimpleTableRowRevokeUser = css`
    & td {
        position: relative;
        &:first-child {
            &::before {
                ${StyledTableData}
                background-image: url(${RevokeUserImage});
            }
        }
    }
`;

const StyledSimpleTableRowError = css`
    & td {
        position: relative;
        &:last-child {
            &::before {
                ${StyledTableData}
                background-image: url(${TimesCircleImage});
            }
        }
    }
`;

const StyledSimpleTableRowSuccess = css`
    & td {
        position: relative;
        &:last-child {
            &::before {
                ${StyledTableData}
                background-image: url(${CheckCircleImage});
            }
        }
    }
`;

const StyledSimpleTableRowLocked = css`
    & td {
        position: relative;
        &:last-child {
            &::before {
                ${StyledTableData}
                background-image: url(${LockedImage});
            }
        }
    }
`;

const StyledSimpleTableRowChild = css<{ hasIcons: boolean }>`
    td:first-child {
        padding-left: ${props => (props.hasIcons ? '60px' : '30px')};
    }
`;

const StyledSimpleTableRowDragOver = css`
    background-color: ${props => props.theme.colours.inputColour};
`;

const StyledSimpleTableRowDisabled = css<{ selected: boolean }>`
    cursor: not-allowed;
    :hover {
        background-color: ${props => (props.selected ? props.theme.colours.inputColour : props.theme.colours.whiteBg)};
    }
`;

const StyledSimpleTableRowNotSelectable = css`
    cursor: initial;
    :hover {
        background-color: ${props => props.theme.colours.whiteBg};
    }
`;

const StyledOrderIcon = css`
    width: 25px;
`;

const StyledOrderIconTableData = styled.td`
    ${StyledOrderIcon};
`;

const StyledOrderIconTableDataIconOrder = styled.div`
    padding: 3px 6px;
    & svg {
        height: 12px;
        fill: $text_default;
    }
`;

const StyledSimpleTableBodyRow = styled.tr<{
    hasIcons: boolean;
    selected: boolean;
    deleted: boolean;
    edited: boolean;
    added: boolean;
    disabled: boolean;
    disableChannelSelect: boolean;
    warning: boolean;
    revokeUser: boolean;
    error: boolean;
    success: boolean;
    locked: boolean;
    child: boolean;
    selectableRows: boolean;
    selectDisabled: boolean;
    dragOver: boolean;
    hasEndAction: boolean;
    hasPills: boolean;
}>`
    ${StyledSimpleTableRow};

    & td:first-child {
        padding-left: ${props => props.hasIcons && '32px'};
        word-wrap: ${props => props.hasIcons && 'break-word'};
    }
    & td:last-child {
        width: ${props => props.hasEndAction && '140px'};
    }
    &not:first-child {
        border-left: solid 1px ${props => props.theme.colours.lightGrey};
    }
    & td {
        ${props => props.hasPills && StyledBodyPill}
    }

    ${props => props.selected && StyledSimpleTableRowSelected};
    ${props => props.deleted && StyledSimpleTableRowDeleted};
    ${props => props.edited && StyledSimpleTableRowEdited};
    ${props => props.added && StyledSimpleTableRowAdded};
    ${props => props.disabled && StyledSimpleTableRowDisabled}
    ${props => props.warning && StyledSimpleTableRowWarning};
    ${props => props.revokeUser && StyledSimpleTableRowRevokeUser};
    ${props => props.error && StyledSimpleTableRowError};
    ${props => props.success && StyledSimpleTableRowSuccess};
    ${props => props.locked && StyledSimpleTableRowLocked};
    ${props => props.child && StyledSimpleTableRowChild}
    ${props =>
        props.selectableRows !== undefined && props.selectableRows === false && StyledSimpleTableRowNotSelectable};
    ${props => props.selectDisabled !== undefined && props.selectDisabled && StyledSimpleTableRowDisabled};
    ${props => props.dragOver && StyledSimpleTableRowDragOver};
`;

// Types
type SimpleTableBodyRowProps = {
    id?: string;
    row: SimpleTableRow;
    index: number;
    hasIcons?: boolean;
    selectableRows?: boolean;
    allowOrdering?: boolean;
    disableOrdering?: boolean;
    selectDisabled?: boolean;
    hasEndAction?: boolean;
    hasPills?: boolean;
    onRowClick?: (event: React.MouseEvent<HTMLTableRowElement>, row: SimpleTableRow) => void;
    reorderRows: (oldIndex: number, newIndex: number) => void;
};

const SimpleTableBodyRow = ({
    id,
    row,
    index,
    hasIcons,
    selectableRows,
    disableOrdering,
    selectDisabled,
    allowOrdering,
    hasEndAction,
    hasPills,
    onRowClick,
    reorderRows,
}: SimpleTableBodyRowProps) => {
    let isDisabledOrdering = disableOrdering !== undefined ? disableOrdering : !allowOrdering;

    const {
        selected,
        deleted,
        edited,
        added,
        disabled,
        disableChannelSelect,
        warning,
        revokeUser,
        error,
        success,
        locked,
        child,
        dragOver,
    } = row?.rowProperty ?? {};

    // if disabled do not allow the user to reorder
    if (selectDisabled !== undefined && selectDisabled) {
        isDisabledOrdering = true;
    }

    // Allowing for a generic onClick event to run as well as a row specific onClick event
    const handleOnRowClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
        // If the select has been disabled do not pass onclick functionality
        if (selectDisabled !== undefined && selectDisabled) {
            return;
        }

        if (onRowClick !== undefined) {
            onRowClick(event, row);
        }

        if (row.onClick !== undefined) {
            row.onClick(event, row);
        }
    };

    const renderColumns = (column: SimpleTableRowColumn, index: number, row: SimpleTableRow) => (
        <SimpleTableBodyCell key={index} column={column} index={index} row={row} />
    );

    // If the row is draggable pass all the event handlers to the row
    if (allowOrdering) {
        // Row onDragStart handler, will create object that will be stored in event.dataTransfer
        const onRowDragStart = (event: React.DragEvent<HTMLDivElement>) => {
            if (isDisabledOrdering) {
                return;
            }

            const data = {
                id,
                rowNumber: index,
            };

            event.dataTransfer.setData('text/plain', JSON.stringify(data));
        };

        // Row onDrop handler, will call the reorderRows with the old and new index for the item
        const onRowDrop = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();

            if (isDisabledOrdering) {
                return;
            }

            const data = JSON.parse(event.dataTransfer.getData('text/plain'));

            // Clear event DataTransfer
            event.dataTransfer.clearData();

            if (data.id !== id) {
                return;
            }

            reorderRows(data.rowNumber, index);
        };

        // When a row is dragged over another add the class dragOver so the user knows where the value will be dropped
        const onRowDragEnter = (event: React.DragEvent<HTMLTableRowElement>) => {
            (event.target as HTMLTableRowElement).classList.add('dragOver');
        };

        // remove the class dragOver when the dragged item is no longer over the row.
        const onRowDragLeave = (event: React.DragEvent<HTMLTableRowElement>) => {
            (event.target as HTMLTableRowElement).classList.remove('dragOver');
        };

        return (
            <StyledSimpleTableBodyRow
                key={row.key}
                data-value={row.dataValue}
                onDrop={onRowDrop}
                onDragStart={onRowDragStart}
                draggable={!isDisabledOrdering}
                onDragEnter={onRowDragEnter}
                onDragLeave={onRowDragLeave}
                onClick={handleOnRowClick}
                hasIcons={hasIcons!}
                selected={selected! || row.selected!}
                deleted={deleted!}
                edited={edited!}
                added={added!}
                disabled={disabled!}
                disableChannelSelect={disableChannelSelect!}
                warning={warning!}
                revokeUser={revokeUser!}
                error={error!}
                success={success!}
                locked={locked!}
                child={child!}
                dragOver={dragOver!}
                selectableRows={selectableRows!}
                selectDisabled={selectDisabled!}
                hasEndAction={hasEndAction!}
                hasPills={hasPills!}
                data-testid="simple-table-table-row"
            >
                <StyledOrderIconTableData>
                    <StyledOrderIconTableDataIconOrder>
                        <IconOrder />
                    </StyledOrderIconTableDataIconOrder>
                </StyledOrderIconTableData>
                {row.columns === undefined || row.columns.length === 0
                    ? null
                    : row.columns.map((column, index) => {
                          return renderColumns(column, index, row);
                      })}
            </StyledSimpleTableBodyRow>
        );
    }

    return (
        <StyledSimpleTableBodyRow
            key={row.key}
            data-value={row.dataValue}
            onClick={handleOnRowClick}
            hasIcons={hasIcons!}
            selected={selected! || row.selected!}
            deleted={deleted!}
            edited={edited!}
            added={added!}
            disabled={disabled!}
            disableChannelSelect={disableChannelSelect!}
            warning={warning!}
            revokeUser={revokeUser!}
            error={error!}
            success={success!}
            locked={locked!}
            child={child!}
            dragOver={dragOver!}
            selectableRows={selectableRows!}
            selectDisabled={selectDisabled!}
            hasEndAction={hasEndAction!}
            hasPills={hasPills!}
            data-testid="simple-table-table-row"
        >
            {row.columns === undefined || row.columns.length === 0
                ? null
                : row.columns.map((column, index) => {
                      return renderColumns(column, index, row);
                  })}
        </StyledSimpleTableBodyRow>
    );
};

export default SimpleTableBodyRow;
