import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generatePath, generateUrl } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import { setPopup, removePopup } from '../../redux/actions/popup';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isAlphaNumericString, isNumber, isDomain } from '../../helpers/validator';
import { AxiosRequestConfig } from 'axios';

// response types from parent component:
import { PathFilterParam, PathLoadOptions, PathResponse } from './automated-kwr-config';

type KWRTopicConfigResponse = {
    created: string;
    id: number;
    name: string;
    resource_uri: string;
    status: number;
    topic: boolean;
    updated: string;
};

type MarketList = {
    label: string;
    value: number;
    name: string;
};

type LanguageList = {
    label: string;
    value: number;
    name: string;
};

type MarketResponse = {
    alpha2: string;
    alpha3: string;
    country: string;
    created: string;
    id: number;
    priority: number;
    resource_uri: string;
    top_level_domain: string;
};

type LanguageResponse = {
    active: Boolean;
    created: string;
    id: number;
    language: string;
    resource_uri: string;
    updated: string;
};

const LayoutAddAutomatedKWRConfig = () => {
    const [marketList, setMarketList] = useState<MarketList[]>([]);
    const [languageList, setLanguageList] = useState<LanguageList[]>([]);
    const [selectedMarket, setSelectedMarket] = useState<MarketList[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageList[]>([]);
    const [searchVolumeThreshold, setSearchVolumeThreshold] = useState('');
    const [impressionThreshold, setImpressionThreshold] = useState('');
    const [topicText, setTopicText] = useState('');
    const [brandName, setBrandName] = useState('');
    const [domainName, setDomainName] = useState('');
    const [closeButtonState, setCloseButtonState] = useState('close');
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState(true);

    const [topicSpecificChecked, setTopicSpecificChecked] = useState(false);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);

    const [pathForReactSelect, setPathForReactSelect] = useState<PathLoadOptions[]>([]);

    const [errorMessageObject, setErrorMessageObject] = useState({
        topicTextErrorMessage: '',
        brandNameErrorMessage: '',
        domainNameErrorMessage: '',
        searchVolumeThresholdErrorMessage: '',
        impressionThresholdErrorMessage: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isPageError, setIsPageError] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        fetchMarketList();
        fetchLanguageList();
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        setSaveChangesButtonDisabled(true);
        if (topicText || brandName || domainName || searchVolumeThreshold || impressionThreshold) {
            setSaveChangesButtonDisabled(false);
            setCloseButtonState('cancel');
        }
    }, [topicText, brandName, domainName, searchVolumeThreshold, impressionThreshold]);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            topicTextErrorMessage: '',
            brandNameErrorMessage: '',
            domainNameErrorMessage: '',
            searchVolumeThresholdErrorMessage: '',
            impressionThresholdErrorMessage: '',
        };

        if (topicSpecificChecked) {
            if (!isAlphaNumericString(topicText) || topicText.length === 0) {
                hasFormError = true;
                errorMessageObj.topicTextErrorMessage = 'Please enter a valid Topic Name.';
            }
        }

        if (!isAlphaNumericString(brandName) || brandName.length === 0) {
            hasFormError = true;
            errorMessageObj.brandNameErrorMessage = 'Please enter a valid Brand Name.';
        }

        if (!isDomain(domainName) || domainName.length === 0) {
            hasFormError = true;
            errorMessageObj.domainNameErrorMessage = 'Please enter a valid Domain Name.';
        }

        if (!isNumber(searchVolumeThreshold) || Number(searchVolumeThreshold) === 0) {
            hasFormError = true;
            errorMessageObj.searchVolumeThresholdErrorMessage = 'Please enter a valid Search Volume Threshold Value.';
        }

        if (!isNumber(impressionThreshold) || Number(impressionThreshold) === 0) {
            hasFormError = true;
            errorMessageObj.impressionThresholdErrorMessage = 'Please enter a valid Impression Threshold Value.';
        }

        if (hasFormError) {
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const fetchMarketList = () => {
        axios({
            method: 'GET',
            url: generateUrl('config', 'seogd-market', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
                { key: 'order_by', value: 'country' },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const allMarkets = response.data.objects.map((data: MarketResponse) => {
                    return {
                        label: data.alpha2,
                        value: data.id,
                        name: data.country,
                        ...data,
                    };
                });
                setMarketList(allMarkets);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setIsPageError(true);
                errorHandling(error);
            });
    };

    const fetchLanguageList = () => {
        axios({
            method: 'GET',
            url: generateUrl('config', 'attrib-language', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const languages = response.data.objects.map((data: LanguageResponse) => {
                    return {
                        label: data.language,
                        value: data.id,
                        name: data.language,
                        ...data,
                    };
                });
                setIsLoading(false);
                setLanguageList(languages);
            })
            .catch(error => {
                setIsLoading(false);
                setIsPageError(true);
                errorHandling(error);
            });
    };

    const handleTopic = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTopicText(event?.target?.value);
    };

    const handleSelectMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
        let marketId = parseInt(event?.target?.value);
        let selectMarket = marketList.filter(market => market?.value === marketId);
        setSelectedMarket(selectMarket);
    };

    const handleSelectLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
        let languageId = parseInt(event.target.value);
        let selectLanguage = languageList.filter(lng => lng.value === languageId);
        setSelectedLanguage(selectLanguage);
    };

    const handleBrandName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBrandName(event?.target?.value);
    };

    const handleDomainName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDomainName(event?.target?.value);
    };

    const handleSearchVolume = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchVolumeThreshold(event?.target?.value);
    };

    const handleImpressions = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImpressionThreshold(event?.target?.value);
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onCheckBoxClick = (checkBoxName: React.ChangeEvent<HTMLInputElement>) => {
        let checked = String(checkBoxName) === 'addTopicSpecificValue' ? !topicSpecificChecked : topicSpecificChecked;
        setTopicSpecificChecked(checked);
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(setModal('AutomatedKWRConfig', {}));
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const pathLoadOptions = async (search: string, prevOptions: PathLoadOptions[]) => {
        const filterParams: PathFilterParam[] = [
            { key: 'active', value: 1 },
            { key: 'deleted', value: 0 },
            { key: 'limit', value: 25 },
            { key: 'offset', value: prevOptions.length },
        ];

        if (search) {
            filterParams.push({ key: 'path__icontains', value: search.toLowerCase() });
        }
        const filterUrl = generateUrl('config', 'dash-path', filterParams);

        const filteredOptions = await axios.get(filterUrl, { withCredentials: true });
        const options = filteredOptions.data.objects.map((item: PathResponse) => {
            return {
                value: item.id,
                label: item.path,
            };
        });

        const hasMore = filteredOptions.data.meta.next ? true : false;

        return {
            options: options,
            hasMore,
        };
    };

    const saveKWRConfig = () => {
        return new Promise(async (resolve, reject) => {
            const kwrTopicPayload = {
                topic: topicSpecificChecked,
                name: topicSpecificChecked ? topicText : null,
            };

            let kwrTopicResponse!: KWRTopicConfigResponse;
            let kwrConfigRequest: AxiosResponse;
            let onKWRConfigSuccess = 0;

            try {
                const kwrTopicConfig: AxiosRequestConfig = {
                    method: 'POST',
                    url: generateUrl('config', 'kwr-topic-config'),
                    data: kwrTopicPayload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const { data, status } = await axios(kwrTopicConfig);

                kwrTopicResponse = data;
                onKWRConfigSuccess = status;

                if (![201, 200].includes(status)) {
                    throw new Error('Status not 201');
                }
            } catch (error) {
                console.log(error);
                reject('Error making a connection to API.');
            }

            if ([201, 200].includes(onKWRConfigSuccess)) {
                const kwrConfigPayload = {
                    kwr_topic_config: generatePath('config', 'kwr-topic-config', String(kwrTopicResponse.id)),
                    market: generatePath('config', 'seogd-market', String(selectedMarket[0].value)),
                    language: generatePath('config', 'attrib-language', String(selectedLanguage[0].value)),
                    brand_name: brandName,
                    domain: domainName,
                    reduction_volume: searchVolumeThreshold,
                    reduction_impressions: impressionThreshold,
                    reduction_trend: 0,
                    reduction_clicks: 0,
                    relevance_score: 0,
                };

                try {
                    const kwrConfig: AxiosRequestConfig = {
                        method: 'POST',
                        url: generateUrl('config', 'kwr-config'),
                        data: kwrConfigPayload,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    };

                    kwrConfigRequest = await axios(kwrConfig);

                    if (![201, 200].includes(kwrConfigRequest.status)) {
                        throw new Error('Status not 201');
                    }
                } catch (error) {
                    console.log(error);
                    reject('Error creating KWR config.');
                }
            } else {
                reject('Error creating KWR Topic config.');
                throw new Error('Status not 201');
            }

            if (topicSpecificChecked) {
                const mappedPathPayload = pathForReactSelect.map(item => {
                    return {
                        kwr_topic_config: `/api/config/kwr-topic-config/${kwrConfigRequest.data.kwr_topic_config.id}/`,
                        path: `/api/config/path/${item.value}/`,
                    };
                });

                try {
                    const kwrTopiMappedPathConfig: AxiosRequestConfig = {
                        method: 'PATCH',
                        url: generateUrl('config', 'kwr-topic-mapped-path'),
                        data: { objects: mappedPathPayload },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    };

                    const kwrTopiMappedPathResponse = await axios(kwrTopiMappedPathConfig);
                    if (![201, 200, 202].includes(kwrTopiMappedPathResponse.status)) {
                        throw new Error('Status not 200s');
                    }
                } catch (error) {
                    console.log(error);
                    reject('Error creating KWR config.');
                }
            }

            resolve('KWR Config Created');
        });
    };

    const handleNavigateMangeKWRConfigModal = () => {
        dispatch(setModal('AutomatedKWRConfig', {}));
    };

    const handlePaths = (path: PathLoadOptions[]) => {
        setPathForReactSelect(path);
    };

    const onSaveChangesClick = () => {
        if (!formValidator()) {
            return;
        }

        setSaveChangesButtonLoading(true);
        setSaveChangesButtonDisabled(true);
        setCloseButtonDisabled(true);

        saveKWRConfig()
            .then(() => {
                dispatch(
                    addNotification({
                        copy: 'A KWR config was successfully created.',
                        type: NotificationMessageType.Success,
                    })
                );
                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
                handleNavigateMangeKWRConfigModal();
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue adding a KWR config.',
                        type: NotificationMessageType.Error,
                    })
                );

                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
            });
    };

    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const renderAccordion = () => {
        const accordions = [
            {
                header: 'KWR Config',
                required: false,
                open: true,
                type: 'form',
                intro: '',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Topic Specific:',
                                type: 'checkbox',
                                toolTipCopy: `Topic specific keyword research allows users to run automated keyword research on a specific topic. If left unchecked, we'll run automated keyword research for the whole website.`,
                                inputKeyValue: 'addTopicSpecificValue',
                                checked: topicSpecificChecked,
                                inputOnChange: onCheckBoxClick,
                            },
                            topicSpecificChecked
                                ? {
                                      label: 'Topic:',
                                      type: 'text',
                                      requiredField: false,
                                      toolTipCopy: 'Enter a Topic.',
                                      inputKeyValue: 'addTopicSpecificText',
                                      inputValue: topicText,
                                      inputOnChange: handleTopic,
                                      errorMessage: errorMessageObject.topicTextErrorMessage,
                                  }
                                : {},
                            topicSpecificChecked
                                ? {
                                      label: 'Path:',
                                      type: 'reactSelect',
                                      requiredField: false,
                                      inputOptions: pathLoadOptions,
                                      toolTipCopy: 'Select paths with similar content.',
                                      isMulti: true,
                                      inputKeyValue: 'add-topic-specific-path',
                                      inputValue: pathForReactSelect,
                                      inputOnChange: handlePaths,
                                      errorMessage: '',
                                      isClearable: true,
                                  }
                                : {},
                            {
                                label: 'Market:',
                                type: 'select',
                                requiredField: false,
                                toolTipCopy: 'Select a Market.',
                                inputKeyValue: 'add-seogd-market',
                                inputOptions: marketList,
                                inputOnChange: handleSelectMarket,
                                errorMessage: '',
                            },
                            {
                                label: 'Language:',
                                type: 'select',
                                requiredField: false,
                                toolTipCopy: 'Select a Language.',
                                inputKeyValue: 'add-seogd-language',
                                inputOptions: languageList,
                                inputOnChange: handleSelectLanguage,
                                errorMessage: '',
                            },
                            {
                                label: 'Brand Name:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: `We'll use brand name to filter out branded terms from the final keyword set.`,
                                inputKeyValue: 'kwrBrandName',
                                inputValue: brandName,
                                inputOnChange: handleBrandName,
                                errorMessage: errorMessageObject.brandNameErrorMessage,
                            },
                            {
                                label: 'Domain Name:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: 'Enter domain name.',
                                inputKeyValue: 'kwrDomainName',
                                inputValue: domainName,
                                inputOnChange: handleDomainName,
                                errorMessage: errorMessageObject.domainNameErrorMessage,
                            },
                            {
                                label: 'Search Volume Threshold:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: `Set the search volume threshold which we'll use to filter out keywords which have a search volume less than this limit. Search volume is pulled from SEMRUSH and the value is monthly.`,
                                inputKeyValue: 'searchVolumeThreshold',
                                inputValue: searchVolumeThreshold,
                                inputOnChange: handleSearchVolume,
                                errorMessage: errorMessageObject.searchVolumeThresholdErrorMessage,
                            },
                            {
                                label: 'Impressions Threshold:',
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: `Set the impression limit which we'll use to filter out keywords which have a search volume less than this limit. Impression are pulled form Google Search Console and the value is monthly.`,
                                inputKeyValue: 'impressionThreshold',
                                inputValue: impressionThreshold,
                                inputOnChange: handleImpressions,
                                errorMessage: errorMessageObject.impressionThresholdErrorMessage,
                            },
                        ],
                        columns: 2,
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Add Automated Keyword Configuration</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (isPageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Add Automated Keyword Configuration</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Add Automated Keyword Configuration</h2>
            <p>Add new automated KWR configurations.</p>
            {renderAccordion()}
        </div>
    );
};

export default LayoutAddAutomatedKWRConfig;
