import numeral from 'numeral';

// Type
import { BigNumberDecimalRounding, BigNumberFormatting } from '../types';
import store from '../../redux/store';

export const formatNumber = (number: number, width: number, rounding?: BigNumberDecimalRounding): string => {
    if (typeof number !== 'number') throw new Error('Please provide a valid number');
    if (!width) throw new Error('Please provide a width for calculating the number format');

    if (rounding && !rounding.applyRounding) {
        return String(number);
    }

    if (rounding?.decimalPoints === 0) {
        return numeral(number).format('0,0');
    }

    if (rounding && rounding.applyRounding && rounding.decimalPoints) {
        return numeral(number).format(`0,0[.]${'0'.repeat(rounding.decimalPoints)}a`);
    }

    if (width < 5) {
        return numeral(number).format('0,0[.]0a');
    } else if (width === 5) {
        return numeral(number).format('0,0[.]00a');
    } else if (width > 5 && width < 8) {
        return numeral(number).format('0,0[.]0000a');
    } else {
        return numeral(number).format('0,0[.]0000000');
    }
};

export const formatValue = (number: string, format: BigNumberFormatting) => {
    const currencySymbol = store.getState().currency.symbol;
    if (format === 'percentage') {
        return `${number}%`;
    } else if (format === 'currency') {
        return currencySymbol + number;
    }
    return number;
};
