import React from 'react';
import styled from 'styled-components';

import TableContributionSkeleton from './tables/components/skeletons/table-contribution-skeleton';

// Styles
const StyledContributionAnalysisWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
`;

const StyledContributionAnalysisChart = styled.div`
    position: relative;
    margin: 1.25%;
    min-height: 560px;
    width: 100%;
`;

const StyledContributionAnalysisChartSkeleton = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
`;

const StyledContributionAnalysisChartSkeletonCircle = styled.div`
    position: absolute;

    left: 50%;
    top: 25%;
    transform: translate(-50%, 0);
    border-radius: 50%;
    width: 350px;
    height: 350px;
    ${props => props.theme.skeletonLoaderGradient};

    &:nth-child(1) {
        left: 43%;
    }
    &:nth-child(2) {
        left: 57%;
    }
    &:nth-child(3) {
        top: -20%;
        background: ${props => props.theme.colours.offLight};
    }
    &:nth-child(4) {
        top: 70%;
        background: ${props => props.theme.colours.offLight};
    }
`;

// Types
export type ContributionSkeletonProps = {
    chartOnly: boolean;
};

const ContributionSkeleton = ({ chartOnly }: ContributionSkeletonProps) => {
    const chartSkeleton = (
        <StyledContributionAnalysisChart>
            <StyledContributionAnalysisChartSkeleton>
                <StyledContributionAnalysisChartSkeletonCircle />
                <StyledContributionAnalysisChartSkeletonCircle />
                <StyledContributionAnalysisChartSkeletonCircle />
                <StyledContributionAnalysisChartSkeletonCircle />
            </StyledContributionAnalysisChartSkeleton>
        </StyledContributionAnalysisChart>
    );

    if (chartOnly) {
        return <>{chartSkeleton}</>;
    }

    return (
        <StyledContributionAnalysisWrapper>
            <TableContributionSkeleton />
            {chartSkeleton}
        </StyledContributionAnalysisWrapper>
    );
};

export default ContributionSkeleton;
