import React from 'react';
import styled from 'styled-components';

// Components
import TablePaginationDescription from './table-pagination-description';
import TablePaginationPageList from './table-pagination-page-list';

// Type
import { DataTablePaginationConfig } from '../../types';

// Styles
const StyledPagination = styled.div`
    user-select: none;
    text-align: right;
`;

// Types
export type TablePaginationProps = {
    config: DataTablePaginationConfig;
    currentPage: number;
    currentRowCount: number;
    noData: boolean;
    selectedRowCount: number;
    setOwnerState: (key: string, value: number) => void;
    totalResults: number;
};

const TablePagination = ({
    config,
    currentPage,
    currentRowCount,
    noData,
    selectedRowCount,
    setOwnerState,
    totalResults,
}: TablePaginationProps) => {
    const minPage = 1;

    if (noData) return null;

    const maxPage = Math.ceil(totalResults / currentRowCount);

    return (
        <StyledPagination>
            <TablePaginationDescription
                ownerCallback={setOwnerState}
                currentPage={currentPage}
                currentRowCount={currentRowCount}
                totalResults={totalResults}
                minPage={minPage}
                maxPage={maxPage}
                rowOptions={config.rowOptions}
                rowDefault={config.rowDefault}
                selectedRowCount={selectedRowCount}
            />
            <TablePaginationPageList
                currentPage={currentPage}
                currentRowCount={currentRowCount}
                maxButtons={config.maxButtons}
                maxPage={maxPage}
                minPage={minPage}
                ownerCallback={setOwnerState}
                totalResults={totalResults}
            />
        </StyledPagination>
    );
};

export default TablePagination;
