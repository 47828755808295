import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

// Components
import TabList from '../../common/tab-items/tab-list';
import DataTable from '../components/data-table';
import TablePagination from '../components/pagination/table-pagination';
import TableSearchOptions from '../components/filters/table-search-options';
import TableSearch from '../../inputs/table-search';
import CSVDownload from '../../csv-download';

// Helper
import { formatSalesTitle } from '../../../helpers/formatGlobalSaleTitle';

// Styles
const StyledTableSearchWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    gap: 15px 0;
`;

const StyledTableActionWrapper = styled.div`
    margin-left: auto;
    display: flex;
`;

const StyledTableSearchWrapperSearch = styled.div`
    width: 200px;
`;

class WidgetDataTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabConfig: props.tabConfig.map((config, index) => {
                return {
                    key: index,
                    id: index,
                    linkedResourceKey: index,
                    tabName: config.tabName,
                    displayName: formatSalesTitle(config.displayName, this.props.globalSaleTitle),
                    isSelected: index === this.props.tabKey,
                };
            }),
        };
        this.setOwnerState = this.props.setOwnerState || function () {};
    }

    static defaultProps = {
        searchCategory: '',
        searchTerm: '',
    };

    handleTabElementClick = tabKey => {
        const tabConfig = this.state.tabConfig.map(config => ({
            ...config,
            isSelected: config.id === tabKey,
        }));

        this.setOwnerState('tabKey', tabKey);
        this.setState({
            tabConfig: tabConfig,
        });
    };

    render() {
        return (
            <>
                <TableSearchOptions
                    onSearchHandler={this.props.onSearchHandler}
                    tabKey={this.props.tabKey}
                    tabListItems={this.props.tabConfig}
                    enableSearch={this.props.enableSearch}
                    searchCategory={this.props.searchCategory}
                    searchTerm={this.props.searchTerm}
                />
                <StyledTableSearchWrapper>
                    <TabList
                        noData={this.props.noData}
                        tabLists={this.state.tabConfig}
                        loading={this.props.loading}
                        handleTabElementClick={this.handleTabElementClick}
                    />

                    <StyledTableActionWrapper>
                        {this.props.downloadParams && this.props.downloadParams.params && !this.props.loading && (
                            <CSVDownload
                                noData={this.props.noData}
                                params={this.props.downloadParams}
                                filename={
                                    this.props.downloadParams.params
                                        .filter(item => item['key'] === 'filename')
                                        .map(item => item.value)[0]
                                }
                                totalResults={this.props.totalResults}
                                allDataExportConfig={this.props.config.allDataExport}
                            />
                        )}
                        {!this.props.tableSearchHidden && (
                            <StyledTableSearchWrapperSearch>
                                <TableSearch
                                    hidden={this.props.tableSearchHidden}
                                    placeholder="Search..."
                                    searchValue={this.props.searchValue}
                                    onChange={this.props.handleSearchChange}
                                    onClear={this.props.handleClearSearch}
                                />
                            </StyledTableSearchWrapperSearch>
                        )}
                    </StyledTableActionWrapper>
                </StyledTableSearchWrapper>

                <DataTable
                    noData={this.props.noData}
                    error={this.props.error}
                    handleDimensionClick={this.props.handleDimensionClick}
                    loading={this.props.loading}
                    dimensions={this.props.dimensions}
                    data={this.props.data}
                    selectedRows={this.props.selectedRows}
                    metrics={this.props.tabConfig[this.props.tabKey].metrics}
                    orderBy={this.props.orderBy}
                    orderByDir={this.props.orderByDir}
                    setOwnerState={this.setOwnerState}
                    hideTotals={this.props.hideTotals}
                    disableRowClick={this.props.disableRowClick}
                    calculateHeatmap={this.props.calculateHeatmap}
                    config={this.props.config}
                    selectedKey={this.props.tabKey}
                    totals={this.props.totals}
                />

                {this.props.loading === false && !this.props.noData && (
                    <TablePagination
                        noData={this.props.noData}
                        currentPage={this.props.page}
                        currentRowCount={this.props.rowCount}
                        totalResults={this.props.totalResults}
                        setOwnerState={this.setOwnerState}
                        config={this.props.paginationConfig}
                        selectedRowCount={this.props.selectedRowCount}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        globalSaleTitle: state.saleTitle.globalSalesTitle,
    };
};

export default connect(mapStateToProps)(WidgetDataTable);
