import React from 'react';
import styled from 'styled-components';

const StyledBlockSkeleton = styled.div`
    ${props => props.theme.skeletonLoader};
    background-color: ${props => props.theme.colours.borderGrey};
    display: block;
    position: relative;
    width: 100%;
    height: 45px;
`;

const BlockSkeleton = () => {
    return <StyledBlockSkeleton />;
};

export default BlockSkeleton;
