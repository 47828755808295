import { getChannelSubsectionConfig } from '../../filter-bar/configurations/channel-subsection-config';
import { CubedField, DropdownOption } from '../../types';

type ChannelTierData = {
    tier2: CubedField;
    tier3: CubedField;
    tier4: CubedField;
};

enum ChannelTierDisplayNames {
    'tier2' = '2nd Tier',
    'tier3' = '3rd Tier',
    'tier4' = '4th Tier',
}

const getCurrentTier = (tier: string, selectedChannel: DropdownOption | undefined) => {
    const currentChannel = selectedChannel || { label: 'Unknown', value: 'Unknown' };
    const currentSubsection = getChannelSubsectionConfig(currentChannel.label);

    if (currentSubsection) {
        const currentSection = currentSubsection[tier as keyof ChannelTierData];
        if ('displayName' in currentSection) {
            return currentSection;
        }
    }

    return ChannelTierDisplayNames[tier as keyof typeof ChannelTierDisplayNames];
};

export default getCurrentTier;
