import React from 'react';
import styled from 'styled-components';

const StyledSkeletonTableWrapper = styled.div`
    width: 100%;
    margin: 0;
    height: 240px;
`;

const StyledSkeletonTabs = styled.div`
    height: 42px;
    width: 50%;
    position: relative;
`;

const StyledSkeletonTabItems = styled.div`
    display: inline-block;
    position: relative;
    height: 100%;
    width: calc(25% - 12px);
    margin: 0 6px;
    border-radius: 12px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const StyledSkeletonTable = styled.div`
    width: 100%;
    margin: 6px 0;
    height: 198px;
    border-radius: 12px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const TableSkeleton = () => {
    return (
        <StyledSkeletonTableWrapper>
            <StyledSkeletonTabs>
                <StyledSkeletonTabItems />
                <StyledSkeletonTabItems />
                <StyledSkeletonTabItems />
                <StyledSkeletonTabItems />
            </StyledSkeletonTabs>

            <StyledSkeletonTable />
        </StyledSkeletonTableWrapper>
    );
};

export default TableSkeleton;
