import dataTypes from '../../../../filter-bar/enums/data-types';
import { CubedField } from '../../../../types';
import { unknownErrorData } from '../../../helpers/errors';
import { ResourceData, TableData } from '../../../types';
import { v4 as uuidv4 } from 'uuid';

type CustomTableRow = {
    description: string;
    resourceData: ResourceData;
};

type UseResourceTableCustomGroupsArgs = {
    fields: CubedField[];
    descriptionTitle: string;
    dimensionTitle: string;
    groups: {
        title: string;
        dimensionField: CubedField;
        rows: CustomTableRow[];
    }[];
};

const useResourceTableCustomGroups = ({
    fields,
    descriptionTitle,
    dimensionTitle,
    groups,
}: UseResourceTableCustomGroupsArgs): TableData => {
    if (!fields) {
        throw new Error('Expected fields');
    }

    if (!groups) {
        throw new Error('Expected groups');
    }

    // Get the status of each row request
    const statuses = groups.map(group => group.rows.map(row => row.resourceData.status)).flat();

    // Get the overall status
    const loading = statuses.some(status => status === 'loading');
    const empty = statuses.every(status => status === 'empty');
    const success = statuses.every(status => status === 'success');

    if (loading) {
        return {
            type: 'customTableGroups',
            status: 'loading',
        };
    }

    if (empty) {
        return {
            type: 'customTableGroups',
            status: 'empty',
        };
    }

    if (success) {
        // Build the table rows
        const customRows = groups
            .map(group => {
                const dimensionRawName = group.dimensionField.rawName;
                const rows = group.rows
                    .map(row => {
                        if (row.resourceData.status === 'success') {
                            const customObjects = row.resourceData.objects.map(object => {
                                Object.keys(object.values).forEach(key => {
                                    if (dimensionRawName === key) {
                                        object.values['custom_dimension'] = {
                                            value: object.values[key].value,
                                            rawValue: object.values[key].rawValue,
                                        };
                                        object.values['description'] = {
                                            value: row.description,
                                            rawValue: row.description,
                                        };
                                    }
                                });
                                return object;
                            });
                            return [...customObjects];
                        }
                        return [];
                    })
                    .flat();

                return [{ __id: uuidv4(), values: {}, title: group.title }, ...rows];
            })
            .flat();

        // Build the table columns
        const columns = fields.map(field => {
            return { __id: uuidv4(), rawName: field.rawName, displayName: field.displayName, dataType: field.dataType };
        });

        columns.unshift(
            { __id: uuidv4(), rawName: 'description', displayName: descriptionTitle, dataType: dataTypes.STRING },
            { __id: uuidv4(), rawName: 'custom_dimension', displayName: dimensionTitle, dataType: dataTypes.STRING }
        );

        return {
            type: 'customTableGroups',
            status: 'success',
            columns: columns,
            rows: customRows,
            totalRows: 2,
        };
    }

    return unknownErrorData();
};

export default useResourceTableCustomGroups;
