import React from 'react';

// Component
import SimpleTableTotalsCell from '../cells/simple-table-totals-cell';

// Types
type SimpleTableTotalsRowProps = {
    hasPills?: boolean;
    totalSales: number | string;
    totalRevenue: number | string;
};

const SimpleTableTotalsRow = ({ hasPills, totalSales, totalRevenue }: SimpleTableTotalsRowProps) => {
    return <SimpleTableTotalsCell hasPills={hasPills} totalSales={totalSales} totalRevenue={totalRevenue} />;
};

export default SimpleTableTotalsRow;
