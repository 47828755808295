import React, { useContext } from 'react';
import styled from 'styled-components';

// Context
import { ContentInsightsDashboardMenuContext } from '../../../content-insights-context/content-insights-dashboard-menu-context';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.sectionDashboard.widget.base.backgroundColor};
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};
    height: 45px;
    margin: 16px 0;
    padding: 0px 20px;
`;

const BeforeGridGoalMenu = () => {
    const { homepageRelianceGoalsMenu } = useContext(ContentInsightsDashboardMenuContext);

    return <StyledContainer>{homepageRelianceGoalsMenu.menu}</StyledContainer>;
};

export default BeforeGridGoalMenu;
