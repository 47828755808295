import { Layout } from 'react-grid-layout';

// Get the y location of the widget to be added based on existing widgets in the grid
const getWidgetYLocation = (layouts: Layout[]) => {
    if (layouts.length > 0) {
        return (
            layouts.reduce((max, layout) => (max.y > layout.y ? max : layout)).y +
            layouts.reduce((max, layout) => (max.y > layout.y ? max : layout)).h
        );
    }
    return 0;
};

export default getWidgetYLocation;
