import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

// Helpers & Reference Items
import { generatePath, generateUrl } from '../../helpers/request-builder';
import { convertToSeconds } from '../../helpers/segmenter/convert-to-seconds';
import { isInteger, isFloat, isString } from '../../helpers/validator';
import { CONDITION_CONFIG } from '../../configurations/account-configuration/segmenter';

// Redux
import { connect } from 'react-redux';
import { setModal, removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

// Components
import DropdownWithSearch from '../../components/common/dropdown-with-search';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import RenderDataTypeField from './render-datatype-field';
import LayoutModalNavigation from '../../components/navigation/layout-modal-navigation';
import ToggleWithLabels from '../../components/inputs/toggle-with-labels';
import CommonFormInput from '../../components/common/common-form-input';
import FunctionReference from './function-reference';

// Styled Components
export const StyledH1 = styled.h1`
    color: #0a0a0a;
`;

export const StyledP = styled.p`
    margin-bottom: 30px;
`;

export const StyledForm = styled.form`
    background-color: #ffffff;
    padding: 30px 30px 15px 30px;
`;

export const StyledFormSection = styled.div`
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 15px;
`;

export const StyledToggleContainer = styled.div`
    padding-top: 17%;
`;

export const StyledErrorMessageP = styled.p`
    text-align: center;
    color: red;
    font-size: 0.9rem;
`;

const SegmenterCreateConditions = props => {
    const { ADD_FORM_MODAL_TITLE, ADD_FORM_MODAL_DESCRIPTION, TABLE_REF } = CONDITION_CONFIG;

    // loading and page states
    const [dataLoading, setDataLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [relatedTableDataLoading, setRelatedTableDataLoading] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const [saveButtonLoading, setSaveButtonLoading] = useState(false);
    const [hideOperatorDropdown, setHideOperatorDropdown] = useState(false);
    const [showToggle, setShowToggle] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // data returned from api
    const [segmenterFunctions, setSegmenterFunctions] = useState();
    const [dataTypeOperators, setDataTypeOperators] = useState();
    const [dataTypes, setDataTypes] = useState();
    const [relatedTableData, setRelatedTableData] = useState();
    const [durationItems, setDurationItems] = useState();

    // selected & input data
    const [selectedFunction, setSelectedFunction] = useState();
    const [selectedFunctionOperators, setSelectedFunctionOperators] = useState();
    const [selectedRelatedItem, setSelectedRelatedItem] = useState();
    const [selectedOperator, setSelectedOperator] = useState();
    const [selectedDataType, setSelectedDataType] = useState();
    const [selectedDurationUnit, setSelectedDurationUnit] = useState(null);
    const [toggleChecked, setToggleChecked] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [conditionName, setConditionName] = useState('');

    // get functions, operators and data types from db on component load
    useEffect(() => {
        fetchFunctionsData();
    }, []);

    // enable save button when all data present
    useEffect(() => {
        // type 'selector' doesn't require an input value
        const dataTypeCheck =
            selectedFunction && selectedFunction.dataType === 'selector' ? inputValue === '' : inputValue !== '';

        // type 'duration' requires a duration unit
        const durationUnitCheck =
            selectedFunction && selectedFunction.dataType === 'duration'
                ? selectedDurationUnit !== null
                : selectedDurationUnit === null;

        if (
            conditionName.length > 0 &&
            selectedFunction &&
            selectedOperator &&
            dataTypeCheck &&
            durationUnitCheck &&
            errorMessage.length === 0
        ) {
            if (selectedFunction.relatedTable) {
                selectedRelatedItem ? setSaveButtonDisabled(false) : setSaveButtonDisabled(true);
            } else {
                setSaveButtonDisabled(false);
            }
        } else {
            setSaveButtonDisabled(true);
        }
    }, [
        conditionName,
        selectedFunction,
        selectedOperator,
        inputValue,
        errorMessage.length,
        selectedRelatedItem,
        selectedDurationUnit,
    ]);

    // get relevant operators and data type according to currently selected function
    useEffect(() => {
        if (selectedFunction && dataTypeOperators) {
            // find relevant operators by data type
            const relevantDataTypes = dataTypeOperators
                .filter(option => {
                    return option.function_data_type.id === selectedFunction.dataTypeId;
                })
                .map(filteredItem => {
                    return {
                        label: filteredItem.function_operator.display_name,
                        value: filteredItem.function_operator.id,
                    };
                });

            setSelectedFunctionOperators(relevantDataTypes);

            if (relevantDataTypes.length === 1) {
                setHideOperatorDropdown(true);
                setSelectedOperator(relevantDataTypes[0]);
            } else {
                setHideOperatorDropdown(false);
            }

            // save the relevant data type to state
            const dataType = dataTypes.find(dataType => dataType.id === selectedFunction.dataTypeId);
            setSelectedDataType(dataType);

            // reset the input value
            setInputValue('');

            // show the bool toggle if required
            if (dataType.name === 'bool') {
                setShowToggle(true);
                setInputValue(toggleChecked);
            } else {
                setShowToggle(false);
            }

            // reset the relevant table and input value
            setSelectedRelatedItem(null);
        }
    }, [selectedFunction, dataTypeOperators, dataTypes, toggleChecked]);

    // update the input value to value of toggle when toggle visible
    useEffect(() => {
        if (showToggle) {
            setInputValue(toggleChecked);
        }
    }, [showToggle, toggleChecked]);

    // api calls - functions, operators and data types
    const fetchFunctionsData = () => {
        const requests = [];

        // get segmenter functions
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter-function', [{ key: 'active', value: true }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get data type operators
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('config', 'data-type-operator', []),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get data types
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('config', 'data-type', []),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        // get durations
        requests.push(
            axios({
                method: 'GET',
                url: generateUrl('segmenter', 'segmenter-duration', []),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        );

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    // save functions to state
                    const functionDropdownItems = responses[0].data.objects.map(segmenterFunction => {
                        return {
                            label: segmenterFunction.display_name,
                            value: segmenterFunction.id,
                            dataTypeId: segmenterFunction.data_type.id,
                            dataType: segmenterFunction.data_type.name,
                            relatedTable: segmenterFunction.table_name,
                            relatedTableDisplayName: segmenterFunction.table_value_name,
                        };
                    });
                    setSegmenterFunctions(functionDropdownItems);

                    // save data type operators to state
                    setDataTypeOperators(responses[1].data.objects);

                    // save data types to state
                    setDataTypes(responses[2].data.objects);

                    // save durations to state
                    const durations = responses[3].data.objects.map(duration => {
                        return {
                            ...duration,
                            label: `${duration.name.charAt(0).toUpperCase()}${duration.name.slice(1)}s`,
                            value: duration.name,
                        };
                    });
                    setDurationItems(durations);
                })
            )
            .catch(e => {
                console.log(e);
                setPageError(true);
            })
            .finally(() => {
                setDataLoading(false);
            });
    };

    // api call - related table
    const getRelatedTableData = (tableName, displayName) => {
        setRelatedTableDataLoading(true);
        let tastypieName = TABLE_REF.find(ref => {
            return ref.tableName === tableName;
        }).tastypieName;

        axios({
            method: 'GET',
            url: generateUrl('config', tastypieName, []),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const relatedTableItems = response.data.objects.map(item => {
                    return {
                        label: item[displayName],
                        value: item.id,
                    };
                });
                setRelatedTableData(relatedTableItems);
                setRelatedTableDataLoading(false);
            })
            .catch(e => {
                console.log(e);
                setPageError(true);
            });
    };

    // post request to save condition
    const saveCondition = () => {
        // if the data type is duration, convert value to seconds before saving
        let valueToSave = inputValue;
        if (selectedFunction.dataType === 'duration') {
            valueToSave = convertToSeconds(inputValue, selectedDurationUnit.name);
        }

        // if the data type is selector, the value is the related table key
        if (selectedFunction.dataType === 'selector') {
            valueToSave = selectedRelatedItem.value;
        }

        axios({
            method: 'POST',
            url: generateUrl('segmenter', 'segmenter-condition'),
            data: {
                display_name: conditionName,
                has: true,
                key: selectedRelatedItem ? selectedRelatedItem.value : null,
                value: valueToSave,
                active: true,
                function: generatePath('segmenter', 'segmenter-function', selectedFunction.value),
                function_operator: generatePath('segmenter', 'operator', selectedOperator.value),
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                // save the duration unit if applicable
                if (selectedDataType.name === 'duration' && selectedDurationUnit) {
                    return axios({
                        method: 'POST',
                        url: generateUrl('segmenter', 'segmenter-condition-duration'),
                        data: {
                            segmenter_duration: generatePath(
                                'segmenter',
                                'segmenter-duration',
                                durationItems.find(item => item.id === selectedDurationUnit.id).id
                            ),
                            segmenter_condition: generatePath('segmenter', 'segmenter-condition', response.data.id),
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                }
            })
            .then(() => {
                setSaveButtonLoading(false);
                props.addNotification({
                    copy: 'This condition have been successfully saved.',
                    type: NotificationMessageType.Success,
                });
                props.setModal('SegmenterEditConditions');
            })
            .catch(e => {
                console.log(e);
                if (e.response.data.code === 409) {
                    props.addNotification({
                        copy: 'There is already a condition with that name. Please try using a different name.',
                        type: NotificationMessageType.Error,
                    });
                    setSaveButtonLoading(false);
                } else {
                    props.addNotification({
                        copy: 'There was an issue trying to save your condition. Please try again later or contact Cubed Support.',
                        type: NotificationMessageType.Error,
                    });
                    props.removeModal();
                }
            });
    };

    const renderRelevantInputField = () => {
        if (selectedFunction && selectedFunction.dataTypeId) {
            return (
                <RenderDataTypeField
                    dataType={selectedDataType.name}
                    value={inputValue}
                    selectedDurationUnit={selectedDurationUnit}
                    onInputChange={handleInputValue}
                    durationItems={durationItems}
                    onDurationInputUnitChange={handleDurationInputUnit}
                />
            );
        }
    };

    const handleCloseClick = () => {
        props.setModal('SegmenterEditConditions', {});
    };

    const handleSelectedFunction = selectedOption => {
        if (selectedOption) {
            setSelectedFunction(selectedOption);
            setSelectedOperator(null);
            setSelectedDurationUnit(null);
            setErrorMessage('');
            setInputValue('');

            // get related table if required
            if (selectedOption.relatedTable) {
                getRelatedTableData(selectedOption.relatedTable, selectedOption.relatedTableDisplayName);
            } else {
                setRelatedTableData(null);
            }
        } else {
            setSelectedFunction(null);
            setRelatedTableData(null);
            setSelectedOperator(null);
            setSelectedDurationUnit(null);
            setShowToggle(false);
            setErrorMessage('');
            setInputValue('');
        }
    };

    const handleSelectedRelatedTableItem = selectedOption => {
        if (selectedOption) {
            setSelectedRelatedItem(selectedOption);
        } else {
            setSelectedRelatedItem(null);
        }
    };

    const handleSelectedOperator = selectedOption => {
        if (selectedOption) {
            setSelectedOperator(selectedOption);
        } else {
            setSelectedOperator(null);
        }
    };

    const handleChangeToggle = () => {
        setToggleChecked(!toggleChecked);
    };

    const handleInputConditionName = e => {
        setConditionName(e.target.value);
    };

    const handleInputValue = e => {
        if (selectedDataType.name === 'string') {
            !isString(e.target.value) ? setErrorMessage('Please enter a valid string') : setErrorMessage('');
        }
        if (selectedDataType.name === 'float') {
            !isFloat(e.target.value) ? setErrorMessage('Please enter a valid decimal number') : setErrorMessage('');
        }
        if (selectedDataType.name === 'number') {
            !isInteger(e.target.value) ? setErrorMessage('Please enter a valid whole number') : setErrorMessage('');
        }
        if (selectedDataType.name === 'duration') {
            !isInteger(e.target.value) ? setErrorMessage('Please enter a valid whole number') : setErrorMessage('');
        }
        setInputValue(e.target.value);
    };

    const handleDurationInputUnit = selectedOption => {
        if (selectedOption) {
            setSelectedDurationUnit(selectedOption);
        } else {
            setSelectedDurationUnit(null);
        }
    };

    const handleFormSave = e => {
        e.preventDefault();
        setSaveButtonDisabled(true);
        setSaveButtonLoading(true);
        saveCondition();
    };

    const showOperators =
        selectedFunction &&
        !relatedTableDataLoading &&
        selectedFunctionOperators &&
        selectedFunctionOperators.length > 0;

    if (pageError) {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={true}
                    handleSaveChanges={handleFormSave}
                    saveChangesButtonLoading={saveButtonLoading}
                    handleCloseClick={handleCloseClick}
                    saveButtonDisabled={saveButtonDisabled}
                />
                <StyledH1>{ADD_FORM_MODAL_TITLE}</StyledH1>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </>
        );
    }

    if (!dataLoading && !pageError) {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={true}
                    handleSaveChanges={handleFormSave}
                    saveChangesButtonLoading={saveButtonLoading}
                    handleCloseClick={handleCloseClick}
                    saveButtonDisabled={saveButtonDisabled}
                />
                <StyledH1>{ADD_FORM_MODAL_TITLE}</StyledH1>

                <StyledP>{ADD_FORM_MODAL_DESCRIPTION}</StyledP>

                <StyledForm>
                    <StyledFormSection>
                        <CommonFormInput
                            placeholder="Enter a name for your condition"
                            label="Condition Name:"
                            toolTipCopy="Enter a name for your condition"
                            onChange={handleInputConditionName}
                            value={conditionName}
                            requiredField={true}
                        />
                    </StyledFormSection>
                    <StyledFormSection>
                        <DropdownWithSearch
                            dropdownItems={segmenterFunctions}
                            placeholder={'Select a function:'}
                            label="Function:"
                            selected={selectedFunction}
                            requiredField={true}
                            toolTipCopy={'Select a function'}
                            onSelectedItemChange={handleSelectedFunction}
                        />

                        {showToggle && (
                            <StyledToggleContainer>
                                <ToggleWithLabels
                                    trueLabel={'True'}
                                    falseLabel={'False'}
                                    checked={toggleChecked}
                                    onClick={handleChangeToggle}
                                />
                            </StyledToggleContainer>
                        )}
                    </StyledFormSection>

                    {!relatedTableDataLoading && relatedTableData && (
                        <StyledFormSection>
                            <DropdownWithSearch
                                dropdownItems={relatedTableData}
                                placeholder={'Select an item:'}
                                label={'Related Item'}
                                toolTipCopy={'Select a related item'}
                                requiredField={true}
                                selected={selectedRelatedItem}
                                onSelectedItemChange={handleSelectedRelatedTableItem}
                            />
                        </StyledFormSection>
                    )}

                    {relatedTableDataLoading && (
                        <StyledFormSection>
                            <LoadingSpinner />
                        </StyledFormSection>
                    )}

                    {showOperators && (
                        <StyledFormSection>
                            <DropdownWithSearch
                                dropdownItems={selectedFunctionOperators}
                                placeholder={'Select an operator'}
                                label={'Operator:'}
                                toolTipCopy={'Select an operator'}
                                requiredField={true}
                                selected={selectedOperator}
                                onSelectedItemChange={handleSelectedOperator}
                                hidden={hideOperatorDropdown}
                            />

                            {!showToggle && renderRelevantInputField()}
                        </StyledFormSection>
                    )}

                    {errorMessage && (
                        <StyledFormSection>
                            <StyledErrorMessageP>{errorMessage}</StyledErrorMessageP>
                        </StyledFormSection>
                    )}
                </StyledForm>
                <FunctionReference selectedFunction={selectedFunction} />
            </>
        );
    } else {
        return (
            <>
                <LayoutModalNavigation
                    isAddForm={true}
                    handleSaveChanges={handleFormSave}
                    saveChangesButtonLoading={saveButtonLoading}
                    handleCloseClick={handleCloseClick}
                    saveButtonDisabled={saveButtonDisabled}
                />
                <StyledH1>{ADD_FORM_MODAL_TITLE}</StyledH1>
                <LoadingSpinner />
            </>
        );
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        removeModal: () => {
            dispatch(removeModal());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(null, mapDispatchToProps)(SegmenterCreateConditions);
