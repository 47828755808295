import { grid } from 'css-grid-template-parser';

// Convert the grid layout string saved in the db back into a react-grid-layout layout object
const parseGridLayouts = (breakpoint: string, layoutAreas: string) => {
    const layouts = [];

    const rows = grid(layoutAreas.replaceAll(',', '\n'));

    const getMaxWidth = () => {
        switch (breakpoint) {
            case 'xl':
                return 8;
            case 'lg':
                return 6;
            case 'md':
                return 4;
            case 'sm':
                return 2;
            default:
                return 1;
        }
    };

    for (const [key, value] of Object.entries(rows.areas)) {
        layouts.push({
            i: key,
            x: value.column.start - 1,
            y: value.row.start - 1,
            w: value.column.span,
            h: value.row.span,
            minW: 1,
            maxW: getMaxWidth(),
            minH: 1,
            maxH: 4,
        });
    }

    return layouts;
};

export default parseGridLayouts;
