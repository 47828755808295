import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

// Resources
import {
    DASHBOARD_BUILDER_DASHBOARD,
    DASHBOARD_BUILDER_SECTION_AREAS,
    DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON,
    DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
    DASHBOARD_BUILDER_WIDGET_TYPE,
} from '../../config/resources-dashboard-builder';

// Context
import { DashboardBuilderContext } from '../../context/dashboard-builder-context';

// Config
import layoutConfiguration from '../configuration/layout-configuration';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// Helpers
import parseGridLayouts from '../helpers/parse-grid-layouts';
import getWidgetYLocation from '../helpers/get-widget-y-location';

// Notifications
import { addNotification } from '../../../redux/actions/notification';
import { NotificationMessageType } from '../../../enums/notification-types';

// React Query
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePostDashboardCreate from '../hooks/use-post-dashboard-create';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';

// Types
import { DashboardDetails, SectionConfig, WidgetConfig, WidgetConfigDataSuccess } from '../../types';
import { FieldValues } from 'react-hook-form';
import { DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router';
import { ConfigDataSuccess } from '../../../react-query/types';
import { RootState } from '../../../redux/store';
import usePatchDashboardUpdate from '../hooks/use-patch-dashboard-update';
import getWidgetsInUse from '../../libraries/widget-library/helpers/get-widgets-in-use';

export interface CreateADashboardContextValues {
    // Dashboard Details
    dashboardId?: string;
    dashboardQuery: UseQueryResult<ConfigDataSuccess>;
    dashboardDetails: DashboardDetails;
    setDashboardDetails: Dispatch<SetStateAction<DashboardDetails>>;
    // Widgets
    widgetQuery: UseQueryResult<WidgetConfigDataSuccess>;
    widgetTypeQuery: UseQueryResult<WidgetConfigDataSuccess>;
    widgets: WidgetConfig[];
    currentWidgets: WidgetConfig[];
    setCurrentWidgets: Dispatch<SetStateAction<WidgetConfig[]>>;
    handleAddWidgetToSection: (result: DropResult) => void;
    handleRemoveWidgetFromSection: (widgetId: string) => void;
    handleFilterWidgets: (searchTerm: string) => void;
    // Breakpoints
    breakpoint?: string;
    setBreakpoint(breakpoint: string): void;
    // Sections
    sections: SectionConfig[];
    setSections: Dispatch<SetStateAction<SectionConfig[]>>;
    handleCreateSection: (data: FieldValues) => void;
    handleRemoveSection: (sectionId: string) => void;
    handleReorderSections: (result: DropResult) => void;
    handleAccordionChange: (sectionIndex: number, isVisible: boolean) => void;
    // Comparison View
    showComparison: boolean;
    handleComparisonFormSubmit: (data: FieldValues) => void;
    // Save
    handleSaveDashboard: () => void;
    saveDashboardMutation: UseMutationResult<void, Error, void, unknown>;
    updateDashboardMutation: UseMutationResult<void, Error, void, unknown>;
}

export const CreateADashboardContext = createContext<CreateADashboardContextValues>(
    {} as CreateADashboardContextValues
);

const CreateADashboardContextProvider = ({ children }: { children: JSX.Element }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accountToken = useSelector((state: RootState) => state.account.token);
    const { dashboardId } = useParams();
    const { clientUserId, clientUserFirstName } = useContext(DashboardBuilderContext);

    // Dashboard Details
    const [dashboardDetails, setDashboardDetails] = useState({
        title: 'My Dashboard',
        description: '',
        isPrivate: false,
        enableComparison: false,
        changed: false,
    });

    useEffect(() => {
        if (!dashboardId) {
            if (clientUserFirstName) {
                setDashboardDetails({
                    ...dashboardDetails,
                    title: `${clientUserFirstName}'s Dashboard`,
                });
            }
        }
    }, [clientUserFirstName]); // eslint-disable-line react-hooks/exhaustive-deps

    // If there is a dashboardId in the URL, fetch the dashboard details
    const dashboardQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_DASHBOARD,
        params: [
            { key: 'id', value: dashboardId || 0 },
            { key: 'author', value: clientUserId },
            { key: 'active', value: true },
        ],
        enabled: !!dashboardId,
    });

    const sectionQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_SECTION_AREAS,
        params: [{ key: 'section__dashboard', value: dashboardId || 0 }],
        enabled: !!dashboardId,
    });

    const sectionComparisonQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON,
        params: [{ key: 'section__dashboard', value: dashboardId || 0 }],
        enabled: !!dashboardId,
    });

    const widgetsInUse = sectionQuery.data ? getWidgetsInUse(sectionQuery.data) : undefined;

    const currentWidgetQuery = useFetchResource<WidgetConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
        params: [{ key: 'widget__id__in', value: widgetsInUse ? widgetsInUse.join(',') : '' }],
        enabled: !!dashboardId && !!widgetsInUse,
    });

    useEffect(() => {
        if (dashboardQuery.isSuccess && dashboardQuery.data.objects.length > 0) {
            const dashboard = dashboardQuery.data.objects[0];
            setDashboardDetails({
                ...dashboardDetails,
                title: dashboard.title,
                description: dashboard.description,
                isPrivate: dashboard.private,
                enableComparison: dashboard.comparison_enabled,
            });
        }
    }, [dashboardQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            dashboardQuery.isSuccess &&
            sectionQuery.isSuccess &&
            sectionComparisonQuery.isSuccess &&
            currentWidgetQuery.isSuccess
        ) {
            // Set the current widgets
            setCurrentWidgets(currentWidgetQuery.data.objects);

            // Set the sections
            let currentSections = sectionQuery.data.objects
                .map(section => {
                    return {
                        id: section.section.id,
                        index: section.section.display_order,
                        sectionId: `section-existing-${section.section.id}`,
                        sectionAreasId: section.id,
                        sectionName: section.section.title,
                        collapsed: section.section.default_collapsed,
                        layouts: {
                            xl: parseGridLayouts('xl', section.grid_xl),
                            lg: parseGridLayouts('lg', section.grid_lg),
                            md: parseGridLayouts('md', section.grid_md),
                            sm: parseGridLayouts('sm', section.grid_sm),
                            xs: parseGridLayouts('xs', section.grid_xs),
                        },
                        comparisonLayouts: {},
                    };
                })
                .sort((a, b) => a.index - b.index);

            currentSections = currentSections.map(section => {
                const matchingComparisonSection = sectionComparisonQuery.data.objects.find(sectionComparison => {
                    return sectionComparison.section.id === section.id;
                });

                return {
                    ...section,
                    sectionAreasComparisonId: matchingComparisonSection?.id,
                    comparisonLayouts: {
                        xl: parseGridLayouts('xl', matchingComparisonSection?.grid_xl),
                        lg: parseGridLayouts('lg', matchingComparisonSection?.grid_lg),
                        md: parseGridLayouts('md', matchingComparisonSection?.grid_md),
                        sm: parseGridLayouts('sm', matchingComparisonSection?.grid_sm),
                        xs: parseGridLayouts('xs', matchingComparisonSection?.grid_xs),
                    },
                };
            });

            setSections(currentSections);
        }
    }, [dashboardQuery.data, sectionQuery.data, currentWidgetQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    // Widgets created by the current user
    const widgetQuery = useFetchResource<WidgetConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_WIDGET_REQUEST_CONFIG,
        params: [{ key: 'widget__author', value: clientUserId }],
        enabled: !!clientUserId,
    });

    const [widgets, setWidgets] = useState<WidgetConfig[]>([]);

    useEffect(() => {
        if (widgetQuery.isSuccess) {
            setWidgets(widgetQuery.data.objects);
        }
    }, [widgetQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    // Widget Types
    const widgetTypeQuery = useFetchResource<WidgetConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_WIDGET_TYPE,
    });

    // Widgets currently being displayed in the grid
    const [currentWidgets, setCurrentWidgets] = useState<WidgetConfig[]>([]);

    const handleAddWidgetToSection = (result: DropResult) => {
        const chosenWidget =
            widgets && widgets.find(widget => widget.widget.id === parseInt(result.draggableId.split('-')[1]));
        if (chosenWidget && breakpoint && result.destination?.droppableId) {
            const updatedSections = sections.map(section => {
                if (section.sectionId === result.destination?.droppableId) {
                    return {
                        ...section,
                        layouts: {
                            xl: [
                                ...section.layouts.xl,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.xl,
                                    y: getWidgetYLocation(section.layouts.xl),
                                },
                            ],
                            lg: [
                                ...section.layouts.lg,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.lg,
                                    y: getWidgetYLocation(section.layouts.lg),
                                },
                            ],
                            md: [
                                ...section.layouts.md,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.md,
                                    y: getWidgetYLocation(section.layouts.md),
                                },
                            ],
                            sm: [
                                ...section.layouts.sm,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.sm,
                                    y: getWidgetYLocation(section.layouts.sm),
                                },
                            ],
                            xs: [
                                ...section.layouts.xs,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.xs,
                                    y: getWidgetYLocation(section.layouts.xs),
                                },
                            ],
                        },
                        comparisonLayouts: {
                            xl: [
                                ...section.layouts.xl,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.xl,
                                    y: getWidgetYLocation(section.layouts.xl),
                                },
                            ],
                            lg: [
                                ...section.layouts.lg,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.lg,
                                    y: getWidgetYLocation(section.layouts.lg),
                                },
                            ],
                            md: [
                                ...section.layouts.md,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.md,
                                    y: getWidgetYLocation(section.layouts.md),
                                },
                            ],
                            sm: [
                                ...section.layouts.sm,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.sm,
                                    y: getWidgetYLocation(section.layouts.sm),
                                },
                            ],
                            xs: [
                                ...section.layouts.xs,
                                {
                                    i: `widget${chosenWidget.widget.id}`,
                                    ...layoutConfiguration.xs,
                                    y: getWidgetYLocation(section.layouts.xs),
                                },
                            ],
                        },
                    };
                } else {
                    return section;
                }
            });
            setCurrentWidgets([...currentWidgets, { ...chosenWidget, sectionId: result.destination.droppableId }]);
            setSections(updatedSections);
        }
    };

    const handleRemoveWidgetFromSection = (widgetId: string) => {
        const updatedSections = sections.map(section => {
            return {
                ...section,
                layouts: {
                    xl: section.layouts.xl.filter(layout => layout.i !== widgetId),
                    lg: section.layouts.lg.filter(layout => layout.i !== widgetId),
                    md: section.layouts.md.filter(layout => layout.i !== widgetId),
                    sm: section.layouts.sm.filter(layout => layout.i !== widgetId),
                    xs: section.layouts.xs.filter(layout => layout.i !== widgetId),
                },
                comparisonLayouts: {
                    xl: section.comparisonLayouts.xl.filter(layout => layout.i !== widgetId),
                    lg: section.comparisonLayouts.lg.filter(layout => layout.i !== widgetId),
                    md: section.comparisonLayouts.md.filter(layout => layout.i !== widgetId),
                    sm: section.comparisonLayouts.sm.filter(layout => layout.i !== widgetId),
                    xs: section.comparisonLayouts.xs.filter(layout => layout.i !== widgetId),
                },
            };
        });

        setSections(updatedSections);
        setCurrentWidgets(
            currentWidgets.filter(widget => widget.widget.id !== parseInt(widgetId.replace('widget', '')))
        );
    };

    const handleFilterWidgets = (searchTerm: string) => {
        if (searchTerm) {
            const updatedWidgets = widgets.filter(widget =>
                widget.widget.title.toLowerCase().includes(searchTerm.toLowerCase())
            );

            setWidgets(updatedWidgets);
        }
    };

    // Current dashboard breakpoint size
    const [breakpoint, setBreakpoint] = useState<string>();

    // Sections
    const [sections, setSections] = useState<SectionConfig[]>([]);
    const [removedSections, setRemovedSections] = useState<SectionConfig[]>([]);

    const handleCreateSection = (data: FieldValues) => {
        const sectionIndex = sections.length === 0 ? 0 : Math.max(...sections.map(section => section.index)) + 1;
        const sectionId = `section${sectionIndex}`;
        setSections([
            ...sections,
            {
                index: sectionIndex,
                sectionId: sectionId,
                sectionName: data.sectionName,
                collapsed: false,
                layouts: { xl: [], lg: [], md: [], sm: [], xs: [] },
                comparisonLayouts: { xl: [], lg: [], md: [], sm: [], xs: [] },
            },
        ]);
    };

    const handleRemoveSection = (sectionId: string) => {
        if (sectionId.includes('existing')) {
            setRemovedSections([
                ...removedSections,
                sections.find(section => section.sectionId === sectionId) as SectionConfig,
            ]);
        }
        setCurrentWidgets(currentWidgets.filter(widget => widget.sectionId !== sectionId));
        setSections(sections.filter(section => section.sectionId !== sectionId));
    };

    const handleReorderSections = (result: DropResult) => {
        const movedSection = sections[result.source.index];
        const updatedSections = sections.filter(section => section.sectionId !== movedSection.sectionId);
        updatedSections.splice(result.destination!.index, 0, movedSection);

        setSections(updatedSections);
    };

    // If an accordion is opened or closed, update the section's collapsed state
    const handleAccordionChange = (sectionIndex: number, isVisible: boolean) => {
        const updatedSections = sections.map(section => {
            if (section.index === sectionIndex) {
                return {
                    ...section,
                    collapsed: !isVisible,
                };
            } else {
                return section;
            }
        });

        setSections(updatedSections);
    };

    // Comparison View
    const [showComparison, setShowComparison] = useState(false);

    const handleComparisonFormSubmit = (data: FieldValues) => {
        setShowComparison(data.showComparison);
    };

    // Save Dashboard
    const saveDashboardMutation = usePostDashboardCreate({
        dashboardDetails,
        sections,

        onSuccess: () => {
            // Reset the dashboard configuration
            setDashboardDetails({
                title: clientUserFirstName ? `${clientUserFirstName}'s Dashboard` : 'My Dashboard',
                description: '',
                isPrivate: false,
                enableComparison: false,
                changed: false,
            });
            setCurrentWidgets([]);
            setSections([]);
            dispatch(
                addNotification({
                    copy: `Dashboard created successfully!`,
                    type: NotificationMessageType.Success,
                })
            );
            navigate(`/${accountToken}/dashboard-builder/dashboard-library`);
        },
    });

    const updateDashboardMutation = usePatchDashboardUpdate({
        dashboardId: dashboardId || '',
        dashboardDetails,
        sections,
        removedSections,
        onSuccess: () => {
            addNotification({
                copy: `Dashboard updated successfully!`,
                type: NotificationMessageType.Success,
            });
            navigate(`/${accountToken}/dashboard-builder/dashboard-library`);
        },
    });

    const handleSaveDashboard = () => {
        if (dashboardId) {
            updateDashboardMutation.mutate();
        } else {
            saveDashboardMutation.mutate();
        }
    };

    return (
        <CreateADashboardContext.Provider
            value={{
                // Dashboard Details
                dashboardId,
                dashboardQuery,
                dashboardDetails,
                setDashboardDetails,
                // Widgets
                widgetQuery,
                widgetTypeQuery,
                widgets,
                currentWidgets,
                setCurrentWidgets,
                handleAddWidgetToSection,
                handleRemoveWidgetFromSection,
                handleFilterWidgets,
                // Breakpoints
                breakpoint,
                setBreakpoint,
                // Sections
                sections,
                setSections,
                handleCreateSection,
                handleRemoveSection,
                handleReorderSections,
                handleAccordionChange,
                // Comparison View
                showComparison,
                handleComparisonFormSubmit,
                // Save
                handleSaveDashboard,
                saveDashboardMutation,
                updateDashboardMutation,
            }}
        >
            {children}
        </CreateADashboardContext.Provider>
    );
};

export default CreateADashboardContextProvider;
