import React, { Component } from 'react';

import InputField from '../inputs/input-field';

class ListRowCheckCopy extends Component {
    render() {
        return (
            <div
                key={this.props.keyValue}
                data-value={this.props.dataValue}
                className="list-row__check-copy"
                onClick={this.props.onClick}
            >
                <div className="list-row__check-copy__check">
                    <InputField checked={this.props.checked} type="checkbox" />
                </div>
                <div className="list-row__check-copy__copy">
                    <p>{this.props.copy}</p>
                </div>
            </div>
        );
    }
}

export default ListRowCheckCopy;
