// React Dependencies
import React from 'react';
import styled from 'styled-components';
import { ButtonThemes } from '../enums/button-themes';

// Import Components
import InputButtonWrapper from './inputs/input-button-wrapper';
import InputButton from './inputs/input-button';

const StyledP = styled.p`
    font-weight: 400;
    margin: 0px 18px;
`;

const StyledWrapper = styled.div`
    width: 100%;
    height: 45px;
`;

type ModalNavigationButtons = {
    value: string;
    onClick: () => void;
    buttonTheme?: ButtonThemes;
    disabled?: boolean;
    isLoading?: boolean;
    hidden?: boolean;
};

export type ModalNavigationProps = {
    buttons: ModalNavigationButtons[];
    count?: number;
};

const ModalNavigation = ({ buttons, count }: ModalNavigationProps) => {
    return (
        <StyledWrapper>
            <InputButtonWrapper>
                {count && count > 0 ? <StyledP>Unsaved Changes: {count}</StyledP> : null}
                {buttons.map(button => (
                    <InputButton
                        key={button.value}
                        value={button.value}
                        buttonTheme={button.buttonTheme}
                        disabled={button.disabled}
                        onClick={button.onClick}
                        isLoading={button.isLoading}
                        hidden={button.hidden}
                    />
                ))}
            </InputButtonWrapper>
        </StyledWrapper>
    );
};

export default ModalNavigation;
