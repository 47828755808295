import React from 'react';
import styled, { css } from 'styled-components';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { ButtonThemes } from '../../../enums/button-themes';
import InputButtonLoadingSpindle from '../../../components/inputs/input-button-loading-spindle';

export enum TriggerType {
    WRAPPER,
    BUTTON_PRIMARY,
    BUTTON_WARNING,
}

const StyledButtonBase = css<{ $type: TriggerType; $isLoading?: boolean }>`
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: ${props =>
        props.$isLoading
            ? props.theme.dialog.trigger.backgroundColor.loading
            : props.$type === TriggerType.BUTTON_WARNING
            ? props.theme.dialog.trigger.backgroundColor.warning
            : props.$type === TriggerType.WRAPPER
            ? 'transparent'
            : props.theme.dialog.trigger.backgroundColor.primary};
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 3px;
    color: ${props => props.$type !== TriggerType.WRAPPER && props.theme.colours.white};
    text-align: center;
    text-transform: uppercase;
    line-height: 32px;
    padding: ${props => (props.$type === TriggerType.WRAPPER ? '0' : '0 12px')};
`;

const StyledButton = styled.button<{ $type: TriggerType; $isLoading?: boolean }>`
    ${StyledButtonBase}
`;

const StyledDialogTrigger = styled(AlertDialog.Trigger)<{ $type: TriggerType; $isLoading?: boolean }>`
    ${StyledButtonBase}
`;

const DialogTrigger = ({
    type = TriggerType.BUTTON_PRIMARY,
    loading,
    onClick,
    children,
}: {
    type?: TriggerType;
    loading?: boolean;
    onClick?: () => void;
    children: React.ReactNode | React.ReactNode[];
}) => {
    if (loading) {
        return (
            <StyledButton $type={type} $isLoading={loading} disabled={true}>
                <InputButtonLoadingSpindle buttonTheme={ButtonThemes.Primary} />
                {children}
            </StyledButton>
        );
    }
    return (
        <StyledDialogTrigger $type={type} $isLoading={loading} onClick={onClick}>
            {children}
        </StyledDialogTrigger>
    );
};

export default DialogTrigger;
