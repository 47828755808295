import React from 'react';

import { RequestFilter, WidgetMenuCollection, WidgetMenuObj, WidgetMenuType } from '../../../types';
import WidgetMenuSection from '../components/section-menu/widget-menu-section';
import LayoutContextProvider from '../context/layout-context';

type UseWidgetMenuSectionArgs = {
    sections: WidgetMenuCollection[];
};

const useWidgetMenuSection = ({ sections }: UseWidgetMenuSectionArgs): WidgetMenuObj => {
    const sectionFilters: RequestFilter[] = [];
    let showEmpty = false;

    sections.map(section =>
        section.menus.forEach(menu => {
            if (menu.request?.sectionFilters) {
                sectionFilters.push(...menu.request.sectionFilters);
            }

            if (menu.request?.showEmpty) {
                showEmpty = true;
            }
        })
    );

    return {
        type: WidgetMenuType.SECTION,
        menu: (
            <LayoutContextProvider>
                <WidgetMenuSection sections={sections} />
            </LayoutContextProvider>
        ),
        request: {
            sectionFilters: sectionFilters,
            showEmpty,
        },
    };
};

export default useWidgetMenuSection;
