import React, { useContext } from 'react';
import styled from 'styled-components';

// Context
import { DashboardBuilderContext } from '../../context/dashboard-builder-context';
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

// Form
import Form from '../../../components/forms/form';
import validators from '../../../components/forms/validation/validators';

// Types
import { FieldValues } from 'react-hook-form';
import { DashboardDetails } from '../../types';

const StyledDescription = styled.p`
    font-size: 0.8rem;
    font-style: italic;
    max-width: 400px;
`;

const FormDashboardDetails = ({
    dashboardDetails,
    onSubmit,
}: {
    dashboardDetails: DashboardDetails;
    onSubmit: (data: FieldValues) => void;
}) => {
    const { userDashboards } = useContext(DashboardBuilderContext);
    const { dashboardId } = useContext(CreateADashboardContext);

    let existingDashboardNames = userDashboards?.map(dashboard => dashboard.title);

    if (dashboardId) {
        existingDashboardNames = existingDashboardNames.filter(name => name !== dashboardDetails.title);
    }

    return (
        <Form
            defaultValues={{
                dashboardName: dashboardDetails.title,
                dashboardDescription: dashboardDetails.description,
                isPrivate: dashboardDetails.isPrivate,
                enableComparison: dashboardDetails.enableComparison,
            }}
            onSubmit={onSubmit}
        >
            <Form.Body>
                <Form.Section>
                    <Form.Field>
                        <Form.InputLabel htmlFor="dashboardName" label="Dashboard Name" />
                        <Form.InputText
                            name="dashboardName"
                            validators={[
                                validators.required,
                                validators.maxLength(100),
                                ...[
                                    {
                                        validate: (value: string) => {
                                            return (
                                                !existingDashboardNames?.includes(value.trim()) ||
                                                'A dashboard with this name already exists'
                                            );
                                        },
                                    },
                                ],
                            ]}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputLabel htmlFor="dashboardDescription" label="Description" />
                        <Form.InputTextArea name="dashboardDescription" />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputCheckbox name="enableComparison" label="Enable Comparison View" />
                    </Form.Field>

                    <Form.Field>
                        <Form.InputCheckbox name="isPrivate" label="Private" />
                    </Form.Field>

                    <StyledDescription>
                        Note: private dashboards will not be displayed in other users' dashboard libraries, and only you
                        will be able to view them.
                    </StyledDescription>
                </Form.Section>
            </Form.Body>
            <Form.Footer>
                <Form.InputButton value="Update" />
            </Form.Footer>
        </Form>
    );
};

export default FormDashboardDetails;
