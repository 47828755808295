import React from 'react';
import Cancel from './cancel';
import styled from 'styled-components';
import { Instance } from 'tinycolor2';
import { SelectedRow } from './selection-legend';

const StyledCancelButton = styled.button`
    float: right;
    padding: 8px;
    background-color: ${props => props.theme.colours.white};
    border-style: none;
`;

const StyledTextSpan = styled.span`
    padding: 0 6px;
    overflow: hidden;
    max-width: 210px;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    margin-left: 5px;
    line-height: 43px;
    font-size: 14px;
`;

const StyledColourSpan = styled.span<{ backgroundColor: string }>`
    width: 42px;
    height: 42px;
    background-color: ${props => props.backgroundColor};
`;
const StyledList = styled.div`
    background-color: ${props => props.theme.colours.offLight};
    padding: 3px;
    margin-bottom: 10px;
    border-radius: 12px;
`;

const StyledBox = styled.div<{ borderColor: string; isAll?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${props => props.theme.colours.white};
    height: 42px;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.24);
    border: 1px solid black;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-color: ${props => props.borderColor};
    font-style: ${props => (props.isAll ? 'italic' : '')};
`;

const StyledContentContainer = styled.div`
    display: flex;
    align-items: center;
`;

type SelectionLegendItemProps = {
    isAll?: boolean;
    colour: Instance;
    text: string;
    rowId?: number | null;
    selectedRows: SelectedRow[];
    onUpdateRows: (updatedRows: SelectedRow[]) => void;
};

const SelectionLegendItem = ({ isAll, colour, text, rowId, selectedRows, onUpdateRows }: SelectionLegendItemProps) => {
    const removeSelection = () => {
        const updatedRows = selectedRows.filter(row => row.rowId !== rowId);
        onUpdateRows(updatedRows);
    };

    const itemColourStyle = {
        backgroundColor: colour.clone().toString(),
        borderColor: colour.clone().darken(10).toString(),
    };

    return (
        <StyledList key={rowId}>
            <StyledBox borderColor={colour.clone().darken(10).toString()} isAll={isAll}>
                <StyledContentContainer>
                    <StyledColourSpan backgroundColor={itemColourStyle.backgroundColor} />
                    <StyledTextSpan>{text}</StyledTextSpan>
                </StyledContentContainer>
                <StyledCancelButton>
                    {!isAll && <Cancel onClick={removeSelection} colour="red" floatRight={true} />}
                </StyledCancelButton>
            </StyledBox>
        </StyledList>
    );
};

export default SelectionLegendItem;
