export enum FileType {
    CSV = 'text/csv, application/vnd.ms-excel',
}

export enum FileColumnDataType {
    NUMBER = 'number',
    DATE = 'date',
    STRING_IN_ARRAY = 'stringInArray',
}

export type FileFormatConfig = {
    header: string;
    format: FileColumnDataType;
    comparisonArray?: string[];
}[];

export type FileData = { [key: string]: string | number };
