import React from 'react';
import styled from 'styled-components';
import { inputStyles } from './styles/input-styles';

// Context
import { FieldError, useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

// Validation
import buildValidators from '../validation/helpers/build-validators';
import { Validator } from '../types';

// Components
import InputContainer from './components/input-container';
import InputErrorMessage from './components/input-error-message';

const StyledTextArea = styled.textarea<{ disabled: boolean }>`
    ${inputStyles}
    min-height: 100px;
    resize: none;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 2px;

    &:focus {
        outline: none;
    }

    ::placeholder {
        color: ${props => props.theme.forms.inputs.placeHolderColor};
        opacity: 1;
    }

    ::-ms-input-placeholder {
        color: ${props => props.theme.forms.inputs.placeHolderColor};
    }
`;

export type InputTextAreaProps = {
    name: string;
    placeholder?: string;
    disabled?: boolean;
    validators?: Validator[];
};

const InputTextArea = ({ name, placeholder, disabled, validators = [] }: InputTextAreaProps) => {
    const { status } = useCubedFormContext();
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <InputContainer>
            <StyledTextArea
                placeholder={placeholder}
                disabled={!!disabled || status === 'disabled' || status === 'loading'}
                {...register(name, { ...buildValidators(validators) })}
            />

            {errors[name] && <InputErrorMessage error={errors[name] as FieldError} />}
        </InputContainer>
    );
};

export default InputTextArea;
