import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import ModalNavigation from '../modal-navigation';
import { ButtonThemes } from '../../enums/button-themes';

const LayoutModalNavigation = ({
    isAddForm,
    handleSaveChanges,
    saveChangesButtonLoading,
    handleCloseClick,
    updatedData,
    deletedData,
    saveChangesButtonHidden,
    saveButtonDisabled,
}) => {
    let closeButtonText = 'CLOSE';
    let closeButtonStyle = ButtonThemes.Secondary;
    let modalNavigationButtons = [];

    if (isAddForm) {
        modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: handleSaveChanges,
                disabled: saveButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonText,
                onClick: handleCloseClick,
                disabled: false,
                buttonTheme: closeButtonStyle,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    } else {
        const numberOfChanges = updatedData.length;

        if (updatedData.length > 0 || deletedData.length > 0) {
            closeButtonText = 'CANCEL';
            closeButtonStyle = ButtonThemes.RedSecondary;
        }

        modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: handleSaveChanges,
                hidden: saveChangesButtonHidden,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonText,
                onClick: handleCloseClick,
                // disabled: this.state.closeButtonDisabled,
                buttonTheme: closeButtonStyle,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    }
};

ModalNavigation.propTypes = {
    isAddForm: PropTypes.bool,
    handleSaveChanges: PropTypes.func,
    saveChangesButtonLoading: PropTypes.bool,
    handleCloseClick: PropTypes.func,
    updatedData: PropTypes.array,
    deletedData: PropTypes.array,
    saveChangesButtonHidden: PropTypes.bool,
    saveButtonDisabled: PropTypes.bool,
};

export default memo(LayoutModalNavigation, isEqual);
