import React, { useContext } from 'react';
import styled from 'styled-components';
import { getChannelSubsectionConfig } from '../configurations/channel-subsection-config';
import { FilterBarContext } from '../context/filter-bar-context';

// Types
import { RequestStatus } from '../../section-dashboard/types';

// Icon
import IconChevronsRight from '../../components/icons/chevrons-right';

// Component
import SectionDashboardMenuSelect from '../../section-dashboard/components/menus/section-dashboard-menu-select';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    min-height: 53px;
    padding: 10px 16px;
    margin: 12px 0px 8px 0px;
    background-color: ${props => props.theme.sectionDashboard.widget.base.backgroundColor};
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};
    color: ${props => props.theme.filterBar.channelSignpost.textColor};

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const StyledTierWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
`;

const StyledTierContainer = styled.span`
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
`;

const StyledTierTitle = styled.span`
    font-weight: 400;
    font-size: 0.9rem;
`;

const StyledTier = styled.span<{ $loading: boolean }>`
    font-weight: ${props => props.theme.filterBar.channelSignpost.tierFontWeight};
    text-transform: uppercase;
    font-size: 0.85rem;
    color: ${props => props.theme.filterBar.channelSignpost.tierTextColor};
    width: ${props => props.$loading && '100px'};
    height: ${props => props.$loading && '19px'};
    background-color: ${props => props.$loading && props.theme.sectionDashboard.widget.menu.separatorColor};
    border-radius: ${props => props.$loading && '5px'};
`;

const StyledIconChevrons = styled(IconChevronsRight)`
    width: 10px;
    margin-right: 10px;
    color: ${props => props.theme.colours.cubedOrange};
`;

const FilterBarChannelSignpost = () => {
    const { channelDropdownOptions, selectedChannel, handleSingleChannelSelect } = useContext(FilterBarContext);

    const loading = channelDropdownOptions.status === RequestStatus.LOADING;
    if (loading || selectedChannel) {
        const currentChannel = selectedChannel || { label: 'Unknown', value: 'Unknown' };
        const currentSubsection = getChannelSubsectionConfig(currentChannel.label);

        return (
            <StyledContainer>
                <SectionDashboardMenuSelect
                    label="Channel"
                    dropdownOptions={channelDropdownOptions}
                    selectedOption={selectedChannel}
                    onOptionChange={handleSingleChannelSelect}
                    emptyMessage="No channels found"
                />

                <StyledTierWrapper>
                    <StyledTierContainer>
                        <StyledIconChevrons />
                        <StyledTierTitle>2nd Tier Type:</StyledTierTitle>
                        <StyledTier $loading={loading}>
                            {!loading && (currentSubsection?.tier2.displayName || 'Unknown')}
                        </StyledTier>
                    </StyledTierContainer>

                    <StyledTierContainer>
                        <StyledIconChevrons />
                        <StyledTierTitle>3rd Tier Type:</StyledTierTitle>
                        <StyledTier $loading={loading}>
                            {!loading && (currentSubsection?.tier3.displayName || 'Unknown')}
                        </StyledTier>
                    </StyledTierContainer>

                    <StyledTierContainer>
                        <StyledIconChevrons />
                        <StyledTierTitle>4th Tier Type:</StyledTierTitle>
                        <StyledTier $loading={loading}>
                            {!loading && (currentSubsection?.tier4.displayName || 'Unknown')}
                        </StyledTier>
                    </StyledTierContainer>
                </StyledTierWrapper>
            </StyledContainer>
        );
    }
    return null;
};

export default FilterBarChannelSignpost;
