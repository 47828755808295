import { useContext } from 'react';

import axios from 'axios';

// React Query
import { useMutation, useQueryClient } from '@tanstack/react-query';

// Context
import { DashboardBuilderContext } from '../../context/dashboard-builder-context';

// Resources
import {
    DASHBOARD_BUILDER_CLIENT_USERS,
    DASHBOARD_BUILDER_DASHBOARD,
    DASHBOARD_BUILDER_SECTION,
    DASHBOARD_BUILDER_SECTION_AREAS,
    DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON,
} from '../../config/resources-dashboard-builder';

// Helpers
import generateQueryKey from '../../../react-query/helpers/generate-query-key';
import { generatePath, generateUrl } from '../../../helpers/request-builder';
import parseGridAreas from '../helpers/parse-grid-areas';

// Types
import { DashboardDetails, SectionConfig } from '../../types';

type UsePostDashboardCreateArgs = {
    dashboardDetails: DashboardDetails;
    sections: SectionConfig[];
    onSuccess: () => void;
};

// A custom hook for creating new dashboards in the dashboard builder
const usePostDashboardCreate = ({ dashboardDetails, sections, onSuccess }: UsePostDashboardCreateArgs) => {
    const queryClient = useQueryClient();
    const { clientUserId } = useContext(DashboardBuilderContext);

    const mutation = useMutation({
        mutationFn: async () => {
            // Step 1: Save the dashboard details and return the created dashboard id
            return axios({
                method: 'POST',
                url: generateUrl('dashboard-builder', DASHBOARD_BUILDER_DASHBOARD.id),
                data: {
                    title: dashboardDetails.title,
                    description: dashboardDetails.description,
                    private: dashboardDetails.isPrivate,
                    comparison_enabled: dashboardDetails.enableComparison,
                    author:
                        generatePath('dashboard-builder', DASHBOARD_BUILDER_CLIENT_USERS.id, String(clientUserId)) ||
                        '0',
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    // Step 2: Save each section and return a section id
                    const dashboardId = response.data.id;

                    sections.forEach(async (section, index) => {
                        axios({
                            method: 'POST',
                            url: generateUrl('dashboard-builder', DASHBOARD_BUILDER_SECTION.id),
                            data: {
                                dashboard: generatePath(
                                    'dashboard-builder',
                                    DASHBOARD_BUILDER_DASHBOARD.id,
                                    String(dashboardId)
                                ),
                                title: section.sectionName,
                                default_collapsed: section.collapsed,
                                display_order: index,
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }).then(response => {
                            // Step 3: Save the section areas
                            const createdSectionId = response.data.id;

                            return axios({
                                method: 'POST',
                                url: generateUrl('dashboard-builder', DASHBOARD_BUILDER_SECTION_AREAS.id),
                                data: {
                                    section: generatePath(
                                        'dashboard-builder',
                                        DASHBOARD_BUILDER_SECTION.id,
                                        String(createdSectionId)
                                    ),
                                    grid_xs: parseGridAreas('xs', section.layouts.xs),
                                    grid_sm: parseGridAreas('sm', section.layouts.sm),
                                    grid_md: parseGridAreas('md', section.layouts.md),
                                    grid_lg: parseGridAreas('lg', section.layouts.lg),
                                    grid_xl: parseGridAreas('xl', section.layouts.xl),
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            }).then(() => {
                                return axios({
                                    method: 'POST',
                                    url: generateUrl(
                                        'dashboard-builder',
                                        DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON.id
                                    ),
                                    data: {
                                        section: generatePath(
                                            'dashboard-builder',
                                            DASHBOARD_BUILDER_SECTION.id,
                                            String(createdSectionId)
                                        ),
                                        grid_xs: parseGridAreas('xs', section.comparisonLayouts.xs),
                                        grid_sm: parseGridAreas('sm', section.comparisonLayouts.sm),
                                        grid_md: parseGridAreas('md', section.comparisonLayouts.md),
                                        grid_lg: parseGridAreas('lg', section.comparisonLayouts.lg),
                                        grid_xl: parseGridAreas('xl', section.comparisonLayouts.xl),
                                    },
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });
                            });
                        });
                    });
                    return dashboardId;
                })
                .finally(() => {
                    queryClient.invalidateQueries({
                        queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_DASHBOARD }),
                    });
                    queryClient.invalidateQueries({
                        queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_SECTION }),
                    });
                    queryClient.invalidateQueries({
                        queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_SECTION_AREAS }),
                    });
                    queryClient.invalidateQueries({
                        queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON }),
                    });
                });
        },
        onSuccess: () => {
            onSuccess();
        },
    });

    return mutation;
};

export default usePostDashboardCreate;
