import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CubedConfigResource, CubedResource } from '../../types';
import axios from 'axios';
import { generateUrlDetail } from '../../helpers/request-builder';
import generateQueryKey from '../helpers/generate-query-key';

type UsePatchResourceArgs = {
    resource: CubedResource | CubedConfigResource;
    resourceId: string;
    data: { [key: string]: string | number | boolean };
    handleOnSuccess?: () => void;
    handleOnError?: () => void;
};

const usePatchResource = ({ resource, resourceId, data, handleOnSuccess, handleOnError }: UsePatchResourceArgs) => {
    const queryClient = useQueryClient();
    const queryKey = generateQueryKey({ resource });

    const url = generateUrlDetail(resource.category, resource.id, resourceId);

    const mutation = useMutation({
        mutationFn: async () => {
            if (url) {
                return axios({
                    method: 'PATCH',
                    url: url,
                    data: data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .catch(error => {
                        throw new Error(error.response.data.message);
                    })
                    .finally(() => {
                        queryClient.invalidateQueries({
                            queryKey: queryKey,
                        });
                    });
            }
            throw new Error('Error when generating url');
        },
        onSuccess: handleOnSuccess,
        onError: handleOnError,
    });

    return mutation;
};

export default usePatchResource;
