import React from 'react';
import styled from 'styled-components';

// Styles
const StyledPillWrapper = styled.div<{
    isStart: boolean;
    isEnd: boolean;
    isActive: boolean;
    isMore: boolean;
    backgroundColor: string;
    border: string;
}>`
    padding: ${props => (props.isMore ? `0 4px 0 4px` : `3px 12px`)};
    background-color: ${props =>
        props.backgroundColor
            ? props.backgroundColor
            : props.isActive
            ? props.theme.colours.lightGrey
            : props.theme.colours.inputColour};
    display: inline-block;
    margin: 6px 3px 6px 0;
    border: ${props =>
        props.border
            ? props.border
            : `solid 1px ${props.isActive ? props.theme.colours.primary : props.theme.colours.lightGrey}`};
    border-radius: ${props =>
        props.isMore ? `0px` : props.isStart ? `20px 0 0 20px` : props.isEnd ? `0 20px 20px 0` : `3px`};
    vertical-align: bottom;
    height: ${props => props.isMore && `30px`};
`;

const StyledPillText = styled.p<{ isMore: boolean }>`
    font-size: 1em;
    padding: 0 !important;
    margin: 0 !important;
    max-width: 70px;
    color: ${props => props.isMore && `#cccccc`};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: width 2s ease;

    &:hover {
        max-width: unset;
        cursor: default;
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
    }
`;

export type SimplePillProps = {
    isStart: boolean;
    isEnd: boolean;
    isMore: boolean;
    styleOverride: { backgroundColor?: string; border?: string };
    copy: string;
    isActive: boolean;
};

const SimplePill = ({ isStart, isEnd, isMore, styleOverride, copy, isActive }: SimplePillProps) => {
    const { backgroundColor, border } = styleOverride;

    return (
        <StyledPillWrapper
            isStart={isStart}
            isEnd={isEnd}
            isActive={isActive}
            isMore={isMore}
            backgroundColor={backgroundColor!}
            border={border!}
        >
            <StyledPillText isMore={isMore}>{copy}</StyledPillText>
        </StyledPillWrapper>
    );
};

export default SimplePill;
