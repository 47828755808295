import React, { useEffect, useState } from 'react';

// Config
import { CONFIG_CHANNELS, CONFIG_PATTERN } from '../../configurations/resources-config';

// Redux

import { useDispatch } from 'react-redux';
import { setModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';

// Enums & Helpers
import { ChannelColour } from './manage-channels';
import { ButtonThemes } from '../../enums/button-themes';
import { channelColours, colourToInt } from '../../helpers/colours';
import { isAlphaNumericString } from '../../helpers/validator';
import { generatePath } from '../../helpers/request-builder';

// Types
import { NotificationMessageType } from '../../enums/notification-types';

// Hooks
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import usePostResource from '../../react-query/hooks/use-post-resource';
import usePostMultiResources from '../../react-query/hooks/use-post-multi-resources';

// Components
import ModalNavigation from '../../components/modal-navigation';
import WidgetAccordion from '../../widgets/accordion';

type ManageChannelsAddPattern = {
    name: string;
    selected: boolean;
};

const LayoutModalCreateChannel = () => {
    const dispatch = useDispatch();

    // Accordion States
    const [channelDetailsAccordionOpen, setChannelDetailsAccordionOpen] = useState(true);
    const [patternsAccordionVisible, setPatternsAccordionVisible] = useState(false);
    const [patternsAccordionOpen, setPatternsAccordionOpen] = useState(false);

    // Channel States
    const [channelName, setChannelName] = useState('');
    const [channelNameErrorMessage, setChannelNameErrorMessage] = useState<string>();
    const [channelColour, setChannelColour] = useState<ChannelColour>();
    const [channelColourErrorMessage, setChannelColourErrorMessage] = useState<string>();
    const [createdChannelId, setCreatedChannelId] = useState<string>();

    // Pattern States
    const [addedPatterns, setAddedPatterns] = useState<ManageChannelsAddPattern[]>([]);
    const [patternNameToAdd, setPatternNameToAdd] = useState<string>();
    const [patternNameToAddErrorMessage, setPatternNameToAddErrorMessage] = useState<string>();
    const [enablePatternExistsQuery, setEnablePatternExistsQuery] = useState<boolean>(false);
    const [editPatternAccordionVisible, setEditPatternAccordionVisible] = useState<boolean>(false);
    const [patternNameToEdit, setPatternNameToEdit] = useState<string>();
    const [patternNameToEditErrorMessage, setPatternNameToEditErrorMessage] = useState<string>();
    const [enableEditedPatternExistsQuery, setEnableEditedPatternExistsQuery] = useState<boolean>(false);

    // Queries
    const patternExistsQuery = useFetchResource({
        resource: CONFIG_PATTERN,
        params: [
            { key: 'pattern__contains', value: patternNameToAdd ? patternNameToAdd : '' },
            { key: 'active', value: 1 },
            { key: 'referer__active', value: 1 },
        ],
        enabled: enablePatternExistsQuery,
    });

    const editedPatternExistsQuery = useFetchResource({
        resource: CONFIG_PATTERN,
        params: [
            { key: 'pattern__contains', value: patternNameToEdit ? patternNameToEdit : '' },
            { key: 'active', value: 1 },
            { key: 'referer__active', value: 1 },
        ],
        enabled: enableEditedPatternExistsQuery,
    });

    const channelPostMutation = usePostResource({
        resource: CONFIG_CHANNELS,
        data: {
            name: channelName,
            colour: channelColour && colourToInt(channelColour.colour),
        },
        handleOnSuccess: data => {
            setCreatedChannelId(data.id as string);
        },
        handleOnError: () => {
            addNotification({
                copy: 'There was an issue while saving your channel. Please try again later.',
                type: NotificationMessageType.Error,
            });
        },
    });

    const patternPostMutation = usePostMultiResources({
        resource: CONFIG_PATTERN,
        data: addedPatterns.map(pattern => {
            return {
                pattern: pattern.name,
                referer: generatePath('config', 'referer', createdChannelId),
            };
        }),
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'Channel added successfully.',
                    type: NotificationMessageType.Success,
                })
            );
            dispatch(setModal('ManageChannels'));
        },
        handleOnError: () => {
            addNotification({
                copy: 'There was an issue while saving your patterns. Please try again later.',
                type: NotificationMessageType.Error,
            });
        },
    });

    // After saving a new channel, save the patterns
    useEffect(() => {
        if (createdChannelId) {
            patternPostMutation.mutate();
        }
    }, [createdChannelId]); // eslint-disable-line react-hooks/exhaustive-deps

    // Validate New Patterns
    useEffect(() => {
        if (patternNameToAdd && patternNameToAdd.length > 0) {
            if (patternExistsQuery.data && patternExistsQuery.data.objects.length > 0) {
                setPatternNameToAddErrorMessage(
                    'This pattern already exists. Please provide a unique pattern for this channel.'
                );
            } else if (addedPatterns.filter(pattern => pattern.name === patternNameToAdd).length > 0) {
                setPatternNameToAddErrorMessage(
                    'This pattern has already been added below. Please provide a unique pattern for this channel.'
                );
            } else {
                setPatternNameToAddErrorMessage(undefined);
            }
        } else {
            setPatternNameToAddErrorMessage(undefined);
        }
    }, [patternNameToAdd, patternExistsQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    // Validate Edited Pattern
    useEffect(() => {
        if (patternNameToEdit && patternNameToEdit.length > 0) {
            if (editedPatternExistsQuery.data && editedPatternExistsQuery.data.objects.length > 0) {
                setPatternNameToEditErrorMessage(
                    'This pattern already exists. Please provide a unique pattern for this channel.'
                );
            } else if (
                addedPatterns.filter(pattern => !pattern.selected).filter(pattern => pattern.name === patternNameToEdit)
                    .length > 0
            ) {
                setPatternNameToEditErrorMessage(
                    'This pattern has already been added above. Please provide a unique pattern for this channel.'
                );
            } else {
                setPatternNameToEditErrorMessage(undefined);
            }
        } else {
            setPatternNameToEditErrorMessage(undefined);
        }
    }, [patternNameToEdit, editedPatternExistsQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handlers
    const handleChannelNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChannelName(event.target.value);
    };

    const formValidatorChannel = () => {
        let isValid = true;

        if (!isAlphaNumericString(channelName) || channelName.length === 0) {
            isValid = false;
            setChannelNameErrorMessage('Please enter a valid Channel Name.');
        } else {
            setChannelNameErrorMessage(undefined);
        }

        if (!channelColour) {
            isValid = false;
            setChannelColourErrorMessage('Please select a colour for the Channel.');
        } else {
            setChannelColourErrorMessage(undefined);
        }

        return isValid;
    };

    const handleChannelDetailsNextClick = () => {
        if (formValidatorChannel()) {
            setChannelDetailsAccordionOpen(false);
            setPatternsAccordionOpen(true);
            setPatternsAccordionVisible(true);
        }
    };

    const handleAddPatternChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnablePatternExistsQuery(true);
        setPatternNameToAdd(event.target.value);
    };

    const handleAddPatternClick = () => {
        setEnablePatternExistsQuery(false);
        if (patternNameToAdd && patternNameToAdd.length > 0) {
            setAddedPatterns([
                ...addedPatterns,
                {
                    name: patternNameToAdd,
                    selected: false,
                },
            ]);
            setPatternNameToAdd('');
        }
    };

    const handleEditPatternClick = () => {
        setPatternNameToEdit(addedPatterns.filter(pattern => pattern.selected)[0].name);
        setEditPatternAccordionVisible(true);
        setPatternsAccordionOpen(false);
    };

    const handleCancelEditPatternClick = () => {
        setPatternNameToEdit('');
        setEditPatternAccordionVisible(false);
        setPatternsAccordionOpen(true);
    };

    const handleDeletePatternClick = () => {
        const updatedPatterns = addedPatterns.filter(pattern => !pattern.selected);
        setAddedPatterns(updatedPatterns);
        setPatternsAccordionOpen(true);
    };

    const handlePatternSelect = (patternName: string) => {
        const updatedPatterns = addedPatterns.map(pattern => {
            if (pattern.name === patternName) {
                return {
                    name: pattern.name,
                    selected: !pattern.selected,
                };
            }
            return pattern;
        });
        setAddedPatterns(updatedPatterns);
    };

    const handleEditPatternChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnableEditedPatternExistsQuery(true);
        setPatternNameToEdit(event.target.value);
    };

    const handleEditPatternUpdateClick = () => {
        setEnableEditedPatternExistsQuery(false);
        if (patternNameToEdit) {
            const updatedPatterns = addedPatterns.map(pattern => {
                if (pattern.selected) {
                    return {
                        name: patternNameToEdit,
                        selected: true,
                    };
                }
                return pattern;
            });
            setAddedPatterns(updatedPatterns);
        }
        setEditPatternAccordionVisible(false);
        setPatternsAccordionOpen(true);
    };

    const handleSaveClick = () => {
        if (formValidatorChannel()) {
            channelPostMutation.mutate();
        }
    };

    const modalNavigationButtons = [
        {
            value: 'SAVE',
            onClick: handleSaveClick,
            disabled: false,
            isLoading: channelPostMutation.isPending || patternPostMutation.isPending,
        },
        {
            value: 'CLOSE',
            onClick: () => dispatch(setModal('ManageChannels')),
            buttonTheme: ButtonThemes.Secondary,
        },
    ];

    const patternTableRows = addedPatterns.map(pattern => {
        return {
            keyValue: pattern.name,
            dataValue: pattern.name,
            selected: pattern.selected,
            columns: [{ copy: pattern.name }],
            onClick: () => handlePatternSelect(pattern.name),
        };
    });

    const channelAccordion = [
        {
            header: 'Channel Details',
            required: true,
            open: channelDetailsAccordionOpen,
            type: 'form',
            config: {
                formConfig: {
                    fields: [
                        {
                            label: 'Channel Name:',
                            type: 'text',
                            requiredField: true,
                            toolTipCopy: 'Enter the friendly name for this custom channel.',
                            inputKeyValue: 'channel-details__name',
                            inputPlaceholder: 'Channel Name...',
                            inputValue: channelName,
                            inputOnChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                                handleChannelNameChange(event),
                            errorMessage: channelNameErrorMessage,
                        },
                        {
                            label: 'Channel Colour:',
                            type: 'colourPicker',
                            requiredField: true,
                            toolTipCopy: 'Select a colour you would like the channel to use in the Cubed System.',
                            inputKeyValue: 'channel-details__colour',
                            inputSelectedColour: channelColour,
                            inputColours: channelColours,
                            onInputChange: (newColour: ChannelColour) => {
                                setChannelColour(newColour);
                            },
                            errorMessage: channelColourErrorMessage,
                        },
                    ],
                    buttons: [
                        {
                            value: 'NEXT',
                            onClick: handleChannelDetailsNextClick,
                        },
                    ],
                },
            },
        },
    ];

    const patternAccordion = [
        {
            header: 'Patterns',
            required: false,
            open: patternsAccordionOpen,
            type: 'formSimpleTable',
            config: {
                copy: 'Add relevant patterns to identify visits for this channel. Patterns should be unique, and can be configured using wildcards and/or regex',
                formConfig: {
                    fields: [
                        {
                            label: 'Add a Pattern:',
                            type: 'text',
                            requiredField: true,
                            toolTipCopy:
                                'Enter a wildcard or regex pattern that will be used to identify visits for this channel.',
                            inputKeyValue: 'patterns__add-pattern',
                            inputPlaceholder: 'Add a Pattern...',
                            inputValue: patternNameToAdd,
                            inputOnChange: handleAddPatternChange,
                            errorMessage: patternNameToAddErrorMessage,
                        },
                    ],
                    buttons: [
                        {
                            value: patternExistsQuery.isFetching ? 'CHECKING...' : 'ADD',
                            onClick: handleAddPatternClick,
                            disabled:
                                patternExistsQuery.isFetching || patternNameToAddErrorMessage || !patternNameToAdd,
                        },
                    ],
                },
                tableConfig: {
                    header: { columns: [{ title: 'Patterns' }] },
                    rows: patternTableRows,
                    errorMessageOverride: 'Please add a Pattern.',
                    isScrollable: true,
                },

                optionalButtonConfig: {
                    buttons: [
                        {
                            value: 'EDIT',
                            onClick: handleEditPatternClick,
                            disabled: addedPatterns.filter(pattern => pattern.selected).length !== 1,
                        },
                        {
                            value: 'REMOVE',
                            onClick: handleDeletePatternClick,
                            theme: ButtonThemes.Red,
                            // disabled: this.state.patternDeleteDisabled,
                        },
                    ],
                },
            },
        },
    ];

    const editPatternAccordion = [
        {
            header: `Edit Pattern - ${
                addedPatterns.find(pattern => pattern.selected) && addedPatterns.find(pattern => pattern.selected)?.name
            }`,
            required: false,
            open: editPatternAccordionVisible,
            type: 'form',
            config: {
                copy: '',
                formConfig: {
                    fields: [
                        {
                            label: 'Pattern:',
                            type: 'text',
                            requiredField: true,
                            toolTipCopy: 'Enter the query parameter pattern that will be use to identify this Channel.',
                            inputKeyValue: 'edit-pattern__pattern',
                            inputPlaceholder: 'Pattern...',
                            inputValue: patternNameToEdit,
                            inputOnChange: handleEditPatternChange,
                            errorMessage: patternNameToEditErrorMessage,
                        },
                    ],
                    buttons: [
                        {
                            value: editedPatternExistsQuery.isFetching ? 'CHECKING...' : 'UPDATE',
                            disabled:
                                editedPatternExistsQuery.isFetching ||
                                patternNameToEditErrorMessage ||
                                !patternNameToEdit,
                            onClick: handleEditPatternUpdateClick,
                            // isLoading: this.state.editPatternUpdateIsLoading,
                        },
                        {
                            value: 'CANCEL',
                            disabled: false,
                            onClick: handleCancelEditPatternClick,
                            buttonTheme: ButtonThemes.Secondary,
                        },
                    ],
                },
            },
        },
    ];

    return (
        <div>
            <ModalNavigation buttons={modalNavigationButtons} />
            <h2>Create Channel</h2>
            <p>Create a new channel with patterns.</p>
            <WidgetAccordion accordions={channelAccordion} />
            {patternsAccordionVisible && <WidgetAccordion accordions={patternAccordion} />}
            {editPatternAccordionVisible && <WidgetAccordion accordions={editPatternAccordion} />}
        </div>
    );
};

export default LayoutModalCreateChannel;
