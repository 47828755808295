// Helpers
import { unknownErrorData } from '../../../helpers/errors';
import { vennChartDataSeries } from '../../../helpers/create-data-series';
import { alternativeGraphColours } from '../../../helpers/graph-colours';

// Types
import { CubedField } from '../../../../types';
import { ResourceComparisonData, VennComparisonData } from '../../../types';

export type UseResourceVennComparisonArgs = {
    resourceData: ResourceComparisonData;
    metric: CubedField;
    diagramTitle: string;
};

const useResourceVennComparison = ({
    resourceData,
    metric,
    diagramTitle,
}: UseResourceVennComparisonArgs): VennComparisonData => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        const dimensionResourceData = resourceData.resources[0];
        const vennResourceData = resourceData.resources[1];
        const vennResourceComparisonData = resourceData.resources[2];

        let data =
            vennResourceData.status === 'success'
                ? vennChartDataSeries(dimensionResourceData, vennResourceData, metric)
                : [];

        let comparisonData =
            vennResourceComparisonData.status === 'success'
                ? vennChartDataSeries(dimensionResourceData, vennResourceComparisonData, metric)
                : [];

        // Match colours between this period and comparison period
        comparisonData = comparisonData.map((comparisonItem, index) => {
            const matchingSeries = data.find(
                item =>
                    item.sets.length === comparisonItem.sets.length &&
                    item.sets.every(function (element, index) {
                        return element === comparisonItem.sets[index];
                    })
            );

            if (matchingSeries) {
                return {
                    ...comparisonItem,
                    color: matchingSeries.color,
                    themeColour: matchingSeries.themeColour,
                };
            } else {
                return {
                    ...comparisonItem,
                    color: alternativeGraphColours[index % alternativeGraphColours.length].solidGraphColour,
                    themeColour: alternativeGraphColours[index % alternativeGraphColours.length],
                };
            }
        });

        const vennData: VennComparisonData = {
            type: 'vennComparison',
            status: 'success',
            series: {
                type: 'venn',
                name: diagramTitle,
                data: data,
            },
            comparisonSeries: {
                type: 'venn',
                name: diagramTitle,
                data: comparisonData,
            },
            title: {
                text: diagramTitle,
            },
        };

        return vennData;
    }

    return unknownErrorData();
};

export default useResourceVennComparison;
