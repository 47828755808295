import React from 'react';
import { useSectionDashboard } from '../../context/section-dashboard-context';
import styled from 'styled-components';
import { WidgetColour, WidgetTheme } from '../../types';
import SectionDashboardWidgetContextProvider, {
    useSectionDashboardWidget,
} from '../../context/section-dashboard-widget-context';
import IconExternalLink from '../../../components/icons/external-link';

export type TextPosition = 'start' | 'end' | 'center';

type TextBoxAppearanceProps = {
    textJustify?: TextPosition;
    fontSize?: string;
};

type StyledContainerProps = {
    gridArea: string;
    widgetTheme: WidgetTheme;
    colour: WidgetColour;
};

const StyledContainer = styled.div<StyledContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    grid-area: ${props => props.gridArea};
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};
    background: linear-gradient(
        180deg,
        ${props => props.widgetTheme.gradientStart} 0%,
        ${props => props.widgetTheme.gradientEnd} 100%
    );
    border: 1px solid ${props => props.theme.sectionDashboard.widget.base.borderColor};
    border-radius: 6px;
    overflow-y: auto;
    line-height: 1.5;
    padding: 20px;
    color: ${props =>
        props.colour === WidgetColour.Default
            ? props.theme.sectionDashboard.widget.textBox.textColorDefault
            : props.theme.sectionDashboard.widget.textBox.textColor};
`;

const StyledCopyContainer = styled.div<TextBoxAppearanceProps>`
    display: flex;
    justify-content: ${props => props.textJustify};
    font-size: ${props => props.fontSize || '1rem'};
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
    border: none;
    border-radius: 3px;
    background-color: ${props => props.theme.colours.lightGrey};
    color: ${props => props.theme.colours.offBlackLighter};
    padding: 5px 10px;
    font-size: 0.9rem;
    font-weight: 600;
    white-space: nowrap;
`;

const StyledIconExternalLink = styled(IconExternalLink)`
    width: 12px;
    position: relative;
    top: -1px;
`;

type WidgetTextBoxContentProps = TextBoxAppearanceProps & {
    colour: WidgetColour;
    copy: string;
    buttonCopy?: string;
    buttonHref?: string;
};

const WidgetTextBoxContent = ({
    colour,
    copy,
    buttonCopy,
    buttonHref,
    textJustify,
    fontSize,
}: WidgetTextBoxContentProps) => {
    const { area } = useSectionDashboard();
    const { theme } = useSectionDashboardWidget();

    const handleButtonClick = () => {
        window.open(buttonHref, '_blank');
    };

    return (
        <>
            <StyledContainer gridArea={area} widgetTheme={theme} colour={colour}>
                <StyledCopyContainer textJustify={textJustify} fontSize={fontSize}>
                    {copy}
                </StyledCopyContainer>
                {buttonCopy && buttonHref && (
                    <StyledButton onClick={handleButtonClick}>
                        {buttonCopy}
                        <StyledIconExternalLink />
                    </StyledButton>
                )}
            </StyledContainer>
        </>
    );
};

export type WidgetTextBoxProps = WidgetTextBoxContentProps & {
    colour?: WidgetColour;
};

const WidgetTextBox = ({
    copy,
    buttonCopy,
    buttonHref,
    colour = WidgetColour.Default,
    textJustify = 'start',
    fontSize,
}: WidgetTextBoxProps) => {
    return (
        <SectionDashboardWidgetContextProvider colour={colour}>
            <WidgetTextBoxContent
                colour={colour}
                copy={copy}
                buttonCopy={buttonCopy}
                buttonHref={buttonHref}
                textJustify={textJustify}
                fontSize={fontSize}
            />
        </SectionDashboardWidgetContextProvider>
    );
};

export default WidgetTextBox;
