import React from 'react';
import Form from '../../../components/forms/form';
import validators from '../../../components/forms/validation/validators';
import { FieldValues } from 'react-hook-form';
import Popover from '../../components/popover/popover';
import { SectionConfig } from '../../types';

const FormSection = ({
    sections,
    sectionId,
    submitValue,
    onSubmit,
}: {
    sections: SectionConfig[];
    sectionId?: string;
    submitValue: string;
    onSubmit: (data: FieldValues) => void;
}) => {
    const defaultValues = sectionId
        ? { sectionName: sections.find(section => section.sectionId === sectionId)?.sectionName }
        : {};

    return (
        <Form onSubmit={onSubmit} defaultValues={defaultValues}>
            <Form.Body>
                <Form.Field>
                    <Form.InputLabel htmlFor="sectionName" label="Section Name" />
                    <Form.InputText
                        name="sectionName"
                        placeholder="Enter Section Name"
                        validators={[
                            validators.required,
                            validators.maxLength(100),
                            ...[
                                {
                                    validate: (value: string) => {
                                        return (
                                            !sections.map(section => section.sectionName).includes(value) ||
                                            'This section name already exists'
                                        );
                                    },
                                },
                            ],
                        ]}
                    />
                </Form.Field>
            </Form.Body>
            <Form.Footer>
                <Popover.Close asChild>
                    <Form.InputButton type="submit" value={submitValue} />
                </Popover.Close>
            </Form.Footer>
        </Form>
    );
};

export default FormSection;
