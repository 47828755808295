import { DashboardBuilderDashboard, DashboardBuilderDashboardConfig, DashboardBuilderSection } from '../types';
import { WidgetConfig } from '../../types';
import { DatePickerType } from '../../../configurations/enums/date-picker-type';
import getWidgetPreview from '../../widgets/helpers/get-widget-preview';

const generateDashboardConfig = ({
    isComparison,
    dashboardDetails,
    widgets,
    sections,
}: {
    isComparison: boolean;
    dashboardDetails: DashboardBuilderDashboard;
    widgets: WidgetConfig[];
    sections: DashboardBuilderSection[];
}): DashboardBuilderDashboardConfig => {
    return {
        title: dashboardDetails.title,
        description: dashboardDetails.desription,
        filters: {
            status: {
                isEnableDatePicker: true,
                isEnableComparisonDatePicker: dashboardDetails.comparison_enabled,
                isEnableProductSelect: false,
                isEnableMetrics: false,
                isEnableButtonGroup: false,
                isEnablePdfExport: true,
                isEnableChannelMultiSelect: false,
                isEnableChannelSingleSelect: {
                    enabled: false,
                },
                isDashboardBuilder: true,
            },
            comparisonStatus: {
                showComparisonDatePicker: isComparison,
                redirectUrl: isComparison
                    ? `dashboard-builder/dashboard/${dashboardDetails.id}`
                    : `dashboard-builder/dashboard-comparison/${dashboardDetails.id}`,
            },
            datePicker: {},
            ...(isComparison && {
                comparisonDatePicker: {
                    datePickerType: DatePickerType.Comparison,
                },
            }),
        },
        layouts: {
            xs: {
                sections: sections.map(section => {
                    return {
                        title: section.section.title,
                        collapsed: section.section.default_collapsed,
                        grid: section.grid_xs.replaceAll(',', '\n'),
                    };
                }),
            },
            sm: {
                sections: sections.map(section => {
                    return {
                        title: section.section.title,
                        collapsed: section.section.default_collapsed,
                        grid: section.grid_sm.replaceAll(',', '\n'),
                    };
                }),
            },
            md: {
                sections: sections.map(section => {
                    return {
                        title: section.section.title,
                        collapsed: section.section.default_collapsed,
                        grid: section.grid_md.replaceAll(',', '\n'),
                    };
                }),
            },
            lg: {
                sections: sections.map(section => {
                    return {
                        title: section.section.title,
                        collapsed: section.section.default_collapsed,
                        grid: section.grid_lg.replaceAll(',', '\n'),
                    };
                }),
            },
            xl: {
                sections: sections.map(section => {
                    return {
                        title: section.section.title,
                        collapsed: section.section.default_collapsed,
                        grid: section.grid_xl.replaceAll(',', '\n'),
                    };
                }),
            },
        },
        components: () => {
            const renderWidget = (widget: WidgetConfig) => {
                return getWidgetPreview({
                    type: widget.widget.type.name,
                    config: widget,
                    showComparison: isComparison,
                });
            };

            const sectionWidget = widgets.reduce(
                (acc, widget) => ({
                    ...acc,
                    [`widget${widget.widget.id}`]: renderWidget(widget),
                }),
                {}
            );
            return sectionWidget;
        },
    };
};

export default generateDashboardConfig;
