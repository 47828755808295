import React from 'react';
import { WidgetConfig } from '../../types';
import getResource from '../../helpers/get-resource';
import getField from '../../helpers/get-field';
import useResource from '../../../section-dashboard/hooks/widget/use-resource';

import CreateAWidgetPreviewMessage from '../../create-a-widget/components/create-a-widget-preview-message';
import useResourceBigNumber from '../../../section-dashboard/hooks/widget/big-number/use-resource-big-number';
import WidgetBigNumber from '../../../section-dashboard/widgets/widget/widget-big-number';

import { WidgetColour } from '../../../section-dashboard/types';

const WidgetPreviewBigNumber = ({ config }: { config: WidgetConfig }) => {
    // Get the request values from the widget
    const resource = getResource(config.resource);
    const graphMetricField = config.graph_metrics && getField(config.graph_metrics);
    const widgetColor = config.widget_color;

    const resourceData = useResource({
        resource: resource,
        request: {
            isGraph: true,
            orderBy: resource ? [{ field: resource?.dateDimension, orderByDirection: 'asc' }] : [],
            groupBy: resource?.dateDimension,
            graphGroupBy: resource ? [resource?.dateDimension] : [],
            graphMetrics: graphMetricField ? [graphMetricField] : [],
            enabled: !!resource,
        },
    });

    const bigNumberData = useResourceBigNumber({
        resourceData: resourceData,
        field: graphMetricField || undefined,
        aggregation: config.aggregation || 'avg',
        formatting: config.formatting,
        ...(config.rounding_decimals && {
            rounding: {
                applyRounding: true,
                decimalPoints: config.rounding_decimals,
            },
        }),
    });

    if (config.graph_metrics && config.graph_metrics.length > 0) {
        return (
            <WidgetBigNumber
                title={config.widget.title}
                data={bigNumberData}
                colour={WidgetColour[widgetColor as keyof typeof WidgetColour]}
            />
        );
    }

    return <CreateAWidgetPreviewMessage message="Please select a metric to preview the big number widget" />;
};

export default WidgetPreviewBigNumber;
