import React, { createContext, useContext } from 'react';
import useWidgetTheme from '../hooks/base/use-widget-theme';

import { WidgetTheme, WidgetColour } from '../types';

export type AppContextInterface = {
    colour: WidgetColour;
    theme: WidgetTheme;
};

export const SectionDashboardWidgetContext = createContext<AppContextInterface>({} as AppContextInterface);

export const useSectionDashboardWidget = () => {
    return useContext(SectionDashboardWidgetContext);
};

type SectionDashboardWidgetContextProviderProps = {
    colour: WidgetColour;
    children: JSX.Element;
};

const SectionDashboardWidgetContextProvider = ({ colour, children }: SectionDashboardWidgetContextProviderProps) => {
    const theme = useWidgetTheme(colour);
    const value = { colour, theme };
    return <SectionDashboardWidgetContext.Provider value={value}>{children}</SectionDashboardWidgetContext.Provider>;
};

export default SectionDashboardWidgetContextProvider;
