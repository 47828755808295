import store from '../../../redux/store';
import { SimpleTableRow } from '../types';

export const getTotal = (rows: SimpleTableRow[], column: string): number | string => {
    const currencySymbol = store.getState().currency.symbol;
    // Calculates the total of specified column of the rows.
    if (typeof column !== 'string') {
        throw new Error('Column name must be a valid string');
    } else if (column !== 'forSales' && column !== 'forRevenue') {
        throw new Error(column + "isn't a valid column");
    }

    let totalsValue: number | string = 0;
    let totals = 0;

    if (column === 'forSales') {
        rows.forEach(row => {
            totals += row.columns[1].rawValue as number;
        });
        totalsValue = totals;
    } else if (column === 'forRevenue') {
        rows.forEach(row => {
            totals += row.columns[2].rawValue as number;
        });

        // Adding comma, currency string, and making the number always have 2 decimal places (Example: £234,567.89)
        totalsValue =
            currencySymbol +
            new Intl.NumberFormat('en-UK', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(totals);
    }

    return totalsValue;
};
