import React from 'react';
import styled, { css } from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

// Types
import { DropdownOption, RequestStatus, SelectOptionsRequest } from '../../types';
import IconCheck from '../../../components/icons/check';
import IconChevronUp from '../../../components/icons/chevron-up';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    & button {
        all: inherit;
        cursor: 'pointer';
    }
`;

const StyledLabel = styled.div`
    font-size: 0.9rem;
    font-weight: ${props => props.theme.sectionDashboard.section.headingFontWeight};
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${props => props.theme.sectionDashboard.section.headingTextColor};
    text-transform: uppercase;
`;

const StyledTriggerContent = styled.div<{ empty?: boolean }>`
    border: 2px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    border-radius: 5px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    min-width: 150px;
    font-weight: ${props => props.theme.sectionDashboard.widget.menu.fontWeight};
    font-size: 0.9rem;
    cursor: ${props => (props.empty ? 'auto' : 'pointer')};
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundColor};
`;

const StyledOptionLabel = styled.div<{ empty?: boolean }>`
    font-size: 1rem;
    padding: 5px 10px;
    color: ${props =>
        props.empty
            ? props.theme.sectionDashboard.widget.menu.textColorDisabled
            : props.theme.sectionDashboard.widget.menu.textColor};
`;

const StyledIconContainer = styled.div`
    padding: 0px 10px;
    height: 100%;
    border-left: 1px solid ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
`;

const StyledIconChevronSmall = styled(IconChevronUp)<{ empty?: boolean }>`
    transform: rotate(180deg);
    height: 14px;
    margin-top: 8px;
    width: auto;
    & path {
        fill: ${props =>
            props.empty
                ? props.theme.sectionDashboard.widget.menu.separatorColor
                : props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledContentBase = css`
    background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundColor};
    color: ${props => props.theme.sectionDashboard.widget.menu.textColor};
    min-width: 210px;
    padding: 10px 0px;
    border-radius: 6px;
    box-shadow: ${props => props.theme.sectionDashboard.widget.menu.shadow};
    max-height: 50vh;
    overflow-y: auto;
`;

const StyledContent = styled(DropdownMenu.Content)`
    ${StyledContentBase}
    z-index: 21; // Overlap the top bar
`;

const StyledDropdownItem = styled(DropdownMenu.Item)`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px 10px 15px;
    cursor: pointer;
    word-break: break-all;

    &:hover {
        background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
        border: none;
        outline: none;
    }
`;

const StyledIconCheck = styled(IconCheck)<{ visible: boolean }>`
    width: 18px;
    visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const StyledLoadingContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const StyledDropdownSkeleton = styled.div`
    height: 19px;
    width: 150px;
    background: ${props => props.theme.sectionDashboard.widget.menu.skeletonColor};
    border-radius: 5px;
`;

export type SectionDashboardMenuSelectProps = {
    label: string;
    dropdownOptions: SelectOptionsRequest;
    selectedOption?: DropdownOption;
    onOptionChange: (option: string) => void;
    emptyMessage: string;
    menuContentAlign?: 'start' | 'end';
};

const SectionDashboardMenuSelect = ({
    label,
    dropdownOptions,
    selectedOption,
    onOptionChange,
    emptyMessage = 'No items found',
    menuContentAlign = 'end',
}: SectionDashboardMenuSelectProps) => {
    const renderTrigger = ({ isEmpty }: { isEmpty: boolean }) => {
        return (
            <>
                <StyledLabel>{label}:</StyledLabel>
                <DropdownMenu.Trigger id="select-dashboard-menu">
                    <StyledTriggerContent empty={isEmpty}>
                        <StyledOptionLabel empty={isEmpty}>
                            {selectedOption ? selectedOption.label : emptyMessage}
                        </StyledOptionLabel>
                        <StyledIconContainer>
                            <StyledIconChevronSmall empty={isEmpty} />
                        </StyledIconContainer>
                    </StyledTriggerContent>
                </DropdownMenu.Trigger>
            </>
        );
    };

    if (dropdownOptions.status === RequestStatus.LOADING) {
        return (
            <StyledLoadingContainer>
                <StyledLabel>{label}:</StyledLabel>
                <StyledDropdownSkeleton />
            </StyledLoadingContainer>
        );
    }

    if (dropdownOptions.status === RequestStatus.EMPTY || dropdownOptions.status === RequestStatus.ERROR) {
        return (
            <StyledLoadingContainer>
                <StyledContainer>
                    <DropdownMenu.Root>{renderTrigger({ isEmpty: true })}</DropdownMenu.Root>
                </StyledContainer>
            </StyledLoadingContainer>
        );
    }

    if (dropdownOptions.status === RequestStatus.SUCCESS && selectedOption) {
        return (
            <StyledContainer>
                <DropdownMenu.Root>
                    {renderTrigger({ isEmpty: false })}

                    <DropdownMenu.Portal>
                        <StyledContent side="bottom" align={menuContentAlign}>
                            {dropdownOptions.options.map(option => {
                                return (
                                    <StyledDropdownItem onClick={() => onOptionChange(option.value)} key={option.value}>
                                        <StyledIconCheck visible={option.value === selectedOption.value} />
                                        {option.label}
                                    </StyledDropdownItem>
                                );
                            })}
                        </StyledContent>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            </StyledContainer>
        );
    }
    return null;
};

export default SectionDashboardMenuSelect;
