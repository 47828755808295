import { createGlobalStyle } from 'styled-components';

const ResetSectionDashboardStyles = createGlobalStyle`
body {
    background-color:  ${({ theme }) =>
        theme.name === 'dark' ? '#1c1c1c' : props => props.theme.colours.offLight} !important;

}   
`;

export default ResetSectionDashboardStyles;
