export const findButtonStates = (selectedItems, deletedItems, updatedItems, showEditItems) => {
    let containsDeleted = false;
    let containsNonDeleted = false;
    let containsEdited = false;

    selectedItems.forEach(condition => {
        if (deletedItems.includes(condition)) {
            containsDeleted = true;
        } else {
            containsNonDeleted = true;
        }

        if (updatedItems.includes(condition)) {
            containsEdited = true;
        }
    });

    // change the status of the action buttons depending on selected conditions
    let editButtonDisabled = true;
    let deleteButtonDisabled = true;
    let deleteButtonUndo = false;
    let newButtonDisabled = false;

    if (selectedItems.length > 0) {
        editButtonDisabled = false;
        deleteButtonDisabled = false;
    }

    if (selectedItems.length > 1) {
        editButtonDisabled = true;
        deleteButtonDisabled = false;
    }

    if (containsDeleted && !containsNonDeleted && !containsEdited) {
        editButtonDisabled = true;
        deleteButtonDisabled = false;
        deleteButtonUndo = true;
    }

    if (containsDeleted) {
        editButtonDisabled = true;
    }

    if (containsDeleted && containsNonDeleted) {
        deleteButtonDisabled = true;
        deleteButtonUndo = false;
    }

    if (showEditItems) {
        editButtonDisabled = true;
        deleteButtonDisabled = true;
        newButtonDisabled = true;
    }

    if (updatedItems.length > 0 || deletedItems.length > 0) {
        newButtonDisabled = true;
    }

    return {
        editButtonDisabled: editButtonDisabled,
        deleteButtonDisabled: deleteButtonDisabled,
        deleteButtonUndo: deleteButtonUndo,
        newButtonDisabled: newButtonDisabled,
    };
};
