import { isDate, isInteger, isNumber } from '../../helpers/validator';
import { MetricsFilter, Operator, OperatorResponse } from '../types';

export const isAnyDataEmptyCheck = (filter: MetricsFilter) => {
    if (Object.keys(filter.metric).length === 0 || Object.keys(filter.operator).length === 0 || filter.value === '') {
        return true;
    } else {
        return false;
    }
};

export const mapTransitionStyles = (styles: { translate: number; opacity: number }) => {
    if (styles.translate === 0) {
        return {
            opacity: styles.opacity,
        };
    } else {
        return {
            opacity: styles.opacity,
            transform: `translateX(${styles.translate}vw)`,
        };
    }
};

export const getOperatorOptions = (
    operatorData: OperatorResponse[],
    dataType: number,
    existingOperatorsForFilter: string[]
) => {
    let operators: Operator[] = [];

    operatorData.forEach((dataTypeResponse: OperatorResponse) => {
        let operator;
        if (
            dataTypeResponse.function_data_type.id === dataType &&
            !existingOperatorsForFilter.includes(dataTypeResponse.function_operator.display_name)
        ) {
            operator = {
                ...dataTypeResponse.function_operator,
                value: dataTypeResponse.function_operator.id,
                label: dataTypeResponse.function_operator.display_name,
            };
        }

        if (operator !== undefined) {
            operators.push(operator);
        }
    });

    return [...operators];
};

export const validateFilterData = (filterData: MetricsFilter) => {
    const { value, metricType } = filterData;

    if (value === '') {
        return false;
    }

    switch (metricType) {
        case 1: // date
            return isDate(value);
        case 4: // number
            return isInteger(value);
        case 5: // float
            return isNumber(value);
        case 8: // money
            return isNumber(value);
        default:
            return true;
    }
};
