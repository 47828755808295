import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { sparkConfig } from '../configurations/charts/spark-chart-config.js';
import numeral from 'numeral';
import moment from 'moment';

import styled from 'styled-components';
import { chartReflow } from '../helpers/chart-reflow.js';
import { ChartDateGranularity } from '../configurations/common/chart-types.js';

const StyledSparkLine = styled.div<{ colour: string; hideTotals?: boolean }>`
    ${props => props.theme.boxShadow(1)}
    flex: 1 1 0;
    min-width: 0;
    margin: 6px;
    position: relative;
    background-color: ${props => props.colour};
    height: ${props => !props.hideTotals && '100%'};
`;

const StyledTitle = styled.div<{ colour: string }>`
    margin: 12px;
    width: calc(100% - 24px);
    color: ${props => props.theme.colours.white};
    text-align: left;
    background-color: ${props => props.colour};
`;

const StyledLineHolder = styled.div`
    position: relative;
`;

const StyledHighchartsWrapper = styled.div`
    width: 100%;
    height: 10vh;
    margin: 0 auto;
`;

const StyledLoading = styled.div<{ isLoading: boolean; colour: string; transition?: string }>`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: ${props => (props.isLoading ? 0.5 : 0)};
    ${props => props.transition};
    visibility: ${props => (props.isLoading ? 'visible' : 'hidden')};
    background-color: ${props => props.colour};
`;

const StyledMessageBox = styled.div`
    padding: 12px;
    background-color: ${props => props.theme.colours.white};
    overflow: hidden;
`;

const StyledNoData = styled.div`
    width: 100%;
    font-size: 1.2vw;
    text-align: center;
    padding-bottom: 6px 0;
`;

const StyledItem = styled.div`
    float: left;
    position: relative;
    width: 50%;
`;

const StyledValue = styled.div`
    text-align: center;
    width: 100%;
    font-size: 1.1vw;
`;

const StyledLabel = styled.div`
    text-align: center;
    line-height: 12px;
    min-height: 12px;
    font-size: 12px;
`;

export type TooltipFormatter = Highcharts.TooltipFormatterContextObject & {
    series: {
        options: {
            dimension: string;
        };
    };
    key: number;
};

export type SparkLineProps = {
    id: string;
    loading: boolean;
    noData: boolean;
    colour: string;
    periodName: string;
    chartDateGranularity: string;
    dimension: string;
    data: number[][];
    fullTotal: number;
    lastTotal: number;
    hideTotals?: boolean;
    hideUTCDateTime?: boolean;
};

const SparkLine = ({
    id,
    loading,
    noData,
    colour = '#58b5c4',
    periodName,
    chartDateGranularity,
    dimension,
    data,
    fullTotal,
    lastTotal,
    hideTotals,
    hideUTCDateTime = true,
}: SparkLineProps) => {
    useEffect(() => {
        chartReflow();
    });

    const tooltipFormatter = () => {
        return function (this: TooltipFormatter) {
            let s = [];
            let formattedPoint = this.series.options.dimension + ': ' + numeral(this.y).format('0,0');
            let dateFormatter =
                chartDateGranularity === ChartDateGranularity.Hour
                    ? 'dddd, MMM DD, HH:mm'
                    : chartDateGranularity === ChartDateGranularity.Minute
                    ? 'dddd, MMM DD, HH:mm:ss'
                    : 'dddd, MMM DD, YYYY';
            s.push(
                `<span><b>${moment
                    .unix(this.key / 1000)
                    .utc()
                    .format(dateFormatter)}</b><br/>${formattedPoint}</span>`
            );
            return s;
        };
    };

    return (
        <StyledSparkLine data-testid={id} colour={colour} hideTotals={hideTotals}>
            <StyledTitle colour={colour}>{`${dimension} by ${periodName}`}</StyledTitle>
            <StyledLineHolder>
                <StyledHighchartsWrapper>
                    <StyledLoading isLoading={loading} colour={colour} />
                    <HighchartsReact
                        containerProps={{ style: { height: '100%' } }}
                        constructorType={'chart'}
                        highcharts={Highcharts}
                        options={{
                            ...sparkConfig,
                            tooltip: { formatter: tooltipFormatter() },
                            series: [
                                {
                                    dimension: dimension,
                                    data: data,
                                    color: 'white',
                                },
                            ],
                            redraw: true,
                            chart: { ...sparkConfig.chart, backgroundColor: colour },
                        }}
                    />
                </StyledHighchartsWrapper>
            </StyledLineHolder>
            {!hideTotals && (
                <StyledMessageBox>
                    {loading || noData ? (
                        <StyledNoData>{loading ? 'Loading...' : 'No data available.'}</StyledNoData>
                    ) : (
                        [
                            <StyledItem key={id + 'total'}>
                                <StyledValue data-testid="sparkline-value">{fullTotal}</StyledValue>
                                <StyledLabel data-testid="sparkline-label">
                                    {chartDateGranularity === ChartDateGranularity.Hour
                                        ? 'Total for selected date range'
                                        : `Total for date range (${data.length} ${periodName}${
                                              data.length > 1 ? 's' : ''
                                          })`}
                                </StyledLabel>
                            </StyledItem>,
                            <StyledItem key={id + 'single'}>
                                <StyledValue data-testid="sparkline-value">{lastTotal}</StyledValue>
                                <StyledLabel data-testid="sparkline-label">
                                    {chartDateGranularity === ChartDateGranularity.Hour
                                        ? 'Most recent value'
                                        : `Most recent ${periodName}'s total`}
                                </StyledLabel>
                            </StyledItem>,
                        ]
                    )}
                </StyledMessageBox>
            )}
            {!hideUTCDateTime && (
                <StyledMessageBox>
                    <StyledLabel data-testid="sparkline-label">{'Date is displayed in UTC timezone'}</StyledLabel>
                </StyledMessageBox>
            )}
        </StyledSparkLine>
    );
};

export default SparkLine;
