// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import {
    isAlphaNumericString,
    isAlphaString,
    isUrl,
    isAlphaNumericSpecialCharacterString,
} from '../../helpers/validator';
import { generateUrl, generatePath } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Import Widgets
import WidgetAccordion from '../../widgets/accordion';

// Import Components
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

// Import Remove Duplicates
import { removeDuplicates } from '../../helpers/utils';

class LayoutModalMagento extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            events: [],
            selectedEvents: [],
            magentoConnectionAccordionOpen: true,
            magentoConfigAccordionOpen: false,
            serverUrl: '',
            storeCode: '',
            dbStoreCode: '',
            storeCodeOptions: [],
            saleEventProductEventOptions: [],
            currencyCode: '',
            saleEvent: '',
            consumerKey: '',
            consumerSecret: '',
            accessToken: '',
            accessTokenSecret: '',
            magentoDetailsResponseErrorMessage: '',
            serverUrlResponseErrorMessage: '',
            bufferingMessage: '',
            isStoreCodeResponseError: false,
            isServerUrlResponseError: false,
            isMagentoDetailsResponseError: false,
            manStoreCode: '',
            isBuffering: false,
            consumerKeyErrorMessage: '',
            consumerSecretErrorMessage: '',
            accessTokenErrorMessage: '',
            accessTokenSecretErrorMessage: '',
            serverUrlErrorMessage: '',
            storeCodeErrorMessage: '',
            manStoreCodeErrorMessage: '',
            currencyCodeErrorMessage: '',
            saleEventErrorMessage: '',
        };
        this.timeout = 0;
    }

    componentDidMount() {
        const config = {
            resourceGroup: 'config',
            resourceName: 'product-event',
            resourceParams: [
                {
                    key: 'product__product_type__id',
                    value: 2,
                },
                {
                    key: 'active',
                    value: 1,
                },
                {
                    key: 'sale',
                    value: 1,
                },
            ],
        };
        this.props.getRequest(config);
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                // Manipulate the ProductEvent to work with a select field
                let productOptions = this.props.request.data.objects
                    .filter(object => object.product.product_type.hidden_config === false)
                    .map(productEvent => {
                        return {
                            keyValue: `product-event-option_${productEvent.event.name.replace(/ /g, '')}`,
                            value: productEvent.event.name,
                            name: productEvent.event.name,
                        };
                    });

                productOptions = removeDuplicates(productOptions, 'name');

                this.setState({
                    isLoading: false,
                    saleEventProductEventOptions: productOptions,
                });
            } else {
                this.setState({
                    pageError: true,
                    isLoading: false,
                });
            }

            this.props.delRequest();
        }

        // Checks to see if a change has been made and if so switch the button to being
        if (this.state.closeButtonState === 'close') {
            if (
                this.state.serverUrl !== '' ||
                this.state.manStoreCode !== '' ||
                this.state.currencyCode !== '' ||
                this.state.saleEvent !== '' ||
                this.state.consumerKey !== '' ||
                this.state.consumerSecret !== '' ||
                this.state.accessToken !== '' ||
                this.state.accessTokenSecret !== ''
            ) {
                this.setState({
                    closeButtonState: 'cancel',
                    saveChangesButtonDisabled: false,
                });
            }
        }
    }

    handleNavigateMagentoKeyManageModal = () => {
        this.props.setModal('ManageMagento', {});
    };

    // CLOSE - CANCEL click handler
    onCloseClick = () => {
        if (this.state.closeButtonState === 'close') {
            this.handleNavigateMagentoKeyManageModal();
            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD MAGENTO KEY',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        style: 'secondary',
                    },
                ],
            });
        }
    };

    // Checks if the form fields have valid details
    magentoConnectionformValidator = () => {
        this.setState({
            consumerKeyErrorMessage: '',
            consumerSecretErrorMessage: '',
            accessTokenErrorMessage: '',
            accessTokenSecretErrorMessage: '',
        });
        let hasFormError = false;
        let errorMessageObject = {
            consumerKeyErrorMessage: '',
            consumerSecretErrorMessage: '',
            accessTokenErrorMessage: '',
            accessTokenSecretErrorMessage: '',
        };

        // Validate and ensure consumerKey
        if (!isAlphaNumericSpecialCharacterString(this.state.consumerKey) || this.state.consumerKey.length === 0) {
            hasFormError = true;
            errorMessageObject.consumerKeyErrorMessage = 'Please enter a valid Consumer Key.';
        }

        // Validate and ensure consumerSecret
        if (
            !isAlphaNumericSpecialCharacterString(this.state.consumerSecret) ||
            this.state.consumerSecret.length === 0
        ) {
            hasFormError = true;
            errorMessageObject.consumerSecretErrorMessage = 'Please enter a valid Consumer Secret.';
        }

        // Validate and ensure accessToken
        if (!isAlphaNumericSpecialCharacterString(this.state.accessToken) || this.state.accessToken.length === 0) {
            hasFormError = true;
            errorMessageObject.accessTokenErrorMessage = 'Please enter a valid Access Token.';
        }

        // Validate and ensure accessTokenSecret
        if (
            !isAlphaNumericSpecialCharacterString(this.state.accessTokenSecret) ||
            this.state.accessTokenSecret.length === 0
        ) {
            hasFormError = true;
            errorMessageObject.accessTokenSecretErrorMessage = 'Please enter a valid Access Token Secret.';
        }

        if (hasFormError) {
            this.setState({
                consumerKeyErrorMessage: errorMessageObject.consumerKeyErrorMessage,
                consumerSecretErrorMessage: errorMessageObject.consumerSecretErrorMessage,
                accessTokenErrorMessage: errorMessageObject.accessTokenErrorMessage,
                accessTokenSecretErrorMessage: errorMessageObject.accessTokenSecretErrorMessage,
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    magentoConfigformValidator = () => {
        this.setState({
            serverUrlErrorMessage: '',
            manStoreCodeErrorMessage: '',
            currencyCodeErrorMessage: '',
            saleEventErrorMessage: '',
        });
        let hasFormError = false;
        let errorMessageObject = {
            serverUrlErrorMessage: '',
            consumerKeyErrorMessage: '',
            manStoreCodeErrorMessage: '',
            currencyCodeErrorMessage: '',
            saleEventErrorMessage: '',
        };

        // Validate and ensure serverUrl and check that it is populated
        if (!isUrl(this.state.serverUrl) || this.state.serverUrl.length === 0) {
            hasFormError = true;
            errorMessageObject.serverUrlErrorMessage = 'Please enter a valid Server URL.';
        }

        // Validate and ensure storeCode and check that it is populated
        if (!isAlphaNumericSpecialCharacterString(this.state.manStoreCode)) {
            hasFormError = true;
            errorMessageObject.manStoreCodeErrorMessage = 'Please enter a valid Store Code.';
        }

        // Validate and ensure currency code
        if (!isAlphaString(this.state.currencyCode) || this.state.currencyCode.length === 0) {
            hasFormError = true;
            errorMessageObject.currencyCodeErrorMessage = 'Please enter a valid Currency Code.';
        }

        // Validate and ensure sale-event
        if (!isAlphaNumericString(this.state.saleEvent) || this.state.saleEvent.length === 0) {
            hasFormError = true;
            errorMessageObject.saleEventErrorMessage = 'Please select a Sale Event';
        }

        if (hasFormError) {
            this.setState({
                serverUrlErrorMessage: errorMessageObject.serverUrlErrorMessage,
                manStoreCodeErrorMessage: errorMessageObject.manStoreCodeErrorMessage,
                currencyCodeErrorMessage: errorMessageObject.currencyCodeErrorMessage,
                saleEventErrorMessage: errorMessageObject.saleEventErrorMessage,
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    // Makes a POST request to the API with the data entered by the user.
    saveMagentoKey = () => {
        return new Promise(async (resolve, reject) => {
            const magentoConnectionPayload = {
                consumer_key: this.state.consumerKey,
                consumer_secret: this.state.consumerSecret,
                access_token: this.state.accessToken,
                access_token_secret: this.state.accessTokenSecret,
            };

            const magentoConnectionConfig = {
                method: 'POST',
                url: generateUrl('config', 'magento-connection'),
                data: magentoConnectionPayload,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            try {
                const magentoConnectionRequest = await Axios(magentoConnectionConfig);

                if (magentoConnectionRequest.status !== 201) {
                    throw new Error('Status not 201');
                }

                const magentoDetailsConfigPayload = {
                    server_url: this.state.serverUrl,
                    store_code: this.state.dbStoreCode || this.state.manStoreCode,
                    currency_code: this.state.currencyCode,
                    sale_event: this.state.saleEvent,

                    connection: generatePath('config', 'magento-connection', magentoConnectionRequest.data.id),
                };

                const magentoDetailsConfig = {
                    method: 'POST',
                    url: generateUrl('config', 'magento-config'),
                    data: magentoDetailsConfigPayload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const magentoDetailsRequest = await Axios(magentoDetailsConfig);

                if (magentoDetailsRequest.status !== 201) {
                    throw new Error('Status not 201');
                }
            } catch (error) {
                console.log(error, 'ERROR');
                reject('Error making a magento connection to API.');
            }

            resolve('Magento Connection Created');
        });
    };

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick = () => {
        this.props.removePopup();
        this.handleNavigateMagentoKeyManageModal();
    };

    // SecondaryAction of the close popup
    onPopupStayHereClick = () => {
        this.props.removePopup();
    };

    // Saved changes clicked handler
    onSaveChangesClick = () => {
        // If there is an error reopen the magento details tab as the error will be there
        if (!this.magentoConnectionformValidator() || !this.magentoConfigformValidator()) {
            this.setState({
                magentoConnectionAccordionOpen: true,
                magentoConfigAccordionOpen: true,
            });
            return;
        }

        this.setState({
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: true,
            closeButtonDisabled: true,
        });

        this.saveMagentoKey()
            .then(() => {
                this.props.addNotification({
                    copy: 'A Magento Key was successfully created.',
                    type: NotificationMessageType.Success,
                });
                this.handleNavigateMagentoKeyManageModal();
            })
            .catch(err => {
                console.log(err, 'err');
                this.props.addNotification({
                    copy: 'There was an issue adding this Magento Key, please try again later.',
                    type: NotificationMessageType.Error,
                });

                this.setState({
                    saveChangesButtonDisabled: false,
                    saveChangesButtonLoading: false,
                    closeButtonDisabled: false,
                });
            });
    };

    // Manage the checkbox input fields
    onCheckboxChange = checkboxName => {
        let checked = !this.state[checkboxName];
        this.setState({
            [checkboxName]: checked,
        });
    };

    // Manage the input fields changes and updating the state with the new value entered by the user
    onMagentoConsumerKeyChange = event => {
        this.setState({
            consumerKey: event.target.value,
        });
    };
    onMagentoConsumerSecretChange = event => {
        this.setState({
            consumerSecret: event.target.value,
        });
    };
    onMagentoAccessTokenChange = event => {
        this.setState({
            accessToken: event.target.value,
        });
    };
    onMagentoAccessTokenSecretChange = event => {
        this.setState({
            accessTokenSecret: event.target.value,
        });
    };
    onMagentoServerUrlChange = event => {
        const value = event.target.value;
        if (value.length !== 0 && isUrl(value)) {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getStores(value);
            }, 2500);
        }
        this.setState({
            serverUrl: value,
        });
    };
    onMagentoStoreCodeChange = event => {
        const selectedStoreCode = this.state.storeCodeOptions.filter(code => code.name === event.target.value)[0]?.code;
        this.setState({
            dbStoreCode: selectedStoreCode,
            storeCode: event.target.value,
        });
    };
    onMagentoManualStoreCodeChange = event => {
        this.setState({
            manStoreCode: event.target.value,
        });
    };
    onMagentoCurrencyCodeChange = event => {
        this.setState({
            currencyCode: event.target.value,
        });
    };
    onMagentoSaleEventChange = event => {
        this.setState({
            saleEvent: event.target.value,
        });
    };

    getStores = async client_url => {
        const params = {
            access_token: this.state.accessToken,
            client_url: client_url,
        };

        this.setState({
            isBuffering: true,
            bufferingMessage: 'Checking API credentials...',
        });

        await Axios({
            method: 'GET',
            url: `${this.props.meta.apiDomain}/api/${this.props.account.token}/integrations/magento/get-stores`,
            withCredentials: true,
            params: params,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.status_code === 200) {
                    const storeCodeList = response.data.data.map(code => {
                        return {
                            id: code.id,
                            name: code.name,
                            code: code.code,
                        };
                    });
                    this.setState({
                        storeCodeOptions: storeCodeList,
                        isStoreCodeResponseError: false,
                        isServerUrlResponseError: false,
                        isMagentoDetailsResponseError: false,
                        saveChangesButtonDisabled: false,
                        isBuffering: false,
                        bufferingMessage: '',
                    });
                } else if (response.data.status_code === 500) {
                    // For Host URL validation when incorrect or spelled incorrectly
                    this.setState({
                        isServerUrlResponseError: true,
                        saveChangesButtonDisabled: true,
                        isBuffering: false,
                        bufferingMessage: '',
                        serverUrlResponseErrorMessage: 'Host URL is incorrect',
                    });
                } else if (response.data.status_code === 400) {
                    // For invalid/empty details
                    this.setState({
                        isMagentoDetailsResponseError: true,
                        isBuffering: false,
                        bufferingMessage: '',
                        magentoDetailsResponseErrorMessage: `<p>Enter your Magento Details for the completion of your Magento Key. Please confirm that the API credentials provided here have the correct permissions, including the "store resource", you can read more about it here: <a href='https://docs.magento.com/user-guide/stores/stores-all-view-edit.html' target='_blank'>Magento Documentation</a></p>`,
                    });
                } else {
                    this.setState({
                        storeCodeOptions: [{ id: '', name: 'No Store Codes Available', code: '' }],
                        isMagentoDetailsResponseError: true,
                        isStoreCodeResponseError: true,
                        isServerUrlResponseError: false,
                        saveChangesButtonDisabled: false,
                        isBuffering: false,
                        bufferingMessage: '',
                        magentoDetailsResponseErrorMessage: `<p>Enter your Magento Details for the completion of your Magento Key. Please confirm that the API credentials provided here have the correct permissions, including the "store resource", you can read more about it here: <a href='https://docs.magento.com/user-guide/stores/stores-all-view-edit.html' target='_blank'>Magento Documentation</a></p>`,
                        storeCodeResponseErrorMessage: `<p style="color:#6f6f6f">These credentials are not authorized to access the Magento API 'store' resource. Please go <a href='https://docs.magento.com/user-guide/stores/stores-all-view-edit.html' target='_blank'>Magento Documentation</a> to read more. Alternatively, if you use store code, you can enter it manually below.</p>`,
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    // On the accordion the next button click handler
    onMagentoKeyNextClick = () => {
        this.setState({
            consumerKeyErrorMessage: '',
            consumerSecretErrorMessage: '',
            accessTokenErrorMessage: '',
            accessTokenSecretErrorMessage: '',
        });
        if (this.magentoConnectionformValidator()) {
            this.setState({
                magentoConnectionAccordionOpen: false,
                magentoConfigAccordionOpen: true,
            });
        }
    };

    renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    renderAccordion = () => {
        const accordions = [
            {
                header: 'Magento Connection Details',
                required: true,
                open: this.state.magentoConnectionAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Consumer Key:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter your magento consumer key.',
                                inputKeyValue: 'consumerKey',
                                inputPlaceholder: 'Consumer Key...',
                                inputValue: this.state.consumerKey,
                                inputOnChange: this.onMagentoConsumerKeyChange,
                                errorMessage: this.state.consumerKeyErrorMessage,
                            },
                            {
                                label: 'Consumer Secret:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter your magento consumer secret.',
                                inputKeyValue: 'consumerSecret',
                                inputPlaceholder: 'Consumer Secret...',
                                inputValue: this.state.consumerSecret,
                                inputOnChange: this.onMagentoConsumerSecretChange,
                                errorMessage: this.state.consumerSecretErrorMessage,
                            },
                            {
                                label: 'Access Token:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter your magento access token.',
                                inputKeyValue: 'accessToken',
                                inputPlaceholder: 'Access Token...',
                                inputValue: this.state.accessToken,
                                inputOnChange: this.onMagentoAccessTokenChange,
                                errorMessage: this.state.accessTokenErrorMessage,
                            },
                            {
                                label: 'Access Token Secret:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter your magento access token secret.',
                                inputKeyValue: 'accessTokenSecret',
                                inputPlaceholder: 'Access Token Secret...',
                                inputValue: this.state.accessTokenSecret,
                                inputOnChange: this.onMagentoAccessTokenSecretChange,
                                errorMessage: this.state.accessTokenSecretErrorMessage,
                            },
                        ],
                        buttons: [
                            {
                                value: 'NEXT',
                                onClick: this.onMagentoKeyNextClick,
                            },
                        ],
                    },
                },
            },
            {
                header: ' Magento Details',
                required: true,
                open: this.state.magentoConfigAccordionOpen,
                type: 'form',
                intro: this.state.isMagentoDetailsResponseError
                    ? this.state.magentoDetailsResponseErrorMessage
                    : '<p>Enter your Magento Details for the completion of your Magento Key. </p>',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Server URL:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter your magento server url.',
                                inputKeyValue: 'serverUrl',
                                inputPlaceholder: 'Server URL...',
                                inputValue: this.state.serverUrl,
                                isLoading: this.state.isBuffering,
                                inputOnChange: this.onMagentoServerUrlChange,
                                errorMessage: this.state.isServerUrlResponseError
                                    ? this.state.serverUrlResponseErrorMessage
                                    : this.state.serverUrlErrorMessage,
                                bufferingMessage: this.state.bufferingMessage,
                            },
                            {
                                label: 'Store Code:',
                                type: 'select',
                                requiredField: false,
                                toolTipCopy: 'Select your magento store code or Enter Manually',
                                inputKeyValue: 'storeCode',
                                customPlaceholder: this.state.storeCodeOptions[0]?.name,
                                inputOptions: this.state.storeCodeOptions,
                                inputValue: this.state.storeCode,
                                inputOnChange: this.onMagentoStoreCodeChange,
                                infoMessage: this.state.isStoreCodeResponseError
                                    ? this.state.storeCodeResponseErrorMessage
                                    : this.state.storeCodeErrorMessage,
                            },
                            // Following field will remain hidden when Server URL retrieves list of Store Code successfully.
                            // If not, it will appear below Store Code field for manual entry from user.
                            this.state.isStoreCodeResponseError
                                ? {
                                      label: '',
                                      type: 'text',
                                      requiredField: false,
                                      toolTipCopy: '',
                                      inputKeyValue: 'manStoreCode',
                                      inputPlaceholder: 'Enter Store Code here...',
                                      inputValue: this.state.manStoreCode,
                                      inputOnChange: this.onMagentoManualStoreCodeChange,
                                      errorMessage: this.state.manStoreCodeErrorMessage,
                                  }
                                : '',
                            {
                                label: 'Currency Code',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter your magento currency code.',
                                inputKeyValue: 'currencyCode',
                                inputPlaceholder: 'Currency Code...',
                                inputValue: this.state.currencyCode,
                                inputOnChange: this.onMagentoCurrencyCodeChange,
                                errorMessage: this.state.currencyCodeErrorMessage,
                            },
                            {
                                label: 'Sale Event',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a Sale Event.',
                                inputKeyValue: 'saleEvent',
                                inputOptions: this.state.saleEventProductEventOptions,
                                inputValue: this.state.saleEvent,
                                inputOnChange: this.onMagentoSaleEventChange,
                                errorMessage: this.state.saleEventErrorMessage,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__add-magento">
                    <this.renderModalNavigation />
                    <h2>Add Magento Key</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__add-magento">
                    <this.renderModalNavigation />
                    <h2>Add Magento Key</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email. " />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__add-magento">
                <this.renderModalNavigation />
                <h2>Add Magento Key</h2>
                <p>
                    Add your Magento API key to allow the import of Magento sales data. For more information on where to
                    set-up and find your Magento API keys please visit: &nbsp;
                    <a href="https://devdocs.magento.com/guides/v2.4/get-started/authentication/gs-authentication-token.html">
                        https://devdocs.magento.com/guides/v2.4/get-started/authentication/gs-authentication-token.html
                    </a>
                    .
                </p>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meta: state.meta,
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalMagento);
