import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input<{ disabled: boolean }>`
    font-family: inherit;
    font-size: 14.5px;
    width: 100%;
    height: 40px;
    background-color: ${props => (props.disabled ? props.theme.colours.lightGrey : props.theme.colours.inputColour)};
    border: none;
    border-bottom: solid 2px
        ${props => (props.disabled ? props.theme.colours.midGrey : props.theme.colours.offBlackLighter)};
    color: ${props => props.disabled && props.theme.colours.midGrey};
    padding: 0px 10px;
`;

export type InputTextProps = {
    value: string | undefined;
    placeholder?: string;
    disabled?: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
};

const InputText = ({ value, placeholder, disabled, onChange }: InputTextProps) => {
    return (
        <StyledInput
            type="text"
            value={value}
            placeholder={placeholder}
            disabled={disabled ? disabled : false}
            onChange={onChange}
        />
    );
};

export default InputText;
