import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 380px;
    font-size: 0.9rem;
    font-weight: 400;
    font-style: italic;
    color: ${props => props.theme.colours.darkGrey};
    text-align: center;
`;

const CreateAWidgetPreviewMessage = ({ message }: { message: string }) => {
    return <StyledContainer>{message}</StyledContainer>;
};

export default CreateAWidgetPreviewMessage;
