import * as segmenterFields from './fields';

export const SEGMENTER_RESOURCE = {
    category: 'segment',
    id: 'segmenter-manage',
    displayName: 'segment',
    dateDimension: {},
    defaultDimensions: [segmenterFields.SEGMENT_NAME],
    dimensions: [segmenterFields.SEGMENT_NAME],
    defaultOrderBy: segmenterFields.SEGMENT_NAME,
};

export const CONDITION_RESOURCE = {
    category: 'condition',
    id: 'condition-manage',
    displayName: 'condition',
    dateDimension: {},
    defaultDimensions: [segmenterFields.CONDITION_NAME],
    dimensions: [segmenterFields.CONDITION_NAME],
    defaultOrderBy: segmenterFields.CONDITION_NAME,
};
