import { Popup, PopupAction } from '../types';

export default function reducer(state = initialState, action: PopupAction) {
    switch (action.type) {
        case 'SET_POPUP':
            return Object.assign({}, state, {
                isVisible: true,
                title: action.payload.title,
                iconType: action.payload.iconType,
                contentType: action.payload.contentType,
                config: { ...action.payload.config },
                buttons: [...action.payload.buttons],
            });
        case 'DEL_POPUP':
            return Object.assign({}, state, {
                isVisible: false,
                title: '',
                iconType: '',
                contentType: '',
                config: {},
                buttons: [],
            });
        default:
            return state;
    }
}

const initialState: Popup = {
    isVisible: false,
    title: '',
    iconType: '',
    contentType: '',
    config: {},
    buttons: [],
};
