// React dependencies
import React, { FunctionComponent, memo, useState } from 'react';

// Core dependencies
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import Axios, { AxiosRequestConfig } from 'axios';
import styled, { css } from 'styled-components';
import { ButtonThemes } from '../../enums/button-themes';

// Helpers
import { generateUrlDetail } from '../../helpers/request-builder';
import { isValidUrl } from '../../helpers/valid-url';
import { errorHandling } from '../../helpers/request-error-handling';

// Component Dependencies
import LoadingSpinner from '../../components/loading-spinner';

// Redux Actions
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { RootState } from '../../redux/reducers/core';

// Widget Dependencies
import WidgetAccordion from '../../widgets/accordion';

// Styling
const sharedPaddingStyles = css`
    padding: 12px 14px;
`;

const sharedRemoveMarginStyles = css`
    margin: 0;
`;

const StyledMachedPatternSection = styled.div`
    background-color: #ffffff;
    border-radius: 2px;
`;

const StyledMachedPatternHeaderSection = styled.div`
    ${sharedPaddingStyles}
    border-bottom: solid 2px #f1f1f1;
`;

const StyledMachedPatternHeader = styled.h3`
    ${sharedRemoveMarginStyles}
`;

const StyledMachedPatternItemSection = styled.div`
    ${sharedPaddingStyles}
`;

const StyledMachedPatternItem = styled.p`
    ${sharedRemoveMarginStyles}
`;

export interface IManageFindPatternProps {
    setAccordionOpen: (open: boolean) => void;
}

const ManageFindPattern: FunctionComponent<IManageFindPatternProps> = ({ setAccordionOpen }) => {
    const dispatch = useDispatch();

    const { account } = useSelector((state: RootState) => state);

    const [channelUrl, setChannelUrl] = useState('');
    const [channelUrlError, setChannelUrlError] = useState('');
    const [isSubmitButtonDisable, setIsSubmitButtonDisable] = useState(true);
    const [matchPattern, setMatchPattern] = useState('');
    const [matchChannel, setMatchChannel] = useState('');
    const [isMatchPatternLoading, setIsMatchPatternLoading] = useState(false);

    const handleChannelUrlOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChannelUrl(event.target.value);

        if (isValidUrl(event.target.value)) {
            setIsSubmitButtonDisable(false);
            setChannelUrlError('');
        } else {
            setIsSubmitButtonDisable(true);
            setChannelUrlError('Invalid URL was given. URLS should be in format https://www.example.com');
        }
    };

    const handleChannelPatternOnClick = () => {
        setMatchPattern('');
        setMatchChannel('');
        setIsMatchPatternLoading(true);

        const payload = new FormData();
        payload.append('url', channelUrl);

        const config: AxiosRequestConfig = {
            method: 'POST',
            url: generateUrlDetail(account.token, 'pattern', 'pattern-validate', [], false)!,
            data: payload,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        Axios(config)
            .then((response: any) => {
                setMatchPattern(response.data.pattern);
                setMatchChannel(response.data.referer);
                setIsMatchPatternLoading(false);
            })
            .catch((error: any) => {
                errorHandling(error);
                setMatchPattern('');
                setMatchChannel('');
                setIsMatchPatternLoading(false);
                dispatch(
                    addNotification({
                        copy: 'There was an error getting the details, please try again later.',
                        type: NotificationMessageType.Error,
                    })
                );
            });
    };

    const handleOnAccordionCloseClick = () => {
        setAccordionOpen(false);
    };

    const renderAccordion = () => {
        const accordions = [
            {
                header: 'Find Pattern for URL',
                required: true,
                open: true,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Test URL:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter the URL for validating. Format should be https://www.example.com',
                                inputKeyValue: 'channel-details__url',
                                inputPlaceholder: 'https://www.example.com',
                                inputValue: channelUrl,
                                inputOnChange: handleChannelUrlOnChange,
                                errorMessage: channelUrlError,
                            },
                        ],
                        columns: 1,
                        buttons: [
                            {
                                value: 'Find Pattern',
                                onClick: handleChannelPatternOnClick,
                                disabled: isSubmitButtonDisable,
                            },
                            {
                                value: 'CANCEL',
                                buttonTheme: ButtonThemes.Secondary,
                                onClick: handleOnAccordionCloseClick,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    return (
        <div>
            <h2>Test Pattern</h2>
            <p>
                Enter a URL into the input box below, then click the Find Pattern button. This will return the Pattern,
                and its Channel, that the Cubed system will use to match a visitor's referer string on.
            </p>
            {renderAccordion()}
            {!isMatchPatternLoading ? (
                <StyledMachedPatternSection data-testid="manage-find-pattern">
                    {matchPattern !== '' && (
                        <>
                            <StyledMachedPatternHeaderSection>
                                <StyledMachedPatternHeader>
                                    Found Channel with matching Pattern
                                </StyledMachedPatternHeader>
                            </StyledMachedPatternHeaderSection>
                            <StyledMachedPatternItemSection>
                                <StyledMachedPatternItem>
                                    {matchChannel} - {matchPattern}
                                </StyledMachedPatternItem>
                            </StyledMachedPatternItemSection>
                        </>
                    )}
                </StyledMachedPatternSection>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};

export default memo(ManageFindPattern, isEqual);
