import React from 'react';
import styled from 'styled-components';

// Types
import { WidgetDirection, HistogramChart } from '../../types';

// Helper
import { graphColours } from '../../helpers/graph-colours';

const StyledBoxContainer = styled.div<{ direction: WidgetDirection }>`
    display: flex;
    flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
    height: 100%;
`;

const StyledBox = styled.div<{ backgroundColor: string }>`
    flex-grow: 1;
    position: relative;
    text-align: center;
    background-color: ${props => props.backgroundColor};
`;

const StyledValueContainer = styled.div`
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
`;

const StyledCentralValueContainer = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: ${props => props.theme.sectionDashboard.widget.venn.bigNumber.textColor};
`;

const StyledPillContainer = styled.div<{ width: number; height: number }>`
    margin: ${props => (props.width === 1 && props.width === 1 ? `0px 0px 3px 0px` : `5px 0px`)};
    border-radius: 15px;
    font-size: 0.7rem;
    font-weight: 500;
    padding: 2px 8px;
    color: ${props => props.theme.sectionDashboard.widget.venn.bigNumber.textColor};
    background-color: rgba(255, 255, 255, 0.4);
`;

const StyledP = styled.p`
    margin: 0;
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 5px;
    font-weight: 600;
`;

export type WidgetHistogramBigNumberComparisonProps = {
    metric: string;
    data: HistogramChart[];
    comparisonData?: HistogramChart[];
    width: number;
    height: number;
    direction: WidgetDirection;
};

const WidgetHistogramBigNumberComparison = ({
    metric,
    data,
    comparisonData,
    width,
    height,
    direction,
}: WidgetHistogramBigNumberComparisonProps) => {
    const dataList = data.length > 0 ? data[0] : null;
    const comparisonDataList = comparisonData && comparisonData.length > 0 ? comparisonData[0] : null;

    const renderWidgetBox = (data: HistogramChart | null, colorIndex: number, label: string) => (
        <StyledBox backgroundColor={graphColours[colorIndex].splineGradientStart} key={`${data?.y}${colorIndex}`}>
            <StyledValueContainer>
                {data ? (
                    <>
                        {(height > 1 || width > 1) && (
                            <StyledCentralValueContainer>
                                {data.y}
                                <StyledP>{metric}</StyledP>
                            </StyledCentralValueContainer>
                        )}
                        <StyledPillContainer width={width} height={height}>
                            {label}
                        </StyledPillContainer>
                    </>
                ) : (
                    <StyledCentralValueContainer>
                        <StyledP>No Data</StyledP>
                    </StyledCentralValueContainer>
                )}
            </StyledValueContainer>
        </StyledBox>
    );

    return (
        <StyledBoxContainer direction={direction}>
            {renderWidgetBox(dataList, 1, 'This Period')}
            {renderWidgetBox(comparisonDataList, 3, 'Comparison Period')}
        </StyledBoxContainer>
    );
};

export default WidgetHistogramBigNumberComparison;
