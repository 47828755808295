import React from 'react';
import styled from 'styled-components';

const StyledFormBody = styled.div<{ direction: string }>`
    width: 100%;
    display: flex;
    flex-direction: ${props => props.direction};
    gap: ${props => (props.direction === 'row' ? '15px' : '30px')};
`;

type FormBodyProps = {
    direction?: 'row' | 'column';
    children: React.ReactNode;
};

const FormBody = ({ direction = 'row', children }: FormBodyProps) => {
    return <StyledFormBody direction={direction}>{children}</StyledFormBody>;
};

export default FormBody;
