import { CubedResource } from '../../../types';
import { comparisonRequestError } from '../../helpers/errors';
import { getMultiRequestStatus } from '../../helpers/get-multi-request-status';
import { SectionDashboardRequest, ViewComparisonData } from '../../types';
import useView from './use-view';

export type UseViewComparisonArgs = {
    resource: CubedResource;
    request: SectionDashboardRequest;
};

const useViewComparison = ({ resource, request }: UseViewComparisonArgs): ViewComparisonData => {
    const data = useView({ resource, request });
    const comparisonData = useView({ resource, request, isComparison: true });
    const status = getMultiRequestStatus({ requests: [data, comparisonData] });

    if (status === 'loading' || status === 'empty') {
        return {
            type: 'viewComparison',
            status: status,
        };
    }

    if (status === 'error') {
        return {
            type: 'viewComparison',
            status: status,
            error: {
                message: comparisonRequestError(data, comparisonData),
            },
        };
    }

    return {
        type: 'viewComparison',
        status: status,
        request: request,
        resources: [
            {
                ...data,
                type: 'view',
            },
            {
                ...comparisonData,
                type: 'view',
            },
        ],
    };
};

export default useViewComparison;
