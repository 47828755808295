import { CubedAdvancedRuleResource } from '../types';

export const SEGMENTER_PATTERN_RULES_RESOURCE: CubedAdvancedRuleResource = {
    name: 'segmenter-pattern',
    category: 'segmenter',
};

export const SEGMENTER_PATTERN_RULES_FK_RESOURCE: CubedAdvancedRuleResource = {
    name: 'segmenter-pattern-lite',
    category: 'segmenter',
};

export const SUBCHANNEL_RULES_RESOURCE: CubedAdvancedRuleResource = {
    name: 'segmenter-subchannel',
    category: 'segmenter',
};

export const SUBCHANNEL_RULES_FK_RESOURCE: CubedAdvancedRuleResource = {
    name: 'subchannel',
    category: 'config',
};

export const BRAND_STATUS_RULES_RESOURCE: CubedAdvancedRuleResource = {
    name: 'segmenter-brand-status',
    category: 'segmenter',
};

export const BRAND_STATUS_RULES_FK_RESOURCE: CubedAdvancedRuleResource = {
    name: 'brand-status',
    category: 'segmenter',
};

export const MARKETING_FUNNEL_RULES_RESOURCE: CubedAdvancedRuleResource = {
    name: 'segmenter-marketing-funnel',
    category: 'segmenter',
};

export const MARKETING_FUNNEL_RULES_FK_RESOURCE: CubedAdvancedRuleResource = {
    name: 'marketing-funnel',
    category: 'segmenter',
};

export const SEGMENTER_SUBCHANNEL_CONFIG_RESOURCE: CubedAdvancedRuleResource = {
    name: 'segmenter-subchannel-config',
    category: 'segmenter',
};
