import { v4 as uuidv4 } from 'uuid';

import { formatDimensionFilters } from '../../../helpers/dimension/dimension-helper';
import { formatDates } from '../../../helpers/format-dates';
import { cloneDeep } from 'lodash';
import store from '../../../redux/store';

export const formatTotalsRawData = (dimensions, metrics, meta) => {
    const dimensionTotals = dimensions.map((dimension, index) => {
        return {
            displayData: index === 0 ? 'Totals:' : '    ',
            isDimension: true,
            column: dimension,
        };
    });

    const metricTotals = metrics.map(metric => {
        return {
            displayData: meta ? meta.totals[metric.rawName].value : '',
            column: metric,
        };
    }, this);

    return [...dimensionTotals, ...metricTotals];
};

export const findRowInSelected = (selectedRows, rowId) => {
    selectedRows.forEach(row => {
        if (parseInt(row.rowId, 10) === rowId) return row;
    });
    return null;
};

export const calculateHeatmap = (value, ranges) => {
    if (value === ranges.default[0]) {
        return ranges.default[1];
    }

    ranges.forEach(data => {
        const range = ranges[data];
        if (value >= range[0] && value <= range[1]) return data;
    });

    return ranges.default[1];
};

export const calculateProportion = (column, totals, dataObject) => {
    const currencySymbol = store.getState().currency.symbol;

    let newData = dataObject;
    const newObjects = cloneDeep(newData.objects);

    if (newData.objects) {
        newObjects.forEach(row => {
            let colTotal;
            let proportion;
            totals.forEach(total => {
                if (total.column.rawName === column.rawName) {
                    colTotal = parseFloat(total.displayData.replace(currencySymbol, '').replace(/,/g, ''));
                }
            });

            if (colTotal > 0) {
                proportion = ((row[column.rawName].raw_value / colTotal) * 100).toFixed(2);
            }
            if (!isNaN(proportion)) {
                row[column.rawName].proportion = proportion;
            }
        });
    }
    newData.objects = newObjects;
    return newData;
};

export const formatTotals = (dimensions, metrics, displayData) => {
    const dimensionTotals = dimensions.map((dimension, index) => {
        return {
            key: uuidv4(),
            displayData: index === 0 ? 'Totals:' : '    ',
            isDimension: true,
            column: dimension,
        };
    });
    const metricTotals = metrics.map(metric => {
        if (!displayData.meta.totals[metric.rawName]) {
            return {
                key: uuidv4(),
                displayData: 'NA',
                column: metric,
            };
        }
        return {
            key: uuidv4(),
            displayData: displayData.meta ? displayData.meta.totals[metric.rawName].value : '',
            column: metric,
        };
    }, this);

    return [...dimensionTotals, ...metricTotals];
};

const formatSearchField = (field, value) => {
    const obj = {
        key: `${field.rawName}${field.lookupTerm ? '__' + field.lookupTerm : ''}__icontains`,
        value: value,
    };
    return { ...obj };
};

export const generateTableParams = (
    config,
    dimensionFilters,
    page,
    resource,
    rowCount,
    searchValue,
    selectedProducts,
    tableGroupBy,
    tableOrderBy,
    tableOrderDir,
    startDate,
    endDate,
    selectedMetrics
) => {
    const searchObject = { ...formatSearchField(tableGroupBy[0], searchValue) };
    const orderDirModifier = tableOrderDir === 'desc' ? '-' : '';
    const dateParams = [];
    let metricParams = [];
    const dateFormatted = formatDates(startDate, endDate, resource);

    if (resource.dateDimension) {
        dateParams.push({
            key: `${resource.dateDimension.rawName}__gte`,
            value: dateFormatted.startDate,
        });
        dateParams.push({
            key: `${resource.dateDimension.rawName}__lte`,
            value: dateFormatted.endDate,
        });
    }
    const productParams = config.ignoreProducts
        ? []
        : [
              {
                  key: 'product__in',
                  value: selectedProducts.map(product => product.id).toString(),
              },
          ];
    selectedMetrics &&
        selectedMetrics.forEach(metricFilter => {
            if (metricFilter.status === 'view') {
                metricParams = [
                    ...metricParams,
                    metricFilter.metricType === 9
                        ? `${metricFilter.metric.rawName}=${metricFilter.value === 'true' ? 1 : 0}`
                        : `${metricFilter.metric.rawName}__${metricFilter.operator.name}=${metricFilter.value}`,
                ];
            }
        });
    const params = [
        ...formatDimensionFilters(dimensionFilters, resource),
        ...dateParams,
        ...productParams,
        searchObject,
        {
            key: 'group_by',
            value: tableGroupBy.map(group => group.rawName).toString(),
        },
        {
            key: 'order_by',
            value: orderDirModifier + tableOrderBy.rawName,
        },
        {
            key: 'limit',
            value: rowCount,
        },
        {
            key: 'offset',
            value: rowCount * (page - 1),
        },
    ];
    if (metricParams.length > 0) {
        params.push({
            key: 'having',
            value: metricParams.toString(),
        });
    }
    return params;
};

export const getTotals = (data, tableGroupBy, metrics) => {
    let totals = [];
    if (data.meta && data.meta.hasOwnProperty('totals')) {
        totals = formatTotals(tableGroupBy, metrics, data);
    }
    return totals;
};

export const getProportions = (data, totals, tableGroupBy, metrics) => {
    let dataWithProportions = data;
    const copiedData = { ...data };
    const columns = [...tableGroupBy, ...metrics];

    if (columns.length > 0) {
        columns.forEach(column => {
            if (column.displayProportion) {
                dataWithProportions = calculateProportion(column, totals, copiedData);
            }
        });
    }

    return dataWithProportions;
};
