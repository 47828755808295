// Types
import { CubedField } from '../types';
import { ConfigDataSuccess } from '../react-query/types';

// Configuration
import { CONFIG_DASH_PATH, CONFIG_GOALS, CONFIG_PATH_CATEGORY } from '../configurations/resources-config';

// Helpers
import buildDropdownOptions from '../react-query/select-functions/build-dropdown-options';

type TierTwoContentDropdownInfiniteQueryArgs = {
    field: CubedField;
    offset: number;
    limit: number;
    search?: string;
};

type PathDropdownInfiniteQueryArgs = {
    offset: number;
    limit: number;
    search?: string;
};

export const tierTwoContentDropdownInfiniteQuery = ({
    field,
    offset = 0,
    limit = 100,
    search = '',
}: TierTwoContentDropdownInfiniteQueryArgs) => {
    return {
        resource: CONFIG_PATH_CATEGORY,
        params: [
            { key: 'offset', value: offset },
            { key: 'limit', value: limit },
            { key: 'order_by', value: `${field.rawName}` },
            { key: `${field.rawName}__icontains`, value: search },
        ],
        limit: limit,
    };
};

export const contentDetailGoalsQuery = {
    resource: CONFIG_GOALS,
    params: [
        { key: 'active', value: 1 },
        { key: 'order_by', value: '-default' },
    ],
    staleTime: 1000 * 60 * 5, // 5 minutes,
    select: (data: ConfigDataSuccess) => {
        return buildDropdownOptions({ data: data, labelField: 'name', valueField: 'name' });
    },
};

export const pathDropdownInfiniteQuery = ({ offset = 0, limit = 100, search = '' }: PathDropdownInfiniteQueryArgs) => {
    return {
        resource: CONFIG_DASH_PATH,
        params: [
            { key: 'offset', value: offset },
            { key: 'limit', value: limit },
            { key: 'order_by', value: 'path' },
            { key: 'path__icontains', value: search },
        ],
        limit: limit,
    };
};
