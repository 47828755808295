import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

// Redux
import { useDispatch } from 'react-redux';
import { setTitle } from '../../../../redux/actions/page-meta';

// Context
import DashboardLibraryContextProvider, { DashboardLibraryContext } from '../context/dashboard-library-context';
import { DashboardBuilderContext } from '../../../context/dashboard-builder-context';

// Components
import LayoutLoading from '../../../layouts/layout-loading';
import LayoutDashboardLibraryList from './layout-dashboard-library-list';
import LayoutEmpty from '../../../layouts/layout-empty';
import IconDisplaySlash from '../../../../components/icons/display-slash';
import LayoutDashboardLibraryTopBar from './layout-dashboard-library-top-bar';
import EmptyWidgets from '../components/empty-widgets';

const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const StyledIconDisplaySlash = styled(IconDisplaySlash)`
    width: 40px;
`;

const LayoutDashboardLibraryContent = () => {
    const { userDashboardsQuery } = useContext(DashboardBuilderContext);
    const {
        accountDashboardsQuery,
        accountDashboards,
        currentUserDashboards,
        widgetQuery,
        homepageQuery,
        deleteHomepageMutation,
        addHomepageMutation,
    } = useContext(DashboardLibraryContext);

    if (
        userDashboardsQuery.isLoading ||
        userDashboardsQuery.isFetching ||
        accountDashboardsQuery.isLoading ||
        widgetQuery.isLoading ||
        homepageQuery.isLoading ||
        homepageQuery.isFetching ||
        deleteHomepageMutation.isPending ||
        addHomepageMutation.isPending
    ) {
        return <LayoutLoading copy={'Loading dashboards...'} />;
    }

    if (widgetQuery.isSuccess && widgetQuery.data.objects.length === 0) {
        return (
            <StyledContainer>
                <LayoutDashboardLibraryTopBar />
                <EmptyWidgets />
            </StyledContainer>
        );
    }

    if (
        userDashboardsQuery.isSuccess &&
        userDashboardsQuery.data.objects.length === 0 &&
        accountDashboardsQuery.isSuccess &&
        accountDashboardsQuery.data.objects.length === 0
    ) {
        return (
            <StyledContainer>
                <LayoutDashboardLibraryTopBar />
                <LayoutEmpty icon={<StyledIconDisplaySlash />} copy="You have not created any dashboards yet" />
            </StyledContainer>
        );
    }

    if (accountDashboards.length === 0 && currentUserDashboards.length === 0) {
        return (
            <StyledContainer>
                <LayoutDashboardLibraryTopBar />
                <LayoutEmpty icon={<StyledIconDisplaySlash />} copy="No dashboards found." />
            </StyledContainer>
        );
    }

    return (
        <StyledContainer>
            <LayoutDashboardLibraryTopBar />
            <LayoutDashboardLibraryList />
        </StyledContainer>
    );
};

const LayoutDashboardLibrary = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle('Dashboard Library'));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <DashboardLibraryContextProvider>
            <LayoutDashboardLibraryContent />
        </DashboardLibraryContextProvider>
    );
};

export default LayoutDashboardLibrary;
