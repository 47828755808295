/* eslint-disable react-hooks/rules-of-hooks */

import React, { useContext } from 'react';
import theme from '../../../styled-components/theme.styled';

// Fields & Resources
import * as fields from '../../../configurations/fields';
import * as resources from '../../../configurations/resources';

// Components
import BeforeGridConfiguredGoals from '../../../section-dashboard/components/sections/before-grid/before-grid-configured-goals';

// Widgets
import WidgetSparkLineComparison from '../../../section-dashboard/widgets/widget-comparison/widget-spark-line-comparison';
import WidgetBigNumberComparison from '../../../section-dashboard/widgets/widget-comparison/widget-big-number-comparison';
import WidgetVennComparison from '../../../section-dashboard/widgets/widget-comparison/widget-venn-comparison';
import WidgetTableComparison from '../../../section-dashboard/widgets/widget-comparison/widget-table-comparison';
import WidgetStackedAreaChartComparison from '../../../section-dashboard/widgets/widget-comparison/widget-stacked-area-chart-comparison';
import WidgetTable from '../../../section-dashboard/widgets/widget/widget-table';
import WidgetPieChartTableComparison from '../../../section-dashboard/widgets/widget-comparison/widget-pie-chart-table-comparison';

// Widget Menus
import WidgetFunnelChartComparison from '../../../section-dashboard/widgets/widget-comparison/widget-funnel-chart-comparison';
import WidgetMenuTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';
import WidgetMenuStackedAreaChart from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-stacked-area-chart';
import WidgetMenuPieChartTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-pie-chart-table';

// Hooks
import { useDatesFormatted } from '../../../hooks/use-dates';

import useViewComparison from '../../../section-dashboard/hooks/view/use-view-comparison';
import useResourceComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-comparison';
import useResourceBigNumberComparison from '../../../section-dashboard/hooks/widget-comparison/big-number/use-resource-big-number-comparison';
import useResourceSparkLineComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-spark-line-comparison';
import useResourceTotalComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-total-comparison';
import useResourceVennResourceComparison from '../../../section-dashboard/hooks/widget-comparison/venn/use-resource-venn-resource-comparison';
import useResourceVennComparison from '../../../section-dashboard/hooks/widget-comparison/venn/use-resource-venn-comparison';
import useResourceFunnelComparisonChart from '../../../section-dashboard/hooks/widget-comparison/use-resource-funnel-comparison-chart';
import useResourceTableComparison from '../../../section-dashboard/hooks/widget-comparison/table/use-resource-table-comparison';
import useRequestTable from '../../../section-dashboard/hooks/request/use-request-table';
import useResourceCategoryMixComparison from '../../../section-dashboard/hooks/widget-comparison/pie-chart-table/use-resource-pie-chart-table-category-mix-comparison';
import useWidgetMenuFooterQuerySelect from '../../../section-dashboard/widgets/menus/hooks/use-widget-menu-footer-query-select';
import useRequestStackedAreaChart from '../../../section-dashboard/hooks/request/use-request-stacked-area-chart';
import useResourceStackedAreaChartComparison from '../../../section-dashboard/hooks/widget-comparison/use-resource-stacked-area-chart-comparison';
import useResourceTableCustomGroups from '../../../section-dashboard/hooks/widget/table/use-resource-table-custom-groups';
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourcePieChartTableComparison from '../../../section-dashboard/hooks/widget-comparison/pie-chart-table/use-resource-pie-chart-table-comparison';
import useResourceCombineBigNumberRequestsComparison from '../../../section-dashboard/hooks/widget-comparison/big-number/use-resource-combine-big-number-requests-comparison';
import useRequestPieChartTable from '../../../section-dashboard/hooks/request/use-request-pie-chart-table';

// Types & Enums
import { FilterOperator, WidgetColour, WidgetMenuObj } from '../../../section-dashboard/types';
import { DatePickerType } from '../../../configurations/enums/date-picker-type';

// Component
import BeforeSectionPageTypeDropdown from '../../components/sections/before-section/before-section-page-type-dropdown';

// Context
import { ContentInsightsDashboardMenuContext } from '../../content-insights-context/content-insights-dashboard-menu-context';

// Queries
import { defaultGoalsDropdownQuery, goalsDropdownQuery } from '../../../section-dashboard/queries';
import { contentInsightsPageTypeDropdownQuery } from '../../queries';

// Menu
import BeforeGridGoalMenu from '../../components/sections/before-grid/before-grid-goal-menu';

export const config = {
    title: 'Content Insights Dashboard',
    description: '', // TODO description
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableComparisonDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
            isEnableButtonGroup: false,
            isEnablePdfExport: true,
            isEnableChannelMultiSelect: false,
            isEnableChannelSingleSelect: {
                enabled: false,
            },
        },
        comparisonStatus: {
            showComparisonDatePicker: true,
            redirectUrl: 'content-insights-dashboard',
        },
        datePicker: {},
        comparisonDatePicker: {
            datePickerType: DatePickerType.Comparison,
        },
    },
    context: () => {
        // Context
        const menuContext = useContext(ContentInsightsDashboardMenuContext);
        return { menuContext };
    },
    pageTypeTitle: () => {
        return config.context().menuContext.selectedPageType.label;
    },
    layouts: {
        xs: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a'
                    'b'
                    'c'
                    'd'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'e'
                    'f'
                    'g'
                    'h'
                    'h'
                    'i'
                    'j'
                    'k'
                    'l'
                    'm'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'n'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'o'
                    `,
                },
                {
                    title: (): string => `Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'p'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'q'
                    'r'
                    's'
                    't'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    grid: `
                    'u'
                    'v'
                    'w'
                    `,
                },
                {
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'x'
                    'y'
                    'z'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'aa'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by goal completions)`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewConversionMixByGoalCompletionsSectionMenu.menu,
                    grid: `
                    'bb'
                    `,
                },
                {
                    title: (): string => `Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'cc'
                    `,
                },
                {
                    title: 'Top Performing Pages (during selected period)',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'dd'
                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ee'
                    `,
                },
                {
                    title: (): string =>
                        `${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'ff'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'gg'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'hh'
                    `,
                },
            ],
        },
        sm: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a b'
                    'c d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'e g'
                    'f f'
                    'h h'
                    'h h'
                    'i i'
                    'j j'
                    'k k'
                    'l l'
                    'l l'
                    'm m'
                    'm m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'n n'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'o o'
                    `,
                },
                {
                    title: (): string => `Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'p p'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'q q'
                    'r r'
                    's s'
                    't t'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    grid: `
                    'u u'
                    'v v'
                    'w w'
                    `,
                },
                {
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'x x'
                    'y y'
                    'z z'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'aa aa'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by goal completions)`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewConversionMixByGoalCompletionsSectionMenu.menu,
                    grid: `
                    'bb bb'
                    `,
                },
                {
                    title: (): string => `Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'cc cc'
                    `,
                },
                {
                    title: 'Top Performing Pages (during selected period)',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'dd dd'

                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ee ee'
                    `,
                },
                {
                    title: (): string =>
                        `${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'ff ff'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'gg gg'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'hh hh'
                    `,
                },
            ],
        },
        md: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b'
                    'c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'h h h h'
                    'h h h h'
                    'i i e f'
                    'k k g g'
                    'l l l l'
                    'l l l l'
                    'm m m m'
                    'm m m m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'n n n n'
                    'n n n n'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'o o o o'
                    'o o o o'
                    `,
                },
                {
                    title: (): string => `Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'p p p p'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'q q q q'
                    'r r r r'
                    's s s s'
                    't t t t'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    grid: `
                    'u u u u'
                    'v v v v'
                    'w w w w'
                    `,
                },
                {
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'x x x x'
                    'y y y y'
                    'z z z z'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'aa aa aa aa'
                    'aa aa aa aa'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by goal completions)`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewConversionMixByGoalCompletionsSectionMenu.menu,
                    grid: `
                    'bb bb bb bb'
                    'bb bb bb bb'
                    `,
                },
                {
                    title: (): string => `Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'cc cc cc cc'
                    `,
                },
                {
                    title: 'Top Performing Pages (during selected period)',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'dd dd dd dd'
                    'dd dd dd dd'
                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ee ee ee ee'
                    'ee ee ee ee'
                    `,
                },
                {
                    title: (): string =>
                        `${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'ff ff ff ff'
                    'ff ff ff ff'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'gg gg gg gg'
                    'gg gg gg gg'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'hh hh hh hh'
                    'hh hh hh hh'
                    `,
                },
            ],
        },
        lg: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a a b b b'
                    'a a a b b b'
                    'c c c d d d'
                    'c c c d d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'h h h h e i'
                    'h h h h f j'
                    'h h h h g k'
                    'l l l l l l'
                    'l l l l l l'
                    'l l l l l l'
                    'm m m m m m'
                    'm m m m m m'
                    'm m m m m m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'n n n n n n'
                    'n n n n n n'
                    'n n n n n n'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'o o o o o o'
                    'o o o o o o'
                    'o o o o o o'
                    `,
                },
                {
                    title: (): string => `Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'p p p p p p'
                    'p p p p p p'
                    'p p p p p p'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'q q q r r r'
                    's s s t t t'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    grid: `
                    'u u v v w w'
                    `,
                },
                {
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'x x y y z z'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'aa aa aa aa aa aa'
                    'aa aa aa aa aa aa'
                    'aa aa aa aa aa aa'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by goal completions)`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewConversionMixByGoalCompletionsSectionMenu.menu,
                    grid: `
                    'bb bb bb bb bb bb'
                    'bb bb bb bb bb bb'
                    'bb bb bb bb bb bb'
                    `,
                },
                {
                    title: (): string => `Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'cc cc cc cc cc cc'
                    'cc cc cc cc cc cc'
                    'cc cc cc cc cc cc'
                    `,
                },
                {
                    title: 'Top Performing Pages (during selected period)',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'dd dd dd dd dd dd'
                    'dd dd dd dd dd dd'
                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ee ee ee ee ee ee'
                    'ee ee ee ee ee ee'
                    'ee ee ee ee ee ee'
                    `,
                },
                {
                    title: (): string =>
                        `${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'ff ff ff ff ff ff'
                    'ff ff ff ff ff ff'
                    'ff ff ff ff ff ff'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'gg gg gg gg gg gg'
                    'gg gg gg gg gg gg'
                    'gg gg gg gg gg gg'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'hh hh hh hh hh hh'
                    'hh hh hh hh hh hh'
                    'hh hh hh hh hh hh'
                    `,
                },
            ],
        },
        xl: {
            sections: [
                {
                    title: 'Overall Profile',
                    grid: `
                    'a a b b c c d d'
                    'a a b b c c d d'
                    `,
                },
                {
                    title: 'Goals Snapshot',
                    beforeGrid: <BeforeGridConfiguredGoals />,
                    collapsed: true,
                    grid: `
                    'h h h h h h e i'
                    'h h h h h h f j'
                    'h h h h h h g k'
                    'l l l l l l l l'
                    'l l l l l l l l'
                    'l l l l l l l l'
                    'm m m m m m m m'
                    'm m m m m m m m'
                    'm m m m m m m m'
                    `,
                },
                {
                    title: 'Goal Completions Over Time',
                    collapsed: true,
                    grid: `
                    'n n n n n n n n'
                    'n n n n n n n n'
                    'n n n n n n n n'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Traffic Mix (by pageviews)`,
                    beforeSection: <BeforeSectionPageTypeDropdown />,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewTrafficSectionMenu.menu,
                    grid: `
                    'o o o o o o o o'
                    'o o o o o o o o'
                    'o o o o o o o o'
                    `,
                },
                {
                    title: (): string => `Traffic by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewTrafficByPageTypeSectionMenu.menu,
                    grid: `
                    'p p p p p p p p'
                    'p p p p p p p p'
                    'p p p p p p p p'
                    `,
                },
                {
                    title: 'Pages with Zero Pageviews',
                    grid: `
                    'q q r r s s t t'
                    `,
                },
                {
                    title: 'Homepage Reliance Analysis',
                    beforeGrid: <BeforeGridGoalMenu />,
                    grid: `
                    'u v w w x y z z'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by value)`,
                    menu: (): JSX.Element => config.context().menuContext.contentOverviewConversionMixSectionMenu.menu,
                    grid: `
                    'aa aa aa aa aa aa aa aa'
                    'aa aa aa aa aa aa aa aa'
                    'aa aa aa aa aa aa aa aa'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Conversion Mix (by goal completions)`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverviewConversionMixByGoalCompletionsSectionMenu.menu,
                    grid: `
                    'bb bb bb bb bb bb bb bb'
                    'bb bb bb bb bb bb bb bb'
                    'bb bb bb bb bb bb bb bb'
                    `,
                },
                {
                    title: (): string => `Conversions by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.contentOverViewConversionsByPageTypeSectionMenu.menu,
                    grid: `
                    'cc cc cc cc cc cc cc cc'
                    'cc cc cc cc cc cc cc cc'
                    'cc cc cc cc cc cc cc cc'
                    `,
                },
                {
                    title: 'Top Performing Pages (during selected period)',
                    menu: (): JSX.Element => config.context().menuContext.topPerformingPagesSectionMenu.menu,
                    grid: `
                    'dd dd dd dd dd dd dd dd'
                    'dd dd dd dd dd dd dd dd'
                    `,
                },
                {
                    title: (): string => `Goal Completions - Goal Summary by  ${config.pageTypeTitle()}`,
                    menu: (): JSX.Element => config.context().menuContext.goalCompletionsGoalSummarySectionMenu.menu,
                    grid: `
                    'ee ee ee ee ee ee ee ee'
                    'ee ee ee ee ee ee ee ee'
                    'ee ee ee ee ee ee ee ee'
                    `,
                },
                {
                    title: (): string =>
                        `${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceSummaryByGoalSectionMenu.menu,
                    grid: `
                    'ff ff ff ff ff ff ff ff'
                    'ff ff ff ff ff ff ff ff'
                    'ff ff ff ff ff ff ff ff'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Traffic`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeTrafficSectionMenu.menu,
                    grid: `
                    'gg gg gg gg gg gg gg gg'
                    'gg gg gg gg gg gg gg gg'
                    'gg gg gg gg gg gg gg gg'
                    `,
                },
                {
                    title: (): string => `${config.pageTypeTitle()} Performance Over Time - Conversions`,
                    menu: (): JSX.Element =>
                        config.context().menuContext.pageTypePerformanceOverTimeConversionsSectionMenu.menu,
                    grid: `
                    'hh hh hh hh hh hh hh hh'
                    'hh hh hh hh hh hh hh hh'
                    'hh hh hh hh hh hh hh hh'
                    `,
                },
            ],
        },
    },
    components: () => {
        const menuContext = useContext(ContentInsightsDashboardMenuContext);
        const {
            currentPageTypeField,
            contentOverviewTrafficSectionMenu,
            contentOverviewTrafficByPageTypeSectionMenu,
            homepageRelianceGoalsMenu,
            contentOverviewConversionMixSectionMenu,
            contentOverviewConversionMixByGoalCompletionsSectionMenu,
            contentOverViewConversionsByPageTypeSectionMenu,
            topPerformingPagesSectionMenu,
            goalCompletionsGoalSummarySectionMenu,
            pageTypePerformanceSummaryByGoalSectionMenu,
            pageTypePerformanceOverTimeTrafficSectionMenu,
            pageTypePerformanceOverTimeConversionsSectionMenu,
        } = menuContext;

        // Dates for Venn & Funnel Filters
        // This Period Dates
        // Dates
        const { startDate: startDateTime, endDate: endDateTime } = useDatesFormatted({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
        });
        const { startDate, endDate } = useDatesFormatted({ resource: resources.REPORT_CONTENT_DASHBOARD_PATH });

        // Comparison Period Dates
        const { startDate: comparisonStartDateTime, endDate: comparisonEndDateTime } = useDatesFormatted({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
            isComparison: true,
        });
        const { endDate: comparisonEndDate } = useDatesFormatted({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            isComparison: true,
        });

        // Sparklines
        const goalSnapshotData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                isGraph: true,
                groupBy: fields.SALES_DATE_DATE,
                graphGroupBy: [fields.SALES_DATE_DATE],
                graphMetrics: [
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
                selectedFields: [
                    fields.VISITS,
                    fields.COST,
                    fields.FM_SALES,
                    fields.FM_REVENUE,
                    fields.GOAL_CVR_GOAL_SNAPSHOT,
                ],
            },
        });

        // Sparkline Totals
        const sparkTotalData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
                totals: true,
                selectedFields: [fields.VISITS, fields.COST, fields.FM_SALES, fields.FM_REVENUE],
            },
        });

        const sparkTotals = useResourceTotalComparison({
            resourceData: sparkTotalData,
        });

        // Visits Sparkline
        const visitsSpark = useResourceSparkLineComparison({
            resourceData: goalSnapshotData,
            metric: fields.VISITS,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Sales Sparkline
        const salesSpark = useResourceSparkLineComparison({
            resourceData: goalSnapshotData,
            metric: fields.FM_SALES,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Revenue Sparkline
        const revenueSpark = useResourceSparkLineComparison({
            resourceData: goalSnapshotData,
            metric: fields.FM_REVENUE,
            dateDimension: fields.SALES_DATE_DATE,
        });

        // Cost Sparkline
        const costSpark = useResourceSparkLineComparison({
            resourceData: goalSnapshotData,
            metric: fields.COST,
            dateDimension: fields.SALES_DATE_DATE,
        });

        const goalSnapshotDefaultGoalTotalsData = (menu: WidgetMenuObj) =>
            useResourceComparison({
                resource: resources.REPORT_MCS_CHANNEL,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                    ...menu.request,
                    filters: [
                        ...(menu.request.filters ?? []),
                        { field: fields.PRODUCT_DEFAULT, operator: FilterOperator.EQUALS, value: '1' },
                    ],
                    selectedFields: [fields.FM_SALES, fields.FM_CVR, fields.FM_REVENUE],
                },
            });

        // Default Goal Goals Completed
        const defaultGoalTotalGoalsBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const defaultGoalTotalGoalsBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalGoalsBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Default Goal Attributed Value
        const defaultGoalTotalRevenueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const defaultGoalTotalRevenueBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalTotalRevenueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // Default Goal CR%
        const defaultGoalCRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: defaultGoalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const defaultGoalCRBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotDefaultGoalTotalsData(defaultGoalCRBigNumberMenu),
            field: fields.FM_CVR,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        const goalSnapshotBigNumberData = (menu: WidgetMenuObj) =>
            useResourceComparison({
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
                request: {
                    isGraph: true,
                    groupBy: fields.PRODUCT,
                    graphGroupBy: [fields.PRODUCT],
                    graphMetrics: [fields.VISITS, fields.FM_SALES, fields.FM_REVENUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
                    ...menu.request,
                    selectedFields: [fields.VISITS, fields.FM_SALES, fields.FM_REVENUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
                },
            });

        // Total Goals Completed
        const totalGoalsCompletedBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalGoalsCompletedBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotBigNumberData(totalGoalsCompletedBigNumberMenu),
            field: fields.FM_SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Attributed Value
        const totalAttributedValueBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalAttributedValueBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotBigNumberData(totalAttributedValueBigNumberMenu),
            field: fields.FM_REVENUE,
            aggregation: 'sum',
            formatting: 'currency',
            displayGraph: false,
        });

        // Total CR%
        const totalCVRBigNumberMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });
        const totalCVRBigNumber = useResourceBigNumberComparison({
            resourceData: goalSnapshotBigNumberData(totalCVRBigNumberMenu),
            field: fields.GOAL_CVR_GOAL_SNAPSHOT,
            aggregation: 'sum',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        // Goal Snapshot Table
        const goalSnapshotRequest = useRequestTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' }],
            fields: [fields.PRODUCT, fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
            selectedFields: [fields.GOAL_COMPLETIONS, fields.GOAL_VALUE, fields.GOAL_CVR_GOAL_SNAPSHOT],
        });

        const goalSnapshotTableData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: goalSnapshotRequest,
        });

        const goalSnapshotTotalData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_SNAPSHOT,
            request: {
                ...goalSnapshotRequest,
                totals: true,
            },
        });

        const goalSnapshotTable = useResourceTableComparison({
            resourceData: goalSnapshotTableData,
            request: goalSnapshotRequest,
            dimension: fields.PRODUCT,
        });

        const goalSnapshotTableTotals = useResourceTotalComparison({
            resourceData: goalSnapshotTotalData,
        });

        // Goal Completion Crossover Venn
        const goalCrossoverVennData = useResourceVennResourceComparison({
            dimensionResource: {
                resource: resources.REPORT_DASH_PRODUCT,
                request: {
                    ignoreDate: true,
                    groupBy: fields.PRODUCT_NAME,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                },
            },
            vennResource: {
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
                request: {
                    ignoreDate: true,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                    groupBy: fields.ID,
                    filters: [
                        { field: fields.START_DATETIME, operator: FilterOperator.EQUALS, value: startDateTime },
                        { field: fields.END_DATETIME, operator: FilterOperator.EQUALS, value: endDateTime },
                    ],
                },
            },
            vennComparisonResource: {
                resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_VENN,
                request: {
                    ignoreDate: true,
                    orderBy: [{ field: fields.ID, orderByDirection: 'asc' }],
                    groupBy: fields.ID,
                    filters: [
                        {
                            field: fields.START_DATETIME,
                            operator: FilterOperator.EQUALS,
                            value: comparisonStartDateTime,
                        },
                        { field: fields.END_DATETIME, operator: FilterOperator.EQUALS, value: comparisonEndDateTime },
                    ],
                },
            },
        });

        const goalCrossoverVenn = useResourceVennComparison({
            resourceData: goalCrossoverVennData,
            metric: fields.VISITORS,
            diagramTitle: 'Goal Completion Crossover',
        });

        // Goals Completion Complexity Funnel
        const goalCompletionComplexityFooterMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const goalCompletionComplexityFunnelData = useViewComparison({
            resource: resources.AGG_CUSTOMER_JOURNEY_COMPLEXITY,
            request: goalCompletionComplexityFooterMenu.request,
        });

        const goalCompletionComplexityFunnel = useResourceFunnelComparisonChart({
            resourceData: goalCompletionComplexityFunnelData,
            metric: fields.SALES,
        });

        // Goal Completions Stacked Area Chart
        const goalCompletionStackedAreaChartRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Count of Completions', field: fields.FM_SALES, active: true },
                { name: 'Value of Completions', field: fields.FM_REVENUE, active: false },
            ],
            groupBy: fields.PRODUCT,
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.GOAL_COMPLETIONS, orderByDirection: 'desc' },
            ],
            selectedFields: [fields.FM_SALES, fields.FM_REVENUE],
        });

        const goalCompletionsData = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: goalCompletionStackedAreaChartRequest,
        });

        const goalCompletionsAdditionalSeries = useResourceComparison({
            resource: resources.REPORT_CHANNEL_DASHBOARD_GOAL_COMPLETION,
            request: {
                groupBy: fields.SALES_DATE_DATE,
                orderBy: [{ field: fields.SALES_DATE_DATE, orderByDirection: 'asc' }],
            },
        });

        const goalCompletionStackedAreaChart = useResourceStackedAreaChartComparison({
            areaSeriesMetric: fields.PRODUCT,
            resourceData: goalCompletionsData,
            dateDimension: fields.SALES_DATE_DATE,
            optionalSeries: [
                {
                    title: 'Unique Visits',
                    yAxis: fields.VISITS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.purple,
                },
                {
                    title: 'Unique Visitors',
                    yAxis: fields.VISITORS,
                    resourceData: goalCompletionsAdditionalSeries,
                    colour: theme.sectionDashboard.colour.emerald,
                },
            ],
        });

        /***
         Title: Page Type Traffic Mix - pageviews
         Widget: Pie Chart Table
        */
        const contentOverviewRequest = useRequestPieChartTable({
            groupBy: currentPageTypeField,
            orderBy: [{ field: fields.UNIQUE_PAGE_VIEWS, orderByDirection: 'desc' }],
            fields: [currentPageTypeField, fields.CATEGORY_MIX, fields.UNIQUE_PAGE_VIEWS],
            chartField: fields.UNIQUE_PAGE_VIEWS,
            chartMetrics: [fields.UNIQUE_PAGE_VIEWS, fields.CATEGORY_MIX],
            ...contentOverviewTrafficSectionMenu.request,
            selectedFields: [currentPageTypeField, fields.UNIQUE_PAGE_VIEWS],
            rowsPerPage: 10,
        });

        const contentOverviewData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: contentOverviewRequest,
        });

        const contentOverviewByChannelTotalData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...contentOverviewRequest,
                totals: true,
            },
        });

        const contentOverviewDonutPieChartTableTotal = useResourceTotalComparison({
            resourceData: contentOverviewByChannelTotalData,
        });

        const contentOverviewViewMix = useResourceCategoryMixComparison({
            resourceData: contentOverviewData,
            totalData: contentOverviewDonutPieChartTableTotal,
            mixField: fields.UNIQUE_PAGE_VIEWS,
        });

        const contentOverviewDonutPieChartTable = useResourcePieChartTableComparison({
            resourceData: contentOverviewViewMix,
            chartDimension: currentPageTypeField,
            mixField: fields.CATEGORY_MIX,
        });

        // Content Overview
        // Traffic By Page Type Table
        const trafficByPageTypeRequest = useRequestTable({
            groupBy: currentPageTypeField,
            fields: [
                currentPageTypeField,
                fields.UNIQUE_PAGE_VIEWS,
                fields.PAGE_VIEWS,
                fields.PAGE_EXITS,
                fields.PAGE_EXIT_RATE,
                fields.LANDING_PAGE_VIEWS,
                fields.LANDING_PAGE_BOUNCES,
                fields.LANDING_PAGE_BOUNCE_RATE,
            ],
            orderBy: [{ field: fields.UNIQUE_PAGE_VIEWS, orderByDirection: 'desc' }],
            ...contentOverviewTrafficByPageTypeSectionMenu.request,
            selectedFields: [
                currentPageTypeField,
                fields.UNIQUE_PAGE_VIEWS,
                fields.PAGE_VIEWS,
                fields.PAGE_EXITS,
                fields.PAGE_EXIT_RATE,
                fields.LANDING_PAGE_VIEWS,
                fields.LANDING_PAGE_BOUNCES,
                fields.LANDING_PAGE_BOUNCE_RATE,
            ],
        });

        const trafficByPageTypeData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: trafficByPageTypeRequest,
        });

        const trafficByPageTypeTotalData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...trafficByPageTypeRequest,
                totals: true,
            },
        });

        const trafficByPageTypeTable = useResourceTableComparison({
            resourceData: trafficByPageTypeData,
            request: trafficByPageTypeRequest,
            dimension: currentPageTypeField,
        });

        const trafficByPageTypeTableTotals = useResourceTotalComparison({
            resourceData: trafficByPageTypeTotalData,
        });

        // Pages with Zero Pageviews
        //  Total Count of Indexed Pages Resource
        const totalCountOfIndexedPagesTotalsData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_DOMAIN_PERFORMANCE,
            request: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.DOMAIN],
                graphMetrics: [fields.INDEXED_PAGE_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: endDate,
                    },
                ],
                selectedFields: [fields.DOMAIN, fields.CREATED_DATETIME, fields.INDEXED_PAGE_COUNT],
            },
            comparisonRequest: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.DOMAIN],
                graphMetrics: [fields.INDEXED_PAGE_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: comparisonEndDate,
                    },
                ],
                selectedFields: [fields.DOMAIN, fields.CREATED_DATETIME, fields.INDEXED_PAGE_COUNT],
            },
        });

        // Total Count of Indexed Pages
        const totalCountOfIndexedPagesBigNumber = useResourceBigNumberComparison({
            resourceData: totalCountOfIndexedPagesTotalsData,
            field: fields.INDEXED_PAGE_COUNT,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Count of Pages viewed Resource
        const totalCountOfPagesViewedTotalsData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_DOMAIN_PERFORMANCE,
            request: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.DOMAIN],
                graphMetrics: [fields.PAGES_VIEWED_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: endDate,
                    },
                ],
                selectedFields: [fields.DOMAIN, fields.CREATED_DATETIME, fields.PAGES_VIEWED_COUNT],
            },
            comparisonRequest: {
                ignoreDate: true,
                isGraph: true,
                graphGroupBy: [fields.DOMAIN],
                graphMetrics: [fields.PAGES_VIEWED_COUNT],
                filters: [
                    {
                        field: fields.CREATED_DATETIME,
                        operator: FilterOperator.EQUALS,
                        value: comparisonEndDate,
                    },
                ],
                selectedFields: [fields.DOMAIN, fields.CREATED_DATETIME, fields.PAGES_VIEWED_COUNT],
            },
        });

        // Total Count of Pages viewed
        const totalCountOfPageViewedBigNumber = useResourceBigNumberComparison({
            resourceData: totalCountOfPagesViewedTotalsData,
            field: fields.PAGES_VIEWED_COUNT,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Count of Pages Zero Views
        const totalCountOfPageZeroViewsBigNumber = useResourceBigNumberComparison({
            resourceData: totalCountOfIndexedPagesTotalsData,
            aggregationSumData: totalCountOfPagesViewedTotalsData,
            field: fields.INDEXED_PAGE_COUNT,
            aggregationSumField: fields.PAGES_VIEWED_COUNT,
            aggregation: 'difference',
            displayGraph: false,
        });

        // Browsed : Unbrowsed Ratio
        const browsedVsUnbrowsedRatioBigNumber = useResourceBigNumberComparison({
            resourceData: totalCountOfPagesViewedTotalsData,
            aggregationSumData: totalCountOfIndexedPagesTotalsData,
            field: fields.PAGES_VIEWED_COUNT,
            aggregationSumField: fields.INDEXED_PAGE_COUNT,
            aggregation: 'ratio',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 0,
            },
            displayGraph: false,
        });

        /***
         Title: Homepage Reliance Analysis section - Traffic
         Widget: Big Number
        */
        // All Page Resource
        const allpageRelianceAnalysisTrafficData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_SUBDIRECTORY],
                graphMetrics: [fields.UNIQUE_PAGE_VIEWS],
                selectedFields: [fields.PATH_SUBDIRECTORY, fields.UNIQUE_PAGE_VIEWS],
            },
        });

        // Home Page Resource
        const homepagePathsRelianceAnalysisTrafficData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.UNIQUE_PAGE_VIEWS],
                orFilters: [
                    { field: fields.PATH_CATEGORY, operator: FilterOperator.EQUALS, value: 'Homepage' },
                    { field: fields.PATH_PATH, operator: FilterOperator.EQUALS, value: '/' },
                    { field: fields.PATH_SUBDIRECTORY, operator: FilterOperator.EQUALS, value: 'home' },
                    { field: fields.PATH_HOMEPAGE, operator: FilterOperator.EQUALS, value: '1' },
                ],
            },
        });

        // Combine the Home and '/' Root page resources
        const homepageRelianceAnalysisTrafficData = useResourceCombineBigNumberRequestsComparison({
            resourceData: [homepagePathsRelianceAnalysisTrafficData],
        });

        // Total Page Views (All Pages)
        const totalAllPagesVieswTrafficBigNumber = useResourceBigNumberComparison({
            resourceData: allpageRelianceAnalysisTrafficData,
            field: fields.UNIQUE_PAGE_VIEWS,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Page Views (Home Page)
        const totalHomePageViewsTrafficBigNumber = useResourceBigNumberComparison({
            resourceData: homepageRelianceAnalysisTrafficData,
            field: fields.UNIQUE_PAGE_VIEWS,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Page Views (Home Page) / Total Page Views (All Pages)
        const totalAllPagesVsHomePageViewsTrafficBigNumber = useResourceBigNumberComparison({
            resourceData: homepageRelianceAnalysisTrafficData,
            aggregationSumData: allpageRelianceAnalysisTrafficData,
            field: fields.UNIQUE_PAGE_VIEWS,
            aggregation: 'mix',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        /***
         Title: Homepage Reliance Analysis section - Goals
         Widget: Big Number
        */
        // All Page Resource
        const totalGoalCompletionsData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_SUBDIRECTORY],
                graphMetrics: [fields.SALES],
                ...homepageRelianceGoalsMenu.request,
                selectedFields: [fields.PATH_SUBDIRECTORY, fields.SALES],
            },
        });

        // Home Page Resource
        const homepageHomeGoalCompletionsData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                isGraph: true,
                graphGroupBy: [fields.PATH_PATH],
                graphMetrics: [fields.SALES],
                orFilters: [
                    { field: fields.PATH_CATEGORY, operator: FilterOperator.EQUALS, value: 'Homepage' },
                    { field: fields.PATH_SUBDIRECTORY, operator: FilterOperator.EQUALS, value: 'home' },
                    { field: fields.PATH_PATH, operator: FilterOperator.EQUALS, value: '/' },
                    { field: fields.PATH_HOMEPAGE, operator: FilterOperator.EQUALS, value: '1' },
                ],
                ...homepageRelianceGoalsMenu.request,
            },
        });

        // Combine the Home and '/' Root page resources
        const homepageGoalCompletionsData = useResourceCombineBigNumberRequestsComparison({
            resourceData: [homepageHomeGoalCompletionsData],
        });

        // Total Page Views (All Pages)
        const totalGoalCompletionsBigNumber = useResourceBigNumberComparison({
            resourceData: totalGoalCompletionsData,
            field: fields.SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Page Views (Home Page)
        const totalHomePageGoalCompletionsBigNumber = useResourceBigNumberComparison({
            resourceData: homepageGoalCompletionsData,
            field: fields.SALES,
            aggregation: 'sum',
            displayGraph: false,
        });

        // Total Page Views (Home Page) / Total Page Views (All Pages)
        const totalAllPagesVsHomePageViewsGoalCompletionsBigNumber = useResourceBigNumberComparison({
            resourceData: homepageGoalCompletionsData,
            aggregationSumData: totalGoalCompletionsData,
            field: fields.SALES,
            aggregation: 'mix',
            formatting: 'percentage',
            rounding: {
                applyRounding: true,
                decimalPoints: 2,
            },
            displayGraph: false,
        });

        /***
         Title: Page Type Conversion Mix (by goal value)
         Widget: Pie Chart Table
        */
        const contentOverviewConversionsGoalMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const contentOverviewConversionsRequest = useRequestPieChartTable({
            groupBy: currentPageTypeField,
            orderBy: [{ field: fields.CONVERSION_VALUE, orderByDirection: 'desc' }],
            fields: [currentPageTypeField, fields.CATEGORY_MIX, fields.CONVERSION_VALUE],
            chartField: fields.CONVERSION_VALUE,
            chartMetrics: [fields.CONVERSION_VALUE, fields.CATEGORY_MIX],
            rowsPerPage: 10,
            ...contentOverviewConversionsGoalMenu.request,
            ...contentOverviewConversionMixSectionMenu.request,
            selectedFields: [currentPageTypeField, fields.CONVERSION_VALUE],
        });

        const contentOverviewConversionsData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: contentOverviewConversionsRequest,
        });

        const contentOverviewConversionsTotalData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...contentOverviewConversionsRequest,
                totals: true,
            },
        });

        const contentOverviewConversionsTotals = useResourceTotalComparison({
            resourceData: contentOverviewConversionsTotalData,
        });

        const contentOverviewMixGoalValue = useResourceCategoryMixComparison({
            resourceData: contentOverviewConversionsData,
            totalData: contentOverviewConversionsTotals,
            mixField: fields.CONVERSION_VALUE,
        });

        const contentOverviewMixGoalValueDonutPieChartTable = useResourcePieChartTableComparison({
            resourceData: contentOverviewMixGoalValue,
            chartDimension: currentPageTypeField,
            mixField: fields.CATEGORY_MIX,
        });

        //Page Type Conversion Mix (by goal completions)
        const contentOverviewCompletionsGoalMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
            includeAllOption: true,
        });

        const contentOverviewCompletionsRequest = useRequestPieChartTable({
            groupBy: currentPageTypeField,
            orderBy: [{ field: fields.CONVERSIONS, orderByDirection: 'desc' }],
            fields: [currentPageTypeField, fields.CATEGORY_MIX, fields.CONVERSIONS],
            chartField: fields.CONVERSIONS,
            chartMetrics: [fields.CONVERSIONS, fields.CATEGORY_MIX],
            rowsPerPage: 10,
            ...contentOverviewCompletionsGoalMenu.request,
            ...contentOverviewConversionMixByGoalCompletionsSectionMenu.request,
            selectedFields: [currentPageTypeField, fields.CONVERSIONS],
        });

        const contentOverviewCompletionsData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: contentOverviewCompletionsRequest,
        });

        const contentOverviewCompletionsTotalData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...contentOverviewCompletionsRequest,
                totals: true,
            },
        });

        const contentOverviewCompletionsTotals = useResourceTotalComparison({
            resourceData: contentOverviewCompletionsTotalData,
        });

        const contentOverviewCompletionsMixGoalValue = useResourceCategoryMixComparison({
            resourceData: contentOverviewCompletionsData,
            totalData: contentOverviewCompletionsTotals,
            mixField: fields.CONVERSIONS,
        });

        const contentOverviewCompletionsMixGoalValuePieChartTable = useResourcePieChartTableComparison({
            resourceData: contentOverviewCompletionsMixGoalValue,
            chartDimension: currentPageTypeField,
            mixField: fields.CATEGORY_MIX,
        });

        // Content Overview
        // Conversions By Page Type Table
        const conversionsByPageTypeRequest = useRequestTable({
            groupBy: currentPageTypeField,
            fields: [
                currentPageTypeField,
                fields.UNIQUE_PAGE_VIEWS,
                fields.ASSISTS,
                fields.ASSISTS_PAGE_VIEWS_RATE,
                fields.CONVERSIONS,
                fields.CONVERSIONS_CVR,
                fields.CONVERSION_VALUE,
            ],
            orderBy: [{ field: fields.CONVERSION_VALUE, orderByDirection: 'desc' }],
            ...contentOverViewConversionsByPageTypeSectionMenu.request,
            selectedFields: [
                currentPageTypeField,
                fields.UNIQUE_PAGE_VIEWS,
                fields.ASSISTS,
                fields.ASSISTS_PAGE_VIEWS_RATE,
                fields.CONVERSIONS,
                fields.CONVERSIONS_CVR,
                fields.CONVERSION_VALUE,
            ],
        });

        const conversionsByPageTypeData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: conversionsByPageTypeRequest,
        });

        const conversionsByPageTypeTotalData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...conversionsByPageTypeRequest,
                totals: true,
            },
        });

        const conversionsByPageTypeTable = useResourceTableComparison({
            resourceData: conversionsByPageTypeData,
            request: conversionsByPageTypeRequest,
            dimension: currentPageTypeField,
        });

        const conversionsByPageTypeTableTotals = useResourceTotalComparison({
            resourceData: conversionsByPageTypeTotalData,
        });

        // Top Performing Pages Table
        const pageGroupMostVisitedData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.PAGE_VIEWS, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const groupGreatestConversions = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.CONVERSIONS, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const groupGreatestValue = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: currentPageTypeField,
                orderBy: [{ field: fields.CONVERSION_VALUE, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const pageMostVisitedData = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: fields.PATH_PATH,
                orderBy: [{ field: fields.UNIQUE_PAGE_VIEWS, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const pageGreatestConversions = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: fields.PATH_PATH,
                orderBy: [{ field: fields.CONVERSIONS, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const pageGreatestValue = useResource({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                groupBy: fields.PATH_PATH,
                orderBy: [{ field: fields.CONVERSION_VALUE, orderByDirection: 'desc' }],
                limit: 1,
                ...topPerformingPagesSectionMenu.request,
            },
        });

        const topPerformingPagesTable = useResourceTableCustomGroups({
            fields: [fields.UNIQUE_PAGE_VIEWS, fields.CONVERSIONS, fields.CONVERSION_VALUE],
            descriptionTitle: 'Performance Metric',
            dimensionTitle: 'Page URL',
            groups: [
                {
                    title: 'Page Type Groupings',
                    dimensionField: currentPageTypeField,
                    rows: [
                        {
                            description: 'Most Visited Page Type Group',
                            resourceData: pageGroupMostVisitedData,
                        },
                        {
                            description: 'Group driving greatest count of conversions',
                            resourceData: groupGreatestConversions,
                        },
                        {
                            description: 'Group driving greatest value of conversions',
                            resourceData: groupGreatestValue,
                        },
                    ],
                },
                {
                    title: 'Individual Pages',
                    dimensionField: fields.PATH_PATH,
                    rows: [
                        {
                            description: 'Most Visited Page',
                            resourceData: pageMostVisitedData,
                        },
                        {
                            description: 'Page driving greatest count of conversions',
                            resourceData: pageGreatestConversions,
                        },
                        {
                            description: 'Page driving greatest value of conversions',
                            resourceData: pageGreatestValue,
                        },
                    ],
                },
            ],
        });

        // Goal Completions - Goal Summary by Page Type - Donut/Table
        const goalCompletionsGoalByPageMenu = useWidgetMenuFooterQuerySelect({
            query: goalsDropdownQuery,
            dropdownField: fields.PRODUCT,
        });

        const goalCompletionsGoalByPageRequest = useRequestPieChartTable({
            groupBy: currentPageTypeField,
            orderBy: [{ field: fields.CONVERSIONS, orderByDirection: 'desc' }],
            fields: [
                currentPageTypeField,
                fields.PAGE_VIEWS,
                fields.UNIQUE_PAGE_VIEWS,
                fields.CONVERSIONS,
                fields.REVENUE,
            ],
            chartField: fields.CONVERSIONS,
            chartMetrics: [fields.PAGE_VIEWS, fields.UNIQUE_PAGE_VIEWS, fields.CONVERSIONS, fields.REVENUE],
            ...goalCompletionsGoalByPageMenu.request,
            ...goalCompletionsGoalSummarySectionMenu.request,
            rowsPerPage: 10,
            selectedFields: [
                currentPageTypeField,
                fields.PAGE_VIEWS,
                fields.UNIQUE_PAGE_VIEWS,
                fields.CONVERSIONS,
                fields.REVENUE,
            ],
        });

        const goalCompletionsGoalByPageData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: goalCompletionsGoalByPageRequest,
        });

        const goalCompletionsGoalByPageTotalData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...goalCompletionsGoalByPageRequest,
                totals: true,
            },
        });

        const goalCompletionsGoalByPageDonutTable = useResourcePieChartTableComparison({
            resourceData: goalCompletionsGoalByPageData,
            chartDimension: currentPageTypeField,
        });

        const goalCompletionsGoalByPageDonutTableTotal = useResourceTotalComparison({
            resourceData: goalCompletionsGoalByPageTotalData,
        });

        // Page Type Performance - Page Type Summary By Goal
        const pageTypePerformancePageByGoalMenu = useWidgetMenuFooterQuerySelect({
            query: contentInsightsPageTypeDropdownQuery({
                currentPageTypeField: currentPageTypeField,
                startDate: startDate,
                endDate: endDate,
                excludeUnknown: pageTypePerformanceSummaryByGoalSectionMenu.checked,
            }),
            dropdownField: currentPageTypeField,
        });

        const pageTypePerformancePageByGoalRequest = useRequestPieChartTable({
            groupBy: fields.PRODUCT,
            orderBy: [{ field: fields.CONVERSIONS, orderByDirection: 'desc' }],
            fields: [
                fields.PRODUCT,
                fields.VISITS,
                fields.PAGE_VIEWS,
                fields.UNIQUE_PAGE_VIEWS,
                fields.CONVERSIONS,
                fields.REVENUE,
            ],
            chartField: fields.CONVERSIONS,
            chartMetrics: [
                fields.VISITS,
                fields.PAGE_VIEWS,
                fields.UNIQUE_PAGE_VIEWS,
                fields.CONVERSIONS,
                fields.REVENUE,
            ],
            ...pageTypePerformancePageByGoalMenu.request,
            ...pageTypePerformanceSummaryByGoalSectionMenu.request,
            selectedFields: [
                fields.PRODUCT,
                fields.VISITS,
                fields.PAGE_VIEWS,
                fields.UNIQUE_PAGE_VIEWS,
                fields.CONVERSIONS,
                fields.REVENUE,
            ],
            rowsPerPage: 10,
        });

        const pageTypePerformancePageByGoalData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: pageTypePerformancePageByGoalRequest,
        });

        const pageTypePerformancePageByGoalTotalData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: {
                ...pageTypePerformancePageByGoalRequest,
                totals: true,
            },
        });

        const pageTypePerformancePageByGoalDonutTable = useResourcePieChartTableComparison({
            resourceData: pageTypePerformancePageByGoalData,
            chartDimension: fields.PRODUCT,
        });

        const pageTypePerformancePageByGoalDonutTableTotal = useResourceTotalComparison({
            resourceData: pageTypePerformancePageByGoalTotalData,
        });

        // Page Type Performance Over Time - Traffic - Stacked Area Chart
        const pageTypePerformanceOverTimeTrafficRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Page Views', field: fields.PAGE_VIEWS, active: true },
                { name: 'Unique Page Views', field: fields.UNIQUE_PAGE_VIEWS, active: false },
            ],
            groupBy: [currentPageTypeField, fields.SALES_DATE_DATE],
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.PAGE_VIEWS, orderByDirection: 'desc' },
            ],
            ...pageTypePerformanceOverTimeTrafficSectionMenu.request,
            selectedFields: [currentPageTypeField, fields.SALES_DATE_DATE, fields.PAGE_VIEWS, fields.UNIQUE_PAGE_VIEWS],
        });

        const pageTypePerformanceOverTimeTrafficData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: pageTypePerformanceOverTimeTrafficRequest,
        });

        const pageTypePerformanceOverTimeTraffic = useResourceStackedAreaChartComparison({
            areaSeriesMetric: currentPageTypeField,
            resourceData: pageTypePerformanceOverTimeTrafficData,
            dateDimension: fields.SALES_DATE_DATE,
            seriesLimit: 10,
        });

        // Page Type Performance Over Time - Conversions - Stacked Area Chart
        const pageTypePerformanceOverTimeConversionsRequest = useRequestStackedAreaChart({
            yAxisOptions: [
                { name: 'Goal Completions', field: fields.CONVERSIONS, active: true },
                { name: 'Conversion Value', field: fields.CONVERSION_VALUE, active: false },
            ],
            groupBy: [currentPageTypeField, fields.SALES_DATE_DATE],
            orderBy: [
                { field: fields.SALES_DATE_DATE, orderByDirection: 'asc' },
                { field: fields.CONVERSIONS, orderByDirection: 'desc' },
            ],
            ...pageTypePerformanceOverTimeConversionsSectionMenu.request,
            selectedFields: [currentPageTypeField, fields.SALES_DATE_DATE, fields.CONVERSIONS, fields.CONVERSION_VALUE],
        });

        const pageTypePerformanceOverTimeConversionsData = useResourceComparison({
            resource: resources.REPORT_CONTENT_DASHBOARD_PATH,
            request: pageTypePerformanceOverTimeConversionsRequest,
        });

        const pageTypePerformanceOverTimeConversions = useResourceStackedAreaChartComparison({
            areaSeriesMetric: currentPageTypeField,
            resourceData: pageTypePerformanceOverTimeConversionsData,
            dateDimension: fields.SALES_DATE_DATE,
            seriesLimit: 10,
        });

        return {
            a: (
                <WidgetSparkLineComparison
                    title="Visits by Day"
                    data={visitsSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Teal}
                />
            ),
            b: (
                <WidgetSparkLineComparison
                    title="Sales by Day"
                    data={salesSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Orange}
                />
            ),
            c: (
                <WidgetSparkLineComparison
                    title="Revenue by Day"
                    data={revenueSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Green}
                />
            ),
            d: (
                <WidgetSparkLineComparison
                    title="Cost by Day"
                    data={costSpark}
                    totalData={sparkTotals}
                    colour={WidgetColour.Rose}
                />
            ),
            e: (
                <WidgetBigNumberComparison
                    title="Default Goal Goals Completed"
                    data={defaultGoalTotalGoalsBigNumber}
                    menu={defaultGoalTotalGoalsBigNumberMenu.menu}
                />
            ),
            f: (
                <WidgetBigNumberComparison
                    title="Default Goal Attributed Value"
                    data={defaultGoalTotalRevenueBigNumber}
                    menu={defaultGoalTotalRevenueBigNumberMenu.menu}
                />
            ),
            g: (
                <WidgetBigNumberComparison
                    title="Default Goal CR%"
                    data={defaultGoalCRBigNumber}
                    menu={defaultGoalCRBigNumberMenu.menu}
                />
            ),
            h: (
                <WidgetTableComparison
                    title="Goals Snapshot"
                    data={goalSnapshotTable}
                    totalData={goalSnapshotTableTotals}
                    menu={<WidgetMenuTable data={goalSnapshotTable} />}
                />
            ),
            i: (
                <WidgetBigNumberComparison
                    title="Total Goals Completed"
                    data={totalGoalsCompletedBigNumber}
                    menu={totalGoalsCompletedBigNumberMenu.menu}
                />
            ),
            j: (
                <WidgetBigNumberComparison
                    title="Total Attributed Value"
                    data={totalAttributedValueBigNumber}
                    menu={totalAttributedValueBigNumberMenu.menu}
                />
            ),
            k: (
                <WidgetBigNumberComparison
                    title="All Goals CR%"
                    data={totalCVRBigNumber}
                    menu={totalCVRBigNumberMenu.menu}
                />
            ),
            l: <WidgetVennComparison title="Goal Completion Crossover" data={goalCrossoverVenn} />,
            m: (
                <WidgetFunnelChartComparison
                    title="Goal Completion Complexity"
                    data={goalCompletionComplexityFunnel}
                    menu={goalCompletionComplexityFooterMenu.menu}
                />
            ),
            n: (
                <WidgetStackedAreaChartComparison
                    title="Goal Completions Over Time"
                    data={goalCompletionStackedAreaChart}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={goalCompletionStackedAreaChart}
                            request={goalCompletionStackedAreaChartRequest}
                        />
                    }
                />
            ),
            o: (
                <WidgetPieChartTableComparison
                    title={`${config.pageTypeTitle()} Traffic Mix (by pageviews)`}
                    data={contentOverviewDonutPieChartTable}
                    totalData={contentOverviewDonutPieChartTableTotal}
                    href=""
                    display="donut"
                    menu={<WidgetMenuPieChartTable data={contentOverviewDonutPieChartTable} />}
                />
            ),
            p: (
                <WidgetTableComparison
                    title={`Traffic By ${config.pageTypeTitle()}`}
                    data={trafficByPageTypeTable}
                    totalData={trafficByPageTypeTableTotals}
                    menu={<WidgetMenuTable data={trafficByPageTypeTable} />}
                />
            ),
            q: (
                <WidgetBigNumberComparison
                    title="Total Count of Indexed Pages"
                    data={totalCountOfIndexedPagesBigNumber}
                />
            ),
            r: <WidgetBigNumberComparison title="Total Count of Pages viewed" data={totalCountOfPageViewedBigNumber} />,
            s: (
                <WidgetBigNumberComparison
                    title="Total Count of Pages with Zero Views"
                    data={totalCountOfPageZeroViewsBigNumber}
                    invertVariance={true}
                />
            ),
            t: <WidgetBigNumberComparison title="Browsed : Unbrowsed Ratio" data={browsedVsUnbrowsedRatioBigNumber} />,
            u: <WidgetBigNumberComparison title="Total Pageviews" data={totalAllPagesVieswTrafficBigNumber} />,
            v: <WidgetBigNumberComparison title="Homepage Pageviews" data={totalHomePageViewsTrafficBigNumber} />,
            w: (
                <WidgetBigNumberComparison
                    title="Homepage Pageview Mix"
                    data={totalAllPagesVsHomePageViewsTrafficBigNumber}
                />
            ),
            x: <WidgetBigNumberComparison title="Total Goal Completions" data={totalGoalCompletionsBigNumber} />,
            y: (
                <WidgetBigNumberComparison
                    title="Homepage Goal completions"
                    data={totalHomePageGoalCompletionsBigNumber}
                />
            ),
            z: (
                <WidgetBigNumberComparison
                    title="Homepage Goal Completion Mix"
                    data={totalAllPagesVsHomePageViewsGoalCompletionsBigNumber}
                />
            ),
            aa: (
                <WidgetPieChartTableComparison
                    title={`${config.pageTypeTitle()} Conversion Mix (by value)`}
                    data={contentOverviewMixGoalValueDonutPieChartTable}
                    totalData={contentOverviewConversionsTotals}
                    href=""
                    display="donut"
                    menu={
                        <>
                            {contentOverviewConversionsGoalMenu.menu}
                            <WidgetMenuPieChartTable data={contentOverviewMixGoalValueDonutPieChartTable} />
                        </>
                    }
                />
            ),
            bb: (
                <WidgetPieChartTableComparison
                    title={`${config.pageTypeTitle()} Conversion Mix (by goal completions)`}
                    data={contentOverviewCompletionsMixGoalValuePieChartTable}
                    totalData={contentOverviewCompletionsTotals}
                    href=""
                    display="donut"
                    menu={
                        <>
                            {contentOverviewCompletionsGoalMenu.menu}
                            <WidgetMenuPieChartTable data={contentOverviewCompletionsMixGoalValuePieChartTable} />
                        </>
                    }
                />
            ),
            cc: (
                <WidgetTableComparison
                    title={`Conversions By ${config.pageTypeTitle()}`}
                    data={conversionsByPageTypeTable}
                    totalData={conversionsByPageTypeTableTotals}
                    menu={<WidgetMenuTable data={conversionsByPageTypeTable} />}
                />
            ),
            dd: <WidgetTable title="Top Performing Pages (during selected period)" data={topPerformingPagesTable} />,
            ee: (
                <WidgetPieChartTableComparison
                    title={`Goal Completions - Goal Summary by ${config.pageTypeTitle()}`}
                    data={goalCompletionsGoalByPageDonutTable}
                    totalData={goalCompletionsGoalByPageDonutTableTotal}
                    href=""
                    display="donut"
                    menu={
                        <>
                            {goalCompletionsGoalByPageMenu.menu}
                            <WidgetMenuPieChartTable data={goalCompletionsGoalByPageDonutTable} />
                        </>
                    }
                />
            ),
            ff: (
                <WidgetPieChartTableComparison
                    title={`${config.pageTypeTitle()} Performance - ${config.pageTypeTitle()} Summary By Goal`}
                    data={pageTypePerformancePageByGoalDonutTable}
                    totalData={pageTypePerformancePageByGoalDonutTableTotal}
                    href=""
                    display="donut"
                    menu={
                        <>
                            {pageTypePerformancePageByGoalMenu.menu}
                            <WidgetMenuPieChartTable data={pageTypePerformancePageByGoalDonutTable} />
                        </>
                    }
                />
            ),
            gg: (
                <WidgetStackedAreaChartComparison
                    title={`${config.pageTypeTitle()} Performance Over Time - Traffic`}
                    data={pageTypePerformanceOverTimeTraffic}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={pageTypePerformanceOverTimeTraffic}
                            request={pageTypePerformanceOverTimeTrafficRequest}
                        />
                    }
                />
            ),
            hh: (
                <WidgetStackedAreaChartComparison
                    title={`${config.pageTypeTitle()} Performance Over Time - Conversions`}
                    data={pageTypePerformanceOverTimeConversions}
                    menu={
                        <WidgetMenuStackedAreaChart
                            data={pageTypePerformanceOverTimeConversions}
                            request={pageTypePerformanceOverTimeConversionsRequest}
                        />
                    }
                />
            ),
        };
    },
};
