import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Config
import { CONFIG_KWR_BLACKLISTED_KEYWORD } from '../../../configurations/resources-config';

// Hooks
import useFetchResource from '../../../react-query/hooks/use-fetch-resource';
import usePatchMultiResourceWithPayload from '../../../react-query/hooks/use-patch-multi-resource-with-payload';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal, setModal } from '../../../redux/actions/modal';
import { removePopup, setPopup } from '../../../redux/actions/popup';
import { addNotification } from '../../../redux/actions/notification';

// Components
import ModalNavigation from '../../../components/modal-navigation';
import ConfigTable from '../../../components/tables/config-table/config-table';
import Form from '../../../components/forms/form';
import FormEditBlacklistedKeyword from './forms/form-edit-blacklisted-keyword';

// Types & Enums
import { ButtonThemes } from '../../../enums/button-themes';
import { ConfigDataSuccess } from '../../../react-query/types';
import { FieldValues } from 'react-hook-form';

// Helpers
import { generatePath } from '../../../helpers/request-builder';

const StyledEditContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 10px 20px;
    margin-top: 20px;
`;

type BlacklistKeywords = {
    id: number;
    keyword: string;
    market: string;
    marketId: number;
    type: string;
    typeId: number;
    selected: boolean;
};

const LayoutModalManageBlacklistedKeywords = () => {
    const dispatch = useDispatch();

    const [keywords, setKeywords] = useState<BlacklistKeywords[]>([]);
    const [keywordToEdit, setKeywordToEdit] = useState<BlacklistKeywords>();

    // Queries
    const blacklistedKeywordsQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_KWR_BLACKLISTED_KEYWORD,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
    });

    useEffect(() => {
        if (blacklistedKeywordsQuery.data) {
            setKeywords(
                blacklistedKeywordsQuery?.data?.objects.map(object => {
                    return {
                        id: object.id,
                        keyword: object.keyword,
                        market: object.market.country,
                        marketId: object.market.id,
                        type: object.type.type,
                        typeId: object.type.id,
                        selected: false,
                    };
                })
            );
        }
    }, [blacklistedKeywordsQuery.data]);

    // Mutations
    const blacklistedKeywordDeleteMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_KWR_BLACKLISTED_KEYWORD,
        handleOnSuccess: () => {
            dispatch(addNotification({ copy: 'Keywords removed from blacklist', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue removing keywords from blacklist, please try again later',
                    type: 'error',
                })
            );
        },
    });

    const blacklistedKeywordPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_KWR_BLACKLISTED_KEYWORD,
        handleOnSuccess: () => {
            setKeywordToEdit(undefined);
            dispatch(addNotification({ copy: 'Blacklisted keyword successfully updated', type: 'success' }));
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue updating this keyword, please try again later',
                    type: 'error',
                })
            );
        },
    });

    // Handlers
    const handleCheckboxChange = (rowId: number) => {
        const updatedKeywords = keywords.map(keyword => {
            if (keyword.id === rowId) {
                keyword.selected = !keyword.selected;
            }
            return keyword;
        });
        setKeywords(updatedKeywords);
    };

    // Bulk Delete
    const handleBulkDelete = () => {
        dispatch(removePopup());
        keywords
            .filter(keyword => keyword.selected)
            .forEach(keyword => {
                blacklistedKeywordDeleteMutation.mutate({
                    resourceId: [keyword.id.toString()],
                    payload: [
                        {
                            active: 0,
                        },
                    ],
                });
            });
    };

    const handleSingleDelete = (rowId: number) => {
        dispatch(removePopup());

        blacklistedKeywordDeleteMutation.mutate({
            resourceId: [rowId.toString()],
            payload: [
                {
                    active: 0,
                },
            ],
        });
    };

    const handleBulkDeleteClick = () => {
        dispatch(
            setPopup({
                title: 'Delete Blacklisted Keywords',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove these keywords from the blacklist?',
                },
                buttons: [
                    {
                        onClick: handleBulkDelete,
                        value: 'Yes, Remove',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    const handleSingleDeleteClick = (rowId: number) => {
        dispatch(
            setPopup({
                title: 'Delete Blacklisted Keyword',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to remove this keywords from the blacklist?',
                },
                buttons: [
                    {
                        onClick: () => handleSingleDelete(rowId),
                        value: 'Yes, Remove',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'Cancel',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    // Edit Keyword
    const handleSubmit = (data: FieldValues) => {
        if (keywordToEdit) {
            blacklistedKeywordPatchMutation.mutate({
                resourceId: [keywordToEdit.id?.toString()],
                payload: [
                    {
                        keyword: data.keyword,
                        market: generatePath('config', 'seogd-market', data.market),
                        type: generatePath('config', 'keyword-type', data.type),
                    },
                ],
            });
        }
    };

    return (
        <>
            <ModalNavigation
                buttons={[
                    {
                        value: 'CLOSE',
                        onClick: () => dispatch(removeModal()),
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Manage Blacklisted Keywords</h2>

            <ConfigTable
                status={blacklistedKeywordsQuery.status}
                isFetching={blacklistedKeywordsQuery.isFetching || blacklistedKeywordDeleteMutation.isPending}
                disabled={blacklistedKeywordDeleteMutation.isPending}
                empty={blacklistedKeywordsQuery?.data?.objects.length === 0}
            >
                <ConfigTable.Table>
                    <ConfigTable.Header>
                        <ConfigTable.Row key="blacklist-header">
                            <ConfigTable.CellHeader />
                            <ConfigTable.CellHeader>Blacklisted Keyword</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Market</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader>Keyword Type</ConfigTable.CellHeader>
                            <ConfigTable.CellHeader />
                        </ConfigTable.Row>
                    </ConfigTable.Header>
                    <ConfigTable.Body>
                        {keywords.map(keyword => (
                            <ConfigTable.Row key={keyword.id}>
                                <ConfigTable.CellCheckbox
                                    rowId={keyword.id}
                                    checked={keyword.selected}
                                    onCheckedChange={handleCheckboxChange}
                                />
                                <ConfigTable.Cell>{keyword.keyword}</ConfigTable.Cell>
                                <ConfigTable.Cell>{keyword.market}</ConfigTable.Cell>
                                <ConfigTable.Cell>{keyword.type}</ConfigTable.Cell>
                                <ConfigTable.CellActions>
                                    <ConfigTable.ActionDropdownItem
                                        rowId={keyword.id}
                                        type="edit"
                                        onClick={rowId =>
                                            setKeywordToEdit(keywords.find(keyword => keyword.id === rowId))
                                        }
                                    />
                                    <ConfigTable.ActionDropdownItem
                                        rowId={keyword.id}
                                        type="delete"
                                        label="Remove"
                                        onClick={handleSingleDeleteClick}
                                    />
                                </ConfigTable.CellActions>
                            </ConfigTable.Row>
                        ))}
                    </ConfigTable.Body>
                </ConfigTable.Table>
                <ConfigTable.ActionBar>
                    <ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionButton
                            type="delete"
                            label="Remove"
                            onClick={handleBulkDeleteClick}
                            isDisabled={keywords.filter(keyword => keyword.selected).length === 0}
                        />
                    </ConfigTable.ActionBarJustifyLeft>
                    <ConfigTable.ActionBarJustifyRight>
                        <ConfigTable.ActionButton
                            type="add"
                            label="Add Keywords to Blacklist"
                            onClick={() => dispatch(setModal('AddBlacklistedKeywords', {}))}
                        />
                    </ConfigTable.ActionBarJustifyRight>
                </ConfigTable.ActionBar>
            </ConfigTable>

            {keywordToEdit ? (
                <StyledEditContainer>
                    <h2>Edit Blacklisted Keyword</h2>

                    <Form
                        status={blacklistedKeywordPatchMutation.isPending ? 'disabled' : 'enabled'}
                        onSubmit={handleSubmit}
                        defaultValues={{
                            keyword: keywordToEdit.keyword,
                            market: keywordToEdit.marketId,
                            type: keywordToEdit.typeId,
                        }}
                    >
                        <FormEditBlacklistedKeyword />
                        <Form.Footer>
                            <Form.InputButton
                                type="submit"
                                value="Save"
                                loading={blacklistedKeywordPatchMutation.isPending}
                            />
                            <Form.InputButton
                                type="button"
                                value="Cancel"
                                onClick={() => setKeywordToEdit(undefined)}
                                buttonTheme={ButtonThemes.Secondary}
                            />
                        </Form.Footer>
                    </Form>
                </StyledEditContainer>
            ) : null}
        </>
    );
};

export default LayoutModalManageBlacklistedKeywords;
