import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//Components
import Button from '../components/inputs/input-button';
import ViewMenu from './menu';

// Redux
import { connect } from 'react-redux';
import { setDisplayMenu } from '../redux/actions/page-meta';

// Images
import imageLoginBackground from '../assets/images/bg-login.jpg';
import iconHandCloud from '../assets/images/icons/hand-cloud-icon.png';

class ErrorPageNotFound extends Component {
    componentDidMount() {
        if (this.props.account.token === '') {
            this.props.setDisplayMenu(false);
        }
    }

    render() {
        return (
            <div className="error-view">
                {this.props.user.isLoggedIn === true && (
                    <ViewMenu history={this.props.history} upperMenuClassName="light" />
                )}
                <img src={imageLoginBackground} alt="Background" />
                <div className="error-view__flex-wrapper">
                    <div className="error-view__content">
                        <h1>404 - PAGE NOT FOUND</h1>
                        <p>
                            Sorry the page you were looking for <br />
                            could not be found.
                        </p>
                        {this.props.user.isLoggedIn === true ? (
                            <Link to={'/' + this.props.account.token}>
                                <Button value="BACK TO DASHBOARD" />
                            </Link>
                        ) : (
                            <Link to="/">
                                <Button value="BACK TO HOME" />
                            </Link>
                        )}
                    </div>
                    <div className="error-view__icon">
                        <img src={iconHandCloud} alt="hand-cloud" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        user: state.user,
        account: state.account,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setDisplayMenu: displayMenu => {
            dispatch(setDisplayMenu(displayMenu));
        },
    };
};

export default connect(mapStatesToProps, mapDispatchToProps)(ErrorPageNotFound);
