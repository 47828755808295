import React, { Component } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    width: 90%;
    min-height: 200px;
    margin: 24px;
    position: relative;
`;

const StyledBlockSkeleton = styled.div`
    ${props => props.theme.skeletonLoader}
    position: relative;
    width: 100%;
    height: 42px;
    margin: 12px;
`;

class SkeletonTimeline extends Component {
    render() {
        return (
            <StyledContainer>
                <StyledBlockSkeleton></StyledBlockSkeleton>
                <StyledBlockSkeleton></StyledBlockSkeleton>
                <StyledBlockSkeleton></StyledBlockSkeleton>
                <StyledBlockSkeleton></StyledBlockSkeleton>
                <StyledBlockSkeleton></StyledBlockSkeleton>
                <StyledBlockSkeleton></StyledBlockSkeleton>
            </StyledContainer>
        );
    }
}

export default SkeletonTimeline;
