import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
    padding: 12px 0;
    position: relative;
    display: flex;
    justify-content: flex-start;
`;

const StyledLabel = styled.div`
    font-size: 13px;
    padding: 0 6px;
    line-height: 18px;
    text-transform: uppercase;
`;

const StyledToggle = styled.div`
    cursor: pointer;
    width: 42px;
    position: relative;
    height: 18px;
    display: inline-block;
`;

const StyledSlider = styled.div<{ checked: boolean }>`
    position: relative;
    width: 80%;
    height: 12px;
    top: 3px;
    left: 10%;
    border-radius: 30px;
    border: 1px solid darken ${props => props.theme.colours.darkerGrey};
    background-color: ${props => (props.checked ? props.theme.colours.offBlackLighter : props.theme.colours.darkGrey)};
`;

const StyledButton = styled.div<{ checked: boolean }>`
    ${props => props.theme.boxShadow(1)}
    position: absolute;
    top: 0;
    left: ${props => (props.checked ? 'calc(100% - 18px)' : 0)};
    height: 18px;
    width: 18px;
    background-color: ${props => props.theme.colours.white};
    border: 1px solid ${props => props.theme.colours.midGrey};
    transition: 0.2s ease-in-out;
    border-radius: 30px;
    &.on {
        left: calc(100% - 15px);
    }
`;

export type SimpleToggleProps = {
    checked: boolean;
    labelText?: string;
    onClick: () => void;
};

export const SimpleToggle = ({ checked, labelText, onClick }: SimpleToggleProps) => {
    return (
        <StyledWrapper>
            {labelText && <StyledLabel>{labelText}</StyledLabel>}
            <StyledToggle onClick={onClick}>
                <StyledSlider checked={checked} />
                <StyledButton checked={checked} />
            </StyledToggle>
        </StyledWrapper>
    );
};

export default SimpleToggle;
