/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState } from 'react';

// Fields and Resources
import * as fields from '../../configurations/fields';

// Types
import { RequestStatus, WidgetMenuObj, WidgetMenuType } from '../../section-dashboard/types';
import { CubedField } from '../../types';

// Hooks
import useWidgetMenuSection from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section';
import useWidgetMenuSectionQuerySelect from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-query-select';
import useWidgetMenuSectionSelect from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-select';
import useWidgetSectionQuerySelectInfinite from '../../section-dashboard/widgets/menus/hooks/use-widget-section-query-select-infinite';

// Queries
import { geolocationDropdownQuery, goalsDropdownQuery } from '../../section-dashboard/queries';

// Enums
import {
    selectLevelDropdownRequest,
    visitorTypeDropdownRequest,
} from '../customer-insights-dashboard/configurations/dropdown-options';

// Components
import WidgetMenuSectionSelect from '../../section-dashboard/widgets/menus/components/section-menu/widget-menu-section-select';

export interface CustomerInsightsDashboardMenuContextValues {
    // Traffic Overview
    trafficOverviewSelectedLevelField: CubedField;
    trafficOverviewGeographicSplitsSectionMenu: WidgetMenuObj;

    // Goal Completion Overview
    countGoalsByCustomerTypeSectionMenu: WidgetMenuObj;
    valueGoalsByCustomerTypeSectionMenu: WidgetMenuObj;

    countGoalsByLocationSelectedLevelField: CubedField;
    countGoalsByLocationSectionMenu: WidgetMenuObj;

    valueGoalsByLocationSelectedLevelField: CubedField;
    valueGoalsByLocationSectionMenu: WidgetMenuObj;

    countLocationsByGoalSectionMenu: WidgetMenuObj;
    valueLocationsByGoalSectionMenu: WidgetMenuObj;
}

type CustomerInsightsDashboardMenuContextProps = {
    children: JSX.Element;
};

export const CustomerInsightsDashboardMenuContext = createContext<CustomerInsightsDashboardMenuContextValues>(
    {} as CustomerInsightsDashboardMenuContextValues
);

const CustomerInsightsDashboardMenuProvider = ({ children }: CustomerInsightsDashboardMenuContextProps) => {
    const levelDropdownOptions = selectLevelDropdownRequest;

    // Traffic Overview - Geographic Splits
    const [trafficOverviewSelectedLevel, setTrafficOverviewSelectedLevel] = useState(
        selectLevelDropdownRequest.options[0]
    );
    const [trafficOverviewSelectedLevelField, setTrafficOverviewSelectedLevelField] = useState(
        selectLevelDropdownRequest.options[0].field
    );

    const handleTrafficOverviewLevelChange = (selectedOption: string) => {
        const selectedLevel = levelDropdownOptions.options.filter(option => option.value === selectedOption)[0];
        setTrafficOverviewSelectedLevel(selectedLevel);
        setTrafficOverviewSelectedLevelField(selectedLevel.field);
    };

    const trafficOverviewGeographicSplitsSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    {
                        type: WidgetMenuType.SELECT,
                        menu: (
                            <WidgetMenuSectionSelect
                                label="Level"
                                dropdownOptions={levelDropdownOptions}
                                selectedOption={trafficOverviewSelectedLevel}
                                onOptionChange={handleTrafficOverviewLevelChange}
                                emptyMessage="No levels found"
                            />
                        ),
                        request: {},
                        status: RequestStatus.SUCCESS,
                    },
                    useWidgetMenuSectionSelect({
                        dropdownRequestOption: visitorTypeDropdownRequest,
                        dropdownField: fields.VISIT_TYPE,
                        includeAllOption: true,
                        label: 'Visit Type',
                    }),
                ],
            },
        ],
    });

    // Goal Completion Overview - Count of Goals by Customer Type
    const countGoalsByCustomerTypeSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionSelect({
                        dropdownRequestOption: visitorTypeDropdownRequest,
                        dropdownField: fields.VISIT_TYPE,
                        includeAllOption: true,
                        label: 'Visit Type',
                    }),
                ],
            },
        ],
    });

    const valueGoalsByCustomerTypeSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionSelect({
                        dropdownRequestOption: visitorTypeDropdownRequest,
                        dropdownField: fields.VISIT_TYPE,
                        includeAllOption: true,
                        label: 'Visit Type',
                    }),
                ],
            },
        ],
    });

    // Goal Completion Count Goals by Location
    const [countGoalsByLocationSelectedLevel, setCountGoalsByLocationSelectedLevel] = useState(
        selectLevelDropdownRequest.options[0]
    );
    const [countGoalsByLocationSelectedLevelField, setCountGoalsByLocationSelectedLevelField] = useState(
        selectLevelDropdownRequest.options[0].field
    );

    const handleCountGoalsByLocationLevelChange = (selectedOption: string) => {
        const selectedLevel = levelDropdownOptions.options.filter(option => option.value === selectedOption)[0];
        setCountGoalsByLocationSelectedLevel(selectedLevel);
        setCountGoalsByLocationSelectedLevelField(selectedLevel.field);
    };

    const countGoalsByLocationSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    {
                        type: WidgetMenuType.SELECT,
                        menu: (
                            <WidgetMenuSectionSelect
                                label="Level"
                                dropdownOptions={levelDropdownOptions}
                                selectedOption={countGoalsByLocationSelectedLevel}
                                onOptionChange={handleCountGoalsByLocationLevelChange}
                                emptyMessage="No levels found"
                            />
                        ),
                        request: {},
                        status: RequestStatus.SUCCESS,
                    },
                    useWidgetMenuSectionQuerySelect({
                        query: goalsDropdownQuery,
                        dropdownField: fields.PRODUCT,
                        includeAllOption: true,
                    }),
                    useWidgetMenuSectionSelect({
                        dropdownRequestOption: visitorTypeDropdownRequest,
                        dropdownField: fields.VISIT_TYPE,
                        includeAllOption: true,
                        label: 'Visit',
                    }),
                ],
            },
        ],
    });

    // Goal Completion Value Goals by Location
    const [valueGoalsByLocationSelectedLevel, setValueGoalsByLocationSelectedLevel] = useState(
        selectLevelDropdownRequest.options[0]
    );
    const [valueGoalsByLocationSelectedLevelField, setValueGoalsByLocationSelectedLevelField] = useState(
        selectLevelDropdownRequest.options[0].field
    );

    const handleValueGoalsByLocationLevelChange = (selectedOption: string) => {
        const selectedLevel = levelDropdownOptions.options.filter(option => option.value === selectedOption)[0];
        setValueGoalsByLocationSelectedLevel(selectedLevel);
        setValueGoalsByLocationSelectedLevelField(selectedLevel.field);
    };

    const valueGoalsByLocationSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    {
                        type: WidgetMenuType.SELECT,
                        menu: (
                            <WidgetMenuSectionSelect
                                label="Level"
                                dropdownOptions={levelDropdownOptions}
                                selectedOption={valueGoalsByLocationSelectedLevel}
                                onOptionChange={handleValueGoalsByLocationLevelChange}
                                emptyMessage="No levels found"
                            />
                        ),
                        request: {},
                        status: RequestStatus.SUCCESS,
                    },
                    useWidgetMenuSectionQuerySelect({
                        query: goalsDropdownQuery,
                        dropdownField: fields.PRODUCT,
                        includeAllOption: true,
                    }),
                    useWidgetMenuSectionSelect({
                        dropdownRequestOption: visitorTypeDropdownRequest,
                        dropdownField: fields.VISIT_TYPE,
                        includeAllOption: true,
                        label: 'Visit',
                    }),
                ],
            },
        ],
    });

    // Goal Completion Count Locations by Goal
    const [countLocationsByGoalSelectedLevel, setCountLocationsByGoalSelectedLevel] = useState(
        selectLevelDropdownRequest.options[0]
    );

    const [countLocationsByGoalSelectedLevelField, setCountLocationsByGoalSelectedLevelField] = useState(
        selectLevelDropdownRequest.options[0].field
    );

    const handleCountLocationByGoalLevelChange = (selectedOption: string) => {
        const selectedLevel = levelDropdownOptions.options.filter(option => option.value === selectedOption)[0];
        setCountLocationsByGoalSelectedLevel(selectedLevel);
        setCountLocationsByGoalSelectedLevelField(selectedLevel.field);
    };

    const countLocationsByGoalSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    {
                        type: WidgetMenuType.SELECT,
                        menu: (
                            <WidgetMenuSectionSelect
                                label="Level"
                                dropdownOptions={levelDropdownOptions}
                                selectedOption={countLocationsByGoalSelectedLevel}
                                onOptionChange={handleCountLocationByGoalLevelChange}
                                emptyMessage="No levels found"
                            />
                        ),
                        request: {},
                        status: RequestStatus.SUCCESS,
                    },
                    useWidgetSectionQuerySelectInfinite({
                        query: geolocationDropdownQuery({
                            field: countLocationsByGoalSelectedLevel.dropdownField,
                            offset: 0,
                            limit:
                                countLocationsByGoalSelectedLevel.dropdownField === fields.GEOLOCATION_COUNTRY_NAME
                                    ? 500
                                    : 100,
                        }),
                        dropdownField: countLocationsByGoalSelectedLevel.dropdownField,
                        filterFieldOverride: countLocationsByGoalSelectedLevelField,
                        includeAllOption: true,
                        label:
                            countLocationsByGoalSelectedLevel.dropdownField === fields.GEOLOCATION_COUNTRY_NAME
                                ? 'Country'
                                : 'City',
                        allLabel:
                            countLocationsByGoalSelectedLevel.dropdownField === fields.GEOLOCATION_COUNTRY_NAME
                                ? 'Countries'
                                : 'Cities',
                    }),
                    useWidgetMenuSectionSelect({
                        dropdownRequestOption: visitorTypeDropdownRequest,
                        dropdownField: fields.VISIT_TYPE,
                        includeAllOption: true,
                        label: 'Visit',
                    }),
                ],
            },
        ],
    });

    // Goal Completion Value Locations by Goal
    const [valueLocationsByGoalSelectedLevel, setValueLocationsByGoalSelectedLevel] = useState(
        selectLevelDropdownRequest.options[0]
    );

    const [valueLocationsByGoalSelectedLevelField, setValueLocationsByGoalSelectedLevelField] = useState(
        selectLevelDropdownRequest.options[0].field
    );

    const handleValueLocationByGoalLevelChange = (selectedOption: string) => {
        const selectedLevel = levelDropdownOptions.options.filter(option => option.value === selectedOption)[0];
        setValueLocationsByGoalSelectedLevel(selectedLevel);
        setValueLocationsByGoalSelectedLevelField(selectedLevel.field);
    };

    const valueLocationsByGoalSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    {
                        type: WidgetMenuType.SELECT,
                        menu: (
                            <WidgetMenuSectionSelect
                                label="Level"
                                dropdownOptions={levelDropdownOptions}
                                selectedOption={valueLocationsByGoalSelectedLevel}
                                onOptionChange={handleValueLocationByGoalLevelChange}
                                emptyMessage="No levels found"
                            />
                        ),
                        request: {},
                        status: RequestStatus.SUCCESS,
                    },
                    useWidgetSectionQuerySelectInfinite({
                        query: geolocationDropdownQuery({
                            field: valueLocationsByGoalSelectedLevel.dropdownField,
                            offset: 0,
                            limit:
                                valueLocationsByGoalSelectedLevel.dropdownField === fields.GEOLOCATION_COUNTRY_NAME
                                    ? 500
                                    : 100,
                        }),
                        dropdownField: valueLocationsByGoalSelectedLevel.dropdownField,
                        filterFieldOverride: valueLocationsByGoalSelectedLevelField,
                        includeAllOption: true,
                        label:
                            valueLocationsByGoalSelectedLevel.dropdownField === fields.GEOLOCATION_COUNTRY_NAME
                                ? 'Country'
                                : 'City',
                        allLabel:
                            valueLocationsByGoalSelectedLevel.dropdownField === fields.GEOLOCATION_COUNTRY_NAME
                                ? 'Countries'
                                : 'Cities',
                    }),
                    useWidgetMenuSectionSelect({
                        dropdownRequestOption: visitorTypeDropdownRequest,
                        dropdownField: fields.VISIT_TYPE,
                        includeAllOption: true,
                        label: 'Visit',
                    }),
                ],
            },
        ],
    });

    return (
        <CustomerInsightsDashboardMenuContext.Provider
            value={{
                // Traffic Overview
                trafficOverviewSelectedLevelField,
                trafficOverviewGeographicSplitsSectionMenu,

                // Goal Completion Overview
                countGoalsByCustomerTypeSectionMenu,
                valueGoalsByCustomerTypeSectionMenu,

                countGoalsByLocationSelectedLevelField,
                countGoalsByLocationSectionMenu,

                valueGoalsByLocationSelectedLevelField,
                valueGoalsByLocationSectionMenu,

                countLocationsByGoalSectionMenu,
                valueLocationsByGoalSectionMenu,
            }}
        >
            {children}
        </CustomerInsightsDashboardMenuContext.Provider>
    );
};

export default CustomerInsightsDashboardMenuProvider;
