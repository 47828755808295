import React from 'react';
import styled from 'styled-components';
import * as Popover from '@radix-ui/react-popover';

const StyledPopoverTrigger = styled(Popover.Trigger)`
    display: flex;
    align-items: center;
    gap: 6px;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 0.9rem;
    font-weight: 500;
    width: 100%;
    color: ${props => props.theme.popover.trigger.color};
`;

const PopoverTrigger = ({ children }: { children: React.ReactNode }) => {
    return <StyledPopoverTrigger>{children}</StyledPopoverTrigger>;
};

export default PopoverTrigger;
