import React from 'react';
import styled from 'styled-components';

// Component
import SectionDashboardBigNumberBar from '../../components/section-dashboard-big-number-bar';

// Types
import { FunnelChartSeries, WidgetDirection } from '../../types';

const StyledBoxContainer = styled.div<{ direction: WidgetDirection }>`
    display: flex;
    flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
    height: 100%;
`;

export type WidgetFunnelChartBigNumberProps = {
    data: FunnelChartSeries[];
    direction: WidgetDirection;
    total: number;
};

const WidgetFunnelChartBigNumber = ({ data, direction, total }: WidgetFunnelChartBigNumberProps) => {
    return (
        <StyledBoxContainer direction={direction}>
            {data.map((dataPoint, index) => {
                const { name, y, color } = dataPoint;
                const percentage = (dataPoint.y / total) * 100;

                return (
                    <SectionDashboardBigNumberBar
                        index={index}
                        percentage={percentage}
                        direction={direction}
                        name={name}
                        color={color}
                        value={y}
                    />
                );
            })}
        </StyledBoxContainer>
    );
};

export default WidgetFunnelChartBigNumber;
