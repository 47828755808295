import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DashboardDetails, SectionConfig } from '../../types';
import axios from 'axios';
import { generatePath, generateUrl, generateUrlDetail } from '../../../helpers/request-builder';
import {
    DASHBOARD_BUILDER_DASHBOARD,
    DASHBOARD_BUILDER_SECTION,
    DASHBOARD_BUILDER_SECTION_AREAS,
    DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON,
} from '../../config/resources-dashboard-builder';
import parseGridAreas from '../helpers/parse-grid-areas';
import generateQueryKey from '../../../react-query/helpers/generate-query-key';

type UsePatchDashboardUpdateArgs = {
    dashboardId: string;
    dashboardDetails: DashboardDetails;
    sections: SectionConfig[];
    removedSections: SectionConfig[];
    onSuccess: () => void;
};

const usePatchDashboardUpdate = ({
    dashboardId,
    dashboardDetails,
    sections,
    removedSections,
    onSuccess,
}: UsePatchDashboardUpdateArgs) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: async () => {
            const requests = [];

            // If the dashboard details have been changed, update the dashboard
            if (dashboardDetails.changed) {
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail(
                            'dashboard-builder',
                            DASHBOARD_BUILDER_DASHBOARD.id,
                            dashboardId || '0'
                        )!,
                        data: {
                            title: dashboardDetails.title,
                            description: dashboardDetails.description,
                            private: dashboardDetails.isPrivate,
                            comparison_enabled: dashboardDetails.enableComparison,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            }

            // Loop through each section.
            // If it is an existing section, update the section and section areas.
            // If it is a new section, create the section and section areas.
            sections.forEach(async (section, index) => {
                if (section.sectionId.includes('existing')) {
                    requests.push(
                        axios({
                            method: 'PATCH',
                            url: generateUrlDetail(
                                'dashboard-builder',
                                DASHBOARD_BUILDER_SECTION.id,
                                section.sectionId.split('-').at(-1)
                            )!,
                            data: {
                                title: section.sectionName,
                                default_collapsed: section.collapsed,
                                display_order: index,
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );

                    requests.push(
                        axios({
                            method: 'PATCH',
                            url: generateUrlDetail(
                                'dashboard-builder',
                                DASHBOARD_BUILDER_SECTION_AREAS.id,
                                section.sectionAreasId
                            )!,
                            data: {
                                grid_xs: parseGridAreas('xs', section.layouts.xs),
                                grid_sm: parseGridAreas('sm', section.layouts.sm),
                                grid_md: parseGridAreas('md', section.layouts.md),
                                grid_lg: parseGridAreas('lg', section.layouts.lg),
                                grid_xl: parseGridAreas('xl', section.layouts.xl),
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );

                    requests.push(
                        axios({
                            method: 'PATCH',
                            url: generateUrlDetail(
                                'dashboard-builder',
                                DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON.id,
                                section.sectionAreasComparisonId
                            )!,
                            data: {
                                grid_xs: parseGridAreas('xs', section.comparisonLayouts.xs),
                                grid_sm: parseGridAreas('sm', section.comparisonLayouts.sm),
                                grid_md: parseGridAreas('md', section.comparisonLayouts.md),
                                grid_lg: parseGridAreas('lg', section.comparisonLayouts.lg),
                                grid_xl: parseGridAreas('xl', section.comparisonLayouts.xl),
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                } else {
                    requests.push(
                        axios({
                            method: 'POST',
                            url: generateUrl('dashboard-builder', DASHBOARD_BUILDER_SECTION.id),
                            data: {
                                dashboard: generatePath(
                                    'dashboard-builder',
                                    DASHBOARD_BUILDER_DASHBOARD.id,
                                    String(dashboardId)
                                ),
                                title: section.sectionName,
                                default_collapsed: section.collapsed,
                                display_order: index,
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }).then(response => {
                            // Step 3: Save the section areas
                            const createdSectionId = response.data.id;

                            return axios({
                                method: 'POST',
                                url: generateUrl('dashboard-builder', DASHBOARD_BUILDER_SECTION_AREAS.id),
                                data: {
                                    section: generatePath(
                                        'dashboard-builder',
                                        DASHBOARD_BUILDER_SECTION.id,
                                        String(createdSectionId)
                                    ),
                                    grid_xs: parseGridAreas('xs', section.layouts.xs),
                                    grid_sm: parseGridAreas('sm', section.layouts.sm),
                                    grid_md: parseGridAreas('md', section.layouts.md),
                                    grid_lg: parseGridAreas('lg', section.layouts.lg),
                                    grid_xl: parseGridAreas('xl', section.layouts.xl),
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            }).then(() => {
                                return axios({
                                    method: 'POST',
                                    url: generateUrl(
                                        'dashboard-builder',
                                        DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON.id
                                    ),
                                    data: {
                                        section: generatePath(
                                            'dashboard-builder',
                                            DASHBOARD_BUILDER_SECTION.id,
                                            String(createdSectionId)
                                        ),
                                        grid_xs: parseGridAreas('xs', section.comparisonLayouts.xs),
                                        grid_sm: parseGridAreas('sm', section.comparisonLayouts.sm),
                                        grid_md: parseGridAreas('md', section.comparisonLayouts.md),
                                        grid_lg: parseGridAreas('lg', section.comparisonLayouts.lg),
                                        grid_xl: parseGridAreas('xl', section.comparisonLayouts.xl),
                                    },
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                });
                            });
                        })
                    );
                }
            });

            // Loop through the removed existing sections and delete them
            removedSections.forEach(section => {
                requests.push(
                    axios({
                        method: 'DELETE',
                        url: generateUrlDetail(
                            'dashboard-builder',
                            DASHBOARD_BUILDER_SECTION_AREAS.id,
                            section.sectionAreasId
                        )!,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then(() => {
                        axios({
                            method: 'DELETE',
                            url: generateUrlDetail(
                                'dashboard-builder',
                                DASHBOARD_BUILDER_SECTION.id,
                                section.sectionId.split('-').at(-1)
                            )!,
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                    })
                );
            });

            return axios.all(requests).then(() => {
                queryClient.invalidateQueries({
                    queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_DASHBOARD }),
                });
                queryClient.invalidateQueries({
                    queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_SECTION }),
                });
                queryClient.invalidateQueries({
                    queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_SECTION_AREAS }),
                });
                queryClient.invalidateQueries({
                    queryKey: generateQueryKey({ resource: DASHBOARD_BUILDER_SECTION_AREAS_COMPARISON }),
                });
            });
        },
        onSuccess: () => {
            onSuccess();
        },
    });

    return mutation;
};

export default usePatchDashboardUpdate;
