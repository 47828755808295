import { ResourceData } from '../../../types';

// Types
type UseResourceCombineBigNumberRequestsArgs = {
    resourceData: ResourceData[];
};

const useResourceCombineBigNumberRequests = ({ resourceData }: UseResourceCombineBigNumberRequestsArgs) => {
    let resultResource = {
        status: 'success',
        request: {},
        type: 'resource',
        meta: {},
        objects: [],
        resource: {},
    };
    let hasSuccess = false;
    let hasLoading = false;
    let hasEmpty = false;
    let hasError = false;

    resourceData.forEach(item => {
        if (item.status === 'success') {
            hasSuccess = true;
            resultResource.request = { ...resultResource.request, ...item.request };
            resultResource.type = item.type;
            resultResource.meta = { ...resultResource.meta, ...item.meta };
            resultResource.objects = resultResource.objects.concat(item.objects as []);
            resultResource.resource = { ...resultResource.resource, ...item.resource };
        } else if (item.status === 'loading') {
            hasLoading = true;
        } else if (item.status === 'empty') {
            hasEmpty = true;
        } else if (item.status === 'error') {
            hasError = true;
        }
    });

    if (hasLoading) {
        resultResource.status = 'loading';
    } else if (hasEmpty && !hasSuccess && !hasError) {
        resultResource.status = 'empty';
    } else if (hasError && !hasSuccess) {
        resultResource.status = 'error';
    }

    return resultResource as ResourceData;
};

export default useResourceCombineBigNumberRequests;
