import React, { useEffect } from 'react';

// Helpers
import getResource from '../../helpers/get-resource';
import getField from '../../helpers/get-field';

// Hooks
import useRequestTable from '../../../section-dashboard/hooks/request/use-request-table';
import useResource from '../../../section-dashboard/hooks/widget/use-resource';
import useResourceTable from '../../../section-dashboard/hooks/widget/table/use-resource-table';

// Types
import { CubedField } from '../../../types';
import { WidgetConfig } from '../../types';

// Components
import CreateAWidgetPreviewMessage from '../../create-a-widget/components/create-a-widget-preview-message';
import WidgetTable from '../../../section-dashboard/widgets/widget/widget-table';
import WidgetMenuTable from '../../../section-dashboard/widgets/menus/components/widget-menu/widget-menu-table';

const WidgetPreviewTable = ({ config }: { config: WidgetConfig }) => {
    // Get the request values from the widget
    const resource = getResource(config.resource);
    const groupByField = getField(config.group_by);

    const orderByField = getField(config.order_by);
    const orderByDirection = config.order_by_direction.direction;
    const fields = [
        ...(groupByField ? [groupByField] : []),
        ...config.fields.split(',').map((field: string) => getField(field)),
    ].filter(field => field !== undefined) as CubedField[];

    const request = useRequestTable({
        orderBy: orderByField ? [{ field: orderByField, orderByDirection: orderByDirection }] : [],
        groupBy: groupByField,
        fields,
        rowsPerPage: 25,
        enabled: !!resource && !!groupByField && !!orderByField,
    });

    useEffect(() => {
        if (request && request.setOrderBy && orderByField && orderByDirection) {
            request.setOrderBy([{ field: orderByField, orderByDirection: orderByDirection }]);
        }
    }, [orderByField, orderByDirection]); // eslint-disable-line react-hooks/exhaustive-deps

    const resourceData = useResource({
        resource: request.groupBy && request.orderBy ? resource : undefined,
        request: request,
    });

    const tableData = useResourceTable({
        resourceData: resourceData,
    });

    if (config.group_by.length > 0 && config.order_by.length > 0) {
        return <WidgetTable title={config.widget.title} data={tableData} menu={<WidgetMenuTable data={tableData} />} />;
    }

    return (
        <CreateAWidgetPreviewMessage message="Please select a dimension and at least one column to preview the table widget" />
    );
};

export default WidgetPreviewTable;
