import React from 'react';
import styled from 'styled-components';
import IconChevronLeft from './icons/chevron-left';

const StyledCollapseExpandLink = styled.div`
    background-color: #ffffff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: fixed;
    filter: drop-shadow(0px 0px 4px #6f6f6f);
    z-index: 51;
    cursor: pointer;
    top: 35px;
    left: ${props => (props.showSidebar ? '287px' : '78px')};

    & svg {
        filter: invert(0%) sepia(54%) saturate(1119%) hue-rotate(36deg) brightness(101%) contrast(105%);
        height: 10px;
        position: relative;
        top: 2px;
        left: 9px;
        transform: ${props => !props.showSidebar && 'rotate(180deg)'};
        fill: #000000;
    }
`;

const NavCollapseExpandLink = ({ showSidebar, onToggleSidebar }) => {
    const onEnterPress = e => {
        if (e.key === 'Enter') {
            onToggleSidebar();
        }
    };

    return (
        <StyledCollapseExpandLink
            showSidebar={showSidebar}
            onClick={onToggleSidebar}
            onKeyDown={onEnterPress}
            tabIndex={0}
            data-testid="nav-expand-link"
        >
            <IconChevronLeft />
        </StyledCollapseExpandLink>
    );
};

export default NavCollapseExpandLink;
