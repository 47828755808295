import React, { Fragment, useContext } from 'react';
import styled, { useTheme } from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

// Context
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

// Hooks
import { useSearchParams } from 'react-router-dom';

// Types & Enums
import { TriggerType } from '../../components/dialog/dialog-trigger';

// Components
import CreateADashboardDraggableItem from './create-a-dashboard-draggable-item';
import FormWidgetSearch from '../forms/form-widget-search';
import Form from '../../../components/forms/form';
import Dialog from '../../components/dialog/dialog';
import IconPenToSquare from '../../../components/icons/pen-to-square';
import LayoutEditWidget from '../../create-a-widget/layouts/layout-edit-a-widget';
import WidgetBaseLoader from '../../../section-dashboard/widgets/base/widget-base-loader';
import IconClose from '../../../components/icons/close-icon';

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const StyledTitle = styled.h2`
    font-size: 1rem;
    color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.sections.titleColor};
    font-weight: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.sections.titleFontWeight};
    margin: 0;
`;

const StyledWidgetTypeTitle = styled.h3`
    font-size: 0.8rem;
    color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.sections.titleColor};
    margin: 0;
    text-transform: uppercase;
    font-weight: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.sections.titleFontWeight};
`;

const StyledEmptyMessage = styled.p`
    font-size: 0.9rem;
    margin: 0 auto;
    font-style: italic;
`;

const StyledOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const StyledDescription = styled.p`
    font-size: 0.8rem;
    font-style: italic;
    margin: 0;
    color: ${props => props.theme.dashboardBuilder.createADashboard.sidebar.sections.color};
`;

const StyledIconPenToSquare = styled(IconPenToSquare)`
    width: 16px;
`;

const StyledReloading = styled.div`
    display: flex;
    align-items: center;
`;

const StyledReloadingText = styled.span`
    font-size: 0.9rem;
    font-style: italic;
`;

const StyledCancelContainer = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    gap: 6px;
    top: 22px;
    right: 25px;
`;

const StyledIconClose = styled(IconClose)`
    width: 12px;
`;

const CreateADashboardWidgetList = () => {
    const theme = useTheme();
    const { widgetQuery, widgetTypeQuery, widgets, currentWidgets } = useContext(CreateADashboardContext);
    let [, setSearchParams] = useSearchParams();

    const handleEditClick = (id: number) => {
        setSearchParams(`edit=${id}`);
    };

    const handleCloseClick = () => {
        setSearchParams('');
    };

    if (widgets) {
        const currentWidgetIds = currentWidgets.map(widget => widget.widget.id);

        return (
            <StyledContainer>
                <StyledTitle>Widgets</StyledTitle>
                {widgetQuery.isFetching ? (
                    <StyledReloading>
                        <WidgetBaseLoader width={20} height={20} margin={10} transformOrigin={true} />
                        <StyledReloadingText>Reloading Widgets...</StyledReloadingText>
                    </StyledReloading>
                ) : (
                    <Form onSubmit={() => {}}>
                        <FormWidgetSearch />
                    </Form>
                )}
                {currentWidgetIds.length === widgets.length && (
                    <StyledEmptyMessage>No widgets to display</StyledEmptyMessage>
                )}
                {widgetTypeQuery.isSuccess &&
                    widgetTypeQuery.data.objects.map((widgetType, index) => {
                        const typeWidgets = widgets
                            .filter(widget => widget.widget.type.id === widgetType.id)
                            .filter(widget => !currentWidgetIds.includes(widget.widget.id))
                            .sort((a, b) => a.widget.title.localeCompare(b.widget.title));

                        if (typeWidgets.length > 0) {
                            return (
                                <Fragment key={`droppable${index}`}>
                                    <StyledWidgetTypeTitle>{widgetType.display_name}s</StyledWidgetTypeTitle>
                                    <Droppable droppableId={`widgetList-${widgetType.name}`}>
                                        {provided => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                <StyledOptionContainer>
                                                    {typeWidgets.map((widget, widgetIndex) => {
                                                        return (
                                                            <CreateADashboardDraggableItem
                                                                key={widget.widget.id}
                                                                itemId={widget.widget.id}
                                                                displayName={widget.widget.title}
                                                                iconType={widget.widget.type.name}
                                                                index={widgetIndex}
                                                                menu={
                                                                    <Dialog>
                                                                        <Dialog.Trigger
                                                                            type={TriggerType.WRAPPER}
                                                                            onClick={() =>
                                                                                handleEditClick(widget.widget.id)
                                                                            }
                                                                        >
                                                                            <StyledIconPenToSquare />
                                                                        </Dialog.Trigger>
                                                                        <Dialog.Content
                                                                            width="85vw"
                                                                            height="80vh"
                                                                            backgroundColor={
                                                                                theme.dashboardBuilder.container
                                                                                    .backgroundColor
                                                                            }
                                                                        >
                                                                            <Dialog.Title iconType="edit">
                                                                                Edit Widget
                                                                            </Dialog.Title>
                                                                            <LayoutEditWidget />

                                                                            <Dialog.Cancel onClick={handleCloseClick}>
                                                                                <StyledCancelContainer>
                                                                                    <StyledIconClose />
                                                                                    Close
                                                                                </StyledCancelContainer>
                                                                            </Dialog.Cancel>
                                                                        </Dialog.Content>
                                                                    </Dialog>
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </StyledOptionContainer>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </Fragment>
                            );
                        }
                        return null;
                    })}

                {widgets.length > 0 && (
                    <>
                        <StyledDescription>Note: a Widget can only be added to a dashboard once.</StyledDescription>

                        <StyledDescription>
                            Tip: If you are working on a smaller screen, zoom out using your browser's zoom controls to
                            see the larger viewport sizes.
                        </StyledDescription>
                    </>
                )}
            </StyledContainer>
        );
    }

    return null;
};

export default CreateADashboardWidgetList;
