import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';

export const config = {
    pageTitle: 'Corridor Performance',
    dateDimension: fields.SALES_DATE_DATE,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: true,
            isEnableProductSingleSelect: true,
            isEnableMetrics: true,
        },
    },
    resources: [resources.REPORT_MCS_PAYMENTS, resources.REPORT_MCS_PAYMENTS_LANDING_PATH],
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'corridor-performance-chart',
        allYMetrics: [
            fields.TRANSFERS,
            fields.TRANSFERS_PER_VISIT,
            fields.TRANSFER_AMOUNT,
            fields.AVG_TRANSFER_AMOUNT,
            fields.VISITS,
            fields.NEW_VISITS,
            fields.NEW_VISITS_RATE,
            fields.REPEAT_VISITS,
            fields.REPEAT_VISITS_RATE,
            fields.FEES,
            fields.FEES_PER_TRANSFER,
            fields.COST,
            fields.COST_PER_TRANSFER,
        ],
        initialYMetrics: [fields.TRANSFERS],
    },
    table: {
        tabListItems: [
            {
                tabName: 'transfers',
                displayName: 'Transfers',
                isSelected: true,
                metrics: [
                    fields.TRANSFERS,
                    fields.TRANSFERS_PER_VISIT,
                    fields.TRANSFER_AMOUNT,
                    fields.AVG_TRANSFER_AMOUNT,
                    fields.FEES,
                    fields.FEES_PER_TRANSFER,
                ],
            },
            {
                tabName: 'traffic',
                displayName: 'Traffic',
                isSelected: true,
                metrics: [
                    fields.VISITS,
                    fields.NEW_VISITS,
                    fields.NEW_VISITS_RATE,
                    fields.REPEAT_VISITS,
                    fields.REPEAT_VISITS_RATE,
                ],
            },
            {
                tabName: 'cost',
                displayName: 'Cost',
                isSelected: true,
                metrics: [fields.COST, fields.COST_PER_TRANSFER],
            },
            {
                tabName: 'all',
                displayName: 'All',
                isSelected: true,
                metrics: [
                    fields.TRANSFERS,
                    fields.TRANSFERS_PER_VISIT,
                    fields.TRANSFER_AMOUNT,
                    fields.AVG_TRANSFER_AMOUNT,
                    fields.VISITS,
                    fields.NEW_VISITS,
                    fields.NEW_VISITS_RATE,
                    fields.REPEAT_VISITS,
                    fields.REPEAT_VISITS_RATE,
                    fields.FEES,
                    fields.FEES_PER_TRANSFER,
                    fields.COST,
                    fields.COST_PER_TRANSFER,
                ],
            },
        ],
        fieldDataOverrides: {
            referer: [
                {
                    property: 'subchannels',
                    condition: function (subchannels) {
                        return Array.isArray(subchannels) ? subchannels.length > 1 : false;
                    },
                    field: fields.SUBCHANNEL_NAME,
                },
            ],
        },
        fieldParentOverrides: {
            subchannel_name: fields.REFERER,
        },
        fieldChildren: {
            [fields.REFERER.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_PAYMENTS,
                        field: fields.PAYMENTS_PAYMENT_FROM,
                    },
                ],
            },
            [fields.SUBCHANNEL.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_PAYMENTS,
                        field: fields.PAYMENTS_PAYMENT_FROM,
                    },
                ],
            },
            [fields.PAYMENTS_PAYMENT_FROM.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_PAYMENTS,
                        field: fields.PAYMENTS_PAYMENT_TO,
                    },
                ],
            },
            [fields.PAYMENTS_PAYMENT_TO.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_PAYMENTS,
                        field: fields.PAYMENTS_SENDER_CURRENCY,
                    },
                ],
            },
            [fields.PAYMENTS_SENDER_CURRENCY.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_PAYMENTS,
                        field: fields.PAYMENTS_RECEIVER_CURRENCY,
                    },
                ],
            },
            [fields.PAYMENTS_RECEIVER_CURRENCY.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_PAYMENTS,
                        field: fields.PAYMENTS_PAYMENT_FROM,
                    },
                ],
            },
            [fields.LANDING_PATH_HOST.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_PAYMENTS_LANDING_PATH,
                        field: fields.LANDING_PATH_PATH,
                    },
                ],
            },
            [fields.LANDING_PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_PAYMENTS_LANDING_PATH,
                        field: fields.PAYMENTS_PAYMENT_FROM,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
