export default function sunBurstConfig() {
    return {
        credits: {
            enabled: false,
        },
        series: [
            {
                type: 'sunburst',
                allowDrillToNode: true,
                cursor: 'pointer',
                levels: [
                    {
                        level: 1,
                        levelIsConstant: true,
                        levelSize: {
                            unit: 'pixels',
                            value: 120,
                        },
                    },
                    {
                        level: 2,
                        levelIsConstant: true,
                        levelSize: {
                            unit: 'pixels',
                            value: 40,
                        },
                    },
                    {
                        level: 3,
                        levelIsConstant: true,
                        levelSize: {
                            unit: 'pixels',
                            value: 40,
                        },
                    },
                    {
                        level: 4,
                        levelIsConstant: true,
                        levelSize: {
                            unit: 'pixels',
                            value: 40,
                        },
                    },
                    {
                        level: 5,
                        levelIsConstant: true,
                        levelSize: {
                            unit: 'pixels',
                            value: 40,
                        },
                    },
                    {
                        level: 6,
                        levelIsConstant: true,
                        levelSize: {
                            unit: 'pixels',
                            value: 40,
                        },
                    },
                    {
                        level: 7,
                        levelIsConstant: true,
                        levelSize: {
                            unit: 'pixels',
                            value: 40,
                        },
                    },
                ],
                states: { hover: { enabled: false } },
            },
        ],
        chart: {
            height: '90%',
            styledMode: false,
        },
        title: {
            text: '',
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false,
                },
                stickyTracking: false,
                // states: {
                // 	hover: {
                // 		enable: false,
                // 		brightness: 0
                // 	},
                // 	active: {
                // 		enable: false,
                // 	}
                // }
            },
        },
    };
}
