import React, { createContext, useContext, useEffect, useState } from 'react';

// React Query
import useFetchResource from '../../../../react-query/hooks/use-fetch-resource';
import useDeleteResource from '../../../../react-query/hooks/use-delete-resource';
import usePostMultiResourcesWithPayload from '../../../../react-query/hooks/use-post-multi-resource-with-payload';

import { ConfigDataSuccess } from '../../../../react-query/types';
import { UseQueryResult } from '@tanstack/react-query';

// Resources
import {
    DASHBOARD_BUILDER_DASHBOARD,
    DASHBOARD_BUILDER_DASHBOARD_HOMEPAGE,
    DASHBOARD_BUILDER_WIDGET,
} from '../../../config/resources-dashboard-builder';

// Context
import { DashboardBuilderContext } from '../../../context/dashboard-builder-context';
import { HomepageContext } from '../../../../context/homepage-context';

// Types
import { DashboardConfig } from '../../../types';

// Helpers
import { generatePath } from '../../../../helpers/request-builder';

export interface DashboardLibraryContextValues {
    // Dashboards
    accountDashboardsQuery: UseQueryResult<ConfigDataSuccess, Error>;
    accountDashboards: DashboardConfig[];
    currentUserDashboards: DashboardConfig[];
    homepageQuery: UseQueryResult<ConfigDataSuccess, Error>;
    deleteHomepageMutation: ReturnType<typeof useDeleteResource>;
    addHomepageMutation: ReturnType<typeof usePostMultiResourcesWithPayload>;
    // Handlers
    handleFilterDashboards: (searchTerm: string) => void;
    handleRemoveAsHomepage: () => void;
    handleSetAsHomepage: (dashboardId: number) => void;
    // Widgets
    widgetQuery: UseQueryResult<ConfigDataSuccess, Error>;
}

export const DashboardLibraryContext = createContext<DashboardLibraryContextValues>(
    {} as DashboardLibraryContextValues
);

const DashboardLibraryContextProvider = ({ children }: { children: JSX.Element }) => {
    const { clientUserId, userDashboardsQuery, userDashboards } = useContext(DashboardBuilderContext);
    const { setHomepage } = useContext(HomepageContext);

    // Dashboards
    const accountDashboardsQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_DASHBOARD,
        params: [
            { key: 'active', value: true },
            { key: 'private', value: false },
        ],
    });

    // Dashboard Homepage
    const homepageQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_DASHBOARD_HOMEPAGE,
        params: [
            {
                key: 'user',
                value: clientUserId,
            },
        ],
        enabled: !!clientUserId,
    });

    const deleteHomepageMutation = useDeleteResource({
        resource: DASHBOARD_BUILDER_DASHBOARD_HOMEPAGE,
        resourceIds: [homepageQuery.data?.objects[0]?.id || 0],
    });

    const addHomepageMutation = usePostMultiResourcesWithPayload({
        resource: DASHBOARD_BUILDER_DASHBOARD_HOMEPAGE,
    });

    const [currentUserDashboards, setCurrentUserDashboards] = useState<DashboardConfig[]>([]);
    const [accountDashboards, setAccountDashboards] = useState<DashboardConfig[]>([]);

    useEffect(() => {
        if (homepageQuery.data && homepageQuery.data.objects.length > 0) {
            const userDashboardsUpdated = userDashboards.map(dashboard => {
                if (dashboard.id === homepageQuery.data?.objects[0].dashboard.id) {
                    return {
                        ...dashboard,
                        isHomepage: true,
                    };
                }
                return dashboard;
            });
            setCurrentUserDashboards(userDashboardsUpdated);
        } else {
            setCurrentUserDashboards(userDashboards);
        }
    }, [userDashboards, homepageQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            accountDashboardsQuery.data &&
            accountDashboardsQuery.data.objects.length > 0 &&
            clientUserId &&
            homepageQuery.data
        ) {
            let accountDashboards = accountDashboardsQuery.data.objects
                .map(dashboard => dashboard)
                .filter(dashboard => dashboard.author.id !== clientUserId) as DashboardConfig[];

            if (homepageQuery.data.objects.length > 0) {
                accountDashboards = accountDashboards.map(dashboard => {
                    if (dashboard.id === homepageQuery.data.objects[0].dashboard.id) {
                        return {
                            ...dashboard,
                            isHomepage: true,
                        };
                    }
                    return dashboard;
                });
            }

            setAccountDashboards(accountDashboards);
        }
    }, [accountDashboardsQuery.data, clientUserId, homepageQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFilterDashboards = (searchTerm: string) => {
        if (searchTerm) {
            if (userDashboardsQuery.isSuccess) {
                const updatedUserDashboards = userDashboardsQuery.data.objects.filter(dashboard =>
                    dashboard.title.toLowerCase().includes(searchTerm.toLowerCase())
                );
                setCurrentUserDashboards(updatedUserDashboards.map(dashboard => dashboard as DashboardConfig));
            }
            if (accountDashboardsQuery.isSuccess) {
                const updatedAccountDashboards = accountDashboardsQuery.data.objects
                    .filter(dashboard => dashboard.title.toLowerCase().includes(searchTerm.toLowerCase()))
                    .filter(dashboard => dashboard.author.id !== clientUserId);
                setAccountDashboards(updatedAccountDashboards.map(dashboard => dashboard as DashboardConfig));
            }
        }
    };

    const handleRemoveAsHomepage = () => {
        deleteHomepageMutation.mutate();

        setHomepage({
            dashboardId: null,
            dashboardTitle: null,
        });
    };

    const handleSetAsHomepage = (dashboardId: number) => {
        if (homepageQuery.data && homepageQuery.data.objects.length > 0) {
            deleteHomepageMutation.mutate();
        }

        addHomepageMutation.mutate([
            {
                user: generatePath('dashboard-builder', 'dashboard-builder-client-users', clientUserId.toString()),
                dashboard: generatePath('dashboard-builder', 'dashboard-builder-dashboard', dashboardId.toString()),
            },
        ]);

        setHomepage({
            dashboardId: dashboardId,
            dashboardTitle:
                currentUserDashboards.find(dashboard => dashboard.id === dashboardId)?.title ||
                accountDashboards.find(dashboard => dashboard.id === dashboardId)?.title ||
                null,
        });
    };

    // Widgets
    const widgetQuery = useFetchResource<ConfigDataSuccess>({
        resource: DASHBOARD_BUILDER_WIDGET,
        params: [{ key: 'author', value: clientUserId }],
        enabled: !!clientUserId,
    });

    return (
        <DashboardLibraryContext.Provider
            value={{
                // Dashboards
                accountDashboardsQuery,
                accountDashboards,
                currentUserDashboards,
                homepageQuery,
                deleteHomepageMutation,
                addHomepageMutation,
                // Handlers
                handleFilterDashboards,
                handleRemoveAsHomepage,
                handleSetAsHomepage,
                // Widgets
                widgetQuery,
            }}
        >
            {children}
        </DashboardLibraryContext.Provider>
    );
};

export default DashboardLibraryContextProvider;
