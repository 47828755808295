export function addNotification(notification) {
    notification.id = Math.floor(Math.random() * Math.floor(1000));

    return {
        type: 'PUSH_NOTIFICATION',
        payload: notification,
    };
}

export function removeNotification(notification) {
    return {
        type: 'POP_NOTIFICATION',
        payload: notification,
    };
}
