import Axios, { CancelTokenSource } from 'axios';
import {
    generateUrl,
    generateGetRequest,
    generateGetRequests,
    generateUrlDetail,
    generateBlobRequest,
    generateMultipleBlobRequest,
    RequestParams,
} from './request-builder';

// A custom Cubed class that wraps some of Axios' commands to also use functions from the 'request-builder' as well as expose the cancel functionality.
class Request {
    cancelSource: CancelTokenSource;

    constructor() {
        this.cancelSource = Axios.CancelToken.source();
        this.cancelRequest = this.cancelRequest.bind(this);
        this.get = this.get.bind(this);
        this.download = this.download.bind(this);
        this.getUrl = this.getUrl.bind(this);
        this.all = this.all.bind(this);
        this.allUrl = this.allUrl.bind(this);
    }

    /**
     * Cancels the current Axios request.
     */
    cancelRequest(reason: string) {
        this.cancelSource.cancel(reason);

        // Make a new chancel Token when one has been used
        this.cancelSource = Axios.CancelToken.source();
    }

    /**
     * Get data from a Cubed endpoints using; resourceGroup, resourceName & params.
     */
    get(resourceGroup: string, resourceName: string, params?: RequestParams) {
        const url = generateUrl(resourceGroup, resourceName, params);

        if (url === null) {
            throw new TypeError('Unexpected Type for a given parameter.');
        }
        return generateGetRequest(url, this.cancelSource.token);
    }

    /**
     * Get data from a Cubed endpoints using; resourceGroup, resourceName & params.
     */
    getDetail(resourceGroup: string, resourceName: string, resourceId: string, params: RequestParams) {
        const url = generateUrlDetail(resourceGroup, resourceName, resourceId, params) as string;

        if (url === null) {
            throw new TypeError('Unexpected Type for a given parameter.');
        }

        return generateGetRequest(url, this.cancelSource.token);
    }

    /**
     * Get data from a Cubed endpoints using; resourceGroup, resourceName & params ready to be downloaded.
     */
    download(
        resourceGroup: string,
        resourceName: string,
        params: RequestParams[],
        exportType: 'all' | 'current',
        filename: string
    ) {
        if (exportType === 'all') {
            const urls = params.map(param => {
                return generateUrl(resourceGroup, resourceName, param);
            });

            return generateMultipleBlobRequest(urls).then((data: string[]) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([...data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        } else {
            const url = generateUrl(resourceGroup, resourceName, params[0]);

            return generateBlobRequest(url, filename);
        }
    }

    /**
     * Get data from multiple Cubed endpoints using an array of; resourceGroup, resourceName & params.
     */
    all(requests: { resourceGroup: string; resourceName: string; params: RequestParams }[]) {
        return new Promise((resolve, reject) => {
            try {
                const urls = requests.map(request => {
                    const url = generateUrl(request.resourceGroup, request.resourceName, request.params);

                    return url;
                });

                Axios.all(generateGetRequests(urls, this.cancelSource.token))
                    .then(
                        Axios.spread((...requestRes) => {
                            resolve(requestRes);
                        })
                    )
                    .catch(error => {
                        reject(error);
                    });
            } catch (error) {
                reject(new Error('Unexpected value when generating requests.'));
            }
        });
    }

    /**
     * Get data from a Cubed Endpoint using a URL
     */
    getUrl(url: string) {
        return generateGetRequest(url, this.cancelSource.token);
    }

    /**
     * Get data from multiple Cubed endpoints at once using a list of urls.
     */
    allUrl(urls: string[]) {
        return new Promise((resolve, reject) => {
            try {
                Axios.all(generateGetRequests(urls, this.cancelSource.token))
                    .then(
                        Axios.spread((...requestRes) => {
                            resolve(requestRes);
                        })
                    )
                    .catch(error => {
                        console.error('error', error);
                        reject(error);
                    });
            } catch (error) {
                console.error('error', error);
                reject(error);
            }
        });
    }
}

export default Request;
