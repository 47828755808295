import React from 'react';

// Types
import { CubedField } from '../../../../types';
import { SelectInfiniteQuery, WidgetMenuSectionMenu, WidgetMenuType } from '../../../types';

// Hook
import useWidgetSelectInfiniteState from './use-widget-select-infinite-state';
import useMenuSearch from './use-menu-search';

// Component
import WidgetSectionSelectInfinite from '../components/section-menu/widget-section-select-infinite';

type UseWidgetSectionQuerySelectInfiniteArgs = {
    query: SelectInfiniteQuery;
    dropdownField: CubedField;
    includeAllOption?: boolean;
    selectedOverride?: string;
    emptyMessage?: string;
    label?: string;
    allLabel?: string;
    customIcon?: React.ReactNode;
    menuContentAlign?: 'start' | 'end';
    responsive?: boolean;
    enableSearch?: boolean;
    filterFieldOverride?: CubedField;
};

const useWidgetSectionQuerySelectInfinite = ({
    query,
    dropdownField,
    includeAllOption,
    selectedOverride,
    emptyMessage,
    label,
    allLabel,
    customIcon,
    menuContentAlign = 'end',
    responsive,
    enableSearch,
    filterFieldOverride,
}: UseWidgetSectionQuerySelectInfiniteArgs): WidgetMenuSectionMenu => {
    const { debouncedSearchText, searchText, handleSearch } = useMenuSearch({ dropdownField });

    const indexOfSearch = query.params?.findIndex(param => param.key === `${dropdownField.rawName}__icontains`);

    const queryWithSearch = {
        ...query,
        params: [
            ...(query.params || []).slice(0, indexOfSearch ?? -1),
            { key: `${dropdownField.rawName}__icontains`, value: debouncedSearchText },
            ...(query.params || []).slice((indexOfSearch ?? -1) + 1),
        ],
    };

    const {
        dropdownOptions,
        selectedOption,
        handleChangeSelectedOption,
        filters,
        enabled,
        fetchNextPage,
        isFetchingNextPage,
    } = useWidgetSelectInfiniteState({
        query: queryWithSearch,
        dropdownField,
        includeAllOption,
        selectedOverride,
        label,
        allLabel,
        filterFieldOverride,
    });

    return {
        type: WidgetMenuType.SELECT,
        request: {
            sectionFilters: filters,
            enabled: enabled,
        },
        menu: (
            <WidgetSectionSelectInfinite
                label={label || dropdownField.displayName}
                allLabel={allLabel || dropdownField.displayName}
                dropdownOptions={dropdownOptions}
                selectedOption={selectedOption}
                onOptionChange={handleChangeSelectedOption}
                emptyMessage={emptyMessage || 'No options available'}
                customIcon={customIcon}
                menuContentAlign={menuContentAlign}
                responsive={responsive}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                enableSearch={enableSearch}
                searchText={searchText}
                handleSearch={handleSearch}
            />
        ),
        status: dropdownOptions.status,
    };
};

export default useWidgetSectionQuerySelectInfinite;
