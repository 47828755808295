import theme from '../../styled-components/theme.styled';

export const graphColours = [
    theme.sectionDashboard.colour.blue,
    theme.sectionDashboard.colour.green,
    theme.sectionDashboard.colour.violet,
    theme.sectionDashboard.colour.red,
    theme.sectionDashboard.colour.orange,
    theme.sectionDashboard.colour.sky,
    theme.sectionDashboard.colour.fuchsia,
    theme.sectionDashboard.colour.amber,
    theme.sectionDashboard.colour.teal,
    theme.sectionDashboard.colour.pink,
];

export const alternativeGraphColours = [
    theme.sectionDashboard.colour.indigo,
    theme.sectionDashboard.colour.cyan,
    theme.sectionDashboard.colour.emerald,
    theme.sectionDashboard.colour.yellow,
    theme.sectionDashboard.colour.purple,
    theme.sectionDashboard.colour.rose,
    theme.sectionDashboard.colour.lime,
    theme.sectionDashboard.colour.slate,
];
