import React from 'react';
import styled, { css } from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

// Icons
import IconPenToSquare from '../../../../../icons/pen-to-square';
import IconTrash from '../../../../../icons/trash';

// Context
import { useConfigDragAndDropTableContext } from '../../../context/config-drag-and-drop-table-context';

const StyledDropdownItem = styled(DropdownMenu.Item)`
    display: flex;
    gap: 10px;
    min-width: 150px;
    align-items: center;
    padding: 6px 10px;
    font-size: 14px;
    color: ${props => props.theme.colours.darkGrey};
    cursor: pointer;

    :hover {
        border: none;
        outline: none;
        background-color: #f5f5f5;
        color: ${props => props.theme.colours.offBlackLighter};
    }
`;

const StyledIcon = css`
    height: 15px;
`;

const StyledIconPenToSquare = styled(IconPenToSquare)`
    ${StyledIcon}
`;

const StyledIconTrash = styled(IconTrash)`
    ${StyledIcon}
`;

type ActionDropdownItemProps = {
    rowId: number;
    type: 'edit' | 'delete';
    label?: string;
    onClick: (rowId: number) => void;
};

const ActionDropdownItem = ({ rowId, type, label, onClick }: ActionDropdownItemProps) => {
    const { status } = useConfigDragAndDropTableContext();

    const dropdownItem = () => {
        switch (type) {
            case 'edit':
                return (
                    <StyledDropdownItem onSelect={() => onClick(rowId)}>
                        <StyledIconPenToSquare />
                        {label || 'Edit'}
                    </StyledDropdownItem>
                );
            case 'delete':
                return (
                    <StyledDropdownItem onSelect={() => onClick(rowId)}>
                        <StyledIconTrash />
                        {label || 'Delete'}
                    </StyledDropdownItem>
                );
        }
    };

    if (status === 'success') {
        return (
            <StyledDropdownItem onSelect={() => onClick(rowId)} data-testid="config-table-action-item">
                {dropdownItem()}
            </StyledDropdownItem>
        );
    }
    return null;
};

export default ActionDropdownItem;
