import { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

// Helpers
import buildDropdownResource from '../../../../react-query/helpers/build-dropdown-resource';

// Types
import { CubedField } from '../../../../types';
import {
    DropdownOption,
    SelectOptionsRequest,
    SelectInfiniteQuery,
    RequestFilter,
    RequestStatus,
} from '../../../types';

// Hooks
import useFetchInfiniteResource from '../../../../react-query/hooks/use-fetch-infinite-resource';

type UseWidgetSelectInfiniteStateArgs = {
    query: SelectInfiniteQuery;
    dropdownField: CubedField;
    includeAllOption?: boolean;
    selectedOverride?: string;
    label?: string;
    allLabel?: string;
    filterFieldOverride?: CubedField;
};

type DataObject = {
    [key: string]: string | number | boolean | null;
};

const useWidgetSelectInfiniteState = ({
    query,
    dropdownField,
    includeAllOption = false,
    selectedOverride,
    label,
    allLabel,
    filterFieldOverride,
}: UseWidgetSelectInfiniteStateArgs) => {
    const [dropdownOptions, setDropdownOptions] = useState<SelectOptionsRequest>({
        status: RequestStatus.LOADING,
        options: [],
    });
    const [selectedOption, setSelectedOption] = useState<DropdownOption>();
    const [filters, setFilters] = useState<RequestFilter[]>([]);
    const items = useFetchInfiniteResource(query);

    useEffect(() => {
        if (items.status === 'pending' && dropdownField) {
            setDropdownOptions({
                ...dropdownOptions,
                status: RequestStatus.LOADING,
            });
        }
    }, [dropdownField]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (items.status === RequestStatus.SUCCESS) {
            const dataObjects = items.data.pages.map(page => page.objects);
            const allDataObject = dataObjects.flatMap(subArray =>
                subArray.map((data: DataObject) => data[dropdownField.rawName as keyof DataObject])
            );
            const filteredUniqueNotNullDataObject = Array.from(new Set(allDataObject)).filter(data => data !== null);
            const dropdownData = filteredUniqueNotNullDataObject.map((data: DataObject) => ({
                label: data,
                value: data,
            }));

            const dataResource = {
                ...items,
                data: dropdownData,
            };

            const dropdownResource = buildDropdownResource(dataResource as unknown as UseQueryResult<DropdownOption[]>);

            if (dropdownResource.options.length > 0) {
                const dropdownLabel = allLabel ? `All ${allLabel}` : `All ${label || dropdownField.displayName}s`;
                const allOption = {
                    label: dropdownLabel,
                    value: 'all',
                } as DropdownOption;

                const isAlreadySelected = selectedOption
                    ? dropdownResource.options.some(
                          option => option.label === selectedOption.label && option.value === selectedOption.value
                      )
                    : false;

                if (includeAllOption) {
                    setDropdownOptions({
                        ...dropdownResource,
                        options: [allOption, ...dropdownResource.options],
                    });

                    if (!isAlreadySelected) {
                        setSelectedOption(allOption);
                    } else {
                        setSelectedOption(selectedOption);
                    }
                } else {
                    setDropdownOptions(dropdownResource);

                    if (!isAlreadySelected) {
                        setSelectedOption(dropdownResource.options[0]);
                    } else {
                        setSelectedOption(selectedOption);
                    }
                }
            } else {
                setDropdownOptions(dropdownResource);
                setSelectedOption(undefined);
            }
        }
    }, [items.status, items.data]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedOption && selectedOption.value !== 'all') {
            setFilters([{ field: filterFieldOverride || dropdownField, value: selectedOption.label }]);
        } else {
            setFilters([]);
        }
    }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

    // If there is a selected override, set it as the selected option
    useEffect(() => {
        if (selectedOverride) {
            if (dropdownOptions) {
                const selectedItem = dropdownOptions.options.find(object => object.label === selectedOverride);

                if (selectedItem) {
                    setSelectedOption(selectedItem);
                }
            }
        }
    }, [dropdownOptions, selectedOverride]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeSelectedOption = (selectedOption: string) => {
        setSelectedOption(dropdownOptions.options.find(option => option.value === selectedOption));
    };

    return {
        dropdownOptions,
        selectedOption,
        handleChangeSelectedOption,
        filters,
        enabled: dropdownOptions.status === RequestStatus.EMPTY || !!selectedOption,
        showEmpty: dropdownOptions.status === RequestStatus.ERROR,
        showLoading: dropdownOptions.status === RequestStatus.LOADING,
        fetchNextPage: items.fetchNextPage,
        isFetchingNextPage: items.isFetchingNextPage,
    };
};

export default useWidgetSelectInfiniteState;
