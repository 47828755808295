import React from 'react';
import { VennComparisonData, VennData } from '../../../../types';
import WidgetMenuSeriesCheckbox from '../widget-menu-series-checkbox';
import WidgetMenuDropdown from '../widget-menu-dropdown';
import { VennMenuConfig } from '../../hooks/use-widget-menu-venn-state';

type WidgetMenuVennProps = {
    data: VennData | VennComparisonData;
    menuConfig?: VennMenuConfig[];
    handleMenuChange: (label: string) => void;
};

const WidgetMenuVenn = ({ data, menuConfig, handleMenuChange }: WidgetMenuVennProps) => {
    const loading = data.status === 'loading';
    const success = data.status === 'success';

    if (loading) {
        return <WidgetMenuDropdown loading={loading} />;
    }

    if (success) {
        if (menuConfig) {
            return (
                <WidgetMenuDropdown loading={loading}>
                    {menuConfig.map((menuItem, index) => {
                        return (
                            <WidgetMenuSeriesCheckbox
                                key={menuItem.label}
                                index={index}
                                checked={menuItem.checked}
                                label={menuItem.label}
                                colour={menuItem.colour}
                                onCheckedChange={() => handleMenuChange(menuItem.label)}
                            />
                        );
                    })}
                </WidgetMenuDropdown>
            );
        }
    }
    return null;
};

export default WidgetMenuVenn;
