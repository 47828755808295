import store from '../../redux/store';
import { CubedConfigResource, CubedResource } from '../../types';
import { Params } from '../../section-dashboard/types';

type QueryKey = {
    accountId: string;
    accountToken: string;
    category: string;
    id: string;
    params?: Params;
};

type GenerateQueryKeyArgs = {
    resource?: CubedResource | CubedConfigResource;
    params?: Params;
};

const generateQueryKey = ({ resource, params }: GenerateQueryKeyArgs) => {
    const { token, id } = store.getState().account;

    if (resource) {
        const queryKey: QueryKey[] = [
            {
                accountId: id,
                accountToken: token,
                category: resource.category,
                id: resource.id,
            },
        ];

        if (params) {
            queryKey[0].params = params;
        }

        return queryKey;
    }
    return [];
};
export default generateQueryKey;
