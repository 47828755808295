import React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import styled from 'styled-components';
import IconCheck from '../../../../icons/check';
import { useConfigTableContext } from '../../context/config-table-context';

const StyledCellCheckbox = styled.td`
    width: 30px;
    padding: 0 15px;
`;

const StyledCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const StyledIconCheck = styled(IconCheck)<{ checked: boolean }>`
    height: 12px;
    width: auto;
    & path {
        fill: ${props =>
            props.checked ? props.theme.colours.white : props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledCheckbox = styled(Checkbox.Root)<{ disabled: boolean; checked: boolean }>`
    width: 18px;
    height: 18px;
    background-color: ${props =>
        props.checked
            ? props.theme.sectionDashboard.widget.menu.textColor
            : props.theme.sectionDashboard.widget.menu.separatorColor};
    border: 2px solid
        ${props =>
            props.disabled
                ? props.theme.colours.midGrey
                : props.checked
                ? props.theme.sectionDashboard.widget.menu.backgroundHover
                : props.theme.sectionDashboard.widget.menu.textColor};
    border-radius: 5px;
    display: flex;
    padding-top: 2px;
    justify-content: center;
    align-items: center;

    &:hover {
        border-color: ${props => props.checked && props.theme.colours.black};
        background-color: ${props => props.checked && props.theme.colours.black};

        & path {
            fill: ${props => props.theme.colours.white};
        }
    }
`;

type CellCheckboxProps = {
    rowId: number;
    checked: boolean;
    onCheckedChange: (rowId: number) => void;
};

const CellCheckbox = ({ rowId, checked, onCheckedChange }: CellCheckboxProps) => {
    const { status, isFetching, tableDisabled } = useConfigTableContext();

    if (status === 'success') {
        return (
            <StyledCellCheckbox>
                <StyledCheckboxContainer>
                    <StyledCheckbox
                        disabled={tableDisabled || isFetching}
                        checked={checked}
                        onCheckedChange={() => onCheckedChange(rowId)}
                        data-testid="config-table-cell-checkbox"
                    >
                        <Checkbox.Indicator>
                            <StyledIconCheck checked={checked} />
                        </Checkbox.Indicator>
                    </StyledCheckbox>
                </StyledCheckboxContainer>
            </StyledCellCheckbox>
        );
    }
    return null;
};

export default CellCheckbox;
