import React, { memo } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import Tooltip from '../tooltip';

type CommonFormInputProps = {
    inputType: 'number' | 'text';
    name: string;
    placeholder: string;
    value: string | number;
    dataKey: string;
    required: boolean;
    checked: boolean;
    disabled: boolean;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    onKeyPress: () => {};
    autocomplete: string;
    step: number;
    min: number;
    label: string;
    requiredField: boolean;
    toolTipCopy: string;
};

const StyledLable = styled('div')`
    margin-bottom: 5px;
`;

const StyledRequired = styled('span')`
    color: #d2004a;
`;

const StyledTooltip = styled('div')`
    float: right;
    position: relative;
    z-index: 500001;
`;

const StyledInput = styled('input')`
    width: 100%;
    height: 40px;

    border: none;
    padding: 0 5px;
    margin-bottom: 5px;
    border-radius: 2px;

    ::placeholder,
    ::-webkit-input-placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }
    :-ms-input-placeholder {
        font-weight: 300;
        font-size: 0.9em;
    }

    background-color: ${props => (props.disabled ? props.theme.colours.lightGrey : props.theme.colours.inputColour)};
    border-bottom: ${props =>
        props.disabled ? '2px solid #8c8c8c' : `solid 2px ${props.theme.colours.offBlackLighter}`};
    color: ${props => props.disabled && '#8c8c8c'};
    cursor: ${props => props.disabled && 'not-allowed!important'};
`;

const CommonFormInput = ({
    inputType,
    name,
    placeholder,
    value,
    dataKey,
    required,
    checked,
    disabled,
    onChange,
    onKeyPress,
    autocomplete,
    step,
    min,
    label,
    requiredField,
    toolTipCopy,
}: CommonFormInputProps) => {
    return (
        <div>
            {label && (
                <StyledLable>
                    {label}
                    {requiredField && <StyledRequired>*</StyledRequired>}
                    {toolTipCopy && (
                        <StyledTooltip>
                            <Tooltip copy={toolTipCopy} />
                        </StyledTooltip>
                    )}
                </StyledLable>
            )}
            <StyledInput
                data-key={dataKey}
                data-required={required}
                type={inputType}
                name={name}
                placeholder={placeholder}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                onKeyPress={onKeyPress}
                autoComplete={autocomplete}
                step={step}
                min={min}
            />
        </div>
    );
};

export default memo(CommonFormInput, isEqual);
