import moment from 'moment';

export const formatAnnotationResponse = (chartData, response) => {
    const data = [];

    chartData[0].data.forEach(chartDataItem => {
        const chartDataDate = moment(new Date(chartDataItem[0])).format('YYYY-MM-DD');

        const matchedAnnotation = response.data.find(dataItem => dataItem.date === chartDataDate);

        if (matchedAnnotation) {
            data.push({
                name: matchedAnnotation.name,
                data: chartDataItem,
            });
        }
    });
    return data;
};

export const formatAnnotationsChartConfig = annotationData => {
    const annotationsConfig = [];

    annotationData.forEach(annotationDataItem => {
        if (annotationDataItem.checked) {
            annotationDataItem.data.forEach(dataItem => {
                annotationsConfig.push({
                    draggable: true,
                    labelOptions: {
                        backgroundColor: '#1e1c1c',
                        borderRadius: 10,
                        borderWidth: 1,
                        allowOverlap: true,
                    },
                    labels: [
                        {
                            point: {
                                xAxis: 0,
                                yAxis: 0,
                                x: dataItem.data[0],
                                y: dataItem.data[1],
                            },
                            text: dataItem.name,
                            style: {
                                width: 'fit-content',
                                cursor: 'pointer',
                            },
                        },
                    ],
                });
            });
        }
    });

    return annotationsConfig;
};
