import React, { useEffect, useState, memo, useContext } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { isEqual } from 'lodash';

// Redux Dependencies
import { connect } from 'react-redux';
import { setTitle, setDescription } from '../../redux/actions/page-meta';
import { setModal } from '../../redux/actions/modal';

// Highcharts Dependencies
import Highcharts from 'highcharts';
import VennModule from 'highcharts/modules/venn.js';
import CommonHighcharts from '../../components/common/common-highcharts';

// Helpers & Config
import { generateUrl } from '../../helpers/request-builder';
import { vennConfig } from '../../configurations/charts/venn-diagram-config';
import { DatePickerType } from '../../configurations/enums/date-picker-type';

// Components
import DropdownWithSearch from '../../components/common/dropdown-with-search';
import Message from '../../components/message';
import LoadingSpinner from '../../components/loading-spinner';

// Filterbar
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';

VennModule(Highcharts);

const StyledContainer = styled.div`
    display: block;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 6px;
    background-color: #ffffff;
`;

const StyledDropdownContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 30px;
    margin-bottom: 30px;
`;

const StyledLinkContainer = styled.div`
    width: 100%;
    text-align: center;
    margin: 30px;
`;

const StyledButton = styled.button`
    background-color: #ffffff;
    border: 0;
    padding: 0;
    color: #58b5c4;
`;

const SegmenterVenn = ({ startDate, endDate, setTitle, setModal, status }) => {
    const { setFilterStatus, setFilterMetricsOptions, setDatePickerConfig } = useContext(FilterBarContext);

    // API Returned Data
    const [segments, setSegments] = useState([]);
    const [vennData, setVennData] = useState([]);

    // Selected Segments
    const [firstSelectedSegment, setFirstSelectedSegment] = useState(null);
    const [secondSelectedSegment, setSecondSelectedSegment] = useState(null);
    const [thirdSelectedSegment, setThirdSelectedSegment] = useState(null);
    const [selectedSegments, setSelectedSegments] = useState([]);

    // Page States
    const [showLoading, setShowLoading] = useState(true);
    const [showPageError, setShowPageError] = useState(false);
    const [showDateError, setShowDateError] = useState(false);
    const [notEnoughDataError, setNotEnoughDataError] = useState(false);

    // set report props on page load
    useEffect(() => {
        setTitle('Segment Venn');

        // Filter bar configurations
        setFilterStatus({
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: false,
        });
        setFilterMetricsOptions([]); // reset metrics
        setDatePickerConfig({ datePickerType: DatePickerType.Day });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // check a selected date for validity and trigger api requests
    useEffect(() => {
        const showDateError = startDate.format('L') !== endDate.format('L');
        setShowDateError(showDateError);

        if (!showDateError) {
            setShowLoading(true);
            setFirstSelectedSegment(null);
            setSecondSelectedSegment(null);
            setThirdSelectedSegment(null);
            fetchAllSegments();
            fetchInitialVennData();
        }
    }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

    // every time a segment dropdown is changed save selected segments to state
    useEffect(() => {
        const currentSelectedSegments = [
            firstSelectedSegment?.value,
            secondSelectedSegment?.value,
            thirdSelectedSegment?.value,
        ]
            .filter(segment => segment) // remove null/undefined
            .sort();

        setSelectedSegments(currentSelectedSegments);
    }, [firstSelectedSegment, secondSelectedSegment, thirdSelectedSegment]);

    // api call to get segments when selected segments change
    useEffect(() => {
        if (selectedSegments.length > 1) {
            const requests = [];

            // get data for individual segments
            selectedSegments.forEach(segment => {
                requests.push(
                    axios({
                        method: 'GET',
                        url: generateUrl('segmenter', 'report-segmenter-segment-venn', [
                            { key: 'segments', value: `(${segment})` },
                            { key: 'date', value: startDate.format('YYYY-MM-DD') },
                            { key: 'group_by', value: 'date' },
                        ]),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });

            // get data for joint segments
            requests.push(
                axios({
                    method: 'GET',
                    url: generateUrl('segmenter', 'report-segmenter-segment-venn', [
                        { key: 'segments', value: `(${selectedSegments[0]},${selectedSegments[1]})` },
                        { key: 'date', value: startDate.format('YYYY-MM-DD') },
                        { key: 'group_by', value: 'date' },
                    ]),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );

            if (selectedSegments[2]) {
                requests.push(
                    axios({
                        method: 'GET',
                        url: generateUrl('segmenter', 'report-segmenter-segment-venn', [
                            { key: 'segments', value: `(${selectedSegments[0]},${selectedSegments[2]})` },
                            { key: 'date', value: startDate.format('YYYY-MM-DD') },
                            { key: 'group_by', value: 'date' },
                        ]),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
                requests.push(
                    axios({
                        method: 'GET',
                        url: generateUrl('segmenter', 'report-segmenter-segment-venn', [
                            { key: 'segments', value: `(${selectedSegments[1]},${selectedSegments[2]})` },
                            { key: 'date', value: startDate.format('YYYY-MM-DD') },
                            { key: 'group_by', value: 'date' },
                        ]),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
                requests.push(
                    axios({
                        method: 'GET',
                        url: generateUrl('segmenter', 'report-segmenter-segment-venn', [
                            {
                                key: 'segments',
                                value: `(${selectedSegments[0]},${selectedSegments[1]},${selectedSegments[2]})`,
                            },
                            { key: 'date', value: startDate.format('YYYY-MM-DD') },
                            { key: 'group_by', value: 'date' },
                        ]),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            }

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        const data = responses
                            .map((response, index) => {
                                if (response.data.objects.length > 0) {
                                    const responseData = response.data.objects[0];
                                    const firstLabel = responseData.first_segment.value;
                                    const secondLabel = responseData.second_segment.value;
                                    const thirdLabel = responseData.third_segment.value;
                                    return {
                                        sets: responseData.segments.replace('(', '').replace(')', '').split(','),
                                        value: responseData.visitors.raw_value,
                                        name: `${firstLabel} ${secondLabel && `& ${secondLabel}`} ${
                                            thirdLabel && `& ${thirdLabel}`
                                        }`,
                                    };
                                }
                                return null;
                            })
                            .filter(data => data);

                        const series = [
                            {
                                type: 'venn',
                                name: 'Segments',
                                data: data,
                            },
                        ];

                        setVennData(series);
                        setShowDateError(false);
                        setShowLoading(false);
                        setShowPageError(false);
                    })
                )
                .catch(e => {
                    console.log(e);
                    setShowLoading(false);
                    setShowDateError(false);
                    setShowPageError(true);
                });
        }
    }, [selectedSegments]); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchAllSegments = () => {
        axios({
            method: 'GET',
            url: generateUrl('segmenter', 'segmenter', [{ key: 'active', value: true }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                const segments = response.data.objects
                    .map(item => {
                        return {
                            label: item.name,
                            value: item.id,
                        };
                    })
                    .filter(segment => segment);

                setSegments(segments);
            })
            .catch(e => {
                console.log(e);
                setShowLoading(false);
                setShowDateError(false);
                setShowPageError(true);
            });
    };

    const fetchInitialVennData = () => {
        // Regex pattern "^((\\?!\w+).)*$" is used to check if the column has no words or it is blank.

        axios({
            method: 'GET',
            url: generateUrl('segmenter', 'report-segmenter-segment-venn', [
                { key: 'order_by', value: '-visitors' },
                { key: 'group_by', value: 'date' },
                { key: 'second_segment__regex', value: '^((\\?!w+).)*$' },
                { key: 'date', value: startDate.format('YYYY-MM-DD') },
            ]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.data.objects.length < 2) {
                    setNotEnoughDataError(true);
                    setShowLoading(false);
                    setShowPageError(false);
                } else {
                    // find the 2 segments with the most visits and use these initially in the diagram
                    const firstSegment = response.data.objects[0];
                    const secondSegment = response.data.objects[1];

                    setFirstSelectedSegment({
                        label: firstSegment.first_segment.value,
                        value: parseInt(firstSegment.segments.replace('(', '').replace(')', null)),
                    });
                    setSecondSelectedSegment({
                        label: secondSegment.first_segment.value,
                        value: parseInt(secondSegment.segments.replace('(', '').replace(')', null)),
                    });

                    setShowLoading(false);
                    setNotEnoughDataError(false);
                    setShowPageError(false);
                }
            })
            .catch(e => {
                console.log(e);
                setShowLoading(false);
                setShowDateError(false);
                setShowPageError(true);
            });
    };

    const handleFirstDropdownSelect = selectedOption => {
        setFirstSelectedSegment(selectedOption);
    };

    const handleSecondDropdownSelect = selectedOption => {
        setSecondSelectedSegment(selectedOption);
    };

    const handleThirdDropdownSelect = selectedOption => {
        setThirdSelectedSegment(selectedOption);
    };

    const handleCreateButtonClick = () => {
        setModal('SegmenterEditSegment', {});
    };

    if (showLoading) {
        return (
            <StyledContainer>
                <LoadingSpinner />
            </StyledContainer>
        );
    } else if (showDateError) {
        return (
            <StyledContainer>
                <Message type="error" title="Date Error" copy="Sorry, you can only view one day at a time." />
            </StyledContainer>
        );
    } else if (showPageError) {
        return (
            <StyledContainer>
                <Message
                    type="error"
                    title="Date Error"
                    copy="There was a server issue getting this page ready. Please try again later, or contact support@cubed.email'"
                />
            </StyledContainer>
        );
    } else if (notEnoughDataError) {
        return (
            <StyledContainer>
                <Message
                    type="info"
                    title="Not Enough Data"
                    copy="Sorry, there isn't enough data to show for the selected day. Please try a different day."
                />
                <StyledLinkContainer>
                    You can manage and create new segments{' '}
                    <StyledButton onClick={handleCreateButtonClick}>here</StyledButton>
                </StyledLinkContainer>
            </StyledContainer>
        );
    } else {
        return (
            <StyledContainer>
                <StyledDropdownContainer>
                    <DropdownWithSearch
                        dropdownItems={segments.filter(segment => !selectedSegments.includes(segment.value))}
                        selected={firstSelectedSegment}
                        onSelectedItemChange={handleFirstDropdownSelect}
                        label="Segment 1"
                        placeholder="Select a segment"
                        isClearable={true}
                    />
                    <DropdownWithSearch
                        dropdownItems={segments.filter(segment => !selectedSegments.includes(segment.value))}
                        selected={secondSelectedSegment}
                        onSelectedItemChange={handleSecondDropdownSelect}
                        label="Segment 2"
                        placeholder="Select a segment"
                        isClearable={true}
                    />
                    <DropdownWithSearch
                        dropdownItems={segments.filter(segment => !selectedSegments.includes(segment.value))}
                        selected={thirdSelectedSegment}
                        onSelectedItemChange={handleThirdDropdownSelect}
                        label="Segment 3"
                        placeholder="Select a segment"
                        isClearable={true}
                    />
                </StyledDropdownContainer>
                {selectedSegments.length > 1 ? (
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ series: vennData, title: 'Segments', ...vennConfig }}
                        immutable={true}
                    />
                ) : (
                    <Message type="info" copy="Please select at least two segments to display in the diagram." />
                )}
            </StyledContainer>
        );
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setTitle: title => {
            dispatch(setTitle(title));
        },
        setDescription: description => {
            dispatch(setDescription(description));
        },
    };
};

const mapStateToProps = state => {
    return {
        startDate: state.date.startDate,
        endDate: state.date.endDate,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(SegmenterVenn, isEqual));
