import { DefaultTheme } from 'styled-components';
import { graphColours } from '../helpers/graph-colours';
import { TooltipFormatter } from '../helpers/tooltip-formatter';

const colours = graphColours;

export const nestedDonutChartConfig = (theme: DefaultTheme, width: number, height: number) => {
    return {
        colors: [...colours.map(colour => colour.solidGraphColour)],
        chart: {
            type: 'column',
            inverted: true,
            polar: true,
            style: {
                fontFamily: 'Work Sans',
            },
            backgroundColor: 'transparent',
            animation: false,
        },
        title: {
            text: null,
        },
        tooltip: {
            outside: true,
        },
        pane: {
            size: '100%',
            innerSize: '20%',
            endAngle: 360,
        },
        xAxis: {
            tickInterval: 1,
            labels: {
                enabled: true,
                useHTML: true,
                align: 'left',
                style: {
                    textOutline: '0px',
                    fontSize: '10px',
                    fontWeight: 600,
                    background: theme.name === 'dark' ? '#fff' : 'rgba(255, 255, 255, 0.6)',
                    padding: '3px 5px',
                    borderRadius: '6px',
                },
            },
            lineWidth: 0,
            gridLineWidth: 0,
        },
        yAxis: {
            labels: {
                enabled: false,
            },
            lineWidth: 0,
            tickInterval: 25,
            reversedStacks: false,
            endOnTick: true,
            showLastLabel: true,
            gridLineWidth: 0,
        },
        plotOptions: {
            series: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    formatter: function (this: TooltipFormatter) {
                        return `<div style="text-align:center;">
                                    ${this.series.name}
                                    <div style="font-size: 12px; font-weight: 800;">${this.percentage.toFixed(0)}%</div>
                                </div>`;
                    },
                    useHTML: true,
                    style: {
                        textOutline: '0px',
                        fontSize: height > 3 && width > 6 ? '11px' : '10px',
                        fontWeight: 600,
                        color: theme.sectionDashboard.widget.pie.dataLabelColor,
                    },
                },
            },
            column: {
                stacking: 'percent',
                borderWidth: 0,
                pointPadding: 0,
                groupPadding: 0.15,
                borderRadius: 0,
            },
            borderColor: theme.name === 'dark' && '#605f5f',
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };
};

export const nestedDonutBarChartConfig = (theme: DefaultTheme) => {
    return {
        colors: [...colours.map(colour => colour.solidGraphColour)],
        chart: {
            type: 'bar',
            style: {
                fontFamily: 'Work Sans',
            },
            backgroundColor: 'transparent',
        },
        title: {
            text: null,
        },
        xAxis: {
            endOnTick: false,
            labels: {
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                },
            },
        },
        yAxis: {
            endOnTick: false,
            title: {
                text: null,
            },
            gridLineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#e6e6e6',
            labels: {
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                },
            },
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                },
                borderColor: theme.name === 'dark' && '#605f5f',
            },
            column: {
                pointPlacement: 'on',
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };
};

export const nestedDonutColumnChartConfig = (theme: DefaultTheme) => {
    return {
        colors: [...colours.map(colour => colour.solidGraphColour)],
        chart: {
            type: 'column',
            style: {
                fontFamily: 'Work Sans',
            },
            backgroundColor: 'transparent',
        },
        title: {
            text: null,
        },
        xAxis: {
            endOnTick: false,
            labels: {
                enabled: true,
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                },
            },
        },
        yAxis: {
            endOnTick: false,
            allowDecimals: false,
            min: 0,
            title: {
                text: null,
            },
            gridLineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#e6e6e6',
            labels: {
                enabled: true,
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                },
            },
        },
        plotOptions: {
            series: {
                borderColor: theme.name === 'dark' && '#605f5f',
            },
            column: {
                stacking: 'normal',
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };
};
