import React, { ChangeEventHandler, useEffect, useState } from 'react';
import axios, { AxiosPromise } from 'axios';

// Redux
import { useDispatch } from 'react-redux';
import { removePopup } from '../../../redux/actions/popup';
import { setModal } from '../../../redux/actions/modal';

// Styled Components
import styled from 'styled-components';
import { StyledFormContainer } from '../create-segment';

// Helpers & Reference Objects
import { generateUrl, generateUrlDetail, generatePath } from '../../../helpers/request-builder';
import { GroupRule } from '../../../helpers/segmenter/group-rule';
import { OPERATORS } from '../../../helpers/segmenter/reference-objects';

import { DragAndDropTableRow } from '../../../components/tables/types';
import { CubedAdvancedRuleResource } from '../../../types';

import {
    reorderRows,
    handleAddNewRow,
    fetchRequests,
    resetTableRows,
    showSaveChangesModal,
    addRowRequest,
    showDeleteModal,
    modifiedRowRequest,
} from '../../../helpers/segmenter/advanced-rule-builder-helper';
import { ButtonThemes } from '../../../enums/button-themes';

// Components
import DragAndDropTable from '../../../components/tables/components/drag-and-drop-table';
import InputButton from '../../../components/inputs/input-button';
import DropdownWithSearch from '../../../components/common/dropdown-with-search';
import SegmenterRuleBuilder from '../segmenter-rule-builder';
import ActionButtons from '../../../components/navigation/action-buttons';
import FormTextInput from '../../../components/form-fields/form-text-input';
import FormTextArea from '../../../components/form-fields/form-text-area';

// Hooks
import useButtonStates from './hooks/use-buttons-states';
import useTableStatus from '../../../components/tables/hooks/use-table-status';

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
`;

const StyledActionButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const StyledReorderButtonContainer = styled.div`
    display: flex;
    gap: 10px;
`;

const StyledSelectPriorityContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
`;

const StyledPriorityFormContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const StyledSelectPriorityDropdown = styled.div`
    flex-grow: 1;
`;

const StyledSaveActionsButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 10px;
`;

const StyledAddConditionButtonContainer = styled.div`
    text-align: left;
    margin-bottom: 20px;
    margin-top: 10.5px;
`;

type ForeignKeyColumnNameType = 'subchannel' | 'pattern';

type AdvancedRuleBuilderProps = {
    resource: CubedAdvancedRuleResource;
    fkResource: CubedAdvancedRuleResource;
    fkColumnName: ForeignKeyColumnNameType;
    segmentName: string;
    ruleObject: any;
    segmentType: string;
    segmentTypeKey?: string;
    modalNavigation: (pageError: boolean, pageLoading: boolean) => JSX.Element;
};

type SegmenterData = {
    dataType?: string;
    dataTypeId?: number;
    label: string;
    value: string;
};
type SegmenterRuleData = {
    id?: number;
    name?: string;
    description?: string;
    priority?: number;
    rule?: string;
    active?: boolean;
    subchannel?: string;
    marketingFunnel?: string;
    brandStatus?: string;
    referer?: string;
    pattern_id?: number;
    referer_id?: number;
    pattern?: string;
};

type ForeignKeyColumnNameObjectType = {
    name: string;
    active: boolean;
    referer_name?: string;
    referer_id?: number;
    pattern_id?: number;
    pattern?: string;
};

export type SegmenterRuleDataWithActiveField = SegmenterRuleData & {
    [key in ForeignKeyColumnNameType]: ForeignKeyColumnNameObjectType;
};

type SegmentKeys = {
    subchannel: string;
    brandStatus: string;
    marketingFunnel: string;
    pattern: string;
    referer: string;
};

type Function = {
    display_name: string;
    table_name: string;
    function_name: string;
    id: number;
};

type FunctionOperator = {
    display_name: string;
    name: string;
    id: number;
};

type ConditionDataResponse = {
    active: boolean;
    display_name: string;
    function: Function;
    function_operator: FunctionOperator;
    has: boolean;
    id: number;
    key: string;
    value: string;
};

const LayoutAdvancedRuleBuilder = ({
    resource,
    fkResource,
    fkColumnName,
    segmentName,
    ruleObject,
    segmentType,
    segmentTypeKey,
    modalNavigation,
}: AdvancedRuleBuilderProps) => {
    const [rule, setRule] = useState(new GroupRule(OPERATORS[0]));
    const [pattern, setPattern] = useState('');

    // Page States
    const [pageLoading, setPageLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [disableTableClick, setDisableTableClick] = useState(false);
    const [showEditCreateSegmenter, setShowEditCreateSegmenter] = useState(false);
    const [changesSaving, setChangesSaving] = useState(false);
    const [editingTableOrder, setEditingTableOrder] = useState(false);
    const [showSelectPriority, setShowSelectPriority] = useState(false);
    const [showCreateNewSegment, setShowCreateNewSegment] = useState(false);
    const [noData, setNoData] = useState(false);

    // Data returned from api
    const [conditions, setConditions] = useState([]);
    // contains only active segmenter rule data
    const [activeSegmenterRuleData, setActiveSegmenterRuleData] = useState<SegmenterRuleData[]>([]);
    // contains all active and deleted segmenter rule data
    const [allSegmenterRuleData, setAllSegmenterRuleData] = useState<SegmenterRuleData[]>([]);
    // contains segmenter data (brand status, marketing funnel, patterns, subchannels)
    const [segmenterData, setSegmenterData] = useState<SegmenterData[]>([]);

    // Selected values
    const [segmentCurrentlyEditing, setSegmentCurrentlyEditing] = useState<SegmenterRuleData>();
    const [selectedStatus, setSelectedStatus] = useState<SegmenterData>();
    const [selectedPosition, setSelectedPosition] = useState<SegmenterData>();
    const [selectedRow, setSelectedRow] = useState<SegmenterData>();

    // Table Rows
    const [tableRows, setTableRows] = useState<DragAndDropTableRow[]>([]);
    const [rowSnapshot, setRowSnapshot] = useState<DragAndDropTableRow[]>([]);

    // Redux
    const dispatch = useDispatch();

    let segmentKey: keyof SegmenterRuleData & keyof SegmentKeys = segmentName as keyof SegmenterRuleData &
        keyof SegmentKeys;
    let refererAsAKey: (keyof SegmenterRuleData & keyof SegmentKeys) | undefined = segmentTypeKey as
        | (keyof SegmenterRuleData & keyof SegmentKeys)
        | undefined;

    const isNotUniqueRule =
        segmentCurrentlyEditing &&
        activeSegmenterRuleData &&
        activeSegmenterRuleData.some(
            row => row.rule === segmentCurrentlyEditing.rule && row.id !== segmentCurrentlyEditing.id
        );

    const segmenterRuleDataToUpdate = allSegmenterRuleData.filter(row => row.rule === segmentCurrentlyEditing?.rule);
    const segmentCurrentlyEditingStatus = segmentCurrentlyEditing?.[refererAsAKey || segmentKey];
    const tableStatus = useTableStatus({ tableLoading, changesSaving, saveError, noData });

    // Button States
    const {
        priorityOrderButtonDisabled,
        createButtonDisabled,
        editButtonDisabled,
        deleteButtonDisabled,
        saveButtonDisabled,
        applyButtonDisabled,
    } = useButtonStates({
        tableRows,
        showEditCreateSegmenter,
        editingTableOrder,
        tableStatus,
        segmentCurrentlyEditing,
        isNotUniqueRule,
        segmentCurrentlyEditingStatus,
    });

    // on page load - fetch data
    useEffect(() => {
        fetchFormData();
        fetchSegmenterData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // update final pattern every time there is a change to rules
    useEffect(() => {
        if (segmentCurrentlyEditing) {
            setSegmentCurrentlyEditing({
                ...segmentCurrentlyEditing,
                rule: `(${pattern})`,
            });
        }
    }, [pattern]); // eslint-disable-line react-hooks/exhaustive-deps

    // helper functions
    const mapSectionRows = (row: SegmenterRuleDataWithActiveField) => {
        let mappedRow: SegmenterRuleData = {
            id: row.id,
            name: row.name,
            description: row.description,
            priority: row.priority,
            rule: row.rule,
            [segmentKey]: row[fkColumnName].name,
        };
        if (segmentKey === 'pattern') {
            mappedRow.referer = row.pattern.referer_name;
            mappedRow.referer_id = row.pattern.referer_id;
            mappedRow.pattern_id = row.pattern.pattern_id;
            mappedRow.pattern = row.pattern.pattern;
        }
        return mappedRow;
    };

    const mapSectionColumn = (column: SegmenterRuleData) => {
        let mappedColumn: DragAndDropTableRow = {
            id: column.id!,
            dragId: fkResource.name + '-' + column.id,
            columns: {
                priority: column.priority!,
                name: column.name!,
                description: column.description!,
                rule: column.rule!,
            },
            selected: false,
        };
        mappedColumn.columns[segmentKey] = column[segmentKey] || '';

        if (segmentKey === 'pattern') {
            mappedColumn.columns.referer = column.referer || '';
            mappedColumn.columns.referer_id = column.referer_id || '';
            mappedColumn.columns.pattern_id = column.pattern_id || '';
            mappedColumn.columns.pattern = column.pattern || '';
        }
        return mappedColumn;
    };

    const modifyReferer = async () => {
        const request = {
            method: 'PATCH',
            url: generateUrlDetail(
                fkResource.category,
                fkResource.name,
                segmentCurrentlyEditing?.pattern_id?.toString()
            )!,
            data: {
                referer: generatePath('config', 'referer', selectedStatus?.value),
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        await axios(request).catch(e => {
            console.log('error:', e);
            throw e;
        });
    };
    const getPatternStatus = async (refererValue: string, nameOfEditingSegment: string, refererLabel: string) => {
        const patternName = '*segmenter-pattern*' + nameOfEditingSegment + '=' + refererLabel + '*';
        const newPattern = {
            method: 'POST',
            url: generateUrl(fkResource.category, fkResource.name),
            data: {
                pattern: patternName,
                referer: generatePath('config', 'referer', refererValue),
                active: true,
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const newPatternRequest = await axios(newPattern);

        return { label: patternName, value: newPatternRequest.data.pattern_id };
    };
    // helper function ends

    const fetchSegmenterData = () => {
        setTableLoading(true);
        axios({
            method: 'GET',
            url: generateUrl(resource.category, resource.name, [{ key: 'order_by', value: 'priority' }]),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                let allRuleData: SegmenterRuleData[] = [];
                let activeRuleData: SegmenterRuleData[] = [];

                response.data.objects.forEach((row: SegmenterRuleDataWithActiveField) => {
                    const mappedRow: SegmenterRuleData = mapSectionRows(row);
                    allRuleData.push(mappedRow);

                    if (row.active === true) {
                        activeRuleData.push(mappedRow);
                    }
                });

                setAllSegmenterRuleData(allRuleData);
                setActiveSegmenterRuleData(activeRuleData);

                // set the table rows
                const rows = activeRuleData.map(column => {
                    return mapSectionColumn(column);
                });

                setTableRows(rows);
                setTableLoading(false);
                setDisableTableClick(false);
                setNoData(activeRuleData.length === 0);
            })
            .catch(e => {
                console.log(e);
                setPageError(true);
            });
    };

    const fetchFormData = () => {
        let resourceGroup = segmentName === 'pattern' ? 'config' : fkResource.category;
        let resourceName = segmentName === 'pattern' ? 'referer' : fkResource.name;

        const requests = fetchRequests(resourceGroup, resourceName);

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    const conditionsData = responses[0].data.objects.map((condition: ConditionDataResponse) => {
                        return {
                            id: condition.id,
                            functionName: condition.function.display_name,
                            relatedTable: condition.function.table_name,
                            relatedTableKey: condition.key,
                            operator: condition.function_operator.name,
                            inputValue: condition.value,
                            label: condition.display_name,
                            value: condition.id,
                            active: condition.active,
                        };
                    });

                    const segmenterData = responses[1].data.objects.map((segmenterData: SegmenterRuleData) => {
                        return {
                            label: segmenterData.name,
                            value: segmenterData.id,
                        };
                    });

                    setConditions(conditionsData);
                    setSegmenterData(segmenterData);
                })
            )
            .catch(e => {
                setPageError(true);
            })
            .finally(() => {
                setPageLoading(false);
            });
    };

    const handleReorderRows = (rows: DragAndDropTableRow[], startIndex: number, endIndex: number) => {
        setTableRows(reorderRows(rows, startIndex, endIndex));
    };

    const handleResetTableRows = () => {
        setTableRows(resetTableRows(tableRows));
    };

    const handleTableRowClick = (rowId: number) => {
        if (!disableTableClick) {
            const rows = tableRows.map(row => {
                return {
                    ...row,
                    selected: row.selected ? false : rowId === row.id,
                };
            });

            setTableRows(rows);
        }
    };

    const handleEditClick = () => {
        const segmentToEdit = activeSegmenterRuleData.filter(
            data => data.id === tableRows.filter(row => row.selected)[0].id
        )[0];

        setSelectedStatus(
            segmenterData.filter(status => status.label === segmentToEdit[refererAsAKey || segmentKey])[0]
        );

        setSegmentCurrentlyEditing(segmentToEdit);
        setShowEditCreateSegmenter(true);
        setDisableTableClick(true);
        const group = rule.deserialize(segmentToEdit.rule, conditions);
        if (group) {
            setRule(group);
        }
    };

    const handleApplyEditChanges = async () => {
        dispatch(removePopup());
        setChangesSaving(true);
        setShowEditCreateSegmenter(false);

        let status: SegmenterData = selectedStatus!;
        if (segmentName === 'pattern') {
            status = {
                label: segmentCurrentlyEditing?.pattern!,
                value: segmentCurrentlyEditing?.pattern_id?.toString()!,
            };
            modifyReferer();
        }

        const requests = modifiedRowRequest({
            segmenterRuleDataToUpdate,
            segmentCurrentlyEditing,
            status,
            resource,
            fkResource,
            fkColumnName,
        });

        axios
            .all(requests)
            .then(() => {
                setChangesSaving(false);
                setSegmentCurrentlyEditing(undefined);
                setSelectedStatus(undefined);
                setDisableTableClick(false);
                handleResetTableRows();
                fetchSegmenterData();
            })
            .catch(e => {
                console.log(e);
                setChangesSaving(false);
                setSaveError(true);
            });
    };

    const handleApplyClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        showSaveChangesModal(dispatch, handlePopupDiscardClick, handleApplyEditChanges);
    };

    const handleDelete = () => {
        dispatch(removePopup());
        setDisableTableClick(true);
        setChangesSaving(true);

        const segmentToDelete = activeSegmenterRuleData.filter(
            row => row.id === tableRows.filter(row => row.selected)[0].id
        )[0];
        axios({
            method: 'DELETE',
            url: generateUrlDetail(resource.category, resource.name, segmentToDelete.id?.toString())!,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(() => {
                setChangesSaving(false);
                setSegmentCurrentlyEditing(undefined);
                setDisableTableClick(false);
                handleResetTableRows();
                fetchSegmenterData();
            })
            .catch(e => {
                console.log(e);
                setChangesSaving(false);
                setSaveError(true);
            });
    };

    const handlePopupDiscardClick = () => {
        dispatch(removePopup());
        setShowSelectPriority(false);
        setShowEditCreateSegmenter(false);
        setDisableTableClick(false);
    };

    const handleCancelEdit = () => {
        setShowSelectPriority(false);
        setShowEditCreateSegmenter(false);
        setSegmentCurrentlyEditing(undefined);
        handleResetTableRows();
        setRule(new GroupRule(OPERATORS[0]));
        setDisableTableClick(false);
        setShowCreateNewSegment(false);
        // reset dropdown selected value
        setSelectedStatus(undefined);
        setSelectedPosition(undefined);
        setSelectedRow(undefined);
    };

    const handleChangeSegmenterDataName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const segmenterEditing = {
            ...segmentCurrentlyEditing,
            name: e.target.value,
        };
        setSegmentCurrentlyEditing(segmenterEditing);
    };

    const handleChangeSegmenterDataDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        const segmenterEditing = {
            ...segmentCurrentlyEditing,
            description: e.target.value,
        };
        setSegmentCurrentlyEditing(segmenterEditing);
    };

    const handlePatternChange = (pattern: string) => {
        setPattern(pattern);
    };

    const handleSelectedStatus = (selectedOption: SegmenterData) => {
        setSelectedStatus(selectedOption);
        const segmenterEditing = {
            ...segmentCurrentlyEditing,
            [refererAsAKey || segmentName]: selectedOption.label,
        };
        setSegmentCurrentlyEditing(segmenterEditing);
    };

    const handleSelectedPosition = (selectedOption: SegmenterData) => {
        setSelectedPosition(selectedOption);
    };

    const handleSelectedSegment = (selectedOption: SegmenterData) => {
        setSelectedRow(selectedOption);
    };

    const handleReorderButtonClick = () => {
        if (editingTableOrder) {
            setChangesSaving(true);
            const requests: AxiosPromise[] = [];

            tableRows.forEach(row => {
                const reorderedRow = allSegmenterRuleData.filter(data => data.id === row.id);

                // save modified reordered rows
                if (row.columns.priority !== reorderedRow[0].priority) {
                    requests.push(
                        axios({
                            method: 'PATCH',
                            url: generateUrlDetail(resource.category, resource.name, row.id.toString())!,
                            data: {
                                priority: row.columns.priority,
                            },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                }
            });

            axios
                .all(requests)
                .then(() => {
                    setChangesSaving(false);
                    setSegmentCurrentlyEditing(undefined);
                    setDisableTableClick(false);
                    handleResetTableRows();
                    fetchSegmenterData();
                })
                .catch(e => {
                    console.log(e);
                    setSaveError(true);
                    setChangesSaving(false);
                });

            setEditingTableOrder(false);
        } else {
            handleResetTableRows();
            setRowSnapshot(tableRows);
            setSelectedStatus(undefined);
            setEditingTableOrder(true);
            setDisableTableClick(true);
        }
    };

    const handleCancelReorder = () => {
        setEditingTableOrder(false);
        setTableRows(rowSnapshot);
        setRowSnapshot([]);
        setDisableTableClick(false);
    };

    const handleCreateNew = () => {
        handleResetTableRows();
        setShowSelectPriority(true);
        setShowEditCreateSegmenter(true);
        setShowCreateNewSegment(true);
        setDisableTableClick(true);
        setRule(new GroupRule(OPERATORS[0]));

        const segmentToEdit = {
            [refererAsAKey || segmentKey]: null,
            name: undefined,
            description: undefined,
            priority: undefined,
            rule: undefined,
        };

        setSegmentCurrentlyEditing(segmentToEdit);
    };

    const handleSaveClick = async () => {
        const results = handleAddNewRow(selectedPosition, selectedRow, segmentCurrentlyEditing, tableRows);
        setChangesSaving(true);
        setShowSelectPriority(false);
        setShowEditCreateSegmenter(false);
        setShowCreateNewSegment(false);

        let status: SegmenterData = selectedStatus!;

        try {
            if (segmentName === 'pattern' && selectedStatus?.value && segmentCurrentlyEditing?.name) {
                status = await getPatternStatus(
                    selectedStatus?.value,
                    segmentCurrentlyEditing?.name,
                    selectedStatus?.label
                );
            }
        } catch (e) {
            console.log(e);
            setChangesSaving(false);
            setSaveError(true);
        }

        const requests: AxiosPromise[] = addRowRequest({
            results,
            segmenterRuleDataToUpdate,
            status,
            segmentCurrentlyEditing,
            allSegmenterRuleData,
            resource,
            fkResource,
            fkColumnName,
        });

        axios
            .all(requests)
            .then(
                axios.spread(resp => {
                    setChangesSaving(false);
                    fetchSegmenterData();
                    // reset dropdown selected value
                    setSelectedStatus(undefined);
                    setSelectedPosition(undefined);
                    setSelectedRow(undefined);
                })
            )
            .catch(e => {
                console.log(e);
                setChangesSaving(false);
                setSaveError(true);
            });
    };

    if (pageError) {
        return modalNavigation(pageError, pageLoading);
    }
    if (!pageLoading) {
        return (
            <>
                {modalNavigation(pageError, pageLoading)}
                <DragAndDropTable
                    rows={tableRows}
                    headers={ruleObject}
                    onReorderRows={handleReorderRows}
                    handleRowClick={handleTableRowClick}
                    dragDisabled={!editingTableOrder}
                    rowClickDisabled={disableTableClick}
                    status={tableStatus}
                />

                <StyledButtonContainer>
                    <StyledReorderButtonContainer>
                        <InputButton
                            buttonTheme={editingTableOrder ? ButtonThemes.Green : ButtonThemes.Primary}
                            value={editingTableOrder ? 'SAVE PRIORITY ORDER' : 'EDIT PRIORITY ORDER'}
                            disabled={priorityOrderButtonDisabled}
                            onClick={handleReorderButtonClick}
                        />
                        {editingTableOrder && (
                            <InputButton
                                buttonTheme={ButtonThemes.Red}
                                value={'CANCEL'}
                                disabled={false}
                                onClick={handleCancelReorder}
                            />
                        )}
                    </StyledReorderButtonContainer>

                    <StyledActionButtonsContainer>
                        <InputButton
                            value={`Create New Rule`}
                            disabled={createButtonDisabled}
                            onClick={handleCreateNew}
                        />
                        <InputButton value="EDIT RULE" disabled={editButtonDisabled} onClick={handleEditClick} />
                        <InputButton
                            buttonTheme={ButtonThemes.Red}
                            value={'DELETE'}
                            disabled={deleteButtonDisabled}
                            onClick={() => showDeleteModal(dispatch, handlePopupDiscardClick, handleDelete)}
                        />
                    </StyledActionButtonsContainer>
                </StyledButtonContainer>

                {showEditCreateSegmenter && (
                    <>
                        <StyledFormContainer>
                            <FormTextInput
                                inputPlaceholder="Enter a name for your Rule"
                                label="Rule Name:"
                                toolTipCopy="Enter a name for your rule"
                                inputOnChange={handleChangeSegmenterDataName}
                                inputValue={segmentCurrentlyEditing?.name}
                                requiredField={true}
                            />
                            <FormTextArea
                                requiredField={false}
                                label="Rule Description:"
                                toolTipCopy="Enter a description for your rule"
                                inputValue={segmentCurrentlyEditing?.description}
                                inputOnChange={handleChangeSegmenterDataDescription as ChangeEventHandler}
                            />

                            <DropdownWithSearch
                                isAccordionForm={false}
                                dropdownItems={segmenterData}
                                placeholder={'Select a ' + segmentType}
                                label={segmentType + ' :'}
                                selected={selectedStatus}
                                requiredField={true}
                                toolTipCopy={'Select a ' + segmentType}
                                onSelectedItemChange={handleSelectedStatus}
                            />
                        </StyledFormContainer>

                        {showSelectPriority && !noData && (
                            <StyledSelectPriorityContainer>
                                <p>
                                    Please select where you would like your rule to appear in the priority list. If you
                                    don't select a position for your new rule it will be added to the bottom of the
                                    list.
                                </p>
                                <StyledPriorityFormContainer>
                                    <StyledSelectPriorityDropdown>
                                        <DropdownWithSearch
                                            isAccordionForm={false}
                                            dropdownItems={[
                                                { label: 'Before', value: 'before' },
                                                { label: 'After', value: 'after' },
                                            ]}
                                            placeholder={'Select a position'}
                                            label={'Position:'}
                                            toolTipCopy={'Select the priority position for your rule'}
                                            requiredField={false}
                                            selected={selectedPosition}
                                            onSelectedItemChange={handleSelectedPosition}
                                        />
                                    </StyledSelectPriorityDropdown>
                                    <StyledSelectPriorityDropdown>
                                        <DropdownWithSearch
                                            isAccordionForm={false}
                                            dropdownItems={tableRows.map(row => {
                                                return {
                                                    label: row.columns.name as string,
                                                    value: row.columns.priority,
                                                };
                                            })}
                                            placeholder={'Select a Rule'}
                                            label={'Rule:'}
                                            toolTipCopy={
                                                'Select the rule you would like your new rule to appear before/after'
                                            }
                                            requiredField={false}
                                            selected={selectedRow}
                                            onSelectedItemChange={handleSelectedSegment}
                                        />
                                    </StyledSelectPriorityDropdown>
                                </StyledPriorityFormContainer>
                            </StyledSelectPriorityContainer>
                        )}

                        <SegmenterRuleBuilder
                            data={[]}
                            isFrom="Edit"
                            segmentName={segmentCurrentlyEditing?.name}
                            onPatternChange={handlePatternChange}
                            rules={rule}
                            isNotUniqueRule={isNotUniqueRule}
                        />

                        {showCreateNewSegment ? (
                            <StyledButtonContainer>
                                <StyledAddConditionButtonContainer>
                                    <InputButton
                                        value={`CREATE NEW CONDITION`}
                                        onClick={() => dispatch(setModal('SegmenterEditConditions'))}
                                    />
                                </StyledAddConditionButtonContainer>

                                <StyledSaveActionsButtonsContainer>
                                    <InputButton
                                        value={'SAVE'}
                                        onClick={handleSaveClick}
                                        disabled={saveButtonDisabled}
                                    />
                                    <InputButton
                                        value={'CANCEL'}
                                        buttonTheme={ButtonThemes.Secondary}
                                        onClick={handleCancelEdit}
                                    />
                                </StyledSaveActionsButtonsContainer>
                            </StyledButtonContainer>
                        ) : (
                            <StyledButtonContainer>
                                <StyledAddConditionButtonContainer>
                                    <InputButton
                                        value={`CREATE NEW CONDITION`}
                                        onClick={() => dispatch(setModal('SegmenterEditConditions'))}
                                    />
                                </StyledAddConditionButtonContainer>
                                <ActionButtons
                                    handleApplyClick={handleApplyClick}
                                    handleCancelEdit={handleCancelEdit}
                                    applyButtonDisabled={applyButtonDisabled}
                                />
                            </StyledButtonContainer>
                        )}
                    </>
                )}
            </>
        );
    } else {
        return modalNavigation(pageError, pageLoading);
    }
};

export default LayoutAdvancedRuleBuilder;
