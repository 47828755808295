import { css } from 'styled-components';

export const inputStyles = css<{ disabled: boolean }>`
    font-family: inherit;
    font-size: 14.5px;
    width: 100%;
    height: 40px;
    background-color: ${props =>
        props.disabled ? props.theme.forms.inputs.backgroundColorDisabled : props.theme.forms.inputs.backgroundColor};
    border: none;
    border-bottom: solid 2px
        ${props =>
            props.disabled ? props.theme.forms.inputs.accentColorDisabled : props.theme.forms.inputs.accentColor};
    color: ${props =>
        props.disabled ? props.theme.forms.inputs.textColorDisabled : props.theme.forms.inputs.textColor};
    padding: 0px 10px;
    box-sizing: border-box;

    ::placeholder {
        color: ${props => props.theme.forms.inputs.placeHolderColor};
        opacity: 1;
    }

    ::-ms-input-placeholder {
        color: ${props => props.theme.forms.inputs.placeHolderColor};
    }
`;
