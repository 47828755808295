import moment from 'moment';
import { ChartDateGranularity } from '../configurations/common/chart-types';
import { getTheGraphGranularity } from '../table-graph/components/chart/helpers/chart-helper';

export const createArrayOfDates = (startDate, endDate, dateGranularity = ChartDateGranularity.Day) => {
    const start =
        dateGranularity === ChartDateGranularity.Month
            ? moment(startDate).startOf('month')
            : dateGranularity === ChartDateGranularity.Minute
            ? moment.utc(startDate.format('YYYY-MM-DD HH:mm:00'))
            : moment.utc(startDate.format('YYYY-MM-DD'));
    const end =
        dateGranularity === ChartDateGranularity.Month
            ? moment.utc(endDate).startOf('month')
            : dateGranularity === ChartDateGranularity.Minute
            ? moment.utc(endDate.format('YYYY-MM-DD HH:mm:00'))
            : moment.utc(endDate.format('YYYY-MM-DD'));
    const range = [moment({ ...start })];

    if (start.isAfter(end)) {
        console.error('Start date is higher than end date.');
        return [];
    }

    if (start.isSame(end, 'date')) {
        while (start.isSame(end, 'date')) {
            start.add(1, dateGranularity);
            range.push(moment({ ...start }));
        }
    } else {
        while (start.unix() < end.unix()) {
            start.add(1, dateGranularity);
            range.push(moment({ ...start }));
        }
    }
    return range.map(date => date);
};

export const mapDataToSeries = (dataPoints = [], datas, dimension, metric, graphGranularity) => {
    if (!graphGranularity) {
        graphGranularity = getTheGraphGranularity(dataPoints.slice(0, 2));
    }

    const series = [];

    if (moment.isMoment(dataPoints[0])) {
        for (const date of dataPoints) {
            let matchFound = false;
            for (const data of datas) {
                const graphDate = moment.utc(date);
                const responseDataDate = moment.utc(data[dimension.rawName].raw_value);
                if (graphDate.isSame(responseDataDate, graphGranularity)) {
                    matchFound = true;
                    series.push([date.unix() * 1000, data[metric].raw_value]);
                    break;
                }
            }
            if (!matchFound) series.push([date.unix() * 1000, 0]);
        }
    } else {
        for (const item of dataPoints) {
            for (const data of datas) {
                if (item.raw_value === data[dimension.rawName].raw_value) {
                    series.push([item.raw_value, data[metric].raw_value]);
                }
            }
        }
    }

    return series;
};

/*
	Add a custom clamp logic to the native Math object
*/
export const mathClamp = function (number, min, max) {
    return Math.min(Math.max(number, min), max);
};

export const convertJSONToCubedJSON = data => {
    const newData = [];
    for (let row of data.rows) {
        const newRow = {};
        for (let item in row) {
            const newItem = {};
            newItem.raw_value = row[item];
            newItem.value = row[item];
            newRow[item] = newItem;
        }
        newData.push(newRow);
    }
    return newData;
};

export const removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, index, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === index;
    });
};

export const createSeries = (responseData, feild, dimension) => {
    const dates = [];
    responseData.forEach(item => dates.push(moment(item.date.raw_value)));
    return mapDataToSeries(dates, responseData, feild, dimension);
};

export const createEventSeries = (responseData, feild, dimension) => {
    const dates = [];
    responseData.forEach(item => dates.push(moment(item.created.raw_value)));
    return mapDataToSeries(dates, responseData, feild, dimension);
};

export const createViewSeries = (responseData, feild, dimension) => {
    const dates = [];
    responseData.forEach(item => dates.push(moment(item.last_visit.raw_value)));
    return mapDataToSeries(dates, responseData, feild, dimension);
};
export const createTagSeries = (responseData, feild, dimension) => {
    const dates = [];
    responseData.forEach(item => dates.push(moment(item.created.raw_value)));
    return mapDataToSeries(dates, responseData, feild, dimension);
};
export const createImpressionSeries = (responseData, feild, dimension) => {
    const dates = [];
    responseData.forEach(item => dates.push(moment(item.first_visit.raw_value)));
    return mapDataToSeries(dates, responseData, feild, dimension);
};

export const getLastValidDay = (todayDate, dayOfTheWeek) => {
    // finds the last valid day where there is likely to be data in the database
    // based on data being pulled on the 1st and 14th of the month (gtrends)

    if (moment(dayOfTheWeek, 'dddd', true).isValid()) {
        let lastValidDay;
        if (todayDate < 14) {
            lastValidDay = moment().subtract(1, 'month').endOf('month');
            while (lastValidDay.format('dddd') !== dayOfTheWeek) {
                lastValidDay.subtract(1, 'day');
            }
        } else {
            lastValidDay = moment();
            while (lastValidDay.format('dddd') !== dayOfTheWeek && !lastValidDay.date() <= 14) {
                lastValidDay.subtract(1, 'day');
            }
        }
        return lastValidDay;
    } else {
        throw new Error('Day of the week must be a valid string');
    }
};

export const sortObjectArray = (items, key) => {
    return items.sort((a, b) => {
        return a[key] - b[key];
    });
};

export const setUniqueArray = (items, key) => {
    return items.filter((v, i, a) => a.findIndex(v2 => v2[key] === v[key]) === i);
};
