import React from 'react';
import { QueryStatus } from '@tanstack/react-query';
import { createContext, useContext } from 'react';

type ConfigTableContextValues = {
    status: QueryStatus;
    isFetching: boolean;
    tableDisabled: boolean;
    empty: boolean;
};

const ConfigTableContext = createContext<ConfigTableContextValues | undefined>(undefined);

export const useConfigTableContext = () => {
    const context = useContext(ConfigTableContext);
    if (context === undefined) {
        throw new Error('"Config Table" child components cannot be rendered outside of the ConfigTable component');
    }
    return context;
};

type ConfigTableProviderProps = {
    status: QueryStatus;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
    children: React.ReactNode;
};

export const ConfigTableProvider = ({ status, isFetching, disabled, empty, children }: ConfigTableProviderProps) => {
    return (
        <ConfigTableContext.Provider value={{ status, isFetching, tableDisabled: disabled, empty }}>
            {children}
        </ConfigTableContext.Provider>
    );
};
