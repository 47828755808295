import React from 'react';
import styled from 'styled-components';
import DropdownWithSearch from '../../components/common/dropdown-with-search';
import IconCross from '../../components/icons/cross';

//Styled Components
const SyledAddConditionContainer = styled.div`
    display: flex;
`;

const StyledAddCondition = styled.div`
    background-color: #ffffff;
    border-left: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    height: 80px;
    padding: 20px 20px 0px 20px;
    width: 100%;
    display: flex;
`;

const StyledMargin = styled.div`
    width: 80px;
`;

const StyledDropdownContainer = styled.div`
    display: inline-block;
    width: 95%;
`;

const StyledButtonContainer = styled.div`
    display: inline-block;
    text-align: right;
    width: 5%;
`;

export const StyledDeleteButton = styled.button`
    & svg {
        width: 15px;
        & path {
            fill: #0a0a0a;
        }
    }
    border: 0;
    background-color: #ffffff;
    padding: 6px 5px 6px 10px;
    position: relative;
    top: 5px;
`;

const CreateSegmentAddCondition = ({ conditions, onSelectCondition, selectedCondition, onCloseAddCondition }) => {
    return (
        <SyledAddConditionContainer>
            <StyledMargin />
            <StyledAddCondition>
                <StyledDropdownContainer>
                    <DropdownWithSearch
                        dropdownItems={conditions}
                        onSelectedItemChange={onSelectCondition}
                        selected={selectedCondition}
                        placeholder={'Please select a condition'}
                    />
                </StyledDropdownContainer>
                <StyledButtonContainer>
                    <StyledDeleteButton onClick={onCloseAddCondition}>
                        <IconCross />
                    </StyledDeleteButton>
                </StyledButtonContainer>
            </StyledAddCondition>
        </SyledAddConditionContainer>
    );
};

export default CreateSegmentAddCondition;
