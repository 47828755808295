import React from 'react';
import { DateRangePicker, DefinedRange } from 'react-date-range';
import classnames from 'classnames';
import CubedDateRange from './cubed-date-range';

class CubedDateRangePicker extends DateRangePicker {
    render() {
        const { focusedRange } = this.state;
        return (
            <div className={classnames(this.styles.dateRangePickerWrapper, this.props.className)}>
                <DefinedRange
                    focusedRange={focusedRange}
                    onPreviewChange={value =>
                        this.dateRange.updatePreview(
                            value ? this.dateRange.calcNewSelection(value, typeof value === 'string') : null
                        )
                    }
                    {...this.props}
                    range={this.props.ranges[focusedRange[0]]}
                    className={undefined}
                />
                <CubedDateRange
                    onRangeFocusChange={focusedRange => this.setState({ focusedRange })}
                    focusedRange={focusedRange}
                    {...this.props}
                    ref={t => (this.dateRange = t)}
                    className={undefined}
                />
            </div>
        );
    }
}

export default CubedDateRangePicker;
