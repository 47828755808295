import * as fields from './fields';
import * as resources from './resources';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';

export const config = {
    pageTitle: 'Geographical Performance',
    dateDimension: fields.SALES_DATE_DATETIME,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: true,
            isEnableMetrics: true,
        },
    },
    resources: [resources.REPORT_MCS_REGION, resources.REPORT_MCS_LANDING_PATH_REGION],
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'report-mcs-region',
        allYMetrics: [
            fields.VISITS,
            fields.BOUNCES,
            fields.BOUNCE_RATE,
            fields.ASSISTS,
            fields.ASSISTS_RATE,
            fields.AVG_DELTA,
            fields.FM_SALES,
            fields.FM_CVR,
            fields.FM_REVENUE,
            fields.FM_AOV,
            fields.COST,
            fields.FM_CPA,
            fields.FM_ROI,
            fields.FM_ROAS,
        ],
        initialYMetrics: [fields.FM_SALES],
    },
    table: {
        tabListItems: [
            {
                tabName: 'overview',
                displayName: 'Overview',
                isSelected: true,
                metrics: [fields.VISITS, fields.FM_SALES, fields.FM_REVENUE, fields.FM_AOV],
            },
            {
                tabName: 'traffic',
                displayName: 'Traffic',
                metrics: [
                    fields.VISITS,
                    fields.ASSISTS,
                    fields.ASSISTS_RATE,
                    fields.AVG_DELTA,
                    fields.BOUNCES,
                    fields.BOUNCE_RATE,
                ],
            },
            {
                tabName: 'conversion_rates',
                displayName: 'Conversion Rates',
                metrics: [fields.VISITS, fields.FM_SALES, fields.FM_CVR],
            },
            {
                tabName: 'cost',
                displayName: 'Cost Analysis',
                metrics: [fields.FM_REVENUE, fields.COST, fields.FM_CPA, fields.FM_ROI, fields.FM_ROAS],
            },
            {
                tabName: 'all',
                displayName: 'All',
                metrics: [
                    fields.VISITS,
                    fields.ASSISTS,
                    fields.ASSISTS_RATE,
                    fields.AVG_DELTA,
                    fields.BOUNCES,
                    fields.BOUNCE_RATE,
                    fields.FM_SALES,
                    fields.FM_CVR,
                    fields.FM_REVENUE,
                    fields.FM_AOV,
                    fields.COST,
                    fields.FM_CPA,
                    fields.FM_ROI,
                    fields.FM_ROAS,
                ],
            },
        ],
        fieldDataOverrides: {
            referer: [
                {
                    property: 'subchannels',
                    condition: function (subchannels) {
                        return Array.isArray(subchannels) ? subchannels.length > 1 : false;
                    },
                    field: fields.SUBCHANNEL_NAME,
                },
            ],
        },
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.REFERER.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_MCS_REGION,
                        field: fields.MCS_COUNTRY_NAME,
                    },
                ],
            },
            [fields.SUBCHANNEL_NAME.id]: {
                default: [
                    {
                        useCurrent: true,
                        resource: resources.REPORT_MCS_REGION,
                        field: fields.MCS_COUNTRY_NAME,
                    },
                ],
            },
            [fields.MCS_CONTINENT_NAME.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_LANDING_PATH_REGION,
                        field: fields.MCS_COUNTRY_NAME,
                    },
                ],
            },
            [fields.MCS_COUNTRY_NAME.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_LANDING_PATH_REGION,
                        field: fields.MCS_CITY_NAME,
                    },
                ],
            },
            [fields.MCS_CITY_NAME.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_LANDING_PATH_REGION,
                        field: fields.REFERER,
                    },
                ],
            },
            [fields.LANDING_PATH_HOST.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_LANDING_PATH_REGION,
                        field: fields.LANDING_PATH_PATH,
                    },
                ],
            },
            [fields.LANDING_PATH_PATH.id]: {
                default: [
                    {
                        resource: resources.REPORT_MCS_LANDING_PATH_REGION,
                        field: fields.MCS_COUNTRY_NAME,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
