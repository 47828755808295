import React, { memo } from 'react';

// Core Dependencies
import { isEqual } from 'lodash';
import styled from 'styled-components';

// Components
import BreadcrumbsDropdownItem from './breadcrumbs-dropdown-item';
import Cancel from '../../components/cancel';

// Image
import DropDownArrowImage from '../../assets/images/icons/drop-down-arrow.svg';

// Hooks
import UseComponentVisible from '../../hooks/use-component-visible';

// Types
import { CubedField, CubedResource } from '../../types';
import { BreadcrumbCurrentProps } from '../types';

// Styles
const StyledBreadcrumbsItem = styled.div`
    position: relative;
    box-sizing: border-box;
    float: right;
    margin-left: -20px;
`;

const StyledBreadcrumbsItemLabel = styled.div`
    height: 11px;
    line-height: 11px;
    position: relative;
    padding-left: 24px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 4px;
    text-transform: uppercase;
    color: #0a0a0a;
`;

const StyledBreadcrumbsItemContentWrapper = styled.div`
    border-radius: 0 37px 37px 0;
    background-color: ${props => props.theme.colours.white};
    padding: 6px;
    display: block;
    width: inherit;
    height: 100%;
`;

const StyledBreadcrumbsItemContentCurrent = styled.div<{
    colourOverride: string;
}>`
    border-top: 1px solid #3e3a3a;
    border-bottom: 1px solid ${props => props.theme.colours.black};
    border-colour: ${props => props.colourOverride !== undefined && `${props.colourOverride}`};
    background-color: ${props => (props.colourOverride !== undefined ? props.colourOverride : '#1e1c1c')};
    color: ${props => props.theme.colours.light};

    border: none;
    line-height: 37px;
    height: 37px;
    min-width: 54px;
    padding-left: 24px;
    position: relative;
    border-radius: 0 37px 37px 0;
    overflow: hidden;
`;

const StyledBreadcrumbsItemContentSpan = styled.span`
    font-weight: 300;
    padding-right: 24px;
    display: inline-block;
`;

const StyledBreadcrumbsItemDropdown = styled.div<{
    expendDropdownList: boolean;
}>`
    ${props => props.theme.boxShadow(1)}
    position: absolute;
    background-color: ${props => props.theme.colours.textLight};
    transition: ${props => props.theme.transition};
    z-index: 1000;
    width: 100%;
    padding: 6px;
    overflow: hidden;

    visibility: ${props => (props.expendDropdownList ? 'visible' : 'hidden')};
    max-height: ${props => (props.expendDropdownList ? '600px' : '0')};

    right: -212px;
    top: 31px;
    min-width: 240px;
    overflow-y: auto;
`;

const StyledDropdownButton = styled.span`
    cursor: pointer;
    padding: 12.5px;
    background-color: ${props => props.theme.colours.offBlackLighter};
    transition: ${props => props.theme.transition};

    &:hover {
        background-color: ${props => props.theme.colours.black};
    }
`;

const StyledDropdownButtonIcon = styled.div<{
    backgroundImage: string;
}>`
    display: inline-block;
    margin-left: 0;
    width: 12px;
    height: 12px;
    background-image: url(${props => props.backgroundImage});
    background-size: contain;
    background-repeat: no-repeat;
`;

const StyledDropdown = styled.div`
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 4px;
    text-transform: uppercase;
    color: #0a0a0a;
    padding-left: 0 important;
`;

const BreadcrumbCurrent = ({
    filters,
    resource,
    removeFilter,
    setResourceAndField,
    allResources,
    dimension,
    dropdownVisible,
    colourOverride,
}: BreadcrumbCurrentProps) => {
    const {
        ref: dropDownListRef,
        isComponentVisible: expendDropdownList,
        setIsComponentVisible: setExpendDropdownList,
    } = UseComponentVisible(false);

    const handleToggleDropdownList = () => {
        setExpendDropdownList(!expendDropdownList);
    };

    const goBack = () => {
        const filter = filters[filters.length - 1];
        setResourceAndField(resource, filter.field, filter);
        removeFilter(filter.field);
    };

    const setGroupBy = (resource: CubedResource, field: CubedField) => {
        setResourceAndField(resource, field);
        setExpendDropdownList(false);
    };

    const findDropdownOptions = () => {
        const allFields: CubedField[] = [];
        let selectedFields: CubedField[] = [];

        selectedFields = filters.map(filter => filter.field);

        const dropdownOptions = [];
        for (let resourceData of allResources) {
            let visibleFields = [];

            if (resourceData.dimensions !== undefined) {
                for (let field of resourceData.dimensions) {
                    if (!allFields.includes(field) && !selectedFields.includes(field)) {
                        allFields.push(field);
                        const id = `${resourceData.id}-${field.rawName}`;
                        visibleFields.push({ ...field, id });
                    }
                }
            }

            if (visibleFields.length > 0) {
                dropdownOptions.push({ id: resourceData.id, resource: resourceData, fields: visibleFields });
            }
        }

        return dropdownOptions;
    };

    const renderDropdownOptions = () => {
        const options = findDropdownOptions();

        return options.map(option => {
            return (
                <div key={option.id}>
                    <StyledDropdown>{option.resource.displayName}</StyledDropdown>
                    {option.fields.map(field => {
                        return (
                            <BreadcrumbsDropdownItem
                                field={field}
                                resource={option.resource}
                                onClick={setGroupBy}
                                key={field.id}
                            />
                        );
                    })}
                </div>
            );
        });
    };

    const renderDropdown = () => {
        if (dropdownVisible !== undefined && dropdownVisible === false) {
            return null;
        } else {
            return (
                <StyledDropdownButton ref={dropDownListRef} onClick={handleToggleDropdownList}>
                    <StyledDropdownButtonIcon backgroundImage={DropDownArrowImage} />
                </StyledDropdownButton>
            );
        }
    };

    return (
        <StyledBreadcrumbsItem>
            <StyledBreadcrumbsItemLabel data-testid="breadcrumb-item-label" />
            <StyledBreadcrumbsItemContentWrapper>
                <StyledBreadcrumbsItemContentCurrent
                    colourOverride={colourOverride}
                    data-testid="breadcrumb-item-content"
                >
                    <StyledBreadcrumbsItemContentSpan>{dimension[0].displayName}</StyledBreadcrumbsItemContentSpan>
                    {renderDropdown()}
                    {filters[filters.length - 1] && <Cancel onClick={goBack} colour="white" floatRight={true} />}
                </StyledBreadcrumbsItemContentCurrent>
            </StyledBreadcrumbsItemContentWrapper>

            <StyledBreadcrumbsItemDropdown expendDropdownList={expendDropdownList}>
                <ul>{renderDropdownOptions()}</ul>
            </StyledBreadcrumbsItemDropdown>
        </StyledBreadcrumbsItem>
    );
};

//when option is selected, groupBy should be set to field, and resource should be set to resource. If field is already in list, then don't add another.
export default memo(BreadcrumbCurrent, isEqual);
