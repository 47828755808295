import React from 'react';
import styled from 'styled-components';
import SectionDashboardProvider from '../../context/section-dashboard-context';
import { Grid } from 'css-grid-template-parser';
import { SectionDashboardConfig, SectionDashboardLayout } from '../../types';

const StyledGridLayout = styled.div<{ sectionIndex: number; config: SectionDashboardConfig; areas: Grid }>`
    display: grid;
    width: 100%;
    gap: 16px;
    grid-template-rows: ${props => `repeat(${props.areas.height}, ${props.theme.sectionDashboard.grid.row.height})`};

    @media (min-width: ${props => props.theme.sectionDashboard.grid.breakpoints.xs}) {
        grid-template-columns: 1fr;
        grid-template-areas: ${props => props.config.layouts.xs.sections[props.sectionIndex]?.grid};
    }
    @media (min-width: ${props => props.theme.sectionDashboard.grid.breakpoints.sm}) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: ${props => props.config.layouts.sm.sections[props.sectionIndex]?.grid};
    }
    @media (min-width: ${props => props.theme.sectionDashboard.grid.breakpoints.md}) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: ${props => props.config.layouts.md.sections[props.sectionIndex]?.grid};
    }
    @media (min-width: ${props => props.theme.sectionDashboard.grid.breakpoints.lg}) {
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas: ${props => props.config.layouts.lg.sections[props.sectionIndex]?.grid};
    }
    @media (min-width: ${props => props.theme.sectionDashboard.grid.breakpoints.xl}) {
        grid-template-columns: repeat(8, 1fr);
        grid-template-areas: ${props => props.config.layouts.xl.sections[props.sectionIndex]?.grid};
    }
`;

type SectionDashboardSectionProps = {
    sectionIndex: number;
    config: SectionDashboardConfig;
    layout: SectionDashboardLayout;
    areas: Grid;
};

const SectionDashboardSection = ({ sectionIndex, config, layout, areas }: SectionDashboardSectionProps) => {
    const components = config.components();

    return (
        <StyledGridLayout sectionIndex={sectionIndex} config={config} areas={areas}>
            {Object.entries(components).map(([key, component]) => {
                const areaConfig = areas.areas[key];
                const displayComponent = component as JSX.Element;

                if (areaConfig) {
                    return (
                        <SectionDashboardProvider
                            sectionIndex={sectionIndex}
                            x={areaConfig.column.start - 1}
                            y={areaConfig.row.start - 1}
                            width={areaConfig.column.span}
                            height={areaConfig.row.span}
                            area={key}
                            layout={layout}
                            key={key}
                        >
                            {displayComponent}
                        </SectionDashboardProvider>
                    );
                }

                return null;
            })}
        </StyledGridLayout>
    );
};

export default SectionDashboardSection;
