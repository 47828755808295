import React, { createContext, useContext } from 'react';

// Fields and Resources
import * as fields from '../../configurations/fields';
import * as resources from '../../configurations/resources';

// Queries
import {
    channelDetailGoalsQuery,
    channelDetailSubchannelQuery,
    channelDetailTier2Query,
    channelDetailTier3Query,
    channelDetailTier4Query,
} from '../queries';

// Context
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';

// Config
import { getChannelSubsectionConfig } from '../../filter-bar/configurations/channel-subsection-config';

// Types
import {
    SelectOptionsRequest,
    SelectQuery,
    FilterOperator,
    RequestFilter,
    WidgetMenuObj,
} from '../../section-dashboard/types';
import { CubedField, CubedResource } from '../../types';

// Helpers
import getCurrentTier from '../helpers/get-current-tier';
import { filterUnknownRegex } from '../../section-dashboard/helpers/get-resource-filters';

// Hooks
import { useDatesFormatted } from '../../hooks/use-dates';
import useWidgetMenuSection from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section';
import useWidgetMenuSectionQuerySelect from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-query-select';
import useWidgetMenuSectionMultiSelect from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-multi-select';

// Components
import useWidgetMenuSectionCheckbox from '../../section-dashboard/widgets/menus/hooks/use-widget-menu-section-checkbox';

export interface ChannelDetailDashboardMenuContextValues {
    // Page Level
    channelDropdownOptions: SelectOptionsRequest;
    selectedChannelFilter: RequestFilter[] | undefined;
    currentResource?: CubedResource;
    tier2Field: CubedField;
    tier3Field: CubedField;
    tier4Field: CubedField;
    // Tier 2
    tier2ProfileSectionMenu: WidgetMenuObj;
    tier2GoalCompletionSectionMenu: WidgetMenuObj;
    // Tier 3
    tier3ProfileSectionMenu: WidgetMenuObj;
    tier3GoalCompletionSectionMenu: WidgetMenuObj;
    // Tier 4
    tier4ProfileSectionMenu: WidgetMenuObj;
    tier4GoalCompletionSectionMenu: WidgetMenuObj;
}

type ChannelDetailDashboardMenuContextProps = {
    children: JSX.Element;
};

export const ChannelDetailDashboardMenuContext = createContext<ChannelDetailDashboardMenuContextValues>(
    {} as ChannelDetailDashboardMenuContextValues
);

const ChannelDetailDashboardMenuProvider = ({ children }: ChannelDetailDashboardMenuContextProps) => {
    // Dates
    const { startDate, endDate } = useDatesFormatted({ resource: resources.REPORT_MCS_CHANNEL });

    // Filter Bar Context
    const { channelDropdownOptions, selectedChannel } = useContext(FilterBarContext);

    // Current Tier and Resource Data
    const tier2Field = getCurrentTier('tier2', selectedChannel) as CubedField;
    const tier3Field = getCurrentTier('tier3', selectedChannel) as CubedField;
    const tier4Field = getCurrentTier('tier4', selectedChannel) as CubedField;

    const currentResource = selectedChannel ? getChannelSubsectionConfig(selectedChannel.label).resource : undefined;

    const selectedChannelFilter = selectedChannel
        ? [{ field: fields.REFERER_ID, operator: FilterOperator.EQUALS, value: selectedChannel.value }]
        : undefined;

    // Queries
    const goalsQueryObj = channelDetailGoalsQuery({
        currentResource,
        selectedChannel,
        startDate,
        endDate,
    }) as SelectQuery;

    const subchannelQueryObj = channelDetailSubchannelQuery({
        currentResource,
        selectedChannel,
        startDate,
        endDate,
    }) as SelectQuery;

    const tier2QueryObj = channelDetailTier2Query({
        currentResource,
        selectedChannel,
        startDate,
        endDate,
        tier2Field,
    }) as SelectQuery;

    const tier3QueryObj = channelDetailTier3Query({
        currentResource,
        selectedChannel,
        startDate,
        endDate,
        tier3Field,
    }) as SelectQuery;

    const tier4QueryObj = channelDetailTier4Query({
        currentResource,
        selectedChannel,
        startDate,
        endDate,
        tier4Field,
    }) as SelectQuery;

    // Tier 2 Profile
    const tier2ProfileSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionMultiSelect({
                        query: goalsQueryObj,
                        dropdownField: fields.PRODUCT,
                        label: 'Goals',
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: subchannelQueryObj,
                        dropdownField: fields.SUBCHANNEL_NAME,
                        includeAllOption: true,
                        label: 'Subchannel',
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: tier2QueryObj,
                        dropdownField: tier2Field,
                        includeAllOption: true,
                    }),
                ],
            },
        ],
    });

    // Goal Completion Count & Value by Tier 2
    const tier2GoalCompletionSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionQuerySelect({
                        query: goalsQueryObj,
                        dropdownField: fields.PRODUCT,
                        includeAllOption: true,
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: subchannelQueryObj,
                        dropdownField: fields.SUBCHANNEL_NAME,
                        includeAllOption: true,
                        label: 'Subchannel',
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: tier2QueryObj,
                        dropdownField: tier2Field,
                        includeAllOption: true,
                    }),
                ],
            },
            {
                menus: [
                    useWidgetMenuSectionCheckbox({
                        label: 'Exclude Unknown',
                        filterField: tier2Field,
                        filterOperator: FilterOperator.IREGEX,
                        filterValue: filterUnknownRegex,
                    }),
                ],
            },
        ],
    });

    // Tier 3 Profile
    const tier3ProfileSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionMultiSelect({
                        query: goalsQueryObj,
                        dropdownField: fields.PRODUCT,
                        label: 'Goals',
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: subchannelQueryObj,
                        dropdownField: fields.SUBCHANNEL_NAME,
                        includeAllOption: true,
                        label: 'Subchannel',
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: tier3QueryObj,
                        dropdownField: tier3Field,
                        includeAllOption: true,
                    }),
                ],
            },
        ],
    });

    // Goal Completion Count & Value by Tier 3
    const tier3GoalCompletionSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionQuerySelect({
                        query: goalsQueryObj,
                        dropdownField: fields.PRODUCT,
                        includeAllOption: true,
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: subchannelQueryObj,
                        dropdownField: fields.SUBCHANNEL_NAME,
                        includeAllOption: true,
                        label: 'Subchannel',
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: tier3QueryObj,
                        dropdownField: tier3Field,
                        includeAllOption: true,
                    }),
                ],
            },
            {
                menus: [
                    useWidgetMenuSectionCheckbox({
                        label: 'Exclude Unknown',
                        filterField: tier3Field,
                        filterOperator: FilterOperator.IREGEX,
                        filterValue: filterUnknownRegex,
                    }),
                ],
            },
        ],
    });

    // Tier 4 Profile
    const tier4ProfileSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionMultiSelect({
                        query: goalsQueryObj,
                        dropdownField: fields.PRODUCT,
                        label: 'Goals',
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: subchannelQueryObj,
                        dropdownField: fields.SUBCHANNEL_NAME,
                        includeAllOption: true,
                        label: 'Subchannel',
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: tier4QueryObj,
                        dropdownField: tier4Field,
                        includeAllOption: true,
                    }),
                ],
            },
        ],
    });

    // Goal Completion Count & Value by Tier 4
    const tier4GoalCompletionSectionMenu = useWidgetMenuSection({
        sections: [
            {
                title: 'Filters',
                menus: [
                    useWidgetMenuSectionQuerySelect({
                        query: goalsQueryObj,
                        dropdownField: fields.PRODUCT,
                        includeAllOption: true,
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: subchannelQueryObj,
                        dropdownField: fields.SUBCHANNEL_NAME,
                        includeAllOption: true,
                        label: 'Subchannel',
                    }),
                    useWidgetMenuSectionQuerySelect({
                        query: tier4QueryObj,
                        dropdownField: tier4Field,
                        includeAllOption: true,
                    }),
                ],
            },
            {
                menus: [
                    useWidgetMenuSectionCheckbox({
                        label: 'Exclude Unknown',
                        filterField: tier4Field,
                        filterOperator: FilterOperator.IREGEX,
                        filterValue: filterUnknownRegex,
                    }),
                ],
            },
        ],
    });

    return (
        <ChannelDetailDashboardMenuContext.Provider
            value={{
                // Page Level
                channelDropdownOptions,
                selectedChannelFilter,
                currentResource,
                tier2Field,
                tier3Field,
                tier4Field,
                // Tier 2
                tier2ProfileSectionMenu,
                tier2GoalCompletionSectionMenu,
                // Tier 3
                tier3ProfileSectionMenu,
                tier3GoalCompletionSectionMenu,
                // Tier 4
                tier4ProfileSectionMenu,
                tier4GoalCompletionSectionMenu,
            }}
        >
            {children}
        </ChannelDetailDashboardMenuContext.Provider>
    );
};

export default ChannelDetailDashboardMenuProvider;
