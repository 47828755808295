/* eslint-disable react-hooks/exhaustive-deps */
// React Dependencies
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import InputButton from '../components/inputs/input-button';
import InputButtonWrapper from '../components/inputs/input-button-wrapper';

// Layouts
import LayoutPopupImportIntegrationAccounts from '../layouts/popups/import-integration-accounts';
import LayoutPopupCreateOauthConnection from '../layouts/popups/google-oauth/create-oauth-connection';

// Redux
import { removePopup } from '../redux/actions/popup';
import { RootState } from '../redux/reducers/core';

// Enums
import { ButtonThemes } from '../enums/button-themes';

// Image
import WarningImage from '../assets/images/icons/warning-icon.png';
import ErrorImage from '../assets/images/icons/error-icon.png';
import InformativeImage from '../assets/images/icons/informative-icon.png';

// Styles
const StyledPopup = styled.div`
    z-index: 500001;
    position: fixed;
    background-color: rgba(33, 33, 33, 0.7);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
`;

const StyledPopupDialog = styled.div`
    cursor: initial;
    background-color: ${props => props.theme.colours.whiteBg};
    max-width: 60vw;
    width: 100%;
    padding: 20px;
    border-radius: 2px;
    margin: auto;
    margin-top: 50vh;
    transform: translateY(-50%);
`;

const StyledPopupDialogHeader = styled.div`
    margin: 0 -20px 15px -20px;
    padding: 0px 20px 15px 20px;
    border-bottom: solid 2px ${props => props.theme.colours.lightGrey};

    display: flex;
    align-items: center;
`;

const StyledPopupDialogHeaderIcon = styled.div<{ image: string }>`
    height: 26px;
    width: 26px;
    background-image: url(${props => props.image});
    background-position: center;
    background-size: contain;
`;

const StyledHeaderTeritiary = styled.h3`
    margin: 0 0 0 10px;
`;

// Types
type PopupButton = {
    buttonTheme?: ButtonThemes;
    onClick: () => void;
    value: string;
    style: string;
    isLoading?: boolean;
    disabled?: boolean;
};

const ViewPopup = () => {
    const popup = useSelector((state: RootState) => state.popup);
    const dispatch = useDispatch();

    const escKeyPress = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            dispatch(removePopup());
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escKeyPress, false);
        return () => {
            document.removeEventListener('keydown', escKeyPress, false);
        };
    }, [escKeyPress]);

    const renderContent = () => {
        switch (popup.contentType) {
            case 'simple':
                return <p>{popup.config.copy}</p>;
            case 'importIntegrationAccounts':
                return (
                    <LayoutPopupImportIntegrationAccounts
                        technology={popup.config.technology}
                        connectionId={popup.config.connectionId}
                        onImportFinishOverride={popup.config.onImportFinishOverride}
                    />
                );
            case 'createOauthConnection':
                return (
                    <LayoutPopupCreateOauthConnection
                        description={popup.config.description}
                        detailedDescription={popup.config.detailedDescription}
                        informationAboutPreviousConnection={popup.config.informationAboutPreviousConnection}
                    />
                );

            default:
                return null;
        }
    };

    if (!popup.isVisible) {
        return null;
    }

    const imageType =
        popup.iconType === 'warning'
            ? WarningImage
            : popup.iconType === 'error'
            ? ErrorImage
            : popup.iconType === 'informative'
            ? InformativeImage
            : '';

    return (
        <StyledPopup>
            <StyledPopupDialog>
                <StyledPopupDialogHeader>
                    <StyledPopupDialogHeaderIcon image={imageType} />
                    <StyledHeaderTeritiary>{popup.title}</StyledHeaderTeritiary>
                </StyledPopupDialogHeader>

                {renderContent()}

                <InputButtonWrapper>
                    {popup.buttons.map((button: PopupButton) => (
                        <InputButton
                            key={button.value}
                            value={button.value}
                            buttonTheme={button.buttonTheme}
                            disabled={button.disabled}
                            onClick={button.onClick}
                            isLoading={button.isLoading}
                        />
                    ))}
                </InputButtonWrapper>
            </StyledPopupDialog>
        </StyledPopup>
    );
};

export default ViewPopup;
