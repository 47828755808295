import { useInfiniteQuery } from '@tanstack/react-query';

// Types
import { CubedConfigResource, CubedResource } from '../../types';
import { Params } from '../../section-dashboard/types';

// Hooks
import generateQueryKey from '../helpers/generate-query-key';

// Query functions
import fetchInfiniteResource from '../query-functions/fetch-infinite-resource';

export type UseFetchInfiniteResourceArgs = {
    resource?: CubedResource | CubedConfigResource;
    params?: Params;
    enabled?: boolean;
    limit: number;
};

const useFetchInfiniteResource = ({
    resource,
    params = [],
    enabled = !!resource,
    limit = 100,
}: UseFetchInfiniteResourceArgs) => {
    const queryKey = generateQueryKey({ resource, params });

    if (typeof enabled === 'boolean') {
        enabled = enabled && !!resource;
    }

    const response = useInfiniteQuery({
        queryKey: queryKey,
        queryFn: fetchInfiniteResource,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.meta.next !== null) {
                return lastPage.meta.offset + limit;
            }

            return false;
        },
    });

    return response;
};

export default useFetchInfiniteResource;
