import React, { useContext, useEffect } from 'react';
import Form from '../../../components/forms/form';
import { FieldValues, useFormContext } from 'react-hook-form';
import { CreateADashboardContext } from '../context/create-a-dashboard-context';

const FormWidgetSearch = () => {
    const { handleFilterWidgets } = useContext(CreateADashboardContext);
    const { handleSubmit, watch } = useFormContext();

    const onSubmit = (data: FieldValues) => {
        handleFilterWidgets(data.search);
    };

    // Handle form submit on dropdown change
    useEffect(() => {
        const subscription = watch(() => handleSubmit(onSubmit)());
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Form.Body>
            <Form.Field>
                <Form.InputSearch name="search" placeholder="Search for a widget" />
            </Form.Field>
        </Form.Body>
    );
};

export default FormWidgetSearch;
