// React Dependencies
import React, { useEffect, useState } from 'react';

// Resources
import {
    CONFIG_BLACKLIST_IP,
    CONFIG_BLACKLIST_LEVEL,
    CONFIG_BLACKLIST_VISITOR,
} from '../../configurations/resources-config';

// Hooks
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import usePostResource from '../../react-query/hooks/use-post-resource';
import usePatchResource from '../../react-query/hooks/use-patch-resource';

// Helpers & Enums
import { isIp, isNumber } from '../../helpers/validator';
import { generatePath } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Types
import { ConfigDataSuccess } from '../../react-query/types';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

// Components
import WidgetAccordion from '../../widgets/accordion';
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

const LayoutModalCreateBlacklistRule = () => {
    const dispatch = useDispatch();
    const modal = useSelector((state: RootState) => state.modal);

    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState(true);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState(false);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
    const [closeButtonState, setCloseButtonState] = useState('close');

    const [ruleDetailsRuleType, setRuleDetailsRuleType] = useState('');
    const [ruleDetailsLevel, setRuleDetailsLevel] = useState('');
    const [ruleDetailsMinimumIp, setRuleDetailsMinimumIp] = useState('');
    const [ruleDetailsMaximumIp, setRuleDetailsMaximumIp] = useState('');
    const [ruleDetailsMinimumVisitorId, setRuleDetailsMinimumVisitorId] = useState('');
    const [ruleDetailsMaximumVisitorId, setRuleDetailsMaximumVisitorId] = useState('');
    const [ruleDetailsLevelErrorMessage, setRuleDetailsLevelErrorMessage] = useState('');
    const [ruleDetailsMinimumIpErrorMessage, setRuleDetailsMinimumIpErrorMessage] = useState('');
    const [ruleDetailsMaximumIpErrorMessage, setRuleDetailsMaximumIpErrorMessage] = useState('');
    const [ruleDetailsRuleTypeErrorMessage, setRuleDetailsRuleTypeErrorMessage] = useState('');
    const [ruleDetailsMinimumVisitorIdErrorMessage, setRuleDetailsMinimumVisitorIdErrorMessage] = useState('');
    const [ruleDetailsMaximumVisitorIdErrorMessage, setRuleDetailsMaximumVisitorIdErrorMessage] = useState('');

    const typeOptions = [
        {
            keyValue: `type-option__ip`,
            value: 'ip',
            name: 'IP Address',
        },
        {
            keyValue: `type-option__visitor`,
            value: 'visitor',
            name: 'Visitor ID',
        },
    ];

    // Queries and Mutations
    const levelOptionQuery = useFetchResource({
        resource: CONFIG_BLACKLIST_LEVEL,
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(option => {
                return {
                    keyValue: `level-option_${option.name.replace(/ /g, '')}`,
                    value: option.id,
                    name: option.name,
                };
            });
        },
    });

    const blacklistQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_BLACKLIST_IP,
        params: [
            {
                key: 'ip_min',
                value: ruleDetailsMinimumIp,
            },
            {
                key: 'ip_max',
                value: ruleDetailsMaximumIp,
            },
        ],
        enabled: ruleDetailsRuleType === 'ip' && ruleDetailsMinimumIp !== '' && ruleDetailsMaximumIp !== '',
    });

    const visitorBlacklistQuery = useFetchResource<ConfigDataSuccess>({
        resource: CONFIG_BLACKLIST_VISITOR,
        params: [
            {
                key: 'visitor_id_min',
                value: ruleDetailsMinimumVisitorId,
            },
            {
                key: 'visitor_id_max',
                value: ruleDetailsMaximumVisitorId,
            },
        ],
        enabled:
            ruleDetailsRuleType === 'visitor' &&
            ruleDetailsMinimumVisitorId !== '' &&
            ruleDetailsMaximumVisitorId !== '',
    });

    // Handler for mutation success
    const handleMutationSuccess = () => {
        setSaveChangesButtonDisabled(false);
        setSaveChangesButtonLoading(false);
        setCloseButtonDisabled(false);

        dispatch(
            addNotification({
                copy: 'The rule has been successfully added.',
                type: NotificationMessageType.Success,
            })
        );

        if (ruleDetailsRuleType === 'ip') {
            dispatch(setModal('ManageIpBlacklist', {}));
        } else {
            dispatch(setModal('ManageVisitorBlacklist', {}));
        }
    };

    // Handler for mutation error
    const handleMutationError = () => {
        dispatch(
            addNotification({
                copy: 'There was an issue trying to add this rule. Please try again later',
                type: NotificationMessageType.Error,
            })
        );
        setSaveChangesButtonDisabled(false);
        setSaveChangesButtonLoading(false);
        setCloseButtonDisabled(false);
    };

    const blacklistIPPostMutation = usePostResource({
        resource: CONFIG_BLACKLIST_IP,
        data: {
            ip_min: ruleDetailsMinimumIp,
            ip_max: ruleDetailsMaximumIp,
            level: generatePath('config', 'blacklist-level', ruleDetailsLevel),
            active: 1,
        },
        handleOnSuccess: handleMutationSuccess,
        handleOnError: handleMutationError,
    });

    const blacklistIPPatchMutation = usePatchResource({
        resource: CONFIG_BLACKLIST_IP,
        resourceId: blacklistQuery?.data?.objects[0]?.id,
        data: {
            ip_min: ruleDetailsMinimumIp,
            ip_max: ruleDetailsMaximumIp,
            level: generatePath('config', 'blacklist-level', ruleDetailsLevel),
            active: 1,
        },
        handleOnSuccess: handleMutationSuccess,
        handleOnError: handleMutationError,
    });

    const blacklistVisitorPostMutation = usePostResource({
        resource: CONFIG_BLACKLIST_VISITOR,
        data: {
            visitor_id_min: ruleDetailsMinimumVisitorId,
            visitor_id_max: ruleDetailsMaximumVisitorId,
            level: generatePath('config', 'blacklist-level', ruleDetailsLevel),
            active: 1,
        },
        handleOnSuccess: handleMutationSuccess,
        handleOnError: handleMutationError,
    });

    const blacklistVisitorPatchMutation = usePatchResource({
        resource: CONFIG_BLACKLIST_VISITOR,
        resourceId: visitorBlacklistQuery?.data?.objects[0]?.id,
        data: {
            visitor_id_min: ruleDetailsMinimumVisitorId,
            visitor_id_max: ruleDetailsMaximumVisitorId,
            level: generatePath('config', 'blacklist-level', ruleDetailsLevel),
            active: 1,
        },
        handleOnSuccess: handleMutationSuccess,
        handleOnError: handleMutationError,
    });

    //Enable the save changes and cancel when a user selects the type of rule they want
    useEffect(() => {
        if (saveChangesButtonDisabled && ruleDetailsRuleType !== '') {
            setSaveChangesButtonDisabled(false);
            setCloseButtonState('cancel');
        }
    }, [ruleDetailsRuleType, saveChangesButtonDisabled]);

    // Handle close modal
    const handleCloseModal = () => {
        if (modal.type === 'CreateIPBlacklistRule') {
            dispatch(setModal('ManageIpBlacklist', {}));
        } else {
            dispatch(setModal('ManageVisitorBlacklist', {}));
        }
    };

    // CLOSE - CANCEL click handler
    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            handleCloseModal();
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                    },
                    buttons: [
                        {
                            onClick: onPopupDiscardChangesClick,
                            value: 'DISCARD RULE',
                        },
                        {
                            onClick: () => dispatch(removePopup()),
                            value: 'STAY HERE',
                            style: 'secondary',
                        },
                    ],
                })
            );
        }
    };

    const formValidator = () => {
        setRuleDetailsLevelErrorMessage('');
        setRuleDetailsMinimumIpErrorMessage('');
        setRuleDetailsMaximumIpErrorMessage('');
        setRuleDetailsRuleTypeErrorMessage('');
        setRuleDetailsMinimumVisitorIdErrorMessage('');
        setRuleDetailsMaximumVisitorIdErrorMessage('');

        let hasFormError = false;

        if (ruleDetailsRuleType === 'ip') {
            if (!isIp(ruleDetailsMinimumIp) || ruleDetailsMinimumIp.length === 0) {
                hasFormError = true;
                setRuleDetailsMinimumIpErrorMessage('Please enter a valid IP Address.');
            }

            if (!isIp(ruleDetailsMaximumIp) || ruleDetailsMaximumIp.length === 0) {
                hasFormError = true;
                setRuleDetailsMaximumIpErrorMessage('Please enter a valid IP Address.');
            }
        } else if (ruleDetailsRuleType === 'visitor') {
            if (!isNumber(ruleDetailsMinimumVisitorId) || ruleDetailsMinimumVisitorId.length === 0) {
                hasFormError = true;
                setRuleDetailsMinimumVisitorIdErrorMessage('Please enter a valid Visitor ID.');
            }

            if (!isNumber(ruleDetailsMaximumVisitorId) || ruleDetailsMaximumVisitorId.length === 0) {
                hasFormError = true;
                setRuleDetailsMaximumVisitorIdErrorMessage('Please enter a valid Visitor ID.');
            }

            if (
                !isNumber(ruleDetailsMinimumVisitorId) &&
                !isNumber(ruleDetailsMaximumVisitorId) &&
                ruleDetailsMinimumVisitorId > ruleDetailsMaximumVisitorId
            ) {
                hasFormError = true;
                setRuleDetailsMaximumVisitorIdErrorMessage(
                    'Maximum visitor ID must be greater than Minimum Visitor ID.'
                );
            }
        }

        if (!isNumber(ruleDetailsLevel) || ruleDetailsLevel.length === 0) {
            hasFormError = true;
            setRuleDetailsLevelErrorMessage('Please select an option for the Level.');
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    // PrimaryAction of the close popup
    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        handleCloseModal();
    };

    // Saved changes clicked handler
    const onSaveChangesClick = () => {
        setSaveChangesButtonDisabled(true);
        setSaveChangesButtonLoading(true);
        setCloseButtonDisabled(true);

        if (formValidator()) {
            if (ruleDetailsRuleType === 'ip') {
                if (blacklistQuery.status === 'success' && blacklistQuery.data.objects.length > 0) {
                    blacklistIPPatchMutation.mutate();
                } else {
                    blacklistIPPostMutation.mutate();
                }
            } else {
                if (visitorBlacklistQuery.status === 'success' && visitorBlacklistQuery.data.objects.length > 0) {
                    blacklistVisitorPatchMutation.mutate();
                } else {
                    blacklistVisitorPostMutation.mutate();
                }
            }
        } else {
            setSaveChangesButtonDisabled(false);
            setSaveChangesButtonLoading(false);
            setCloseButtonDisabled(false);
        }
    };

    // Form Handlers
    const onRuleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRuleDetailsRuleType(event.target.value);
    };

    const onMinimumIPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRuleDetailsMinimumIp(event.target.value);
    };

    const onMaximumIPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRuleDetailsMaximumIp(event.target.value);
    };

    const onLevelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRuleDetailsLevel(event.target.value);
    };

    const onMinimumVisitorIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRuleDetailsMinimumVisitorId(event.target.value);
    };

    const onMaximumVisitorIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRuleDetailsMaximumVisitorId(event.target.value);
    };

    // Modal Navigation
    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled || blacklistQuery.isLoading || visitorBlacklistQuery.isLoading,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    // Accordion
    const renderAccordion = () => {
        // Default fields in the Rule Details Accordion
        let fields;

        fields = [
            {
                label: 'Rule Type:',
                type: 'select',
                requiredField: true,
                toolTipCopy: 'Select whether the rule should blacklist based on Visitor ID or IP address.',
                inputKeyValue: 'rule-details__rule-type',
                inputOptions: typeOptions,
                inputValue: ruleDetailsRuleType,
                inputOnChange: onRuleTypeChange,
                errorMessage: ruleDetailsRuleTypeErrorMessage,
            },
        ];

        if (ruleDetailsRuleType === 'ip') {
            fields = [
                {
                    label: 'Rule Type:',
                    type: 'select',
                    requiredField: true,
                    toolTipCopy: 'Select whether the rule should blacklist based on Visitor ID or IP address.',
                    inputKeyValue: 'rule-details__rule-type',
                    inputOptions: typeOptions,
                    inputValue: ruleDetailsRuleType,
                    inputOnChange: onRuleTypeChange,
                    errorMessage: ruleDetailsRuleTypeErrorMessage,
                },
                {
                    label: 'Minimum IP:',
                    type: 'text',
                    requiredField: true,
                    toolTipCopy: 'The start of the IP range you wish to ignore hits from.',
                    inputKeyValue: 'rule-details__minimum-ip',
                    inputValue: ruleDetailsMinimumIp,
                    inputOnChange: onMinimumIPChange,
                    errorMessage: ruleDetailsMinimumIpErrorMessage,
                },
                {
                    label: 'Maximum IP:',
                    type: 'text',
                    requiredField: true,
                    toolTipCopy:
                        "The end of the IP range you wish to ignore hits from. If it's only a single IP then just add the same IP here.",
                    inputKeyValue: 'rule-details__maximum-ip',
                    inputValue: ruleDetailsMaximumIp,
                    inputOnChange: onMaximumIPChange,
                    errorMessage: ruleDetailsMaximumIpErrorMessage,
                },
                {
                    label: 'Level:',
                    type: 'select',
                    requiredField: true,
                    toolTipCopy: 'Define what to do with visits from your defined blacklist.',
                    inputKeyValue: 'rule-details__level',
                    inputOptions: levelOptionQuery.status === 'success' ? levelOptionQuery.data : [],
                    inputValue: ruleDetailsLevel,
                    inputOnChange: onLevelChange,
                    errorMessage: ruleDetailsLevelErrorMessage,
                },
            ];
        } else if (ruleDetailsRuleType === 'visitor') {
            fields = [
                {
                    label: 'Rule Type:',
                    type: 'select',
                    requiredField: true,
                    toolTipCopy: 'Select whether the rule should blacklist based on Visitor ID or IP address.',
                    inputKeyValue: 'rule-details__rule-type',
                    inputOptions: typeOptions,
                    inputValue: ruleDetailsRuleType,
                    inputOnChange: onRuleTypeChange,
                    errorMessage: ruleDetailsRuleTypeErrorMessage,
                },
                {
                    label: 'Minimum Visitor ID:',
                    type: 'text',
                    requiredField: true,
                    toolTipCopy: 'The start of the Visitor ID range you wish to ignore hits from.',
                    inputKeyValue: 'rule-details__minimum-visitor-id',
                    inputValue: ruleDetailsMinimumVisitorId,
                    inputOnChange: onMinimumVisitorIDChange,
                    errorMessage: ruleDetailsMinimumVisitorIdErrorMessage,
                },
                {
                    label: 'Maximum Visitor ID:',
                    type: 'text',
                    requiredField: true,
                    toolTipCopy:
                        "The end of the Visitor ID range you wish to ignore hits from. If it's only a single ID then just add the same ID here.",
                    inputKeyValue: 'rule-details__maximum-visitor-id',
                    inputValue: ruleDetailsMaximumVisitorId,
                    inputOnChange: onMaximumVisitorIDChange,
                    errorMessage: ruleDetailsMaximumVisitorIdErrorMessage,
                },
                {
                    label: 'Level:',
                    type: 'select',
                    requiredField: true,
                    toolTipCopy: 'Define what to do with visits from your defined blacklist.',
                    inputKeyValue: 'rule-details__level',
                    inputOptions: levelOptionQuery.status === 'success' ? levelOptionQuery.data : [],
                    inputValue: ruleDetailsLevel,
                    inputOnChange: onLevelChange,
                    errorMessage: ruleDetailsLevelErrorMessage,
                },
            ];
        }

        const accordions = [
            {
                header: 'Rule Details',
                required: true,
                open: true,
                type: 'form',
                config: {
                    formConfig: {
                        fields: fields,
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    if (levelOptionQuery.isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Create Blacklist Rule</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (levelOptionQuery.isError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Create Blacklist Rule</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Create Blacklist Rule</h2>
            <p>
                Create a new blacklisting rule below. For more help see{' '}
                <a href="https://tag.docs.withcubed.com/onboarding/general/#blacklisting>blacklisting">blacklisting</a>.
            </p>
            {renderAccordion()}
        </div>
    );
};

export default LayoutModalCreateBlacklistRule;
