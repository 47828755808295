// Helpers
import { unknownErrorData } from '../../helpers/errors';
import { getRawValueAsNumber, getValue } from '../../helpers/resource-data';

// Types
import { CubedField } from '../../../types';
import { PieDataComparison, PieSeries, ResourceComparisonData } from '../../types';
import { graphColours } from '../../helpers/graph-colours';

export type UseResourcePieChartComparisonArgs = {
    resourceData: ResourceComparisonData;
    dimension?: CubedField;
};

const useResourcePieChartComparison = ({
    resourceData,
    dimension,
}: UseResourcePieChartComparisonArgs): PieDataComparison => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success' && dimension && resourceData.request?.chartField) {
        const field = resourceData.request.chartField;

        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        let series: PieSeries[] = [];
        let comparisonSeries: PieSeries[] = [];

        if (resource.status === 'success') {
            const total = resource.objects
                .map(data => getRawValueAsNumber(data, field.rawName))
                .reduce((a, b) => a + b);

            const seriesData = resource.objects.map((object, index) => {
                const colour = graphColours[index % graphColours.length].solidGraphColour;

                return {
                    name: getValue(object, dimension.rawName),
                    value: getRawValueAsNumber(object, field.rawName),
                    color: colour,
                };
            });

            seriesData.sort((a, b) => b.value - a.value);

            series = [
                {
                    name: dimension.rawName,
                    data: seriesData,
                    total: total,
                },
            ];
        }

        if (comparisonResource.status === 'success') {
            const total = comparisonResource.objects
                .map(data => getRawValueAsNumber(data, field.rawName))
                .reduce((a, b) => a + b);

            const seriesData = comparisonResource.objects.map((object, index) => {
                const colour = graphColours[index % graphColours.length].solidGraphColour;

                return {
                    name: getValue(object, dimension.rawName),
                    value: getRawValueAsNumber(object, field.rawName),
                    color:
                        series[0]?.data.find(data => data.name === getValue(object, dimension.rawName))?.color ||
                        colour,
                };
            });

            seriesData.sort((a, b) => b.value - a.value);

            comparisonSeries = [
                {
                    name: dimension.rawName,
                    data: seriesData,
                    total: total,
                },
            ];
        }

        return {
            type: 'pieChart',
            status: 'success',
            request: resourceData.request,
            series: series,
            comparisonSeries: comparisonSeries,
        };
    }

    return unknownErrorData();
};

export default useResourcePieChartComparison;
