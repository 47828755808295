import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

// Context
import { useConfigDragAndDropTableContext } from '../context/config-drag-and-drop-table-context';

const StyledRow = styled.tr<{
    isFetching: boolean;
    disabled: boolean;
    isDragging?: boolean;
    selected?: boolean;
    dragDisabled?: boolean;
}>`
    table-layout: 'initial';
    user-select: 'none';
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colours.lightGrey};
    background: ${props =>
        props.isDragging
            ? props.theme.colours.offBlackLighter
            : props.selected
            ? props.theme.colours.lightGrey
            : props.theme.colours.white};
    color: ${props => (props.isDragging ? props.theme.colours.white : props.theme.colours.offBlackLighter)};
    display: ${props => (props.isDragging ? 'table' : 'table-row')};
    border-radius: ${props => (props.isDragging ? '5px 5px 6px 6px' : '0px')};
    cursor: ${props => (props.dragDisabled ? 'default' : props.isDragging ? 'grabbing' : 'grab')};

    :hover,
    :focus {
        background-color: ${props => !props.disabled && !props.isFetching && '#f9f9f9'};
    }
`;

type SubchannelRule = {
    id: number;
    name: string;
    rule: string;
    description: string;
    priority: number;
    subchannel: string;
    subchannelId: number;
    referer: string;
};

type TableRow = {
    dragId: string;
    selected: boolean;
} & SubchannelRule;

type RowProps = {
    key: string | number;
    index: number;
    row?: TableRow;
    children: React.ReactNode;
};

const Row = ({ key, index, row, children }: RowProps) => {
    const { status, isFetching, tableDisabled, dragDisabled } = useConfigDragAndDropTableContext();

    if (status) {
        if (row) {
            return (
                <Draggable key={key} draggableId={row.dragId} index={index} isDragDisabled={dragDisabled}>
                    {(provided, snapshot) => (
                        <StyledRow
                            isFetching={isFetching}
                            disabled={tableDisabled}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                ...provided.draggableProps.style,
                                left: '20',
                                tableLayout: 'fixed',
                            }}
                            selected={row.selected}
                            dragDisabled={dragDisabled}
                            isDragging={snapshot.isDragging}
                        >
                            {React.Children.map(children, child =>
                                React.cloneElement(child as React.ReactElement, { isDragging: snapshot.isDragging })
                            )}
                        </StyledRow>
                    )}
                </Draggable>
            );
        }

        return (
            <StyledRow key={key} isFetching={isFetching} disabled={tableDisabled}>
                {children}
            </StyledRow>
        );
    }

    return null;
};

export default Row;
