import React, { Component } from 'react';

import SimpleTable from '../tables/components/simple-table';
import InputButton from '../inputs/input-button';
import InputButtonWrapper from '../inputs/input-button-wrapper';

class AccordionSimpleTable extends Component {
    constructor(props) {
        super(props);

        this.renderOptionalButtons = this.renderOptionalButtons.bind(this);
    }

    renderOptionalButtons() {
        if (this.props.config.optionalButtonConfig === undefined) {
            return null;
        }

        return (
            <InputButtonWrapper>
                {this.props.config.optionalButtonConfig.buttons.map((button, index) => (
                    <InputButton
                        key={index}
                        value={button.value}
                        buttonTheme={button.buttonTheme}
                        disabled={button.disabled}
                        onClick={button.onClick}
                    />
                ))}
            </InputButtonWrapper>
        );
    }

    render() {
        return (
            <div className="accordion__item-content__simple-table">
                <p className="accordion__item-content__simple-table__copy">{this.props.config.copy}</p>

                <SimpleTable
                    rows={this.props.config.tableRows}
                    header={this.props.config.tableHeader}
                    isLoading={this.props.config.isLoading}
                    errorMessageOverride={this.props.config.errorMessageOverride}
                    enableSearch={this.props.config.enableSearch}
                    searchableColumns={this.props.config.searchableColumns}
                    enableFilters={this.props.config.enableFilters}
                    filters={this.props.config.filters}
                    hasIcons={this.props.config.hasIcons}
                    isScrollable={this.props.config.isScrollable}
                    hasEndAction={this.props.config.hasEndAction}
                />

                <this.renderOptionalButtons />
            </div>
        );
    }
}

export default AccordionSimpleTable;
