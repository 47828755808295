// React Dependencies
import React, { Component } from 'react';

// Import Components
import AccordionForm from './accordion-items/accordion-form';
import AccordionListBuilder from './accordion-items/accordion-list-builder';
import AccordionList from './accordion-items/accordion-list';
import AccordionSimpleTable from './accordion-items/accordion-simple-table';
import AccordionFormList from './accordion-items/accordion-form-list';
import AccordionFormSimpleTable from './accordion-items/accordion-form-simple-table';
import IconChevronUp from './icons/chevron-up';

class Accordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: this.props.accordion.open,
        };

        this.onAccordionClick = this.onAccordionClick.bind(this);
        this.renderAccordionContent = this.renderAccordionContent.bind(this);
        this.renderIntroText = this.renderIntroText.bind(this);
    }
    onAccordionClick() {
        this.setState({
            active: !this.state.active,
        });
    }

    componentWillUpdate(prevProps, prevState) {
        if (this.props.accordion.open !== prevProps.accordion.open) {
            this.setState({
                active: prevProps.accordion.open,
            });
        }
    }

    renderIntroText() {
        if (this.props.accordion.intro !== undefined) {
            const html = { __html: this.props.accordion.intro };
            return <div className="accordion__item-content__intro" dangerouslySetInnerHTML={html}></div>;
        }
        return null;
    }

    renderAccordionContent() {
        switch (this.props.accordion.type) {
            case 'form':
                return (
                    <AccordionForm
                        config={this.props.accordion.config}
                        onFormSubmitClick={this.props.accordion.onFormSubmitClick}
                    />
                );
            case 'list-builder':
                return (
                    <AccordionListBuilder
                        config={this.props.accordion.config}
                        onFormSubmitClick={this.props.accordion.onFormSubmitClick}
                    />
                );
            case 'list':
                return (
                    <AccordionList
                        config={this.props.accordion.config}
                        onFormSubmitClick={this.props.accordion.onFormSubmitClick}
                    />
                );
            case 'formList':
                return (
                    <AccordionFormList
                        config={this.props.accordion.config}
                        onFormSubmitClick={this.props.accordion.onFormSubmitClick}
                    />
                );
            case 'formSimpleTable':
                return <AccordionFormSimpleTable config={this.props.accordion.config} />;
            case 'simpleTable':
                return <AccordionSimpleTable config={this.props.accordion.config} />;
            default:
                return null;
        }
    }

    render() {
        return (
            <div className={`accordion__item ${this.state.active ? 'active' : ''}`}>
                <div className="accordion__item-header" onClick={this.onAccordionClick}>
                    <div className="accordion__item-header__icon">
                        <div className="icon icon-chevron-up">
                            <IconChevronUp />
                        </div>
                    </div>
                    <h3>
                        {this.props.accordion.header}{' '}
                        {this.props.accordion.required ? <span className="required">*</span> : ''}{' '}
                    </h3>
                </div>
                <div className="accordion__item-content">
                    <this.renderIntroText />
                    <this.renderAccordionContent />
                </div>
            </div>
        );
    }
}

export default Accordion;
