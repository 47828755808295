import * as fields from './fields';

export const REPORT_SCV_VISITORS = {
    category: 'report',
    id: 'report-scv-visitors',
    displayName: 'Single Customer View Vistors',
    defaultDateDimension: fields.LAST_VISIT,
    dateDimensions: [fields.FIRST_VISIT, fields.LAST_VISIT, fields.LATEST_SALE_DATE],
    defaultDimensions: [fields.VISITOR_ID],
    defaultOrderBy: fields.LAST_VISIT,
};

export const REPORT_SCV_TRANSACTIONS = {
    category: 'report',
    id: 'report-scv-transactions',
    displayName: 'Single Customer View Transactions',
    defaultDateDimension: fields.SALES_DATE,
    dateDimensions: [fields.SALES_DATE],
    defaultDimensions: [fields.TRANSACTION_ID],
    defaultOrderBy: fields.SALES_DATE,
};

export const REPORT_SCV_CHANNEL = {
    category: 'report',
    id: 'report-scv-channel',
    displayName: 'Single Customer View Channel',
    defaultDateDimension: fields.LAST_VISIT,
    dateDimensions: [fields.FIRST_VISIT, fields.LAST_VISIT, fields.LATEST_SALE_DATE],
    defaultDimensions: [fields.VISITOR_ID],
    defaultOrderBy: fields.LAST_VISIT,
};
