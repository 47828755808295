import React, { Component } from 'react';
import FunnelSection from './funnel-section';
import tinycolor from 'tinycolor2';
import './../sass/components/funnel.scss';

class Funnel extends Component {
    constructor(props) {
        super(props);
        this.renderSections = this.renderSections.bind(this);
    }
    static defaultProps = {
        colour: tinycolor('#58b5c4'),
    };
    renderSections() {
        return (
            <div className="funnel">
                {this.props.data.map((data, index) => {
                    let endCount = data.count;
                    if (this.props.data[index + 1]) {
                        endCount = this.props.data[index + 1].count;
                    }
                    return (
                        <FunnelSection
                            data={data}
                            dataSize={this.props.data.length}
                            max={this.props.max}
                            endCount={endCount}
                            key={index}
                            colour={this.props.colour}
                            showNames={this.props.showNames}
                        />
                    );
                })}
            </div>
        );
    }
    render() {
        return <this.renderSections />;
    }
}

export default Funnel;
