import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { setModal } from '../../redux/actions/modal';
import Tooltip, { TooltipPopUpSide } from '../../components/tooltip';

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
`;

const StyledExpandedDropdownItem = styled.li`
    color: ${props => props.theme.colours.light};
    font-size: 13px;
    margin-left: 45px;
    border-left: 1px solid
        ${props => (props.isActive ? props.theme.colours.cubedOrange : props.theme.colours.darkerGrey)};
    padding: 14px 20px 14px 27px;
    font-weight: 500;
    text-align: left;

    &:hover {
        border-left: 1px solid ${props => props.theme.colours.cubedOrange};
        background-color: ${props => props.theme.colours.offBlackLighter};
    }
`;

const NavDropdownItems = ({ dropdownItems, sidebarVisible }) => {
    const { account } = useSelector(state => state);
    const dispatch = useDispatch();

    return (
        sidebarVisible && (
            <ul data-testid="nav-section-items">
                {dropdownItems
                    .filter(item => !item.itemExcludedAccountTypes?.includes(account.group))
                    .map(item => {
                        if (item.modal) {
                            return (
                                <StyledNavLink
                                    to={'#'}
                                    key={item.displayName}
                                    onClick={() => dispatch(setModal(item.modal, {}))}
                                >
                                    <Tooltip
                                        copy={item.description}
                                        tooltipPopUpSide={TooltipPopUpSide.Right}
                                        usePlaceholder={false}
                                        invertColours={true}
                                        preventClick={false}
                                    >
                                        <StyledExpandedDropdownItem key={item.displayName}>
                                            {item.displayName}
                                        </StyledExpandedDropdownItem>
                                    </Tooltip>
                                </StyledNavLink>
                            );
                        } else {
                            return (
                                <StyledNavLink
                                    to={`/${account.token + item.path}`}
                                    key={item.displayName}
                                    children={({ isActive }) => {
                                        return (
                                            <Tooltip
                                                copy={item.description}
                                                tooltipPopUpSide={TooltipPopUpSide.Right}
                                                usePlaceholder={false}
                                                invertColours={true}
                                                preventClick={false}
                                            >
                                                <StyledExpandedDropdownItem key={item.displayName} isActive={isActive}>
                                                    {item.displayName}
                                                </StyledExpandedDropdownItem>
                                            </Tooltip>
                                        );
                                    }}
                                />
                            );
                        }
                    })}
            </ul>
        )
    );
};

export default NavDropdownItems;
